export type SubscriptionType = 'FREE' | 'ESSENTIAL' | 'ADVANCED' | 'UNLIMITED';

export class MisAccount {
  id: number;
  companyName: string;
  address: string;
  zipCode: string;
  city: string;
  countryCode: string;
  vatId: string;
  web: string;
  contacts: MisContact[];
  tags: MisTag[];
  subscription: SubscriptionType;
}

export class MisRegisterAccount {
  email: string;
  password: string;
  promotionCode: string;
  reCaptcha: MisReCaptcha;
  newsletter: boolean;
}

export class MisReCaptcha {
  reCaptchaId: string;
  reCaptchaResponse: string;
}

export type MisContactType = 'BILLING' | 'TECHNICAL' | 'USER';

export type MisSalutation = 'MR' | 'MRS' | 'X' | '';

export class MisContact {
  id: number;
  type: MisContactType[];
  salutation: MisSalutation;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  phone: string;
  mobile: string;
}

export type MisTagType = 'BOOLEAN' | 'DATE' | 'DATE_TIME' | 'EMAIL' | 'FLOAT' | 'INTEGER' | 'TEXT' | 'JSON' | 'URL';

export class MisTag {
  id: number;
  key: string;
  value: any;
  type: MisTagType;
  readonly: boolean;
}


export type MisUserRole = 'SUPER_USER' | 'ACCOUNT_ADMIN' | 'ACCOUNT_MEMBER' | 'INACTIVE';

export class MisUser {
  id: number;
  cognitoId: string;
  role: MisUserRole;
  contact: MisContact;
  tags: MisTag[];
}

export class MisUserWithAccount {
  id: number;
  cognitoId: string;
  role: MisUserRole;
  account: MisAccount;
  contact: MisContact;
  tags: MisTag[];
}

export class MisUserCreate {
  role: MisUserRole;
  email: string;
  password: string;
  salutation: string;
  firstName: string;
  lastName: string;
  phone: string;
  mobile: string;
}

export class MisNewsletter {
  registrationDate: Date;
}

export const MisTagUtils = {
  keys: {
    flowFactServerUrl: 'flowfact.server.url',
    flowFactServerCustomerId: 'flowfact.server.customer.id',
    flowFactServerUserName: 'flowfact.server.user.name',
    flowFactServerUserPassword: 'flowfact.server.user.password',

    immolistFilters: 'immolist.filters',
    immolistActiveFilter: 'immolist.activeFilter',
    immolistColumnOrder: 'immolist.columnOrder',

    priceAtlasLastPosition: 'priceAtlas.lastPosition',

    termsAcceptanceDate: 'terms.acceptance.date',
    emailVerificationPending: 'email.verification.pending',

    finishedGuidedTourInitial: 'finished.guided.tour.initial',
    finishedGuidedTourTable: 'finished.guided.tour.table',
    finishedGuidedTourImmSubMenu: 'finished.guided.tour.immo.submenu',
    finishedGuidedTourPriceAtlas: 'finished.guided.tour.price.atlas',

    notificationOffMarketOffers: 'notification.receive.off.market.offers',

    filterRestrictedZipCodes: 'filter.restricted.zip.codes',

    marketReportLayout: 'marketReport.layout',

    permissionMarketReports: 'permission.marketReports'
  },

  getTagByKey: function (tags: MisTag[], key: string): MisTag {
    if (tags.length === 0) {
      return null;
    }
    const foundTags = tags.filter(tag => tag.key === key);
    if (foundTags.length > 0) {
      return foundTags[0];
    }
    return null;
  }
};
