<div class="mis-modal-medium">
  <h2 mat-dialog-title>Marktberichtlayout anpassen</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form [formGroup]="formGroup"
        (submit)="saveAndClose()"
        *ngIf="!isLoading">
    <mat-dialog-content>

      <mis-hint type="INFO-BORDERED">
        Die Layoutanpassungen sind <b>nur</b> für Marktberichte verfügbar, welche von <u>propfox.io</u> herausgegeben werden.
      </mis-hint>

      <!-- Texte -->
      <h3>Texte</h3>
      <mat-form-field>
        <textarea matInput
                  formControlName="introText"
                  name="introText"
                  placeholder="Vorwort"
                  rows="6"
                  required="required">
        </textarea>
      </mat-form-field>

      <!-- Farbe -->
      <h3>Layout und Farben</h3>
      <div class="mis-row mis-row-separated">
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="introMainHeadingColor"
                                       placeholder="Introseite - Überschrift 1"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="introMainHeadingColor"
                                       placeholder="Introseite - Überschrift 2 (Stadtname)"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
      </div>
      <div class="mis-row mis-row-separated">
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="mainHeadingColor"
                                       placeholder="Farbe Überschrift Ebene 1"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="subHeadingColor"
                                       placeholder="Farbe Überschrift Ebene 2"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
      </div>
      <div class="mis-row mis-row-separated">
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="textColor"
                                       placeholder="Textfarbe"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
      </div>

      <div class="mis-row mis-row-separated">
        <div class="mis-column-50">
          <mis-form-field-file-upload accept=".jpg,.png,.svg"
                                      placeholder="Logo"
                                      [file]="logoFile"
                                      (choseFile)="changedLogoFile($event)">
          </mis-form-field-file-upload>
        </div>
      </div>

      <!-- Chartfarben -->
      <h3>Layout und Farben</h3>
      <div class="mis-row mis-row-separated">
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="chartColor1"
                                       placeholder="Diagrammfarbe 1"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="chartColor2"
                                       placeholder="Diagrammfarbe 2"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
        <div class="mis-column-50">
          <mis-form-field-color-picker [formGroup]="formGroup"
                                       formGroupControlName="chartColor3"
                                       placeholder="Diagrammfarbe 3"
                                       required="required">
          </mis-form-field-color-picker>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Speichern
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
