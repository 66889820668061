import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ResearchItem} from '../../../../services/research/model/research.item.model';
import {FieldName, QuerySpec, SortField} from '../../../../services/research/model/research.filter.model';
import {ColumnConfigurationService} from '../../../../services/research/column.configuration.service';
import * as moment from 'moment';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../../viewservice/view.service';
import {MisEventUtils} from '../../../../services/common/event.util';
import {ImmoTableColumnConfiguration, ImmoTableColumnConfigurationRestriction} from './immo-table-configuration/immo.table.classes';
import {GuidedTourStep} from '../../guided-tour/guided.tour.service';
import {ImmoTableFilterEvent} from './immo-table-column/immo.table.column.item';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormFieldsDropdownValue} from '../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';
import {ImmoDetailsModalComponent, ImmoDetailsModalComponentData} from './immo-table-details-pane/immo-details-modal/immo.details.modal.component';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';

export type SortDirection = 'ASC' | 'DESC';

export class SortItem {
  constructor(public direction: SortDirection, public colKey: string) {
  }
}

@Component({
  selector:        'mis-immo-table',
  templateUrl:     './immo.table.component.html',
  styleUrls:       ['./immo.table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableComponent implements OnInit, OnDestroy, OnChanges, ViewServiceChangeListener {
  @Input() filterQuery: QuerySpec;
  @Input() rows: ResearchItem[];
  @Input() height: number;
  @Input() detailsEnabled: boolean = true;
  @Input() columnConfiguration: ImmoTableColumnConfiguration[] = [];
  @Input() isLoading: boolean;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() pageTotalHits: number;
  @Input() totalPages: number;
  @Input() sortItem: SortItem;
  @Input() topMargin: number;
  @Output() changeSort = new EventEmitter();
  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() filter = new EventEmitter<ImmoTableFilterEvent>();
  guidedTourStep = GuidedTourStep;

  contentHeight = '700px';

  scrollLeftPosition = 0;
  isDragnDropDisabled: boolean;

  availablePages: { [key: string]: FormFieldsDropdownValue } = {};
  availablePagingItems: { [key: string]: FormFieldsDropdownValue } = {};
  viewServiceInstance = ViewServiceInstance;

  constructor(private dialog: MatDialog,
              private columnConfigurationService: ColumnConfigurationService,
              private authenticationProvider: AuthenticationProvider) {
  }

  ngOnInit(): void {
    this.columnConfiguration = this.columnConfigurationService.loadImmoTableColumnOrder();

    if (this.authenticationProvider.getUser().account.subscription === 'FREE') {
      this.columnConfiguration.forEach((column) => {
        if (column.colKey === 'preis' ||
          column.colKey === 'preisJeQm') {
          column.restrictions = new ImmoTableColumnConfigurationRestriction(true);
        }
      });
    } else {
      this.columnConfiguration.forEach((column) => {
        column.restrictions = null;
      });
    }

    ViewServiceInstance.listenOnResize(this);

    this.isDragnDropDisabled = ViewServiceInstance.isMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initPagination();
    this.onResize();

    this.availablePages = {};
    for (let i = 0; i < this.totalPages; i++) {
      this.availablePages[i] = new FormFieldsDropdownValue('' + (i + 1));
    }
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    if (this.height) {
      const padding = 20;
      this.contentHeight = (this.height - padding) + 'px';
      return;
    }

    const tablePadding = 30;
    let newHeight = window.innerHeight - this.topMargin - tablePadding;
    this.contentHeight = newHeight + 'px';

    if (ViewServiceInstance.isMobile()) {
      this.contentHeight = window.innerHeight - tablePadding + 'px';
    }
  }

  private initPagination(): void {
    this.availablePagingItems = {};

    if (this.pageSizeOptions) {
      this.pageSizeOptions.forEach(pso => this.availablePagingItems[pso] = new FormFieldsDropdownValue('' + pso));
    }
  }

  // ######## Sort & pagination
  changedSort(event: Event, direction: SortDirection, colKey: string) {
    event.stopPropagation();
    this.sortItem = new SortItem(direction, colKey);

    let sortField;
    if (direction === 'ASC') {
      sortField = new SortField(<FieldName>colKey, 'asc');
    } else {
      sortField = new SortField(<FieldName>colKey, 'desc');
    }
    this.changeSort.emit(sortField);
  }

  changedPagination(newPage: number | string, newPageSize: number, event: Event = null) {
    MisEventUtils.stopEvent(event);

    const maxPages = Math.ceil(this.pageTotalHits / newPageSize);
    if (newPage < 0 || newPage >= maxPages) {
      return;
    }

    const pageEvent = new PageEvent();
    pageEvent.pageSize = newPageSize;
    pageEvent.pageIndex = parseInt('' + newPage);
    this.changePage.emit(pageEvent);
  }

  // ######## Show details
  toggleDetails(event: Event, researchItem: ResearchItem) {
    if (event) {
      event.stopPropagation();
    }

    if (this.detailsEnabled) {
      researchItem.tableShowDetails = !researchItem.tableShowDetails;
    }
  }

  isResearchItemInactive(researchItem: ResearchItem): boolean {
    if (researchItem.inactive == null) {
      const currentDate = moment();
      researchItem.inactive = currentDate.isAfter(researchItem.validUntil);
    }

    return researchItem.inactive;
  }

  scrollTable(event): void {
    if (this.viewServiceInstance.isMobile()) {
      this.scrollFilterBarOnMobile(event);
      return;
    }

    let newLeftPosition = event.target.scrollLeft;
    this.scrollLeftPosition = newLeftPosition > 0 ? newLeftPosition : 0;
  }

  private scrollFilterBarOnMobile(event): void {
    if (event.target.scrollTop > 0) {
      const naviHeight = document.getElementsByClassName('mis-filter-pane-wrapper')[0].clientHeight;
      document.getElementsByClassName('mis-immo-main')[0].scroll({top: naviHeight});
    }
  }

  changedTableHeaderItems(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnConfiguration, event.previousIndex, event.currentIndex);
    this.columnConfigurationService.saveImmoTableColumnOrder(this.columnConfiguration);
  }

  changedHitsPerPage(values: { [key: string]: FormFieldsDropdownValue }) {
    this.changedPagination(0, parseInt(Object.keys(values)[0]));
  }

  changedPage(values: { [key: string]: FormFieldsDropdownValue }) {
    this.changedPagination(parseInt(Object.keys(values)[0]), this.pageSize);
  }

  clickedColumn(colConfig: ImmoTableColumnConfiguration, row: ResearchItem): void {
    if (colConfig.columnName === 'imageUrl' ||
      colConfig.columnName === 'title') {
      this.dialog.open(ImmoDetailsModalComponent, {
        data: new ImmoDetailsModalComponentData(row, this.filterQuery)
      });
    }
  }
}
