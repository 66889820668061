import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent, SnackbarData} from './snackbar.component';

@Injectable({providedIn: 'root'})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {
  }

  success(message: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: new SnackbarData('fas fa-check', message)
    });
  }

  error(message: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: new SnackbarData('fas fa-car-crash', message, 'ERROR')
    });
  }

  hint(message: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: new SnackbarData('fas fa-exclamation', message, 'HINT')
    });
  }
}
