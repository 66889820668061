<div #mapContainer>

  <mis-higher-tariff-required-hint *ngIf="!featureActivated"
                                   type="MAP">
    Die Ansicht der Kartenfunktion ist erst ab dem Tarif "Essential" nutzbar.
  </mis-higher-tariff-required-hint>

  <google-map *ngIf="featureActivated"
              [width]="width"
              [height]="height"
              [center]="mapCenter"
              [zoom]="mapZoom"
              [options]="mapOptions"
              (mapClick)="click($event)"
              (mapDragend)="changedMapBounds()"
              (zoomChanged)="changedZoom()"
              (mapMouseout)="emitMapMouseout()">

    <map-marker *ngFor="let markerPosition of mapMarkerPositions"
                [position]="markerPosition"
                [options]="mapMarkerOptions"></map-marker>

    <map-polygon *ngFor="let polygon of zipCodeMapData?.polygons"
                 [paths]="polygon.coordinates"
                 [options]="zipCodePolygonOptions"></map-polygon>

    <map-polygon *ngFor="let polygon of polygons"
                 [paths]="polygon.coordinates"
                 [options]="polygon.options"
                 (polygonClick)="polygonClick(polygon)"
                 (polygonMouseover)="polygonHover(polygon)"></map-polygon>

    <map-ground-overlay *ngIf="imageOverlay"
                        [bounds]="imageOverlay.bounds"
                        [opacity]="imageOverlay.opacity"
                        [url]="imageOverlay.imgSrc"></map-ground-overlay>
  </google-map>

</div>
