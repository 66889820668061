import {TextColumn} from '../immo-table-column/immo-table-column-text/immo.table.column.text.component';
import {ImageColumn} from '../immo-table-column/immo-table-column-image/immo.table.column.image.component';
import {NumberColumn} from '../immo-table-column/immo-table-column-number/immo.table.column.number.component';
import {BooleanColumn} from '../immo-table-column/immo-table-column-boolean/immo.table.column.boolean.component';
import {SourceColumn} from '../immo-table-column/immo-table-column-source/immo.table.column.source.component';
import {DateColumn} from '../immo-table-column/immo-table-column-date/immo.table.column.date.component';
import {RealtorColumn} from '../immo-table-column/immo-table-column-realtor/immo.table.column.realtor.component';
import {ImmoTableColumnConfiguration} from './immo.table.classes';
import {PriceWithTrendColumn} from '../immo-table-column/immo-table-column-price-with-trend/immo.table.column.price.with.trend.component';
import {TitleColumn} from "../immo-table-column/immo-table-column-title/immo-table-column-title.component";

/**
 * Konfiguration der Immobilien Tabellenspalten.
 */
export const IMMO_TABLE_COLUMN_CONFIGURATION: ImmoTableColumnConfiguration[] = [
  new ImmoTableColumnConfiguration('imageUrl', 'Bild', '', 'imageUrl', 's', false, new ImageColumn()),
  new ImmoTableColumnConfiguration('source', 'Quelle', '', 'source', 'l', false, new SourceColumn()),
  new ImmoTableColumnConfiguration('title', 'Titel', '', 'title', 'xxl', true, new TitleColumn()),
  new ImmoTableColumnConfiguration('postleitzahl', 'PLZ', '', 'adresse.postleitzahl', 'm', true, new TextColumn()),
  new ImmoTableColumnConfiguration('ort', 'Ort', '', 'adresse.ort', 'xl', true, new TextColumn()),

  new ImmoTableColumnConfiguration('preis', 'Preis', '', 'preis', 'm', true, new PriceWithTrendColumn()),
  new ImmoTableColumnConfiguration('preisJeQm', 'Preis je m²', '', 'preisJeQm', 'm', true, new NumberColumn('€/m²')),
  new ImmoTableColumnConfiguration('freeOfCommission', 'Provision', '', 'freeOfCommission', 'm', true, new BooleanColumn(true)),

  new ImmoTableColumnConfiguration('nebenkosten', 'Nebenkosten', '', 'nebenkosten', 'm', true, new NumberColumn('€')),
  new ImmoTableColumnConfiguration('heizkosten', 'Heizkosten', '', 'heizkosten', 'm', true, new NumberColumn('€')),
  new ImmoTableColumnConfiguration('kaution', 'Kaution', '', 'kaution', 'm', true, new NumberColumn('€')),

  new ImmoTableColumnConfiguration('wohnflaeche', 'Wohnfläche', '', 'wohnflaeche', 'm', true, new NumberColumn('m²')),
  new ImmoTableColumnConfiguration('zimmerAnzahl', 'Zimmer', '', 'zimmerAnzahl', 'm', true, new NumberColumn('', 1)),
  new ImmoTableColumnConfiguration('grundstueckflaeche', 'Grundstücksfläche', '', 'grundstueckflaeche', 'l', true, new NumberColumn('m²', 0)),

  new ImmoTableColumnConfiguration('firstOccurrenceDate', 'Gefunden am', '', 'firstOccurrenceDate', 'l', true, new DateColumn(true)),
  new ImmoTableColumnConfiguration('realtorName', 'Anbieter', '', 'realtorName', 'l', true, new RealtorColumn()),

  new ImmoTableColumnConfiguration('baujahr', 'Baujahr', '', 'baujahr', 'm', true, new TextColumn()),
  new ImmoTableColumnConfiguration('etage', 'Etage', '', 'etage', 'm', true, new TextColumn()),
  new ImmoTableColumnConfiguration('etagenAnzahl', 'Etagenanzahl', '', 'etagenAnzahl', 'm', true, new TextColumn()),
  new ImmoTableColumnConfiguration('zustand', 'Zustand', '', 'zustand', 'm', true, new TextColumn()),
  new ImmoTableColumnConfiguration('ausstattung', 'Ausstattung', '', 'ausstattung', 'm', true, new TextColumn()),

  new ImmoTableColumnConfiguration('badezimmer', 'Badezimmer', '', 'badezimmer', 'm', true, new NumberColumn('', 0)),
  new ImmoTableColumnConfiguration('schlafzimmer', 'Schlafzimmer', '', 'schlafzimmer', 'm', true, new NumberColumn('', 0)),
  new ImmoTableColumnConfiguration('merkmale', 'Merkmale', '', 'merkmale', 'm', false, new TextColumn()),
];
