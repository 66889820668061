import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export const PasswordValidator = Validators.compose([
  // 1. Password Field is Required
  Validators.required,
  // 2. check whether the entered password has a number
  Validators.pattern(/\d/),
  // 3. check whether the entered password has letters
  Validators.pattern(/[a-zA-Z]/),
  // 5. check whether the entered password has a special character
  //Validators.pattern(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/),
  // 6. Has a minimum length of 8 characters
  Validators.minLength(8)
]);

@Component({
  selector: 'mis-form-field-password',
  templateUrl: './form.fields.password.component.html',
})
export class FormFieldsPasswordComponent implements OnInit {
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.formGroup.addControl('password', new FormControl('', PasswordValidator));
    this.formGroup.addControl('passwordRepeat', new FormControl('', Validators.required));
  }
}
