<mis-loading *ngIf="isLoading"></mis-loading>

<div class="mis-account-management"
     *ngIf="!isLoading && account != null">
  <div class="mis-row">
    <div class="mis-column-50">
      <h2>Kontaktdaten (entspricht Rechnungsanschrift)<a href="#" (click)="editAccountData($event)">bearbeiten</a></h2>

      <mis-field-box label="Firma" [isEmpty]="!account.companyName">
        {{account.companyName ? account.companyName : 'Noch nicht gesetzt'}}
      </mis-field-box>

      <mis-field-box label="Adresse" [isEmpty]="!account.address">
        {{account.address ? account.address : 'Noch nicht gesetzt'}}<br>
        {{account.zipCode}} {{account.city}}<br>
        {{countryCode ? countryCode.name : ''}}
      </mis-field-box>

      <mis-field-box label="Umsatzsteuer ID" [isEmpty]="!account.vatId">
        {{account.vatId ? account.vatId : 'Noch nicht gesetzt'}}
      </mis-field-box>

      <mis-field-box label="Internet-Adresse" [isEmpty]="!account.web">
        {{account.web ? account.web : 'Noch nicht gesetzt'}}
      </mis-field-box>
    </div>

    <div class="mis-column-50">
      <h2>Kontoeinstellungen</h2>

      <mis-field-box *ngIf="hasFlowFactData"
                     label="FlowFact Einstellungen"
                     [editable]="true"
                     (edit)="editFlowFactData()">
        Server-Url: {{getFlowFactServerUrl()}}<br>
        Server-Customer-Id: {{getFlowFactServerCustomerId()}}
      </mis-field-box>

      <mis-empty-result-hint *ngIf="!hasFlowFactData"
                             icon="fas fa-cogs">In Ihrem Konto sind noch keine weiteren Einstellungen verfügbar.</mis-empty-result-hint>
    </div>
  </div>

  <h2>Kontakte</h2>
  <div class="mis-row">
    <div class="mis-column-33"
         *ngFor="let contact of account.contacts">
      <mis-field-box [label]="(contact.type | misTranslateContactType)"
                     [editable]="true"
                     (edit)="editContact(contact)">
        {{contact.salutation | misTranslateSalutation}} {{contact.firstName}} {{contact.lastName}}<br>
        {{contact.email}}

        <br *ngIf="contact.phone">
        {{contact.phone}}

        <br *ngIf="contact.mobile">
        {{contact.mobile}}
      </mis-field-box>
    </div>

    <div class="mis-column-33">
    </div>
  </div>
</div>
