<div class="mis-price-atlas-table-container resizing"
     [ngClass]="{'resizing': isTableResizingEnabled}"
     (mousemove)="drag($event)">

  <div class="mis-price-atlas-table"
       [ngClass]="{'closed': !tableOpened}"
       [joyrideStep]="guidedTourStep.PRICE_ATLAS_TABLE"
       title="Preisatlas Ergebnisliste"
       text="Die Legende zeigt Ihnen, welche Farbe auf der Karte, welche Eingruppierung darstellt.">
    <div class="table-toggle-pane"
         [ngClass]="{'opened': tableOpened}"
         (mousedown)="startDrag($event)"
         (mouseup)="stopDrag($event)">
      <a class="table-toggle-pane-anchor"
         href="#"
         [ngClass]="{'opened': tableOpened}"
         (click)="toggleTable($event)">
        <i class="fas fa-chevron-up"></i>
      </a>
    </div>

    <div class="table-container"
         [ngClass]="{'opened': tableOpened}"
         [style.height]="styleContentHeight">

      <a *ngIf="!tableOpened"
         class="preview-title"
         href="#"
         (click)="toggleTable($event)">
        Ergebnisliste öffnen {{styleContentHeight}}
      </a>

      <div *ngIf="tableOpened">
        <mis-loading *ngIf="isLoading"></mis-loading>

        <div *ngIf="!isLoading">
          <mis-immo-table *ngIf="researchItems && selectedZipCode"
                          [rows]="researchItems"
                          [detailsEnabled]="false"
                          [height]="contentHeight"
                          [isLoading]="isLoading"
                          [pageIndex]="pageIndex"
                          [pageSize]="pageHitsPerPage"
                          [pageSizeOptions]="pageSizeOptions"
                          [pageTotalHits]="pageItemCount"
                          [sortItem]="getSortItem()"
                          [totalPages]="totalPages"
                          (changeSort)="changeSort($event)"
                          (changePage)="changePagination($event)"></mis-immo-table>


          <mis-empty-result-hint *ngIf="!selectedZipCode"
                                 icon="fas fa-exclamation-triangle">
            Bitte wählen Sie ein Postleitzahlengebiet aus um die entsprechende Objekte zu laden.
          </mis-empty-result-hint>

        </div>
      </div>
    </div>
  </div>
</div>
