import {Component, Input} from '@angular/core';

export class QuickInfoItem {
  constructor(public title: string,
              public tooltip: string,
              public icon: string,
              public isEnabled: boolean = true) {
  }
}

@Component({
  selector:    'mis-quick-info-item',
  templateUrl: './quick.info.item.component.html',
  styleUrls:   ['./quick.info.item.component.scss']
})
export class QuickInfoItemComponent {
  @Input() item: QuickInfoItem;
  @Input() width: string;
}
