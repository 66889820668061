import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../local.storage.service';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {Observable} from 'rxjs';
import {FeedbackReport} from './model/feedback.report.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class FeedbackService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  send(feedback: FeedbackReport): Observable<void> {
    return this.POST(environment.misAccountEndpoint + '/feedback', feedback);
  }
}
