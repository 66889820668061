import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../../services/aaa/user.service';
import {MisTagUtils, MisUser} from '../../../../../services/aaa/model/account.model';
import {UserCreateModalComponent} from '../user-create-modal/user.create.modal.component';
import {UserEditModalComponent} from '../user-details/user-edit-modal/user.edit.modal.component';
import {UserFlowFactDataEditModalComponent} from '../user-details/user-flow-fact-data-edit-modal/user.flow.fact.data.edit.modal.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {MisEventUtils} from '../../../../../services/common/event.util';

@Component({
  selector: 'mis-user-overview',
  templateUrl: './user.overview.component.html',
  styleUrls: ['./user.overview.component.scss',
    '../../../../../../assets/scss/_table.scss']
})
export class UserOverviewComponent implements OnInit {
  isLoading = false;
  users: MisUser[];

  constructor(private userService: UserService,
              private dialog: MatDialog,
              private snackBar: SnackbarService) {
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.users = [];
    this.userService.getUsers().subscribe(users => {
      this.users = users.content;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  // ------------------------------------------------------------
  // Button actions
  createUser(): void {
    const dialogRef = this.dialog.open(UserCreateModalComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.loadUsers();
    });
  }

  editUser(event, user: MisUser): void {
    MisEventUtils.stopEvent(event);

    const dialogRef = this.dialog.open(UserEditModalComponent, {
      data: user
    });
    dialogRef.afterClosed().subscribe((changedUser) => {
      if (changedUser) {
        this.loadUsers();
      }
    });
  }

  hasUserFlowFactDataTags(user: MisUser) {
    return user.tags.filter(tag => tag.key === MisTagUtils.keys.flowFactServerUserName).length > 0;
  }

  editUserFlowFactData(event, user: MisUser): void {
    MisEventUtils.stopEvent(event);

    const dialogRef = this.dialog.open(UserFlowFactDataEditModalComponent, {
      data: user
    });
    dialogRef.afterClosed().subscribe((changedUser) => {
      if (changedUser) {
        this.loadUsers();
      }
    });
  }
}
