import {
  AngebotsTyp,
  DateFilterType,
  ImmobilienTyp,
  QueryField,
  QuerySpec
} from '../../../../services/research/model/research.filter.model';
import * as moment from 'moment';

export class FilterDateRangeEvent {
  public type: DateFilterType;
  public typeDefinitions: any;
  public fodStart: Date;
  public fodEnd: Date = null;

  private constructor() {
  }

  static ofEffectiveDay(day: Date): FilterDateRangeEvent {
    const filter = new FilterDateRangeEvent();
    filter.type = 'EFFECTIVE_DAY';
    filter.fodStart = day;
    return filter;
  }

  static ofTimeRange(startDate: Date, endDate: Date): FilterDateRangeEvent {
    const filter = new FilterDateRangeEvent();
    filter.type = 'TIME_RANGE';
    filter.fodStart = startDate;
    filter.fodEnd = endDate;

    return filter;
  }

  static ofNewerThan(hours: number): FilterDateRangeEvent {
    const filter = new FilterDateRangeEvent();
    filter.type = 'NEWER_THAN';
    filter.typeDefinitions = hours;
    filter.fodStart = moment().subtract(hours, 'hours').toDate();

    return filter;
  }

  static ofNewerSince(date: Date): FilterDateRangeEvent {
    const filter = new FilterDateRangeEvent();
    filter.type = 'NEWER_SINCE';
    filter.fodStart = date;

    return filter;
  }

  static ofOlderThan(days: number): FilterDateRangeEvent {
    const filter = new FilterDateRangeEvent();
    filter.type = 'OLDER_THAN';
    filter.typeDefinitions = days;
    filter.fodStart = moment().subtract(days, 'days').toDate();

    return filter;
  }
}

export class FilterBuilder {

  constructor() {
    this.clear();
  }

  private querySpec: QuerySpec;
  private offerType: AngebotsTyp;
  private propertyType: ImmobilienTyp;
  private dateRange: FilterDateRangeEvent;
  private cities: string[];
  private zipCodes: string[];
  private extendedFilterQueryFields: QueryField[] = null;
  private showOnlyActive = true;

  private static isToday(other: Date): Boolean {
    if (other == null) {
      return true;
    }

    const now = moment();
    return other.getFullYear() === now.year() && other.getMonth() === now.month() && other.getDate() === now.date();
  }

  public clear(): FilterBuilder {
    this.querySpec = null;
    this.offerType = null;
    this.propertyType = null;
    this.dateRange = null;
    this.cities = null;
    this.zipCodes = null;
    this.extendedFilterQueryFields = null;
    this.showOnlyActive = true;

    return this;
  }

  public withQuery(querySpec: QuerySpec): FilterBuilder {
    if (querySpec) {
      this.querySpec = new QuerySpec(querySpec);
    }

    return this;
  }

  public withOfferType(offerType: AngebotsTyp): FilterBuilder {
    if (offerType) {
      this.offerType = offerType;
    }
    return this;
  }

  public withPropertyType(propertyType: ImmobilienTyp): FilterBuilder {
    if (propertyType) {
      this.propertyType = propertyType;
    }
    return this;
  }

  public withDateRange(dateRange: FilterDateRangeEvent): FilterBuilder {
    if (dateRange) {
      this.dateRange = dateRange;
    }
    return this;
  }

  public withCities(cities: string[]): FilterBuilder {
    if (cities) {
      this.cities = cities;
    }
    return this;
  }

  public withZipCodes(zipCodes: string[]): FilterBuilder {
    if (zipCodes) {
      this.zipCodes = zipCodes;
    }
    return this;
  }

  public withExtendsFilters(extendedFilterQueryFields: QueryField[]): FilterBuilder {
    if (extendedFilterQueryFields) {
      this.extendedFilterQueryFields = this.clearDatePeriodFilter(extendedFilterQueryFields);
    }
    return this;
  }

  public withShowOnlyActive(showOnlyActive: boolean): FilterBuilder {
    this.showOnlyActive = showOnlyActive;
    return this;
  }

  public build(): QuerySpec {

    // update existing query
    if (this.querySpec) {
      this.setupDatePeriod(this.querySpec.root);
      return this.querySpec;
    }

    // create new Query
    const root = this.setupQueryBasics();
    this.setupDatePeriod(root);
    this.setupLocationFilter(root);

    if (this.extendedFilterQueryFields) {
      this.extendedFilterQueryFields.forEach(field => {
        root.combineList.push(field);
      });
    }

    return this.querySpec;
  }

  private setupQueryBasics(): QueryField {
    this.querySpec = new QuerySpec();
    if (this.offerType) {
      this.querySpec.angebotsTyp = this.offerType;
    }
    if (this.propertyType) {
      this.querySpec.immobilienTyp = this.propertyType;
    }

    // wir möchten immer nur den letzten Stand sehen, der Rest interessiert und erst mal nicht
    this.querySpec.root = QueryField.eq('latest', true);
    this.querySpec.root.combineList = [];
    this.querySpec.root.combineWith = 'and';

    return this.querySpec.root;
  }

  private setupDatePeriod(target: QueryField) {

    // remove old date filter
    target.combineList = this.clearDatePeriodFilter(target.combineList);

    if (!this.dateRange) {
      if (this.showOnlyActive) {
        const vud = moment.utc(new Date()).toDate();
        target.combineList.push(QueryField.gthOrEq('validUntil', vud));
      }
      return;
    }

    switch (this.dateRange.type) {

      case 'EFFECTIVE_DAY': {
        // Stichtag
        // fod <= END of DAY dateRange.fodStart
        // vud >= START of DAY dateRange.fodStart

        const fod = moment(this.dateRange.fodStart)
          .endOf('day')
          .utc(false)
          .toDate();
        const vud = moment(this.dateRange.fodStart)
          .startOf('day')
          .utc(false)
          .toDate();


        const fodQuery = QueryField.lthOrEq('firstOccurrenceDate', fod);
        fodQuery.tValueType = this.dateRange.type;

        target.combineList.push(fodQuery);
        target.combineList.push(QueryField.gthOrEq('validUntil', vud));

        break;
      }
      case 'TIME_RANGE': {
        // Zeitraum
        // fod <= END of DAY dateRange.fodEnd
        // vud >= START of DAY dateRange.fodStart

        const fod = moment(this.dateRange.fodEnd)
          .endOf('day')
          .utc(false)
          .toDate();
        const vud = moment(this.dateRange.fodStart)
          .startOf('day')
          .utc(false)
          .toDate();

        const fodQuery = QueryField.lthOrEq('firstOccurrenceDate', fod);
        fodQuery.tValueType = this.dateRange.type;

        target.combineList.push(fodQuery);
        target.combineList.push(QueryField.gthOrEq('validUntil', vud));

        break;
      }
      case 'NEWER_SINCE': {
        // fod >= dateRange.fodStart
        const fodQuery = QueryField.gthOrEq('firstOccurrenceDate', this.dateRange.fodStart);
        fodQuery.tValueType = this.dateRange.type;
        target.combineList.push(fodQuery);

        if (this.showOnlyActive) {
          const vud = moment.utc(new Date()).toDate();
          target.combineList.push(QueryField.gthOrEq('validUntil', vud));
        }

        break;
      }
      case 'NEWER_THAN': {
        // fod >= dateRange.fodStart
        const fodQuery = QueryField.gthOrEq('firstOccurrenceDate', this.dateRange.fodStart);
        fodQuery.tValueType = this.dateRange.type;
        fodQuery.tValueTypeArg = this.dateRange.typeDefinitions;
        target.combineList.push(fodQuery);

        if (this.showOnlyActive) {
          const vud = moment.utc(new Date()).toDate();
          target.combineList.push(QueryField.gthOrEq('validUntil', vud));
        }

        break;
      }
      case 'OLDER_THAN': {
        // fod <== X
        // START OF DAY
        const fod = moment.utc(this.dateRange.fodStart)
          .startOf('day')
          .toDate();

        const fodQuery = QueryField.lthOrEq('firstOccurrenceDate', fod);
        fodQuery.tValueType = this.dateRange.type;
        fodQuery.tValueTypeArg = this.dateRange.typeDefinitions;
        target.combineList.push(fodQuery);

        const vud = moment.utc(new Date()).toDate();
        target.combineList.push(QueryField.gthOrEq('validUntil', vud));

        break;
      }
    }
  }

  private clearDatePeriodFilter(queryFields: QueryField[]): QueryField[] {
    return queryFields.filter(filter => {
      const isValidUntil = filter.field === 'validUntil';
      const isFirstOcc = filter.field === 'firstOccurrenceDate';
      return !isValidUntil && !isFirstOcc;
    });
  }

  private setupLocationFilter(target: QueryField): void {
    // orte und postleitzahlen sind damit faktisch UND-verknüpft - das soll so sein
    if (this.cities && this.cities.length > 0) {
      const citiesField = new QueryField();
      citiesField.field = 'ort';
      citiesField.comparator = 'eq';
      citiesField.sValueList = this.cities;
      citiesField.combineListElementsWith = 'or';
      target.combineList.push(citiesField);
    }

    if (this.zipCodes && this.zipCodes.length > 0) {
      const zipCodesField = new QueryField();
      zipCodesField.field = 'postleitzahl';
      zipCodesField.comparator = 'starts';
      zipCodesField.combineListElementsWith = 'or';
      zipCodesField.sValueList = this.zipCodes;
      target.combineList.push(zipCodesField);
    }
  }
}
