import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {QuerySpec} from '../research/model/research.filter.model';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MisBenchmarkRow, MisSinglePropertyBenchmark} from './model/simple.analysis.model';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {ResearchItem} from '../research/model/research.item.model';
import {MisAuswertungRoot, MisAuswertungSourceCluster} from './model/legacy.analysis.model';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class AnalyticLegacyService extends AbstractService {

  private auswertungCluster: MisAuswertungSourceCluster;

  private listeners = [];
  private initialized: boolean;
  private initializationStarted: boolean;

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadAuswertung(filter: QuerySpec) {
    if (this.initializationStarted) {
      return null;
    }

    let loadSubscriber: Subscriber<void>;
    const loadObservable = new Observable(sub => {
      loadSubscriber = sub;
    });
    this.initializationStarted = true;
    this.initialized = false;
    this.auswertungCluster = null;

    const url = environment.misAnalyticEndpoint + '/auswertung.json';

    filter.page = null;
    filter.size = null;
    filter.sort = null;

    const params = new HttpParams().set('query', JSON.stringify(filter));

    this.GET<MisAuswertungRoot>(url, params).subscribe(data => {
      const source = data.sources.find(s => s.source === 'Alle');
      if (source != null) {
        this.auswertungCluster = source.clusters.find(c => c.name === 'Gesamt');
      }

      this.initializationStarted = false;
      this.initialized = true;
      this.listeners.forEach(listener => listener());
      this.listeners = [];
      loadSubscriber.next();

    }, (err) => {
      this.auswertungCluster = null;

      this.initializationStarted = false;
      this.initialized = true;

      this.listeners.forEach(listener => listener());
      this.listeners = [];
      loadSubscriber.next();
    });

    return loadObservable;
  }

  getSingleBenchmark(item: ResearchItem): Observable<MisSinglePropertyBenchmark> {
    return new Observable(subscription => {

      if (this.initialized) {
        subscription.next(this.buildSingleBenchmarkItem(item));
        subscription.complete();
        subscription.unsubscribe();
        return;
      }

      if (this.initializationStarted) {
        this.listeners.push(() => {
          subscription.next(this.buildSingleBenchmarkItem(item));
          subscription.complete();
          subscription.unsubscribe();
        });
        return;
      }

      subscription.error();
      subscription.complete();
    });
  }

  private buildSingleBenchmarkItem(item: ResearchItem): MisSinglePropertyBenchmark {

    if (this.auswertungCluster == null) {
      return null;
    }

    const result = new MisSinglePropertyBenchmark();
    // result.rating = this.getRating();
    // result.score = Math.random();

    result.rows = [
      this.getBenchmarkRowPrice(item),
      this.getBenchmarkRowPricePerLivingSpace(item)
    ];

    if (item.immobilienTyp === 'WOHNUNG'
      || item.immobilienTyp === 'HAUS'
      || item.immobilienTyp === 'ANLAGEOBJEKT') {
      result.rows.push(this.getBenchmarkRowRoomNumber(item));
      result.rows.push(this.getBenchmarkRowLivingArea(item));
    }

    if (item.immobilienTyp === 'GRUNDSTUECK'
      || item.immobilienTyp === 'ANLAGEOBJEKT') {
      result.rows.push(this.getBenchmarkRowPropertySize(item));
    }

    if (item.angebotsTyp == 'MIETE') {
      result.rows.push(this.getBenchmarkRowUtilities(item));
    }

    result.rows.push(this.getBenchmarkRowAge(item));

    return result;
  }

  private getBenchmarkRowRoomNumber(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'ROOM_NUMBER';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.zimmerAnzahl;
      result.min = row.zimmerAnzahl.min;
      result.max = row.zimmerAnzahl.max;
      result.mean = row.zimmerAnzahl.mean;
      result.median = row.zimmerAnzahl.median;
      result.percentile20 = row.zimmerAnzahl.percentile20;
      result.percentile80 = row.zimmerAnzahl.percentile80;
    }

    return result;
  }

  private getBenchmarkRowPrice(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'PRICE';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.preis;
      result.min = row.preis.min;
      result.max = row.preis.max;
      result.mean = row.preis.mean;
      result.median = row.preis.median;
      result.percentile20 = row.preis.percentile20;
      result.percentile80 = row.preis.percentile80;
    }

    return result;
  }

  private getBenchmarkRowLivingArea(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'LIVING_SPACE';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.wohnflaeche;
      result.min = row.wohnflaeche.min;
      result.max = row.wohnflaeche.max;
      result.mean = row.wohnflaeche.mean;
      result.median = row.wohnflaeche.median;
      result.percentile20 = row.wohnflaeche.percentile20;
      result.percentile80 = row.wohnflaeche.percentile80;
    }

    return result;
  }

  private getBenchmarkRowPropertySize(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'PROPERTY_SIZE';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.grundstueckflaeche;
      result.min = row.grundstuecksFlaeche.min;
      result.max = row.grundstuecksFlaeche.max;
      result.mean = row.grundstuecksFlaeche.mean;
      result.median = row.grundstuecksFlaeche.median;
      result.percentile20 = row.grundstuecksFlaeche.percentile20;
      result.percentile80 = row.grundstuecksFlaeche.percentile80;
    }

    return result;
  }

  private getBenchmarkRowPricePerLivingSpace(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'PRICE_PER_LIVING_SPACE';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.preisJeQm;
      result.min = row.preisJeQm.min;
      result.max = row.preisJeQm.max;
      result.mean = row.preisJeQm.mean;
      result.median = row.preisJeQm.median;
      result.percentile20 = row.preisJeQm.percentile20;
      result.percentile80 = row.preisJeQm.percentile80;
    }

    return result;
  }

  private getBenchmarkRowAge(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'AGE';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = this.alterInTagen(item);
      result.min = row.alter.min;
      result.max = row.alter.max;
      result.mean = row.alter.mean;
      result.median = row.alter.median;
      result.percentile20 = row.alter.percentile20;
      result.percentile80 = row.alter.percentile80;
    }

    return result;
  }

  private getBenchmarkRowUtilities(item: ResearchItem): MisBenchmarkRow {
    const result = new MisBenchmarkRow();
    result.type = 'UTILITIES';

    if (this.auswertungCluster != null) {
      const row = this.auswertungCluster.rows[0];
      result.actual = item.nebenkosten;
      result.min = row.nebenkosten.min;
      result.max = row.nebenkosten.max;
      result.mean = row.nebenkosten.mean;
      result.median = row.nebenkosten.median;
      result.percentile20 = row.nebenkosten.percentile20;
      result.percentile80 = row.nebenkosten.percentile80;
    }

    return result;
  }

  private alterInTagen(item: ResearchItem): number {
    if (item != null && item.firstOccurrenceDate != null) {
      const oneDay = 1000 * 60 * 60 * 24;
      const today = new Date().getTime();
      const diff = today - new Date(item.firstOccurrenceDate).getTime();
      return Math.round(Math.abs(diff) / oneDay);
    }
    return 0;
  }

}

