import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DateFilterType, QuerySpec} from '../../../../../services/research/model/research.filter.model';
import {FilterDateRangeEvent} from '../filter.builder';
import * as moment from 'moment';
import {FilterQueryReader} from '../filter.query.reader';
import {MatRadioChange} from '@angular/material/radio';
import {DatetimePickerEvent} from '../../../../basic/form/form-fields-datetime-picker/form.fields.datetime.picker.component';
import {ViewServiceInstance} from '../../../../../viewservice/view.service';
import {FormFieldsDropdownValue} from '../../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';

export const NEWER_THAN_VALUES = {'1': '1 Stunde', '6': '6 Stunden', '12': '12 Stunden', '24': '24 Stunden', '48': '48 Stunden', '168': '7 Tage', '336': '14 Tage'};
export const NEWER_THAN_DEFAULT_VALUE = '24';

export const OLDER_THAN_VALUES = {'7': '1 Woche', '14': '2 Wochen', '28': '4 Wochen', '56': '2 Monate', '112': '4 Monate', '168': '6 Monate', '365': '12 Monate'};
export const OLDER_THAN_DEFAULT_VALUE = '56';

@Component({
  selector:    'mis-filter-time-range',
  templateUrl: './filter.time.range.component.html',
  styleUrls:   ['./filter.time.range.component.scss']
})
export class FilterTimeRangeComponent implements OnChanges {
  @Input() filterQuery: QuerySpec = null;
  @Output() changedFilter = new EventEmitter<FilterDateRangeEvent>();

  viewServiceInstance = ViewServiceInstance;

  activeTimeRangeType: DateFilterType;

  effectiveDate: Date;
  effectiveMinDate: Date;
  effectiveMaxDate: Date = new Date();

  timeRangeMinDate: Date;
  timeRangeMaxDate: Date;

  newerSinceDate: Date;
  newerSinceDateTimeHours: number;
  newerSinceDateTimeMinutes: number;
  newerSinceMaxDate: Date = new Date();

  newerThanValue: string = NEWER_THAN_DEFAULT_VALUE;
  newerThanAvailableValues: { [key: string]: FormFieldsDropdownValue } = {};

  olderThanValue: string = OLDER_THAN_DEFAULT_VALUE;
  olderThanAvailableValues: { [key: string]: FormFieldsDropdownValue } = {};

  constructor() {
    Object.keys(NEWER_THAN_VALUES).forEach((newerThanValueKey) => {
      this.newerThanAvailableValues[newerThanValueKey] = new FormFieldsDropdownValue(NEWER_THAN_VALUES[newerThanValueKey]);
    });

    Object.keys(OLDER_THAN_VALUES).forEach((olderThanValueKey) => {
      this.olderThanAvailableValues[olderThanValueKey] = new FormFieldsDropdownValue(OLDER_THAN_VALUES[olderThanValueKey]);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillFields();
  }

  private fillFields(): void {
    const filterDateRangeEvent = FilterQueryReader.getFilterDateRangeEvent(this.filterQuery);
    if (!filterDateRangeEvent) {
      this.activeTimeRangeType = null;
      this.newerThanValue = NEWER_THAN_DEFAULT_VALUE;
      this.olderThanValue = '56';
      return;
    }

    this.activeTimeRangeType = filterDateRangeEvent.type;

    switch (filterDateRangeEvent.type) {
      case 'EFFECTIVE_DAY':
        this.effectiveDate = filterDateRangeEvent.fodStart;
        break;
      case 'TIME_RANGE':
        this.timeRangeMinDate = filterDateRangeEvent.fodStart;
        this.timeRangeMaxDate = filterDateRangeEvent.fodEnd;
        break;
      case 'NEWER_SINCE':
        this.newerSinceDate = filterDateRangeEvent.fodStart;
        this.newerSinceDateTimeHours = moment(this.newerSinceDate).get('hours');
        this.newerSinceDateTimeMinutes = moment(this.newerSinceDate).get('minutes');
        break;
      case 'NEWER_THAN':
        this.newerThanValue = filterDateRangeEvent.typeDefinitions;
        break;
      case 'OLDER_THAN':
        this.olderThanValue = filterDateRangeEvent.typeDefinitions;
        break;
    }
  }

  changedTimeRangeType(event: MatRadioChange) {
    if (!event.value) {
      this.activeTimeRangeType = null;
      this.changedFilter.emit(null);

    } else {
      this.activeTimeRangeType = event.source.value as DateFilterType;

      switch (this.activeTimeRangeType) {
        case 'NEWER_SINCE':
          const newSinceDate = this.newerSinceDate ? this.newerSinceDate : new Date();
          this.changedNewerSinceDate(newSinceDate);
          break;
        case 'NEWER_THAN':
          this.changedNewerThan({[this.newerThanValue]: null});
          break;
        case 'OLDER_THAN':
          this.changedOlderThanValue({[this.olderThanValue]: null});
          break;
        case 'EFFECTIVE_DAY':
          const newEffectiveDate = this.effectiveDate ? this.effectiveDate : new Date();
          this.changedEffectiveDate(newEffectiveDate);
          break;
        case 'TIME_RANGE':
          this.timeRangeMinDate = this.timeRangeMinDate ? this.timeRangeMinDate : new Date();
          this.timeRangeMaxDate = this.timeRangeMaxDate ? this.timeRangeMaxDate : new Date();
          this.changedFilter.emit(FilterDateRangeEvent.ofTimeRange(this.timeRangeMinDate, this.timeRangeMaxDate));
          break;
      }
    }
  }

  changedEffectiveDate(date: Date) {
    this.activeTimeRangeType = 'EFFECTIVE_DAY';
    this.effectiveDate = date;

    this.changedFilter.emit(FilterDateRangeEvent.ofEffectiveDay(date));
  }

  changedNewerSinceDate(date: Date) {
    this.activeTimeRangeType = 'NEWER_SINCE';
    this.newerSinceDate = date;

    if (!this.newerSinceDateTimeHours) {
      this.newerSinceDateTimeHours = moment(this.newerSinceDate).get('hours');
      this.newerSinceDateTimeMinutes = moment(this.newerSinceDate).get('minutes');
    }

    this.newerSinceDate.setHours(this.newerSinceDateTimeHours);
    this.newerSinceDate.setMinutes(this.newerSinceDateTimeMinutes);
    this.newerSinceDate.setSeconds(0);

    this.changedFilter.emit(FilterDateRangeEvent.ofNewerSince(date));
  }

  changedNewerSinceDateTime(event: DatetimePickerEvent): void {
    this.activeTimeRangeType = 'NEWER_SINCE';
    this.newerSinceDateTimeHours = event.hours;
    this.newerSinceDateTimeMinutes = event.minutes;

    if (!this.newerSinceDate) {
      this.changedNewerSinceDate(new Date());
    } else {
      this.changedNewerSinceDate(this.newerSinceDate);
    }
  }

  changedTimeRangeFromDate(date: Date) {
    this.activeTimeRangeType = 'TIME_RANGE';

    this.timeRangeMinDate = date;
    this.timeRangeMaxDate = this.timeRangeMaxDate ? this.timeRangeMaxDate : new Date();

    if (moment(this.timeRangeMinDate).isAfter(moment(this.timeRangeMaxDate))) {
      this.timeRangeMaxDate = this.timeRangeMinDate;
    }

    this.changedFilter.emit(FilterDateRangeEvent.ofTimeRange(this.timeRangeMinDate, this.timeRangeMaxDate));
  }

  changedTimeRangeToDate(date: Date) {
    this.activeTimeRangeType = 'TIME_RANGE';

    this.timeRangeMinDate = this.timeRangeMinDate ? this.timeRangeMinDate : date;
    this.timeRangeMaxDate = date;

    if (moment(this.timeRangeMinDate).isAfter(moment(this.timeRangeMaxDate))) {
      this.timeRangeMinDate = this.timeRangeMaxDate;
    }

    this.changedFilter.emit(FilterDateRangeEvent.ofTimeRange(this.timeRangeMinDate, this.timeRangeMaxDate));
  }

  changedNewerThan(values: { [key: string]: FormFieldsDropdownValue }) {
    this.activeTimeRangeType = 'NEWER_THAN';
    this.newerThanValue = Object.keys(values)[0];

    this.changedFilter.emit(FilterDateRangeEvent.ofNewerThan(parseInt(this.newerThanValue)));
  }

  changedOlderThanValue(values: { [key: string]: FormFieldsDropdownValue }) {
    this.activeTimeRangeType = 'OLDER_THAN';
    this.olderThanValue = Object.keys(values)[0];

    this.changedFilter.emit(FilterDateRangeEvent.ofOlderThan(parseInt(this.olderThanValue)));
  }

  selectAll(): void {
    this.activeTimeRangeType = null;
    this.changedFilter.emit(null);
  }
}
