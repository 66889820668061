import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService, MisHeaders} from '../abstract.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MisUserWithAccount} from './model/account.model';
import {AuthenticationProvider, AuthenticationTokenHolder} from './authentication.provider';
import {LocalStorageService} from "../local.storage.service";

export class AuthenticationResult {
  user: MisUserWithAccount;
  challengeName: string;
  authenticationState: 'SUCCESSFUL' | 'USER_NOT_CONFIRMED';
}

@Injectable({providedIn: 'root'})
export class AuthenticationService extends AbstractService {
  private tokenUpdateInterval: any;

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  authenticate(email: string, password: string): Observable<AuthenticationResult> {
    return new Observable<AuthenticationResult>((subscription) => {
      this.httpClient.post(environment.misAccountEndpoint + '/authentication', {
        email: email,
        password: password
      }, {
        observe: 'response',
      }).subscribe((response) => {
        const authenticationResult: AuthenticationResult = <AuthenticationResult>response.body;
        this.authenticationProvider.successLoginDone(authenticationResult.user, this.determineSecurityHeaders(response.headers));

        subscription.next(authenticationResult);
        this.initTokenRefreshInterval();
        subscription.complete();

      }, (error) => {
        subscription.error(error);
        subscription.complete();
      });
    });
  }

  refreshAuthenticationToken(): Observable<MisUserWithAccount> {
    return new Observable<MisUserWithAccount>((subscription) => {

      if (!this.authenticationProvider.isAuthenticated()) {
        subscription.error();
        subscription.complete();
        return;
      }

      const headers = new HttpHeaders()
        .set(MisHeaders.AUTHENTIZATION, this.authenticationProvider.getJwtToken())
        .set(MisHeaders.MIS_REFRESH_TOKEN, this.authenticationProvider.getRefreshToken());

      this.httpClient.put(environment.misAccountEndpoint + '/authentication', null, {
        headers: headers,
        observe: 'response'
      }).subscribe((response) => {
        const user: MisUserWithAccount = <MisUserWithAccount>response.body;

        this.authenticationProvider.successLoginDone(user, this.determineSecurityHeaders(response.headers));

        subscription.next(user);
        this.initTokenRefreshInterval();
        subscription.complete();

      }, (error) => {

        this.authenticationProvider.successLogoutDone();
        subscription.error(error);
        subscription.complete();
      });
    });
  }

  forgotPassword(email: string): Observable<void> {
    return this.POST<void>(environment.misAccountEndpoint + '/password/reset', email);
  }

  confirmNewPassword(email: string, verificationCode: string, password: string): Observable<void> {
    return this.POST<void>(environment.misAccountEndpoint + '/password/reset/verify', {
      email,
      password,
      verificationCode
    });
  }

  logout(): void {
    this.authenticationProvider.successLogoutDone();

    try {

      if (this.tokenUpdateInterval != null) {
        clearInterval(this.tokenUpdateInterval);
        this.tokenUpdateInterval = null;
      }

      const headers = new HttpHeaders()
        .set(MisHeaders.AUTHENTIZATION, this.authenticationProvider.getJwtToken())
        .set(MisHeaders.MIS_REFRESH_TOKEN, this.authenticationProvider.getRefreshToken());

      this.httpClient.delete(environment.misAccountEndpoint + '/authentication', {
        headers: headers
      }).subscribe(() => {
      }, () => {
      });

    } catch (e) {
    }
  }

  private determineSecurityHeaders(headers: HttpHeaders): AuthenticationTokenHolder {
    const jwt = headers.get(MisHeaders.AUTHENTIZATION);
    const refreshToken = headers.get(MisHeaders.MIS_REFRESH_TOKEN);
    const accessToken = headers.get(MisHeaders.MIS_ACCESS_TOKEN);
    const expiresInSeconds = +headers.get(MisHeaders.MIS_TOKEN_EXPIRES);

    return new AuthenticationTokenHolder(jwt, refreshToken, accessToken, expiresInSeconds);
  }

  private initTokenRefreshInterval() {
    if (this.tokenUpdateInterval == null) {
      this.tokenUpdateInterval = setInterval(() => {
        this.refreshAuthenticationToken().subscribe(() => {
        });
      }, 5 * 60 * 1000); // 5 Minutes
    }
  }

}

