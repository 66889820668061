<div class="mis-modal-medium">
  <h2 mat-dialog-title>
    Neue Marktberichtkonfiguration erstellen
  </h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form [formGroup]="formGroup"
        (submit)="saveAndClose()"
        *ngIf="!isLoading">

    <mat-dialog-content>
      <p>
        Konfigurieren Sie hier Ihre individuellen Inhalte Ihrer Marktberichtes. Erstellen Sie Marktberichte für die
        Städte die für Sie wichtig sind. Wählen Sie zudem Verleichsstädte aus, welche Sie als Referenzstädte im
        Marktbericht sehen möchten.
      </p>

      <!-- Stadt -->
      <mat-form-field>
        <mat-select [formControl]="cityCtrl"
                    placeholder="Stadt für welche der Bericht erstellt werden soll"
                    [required]="true">
          <mat-option *ngFor="let city of getAvailableCities(true)"
                      [value]="city">
            {{city}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Text -->
      <mat-form-field>
        <textarea matInput
                  [formControl]="cityDescriptionCtrl"
                  name="cityDescription"
                  placeholder="Einführungstext zur Stadt"
                  rows="6"
                  required="required">
        </textarea>
      </mat-form-field>
      <mis-hint type="INFO-BORDERED">
        Der Einführungstext wird im Marktbericht auf der Übersichtsseite der Stadt angezeigt.
      </mis-hint>
      <br>

      <!-- Vergleichsstädte -->
      <mat-form-field [ngClass]="{'mat-form-field-invalid': isComparisonCitiesFieldInvalid}">
        <mat-label>Vergleichsstädte (min. 1, max. 2) *</mat-label>
        <mat-chip-list #comparisonCityList>
          <mat-chip *ngFor="let city of selectedComparisonCities"
                    (removed)="removeComparisonCity(city)">
            {{city}}
            <span matChipRemove>
              <mat-icon>cancel</mat-icon>
            </span>
          </mat-chip>
          <input placeholder="Stadt auswählen..."
                 #comparisonCityInput
                 [formControl]="comparisonCityCtrl"
                 [hidden]="selectedComparisonCities.length >= 2"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="comparisonCityList"
                 (matChipInputTokenEnd)="addComparisonCity($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete"
                          (optionSelected)="selectedComparisonCity($event)">
          <mat-option *ngFor="let city of filteredComparisonCitiesObservable | async"
                      [value]="city">
            {{city}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Anlegen
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
