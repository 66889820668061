import {Component, Input} from '@angular/core';

export type HigherTariffRequiredBackground = 'MAP' | 'PRICE_CHART';
export type HigherTariffRequiredSize = 'SMALL' | 'MEDIUM';

@Component({
  selector:    'mis-higher-tariff-required-hint',
  templateUrl: './higher.tariff.required.hint.component.html',
  styleUrls: ['./higher.tariff.required.hint.component.scss']
})
export class HigherTariffRequiredHintComponent {
  @Input() size: HigherTariffRequiredSize = 'MEDIUM';
  @Input() type: HigherTariffRequiredBackground;

  get backgroundClass(): string {
    if (this.type) {
      return this.type.toLowerCase();
    }
    return '';
  }
}
