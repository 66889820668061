import {Injectable} from '@angular/core';
import {JoyrideService} from 'ngx-joyride';
import {UserService} from '../../../services/aaa/user.service';
import {MisTag, MisTagUtils, MisUser} from '../../../services/aaa/model/account.model';
import {AuthenticationProvider} from '../../../services/aaa/authentication.provider';
import {ViewServiceInstance} from '../../../viewservice/view.service';

export enum GuidedTourType {
  INITIAL_TOUR = 'INITIAL_TOUR',
  IMMO_TABLE_TOUR = 'IMMO_TABLE_TOUR',
  IMMO_SUB_MENU = 'IMMO_SUB_MENU',
  PRICE_ATLAS_TOUR = 'PRICE_ATLAS_TOUR'
}

export enum GuidedTourStep {
  HEADER_MAIN_NAVIGATION = 'HEADER_MAIN_NAVIGATION',
  HEADER_SHOW_HELP = 'HEADER_SHOW_HELP',
  HEADER_SHOW_DETAILED = 'HEADER_SHOW_DETAILED',
  HEADER_PROFILE = 'HEADER_PROFILE',
  IMMO_SHOW_SUB_MENU = 'IMMO_SHOW_SUB_MENU',
  IMMO_FILTER_PANE_MAIN = 'IMMO_FILTER_PANE_MAIN',
  IMMO_FILTER_EXTENDED_FILTERS = 'IMMO_FILTER_EXTENDED_FILTERS',
  IMMO_FILTER_TIME_RANGE_FILTER = 'IMMO_FILTER_TIME_RANGE_FILTER',
  IMMO_FILTER_RESET = 'IMMO_FILTER_RESET',
  IMMO_TABLE_ROW = 'IMMO_TABLE_ROW',

  IMMO_TABLE_PAGINATION = 'IMMO_TABLE_PAGINATION',
  IMMO_TABLE_SHOW_DETAILS = 'IMMO_TABLE_SHOW_DETAILS',
  IMMO_TABLE_WATCH_ITEM = 'IMMO_TABLE_WATCH_ITEM',

  IMMO_SUB_MENU_EXPORT = 'IMMO_SUB_MENU_EXPORT',
  IMMO_SUB_MENU_MY_SEARCH = 'IMMO_SUB_MENU_MY_SEARCH',
  IMMO_SUB_MENU_NOTIFICATIONS = 'IMMO_SUB_MENU_NOTIFICATIONS',
  IMMO_SUB_MENU_SHARE = 'IMMO_SUB_MENU_SHARE',

  PRICE_ATLAS_FILTER = 'PRICE_ATLAS_FILTER',
  PRICE_ATLAS_LEGEND = 'PRICE_ATLAS_LEGEND',
  PRICE_ATLAS_TOOLBAR = 'PRICE_ATLAS_TOOLBAR',
  PRICE_ATLAS_TABLE = 'PRICE_ATLAS_TABLE'
}

export class GuidedTourConfiguration {
  constructor(public tourType: GuidedTourType, public userTagKey: string, public steps: GuidedTourStep[]) {
  }
}

export const GUIDED_TOUR_CONFIGURATIONS: GuidedTourConfiguration[] = [
  new GuidedTourConfiguration(GuidedTourType.INITIAL_TOUR, MisTagUtils.keys.finishedGuidedTourInitial, [
    GuidedTourStep.HEADER_MAIN_NAVIGATION,
    // GuidedTourStep.HEADER_SHOW_DETAILED,
    // GuidedTourStep.HEADER_SHOW_HELP,
    // GuidedTourStep.HEADER_PROFILE,
    GuidedTourStep.IMMO_SHOW_SUB_MENU,
    GuidedTourStep.IMMO_FILTER_PANE_MAIN,
    GuidedTourStep.IMMO_FILTER_RESET,
    GuidedTourStep.IMMO_FILTER_EXTENDED_FILTERS,
    GuidedTourStep.IMMO_FILTER_TIME_RANGE_FILTER,
    GuidedTourStep.IMMO_TABLE_ROW,
  ]),
  new GuidedTourConfiguration(GuidedTourType.IMMO_TABLE_TOUR, MisTagUtils.keys.finishedGuidedTourTable, [
    GuidedTourStep.IMMO_TABLE_PAGINATION,
    GuidedTourStep.IMMO_TABLE_SHOW_DETAILS,
    GuidedTourStep.IMMO_TABLE_WATCH_ITEM,
  ]),
  new GuidedTourConfiguration(GuidedTourType.IMMO_SUB_MENU, MisTagUtils.keys.finishedGuidedTourImmSubMenu,  [
    GuidedTourStep.IMMO_SUB_MENU_EXPORT,
    GuidedTourStep.IMMO_SUB_MENU_MY_SEARCH,
    GuidedTourStep.IMMO_SUB_MENU_NOTIFICATIONS,
    GuidedTourStep.IMMO_SUB_MENU_SHARE,
  ]),
  new GuidedTourConfiguration(GuidedTourType.PRICE_ATLAS_TOUR, MisTagUtils.keys.finishedGuidedTourPriceAtlas,  [
    GuidedTourStep.PRICE_ATLAS_FILTER,
    GuidedTourStep.PRICE_ATLAS_TOOLBAR,
    GuidedTourStep.PRICE_ATLAS_LEGEND,
    GuidedTourStep.PRICE_ATLAS_TABLE
  ])
];

@Injectable({providedIn: 'root'})
export class GuidedTourService {
  private isTourStarted;

  constructor(private authenticationProvider: AuthenticationProvider,
              private userService: UserService,
              private readonly joyrideService: JoyrideService) {
  }

  startTour(tourType: GuidedTourType): void {
    const user = this.authenticationProvider.getUser();

    if (this.isTourStarted || !user || (ViewServiceInstance.isMobile() && !ViewServiceInstance.isTablet())) {
      return;
    }

    const tourConfiguration = this.getTourConfiguration(tourType);
    if (!tourConfiguration) {
      return;
    }

    let guidedTourTag = MisTagUtils.getTagByKey(user.tags, tourConfiguration.userTagKey);
    if (guidedTourTag && (guidedTourTag.value || guidedTourTag.value === 'true')) {
      return;
    }

    this.isTourStarted = true;

    this.joyrideService.startTour(
      {
        steps:       tourConfiguration.steps,
        customTexts: {
          next: 'Weiter',
          prev: 'Zurück',
          done: 'Los geht\'s'
        },
        themeColor:  '#008ecc'
      }
    ).subscribe((step) => {
      }, (error) => {
      }, () => {
        this.createOrUpdateTag(user, tourConfiguration.userTagKey);
        this.isTourStarted = false;
      }
    );
  }

  private createOrUpdateTag(user: MisUser, tagKey: string): void {
    let guidedTourTag = MisTagUtils.getTagByKey(user.tags, tagKey);

    if (guidedTourTag) {
      guidedTourTag.value = true;
      this.userService.updateUserTag(user.id, guidedTourTag).subscribe(() => {
      }, () => {
      });

    } else {
      guidedTourTag = new MisTag();
      guidedTourTag.key = tagKey;
      guidedTourTag.value = true;
      guidedTourTag.type = 'BOOLEAN';
      user.tags.push(guidedTourTag);

      this.userService.addUserTag(user.id, guidedTourTag).subscribe(() => {
      }, () => {
      });
    }
  }

  private getTourConfiguration(tourType: GuidedTourType): GuidedTourConfiguration {
    const configs = GUIDED_TOUR_CONFIGURATIONS.filter((conf) => conf.tourType === tourType);

    if (configs && configs.length > 0) {
      return configs[0];
    }
    return null;
  }

  restart(url: string): void {

    const user = this.authenticationProvider.getUser();
    if (!user) {
      return;
    }


    if (url == '/research') {
      this.reset(user, GuidedTourType.INITIAL_TOUR);
      this.reset(user, GuidedTourType.IMMO_TABLE_TOUR);

      this.startTour(GuidedTourType.INITIAL_TOUR);
    }

    if (url == '/price-atlas') {
      this.reset(user, GuidedTourType.PRICE_ATLAS_TOUR);
      this.startTour(GuidedTourType.PRICE_ATLAS_TOUR);
    }
  }

  private reset(user: MisUser, type: GuidedTourType): void {
    const tag = MisTagUtils.getTagByKey(user.tags, this.getTourConfiguration(type).userTagKey);
    if (tag) {
      this.userService.deleteUserTag(user.id, tag).subscribe(() => {
      }, () => {
      });
      tag.value = false;
    }
  }
}
