<h1>Plain Backend View</h1>

<div *ngIf="isLoading">
  <mis-loading></mis-loading>
</div>

<div *ngIf="!isLoading"
     class="mis-row mis-column-boxed">
  <div class="mis-column-75">

    <form [formGroup]="formGroup"
          (submit)="send()">

      <div class="mis-row">
        <div class="mis-column-25">
          <mat-form-field>
            <select matNativeControl>
              <option value="GET" selected>GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
          </mat-form-field>
        </div>
        <div class="mis-column-75">

          <mat-form-field>
            <input matInput
                   formControlName="url"
                   placeholder="Vollständige Backend Url">
          </mat-form-field>
        </div>
      </div>

      <mat-form-field>
          <textarea matInput
                    formControlName="payload"
                    placeholder="Payload"
                    rows="30"></textarea>
      </mat-form-field>

      <button type="submit">Senden</button>

      <h2>Ergebnis</h2>
      <pre>{{response | json}}</pre>
    </form>

  </div>
  <div class="mis-column-25">
  </div>
</div>
