import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';
import {MisEventUtils} from '../../../../../../services/common/event.util';

export class ImageColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnImageComponent;
  }
}

@Component({
  selector:        'mis-immo-table-column-image',
  templateUrl:     './immo.table.column.image.component.html',
  styleUrls:       ['./immo.table.column.image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnImageComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: AbstractImmoTableColumnData;

  columnClick = new EventEmitter<void>();
  imageUrl: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.imageUrl = this.getValue(this.researchItem, this.columnName);
  }

  showSkeleton() {
    this.imageUrl = null;
  }

  triggerClick(event: Event): void {
    MisEventUtils.stopEvent(event);

    this.columnClick.emit();
  }

}
