import {Component} from '@angular/core';
import {ResearchService} from '../../../services/research/research.service';

@Component({
  selector: 'mis-immo-management',
  templateUrl: './immo.management.component.html'
})
export class ImmoManagementComponent {
  constructor(public tableService: ResearchService) {
  }
}
