import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '../../../services/abstract.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationProvider} from '../../../services/aaa/authentication.provider';
import {LocalStorageService} from "../../../services/local.storage.service";

@Component({
  selector: 'mis-pain-backend',
  templateUrl: './plain.backend.component.html'
})
export class PlainBackendComponent extends AbstractService {
  formGroup: FormGroup;
  response: any;
  isLoading: boolean;

  constructor(httpClient: HttpClient,
              formBuilder: FormBuilder,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);

    this.formGroup = formBuilder.group({
      'url': [environment.misAccountEndpoint],
      'method': ['GET'],
      'payload': [''],
    });
  }

  send() {
    this.response = null;
    let payload = null;

    try {
      if (this.formGroup.get('payload').value !== '') {
        payload = JSON.parse(this.formGroup.get('payload').value);
      }
    } catch (e) {
      this.response = 'JSON konnte nicht geparst werden!';
      return;
    }
    this.isLoading = true;

    switch (this.formGroup.get('method').value) {
      case 'GET':
        this.GET<string>(this.formGroup.get('url').value).subscribe((response) => {
          this.response = response;
          this.isLoading = false;
        }, (err) => {
          this.response = err;
          this.isLoading = false;
        });
        break;

      case 'POST':
        this.POST<string>(this.formGroup.get('url').value, payload).subscribe((response) => {
          this.response = response;
          this.isLoading = false;
        }, (err) => {
          this.response = err;
          this.isLoading = false;
        });
        break;

      case 'PUT':
        this.PUT<string>(this.formGroup.get('url').value, payload).subscribe((response) => {
          this.response = response;
          this.isLoading = false;
        }, (err) => {
          this.response = err.message;
          this.isLoading = false;
        });
        break;
    }
  }
}
