import {Component, Input, OnInit} from '@angular/core';
import {ResearchItem} from '../../../../../../services/research/model/research.item.model';
import {MisBenchmakType, MisBenchmarkRow, MisSinglePropertyBenchmark} from '../../../../../../services/analytic/model/simple.analysis.model';
import * as moment from 'moment';
import {AnalyticLegacyService} from '../../../../../../services/analytic/analytic.legacy.service';
import {QuerySpec} from '../../../../../../services/research/model/research.filter.model';

@Component({
  selector:    'mis-immo-analysis-by-filter',
  templateUrl: './immo.analysis.by.filter.component.html',
  styleUrls:   ['./immo.analysis.by.filter.component.scss']
})
export class ImmoAnalysisByFilterComponent implements OnInit {
  @Input() filterQuery: QuerySpec;
  @Input() researchItem: ResearchItem;

  isLoading = true;
  benchmark: MisSinglePropertyBenchmark;
  preparedBenchmarks: Map<MisBenchmakType, {
    benchmark: MisBenchmarkRow,
    fractionDigits: number,
    title: string,
    unit: string
  }> = new Map<MisBenchmakType, { benchmark: MisBenchmarkRow; fractionDigits: number; title: string; unit: string }>();

  currentDate = moment();
  analysisItemCss: string;

  constructor(public analyticService: AnalyticLegacyService) {
  }

  ngOnInit(): void {
    this.buildAnalysisItemCss();
    this.loadAnalysis();
  }

  loadAnalysis(): void {
    setTimeout(() => {
      this.analyticService.getSingleBenchmark(this.researchItem).subscribe((benchmark) => {
        this.benchmark = benchmark;
        this.preparedBenchmarks.clear();

        if (benchmark != null && benchmark.rows != null) {
          this.addBenchmarkRow('PRICE_PER_LIVING_SPACE', 'Preis/m²', '€/m²');
          this.addBenchmarkRow('PRICE', 'Preis', '€');
          this.addBenchmarkRow('LIVING_SPACE', 'Wohnfläche', 'm²');
          this.addBenchmarkRow('UTILITIES', 'Nebenkosten', '€');
          this.addBenchmarkRow('ROOM_NUMBER', ' Zimmer', '');
          this.addBenchmarkRow('PROPERTY_SIZE', 'Grundstücksfläche', 'm²');
          this.addBenchmarkRow('AGE', 'Inseratsalter', 'Tage(e)');
          this.addBenchmarkRow('PRICE_PER_PROPERTY_SIZE', 'Preis/m² (Grundstücksfläche)', '€/m²');
        }

        this.isLoading = false;
      }, (err) => {

        this.isLoading = false;
      });
    }, 100);
  }

  private addBenchmarkRow(type: MisBenchmakType, title: string, unit: string): void {
    const benchmark = this.benchmark.rows.find(b => b.type === type);

    if (benchmark) {
      let fractionDigits = this.getFractionsDigits(type);

      this.preparedBenchmarks.set(type, {
        benchmark,
        fractionDigits,
        title,
        unit
      });
    }
  }

  getFractionsDigits(type: MisBenchmakType): number {
    if (type === 'PRICE' ||
      type === 'PROPERTY_SIZE' ||
      type === 'AGE') {
      return 0;
    }

    if (type === 'LIVING_SPACE' ||
      type === 'ROOM_NUMBER') {
      return 1;
    }

    if (type === 'PRICE_PER_LIVING_SPACE') {
      return this.filterQuery.angebotsTyp === 'MIETE' ? 2 : 0;
    }
    return 2;
  }

  // ------------------------------------------------------------

  buildAnalysisItemCss(): void {
    if (this.currentDate.isAfter(this.researchItem.validUntil)) {
      this.analysisItemCss = 'mis-immo-analysis-is-outdated';
    }
  }
}
