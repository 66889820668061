<div class="mis-immo-table"
     [ngClass]="{'mis-immo-table-mobile': (viewServiceInstance.isMobile() && !viewServiceInstance.isTablet())}">

  <div class="mis-immo-table-container"
       [style.height]="contentHeight"
       (scroll)="scrollTable($event)">

    <table (scroll)="scrollTable($event)">
      <thead>
      <tr cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="changedTableHeaderItems($event)">
        <th [style.min-width]="'40px'"
            [style.max-width]="'40px'"
            [style.width]="'40px'"></th>
        <th [style.min-width]="'40px'"
            [style.max-width]="'40px'"
            [style.width]="'40px'"></th>
        <th *ngFor="let colConfig of columnConfiguration"
            class="mis-immo-table-col-size-{{colConfig.colSize}}"
            cdkDrag
            [cdkDragDisabled]="isDragnDropDisabled">
          <div>{{colConfig.title}}</div>
          <div>
            <i class="fas fa-arrow-up"
               *ngIf="colConfig.sortable && !(colConfig.restrictions && colConfig.restrictions.sort)"
               [ngClass]="{'mis-sort-active': sortItem && sortItem.colKey == colConfig.colKey && sortItem.direction == 'ASC'}"
               (click)="changedSort($event, 'ASC', colConfig.colKey)"></i>
            <i class="fas fa-arrow-down"
               *ngIf="colConfig.sortable && !(colConfig.restrictions && colConfig.restrictions.sort)"
               [ngClass]="{'mis-sort-active': sortItem && sortItem.colKey == colConfig.colKey && sortItem.direction == 'DESC'}"
               (click)="changedSort($event, 'DESC', colConfig.colKey)"></i>
          </div>
        </th>
      </tr>
      </thead>
      <tbody *ngIf="!isLoading">
      <ng-container *ngFor="let row of rows; let rowIndex = index">
        <tr class="mis-immo-table-row"
            [ngClass]="{'mis-immo-table-row-show-details': row.tableShowDetails, 'mis-immo-table-row-inactive': isResearchItemInactive(row)}"
            [joyrideStep]="guidedTourStep.IMMO_TABLE_ROW + (rowIndex === 0 ? '' : '_HIDE')"
            title="Ergebnistabelle"
            text="In dieser Tabelle sehen Sie Ihr Suchergebnis. Klicken Sie auf einen Eintrag um weitere Details und Analysen zum jeweiligen Objekt zu öffnen.">
          <td [style.min-width]="'40px'"
              [style.max-width]="'40px'"
              [style.width]="'40px'"
              [joyrideStep]="guidedTourStep.IMMO_TABLE_SHOW_DETAILS + (rowIndex === 0 ? '' : '_HIDE')"
              title="Detailinformationen anzeigen"
              text="Klicken Sie auf den Pfeil auf der linken Tabellenzeile oder auf die Tabellenzeile um weitere detaillierte Informationen zum jeweilige Objekt ansehen zu können."
              (click)="toggleDetails($event , row)">
            <i *ngIf="detailsEnabled"
               class="mis-immo-table-row-details-btn fas fa-chevron-down"
               title="Details anzeigen"></i>
          </td>
          <td [style.min-width]="'40px'"
              [style.max-width]="'40px'"
              [style.width]="'40px'"
              [joyrideStep]="guidedTourStep.IMMO_TABLE_WATCH_ITEM + (rowIndex === 0 ? '' : '_HIDE')"
              title="Objekt merken"
              text="Speichern Sie sich einzelnen Objekte auf Ihrer persönichen Merkliste. Zur Merkliste gelangen Sie über das Hauptmenü links.">
            <mis-watch-icon [researchItemId]="row.id"></mis-watch-icon>
          </td>
          <td *ngFor="let colConfig of columnConfiguration"
              class="mis-immo-table-col-size-{{colConfig.colSize}}"
              (click)="toggleDetails($event , row)">
            <mis-immo-table-column [columnName]="colConfig.columnName"
                                   [renderingConfig]="colConfig.renderingConfig"
                                   [researchItem]="row"
                                   (columnClick)="clickedColumn(colConfig, row)"
                                   (filter)="filter.emit($event)"></mis-immo-table-column>
          </td>
        </tr>
        <tr class="mis-immo-table-row-details"
            [hidden]="!row.tableShowDetails">
          <td [colSpan]="columnConfiguration.length + 2">
            <mis-immo-table-details-pane [filterQuery]="filterQuery"
                                         [researchItem]="row"
                                         [marginLeft]="scrollLeftPosition"
                                         [visible]="row.tableShowDetails"></mis-immo-table-details-pane>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="!isLoading && (!rows || rows.length == 0)">
        <td [colSpan]="columnConfiguration.length + 2"
            class="mis-immo-table-row-empty">
          <mis-empty-result-hint icon="fas fa-search">Es wurden keine Einträge gefunden.</mis-empty-result-hint>

          <div *ngIf="viewServiceInstance.isDesktop() || viewServiceInstance.isTablet()"
               [ngStyle]="{textAlign: 'center'}">
            <iframe width="560"
                    height="315"
                    src="https://www.youtube.com/embed/PMw-I3IVrdc"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </td>
      </tr>
      <tr class="mis-immo-table-empty-buffer">
        <td [colSpan]="columnConfiguration.length + 2"></td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="isLoading"
         class="mis-immo-table-loading">
      <mis-loading></mis-loading>
    </div>
  </div>

  <!-- secondary navigation / pagination -->
  <div class="mis-immo-table-navigation"
       [ngClass]="{'is-mobile': viewServiceInstance.isMobile()}"
       [joyrideStep]="guidedTourStep.IMMO_TABLE_PAGINATION"
       title="Tabellenmenü"
       text="Hier finden Sie die Anzahl der Treffer Ihres Suchergebnisses. Weiterhin haben Sie die Möglichkeit die Anzahl der angezeigten Treffer je Seite einzustellen und können zur nächsten Suchergebnisseite blättern.">
    <div class="mis-immo-table-navigation-item">
      <div *ngIf="viewServiceInstance.isDesktop()"
           class="mis-immo-table-navigation-item-label">
        Suchergebnis
      </div>
      <div class="mis-immo-table-navigation-item-content">
        <span>{{pageTotalHits | misNumberFormat:0}} Treffer</span>
      </div>
    </div>
    <div *ngIf="viewServiceInstance.isDesktop() || viewServiceInstance.isTablet()"
         class="mis-immo-table-navigation-item">
      <div *ngIf="viewServiceInstance.isDesktop()"
           class="mis-immo-table-navigation-item-label">
        Anzeige pro Seite
      </div>
      <div class="mis-immo-table-navigation-item-content">
        <mis-form-field-dropdown label="Einträge pro Seite"
                                 [values]="availablePagingItems"
                                 [displayMode]="'simple'"
                                 dropDownPosition="top"
                                 [selectedValues]="['' + pageSize]"
                                 (changed)="changedHitsPerPage($event)"></mis-form-field-dropdown>
      </div>
    </div>
    <div class="mis-immo-table-navigation-item">
      <div *ngIf="viewServiceInstance.isDesktop()"
           class="mis-immo-table-navigation-item-label">
        Seitennavigation
      </div>
      <div class="mis-immo-table-navigation-item-content mis-immo-table-navigation-item-content-pagination">
        <a href="#"
           class="mis-boxed-link-dark"
           (click)="changedPagination(pageIndex - 1, pageSize, $event)">
          <i class="fas fa-chevron-left"></i>
        </a>
        <mis-form-field-dropdown label="Seite"
                                 [values]="this.availablePages"
                                 [displayMode]="'simple'"
                                 dropDownPosition="top"
                                 [selectedValues]="['' + pageIndex]"
                                 (changed)="changedPage($event)"></mis-form-field-dropdown>
        <a href="#"
           class="mis-boxed-link-dark"
           (click)="changedPagination(pageIndex + 1, pageSize, $event)">
          <i class="fas fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>

</div>
