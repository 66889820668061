import {Component, Inject} from '@angular/core';
import {MisUser} from '../../../../../../services/aaa/model/account.model';
import {FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../../../../services/aaa/user.service';
import {MisBaseUtils} from '../../../../../../services/common/base.util';
import {AuthenticationProvider} from '../../../../../../services/aaa/authentication.provider';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../../basic/snackbar/snackbar.service';
import {AbstractFormComponent} from '../../../../../basic/form/abstract.form.component';

@Component({
  selector: 'mis-user-edit-modal',
  templateUrl: './user.edit.modal.component.html'
})
export class UserEditModalComponent extends AbstractFormComponent {
  user: MisUser;

  constructor(public dialogRef: MatDialogRef<UserEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: MisUser,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private authenticationProvider: AuthenticationProvider) {
    super();

    this.user = modalData;
    const currentUser = this.authenticationProvider.getUser();

    this.formGroup = formBuilder.group({
      'salutation': [this.user.contact.salutation],
      'firstName': [this.user.contact.firstName, Validators.required],
      'lastName': [this.user.contact.lastName, Validators.required],
      'email': [{value: this.user.contact.email, disabled: true}],
      'role': [{
        value: this.user.role,
        disabled: (currentUser.role !== 'ACCOUNT_ADMIN' || this.user.id === currentUser.id)
      }, Validators.required],
      'phone': [this.user.contact.phone],
      'mobile': [this.user.contact.mobile]
    });
  }

  customSaveAction(): void {
    this.prepareUser();

    this.userService.updateUser(this.user).subscribe((updatedUser) => {
      this.snackBar.success('Die Nutzerdaten wurde erfolgreich aktualisiert.');

      if (this.authenticationProvider.getUser().id === this.user.id) {
        this.authenticationProvider.refreshUserData(updatedUser);
      }

      this.dialogRef.close(true);
    }, (err) => {
      this.isLoading = false;
      this.snackBar.error('Die Aktualisierung der Nutzerdaten ist fehlgeschlagen.');
    });
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  private prepareUser(): void {
    this.user.contact.salutation = MisBaseUtils.getValueOrDefault(this.formGroup.get('salutation').value, '');
    this.user.contact.firstName = this.formGroup.get('firstName').value;
    this.user.contact.lastName = this.formGroup.get('lastName').value;
    this.user.role = this.formGroup.get('role').value;
    this.user.contact.mobile = MisBaseUtils.getValueOrDefault(this.formGroup.get('mobile').value, '');
    this.user.contact.phone = MisBaseUtils.getValueOrDefault(this.formGroup.get('phone').value, '');
  }
}
