<div class="mis-mat-date-picker-header">
  <span class="mis-mat-date-picker-header-label">{{periodLabel}}</span>

  <button mat-icon-button
          ngClass="mis-button-unstyled mis-mat-date-picker-header-double-arrow"
          (click)="previousClicked('year')"
          title="Zum vorherigen Jahr">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button
          ngClass="mis-button-unstyled"
          (click)="previousClicked('month')"
          title="Zum vorherigen Monat">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button
          ngClass="mis-button-unstyled mis-mat-date-picker-header-today"
          (click)="todayClicked()"
          title="Zum heutigen Datum springen">
    <mat-icon>today</mat-icon>
  </button>
  <button mat-icon-button
          ngClass="mis-button-unstyled"
          (click)="nextClicked('month')"
          title="Zum nächsten Monat">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button
          ngClass="mis-button-unstyled mis-mat-date-picker-header-double-arrow"
          (click)="nextClicked('year')"
          title="Zum nächsten Jahr">
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
