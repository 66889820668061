<mis-map #mapComponent
         gestureHandling="greedy"
         [disableDoubleClickZoom]="true"
         [height]="contentHeight"
         [imageOverlay]="level1ImageOverlay"
         [mapZoomLevel]="12"
         [mapCenter]="mapCenter"
         [polygons]="polygons"
         minimalRequiredAccountSubscription="FREE"
         (mapZoomed)="updateMapContent($event)"
         (mapDragged)="updateMapContent($event)"
         (polygonClicked)="clickedPolygon($event)"
         (polygonHovered)="hoveredPolygon($event)"
         (mapMouseout)="emitMapMouseout()"
         (mousemove)="updateMouseLocation($event)"></mis-map>

<div class="legend"
     [joyrideStep]="guidedTourStep.PRICE_ATLAS_LEGEND"
     title="Preisatlas Legende"
     text="Die Legende zeigt Ihnen, welche Farbe auf der Karte, welche Eingruppierung darstellt.">
  <mis-price-atlas-map-legend [analyticLayerType]="analyticLayerType"
                              [legendItems]="priceAtlasMapLegendItems"
                              (resetLegendBounds)="resetCustomStatisticsMeta()"
                              (updateLegendBounds)="updateCustomStatisticsMeta($event)"></mis-price-atlas-map-legend>
</div>

<div class="toolbar"
     [joyrideStep]="guidedTourStep.PRICE_ATLAS_TOOLBAR"
     title="Preisatlas Toolbar"
     text="Ändern Sie über diese Toolbar die gewünschte Darstellung auf der Karte. Je nach dem welche Darstellung Sie ausgewählt haben, werden die Postleitzahlengebiete mit den entsprechenden Statistikwerten eingefärbt.">
  <mis-price-atlas-map-toolbar [analyticLayerType]="analyticLayerType"
                               [availableAnalyticsLayerTypes]="availableAnalyticsLayerTypes"
                               (changeAnalyticsLayerType)="changeAnalyticsLayerType.emit($event)"></mis-price-atlas-map-toolbar>
</div>

<div *ngIf="actualHoveredPolygon && toolTipPositionX && toolTipPositionY && actualHoveredPolygon"
     class="map-tooltip"
     [style.top]="toolTipPositionY"
     [style.right]="toolTipPositionX">
  Postleitzahl {{actualHoveredPolygon.id}}
</div>
