<div class="mis-modal-medium">
  <h2 mat-dialog-title>Anbieter Einstellungen</h2>

  <mat-dialog-content>

    <div>
      <p>Speichern Sie Ihre Anbieternamen von den verschiedenen Plattformen. Dies ermöglicht Ihnen einen schnellen
        Zugriff auf all Ihre Objekte.</p>

      <mis-hint *ngIf="objectKeys(sortedOffererList).length === 0">
        Um die Seite "Meine Objekte" nutzen zu können, müssen Sie zunächst Ihren Anbieternamen von anderen Plattformen
        hinterlegen. Anschließend sehen Sie alle Ihre Objekte auf einem Blick.
      </mis-hint>

      <div class="mis-offerer-settings-new-item-pane">
        <div class="mis-offerer-settings-new-item-pane-offerer-name">
          <mis-form-field-input placeholder="Ihr Anbietername"
                                [isAutoComplete]="true"
                                [value]="formOffererValue"
                                (loadAutoCompleteValues)="findOfferersByName($event)"
                                (changedValue)="changeOffererName($event)"></mis-form-field-input>
        </div>

        <div class="mis-offerer-settings-new-item-pane-button">
          <button (click)="saveOfferer()"
                  [disabled]="!formOfferer">Hinzufügen
          </button>
        </div>
      </div>
    </div>

    <div class="mis-offerer-list">
      <mis-empty-result-hint *ngIf="objectKeys(sortedOffererList).length === 0"
                             size="small">Legen Sie Ihre Anbieterreferenzen über die Eingabefelder an. Verknüpfen Sie so
        die von Ihnen eingestellten Objekte auf z.B. ImmobilienScout24 mit Ihrer propfox Konto.
      </mis-empty-result-hint>

      <div *ngFor="let sourceName of objectKeys(sortedOffererList)"
           class="mis-offerer-list-sources">
        <h3 *ngIf="sourceName && sourceName != 'null'">{{sourceName}}</h3>
        <div class="mis-offerer-list-item" *ngFor="let offerer of sortedOffererList[sourceName]">
          <a [href]="offerer.url"
             target="_blank"
             title="Quellplattform öffnen">{{offerer.name}}</a>
          <a href="#"
             class="mis-offerer-list-item-remove"
             (click)="removeOffererItem($event, offerer)">
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane">
      <button class="mis-button-pane-back"
              type="button"
              (click)="dialogRef.close(false)">Abbrechen
      </button>
      <button class="mis-button-pane-back"
              type="button"
              [disabled]="objectKeys(sortedOffererList).length === 0"
              (click)="dialogRef.close(true)">Schließen
      </button>
    </div>
  </mat-dialog-actions>
</div>
