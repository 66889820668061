import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {LocalStorageService} from '../local.storage.service';
import {ImmobilienTyp} from '../research/model/research.filter.model';
import {AbstractService} from '../abstract.service';
import {Rendite, RenditeAtlasMeta, RenditeAtlasResponse} from './model/rendite.model';
import {StatisticMeta} from './model/price.atlas.model';
import {PriceAtlasFilter} from './model/price.atlas.filter.model';

export class LoadRenditeRequest {
  constructor(public numberOfRooms: number,
              public kaufpreis: number,
              public flaeche: number) {
  }
}

@Injectable({providedIn: 'root'})
export class RenditeService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadRendite(propertyType: ImmobilienTyp, zipCode: string, request: LoadRenditeRequest): Observable<Rendite> {
    let params: HttpParams = new HttpParams();
    params = params.append('kaufpreis', '' + request.kaufpreis);
    params = params.append('flaeche', '' + request.flaeche);

    if (request.numberOfRooms) {
      params = params.append('zimmer', '' + request.numberOfRooms);
    }

    return this.GET<Rendite>(environment.misAnalyticEndpoint + '/rendite/' + propertyType + '/' + zipCode + '/rendite.json', params);
  }

  loadRenditeAtlas(filter: PriceAtlasFilter): Observable<RenditeAtlasResponse> {
    let params: HttpParams = new HttpParams();
    params = params.append('postleitzahlen', filter.zipCodes.join(','));

    if (filter.numberOfRooms) {
      params = params.append('zimmer', '' + filter.numberOfRooms);
    }
    if (filter.validUntilDate) {
      params = params.append('vud', filter.validUntilDate.toISOString());
    }

    return new Observable<RenditeAtlasResponse>((subscriber) => {
      this.GET<Rendite[]>(environment.misAnalyticEndpoint + '/rendite/' + filter.propertyType + '/rendite.json', params).subscribe((response) => {
        subscriber.next(this.convertRenditeAtlasResponse(response));
        subscriber.unsubscribe();

      }, (error) => {
        subscriber.error();
        subscriber.unsubscribe();
      });
    });
  }

  private convertRenditeAtlasResponse(renditeItemsToConvert: Rendite[]): RenditeAtlasResponse {
    const einwohnerMeta = new StatisticMeta();
    const renditeMeta = new StatisticMeta();
    renditeMeta.unit = '%';
    const faktorMeta = new StatisticMeta();

    renditeItemsToConvert.forEach((rendite) => {
      einwohnerMeta.lowerBound = this.getLowerBound(einwohnerMeta, rendite.einwohner);
      einwohnerMeta.upperBound = this.getUpperBound(einwohnerMeta, rendite.einwohner);

      rendite.rendite.min *= 100;
      rendite.rendite.max *= 100;
      rendite.rendite.median *= 100;
      rendite.rendite.mean *= 100;
      rendite.rendite.p20 *= 100;
      rendite.rendite.p80 *= 100;

      renditeMeta.lowerBound = this.getLowerBound(renditeMeta, rendite.rendite.median);
      renditeMeta.upperBound = this.getUpperBound(renditeMeta, rendite.rendite.median);
      if (renditeMeta.upperBound > 18) {
        renditeMeta.upperBound = 18;
      }

      faktorMeta.lowerBound = this.getLowerBound(faktorMeta, rendite.faktor.median);
      faktorMeta.upperBound = this.getUpperBound(faktorMeta, rendite.faktor.median);
    });

    const renditeAtlasResponse = new RenditeAtlasResponse();
    renditeAtlasResponse.meta = new RenditeAtlasMeta();
    renditeAtlasResponse.meta.einwohner = einwohnerMeta;
    renditeAtlasResponse.meta.rendite = renditeMeta;
    renditeAtlasResponse.meta.faktor = faktorMeta;

    renditeAtlasResponse.renditeAtlas = renditeItemsToConvert;
    return renditeAtlasResponse;
  }

  private getLowerBound(stats: StatisticMeta, newValue: number): number {
    if (!newValue || isNaN(newValue)) {
      return stats.lowerBound;
    }

    return (!stats.lowerBound || stats.lowerBound > newValue) ? newValue : stats.lowerBound;
  }

  private getUpperBound(stats: StatisticMeta, newValue: number): number {
    if (!newValue || isNaN(newValue)) {
      return stats.upperBound;
    }

    return (!stats.upperBound || stats.upperBound < newValue) ? newValue : stats.upperBound;
  }
}
