<div class="mis-modal-medium">
  <h2 mat-dialog-title>Nutzerdaten bearbeiten</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form [formGroup]="formGroup"
        (submit)="saveForm()"
        *ngIf="!isLoading">
    <mat-dialog-content>

      <mat-form-field>
        <mat-select formControlName="salutation"
                    placeholder="Anrede">
          <mat-option value="">-</mat-option>
          <mat-option value="MR">Herr</mat-option>
          <mat-option value="MRS">Frau</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="firstName"
               name="firstName"
               placeholder="Vorname"
               required="required">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="lastName"
               name="lastName"
               placeholder="Nachname"
               required="required">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="email"
               name="usermail"
               type="email"
               placeholder="E-Mail-Adresse"
               required="required">
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="role"
                    placeholder="Rolle">
          <mat-option value="ACCOUNT_MEMBER">Nutzer</mat-option>
          <mat-option value="ACCOUNT_ADMIN">Administrator</mat-option>
          <mat-option value="INACTIVE">Deaktiviert</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="phone"
               type="tel"
               placeholder="Telefon">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="mobile"
               type="tel"
               placeholder="Mobilfunknummer">
      </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Speichern
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
