<div class="mis-modal-medium">
  <h2 mat-dialog-title>Anzeige Einstellungen</h2>

  <mat-dialog-content>
    <div>
      <form [formGroup]="formGroup"
            (submit)="saveForm()">
        <div class="mis-row">
          <div *ngFor="let field of priceAtlasFields"
               class="mis-column-33">
            <mat-checkbox [formControlName]="field.type">{{field.title}}</mat-checkbox>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane">
      <button class="mis-button-pane-back"
              type="button"
              (click)="dialogRef.close(null)">Abbrechen
      </button>
      <button class="mis-button-pane-back"
              type="button"
              (click)="saveForm()">Übernehmen
      </button>
    </div>
  </mat-dialog-actions>
</div>
