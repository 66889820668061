import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ResearchService} from '../../../services/research/research.service';
import {environment} from '../../../../environments/environment';
import {QueryField, QuerySpec, SortField} from '../../../services/research/model/research.filter.model';
import {ResearchItem} from '../../../services/research/model/research.item.model';
import {AnalyticDownloadType, AnalyticService} from '../../../services/analytic/analytic.service';
import {saveAs} from 'file-saver';
import {Subscription} from 'rxjs';
import {TableServiceInterface} from '../../../services/research/table.service.interface';
import {FilterBuilder, FilterDateRangeEvent} from './filter-pane/filter.builder';
import {AnalyticLegacyService} from '../../../services/analytic/analytic.legacy.service';
import {SortItem} from './immo-table/immo.table.component';
import {SavedSearch, SavedSearchService} from '../../../services/research/saved.search.service';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';
import {SnackbarService} from '../../basic/snackbar/snackbar.service';
import * as moment from 'moment';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../viewservice/view.service';
import {FilterQueryReader} from './filter-pane/filter.query.reader';
import {GuidedTourService, GuidedTourType} from '../guided-tour/guided.tour.service';
import {ImmoTableFilterEvent} from './immo-table/immo-table-column/immo.table.column.item';
import {OffererService} from '../../../services/aaa/offerer.service';
import {FilterPaneComponent} from './filter-pane/filter.pane.component';
import {MatDialog} from '@angular/material/dialog';
import {OffererSettingsModalComponent} from './immo-navigation/offerer-settings-modal/offerer.settings.modal.component';
import {Location} from '@angular/common';

export type ImmoOverviewViewType = 'WATCHLIST' | 'IMMO_MANAGEMENT' | 'RESEARCH';

export const DEFAULT_SORT_CRITERIA = new SortField('firstOccurrenceDate', 'desc');

@Component({
  selector:    'mis-immo-overview',
  templateUrl: './immo.overview.component.html',
  styleUrls:   ['./immo.overview.component.scss']
})
export class ImmoOverviewComponent implements OnInit, OnDestroy, ViewServiceChangeListener {
  @Input() viewType: ImmoOverviewViewType;
  @Input() tableService: TableServiceInterface;
  @ViewChild(FilterPaneComponent, {static: true}) filterPane: FilterPaneComponent;

  navigationOpened: boolean;

  isLoading = false;
  isLoadingAuswertung = false;
  isDownloadingExport = false;
  offererSettingsConfigurationRequired: boolean = false;
  resultIsEmpty = true;
  filterQuery: QuerySpec;
  sortField: SortField = DEFAULT_SORT_CRITERIA;

  researchItems: ResearchItem[];

  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageHitsPerPage: number;
  pageIndex = 0;
  pageItemCount = 0;
  totalPages = 1;
  filterPaneHeight: number = 0;

  contentHeight: string;
  viewServiceInstance = ViewServiceInstance;
  private loadListSubscriber: Subscription;

  constructor(private researchService: ResearchService,
              private analyticService: AnalyticService,
              private analyticLegacyService: AnalyticLegacyService,
              private dialog: MatDialog,
              private snackBar: SnackbarService,
              private savedSearchService: SavedSearchService,
              private route: ActivatedRoute,
              private location: Location,
              private guidedTourService: GuidedTourService,
              private offererService: OffererService) {

    ViewServiceInstance.listenOnResize(this);
    this.pageHitsPerPage = environment.paginationDefaultHitsPerPage;
    this.initializeOverview();
  }

  ngOnInit() {
    this.updatePaginationSizeOptions();

    if (this.filterQuery != null) {
      this.reloadItemList(this.filterQuery, 0, this.pageHitsPerPage);
    }
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    this.contentHeight = window.innerHeight + 'px';
  }

  private initializeOverview(): void {
    // Load filter from deep url with filtername / savedSearch
    if (this.route.snapshot.params.hasOwnProperty('filtername')) {
      // lade Filtername
      this.isLoading = true;
      const filterName = this.route.snapshot.params.filtername;
      console.log("Found filtername: " + filterName);

      this.savedSearchService.findSavedSearch(filterName).subscribe((savedSearch) => {
        const filterBuilder = new FilterBuilder().withQuery(savedSearch.spec);

        const firstOccuranceDate = this.route.snapshot.queryParamMap.get('fod');
        if (firstOccuranceDate) {
          const fod = moment(firstOccuranceDate).toDate();
          filterBuilder.withDateRange(FilterDateRangeEvent.ofNewerSince(fod));
        }

        this.filterQuery = filterBuilder.build();
        this.refreshView();

      }, (error) => {
        this.snackBar.hint('Der Filter konnte nicht gefunden werden!');
        this.researchItems = [];
        this.isLoading = false;
        this.isLoadingAuswertung = false;
      });
      return;
    }

    // load filter by deep url with filter query
    const filterQueryFromUrl = this.getFilterQueryFromUrlQuery();
    if (filterQueryFromUrl) {
      this.filterQuery = filterQueryFromUrl;
      return;
    }

    this.filterQuery = this.loadUserFilter();
  }

  private getFilterQueryFromUrlQuery(): QuerySpec {
    const filterQueryParam = this.route.snapshot.queryParamMap.get('filterQuery');

    if (!filterQueryParam) {
      return null;
    }

    const filterQuery = JSON.parse(filterQueryParam);
    return this.filterQuery = new FilterBuilder()
      .withQuery(filterQuery)
      .build();
  }

  private loadUserFilter(): QuerySpec {
    // load user filter
    const activeUserFilter = this.savedSearchService.getActiveSearch();

    if (activeUserFilter) {
      this.pageHitsPerPage = activeUserFilter.pageHits;
      const filterDateRangeEvent = FilterQueryReader.getFilterDateRangeEvent(activeUserFilter.querySpec);

      const filterBuilder = new FilterBuilder()
        .withQuery(activeUserFilter.querySpec)
        .withDateRange(filterDateRangeEvent);
      return filterBuilder.build();

    } else {
      return new FilterBuilder()
        .withOfferType('MIETE')
        .withPropertyType('WOHNUNG')
        .build();
    }
  }

  reloadItemList(filterQuery: QuerySpec, pageIndex: number, pageHits: number): void {

    // disabled immo management list loading
    if (this.loadListSubscriber) {
      this.loadListSubscriber.unsubscribe();
      this.loadListSubscriber = null;
    }

    this.pageIndex = pageIndex;
    this.pageHitsPerPage = pageHits;

    // In der Marktanalyse (RESEARCH) muss ein Ort oder eine Postlietzahl angegebenen werden!
    if (!filterQuery || this.viewType === 'RESEARCH' && !filterQuery.hasCityOrZipCodeFilters()) {
      this.researchItems = [];
      this.resultIsEmpty = true;
      this.pageItemCount = 0;
      this.sortField = DEFAULT_SORT_CRITERIA;
      return;
    }

    this.isLoading = true;
    this.isLoadingAuswertung = true;

    this.addOfferersToFilterIfNecessary(filterQuery, (newFilterQuery) => {
      this.loadItemList(newFilterQuery);
    });
  }

  private loadItemList(filterQuery: QuerySpec): void {
    this.researchItems = [];

    this.filterQuery = filterQuery;
    this.filterQuery.page = this.pageIndex;
    this.filterQuery.size = this.pageHitsPerPage;
    this.filterPane.ngOnChanges(null);

    if (!this.tableService) {
      return;
    }

    this.loadListSubscriber = this.tableService.getList(this.filterQuery, this.sortField).subscribe((result) => {
      this.researchItems = result.content;
      this.resultIsEmpty = result.content.length === 0;
      this.pageItemCount = result.totalElements;
      this.totalPages = result.totalPages;

      // load auswertung
      if (!this.resultIsEmpty) {
        const subscription = this.analyticLegacyService.loadAuswertung(filterQuery);
        if (!subscription) {
          this.isLoadingAuswertung = false;

        } else {
          subscription.subscribe(() => {
            this.isLoadingAuswertung = false;
          });
        }

      } else {
        this.isLoadingAuswertung = false;
      }

      this.isLoading = false;

      if (this.researchItems.length > 0) {
        this.guidedTourService.startTour(GuidedTourType.IMMO_TABLE_TOUR);
      }

    }, () => {
      this.researchItems = [];

      this.pageItemCount = 0;
      this.resultIsEmpty = true;
      this.isLoading = false;
      this.isLoadingAuswertung = false;
    });
  }

  refreshView(): void {
    this.reloadItemList(this.filterQuery, this.pageIndex, this.pageHitsPerPage);
  }

  // ------------------------------------------------------------
  // Pagination / Sort
  changePagination(pageEvent: PageEvent): void {
    this.reloadItemList(this.filterQuery, pageEvent.pageIndex, pageEvent.pageSize);

    // store current filter
    this.savedSearchService.updateActiveSearch(this.filterQuery, pageEvent.pageSize).subscribe(() => {
    });
  }

  getSortItem(): SortItem {
    const direction = this.sortField.direction === 'asc' ? 'ASC' : 'DESC';
    return new SortItem(direction, this.sortField.field);
  }

  changeSort(sortField: SortField) {
    this.sortField = sortField;
    this.reloadItemList(this.filterQuery, 0, this.pageHitsPerPage);
  }

  // ------------------------------------------------------------
  // Export
  exportResults(variant: string): void {
    if (this.isLoading || this.isLoadingAuswertung || this.isDownloadingExport) {
      return;
    }

    if (this.resultIsEmpty) {
      this.snackBar.hint('Bitte passen Sie die Filterung an, um mindestens ein Suchergebnis zu erhalten, welches exportiert werden soll.');
      return;
    }

    const filter = this.filterQuery;
    filter.page = null;
    filter.size = null;
    this.isDownloadingExport = true;

    if (variant === '.csv') {
      this.researchService.downloadAsCsv(filter).subscribe((result) => {
        saveAs(result, 'propfox-auswertung-' + filter.angebotsTyp + '-' + filter.immobilienTyp + variant);
        this.isDownloadingExport = false;

      }, () => {
        this.snackBar.error('Beim Exportieren ist ein Fehler aufgetreten.');
        this.isDownloadingExport = false;
      });

    } else {
      this.analyticService.downloadAuswertung(variant as AnalyticDownloadType, filter, this.sortField).subscribe((result) => {
        saveAs(result, 'propfox-auswertung-' + filter.angebotsTyp + '-' + filter.immobilienTyp + variant);
        this.isDownloadingExport = false;
      }, () => {
        this.snackBar.error('Beim Exportieren ist ein Fehler aufgetreten.');
        this.isDownloadingExport = false;
      });
    }
  }

  // ------------------------------------------------------------
  // Pagination
  private updatePaginationSizeOptions() {
    this.pageIndex = 0;
    this.pageSizeOptions = [10, 25, 50, 100];

    const hasSizeOption = this.pageSizeOptions.find(opt => this.pageHitsPerPage === opt);
    if (!hasSizeOption) {
      this.pageHitsPerPage = environment.paginationDefaultHitsPerPage;
    }
  }

  // ------------------------------------------------------------
  // Search
  applySearch(search: SavedSearch): void {
    const filterBuilder = new FilterBuilder();
    filterBuilder.withQuery(search.spec);

    this.applyFilter(filterBuilder.build(), 0, this.pageHitsPerPage);
    this.setActualUrlLocation(search);
  }

  // ------------------------------------------------------------
  // Filter
  applyFilter(query: QuerySpec, pageIndex: number, pageHits: number): void {
    this.reloadItemList(query, 0, pageHits);

    this.setActualUrlLocation(null);
    this.savedSearchService.updateActiveSearch(query, pageHits).subscribe(() => {
    });
  }

  private setActualUrlLocation(search: SavedSearch): void {
    let url = '/' + this.viewType.toLowerCase().replace('_', '-');

    if (search) {
      url += '/' + encodeURIComponent(search.name);
    }

    this.location.go(url);
  }

  changedFilterPaneHeight(newHeight: number): void {
    this.filterPaneHeight = newHeight;
  }

  updateFilterFromImmoTable(filterEvent: ImmoTableFilterEvent): void {

    const newFilterQuery = new QuerySpec(this.filterQuery);
    newFilterQuery.root.combineList = newFilterQuery.root.combineList.filter(extendedFilter => extendedFilter.field !== filterEvent.field);
    newFilterQuery.root.combineList.push(QueryField.eq(filterEvent.field, filterEvent.value));

    this.applyFilter(newFilterQuery, 0, this.pageHitsPerPage);
  }

  // ------------------------------------------------------------
  // Navigation
  toggleNavigation(opened): void {
    this.navigationOpened = opened;
  }

  // Immo table specific code
  private addOfferersToFilterIfNecessary(filterQuery: QuerySpec, callback: (filterQuery: QuerySpec) => void): void {

    if (filterQuery == null || this.viewType !== 'IMMO_MANAGEMENT') {
      callback(filterQuery);
      return;
    }

    filterQuery.root.combineList = filterQuery.root.combineList.filter(filter => filter.field !== 'realtorName');

    this.offererService.getList().subscribe((offerers) => {
      if (offerers.length === 0) {
        // in der Ansicht "Meine Objekte" muss mindestens ein Anbieter hinterlegt sein!
        this.isLoading = false;
        this.isLoadingAuswertung = false;
        this.offererSettingsConfigurationRequired = true;
        this.filterPane.ngOnChanges(null);

        // show offerersettings modal
        const dialogRef = this.dialog.open(OffererSettingsModalComponent);
        dialogRef.afterClosed().subscribe((refresh) => {
          if (refresh) {
            this.applyFilter(this.filterQuery, 0, this.pageHitsPerPage);
          }
        });

        return;
      }

      this.offererSettingsConfigurationRequired = false;

      if (offerers.length === 1) {
        filterQuery.root.combineList.unshift(QueryField.eq('realtorName', offerers[0].name));

      } else if (offerers.length > 1) {
        const queryField = new QueryField();
        queryField.field = 'realtorName';
        queryField.comparator = 'eq';
        queryField.sValueList = offerers.map(v => v.name);
        queryField.combineListElementsWith = 'or';

        filterQuery.root.combineList.unshift(queryField);
      }

      callback(filterQuery);
    }, (err) => {

      callback(filterQuery);
    });
  }
}
