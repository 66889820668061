import { Component, Input, OnInit } from '@angular/core';
import { ResearchItem } from '../../../../../../services/research/model/research.item.model';
import { AnalyticService } from '../../../../../../services/analytic/analytic.service';
import {
  MisAuswertungRoot,
  MisAuswertungSourceClusterRow
} from '../../../../../../services/analytic/model/legacy.analysis.model';
import { MisBenchmarkRow } from '../../../../../../services/analytic/model/simple.analysis.model';
import * as moment from "moment";

@Component({
  selector: 'mis-immo-analysis-by-object',
  templateUrl: './immo.analysis.by.object.component.html',
  styleUrls: ['./immo.analysis.by.object.component.scss']
})
export class ImmoAnalysisByObjectComponent implements OnInit {
  @Input() researchItem: ResearchItem;

  isLoading: boolean = true;
  analysis: MisAuswertungRoot;
  objectCount: number;

  benchmarkRoomNumber: MisBenchmarkRow;
  benchmarkPrice: MisBenchmarkRow;
  benchmarkUtilities: MisBenchmarkRow;
  benchmarkLivingSpace: MisBenchmarkRow;
  benchmarkAge: MisBenchmarkRow;
  benchmarkPricePerLivingSpace: MisBenchmarkRow;
  benchmarkPropertySize: MisBenchmarkRow;
  benchmarkPricePerPropertySize: MisBenchmarkRow;

  constructor(private analyticService: AnalyticService) {
  }

  ngOnInit(): void {
    this.loadAnalysis();
  }

  private loadAnalysis(): void {
    const immo = this.researchItem;
    this.analyticService
      .getAuswertungByImmobilie(immo.id.sourceName, immo.id.externalId, immo.immobilienTyp)
      .subscribe((analysis: MisAuswertungRoot) => {
        this.analysis = analysis;

        const analysisRows: MisAuswertungSourceClusterRow[] = analysis.sources
          .filter((source) => source.source === 'Alle')
          .flatMap(source => source.clusters)
          .filter((cluster) => cluster.name === 'Gesamt')
          .map((cluster) => cluster.rows[0]);

        if (!analysisRows || analysisRows.length === 0) {
          this.isLoading = false;
          return;
        }

        const analysisRow: MisAuswertungSourceClusterRow = analysisRows[0];
        this.objectCount = analysisRow.anzahl;

        if (analysisRow.zimmerAnzahl && immo.zimmerAnzahl) {
          this.benchmarkRoomNumber = analysisRow.zimmerAnzahl as MisBenchmarkRow;
          this.benchmarkRoomNumber.actual = immo.zimmerAnzahl;
        }

        if (analysisRow.preis && immo.preis) {
          this.benchmarkPrice = analysisRow.preis as MisBenchmarkRow;
          this.benchmarkPrice.actual = immo.preis;
        }

        if (analysisRow.nebenkosten && immo.nebenkosten) {
          this.benchmarkUtilities = analysisRow.nebenkosten as MisBenchmarkRow;
          this.benchmarkUtilities.actual = immo.nebenkosten;
        }

        if (analysisRow.wohnflaeche && immo.wohnflaeche) {
          this.benchmarkLivingSpace = analysisRow.wohnflaeche as MisBenchmarkRow;
          this.benchmarkLivingSpace.actual = immo.wohnflaeche;
        }

        if (analysisRow.preisJeQm && immo.preisJeQm) {
          this.benchmarkPricePerLivingSpace = analysisRow.preisJeQm as MisBenchmarkRow;
          this.benchmarkPricePerLivingSpace.actual = immo.preisJeQm;
        }

        if (analysisRow.grundstuecksFlaeche && immo.grundstueckflaeche) {
          this.benchmarkPropertySize = analysisRow.grundstuecksFlaeche as MisBenchmarkRow;
          this.benchmarkPropertySize.actual = immo.grundstueckflaeche;
        }

        if (analysisRow.alter && immo.firstOccurrenceDate) {
          const now = moment(new Date());
          const fod = moment(immo.firstOccurrenceDate);
          this.benchmarkAge = analysisRow.alter as MisBenchmarkRow;
          this.benchmarkAge.actual = fod.diff(now, "days", false);;
        }

        this.isLoading = false;
      }, (err) => {
        console.warn(err);
      });
  }
}
