import {AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList} from '@angular/core';
import {TabItemComponent} from './tab-item/tab-item.component';
import {MisEventUtils} from '../../../services/common/event.util';

@Component({
  selector: 'mis-tabs-group',
  templateUrl: './tabs-group.component.html',
  styleUrls: ['./tabs-group.component.scss']
})
export class TabsGroupComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent, {descendants: false}) allTabs: QueryList<TabItemComponent>;
  @Output() changedTab = new EventEmitter<number>();

  ngAfterContentInit(): void {
    const activeTabs = this.allTabs.filter((tab) => tab.active);
    if (activeTabs.length > 0) {
      this.selectTab(null, activeTabs[0]);
    } else if (this.allTabs.length > 0) {
      this.selectTab(null, this.allTabs.first);
    }
  }

  selectTab(event: Event, tab: TabItemComponent) {
    MisEventUtils.stopEvent(event);

    this.allTabs.forEach((tabItem) => tabItem.active = false);
    tab.activateTab();
  }
}
