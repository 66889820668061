import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MisMatDatePickerHeader} from '../../../../material/mis-mat-datepicker/mis.mat.date.picker.header';
import {Moment} from 'moment';

@Component({
  selector: 'mis-form-field-datepicker',
  templateUrl: './form.fields.datepicker.component.html',
  styleUrls: ['./form.fields.datepicker.component.scss']
})
export class FormFieldsDatepickerComponent {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() selectedDate: Date;
  @Output() changedDate = new EventEmitter<Date>();

  misMatDatePickerHeader = MisMatDatePickerHeader;

  changeDate(event: MatDatepickerInputEvent<Moment>) {
    const newValue = event.value.toDate();

    if (newValue !== this.selectedDate) {
      this.selectedDate = newValue;
      this.changedDate.emit(newValue);
    }
  }

  selectedTodayDate(event) {
    this.selectedDate = new Date();
    this.changedDate.emit(this.selectedDate);
  }
}
