import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MisSubscription, TariffInfoItem} from '../../../../../../services/aaa/model/tariff.model';
import * as moment from 'moment';

@Component({
  selector: 'mis-tariff-item',
  templateUrl: './tariff.item.component.html',
  styleUrls: ['./tariff.item.component.scss']
})
export class TariffItemComponent implements OnInit, OnChanges {
  @Input() tariffInfo: TariffInfoItem;
  @Input() tariff: MisSubscription;
  @Input() isLoading: boolean;
  @Input() currentSelected: boolean;
  @Output() selectTariff = new EventEmitter<TariffInfoItem>();

  isActualTariff: boolean;
  isNextTariff: boolean;
  nextTariffValidFromDate: Date;
  hasNextTariff: boolean;

  ngOnInit(): void {
    if (!this.tariff) {
      this.isActualTariff = this.tariffInfo.backendKey === 'FREE';
      return;
    }

    this.isActualTariff = this.tariff.type === this.tariffInfo.backendKey;
    this.isNextTariff = this.tariff.nextType === this.tariffInfo.backendKey;
    this.hasNextTariff = this.tariff.nextType != null;

    if (this.isNextTariff) {
      this.nextTariffValidFromDate = moment(this.tariff.expirationDate).add(1, 'day').toDate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
}

