import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

const ColorValueRegex = RegExp('^((#[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?)|(rgba\\(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]),\\d(\\.)?\\d*\\)))$');

export function colorValueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return null;
    }

    if (!ColorValueRegex.test(control.value)) {
      return {colorValueInValid: "true"};
    }
    return null;
  };
}
