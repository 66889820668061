import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../../../services/aaa/account.service';
import {MisAccount, MisContact, MisTagUtils} from '../../../../services/aaa/model/account.model';
import {CountryCode, getCountryByCode} from '../../../../services/common/country.codes.util';
import {AccountDataEditModalComponent} from './account-data-edit-modal/account.data.edit.modal.component';
import {ContactEditModalComponent} from './contact-edit-modal/contact.edit.modal.component';
import {AccountFlowFactDataEditModalComponent} from './account-flow-fact-data-edit-modal/account.flow.fact.data.edit.modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MisEventUtils} from '../../../../services/common/event.util';

@Component({
  selector: 'mis-account-management',
  templateUrl: './account.management.component.html',
  styleUrls: ['./account.management.component.scss']
})
export class AccountManagementComponent implements OnInit {

  account: MisAccount;
  isLoading = true;
  countryCode: CountryCode;
  hasFlowFactData: boolean;

  constructor(private accountService: AccountService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadAccount();
  }

  loadAccount(): void {
    this.isLoading = true;

    this.accountService.getAccount().subscribe((account) => {
      this.account = account;
      this.countryCode = getCountryByCode(account.countryCode);
      this.hasFlowFactData = this.hasFlowFactDataTags();
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  editAccountData(event) {
    MisEventUtils.stopEvent(event);

    this.dialog.open(AccountDataEditModalComponent, {
      data: {
        account: this.account
      }
    });
  }

  editContact(contact: MisContact): void {
    this.dialog.open(ContactEditModalComponent, {
      data: {
        account: this.account,
        contact: contact
      }
    });
  }

  editFlowFactData(): void {
    this.dialog.open(AccountFlowFactDataEditModalComponent, {
      data: this.account
    });
  }

  private hasFlowFactDataTags() {
    return this.account.tags.filter(tag => tag.key === MisTagUtils.keys.flowFactServerUrl).length > 0;
  }

  getFlowFactServerUrl() {
    return MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerUrl).value;
  }

  getFlowFactServerCustomerId() {
    return MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerCustomerId).value;
  }
}
