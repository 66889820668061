import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MarketReportService} from "../../../../../services/market-report/market.report.service";
import {MarketReportLayout} from "../../../../../services/market-report/model/market.report.customization.model";
import {PrintComponent, PrintContainerComponent} from "../../../../basic/print-container/print.container.component";
import {DomSanitizer} from "@angular/platform-browser";
import {MarketReportData} from "../../../../../services/market-report/model/market.report.model";
import {MarketReportConfiguration} from "../../../../../services/market-report/model/market.report.configuration.model";

export class MarketReportExportComponentData {
  constructor(public marketReportLayout: MarketReportLayout,
              public marketReportData: MarketReportData,
              public marketReportConfiguration: MarketReportConfiguration) {
  }

}

@Component({
  template: ''
})
export abstract class MarketReportExportSubComponent {
  @Input() city: string;
  @Output() loaded = new EventEmitter<void>();
}

@Component({
  selector: 'mis-market-report-export',
  templateUrl: './market.report.export.component.html'
})
export class MarketReportExportComponent implements PrintComponent {
  data: any;
  isInitialised: boolean;
  printContainer: PrintContainerComponent;

  marketReportLayout?: MarketReportLayout;
  marketReportData?: MarketReportData;
  marketReportConfiguration: MarketReportConfiguration;

  notRenderedPages: string[] = [
    'intro-page',
    'overview-page',
    'city-short-report-page',
    'leasing-market-page',
    'buyer-market-page',
    'imprint-page'
  ];

  constructor(private sanitizer: DomSanitizer) {
  }

  initPrint(): void {
    const exportData = this.data as MarketReportExportComponentData;
    this.marketReportData = exportData.marketReportData;
    this.marketReportLayout = exportData.marketReportLayout;
    this.marketReportConfiguration = exportData.marketReportConfiguration;
  }

  @HostBinding('attr.style')
  get valueAsStyle(): any {
    const cssVariables = {};

    if (!this.marketReportLayout) {
      return;
    }

    //======================================
    cssVariables['introMainHeadingColor'] = this.marketReportLayout.introMainHeadingColor;
    cssVariables['introSubHeadingColor'] = this.marketReportLayout.introSubHeadingColor;
    cssVariables['mainHeadingColor'] = this.marketReportLayout.mainHeadingColor;
    cssVariables['subHeadingColor'] = this.marketReportLayout.subHeadingColor;
    cssVariables['textColor'] = this.marketReportLayout.textColor;

    let preparedCssVariableTrustedValues = '';
    Object.keys(cssVariables).forEach((cssVariablesKey) => {
      preparedCssVariableTrustedValues += `--${cssVariablesKey}: ${cssVariables[cssVariablesKey]};`;
    });

    return this.sanitizer.bypassSecurityTrustStyle(preparedCssVariableTrustedValues);
  }

  rendered(page: string): void {
    this.notRenderedPages = this.notRenderedPages.filter(p => p !== page);

    if (this.notRenderedPages.length === 0) {
      setTimeout(() => {
        this.isInitialised = true;
      }, 500);
    }
  }
}
