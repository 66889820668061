<div class="mis-login-home mis-small-container">
  <div
    class="mis-small-container-item mis-small-container-item-{{activeComponent === 'login' ? 'active' : 'inactive'}}">
    <div class="mis-small-container-item-content">
      <mis-login-wrapper></mis-login-wrapper>
    </div>
  </div>

  <div *ngIf="activeComponent === 'login'"
       class="mis-button-center">
    <a href="https://propfox.io/mehr-ueber-propfox-erfahren/"
       class="mis-login-wrapper-choose-button">
      Mehr zur Registrierung erfahren.
    </a>
  </div>

  <!--<div class="mis-button-center">
    <button class="mis-login-wrapper-choose-button"
            (click)="toggleWrapper($event)">{{toggleButtonTitle}}
    </button>
  </div>-->

  <div class="mis-small-container-item mis-small-container-item-{{activeComponent === 'registration' ? 'active' : 'inactive'}}">
    <div class="mis-small-container-item-content">
      <mis-registration-wrapper [isRegistrationVisible]="activeComponent === 'registration'"></mis-registration-wrapper>
    </div>
  </div>
</div>
