import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PriceAtlasService} from '../../../services/price-altas/price.atlas.service';
import {Preisatlas, PreisatlasResponse} from '../../../services/price-altas/model/price.atlas.model';
import {PriceAtlasMapChangedMap, PriceAtlasMapComponent} from './price-atlas-map/price.atlas.map.component';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../viewservice/view.service';
import {GuidedTourService, GuidedTourStep, GuidedTourType} from '../../common/guided-tour/guided.tour.service';
import {RenditeService} from '../../../services/price-altas/rendite.service';
import {SnackbarService} from '../../basic/snackbar/snackbar.service';
import {PriceAtlasFilter} from '../../../services/price-altas/model/price.atlas.filter.model';
import {PriceAtlasFilterEvent} from './price-atlas-filter/price-atlas-filter.component';
import * as moment from 'moment';
import {SavedPriceAtlasSettings} from '../../../services/price-altas/model/saved.price.atlas.settings.model';
import {PriceAtlasTableComponent} from './price-atlas-table/price-atlas-table.component';
import {PriceAtlasFieldType} from './price.atlas.configuration';

@Component({
  selector:    'mis-price-atlas',
  templateUrl: './price.atlas.component.html',
  styleUrls:   ['./price.atlas.component.scss']
})
export class PriceAtlasComponent implements ViewServiceChangeListener, OnInit, OnDestroy {
  @ViewChild('priceAtlasMap', {static: true}) priceAtlasMap: PriceAtlasMapComponent;
  @ViewChild('priceAtlasTable', {static: true}) priceAtlasTable: PriceAtlasTableComponent;

  isLoading: boolean = true;

  currentAnalyticLayerType: PriceAtlasFieldType = 'alter';
  availablePriceAtlasFieldsOnMap: PriceAtlasFieldType[];
  visiblePriceAtlasFields: PriceAtlasFieldType[];
  currentFilter: PriceAtlasFilter;
  preisAtlasByZipCodes: { [zipCode: string]: Preisatlas };
  visibleZipCodes: string[];

  selectedZipCode: string;
  hoveredZipCode: string;
  activeZipCode: string;
  activeZipCodePreisAtlas: Preisatlas;
  preisAtlasResponse: PreisatlasResponse;
  mapCenter: google.maps.LatLngLiteral;

  maxHeightDetailsContainer: string;

  guidedTourStep = GuidedTourStep;

  constructor(private priceAtlasService: PriceAtlasService,
              private renditeService: RenditeService,
              private guidedTourService: GuidedTourService,
              private snackBar: SnackbarService) {
    this.availablePriceAtlasFieldsOnMap = ['rendite', 'faktor', 'preisJeQm', 'preis', 'flaeche', 'alter', 'anzahl', 'einwohner', 'anzahlJeEinwohner'];
    this.visiblePriceAtlasFields = ['rendite', 'preisJeQm', 'preis', 'flaeche', 'alter', 'kaufkraft', 'anzahl', 'einwohner', 'anzahlJeEinwohner'];
    this.setupPriceAtlasSettings();
  }

  private setupPriceAtlasSettings(): void {
    // default location = Dresden
    this.mapCenter = {
      lat: 51.05996488469941,
      lng: 13.736651914285373
    };

    const mapSettings = this.priceAtlasService.getLastUsedMapSettings();
    if (mapSettings) {
      if (mapSettings.position) {
        this.mapCenter = mapSettings.position;
      }

      if (mapSettings.visibleFields) {
        this.visiblePriceAtlasFields = mapSettings.visibleFields;
      }
    }
  }

  onResize(): void {
    this.maxHeightDetailsContainer = (window.innerHeight - 220) + 'px';
  }

  ngOnInit() {
    ViewServiceInstance.listenOnResize(this);
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  loadPreisatlas(): void {
    this.isLoading = true;

    this.priceAtlasService.loadPriceAtlas(this.currentFilter).subscribe((preisAtlasResponse) => {
        this.preisAtlasByZipCodes = {};
        this.preisAtlasResponse = preisAtlasResponse;
        preisAtlasResponse.preisatlas.forEach((preisAtlasItem) => {
          this.preisAtlasByZipCodes[preisAtlasItem.postleitzahl] = preisAtlasItem;
        });
        this.priceAtlasMap.updatePreisatlas(this.preisAtlasByZipCodes, preisAtlasResponse.meta);
        this.priceAtlasMap.updateAnalyticLayerType(this.currentAnalyticLayerType);
        this.priceAtlasTable.updateFilter(this.currentFilter);

        this.activeZipCodePreisAtlas = this.preisAtlasByZipCodes[this.activeZipCode];
        this.isLoading = false;
        this.guidedTourService.startTour(GuidedTourType.PRICE_ATLAS_TOUR);

      }, (ignore) => {
        this.isLoading = false;
        this.snackBar.error('Der Preisatlas konnte nicht geladen werden.');
      }
    );
  }

  changedSelectedZipCode(zipCode: string): void {
    this.selectedZipCode = zipCode;
    this.activeZipCode = this.selectedZipCode;
    this.activeZipCodePreisAtlas = this.preisAtlasByZipCodes[this.selectedZipCode];

    this.priceAtlasTable.updateZipCode(this.selectedZipCode);
  }

  applyFilter(filter: PriceAtlasFilterEvent) {
    this.currentFilter = filter.toPriceAtlas(this.visibleZipCodes);
    this.currentFilter.zipCodes = this.visibleZipCodes;

    if (this.currentFilter.offerType === 'KAUF') {
      this.availablePriceAtlasFieldsOnMap = ['rendite', 'faktor', 'preisJeQm', 'preis', 'flaeche', 'alter', 'anzahl', 'einwohner', 'anzahlJeEinwohner'];
    } else {
      this.availablePriceAtlasFieldsOnMap = ['preisJeQm', 'preis', 'flaeche', 'alter', 'anzahl', 'einwohner', 'anzahlJeEinwohner'];
      this.currentAnalyticLayerType = this.currentAnalyticLayerType === 'rendite' ? 'alter' : this.currentAnalyticLayerType;
    }

    this.loadPreisatlas();
  }

  changedMapPosition(changeEvent: PriceAtlasMapChangedMap) {
    this.visibleZipCodes = changeEvent.visibleZipCodes;

    if (!this.currentFilter) {
      // setup default filter
      this.currentFilter = new PriceAtlasFilter('WOHNUNG', 'KAUF', [], moment().subtract(1, 'year').toDate());
    }
    this.currentFilter.zipCodes = this.visibleZipCodes;

    // update user price altas settings
    let settings = this.priceAtlasService.getLastUsedMapSettings();
    settings = settings ? settings : new SavedPriceAtlasSettings();
    settings.position = changeEvent.position.toJSON();
    this.priceAtlasService.saveLastUsedMapSettings(settings);

    this.loadPreisatlas();
  }

  updateAnalyticLayerType(analyticLayerType: PriceAtlasFieldType) {
    this.currentAnalyticLayerType = analyticLayerType;
    this.priceAtlasMap.updateAnalyticLayerType(analyticLayerType);
  }

  showZipCodeInfos(zipCode: string) {
    if (!this.selectedZipCode) {
      this.hoveredZipCode = zipCode;
      this.activeZipCode = zipCode;
      this.activeZipCodePreisAtlas = this.preisAtlasByZipCodes[zipCode];
    }
  }

  showSelectedZipCodeInfo(): void {
    if (this.selectedZipCode) {
      this.activeZipCode = this.selectedZipCode;
      this.activeZipCodePreisAtlas = this.preisAtlasByZipCodes[this.selectedZipCode];
    }
  }

  changedVisiblePriceAtlasFields(newFieldTypes: PriceAtlasFieldType[]): void {
    this.visiblePriceAtlasFields = newFieldTypes;

    // update user price altas settings
    let settings = this.priceAtlasService.getLastUsedMapSettings();
    settings = settings ? settings : new SavedPriceAtlasSettings();
    settings.visibleFields = newFieldTypes;
    this.priceAtlasService.saveLastUsedMapSettings(settings);
  }
}
