// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  misAccountEndpoint:  'https://api.propfox.io/account/api',
  misResearchEndpoint: 'https://api.propfox.io/research/api',
  misAnalyticEndpoint: 'https://api.propfox.io/analytic/api',
  misFlowFactEndpoint: 'https://api.propfox.io/flowfact/api',
  misCrawlerEndpoint:  'https://api.propfox.io/crawler/api',
  misCdnEndpoint:      'https://cdn.propfox.io',

  reCaptchaSiteKey:    '6LdPrXwUAAAAAEyAdDQBxyL9oCgVqUKqenyscTmD',
  reCaptchaSiteAction: 'homepage',

  paginationDefaultHitsPerPage: 50,

  maxFileUploadSizeBytes: 2097152, // 2MB
};
