<div class="mis-immo-analysis-by-filter {{analysisItemCss}}">

  <!--<mis-rating-label *ngIf="benchmark && benchmark.rating"-->
  <!--[rating]="benchmark.rating"></mis-rating-label>-->

  <mis-loading *ngIf="isLoading"></mis-loading>

  <div *ngIf="!isLoading && benchmark != null"
       class="mis-immo-analysis-chart">

    <mis-bullet-chart *ngFor="let benchmarkItem of preparedBenchmarks.values()"
                      [chartTitle]="benchmarkItem.title"
                      [min]="benchmarkItem.benchmark.min"
                      [max]="benchmarkItem.benchmark.max"
                      [percentile20]="benchmarkItem.benchmark.percentile20"
                      [median]="benchmarkItem.benchmark.median"
                      [mean]="benchmarkItem.benchmark.mean"
                      [percentile80]="benchmarkItem.benchmark.percentile80"
                      [target]="benchmarkItem.benchmark.actual"
                      [formatDecimalDigits]="benchmarkItem.fractionDigits"
                      [unit]="benchmarkItem.unit"></mis-bullet-chart>

  </div>

  <div class="mis-immo-analysis-empty"
       *ngIf="!isLoading && benchmark == null">

    <mis-higher-tariff-required-hint>
      Ihr aktuell gebuchtes Paket reicht nicht aus um diese Analyse durchzuführen. Bitte schränken Sie die Ergebnisliste über den Filter weiter ein.
    </mis-higher-tariff-required-hint>
  </div>
</div>
