<div class="mis-form-field-from-until-wrapper"
     [ngClass]="{'mis-form-field-from-until-has-error': hasErrors}"
     [formGroup]="formGroup">
  <div>
    <input formControlName="{{controlNamePrefix}}From"
           #fromInput
           name="{{controlNamePrefix}}From"
           placeholder="{{placeHolderFrom}}"
           autocomplete="off"
           (change)="changeFromInputValue($event)"
           (keydown)="keyDown($event, 'From')"
           (keyup)="keyUp($event, 'From')">

    <div class="mis-form-field-number-buttons">
      <div (click)="increase($event, 'From')"><i class="fas fa-plus"></i></div>
      <div (click)="decrease($event, 'From')"><i class="fas fa-minus"></i></div>
    </div>
  </div>
  <div>-</div>
  <div>
    <input formControlName="{{controlNamePrefix}}Until"
           #untilInput
           name="{{controlNamePrefix}}Until"
           placeholder="{{placeHolderUntil}}"
           autocomplete="off"
           (change)="changeUntilInputValue($event)"
           (keydown)="keyDown($event, 'Until')"
           (keyup)="keyUp($event, 'Until')"
           (blur)="triggerChangeEventIfPossible()">

    <div class="mis-form-field-number-buttons">
      <div (click)="increase($event, 'Until')"><i class="fas fa-plus"></i></div>
      <div (click)="decrease($event, 'Until')"><i class="fas fa-minus"></i></div>
    </div>
  </div>

  <div class="mis-form-field-from-until-error">
    <i class="fas fa-exclamation-circle"></i>

    <div class="mis-tooltip">
      <div class="mis-tooltip-content">Der Bis-Wert muss größer als der Ab-Wert sein.</div>
    </div>
  </div>
</div>
