import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TariffService} from '../../../../../services/aaa/tariff.service';
import {MisSubscription, TariffInfoItem} from '../../../../../services/aaa/model/tariff.model';
import {SubscriptionType} from '../../../../../services/aaa/model/account.model';

@Component({
  selector: 'mis-tariff-items',
  templateUrl: './tariff.items.component.html',
  styleUrls: ['./tariff.items.component.scss']
})
export class TariffItemsComponent implements OnInit {
  @Input() tariff: MisSubscription;
  @Output() selectedTariff = new EventEmitter<SubscriptionType>();

  tariffInfos: TariffInfoItem[];
  currentSelectedTariffKey: SubscriptionType;
  hasNextTariff: boolean;

  constructor(private tariffService: TariffService) {
    this.tariffInfos = tariffService.getAvailableTariffInfos();
  }

  ngOnInit(): void {
    this.prepareComponent();
  }

  selectTariff(tariff: TariffInfoItem): void {
    this.currentSelectedTariffKey = tariff.backendKey;
    this.selectedTariff.emit(this.currentSelectedTariffKey);
  }

  private prepareComponent() {
    this.hasNextTariff = (this.tariff && this.tariff.nextType != null);
  }
}
