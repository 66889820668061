<mat-radio-group>
  <div class="mis-filter-time-range-wrapper"
       [ngClass]="{'mis-filter-time-range-wrapper-mobile': (viewServiceInstance.isMobile() && !viewServiceInstance.isTablet())}">
    <div class="mis-row">

      <div class="mis-column-50">
        <h3>Relativ</h3>
        <div>
          <div class="mis-filter-time-range-category">
            <mat-radio-button value=""
                              [checked]="activeTimeRangeType == null"
                              (change)="selectAll()">
              Alle aktiven Immobilien anzeigen
            </mat-radio-button>
          </div>

          <div class="mis-filter-time-range-category">
            <mat-radio-button value="NEWER_THAN"
                              [checked]="activeTimeRangeType === 'NEWER_THAN'"
                              (change)="changedTimeRangeType($event)">
              Letzte
            </mat-radio-button>
            <div class="mis-filter-time-range-input-wrapper">
              <mis-form-field-dropdown [values]="newerThanAvailableValues"
                                       [selectedValues]="[newerThanValue]"
                                       (changed)="changedNewerThan($event)"></mis-form-field-dropdown>
            </div>
          </div>

          <div class="mis-filter-time-range-category">
            <mat-radio-button value="OLDER_THAN"
                              [checked]="activeTimeRangeType === 'OLDER_THAN'"
                              (change)="changedTimeRangeType($event)">
              Älter als
            </mat-radio-button>
            <div class="mis-filter-time-range-input-wrapper">
              <mis-form-field-dropdown [values]="olderThanAvailableValues"
                                       [selectedValues]="[olderThanValue]"
                                       (changed)="changedOlderThanValue($event)"></mis-form-field-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="mis-column-50">
        <h3>Absolut</h3>
        <div>
          <div class="mis-filter-time-range-category">
            <mat-radio-button value="EFFECTIVE_DAY"
                              [checked]="activeTimeRangeType === 'EFFECTIVE_DAY'"
                              (change)="changedTimeRangeType($event)">
              Stichtag
            </mat-radio-button>
            <div class="mis-filter-time-range-input-wrapper">
              <mis-form-field-datepicker [selectedDate]="effectiveDate"
                                         [minDate]="effectiveMinDate"
                                         [maxDate]="effectiveMaxDate"
                                         (changedDate)="changedEffectiveDate($event)"></mis-form-field-datepicker>
            </div>
          </div>

          <div class="mis-filter-time-range-category">
            <mat-radio-button value="TIME_RANGE"
                              [checked]="activeTimeRangeType === 'TIME_RANGE'"
                              (change)="changedTimeRangeType($event)">
              Zeitraum
            </mat-radio-button>

            <div class="mis-filter-time-range-multi-input-wrapper">
              <div class="mis-filter-time-range-input-wrapper">
                <mis-form-field-datepicker [selectedDate]="timeRangeMinDate"
                                           [maxDate]="timeRangeMaxDate"
                                           (changedDate)="changedTimeRangeFromDate($event)"></mis-form-field-datepicker>
              </div>
              <div class="mis-filter-time-range-input-wrapper">
                <span>bis</span>
                <mis-form-field-datepicker [selectedDate]="timeRangeMaxDate"
                                           [minDate]="timeRangeMinDate"
                                           [maxDate]="effectiveMaxDate"
                                           (changedDate)="changedTimeRangeToDate($event)"></mis-form-field-datepicker>
              </div>
            </div>
          </div>

          <div class="mis-filter-time-range-category">
            <mat-radio-button value="NEWER_SINCE"
                              [checked]="activeTimeRangeType === 'NEWER_SINCE'"
                              (change)="changedTimeRangeType($event)">
              Neu seit
            </mat-radio-button>
            <div class="mis-filter-time-range-multi-input-wrapper">
              <div class="mis-filter-time-range-input-wrapper">
                <mis-form-field-datepicker [selectedDate]="newerSinceDate"
                                           [maxDate]="newerSinceMaxDate"
                                           (changedDate)="changedNewerSinceDate($event)"></mis-form-field-datepicker>
              </div>
              <div class="mis-filter-time-range-input-wrapper">
                <mis-form-fields-datetime-picker [hours]="newerSinceDateTimeHours"
                                                 [minutes]="newerSinceDateTimeMinutes"
                                                 (changedValues)="changedNewerSinceDateTime($event)"></mis-form-fields-datetime-picker>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</mat-radio-group>
