import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {MisBaseUtils} from '../../../../../../services/common/base.util';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';

export class NumberColumnData extends AbstractImmoTableColumnData {
  constructor(public suffix: string = '', public fractionDigits: number = 2) {
    super();
  }
}

export class NumberColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: NumberColumnData;

  constructor(suffix: string, fractionDigits = 2) {
    super();
    this.component = ImmoTableColumnNumberComponent;
    this.data = new NumberColumnData(suffix, fractionDigits);
  }
}

@Component({
  selector: 'mis-immo-table-column-number',
  templateUrl: './immo.table.column.number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnNumberComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: NumberColumnData;

  numberValue: string;

  ngOnInit(): void {
    const suffix = this.additionalData && this.additionalData.suffix;

    const value = MisBaseUtils.formatNumber(this.getValue(this.researchItem, this.columnName), this.additionalData.fractionDigits);
    if (value === '-') {
      this.numberValue = value;
    } else {
      this.numberValue = MisBaseUtils.formatNumber(this.getValue(this.researchItem, this.columnName), this.additionalData.fractionDigits) + ' ' + suffix;
    }
  }
}
