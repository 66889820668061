<div class="mis-color-calculation-container">
  <div *ngFor="let colorStep of colorSteps">
    <span class="mis-color-box">
      <!--<span [ngStyle]="{'background-color': 'rgba(' + red + ',' + green + ',' + blue + ','+ ((colorStep) / 100) +')'}">&nbsp;</span>-->
      <span [ngStyle]="{'background-color': calcLighterAlphaColorAsHex(100 - colorStep) }">&nbsp;</span>
    </span>
    <span class="mis-color-text-box">{{100 - colorStep}} %</span>
    <span class="mis-color-text-hex">{{calcLighterAlphaColorAsHex(100 - colorStep)}}</span>
  </div>

  <div>
    <span class="mis-color-box">
      <span [ngStyle]="{'background-color': 'rgb(' + red + ',' + green + ',' + blue + ')'}">&nbsp;</span>
    </span>
    <span class="mis-color-text-box">Primary</span>
    <span class="mis-color-text-hex">#{{hexColor}}</span>
  </div>

  <div *ngFor="let colorStep of colorSteps">
    <!--<span class="mis-color-box mis-color-calculation-dark">
      <span [ngStyle]="{'background-color': 'rgba(' + red + ',' + green + ',' + blue + ','+ ((100 - colorStep) / 100) +')'}">&nbsp;</span>
      <span [ngStyle]="{'background-color': calcDarkerAlphaColorAsHex(colorStep) }">&nbsp;</span>
    </span>-->
    <span class="mis-color-box">
      <span [ngStyle]="{'background-color': calcDarkerAlphaColorAsHex(colorStep) }">&nbsp;</span>
    </span>
    <span class="mis-color-text-box">{{colorStep}} %</span>
    <span class="mis-color-text-hex">{{calcDarkerAlphaColorAsHex(colorStep)}}</span>
  </div>

</div>
