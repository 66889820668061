import {Component, Input} from '@angular/core';

@Component({
  selector: 'mis-empty-result-hint',
  templateUrl: './empty.result.hint.component.html',
  styleUrls: ['./empty.result.hint.component.scss']
})
export class EmptyResultHintComponent {
  @Input() icon = 'fas fa-search';
  @Input() size: 'normal' | 'small' = 'normal';
}
