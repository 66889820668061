import {Component, Input, OnInit} from '@angular/core';
import {FilterListItemExtendedFilterItem} from '../../../filter-pane/filter-extended-items/filter.extended.items.component';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {SavedSearch} from '../../../../../../services/research/saved.search.service';
import {FilterQueryReader} from '../../../filter-pane/filter.query.reader';
import {IMMO_EXTENDED_FILTERS_CONFIGURATIONS} from '../../../immo-table/immo-table-configuration/immo.table.filter.configuration';
import {
  ImmoTableEnumFilter,
  ImmoTableNumberMinMaxFilter
} from '../../../immo-table/immo-table-configuration/immo.table.classes';
import {MisBaseUtils} from '../../../../../../services/common/base.util';

@Component({
  selector:    'mis-saved-search-filters',
  templateUrl: './saved.search.filters.component.html',
  styleUrls:   ['./saved.search.filters.component.scss']
})
export class SavedSearchFiltersComponent implements OnInit {
  @Input() savedSearch: SavedSearch;

  propertyType: string;
  offerType: string;
  locations: string;

  extendedFilters: FilterListItemExtendedFilterItem[];

  ngOnInit(): void {
    this.prepareTitleAndDetails();
  }

  private prepareTitleAndDetails(): void {
    const querySpec = this.savedSearch.spec;

    this.propertyType = FilterQueryReader.getImmobilienTypDisplayName(querySpec);
    this.offerType = FilterQueryReader.getAngebotsTypDisplayName(querySpec);
    this.locations = FilterQueryReader.joinLocationFilters(querySpec);

    this.prepare();
  }

  private prepare(): void {
    const rootFilter = this.savedSearch.spec.root.combineList;
    const extendedFilters: QueryField[] = [];

    rootFilter.forEach((item) => {
      if (item.field !== 'latest') {
        // extended filters
        extendedFilters.push(item);
      }
    });

    this.convertExtendedFilters(extendedFilters);
  }

  private convertExtendedFilters(extendedFilters: QueryField[]): void {
    if (extendedFilters.length === 0) {
      return;
    }

    const sortedFilterItems: { [key: string]: FilterListItemExtendedFilterItem } = {};

    extendedFilters.forEach(extendedFilter => {

      IMMO_EXTENDED_FILTERS_CONFIGURATIONS
        .forEach(config => {
          if (extendedFilter.field === config.fieldName) {
            let preparedFilterItem = sortedFilterItems[config.fieldName];

            if (!preparedFilterItem) {
              preparedFilterItem = new FilterListItemExtendedFilterItem();
              preparedFilterItem.filterName = config.title;
              preparedFilterItem.fieldName = config.fieldName;
              preparedFilterItem.filterSettings = config;
              sortedFilterItems[config.fieldName] = preparedFilterItem;
            }

            preparedFilterItem.queryFields.push(extendedFilter);
          }
        });
    });

    this.extendedFilters = Object.values(sortedFilterItems);
  }

  buildEnumDisplayValue(extendedFilter: FilterListItemExtendedFilterItem) {
    const filterSettings: ImmoTableEnumFilter = extendedFilter.filterSettings as ImmoTableEnumFilter;

    const values = [];
    if (extendedFilter.queryFields[0].sValue) {
      values.push(extendedFilter.queryFields[0].sValue);
    } else if (extendedFilter.queryFields[0].sValueList) {
      extendedFilter.queryFields[0].sValueList.forEach(item => values.push(item));
    }

    const availableEnumValues = filterSettings.values;
    return values.map(value => availableEnumValues[value]);
  }

  buildMinMaxDisplayValue(extendedFilter: FilterListItemExtendedFilterItem) {
    const filterSettings: ImmoTableNumberMinMaxFilter = extendedFilter.filterSettings as ImmoTableNumberMinMaxFilter;

    const displayValues = extendedFilter.queryFields.map(value => MisBaseUtils.formatNumber(value.nValue, filterSettings.decimalDigits));
    return displayValues.join(' - ');
  }
}
