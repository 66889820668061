import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationProvider} from './authentication.provider';
import {RoutingService} from '../routing.service';
import {MisTagUtils, SubscriptionType} from "./model/account.model";

@Injectable()
export class AuthenticationGuard implements CanActivate {

  // ------------------------------------------------------------------------------------
  // Constructor
  constructor(private authenticationProvider: AuthenticationProvider,
              private routerService: RoutingService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.authenticationProvider.isAuthenticated()) {
      if (route) {
        this.routerService.setRouteAfterLogin(route);
      }

      this.routerService.navigate('login');
      return false;
    }


    if (this.hasRequiredSubscription(route.data['requiredSubscription'])
      && this.hasAccountPermission(route.data['requiredPermissionTag'])
      && this.hasRequiredUserRole(route.data['requiredRole'])) {
      return true;
    }

    return this.routerService.navigate('research');
  }

  private hasAccountPermission(requiredPermissionTag: string): boolean {
    if (!requiredPermissionTag) {
      return true;
    }

    const permissionTag = MisTagUtils.getTagByKey(this.authenticationProvider.getUser().account.tags, MisTagUtils.keys.permissionMarketReports);
    return permissionTag && permissionTag.value === 'true';
  }

  private hasRequiredSubscription(requiredSubscription: string): boolean {
    if (!requiredSubscription) {
      return true;
    }

    return this.authenticationProvider.authorizedFor(requiredSubscription as SubscriptionType);
  }

  private hasRequiredUserRole(requiredRole: string): boolean {

    if (!requiredRole) {
      return true;
    }

    const user = this.authenticationProvider.getUser();
    if (user && user.role === requiredRole) {
      return true;
    }

    return false;
  }
}
