export class CountryCode {
  constructor(public name: string, public code: string) {
  }
}

export const COUNTRY_CODES: CountryCode[] = [
  new CountryCode('Afghanistan', 'AF'),
  new CountryCode('Ägypten', 'EG'),
  new CountryCode('Albanien', 'AL'),
  new CountryCode('Algerien', 'DZ'),
  new CountryCode('Andorra', 'AD'),
  new CountryCode('Angola', 'AO'),
  new CountryCode('Anguilla', 'AI'),
  new CountryCode('Antarktis', 'AQ'),
  new CountryCode('Antigua und Barbuda', 'AG'),
  new CountryCode('Äquatorial Guinea', 'GQ'),
  new CountryCode('Argentinien', 'AR'),
  new CountryCode('Armenien', 'AM'),
  new CountryCode('Aruba', 'AW'),
  new CountryCode('Aserbaidschan', 'AZ'),
  new CountryCode('Äthiopien', 'ET'),
  new CountryCode('Australien', 'AU'),
  new CountryCode('Bahamas', 'BS'),
  new CountryCode('Bahrain', 'BH'),
  new CountryCode('Bangladesh', 'BD'),
  new CountryCode('Barbados', 'BB'),
  new CountryCode('Belgien', 'BE'),
  new CountryCode('Belize', 'BZ'),
  new CountryCode('Benin', 'BJ'),
  new CountryCode('Bermudas', 'BM'),
  new CountryCode('Bhutan', 'BT'),
  new CountryCode('Birma', 'MM'),
  new CountryCode('Bolivien', 'BO'),
  new CountryCode('Bosnien-Herzegowina', 'BA'),
  new CountryCode('Botswana', 'BW'),
  new CountryCode('Bouvet Inseln', 'BV'),
  new CountryCode('Brasilien', 'BR'),
  new CountryCode('Britisch-Indischer Ozean', 'IO'),
  new CountryCode('Brunei', 'BN'),
  new CountryCode('Bulgarien', 'BG'),
  new CountryCode('Burkina Faso', 'BF'),
  new CountryCode('Burundi', 'BI'),
  new CountryCode('Chile', 'CL'),
  new CountryCode('China', 'CN'),
  new CountryCode('Christmas Island', 'CX'),
  new CountryCode('Cook Inseln', 'CK'),
  new CountryCode('Costa Rica', 'CR'),
  new CountryCode('Dänemark', 'DK'),
  new CountryCode('Deutschland', 'DE'),
  new CountryCode('Djibuti', 'DJ'),
  new CountryCode('Dominika', 'DM'),
  new CountryCode('Dominikanische Republik', 'DO'),
  new CountryCode('Ecuador', 'EC'),
  new CountryCode('El Salvador', 'SV'),
  new CountryCode('Elfenbeinküste', 'CI'),
  new CountryCode('Eritrea', 'ER'),
  new CountryCode('Estland', 'EE'),
  new CountryCode('Falkland Inseln', 'FK'),
  new CountryCode('Färöer Inseln', 'FO'),
  new CountryCode('Fidschi', 'FJ'),
  new CountryCode('Finnland', 'FI'),
  new CountryCode('Frankreich', 'FR'),
  new CountryCode('französisch Guyana', 'GF'),
  new CountryCode('Französisch Polynesien', 'PF'),
  new CountryCode('Französisches Süd-Territorium', 'TF'),
  new CountryCode('Gabun', 'GA'),
  new CountryCode('Gambia', 'GM'),
  new CountryCode('Georgien', 'GE'),
  new CountryCode('Ghana', 'GH'),
  new CountryCode('Gibraltar', 'GI'),
  new CountryCode('Grenada', 'GD'),
  new CountryCode('Griechenland', 'GR'),
  new CountryCode('Grönland', 'GL'),
  new CountryCode('Großbritannien', 'UK'),
  new CountryCode('Großbritannien (UK)', 'GB'),
  new CountryCode('Guadeloupe', 'GP'),
  new CountryCode('Guam', 'GU'),
  new CountryCode('Guatemala', 'GT'),
  new CountryCode('Guinea', 'GN'),
  new CountryCode('Guinea Bissau', 'GW'),
  new CountryCode('Guyana', 'GY'),
  new CountryCode('Haiti', 'HT'),
  new CountryCode('Heard und McDonald Islands', 'HM'),
  new CountryCode('Honduras', 'HN'),
  new CountryCode('Hong Kong', 'HK'),
  new CountryCode('Indien', 'IN'),
  new CountryCode('Indonesien', 'ID'),
  new CountryCode('Irak', 'IQ'),
  new CountryCode('Iran', 'IR'),
  new CountryCode('Irland', 'IE'),
  new CountryCode('Island', 'IS'),
  new CountryCode('Israel', 'IL'),
  new CountryCode('Italien', 'IT'),
  new CountryCode('Jamaika', 'JM'),
  new CountryCode('Japan', 'JP'),
  new CountryCode('Jemen', 'YE'),
  new CountryCode('Jordanien', 'JO'),
  new CountryCode('Jugoslawien', 'YU'),
  new CountryCode('Kaiman Inseln', 'KY'),
  new CountryCode('Kambodscha', 'KH'),
  new CountryCode('Kamerun', 'CM'),
  new CountryCode('Kanada', 'CA'),
  new CountryCode('Kap Verde', 'CV'),
  new CountryCode('Kasachstan', 'KZ'),
  new CountryCode('Kenia', 'KE'),
  new CountryCode('Kirgisistan', 'KG'),
  new CountryCode('Kiribati', 'KI'),
  new CountryCode('Kokosinseln', 'CC'),
  new CountryCode('Kolumbien', 'CO'),
  new CountryCode('Komoren', 'KM'),
  new CountryCode('Kongo', 'CG'),
  new CountryCode('Kongo, Demokratische Republik', 'CD'),
  new CountryCode('Kroatien', 'HR'),
  new CountryCode('Kuba', 'CU'),
  new CountryCode('Kuwait', 'KW'),
  new CountryCode('Laos', 'LA'),
  new CountryCode('Lesotho', 'LS'),
  new CountryCode('Lettland', 'LV'),
  new CountryCode('Libanon', 'LB'),
  new CountryCode('Liberia', 'LR'),
  new CountryCode('Libyen', 'LY'),
  new CountryCode('Liechtenstein', 'LI'),
  new CountryCode('Litauen', 'LT'),
  new CountryCode('Luxemburg', 'LU'),
  new CountryCode('Macao', 'MO'),
  new CountryCode('Madagaskar', 'MG'),
  new CountryCode('Malawi', 'MW'),
  new CountryCode('Malaysia', 'MY'),
  new CountryCode('Malediven', 'MV'),
  new CountryCode('Mali', 'ML'),
  new CountryCode('Malta', 'MT'),
  new CountryCode('Marianen', 'MP'),
  new CountryCode('Marokko', 'MA'),
  new CountryCode('Marshall Inseln', 'MH'),
  new CountryCode('Martinique', 'MQ'),
  new CountryCode('Mauretanien', 'MR'),
  new CountryCode('Mauritius', 'MU'),
  new CountryCode('Mayotte', 'YT'),
  new CountryCode('Mazedonien', 'MK'),
  new CountryCode('Mexiko', 'MX'),
  new CountryCode('Mikronesien', 'FM'),
  new CountryCode('Mocambique', 'MZ'),
  new CountryCode('Moldavien', 'MD'),
  new CountryCode('Monaco', 'MC'),
  new CountryCode('Mongolei', 'MN'),
  new CountryCode('Montserrat', 'MS'),
  new CountryCode('Namibia', 'NA'),
  new CountryCode('Nauru', 'NR'),
  new CountryCode('Nepal', 'NP'),
  new CountryCode('Neukaledonien', 'NC'),
  new CountryCode('Neuseeland', 'NZ'),
  new CountryCode('Nicaragua', 'NI'),
  new CountryCode('Niederlande', 'NL'),
  new CountryCode('Niederländische Antillen', 'AN'),
  new CountryCode('Niger', 'NE'),
  new CountryCode('Nigeria', 'NG'),
  new CountryCode('Niue', 'NU'),
  new CountryCode('Nord Korea', 'KP'),
  new CountryCode('Norfolk Inseln', 'NF'),
  new CountryCode('Norwegen', 'NO'),
  new CountryCode('Oman', 'OM'),
  new CountryCode('Österreich', 'AT'),
  new CountryCode('Pakistan', 'PK'),
  new CountryCode('Palästina', 'PS'),
  new CountryCode('Palau', 'PW'),
  new CountryCode('Panama', 'PA'),
  new CountryCode('Papua Neuguinea', 'PG'),
  new CountryCode('Paraguay', 'PY'),
  new CountryCode('Peru', 'PE'),
  new CountryCode('Philippinen', 'PH'),
  new CountryCode('Pitcairn', 'PN'),
  new CountryCode('Polen', 'PL'),
  new CountryCode('Portugal', 'PT'),
  new CountryCode('Puerto Rico', 'PR'),
  new CountryCode('Qatar', 'QA'),
  new CountryCode('Reunion', 'RE'),
  new CountryCode('Ruanda', 'RW'),
  new CountryCode('Rumänien', 'RO'),
  new CountryCode('Russland', 'RU'),
  new CountryCode('Saint Lucia', 'LC'),
  new CountryCode('Sambia', 'ZM'),
  new CountryCode('Samoa', 'AS'),
  new CountryCode('Samoa', 'WS'),
  new CountryCode('San Marino', 'SM'),
  new CountryCode('Sao Tome', 'ST'),
  new CountryCode('Saudi Arabien', 'SA'),
  new CountryCode('Schweden', 'SE'),
  new CountryCode('Schweiz', 'CH'),
  new CountryCode('Senegal', 'SN'),
  new CountryCode('Seychellen', 'SC'),
  new CountryCode('Sierra Leone', 'SL'),
  new CountryCode('Singapur', 'SG'),
  new CountryCode('Slowakei -slowakische Republik-', 'SK'),
  new CountryCode('Slowenien', 'SI'),
  new CountryCode('Solomon Inseln', 'SB'),
  new CountryCode('Somalia', 'SO'),
  new CountryCode('South Georgia, South Sandwich Isl.', 'GS'),
  new CountryCode('Spanien', 'ES'),
  new CountryCode('Sri Lanka', 'LK'),
  new CountryCode('St. Helena', 'SH'),
  new CountryCode('St. Kitts Nevis Anguilla', 'KN'),
  new CountryCode('St. Pierre und Miquelon', 'PM'),
  new CountryCode('St. Vincent', 'VC'),
  new CountryCode('Süd Korea', 'KR'),
  new CountryCode('Südafrika', 'ZA'),
  new CountryCode('Sudan', 'SD'),
  new CountryCode('Surinam', 'SR'),
  new CountryCode('Svalbard und Jan Mayen Islands', 'SJ'),
  new CountryCode('Swasiland', 'SZ'),
  new CountryCode('Syrien', 'SY'),
  new CountryCode('Tadschikistan', 'TJ'),
  new CountryCode('Taiwan', 'TW'),
  new CountryCode('Tansania', 'TZ'),
  new CountryCode('Thailand', 'TH'),
  new CountryCode('Timor', 'TP'),
  new CountryCode('Togo', 'TG'),
  new CountryCode('Tokelau', 'TK'),
  new CountryCode('Tonga', 'TO'),
  new CountryCode('Trinidad Tobago', 'TT'),
  new CountryCode('Tschad', 'TD'),
  new CountryCode('Tschechische Republik', 'CZ'),
  new CountryCode('Tunesien', 'TN'),
  new CountryCode('Türkei', 'TR'),
  new CountryCode('Turkmenistan', 'TM'),
  new CountryCode('Turks und Kaikos Inseln', 'TC'),
  new CountryCode('Tuvalu', 'TV'),
  new CountryCode('Uganda', 'UG'),
  new CountryCode('Ukraine', 'UA'),
  new CountryCode('Ungarn', 'HU'),
  new CountryCode('Uruguay', 'UY'),
  new CountryCode('Usbekistan', 'UZ'),
  new CountryCode('Vanuatu', 'VU'),
  new CountryCode('Vatikan', 'VA'),
  new CountryCode('Venezuela', 'VE'),
  new CountryCode('Vereinigte Arabische Emirate', 'AE'),
  new CountryCode('Vereinigte Staaten von Amerika', 'US'),
  new CountryCode('Vietnam', 'VN'),
  new CountryCode('Virgin Island (Brit.)', 'VG'),
  new CountryCode('Virgin Island (USA)', 'VI'),
  new CountryCode('Wallis et Futuna', 'WF'),
  new CountryCode('Weissrussland', 'BY'),
  new CountryCode('Westsahara', 'EH'),
  new CountryCode('Zentralafrikanische Republik', 'CF'),
  new CountryCode('Zimbabwe', 'ZW'),
  new CountryCode('Zypern', 'CY')
];

export function getCountryByCode(countryCode: string): CountryCode {
  const numberOfCountries = COUNTRY_CODES.length;
  let o = 0;
  while (o < numberOfCountries) {
    if (COUNTRY_CODES[o].code === countryCode) {
      return COUNTRY_CODES[o];
    }
    o++;
  }
  return null;
}
