import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {UserService} from '../aaa/user.service';
import {IMMO_TABLE_COLUMN_CONFIGURATION} from '../../components/common/immo-table/immo-table/immo-table-configuration/immo.table.configuration';
import {MisTag, MisTagUtils} from '../aaa/model/account.model';
import {ImmoTableColumnConfiguration} from '../../components/common/immo-table/immo-table/immo-table-configuration/immo.table.classes';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class ColumnConfigurationService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService,
              private userService: UserService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadImmoTableColumnOrder(): ImmoTableColumnConfiguration[] {

    if (this.authenticationProvider.isAuthenticated()) {
      const storedFilterTag = MisTagUtils.getTagByKey(this.authenticationProvider.getUser().tags, MisTagUtils.keys.immolistColumnOrder);

      if (storedFilterTag != null) {
        let columnKeys: string[] = JSON.parse(storedFilterTag.value);
        columnKeys = columnKeys.filter(key => IMMO_TABLE_COLUMN_CONFIGURATION.filter(config => config.colKey === key).length > 0);
        return columnKeys.map(key => IMMO_TABLE_COLUMN_CONFIGURATION.filter(config => config.colKey === key)[0]);
      }
    }

    return IMMO_TABLE_COLUMN_CONFIGURATION;
  }

  saveImmoTableColumnOrder(columnConfigurations: ImmoTableColumnConfiguration[]): void {
    if (!this.authenticationProvider.isAuthenticated()) {
      return;
    }

    const user = this.authenticationProvider.getUser();
    const columnsKeys = columnConfigurations.map(config => config.colKey);

    const newTag = new MisTag();
    newTag.key = MisTagUtils.keys.immolistColumnOrder;
    newTag.value = JSON.stringify(columnsKeys);
    newTag.type = 'JSON';

    user.tags.push(newTag);
    this.userService.updateUserTag(user.id, newTag).subscribe(() => {
    }, () => {
    });
  }
}
