import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../viewservice/view.service';
import {AuthenticationProvider} from '../../../services/aaa/authentication.provider';
import {MapService} from '../../../services/map/map.service';
import {ZipCodeMapPlaceMark} from '../../../services/map/model/zip.code.map.place.mark.model';
import {GoogleMap} from '@angular/google-maps';
import {Polygon} from '../../../services/map/model/map.polygon.model';
import MapTypeId = google.maps.MapTypeId;
import {SubscriptionType} from '../../../services/aaa/model/account.model';

export class MapComponentMarker {
  constructor(public longitude: number, public latitude: number, public isExactPosition: boolean) {
  }
}

export class MapPolygonOptions implements google.maps.PolygonOptions {
  strokeColor = '#009EE3';
  strokeWeight = 0.5;
  fillColor = '#009EE3';
  fillOpacity = 0.2;
}

export class MapPolygon extends Polygon {
  id: string;
  options: google.maps.PolygonOptions = new MapPolygonOptions();
}

export class MapImageOverlay {
  bounds: google.maps.LatLngBounds;
  imgSrc: string;
  opacity: number = 0.7;
}

export class MapChangedEvent {
  bounds: google.maps.LatLngBounds;
  zoomLevel: number;
}

@Component({
  selector:    'mis-map',
  templateUrl: './map.component.html',
  styleUrls:   ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges, OnDestroy, ViewServiceChangeListener, AfterViewInit {
  @Input() gestureHandling: google.maps.GestureHandlingOptions = 'cooperative';
  @Input() disableDoubleClickZoom: boolean = false;
  @Input() height: number = 300;
  @Input() mapZoomLevel: number;
  @Input() markers: MapComponentMarker[];
  @Input() zipCode: string;
  @Input() mapCenter: google.maps.LatLngLiteral = {lat: 50.919529, lng: 10.432850}; // Center of germany

  @Input() imageOverlay: MapImageOverlay;
  @Input() polygons: MapPolygon[];
  @Input() minimalRequiredAccountSubscription: SubscriptionType = 'ESSENTIAL';

  @Output() mapZoomed = new EventEmitter<MapChangedEvent>();
  @Output() mapDragged = new EventEmitter<MapChangedEvent>();
  @Output() polygonClicked = new EventEmitter<MapPolygon>();
  @Output() polygonHovered = new EventEmitter<MapPolygon>();
  @Output() mapMouseout = new EventEmitter<void>();

  @ViewChild('mapContainer', {static: true}) mapContainer;
  @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

  zipCodeMapData: ZipCodeMapPlaceMark;
  zipCodePolygonOptions: google.maps.PolygonOptions = new MapPolygonOptions();

  mapMarkerOptions = {draggable: false};
  mapMarkerPositions: google.maps.LatLngLiteral[] = [];
  mapOptions: google.maps.MapOptions = {
    controlSize:       25,
    fullscreenControl: false,
    gestureHandling:   'cooperative',
    zoomControl:       false,
    streetViewControl: false,
    mapTypeId:         MapTypeId.ROADMAP,
    styles:            [
      // https://developers.google.com/maps/documentation/javascript/style-reference
      ///{elementType: 'geometry', stylers: [{color: '#f8f8f8'}]},
      // {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
      // {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
      // {
      //   featureType: 'administrative.locality',
      //   elementType: 'labels.text.fill',
      //   stylers:     [{color: '#d59563'}],
      // },
      // {
      //   featureType: 'poi',
      //   elementType: 'labels.text.fill',
      //   stylers:     [{color: '#d59563'}],
      // },
      {
        featureType: 'landscape',
        stylers:     [{color: '#F8F8F8'}],
      },
      {
        featureType: 'poi.park',
        stylers:     [{color: '#F8F8F8'}],
      },
      // {
      //   featureType: 'road',
      //   elementType: 'geometry',
      //   stylers:     [{color: '#FFFFFF'}],
      // },
      // {
      //   featureType: 'road',
      //   elementType: 'geometry.stroke',
      //   stylers:     [{color: '#DADCE0'}],
      // },
      // {
      //   featureType: 'road',
      //   elementType: 'labels.text.fill',
      //   stylers:     [{color: '#7B8489'}],
      // },
      // {
      //   featureType: 'road.highway',
      //   elementType: 'geometry',
      //   stylers:     [{color: '#FDE293'}],
      // },
      // {
      //   featureType: 'road.highway',
      //   elementType: 'geometry.stroke',
      //   stylers:     [{color: '#F9AB00'}],
      // },
      // {
      //   featureType: 'road.highway',
      //   elementType: 'labels.text.fill',
      //   stylers:     [{color: '#783B03'}],
      // },
      // {
      //   featureType: 'transit',
      //   elementType: 'geometry',
      //   stylers:     [{color: 'rgba(250,250,250,0)'}],
      // },
      // {
      //   featureType: 'transit.station',
      //   elementType: 'labels.text.fill',
      //   stylers:     [{color: '#d59563'}],
      // },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers:     [{color: '#d8eaff'}],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers:     [{color: '#57acff'}],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers:     [{color: '#57acff'}],
      },
    ]
  };
  mapZoom = 15;

  featureActivated: boolean = true;
  initialized: boolean;
  width: number;

  constructor(private authenticationProvider: AuthenticationProvider,
              private mapService: MapService) {
    ViewServiceInstance.listenOnResize(this);
  }

  ngOnInit(): void {
    this.featureActivated = this.authenticationProvider.authorizedFor(this.minimalRequiredAccountSubscription);

    if (!this.featureActivated) {
      return;
    }

    this.mapOptions.gestureHandling = this.gestureHandling;
    this.mapOptions.disableDoubleClickZoom = this.disableDoubleClickZoom;

    this.onResize();
    this.updateMapZoomLevel(this.mapZoomLevel);

    let hasExactPosition = false;
    // Set Map Markers
    this.markers?.forEach((marker) => {
      if (marker.isExactPosition) {
        this.addMarker(marker.longitude, marker.latitude);
        hasExactPosition = true;
      }
    });

    // Center Map if necessary
    if (this.markers && this.markers.length > 0 && this.markers[0].longitude && this.markers[0].latitude) {
      this.mapCenter = {
        lat: this.markers[0].latitude,
        lng: this.markers[0].longitude,
      };
      this.updateMapZoomLevel(15);
    }

    // Show Zip Code Area
    if (!hasExactPosition && this.zipCode) {
      this.mapService.getPolygonData(this.zipCode).subscribe((result) => {
        this.zipCodeMapData = result;
        this.mapCenter = this.zipCodeMapData.centerPosition;

        this.updateMapZoomLevel(13);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.mapContainer) {
      this.width = this.mapContainer.nativeElement.offsetWidth;
    }

    if (this.polygons?.length > 0) {
      const polys = this.polygons;
      this.polygons = [];

      setTimeout(() => {
        this.polygons = polys;
      }, 1);
    }
  }

  ngAfterViewInit(): void {
    if (!this.featureActivated) {
      return;
    }

    this.initialized = true;
    let done: boolean;

    this.map.boundsChanged.subscribe(() => {
      if (done) {
        return;
      }
      done = true;
      this.changedZoom();
    });
  }

  private updateMapZoomLevel(newZoomLevel: number): void {
    this.mapZoom = this.mapZoomLevel;

    if (!this.mapZoomLevel) {
      this.mapZoom = newZoomLevel;
    }

    if (!this.mapZoom) {
      this.mapZoom = 15;
    }
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    if (this.mapContainer) {
      this.width = this.mapContainer.nativeElement.offsetWidth;
    }
  }

  addMarker(longitude: number, latitude: number): void {
    this.mapMarkerPositions.push({
      lat: latitude,
      lng: longitude
    });
  }

  click(event: google.maps.MouseEvent | google.maps.IconMouseEvent) {
     //console.log(event.latLng.toJSON());
  }

  changedZoom(): void {
    if (this.map && this.initialized) {
      const zoomLevel = new MapChangedEvent();
      zoomLevel.zoomLevel = this.map.getZoom();
      zoomLevel.bounds = this.map.getBounds();
      this.mapZoomed.emit(zoomLevel);
    }
  }

  changedMapBounds() {
    if (this.map && this.initialized) {
      const zoomLevel = new MapChangedEvent();
      zoomLevel.zoomLevel = this.map.getZoom();
      zoomLevel.bounds = this.map.getBounds();
      this.mapDragged.emit(zoomLevel);
    }
  }

  polygonClick(polygon: MapPolygon): void {
    this.polygonClicked.emit(polygon);
  }

  polygonHover(polygon: MapPolygon) {
    this.polygonHovered.emit(polygon);
  }

  emitMapMouseout(): void {
    this.mapMouseout.emit();
  }
}

