import {TextColumn} from '../immo-table-column/immo-table-column-text/immo.table.column.text.component';
import {AbstractImmoTableColumn} from '../immo-table-column/immo.table.column.data';
import {AngebotsTyp, CombineOperation, FieldName, ImmobilienTyp} from '../../../../../services/research/model/research.filter.model';

export class ImmoTableColumnConfigurationRestriction {
  constructor(public sort: boolean) {
  }
}

/**
 * Immo Table configuration classes.
 */
export class ImmoTableColumnConfiguration {
  public colWidth = 0;
  public restrictions: ImmoTableColumnConfigurationRestriction;

  constructor(public colKey: string,
              public title: string,
              public icon: string,
              public columnName: string,
              public colSize: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl',
              public sortable: boolean,
              public renderingConfig: AbstractImmoTableColumn = new TextColumn()) {

    switch (colSize) {
      case 'xs':
        this.colWidth = 40;
        break;
      case 's':
        this.colWidth = 100;
        break;
      case 'm':
        this.colWidth = 150;
        break;
      case 'l':
        this.colWidth = 200;
        break;
      case 'xl':
        this.colWidth = 250;
        break;
      case 'xxl':
        this.colWidth = 350;
        break;
    }
  }
}

/**
 * Immo Table Filter configuration classes.
 */
export type ImmoTableFilterType = 'ENUM' | 'BOOLEAN' | 'TEXT' | 'NUMBER_MIN_MAX' | 'REFERENCE_ID' | 'OFFERER';

export abstract class AbstractImmoTableFilter {
  protected constructor(public fieldName: FieldName, public title: string, public type: ImmoTableFilterType) {
  }
}

export class ImmoTableEnumFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string, public values: { [key: string]: string }, public multiSelect: boolean = false, public listCombineOperator: CombineOperation = null) {
    super(fieldName, title, 'ENUM');
  }
}

export class ImmoTableTextFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string) {
    super(fieldName, title, 'TEXT');
  }
}

export class ImmoTableReferenceIdFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string) {
    super(fieldName, title, 'REFERENCE_ID');
  }
}

export class ImmoTableOffererFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string) {
    super(fieldName, title, 'OFFERER');
  }
}

export class ImmoTableNumberMinMaxFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string, public decimalDigits = 2, public steps = 1, public useThousandSeparator = true) {
    super(fieldName, title, 'NUMBER_MIN_MAX');
  }
}

export class ImmoTableBooleanFilter extends AbstractImmoTableFilter {
  constructor(public fieldName: FieldName, public title: string) {
    super(fieldName, title, 'BOOLEAN');
  }
}

export class ImmoTableFilterValueWithConditionalFilters extends AbstractImmoTableFilter {
  constructor(public immoTyp: ImmobilienTyp, public title: string, public conditionalFilters: AbstractImmoTableFilter[]) {
    super(null, title, null);
  }
}

export class OfferTypeFilters {
  constructor(public value: AngebotsTyp, public title: string, public filters: { [key: string]: ImmoTableFilterValueWithConditionalFilters }) {
  }
}

