<div class="mis-user-details"
     *ngIf="user != null">
  <div class="mis-row">
    <div class="mis-column-50">
      <h2>Nutzerdaten <a href="#" (click)="editUserData($event)">bearbeiten</a></h2>

      <mis-field-box label="Name">
        {{user.contact.salutation | misTranslateSalutation}}<br>
        {{user.contact.firstName}} {{user.contact.lastName}}
      </mis-field-box>

      <mis-field-box label="E-Mail-Adresse">
        {{user.contact.email}}
      </mis-field-box>

      <mis-field-box label="Rolle">
        {{user.role | misTranslateUserRole}}
      </mis-field-box>

      <mis-field-box label="Telefon" [isEmpty]="!user.contact.phone || user.contact.phone == ''">
        {{(!user.contact.phone || user.contact.phone == '') ? 'Noch nicht gesetzt' : user.contact.phone}}
      </mis-field-box>

      <mis-field-box label="Mobile" [isEmpty]="!user.contact.mobile || user.contact.mobile == ''">
        {{(!user.contact.mobile || user.contact.mobile == '') ? 'Noch nicht gesetzt' : user.contact.mobile}}
      </mis-field-box>

      <mis-field-box label="Passwort"
                     [editable]="true"
                     (edit)="openPasswordChangeModal()">
        *******
      </mis-field-box>

      <mis-field-box label="Newsletter"
                     *ngIf="showNewsletterInfo">
        <form *ngIf="!isLoading"
              [formGroup]="formGroup">
          <mat-checkbox formControlName="newsletter"
                        (change)="saveForm()">
            Ja, ich möchte über Neuigkeiten per E-Mail informiert werden.
          </mat-checkbox>
        </form>

        <mis-loading *ngIf="isLoading"
                     size="small"></mis-loading>
      </mis-field-box>

      <mis-field-box label="Allgemeine Geschäftsbedingungen und Datenschutz">
        Wurden am {{termAcceptanceDate | misFormatDate}} akzeptiert.
      </mis-field-box>

    </div>

    <div class="mis-column-50">
      <h2>Nutzereinstellungen</h2>

      <mis-field-box *ngIf="hasFlowFactData"
                     label="FlowFact Einstellungen"
                     [editable]="true"
                     (edit)="editFlowFactData()">
        Nutzername: {{getFlowFactUserName()}}<br>
        Passwort: *****
      </mis-field-box>

      <mis-empty-result-hint *ngIf="!hasFlowFactData"
                             icon="fas fa-cogs">In Ihrem Nutzerkonto sind noch keine weiteren Einstellungen verfügbar.</mis-empty-result-hint>
    </div>
  </div>
</div>
