<div class="mis-multi-bullet-chart"
     [ngStyle]="{'max-width': maxWidth + 'px'}">
  <canvas baseChart
          [width]="chartWidth"
          [height]="chartHeight"
          [datasets]="chartData.data"
          [labels]="chartData.labels"
          [options]="chartData.options"
          [colors]="chartData.colors"
          [legend]="chartLegend"
          [chartType]="chartType"
          [plugins]="chartPlugins">
  </canvas>
</div>
