import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../../services/aaa/authentication.service';

@Component({
  selector: 'mis-password-forgotten-step2',
  templateUrl: './password.forgotten.step2.component.html'
})
export class PasswordForgottenStep2Component {
  @Input() email: string;
  @Output() done = new EventEmitter<void>();

  passwordResetFormGroup: FormGroup;
  showLoading: boolean;
  showPasswordResetFailed: boolean;

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService) {
    this.passwordResetFormGroup = this.formBuilder.group({
      'verificationCode': ['', Validators.required]
    });
  }

  savePassword(): void {
    this.showPasswordResetFailed = false;

    if (!this.passwordResetFormGroup.valid) {
      return;
    }

    this.showLoading = true;
    const password = this.passwordResetFormGroup.get('password').value;
    const verificationCode =  this.passwordResetFormGroup.get('verificationCode').value;

    this.authenticationService.confirmNewPassword(this.email, verificationCode, password).subscribe(() => {
      this.done.emit(this.passwordResetFormGroup.get('password').value);

    }, (error) => {

      // error
      this.showLoading = false;
      this.showPasswordResetFailed = true;
    });
  }
}
