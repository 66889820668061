import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Type} from '@angular/core';
import {ImmoTableFilterEvent} from '../immo.table.column.item';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';
import {MisEventUtils} from '../../../../../../services/common/event.util';

export class SourceColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnSourceComponent;
  }
}

@Component({
  selector:    'mis-immo-table-column-source',
  templateUrl: './immo.table.column.source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnSourceComponent extends AbstractImmoTableColumnItem implements OnInit {
  filter = new EventEmitter<ImmoTableFilterEvent>();

  sourceLink: string;
  sourceLinkText: string;
  sourceLinkReference: string;

  ngOnInit(): void {
    const source: {
      name: string;
      active: boolean;
    } = this.getValue(this.researchItem, this.columnName);

    this.sourceLink = this.researchItem.link;
    this.sourceLinkText = source.name;
    this.sourceLinkReference = this.researchItem.id.externalId;
  }

  addToFilter(event): void {
    MisEventUtils.stopEvent(event);
    this.filter.emit(new ImmoTableFilterEvent('extId', this.sourceLinkReference, 'SET'));
  }
}
