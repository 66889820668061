import {Component, Input} from '@angular/core';

@Component({
  selector: 'mis-info-box',
  templateUrl: './info.box.component.html',
  styleUrls: ['./info.box.component.scss']
})
export class InfoBoxComponent {
  @Input() heading: string;
  @Input() icon = 'fas fa-exclamation';
}
