<div *ngIf="preisAtlas"
     class="price-atlas-details">
  <h3>
    Ergebnisse für PLZ {{zipCode}}

    <a href="#"
       (click)="openConfigurationModal($event)">
      <i class="fas fa-cogs"></i>
    </a>
  </h3>

  <div class="price-atlas-details-info">
    <ng-container *ngFor="let priceAtlasField of priceAtlasFields">
      <mis-quick-info-item *ngIf="isFieldVisible(priceAtlasField)"
                           [item]="buildQuickInfoItem(priceAtlasField)">
        {{getInfoItemValue(priceAtlasField)}}
      </mis-quick-info-item>
    </ng-container>
  </div>

  <ng-container *ngFor="let field of priceAtlasFields">
    <div *ngIf="isBulletChartAvailable(field)">
      <mis-bullet-chart [chartTitle]="field.title"
                        [min]="getPreisAtlasItem(field).min"
                        [max]="getPreisAtlasItem(field).max"
                        [chartMin]="getPreisAtlasItemChartMin(field)"
                        [chartMax]="getPreisAtlasItemChartMax(field)"
                        [percentile20]="getPreisAtlasItem(field).p20"
                        [median]="getPreisAtlasItem(field).median"
                        [mean]="getPreisAtlasItem(field).mean"
                        [percentile80]="getPreisAtlasItem(field).p80"
                        chartType="SIMPLE"
                        [maxWidthPx]="250"
                        [formatDecimalDigits]="field.getFractionsDigits(preisAtlasFilter.offerType === 'MIETE')"
                        [unit]="field.unit"></mis-bullet-chart>
    </div>
  </ng-container>

</div>
