<div class="mis-verification mis-column-boxed mis-center">
  <h1>Verifizierung Ihrer E-Mail-Adresse</h1>

  <p *ngIf="!failed && !resend">Bitte warten Sie eine kurzen Augenblick, wir verifizieren Ihre E-Mail-Adresse.</p>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <div class="mis-verification-failed">
    <p *ngIf="failed"
       class="mis-error">
      Die Verifizierung Ihre E-Mail-Adresse ist fehlgeschlagen. Bitte versuchen Sie den Link aus der Verifizierungsemail
      erneut zu öffnen.<br>
      Sollte dies erneut fehlschlagen, lassen Sie sich bitte eine neu Verifizierungsemail zukommen lassen und versuchen
      Sie es mit den Link in dieser E-Mail erneut.
    </p>

    <p *ngIf="resend">
      Wir haben soeben eine neue Verifizierungsemail an Ihre E-Mail-Adresse {{emailAddress}} gesendet.<br>
      Öffnen Sie bitte der Link aus dieser E-Mail.
    </p>

    <div class="mis-button-pane mis-buttons-left">
      <button (click)="cancelVerification()"
              class="mis-button-pane-back">
        Abbrechen
      </button>
      <button (click)="resendVerificationEmail()">
        <i class="fas fa-redo-alt"></i> Erneut senden
      </button>
    </div>
  </div>

</div>

