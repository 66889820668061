import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MarketReportLayout} from "../../../../../../services/market-report/model/market.report.customization.model";
import {MarketReportExportSubComponent} from "../market.report.export.component";
import {QuickInfoItem} from "../../../../../basic/quick-info-item/quick.info.item.component";
import {PriceAtlasField, PriceAtlasFields} from "../../../../price-atlas/price.atlas.configuration";
import {MisBaseUtils} from "../../../../../../services/common/base.util";
import {MarketReportData} from "../../../../../../services/market-report/model/market.report.model";
import {StatisticValues} from "../../../../../../services/price-altas/model/price.atlas.model";

@Component({
  selector: 'mis-market-report-city-short-report-page',
  templateUrl: './market.report.city.short.report.page.component.html',
  styleUrls: ['./market.report.city.short.report.page.component.scss']
})
export class MarketReportCityShortReportPageComponent extends MarketReportExportSubComponent implements OnChanges {
  @Input() marketReportLayout?: MarketReportLayout;
  @Input() marketReportData?: MarketReportData;
  @Output() loaded = new EventEmitter<void>();

  quickInfoFields = PriceAtlasFields.filter(field => {
    return field.type != 'rendite' &&
      field.type != 'faktor' &&
      field.type != 'kaufkraft' &&
      field.type != 'alter' &&
      field.type != 'anzahlJeEinwohner' &&
      field.type != 'anzahl'
  });

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.loaded.emit();
    }, 1000); // TODO
  }

  buildQuickInfoItem(priceAtlasField: PriceAtlasField): QuickInfoItem {
    return new QuickInfoItem(priceAtlasField.title,
      priceAtlasField.tooltip,
      priceAtlasField.icon);
  }

  getInfoItemValue(field: PriceAtlasField, isOfferTypeMiete: boolean): string {

    let quickInfoItem;
    if (isOfferTypeMiete) {
      quickInfoItem = this.marketReportData.quickInfoMiete[field.type];
    } else {
      quickInfoItem = this.marketReportData.quickInfoKauf[field.type];
    }
    let value;

    if (!quickInfoItem) {
      return null;
    } else if (quickInfoItem.hasOwnProperty('median')) {
      value = (quickInfoItem as StatisticValues).median;
    } else {
      value = quickInfoItem;
    }

    const digits = field.getFractionsDigits(isOfferTypeMiete);
    return MisBaseUtils.formatNumber(value, digits) + ' ' + field.unit;
  }

}

