import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {FromUntilEvent} from '../../../../../basic/form/form-fields-from-until/form.fields.from.until.component';
import {
  AbstractImmoTableFilter,
  ImmoTableNumberMinMaxFilter
} from '../../../immo-table/immo-table-configuration/immo.table.classes';

@Component({
  selector:    'mis-filter-extended-item-min-max',
  templateUrl: './filter.extended.item.min.max.component.html',
  styleUrls:   ['./filter.extended.item.min.max.component.scss']
})
export class FilterExtendedItemMinMaxComponent implements OnInit {
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<FromUntilEvent>();

  minValue: number;
  maxValue: number;
  steps = 1;
  decimalDigits = 2;
  useThousandSeparator: boolean = true;

  ngOnInit(): void {
    const minMaxSettings = this.settings as ImmoTableNumberMinMaxFilter;
    this.decimalDigits = minMaxSettings.decimalDigits;
    this.steps = minMaxSettings.steps;
    this.useThousandSeparator = minMaxSettings.useThousandSeparator;

    if (!this.queryFields || this.queryFields.length === 0) {
      return;
    }

    this.queryFields.forEach(queryField => {
      if (queryField.comparator === 'gthOrEq') {
        this.minValue = queryField.nValue;

      } else if (queryField.comparator === 'lthOrEq') {
        this.maxValue = queryField.nValue;
      }
    });
  }
}
