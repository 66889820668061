<div class="mis-bullet-chart mis-row mis-bullet-chart-type-{{chartType}}"
     (click)="toggleDetails($event)"
     [ngClass]="{'opened': showDetails}"
     title="Details {{showDetails ? 'ausblenden' : 'einblenden'}}">
  <div class="mis-column-25">
    <p>
      <a href="#"
         (click)="toggleDetails($event)"
         class="mis-bullet-chart-show-details-button"
         [ngClass]="{'opened': showDetails}"
         title="Diagrammdetails anzeigen / ausblenden">
        <i class="fas fa-chevron-down"></i>
      </a>
      {{chartTitle}}
    </p>
  </div>
  <div class="mis-column-75"
       [style.maxWidth]="maxWidthPx + 'px'">
    <div class="mis-bullet-chart-container-wrapper"
         #bulletWrapperContainer>
      <div class="mis-bullet-chart-container">

        <div class="mis-bullet-chart-wrapper"
             #bulletWrapper>

          <!-- Line -->
          <div class="mis-bullet-chart-line"
               [style.left]="minLeft"
               [style.right]="maxRight"
               [ngClass]="{'highLighted-min': highlightedField === 'min', 'highLighted-max': highlightedField === 'max'}"></div>

          <!-- Range -->
          <div *ngIf="!rangeOutOfChart"
               class="mis-bullet-chart-range"
               [style.left]="rangeLeft + 'px'"
               [style.right]="rangeRight + 'px'"
               [ngClass]="{'highLighted-p20': highlightedField  === 'percentile20', 'highLighted-p80': highlightedField  === 'percentile80'}">
            <div
              class="mis-bullet-chart-range-min-label mis-bullet-chart-label"
              (mouseover)="highlight('percentile20')"
              (mouseout)="unHighlight()"
              [ngClass]="{'highLighted': highlightedField  === 'percentile20'}"
              title="20% Perzentile: {{percentile20 | misNumberFormat: formatDecimalDigits}} {{unit}}">{{percentile20 | misNumberFormat: formatDecimalDigits}} {{unit}}</div>
            <div
              class="mis-bullet-chart-range-max-label mis-bullet-chart-label"
              (mouseover)="highlight('percentile80')"
              (mouseout)="unHighlight()"
              [ngClass]="{'highLighted': highlightedField === 'percentile80'}"
              title="80% Perzentile: {{percentile80 | misNumberFormat: formatDecimalDigits}} {{unit}}">{{percentile80 | misNumberFormat: formatDecimalDigits}} {{unit}}</div>

            <div *ngIf="rangeRightExceeded"
                 class="mis-bullet-chart-range-max-exceeded">
              <i class="fas fa-bacon"></i>
              <i class="fas fa-bacon"></i>
            </div>
          </div>
          <div *ngIf="rangeOutOfChart"
               class="mis-bullet-chart-range-out-of-chart">
            <i class="fas fa-angle-double-right"></i>
          </div>


          <!-- Target -->
          <div *ngIf="target && !rangeOutOfChart && chartType === 'DETAILED'"
               class="mis-bullet-chart-target"
               [style.left]="targetLeft"
               (mouseover)="highlight('target')"
               (mouseout)="unHighlight()"
               [ngClass]="{'highLighted': highlightedField === 'target'}">
            <i class="fas fa-arrow-down"></i>
            <div
              class="mis-bullet-chart-target-label mis-bullet-chart-label"
              (mouseover)="highlight('target')"
              (mouseout)="unHighlight()"
              [ngClass]="{'highLighted': highlightedField === 'target'}"
              title="Dieses Objekt: {{target | misNumberFormat: formatDecimalDigits}} {{unit}}">{{target | misNumberFormat: formatDecimalDigits}} {{unit}}</div>
          </div>

          <!-- Median -->
          <div *ngIf="!rangeOutOfChart"
               class="mis-bullet-chart-median"
               [ngClass]="{'highLighted': highlightedField === 'median'}"
               [style.left]="medianLeft"
               (mouseover)="highlight('median')"
               (mouseout)="unHighlight()"
               title="Median: {{median | misNumberFormat: formatDecimalDigits}} {{unit}}"></div>
        </div>

        <div *ngIf="chartType === 'DETAILED'"
             class="mis-bullet-chart-description-btn">
          <a href="#"
             (click)="toggleDescription($event)"
             title="Was sehe ich in diesem Diagramm?">
            <i class="fas fa-question-circle"></i>
          </a>
        </div>

        <div *ngIf="showDetails">
          <table>
            <tr *ngIf="target"
                (mouseover)="highlight('target')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'target'}">
              <td>Diese Immobilie</td>
              <td>{{target | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('min')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'min'}">
              <td>Kleinster Wert</td>
              <td>{{min | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('percentile20')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'percentile20'}">
              <td>20% Perzentile</td>
              <td>{{percentile20 | misNumberFormat}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('median')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'median'}">
              <td>Median</td>
              <td>{{median | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('mean')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'mean'}">
              <td>Mittelwert</td>
              <td>{{mean | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('percentile80')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'percentile80'}">
              <td>80% Perzentile</td>
              <td>{{percentile80 | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
            <tr (mouseover)="highlight('max')"
                (mouseout)="unHighlight()"
                [ngClass]="{'highlighted': highlightedField === 'max'}">
              <td>Größter Wert</td>
              <td>{{max | misNumberFormat: formatDecimalDigits}} {{unit}}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>
  <div class="mis-column-100">
    <div class="mis-bullet-chart-hint">
      <mis-hint *ngIf="showDescription"
                [closable]="true"
                (closed)="closeDescription()">
        <h3>Am konkreten Beispiel:</h3>
        <ul>
          <li *ngIf="target">
            Dieses Objekt hat {{descriptionTitle}} von
            <span (mouseover)="highlight('target')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'target'}">{{target | misNumberFormat}} {{unit}}</span>
            und liegt damit <b>{{overUnder}} Median</b> (<span (mouseover)="highlight('median')"
                                                               (mouseout)="unHighlight()"
                                                               [ngClass]="{'highlighted': highlightedField === 'median'}">{{median | misNumberFormat: formatDecimalDigits}} {{unit}}</span>).
          </li>
          <li>
            20% der Objekte liegen unterhalb von
            <span (mouseover)="highlight('percentile20')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'percentile20'}">{{percentile20 | misNumberFormat: formatDecimalDigits}} {{unit}}</span>
            und weitere 20% liegen oberhalb von
            <span (mouseover)="highlight('percentile80')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'percentile80'}">{{percentile80 | misNumberFormat: formatDecimalDigits}} {{unit}}</span>.
          </li>
          <li>
            Innerhalb der Treffermenge ist der kleinste gefundene Wert
            <span (mouseover)="highlight('min')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'min'}">{{min | misNumberFormat: formatDecimalDigits}} {{unit}}</span>,
            der Größte liegt bei
            <span (mouseover)="highlight('max')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'max'}">{{max | misNumberFormat: formatDecimalDigits}} {{unit}}</span>.
          </li>
          <li>
            Der Durchschnitt liegt bei
            <span (mouseover)="highlight('mean')"
                  (mouseout)="unHighlight()"
                  [ngClass]="{'highlighted': highlightedField === 'mean'}">{{mean | misNumberFormat: formatDecimalDigits}} {{unit}}</span>,
            wird im Diagramm jedoch bewusst nicht dargestellt, weil der Wert oft nicht aussagekräftig genug ist.
          </li>
        </ul>
      </mis-hint>

    </div>
  </div>
</div>
