import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MisTag, MisUser, MisUserCreate} from './model/account.model';
import {AbstractService, MisHeaders} from '../abstract.service';
import {AuthenticationProvider} from './authentication.provider';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Page} from '../research/model/page.model';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class UserService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  getUsers(): Observable<Page<MisUser>> {
    return this.GET<Page<MisUser>>(environment.misAccountEndpoint + '/user');
  }

  createUser(user: MisUserCreate): Observable<void> {
    return this.POST<void>(environment.misAccountEndpoint + '/user', user);
  }

  updateUser(user: MisUser): Observable<MisUser> {
    const updateUserModel = {
      role: user.role,
      type: user.contact.type,
      salutation: user.contact.salutation,
      firstName: user.contact.firstName,
      lastName: user.contact.lastName,
      phone: user.contact.phone,
      mobile: user.contact.mobile
    };

    return this.PUT<MisUser>(environment.misAccountEndpoint + '/user/' + user.id, updateUserModel);
  }

  addUserTag(userId: number, tag: MisTag) {
    return this.POST<MisUser>(environment.misAccountEndpoint + '/user/' + userId + '/tag/' + tag.key, tag);
  }

  updateUserTag(userId: number, tag: MisTag) {
    return this.POST<MisUser>(environment.misAccountEndpoint + '/user/' + userId + '/tag/' + tag.key, tag);
  }

  deleteUserTag(userId: number, tag: MisTag) {
    return this.DELETE<MisUser>(environment.misAccountEndpoint + '/user/' + userId + '/tag/' + tag.key);
  }

  deleteUsers(users: MisUser[]) {
    return new Observable(sub => {
      setTimeout(() => {
        sub.next();
      }, 700);
    });
  }

  getCurrentUser(): Observable<MisUser> {
    return this.GET<MisUser>(environment.misAccountEndpoint + '/user/' + this.authenticationProvider.getUser().id);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<void> {
    const headers = this.getHeaders()
      .set(MisHeaders.MIS_ACCESS_TOKEN, this.authenticationProvider.getAccessToken());

    return this.httpClient.post<void>(environment.misAccountEndpoint + '/password/change', {
      oldPassword, newPassword
    }, {
      headers: headers
    });
  }

  resendVerificationEmail(): Observable<void> {
    return this.POST(environment.misAccountEndpoint + '/user/request-verification-mail', null);
  }

  verifyEmail(verificationCode: string): Observable<void> {
    return this.POST(environment.misAccountEndpoint + '/user/verify/' + encodeURI(verificationCode), null);
  }

  hasNewsletter(): Observable<boolean> {
    return new Observable<boolean>(subscription => {
      this.GET(environment.misAccountEndpoint + '/newsletter').subscribe((newsletter) => {
        subscription.next(true);

      }, (err) => {
        subscription.next(false);
      });
    });
  }

  updateNewsletter(registerNewsletter: boolean): Observable<void> {
    if (registerNewsletter) {
      return this.PUT(environment.misAccountEndpoint + '/newsletter', null);
    } else {
      return this.DELETE(environment.misAccountEndpoint + '/newsletter');
    }
  }
}
