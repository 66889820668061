import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MarketReportLayout} from "../../../../../../services/market-report/model/market.report.customization.model";
import {MarketReportExportSubComponent} from "../market.report.export.component";
import {LineChart} from "../../../../../common/chart/line-chart/line.chart.component";
import {ChartOptions} from 'chart.js';
import {Color} from 'ng2-charts';
import {AnalysisLineChartData, MarketReportData} from "../../../../../../services/market-report/model/market.report.model";
import * as moment from 'moment';

@Component({
  selector: 'mis-market-report-overview-page',
  templateUrl: './market.report.overview.page.component.html',
  styleUrls: ['./market.report.overview.page.component.scss']
})
export class MarketReportOverviewPageComponent extends MarketReportExportSubComponent implements OnChanges {
  @Input() marketReportLayout?: MarketReportLayout;
  @Input() marketReportData?: MarketReportData;
  @Output() loaded = new EventEmitter<void>();

  cityComparisonChart: LineChart;
  showChart = false;
  private scaleFactor = 2;

  ngOnChanges(changes: SimpleChanges): void {
    this.buildCityComparisonChart();
  }

  private buildCityComparisonChart(): void {
    this.buildChart(this.marketReportData.cityComparison.chartData);

    setTimeout(() => {
      this.loaded.emit();
    }, 1000);
  }

  private buildChartColors(numberOfGraphs: number): Color[] {
    const chartColors: Color[] = [];
    const colors = [this.marketReportLayout.chartColor1, this.marketReportLayout.chartColor2, this.marketReportLayout.chartColor3];

    for (let i = 0; i < numberOfGraphs; i++) {
      chartColors.push(
        {
          borderColor: colors[i],
          borderWidth: 2,
          pointRadius: 0,
        },
      );
    }

    return chartColors;
  }

  private buildChart(analysisData: AnalysisLineChartData): void {
    this.cityComparisonChart = new LineChart();
    this.cityComparisonChart.colors = this.buildChartColors(Object.keys(analysisData.ySeries).length);

    // build price line chart
    analysisData.xSeries.forEach(xSeries => {
      const preparedLabel = [moment(xSeries, "YYYYMMDD").lang("de").format("MMM"), moment(xSeries, "YYYYMMDD").lang("de").format("YY")];
      this.cityComparisonChart.labels.push(preparedLabel);
    });

    Object.keys(analysisData.ySeries).forEach(ySeriesKey => {
      this.cityComparisonChart.data.push({
        label: ySeriesKey,
        fill: false,
        data: analysisData.ySeries[ySeriesKey],
        lineTension: 0, // avoid round curves
      });
    });

    this.setupChartOptions(this.cityComparisonChart, analysisData);
    this.showChart = true;
  }

  private setupChartOptions(chart: LineChart, analysisData: AnalysisLineChartData): void {
    let minValue = null;
    let maxValue = 0;

    Object.values(analysisData.ySeries).forEach(ySeriesValues => {
      ySeriesValues.forEach(item => {
        if (maxValue < item) {
          maxValue = item;
        }
        if (!minValue || minValue > item) {
          minValue = item;
        }
      });
    });

    const scale = this.getYAxisScale(maxValue - minValue, maxValue);

    const min = this.getYAxisMinValue(minValue, scale);
    const max = this.getYAxisMaxValue(maxValue, scale);

    chart.options = this.buildChartOptions(min, max, scale);
  }

  private getYAxisMinValue(minValue: number, scale: number): number {
    if (!minValue) {
      return 0;
    }
    const scaleMultiplicator = scale / this.scaleFactor;
    const minValueResult = (Math.floor(minValue / scaleMultiplicator) * scaleMultiplicator) - (2 * scale);

    if (minValueResult < 0) {
      return 0;
    } else {
      return minValueResult;
    }
  }

  private getYAxisMaxValue(maxValue: number, scale: number): number {
    const scaleMultiplicator = scale / this.scaleFactor;
    return (Math.ceil(maxValue / scaleMultiplicator) * scaleMultiplicator) + (2 * scale);
  }

  private getYAxisScale(minMaxDifference: number, maxValue): number {
    let scale = this.scaleFactor / 10;
    let calcValue = minMaxDifference;

    if (minMaxDifference > 0) {
      calcValue = minMaxDifference;
    } else if (maxValue > 0) {
      calcValue = maxValue;
    } else {
      return scale;
    }

    while (calcValue > 1.2) {
      calcValue = calcValue / 10;
      scale *= 10;
    }
    return scale;
  }

  private buildChartOptions(yAxisMin: number, yAxisMax: number, yAxisSteps: number): ChartOptions {
    return {
      responsive: true,
      showLines: true,
      spanGaps: false,
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: true,
            minTicksLimit: 12,
            maxTicksLimit: 12
          }
        }],
        yAxes: [{
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8,
            min: yAxisMin,
            max: yAxisMax,
            stepSize: yAxisSteps,
          },
          scaleLabel: {
            display: true,
            labelString: 'Kaufpreisfaktor'
          }
        }]
      },
      legend: {
        display: true,
        position: 'bottom'
      }
    };
  }
}
