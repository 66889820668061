<div class="mis-modal-medium mis-search-name-modal">
  <h2 mat-dialog-title>Suche speichern</h2>

  <mis-loading *ngIf="isLoading"></mis-loading>

  <form *ngIf="!isLoading"
        [formGroup]="formGroup"
        (submit)="saveForm()">
    <mat-dialog-content>
      <div>
        <p>Speichern Sie Ihrer Suche und vergeben Sie für die gespeicherte Suche einen Namen.</p>
        <mat-form-field>
          <input matInput
                 formControlName="filterName"
                 (keyup)="checkSearchName()"
                 name="filterName"
                 placeholder="Filtername"
                 maxlength="64"
                 required="required"
                 [autocomplete]="false">
        </mat-form-field>
      </div>

      <mis-hint *ngIf="showExistingWarning"
                type="WARN"
                icon="fas fa-exclamation-triangle">Es existiert bereits eine gespeicherte Suche mit dem
        Namen {{formGroup.get('filterName').value}}. Wenn Sie auf Speichern klicken, wird die bestehende Suche
        überschrieben.
      </mis-hint>

      <h3>Ihr Filter</h3>
      <mis-saved-search-filters [savedSearch]="savedSearch"></mis-saved-search-filters>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button *ngIf="showExistingWarning"
                class="mis-button-pane-next"
                type="submit">Überschreiben
        </button>
        <button *ngIf="!showExistingWarning"
                class="mis-button-pane-next"
                type="submit">Speichern
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
