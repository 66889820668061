<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h3>Käufermarkt</h3>

    <h4>Geografische Kaufpreisübersicht</h4>
    <mis-graphic-statistic [statistic]="marketReportData?.detailedInfoKauf?.geographicalStatistics">
    </mis-graphic-statistic>

    <div class="vertically-space"></div>

    <h4>Entwicklung Angebotskaufpreise</h4>
    <mis-multi-bullet-chart *ngIf="preisChart"
                            [chartData]="preisChart"
                            [chartWidth]="400"
                            [chartHeight]="200"
                            [maxWidth]="650"></mis-multi-bullet-chart>
  </div>
</div>

<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h4>Angebotskaufpreis nach Zimmeranzahl</h4>
    <mis-multi-bullet-chart *ngIf="preisJeZimmeranzahlChart"
                            [chartData]="preisJeZimmeranzahlChart"
                            [chartWidth]="400"
                            [chartHeight]="200"
                            [maxWidth]="650"></mis-multi-bullet-chart>

    <div class="vertically-space"></div>

    <h4>Wohnraumgrößen nach Zimmeranzahl</h4>
    <mis-multi-bullet-chart *ngIf="flaecheJeZimmeranzahlChart"
                            [chartData]="flaecheJeZimmeranzahlChart"
                            [chartWidth]="400"
                            [chartHeight]="200"
                            [maxWidth]="650"></mis-multi-bullet-chart>
  </div>
</div>
