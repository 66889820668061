import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {
  AbstractImmoTableFilter,
  ImmoTableEnumFilter
} from '../../../immo-table/immo-table-configuration/immo.table.classes';
import {FormFieldsDropdownValue} from '../../../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';

@Component({
  selector:    'mis-filter-extended-item-enum',
  templateUrl: './filter.extended.item.enum.component.html',
  styleUrls:   ['./filter.extended.item.enum.component.scss']
})
export class FilterExtendedItemEnumComponent implements OnInit {
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<string[]>();

  availableValues: { [key: string]: FormFieldsDropdownValue } = {};
  selectedValues: string[] = [];
  multiSelectEnabled: boolean;

  ngOnInit(): void {
    const enumSettings = this.settings as ImmoTableEnumFilter;
    Object.keys(enumSettings.values).forEach((enumItem) => {
      this.availableValues[enumItem] = new FormFieldsDropdownValue(enumSettings.values[enumItem]);
    });
    this.multiSelectEnabled = enumSettings.multiSelect;

    if (this.queryFields && this.queryFields.length > 0) {

      if (this.queryFields[0].sValue != null) {
        this.selectedValues.push(this.queryFields[0].sValue);
      } else if (this.queryFields[0].sValueList != null) {
        this.selectedValues = this.queryFields[0].sValueList;
      }
    }
  }

  changedValue(values: { [key: string]: FormFieldsDropdownValue }): void {
    this.changed.emit(Object.keys(values));
  }
}
