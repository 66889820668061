import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Page} from './model/page.model';
import {ResearchItem} from './model/research.item.model';
import {QuerySpec, SortField} from './model/research.filter.model';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {AbstractTableService} from './abstract.table.service';
import {ResearchItemId} from './model/research.item.id.model';
import {ResearchItemDetails} from './model/research.item.details.model';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class ResearchService extends AbstractTableService {

  readonly cityNameOptions: string[] = ['Berlin', 'Hamburg', 'München', 'Köln', 'Frankfurt am Main', 'Stuttgart', 'Düsseldorf', 'Dortmund', 'Essen', 'Leipzig', 'Bremen', 'Dresden', 'Hannover', 'Nürnberg', 'Duisburg', 'Bochum', 'Wuppertal', 'Bielefeld', 'Bonn', 'Münster', 'Karlsruhe', 'Mannheim', 'Augsburg', 'Wiesbaden', 'Gelsenkirchen', 'Mönchen­gladbach', 'Braunschweig', 'Kiel', 'Chemnitz', 'Aachen', 'Magdeburg', 'Halle (Saale)', 'Freiburg im Breisgau', 'Krefeld', 'Lübeck', 'Mainz', 'Oberhausen', 'Erfurt', 'Rostock', 'Kassel', 'Hagen', 'Saarbrücken', 'Hamm', 'Potsdam', 'Mülheim an der Ruhr', 'Ludwigshafen am Rhein', 'Oldenburg', 'Osnabrück', 'Leverkusen', 'Heidelberg', 'Solingen', 'Darmstadt', 'Herne', 'Neuss', 'Paderborn', 'Regensburg', 'Ingolstadt', 'Würzburg', 'Fürth', 'Offenbach am Main', 'Ulm', 'Wolfsburg', 'Heilbronn', 'Pforzheim', 'Göttingen', 'Bottrop', 'Reutlingen', 'Recklinghausen', 'Koblenz', 'Bremer­haven', 'Bergisch Gladbach', 'Remscheid', 'Jena', 'Erlangen', 'Trier', 'Moers', 'Salzgitter', 'Siegen', 'Hildesheim', 'Cottbus'];

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  getList(filter: QuerySpec, sort: SortField): Observable<Page<ResearchItem>> {
    const url = environment.misResearchEndpoint + '/' + filter.immobilienTyp + '/' + filter.angebotsTyp + '/result.json';

    filter.sort = sort != null ? [sort] : [{'field': 'preis', 'direction': 'asc'}];
    const params = new HttpParams()
      .set('query', this.buildFilterQueryString(filter));

    return this.GET<Page<ResearchItem>>(url, params);
  }

  getCityNames(searchString: string): Observable<string[]> {

    if (searchString == null || searchString.length < 2) {
      return new Observable<string[]>((subscriber) => {
        // FE items
        const filterValue = searchString.toLowerCase();
        const result = this.cityNameOptions.filter(option => option.toLowerCase().includes(filterValue));
        const resultSet = result.length > 5 ? result.slice(0, 5) : result;

        subscriber.next(resultSet);
        subscriber.complete();
      });
    }

    const params = new HttpParams().set('part', searchString);
    return this.GET(environment.misResearchEndpoint + '/orte.json', params);
  }

  getZipCodes(searchString: string): Observable<string[]> {

    if (searchString == null || searchString.length < 2) {
      return new Observable<string[]>((subscriber) => {
        subscriber.next([]);
        subscriber.complete();
      });
    }

    const params = new HttpParams().set('part', searchString);
    return this.GET(environment.misResearchEndpoint + '/postleitzahlen.json', params);
  }

  downloadAsCsv(filter: QuerySpec): Observable<Blob> {
    const url = environment.misResearchEndpoint + '/' + filter.immobilienTyp + '/' + filter.angebotsTyp + '/result.csv';
    const params = new HttpParams().set('query', JSON.stringify(filter));
    return this.GET<Blob>(url, params, 'blob', {'accept': 'text/csv'});
  }

  getDetails(id: ResearchItemId): Observable<ResearchItemDetails> {
    return this.GET<ResearchItemDetails>(environment.misCrawlerEndpoint + '/' + id.sourceName + '/' + id.externalId + '/details.json');
  }
}
