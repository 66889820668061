import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MisEventUtils} from "../../../../services/common/event.util";
import {MarketReportLayoutModalComponent} from "./market-report-layout-modal/market.report.layout.modal.component";

@Component({
  selector: 'mis-market-configuration-overview',
  templateUrl: './market-report-configuration-overview.component.html'
})
export class MarketReportConfigurationOverviewComponent {

  constructor(private dialog: MatDialog) {
  }

  customizeMarketReport(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.dialog.open(MarketReportLayoutModalComponent);
  }
}
