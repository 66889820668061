<div class="mis-tariff-item"
     [ngClass]="{'mis-tariff-item-favorite': tariffInfo.favorite}">
  <div class="mis-tariff-item-header">
    <h2>{{tariffInfo.title}}</h2>
    <p class="mis-tariff-item-header-description">{{tariffInfo.shortDescription}}</p>
    <div class="mis-tariff-item-header-badge" *ngIf="tariffInfo.favorite">Top</div>
  </div>

  <div class="mis-tariff-item-price">
    <div class="mis-tariff-item-price-container">
      <div class="mis-tariff-item-price-euro">{{tariffInfo.price.getCurrentEuroValue()}}</div>
      <div class="mis-tariff-item-price-cent-time">
        <p class="mis-tariff-item-price-cent">,{{tariffInfo.price.getCurrentCentValue()}} €</p>
        <p class="mis-tariff-item-price-time">/ Monat je Nutzer</p>
      </div>
      <div class="mis-tariff-item-old-price"
           *ngIf="tariffInfo.price.oldAmount">{{tariffInfo.price.oldAmount | misNumberFormat}} €
      </div>
    </div>
  </div>

  <div class="mis-tariff-item-button-pane" *ngIf="isLoading">
    <mis-loading size="small"></mis-loading>
  </div>

  <div class="mis-tariff-item-actual-tariff-pane" *ngIf="!isLoading">
    <!-- Actual used tariff info. -->
    <span *ngIf="isActualTariff">
      <i class="fas fa-check-double"></i> Aktueller Tarif<br>
      <span class="mis-tariff-item-tariff-period-of-validity"
            *ngIf="tariff && tariff.expirationDate">
        Restlaufzeit bis zum {{tariff.expirationDate | misFormatDate}}</span>
    </span>

    <!-- Next used tariff info. -->
    <span *ngIf="isNextTariff">
      <i class="fas fa-hourglass-half"></i> Künftiger Tarif<br>
      <span class="mis-tariff-item-tariff-period-of-validity"
            *ngIf="nextTariffValidFromDate">
        Gültig ab {{nextTariffValidFromDate | misFormatDate}}</span>
    </span>
  </div>

  <div class="mis-tariff-item-button-pane" *ngIf="!isLoading && !hasNextTariff">
    <!-- Button for select. -->
    <button *ngIf="!currentSelected && !isActualTariff"
            (click)="selectTariff.emit(tariffInfo)"
            title="Klicken Sie hier um diesen Tariff zu wählen.">Tarif wechseln
    </button>

    <!-- Current selected. -->
    <span *ngIf="currentSelected && !isActualTariff">
      <i class="fas fa-check"></i>Tarif wechseln
    </span>
  </div>

  <div class="mis-tariff-item-content">
    <ul>
      <li *ngFor="let contentItem of tariffInfo.content"><i class="fas fa-check-circle"></i>{{contentItem}}</li>
    </ul>
  </div>

</div>
