import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormFieldToggleItem, FormFieldToggleSelectedItem} from '../../../../basic/form/form-field-toggle/form-field-toggle.component';
import {PriceAtlasFields, PriceAtlasFieldType} from '../../price.atlas.configuration';
import {AuthenticationProvider} from '../../../../../services/aaa/authentication.provider';

@Component({
  selector:    'mis-price-atlas-map-toolbar',
  templateUrl: './price.atlas.map.toolbar.component.html',
})
export class PriceAtlasMapToolbarComponent implements OnChanges {
  @Input() analyticLayerType: PriceAtlasFieldType;
  @Input() availableAnalyticsLayerTypes: PriceAtlasFieldType[];
  @Output() changeAnalyticsLayerType = new EventEmitter<PriceAtlasFieldType>();

  private allAvailableAnalyticsLayerItems = {};
  availableAnalyticsLayerItems = {};
  selectedAnalyticsLayerItem: PriceAtlasFieldType = 'alter';

  constructor(authenticationProvider: AuthenticationProvider) {
    PriceAtlasFields.forEach(field => {
      if (field.hasMapToolbarAction) {
        this.allAvailableAnalyticsLayerItems[field.type] = new FormFieldToggleItem(field.title, field.tooltip, field.icon, null, !authenticationProvider.authorizedFor(field.minimalRequiredAccountSubscription()));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.availableAnalyticsLayerItems = {};

    this.availableAnalyticsLayerTypes.forEach((analyticsType) => {
      this.availableAnalyticsLayerItems[analyticsType] = this.allAvailableAnalyticsLayerItems[analyticsType];
    });

    this.selectedAnalyticsLayerItem = this.analyticLayerType;
  }

  triggerChangeAnalyticsLayerType(newLayerType: FormFieldToggleSelectedItem): void {
    if (newLayerType.key != this.selectedAnalyticsLayerItem) {
      this.selectedAnalyticsLayerItem = newLayerType.key as PriceAtlasFieldType;
      this.changeAnalyticsLayerType.emit(this.selectedAnalyticsLayerItem);
    }
  }

}
