<div class="quick-info-item"
     [ngClass]="{'inactive': !item.isEnabled}"
     [style]="{'width': width}"
     [title]="item.tooltip">

  <i [ngClass]="item.icon"></i>
  <div class="quick-info-item-title">{{item.title}}</div>

  <div *ngIf="item.isEnabled"
       class="quick-info-item-value">
    <ng-content></ng-content>
  </div>
</div>
