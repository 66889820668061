<p>Sie haben Ihre Passwort vergessen? Kein Problem, setzen Sie es einfach zurück! Geben Sie Ihre E-Mail-Adresse ein,
  anschließend senden wir Ihnen eine E-Mail mit einen Verifizierungcode zu. Mit diesem können Sie im nächste Schritt Ihr Passwort zurücksetzen.</p>

<form [formGroup]="passwordForgottenFormGroup"
      (submit)="requestVerificationCode()"
      *ngIf="!showLoading">

  <mat-form-field>
    <input matInput
           formControlName="email"
           name="email"
           type="email"
           placeholder="E-Mail"
           required="required">
  </mat-form-field>

  <mis-support-hint *ngIf="passwordResetFailed"></mis-support-hint>

  <button>Passwort zurücksetzen</button>
</form>

<mis-loading *ngIf="showLoading"></mis-loading>
