import {File} from "../../../components/basic/form/form-fields-file-upload/file.model";

export class MarketReportLayout {
  constructor(public logo: File | null,
              public introText: string,
              public introMainHeadingColor: string,
              public introSubHeadingColor: string,
              public mainHeadingColor: string,
              public subHeadingColor: string,
              public textColor: string,
              public chartColor1: string,
              public chartColor2: string,
              public chartColor3: string) {
  }
}
