import {Injectable} from '@angular/core';
import {MisTagUtils, MisUser, MisUserWithAccount, SubscriptionType} from './model/account.model';
import {LocalStorageService} from '../local.storage.service';

export class AuthenticationTokenHolder {
  tokenExpirationDate: Date;

  constructor(public jwt: string, public refreshToken: string, public accessToken: string, public expiresInSeconds: number) {
    // TODO calculate expiration date
  }
}

@Injectable({providedIn: 'root'})
export class AuthenticationProvider {
  private isLoggedInStatus: boolean;
  private authenticatedUser: MisUserWithAccount;
  private subscribeUserChangeCallback = [];
  private subscribeChallengesRequiredCallback = [];
  private tokenHolder: AuthenticationTokenHolder;

  private provisionalUserAuthentication: {
    user: MisUserWithAccount,
    tokenHolder: AuthenticationTokenHolder
  } = null;

  constructor(private localStorageService: LocalStorageService) {
    const tokenFromStorage = this.localStorageService.get<AuthenticationTokenHolder>('MIS-TOKEN');
    if (tokenFromStorage != null) {
      this.tokenHolder = tokenFromStorage;
      this.isLoggedInStatus = true;

      const userFromStorage = this.localStorageService.get<MisUserWithAccount>('MIS-USER');
      if (userFromStorage != null) {
        this.authenticatedUser = userFromStorage;
      }
    }
  }

  isAuthenticated(): boolean {
    return this.isLoggedInStatus;
  }

  subscribeUserChanges(callback): void {
    this.subscribeUserChangeCallback.push(callback);
  }

  subscribeChallengesRequired(callback): void {
    this.subscribeChallengesRequiredCallback.push(callback);
  }

  successLoginDone(user: MisUserWithAccount, tokenHolder: AuthenticationTokenHolder): void {
    this.provisionalUserAuthentication = {user, tokenHolder};

    if (this.isChallengeNecessary(user)) {
      this.subscribeChallengesRequiredCallback.forEach((callback) => {
        callback(user);
      });
      return;
    }

    this.provisionalUserAuthentication = null;
    this.isLoggedInStatus = true;
    this.authenticatedUser = user;
    this.localStorageService.set('MIS-USER', user);

    this.updateToken(tokenHolder);
    this.triggerUserChangeCallback();
  }

  private isChallengeNecessary(user: MisUserWithAccount): boolean {
    const termsAcceptanceNecessary = this.isTermsAcceptanceNecessary();
    const emailVerificationNecessary = this.isEmailVerificationNecessary();

    return termsAcceptanceNecessary || emailVerificationNecessary;
  }

  isProvisionalAuthenticated() {
    return this.provisionalUserAuthentication != null;
  }

  isTermsAcceptanceNecessary(): boolean {
    const termsAcceptanceDateTag = MisTagUtils.getTagByKey(this.provisionalUserAuthentication.user.tags, MisTagUtils.keys.termsAcceptanceDate);
    return !termsAcceptanceDateTag || !termsAcceptanceDateTag.value;
  }

  isEmailVerificationNecessary(): boolean {
    const emailVerificationPending = MisTagUtils.getTagByKey(this.provisionalUserAuthentication.user.tags, MisTagUtils.keys.emailVerificationPending);
    return emailVerificationPending != null && emailVerificationPending.value === true;
  }

  confirmProvisionalLogin(): void {
    this.isLoggedInStatus = true;
    this.authenticatedUser = this.provisionalUserAuthentication.user;
    this.localStorageService.set('MIS-USER', this.provisionalUserAuthentication.user);
    this.updateToken(this.provisionalUserAuthentication.tokenHolder);

    this.provisionalUserAuthentication = null;
  }

  triggerUserChangeCallback(): void {
    this.subscribeUserChangeCallback.forEach((callback) => {
      callback(this.authenticatedUser);
    });
  }

  private updateToken(newToken: AuthenticationTokenHolder): void {
    if (this.tokenHolder != null) {
      // refreshtoken will be not refreshed! That's why we use the old one.
      newToken.refreshToken = this.tokenHolder.refreshToken;
    }

    this.tokenHolder = newToken;
    this.localStorageService.set('MIS-TOKEN', newToken);
  }

  getJwtToken(): string {
    return this.tokenHolder == null ? null : this.tokenHolder.jwt;
  }

  getRefreshToken(): string {
    return this.tokenHolder == null ? null : this.tokenHolder.refreshToken;
  }

  getAccessToken(): string {
    return this.tokenHolder == null ? null : this.tokenHolder.accessToken;
  }

  successLogoutDone() {
    this.isLoggedInStatus = false;
    this.authenticatedUser = null;
    this.provisionalUserAuthentication = null;

    this.tokenHolder = null;
    this.localStorageService.remove('MIS-TOKEN');
    this.localStorageService.remove('MIS-USER');

    this.subscribeUserChangeCallback.forEach((callback) => {
      callback(null);
    });
  }

  getUser() {
    return this.authenticatedUser;
  }

  refreshUserData(user: MisUser): void {
    if (user.id === this.authenticatedUser.id) {
      this.authenticatedUser.role = user.role;
      this.localStorageService.set('MIS-USER', this.authenticatedUser);
    }
  }

  authorizedFor(subscription: SubscriptionType): boolean {
    if (!this.authenticatedUser) {
      return false;
    }
    if (!subscription) {
      return true;
    }

    const subRange: {[key: string]: SubscriptionType[]} = {
      'FREE' : ['FREE'],
      'ESSENTIAL' : ['FREE', 'ESSENTIAL'],
      'ADVANCED' : ['FREE', 'ESSENTIAL', 'ADVANCED'],
      'UNLIMITED' : ['FREE', 'ESSENTIAL', 'ADVANCED', 'UNLIMITED']
    };

    const userSubscription = this.authenticatedUser.account.subscription;
    return !!subRange[userSubscription].find(s => s === subscription);
  }
}
