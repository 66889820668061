<div class="mis-immo-analysis-by-object">
  <mis-loading *ngIf="isLoading"></mis-loading>

  <div *ngIf="!isLoading && analysis"
       class="mis-immo-analysis-chart">

    <p>
      Anzahl der Vergleichsobjekte: {{objectCount}}
    </p>

    <mis-bullet-chart *ngIf="benchmarkPricePerLivingSpace"
                      chartTitle="Preis/m²"
                      [min]="benchmarkPricePerLivingSpace.min"
                      [max]="benchmarkPricePerLivingSpace.max"
                      [percentile20]="benchmarkPricePerLivingSpace.percentile20"
                      [median]="benchmarkPricePerLivingSpace.median"
                      [mean]="benchmarkPricePerLivingSpace.mean"
                      [percentile80]="benchmarkPricePerLivingSpace.percentile80"
                      [target]="benchmarkPricePerLivingSpace.actual"
                      unit="€/m²"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkPrice"
                      chartTitle="Preis"
                      [formatDecimalDigits]="0"
                      [min]="benchmarkPrice.min"
                      [max]="benchmarkPrice.max"
                      [percentile20]="benchmarkPrice.percentile20"
                      [median]="benchmarkPrice.median"
                      [mean]="benchmarkPrice.mean"
                      [percentile80]="benchmarkPrice.percentile80"
                      [target]="benchmarkPrice.actual"
                      unit="€"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkLivingSpace"
                      chartTitle="Wohnfläche"
                      [formatDecimalDigits]="1"
                      [min]="benchmarkLivingSpace.min"
                      [max]="benchmarkLivingSpace.max"
                      [percentile20]="benchmarkLivingSpace.percentile20"
                      [median]="benchmarkLivingSpace.median"
                      [mean]="benchmarkLivingSpace.mean"
                      [percentile80]="benchmarkLivingSpace.percentile80"
                      [target]="benchmarkLivingSpace.actual"
                      unit="m²"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkUtilities"
                      chartTitle="Nebenkosten"
                      [min]="benchmarkUtilities.min"
                      [max]="benchmarkUtilities.max"
                      [percentile20]="benchmarkUtilities.percentile20"
                      [median]="benchmarkUtilities.median"
                      [mean]="benchmarkUtilities.mean"
                      [percentile80]="benchmarkUtilities.percentile80"
                      [target]="benchmarkUtilities.actual"
                      unit="€"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkRoomNumber"
                      chartTitle="Zimmer"
                      [formatDecimalDigits]="1"
                      [min]="benchmarkRoomNumber.min"
                      [max]="benchmarkRoomNumber.max"
                      [percentile20]="benchmarkRoomNumber.percentile20"
                      [median]="benchmarkRoomNumber.median"
                      [mean]="benchmarkRoomNumber.mean"
                      [percentile80]="benchmarkRoomNumber.percentile80"
                      [target]="benchmarkRoomNumber.actual"
                      unit=""></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkPropertySize"
                      chartTitle="Grundstücksfläche"
                      [formatDecimalDigits]="0"
                      [min]="benchmarkPropertySize.min"
                      [max]="benchmarkPropertySize.max"
                      [percentile20]="benchmarkPropertySize.percentile20"
                      [median]="benchmarkPropertySize.median"
                      [mean]="benchmarkPropertySize.mean"
                      [percentile80]="benchmarkPropertySize.percentile80"
                      [target]="benchmarkPropertySize.actual"
                      unit="m²"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkAge"
                      chartTitle="Inseratsalter"
                      [formatDecimalDigits]="0"
                      [min]="benchmarkAge.min"
                      [max]="benchmarkAge.max"
                      [percentile20]="benchmarkAge.percentile20"
                      [median]="benchmarkAge.median"
                      [mean]="benchmarkAge.mean"
                      [percentile80]="benchmarkAge.percentile80"
                      [target]="benchmarkAge.actual"
                      unit="Tag(e)"></mis-bullet-chart>

    <mis-bullet-chart *ngIf="benchmarkPricePerPropertySize"
                      chartTitle="Preis/m² (Grundstücksfläche)"
                      [min]="benchmarkPricePerPropertySize.min"
                      [max]="benchmarkPricePerPropertySize.max"
                      [percentile20]="benchmarkPricePerPropertySize.percentile20"
                      [median]="benchmarkPricePerPropertySize.median"
                      [mean]="benchmarkPricePerPropertySize.mean"
                      [percentile80]="benchmarkPricePerPropertySize.percentile80"
                      [target]="benchmarkPricePerPropertySize.actual"
                      unit="€/m²"></mis-bullet-chart>
  </div>

  <div *ngIf="!isLoading && analysis == null"
       class="mis-immo-analysis-empty">

    <mis-higher-tariff-required-hint>
      Ihr aktuell gebuchtes Paket reicht nicht aus um diese Analyse durchzuführen. Bitte schränken Sie die Ergebnisliste über den Filter weiter ein.
    </mis-higher-tariff-required-hint>
  </div>
</div>
