import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractFormComponent} from '../../../../basic/form/abstract.form.component';
import {FormBuilder, Validators} from '@angular/forms';
import {COUNTRY_CODES, CountryCode} from '../../../../../services/common/country.codes.util';
import {TariffInfoItem} from '../../../../../services/aaa/model/tariff.model';
import {TariffService} from '../../../../../services/aaa/tariff.service';
import {AccountService} from '../../../../../services/aaa/account.service';
import {MisAccount, MisContact, SubscriptionType} from '../../../../../services/aaa/model/account.model';
import * as _ from 'lodash';
import {MisBaseUtils} from '../../../../../services/common/base.util';
import {Observable} from 'rxjs';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';

@Component({
  selector: 'mis-tariff-change',
  templateUrl: './tariff.change.component.html',
})
export class TariffChangeComponent extends AbstractFormComponent implements OnInit {
  @Input() selectedTariff: SubscriptionType;
  @Output() cancelTariffChange = new EventEmitter<void>();

  changeStep: number = 1;
  tariffInfo: TariffInfoItem;
  countries: CountryCode[];
  account: MisAccount;
  billingContact: MisContact;
  tariffChangeFailed: boolean;

  constructor(formBuilder: FormBuilder,
              private tariffService: TariffService,
              private accountService: AccountService,
              private snackBar: SnackbarService) {
    super();
    this.countries = COUNTRY_CODES;

    this.formGroup = formBuilder.group({
      'companyName': [''],
      'salutation': [''],
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': [''],
      'phone': [''],
      'address': ['', Validators.required],
      'zipCode': ['', Validators.required],
      'city': ['', Validators.required],
      'countryCode': ['DE', Validators.required],
      'vatId': [''],
    });
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.tariffInfo = this.tariffService
      .getAvailableTariffInfos()
      .filter((tariff) => tariff.backendKey === this.selectedTariff)[0];

    this.accountService.getAccount().subscribe(account => {
      this.account = account;

      const billingContacts = account.contacts.filter(contact => _.includes(contact.type, 'BILLING'));
      if (billingContacts.length > 0) {
        this.billingContact = billingContacts[0];
      }

      this.fillForm();
      this.isLoading = false;
    });
  }

  private fillForm(): void {
    this.formGroup.get('companyName').setValue(this.account.companyName);
    this.formGroup.get('vatId').setValue(this.account.vatId);
    this.formGroup.get('address').setValue(this.account.address);
    this.formGroup.get('zipCode').setValue(this.account.zipCode);
    this.formGroup.get('city').setValue(this.account.city);
    this.formGroup.get('countryCode').setValue(this.account.countryCode);
    this.formGroup.get('countryCode').setValue(this.account.countryCode);

    if (this.billingContact) {
      this.formGroup.get('salutation').setValue(this.billingContact.salutation);
      this.formGroup.get('firstName').setValue(this.billingContact.firstName);
      this.formGroup.get('lastName').setValue(this.billingContact.lastName);
      this.formGroup.get('email').setValue(this.billingContact.email);
      this.formGroup.get('phone').setValue(this.billingContact.phone);
    }
  }

  customSaveAction(): void {
    this.isLoading = false;
    this.changeStep = 2;
  }

  changeTariff() {
    this.changeStep = 3;
    this.isLoading = true;

    this.updateAccount().subscribe(() => {
      this.tariffService.updateSubscription(this.selectedTariff).subscribe((newTariff) => {
        this.isLoading = false;
        this.snackBar.success('Der Tarif wurde erfolgreich gewechselt.');

      }, (error) => {
        this.changeFailed();
      });

    }, (err) => {
      this.changeFailed();
    });
  }

  private changeFailed(): void {
    this.tariffChangeFailed = true;
    this.isLoading = false;
    this.snackBar.error('Der Tarifwechsel ist fehlgeschlagen.');
  }

  private updateAccount(): Observable<MisAccount> {
    this.account.companyName = this.formGroup.get('companyName').value;
    this.account.address = this.formGroup.get('address').value;
    this.account.zipCode = this.formGroup.get('zipCode').value;
    this.account.city = this.formGroup.get('city').value;
    this.account.countryCode = this.formGroup.get('countryCode').value;
    this.account.vatId = MisBaseUtils.getValueOrDefault(this.formGroup.get('vatId').value, null);

    if (!this.billingContact) {
      this.billingContact = new MisContact();
      this.account.contacts.push(this.billingContact);
    }

    this.billingContact.salutation = MisBaseUtils.getValueOrDefault(this.formGroup.get('salutation').value, null);
    this.billingContact.firstName = this.formGroup.get('firstName').value;
    this.billingContact.lastName = this.formGroup.get('lastName').value;
    this.billingContact.email = this.formGroup.get('email').value;
    this.billingContact.phone = MisBaseUtils.getValueOrDefault(this.formGroup.get('phone').value, null);

    return this.accountService.updateAccount(this.account);
  }

  redirectToResearch(): void {
    // TODO
  }
}

