import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MisEventUtils} from '../../../../services/common/event.util';

export class FormFieldToggleItem {
  constructor(public value: string,
              public hintText: string,
              public iconClass: string = null,
              public additional: string = null,
              public disabled: boolean = false) {
  }

  static ofValue(value: string, additional: string = null, disabled: boolean = false): FormFieldToggleItem {
    return new FormFieldToggleItem(value, value, null, additional, disabled);
  }
}

export class FormFieldToggleSelectedItem {
  constructor(public key: string,
              public item: FormFieldToggleItem) {
  }
}

export type FormFieldToggleDirection = 'HORIZONTAL' | 'VERTICAL';

@Component({
  selector:    'mis-form-field-toggle',
  templateUrl: './form-field-toggle.component.html',
  styleUrls:   ['./form-field-toggle.component.scss']
})
export class FormFieldToggleComponent {
  @Input() items: { [key: string]: FormFieldToggleItem };
  @Input() activeItemKey: string;
  @Input() direction: FormFieldToggleDirection = 'HORIZONTAL';
  @Input() disabled: boolean = false;
  @Output() toggleItem = new EventEmitter<FormFieldToggleSelectedItem>();

  objectKeys = Object.keys;

  toggle(event: Event, selectedItemKey: string): void {
    MisEventUtils.stopEvent(event);
    if (this.disabled) {
      return;
    }

    const selectedField = this.items[selectedItemKey];
    if (selectedField.disabled) {
      return;
    }

    this.activeItemKey = selectedItemKey;
    const selectedItem = new FormFieldToggleSelectedItem(selectedItemKey, selectedField);
    this.toggleItem.emit(selectedItem);
  }
}
