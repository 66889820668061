
export class MarketReportCity {
  city: string;
  cityDescription: string;
}

export class MarketReportConfiguration {
  id: string;
  city: string;
  comparisonCities: string[];
  cityDescription: string;
  downloadable: boolean;
}
