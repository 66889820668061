export class File {
  base64: string;
  name: string;
  type: string;

  constructor(base64: string = null) {
    if (!base64) {
      return;
    }

    try {
      this.base64 = base64;
      this.type = base64.split(new RegExp('data:(.*);', 'g'))[1];

    } catch (e) {
      console.warn('The given string is not an base64 decoded file.');
    }
  }
}
