import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {ResearchItem} from './model/research.item.model';
import {environment} from '../../../environments/environment';
import {ResearchItemId} from './model/research.item.id.model';
import {WatchlistItem} from './model/watchlist.item.model';
import {QuerySpec, SortField} from './model/research.filter.model';
import {Page} from './model/page.model';
import {EventKey, EventService} from '../event.service';
import {AbstractTableService} from './abstract.table.service';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class WatchlistService extends AbstractTableService {

  private watchlistItems: WatchlistItem[] = [];
  private initialized: boolean;
  private initializationStarted: boolean;

  private listeners = [];

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService,
              private eventService: EventService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  isItemWatched(researchItemId: ResearchItemId): Observable<boolean> {
    return new Observable<boolean>(subscription => {

      if (this.initialized) {
        subscription.next(this.isItemOnWatchList(researchItemId));
        subscription.complete();
        subscription.unsubscribe();
        return;
      }

      if (this.initializationStarted) {
        this.listeners.push(() => {
          subscription.next(this.isItemOnWatchList(researchItemId));
          subscription.complete();
          subscription.unsubscribe();
        });
        return;
      }

      this.loadWatchItems().subscribe(() => {
        subscription.next(this.isItemOnWatchList(researchItemId));
        subscription.complete();
        subscription.unsubscribe();
      }, () => {
        subscription.next(false);
        subscription.complete();
        subscription.unsubscribe();
      });
    });
  }

  private isItemOnWatchList(researchItemId: ResearchItemId): boolean {
    const found = this.watchlistItems.find(item =>
      item.id.externalId === researchItemId.externalId &&
      item.id.sourceName === researchItemId.sourceName
    );

    return !!found;
  }

  loadWatchItems(): Observable<WatchlistItem[]> {
    if (this.initializationStarted) {
      return;
    }
    this.initializationStarted = true;

    return new Observable<WatchlistItem[]>(subscription => {
      this.GET<WatchlistItem[]>(environment.misResearchEndpoint + '/watchlist').subscribe(watchlistItems => {
        this.watchlistItems = watchlistItems;
        subscription.next(watchlistItems);
        this.initialized = true;

        this.listeners.forEach(listener => listener());
        this.listeners = [];

        this.eventService.broadcast(EventKey.WATCHLIST_CHANGED, this.watchlistItems.length);
      });
    });
  }

  updateWatchState(researchItemId: ResearchItemId, watchState: boolean): Observable<void> {
    if (watchState) {
      const watchListItem = new WatchlistItem();
      watchListItem.id = researchItemId;
      this.watchlistItems.push(watchListItem);

      this.eventService.broadcast(EventKey.WATCHLIST_CHANGED, this.watchlistItems.length);
      return this.POST(environment.misResearchEndpoint + '/watchlist/' + researchItemId.sourceName + '/' + researchItemId.externalId, null);

    } else {
      this.watchlistItems = this.watchlistItems.filter(watchListItem => {
        return !(watchListItem.id.externalId === researchItemId.externalId &&
          watchListItem.id.sourceName === researchItemId.sourceName);
      });

      this.eventService.broadcast(EventKey.WATCHLIST_CHANGED, this.watchlistItems.length);
      return this.DELETE(environment.misResearchEndpoint + '/watchlist/' + researchItemId.sourceName + '/' + researchItemId.externalId);
    }
  }

  getList(filter: QuerySpec, sort: SortField): Observable<Page<ResearchItem>> {
    const url = environment.misResearchEndpoint + '/watchlist/immobilien';

    filter.sort = sort != null ? [sort] : [{'field': 'preis', 'direction': 'asc'}];
    const params = new HttpParams()
      .set('query', this.buildFilterQueryString(filter));

    return this.GET<Page<ResearchItem>>(url, params);
  }
}
