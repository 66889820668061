import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ResearchService} from '../../../../services/research/research.service';
import {AngebotsTyp, ImmobilienTyp, QueryField, QuerySpec} from '../../../../services/research/model/research.filter.model';
import {ImmoOverviewViewType} from '../immo.overview.component';
import {FilterBuilder, FilterDateRangeEvent} from './filter.builder';
import * as _ from 'lodash';
import {FormFieldsDropdownLoadAutocompleteValues, FormFieldsDropdownValue} from '../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';
import {MisEventUtils} from '../../../../services/common/event.util';
import {FilterQueryReader} from './filter.query.reader';
import {NEWER_THAN_VALUES, OLDER_THAN_VALUES} from './filter-time-range/filter.time.range.component';
import {MisDateUtil} from '../../../../services/common/date.util';
import {AbstractImmoTableFilter} from '../immo-table/immo-table-configuration/immo.table.classes';
import {IMMO_EXTENDED_FILTERS, IMMO_OFFER_FILTERS} from '../immo-table/immo-table-configuration/immo.table.filter.configuration';
import {GuidedTourStep} from '../../guided-tour/guided.tour.service';
import {ViewServiceInstance} from '../../../../viewservice/view.service';
import {Location} from '@angular/common';
import {AuthenticationProvider} from "../../../../services/aaa/authentication.provider";
import {MisTag, MisTagUtils} from "../../../../services/aaa/model/account.model";

@Component({
  selector: 'mis-filter-pane',
  templateUrl: './filter.pane.component.html',
  styleUrls: ['./filter.pane.component.scss']
})
export class FilterPaneComponent implements OnInit, OnChanges {
  @Input() filterQuery: QuerySpec = null;
  @Input() viewType: ImmoOverviewViewType;
  @Input() pageTotalHits: number;
  @Output() applyFilter = new EventEmitter<QuerySpec>();
  @Output() changedFilterPaneHeight = new EventEmitter<number>();
  @ViewChild('filterDetailPane', {static: true}) filterDetailPane: ElementRef<HTMLElement>;
  @ViewChild('filterTimeRangePane', {static: true}) filterTimeRangePane: ElementRef<HTMLElement>;

  guidedTourStep = GuidedTourStep;
  viewServiceInstance = ViewServiceInstance;

  selectedCities: string[] = [];
  selectedZipCodes: string[] = [];
  restrictedZipCodes: string[] = [];
  selectedOfferType: AngebotsTyp = 'KAUF';
  selectedPropertyType: ImmobilienTyp = 'WOHNUNG';

  availableOfferTypes: { [key: string]: FormFieldsDropdownValue } = {};
  availablePropertyTypes: { [key: string]: FormFieldsDropdownValue } = {};
  availableExtendedFilters: AbstractImmoTableFilter[] = [];

  filterDateRange: FilterDateRangeEvent;
  filterDateRangeDescription: string;
  filterDateRangeDescriptionShort: string;
  extendedFilterQueryFields: QueryField[] = [];
  showDetailFilterPane: boolean;

  showOnlyActiveProperties = false;
  showOnlyActivePropertiesFilterDisabled = false;

  constructor(private location: Location,
              private researchService: ResearchService,
              private authenticationProvider: AuthenticationProvider) {
    this.prepareZipCodeRestriction();

    IMMO_OFFER_FILTERS.forEach(offerFilter => this.availableOfferTypes[offerFilter.value] = new FormFieldsDropdownValue(offerFilter.title));

    this.updatePropertyType();
    this.updateExtendedFilters();
  }

  ngOnInit(): void {
    if (this.filterQuery != null) {
      this.fillFilterPaneFields(this.filterQuery);
    }
    this.emitUpdateFilterPaneHeightEvent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filterQuery != null) {
      this.fillFilterPaneFields(this.filterQuery);
    }
  }

  // #############################################################################
  // auto complete city & zip code
  loadDropdownCityValues(autocompleteContext: FormFieldsDropdownLoadAutocompleteValues) {
    this.researchService.getCityNames(autocompleteContext.searchString).subscribe((cityNames: string[]) => {
      const values: { [key: string]: FormFieldsDropdownValue } = {};
      cityNames.forEach(val => values[val] = new FormFieldsDropdownValue(val));

      autocompleteContext.subscriber.next(values);
      autocompleteContext.subscriber.complete();
    }, () => {
      autocompleteContext.subscriber.next({});
      autocompleteContext.subscriber.complete();
    });
  }

  loadDropdownZipCodeValues(autocompleteContext: FormFieldsDropdownLoadAutocompleteValues) {
    if (this.restrictedZipCodes.length > 0) {
      // Wenn die eingegebene Postleitzahl nicht in der Restricted Liste ist, ist das Suchergebnis leer.
      if (!this.isZipCodeAvailableForUser(autocompleteContext.searchString)) {
        autocompleteContext.subscriber.next({});
        autocompleteContext.subscriber.complete();
        return;
      }
    }

    this.researchService.getZipCodes(autocompleteContext.searchString).subscribe((zipCodes: string[]) => {
      const values: { [key: string]: FormFieldsDropdownValue } = {};
      zipCodes.forEach(val => values[val] = new FormFieldsDropdownValue(val));

      autocompleteContext.subscriber.next(values);
      autocompleteContext.subscriber.complete();
    }, () => {
      autocompleteContext.subscriber.next({});
      autocompleteContext.subscriber.complete();
    });
  }

  private isZipCodeAvailableForUser(zipCodeToCheck: string): boolean {
    return this.restrictedZipCodes.filter(zipCode => {
      if (zipCode.length > zipCodeToCheck.length) {
        return false;
      }

      const searchZipCodeBase = zipCodeToCheck.substr(0, zipCode.length);
      return searchZipCodeBase === zipCode;
    }).length > 0;
  }

  changedCities(values: { [key: string]: FormFieldsDropdownValue }): void {
    this.selectedCities = Object.keys(values);
    this.triggerApplyFilter();
  }

  changedZipCodes(values: { [key: string]: FormFieldsDropdownValue }): void {
    if (this.restrictedZipCodes.length > 0) {
      const zipCodes = Object.keys(values);
      this.selectedZipCodes = zipCodes.filter(zipCode => this.isZipCodeAvailableForUser(zipCode));
    } else {
      this.selectedZipCodes = Object.keys(values);
    }

    this.triggerApplyFilter();
  }

  // #############################################################################
  // apply filter
  triggerApplyFilter(): void {
    // disable filtering for immo management
    this.filterQuery = this.buildFilterQuery();
    this.applyFilter.emit(this.filterQuery);
  }

  private buildFilterQuery(): QuerySpec {
    return new FilterBuilder()
      .withOfferType(this.selectedOfferType)
      .withPropertyType(this.selectedPropertyType)
      .withDateRange(this.filterDateRange)
      .withCities(this.selectedCities)
      .withZipCodes(this.selectedZipCodes)
      .withExtendsFilters(this.extendedFilterQueryFields)
      .withShowOnlyActive(this.showOnlyActiveProperties)
      .build();
  }

  private prepareZipCodeRestriction(): void {
    const restrictedZipCodesTag = MisTagUtils.getTagByKey(this.authenticationProvider.getUser().account.tags, MisTagUtils.keys.filterRestrictedZipCodes);

    if (restrictedZipCodesTag) {
      this.restrictedZipCodes = (restrictedZipCodesTag.value as string).split(',');
    }
  }

  // #############################################################################
  // fill filter pane fields
  private fillFilterPaneFields(filter: QuerySpec): void {

    // fill immo type und angebotstyp
    this.selectedOfferType = filter.angebotsTyp;
    this.selectedPropertyType = filter.immobilienTyp;

    // fill Ort und PLZ
    const rootFilter = filter.root.combineList;
    this.selectedZipCodes = [];
    this.selectedCities = [];
    this.extendedFilterQueryFields = [];

    rootFilter.forEach((filterItem) => {
      if (filterItem.field === 'ort') {
        filterItem.sValueList.forEach(value => this.selectedCities.push(value));

      } else if (filterItem.field === 'postleitzahl') {
        filterItem.sValueList.forEach(value => this.selectedZipCodes.push(value));

      } else {
        this.extendedFilterQueryFields.push(filterItem);
      }
    });

    this.updatePropertyType();
    this.updateExtendedFilters();

    this.filterDateRange = FilterQueryReader.getFilterDateRangeEvent(this.filterQuery);
    this.initShowOnlyActiveProperties();

    this.emitUpdateFilterPaneHeightEvent();
  }

  updateExtendedFilter(extendedQueryFields: QueryField[]) {
    this.extendedFilterQueryFields = extendedQueryFields;
    this.triggerApplyFilter();
  }

  private updateExtendedFilters(): void {
    this.availableExtendedFilters = [];
    const filters = IMMO_OFFER_FILTERS.find(value => value.value === this.selectedOfferType);
    const propertyFilters = filters.filters[this.selectedPropertyType];
    if (propertyFilters != null) {
      this.availableExtendedFilters = propertyFilters.conditionalFilters;
    }
  }

  resetFilter() {
    this.selectedCities = [];
    this.selectedZipCodes = [];
    this.extendedFilterQueryFields = [];
    this.filterDateRange = null;
    this.showOnlyActiveProperties = true;
    this.showOnlyActivePropertiesFilterDisabled = true;

    this.location.go('/' + this.viewType.toLowerCase().replace('_', '-'));

    this.emitUpdateFilterPaneHeightEvent();
    this.triggerApplyFilter();
  }

  changedOfferType(selectedValues: { [key: string]: FormFieldsDropdownValue }) {
    this.selectedOfferType = <AngebotsTyp>Object.keys(selectedValues)[0];

    this.updatePropertyType();
    this.resetExtendedFilters();
    this.updateExtendedFilters();
    this.triggerApplyFilter();
  }

  changedProperty(selectedValues: { [key: string]: FormFieldsDropdownValue }) {
    this.selectedPropertyType = <ImmobilienTyp>Object.keys(selectedValues)[0];

    this.updateExtendedFilters();
    this.triggerApplyFilter();
  }

  private updatePropertyType(): void {
    const filters = IMMO_OFFER_FILTERS.find(value => value.value === this.selectedOfferType);
    this.availablePropertyTypes = {};
    for (let key in filters.filters) {
      this.availablePropertyTypes[key] = new FormFieldsDropdownValue(filters.filters[key].title);
    }

    const oldPropertyType = this.selectedPropertyType;
    if (!this.availablePropertyTypes.hasOwnProperty(oldPropertyType)) {
      this.selectedPropertyType = 'WOHNUNG';
    }
  }

  private resetExtendedFilters(): void {
    if (!this.extendedFilterQueryFields) {
      return;
    }

    const filtersToReset = [
      IMMO_EXTENDED_FILTERS.MIETE.fieldName,
      IMMO_EXTENDED_FILTERS.MIETE_JE_QM.fieldName,
      IMMO_EXTENDED_FILTERS.KAUFPREIS.fieldName,
      IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM.fieldName
    ];

    this.extendedFilterQueryFields = this.extendedFilterQueryFields.filter(filter => {
      const isResetFilter = _.includes(filtersToReset, filter.field);
      return !isResetFilter;
    });
  }

  openDetailFilterPane(event) {
    MisEventUtils.stopEvent(event);

    this.showDetailFilterPane = true;
    this.emitUpdateFilterPaneHeightEvent();
  }

  closeDetailFilterPane(event) {
    MisEventUtils.stopEvent(event);

    this.showDetailFilterPane = false;
    this.emitUpdateFilterPaneHeightEvent();
  }

  toggleDetailFilterPane(event: Event): void {
    MisEventUtils.stopEvent(event);

    this.showDetailFilterPane = !this.showDetailFilterPane;
    this.emitUpdateFilterPaneHeightEvent();
  }

  emitUpdateFilterPaneHeightEvent() {
    let newHeight = 90;

    if (this.showDetailFilterPane) {
      newHeight += this.filterDetailPane.nativeElement.offsetHeight;
      newHeight += this.filterTimeRangePane.nativeElement.offsetHeight;
    }

    this.changedFilterPaneHeight.emit(newHeight);
  }

  updateDateRange(dateRange: FilterDateRangeEvent) {
    this.filterDateRange = dateRange;
    this.initShowOnlyActiveProperties();

    this.triggerApplyFilter();
  }

  initShowOnlyActiveProperties(): void {
    if (!this.filterDateRange) {
      this.showOnlyActiveProperties = true;
      this.showOnlyActivePropertiesFilterDisabled = true;
      this.filterDateRangeDescription = 'Zeitraum ändern';
      this.filterDateRangeDescriptionShort = this.filterDateRangeDescription;
      return;
    }

    if (this.filterDateRange.type === 'OLDER_THAN') {
      this.showOnlyActiveProperties = true;
      this.showOnlyActivePropertiesFilterDisabled = true;

    } else if (this.filterDateRange.type === 'EFFECTIVE_DAY' || this.filterDateRange.type === 'TIME_RANGE') {
      this.showOnlyActiveProperties = false;
      this.showOnlyActivePropertiesFilterDisabled = true;

    } else {
      this.showOnlyActiveProperties = false;
      this.showOnlyActivePropertiesFilterDisabled = false;
    }

    switch (this.filterDateRange.type) {
      case 'OLDER_THAN':
        this.filterDateRangeDescription = 'Älter als ' + OLDER_THAN_VALUES[this.filterDateRange.typeDefinitions];
        this.filterDateRangeDescriptionShort = this.filterDateRangeDescription;
        break;

      case 'NEWER_THAN':
        this.filterDateRangeDescription = 'Neuer als ' + NEWER_THAN_VALUES[this.filterDateRange.typeDefinitions];
        this.filterDateRangeDescriptionShort = this.filterDateRangeDescription;
        break;

      case 'NEWER_SINCE':
        this.filterDateRangeDescription = 'Neu seit ' + MisDateUtil.formatDate(this.filterDateRange.fodStart);
        this.filterDateRangeDescriptionShort = this.filterDateRangeDescription;
        break;

      case 'EFFECTIVE_DAY':
        this.filterDateRangeDescription = 'Stichtag ' + MisDateUtil.formatDate(this.filterDateRange.fodStart);
        this.filterDateRangeDescriptionShort = this.filterDateRangeDescription;
        break;

      case 'TIME_RANGE':
        this.filterDateRangeDescription = 'Zeitraum ' + MisDateUtil.formatDate(this.filterDateRange.fodStart) + ' bis ' + MisDateUtil.formatDate(this.filterDateRange.fodEnd);
        this.filterDateRangeDescriptionShort = MisDateUtil.formatDate(this.filterDateRange.fodStart) + '-' + MisDateUtil.formatDate(this.filterDateRange.fodEnd);
        break;
    }
  }

  updateShowOnlyActiveProperties(event: boolean): void {
    this.showOnlyActiveProperties = event;
    this.triggerApplyFilter();
  }

  triggerResetFilter(event): void {
    MisEventUtils.stopEvent(event);
    this.resetFilter();
  }
}
