import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MisEventUtils} from '../../../../../../services/common/event.util';

@Component({
  selector:    'mis-table-action-icon-button',
  templateUrl: './table.action.icon.button.component.html',
  styleUrls:   ['./table.action.icon.button.component.scss']
})
export class TableActionIconButtonComponent {
  @Input() active: boolean;
  @Input() toolTip: string;
  @Input() icon: string;
  @Input() label: string;
  @Output() clicked = new EventEmitter();

  clickButton(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.clicked.emit();
  }
}
