<div class="mis-tariff-management">
  <div class="mis-column-boxed">

    <mis-loading *ngIf="isLoading"></mis-loading>

    <div *ngIf="!isLoading && view === 'TARIFF_SELECTION'">
      <h2>Tarifeinstellungen</h2>

      <p class="mis-tariff-description">In Abhängigkeit Ihrer individuellen Bedürfnisse bieten wir unterschiedliche
        Preismodelle mit monatlicher Zahlweise und Kündigungsmöglichkeit. Bitte kontaktieren Sie uns zur Unterbreitung
        Ihres individuellen Angebotes:
        <a href="mailto:support@propfox.io?subject=Anfrage%3A%20Individuelles%20Angebot">support@propfox.io</a>.</p>

      <p class="mis-tariff-description">Kostenpflichtige Tarife verlängern sich automatisch um einen Monat sofern Sie
        nicht bis zu 48h vor Ablauf umgestellt oder gekündigt worden sind. Kündigungen können Sie jederzeit
        unkompliziert an folgende E-Mail-Adresse richten:
        <a href="mailto:support@propfox.io?subject=K%C3%BCndigung%20meines%20Accounts">support@propfox.io</a>.</p>

      <!--<mis-tariff-items [tariff]="tariff"-->
      <!--(selectedTariff)="changedTariff($event)"></mis-tariff-items>-->
    </div>

    <div *ngIf="!isLoading && view === 'INVOICE_DATA_FORM'">
      <mis-tariff-change [selectedTariff]="selectedTariff"
                         (cancelTariffChange)="reset()"></mis-tariff-change>
    </div>

  </div>
</div>
