<div class="mis-immo-overview"
     [ngClass]="{'mis-immo-navigation-open': navigationOpened && (viewServiceInstance.isDesktop() || viewServiceInstance.isTablet()), 'mis-immo-navigation-mobile': (viewServiceInstance.isMobile() && !viewServiceInstance.isTablet())}">

  <mis-immo-navigation [filterQuery]="filterQuery"
                       [isLoadingAuswertung]="isLoadingAuswertung"
                       [isLoadingExport]="isDownloadingExport"
                       [viewType]="viewType"
                       (applySearch)="applySearch($event)"
                       (refreshFiltering)="applyFilter(filterQuery, 0, pageHitsPerPage)"
                       (export)="exportResults($event)"
                       (changedOpenState)="toggleNavigation($event)"></mis-immo-navigation>

  <div class="mis-immo-main"
       [ngStyle]="{'max-height': contentHeight}">

    <mis-filter-pane [filterQuery]="filterQuery"
                     [pageTotalHits]="pageItemCount"
                     (applyFilter)="applyFilter($event, 0, pageHitsPerPage)"
                     (changedFilterPaneHeight)="changedFilterPaneHeight($event)"
                     [viewType]="viewType"
                     #filterPane></mis-filter-pane>

    <mis-immo-table *ngIf="researchItems && !offererSettingsConfigurationRequired"
                    [filterQuery]="filterQuery"
                    [rows]="researchItems"
                    [isLoading]="isLoading"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageHitsPerPage"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageTotalHits]="pageItemCount"
                    [sortItem]="getSortItem()"
                    [topMargin]="filterPaneHeight"
                    [totalPages]="totalPages"
                    (changeSort)="changeSort($event)"
                    (changePage)="changePagination($event)"
                    (filter)="updateFilterFromImmoTable($event)"></mis-immo-table>

    <mis-empty-result-hint *ngIf="offererSettingsConfigurationRequired"
                           icon="fas fa-exclamation-triangle">
      Es konnten keine Objekte für Sie gefunden werden, da Sie noch keine Anbieterreferenz hinterlegt haben.<br>
      Ihre Anbieterreferenz können Sie im Untermenü unter <strong>Weiteres</strong>&nbsp;&nbsp;<i class="fas fa-angle-right"></i>&nbsp;&nbsp;<strong>Anbieter Einstellungen</strong> hinterlegen.
    </mis-empty-result-hint>

  </div>
</div>
