<aside class="mis-header-profile-item"
       [ngClass]="{'mis-header-profile-detailed': showDetailed}">

  <a href="#"
     [ngClass]="{'mis-link-active': showContextMenu}"
     (click)="toggleProfileOverlay($event)"
     (clickOutside)="showContextMenu = false"
     #menuTrigger="matMenuTrigger"
     [matMenuTriggerFor]="profileMenu">
    <i class="fas fa-user"></i>
    <span class="mis-header-profile-item-name">Mein Profil</span>
  </a>

  <mat-menu #profileMenu="matMenu">
    <a *ngIf="isAdminUser && viewServiceInstance.isDesktop()"
       mat-menu-item
       routerLink="/account">Kontoverwaltung</a>
    <a *ngIf="viewServiceInstance.isDesktop() || viewServiceInstance.isTablet()"
       mat-menu-item
       routerLink="/selfservice">Meine Daten</a>
    <a mat-menu-item
       href="#" (click)="logout($event)">Logout</a>
  </mat-menu>

</aside>
