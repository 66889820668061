<aside class="mis-header-help-item"
       [ngClass]="{'mis-header-help-detailed': showDetailed}">

  <a href="#"
     [ngClass]="{'mis-link-active': showContextMenu}"
     (click)="toggleHelpOverlay($event)"
     (clickOutside)="showContextMenu = false"
     #menuTrigger="matMenuTrigger"
     [matMenuTriggerFor]="helpMenu">
    <i class="fas fa-question"></i>
    <span class="mis-header-help-item-name">Hilfe?</span>
  </a>

  <mat-menu #helpMenu="matMenu">
    <a mat-menu-item
       routerLink="/help">Hilfe</a>
    <a mat-menu-item
    (click)="restartTour($event)">Guided Tour starten</a>
    <a mat-menu-item
       href="https://www.propfox.io/agb/"
       target="_blank">Allgemeine Geschäftsbedingungen</a>
    <a mat-menu-item
       href="https://www.propfox.io/datenschutz/"
       target="_blank">Datenschutzbestimmungen</a>
    <a mat-menu-item
       href="https://www.propfox.io/impressum/"
       target="_blank">Impressum</a>
  </mat-menu>

</aside>
