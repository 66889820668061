import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';

export class BooleanColumnData extends AbstractImmoTableColumnData {
  constructor(public useReverse: boolean = false) {
    super();
  }
}

export class BooleanColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: BooleanColumnData;

  constructor(useReverse: boolean = false) {
    super();
    this.component = ImmoTableColumnBooleanComponent;
    this.data = new BooleanColumnData(useReverse);
  }
}

@Component({
  selector: 'mis-immo-table-column-boolean',
  templateUrl: './immo.table.column.boolean.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnBooleanComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: BooleanColumnData;
  text: string;

  ngOnInit(): void {
    const reverse: boolean = this.additionalData && this.additionalData.useReverse;

    const booleanVal = this.getValue(this.researchItem, this.columnName);
    if (booleanVal == null) {
      this.text = '';
    }

    if (reverse) {
      this.text = !booleanVal ? 'Ja' : 'Nein';
    }

    this.text = booleanVal ? 'Ja' : 'Nein';
  }
}
