<div class="mis-form-field-toggle {{direction}}"
     [ngClass]="{'disabled': disabled}">
  <a *ngFor="let itemKey of objectKeys(items)"
     [ngClass]="{'mis-form-field-toggle-active' : itemKey === activeItemKey, 'mis-form-field-toggle-disabled': items[itemKey].disabled}"
     href="#"
     (click)="toggle($event, itemKey)"
     [title]="items[itemKey].hintText">

    <i *ngIf="items[itemKey].iconClass"
       class="{{items[itemKey].iconClass}}"></i>

    <span>{{items[itemKey].value}}</span>
  </a>
</div>
