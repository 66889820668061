import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {LocalStorageService} from '../local.storage.service';
import {AbstractService} from '../abstract.service';
import {PreisatlasResponse, StatisticValues} from './model/price.atlas.model';
import {RenditeService} from './rendite.service';
import {PriceAtlasFilter} from './model/price.atlas.filter.model';
import {UserService} from '../aaa/user.service';
import {MisTag, MisTagUtils} from '../aaa/model/account.model';
import {SavedPriceAtlasSettings} from './model/saved.price.atlas.settings.model';

@Injectable({providedIn: 'root'})
export class PriceAtlasService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService,
              private renditeService: RenditeService,
              private userService: UserService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadPriceAtlas(filter: PriceAtlasFilter): Observable<PreisatlasResponse> {
    return new Observable<PreisatlasResponse>((subscriber) => {
      let params: HttpParams = new HttpParams();
      params = params.append('postleitzahlen', filter.zipCodes.join(','));

      if (filter.numberOfRooms) {
        params = params.append('zimmer', '' + filter.numberOfRooms);
      }
      if (filter.validUntilDate) {
        params = params.append('vud', filter.validUntilDate.toISOString());
      }

      this.GET<PreisatlasResponse>(environment.misAnalyticEndpoint + '/preisatlas/' + filter.offerType + '/' + filter.propertyType + '/preisatlas.json', params).subscribe((preisAtlas) => {

        if (filter.offerType === 'KAUF') {
          // Lade Rendite Atlas
          this.renditeService.loadRenditeAtlas(filter).subscribe((renditeAtlasResponse) => {
            preisAtlas.meta.rendite = renditeAtlasResponse.meta.rendite;
            preisAtlas.meta.faktor = renditeAtlasResponse.meta.faktor;

            preisAtlas.preisatlas.forEach((preisAtlas) => {
              const rendite = renditeAtlasResponse.renditeAtlas.filter(rendite => rendite.postleitzahl === preisAtlas.postleitzahl);
              if (rendite && rendite.length > 0) {
                preisAtlas.rendite = rendite[0].rendite;

                const faktor = rendite[0].faktor;
                preisAtlas.faktor = new StatisticValues();
                preisAtlas.faktor.min = faktor.max;
                preisAtlas.faktor.max = faktor.min;
                preisAtlas.faktor.median = faktor.median;
                preisAtlas.faktor.mean = faktor.mean;
                preisAtlas.faktor.p20 = faktor.p80;
                preisAtlas.faktor.p80 = faktor.p20;
              }
            });
            subscriber.next(preisAtlas);
            subscriber.unsubscribe();

          }, (error) => {
            subscriber.error();
            subscriber.unsubscribe();
          });

        } else {
          subscriber.next(preisAtlas);
          subscriber.unsubscribe();
        }

      }, (error) => {
        subscriber.error();
        subscriber.unsubscribe();
      });
    });
  }

  getLastUsedMapSettings(): SavedPriceAtlasSettings {
    if (!this.authenticationProvider.isAuthenticated()) {
      return null;
    }

    const user = this.authenticationProvider.getUser();
    const settingsTag = MisTagUtils.getTagByKey(user.tags, MisTagUtils.keys.priceAtlasLastPosition);

    if (settingsTag == null) {
      return null;
    } else {
      return JSON.parse(settingsTag.value);
    }
  }

  saveLastUsedMapSettings(settings: SavedPriceAtlasSettings): void {
    if (!this.authenticationProvider.isAuthenticated()) {
      return;
    }

    const user = this.authenticationProvider.getUser();
    const storedLastPosition = MisTagUtils.getTagByKey(user.tags, MisTagUtils.keys.priceAtlasLastPosition);

    if (storedLastPosition == null) {
      const newTag = new MisTag();
      newTag.key = MisTagUtils.keys.priceAtlasLastPosition;
      newTag.value = JSON.stringify(settings);
      newTag.type = 'JSON';

      user.tags.push(newTag);
      this.userService.addUserTag(user.id, newTag).subscribe(() => {
      }, () => {
      });

    } else {
      storedLastPosition.value = JSON.stringify(settings);
      this.userService.updateUserTag(user.id, storedLastPosition).subscribe(() => {
      }, () => {
      });
    }
  }
}
