import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {AuthenticationProvider} from './authentication.provider';
import {
  MisSubscription,
  SubscriptionInterval,
  TariffInfoItem,
  TariffPrice
} from './model/tariff.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from "../local.storage.service";
import {SubscriptionType} from './model/account.model';

export const MIS_TARIFF_ITEMS = [
  new TariffInfoItem(
    'Free',
    'perfekt zum Kennenlernen',
    new TariffPrice(0),
    'FREE',
    [
      'Marktrecherche',
      'Einfache Filtermöglichkeiten',
      'Einfache Objektanalysen und -bewertungen',
      'Bis zu 200 Immobilien je Filter und Auswertung'
    ],
    false),

  new TariffInfoItem(
    'Essential',
    'Beliebt bei Anlegern und Investoren',
    new TariffPrice(50),
    'ESSENTIAL',
    [
      'Marktrecherche',
      'Umfangreiche Filteröglichkeiten',
      'Umfangreiche Objektanalysen und -bewertungen',
      'Anzeige Alter des Inserats',
      'Anzeige Preishistorie (bald)',
      'Einfache Excel-Analysen und Auswertungen',
      'Standartisierte Objektkalulation mit Excel',
      'Bis zu 2.000 Immobilien je Filter und Auswertung'
    ],
    false),

  new TariffInfoItem(
    'Advanced',
    'Beliebt bei Unternehmen',
    new TariffPrice(100),
    'ADVANCED',
    [
      'Marktrecherche',
      'Umfangreiche Filteröglichkeiten',
      'Umfangreiche Objektanalysen und -bewertungen',
      'Anzeige Alter des Inserats',
      'Anzeige Preishistorie (bald)',
      'Detaillierte Excel-Analysen und Auswertungen',
      'Standartisierte Objektkalulation mit Excel',
      'Eigene Excelvorlage zur detailieren Kalkulation hochladen (bald)',
      'Bis zu 5.000 Immobilien je Filter und Auswertung',
      'Automatische Erkennung Ihrer Immobilieninserate (bald)'
    ],
    true)
];

@Injectable({providedIn: 'root'})
export class TariffService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  getAvailableTariffInfos(): TariffInfoItem[] {
    return MIS_TARIFF_ITEMS;
  }

  getSubscription(): Observable<MisSubscription> {
    return this.GET<MisSubscription>(environment.misAccountEndpoint + '/subscription');
  }

  updateSubscription(newSubscriptionKey: SubscriptionType, interval: SubscriptionInterval = 'MONTHLY'): Observable<MisSubscription> {
    return this.PUT<MisSubscription>(environment.misAccountEndpoint + '/subscription/' + newSubscriptionKey + '/' + interval, null);
  }
}
