import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mis-tab-item',
  templateUrl: './tab-item.component.html',
})
export class TabItemComponent {
  @Input() tabTitle: string;
  @Input() tabTitleIcon: string;
  @Input() active: boolean;
  @Output() isActivated = new EventEmitter();

  activateTab(): void {
    this.active = true;
    this.isActivated.emit();
  }
}
