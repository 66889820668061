import {Component} from '@angular/core';
import {ResearchService} from '../../../services/research/research.service';

@Component({
  selector: 'mis-research',
  templateUrl: './research.component.html'
})
export class ResearchComponent {
  constructor(public tableService: ResearchService) {}
}
