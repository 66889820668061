import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'mis-ci-cd',
  templateUrl: './ci.cd.component.html'
})
export class CiCdComponent {
  formGroup: FormGroup;
  co: string;

  constructor(private formBuilder: FormBuilder) {
     this.formGroup = formBuilder.group({
       'hexColor': ['']
     });
   }
}
