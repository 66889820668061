import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MarketReportLayout} from "../../../../../../services/market-report/model/market.report.customization.model";
import {MarketReportExportSubComponent} from "../market.report.export.component";
import {
  AnalysisMultiBulletChartData,
  MarketReportData
} from "../../../../../../services/market-report/model/market.report.model";
import {MultiBulletChartData} from "../../../../../common/chart/multi-bullet-chart/multi.bullet.chart.component";
import {MisBaseUtils} from "../../../../../../services/common/base.util";
import * as moment from "moment";

@Component({
  selector: 'mis-market-report-leasing-market-page',
  templateUrl: './market.report.leasing.market.page.component.html',
  styleUrls: ['./market.report.leasing.market.page.component.scss']
})
export class MarketReportLeasingMarketPageComponent extends MarketReportExportSubComponent implements OnChanges {
  @Input() marketReportLayout?: MarketReportLayout;
  @Input() marketReportData?: MarketReportData;
  @Output() loaded = new EventEmitter<void>();

  preisChart: MultiBulletChartData;
  preisJeZimmeranzahlChart: MultiBulletChartData;
  flaecheJeZimmeranzahlChart: MultiBulletChartData;

  ngOnChanges(changes: SimpleChanges): void {
    let preisStatistics = this.marketReportData.detailedInfoMiete.preisStatistics;
    preisStatistics.xSeries = preisStatistics.xSeries.map((series) => {
      return [moment(series, "YYYYMMDD").lang("de").format("MMM"), moment(series, "YYYYMMDD").lang("de").format("YY")];
    });
    this.preisChart = this.buildChartData(preisStatistics, '€', 'Mietpreis in €/m²');

    this.preisJeZimmeranzahlChart = this.buildChartData(this.marketReportData.detailedInfoMiete.preisJeZimmeranzahlStatistics, '€', 'Mietpreis in €/m²', false);
    this.flaecheJeZimmeranzahlChart = this.buildChartData(this.marketReportData.detailedInfoMiete.flaecheJeZimmeranzahlStatistics, 'm²', 'Wohnungsgröße in m²', false);

    setTimeout(() => {
      this.loaded.emit();
    }, 1000);
  }

  private buildChartData(analysisData: AnalysisMultiBulletChartData, unit: string, yAxisLabel: string, showLine: boolean = true): MultiBulletChartData {
    const chartData = new MultiBulletChartData();
    chartData.colors = [
      {
        borderColor: this.marketReportLayout.chartColor1,
        backgroundColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: this.marketReportLayout.chartColor1
      },
      {
        backgroundColor: this.marketReportLayout.chartColor2,
      }
    ];

    chartData.options = {
      responsive: true,
      scales: {
        xAxes: [{
          barPercentage: 0.3
        }],
        yAxes: [{
          ticks: {
            autoSkip: true,
            // maxTicksLimit: 12,
            // maxTicksLimit: 8,
            // min: yAxisMin,
            // max: yAxisMax,
            // stepSize: yAxisSteps,
            callback: function (value: any, index: any, values: any): string | number {
              return (value ? MisBaseUtils.formatNumber(value) : '0,00') + unit;
            }
          },
          scaleLabel: {
            display: true,
            labelString: yAxisLabel
          }
        }]
      }
    };
    chartData.labels = analysisData.xSeries;

    const medianLineData = [];
    const percentileData = [];
    analysisData.ySeries.forEach(ySeriesData => {
      medianLineData.push(ySeriesData.median);
      percentileData.push([ySeriesData.percentile20, ySeriesData.percentile80]);
    });

    chartData.data = [
      {
        data: medianLineData,
        stack: 'combined',
        type: 'line',
        showLine: showLine,
        // lineTension: 0, // avoid round curves
      },
      {
        data: percentileData,
        stack: 'combined',
        type: 'bar'
      }
    ];

    return chartData;
  }

}

