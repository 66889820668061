<div class="mis-market-report-overview mis-column-boxed">

  <mat-tab-group (selectedTabChange)="changedTab($event)"
                 [selectedIndex]="activeIndex">
    <mat-tab label="Marktberichte">
      <mis-market-report-download-overview *ngIf="activeIndex === 0"></mis-market-report-download-overview>
    </mat-tab>

    <mat-tab label="Einstellungen">
      <mis-market-configuration-overview *ngIf="activeIndex === 1"></mis-market-configuration-overview>
    </mat-tab>
  </mat-tab-group>

</div>
