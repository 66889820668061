import {FormGroup} from '@angular/forms';

export abstract class AbstractFormComponent {
  formGroup: FormGroup;
  isLoading: boolean;

  abstract customSaveAction(): void;

  customFormValidationFailed(): void {
    Object.keys(this.formGroup.controls).forEach(field => {
      const control = this.formGroup.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }

  saveForm(): void {
    if (!this.formGroup.valid) {
      this.customFormValidationFailed();
      return;
    }

    this.isLoading = true;
    this.customSaveAction();
  }

  isFormGroupValid(): boolean {
    return this.formGroup.valid;
  }

  hasError(fieldName: string) {
    const field = this.formGroup.get(fieldName);
    const hasError = field && ((field.dirty || field.touched) && field.errors);

    if (hasError) {
      return true;
    }

    return false;
    // if (!this.backendErrorFields) {
    //   return false;
    // }

    //return this.backendErrorFields.hasOwnProperty(fieldName);
  }

  resetForm(): void {
    this.formGroup.reset();
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
  }

  protected getFieldValue(fieldName: string, defaultValue: any = null): any {
    const value = this.formGroup.get(fieldName).value;
    if (value === null || value === '') {
      return defaultValue;
    }
    return value;
  }
}
