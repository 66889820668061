import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {AbstractImmoTableFilter} from '../../../immo-table/immo-table-configuration/immo.table.classes';
import {ResearchService} from '../../../../../../services/research/research.service';

@Component({
  selector:    'mis-filter-extended-item-reference-id',
  templateUrl: './filter.extended.item.reference.id.component.html'
})
export class FilterExtendedItemReferenceIdComponent implements OnInit {
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<string>();

  value = '';

  constructor(private researchService: ResearchService) {

  }

  ngOnInit(): void {
    if (this.queryFields && this.queryFields.length > 0) {
      this.value = this.queryFields[0].sValue;
    }
  }

  changedValue(newValue: string) {
    if (!newValue) {
      this.changed.emit(null);
      return;
    }

    const externalRefId = this.getExternalReferenceId(newValue);
    this.changed.emit(externalRefId);
  }

  private getExternalReferenceId(value: string): string {
    if (value.indexOf('http') === 0) {

      if (value.indexOf('www.immowelt.de') > 0) {
        return value.substring(value.lastIndexOf('/') + 1);
      }

      if (value.indexOf('www.immobilienscout24.de') > 0) {
        value = value.substring(0, value.length - 2);
        return value.substring(value.lastIndexOf('/') + 1);
      }

      if (value.indexOf('www.ebay-kleinanzeigen.de') > 0) {
        value = value.substring(value.lastIndexOf('/') + 1);
        return value.substring(0, value.indexOf('-'));
      }
    }

    return value;
  }
}
