<div class="mis-form-field-datetime-picker-wrapper"
     [formGroup]="formGroup">
  <div class="mis-form-field-datetime-picker">
    <input formControlName="{{controlNamePrefix}}"
           name="{{controlNamePrefix}}"
           type="time"
           autocomplete="off"
           required="required"
           (change)="changeInputValue($event)">
    <span>Uhr</span>
  </div>
</div>
