import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MisEventUtils} from '../../../services/common/event.util';

@Component({
  selector: 'mis-field-box',
  templateUrl: './field.box.component.html',
  styleUrls: ['./field.box.component.scss']
})
export class FieldBoxComponent {
  @Input() label: string;
  @Input() isEmpty = false;
  @Input() editable = false;
  @Output() edit = new EventEmitter();

  triggerEdit(event) {
    MisEventUtils.stopEvent(event);

    this.edit.emit();
  }
}
