import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {AbstractImmoTableFilter} from '../../../immo-table/immo-table-configuration/immo.table.classes';
import {FormFieldsDropdownValue} from '../../../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';

@Component({
  selector:    'mis-filter-extended-item-boolean',
  templateUrl: './filter.extended.item.boolean.component.html',
  styleUrls:   ['./filter.extended.item.boolean.component.scss']
})
export class FilterExtendedItemBooleanComponent implements OnInit {
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<boolean>();

  availableValues: { [key: string]: FormFieldsDropdownValue } = {'true': new FormFieldsDropdownValue('Ja'), 'false': new FormFieldsDropdownValue('Nein')};
  selectedValue = 'true';

  ngOnInit(): void {
    if (this.queryFields && this.queryFields.length > 0) {
      const value = this.queryFields[0].bValue;
      this.selectedValue = value ? 'true' : 'false';
    }
  }

  changedValue(values: { [key: string]: FormFieldsDropdownValue }): void {
    this.changed.emit(Object.keys(values)[0] === 'true');
  }
}
