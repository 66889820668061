<div
  class="mis-login-wrapper mis-column-horizontal-slidable mis-column-horizontal-slidable-{{isLoginView ? '0' : '1'}}">
  <div class="mis-column-horizontal-slidable-item-0">
    <mis-login></mis-login>
    <a href="#" (click)="showLoginView(false)">Passwort vergessen?</a>
  </div>
  <div class="mis-column-horizontal-slidable-item-1">
    <mis-password-forgotten-wrapper></mis-password-forgotten-wrapper>
    <a href="#" (click)="showLoginView(true)">Zur Anmeldung</a>
  </div>
</div>
