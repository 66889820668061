import {Component, Input} from '@angular/core';

@Component({
  selector: 'mis-form-field-horizontal',
  templateUrl: './form.field.horizontal.component.html',
  styleUrls: ['./form.field.horizontal.component.scss']
})
export class FormFieldHorizontalComponent {
  @Input() title: string;
}
