<mis-debug-info></mis-debug-info>

<div *ngIf="initialized"
     class="mis-app-container"
     [ngClass]="{'mis-app-container-hide-bg': !showBackgroundImage, 'mis-app-header-detailed': showDetailedHeader, 'mis-app-header-hidden': !authenticatedUser}">

  <mis-header [showNavigation]="authenticatedUser != null"></mis-header>

  <div class="mis-app-main-container"
       [ngStyle]="{'height': contentHeight}">
    <router-outlet></router-outlet>
  </div>

  <mis-feedback></mis-feedback>
</div>

<mis-print-container id="market-report-export"></mis-print-container>
