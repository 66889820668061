import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationProvider} from './aaa/authentication.provider';
import {BUILD_INFO} from "../../environments/build-info";
import {LocalStorageService} from "./local.storage.service";

export const MisHeaders = {
  AUTHENTIZATION: 'Authorization',
  MIS_REFRESH_TOKEN: 'X-MIS-REFRESH-TOKEN',
  MIS_ACCESS_TOKEN: 'X-MIS-ACCESS-TOKEN',
  MIS_TOKEN_EXPIRES: 'X-MIS-TOKEN-EXPIRES-IN-SECONDS',
  MIS_BUILD_VERSION: 'X-MIS-BUILD-VERSION',
  MIS_BUILD_NUMBER: 'X-MIS-BUILD-NUMBER',
  MIS_CLIENT_ID: 'X-MIS-CLIENT-ID',
};

export class AbstractService {

  constructor(protected httpClient: HttpClient,
              protected authenticationProvider: AuthenticationProvider,
              protected localStorageService: LocalStorageService) {
  }

  protected getHeaders(): HttpHeaders {
    let clientID: string = this.localStorageService.get<string>('MIS-CLIENT-ID');
    if (!clientID) {
      clientID = this.uuidv4();
      this.localStorageService.set('MIS-CLIENT-ID', clientID);
    }

    let headers = new HttpHeaders()
      .set(MisHeaders.MIS_CLIENT_ID, clientID)
      .set(MisHeaders.MIS_BUILD_NUMBER, BUILD_INFO.BUILD_NUMBER?.toString())
      .set(MisHeaders.MIS_BUILD_VERSION, BUILD_INFO.BUILD_VERSION?.toString());

    if (this.authenticationProvider.isAuthenticated()) {
      return headers
        .set(MisHeaders.AUTHENTIZATION, this.authenticationProvider.getJwtToken())
    }

    return headers;
  }

  protected GET<T>(url: string, params: HttpParams = new HttpParams(), responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json', additionalHeaders: { [name: string]: string } = {}): Observable<T> {

    let preparedHeaders = this.getHeaders();

    if (additionalHeaders) {
      Object.keys(additionalHeaders).forEach(key => {
        preparedHeaders = preparedHeaders.set(key, additionalHeaders[key]);
      });
    }

    const httpOptions = {
      headers: preparedHeaders,
      params: params,
      responseType: <any>responseType
    };

    return this.httpClient.get<T>(url, httpOptions);
  }

  protected POST<T>(url: string, body: any, params: HttpParams = new HttpParams()): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(),
      params: params
    };

    return this.httpClient.post<T>(url, body, httpOptions);
  }

  protected PUT<T>(url: string, body: any, params: HttpParams = new HttpParams()): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(),
      params: params
    };

    return this.httpClient.put<T>(url, body, httpOptions);
  }

  protected DELETE<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(),
      params: params
    };

    return this.httpClient.delete<T>(url, httpOptions);
  }

  /** geklaut bei: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid */
  private uuidv4(): string {
    return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, r => {
        let c: number = parseInt(r);
        return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
      }
    );
  }

}
