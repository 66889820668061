import {Component, Input, ViewChild} from '@angular/core';
import {MisEventUtils} from '../../../../services/common/event.util';
import {GuidedTourService} from '../../../common/guided-tour/guided.tour.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {Router} from "@angular/router";

@Component({
  selector:    'mis-header-help-item',
  templateUrl: './header.help.item.component.html',
  styleUrls:   ['./header.help.item.component.scss']
})
export class HeaderHelpItemComponent {
  @Input() showDetailed: boolean;
  @ViewChild(MatMenuTrigger, {static: true}) menuTrigger: MatMenuTrigger;
  showContextMenu: boolean;

  constructor(private guidedTourService: GuidedTourService,
              private router: Router) {
  }

  restartTour(event): void {
    MisEventUtils.stopEvent(event);

    this.guidedTourService.restart(this.router.url);

    this.menuTrigger.closeMenu();
  }

  toggleHelpOverlay(event): void {
    MisEventUtils.stopEvent(event);
    this.showContextMenu = !this.showContextMenu;
  }
}
