import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {PasswordForgottenWrapperComponent} from './password-forgotten-wrapper/password.forgotten.wrapper.component';

@Component({
  selector:    'mis-login-wrapper',
  templateUrl: './login.wrapper.component.html'
})
export class LoginWrapperComponent implements OnInit {
  @ViewChild(PasswordForgottenWrapperComponent, {static: true}) passwordForgottenWrapper: PasswordForgottenWrapperComponent;

  isLoginView = true;

  constructor(private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const requestedAction = this.route.snapshot.routeConfig.path;
    if (requestedAction === 'password-forgotten') {
      this.showLoginView(false);
    }
  }

  showLoginView(showLogin): boolean {
    this.isLoginView = showLogin;

    if (showLogin) {
      this.location.go('/login');
      this.passwordForgottenWrapper.resetFromParent();
    } else {
      this.location.go('/password-forgotten');
    }
    return false;
  }
}
