import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mis-header-navigation-item',
  templateUrl: './header.navigation.item.component.html',
  styleUrls: ['./header.navigation.item.component.scss']
})
export class HeaderNavigationItemComponent {
  @Input() routerLinkTarget: string;
  @Input() active: boolean;
  @Input() label: string;
  @Input() icon: string;
  @Input() count: number;
  @Output() visit = new EventEmitter<string>();

  triggerVisitEvent(): boolean {
    this.visit.emit(this.routerLinkTarget);
    return true;
  }
}
