import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReCaptcha2Component} from 'ngx-captcha';
import {environment} from '../../../../../environments/environment';

export class RecaptchaResponse {
  captchaCode: string;
  captchaId: number;

  constructor(captchaCode: string, captchaId: number) {
    this.captchaCode = captchaCode;
    this.captchaId = captchaId;
  }
}

@Component({
  selector: 'mis-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() showCaptcha: boolean = true;
  @Output() captchaSolved = new EventEmitter<RecaptchaResponse>();
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  siteKey: string;
  action: string;
  captchaResponse?: string;

  theme: 'light' | 'dark' = 'light';
  size: 'compact' | 'normal' = 'normal';
  lang = 'de';
  type: 'image' | 'audio';

  constructor(private formBuilder: FormBuilder) {
    this.siteKey = environment.reCaptchaSiteKey;
    this.action = environment.reCaptchaSiteAction;
  }

  ngOnInit() {
    this.formGroup.addControl('recaptcha', new FormControl('', Validators.required));
    this.formGroup.addControl('recaptchaResponse', new FormControl(''));
    this.formGroup.addControl('recaptchaId', new FormControl(''));
  }

  handleReset(): void {
    this.captchaResponse = undefined;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    this.formGroup.get('recaptchaResponse').setValue(captchaResponse);
    this.formGroup.get('recaptchaId').setValue(this.captchaElem.getCaptchaId());

    this.captchaSolved.emit(new RecaptchaResponse(captchaResponse, this.captchaElem.getCaptchaId()));
  }

  hasError(): boolean {
    if (!this.formGroup.controls['recaptcha'].touched) {
      return false;
    }
    if (!this.formGroup.controls['recaptcha'].errors) {
      return false;
    }
    return this.formGroup.controls['recaptcha'].errors['required'];
  }
}
