export class ZipCodeLocation {
  zipCode: string;
  north: number;
  east: number;
  south: number;
  west: number;
}

export const ZIP_CODE_LOCATIONS = [{'zipCode': '01067', 'north': 51.0766681, 'east': 13.7471414, 'south': 51.0435592, 'west': 13.6868862}, {
  'zipCode': '01069',
  'north':   51.0571072,
  'east':    13.7585585,
  'south':   51.0197564,
  'west':    13.7109497
}, {'zipCode': '01097', 'north': 51.0845428, 'east': 13.7543709, 'south': 51.054358400000005, 'west': 13.7254824}, {
  'zipCode': '01099',
  'north':   51.128146,
  'east':    13.910944400000002,
  'south':   51.0571072,
  'west':    13.7419254
}, {'zipCode': '01108', 'north': 51.1777202, 'east': 13.8311759, 'south': 51.1258259, 'west': 13.7445226}, {
  'zipCode': '01109',
  'north':   51.1465927,
  'east':    13.814580800000002,
  'south':   51.0981715,
  'west':    13.702750500000002
}, {'zipCode': '01127', 'north': 51.095822600000005, 'east': 13.7511777, 'south': 51.0679955, 'west': 13.713716}, {
  'zipCode': '01129',
  'north':   51.1144831,
  'east':    13.766993,
  'south':   51.0809688,
  'west':    13.6917331
}, {'zipCode': '01139', 'north': 51.0962259, 'east': 13.717165700000002, 'south': 51.06379960000001, 'west': 13.6586341}, {
  'zipCode': '01156',
  'north':   51.1027652,
  'east':    13.6625546,
  'south':   51.0290577,
  'west':    13.5793237
}, {'zipCode': '01157', 'north': 51.0892763, 'east': 13.7000257, 'south': 51.04753430000001, 'west': 13.6458465}, {
  'zipCode': '01159',
  'north':   51.0591055,
  'east':    13.721618200000002,
  'south':   51.0287716,
  'west':    13.6753461
}, {'zipCode': '01169', 'north': 51.0538445, 'east': 13.684955500000001, 'south': 51.0233479, 'west': 13.6469331}, {
  'zipCode': '01187',
  'north':   51.0402431,
  'east':    13.7309981,
  'south':   51.0148787,
  'west':    13.664299400000003
}, {'zipCode': '01189', 'north': 51.0248692, 'east': 13.729556600000002, 'south': 51.0023446, 'west': 13.6757816}, {
  'zipCode': '01217',
  'north':   51.0335115,
  'east':    13.7615062,
  'south':   50.9996323,
  'west':    13.7137398
}, {'zipCode': '01219', 'north': 51.0436823, 'east': 13.7893463, 'south': 51.00004990000001, 'west': 13.7465166}, {
  'zipCode': '01237',
  'north':   51.0294635,
  'east':    13.81849,
  'south':   51.0070447,
  'west':    13.7767317
}, {'zipCode': '01239', 'north': 51.0129022, 'east': 13.8110661, 'south': 50.9857846, 'west': 13.7636708}, {
  'zipCode': '01257',
  'north':   51.0216136,
  'east':    13.8464961,
  'south':   50.974937,
  'west':    13.7890378
}, {'zipCode': '01259', 'north': 51.018573, 'east': 13.8845671, 'south': 50.9779907, 'west': 13.8093729}, {
  'zipCode': '01277',
  'north':   51.0533522,
  'east':    13.8202075,
  'south':   51.0215733,
  'west':    13.7686663
}, {'zipCode': '01279', 'north': 51.0432565, 'east': 13.847114, 'south': 51.0121832, 'west': 13.8042958}, {
  'zipCode': '01307',
  'north':   51.0640951,
  'east':    13.7872926,
  'south':   51.0413195,
  'west':    13.7543709
}, {'zipCode': '01309', 'north': 51.0636507, 'east': 13.8100765, 'south': 51.0348861, 'west': 13.7666666}, {
  'zipCode': '01324',
  'north':   51.0673272,
  'east':    13.870891200000003,
  'south':   51.04825040000001,
  'west':    13.8120474
}, {'zipCode': '01326', 'north': 51.0656479, 'east': 13.9102863, 'south': 50.9868948, 'west': 13.7997018}, {
  'zipCode': '01328',
  'north':   51.0740036,
  'east':    13.9660626,
  'south':   51.009702,
  'west':    13.8401088
}, {'zipCode': '01445', 'north': 51.1428608, 'east': 13.7011022, 'south': 51.0885119, 'west': 13.5853138}, {
  'zipCode': '01454',
  'north':   51.2025787,
  'east':    13.9883362,
  'south':   51.0609783,
  'west':    13.853507000000002
}, {'zipCode': '01458', 'north': 51.2035169, 'east': 13.8698685, 'south': 51.14534080000001, 'west': 13.7648824}, {
  'zipCode': '01465',
  'north':   51.1581104,
  'east':    13.8892414,
  'south':   51.1065609,
  'west':    13.8163735
}, {'zipCode': '01468', 'north': 51.2095843, 'east': 13.7281362, 'south': 51.10846800000001, 'west': 13.593746500000002}, {
  'zipCode': '01471',
  'north':   51.2366078,
  'east':    13.7960331,
  'south':   51.1333318,
  'west':    13.6560282
}, {'zipCode': '01477', 'north': 51.1266161, 'east': 14.047286200000002, 'south': 51.0608569, 'west': 13.9266287}, {
  'zipCode': '01558',
  'north':   51.31915600000001,
  'east':    13.6213756,
  'south':   51.26101620000001,
  'west':    13.4536367
}, {'zipCode': '01561', 'north': 51.3856587, 'east': 13.8560873, 'south': 51.1933629, 'west': 13.4163862}, {
  'zipCode': '01587',
  'north':   51.3134624,
  'east':    13.3060744,
  'south':   51.2888226,
  'west':    13.237827
}, {'zipCode': '01589', 'north': 51.3094795, 'east': 13.3630704, 'south': 51.2691285, 'west': 13.2792642}, {
  'zipCode': '01591',
  'north':   51.3386611,
  'east':    13.293553500000002,
  'south':   51.29415500000001,
  'west':    13.2031217
}, {'zipCode': '01594', 'north': 51.3039636, 'east': 13.4053304, 'south': 51.2024777, 'west': 13.1925285}, {
  'zipCode': '01609',
  'north':   51.4542077,
  'east':    13.5380953,
  'south':   51.3380584,
  'west':    13.3380183
}, {'zipCode': '01612', 'north': 51.3452045, 'east': 13.4580455, 'south': 51.2263459, 'west': 13.3490229}, {
  'zipCode': '01616',
  'north':   51.4112341,
  'east':    13.2931772,
  'south':   51.324636100000006,
  'west':    13.1818322
}, {'zipCode': '01619', 'north': 51.4373839, 'east': 13.3814677, 'south': 51.3070302, 'west': 13.2292211}, {
  'zipCode': '01623',
  'north':   51.2480255,
  'east':    13.3860741,
  'south':   51.1643386,
  'west':    13.2085218
}, {'zipCode': '01640', 'north': 51.1614579, 'east': 13.6554167, 'south': 51.1047889, 'west': 13.4994225}, {
  'zipCode': '01662',
  'north':   51.1931689,
  'east':    13.5422789,
  'south':   51.1291607,
  'west':    13.4241727
}, {'zipCode': '01665', 'north': 51.2463691, 'east': 13.5994266, 'south': 51.0337208, 'west': 13.3264749}, {
  'zipCode': '01683',
  'north':   51.1899763,
  'east':    13.4096468,
  'south':   51.008176600000006,
  'west':    13.2271144
}, {'zipCode': '01689', 'north': 51.2253179, 'east': 13.6377398, 'south': 51.1431165, 'west': 13.4785688}, {
  'zipCode': '01705',
  'north':   51.0357956,
  'east':    13.6986106,
  'south':   50.9533548,
  'west':    13.5805231
}, {'zipCode': '01723', 'north': 51.0665833, 'east': 13.6119068, 'south': 50.981294500000004, 'west': 13.4214228}, {
  'zipCode': '01728',
  'north':   51.010142,
  'east':    13.774516800000002,
  'south':   50.9512945,
  'west':    13.6764422
}, {'zipCode': '01731', 'north': 50.9885823, 'east': 13.8077097, 'south': 50.9149602, 'west': 13.700701}, {
  'zipCode': '01734',
  'north':   50.9867018,
  'east':    13.7205274,
  'south':   50.9124625,
  'west':    13.5918974
}, {'zipCode': '01737', 'north': 51.0261565, 'east': 13.6122943, 'south': 50.9226288, 'west': 13.4283313}, {
  'zipCode': '01738',
  'north':   50.9480432,
  'east':    13.5835249,
  'south':   50.9209674,
  'west':    13.5250535
}, {'zipCode': '01744', 'north': 50.9498687, 'east': 13.7288136, 'south': 50.7708123, 'west': 13.5830158}, {
  'zipCode': '01762',
  'north':   50.8479354,
  'east':    13.6215163,
  'south':   50.772091,
  'west':    13.5205028
}, {'zipCode': '01768', 'north': 50.93287500000001, 'east': 13.8290186, 'south': 50.8057634, 'west': 13.666986200000002}, {
  'zipCode': '01773',
  'north':   50.833494,
  'east':    13.8166672,
  'south':   50.7166799,
  'west':    13.6517498
}, {'zipCode': '01774', 'north': 50.9548746, 'east': 13.624463600000002, 'south': 50.827243, 'west': 13.4557081}, {
  'zipCode': '01776',
  'north':   50.7859038,
  'east':    13.6910168,
  'south':   50.7303119,
  'west':    13.5986343
}, {'zipCode': '01778', 'north': 50.8088754, 'east': 13.9027764, 'south': 50.7241181, 'west': 13.775339600000002}, {
  'zipCode': '01796',
  'north':   51.017922,
  'east':    14.0796399,
  'south':   50.8762525,
  'west':    13.8820586
}, {'zipCode': '01809', 'north': 50.9929421, 'east': 13.9118623, 'south': 50.8996588, 'west': 13.7751118}, {
  'zipCode': '01814',
  'north':   50.9655561,
  'east':    14.312501,
  'south':   50.8423118,
  'west':    14.0598699
}, {'zipCode': '01816', 'north': 50.9053423, 'east': 14.039607100000001, 'south': 50.7885014, 'west': 13.852630900000001}, {
  'zipCode': '01819',
  'north':   50.9296897,
  'east':    13.9434467,
  'south':   50.8322974,
  'west':    13.859519000000002
}, {'zipCode': '01824', 'north': 50.9661314, 'east': 14.167767, 'south': 50.8098626, 'west': 13.9943267}, {
  'zipCode': '01825',
  'north':   50.911954200000004,
  'east':    13.8806749,
  'south':   50.8023917,
  'west':    13.8073807
}, {'zipCode': '01829', 'north': 50.9779187, 'east': 14.0537355, 'south': 50.9440618, 'west': 13.9743454}, {
  'zipCode': '01833',
  'north':   51.0899231,
  'east':    14.1641726,
  'south':   50.9949959,
  'west':    13.9374859
}, {'zipCode': '01844', 'north': 51.0877443, 'east': 14.3203246, 'south': 50.9770226, 'west': 14.1464534}, {
  'zipCode': '01847',
  'north':   51.0087635,
  'east':    14.079882,
  'south':   50.9581073,
  'west':    13.9499385
}, {'zipCode': '01848', 'north': 51.02717390000001, 'east': 14.229723800000002, 'south': 50.9394408, 'west': 14.0476464}, {
  'zipCode': '01855',
  'north':   50.9946438,
  'east':    14.4021721,
  'south':   50.8869631,
  'west':    14.158401500000002
}, {'zipCode': '01877', 'north': 51.1793771, 'east': 14.3074187, 'south': 51.0601401, 'west': 14.0901871}, {
  'zipCode': '01896',
  'north':   51.2410797,
  'east':    14.1077091,
  'south':   51.1542926,
  'west':    13.927434
}, {'zipCode': '01900', 'north': 51.1735927, 'east': 14.1133097, 'south': 51.1176525, 'west': 13.9535168}, {
  'zipCode': '01904',
  'north':   51.1143105,
  'east':    14.390819300000002,
  'south':   51.0384925,
  'west':    14.2656288
}, {'zipCode': '01906', 'north': 51.219577900000004, 'east': 14.2698704, 'south': 51.1572301, 'west': 14.1263964}, {
  'zipCode': '01909',
  'north':   51.1479939,
  'east':    14.1333429,
  'south':   51.0724462,
  'west':    14.0026669
}, {'zipCode': '01917', 'north': 51.3433565, 'east': 14.174367400000001, 'south': 51.2300104, 'west': 13.9938146}, {
  'zipCode': '01920',
  'north':   51.3808224,
  'east':    14.2895618,
  'south':   51.1744613,
  'west':    13.931339100000002
}, {'zipCode': '01936', 'north': 51.3973285, 'east': 14.0469378, 'south': 51.1833862, 'west': 13.7603432}, {
  'zipCode': '01945',
  'north':   51.4849022,
  'east':    14.044871800000001,
  'south':   51.3694758,
  'west':    13.7094713
}, {'zipCode': '01968', 'north': 51.5740301, 'east': 14.1422984, 'south': 51.4722072, 'west': 13.8962163}, {
  'zipCode': '01979',
  'north':   51.5541428,
  'east':    13.8721903,
  'south':   51.4563049,
  'west':    13.630496700000002
}, {'zipCode': '01983', 'north': 51.6320593, 'east': 14.1114029, 'south': 51.5601103, 'west': 13.8662737}, {
  'zipCode': '01987',
  'north':   51.5144141,
  'east':    13.9241003,
  'south':   51.4612792,
  'west':    13.7962616
}, {'zipCode': '01990', 'north': 51.425629300000004, 'east': 13.7829773, 'south': 51.359064, 'west': 13.6802466}, {
  'zipCode': '01993',
  'north':   51.5324282,
  'east':    13.9185771,
  'south':   51.4849227,
  'west':    13.847204900000001
}, {'zipCode': '01994', 'north': 51.5780546, 'east': 13.9803265, 'south': 51.5302158, 'west': 13.8459394}, {
  'zipCode': '01996',
  'north':   51.4846387,
  'east':    14.0625871,
  'south':   51.4281249,
  'west':    13.9829474
}, {'zipCode': '01998', 'north': 51.5607351, 'east': 13.9439878, 'south': 51.5256297, 'west': 13.8299266}, {
  'zipCode': '02625',
  'north':   51.2320745,
  'east':    14.4926393,
  'south':   51.137272,
  'west':    14.324067199999998
}, {'zipCode': '02627', 'north': 51.3461596, 'east': 14.701653000000002, 'south': 51.1143606, 'west': 14.338791600000002}, {
  'zipCode': '02633',
  'north':   51.2281654,
  'east':    14.380226499999997,
  'south':   51.1073036,
  'west':    14.2486005
}, {'zipCode': '02681', 'north': 51.1242997, 'east': 14.488006199999997, 'south': 51.06051000000001, 'west': 14.3438846}, {
  'zipCode': '02689',
  'north':   51.0725663,
  'east':    14.4943228,
  'south':   51.0187652,
  'west':    14.361974499999999
}, {'zipCode': '02692', 'north': 51.1703226, 'east': 14.4886142, 'south': 51.1040032, 'west': 14.348256100000002}, {
  'zipCode': '02694',
  'north':   51.3572769,
  'east':    14.636048500000001,
  'south':   51.1961736,
  'west':    14.4020121
}, {'zipCode': '02699', 'north': 51.3615062, 'east': 14.425797800000002, 'south': 51.2199613, 'west': 14.2550129}, {
  'zipCode': '02708',
  'north':   51.1860745,
  'east':    14.772890800000003,
  'south':   51.0098527,
  'west':    14.536181499999998
}, {'zipCode': '02727', 'north': 50.99007, 'east': 14.630833, 'south': 50.95722380000001, 'west': 14.592235}, {
  'zipCode': '02730',
  'north':   51.0303656,
  'east':    14.6327539,
  'south':   50.9858967,
  'west':    14.558033100000001
}, {'zipCode': '02733', 'north': 51.121081600000004, 'east': 14.584103900000002, 'south': 51.0766732, 'west': 14.471752400000002}, {
  'zipCode': '02736',
  'north':   51.0826953,
  'east':    14.561253,
  'south':   51.0435549,
  'west':    14.473551600000002
}, {'zipCode': '02739', 'north': 51.020199100000006, 'east': 14.6835564, 'south': 50.9639564, 'west': 14.621022899999998}, {
  'zipCode': '02742',
  'north':   51.0580266,
  'east':    14.5958703,
  'south':   51.003742,
  'west':    14.498598399999999
}, {'zipCode': '02747', 'north': 51.0517169, 'east': 14.838239400000003, 'south': 50.9660161, 'west': 14.672645100000002}, {
  'zipCode': '02748',
  'north':   51.0973301,
  'east':    14.894962100000003,
  'south':   50.9971789,
  'west':    14.7538439
}, {'zipCode': '02763', 'north': 50.9710857, 'east': 14.8590079, 'south': 50.8199956, 'west': 14.680512300000002}, {
  'zipCode': '02779',
  'north':   50.9351505,
  'east':    14.7312324,
  'south':   50.86938390000001,
  'west':    14.6304741
}, {'zipCode': '02782', 'north': 50.96272700000001, 'east': 14.655447100000002, 'south': 50.9135484, 'west': 14.560512800000001}, {
  'zipCode': '02785',
  'north':   50.8981435,
  'east':    14.7986429,
  'south':   50.8473221,
  'west':    14.722298700000001
}, {'zipCode': '02788', 'north': 50.9995787, 'east': 14.9069448, 'south': 50.9107329, 'west': 14.8092925}, {
  'zipCode': '02791',
  'north':   50.9955929,
  'east':    14.7883797,
  'south':   50.9292461,
  'west':    14.6502222
}, {'zipCode': '02794', 'north': 50.97663200000001, 'east': 14.7071803, 'south': 50.9233994, 'west': 14.613215200000003}, {
  'zipCode': '02796',
  'north':   50.868674000000006,
  'east':    14.7282958,
  'south':   50.838002,
  'west':    14.656571900000001
}, {'zipCode': '02797', 'north': 50.8661487, 'east': 14.793129300000002, 'south': 50.8192105, 'west': 14.7087218}, {
  'zipCode': '02799',
  'north':   50.8815884,
  'east':    14.681318099999999,
  'south':   50.8485269,
  'west':    14.618888899999998
}, {'zipCode': '02826', 'north': 51.1624227, 'east': 15.000838600000002, 'south': 51.1324778, 'west': 14.953744}, {
  'zipCode': '02827',
  'north':   51.1603712,
  'east':    14.997673300000002,
  'south':   51.0525537,
  'west':    14.911679800000002
}, {'zipCode': '02828', 'north': 51.2279599, 'east': 15.017975700000001, 'south': 51.1530919, 'west': 14.934125699999997}, {
  'zipCode': '02829',
  'north':   51.3015424,
  'east':    15.041931900000002,
  'south':   51.0750545,
  'west':    14.8139827
}, {'zipCode': '02894', 'north': 51.2329101, 'east': 14.8473599, 'south': 51.0831723, 'west': 14.6695578}, {
  'zipCode': '02899',
  'north':   51.0861093,
  'east':    14.964855100000001,
  'south':   50.9722547,
  'west':    14.852961000000002
}, {'zipCode': '02906', 'north': 51.3780379, 'east': 14.8683033, 'south': 51.1921707, 'west': 14.5981297}, {
  'zipCode': '02923',
  'north':   51.4073287,
  'east':    14.9736585,
  'south':   51.1992923,
  'west':    14.804248
}, {'zipCode': '02929', 'north': 51.4329305, 'east': 15.0139953, 'south': 51.278063, 'west': 14.8549083}, {
  'zipCode': '02943',
  'north':   51.5375945,
  'east':    14.747822500000002,
  'south':   51.2995087,
  'west':    14.4578752
}, {'zipCode': '02953', 'north': 51.5972426, 'east': 14.731275700000001, 'south': 51.5188829, 'west': 14.5620621}, {
  'zipCode': '02956',
  'north':   51.4508243,
  'east':    14.9381966,
  'south':   51.3638142,
  'west':    14.686876400000001
}, {'zipCode': '02957', 'north': 51.5347932, 'east': 14.974284, 'south': 51.4287897, 'west': 14.656057200000001}, {
  'zipCode': '02959',
  'north':   51.5812163,
  'east':    14.6064295,
  'south':   51.4676312,
  'west':    14.4397673
}, {'zipCode': '02977', 'north': 51.4660263, 'east': 14.370423299999999, 'south': 51.3823547, 'west': 14.0655603}, {
  'zipCode': '02979',
  'north':   51.5446656,
  'east':    14.5242375,
  'south':   51.4103892,
  'west':    14.0676457
}, {'zipCode': '02991', 'north': 51.4878748, 'east': 14.1824651, 'south': 51.3968577, 'west': 14.0327439}, {
  'zipCode': '02994',
  'north':   51.4190734,
  'east':    14.173897,
  'south':   51.3364606,
  'west':    13.9945651
}, {'zipCode': '02997', 'north': 51.4210266, 'east': 14.3175016, 'south': 51.332411, 'west': 14.1607208}, {
  'zipCode': '02999',
  'north':   51.4521004,
  'east':    14.5007494,
  'south':   51.3055622,
  'west':    14.2813157
}, {'zipCode': '03042', 'north': 51.7982977, 'east': 14.420867800000002, 'south': 51.727943700000004, 'west': 14.338745600000001}, {
  'zipCode': '03044',
  'north':   51.8022414,
  'east':    14.375454000000001,
  'south':   51.7608511,
  'west':    14.2750831
}, {'zipCode': '03046', 'north': 51.7718343, 'east': 14.345107000000002, 'south': 51.7488504, 'west': 14.2780106}, {
  'zipCode': '03048',
  'north':   51.7513958,
  'east':    14.334602,
  'south':   51.7188054,
  'west':    14.2752349
}, {'zipCode': '03050', 'north': 51.7503484, 'east': 14.357723300000002, 'south': 51.7159089, 'west': 14.3234841}, {
  'zipCode': '03051',
  'north':   51.7424543,
  'east':    14.4503957,
  'south':   51.6924104,
  'west':    14.3022717
}, {'zipCode': '03052', 'north': 51.8151005, 'east': 14.5017195, 'south': 51.7474216, 'west': 14.366782699999998}, {
  'zipCode': '03053',
  'north':   51.8219028,
  'east':    14.411504799999998,
  'south':   51.7842067,
  'west':    14.367535000000002
}, {'zipCode': '03054', 'north': 51.8576786, 'east': 14.3938694, 'south': 51.795438, 'west': 14.3087601}, {
  'zipCode': '03055',
  'north':   51.8642527,
  'east':    14.341307,
  'south':   51.7797992,
  'west':    14.273309600000001
}, {'zipCode': '03058', 'north': 51.7795729, 'east': 14.5356064, 'south': 51.6212828, 'west': 14.2958021}, {
  'zipCode': '03096',
  'north':   51.90782250000001,
  'east':    14.3121224,
  'south':   51.7860789,
  'west':    14.0728212
}, {'zipCode': '03099', 'north': 51.8070766, 'east': 14.3096976, 'south': 51.7007423, 'west': 14.1006809}, {
  'zipCode': '03103',
  'north':   51.6397074,
  'east':    14.2131354,
  'south':   51.5403914,
  'west':    14.0577735
}, {'zipCode': '03116', 'north': 51.7280791, 'east': 14.339094600000001, 'south': 51.5801509, 'west': 14.091954000000001}, {
  'zipCode': '03119',
  'north':   51.5919126,
  'east':    14.2814952,
  'south':   51.5278447,
  'west':    14.1463014
}, {'zipCode': '03130', 'north': 51.6539705, 'east': 14.706764700000003, 'south': 51.5048989, 'west': 14.2272682}, {
  'zipCode': '03149',
  'north':   51.821932800000006,
  'east':    14.757694500000001,
  'south':   51.6476465,
  'west':    14.485319099999998
}, {'zipCode': '03159', 'north': 51.6775616, 'east': 14.765815900000002, 'south': 51.5817747, 'west': 14.555606500000001}, {
  'zipCode': '03172',
  'north':   52.0576962,
  'east':    14.7216594,
  'south':   51.8395174,
  'west':    14.3693682
}, {'zipCode': '03185', 'north': 51.9688193, 'east': 14.5653255, 'south': 51.7806356, 'west': 14.2496802}, {
  'zipCode': '03197',
  'north':   51.9598522,
  'east':    14.5938165,
  'south':   51.813773,
  'west':    14.4563234
}, {'zipCode': '03205', 'north': 51.8392487, 'east': 14.0507144, 'south': 51.6137296, 'west': 13.7979593}, {
  'zipCode': '03222',
  'north':   51.9031726,
  'east':    14.0882981,
  'south':   51.7616129,
  'west':    13.8274779
}, {'zipCode': '03226', 'north': 51.8617536, 'east': 14.1437879, 'south': 51.694647800000006, 'west': 13.9873703}, {
  'zipCode': '03229',
  'north':   51.7140151,
  'east':    14.1289174,
  'south':   51.611162400000005,
  'west':    13.9228391
}, {'zipCode': '03238', 'north': 51.6870513, 'east': 13.8903364, 'south': 51.5046918, 'west': 13.5203915}, {
  'zipCode': '03246',
  'north':   51.7599847,
  'east':    13.8349741,
  'south':   51.6672223,
  'west':    13.6952345
}, {'zipCode': '03249', 'north': 51.7542549, 'east': 13.7867315, 'south': 51.6397969, 'west': 13.5073979}, {
  'zipCode': '03253',
  'north':   51.7051616,
  'east':    13.6493645,
  'south':   51.54498250000001,
  'west':    13.3383122
}, {'zipCode': '04103', 'north': 51.3525898, 'east': 12.4065229, 'south': 51.3120698, 'west': 12.3773843}, {
  'zipCode': '04105',
  'north':   51.3614756,
  'east':    12.386026,
  'south':   51.3393194,
  'west':    12.3356302
}, {'zipCode': '04107', 'north': 51.3360274, 'east': 12.3829279, 'south': 51.3241037, 'west': 12.3537326}, {
  'zipCode': '04109',
  'north':   51.3460581,
  'east':    12.383539,
  'south':   51.3302758,
  'west':    12.3457261
}, {'zipCode': '04129', 'north': 51.3889197, 'east': 12.4014071, 'south': 51.3525898, 'west': 12.3713706}, {
  'zipCode': '04155',
  'north':   51.367241,
  'east':    12.378307400000002,
  'south':   51.3546116,
  'west':    12.3408073
}, {'zipCode': '04157', 'north': 51.3862753, 'east': 12.3823218, 'south': 51.3614756, 'west': 12.345998}, {
  'zipCode': '04158',
  'north':   51.4248829,
  'east':    12.3962253,
  'south':   51.3815033,
  'west':    12.2609378
}, {'zipCode': '04159', 'north': 51.4036478, 'east': 12.3589752, 'south': 51.3554657, 'west': 12.2478225}, {
  'zipCode': '04177',
  'north':   51.3561945,
  'east':    12.3457947,
  'south':   51.3289199,
  'west':    12.3128147
}, {'zipCode': '04178', 'north': 51.3763291, 'east': 12.3084401, 'south': 51.33004, 'west': 12.2376696}, {
  'zipCode': '04179',
  'north':   51.3647706,
  'east':    12.3398479,
  'south':   51.3206297,
  'west':    12.2939718
}, {'zipCode': '04205', 'north': 51.33915210000001, 'east': 12.3034696, 'south': 51.3145882, 'west': 12.2366519}, {
  'zipCode': '04207',
  'north':   51.32134610000001,
  'east':    12.3194895,
  'south':   51.2865053,
  'west':    12.2398703
}, {'zipCode': '04209', 'north': 51.3270594, 'east': 12.3134405, 'south': 51.3115305, 'west': 12.2776356}, {
  'zipCode': '04229',
  'north':   51.33919010000001,
  'east':    12.3572981,
  'south':   51.3068164,
  'west':    12.3176072
}, {'zipCode': '04249', 'north': 51.3128015, 'east': 12.3535523, 'south': 51.2381704, 'west': 12.2474717}, {
  'zipCode': '04275',
  'north':   51.3280607,
  'east':    12.3897009,
  'south':   51.3110966,
  'west':    12.3539679
}, {'zipCode': '04277', 'north': 51.3205957, 'east': 12.4074953, 'south': 51.286731700000004, 'west': 12.3397801}, {
  'zipCode': '04279',
  'north':   51.3047005,
  'east':    12.4211507,
  'south':   51.2789789,
  'west':    12.3744999
}, {'zipCode': '04288', 'north': 51.31991210000001, 'east': 12.50533, 'south': 51.2576156, 'west': 12.438024}, {
  'zipCode': '04289',
  'north':   51.3145716,
  'east':    12.4488081,
  'south':   51.2827375,
  'west':    12.4037543
}, {'zipCode': '04299', 'north': 51.3316781, 'east': 12.449012200000002, 'south': 51.3053206, 'west': 12.400218700000002}, {
  'zipCode': '04315',
  'north':   51.3502404,
  'east':    12.4252731,
  'south':   51.337852500000004,
  'west':    12.3922623
}, {'zipCode': '04316', 'north': 51.3400612, 'east': 12.5133478, 'south': 51.3160361, 'west': 12.428106}, {
  'zipCode': '04317',
  'north':   51.3426785,
  'east':    12.4177937,
  'south':   51.3191507,
  'west':    12.392583000000002
}, {'zipCode': '04318', 'north': 51.3590758, 'east': 12.442708, 'south': 51.327414, 'west': 12.4067408}, {
  'zipCode': '04319',
  'north':   51.3610099,
  'east':    12.542440700000002,
  'south':   51.303214800000006,
  'west':    12.4578481
}, {'zipCode': '04328', 'north': 51.3593999, 'east': 12.473854, 'south': 51.3395918, 'west': 12.4337151}, {
  'zipCode': '04329',
  'north':   51.374454,
  'east':    12.4939818,
  'south':   51.3458402,
  'west':    12.4419825
}, {'zipCode': '04347', 'north': 51.3753218, 'east': 12.467100200000003, 'south': 51.3488292, 'west': 12.386026}, {
  'zipCode': '04349',
  'north':   51.4117969,
  'east':    12.4766128,
  'south':   51.3649939,
  'west':    12.413057
}, {'zipCode': '04356', 'north': 51.4481145, 'east': 12.4939828, 'south': 51.3786056, 'west': 12.3785476}, {
  'zipCode': '04357',
  'north':   51.3939589,
  'east':    12.4270265,
  'south':   51.360120300000005,
  'west':    12.3961454
}, {'zipCode': '04416', 'north': 51.2964713, 'east': 12.4492932, 'south': 51.24043540000001, 'west': 12.3222057}, {
  'zipCode': '04420',
  'north':   51.3471581,
  'east':    12.281317,
  'south':   51.2384427,
  'west':    12.1477875
}, {'zipCode': '04425', 'north': 51.4228932, 'east': 12.5763565, 'south': 51.3604145, 'west': 12.4566752}, {
  'zipCode': '04435',
  'north':   51.4616464,
  'east':    12.3546357,
  'south':   51.3325537,
  'west':    12.1724328
}, {'zipCode': '04442', 'north': 51.259865, 'east': 12.3690661, 'south': 51.1764668, 'west': 12.2367873}, {
  'zipCode': '04451',
  'north':   51.3751873,
  'east':    12.5757169,
  'south':   51.324182400000005,
  'west':    12.4988815
}, {'zipCode': '04463', 'north': 51.3059361, 'east': 12.544078, 'south': 51.2068558, 'west': 12.4019444}, {
  'zipCode': '04509',
  'north':   51.6103995,
  'east':    12.5282127,
  'south':   51.4207604,
  'west':    12.148537200000002
}, {'zipCode': '04519', 'north': 51.4933178, 'east': 12.417891, 'south': 51.4153495, 'west': 12.3094165}, {
  'zipCode': '04523',
  'north':   51.2601602,
  'east':    12.3004566,
  'south':   51.1322454,
  'west':    12.1736829
}, {'zipCode': '04539', 'north': 51.18295, 'east': 12.3899992, 'south': 51.0902838, 'west': 12.2200325}, {
  'zipCode': '04552',
  'north':   51.1783899,
  'east':    12.5662635,
  'south':   51.0639743,
  'west':    12.4460891
}, {'zipCode': '04564', 'north': 51.247565, 'east': 12.433447, 'south': 51.1651652, 'west': 12.3431221}, {
  'zipCode': '04565',
  'north':   51.1283027,
  'east':    12.4635029,
  'south':   51.072827,
  'west':    12.3522066
}, {'zipCode': '04567', 'north': 51.206239, 'east': 12.5850093, 'south': 51.1285108, 'west': 12.5078025}, {
  'zipCode': '04571',
  'north':   51.242208,
  'east':    12.5627552,
  'south':   51.171952,
  'west':    12.3924805
}, {'zipCode': '04575', 'north': 51.1879189, 'east': 12.489778, 'south': 51.0984195, 'west': 12.3236628}, {
  'zipCode': '04600',
  'north':   51.0346991,
  'east':    12.4956935,
  'south':   50.9221977,
  'west':    12.3721597
}, {'zipCode': '04603', 'north': 51.0485983, 'east': 12.6539178, 'south': 50.8838826, 'west': 12.33909}, {
  'zipCode': '04610',
  'north':   51.084737,
  'east':    12.4358575,
  'south':   51.0152352,
  'west':    12.250807
}, {'zipCode': '04613', 'north': 51.1034297, 'east': 12.3676947, 'south': 51.0613161, 'west': 12.2780758}, {
  'zipCode': '04617',
  'north':   51.0847066,
  'east':    12.508916,
  'south':   50.955856,
  'west':    12.2324488
}, {'zipCode': '04618', 'north': 50.9996779, 'east': 12.62976, 'south': 50.9025454, 'west': 12.5047393}, {
  'zipCode': '04626',
  'north':   50.9763182,
  'east':    12.4106018,
  'south':   50.8096217,
  'west':    12.2077868
}, {'zipCode': '04639', 'north': 50.9032305, 'east': 12.4881867, 'south': 50.8394675, 'west': 12.3521903}, {
  'zipCode': '04643',
  'north':   51.10351,
  'east':    12.7466319,
  'south':   50.9850434,
  'west':    12.621939
}, {'zipCode': '04651', 'north': 51.1931033, 'east': 12.7539617, 'south': 51.0899299, 'west': 12.566551400000002}, {
  'zipCode': '04654',
  'north':   51.1431428,
  'east':    12.7225123,
  'south':   50.979659,
  'west':    12.5011373
}, {'zipCode': '04668', 'north': 51.31250450000001, 'east': 12.9316916, 'south': 51.1564913, 'west': 12.5619398}, {
  'zipCode': '04680',
  'north':   51.18153,
  'east':    12.898327600000002,
  'south':   51.0912523,
  'west':    12.6747468
}, {'zipCode': '04683', 'north': 51.3152468, 'east': 12.6754815, 'south': 51.2069362, 'west': 12.5140366}, {
  'zipCode': '04687',
  'north':   51.31465060000001,
  'east':    12.8046944,
  'south':   51.2578762,
  'west':    12.6569841
}, {'zipCode': '04703', 'north': 51.2251965, 'east': 13.0243556, 'south': 51.1270215, 'west': 12.849220400000002}, {
  'zipCode': '04720',
  'north':   51.2089357,
  'east':    13.2590717,
  'south':   51.0782729,
  'west':    12.9941035
}, {'zipCode': '04736', 'north': 51.1050386, 'east': 13.1076545, 'south': 51.0471141, 'west': 12.9556393}, {
  'zipCode': '04741',
  'north':   51.1122599,
  'east':    13.2604024,
  'south':   51.048394,
  'west':    13.0810326
}, {'zipCode': '04746', 'north': 51.1568327, 'east': 13.042489600000001, 'south': 51.0707431, 'west': 12.885492}, {
  'zipCode': '04749',
  'north':   51.235925,
  'east':    13.2126425,
  'south':   51.1702779,
  'west':    13.0375383
}, {'zipCode': '04758', 'north': 51.4360862, 'east': 13.217291800000002, 'south': 51.2307709, 'west': 13.0199224}, {
  'zipCode': '04769',
  'north':   51.2732363,
  'east':    13.1818017,
  'south':   51.1997191,
  'west':    12.9054676
}, {'zipCode': '04774', 'north': 51.4330365, 'east': 13.0728582, 'south': 51.3144819, 'west': 12.9022785}, {
  'zipCode': '04779',
  'north':   51.355024,
  'east':    13.049714100000001,
  'south':   51.2408384,
  'west':    12.868661100000002
}, {'zipCode': '04808', 'north': 51.4686953, 'east': 12.943139, 'south': 51.3008217, 'west': 12.6442878}, {
  'zipCode': '04821',
  'north':   51.35197430000001,
  'east':    12.693479800000002,
  'south':   51.300051200000006,
  'west':    12.5757169
}, {'zipCode': '04824', 'north': 51.3409012, 'east': 12.6008699, 'south': 51.3028631, 'west': 12.5313478}, {
  'zipCode': '04827',
  'north':   51.3847327,
  'east':    12.6668379,
  'south':   51.3409012,
  'west':    12.5593997
}, {'zipCode': '04828', 'north': 51.4206994, 'east': 12.7587718, 'south': 51.2952204, 'west': 12.6094035}, {
  'zipCode': '04838',
  'north':   51.5875968,
  'east':    12.777269100000002,
  'south':   51.3733612,
  'west':    12.4840976
}, {'zipCode': '04849', 'north': 51.6629546, 'east': 12.7782109, 'south': 51.541284, 'west': 12.4877742}, {
  'zipCode': '04860',
  'north':   51.6018938,
  'east':    13.1058704,
  'south':   51.5157801,
  'west':    12.8028996
}, {'zipCode': '04861', 'north': 51.5394378, 'east': 13.0718724, 'south': 51.4758384, 'west': 12.9193272}, {
  'zipCode': '04862',
  'north':   51.5688559,
  'east':    12.9361454,
  'south':   51.448459500000006,
  'west':    12.733417
}, {'zipCode': '04874', 'north': 51.5236069, 'east': 13.2038752, 'south': 51.4060143, 'west': 13.0210725}, {
  'zipCode': '04880',
  'north':   51.6848692,
  'east':    13.0174297,
  'south':   51.5653111,
  'west':    12.7065159
}, {'zipCode': '04886', 'north': 51.6692894, 'east': 13.2083488, 'south': 51.4777247, 'west': 12.9316506}, {
  'zipCode': '04889',
  'north':   51.4866636,
  'east':    13.0727073,
  'south':   51.4221452,
  'west':    12.8337148
}, {'zipCode': '04895', 'north': 51.6558753, 'east': 13.3345338, 'south': 51.4419448, 'west': 13.1414109}, {
  'zipCode': '04910',
  'north':   51.5170793,
  'east':    13.605974500000002,
  'south':   51.4262448,
  'west':    13.4574583
}, {'zipCode': '04916', 'north': 51.885751600000006, 'east': 13.3921649, 'south': 51.5973877, 'west': 13.0509726}, {
  'zipCode': '04924',
  'north':   51.5981789,
  'east':    13.5552075,
  'south':   51.4248801,
  'west':    13.2466013
}, {'zipCode': '04928', 'north': 51.5037037, 'east': 13.7221644, 'south': 51.4165779, 'west': 13.5498509}, {
  'zipCode': '04931',
  'north':   51.5035771,
  'east':    13.3735671,
  'south':   51.3845847,
  'west':    13.1740192
}, {'zipCode': '04932', 'north': 51.5053273, 'east': 13.7017091, 'south': 51.3677303, 'west': 13.4011114}, {
  'zipCode': '04934',
  'north':   51.5338548,
  'east':    13.6395115,
  'south':   51.4620015,
  'west':    13.510488800000001
}, {'zipCode': '04936', 'north': 51.8198832, 'east': 13.5485758, 'south': 51.658495, 'west': 13.2919208}, {
  'zipCode': '04938',
  'north':   51.6589286,
  'east':    13.388901,
  'south':   51.5644108,
  'west':    13.2726548
}, {'zipCode': '06108', 'north': 51.5028776, 'east': 11.9819301, 'south': 51.477875700000006, 'west': 11.9444876}, {
  'zipCode': '06110',
  'north':   51.4802831,
  'east':    11.9843412,
  'south':   51.45625410000001,
  'west':    11.9526387
}, {'zipCode': '06112', 'north': 51.4977608, 'east': 12.0269827, 'south': 51.4429996, 'west': 11.9759054}, {
  'zipCode': '06114',
  'north':   51.5103501,
  'east':    11.9908009,
  'south':   51.4899249,
  'west':    11.9486914
}, {'zipCode': '06116', 'north': 51.5028573, 'east': 12.0883745, 'south': 51.4372794, 'west': 11.9909632}, {
  'zipCode': '06118',
  'north':   51.5437203,
  'east':    12.0301118,
  'south':   51.4975914,
  'west':    11.9221284
}, {'zipCode': '06120', 'north': 51.5355334, 'east': 11.9573068, 'south': 51.481758, 'west': 11.8555117}, {
  'zipCode': '06122',
  'north':   51.4917029,
  'east':    11.9476989,
  'south':   51.4791489,
  'west':    11.9090232
}, {'zipCode': '06124', 'north': 51.4820931, 'east': 11.9557267, 'south': 51.4518217, 'west': 11.9016746}, {
  'zipCode': '06126',
  'north':   51.4966069,
  'east':    11.9155332,
  'south':   51.4621521,
  'west':    11.86684
}, {'zipCode': '06128', 'north': 51.47018380000001, 'east': 11.9670005, 'south': 51.4337118, 'west': 11.9142991}, {
  'zipCode': '06130',
  'north':   51.4583524,
  'east':    12.012172,
  'south':   51.445197,
  'west':    11.959307700000002
}, {'zipCode': '06132', 'north': 51.447354600000004, 'east': 12.0282634, 'south': 51.4030729, 'west': 11.9412404}, {
  'zipCode': '06179',
  'north':   51.497952,
  'east':    11.931049800000002,
  'south':   51.3900854,
  'west':    11.7050973
}, {'zipCode': '06184', 'north': 51.486605600000004, 'east': 12.1942421, 'south': 51.4064928, 'west': 12.0207067}, {
  'zipCode': '06188',
  'north':   51.5826837,
  'east':    12.2078606,
  'south':   51.4566892,
  'west':    11.9905303
}, {'zipCode': '06193', 'north': 51.6749389, 'east': 12.0879804, 'south': 51.5242649, 'west': 11.7438285}, {
  'zipCode': '06198',
  'north':   51.6106855,
  'east':    11.8800326,
  'south':   51.4656095,
  'west':    11.6858606
}, {'zipCode': '06217', 'north': 51.3908853, 'east': 12.039402900000002, 'south': 51.2903029, 'west': 11.8983456}, {
  'zipCode': '06231',
  'north':   51.3205945,
  'east':    12.1718907,
  'south':   51.2489074,
  'west':    12.0401116
}, {'zipCode': '06237', 'north': 51.3789546, 'east': 12.1963089, 'south': 51.2742449, 'west': 11.9699582}, {
  'zipCode': '06242',
  'north':   51.34324420000001,
  'east':    11.9735751,
  'south':   51.2345009,
  'west':    11.8171359
}, {'zipCode': '06246', 'north': 51.425282, 'east': 11.9202693, 'south': 51.3323513, 'west': 11.7138676}, {
  'zipCode': '06249',
  'north':   51.337387,
  'east':    11.8475257,
  'south':   51.267538300000005,
  'west':    11.7303587
}, {'zipCode': '06255', 'north': 51.3654757, 'east': 11.821482, 'south': 51.3264873, 'west': 11.7644588}, {
  'zipCode': '06258',
  'north':   51.4388854,
  'east':    12.1826592,
  'south':   51.3355114,
  'west':    11.9033781
}, {'zipCode': '06259', 'north': 51.3441087, 'east': 11.9758433, 'south': 51.28258840000001, 'west': 11.8964246}, {
  'zipCode': '06268',
  'north':   51.4324799,
  'east':    11.7969328,
  'south':   51.2668098,
  'west':    11.393037
}, {'zipCode': '06279', 'north': 51.4607535, 'east': 11.6781609, 'south': 51.40645500000001, 'west': 11.5243254}, {
  'zipCode': '06295',
  'north':   51.6018554,
  'east':    11.6729275,
  'south':   51.4128401,
  'west':    11.4563942
}, {'zipCode': '06308', 'north': 51.6037993, 'east': 11.5314677, 'south': 51.5599619, 'west': 11.4444015}, {
  'zipCode': '06311',
  'north':   51.5750932,
  'east':    11.5232571,
  'south':   51.53547280000001,
  'west':    11.4662058
}, {'zipCode': '06313', 'north': 51.5629174, 'east': 11.5216332, 'south': 51.5012894, 'west': 11.4108521}, {
  'zipCode': '06317',
  'north':   51.5707243,
  'east':    11.7813035,
  'south':   51.4229642,
  'west':    11.5550982
}, {'zipCode': '06333', 'north': 51.7353367, 'east': 11.5696194, 'south': 51.6101112, 'west': 11.3400168}, {
  'zipCode': '06343',
  'north':   51.6617117,
  'east':    11.5153564,
  'south':   51.5279305,
  'west':    11.1980633
}, {'zipCode': '06347', 'north': 51.6635493, 'east': 11.7636506, 'south': 51.56484760000001, 'west': 11.5104741}, {
  'zipCode': '06366',
  'north':   51.7786752,
  'east':    12.0211339,
  'south':   51.7159923,
  'west':    11.934862700000002
}, {'zipCode': '06369', 'north': 51.7924189, 'east': 12.1777737, 'south': 51.6423217, 'west': 11.8467055}, {
  'zipCode': '06385',
  'north':   51.8794859,
  'east':    12.1438535,
  'south':   51.8180647,
  'west':    11.9549695
}, {'zipCode': '06386', 'north': 51.8813411, 'east': 12.1959716, 'south': 51.7027347, 'west': 11.8467287}, {
  'zipCode': '06388',
  'north':   51.7311306,
  'east':    12.0012949,
  'south':   51.645108300000004,
  'west':    11.8386644
}, {'zipCode': '06406', 'north': 51.8477697, 'east': 11.9044149, 'south': 51.7173193, 'west': 11.647061}, {
  'zipCode': '06408',
  'north':   51.83536300000001,
  'east':    11.6966169,
  'south':   51.77213110000001,
  'west':    11.639861
}, {'zipCode': '06420', 'north': 51.7448438, 'east': 11.8655475, 'south': 51.6288444, 'west': 11.6036584}, {
  'zipCode': '06425',
  'north':   51.7676968,
  'east':    11.7341915,
  'south':   51.6554869,
  'west':    11.5997703
}, {'zipCode': '06429', 'north': 51.8810233, 'east': 11.8826936, 'south': 51.7875495, 'west': 11.6584409}, {
  'zipCode': '06449',
  'north':   51.8770426,
  'east':    11.6359369,
  'south':   51.684618900000004,
  'west':    11.3084672
}, {'zipCode': '06456', 'north': 51.729955, 'east': 11.6128263, 'south': 51.6106124, 'west': 11.306604}, {
  'zipCode': '06458',
  'north':   51.9097546,
  'east':    11.344653,
  'south':   51.807914,
  'west':    11.2223698
}, {'zipCode': '06463', 'north': 51.7795675, 'east': 11.4083969, 'south': 51.6699187, 'west': 11.205449}, {
  'zipCode': '06464',
  'north':   51.81268190000001,
  'east':    11.4137134,
  'south':   51.7708734,
  'west':    11.332155
}, {'zipCode': '06466', 'north': 51.8359834, 'east': 11.3521928, 'south': 51.7979158, 'west': 11.235026}, {
  'zipCode': '06467',
  'north':   51.8068743,
  'east':    11.3473618,
  'south':   51.7571527,
  'west':    11.258592900000002
}, {'zipCode': '06469', 'north': 51.822986, 'east': 11.390655, 'south': 51.7951652, 'west': 11.3083047}, {
  'zipCode': '06484',
  'north':   51.8607591,
  'east':    11.2539452,
  'south':   51.74273320000001,
  'west':    11.0552777
}, {'zipCode': '06485', 'north': 51.7505277, 'east': 11.1641944, 'south': 51.6643797, 'west': 11.0544836}, {
  'zipCode': '06493',
  'north':   51.7997218,
  'east':    11.3132004,
  'south':   51.5660547,
  'west':    10.897026
}, {'zipCode': '06502', 'north': 51.8382973, 'east': 11.1150009, 'south': 51.66042060000001, 'west': 10.8866266}, {
  'zipCode': '06526',
  'north':   51.5986567,
  'east':    11.3870626,
  'south':   51.4143535,
  'west':    11.0710041
}, {'zipCode': '06528', 'north': 51.52659040000001, 'east': 11.467324, 'south': 51.3951553, 'west': 11.1420668}, {
  'zipCode': '06536',
  'north':   51.6337943,
  'east':    11.2001888,
  'south':   51.4318828,
  'west':    10.8827728
}, {'zipCode': '06537', 'north': 51.4618685, 'east': 11.176601500000002, 'south': 51.40173200000001, 'west': 10.9780759}, {
  'zipCode': '06542',
  'north':   51.561323,
  'east':    11.504301,
  'south':   51.339283300000005,
  'west':    11.3167103
}, {'zipCode': '06543', 'north': 51.713245, 'east': 11.3580133, 'south': 51.6383998, 'west': 11.2042378}, {
  'zipCode': '06556',
  'north':   51.4132644,
  'east':    11.4015558,
  'south':   51.3076572,
  'west':    11.199293
}, {'zipCode': '06567', 'north': 51.4136342, 'east': 11.2516258, 'south': 51.3107201, 'west': 11.0543903}, {
  'zipCode': '06571',
  'north':   51.3445121,
  'east':    11.4757498,
  'south':   51.2365672,
  'west':    11.2838209
}, {'zipCode': '06577', 'north': 51.3262144, 'east': 11.3284197, 'south': 51.2345238, 'west': 11.1505396}, {
  'zipCode': '06578',
  'north':   51.3463057,
  'east':    11.2206864,
  'south':   51.2542496,
  'west':    11.0299282
}, {'zipCode': '06618', 'north': 51.2016644, 'east': 11.921129200000001, 'south': 51.0426583, 'west': 11.6995414}, {
  'zipCode': '06628',
  'north':   51.17911110000001,
  'east':    11.7716512,
  'south':   51.0786607,
  'west':    11.6000003
}, {'zipCode': '06632', 'north': 51.2836152, 'east': 11.8727655, 'south': 51.1656995, 'west': 11.605811}, {
  'zipCode': '06636',
  'north':   51.2651563,
  'east':    11.7181491,
  'south':   51.1900605,
  'west':    11.599691
}, {'zipCode': '06638', 'north': 51.3059759, 'east': 11.6814355, 'south': 51.2450331, 'west': 11.5956284}, {
  'zipCode': '06642',
  'north':   51.3205212,
  'east':    11.6352442,
  'south':   51.2237421,
  'west':    11.3998209
}, {'zipCode': '06647', 'north': 51.2614843, 'east': 11.6661404, 'south': 51.112906, 'west': 11.3651983}, {
  'zipCode': '06648',
  'north':   51.1565062,
  'east':    11.6106811,
  'south':   51.1023872,
  'west':    11.462747700000001
}, {'zipCode': '06667', 'north': 51.2757303, 'east': 12.0379064, 'south': 51.0961879, 'west': 11.8396937}, {
  'zipCode': '06679',
  'north':   51.2052746,
  'east':    12.2132035,
  'south':   51.1141129,
  'west':    12.0153537
}, {'zipCode': '06682', 'north': 51.16708920000001, 'east': 12.1080898, 'south': 51.082048, 'west': 11.8830104}, {
  'zipCode': '06686',
  'north':   51.2790855,
  'east':    12.2048344,
  'south':   51.1663132,
  'west':    11.9992642
}, {'zipCode': '06688', 'north': 51.2887748, 'east': 12.074734500000002, 'south': 51.2271017, 'west': 11.9761601}, {
  'zipCode': '06711',
  'north':   51.1301383,
  'east':    12.1820202,
  'south':   51.05994580000001,
  'west':    12.045782600000003
}, {'zipCode': '06712', 'north': 51.0912297, 'east': 12.277893100000002, 'south': 50.9378508, 'west': 12.0113042}, {
  'zipCode': '06721',
  'north':   51.1088996,
  'east':    12.0280266,
  'south':   51.0261443,
  'west':    11.8674303
}, {'zipCode': '06722', 'north': 51.0592794, 'east': 12.1011285, 'south': 50.9681117, 'west': 11.9479222}, {
  'zipCode': '06729',
  'north':   51.1427363,
  'east':    12.2947341,
  'south':   51.0057851,
  'west':    12.1469418
}, {'zipCode': '06749', 'north': 51.6588854, 'east': 12.3942537, 'south': 51.5904398, 'west': 12.2795005}, {
  'zipCode': '06766',
  'north':   51.7054651,
  'east':    12.2980322,
  'south':   51.6344316,
  'west':    12.1785665
}, {'zipCode': '06772', 'north': 51.7793666, 'east': 12.6418008, 'south': 51.6181247, 'west': 12.3247555}, {
  'zipCode': '06773',
  'north':   51.8004885,
  'east':    12.6117096,
  'south':   51.6605587,
  'west':    12.3385707
}, {'zipCode': '06774', 'north': 51.7055876, 'east': 12.5804932, 'south': 51.5819495, 'west': 12.3158275}, {
  'zipCode': '06779',
  'north':   51.8000606,
  'east':    12.3635351,
  'south':   51.6915127,
  'west':    12.1651128
}, {'zipCode': '06780', 'north': 51.71591, 'east': 12.2242848, 'south': 51.5762823, 'west': 12.0069837}, {
  'zipCode': '06785',
  'north':   51.8849696,
  'east':    12.5329056,
  'south':   51.7746321,
  'west':    12.2845399
}, {'zipCode': '06792', 'north': 51.6451686, 'east': 12.286774500000002, 'south': 51.5897253, 'west': 12.1946759}, {
  'zipCode': '06794',
  'north':   51.6231094,
  'east':    12.2326122,
  'south':   51.5694029,
  'west':    12.1573713
}, {'zipCode': '06796', 'north': 51.5845693, 'east': 12.2441766, 'south': 51.5311361, 'west': 12.1648768}, {
  'zipCode': '06800',
  'north':   51.7050739,
  'east':    12.3897736,
  'south':   51.6570379,
  'west':    12.2766705
}, {'zipCode': '06803', 'north': 51.6655756, 'east': 12.328748, 'south': 51.632669, 'west': 12.2525972}, {
  'zipCode': '06808',
  'north':   51.6171926,
  'east':    12.393617,
  'south':   51.5824705,
  'west':    12.2800814
}, {'zipCode': '06809', 'north': 51.6014603, 'east': 12.3437525, 'south': 51.5587489, 'west': 12.2125046}, {
  'zipCode': '06842',
  'north':   51.8338391,
  'east':    12.3518038,
  'south':   51.7331378,
  'west':    12.2158132
}, {'zipCode': '06844', 'north': 51.881105, 'east': 12.3237442, 'south': 51.8197545, 'west': 12.2278611}, {
  'zipCode': '06846',
  'north':   51.8780334,
  'east':    12.2385146,
  'south':   51.8136983,
  'west':    12.1314374
}, {'zipCode': '06847', 'north': 51.8358685, 'east': 12.242699, 'south': 51.7570047, 'west': 12.1098646}, {
  'zipCode': '06849',
  'north':   51.8166551,
  'east':    12.2813819,
  'south':   51.78127390000001,
  'west':    12.233968
}, {'zipCode': '06861', 'north': 51.9369394, 'east': 12.2477966, 'south': 51.876866, 'west': 12.1540972}, {
  'zipCode': '06862',
  'north':   51.9706092,
  'east':    12.3209087,
  'south':   51.8576236,
  'west':    12.1137753
}, {'zipCode': '06868', 'north': 52.0448773, 'east': 12.4607447, 'south': 51.9394634, 'west': 12.2522106}, {
  'zipCode': '06869',
  'north':   52.0347658,
  'east':    12.6041177,
  'south':   51.8468086,
  'west':    12.308453
}, {'zipCode': '06886', 'north': 51.9198236, 'east': 12.7374486, 'south': 51.8525215, 'west': 12.4849047}, {
  'zipCode': '06888',
  'north':   51.9516185,
  'east':    12.738505400000001,
  'south':   51.818688800000004,
  'west':    12.4955285
}, {'zipCode': '06889', 'north': 52.013327, 'east': 12.7767377, 'south': 51.8792052, 'west': 12.5549799}, {
  'zipCode': '06895',
  'north':   51.9675816,
  'east':    12.910555600000002,
  'south':   51.80954320000001,
  'west':    12.7096423
}, {'zipCode': '06901', 'north': 51.8553511, 'east': 12.8248152, 'south': 51.685104, 'west': 12.4246215}, {
  'zipCode': '06905',
  'north':   51.7777465,
  'east':    12.8454569,
  'south':   51.6109977,
  'west':    12.6076267
}, {'zipCode': '06917', 'north': 51.9393869, 'east': 13.1705286, 'south': 51.7076889, 'west': 12.776261}, {
  'zipCode': '06925',
  'north':   51.7810314,
  'east':    13.1868819,
  'south':   51.6374205,
  'west':    12.8282991
}, {'zipCode': '07318', 'north': 50.6794944, 'east': 11.4239807, 'south': 50.5258718, 'west': 11.1923783}, {
  'zipCode': '07330',
  'north':   50.61279760000001,
  'east':    11.4827734,
  'south':   50.498751,
  'west':    11.2728443
}, {'zipCode': '07333', 'north': 50.7110672, 'east': 11.5204999, 'south': 50.607286, 'west': 11.3551797}, {
  'zipCode': '07338',
  'north':   50.6427059,
  'east':    11.6339205,
  'south':   50.5211092,
  'west':    11.3750066
}, {'zipCode': '07343', 'north': 50.5240634, 'east': 11.5920976, 'south': 50.373975900000005, 'west': 11.4777989}, {
  'zipCode': '07349',
  'north':   50.5135744,
  'east':    11.4983544,
  'south':   50.4206842,
  'west':    11.4153809
}, {'zipCode': '07356', 'north': 50.4939148, 'east': 11.7334891, 'south': 50.4059597, 'west': 11.5539376}, {
  'zipCode': '07366',
  'north':   50.4507309,
  'east':    11.7681575,
  'south':   50.3867535,
  'west':    11.5476684
}, {'zipCode': '07368', 'north': 50.6050163, 'east': 11.7091065, 'south': 50.479508, 'west': 11.4815013}, {
  'zipCode': '07381',
  'north':   50.762660000000004,
  'east':    11.7114123,
  'south':   50.59104640000001,
  'west':    11.537533
}, {'zipCode': '07387', 'north': 50.7432256, 'east': 11.5667205, 'south': 50.6193666, 'west': 11.4601057}, {
  'zipCode': '07389',
  'north':   50.6906835,
  'east':    11.7482997,
  'south':   50.596624,
  'west':    11.5191647
}, {'zipCode': '07407', 'north': 50.8349945, 'east': 11.5495573, 'south': 50.6683096, 'west': 11.175128300000003}, {
  'zipCode': '07422',
  'north':   50.7125399,
  'east':    11.3129282,
  'south':   50.6391077,
  'west':    11.1831516
}, {'zipCode': '07426', 'north': 50.7357515, 'east': 11.2262534, 'south': 50.603426, 'west': 11.0089405}, {
  'zipCode': '07427',
  'north':   50.6703827,
  'east':    11.260394,
  'south':   50.62070430000001,
  'west':    11.1628911
}, {'zipCode': '07429', 'north': 50.6418689, 'east': 11.2660902, 'south': 50.5930926, 'west': 11.1457563}, {
  'zipCode': '07545',
  'north':   50.8942449,
  'east':    12.0954062,
  'south':   50.8624158,
  'west':    12.0728551
}, {'zipCode': '07546', 'north': 50.9127616, 'east': 12.140988600000002, 'south': 50.8623837, 'west': 12.0575483}, {
  'zipCode': '07548',
  'north':   50.91975120000001,
  'east':    12.0837851,
  'south':   50.8415223,
  'west':    11.9981564
}, {'zipCode': '07549', 'north': 50.8566092, 'east': 12.0795991, 'south': 50.8189426, 'west': 12.0374737}, {
  'zipCode': '07551',
  'north':   50.873151,
  'east':    12.154761,
  'south':   50.79861580000001,
  'west':    12.0706196
}, {'zipCode': '07552', 'north': 50.9326877, 'east': 12.1149373, 'south': 50.8943144, 'west': 12.0295556}, {
  'zipCode': '07554',
  'north':   50.9765857,
  'east':    12.2156954,
  'south':   50.8187327,
  'west':    12.0167707
}, {'zipCode': '07557', 'north': 50.8496957, 'east': 12.0800111, 'south': 50.7920707, 'west': 11.9661021}, {
  'zipCode': '07570',
  'north':   50.8263627,
  'east':    12.174745,
  'south':   50.7216108,
  'west':    11.8961035
}, {'zipCode': '07580', 'north': 50.9415904, 'east': 12.2900746, 'south': 50.7458064, 'west': 12.1359561}, {
  'zipCode': '07586',
  'north':   50.9567241,
  'east':    12.0494892,
  'south':   50.8607097,
  'west':    11.8720082
}, {'zipCode': '07589', 'north': 50.8727033, 'east': 12.0156596, 'south': 50.7799644, 'west': 11.8588328}, {
  'zipCode': '07607',
  'north':   51.0003385,
  'east':    11.9329253,
  'south':   50.9350181,
  'west':    11.8238417
}, {'zipCode': '07613', 'north': 51.0395785, 'east': 12.0210838, 'south': 50.9221665, 'west': 11.8424768}, {
  'zipCode': '07616',
  'north':   50.9984078,
  'east':    11.8793027,
  'south':   50.9089335,
  'west':    11.6916014
}, {'zipCode': '07619', 'north': 51.0572723, 'east': 11.9152451, 'south': 50.96846690000001, 'west': 11.737014}, {
  'zipCode': '07629',
  'north':   50.9093244,
  'east':    11.9210075,
  'south':   50.8322852,
  'west':    11.8082246
}, {'zipCode': '07639', 'north': 50.9509946, 'east': 11.9526698, 'south': 50.8911095, 'west': 11.8084204}, {
  'zipCode': '07646',
  'north':   50.9351912,
  'east':    11.909715,
  'south':   50.76074820000001,
  'west':    11.6205018
}, {'zipCode': '07743', 'north': 50.9768214, 'east': 11.6355793, 'south': 50.9195877, 'west': 11.5347032}, {
  'zipCode': '07745',
  'north':   50.9347813,
  'east':    11.602532500000002,
  'south':   50.8709026,
  'west':    11.5042226
}, {'zipCode': '07747', 'north': 50.9065329, 'east': 11.643328, 'south': 50.8751423, 'west': 11.5947858}, {
  'zipCode': '07749',
  'north':   50.9502854,
  'east':    11.6442469,
  'south':   50.8993704,
  'west':    11.5838659
}, {'zipCode': '07751', 'north': 50.9897042, 'east': 11.727754, 'south': 50.8083187, 'west': 11.4382892}, {
  'zipCode': '07768',
  'north':   50.8569469,
  'east':    11.6822627,
  'south':   50.7468315,
  'west':    11.4202948
}, {'zipCode': '07774', 'north': 51.0877336, 'east': 11.7775231, 'south': 50.98789740000001, 'west': 11.620956700000002}, {
  'zipCode': '07778',
  'north':   51.020459900000006,
  'east':    11.754926500000002,
  'south':   50.964633600000006,
  'west':    11.5466972
}, {'zipCode': '07806', 'north': 50.7990196, 'east': 11.7977184, 'south': 50.6287511, 'west': 11.6461571}, {
  'zipCode': '07819',
  'north':   50.7993506,
  'east':    11.943945,
  'south':   50.6641401,
  'west':    11.7449969
}, {'zipCode': '07907', 'north': 50.6875921, 'east': 11.9442242, 'south': 50.5159368, 'west': 11.6760704}, {
  'zipCode': '07919',
  'north':   50.617761,
  'east':    11.9667268,
  'south':   50.5163514,
  'west':    11.8719676
}, {'zipCode': '07922', 'north': 50.5427152, 'east': 11.9643285, 'south': 50.4492319, 'west': 11.7313625}, {
  'zipCode': '07924',
  'north':   50.6379128,
  'east':    11.7942309,
  'south':   50.5726568,
  'west':    11.633369700000001
}, {'zipCode': '07926', 'north': 50.4735758, 'east': 11.9278728, 'south': 50.4006576, 'west': 11.7232842}, {
  'zipCode': '07927',
  'north':   50.4467498,
  'east':    11.876104600000001,
  'south':   50.3897164,
  'west':    11.7493169
}, {'zipCode': '07929', 'north': 50.533201, 'east': 11.808535, 'south': 50.4449284, 'west': 11.6069495}, {
  'zipCode': '07937',
  'north':   50.6802998,
  'east':    12.1014689,
  'south':   50.5492496,
  'west':    11.880915
}, {'zipCode': '07950', 'north': 50.7151349, 'east': 12.0860539, 'south': 50.6553525, 'west': 11.9388318}, {
  'zipCode': '07952',
  'north':   50.6353267,
  'east':    12.0362196,
  'south':   50.5432922,
  'west':    11.9236216
}, {'zipCode': '07955', 'north': 50.7472675, 'east': 12.0371355, 'south': 50.6588123, 'west': 11.8660505}, {
  'zipCode': '07957',
  'north':   50.7441408,
  'east':    12.166656,
  'south':   50.6220845,
  'west':    12.033256
}, {'zipCode': '07958', 'north': 50.7298416, 'east': 12.0789469, 'south': 50.688076300000006, 'west': 12.0291566}, {
  'zipCode': '07973',
  'north':   50.6944074,
  'east':    12.2807896,
  'south':   50.5669936,
  'west':    12.0821195
}, {'zipCode': '07980', 'north': 50.7973346, 'east': 12.2212688, 'south': 50.6757777, 'west': 12.0948983}, {
  'zipCode': '07985',
  'north':   50.6415725,
  'east':    12.2239554,
  'south':   50.5801114,
  'west':    12.1096317
}, {'zipCode': '07987', 'north': 50.7583862, 'east': 12.322213, 'south': 50.6443078, 'west': 12.15468}, {
  'zipCode': '08056',
  'north':   50.7314223,
  'east':    12.5273403,
  'south':   50.6817078,
  'west':    12.4282206
}, {'zipCode': '08058', 'north': 50.8128393, 'east': 12.5413828, 'south': 50.7226615, 'west': 12.4083479}, {
  'zipCode': '08060',
  'north':   50.7426756,
  'east':    12.4769088,
  'south':   50.7049861,
  'west':    12.4205073
}, {'zipCode': '08062', 'north': 50.7029663, 'east': 12.4875093, 'south': 50.6849236, 'west': 12.449264}, {
  'zipCode': '08064',
  'north':   50.6941643,
  'east':    12.5065512,
  'south':   50.6417164,
  'west':    12.4364927
}, {'zipCode': '08066', 'north': 50.747876, 'east': 12.5659556, 'south': 50.7012758, 'west': 12.4957764}, {
  'zipCode': '08107',
  'north':   50.6536194,
  'east':    12.6157723,
  'south':   50.55169,
  'west':    12.4287128
}, {'zipCode': '08112', 'north': 50.6906008, 'east': 12.56219, 'south': 50.6439232, 'west': 12.4813196}, {
  'zipCode': '08115',
  'north':   50.7083207,
  'east':    12.4641862,
  'south':   50.6362732,
  'west':    12.3759116
}, {'zipCode': '08118', 'north': 50.7099061, 'east': 12.7187033, 'south': 50.6283475, 'west': 12.6261019}, {
  'zipCode': '08132',
  'north':   50.7920885,
  'east':    12.6551885,
  'south':   50.6830894,
  'west':    12.483631
}, {'zipCode': '08134', 'north': 50.6947356, 'east': 12.656535600000002, 'south': 50.6092847, 'west': 12.5347385}, {
  'zipCode': '08141',
  'north':   50.7184607,
  'east':    12.6003752,
  'south':   50.6671577,
  'west':    12.51576
}, {'zipCode': '08144', 'north': 50.6513864, 'east': 12.512894600000001, 'south': 50.6050639, 'west': 12.4097548}, {
  'zipCode': '08147',
  'north':   50.5969411,
  'east':    12.5497266,
  'south':   50.5465654,
  'west':    12.4615645
}, {'zipCode': '08209', 'north': 50.5437573, 'east': 12.495671, 'south': 50.4411059, 'west': 12.3317638}, {
  'zipCode': '08223',
  'north':   50.5014709,
  'east':    12.4371045,
  'south':   50.3812822,
  'west':    12.2629082
}, {'zipCode': '08228', 'north': 50.5770574, 'east': 12.4711605, 'south': 50.5050087, 'west': 12.3648079}, {
  'zipCode': '08233',
  'north':   50.5775011,
  'east':    12.3674903,
  'south':   50.4989451,
  'west':    12.2497062
}, {'zipCode': '08236', 'north': 50.4972252, 'east': 12.410972, 'south': 50.4656252, 'west': 12.3710585}, {
  'zipCode': '08237',
  'north':   50.5678278,
  'east':    12.5278433,
  'south':   50.5188042,
  'west':    12.4292169
}, {'zipCode': '08239', 'north': 50.5092827, 'east': 12.3415862, 'south': 50.4477467, 'west': 12.2484323}, {
  'zipCode': '08248',
  'north':   50.4204667,
  'east':    12.516793500000002,
  'south':   50.34684930000001,
  'west':    12.4260309
}, {'zipCode': '08258', 'north': 50.3541277, 'east': 12.4373964, 'south': 50.253554400000006, 'west': 12.275098900000001}, {
  'zipCode': '08261',
  'north':   50.4305922,
  'east':    12.4111477,
  'south':   50.3450826,
  'west':    12.2461295
}, {'zipCode': '08262', 'north': 50.4698053, 'east': 12.583913000000003, 'south': 50.3965815, 'west': 12.3937868}, {
  'zipCode': '08267',
  'north':   50.3830843,
  'east':    12.4620756,
  'south':   50.3337252,
  'west':    12.3646783
}, {'zipCode': '08280', 'north': 50.6374261, 'east': 12.7398432, 'south': 50.561504600000006, 'west': 12.655463800000001}, {
  'zipCode': '08289',
  'north':   50.620951,
  'east':    12.6602913,
  'south':   50.5633812,
  'west':    12.5756622
}, {'zipCode': '08294', 'north': 50.6634154, 'east': 12.793710400000002, 'south': 50.587568, 'west': 12.6983037}, {
  'zipCode': '08297',
  'north':   50.6997029,
  'east':    12.9133327,
  'south':   50.5958231,
  'west':    12.7625003
}, {'zipCode': '08301', 'north': 50.6409171, 'east': 12.6948803, 'south': 50.5874863, 'west': 12.6292037}, {
  'zipCode': '08304',
  'north':   50.5272757,
  'east':    12.5877735,
  'south':   50.4649467,
  'west':    12.4557807
}, {'zipCode': '08309', 'north': 50.5394477, 'east': 12.703195400000002, 'south': 50.4056049, 'west': 12.4958652}, {
  'zipCode': '08315',
  'north':   50.6051169,
  'east':    12.7871529,
  'south':   50.5180939,
  'west':    12.689214
}, {'zipCode': '08321', 'north': 50.5834327, 'east': 12.6903676, 'south': 50.5165387, 'west': 12.6056776}, {
  'zipCode': '08324',
  'north':   50.5645649,
  'east':    12.7159214,
  'south':   50.5045469,
  'west':    12.6412101
}, {'zipCode': '08328', 'north': 50.5638485, 'east': 12.618902, 'south': 50.5116174, 'west': 12.4765231}, {
  'zipCode': '08340',
  'north':   50.5656283,
  'east':    12.8607211,
  'south':   50.4687156,
  'west':    12.6949684
}, {'zipCode': '08344', 'north': 50.6125347, 'east': 12.8475726, 'south': 50.5455877, 'west': 12.7652303}, {
  'zipCode': '08349',
  'north':   50.4613373,
  'east':    12.7535864,
  'south':   50.3971204,
  'west':    12.6456898
}, {'zipCode': '08352', 'north': 50.5570993, 'east': 12.9096763, 'south': 50.45981, 'west': 12.8170048}, {
  'zipCode': '08359',
  'north':   50.5171688,
  'east':    12.8988035,
  'south':   50.4301563,
  'west':    12.6967767
}, {'zipCode': '08371', 'north': 50.8517757, 'east': 12.619392, 'south': 50.7566663, 'west': 12.4856443}, {
  'zipCode': '08373',
  'north':   50.875908200000005,
  'east':    12.6012639,
  'south':   50.8400807,
  'west':    12.5159324
}, {'zipCode': '08393', 'north': 50.8938575, 'east': 12.5347302, 'south': 50.791147, 'west': 12.409274}, {
  'zipCode': '08396',
  'north':   50.909316,
  'east':    12.6526376,
  'south':   50.837368700000006,
  'west':    12.5072925
}, {'zipCode': '08412', 'north': 50.7768576, 'east': 12.4423254, 'south': 50.6824467, 'west': 12.2526104}, {
  'zipCode': '08427',
  'north':   50.71798540000001,
  'east':    12.3948282,
  'south':   50.663228600000004,
  'west':    12.3130679
}, {'zipCode': '08428', 'north': 50.789369, 'east': 12.3529807, 'south': 50.728348000000004, 'west': 12.2263668}, {
  'zipCode': '08451',
  'north':   50.8574623,
  'east':    12.4552855,
  'south':   50.7808621,
  'west':    12.2429781
}, {'zipCode': '08459', 'north': 50.8077248, 'east': 12.4306038, 'south': 50.759300700000004, 'west': 12.335603}, {
  'zipCode': '08468',
  'north':   50.6632819,
  'east':    12.4235469,
  'south':   50.5801339,
  'west':    12.263552100000002
}, {'zipCode': '08485', 'north': 50.6201195, 'east': 12.448762, 'south': 50.5486418, 'west': 12.2915915}, {
  'zipCode': '08491',
  'north':   50.6329269,
  'east':    12.2945118,
  'south':   50.5625073,
  'west':    12.198548
}, {'zipCode': '08496', 'north': 50.6770235, 'east': 12.3979203, 'south': 50.6332539, 'west': 12.313927200000002}, {
  'zipCode': '08499',
  'north':   50.6342297,
  'east':    12.2851632,
  'south':   50.6027548,
  'west':    12.2406313
}, {'zipCode': '08523', 'north': 50.5100021, 'east': 12.1535546, 'south': 50.4702685, 'west': 12.0877924}, {
  'zipCode': '08525',
  'north':   50.5452527,
  'east':    12.1735967,
  'south':   50.4968106,
  'west':    12.0622149
}, {'zipCode': '08527', 'north': 50.53643550000001, 'east': 12.1951893, 'south': 50.4304178, 'west': 11.9994425}, {
  'zipCode': '08529',
  'north':   50.5210628,
  'east':    12.2010388,
  'south':   50.4808998,
  'west':    12.147369
}, {'zipCode': '08538', 'north': 50.5045279, 'east': 12.1040018, 'south': 50.3749165, 'west': 11.8874255}, {
  'zipCode': '08539',
  'north':   50.5724169,
  'east':    12.0560552,
  'south':   50.4898238,
  'west':    11.9253285
}, {'zipCode': '08541', 'north': 50.550427, 'east': 12.285536, 'south': 50.4495837, 'west': 12.1841975}, {
  'zipCode': '08543',
  'north':   50.5900609,
  'east':    12.2608941,
  'south':   50.5175143,
  'west':    12.1365961
}, {'zipCode': '08547', 'north': 50.5748845, 'east': 12.169156, 'south': 50.527153, 'west': 12.1055717}, {
  'zipCode': '08548',
  'north':   50.5799846,
  'east':    12.1196408,
  'south':   50.5278553,
  'west':    12.0448549
}, {'zipCode': '08606', 'north': 50.4653754, 'east': 12.2783969, 'south': 50.323751, 'west': 11.9727268}, {
  'zipCode': '08626',
  'north':   50.3971968,
  'east':    12.3269844,
  'south':   50.2882224,
  'west':    12.0902298
}, {'zipCode': '08645', 'north': 50.3066215, 'east': 12.2944291, 'south': 50.2588821, 'west': 12.1950841}, {
  'zipCode': '08648',
  'north':   50.2759616,
  'east':    12.3592287,
  'south':   50.1713271,
  'west':    12.2390764
}, {'zipCode': '09111', 'north': 50.8472265, 'east': 12.934514500000002, 'south': 50.8234696, 'west': 12.9117449}, {
  'zipCode': '09112',
  'north':   50.8386958,
  'east':    12.9200632,
  'south':   50.82371890000001,
  'west':    12.892712
}, {'zipCode': '09113', 'north': 50.8589448, 'east': 12.9371873, 'south': 50.8359086, 'west': 12.8864049}, {
  'zipCode': '09114',
  'north':   50.8928351,
  'east':    12.9661569,
  'south':   50.8464217,
  'west':    12.8610742
}, {'zipCode': '09116', 'north': 50.8505899, 'east': 12.8989751, 'south': 50.7907864, 'west': 12.8409658}, {
  'zipCode': '09117',
  'north':   50.8507112,
  'east':    12.8588694,
  'south':   50.794316,
  'west':    12.7970615
}, {'zipCode': '09119', 'north': 50.82896190000001, 'east': 12.9158018, 'south': 50.8019563, 'west': 12.8774578}, {
  'zipCode': '09120',
  'north':   50.8266898,
  'east':    12.9367259,
  'south':   50.7967242,
  'west':    12.8851341
}, {'zipCode': '09122', 'north': 50.8106232, 'east': 12.9073513, 'south': 50.7861955, 'west': 12.8701503}, {
  'zipCode': '09123',
  'north':   50.797563,
  'east':    13.0050565,
  'south':   50.7413804,
  'west':    12.868975900000002
}, {'zipCode': '09125', 'north': 50.8117503, 'east': 12.977968400000002, 'south': 50.7628783, 'west': 12.9024277}, {
  'zipCode': '09126',
  'north':   50.8328456,
  'east':    12.9611032,
  'south':   50.8004611,
  'west':    12.9187476
}, {'zipCode': '09127', 'north': 50.8340981, 'east': 13.0235952, 'south': 50.7892073, 'west': 12.9427387}, {
  'zipCode': '09128',
  'north':   50.8522567,
  'east':    13.0530353,
  'south':   50.7688857,
  'west':    12.9828414
}, {'zipCode': '09130', 'north': 50.8474825, 'east': 12.9749002, 'south': 50.828908, 'west': 12.9299887}, {
  'zipCode': '09131',
  'north':   50.895231,
  'east':    12.9995579,
  'south':   50.8315789,
  'west':    12.9280897
}, {'zipCode': '09212', 'north': 50.9242066, 'east': 12.8059676, 'south': 50.8213073, 'west': 12.636325200000002}, {
  'zipCode': '09217',
  'north':   50.9491275,
  'east':    12.852485,
  'south':   50.8827921,
  'west':    12.7552091
}, {'zipCode': '09221', 'north': 50.8022784, 'east': 12.9081491, 'south': 50.736954, 'west': 12.816650400000002}, {
  'zipCode': '09224',
  'north':   50.8507112,
  'east':    12.8227137,
  'south':   50.7843027,
  'west':    12.727533300000001
}, {'zipCode': '09228', 'north': 50.9038205, 'east': 12.8982579, 'south': 50.8694171, 'west': 12.8219727}, {
  'zipCode': '09232',
  'north':   50.9003094,
  'east':    12.8273908,
  'south':   50.8664893,
  'west':    12.7626823
}, {'zipCode': '09235', 'north': 50.7587713, 'east': 12.9790116, 'south': 50.705197, 'west': 12.8562309}, {
  'zipCode': '09236',
  'north':   50.958071100000005,
  'east':    12.9343595,
  'south':   50.914733,
  'west':    12.8211132
}, {'zipCode': '09241', 'north': 50.9194847, 'east': 12.7859311, 'south': 50.882907, 'west': 12.7400518}, {
  'zipCode': '09243',
  'north':   50.9082281,
  'east':    12.7667771,
  'south':   50.86561470000001,
  'west':    12.699264300000001
}, {'zipCode': '09244', 'north': 50.95161310000001, 'east': 13.0324213, 'south': 50.886211, 'west': 12.876582}, {
  'zipCode': '09247',
  'north':   50.8783164,
  'east':    12.8729876,
  'south':   50.8470641,
  'west':    12.8040769
}, {'zipCode': '09249', 'north': 50.9305943, 'east': 12.8990589, 'south': 50.89752200000001, 'west': 12.819786}, {
  'zipCode': '09306',
  'north':   51.1078386,
  'east':    12.9871991,
  'south':   50.9434661,
  'west':    12.7097252
}, {'zipCode': '09322', 'north': 51.0069693, 'east': 12.7700706, 'south': 50.9016426, 'west': 12.6171453}, {
  'zipCode': '09326',
  'north':   51.1060299,
  'east':    12.9557098,
  'south':   51.0426062,
  'west':    12.8629448
}, {'zipCode': '09328', 'north': 50.9914967, 'east': 12.8095005, 'south': 50.9384446, 'west': 12.691649}, {
  'zipCode': '09337',
  'north':   50.8808613,
  'east':    12.7690959,
  'south':   50.7512183,
  'west':    12.6096988
}, {'zipCode': '09350', 'north': 50.777562800000005, 'east': 12.6687143, 'south': 50.7228762, 'west': 12.6003957}, {
  'zipCode': '09353',
  'north':   50.8039022,
  'east':    12.7788633,
  'south':   50.7684512,
  'west':    12.682768600000001
}, {'zipCode': '09355', 'north': 50.7823327, 'east': 12.7265886, 'south': 50.7382559, 'west': 12.6821047}, {
  'zipCode': '09356',
  'north':   50.8255353,
  'east':    12.6715108,
  'south':   50.7646618,
  'west':    12.5838037
}, {'zipCode': '09366', 'north': 50.7518576, 'east': 12.8417975, 'south': 50.6416446, 'west': 12.6940959}, {
  'zipCode': '09376',
  'north':   50.7409642,
  'east':    12.752076300000002,
  'south':   50.6872359,
  'west':    12.6382712
}, {'zipCode': '09380', 'north': 50.7209773, 'east': 12.8749708, 'south': 50.6769222, 'west': 12.8191649}, {
  'zipCode': '09385',
  'north':   50.79003500000001,
  'east':    12.7947736,
  'south':   50.7273898,
  'west':    12.7111007
}, {'zipCode': '09387', 'north': 50.7885698, 'east': 12.8704431, 'south': 50.7128323, 'west': 12.7789863}, {
  'zipCode': '09390',
  'north':   50.7229924,
  'east':    12.9014044,
  'south':   50.6939569,
  'west':    12.8666007
}, {'zipCode': '09392', 'north': 50.7040143, 'east': 12.937803, 'south': 50.6700678, 'west': 12.883587200000001}, {
  'zipCode': '09394',
  'north':   50.7556057,
  'east':    12.6946447,
  'south':   50.7294555,
  'west':    12.6491304
}, {'zipCode': '09399', 'north': 50.7441478, 'east': 12.7824993, 'south': 50.7089211, 'west': 12.7391936}, {
  'zipCode': '09405',
  'north':   50.7726762,
  'east':    13.09218,
  'south':   50.7156603,
  'west':    13.0171343
}, {'zipCode': '09419', 'north': 50.7010414, 'east': 13.0004366, 'south': 50.6516885, 'west': 12.901238}, {
  'zipCode': '09423',
  'north':   50.733171,
  'east':    13.0088309,
  'south':   50.6895447,
  'west':    12.889662400000002
}, {'zipCode': '09427', 'north': 50.6695482, 'east': 13.0035048, 'south': 50.6243563, 'west': 12.9004789}, {
  'zipCode': '09429',
  'north':   50.6896064,
  'east':    13.1331923,
  'south':   50.6235988,
  'west':    13.0103255
}, {'zipCode': '09430', 'north': 50.7309813, 'east': 13.071742, 'south': 50.6432512, 'west': 12.9710136}, {
  'zipCode': '09432',
  'north':   50.7336613,
  'east':    13.1256895,
  'south':   50.6633027,
  'west':    13.0380197
}, {'zipCode': '09434', 'north': 50.7596298, 'east': 13.1314282, 'south': 50.71616840000001, 'west': 13.0806933}, {
  'zipCode': '09437',
  'north':   50.7869363,
  'east':    13.159192,
  'south':   50.6930686,
  'west':    13.0518936
}, {'zipCode': '09439', 'north': 50.7797613, 'east': 13.0562223, 'south': 50.7161544, 'west': 12.9629547}, {
  'zipCode': '09456',
  'north':   50.6157312,
  'east':    13.1502271,
  'south':   50.5380215,
  'west':    12.9703518
}, {'zipCode': '09465', 'north': 50.5493201, 'east': 13.0306928, 'south': 50.4454895, 'west': 12.917239200000001}, {
  'zipCode': '09468',
  'north':   50.6507827,
  'east':    12.9803274,
  'south':   50.5953228,
  'west':    12.8628218
}, {'zipCode': '09471', 'north': 50.5664664, 'east': 13.0840986, 'south': 50.451609, 'west': 13.0095868}, {
  'zipCode': '09474',
  'north':   50.5492299,
  'east':    12.9784366,
  'south':   50.4592839,
  'west':    12.8828768
}, {'zipCode': '09477', 'north': 50.5742508, 'east': 13.2012754, 'south': 50.5000121, 'west': 13.0591666}, {
  'zipCode': '09481',
  'north':   50.6295757,
  'east':    12.951112,
  'south':   50.510791,
  'west':    12.8280616
}, {'zipCode': '09484', 'north': 50.4670623, 'east': 13.0226896, 'south': 50.40401520000001, 'west': 12.8594838}, {
  'zipCode': '09487',
  'north':   50.6025581,
  'east':    12.9842448,
  'south':   50.53771600000001,
  'west':    12.902757700000002
}, {'zipCode': '09488', 'north': 50.6455214, 'east': 13.0657128, 'south': 50.5954607, 'west': 12.9477713}, {
  'zipCode': '09496',
  'north':   50.6989687,
  'east':    13.3278558,
  'south':   50.5029032,
  'west':    13.1177627
}, {'zipCode': '09509', 'north': 50.7440628, 'east': 13.3121404, 'south': 50.6615719, 'west': 13.1937179}, {
  'zipCode': '09514',
  'north':   50.776231,
  'east':    13.277956,
  'south':   50.6724046,
  'west':    13.1150286
}, {'zipCode': '09518', 'north': 50.65166120000001, 'east': 13.1606978, 'south': 50.5903238, 'west': 13.0567199}, {
  'zipCode': '09526',
  'north':   50.753037,
  'east':    13.447787900000002,
  'south':   50.5970315,
  'west':    13.2370339
}, {'zipCode': '09544', 'north': 50.7267939, 'east': 13.5443604, 'south': 50.6336809, 'west': 13.4295411}, {
  'zipCode': '09548',
  'north':   50.664505,
  'east':    13.5020252,
  'south':   50.6016996,
  'west':    13.4102134
}, {'zipCode': '09557', 'north': 50.8799733, 'east': 13.1413264, 'south': 50.8293662, 'west': 13.0324206}, {
  'zipCode': '09569',
  'north':   50.9321048,
  'east':    13.2537395,
  'south':   50.81048740000001,
  'west':    13.1197513
}, {'zipCode': '09573', 'north': 50.8399561, 'east': 13.2142983, 'south': 50.765676, 'west': 13.018325800000001}, {
  'zipCode': '09575',
  'north':   50.8368022,
  'east':    13.3104912,
  'south':   50.7630338,
  'west':    13.1962203
}, {'zipCode': '09577', 'north': 50.8921436, 'east': 13.0574183, 'south': 50.8462273, 'west': 12.9810163}, {
  'zipCode': '09579',
  'north':   50.7923377,
  'east':    13.240685,
  'south':   50.744332400000005,
  'west':    13.085751
}, {'zipCode': '09599', 'north': 50.9590788, 'east': 13.400510500000001, 'south': 50.8754646, 'west': 13.2660828}, {
  'zipCode': '09600',
  'north':   50.9681744,
  'east':    13.4475879,
  'south':   50.8448742,
  'west':    13.1991105
}, {'zipCode': '09603', 'north': 51.0370623, 'east': 13.3413816, 'south': 50.9423137, 'west': 13.200720000000002}, {
  'zipCode': '09618',
  'north':   50.8872457,
  'east':    13.3640601,
  'south':   50.73921680000001,
  'west':    13.2248907
}, {'zipCode': '09619', 'north': 50.8259495, 'east': 13.4848198, 'south': 50.674441, 'west': 13.3380559}, {
  'zipCode': '09623',
  'north':   50.8462179,
  'east':    13.6520053,
  'south':   50.7089432,
  'west':    13.4461674
}, {'zipCode': '09627', 'north': 50.9489321, 'east': 13.5150513, 'south': 50.8381784, 'west': 13.376345800000001}, {
  'zipCode': '09629',
  'north':   51.0526554,
  'east':    13.4495114,
  'south':   50.9823591,
  'west':    13.3099441
}, {'zipCode': '09633', 'north': 50.9930037, 'east': 13.4755379, 'south': 50.9306372, 'west': 13.3290378}, {
  'zipCode': '09634',
  'north':   51.0523841,
  'east':    13.3752826,
  'south':   51.0202676,
  'west':    13.315903
}, {'zipCode': '09638', 'north': 50.8597566, 'east': 13.473052, 'south': 50.8036011, 'west': 13.3241987}, {
  'zipCode': '09648',
  'north':   51.0603322,
  'east':    13.1192905,
  'south':   50.9427885,
  'west':    12.8670779
}, {'zipCode': '09661', 'north': 51.0688408, 'east': 13.2665999, 'south': 50.9099515, 'west': 12.9862708}, {
  'zipCode': '09669',
  'north':   50.9635923,
  'east':    13.165077,
  'south':   50.8737947,
  'west':    12.9883482
}, {'zipCode': '10115', 'north': 52.5401484, 'east': 13.4012965, 'south': 52.5237433, 'west': 13.3658603}, {
  'zipCode': '10117',
  'north':   52.5283671,
  'east':    13.4057347,
  'south':   52.506999500000006,
  'west':    13.3710363
}, {'zipCode': '10119', 'north': 52.53612, 'east': 13.4165304, 'south': 52.5242564, 'west': 13.3970158}, {
  'zipCode': '10178',
  'north':   52.5283771,
  'east':    13.4291793,
  'south':   52.5121676,
  'west':    13.3948709
}, {'zipCode': '10179', 'north': 52.5234612, 'east': 13.4294017, 'south': 52.5040371, 'west': 13.4016439}, {
  'zipCode': '10243',
  'north':   52.5227218,
  'east':    13.4551222,
  'south':   52.5011004,
  'west':    13.4227803
}, {'zipCode': '10245', 'north': 52.5147369, 'east': 13.4914434, 'south': 52.4860501, 'west': 13.4452288}, {
  'zipCode': '10247',
  'north':   52.5244311,
  'east':    13.4762658,
  'south':   52.5073747,
  'west':    13.4522991
}, {'zipCode': '10249', 'north': 52.52954720000001, 'east': 13.4720931, 'south': 52.5168635, 'west': 13.4197534}, {
  'zipCode': '10315',
  'north':   52.53559150000001,
  'east':    13.5378949,
  'south':   52.5000517,
  'west':    13.4957213
}, {'zipCode': '10317', 'north': 52.514461, 'east': 13.5156169, 'south': 52.4824494, 'west': 13.4662181}, {
  'zipCode': '10318',
  'north':   52.4982942,
  'east':    13.5532396,
  'south':   52.4678355,
  'west':    13.5019663
}, {'zipCode': '10319', 'north': 52.5102727, 'east': 13.5417345, 'south': 52.4909583, 'west': 13.4927918}, {
  'zipCode': '10365',
  'north':   52.53559150000001,
  'east':    13.516965800000001,
  'south':   52.5057629,
  'west':    13.4722603
}, {'zipCode': '10367', 'north': 52.5344167, 'east': 13.4967605, 'south': 52.513105900000006, 'west': 13.4707951}, {
  'zipCode': '10369',
  'north':   52.5404095,
  'east':    13.4797474,
  'south':   52.5220413,
  'west':    13.4561898
}, {'zipCode': '10405', 'north': 52.5455612, 'east': 13.4473745, 'south': 52.5255461, 'west': 13.4117159}, {
  'zipCode': '10407',
  'north':   52.54276080000001,
  'east':    13.4688009,
  'south':   52.52640830000001,
  'west':    13.4238095
}, {'zipCode': '10409', 'north': 52.5517552, 'east': 13.4572701, 'south': 52.5367521, 'west': 13.4267076}, {
  'zipCode': '10435',
  'north':   52.5416704,
  'east':    13.4240024,
  'south':   52.5326204,
  'west':    13.3994175
}, {'zipCode': '10437', 'north': 52.5529084, 'east': 13.426806600000003, 'south': 52.5397635, 'west': 13.3993313}, {
  'zipCode': '10439',
  'north':   52.5585475,
  'east':    13.4299759,
  'south':   52.5446986,
  'west':    13.3971127
}, {'zipCode': '10551', 'north': 52.536460500000004, 'east': 13.3446849, 'south': 52.5253723, 'west': 13.3286742}, {
  'zipCode': '10553',
  'north':   52.5377211,
  'east':    13.3307993,
  'south':   52.5203665,
  'west':    13.3114285
}, {'zipCode': '10555', 'north': 52.5270677, 'east': 13.3436968, 'south': 52.5126067, 'west': 13.3271456}, {
  'zipCode': '10557',
  'north':   52.5383569,
  'east':    13.3772438,
  'south':   52.5137118,
  'west':    13.3364183
}, {'zipCode': '10559', 'north': 52.5377333, 'east': 13.3591407, 'south': 52.5213244, 'west': 13.3430694}, {
  'zipCode': '10585',
  'north':   52.5211214,
  'east':    13.3204071,
  'south':   52.5113609,
  'west':    13.2967608
}, {'zipCode': '10587', 'north': 52.5247142, 'east': 13.3343775, 'south': 52.5110833, 'west': 13.299667}, {
  'zipCode': '10589',
  'north':   52.535399600000005,
  'east':    13.317309,
  'south':   52.520132,
  'west':    13.2927174
}, {'zipCode': '10623', 'north': 52.5164144, 'east': 13.3369819, 'south': 52.5018191, 'west': 13.3177635}, {
  'zipCode': '10625',
  'north':   52.5145856,
  'east':    13.3223622,
  'south':   52.5050072,
  'west':    13.3061616
}, {'zipCode': '10627', 'north': 52.5125057, 'east': 13.3105105, 'south': 52.5024805, 'west': 13.2947257}, {
  'zipCode': '10629',
  'north':   52.50550320000001,
  'east':    13.3215359,
  'south':   52.4995872,
  'west':    13.2948702
}, {'zipCode': '10707', 'north': 52.5026343, 'east': 13.3204972, 'south': 52.4893996, 'west': 13.3060565}, {
  'zipCode': '10709',
  'north':   52.5004878,
  'east':    13.312471,
  'south':   52.488044200000004,
  'west':    13.2948015
}, {'zipCode': '10711', 'north': 52.503317700000004, 'east': 13.3032134, 'south': 52.4905835, 'west': 13.2774098}, {
  'zipCode': '10713',
  'north':   52.4923253,
  'east':    13.3212881,
  'south':   52.4777129,
  'west':    13.3003283
}, {'zipCode': '10715', 'north': 52.48858450000001, 'east': 13.3371689, 'south': 52.4774162, 'west': 13.3202486}, {
  'zipCode': '10717',
  'north':   52.4957152,
  'east':    13.3366814,
  'south':   52.4866257,
  'west':    13.3194193
}, {'zipCode': '10719', 'north': 52.5051289, 'east': 13.3352279, 'south': 52.4912925, 'west': 13.317999200000001}, {
  'zipCode': '10777',
  'north':   52.5010546,
  'east':    13.3552264,
  'south':   52.494332,
  'west':    13.3309635
}, {'zipCode': '10779', 'north': 52.4956144, 'east': 13.3464365, 'south': 52.487949, 'west': 13.3312583}, {
  'zipCode': '10781',
  'north':   52.4975945,
  'east':    13.3610596,
  'south':   52.4895665,
  'west':    13.3450934
}, {'zipCode': '10783', 'north': 52.501606200000005, 'east': 13.3697289, 'south': 52.4909305, 'west': 13.3540836}, {
  'zipCode': '10785',
  'north':   52.51620160000001,
  'east':    13.3776503,
  'south':   52.4939555,
  'west':    13.3490905
}, {'zipCode': '10787', 'north': 52.5145231, 'east': 13.3545834, 'south': 52.4992992, 'west': 13.3318023}, {
  'zipCode': '10789',
  'north':   52.505472,
  'east':    13.3466233,
  'south':   52.4988414,
  'west':    13.3307292
}, {'zipCode': '10823', 'north': 52.49142160000001, 'east': 13.3606056, 'south': 52.4816723, 'west': 13.3449154}, {
  'zipCode': '10825',
  'north':   52.4894958,
  'east':    13.3470766,
  'south':   52.4785242,
  'west':    13.3362267
}, {'zipCode': '10827', 'north': 52.492400100000005, 'east': 13.367301100000002, 'south': 52.4749644, 'west': 13.341488}, {
  'zipCode': '10829',
  'north':   52.4928489,
  'east':    13.3719226,
  'south':   52.4597929,
  'west':    13.3435181
}, {'zipCode': '10961', 'north': 52.49791900000001, 'east': 13.4089878, 'south': 52.4867292, 'west': 13.3852725}, {
  'zipCode': '10963',
  'north':   52.5081214,
  'east':    13.391583,
  'south':   52.4920279,
  'west':    13.372901600000002
}, {'zipCode': '10965', 'north': 52.4960059, 'east': 13.4209034, 'south': 52.4777062, 'west': 13.3672113}, {
  'zipCode': '10967',
  'north':   52.496105,
  'east':    13.425878,
  'south':   52.483177,
  'west':    13.4063337
}, {'zipCode': '10969', 'north': 52.5093816, 'east': 13.4167316, 'south': 52.4953738, 'west': 13.3857617}, {
  'zipCode': '10997',
  'north':   52.5085626,
  'east':    13.4529296,
  'south':   52.4941678,
  'west':    13.4185183
}, {'zipCode': '10999', 'north': 52.5056491, 'east': 13.4446428, 'south': 52.4896095, 'west': 13.4112398}, {
  'zipCode': '12043',
  'north':   52.4871412,
  'east':    13.447749,
  'south':   52.4721785,
  'west':    13.4242555
}, {'zipCode': '12045', 'north': 52.4913201, 'east': 13.4503267, 'south': 52.4800892, 'west': 13.4302316}, {
  'zipCode': '12047',
  'north':   52.4957889,
  'east':    13.4353562,
  'south':   52.4857336,
  'west':    13.420639
}, {'zipCode': '12049', 'north': 52.4865434, 'east': 13.4361153, 'south': 52.4691537, 'west': 13.413491}, {
  'zipCode': '12051',
  'north':   52.47194830000001,
  'east':    13.4438381,
  'south':   52.4613266,
  'west':    13.416041
}, {'zipCode': '12053', 'north': 52.484818, 'east': 13.4409581, 'south': 52.4702113, 'west': 13.4250847}, {
  'zipCode': '12055',
  'north':   52.4775685,
  'east':    13.4571514,
  'south':   52.4660618,
  'west':    13.4396517
}, {'zipCode': '12057', 'north': 52.4855156, 'east': 13.4785501, 'south': 52.4587633, 'west': 13.44121}, {
  'zipCode': '12059',
  'north':   52.4899002,
  'east':    13.4591528,
  'south':   52.4705228,
  'west':    13.4394355
}, {'zipCode': '12099', 'north': 52.47870590000001, 'east': 13.4245455, 'south': 52.4508232, 'west': 13.3813195}, {
  'zipCode': '12101',
  'north':   52.485501,
  'east':    13.3992186,
  'south':   52.4710145,
  'west':    13.3641736
}, {'zipCode': '12103', 'north': 52.4720317, 'east': 13.3853862, 'south': 52.4550431, 'west': 13.3616793}, {
  'zipCode': '12105',
  'north':   52.461161100000005,
  'east':    13.3865013,
  'south':   52.4358213,
  'west':    13.3557055
}, {'zipCode': '12107', 'north': 52.4441289, 'east': 13.4109372, 'south': 52.414544, 'west': 13.3664558}, {
  'zipCode': '12109',
  'north':   52.4552657,
  'east':    13.4160773,
  'south':   52.43585670000001,
  'west':    13.3827343
}, {'zipCode': '12157', 'north': 52.47420090000001, 'east': 13.3594876, 'south': 52.4567678, 'west': 13.3280576}, {
  'zipCode': '12159',
  'north':   52.4785744,
  'east':    13.346545,
  'south':   52.4672192,
  'west':    13.3287308
}, {'zipCode': '12161', 'north': 52.4774956, 'east': 13.337029200000002, 'south': 52.4638934, 'west': 13.3200575}, {
  'zipCode': '12163',
  'north':   52.4670641,
  'east':    13.3399775,
  'south':   52.456888,
  'west':    13.304547000000001
}, {'zipCode': '12165', 'north': 52.4606495, 'east': 13.3260375, 'south': 52.4500897, 'west': 13.3050284}, {
  'zipCode': '12167',
  'north':   52.4559314,
  'east':    13.3536456,
  'south':   52.4425012,
  'west':    13.3189793
}, {'zipCode': '12169', 'north': 52.4610584, 'east': 13.361128800000001, 'south': 52.4464981, 'west': 13.324213}, {
  'zipCode': '12203',
  'north':   52.4550209,
  'east':    13.3256846,
  'south':   52.4306741,
  'west':    13.2935269
}, {'zipCode': '12205', 'north': 52.44588660000001, 'east': 13.309691800000001, 'south': 52.4215438, 'west': 13.279754800000001}, {
  'zipCode': '12207',
  'north':   52.4429913,
  'east':    13.3317039,
  'south':   52.3991062,
  'west':    13.2941097
}, {'zipCode': '12209', 'north': 52.4314365, 'east': 13.343343800000001, 'south': 52.4057019, 'west': 13.3140712}, {
  'zipCode': '12247',
  'north':   52.4487188,
  'east':    13.3676035,
  'south':   52.4293778,
  'west':    13.325599
}, {'zipCode': '12249', 'north': 52.435856, 'east': 13.3719272, 'south': 52.4149462, 'west': 13.3322701}, {
  'zipCode': '12277',
  'north':   52.43362640000001,
  'east':    13.3984817,
  'south':   52.3972233,
  'west':    13.3526544
}, {'zipCode': '12279', 'north': 52.4189172, 'east': 13.3640104, 'south': 52.4020182, 'west': 13.3426626}, {
  'zipCode': '12305',
  'north':   52.4173172,
  'east':    13.4198825,
  'south':   52.3856167,
  'west':    13.3860529
}, {'zipCode': '12307', 'north': 52.4010734, 'east': 13.4142664, 'south': 52.3765819, 'west': 13.3612597}, {
  'zipCode': '12309',
  'north':   52.4109146,
  'east':    13.42746,
  'south':   52.3761384,
  'west':    13.4066445
}, {'zipCode': '12347', 'north': 52.4634287, 'east': 13.4499042, 'south': 52.4338531, 'west': 13.4109765}, {
  'zipCode': '12349',
  'north':   52.4454376,
  'east':    13.4397838,
  'south':   52.4101838,
  'west':    13.399924300000002
}, {'zipCode': '12351', 'north': 52.443064, 'east': 13.4778849, 'south': 52.423203, 'west': 13.4352256}, {
  'zipCode': '12353',
  'north':   52.4310526,
  'east':    13.4852579,
  'south':   52.4144535,
  'west':    13.430880400000001
}, {'zipCode': '12355', 'north': 52.4272022, 'east': 13.5241327, 'south': 52.3959454, 'west': 13.4680167}, {
  'zipCode': '12357',
  'north':   52.4427362,
  'east':    13.5144815,
  'south':   52.4169393,
  'west':    13.4706793
}, {'zipCode': '12359', 'north': 52.464715500000004, 'east': 13.478685, 'south': 52.4330137, 'west': 13.4341595}, {
  'zipCode': '12435',
  'north':   52.4977068,
  'east':    13.4938577,
  'south':   52.473026,
  'west':    13.4396363
}, {'zipCode': '12437', 'north': 52.4841232, 'east': 13.5005638, 'south': 52.442772000000005, 'west': 13.4569705}, {
  'zipCode': '12439',
  'north':   52.469112800000005,
  'east':    13.5585109,
  'south':   52.4408822,
  'west':    13.4956349
}, {'zipCode': '12459', 'north': 52.4833096, 'east': 13.5665957, 'south': 52.4526845, 'west': 13.4941951}, {
  'zipCode': '12487',
  'north':   52.4606361,
  'east':    13.5331613,
  'south':   52.4267279,
  'west':    13.478612900000002
}, {'zipCode': '12489', 'north': 52.4508374, 'east': 13.5636525, 'south': 52.4247629, 'west': 13.517976}, {
  'zipCode': '12524',
  'north':   52.4262432,
  'east':    13.5730087,
  'south':   52.397303300000004,
  'west':    13.516005
}, {'zipCode': '12526', 'north': 52.411900300000006, 'east': 13.5932416, 'south': 52.3881399, 'west': 13.5348234}, {
  'zipCode': '12527',
  'north':   52.42838460000001,
  'east':    13.7098516,
  'south':   52.3382448,
  'west':    13.5543099
}, {'zipCode': '12529', 'north': 52.4210797, 'east': 13.6063923, 'south': 52.3107106, 'west': 13.417893100000002}, {
  'zipCode': '12555',
  'north':   52.4811186,
  'east':    13.6113607,
  'south':   52.4408799,
  'west':    13.5456292
}, {'zipCode': '12557', 'north': 52.4479426, 'east': 13.6161687, 'south': 52.41029950000001, 'west': 13.5591491}, {
  'zipCode': '12559',
  'north':   52.449556,
  'east':    13.739034300000002,
  'south':   52.3804456,
  'west':    13.5813863
}, {'zipCode': '12587', 'north': 52.4792245, 'east': 13.669468, 'south': 52.4374223, 'west': 13.5940801}, {
  'zipCode': '12589',
  'north':   52.4732841,
  'east':    13.7611609,
  'south':   52.416337,
  'west':    13.656757
}, {'zipCode': '12619', 'north': 52.5357369, 'east': 13.607047100000003, 'south': 52.5121176, 'west': 13.5733143}, {
  'zipCode': '12621',
  'north':   52.5289945,
  'east':    13.6213705,
  'south':   52.474677500000006,
  'west':    13.5634409
}, {'zipCode': '12623', 'north': 52.5305734, 'east': 13.6585034, 'south': 52.4653059, 'west': 13.5749929}, {
  'zipCode': '12627',
  'north':   52.5475096,
  'east':    13.6376506,
  'south':   52.526668,
  'west':    13.5913108
}, {'zipCode': '12629', 'north': 52.5497862, 'east': 13.5999167, 'south': 52.5314551, 'west': 13.5807849}, {
  'zipCode': '12679',
  'north':   52.5570404,
  'east':    13.5863837,
  'south':   52.5419343,
  'west':    13.541573600000001
}, {'zipCode': '12681', 'north': 52.5667535, 'east': 13.5863831, 'south': 52.5099309, 'west': 13.516965800000001}, {
  'zipCode': '12683',
  'north':   52.5351716,
  'east':    13.5841343,
  'south':   52.4732052,
  'west':    13.5351965
}, {'zipCode': '12685', 'north': 52.5503283, 'east': 13.586697, 'south': 52.530165, 'west': 13.5387323}, {
  'zipCode': '12687',
  'north':   52.562835,
  'east':    13.581832700000001,
  'south':   52.5503927,
  'west':    13.5503552
}, {'zipCode': '12689', 'north': 52.5745086, 'east': 13.5877853, 'south': 52.5498431, 'west': 13.5443821}, {
  'zipCode': '13051',
  'north':   52.6051636,
  'east':    13.5128802,
  'south':   52.5575009,
  'west':    13.4776719
}, {'zipCode': '13053', 'north': 52.5664762, 'east': 13.5322639, 'south': 52.53559150000001, 'west': 13.4670814}, {
  'zipCode': '13055',
  'north':   52.549456500000005,
  'east':    13.5175417,
  'south':   52.5331305,
  'west':    13.4732175
}, {'zipCode': '13057', 'north': 52.587644, 'east': 13.5677059, 'south': 52.5554291, 'west': 13.5129533}, {
  'zipCode': '13059',
  'north':   52.5927494,
  'east':    13.5471255,
  'south':   52.5661437,
  'west':    13.4987769
}, {'zipCode': '13086', 'north': 52.5674076, 'east': 13.4664045, 'south': 52.5469558, 'west': 13.4290614}, {
  'zipCode': '13088',
  'north':   52.5806102,
  'east':    13.4957006,
  'south':   52.5385292,
  'west':    13.4479835
}, {'zipCode': '13089', 'north': 52.5831033, 'east': 13.4595757, 'south': 52.556651, 'west': 13.4272141}, {
  'zipCode': '13125',
  'north':   52.6755087,
  'east':    13.523022,
  'south':   52.5984556,
  'west':    13.445826600000002
}, {'zipCode': '13127', 'north': 52.6500395, 'east': 13.4632272, 'south': 52.5813765, 'west': 13.4044407}, {
  'zipCode': '13129',
  'north':   52.6103224,
  'east':    13.4848337,
  'south':   52.5749977,
  'west':    13.430899
}, {'zipCode': '13156', 'north': 52.594557, 'east': 13.4283683, 'south': 52.5682415, 'west': 13.3719846}, {
  'zipCode': '13158',
  'north':   52.6072381,
  'east':    13.4212121,
  'south':   52.5720278,
  'west':    13.3475592
}, {'zipCode': '13159', 'north': 52.6482946, 'east': 13.4207811, 'south': 52.6000995, 'west': 13.366911800000002}, {
  'zipCode': '13187',
  'north':   52.5823684,
  'east':    13.4288238,
  'south':   52.556921,
  'west':    13.388399
}, {'zipCode': '13189', 'north': 52.582768, 'east': 13.4338944, 'south': 52.5515448, 'west': 13.4094071}, {
  'zipCode': '13347',
  'north':   52.5578857,
  'east':    13.3787134,
  'south':   52.537325,
  'west':    13.3510143
}, {'zipCode': '13349', 'north': 52.5640219, 'east': 13.3651207, 'south': 52.5505073, 'west': 13.3312085}, {
  'zipCode': '13351',
  'north':   52.5613392,
  'east':    13.3502551,
  'south':   52.5410981,
  'west':    13.314247
}, {'zipCode': '13353', 'north': 52.5508776, 'east': 13.3749244, 'south': 52.5346555, 'west': 13.3264124}, {
  'zipCode': '13355',
  'north':   52.5484931,
  'east':    13.403485,
  'south':   52.5331793,
  'west':    13.3757511
}, {'zipCode': '13357', 'north': 52.5564358, 'east': 13.4002963, 'south': 52.5426118, 'west': 13.3684135}, {
  'zipCode': '13359',
  'north':   52.5676684,
  'east':    13.3976194,
  'south':   52.5544697,
  'west':    13.3634603
}, {'zipCode': '13403', 'north': 52.5875702, 'east': 13.3408431, 'south': 52.5625918, 'west': 13.3016964}, {
  'zipCode': '13405',
  'north':   52.5723703,
  'east':    13.336660900000002,
  'south':   52.5483208,
  'west':    13.2563541
}, {'zipCode': '13407', 'north': 52.5869674, 'east': 13.3722478, 'south': 52.5575966, 'west': 13.331485}, {
  'zipCode': '13409',
  'north':   52.576934,
  'east':    13.389278800000001,
  'south':   52.5580062,
  'west':    13.3584125
}, {'zipCode': '13435', 'north': 52.6084617, 'east': 13.3645514, 'south': 52.5945012, 'west': 13.3290443}, {
  'zipCode': '13437',
  'north':   52.605971200000006,
  'east':    13.3578126,
  'south':   52.5781187,
  'west':    13.300994200000002
}, {'zipCode': '13439', 'north': 52.612205, 'east': 13.375409, 'south': 52.5898041, 'west': 13.3365654}, {
  'zipCode': '13465',
  'north':   52.6607411,
  'east':    13.3109784,
  'south':   52.6247703,
  'west':    13.2621503
}, {'zipCode': '13467', 'north': 52.6282008, 'east': 13.33677, 'south': 52.6007454, 'west': 13.2813747}, {
  'zipCode': '13469',
  'north':   52.6254836,
  'east':    13.3763311,
  'south':   52.597068500000006,
  'west':    13.3008771
}, {'zipCode': '13503', 'north': 52.6284157, 'east': 13.2962465, 'south': 52.5960179, 'west': 13.2016176}, {
  'zipCode': '13505',
  'north':   52.600748,
  'east':    13.2729151,
  'south':   52.5622997,
  'west':    13.2082024
}, {'zipCode': '13507', 'north': 52.6000582, 'east': 13.3034087, 'south': 52.5531143, 'west': 13.2423401}, {
  'zipCode': '13509',
  'north':   52.606575,
  'east':    13.3207615,
  'south':   52.5745487,
  'west':    13.2827198
}, {'zipCode': '13581', 'north': 52.5405457, 'east': 13.2032642, 'south': 52.5218925, 'west': 13.1566403}, {
  'zipCode': '13583',
  'north':   52.5542938,
  'east':    13.1957548,
  'south':   52.535506700000006,
  'west':    13.167931
}, {'zipCode': '13585', 'north': 52.5566401, 'east': 13.2228429, 'south': 52.538174, 'west': 13.1901419}, {
  'zipCode': '13587',
  'north':   52.5987969,
  'east':    13.2287231,
  'south':   52.5514128,
  'west':    13.1279616
}, {'zipCode': '13589', 'north': 52.5704632, 'east': 13.1982206, 'south': 52.5442921, 'west': 13.1434513}, {
  'zipCode': '13591',
  'north':   52.5560119,
  'east':    13.1711822,
  'south':   52.5170034,
  'west':    13.1173799
}, {'zipCode': '13593', 'north': 52.52731670000001, 'east': 13.1887664, 'south': 52.500244, 'west': 13.1428799}, {
  'zipCode': '13595',
  'north':   52.52924420000001,
  'east':    13.2164365,
  'south':   52.4986912,
  'west':    13.1735407
}, {'zipCode': '13597', 'north': 52.541196, 'east': 13.2475713, 'south': 52.511651, 'west': 13.1948215}, {
  'zipCode': '13599',
  'north':   52.5721976,
  'east':    13.2627741,
  'south':   52.5271793,
  'west':    13.2082191
}, {'zipCode': '13627', 'north': 52.5488957, 'east': 13.3283524, 'south': 52.529591800000006, 'west': 13.2725901}, {
  'zipCode': '13629',
  'north':   52.5578239,
  'east':    13.302390900000002,
  'south':   52.5271793,
  'west':    13.240821
}, {'zipCode': '14050', 'north': 52.5304889, 'east': 13.2835758, 'south': 52.5098978, 'west': 13.246341200000002}, {
  'zipCode': '14052',
  'north':   52.5261291,
  'east':    13.2740286,
  'south':   52.5022797,
  'west':    13.2340938
}, {'zipCode': '14053', 'north': 52.521817, 'east': 13.252379, 'south': 52.509962, 'west': 13.2231215}, {
  'zipCode': '14055',
  'north':   52.5140084,
  'east':    13.2874647,
  'south':   52.4860006,
  'west':    13.1990817
}, {'zipCode': '14057', 'north': 52.5114959, 'east': 13.2991984, 'south': 52.50235, 'west': 13.2733298}, {
  'zipCode': '14059',
  'north':   52.5316741,
  'east':    13.3001223,
  'south':   52.5102241,
  'west':    13.2739115
}, {'zipCode': '14089', 'north': 52.50162310000001, 'east': 13.191709300000001, 'south': 52.439615, 'west': 13.109295}, {
  'zipCode': '14109',
  'north':   52.4521973,
  'east':    13.2004562,
  'south':   52.3872254,
  'west':    13.088345
}, {'zipCode': '14129', 'north': 52.4713471, 'east': 13.2420221, 'south': 52.4154085, 'west': 13.1618615}, {
  'zipCode': '14163',
  'north':   52.4552041,
  'east':    13.2597035,
  'south':   52.4193503,
  'west':    13.214849
}, {'zipCode': '14165', 'north': 52.43065810000001, 'east': 13.270155, 'south': 52.404234100000004, 'west': 13.2252617}, {
  'zipCode': '14167',
  'north':   52.4388987,
  'east':    13.2975759,
  'south':   52.404189,
  'west':    13.2573894
}, {'zipCode': '14169', 'north': 52.4691935, 'east': 13.2853174, 'south': 52.43065810000001, 'west': 13.2349651}, {
  'zipCode': '14193',
  'north':   52.5052072,
  'east':    13.299661600000002,
  'south':   52.4571354,
  'west':    13.1865954
}, {'zipCode': '14195', 'north': 52.475313, 'east': 13.3107973, 'south': 52.441804, 'west': 13.2561187}, {
  'zipCode': '14197',
  'north':   52.4805375,
  'east':    13.323749400000002,
  'south':   52.4658333,
  'west':    13.2989981
}, {'zipCode': '14199', 'north': 52.4885763, 'east': 13.3106116, 'south': 52.467355700000006, 'west': 13.2788745}, {
  'zipCode': '14467',
  'north':   52.4196325,
  'east':    13.0910434,
  'south':   52.3881158,
  'west':    13.0441732
}, {'zipCode': '14469', 'north': 52.4536928, 'east': 13.1231531, 'south': 52.3924162, 'west': 12.9345863}, {
  'zipCode': '14471',
  'north':   52.4010618,
  'east':    13.0481554,
  'south':   52.3560059,
  'west':    12.9712508
}, {'zipCode': '14473', 'north': 52.4006522, 'east': 13.091511100000002, 'south': 52.3420411, 'west': 13.0080147}, {
  'zipCode': '14476',
  'north':   52.51468760000001,
  'east':    13.1572153,
  'south':   52.3845072,
  'west':    12.8867757
}, {'zipCode': '14478', 'north': 52.3837685, 'east': 13.1201465, 'south': 52.3492626, 'west': 13.0627689}, {
  'zipCode': '14480',
  'north':   52.3972467,
  'east':    13.1682033,
  'south':   52.349946900000006,
  'west':    13.1100889
}, {'zipCode': '14482', 'north': 52.4137954, 'east': 13.1389502, 'south': 52.3703857, 'west': 13.074402}, {
  'zipCode': '14513',
  'north':   52.4162514,
  'east':    13.3123024,
  'south':   52.3500011,
  'west':    13.2331969
}, {'zipCode': '14532', 'north': 52.4211746, 'east': 13.2543053, 'south': 52.319316, 'west': 13.1401119}, {
  'zipCode': '14542',
  'north':   52.4605516,
  'east':    12.968394400000001,
  'south':   52.2990301,
  'west':    12.803210000000002
}, {'zipCode': '14547', 'north': 52.3036538, 'east': 13.1030089, 'south': 52.1411865, 'west': 12.7801378}, {
  'zipCode': '14548',
  'north':   52.3930041,
  'east':    13.0226701,
  'south':   52.2744666,
  'west':    12.8517722
}, {'zipCode': '14550', 'north': 52.4733835, 'east': 12.8643665, 'south': 52.3620875, 'west': 12.6556331}, {
  'zipCode': '14552',
  'north':   52.3485102,
  'east':    13.1158029,
  'south':   52.2253368,
  'west':    12.96701
}, {'zipCode': '14554', 'north': 52.305847, 'east': 13.0653341, 'south': 52.2546656, 'west': 12.9521573}, {
  'zipCode': '14558',
  'north':   52.3647683,
  'east':    13.1764756,
  'south':   52.2477815,
  'west':    13.0482155
}, {'zipCode': '14612', 'north': 52.6127414, 'east': 13.1536906, 'south': 52.543792, 'west': 13.0003014}, {
  'zipCode': '14621',
  'north':   52.692524,
  'east':    13.1659903,
  'south':   52.5869228,
  'west':    12.901252500000002
}, {'zipCode': '14624', 'north': 52.5582788, 'east': 13.168822700000002, 'south': 52.4593111, 'west': 12.9920586}, {
  'zipCode': '14641',
  'north':   52.7253751,
  'east':    13.019076,
  'south':   52.5013158,
  'west':    12.622148
}, {'zipCode': '14656', 'north': 52.615503, 'east': 13.0493725, 'south': 52.55820730000001, 'west': 12.8986449}, {
  'zipCode': '14662',
  'north':   52.8106114,
  'east':    12.7409758,
  'south':   52.62460240000001,
  'west':    12.5137151
}, {'zipCode': '14669', 'north': 52.5617054, 'east': 12.9471305, 'south': 52.4503127, 'west': 12.734053900000001}, {
  'zipCode': '14712',
  'north':   52.67177860000001,
  'east':    12.4309085,
  'south':   52.5466338,
  'west':    12.2310775
}, {'zipCode': '14715', 'north': 52.8009144, 'east': 12.6952446, 'south': 52.4383647, 'west': 12.1172961}, {
  'zipCode': '14727',
  'north':   52.5892534,
  'east':    12.4306604,
  'south':   52.5009436,
  'west':    12.3040975
}, {'zipCode': '14728', 'north': 52.7945295, 'east': 12.5610706, 'south': 52.6769034, 'west': 12.268642}, {
  'zipCode': '14770',
  'north':   52.4257399,
  'east':    12.5661948,
  'south':   52.3877935,
  'west':    12.4611902
}, {'zipCode': '14772', 'north': 52.4754486, 'east': 12.5732914, 'south': 52.4049433, 'west': 12.4433354}, {
  'zipCode': '14774',
  'north':   52.458671,
  'east':    12.4820445,
  'south':   52.3115514,
  'west':    12.3613397
}, {'zipCode': '14776', 'north': 52.4590788, 'east': 12.7050093, 'south': 52.3189615, 'west': 12.4495814}, {
  'zipCode': '14778',
  'north':   52.55008420000001,
  'east':    12.7595184,
  'south':   52.2372983,
  'west':    12.3861439
}, {'zipCode': '14789', 'north': 52.4541795, 'east': 12.4227416, 'south': 52.3025934, 'west': 12.2731717}, {
  'zipCode': '14793',
  'north':   52.3109718,
  'east':    12.4752423,
  'south':   52.18453310000001,
  'west':    12.241742
}, {'zipCode': '14797', 'north': 52.396155, 'east': 12.827867, 'south': 52.242484600000004, 'west': 12.4999088}, {
  'zipCode': '14798',
  'north':   52.5572154,
  'east':    12.5831346,
  'south':   52.4157779,
  'west':    12.4063231
}, {'zipCode': '14806', 'north': 52.2704984, 'east': 12.7535781, 'south': 52.069741, 'west': 12.4340506}, {
  'zipCode': '14822',
  'north':   52.3085257,
  'east':    12.9021358,
  'south':   52.0888642,
  'west':    12.611251300000001
}, {'zipCode': '14823', 'north': 52.1429904, 'east': 12.7812628, 'south': 51.9814631, 'west': 12.5349644}, {
  'zipCode': '14827',
  'north':   52.1789489,
  'east':    12.5814672,
  'south':   52.0007196,
  'west':    12.2559981
}, {'zipCode': '14828', 'north': 52.2070533, 'east': 12.4441228, 'south': 52.134761, 'west': 12.2158557}, {
  'zipCode': '14913',
  'north':   52.096028,
  'east':    13.3368821,
  'south':   51.8188314,
  'west':    12.7699232
}, {'zipCode': '14929', 'north': 52.187528300000004, 'east': 13.026818, 'south': 51.9794258, 'west': 12.6991172}, {
  'zipCode': '14943',
  'north':   52.13401850000001,
  'east':    13.2134088,
  'south':   52.0268417,
  'west':    13.0801579
}, {'zipCode': '14947', 'north': 52.2081161, 'east': 13.414706, 'south': 51.9810822, 'west': 12.9501388}, {
  'zipCode': '14959',
  'north':   52.2731901,
  'east':    13.3300447,
  'south':   52.1515346,
  'west':    13.082672800000001
}, {'zipCode': '14974', 'north': 52.3388974, 'east': 13.3937364, 'south': 52.2448952, 'west': 13.1375152}, {
  'zipCode': '14979',
  'north':   52.4113349,
  'east':    13.3884283,
  'south':   52.317147,
  'west':    13.2403594
}, {'zipCode': '15230', 'north': 52.3560097, 'east': 14.5763718, 'south': 52.3160767, 'west': 14.5253606}, {
  'zipCode': '15232',
  'north':   52.3397804,
  'east':    14.550831600000002,
  'south':   52.3179233,
  'west':    14.5297762
}, {'zipCode': '15234', 'north': 52.3980721, 'east': 14.553283199999997, 'south': 52.26633330000001, 'west': 14.3948254}, {
  'zipCode': '15236',
  'north':   52.409615,
  'east':    14.601364399999998,
  'south':   52.2528709,
  'west':    14.3014142
}, {'zipCode': '15295', 'north': 52.2870972, 'east': 14.7162554, 'south': 52.1945498, 'west': 14.453190100000002}, {
  'zipCode': '15299',
  'north':   52.3166285,
  'east':    14.529841100000002,
  'south':   52.0486646,
  'west':    14.3101898
}, {'zipCode': '15306', 'north': 52.619077, 'east': 14.5209565, 'south': 52.4029182, 'west': 14.2192427}, {
  'zipCode': '15320',
  'north':   52.7156099,
  'east':    14.30748,
  'south':   52.5445602,
  'west':    14.1303515
}, {'zipCode': '15324', 'north': 52.7608542, 'east': 14.498175899999998, 'south': 52.5894152, 'west': 14.2697216}, {
  'zipCode': '15326',
  'north':   52.50353090000001,
  'east':    14.611260999999999,
  'south':   52.3739688,
  'west':    14.3140045
}, {'zipCode': '15328', 'north': 52.647035, 'east': 14.639719600000001, 'south': 52.4695529, 'west': 14.4112712}, {
  'zipCode': '15344',
  'north':   52.6035192,
  'east':    14.0208136,
  'south':   52.5142033,
  'west':    13.8131493
}, {'zipCode': '15345', 'north': 52.7153583, 'east': 14.1296606, 'south': 52.4675373, 'west': 13.6686552}, {
  'zipCode': '15366',
  'north':   52.56948,
  'east':    13.7428712,
  'south':   52.4664541,
  'west':    13.6105904
}, {'zipCode': '15370', 'north': 52.5515708, 'east': 13.8357909, 'south': 52.4864889, 'west': 13.7097245}, {
  'zipCode': '15374',
  'north':   52.6012364,
  'east':    14.2591175,
  'south':   52.4465329,
  'west':    13.9526402
}, {'zipCode': '15377', 'north': 52.652784100000005, 'east': 14.179938, 'south': 52.5099396, 'west': 13.9265487}, {
  'zipCode': '15378',
  'north':   52.5297785,
  'east':    13.8890105,
  'south':   52.4538864,
  'west':    13.7991407
}, {'zipCode': '15517', 'north': 52.4268707, 'east': 14.1495065, 'south': 52.322678, 'west': 13.9378317}, {
  'zipCode': '15518',
  'north':   52.4989026,
  'east':    14.404198300000001,
  'south':   52.276075,
  'west':    13.9772591
}, {'zipCode': '15526', 'north': 52.3298976, 'east': 14.1174816, 'south': 52.2033343, 'west': 13.9430989}, {
  'zipCode': '15528',
  'north':   52.3998809,
  'east':    14.0065898,
  'south':   52.2762646,
  'west':    13.7348842
}, {'zipCode': '15537', 'north': 52.4787793, 'east': 14.0243665, 'south': 52.3505588, 'west': 13.686125100000002}, {
  'zipCode': '15562',
  'north':   52.513301000000006,
  'east':    13.8727383,
  'south':   52.4374375,
  'west':    13.7405876
}, {'zipCode': '15566', 'north': 52.5008842, 'east': 13.7438842, 'south': 52.4547613, 'west': 13.6672387}, {
  'zipCode': '15569',
  'north':   52.46711,
  'east':    13.8019811,
  'south':   52.4306745,
  'west':    13.7205844
}, {'zipCode': '15711', 'north': 52.3193943, 'east': 13.6722279, 'south': 52.2536551, 'west': 13.5683735}, {
  'zipCode': '15712',
  'north':   52.330852,
  'east':    13.754945100000002,
  'south':   52.2648883,
  'west':    13.6397727
}, {'zipCode': '15713', 'north': 52.3893919, 'east': 13.7570863, 'south': 52.2979654, 'west': 13.6419008}, {
  'zipCode': '15732',
  'north':   52.3813544,
  'east':    13.6469624,
  'south':   52.3372646,
  'west':    13.5611201
}, {'zipCode': '15738', 'north': 52.3691655, 'east': 13.6541117, 'south': 52.31063220000001, 'west': 13.5607045}, {
  'zipCode': '15741',
  'north':   52.2604063,
  'east':    13.7109897,
  'south':   52.194598,
  'west':    13.5871978
}, {'zipCode': '15745', 'north': 52.34161, 'east': 13.6454285, 'south': 52.3057709, 'west': 13.589836}, {
  'zipCode': '15746',
  'north':   52.2024763,
  'east':    13.7808466,
  'south':   52.1089423,
  'west':    13.5877948
}, {'zipCode': '15748', 'north': 52.19261600000001, 'east': 13.8940279, 'south': 52.06216580000001, 'west': 13.7367366}, {
  'zipCode': '15749',
  'north':   52.3311834,
  'east':    13.6240959,
  'south':   52.142602,
  'west':    13.449736400000003
}, {'zipCode': '15754', 'north': 52.32301840000001, 'east': 13.8629036, 'south': 52.180444800000004, 'west': 13.6693107}, {
  'zipCode': '15755',
  'north':   52.165344,
  'east':    13.6659989,
  'south':   52.073065500000006,
  'west':    13.540784
}, {'zipCode': '15757', 'north': 52.1349833, 'east': 13.776963, 'south': 52.0260133, 'west': 13.6227983}, {
  'zipCode': '15806',
  'north':   52.2820009,
  'east':    13.5822184,
  'south':   52.092166,
  'west':    13.2917839
}, {'zipCode': '15827', 'north': 52.352466, 'east': 13.4644142, 'south': 52.2995145, 'west': 13.3435255}, {
  'zipCode': '15831',
  'north':   52.3778616,
  'east':    13.4990705,
  'south':   52.2871203,
  'west':    13.3443605
}, {'zipCode': '15834', 'north': 52.3221044, 'east': 13.5112614, 'south': 52.2549391, 'west': 13.3906465}, {
  'zipCode': '15837',
  'north':   52.1128268,
  'east':    13.6528744,
  'south':   51.9566804,
  'west':    13.2785761
}, {'zipCode': '15838', 'north': 52.21248070000001, 'east': 13.4674591, 'south': 52.0867521, 'west': 13.2981975}, {
  'zipCode': '15848',
  'north':   52.3316921,
  'east':    14.4703,
  'south':   52.0241607,
  'west':    14.0055457
}, {'zipCode': '15859', 'north': 52.3114937, 'east': 14.0666109, 'south': 52.1158164, 'west': 13.817505300000002}, {
  'zipCode': '15864',
  'north':   52.2586391,
  'east':    14.1035588,
  'south':   52.1758368,
  'west':    13.9338911
}, {'zipCode': '15868', 'north': 52.0724033, 'east': 14.4534531, 'south': 51.9322453, 'west': 14.2201254}, {
  'zipCode': '15890',
  'north':   52.2355035,
  'east':    14.705567199999999,
  'south':   52.0843505,
  'west':    14.4186097
}, {'zipCode': '15898', 'north': 52.1294116, 'east': 14.759094, 'south': 51.9997229, 'west': 14.4791494}, {
  'zipCode': '15907',
  'north':   51.9987877,
  'east':    14.0195732,
  'south':   51.892133,
  'west':    13.78802
}, {'zipCode': '15910', 'north': 52.1313384, 'east': 13.9335722, 'south': 51.90245230000001, 'west': 13.6335763}, {
  'zipCode': '15913',
  'north':   52.1377844,
  'east':    14.3442982,
  'south':   51.8570166,
  'west':    13.8797173
}, {'zipCode': '15926', 'north': 51.9286085, 'east': 13.8663377, 'south': 51.7267821, 'west': 13.5053206}, {
  'zipCode': '15936',
  'north':   51.9771469,
  'east':    13.5822093,
  'south':   51.7907328,
  'west':    13.2715582
}, {'zipCode': '15938', 'north': 52.0105984, 'east': 13.7639089, 'south': 51.894611, 'west': 13.4109416}, {
  'zipCode': '16225',
  'north':   52.8822012,
  'east':    13.9102101,
  'south':   52.7764335,
  'west':    13.657660600000002
}, {'zipCode': '16227', 'north': 52.867496, 'east': 13.7772854, 'south': 52.8120864, 'west': 13.694628100000001}, {
  'zipCode': '16230',
  'north':   52.9674715,
  'east':    14.0271265,
  'south':   52.69362,
  'west':    13.6648817
}, {'zipCode': '16244', 'north': 52.9994756, 'east': 13.79077, 'south': 52.7990212, 'west': 13.3975664}, {
  'zipCode': '16247',
  'north':   53.0586826,
  'east':    13.9531005,
  'south':   52.901542,
  'west':    13.6020169
}, {'zipCode': '16248', 'north': 52.9664651, 'east': 14.157439, 'south': 52.78771540000001, 'west': 13.8391421}, {
  'zipCode': '16259',
  'north':   52.90330500000001,
  'east':    14.3311817,
  'south':   52.665823,
  'west':    13.7310804
}, {'zipCode': '16269', 'north': 52.782507, 'east': 14.2570969, 'south': 52.638751, 'west': 13.9766936}, {
  'zipCode': '16278',
  'north':   53.159415,
  'east':    14.2060467,
  'south':   52.9314894,
  'west':    13.7865307
}, {'zipCode': '16303', 'north': 53.1754243, 'east': 14.381656299999998, 'south': 52.9862379, 'west': 14.1305375}, {
  'zipCode': '16306',
  'north':   53.267767,
  'east':    14.345363800000001,
  'south':   53.03626680000001,
  'west':    14.0008728
}, {'zipCode': '16307', 'north': 53.3300645, 'east': 14.4499946, 'south': 53.1259362, 'west': 14.2619808}, {
  'zipCode': '16321',
  'north':   52.757096,
  'east':    13.6684599,
  'south':   52.6085389,
  'west':    13.4671216
}, {'zipCode': '16341', 'north': 52.679171100000005, 'east': 13.5807267, 'south': 52.6005975, 'west': 13.4745328}, {
  'zipCode': '16348',
  'north':   52.8857141,
  'east':    13.6508285,
  'south':   52.6354682,
  'west':    13.3911816
}, {'zipCode': '16356', 'north': 52.7136828, 'east': 13.858771500000001, 'south': 52.5455665, 'west': 13.4966629}, {
  'zipCode': '16359',
  'north':   52.8301641,
  'east':    13.688106400000002,
  'south':   52.69885800000001,
  'west':    13.5799848
}, {'zipCode': '16515', 'north': 52.8464685, 'east': 13.4306044, 'south': 52.6956627, 'west': 13.1309851}, {
  'zipCode': '16540',
  'north':   52.691232,
  'east':    13.304232100000002,
  'south':   52.6268645,
  'west':    13.2292456
}, {'zipCode': '16547', 'north': 52.7264866, 'east': 13.3661962, 'south': 52.6809491, 'west': 13.2445277}, {
  'zipCode': '16548',
  'north':   52.64353,
  'east':    13.3614931,
  'south':   52.6226513,
  'west':    13.3025454
}, {'zipCode': '16552', 'north': 52.6558626, 'east': 13.394477700000001, 'south': 52.6227308, 'west': 13.343974}, {
  'zipCode': '16556',
  'north':   52.7408647,
  'east':    13.3152707,
  'south':   52.6910107,
  'west':    13.2060415
}, {'zipCode': '16559', 'north': 52.9377666, 'east': 13.5230803, 'south': 52.7955133, 'west': 13.2475817}, {
  'zipCode': '16562',
  'north':   52.70046020000001,
  'east':    13.3595883,
  'south':   52.6573665,
  'west':    13.288684
}, {'zipCode': '16567', 'north': 52.6980887, 'east': 13.422670000000002, 'south': 52.6351295, 'west': 13.3004321}, {
  'zipCode': '16727',
  'north':   52.762715,
  'east':    13.2502057,
  'south':   52.6068511,
  'west':    12.981784
}, {'zipCode': '16761', 'north': 52.6758077, 'east': 13.2436662, 'south': 52.5865996, 'west': 13.1528447}, {
  'zipCode': '16766',
  'north':   52.89728300000001,
  'east':    13.2030507,
  'south':   52.7009697,
  'west':    12.846216
}, {'zipCode': '16767', 'north': 52.74517240000001, 'east': 13.214839, 'south': 52.7044948, 'west': 13.1731946}, {
  'zipCode': '16775',
  'north':   53.1855542,
  'east':    13.3745457,
  'south':   52.7975691,
  'west':    12.9378272
}, {'zipCode': '16792', 'north': 53.0794079, 'east': 13.5175627, 'south': 52.9012033, 'west': 13.209947700000003}, {
  'zipCode': '16798',
  'north':   53.2512282,
  'east':    13.3673214,
  'south':   53.0529821,
  'west':    13.0260897
}, {'zipCode': '16816', 'north': 52.9803657, 'east': 12.8776752, 'south': 52.8549622, 'west': 12.7347971}, {
  'zipCode': '16818',
  'north':   53.1035089,
  'east':    12.9863611,
  'south':   52.6810392,
  'west':    12.5304535
}, {'zipCode': '16827', 'north': 53.03722260000001, 'east': 12.9687608, 'south': 52.9338146, 'west': 12.7740012}, {
  'zipCode': '16831',
  'north':   53.2002456,
  'east':    13.0281611,
  'south':   53.0223127,
  'west':    12.7709124
}, {'zipCode': '16833', 'north': 52.8873392, 'east': 12.9226104, 'south': 52.691337, 'west': 12.645975300000002}, {
  'zipCode': '16835',
  'north':   53.0750714,
  'east':    13.0578059,
  'south':   52.8422035,
  'west':    12.8718264
}, {'zipCode': '16837', 'north': 53.210074, 'east': 12.8751783, 'south': 53.0927686, 'west': 12.6416337}, {
  'zipCode': '16845',
  'north':   52.9759454,
  'east':    12.7216108,
  'south':   52.752021400000004,
  'west':    12.1200586
}, {'zipCode': '16866', 'north': 53.0819678, 'east': 12.5638781, 'south': 52.8926679, 'west': 12.054048}, {
  'zipCode': '16868',
  'north':   52.9563413,
  'east':    12.5244129,
  'south':   52.8647235,
  'west':    12.4266024
}, {'zipCode': '16909', 'north': 53.321574, 'east': 12.758208, 'south': 53.0146335, 'west': 12.2892172}, {
  'zipCode': '16928',
  'north':   53.2730884,
  'east':    12.341074,
  'south':   53.0164627,
  'west':    11.8522236
}, {'zipCode': '16945', 'north': 53.371256, 'east': 12.3558559, 'south': 53.1972015, 'west': 12.0140259}, {
  'zipCode': '16949',
  'north':   53.321397,
  'east':    12.1613643,
  'south':   53.177176,
  'west':    11.9121109
}, {'zipCode': '17033', 'north': 53.61899950000001, 'east': 13.3004543, 'south': 53.4441983, 'west': 13.1367108}, {
  'zipCode': '17034',
  'north':   53.5989298,
  'east':    13.3397506,
  'south':   53.5571401,
  'west':    13.2511298
}, {'zipCode': '17036', 'north': 53.5616607, 'east': 13.3441823, 'south': 53.5209899, 'west': 13.2771697}, {
  'zipCode': '17039',
  'north':   53.7182422,
  'east':    13.4881133,
  'south':   53.4964136,
  'west':    13.0882638
}, {'zipCode': '17087', 'north': 53.7497243, 'east': 13.316794, 'south': 53.6493748, 'west': 13.1688514}, {
  'zipCode': '17089',
  'north':   53.84887870000001,
  'east':    13.4323762,
  'south':   53.6705174,
  'west':    13.1301724
}, {'zipCode': '17091', 'north': 53.786908, 'east': 13.2662769, 'south': 53.5445633, 'west': 12.9578711}, {
  'zipCode': '17094',
  'north':   53.5633863,
  'east':    13.4944753,
  'south':   53.4221427,
  'west':    13.1759224
}, {'zipCode': '17098', 'north': 53.7517122, 'east': 13.662252600000002, 'south': 53.5565307, 'west': 13.407236000000001}, {
  'zipCode': '17099',
  'north':   53.7038558,
  'east':    13.7950281,
  'south':   53.5869573,
  'west':    13.4201814
}, {'zipCode': '17109', 'north': 53.9805232, 'east': 13.1220699, 'south': 53.8662931, 'west': 12.9458779}, {
  'zipCode': '17111',
  'north':   54.00443200000001,
  'east':    13.233352,
  'south':   53.7331694,
  'west':    12.8631312
}, {'zipCode': '17121', 'north': 54.0559571, 'east': 13.3425269, 'south': 53.9310627, 'west': 12.986939}, {
  'zipCode': '17126',
  'north':   53.9380471,
  'east':    13.4093903,
  'south':   53.8549257,
  'west':    13.2649403
}, {'zipCode': '17129', 'north': 53.9743544, 'east': 13.390852, 'south': 53.8074305, 'west': 13.181461900000002}, {
  'zipCode': '17139',
  'north':   53.8483676,
  'east':    12.9122921,
  'south':   53.58602950000001,
  'west':    12.623475
}, {'zipCode': '17153', 'north': 53.774933, 'east': 13.0555026, 'south': 53.5893861, 'west': 12.7893532}, {
  'zipCode': '17154',
  'north':   53.8720161,
  'east':    12.8689297,
  'south':   53.7789808,
  'west':    12.702702
}, {'zipCode': '17159', 'north': 54.004853, 'east': 12.9332805, 'south': 53.8337227, 'west': 12.7651799}, {
  'zipCode': '17166',
  'north':   53.833932100000006,
  'east':    12.70438,
  'south':   53.640786,
  'west':    12.427608
}, {'zipCode': '17168', 'north': 53.942633, 'east': 12.756953, 'south': 53.8014481, 'west': 12.423177}, {
  'zipCode': '17179',
  'north':   54.04650180000001,
  'east':    12.815898,
  'south':   53.8601961,
  'west':    12.500728
}, {'zipCode': '17192', 'north': 53.6249754, 'east': 12.9105187, 'south': 53.3960267, 'west': 12.5614264}, {
  'zipCode': '17194',
  'north':   53.69849,
  'east':    12.7323226,
  'south':   53.4880697,
  'west':    12.396036
}, {'zipCode': '17207', 'north': 53.4324321, 'east': 12.7806685, 'south': 53.2912441, 'west': 12.5097181}, {
  'zipCode': '17209',
  'north':   53.4600533,
  'east':    12.7223331,
  'south':   53.2443351,
  'west':    12.274995
}, {'zipCode': '17213', 'north': 53.5112619, 'east': 12.5795747, 'south': 53.37085900000001, 'west': 12.331899}, {
  'zipCode': '17214',
  'north':   53.593705,
  'east':    12.4869388,
  'south':   53.47782900000001,
  'west':    12.289263
}, {'zipCode': '17217', 'north': 53.5682333, 'east': 13.1851903, 'south': 53.4612468, 'west': 12.9650383}, {
  'zipCode': '17219',
  'north':   53.5959622,
  'east':    13.0257781,
  'south':   53.4509547,
  'west':    12.8591071
}, {'zipCode': '17235', 'north': 53.411847800000004, 'east': 13.1808998, 'south': 53.2608291, 'west': 12.9284887}, {
  'zipCode': '17237',
  'north':   53.479549,
  'east':    13.422034,
  'south':   53.2166111,
  'west':    12.8438899
}, {'zipCode': '17248', 'north': 53.4311157, 'east': 12.8605264, 'south': 53.2262601, 'west': 12.6638843}, {
  'zipCode': '17252',
  'north':   53.3892627,
  'east':    12.9699517,
  'south':   53.187351,
  'west':    12.6913992
}, {'zipCode': '17255', 'north': 53.3145265, 'east': 13.1081776, 'south': 53.162734400000005, 'west': 12.8545318}, {
  'zipCode': '17258',
  'north':   53.4113353,
  'east':    13.5568602,
  'south':   53.2427328,
  'west':    13.207303
}, {'zipCode': '17268', 'north': 53.3509481, 'east': 13.9130406, 'south': 52.986879800000004, 'west': 13.3221909}, {
  'zipCode': '17279',
  'north':   53.2809696,
  'east':    13.4297278,
  'south':   53.1107154,
  'west':    13.2397842
}, {'zipCode': '17291', 'north': 53.4349135, 'east': 14.1746659, 'south': 53.1387573, 'west': 13.5163553}, {
  'zipCode': '17309',
  'north':   53.6203264,
  'east':    14.2064279,
  'south':   53.41084680000001,
  'west':    13.7997286
}, {'zipCode': '17321', 'north': 53.5931097, 'east': 14.3718858, 'south': 53.3959774, 'west': 14.1105107}, {
  'zipCode': '17322',
  'north':   53.5793684,
  'east':    14.3953797,
  'south':   53.345661,
  'west':    14.0637579
}, {'zipCode': '17326', 'north': 53.441704, 'east': 14.2437564, 'south': 53.3218523, 'west': 14.0509722}, {
  'zipCode': '17328',
  'north':   53.3420613,
  'east':    14.3163464,
  'south':   53.25470640000001,
  'west':    14.0990281
}, {'zipCode': '17329', 'north': 53.3715762, 'east': 14.412220799999998, 'south': 53.30990140000001, 'west': 14.2035833}, {
  'zipCode': '17335',
  'north':   53.6215956,
  'east':    13.8264064,
  'south':   53.4741667,
  'west':    13.6690166
}, {'zipCode': '17337', 'north': 53.5998715, 'east': 13.918525900000002, 'south': 53.3867042, 'west': 13.6256799}, {
  'zipCode': '17348',
  'north':   53.5215313,
  'east':    13.7094304,
  'south':   53.3883777,
  'west':    13.3940627
}, {'zipCode': '17349', 'north': 53.605283, 'east': 13.683054, 'south': 53.4348404, 'west': 13.3528159}, {
  'zipCode': '17358',
  'north':   53.6979056,
  'east':    14.1144267,
  'south':   53.559909100000006,
  'west':    13.8902758
}, {'zipCode': '17367', 'north': 53.7339518, 'east': 14.2407373, 'south': 53.5943985, 'west': 14.0430366}, {
  'zipCode': '17373',
  'north':   53.7573254,
  'east':    14.1398584,
  'south':   53.6997709,
  'west':    13.9635671
}, {'zipCode': '17375', 'north': 53.7978369, 'east': 14.3169455, 'south': 53.5748329, 'west': 13.8264279}, {
  'zipCode': '17379',
  'north':   53.7533442,
  'east':    13.9863734,
  'south':   53.5586329,
  'west':    13.694269000000002
}, {'zipCode': '17389', 'north': 53.8759732, 'east': 13.816286, 'south': 53.773302400000006, 'west': 13.6228221}, {
  'zipCode': '17390',
  'north':   53.9882457,
  'east':    13.8299749,
  'south':   53.8550009,
  'west':    13.484525
}, {'zipCode': '17391', 'north': 53.9179399, 'east': 13.6434281, 'south': 53.7478884, 'west': 13.3432181}, {
  'zipCode': '17392',
  'north':   53.8463266,
  'east':    13.7118714,
  'south':   53.6950295,
  'west':    13.3875533
}, {'zipCode': '17398', 'north': 53.854662600000005, 'east': 13.9439774, 'south': 53.692925, 'west': 13.6550044}, {
  'zipCode': '17406',
  'north':   53.99483,
  'east':    14.0381897,
  'south':   53.8396004,
  'west':    13.8218011
}, {'zipCode': '17419', 'north': 53.9473279, 'east': 14.2264269, 'south': 53.8653704, 'west': 14.0198109}, {
  'zipCode': '17424',
  'north':   53.9686041,
  'east':    14.1839936,
  'south':   53.9182207,
  'west':    14.1070126
}, {'zipCode': '17429', 'north': 53.9936556, 'east': 14.1526677, 'south': 53.8877681, 'west': 13.9583725}, {
  'zipCode': '17438',
  'north':   54.0852958,
  'east':    13.83057,
  'south':   53.9669985,
  'west':    13.6397774
}, {'zipCode': '17440', 'north': 54.2515796, 'east': 13.9341871, 'south': 53.8775561, 'west': 13.6611318}, {
  'zipCode': '17449',
  'north':   54.1797376,
  'east':    13.8967371,
  'south':   54.0603949,
  'west':    13.7509747
}, {'zipCode': '17454', 'north': 54.0896768, 'east': 13.934853, 'south': 54.053212, 'west': 13.8739159}, {
  'zipCode': '17459',
  'north':   54.0754257,
  'east':    14.1135961,
  'south':   53.9820455,
  'west':    13.924822
}, {'zipCode': '17489', 'north': 54.1089677, 'east': 13.4234752, 'south': 54.0591371, 'west': 13.3194771}, {
  'zipCode': '17491',
  'north':   54.0925196,
  'east':    13.4571466,
  'south':   54.055661,
  'west':    13.3969315
}, {'zipCode': '17493', 'north': 54.1853448, 'east': 13.5078273, 'south': 54.0541157, 'west': 13.3232988}, {
  'zipCode': '17495',
  'north':   54.0411247,
  'east':    13.6846962,
  'south':   53.929693,
  'west':    13.4194765
}, {'zipCode': '17498', 'north': 54.1935538, 'east': 13.5392283, 'south': 53.9650256, 'west': 13.216478}, {
  'zipCode': '17506',
  'north':   53.9931709,
  'east':    13.5268291,
  'south':   53.8893988,
  'west':    13.3116149
}, {'zipCode': '17509', 'north': 54.1720259, 'east': 13.79578, 'south': 54.0138302, 'west': 13.4762331}, {
  'zipCode': '18055',
  'north':   54.1048764,
  'east':    12.201404000000002,
  'south':   54.066894,
  'west':    12.126041000000003
}, {'zipCode': '18057', 'north': 54.1044654, 'east': 12.128673, 'south': 54.0793107, 'west': 12.0704821}, {
  'zipCode': '18059',
  'north':   54.083839,
  'east':    12.17757,
  'south':   53.971994,
  'west':    11.996445
}, {'zipCode': '18069', 'north': 54.1322839, 'east': 12.102857, 'south': 54.074412, 'west': 11.984296}, {
  'zipCode': '18106',
  'north':   54.144099,
  'east':    12.094906,
  'south':   54.1139644,
  'west':    12.019319
}, {'zipCode': '18107', 'north': 54.1750029, 'east': 12.065046, 'south': 54.126859, 'west': 11.976251}, {
  'zipCode': '18109',
  'north':   54.165737,
  'east':    12.0974587,
  'south':   54.137849,
  'west':    12.0195658
}, {'zipCode': '18119', 'north': 54.1876545, 'east': 12.141264, 'south': 54.155345, 'west': 11.998369}, {
  'zipCode': '18146',
  'north':   54.23093540000001,
  'east':    12.2954702,
  'south':   54.0881144,
  'west':    12.126471
}, {'zipCode': '18147', 'north': 54.167763, 'east': 12.16905, 'south': 54.094217, 'west': 12.091449}, {
  'zipCode': '18181',
  'north':   54.274638,
  'east':    12.2853049,
  'south':   54.22197,
  'west':    12.178073700000002
}, {'zipCode': '18182', 'north': 54.26094, 'east': 12.391998, 'south': 54.096803, 'west': 12.171189}, {
  'zipCode': '18184',
  'north':   54.1694961,
  'east':    12.354287,
  'south':   54.049216,
  'west':    12.190564
}, {'zipCode': '18190', 'north': 54.124409, 'east': 12.46391, 'south': 53.99557550000001, 'west': 12.297094}, {
  'zipCode': '18195',
  'north':   54.09761,
  'east':    12.659235,
  'south':   53.951128,
  'west':    12.3356259
}, {'zipCode': '18196', 'north': 54.0710631, 'east': 12.337756, 'south': 53.953511, 'west': 12.118216}, {
  'zipCode': '18198',
  'north':   54.09476500000001,
  'east':    12.079437,
  'south':   54.028244,
  'west':    11.970749
}, {'zipCode': '18209', 'north': 54.148952, 'east': 11.997623, 'south': 54.05845, 'west': 11.773545}, {
  'zipCode': '18211',
  'north':   54.1725405,
  'east':    12.022296,
  'south':   54.02813100000001,
  'west':    11.8375
}, {'zipCode': '18225', 'north': 54.156986, 'east': 11.785661, 'south': 54.114137, 'west': 11.692828}, {
  'zipCode': '18230',
  'north':   54.15396410000001,
  'east':    11.774572,
  'south':   54.017562000000005,
  'west':    11.511507
}, {'zipCode': '18233', 'north': 54.0699105, 'east': 11.7668417, 'south': 53.934186, 'west': 11.5615402}, {
  'zipCode': '18236',
  'north':   54.124291,
  'east':    11.866554,
  'south':   53.974385,
  'west':    11.717081
}, {'zipCode': '18239', 'north': 54.070264, 'east': 12.022904, 'south': 53.93294800000001, 'west': 11.781842000000001}, {
  'zipCode': '18246',
  'north':   53.987145,
  'east':    12.0939031,
  'south':   53.79077,
  'west':    11.756522
}, {'zipCode': '18249', 'north': 53.9451514, 'east': 12.050451000000002, 'south': 53.732195, 'west': 11.7900126}, {
  'zipCode': '18258',
  'north':   54.002207,
  'east':    12.189457000000003,
  'south':   53.860117,
  'west':    11.979253
}, {'zipCode': '18273', 'north': 53.837105, 'east': 12.294451, 'south': 53.750922, 'west': 12.119024}, {
  'zipCode': '18276',
  'north':   53.902921,
  'east':    12.374742,
  'south':   53.6357029,
  'west':    11.974885
}, {'zipCode': '18279', 'north': 53.834636, 'east': 12.495413000000001, 'south': 53.66022300000001, 'west': 12.283061}, {
  'zipCode': '18292',
  'north':   53.748226,
  'east':    12.422234,
  'south':   53.567368,
  'west':    12.1656911
}, {'zipCode': '18299', 'north': 54.0012836, 'east': 12.513841, 'south': 53.82026, 'west': 12.159886}, {
  'zipCode': '18311',
  'north':   54.3041542,
  'east':    12.531271,
  'south':   54.188698,
  'west':    12.297849
}, {'zipCode': '18314', 'north': 54.378427, 'east': 12.809453, 'south': 54.272471, 'west': 12.585277}, {
  'zipCode': '18317',
  'north':   54.356453,
  'east':    12.607717,
  'south':   54.27152900000001,
  'west':    12.467967000000002
}, {'zipCode': '18320', 'north': 54.289817, 'east': 12.736395, 'south': 54.188583, 'west': 12.489274}, {
  'zipCode': '18334',
  'north':   54.236428,
  'east':    12.798879,
  'south':   54.018714,
  'west':    12.435579
}, {'zipCode': '18337', 'north': 54.231488, 'east': 12.642712, 'south': 54.104753, 'west': 12.351583}, {
  'zipCode': '18347',
  'north':   54.3983718,
  'east':    12.450065,
  'south':   54.249104,
  'west':    12.278298
}, {'zipCode': '18356', 'north': 54.41129000000001, 'east': 12.790969, 'south': 54.325379, 'west': 12.534276}, {
  'zipCode': '18374',
  'north':   54.447907,
  'east':    12.92669,
  'south':   54.408553,
  'west':    12.631363
}, {'zipCode': '18375', 'north': 54.484453, 'east': 12.644634, 'south': 54.372959, 'west': 12.4345672}, {
  'zipCode': '18435',
  'north':   54.345769,
  'east':    13.0895471,
  'south':   54.3142106,
  'west':    13.0413766
}, {'zipCode': '18437', 'north': 54.322624, 'east': 13.0914268, 'south': 54.263406, 'west': 12.99386}, {
  'zipCode': '18439',
  'north':   54.3194097,
  'east':    13.1781757,
  'south':   54.245042,
  'west':    13.0768936
}, {'zipCode': '18442', 'north': 54.36679800000001, 'east': 13.132625000000003, 'south': 54.196389, 'west': 12.774491}, {
  'zipCode': '18445',
  'north':   54.466971,
  'east':    13.0941332,
  'south':   54.313255,
  'west':    12.879629
}, {'zipCode': '18461', 'north': 54.2437508, 'east': 12.969819, 'south': 54.088587, 'west': 12.730011}, {
  'zipCode': '18465',
  'north':   54.17751400000001,
  'east':    12.865181,
  'south':   54.053881,
  'west':    12.697487000000002
}, {'zipCode': '18469', 'north': 54.323582, 'east': 12.93465, 'south': 54.218557, 'west': 12.70128}, {'zipCode': '18507', 'north': 54.172351, 'east': 13.11988, 'south': 54.07287, 'west': 12.963852}, {
  'zipCode': '18510',
  'north':   54.2451536,
  'east':    13.122543,
  'south':   54.128837,
  'west':    12.890529100000002
}, {'zipCode': '18513', 'north': 54.141103, 'east': 13.03603, 'south': 53.973291, 'west': 12.74161}, {
  'zipCode': '18516',
  'north':   54.148928,
  'east':    13.288553,
  'south':   54.013586,
  'west':    12.961779
}, {'zipCode': '18519', 'north': 54.2664299, 'east': 13.3192696, 'south': 54.11303, 'west': 13.083694}, {
  'zipCode': '18528',
  'north':   54.5397451,
  'east':    13.61467,
  'south':   54.3592075,
  'west':    13.2909689
}, {'zipCode': '18546', 'north': 54.5837742, 'east': 13.6812683, 'south': 54.4704259, 'west': 13.525794}, {
  'zipCode': '18551',
  'north':   54.5854832,
  'east':    13.6408008,
  'south':   54.5037815,
  'west':    13.408147
}, {'zipCode': '18556', 'north': 54.6850775, 'east': 13.4378189, 'south': 54.557742, 'west': 13.1602588}, {
  'zipCode': '18565',
  'north':   54.6057131,
  'east':    13.1573225,
  'south':   54.456806,
  'west':    13.059764400000002
}, {'zipCode': '18569', 'north': 54.5810988, 'east': 13.393003200000003, 'south': 54.4031918, 'west': 13.1161801}, {
  'zipCode': '18573',
  'north':   54.42569730000001,
  'east':    13.362872900000001,
  'south':   54.3094241,
  'west':    13.1140107
}, {'zipCode': '18574', 'north': 54.3801964, 'east': 13.42442, 'south': 54.22081510000001, 'west': 13.1385868}, {
  'zipCode': '18581',
  'north':   54.3945198,
  'east':    13.5964677,
  'south':   54.301098100000004,
  'west':    13.3708123
}, {'zipCode': '18586', 'north': 54.3993556, 'east': 13.7665208, 'south': 54.2688567, 'west': 13.5888329}, {
  'zipCode': '18609',
  'north':   54.4754289,
  'east':    13.6742605,
  'south':   54.3706016,
  'west':    13.5239959
}, {'zipCode': '19053', 'north': 53.6477505, 'east': 11.42062, 'south': 53.6125387, 'west': 11.3900182}, {
  'zipCode': '19055',
  'north':   53.6873387,
  'east':    11.486009,
  'south':   53.6245643,
  'west':    11.3661998
}, {'zipCode': '19057', 'north': 53.6807938, 'east': 11.3895239, 'south': 53.6134074, 'west': 11.2960272}, {
  'zipCode': '19059',
  'north':   53.6575489,
  'east':    11.4071326,
  'south':   53.6210455,
  'west':    11.3771141
}, {'zipCode': '19061', 'north': 53.6286038, 'east': 11.483694, 'south': 53.5443597, 'west': 11.3561611}, {
  'zipCode': '19063',
  'north':   53.63444500000001,
  'east':    11.505544,
  'south':   53.5774954,
  'west':    11.4344444
}, {'zipCode': '19065', 'north': 53.683883, 'east': 11.613452, 'south': 53.56795, 'west': 11.486315}, {
  'zipCode': '19067',
  'north':   53.778316,
  'east':    11.601194,
  'south':   53.620264,
  'west':    11.450316
}, {'zipCode': '19069', 'north': 53.7774379, 'east': 11.501971, 'south': 53.667857, 'west': 11.2898819}, {
  'zipCode': '19071',
  'north':   53.7613887,
  'east':    11.3337866,
  'south':   53.588136,
  'west':    11.2089609
}, {'zipCode': '19073', 'north': 53.6536147, 'east': 11.3662943, 'south': 53.5242838, 'west': 11.1495976}, {
  'zipCode': '19075',
  'north':   53.59437140000001,
  'east':    11.4271084,
  'south':   53.5125586,
  'west':    11.233443
}, {'zipCode': '19077', 'north': 53.5502203, 'east': 11.5177873, 'south': 53.4114673, 'west': 11.3184441}, {
  'zipCode': '19079',
  'north':   53.588704,
  'east':    11.598655000000003,
  'south':   53.446717,
  'west':    11.466557
}, {'zipCode': '19086', 'north': 53.583636, 'east': 11.545552, 'south': 53.530661, 'west': 11.449898}, {
  'zipCode': '19089',
  'north':   53.663784,
  'east':    11.858328,
  'south':   53.463637,
  'west':    11.552784
}, {'zipCode': '19205', 'north': 53.7947309, 'east': 11.2797549, 'south': 53.5966615, 'west': 10.9153488}, {
  'zipCode': '19209',
  'north':   53.699305200000005,
  'east':    11.2650745,
  'south':   53.5664246,
  'west':    11.101973
}, {'zipCode': '19217', 'north': 53.8396401, 'east': 11.1718129, 'south': 53.6990483, 'west': 10.7565778}, {
  'zipCode': '19230',
  'north':   53.5279641,
  'east':    11.4338268,
  'south':   53.2893057,
  'west':    11.0261435
}, {'zipCode': '19243', 'north': 53.6077616, 'east': 11.2168499, 'south': 53.4165215, 'west': 10.9738738}, {
  'zipCode': '19246',
  'north':   53.64664150000001,
  'east':    11.023007000000002,
  'south':   53.4550287,
  'west':    10.8134133
}, {'zipCode': '19249', 'north': 53.3603413, 'east': 11.1729986, 'south': 53.2183654, 'west': 10.982715700000002}, {
  'zipCode': '19258',
  'north':   53.5161822,
  'east':    10.9184173,
  'south':   53.3228344,
  'west':    10.5938299
}, {'zipCode': '19260', 'north': 53.4828611, 'east': 11.0619564, 'south': 53.3362968, 'west': 10.8452318}, {
  'zipCode': '19273',
  'north':   53.3869552,
  'east':    11.1957671,
  'south':   53.1397093,
  'west':    10.730503800000001
}, {'zipCode': '19288', 'north': 53.435393, 'east': 11.6173558, 'south': 53.2583923, 'west': 11.2126472}, {
  'zipCode': '19294',
  'north':   53.300993,
  'east':    11.564288700000002,
  'south':   53.1339826,
  'west':    11.216263
}, {'zipCode': '19300', 'north': 53.34140060000001, 'east': 11.790872, 'south': 53.1210465, 'west': 11.4214518}, {
  'zipCode': '19303',
  'north':   53.2951027,
  'east':    11.4023177,
  'south':   53.1098405,
  'west':    11.0853613
}, {'zipCode': '19306', 'north': 53.485726, 'east': 11.699399, 'south': 53.327127, 'west': 11.4960711}, {
  'zipCode': '19309',
  'north':   53.173999200000004,
  'east':    11.7129514,
  'south':   53.0351585,
  'west':    11.2662278
}, {'zipCode': '19322', 'north': 53.0654761, 'east': 11.939999, 'south': 52.8916775, 'west': 11.6250635}, {
  'zipCode': '19336',
  'north':   53.0117956,
  'east':    12.0469069,
  'south':   52.8760927,
  'west':    11.8316346
}, {'zipCode': '19339', 'north': 53.0831845, 'east': 12.210234, 'south': 52.875821, 'west': 11.8822605}, {
  'zipCode': '19348',
  'north':   53.278955,
  'east':    11.9992999,
  'south':   53.0059242,
  'west':    11.6925954
}, {'zipCode': '19357', 'north': 53.2750278, 'east': 11.8549755, 'south': 53.0712721, 'west': 11.5507971}, {
  'zipCode': '19370',
  'north':   53.504051,
  'east':    11.939071,
  'south':   53.368512,
  'west':    11.73381
}, {'zipCode': '19372', 'north': 53.497033, 'east': 11.987587, 'south': 53.2431744, 'west': 11.617829}, {
  'zipCode': '19374',
  'north':   53.625934,
  'east':    11.975397,
  'south':   53.424736,
  'west':    11.62975
}, {'zipCode': '19376', 'north': 53.4252875, 'east': 12.064795, 'south': 53.271419, 'west': 11.813139}, {
  'zipCode': '19386',
  'north':   53.546565,
  'east':    12.200069,
  'south':   53.34353,
  'west':    11.90519
}, {'zipCode': '19395', 'north': 53.5972663, 'east': 12.3665003, 'south': 53.317902, 'west': 12.113134}, {
  'zipCode': '19399',
  'north':   53.677115,
  'east':    12.253022,
  'south':   53.519665,
  'west':    11.937135
}, {'zipCode': '19406', 'north': 53.770919000000006, 'east': 12.044831, 'south': 53.601873, 'west': 11.760483}, {
  'zipCode': '19412',
  'north':   53.798361,
  'east':    11.805387,
  'south':   53.640474,
  'west':    11.577472
}, {'zipCode': '19417', 'north': 53.8525014, 'east': 11.810558, 'south': 53.758889, 'west': 11.52203}, {
  'zipCode': '20095',
  'north':   53.5569902,
  'east':    10.0089277,
  'south':   53.5457261,
  'west':    9.9911721
}, {'zipCode': '20097', 'north': 53.5546788, 'east': 10.0343564, 'south': 53.5394017, 'west': 10.0067916}, {
  'zipCode': '20099',
  'north':   53.5652488,
  'east':    10.0265115,
  'south':   53.5504521,
  'west':    9.9973681
}, {'zipCode': '20144', 'north': 53.5821629, 'east': 9.9859354, 'south': 53.5676491, 'west': 9.9631463}, {
  'zipCode': '20146',
  'north':   53.5746186,
  'east':    9.9898509,
  'south':   53.5611886,
  'west':    9.9700483
}, {'zipCode': '20148', 'north': 53.5738467, 'east': 10.0082327, 'south': 53.5611886, 'west': 9.9864461}, {
  'zipCode': '20149',
  'north':   53.5852536,
  'east':    10.0048458,
  'south':   53.5725815,
  'west':    9.9814267
}, {'zipCode': '20249', 'north': 53.5942383, 'east': 9.9974094, 'south': 53.5817969, 'west': 9.9780357}, {
  'zipCode': '20251',
  'north':   53.6013861,
  'east':    9.9922735,
  'south':   53.5785701,
  'west':    9.9682667
}, {'zipCode': '20253', 'north': 53.5862418, 'east': 9.976448, 'south': 53.5716494, 'west': 9.9574892}, {
  'zipCode': '20255',
  'north':   53.583107000000005,
  'east':    9.9634752,
  'south':   53.5741909,
  'west':    9.9409158
}, {'zipCode': '20257', 'north': 53.5818622, 'east': 9.9539466, 'south': 53.5683879, 'west': 9.9375341}, {
  'zipCode': '20259',
  'north':   53.5772224,
  'east':    9.9686662,
  'south':   53.565492,
  'west':    9.9484369
}, {'zipCode': '20354', 'north': 53.5668744, 'east': 10.0082327, 'south': 53.5494013, 'west': 9.9836657}, {
  'zipCode': '20355',
  'north':   53.5638046,
  'east':    9.9886904,
  'south':   53.5491051,
  'west':    9.9694941
}, {'zipCode': '20357', 'north': 53.5720545, 'east': 9.9769646, 'south': 53.5564142, 'west': 9.9584707}, {
  'zipCode': '20359',
  'north':   53.5593475,
  'east':    9.9751841,
  'south':   53.5431746,
  'west':    9.952688
}, {'zipCode': '20457', 'north': 53.5511273, 'east': 10.0247839, 'south': 53.5156988, 'west': 9.9298603}, {
  'zipCode': '20459',
  'north':   53.5520174,
  'east':    9.9898527,
  'south':   53.5405532,
  'west':    9.9683755
}, {'zipCode': '20535', 'north': 53.5661079, 'east': 10.067527, 'south': 53.5526464, 'west': 10.0239753}, {
  'zipCode': '20537',
  'north':   53.5561093,
  'east':    10.0707064,
  'south':   53.5377541,
  'west':    10.025261100000002
}, {'zipCode': '20539', 'north': 53.5460392, 'east': 10.0737667, 'south': 53.5002225, 'west': 9.9997099}, {
  'zipCode': '21029',
  'north':   53.499341,
  'east':    10.2662395,
  'south':   53.4655818,
  'west':    10.1981397
}, {'zipCode': '21031', 'north': 53.5229908, 'east': 10.2238906, 'south': 53.4894168, 'west': 10.156479500000001}, {
  'zipCode': '21033',
  'north':   53.5246161,
  'east':    10.2058231,
  'south':   53.4865347,
  'west':    10.1178512
}, {'zipCode': '21035', 'north': 53.5051121, 'east': 10.2066227, 'south': 53.47263000000001, 'west': 10.0952279}, {
  'zipCode': '21037',
  'north':   53.5066474,
  'east':    10.2508909,
  'south':   53.3951118,
  'west':    10.0510373
}, {'zipCode': '21039', 'north': 53.4964635, 'east': 10.358155, 'south': 53.410242200000006, 'west': 10.1835202}, {
  'zipCode': '21073',
  'north':   53.4703038,
  'east':    9.9916916,
  'south':   53.4460025,
  'west':    9.9540276
}, {'zipCode': '21075', 'north': 53.4718168, 'east': 9.9691016, 'south': 53.4465814, 'west': 9.9041173}, {
  'zipCode': '21077',
  'north':   53.4525721,
  'east':    9.9923433,
  'south':   53.41377370000001,
  'west':    9.9066994
}, {'zipCode': '21079', 'north': 53.5061566, 'east': 10.0516115, 'south': 53.4146139, 'west': 9.8803633}, {
  'zipCode': '21107',
  'north':   53.5263913,
  'east':    10.0123046,
  'south':   53.473265,
  'west':    9.9388148
}, {'zipCode': '21109', 'north': 53.5208976, 'east': 10.075876, 'south': 53.4542419, 'west': 9.9922128}, {
  'zipCode': '21129',
  'north':   53.5571351,
  'east':    9.9426168,
  'south':   53.49231850000001,
  'west':    9.7635785
}, {'zipCode': '21147', 'north': 53.4967073, 'east': 9.9171281, 'south': 53.4719194, 'west': 9.798592}, {
  'zipCode': '21149',
  'north':   53.4750752,
  'east':    9.9175382,
  'south':   53.4296167,
  'west':    9.7998057
}, {'zipCode': '21217', 'north': 53.4639066, 'east': 10.106797300000002, 'south': 53.3929131, 'west': 9.9787927}, {
  'zipCode': '21218',
  'north':   53.4294535,
  'east':    10.0238068,
  'south':   53.3535233,
  'west':    9.9214907
}, {'zipCode': '21220', 'north': 53.4120538, 'east': 10.1009699, 'south': 53.3167401, 'west': 9.9970748}, {
  'zipCode': '21224',
  'north':   53.45796310000001,
  'east':    9.9580681,
  'south':   53.3347794,
  'west':    9.8044452
}, {'zipCode': '21227', 'north': 53.3445881, 'east': 9.9970748, 'south': 53.3250093, 'west': 9.9527477}, {
  'zipCode': '21228',
  'north':   53.3588241,
  'east':    10.0149626,
  'south':   53.3353817,
  'west':    9.9475955
}, {'zipCode': '21244', 'north': 53.37933050000001, 'east': 9.930916, 'south': 53.2432085, 'west': 9.7715016}, {
  'zipCode': '21255',
  'north':   53.3454425,
  'east':    9.8514701,
  'south':   53.2161653,
  'west':    9.6029485
}, {'zipCode': '21256', 'north': 53.2815312, 'east': 9.8938442, 'south': 53.205496000000004, 'west': 9.7889806}, {
  'zipCode': '21258',
  'north':   53.3502576,
  'east':    9.693646,
  'south':   53.2564311,
  'west':    9.5823606
}, {'zipCode': '21259', 'north': 53.2642249, 'east': 9.8075837, 'south': 53.1867992, 'west': 9.6802726}, {
  'zipCode': '21261',
  'north':   53.2728971,
  'east':    9.8328465,
  'south':   53.1901722,
  'west':    9.7509188
}, {'zipCode': '21266', 'north': 53.34345, 'east': 9.9839737, 'south': 53.2689219, 'west': 9.875402900000001}, {
  'zipCode': '21271',
  'north':   53.3086272,
  'east':    10.0707596,
  'south':   53.2105753,
  'west':    9.892004
}, {'zipCode': '21272', 'north': 53.2280351, 'east': 10.1098059, 'south': 53.129121, 'west': 9.9861955}, {
  'zipCode': '21274',
  'north':   53.2459773,
  'east':    10.0129241,
  'south':   53.1734013,
  'west':    9.845617800000001
}, {'zipCode': '21279', 'north': 53.42228660000001, 'east': 9.8308255, 'south': 53.3212183, 'west': 9.668626}, {
  'zipCode': '21335',
  'north':   53.2544178,
  'east':    10.423009,
  'south':   53.1918393,
  'west':    10.3440352
}, {'zipCode': '21337', 'north': 53.2727805, 'east': 10.5143562, 'south': 53.2062564, 'west': 10.4085773}, {
  'zipCode': '21339',
  'north':   53.2866442,
  'east':    10.4287671,
  'south':   53.2241603,
  'west':    10.3308253
}, {'zipCode': '21354', 'north': 53.3705299, 'east': 10.8697204, 'south': 53.2180615, 'west': 10.613918400000001}, {
  'zipCode': '21357',
  'north':   53.3709017,
  'east':    10.4342585,
  'south':   53.2819156,
  'west':    10.3086648
}, {'zipCode': '21358', 'north': 53.3013387, 'east': 10.334319, 'south': 53.259773, 'west': 10.2654787}, {
  'zipCode': '21360',
  'north':   53.3065796,
  'east':    10.3656958,
  'south':   53.259795,
  'west':    10.3058751
}, {'zipCode': '21365', 'north': 53.3128229, 'east': 10.498202, 'south': 53.254023, 'west': 10.4231811}, {
  'zipCode': '21368',
  'north':   53.2541862,
  'east':    10.7943639,
  'south':   53.122313000000005,
  'west':    10.6276277
}, {'zipCode': '21369', 'north': 53.1984073, 'east': 10.9095822, 'south': 53.1412347, 'west': 10.7651094}, {
  'zipCode': '21371',
  'north':   53.2285503,
  'east':    10.8767835,
  'south':   53.1914905,
  'west':    10.7780602
}, {'zipCode': '21376', 'north': 53.2663065, 'east': 10.2363731, 'south': 53.1689208, 'west': 10.045004}, {
  'zipCode': '21379',
  'north':   53.3519209,
  'east':    10.6354157,
  'south':   53.2521906,
  'west':    10.4609498
}, {'zipCode': '21380', 'north': 53.385876, 'east': 10.5252572, 'south': 53.3473247, 'west': 10.4418422}, {
  'zipCode': '21382',
  'north':   53.364009,
  'east':    10.5084397,
  'south':   53.3083789,
  'west':    10.399569
}, {'zipCode': '21385', 'north': 53.1935665, 'east': 10.2913073, 'south': 53.0432556, 'west': 10.0371995}, {
  'zipCode': '21386',
  'north':   53.1766918,
  'east':    10.3466143,
  'south':   53.0888257,
  'west':    10.2702587
}, {'zipCode': '21388', 'north': 53.1893276, 'east': 10.1877214, 'south': 53.105709, 'west': 10.0752228}, {
  'zipCode': '21391',
  'north':   53.290093,
  'east':    10.3791274,
  'south':   53.23094570000001,
  'west':    10.2573752
}, {'zipCode': '21394', 'north': 53.2608081, 'east': 10.3572874, 'south': 53.1874143, 'west': 10.1952396}, {
  'zipCode': '21395',
  'north':   53.4115045,
  'east':    10.4713738,
  'south':   53.35513,
  'west':    10.3630417
}, {'zipCode': '21397', 'north': 53.24372530000001, 'east': 10.6132377, 'south': 53.1704449, 'west': 10.4954453}, {
  'zipCode': '21398',
  'north':   53.3075018,
  'east':    10.6853439,
  'south':   53.2405656,
  'west':    10.5906096
}, {'zipCode': '21400', 'north': 53.2722808, 'east': 10.6259681, 'south': 53.203732, 'west': 10.5064855}, {
  'zipCode': '21401',
  'north':   53.2513081,
  'east':    10.692525,
  'south':   53.1828388,
  'west':    10.6003768
}, {'zipCode': '21403', 'north': 53.2293232, 'east': 10.5090127, 'south': 53.1867108, 'west': 10.43653}, {
  'zipCode': '21406',
  'north':   53.1993362,
  'east':    10.4501583,
  'south':   53.1133272,
  'west':    10.3343631
}, {'zipCode': '21407', 'north': 53.2138048, 'east': 10.4714904, 'south': 53.1697006, 'west': 10.4130902}, {
  'zipCode': '21409',
  'north':   53.21151460000001,
  'east':    10.3731821,
  'south':   53.1434444,
  'west':    10.2760694
}, {'zipCode': '21423', 'north': 53.4335055, 'east': 10.356228, 'south': 53.2867397, 'west': 10.0911121}, {
  'zipCode': '21435',
  'north':   53.4266386,
  'east':    10.1735848,
  'south':   53.3411069,
  'west':    10.0562305
}, {'zipCode': '21436', 'north': 53.4262908, 'east': 10.3984301, 'south': 53.3577683, 'west': 10.315267}, {
  'zipCode': '21438',
  'north':   53.3268098,
  'east':    10.1025915,
  'south':   53.2761303,
  'west':    10.0133563
}, {'zipCode': '21439', 'north': 53.3359049, 'east': 10.0556727, 'south': 53.2802916, 'west': 9.9761165}, {
  'zipCode': '21441',
  'north':   53.30024280000001,
  'east':    10.2259918,
  'south':   53.265116,
  'west':    10.1200458
}, {'zipCode': '21442', 'north': 53.3108638, 'east': 10.1678235, 'south': 53.2443795, 'west': 10.0487622}, {
  'zipCode': '21444',
  'north':   53.3001452,
  'east':    10.269891900000001,
  'south':   53.246121,
  'west':    10.1886769
}, {'zipCode': '21445', 'north': 53.31358440000001, 'east': 10.1725249, 'south': 53.2832778, 'west': 10.0945966}, {
  'zipCode': '21447',
  'north':   53.3600632,
  'east':    10.3675447,
  'south':   53.3229701,
  'west':    10.3065495
}, {'zipCode': '21449', 'north': 53.3331875, 'east': 10.3325158, 'south': 53.284424, 'west': 10.2118297}, {
  'zipCode': '21465',
  'north':   53.5799592,
  'east':    10.3158619,
  'south':   53.4789784,
  'west':    10.2104116
}, {'zipCode': '21481', 'north': 53.4124123, 'east': 10.5920221, 'south': 53.35981060000001, 'west': 10.4295028}, {
  'zipCode': '21483',
  'north':   53.4946475,
  'east':    10.6294043,
  'south':   53.3611424,
  'west':    10.4479044
}, {'zipCode': '21493', 'north': 53.6057496, 'east': 10.5902389, 'south': 53.4832076, 'west': 10.4345581}, {
  'zipCode': '21502',
  'north':   53.4852292,
  'east':    10.4708439,
  'south':   53.3960199,
  'west':    10.3078304
}, {'zipCode': '21509', 'north': 53.5594059, 'east': 10.236916, 'south': 53.5199281, 'west': 10.180527}, {
  'zipCode': '21514',
  'north':   53.5670211,
  'east':    10.7845925,
  'south':   53.4441373,
  'west':    10.552593
}, {'zipCode': '21516', 'north': 53.5879542, 'east': 10.6784138, 'south': 53.4606911, 'west': 10.503836}, {
  'zipCode': '21521',
  'north':   53.5737814,
  'east':    10.4696948,
  'south':   53.4805232,
  'west':    10.2681145
}, {'zipCode': '21522', 'north': 53.3717125, 'east': 10.6487117, 'south': 53.3284284, 'west': 10.5032004}, {
  'zipCode': '21524',
  'north':   53.5227141,
  'east':    10.4573034,
  'south':   53.4633393,
  'west':    10.389846
}, {'zipCode': '21526', 'north': 53.4866847, 'east': 10.3954714, 'south': 53.4575648, 'west': 10.3517167}, {
  'zipCode': '21527',
  'north':   53.4862597,
  'east':    10.4867908,
  'south':   53.4467757,
  'west':    10.440135
}, {'zipCode': '21529', 'north': 53.4979697, 'east': 10.3557684, 'south': 53.4635371, 'west': 10.298513}, {
  'zipCode': '21614',
  'north':   53.509454,
  'east':    9.783512100000001,
  'south':   53.4115224,
  'west':    9.5971845
}, {'zipCode': '21629', 'north': 53.5053032, 'east': 9.8618269, 'south': 53.3644242, 'west': 9.7264034}, {
  'zipCode': '21635',
  'north':   53.5757496,
  'east':    9.780283600000002,
  'south':   53.4907863,
  'west':    9.6123129
}, {'zipCode': '21640', 'north': 53.5399469, 'east': 9.6529311, 'south': 53.4570965, 'west': 9.5092412}, {
  'zipCode': '21641',
  'north':   53.473305100000005,
  'east':    9.650112,
  'south':   53.4171612,
  'west':    9.5490695
}, {'zipCode': '21643', 'north': 53.4417973, 'east': 9.6835687, 'south': 53.38771500000001, 'west': 9.5628275}, {
  'zipCode': '21644',
  'north':   53.4288818,
  'east':    9.6236337,
  'south':   53.3369993,
  'west':    9.5096484
}, {'zipCode': '21646', 'north': 53.3763384, 'east': 9.6599818, 'south': 53.3157635, 'west': 9.5614579}, {
  'zipCode': '21647',
  'north':   53.4233333,
  'east':    9.7409554,
  'south':   53.3753497,
  'west':    9.6744259
}, {'zipCode': '21649', 'north': 53.4097265, 'east': 9.6799078, 'south': 53.3392852, 'west': 9.6166279}, {
  'zipCode': '21680',
  'north':   53.6157045,
  'east':    9.5107974,
  'south':   53.5701478,
  'west':    9.4402686
}, {'zipCode': '21682', 'north': 53.6195137, 'east': 9.4995112, 'south': 53.5901791, 'west': 9.417667}, {
  'zipCode': '21683',
  'north':   53.6810232,
  'east':    9.5388808,
  'south':   53.59380540000001,
  'west':    9.3858335
}, {'zipCode': '21684', 'north': 53.5952102, 'east': 9.5749265, 'south': 53.5386106, 'west': 9.373859100000002}, {
  'zipCode': '21698',
  'north':   53.5055005,
  'east':    9.59274,
  'south':   53.3950031,
  'west':    9.337464
}, {'zipCode': '21702', 'north': 53.4500071, 'east': 9.5178288, 'south': 53.3453269, 'west': 9.3570772}, {
  'zipCode': '21706',
  'north':   53.7882302,
  'east':    9.4880521,
  'south':   53.6492885,
  'west':    9.2260365
}, {'zipCode': '21709', 'north': 53.6613557, 'east': 9.3869953, 'south': 53.56662250000001, 'west': 9.2146134}, {
  'zipCode': '21710',
  'north':   53.6845227,
  'east':    9.3594604,
  'south':   53.6320805,
  'west':    9.2615282
}, {'zipCode': '21712', 'north': 53.6960001, 'east': 9.3577891, 'south': 53.661546900000005, 'west': 9.2075036}, {
  'zipCode': '21714',
  'north':   53.6655503,
  'east':    9.4313208,
  'south':   53.5912477,
  'west':    9.328888600000003
}, {'zipCode': '21717', 'north': 53.5720511, 'east': 9.5252657, 'south': 53.4805346, 'west': 9.3291459}, {
  'zipCode': '21720',
  'north':   53.5935226,
  'east':    9.6578619,
  'south':   53.5161381,
  'west':    9.5689385
}, {'zipCode': '21723', 'north': 53.6166272, 'east': 9.6109667, 'south': 53.5655095, 'west': 9.4956018}, {
  'zipCode': '21726',
  'north':   53.6235597,
  'east':    9.3639846,
  'south':   53.5232174,
  'west':    9.1480798
}, {'zipCode': '21727', 'north': 53.5940413, 'east': 9.2596184, 'south': 53.5315097, 'west': 9.1537777}, {
  'zipCode': '21729',
  'north':   53.8605938,
  'east':    9.3318734,
  'south':   53.7994922,
  'west':    9.2361827
}, {'zipCode': '21730', 'north': 53.867231000000004, 'east': 9.1917846, 'south': 53.7905977, 'west': 9.0138938}, {
  'zipCode': '21732',
  'north':   53.867231000000004,
  'east':    9.2536536,
  'south':   53.8025756,
  'west':    9.1660886
}, {'zipCode': '21734', 'north': 53.81185980000001, 'east': 9.3100687, 'south': 53.7557298, 'west': 9.1435665}, {
  'zipCode': '21737',
  'north':   53.815874,
  'east':    9.3543877,
  'south':   53.7297221,
  'west':    9.2665075
}, {'zipCode': '21739', 'north': 53.5533509, 'east': 9.5796202, 'south': 53.5218955, 'west': 9.4957698}, {
  'zipCode': '21745',
  'north':   53.7227116,
  'east':    9.2239801,
  'south':   53.6435293,
  'west':    9.0573593
}, {'zipCode': '21755', 'north': 53.6768619, 'east': 9.280105600000002, 'south': 53.61181, 'west': 9.1585767}, {
  'zipCode': '21756',
  'north':   53.7683846,
  'east':    9.2983008,
  'south':   53.691149,
  'west':    9.1522699
}, {'zipCode': '21762', 'north': 53.8372276, 'east': 8.9616973, 'south': 53.7473051, 'west': 8.8309174}, {
  'zipCode': '21763',
  'north':   53.7925671,
  'east':    8.9352018,
  'south':   53.7445388,
  'west':    8.8390735
}, {'zipCode': '21765', 'north': 53.788623, 'east': 8.8684021, 'south': 53.7463449, 'west': 8.758081}, {
  'zipCode': '21769',
  'north':   53.6773578,
  'east':    9.1754753,
  'south':   53.5529937,
  'west':    8.9676378
}, {'zipCode': '21770', 'north': 53.7036455, 'east': 9.0870871, 'south': 53.6234812, 'west': 8.9698912}, {
  'zipCode': '21772',
  'north':   53.6906714,
  'east':    9.020108,
  'south':   53.5943881,
  'west':    8.9263405
}, {'zipCode': '21775', 'north': 53.7574351, 'east': 8.969815, 'south': 53.6338486, 'west': 8.8267388}, {
  'zipCode': '21776',
  'north':   53.766345,
  'east':    8.8358263,
  'south':   53.6800512,
  'west':    8.7103553
}, {'zipCode': '21781', 'north': 53.8155535, 'east': 9.1485387, 'south': 53.7544782, 'west': 9.0044308}, {
  'zipCode': '21782',
  'north':   53.7800777,
  'east':    9.0229573,
  'south':   53.6872959,
  'west':    8.9570941
}, {'zipCode': '21785', 'north': 53.8405041, 'east': 9.0479866, 'south': 53.7711156, 'west': 8.9556714}, {
  'zipCode': '21787',
  'north':   53.7879236,
  'east':    9.2234352,
  'south':   53.7096722,
  'west':    9.0960766
}, {'zipCode': '21789', 'north': 53.7809971, 'east': 9.1242458, 'south': 53.6889285, 'west': 8.9725377}, {
  'zipCode': '22041',
  'north':   53.5860125,
  'east':    10.1090868,
  'south':   53.5662504,
  'west':    10.0578702
}, {'zipCode': '22043', 'north': 53.5820949, 'east': 10.1506692, 'south': 53.5595423, 'west': 10.0622369}, {
  'zipCode': '22045',
  'north':   53.5979981,
  'east':    10.1520039,
  'south':   53.5697309,
  'west':    10.1022568
}, {'zipCode': '22047', 'north': 53.5990533, 'east': 10.112228, 'south': 53.5791107, 'west': 10.0782192}, {
  'zipCode': '22049',
  'north':   53.5984453,
  'east':    10.0829155,
  'south':   53.574744800000005,
  'west':    10.0532723
}, {'zipCode': '22081', 'north': 53.5834017, 'east': 10.057332, 'south': 53.5664893, 'west': 10.027049}, {
  'zipCode': '22083',
  'north':   53.5853664,
  'east':    10.0466014,
  'south':   53.57028040000001,
  'west':    10.0189817
}, {'zipCode': '22085', 'north': 53.582101, 'east': 10.0271743, 'south': 53.5652488, 'west': 10.0038075}, {
  'zipCode': '22087',
  'north':   53.5705332,
  'east':    10.0390085,
  'south':   53.5563894,
  'west':    10.0082327
}, {'zipCode': '22089', 'north': 53.5760196, 'east': 10.0627028, 'south': 53.5610142, 'west': 10.0314263}, {
  'zipCode': '22111',
  'north':   53.562581,
  'east':    10.1130618,
  'south':   53.5366519,
  'west':    10.0661032
}, {'zipCode': '22113', 'north': 53.5609663, 'east': 10.2104116, 'south': 53.4894554, 'west': 10.0586566}, {
  'zipCode': '22115',
  'north':   53.5382963,
  'east':    10.168776,
  'south':   53.5178117,
  'west':    10.1248946
}, {'zipCode': '22117', 'north': 53.5672305, 'east': 10.1604869, 'south': 53.5290821, 'west': 10.1035938}, {
  'zipCode': '22119',
  'north':   53.56735770000001,
  'east':    10.1333847,
  'south':   53.5415396,
  'west':    10.0807951
}, {'zipCode': '22143', 'north': 53.6172708, 'east': 10.1970347, 'south': 53.5947431, 'west': 10.1382259}, {
  'zipCode': '22145',
  'north':   53.6383737,
  'east':    10.2865945,
  'south':   53.5840086,
  'west':    10.1415679
}, {'zipCode': '22147', 'north': 53.628403500000005, 'east': 10.1539592, 'south': 53.5928071, 'west': 10.1233839}, {
  'zipCode': '22149',
  'north':   53.60116,
  'east':    10.201286,
  'south':   53.5761904,
  'west':    10.1360053
}, {'zipCode': '22159', 'north': 53.64018370000001, 'east': 10.1475824, 'south': 53.5906617, 'west': 10.0947053}, {
  'zipCode': '22175',
  'north':   53.6359402,
  'east':    10.1231403,
  'south':   53.612592,
  'west':    10.0742776
}, {'zipCode': '22177', 'north': 53.6209512, 'east': 10.081041500000001, 'south': 53.5933792, 'west': 10.0547826}, {
  'zipCode': '22179',
  'north':   53.6201555,
  'east':    10.0989795,
  'south':   53.59196270000001,
  'west':    10.0603629
}, {'zipCode': '22297', 'north': 53.6192228, 'east': 10.0386169, 'south': 53.595828, 'west': 9.9847402}, {
  'zipCode': '22299',
  'north':   53.6019237,
  'east':    10.0092488,
  'south':   53.5838346,
  'west':    9.9912541
}, {'zipCode': '22301', 'north': 53.5931834, 'east': 10.0164228, 'south': 53.575329, 'west': 9.9954743}, {
  'zipCode': '22303',
  'north':   53.6017104,
  'east':    10.037075,
  'south':   53.5787654,
  'west':    10.0046995
}, {'zipCode': '22305', 'north': 53.5935714, 'east': 10.0700204, 'south': 53.5818539, 'west': 10.0340059}, {
  'zipCode': '22307',
  'north':   53.6078657,
  'east':    10.0589947,
  'south':   53.59038180000001,
  'west':    10.0321363
}, {'zipCode': '22309', 'north': 53.6205409, 'east': 10.0742537, 'south': 53.6006729, 'west': 10.0378144}, {
  'zipCode': '22335',
  'north':   53.6497214,
  'east':    10.0344021,
  'south':   53.612743800000004,
  'west':    9.9563289
}, {'zipCode': '22337', 'north': 53.6382, 'east': 10.0852059, 'south': 53.6075791, 'west': 10.0180781}, {
  'zipCode': '22339',
  'north':   53.6552026,
  'east':    10.0647135,
  'south':   53.6297229,
  'west':    10.016427
}, {'zipCode': '22359', 'north': 53.6761443, 'east': 10.1988518, 'south': 53.6342243, 'west': 10.136810100000002}, {
  'zipCode': '22391',
  'north':   53.6562067,
  'east':    10.1118998,
  'south':   53.6283935,
  'west':    10.0564914
}, {'zipCode': '22393', 'north': 53.6607369, 'east': 10.1426131, 'south': 53.6302042, 'west': 10.0912856}, {
  'zipCode': '22395',
  'north':   53.6916966,
  'east':    10.144760400000001,
  'south':   53.6524186,
  'west':    10.0839087
}, {'zipCode': '22397', 'north': 53.7394378, 'east': 10.1936896, 'south': 53.6687262, 'west': 10.0604009}, {
  'zipCode': '22399',
  'north':   53.6797629,
  'east':    10.0975383,
  'south':   53.6483648,
  'west':    10.0517108
}, {'zipCode': '22415', 'north': 53.6610192, 'east': 10.0330236, 'south': 53.63695800000001, 'west': 9.9852303}, {
  'zipCode': '22417',
  'north':   53.6819818,
  'east':    10.0595151,
  'south':   53.651332,
  'west':    10.000902400000001
}, {'zipCode': '22419', 'north': 53.6815178, 'east': 10.0202194, 'south': 53.650716100000004, 'west': 9.9869996}, {
  'zipCode': '22453',
  'north':   53.6466063,
  'east':    10.0008026,
  'south':   53.6013861,
  'west':    9.9263361
}, {'zipCode': '22455', 'north': 53.6515719, 'east': 9.979157, 'south': 53.6291089, 'west': 9.9250704}, {
  'zipCode': '22457',
  'north':   53.6555215,
  'east':    9.9516763,
  'south':   53.6220638,
  'west':    9.8861829
}, {'zipCode': '22459', 'north': 53.635107, 'east': 9.9637255, 'south': 53.6167166, 'west': 9.9086849}, {
  'zipCode': '22523',
  'north':   53.6252091,
  'east':    9.9290791,
  'south':   53.5976315,
  'west':    9.8691962
}, {'zipCode': '22525', 'north': 53.6033833, 'east': 9.9406332, 'south': 53.5699936, 'west': 9.8801879}, {
  'zipCode': '22527',
  'north':   53.6145437,
  'east':    9.9578708,
  'south':   53.578389,
  'west':    9.9050064
}, {'zipCode': '22529', 'north': 53.608135, 'east': 9.9847402, 'south': 53.5810005, 'west': 9.935374200000002}, {
  'zipCode': '22547',
  'north':   53.6132514,
  'east':    9.9047317,
  'south':   53.580345,
  'west':    9.854043000000003
}, {'zipCode': '22549', 'north': 53.5981269, 'east': 9.8814943, 'south': 53.5691705, 'west': 9.8346227}, {
  'zipCode': '22559',
  'north':   53.6314046,
  'east':    9.7990198,
  'south':   53.5551486,
  'west':    9.7301155
}, {'zipCode': '22587', 'north': 53.5792523, 'east': 9.8403647, 'south': 53.5506905, 'west': 9.7618484}, {
  'zipCode': '22589',
  'north':   53.6066526,
  'east':    9.843764500000002,
  'south':   53.5653266,
  'west':    9.7788804
}, {'zipCode': '22605', 'north': 53.56222120000001, 'east': 9.9147003, 'south': 53.54155560000001, 'west': 9.8638992}, {
  'zipCode': '22607',
  'north':   53.5824055,
  'east':    9.9004914,
  'south':   53.5583214,
  'west':    9.8637341
}, {'zipCode': '22609', 'north': 53.571985, 'east': 9.8734204, 'south': 53.5442254, 'west': 9.8210982}, {
  'zipCode': '22761',
  'north':   53.5805858,
  'east':    9.9351745,
  'south':   53.5584204,
  'west':    9.883341800000002
}, {'zipCode': '22763', 'north': 53.5600679, 'east': 9.9277069, 'south': 53.5415333, 'west': 9.8979657}, {
  'zipCode': '22765',
  'north':   53.5632796,
  'east':    9.9549436,
  'south':   53.54491530000001,
  'west':    9.921380400000002
}, {'zipCode': '22767', 'north': 53.5610763, 'east': 9.9635987, 'south': 53.5417787, 'west': 9.925020100000001}, {
  'zipCode': '22769',
  'north':   53.5748008,
  'east':    9.9634647,
  'south':   53.5584586,
  'west':    9.9320209
}, {'zipCode': '22844', 'north': 53.759467, 'east': 10.0300872, 'south': 53.6934808, 'west': 9.9527386}, {
  'zipCode': '22846',
  'north':   53.7245972,
  'east':    10.0012878,
  'south':   53.6852146,
  'west':    9.9426521
}, {'zipCode': '22848', 'north': 53.6922295, 'east': 9.999697, 'south': 53.6481241, 'west': 9.9347555}, {
  'zipCode': '22850',
  'north':   53.706213700000006,
  'east':    10.0246033,
  'south':   53.680933,
  'west':    9.9744598
}, {'zipCode': '22851', 'north': 53.7266285, 'east': 10.0729426, 'south': 53.6776981, 'west': 10.0086166}, {
  'zipCode': '22869',
  'north':   53.6156231,
  'east':    9.8681548,
  'south':   53.5841903,
  'west':    9.7897157
}, {'zipCode': '22880', 'north': 53.6187534, 'east': 9.7592222, 'south': 53.557588200000005, 'west': 9.6455488}, {
  'zipCode': '22885',
  'north':   53.5988388,
  'east':    10.278566400000003,
  'south':   53.5540497,
  'west':    10.14752
}, {'zipCode': '22889', 'north': 53.7700249, 'east': 10.17059, 'south': 53.6969573, 'west': 10.0226488}, {
  'zipCode': '22926',
  'north':   53.7051402,
  'east':    10.3156737,
  'south':   53.6333794,
  'west':    10.1699771
}, {'zipCode': '22927', 'north': 53.6896932, 'east': 10.3152039, 'south': 53.6426112, 'west': 10.2520067}, {
  'zipCode': '22929',
  'north':   53.7028441,
  'east':    10.5305044,
  'south':   53.5454325,
  'west':    10.298554
}, {'zipCode': '22941', 'north': 53.768471100000006, 'east': 10.3475839, 'south': 53.6888327, 'west': 10.1693852}, {
  'zipCode': '22946',
  'north':   53.6399554,
  'east':    10.506076,
  'south':   53.5688173,
  'west':    10.241629
}, {'zipCode': '22949', 'north': 53.7142278, 'east': 10.2456656, 'south': 53.6676619, 'west': 10.139962}, {
  'zipCode': '22952',
  'north':   53.6782573,
  'east':    10.4095891,
  'south':   53.6288537,
  'west':    10.3358827
}, {'zipCode': '22955', 'north': 53.6767199, 'east': 10.3505888, 'south': 53.6316287, 'west': 10.2942944}, {
  'zipCode': '22956',
  'north':   53.6671938,
  'east':    10.445636,
  'south':   53.630136300000004,
  'west':    10.38541
}, {'zipCode': '22958', 'north': 53.59725170000001, 'east': 10.4400221, 'south': 53.5593856, 'west': 10.3694789}, {
  'zipCode': '22959',
  'north':   53.6614377,
  'east':    10.5049851,
  'south':   53.6308146,
  'west':    10.4355865
}, {'zipCode': '22961', 'north': 53.6831447, 'east': 10.3721736, 'south': 53.6611952, 'west': 10.3111183}, {
  'zipCode': '22962',
  'north':   53.653676,
  'east':    10.318921,
  'south':   53.613975700000005,
  'west':    10.2410612
}, {'zipCode': '22964', 'north': 53.7385631, 'east': 10.4465228, 'south': 53.6721245, 'west': 10.3514719}, {
  'zipCode': '22965',
  'north':   53.7220651,
  'east':    10.3761566,
  'south':   53.6771711,
  'west':    10.3122778
}, {'zipCode': '22967', 'north': 53.765935400000004, 'east': 10.3469184, 'south': 53.7208438, 'west': 10.2823341}, {
  'zipCode': '22969',
  'north':   53.5912355,
  'east':    10.3632769,
  'south':   53.5540896,
  'west':    10.3064793
}, {'zipCode': '23552', 'north': 53.87647630000001, 'east': 10.6979859, 'south': 53.8579609, 'west': 10.6777956}, {
  'zipCode': '23554',
  'north':   53.9130467,
  'east':    10.7194,
  'south':   53.866208,
  'west':    10.6523607
}, {'zipCode': '23556', 'north': 53.8984499, 'east': 10.6697262, 'south': 53.8490248, 'west': 10.5626369}, {
  'zipCode': '23558',
  'north':   53.8698746,
  'east':    10.6788151,
  'south':   53.8456191,
  'west':    10.6190819
}, {'zipCode': '23560', 'north': 53.8662757, 'east': 10.7299849, 'south': 53.774701, 'west': 10.5540402}, {
  'zipCode': '23562',
  'north':   53.8564898,
  'east':    10.7640544,
  'south':   53.7994433,
  'west':    10.6844538
}, {'zipCode': '23564', 'north': 53.8752152, 'east': 10.7540104, 'south': 53.8312178, 'west': 10.6879936}, {
  'zipCode': '23566',
  'north':   53.8960465,
  'east':    10.7711479,
  'south':   53.8481607,
  'west':    10.7011442
}, {'zipCode': '23568', 'north': 53.9101668, 'east': 10.8087511, 'south': 53.8721017, 'west': 10.690168}, {
  'zipCode': '23569',
  'north':   53.9392721,
  'east':    10.8853085,
  'south':   53.8851859,
  'west':    10.7047647
}, {'zipCode': '23570', 'north': 53.9938868, 'east': 10.9687156, 'south': 53.897111, 'west': 10.816596}, {
  'zipCode': '23611',
  'north':   53.9530912,
  'east':    10.7729511,
  'south':   53.9015471,
  'west':    10.6430859
}, {'zipCode': '23617', 'north': 53.9820909, 'east': 10.6882568, 'south': 53.8794578, 'west': 10.5340159}, {
  'zipCode': '23619',
  'north':   53.9204452,
  'east':    10.6217012,
  'south':   53.834171000000005,
  'west':    10.4244365
}, {'zipCode': '23623', 'north': 54.0751984, 'east': 10.6533159, 'south': 53.9384623, 'west': 10.4480803}, {
  'zipCode': '23626',
  'north':   53.9859493,
  'east':    10.850605,
  'south':   53.9209985,
  'west':    10.704318
}, {'zipCode': '23627', 'north': 53.8277677, 'east': 10.7735662, 'south': 53.7445171, 'west': 10.6807325}, {
  'zipCode': '23628',
  'north':   53.8002416,
  'east':    10.7030364,
  'south':   53.7454852,
  'west':    10.6128918
}, {'zipCode': '23629', 'north': 54.0033158, 'east': 10.6895592, 'south': 53.9764121, 'west': 10.6444151}, {
  'zipCode': '23669',
  'north':   54.00978610000001,
  'east':    10.850605,
  'south':   53.9390287,
  'west':    10.732919
}, {'zipCode': '23683', 'north': 54.0598367, 'east': 10.773091500000001, 'south': 54.00620940000001, 'west': 10.7151539}, {
  'zipCode': '23684',
  'north':   54.0611621,
  'east':    10.7388907,
  'south':   53.9968565,
  'west':    10.6111174
}, {'zipCode': '23689', 'north': 54.0181174, 'east': 10.7546735, 'south': 53.9452584, 'west': 10.6606128}, {
  'zipCode': '23701',
  'north':   54.1932158,
  'east':    10.7608481,
  'south':   54.0458568,
  'west':    10.5435324
}, {'zipCode': '23714', 'north': 54.2360521, 'east': 10.7159655, 'south': 54.132664, 'west': 10.4829391}, {
  'zipCode': '23715',
  'north':   54.1326683,
  'east':    10.6000818,
  'south':   54.0583779,
  'west':    10.4167307
}, {'zipCode': '23717', 'north': 54.2018374, 'east': 10.7768011, 'south': 54.1165246, 'west': 10.6503749}, {
  'zipCode': '23719',
  'north':   54.07273430000001,
  'east':    10.5641779,
  'south':   54.0175103,
  'west':    10.4846853
}, {'zipCode': '23730', 'north': 54.1875633, 'east': 10.9458508, 'south': 54.054433800000005, 'west': 10.7110529}, {
  'zipCode': '23738',
  'north':   54.2705404,
  'east':    11.030761500000002,
  'south':   54.1719212,
  'west':    10.7801928
}, {'zipCode': '23743', 'north': 54.222703800000005, 'east': 11.0484038, 'south': 54.1328569, 'west': 10.8872967}, {
  'zipCode': '23744',
  'north':   54.23480930000001,
  'east':    10.8312849,
  'south':   54.155175,
  'west':    10.6989024
}, {'zipCode': '23746', 'north': 54.215059, 'east': 11.082588500000002, 'south': 54.1762814, 'west': 11.0194466}, {
  'zipCode': '23747',
  'north':   54.2461991,
  'east':    11.0948736,
  'south':   54.1946781,
  'west':    11.0498063
}, {'zipCode': '23749', 'north': 54.27015990000001, 'east': 11.0851677, 'south': 54.2068045, 'west': 10.9926528}, {
  'zipCode': '23758',
  'north':   54.377804600000005,
  'east':    11.0166293,
  'south':   54.217938600000004,
  'west':    10.6989039
}, {'zipCode': '23769', 'north': 54.536218100000006, 'east': 11.3128564, 'south': 54.4013727, 'west': 11.0045365}, {
  'zipCode': '23774',
  'north':   54.3847846,
  'east':    11.0233832,
  'south':   54.348988,
  'west':    10.922646000000002
}, {'zipCode': '23775', 'north': 54.3968426, 'east': 11.1307098, 'south': 54.341975600000005, 'west': 10.9979676}, {
  'zipCode': '23777',
  'north':   54.3141553,
  'east':    11.0851677,
  'south':   54.246662,
  'west':    10.957781600000002
}, {'zipCode': '23779', 'north': 54.3554792, 'east': 11.0770011, 'south': 54.2968794, 'west': 10.970276}, {
  'zipCode': '23795',
  'north':   54.007611,
  'east':    10.4643945,
  'south':   53.8830552,
  'west':    10.203222
}, {'zipCode': '23812', 'north': 53.9739385, 'east': 10.2383074, 'south': 53.9057926, 'west': 10.0374654}, {
  'zipCode': '23813',
  'north':   54.0465334,
  'east':    10.3938195,
  'south':   53.9856146,
  'west':    10.2817977
}, {'zipCode': '23815', 'north': 53.9500687, 'east': 10.5113426, 'south': 53.8992013, 'west': 10.4192077}, {
  'zipCode': '23816',
  'north':   53.8914953,
  'east':    10.3328593,
  'south':   53.8246221,
  'west':    10.1897686
}, {'zipCode': '23818', 'north': 53.9163897, 'east': 10.444472, 'south': 53.8796914, 'west': 10.3356397}, {
  'zipCode': '23820',
  'north':   53.989587,
  'east':    10.5538063,
  'south':   53.9121451,
  'west':    10.4456967
}, {'zipCode': '23821', 'north': 53.9848855, 'east': 10.4552722, 'south': 53.943535, 'west': 10.3472205}, {
  'zipCode': '23823',
  'north':   54.08248050000001,
  'east':    10.5119558,
  'south':   54.0173697,
  'west':    10.3277432
}, {'zipCode': '23824', 'north': 54.08003440000001, 'east': 10.3566856, 'south': 54.0247689, 'west': 10.2817961}, {
  'zipCode': '23826',
  'north':   53.9384603,
  'east':    10.2254563,
  'south':   53.85983670000001,
  'west':    10.0856919
}, {'zipCode': '23827', 'north': 54.0340795, 'east': 10.489339100000002, 'south': 53.9644831, 'west': 10.3204322}, {
  'zipCode': '23829',
  'north':   53.94397430000001,
  'east':    10.2535374,
  'south':   53.8823748,
  'west':    10.2044217
}, {'zipCode': '23843', 'north': 53.8721516, 'east': 10.4365432, 'south': 53.7465126, 'west': 10.2388228}, {
  'zipCode': '23845',
  'north':   53.89481,
  'east':    10.4349081,
  'south':   53.78729380000001,
  'west':    10.1083308
}, {'zipCode': '23847', 'north': 53.82034840000001, 'east': 10.6335879, 'south': 53.6846887, 'west': 10.3249534}, {
  'zipCode': '23858',
  'north':   53.8780794,
  'east':    10.5644345,
  'south':   53.7889296,
  'west':    10.3918113
}, {'zipCode': '23860', 'north': 53.8220604, 'east': 10.6064343, 'south': 53.7793727, 'west': 10.511824000000002}, {
  'zipCode': '23863',
  'north':   53.8026557,
  'east':    10.2391645,
  'south':   53.7399271,
  'west':    10.1060116
}, {'zipCode': '23866', 'north': 53.8243529, 'east': 10.1605584, 'south': 53.7713985, 'west': 10.111924}, {
  'zipCode': '23867',
  'north':   53.8271073,
  'east':    10.2699474,
  'south':   53.7818955,
  'west':    10.1920669
}, {'zipCode': '23869', 'north': 53.7835632, 'east': 10.315997, 'south': 53.7456415, 'west': 10.2296139}, {
  'zipCode': '23879',
  'north':   53.6543149,
  'east':    10.7548391,
  'south':   53.5754626,
  'west':    10.6475836
}, {'zipCode': '23881', 'north': 53.6956242, 'east': 10.7117373, 'south': 53.5697333, 'west': 10.4973859}, {
  'zipCode': '23883',
  'north':   53.6614557,
  'east':    10.9349371,
  'south':   53.5421054,
  'west':    10.6662764
}, {'zipCode': '23896', 'north': 53.6869304, 'east': 10.6555034, 'south': 53.617782, 'west': 10.5206491}, {
  'zipCode': '23898',
  'north':   53.73480490000001,
  'east':    10.6373569,
  'south':   53.6423384,
  'west':    10.4483231
}, {'zipCode': '23899', 'north': 53.604788, 'east': 10.8322101, 'south': 53.507809400000006, 'west': 10.68876}, {
  'zipCode': '23909',
  'north':   53.7814364,
  'east':    10.8318394,
  'south':   53.6317587,
  'west':    10.6794185
}, {'zipCode': '23911', 'north': 53.7593792, 'east': 10.954890000000002, 'south': 53.6271436, 'west': 10.6659275}, {
  'zipCode': '23919',
  'north':   53.7697334,
  'east':    10.6914939,
  'south':   53.6776565,
  'west':    10.5854558
}, {'zipCode': '23923', 'north': 53.9244777, 'east': 11.0429459, 'south': 53.7794491, 'west': 10.7477806}, {
  'zipCode': '23936',
  'north':   53.9610814,
  'east':    11.3215152,
  'south':   53.75044570000001,
  'west':    10.97954
}, {'zipCode': '23942', 'north': 54.0002226, 'east': 11.0867351, 'south': 53.8757104, 'west': 10.8948189}, {
  'zipCode': '23946',
  'north':   54.0149448,
  'east':    11.2602009,
  'south':   53.9361877,
  'west':    11.1435269
}, {'zipCode': '23948', 'north': 54.0142756, 'east': 11.2672616, 'south': 53.8888306, 'west': 11.0178717}, {
  'zipCode': '23966',
  'north':   53.9069504,
  'east':    11.5131635,
  'south':   53.8010949,
  'west':    11.2869711
}, {'zipCode': '23968', 'north': 53.9595662, 'east': 11.4371946, 'south': 53.8406105, 'west': 11.2294675}, {
  'zipCode': '23970',
  'north':   53.93834050000001,
  'east':    11.6515018,
  'south':   53.8651019,
  'west':    11.4425234
}, {'zipCode': '23972', 'north': 53.8843471, 'east': 11.6034291, 'south': 53.7902072, 'west': 11.3875388}, {
  'zipCode': '23974',
  'north':   54.0340458,
  'east':    11.677716,
  'south':   53.8985344,
  'west':    11.4776764
}, {'zipCode': '23992', 'north': 53.977318, 'east': 11.805131, 'south': 53.8119567, 'west': 11.5512368}, {
  'zipCode': '23996',
  'north':   53.8474747,
  'east':    11.547594,
  'south':   53.7435017,
  'west':    11.3060905
}, {'zipCode': '23999', 'north': 54.024429500000004, 'east': 11.4963872, 'south': 53.9573735, 'west': 11.3743154}, {
  'zipCode': '24103',
  'north':   54.3310814,
  'east':    10.1491514,
  'south':   54.3148469,
  'west':    10.1218656
}, {'zipCode': '24105', 'north': 54.3503549, 'east': 10.1574063, 'south': 54.3252563, 'west': 10.1278162}, {
  'zipCode': '24106',
  'north':   54.3725011,
  'east':    10.1530143,
  'south':   54.34536,
  'west':    10.0818633
}, {'zipCode': '24107', 'north': 54.3717308, 'east': 10.1009246, 'south': 54.3155153, 'west': 9.9498839}, {
  'zipCode': '24109',
  'north':   54.3343565,
  'east':    10.1007437,
  'south':   54.295230600000004,
  'west':    10.0002291
}, {'zipCode': '24111', 'north': 54.3111126, 'east': 10.0895468, 'south': 54.2972584, 'west': 10.045139}, {
  'zipCode': '24113',
  'north':   54.31535010000001,
  'east':    10.1359404,
  'south':   54.2551242,
  'west':    10.041139
}, {'zipCode': '24114', 'north': 54.3225165, 'east': 10.1358574, 'south': 54.304423, 'west': 10.0918422}, {
  'zipCode': '24116',
  'north':   54.3369071,
  'east':    10.1287488,
  'south':   54.3192724,
  'west':    10.0950867
}, {'zipCode': '24118', 'north': 54.3515155, 'east': 10.1338003, 'south': 54.3310814, 'west': 10.1016743}, {
  'zipCode': '24119',
  'north':   54.3505575,
  'east':    10.1073769,
  'south':   54.3251904,
  'west':    10.0544792
}, {'zipCode': '24143', 'north': 54.3276276, 'east': 10.1662058, 'south': 54.2957398, 'west': 10.1313954}, {
  'zipCode': '24145',
  'north':   54.2994461,
  'east':    10.2024669,
  'south':   54.2507055,
  'west':    10.0835383
}, {'zipCode': '24146', 'north': 54.2968386, 'east': 10.2096859, 'south': 54.2693139, 'west': 10.162613}, {
  'zipCode': '24147',
  'north':   54.3096066,
  'east':    10.1922433,
  'south':   54.2958497,
  'west':    10.160257
}, {'zipCode': '24148', 'north': 54.3332081, 'east': 10.2097806, 'south': 54.3049335, 'west': 10.1563901}, {
  'zipCode': '24149',
  'north':   54.3474323,
  'east':    10.218617700000001,
  'south':   54.3169109,
  'west':    10.1671947
}, {'zipCode': '24159', 'north': 54.4329395, 'east': 10.1925178, 'south': 54.3632936, 'west': 10.1027922}, {
  'zipCode': '24161',
  'north':   54.4245548,
  'east':    10.1425261,
  'south':   54.3609217,
  'west':    10.0431194
}, {'zipCode': '24211', 'north': 54.3034616, 'east': 10.4086987, 'south': 54.1582514, 'west': 10.1456129}, {
  'zipCode': '24214',
  'north':   54.4756376,
  'east':    10.0643577,
  'south':   54.3407167,
  'west':    9.8700424
}, {'zipCode': '24217', 'north': 54.4374772, 'east': 10.4497714, 'south': 54.34645810000001, 'west': 10.2913677}, {
  'zipCode': '24220',
  'north':   54.26830050000001,
  'east':    10.1502466,
  'south':   54.1976407,
  'west':    10.0302007
}, {'zipCode': '24222', 'north': 54.3192876, 'east': 10.2359328, 'south': 54.287546, 'west': 10.1889488}, {
  'zipCode': '24223',
  'north':   54.2990452,
  'east':    10.2929667,
  'south':   54.2498676,
  'west':    10.2028837
}, {'zipCode': '24226', 'north': 54.3931674, 'east': 10.2539442, 'south': 54.34671, 'west': 10.1777738}, {
  'zipCode': '24229',
  'north':   54.4854477,
  'east':    10.2047133,
  'south':   54.4107419,
  'west':    10.0245661
}, {'zipCode': '24232', 'north': 54.3536132, 'east': 10.3293778, 'south': 54.281409000000004, 'west': 10.198842}, {
  'zipCode': '24235',
  'north':   54.4334164,
  'east':    10.3070247,
  'south':   54.3795178,
  'west':    10.209264
}, {'zipCode': '24238', 'north': 54.3187372, 'east': 10.5109235, 'south': 54.2165882, 'west': 10.3492124}, {
  'zipCode': '24239',
  'north':   54.321634100000004,
  'east':    10.0483515,
  'south':   54.2845275,
  'west':    9.9514553
}, {'zipCode': '24241', 'north': 54.2803706, 'east': 10.0786293, 'south': 54.179945000000004, 'west': 9.9474991}, {
  'zipCode': '24242',
  'north':   54.3265341,
  'east':    9.9625475,
  'south':   54.2796013,
  'west':    9.8847372
}, {'zipCode': '24244', 'north': 54.4282193, 'east': 10.0869193, 'south': 54.3819494, 'west': 10.0128015}, {
  'zipCode': '24245',
  'north':   54.241829,
  'east':    10.1876123,
  'south':   54.184727300000006,
  'west':    10.1224754
}, {'zipCode': '24247', 'north': 54.300254, 'east': 10.0694691, 'south': 54.2656611, 'west': 9.9741019}, {
  'zipCode': '24248',
  'north':   54.3550418,
  'east':    10.2164562,
  'south':   54.3408832,
  'west':    10.1737344
}, {'zipCode': '24250', 'north': 54.2146087, 'east': 10.2479352, 'south': 54.1429969, 'west': 10.116121}, {
  'zipCode': '24251',
  'north':   54.4557569,
  'east':    10.0689405,
  'south':   54.4093839,
  'west':    9.9585494
}, {'zipCode': '24253', 'north': 54.3946089, 'east': 10.3579727, 'south': 54.3348991, 'west': 10.237421}, {
  'zipCode': '24254',
  'north':   54.2744,
  'east':    10.0467999,
  'south':   54.2329522,
  'west':    9.9968779
}, {'zipCode': '24256', 'north': 54.3613316, 'east': 10.4345611, 'south': 54.2906629, 'west': 10.3033385}, {
  'zipCode': '24257',
  'north':   54.4043557,
  'east':    10.5401971,
  'south':   54.3166629,
  'west':    10.4187396
}, {'zipCode': '24259', 'north': 54.3084286, 'east': 9.977614900000003, 'south': 54.2387157, 'west': 9.8301453}, {
  'zipCode': '24306',
  'north':   54.2422057,
  'east':    10.541111300000003,
  'south':   54.0820365,
  'west':    10.3303806
}, {'zipCode': '24321', 'north': 54.3813405, 'east': 10.6828872, 'south': 54.2473785, 'west': 10.428848200000001}, {
  'zipCode': '24326',
  'north':   54.1786455,
  'east':    10.4191523,
  'south':   54.0707013,
  'west':    10.2421115
}, {'zipCode': '24327', 'north': 54.3171485, 'east': 10.7293634, 'south': 54.2159816, 'west': 10.5955844}, {
  'zipCode': '24329',
  'north':   54.2683492,
  'east':    10.6065165,
  'south':   54.1726253,
  'west':    10.4505439
}, {'zipCode': '24340', 'north': 54.518629100000005, 'east': 9.9477682, 'south': 54.4067185, 'west': 9.74369}, {
  'zipCode': '24351',
  'north':   54.6174986,
  'east':    10.0288929,
  'south':   54.5536924,
  'west':    9.8268728
}, {'zipCode': '24354', 'north': 54.5863128, 'east': 9.8977854, 'south': 54.4903699, 'west': 9.6731641}, {
  'zipCode': '24357',
  'north':   54.5153377,
  'east':    9.7581437,
  'south':   54.4447199,
  'west':    9.6280602
}, {'zipCode': '24358', 'north': 54.4549535, 'east': 9.743003500000002, 'south': 54.380828, 'west': 9.6554329}, {
  'zipCode': '24360',
  'north':   54.5199743,
  'east':    9.9228015,
  'south':   54.4756689,
  'west':    9.8117734
}, {'zipCode': '24361', 'north': 54.438239800000005, 'east': 9.842761, 'south': 54.36053750000001, 'west': 9.6780609}, {
  'zipCode': '24363',
  'north':   54.42751350000001,
  'east':    9.904809100000001,
  'south':   54.3691563,
  'west':    9.8232792
}, {'zipCode': '24364', 'north': 54.5822628, 'east': 9.9572357, 'south': 54.5330815, 'west': 9.853661}, {
  'zipCode': '24366',
  'north':   54.5415717,
  'east':    9.9371538,
  'south':   54.5058529,
  'west':    9.8352847
}, {'zipCode': '24367', 'north': 54.468251, 'east': 9.7851972, 'south': 54.4292397, 'west': 9.7208015}, {
  'zipCode': '24369',
  'north':   54.5682919,
  'east':    10.0273061,
  'south':   54.489732700000005,
  'west':    9.9066009
}, {'zipCode': '24376', 'north': 54.7385253, 'east': 10.0433968, 'south': 54.6203293, 'west': 9.8703701}, {
  'zipCode': '24392',
  'north':   54.6905633,
  'east':    9.8926869,
  'south':   54.5755789,
  'west':    9.6892234
}, {'zipCode': '24395', 'north': 54.8004005, 'east': 9.9855133, 'south': 54.689156, 'west': 9.7822694}, {
  'zipCode': '24398',
  'north':   54.6469684,
  'east':    10.0336427,
  'south':   54.580881,
  'west':    9.8983959
}, {'zipCode': '24399', 'north': 54.6346347, 'east': 9.9391081, 'south': 54.6203293, 'west': 9.9065106}, {
  'zipCode': '24401',
  'north':   54.6674594,
  'east':    9.7452904,
  'south':   54.6249845,
  'west':    9.6572811
}, {'zipCode': '24402', 'north': 54.7439223, 'east': 9.8521901, 'south': 54.6858242, 'west': 9.7725874}, {
  'zipCode': '24404',
  'north':   54.7126096,
  'east':    10.0368342,
  'south':   54.6708855,
  'west':    9.9829608
}, {'zipCode': '24405', 'north': 54.6978797, 'east': 9.7992331, 'south': 54.6580395, 'west': 9.6640723}, {
  'zipCode': '24407',
  'north':   54.689536,
  'east':    9.9121875,
  'south':   54.6142829,
  'west':    9.8164236
}, {'zipCode': '24409', 'north': 54.717539, 'east': 9.90711, 'south': 54.6659962, 'west': 9.8258849}, {
  'zipCode': '24534',
  'north':   54.0847736,
  'east':    9.9996106,
  'south':   54.0630123,
  'west':    9.957892300000001
}, {'zipCode': '24536', 'north': 54.1496806, 'east': 10.0808758, 'south': 54.0701823, 'west': 9.9511621}, {
  'zipCode': '24537',
  'north':   54.1217004,
  'east':    9.9869538,
  'south':   54.0519697,
  'west':    9.9266614
}, {'zipCode': '24539', 'north': 54.0733113, 'east': 10.0584699, 'south': 54.0173964, 'west': 9.9443345}, {
  'zipCode': '24558',
  'north':   53.8155785,
  'east':    10.1184076,
  'south':   53.7451383,
  'west':    9.9268948
}, {'zipCode': '24568', 'north': 53.8802026, 'east': 10.0836921, 'south': 53.802088, 'west': 9.855287}, {
  'zipCode': '24576',
  'north':   53.961128,
  'east':    10.0417758,
  'south':   53.8621016,
  'west':    9.7515896
}, {'zipCode': '24582', 'north': 54.2192235, 'east': 10.1313674, 'south': 54.1288692, 'west': 9.9452597}, {
  'zipCode': '24589',
  'north':   54.2176913,
  'east':    9.9686778,
  'south':   54.1419424,
  'west':    9.7991812
}, {'zipCode': '24594', 'north': 54.1495804, 'east': 9.7879697, 'south': 54.0425355, 'west': 9.5804724}, {
  'zipCode': '24598',
  'north':   54.0378098,
  'east':    10.140357,
  'south':   53.9298645,
  'west':    9.9680698
}, {'zipCode': '24601', 'north': 54.1750296, 'east': 10.3042064, 'south': 54.071796, 'west': 10.151169}, {
  'zipCode': '24610',
  'north':   54.0703474,
  'east':    10.236035,
  'south':   54.0280481,
  'west':    10.1233977
}, {'zipCode': '24613', 'north': 54.1136717, 'east': 9.861029600000002, 'south': 54.0302049, 'west': 9.7032348}, {
  'zipCode': '24616',
  'north':   54.0501847,
  'east':    9.9191317,
  'south':   53.951493000000006,
  'west':    9.759568
}, {'zipCode': '24619', 'north': 54.1023575, 'east': 10.2936957, 'south': 54.0233347, 'west': 10.0874593}, {
  'zipCode': '24620',
  'north':   54.0977571,
  'east':    10.1218495,
  'south':   54.0556732,
  'west':    10.0163184
}, {'zipCode': '24622', 'north': 54.1569221, 'east': 9.865330100000001, 'south': 54.1039983, 'west': 9.7771708}, {
  'zipCode': '24623',
  'north':   54.0228936,
  'east':    10.0617252,
  'south':   53.9371234,
  'west':    9.8995783
}, {'zipCode': '24625', 'north': 54.1609431, 'east': 10.1184181, 'south': 54.1029667, 'west': 10.019087400000002}, {
  'zipCode': '24626',
  'north':   54.058893600000005,
  'east':    10.1480238,
  'south':   54.0044834,
  'west':    10.013577
}, {'zipCode': '24628', 'north': 53.9183086, 'east': 10.1079447, 'south': 53.8735607, 'west': 10.0366299}, {
  'zipCode': '24629',
  'north':   53.8329081,
  'east':    10.113881000000001,
  'south':   53.7937774,
  'west':    9.9767643
}, {'zipCode': '24631', 'north': 54.2493854, 'east': 9.9798273, 'south': 54.181904, 'west': 9.8924529}, {
  'zipCode': '24632',
  'north':   53.9009126,
  'east':    9.947584,
  'south':   53.8170635,
  'west':    9.7888102
}, {'zipCode': '24634', 'north': 54.0537732, 'east': 9.9570006, 'south': 54.0086911, 'west': 9.8137822}, {
  'zipCode': '24635',
  'north':   54.0410393,
  'east':    10.2941946,
  'south':   53.968579,
  'west':    10.1155225
}, {'zipCode': '24637', 'north': 54.1510696, 'east': 10.1711091, 'south': 54.0833673, 'west': 10.0718308}, {
  'zipCode': '24638',
  'north':   54.1000985,
  'east':    10.3065732,
  'south':   54.0592939,
  'west':    10.255563
}, {'zipCode': '24640', 'north': 53.9350685, 'east': 10.0550177, 'south': 53.8565617, 'west': 9.940366600000003}, {
  'zipCode': '24641',
  'north':   53.8765995,
  'east':    10.1485152,
  'south':   53.817431,
  'west':    10.0516537
}, {'zipCode': '24643', 'north': 53.887613, 'east': 10.1034382, 'south': 53.8484329, 'west': 10.022025}, {
  'zipCode': '24644',
  'north':   54.1654123,
  'east':    9.9843633,
  'south':   54.0966337,
  'west':    9.8430568
}, {'zipCode': '24646', 'north': 54.2346043, 'east': 9.9142222, 'south': 54.1971473, 'west': 9.8539324}, {
  'zipCode': '24647',
  'north':   54.1109722,
  'east':    9.9356753,
  'south':   54.0280029,
  'west':    9.8130212
}, {'zipCode': '24649', 'north': 53.9879629, 'east': 9.9570745, 'south': 53.93073780000001, 'west': 9.8516362}, {
  'zipCode': '24768',
  'north':   54.33876440000001,
  'east':    9.712058,
  'south':   54.2549934,
  'west':    9.6081554
}, {'zipCode': '24782', 'north': 54.3523945, 'east': 9.7173146, 'south': 54.3074589, 'west': 9.6360896}, {
  'zipCode': '24783',
  'north':   54.2993489,
  'east':    9.7594552,
  'south':   54.2516471,
  'west':    9.6666396
}, {'zipCode': '24784', 'north': 54.2903632, 'east': 9.6913137, 'south': 54.2536224, 'west': 9.630961}, {
  'zipCode': '24787',
  'north':   54.3447104,
  'east':    9.6306096,
  'south':   54.2769722,
  'west':    9.542867500000002
}, {'zipCode': '24790', 'north': 54.3470291, 'east': 9.8393448, 'south': 54.2603491, 'west': 9.7036213}, {
  'zipCode': '24791',
  'north':   54.386167400000005,
  'east':    9.6928374,
  'south':   54.3336679,
  'west':    9.5557945
}, {'zipCode': '24793', 'north': 54.2247122, 'east': 9.814629200000002, 'south': 54.1373079, 'west': 9.7096344}, {
  'zipCode': '24794',
  'north':   54.3839053,
  'east':    9.7921575,
  'south':   54.3252184,
  'west':    9.6695709
}, {'zipCode': '24796', 'north': 54.3636735, 'east': 9.9642054, 'south': 54.2958816, 'west': 9.7620522}, {
  'zipCode': '24797',
  'north':   54.2370511,
  'east':    9.6214048,
  'south':   54.17775230000001,
  'west':    9.4481368
}, {'zipCode': '24799', 'north': 54.3731044, 'east': 9.4853598, 'south': 54.2609537, 'west': 9.3112403}, {
  'zipCode': '24800',
  'north':   54.29165,
  'east':    9.5868941,
  'south':   54.23218920000001,
  'west':    9.4323939
}, {'zipCode': '24802', 'north': 54.2869476, 'east': 9.9039284, 'south': 54.1994767, 'west': 9.7203516}, {
  'zipCode': '24803',
  'north':   54.3474291,
  'east':    9.4067045,
  'south':   54.2714751,
  'west':    9.2589109
}, {'zipCode': '24805', 'north': 54.2515287, 'east': 9.5789065, 'south': 54.1965277, 'west': 9.428689}, {
  'zipCode': '24806',
  'north':   54.3575133,
  'east':    9.5757389,
  'south':   54.2524144,
  'west':    9.3931426
}, {'zipCode': '24808', 'north': 54.2637572, 'east': 9.7538425, 'south': 54.1888193, 'west': 9.6138437}, {
  'zipCode': '24809',
  'north':   54.2878456,
  'east':    9.633154,
  'south':   54.2392602,
  'west':    9.565881
}, {'zipCode': '24811', 'north': 54.4536969, 'east': 9.672363400000002, 'south': 54.3530607, 'west': 9.5470349}, {
  'zipCode': '24813',
  'north':   54.2740562,
  'east':    9.6585145,
  'south':   54.2258189,
  'west':    9.5830111
}, {'zipCode': '24814', 'north': 54.3903739, 'east': 9.8737277, 'south': 54.344521, 'west': 9.7798097}, {
  'zipCode': '24816',
  'north':   54.2192918,
  'east':    9.7254506,
  'south':   54.1435362,
  'west':    9.5795133
}, {'zipCode': '24817', 'north': 54.3725019, 'east': 9.5658762, 'south': 54.3363397, 'west': 9.4084202}, {
  'zipCode': '24819',
  'north':   54.1948392,
  'east':    9.6536609,
  'south':   54.1116446,
  'west':    9.5117913
}, {'zipCode': '24837', 'north': 54.5460562, 'east': 9.616047, 'south': 54.4915915, 'west': 9.5106085}, {
  'zipCode': '24848',
  'north':   54.4665173,
  'east':    9.5903084,
  'south':   54.3620947,
  'west':    9.3773003
}, {'zipCode': '24850', 'north': 54.566035, 'east': 9.5393365, 'south': 54.4754319, 'west': 9.4060887}, {
  'zipCode': '24852',
  'north':   54.64493920000001,
  'east':    9.4396272,
  'south':   54.5474791,
  'west':    9.2848199
}, {'zipCode': '24855', 'north': 54.6189755, 'east': 9.4826929, 'south': 54.5363547, 'west': 9.3543232}, {
  'zipCode': '24857',
  'north':   54.5166485,
  'east':    9.6754014,
  'south':   54.4740657,
  'west':    9.5669894
}, {'zipCode': '24860', 'north': 54.6508209, 'east': 9.6260325, 'south': 54.582144500000005, 'west': 9.4955437}, {
  'zipCode': '24861',
  'north':   54.3978753,
  'east':    9.3835945,
  'south':   54.34680310000001,
  'west':    9.2569524
}, {'zipCode': '24863', 'north': 54.4279179, 'east': 9.4432771, 'south': 54.36866040000001, 'west': 9.3261104}, {
  'zipCode': '24864',
  'north':   54.5656866,
  'east':    9.7414461,
  'south':   54.5153377,
  'west':    9.6709588
}, {'zipCode': '24866', 'north': 54.5042973, 'east': 9.5776423, 'south': 54.4695927, 'west': 9.5289326}, {
  'zipCode': '24867',
  'north':   54.5045586,
  'east':    9.537343500000002,
  'south':   54.4512279,
  'west':    9.4369273
}, {'zipCode': '24869', 'north': 54.4522192, 'east': 9.4082015, 'south': 54.4125728, 'west': 9.2983552}, {
  'zipCode': '24870',
  'north':   54.5071183,
  'east':    9.4548826,
  'south':   54.4535019,
  'west':    9.3572518
}, {'zipCode': '24872', 'north': 54.4603658, 'east': 9.4652815, 'south': 54.4197321, 'west': 9.3806929}, {
  'zipCode': '24873',
  'north':   54.6849223,
  'east':    9.5507384,
  'south':   54.6295639,
  'west':    9.4757879
}, {'zipCode': '24876', 'north': 54.4950556, 'east': 9.3899095, 'south': 54.4421766, 'west': 9.3126936}, {
  'zipCode': '24878',
  'north':   54.47157630000001,
  'east':    9.5989962,
  'south':   54.430269200000005,
  'west':    9.5066553
}, {'zipCode': '24879', 'north': 54.5988123, 'east': 9.5505266, 'south': 54.5439208, 'west': 9.4786718}, {
  'zipCode': '24881',
  'north':   54.5861276,
  'east':    9.6250372,
  'south':   54.5359514,
  'west':    9.5276094
}, {'zipCode': '24882', 'north': 54.5663316, 'east': 9.6874391, 'south': 54.5110302, 'west': 9.586898}, {
  'zipCode': '24884',
  'north':   54.4894398,
  'east':    9.6416832,
  'south':   54.4412788,
  'west':    9.543865
}, {'zipCode': '24885', 'north': 54.679860600000005, 'east': 9.5116347, 'south': 54.607288, 'west': 9.4225047}, {
  'zipCode': '24887',
  'north':   54.5885277,
  'east':    9.4181136,
  'south':   54.4752632,
  'west':    9.3156285
}, {'zipCode': '24888', 'north': 54.6252149, 'east': 9.7720126, 'south': 54.5840132, 'west': 9.6825898}, {
  'zipCode': '24890',
  'north':   54.6156511,
  'east':    9.6087686,
  'south':   54.569217,
  'west':    9.4564967
}, {'zipCode': '24891', 'north': 54.6765144, 'east': 9.6790844, 'south': 54.6039266, 'west': 9.5963816}, {
  'zipCode': '24893',
  'north':   54.5957199,
  'east':    9.7243004,
  'south':   54.5482347,
  'west':    9.654928
}, {'zipCode': '24894', 'north': 54.6304609, 'east': 9.701272300000001, 'south': 54.5631655, 'west': 9.611171}, {
  'zipCode': '24896',
  'north':   54.5541548,
  'east':    9.3601972,
  'south':   54.4755232,
  'west':    9.2807292
}, {'zipCode': '24897', 'north': 54.6045193, 'east': 9.8083379, 'south': 54.5402506, 'west': 9.712524}, {
  'zipCode': '24899',
  'north':   54.429921400000005,
  'east':    9.3451423,
  'south':   54.382152,
  'west':    9.2578182
}, {'zipCode': '24937', 'north': 54.8074966, 'east': 9.4541265, 'south': 54.7694754, 'west': 9.4043887}, {
  'zipCode': '24939',
  'north':   54.8243313,
  'east':    9.439002,
  'south':   54.78338350000001,
  'west':    9.3964683
}, {'zipCode': '24941', 'north': 54.7978232, 'east': 9.4614164, 'south': 54.7517986, 'west': 9.3573409}, {
  'zipCode': '24943',
  'north':   54.8060687,
  'east':    9.5068117,
  'south':   54.7498818,
  'west':    9.440668
}, {'zipCode': '24944', 'north': 54.8239796, 'east': 9.5064226, 'south': 54.7965831, 'west': 9.4525433}, {
  'zipCode': '24955',
  'north':   54.842609700000004,
  'east':    9.4228309,
  'south':   54.780806000000005,
  'west':    9.3420529
}, {'zipCode': '24960', 'north': 54.877976, 'east': 9.6338382, 'south': 54.7909548, 'west': 9.4910379}, {
  'zipCode': '24963',
  'north':   54.6981574,
  'east':    9.4357639,
  'south':   54.621393700000006,
  'west':    9.3129498
}, {'zipCode': '24966', 'north': 54.76161160000001, 'east': 9.7179638, 'south': 54.6875971, 'west': 9.5952669}, {
  'zipCode': '24969',
  'north':   54.741121400000004,
  'east':    9.304841,
  'south':   54.64751770000001,
  'west':    9.0621791
}, {'zipCode': '24972', 'north': 54.8065899, 'east': 9.8336272, 'south': 54.7335415, 'west': 9.6906571}, {
  'zipCode': '24975',
  'north':   54.7981023,
  'east':    9.6269279,
  'south':   54.714685,
  'west':    9.4925304
}, {'zipCode': '24976', 'north': 54.7536223, 'east': 9.451972, 'south': 54.7226078, 'west': 9.3660279}, {
  'zipCode': '24977',
  'north':   54.8284398,
  'east':    9.7276608,
  'south':   54.7521681,
  'west':    9.566548
}, {'zipCode': '24980', 'north': 54.7990572, 'east': 9.2781282, 'south': 54.7142544, 'west': 9.0709755}, {
  'zipCode': '24983',
  'north':   54.8121447,
  'east':    9.3785656,
  'south':   54.7018515,
  'west':    9.2453207
}, {'zipCode': '24986', 'north': 54.716295200000005, 'east': 9.6809022, 'south': 54.6362167, 'west': 9.5296587}, {
  'zipCode': '24988',
  'north':   54.7512981,
  'east':    9.4833859,
  'south':   54.6742635,
  'west':    9.365855
}, {'zipCode': '24989', 'north': 54.805183, 'east': 9.7174407, 'south': 54.7563971, 'west': 9.6465937}, {
  'zipCode': '24991',
  'north':   54.7545734,
  'east':    9.5948046,
  'south':   54.6693528,
  'west':    9.4518145
}, {'zipCode': '24992', 'north': 54.6568524, 'east': 9.3268364, 'south': 54.5714553, 'west': 9.2069077}, {
  'zipCode': '24994',
  'north':   54.8737238,
  'east':    9.2501078,
  'south':   54.7808616,
  'west':    9.0773176
}, {'zipCode': '24996', 'north': 54.7455628, 'east': 9.7900024, 'south': 54.6948616, 'west': 9.6999956}, {
  'zipCode': '24997',
  'north':   54.7135242,
  'east':    9.3724052,
  'south':   54.650721,
  'west':    9.2579149
}, {'zipCode': '24999', 'north': 54.8250085, 'east': 9.5693334, 'south': 54.78935930000001, 'west': 9.4995787}, {
  'zipCode': '25335',
  'north':   53.797260400000006,
  'east':    9.7466075,
  'south':   53.714025,
  'west':    9.5042743
}, {'zipCode': '25336', 'north': 53.753182, 'east': 9.7161074, 'south': 53.709477, 'west': 9.6216231}, {
  'zipCode': '25337',
  'north':   53.7713574,
  'east':    9.7665153,
  'south':   53.7218416,
  'west':    9.660881
}, {'zipCode': '25348', 'north': 53.8247289, 'east': 9.4900054, 'south': 53.7462511, 'west': 9.3960541}, {
  'zipCode': '25355',
  'north':   53.8600854,
  'east':    9.8615661,
  'south':   53.7374471,
  'west':    9.6900884
}, {'zipCode': '25358', 'north': 53.8736024, 'east': 9.6775759, 'south': 53.7679765, 'west': 9.5001794}, {
  'zipCode': '25361',
  'north':   53.8523942,
  'east':    9.5866422,
  'south':   53.792536600000005,
  'west':    9.4661896
}, {'zipCode': '25364', 'north': 53.8929185, 'east': 9.7936724, 'south': 53.8238874, 'west': 9.6457115}, {
  'zipCode': '25365',
  'north':   53.8342387,
  'east':    9.7249218,
  'south':   53.7749495,
  'west':    9.6492986
}, {'zipCode': '25368', 'north': 53.7930708, 'east': 9.6281448, 'south': 53.764299, 'west': 9.561596}, {
  'zipCode': '25370',
  'north':   53.7443984,
  'east':    9.6424574,
  'south':   53.6993558,
  'west':    9.5684295
}, {'zipCode': '25371', 'north': 53.7178935, 'east': 9.5869104, 'south': 53.6692447, 'west': 9.5160477}, {
  'zipCode': '25373',
  'north':   53.7457513,
  'east':    9.8203866,
  'south':   53.7065096,
  'west':    9.742297
}, {'zipCode': '25376', 'north': 53.8474794, 'east': 9.4985892, 'south': 53.8052515, 'west': 9.3948039}, {
  'zipCode': '25377',
  'north':   53.7677189,
  'east':    9.5506291,
  'south':   53.6635081,
  'west':    9.4333654
}, {'zipCode': '25379', 'north': 53.795744400000004, 'east': 9.5555455, 'south': 53.7605752, 'west': 9.4582719}, {
  'zipCode': '25421',
  'north':   53.6838731,
  'east':    9.8286789,
  'south':   53.6086307,
  'west':    9.7636444
}, {'zipCode': '25436', 'north': 53.7245968, 'east': 9.7820676, 'south': 53.6523986, 'west': 9.5731915}, {
  'zipCode': '25451',
  'north':   53.7697524,
  'east':    9.9833486,
  'south':   53.703627,
  'west':    9.8273647
}, {'zipCode': '25462', 'north': 53.6748597, 'east': 9.896880400000002, 'south': 53.6219472, 'west': 9.8085556}, {
  'zipCode': '25469',
  'north':   53.6472848,
  'east':    9.8849942,
  'south':   53.6092986,
  'west':    9.7988673
}, {'zipCode': '25474', 'north': 53.7197035, 'east': 9.9526588, 'south': 53.6345463, 'west': 9.8489769}, {
  'zipCode': '25479',
  'north':   53.7720716,
  'east':    9.9657832,
  'south':   53.7472712,
  'west':    9.8925592
}, {'zipCode': '25482', 'north': 53.680145, 'east': 9.7778346, 'south': 53.6182658, 'west': 9.6913199}, {
  'zipCode': '25485',
  'north':   53.8232026,
  'east':    9.9015776,
  'south':   53.7289433,
  'west':    9.7903338
}, {'zipCode': '25486', 'north': 53.8168224, 'east': 9.9707503, 'south': 53.76636640000001, 'west': 9.8667419}, {
  'zipCode': '25488',
  'north':   53.64137540000001,
  'east':    9.755937,
  'south':   53.6063548,
  'west':    9.6486712
}, {'zipCode': '25489', 'north': 53.6743607, 'east': 9.6490259, 'south': 53.60999150000001, 'west': 9.5490054}, {
  'zipCode': '25491',
  'north':   53.66518310000001,
  'east':    9.662639,
  'south':   53.588001,
  'west':    9.5350426
}, {'zipCode': '25492', 'north': 53.6652945, 'east': 9.7151466, 'south': 53.6318605, 'west': 9.6399817}, {
  'zipCode': '25494',
  'north':   53.743775500000005,
  'east':    9.8577718,
  'south':   53.67508,
  'west':    9.7926517
}, {'zipCode': '25495', 'north': 53.7239985, 'east': 9.8076256, 'south': 53.682991300000005, 'west': 9.7670457}, {
  'zipCode': '25497',
  'north':   53.6968562,
  'east':    9.7796845,
  'south':   53.662776,
  'west':    9.7449903
}, {'zipCode': '25499', 'north': 53.7108125, 'east': 9.8891128, 'south': 53.6630138, 'west': 9.8196605}, {
  'zipCode': '25524',
  'north':   53.9633653,
  'east':    9.6494369,
  'south':   53.8713734,
  'west':    9.4295683
}, {'zipCode': '25541', 'north': 53.9506195, 'east': 9.2206457, 'south': 53.8775428, 'west': 9.0178975}, {
  'zipCode': '25548',
  'north':   54.01071700000001,
  'east':    9.7771735,
  'south':   53.897502,
  'west':    9.6404062
}, {'zipCode': '25551', 'north': 54.0495243, 'east': 9.7128373, 'south': 53.9256968, 'west': 9.5417284}, {
  'zipCode': '25554',
  'north':   53.9863418,
  'east':    9.4487291,
  'south':   53.8689677,
  'west':    9.2751425
}, {'zipCode': '25557', 'north': 54.1994837, 'east': 9.5168213, 'south': 54.0623261, 'west': 9.3042966}, {
  'zipCode': '25560',
  'north':   54.0996034,
  'east':    9.5595966,
  'south':   54.0057944,
  'west':    9.3787935
}, {'zipCode': '25563', 'north': 53.9748324, 'east': 9.8376573, 'south': 53.8900696, 'west': 9.6846466}, {
  'zipCode': '25566',
  'north':   53.8925198,
  'east':    9.6329622,
  'south':   53.8532969,
  'west':    9.5443477
}, {'zipCode': '25569', 'north': 53.9162068, 'east': 9.5062243, 'south': 53.8467088, 'west': 9.4083526}, {
  'zipCode': '25572',
  'north':   53.9908173,
  'east':    9.3099446,
  'south':   53.8758861,
  'west':    9.1986085
}, {'zipCode': '25573', 'north': 53.9064005, 'east': 9.4352674, 'south': 53.8458704, 'west': 9.3626837}, {
  'zipCode': '25575',
  'north':   54.15452890000001,
  'east':    9.5663489,
  'south':   54.0912413,
  'west':    9.5091183
}, {'zipCode': '25576', 'north': 53.8945592, 'east': 9.357785, 'south': 53.8446827, 'west': 9.2739411}, {
  'zipCode': '25578',
  'north':   53.8955707,
  'east':    9.5815011,
  'south':   53.8451102,
  'west':    9.4828893
}, {'zipCode': '25579', 'north': 54.0300944, 'east': 9.7908728, 'south': 53.9811177, 'west': 9.7253513}, {
  'zipCode': '25581',
  'north':   54.0634018,
  'east':    9.7641054,
  'south':   54.0083387,
  'west':    9.6558717
}, {'zipCode': '25582', 'north': 54.0509205, 'east': 9.5904604, 'south': 53.9669972, 'west': 9.4344491}, {
  'zipCode': '25584',
  'north':   54.0710468,
  'east':    9.398842,
  'south':   54.0303526,
  'west':    9.2852887
}, {'zipCode': '25585', 'north': 54.1863904, 'east': 9.5283964, 'south': 54.12242570000001, 'west': 9.448397}, {
  'zipCode': '25587',
  'north':   53.9137712,
  'east':    9.5616438,
  'south':   53.8871376,
  'west':    9.5131927
}, {'zipCode': '25588', 'north': 54.0272698, 'east': 9.4833448, 'south': 53.9359665, 'west': 9.4036936}, {
  'zipCode': '25590',
  'north':   54.1289868,
  'east':    9.6198985,
  'south':   54.0872209,
  'west':    9.5558842
}, {'zipCode': '25591', 'north': 53.9829382, 'east': 9.5474125, 'south': 53.9557087, 'west': 9.4537979}, {
  'zipCode': '25593',
  'north':   54.0923213,
  'east':    9.607846,
  'south':   54.0414731,
  'west':    9.5174
}, {'zipCode': '25594', 'north': 54.0133735, 'east': 9.4128951, 'south': 53.9648022, 'west': 9.2853888}, {
  'zipCode': '25596',
  'north':   54.05096120000001,
  'east':    9.441621,
  'south':   53.993711700000006,
  'west':    9.2928399
}, {'zipCode': '25597', 'north': 53.9391948, 'east': 9.6930339, 'south': 53.8811924, 'west': 9.5746965}, {
  'zipCode': '25599',
  'north':   53.8719669,
  'east':    9.4421815,
  'south':   53.8232249,
  'west':    9.346338200000002
}, {'zipCode': '25693', 'north': 54.0404309, 'east': 9.1667227, 'south': 53.9470569, 'west': 8.9950904}, {
  'zipCode': '25704',
  'north':   54.1508819,
  'east':    9.2059225,
  'south':   54.0379166,
  'west':    8.932461
}, {'zipCode': '25709', 'north': 54.034563, 'east': 9.0640346, 'south': 53.9135231, 'west': 8.8985794}, {
  'zipCode': '25712',
  'north':   54.0419623,
  'east':    9.3051307,
  'south':   53.941621,
  'west':    9.1581747
}, {'zipCode': '25715', 'north': 53.980378, 'east': 9.2288252, 'south': 53.919324, 'west': 9.0629643}, {
  'zipCode': '25718',
  'north':   54.0698549,
  'east':    8.9763767,
  'south':   53.9518869,
  'west':    8.6785796
}, {'zipCode': '25719', 'north': 54.0506559, 'east': 9.119014, 'south': 53.9969443, 'west': 8.9607811}, {
  'zipCode': '25721',
  'north':   54.0793796,
  'east':    9.2901966,
  'south':   54.0348571,
  'west':    9.2340633
}, {'zipCode': '25724', 'north': 53.9455485, 'east': 9.084215300000002, 'south': 53.8912441, 'west': 8.9422625}, {
  'zipCode': '25725',
  'north':   54.1187051,
  'east':    9.3295768,
  'south':   54.0417497,
  'west':    9.2473528
}, {'zipCode': '25727', 'north': 54.0944749, 'east': 9.2602094, 'south': 54.0059133, 'west': 9.128254600000002}, {
  'zipCode': '25729',
  'north':   54.06580410000001,
  'east':    9.1678023,
  'south':   54.021009,
  'west':    9.0838057
}, {'zipCode': '25746', 'north': 54.2446659, 'east': 9.213009, 'south': 54.161027, 'west': 8.9502751}, {
  'zipCode': '25761',
  'north':   54.2059222,
  'east':    8.9526639,
  'south':   54.1189254,
  'west':    8.8074897
}, {'zipCode': '25764', 'north': 54.2881009, 'east': 9.0133026, 'south': 54.1345232, 'west': 8.8221976}, {
  'zipCode': '25767',
  'north':   54.2068507,
  'east':    9.4422382,
  'south':   54.0865946,
  'west':    9.1900421
}, {'zipCode': '25770', 'north': 54.1748022, 'east': 9.1346558, 'south': 54.1310403, 'west': 9.0412857}, {
  'zipCode': '25774',
  'north':   54.378779,
  'east':    9.0684721,
  'south':   54.2485586,
  'west':    8.9063728
}, {'zipCode': '25776', 'north': 54.37032, 'east': 9.1531655, 'south': 54.2721444, 'west': 9.0090079}, {
  'zipCode': '25779',
  'north':   54.3375171,
  'east':    9.2512258,
  'south':   54.2181829,
  'west':    9.0658506
}, {'zipCode': '25782', 'north': 54.2779308, 'east': 9.3719004, 'south': 54.1717614, 'west': 9.1831099}, {
  'zipCode': '25785',
  'north':   54.1945167,
  'east':    9.247138,
  'south':   54.1029782,
  'west':    9.1271973
}, {'zipCode': '25786', 'north': 54.26827900000001, 'east': 9.4443681, 'south': 54.2211715, 'west': 9.3351806}, {
  'zipCode': '25788',
  'north':   54.3469793,
  'east':    9.2841631,
  'south':   54.2743427,
  'west':    9.1992822
}, {'zipCode': '25791', 'north': 54.2695737, 'east': 9.2329219, 'south': 54.212088800000004, 'west': 9.1604696}, {
  'zipCode': '25792',
  'north':   54.2690129,
  'east':    9.0513195,
  'south':   54.2050824,
  'west':    8.9339775
}, {'zipCode': '25794', 'north': 54.2941298, 'east': 9.4044651, 'south': 54.243735900000004, 'west': 9.2507015}, {
  'zipCode': '25795',
  'north':   54.2798996,
  'east':    9.126218100000003,
  'south':   54.2088184,
  'west':    9.0328576
}, {'zipCode': '25797', 'north': 54.1827537, 'east': 9.0592204, 'south': 54.1357578, 'west': 8.9456698}, {
  'zipCode': '25799',
  'north':   54.2365367,
  'east':    9.4429955,
  'south':   54.2008576,
  'west':    9.3588643
}, {'zipCode': '25813', 'north': 54.5208603, 'east': 9.1724933, 'south': 54.4090958, 'west': 8.9248754}, {
  'zipCode': '25821',
  'north':   54.6793418,
  'east':    9.0787728,
  'south':   54.5554676,
  'west':    8.8087098
}, {'zipCode': '25826', 'north': 54.3602601, 'east': 8.7069762, 'south': 54.2645274, 'west': 8.582239500000002}, {
  'zipCode': '25832',
  'north':   54.3514846,
  'east':    8.9654162,
  'south':   54.2605987,
  'west':    8.8223141
}, {'zipCode': '25836', 'north': 54.4086158, 'east': 8.8750588, 'south': 54.2805815, 'west': 8.690916}, {
  'zipCode': '25840',
  'north':   54.4247004,
  'east':    9.1543132,
  'south':   54.3479672,
  'west':    9.019207800000002
}, {'zipCode': '25842', 'north': 54.733444, 'east': 9.0741608, 'south': 54.6466892, 'west': 8.7515458}, {
  'zipCode': '25845',
  'north':   54.5556786,
  'east':    8.9595885,
  'south':   54.4595468,
  'west':    8.718442
}, {'zipCode': '25849', 'north': 54.5573961, 'east': 8.7087629, 'south': 54.4595935, 'west': 8.5449529}, {
  'zipCode': '25850',
  'north':   54.5867186,
  'east':    9.3114938,
  'south':   54.5364534,
  'west':    9.1926409
}, {'zipCode': '25852', 'north': 54.6654148, 'east': 8.9772414, 'south': 54.6138421, 'west': 8.8581295}, {
  'zipCode': '25853',
  'north':   54.6262922,
  'east':    9.1197334,
  'south':   54.5501603,
  'west':    9.016031
}, {'zipCode': '25855', 'north': 54.6151204, 'east': 9.2224671, 'south': 54.5802513, 'west': 9.1445843}, {
  'zipCode': '25856',
  'north':   54.5670086,
  'east':    9.0600992,
  'south':   54.50506790000001,
  'west':    8.9343381
}, {'zipCode': '25858', 'north': 54.6694188, 'east': 9.1010321, 'south': 54.6224026, 'west': 9.0274247}, {
  'zipCode': '25859',
  'north':   54.5791996,
  'east':    8.573517,
  'south':   54.527166,
  'west':    8.5072135
}, {'zipCode': '25860', 'north': 54.5537347, 'east': 9.1405023, 'south': 54.5003266, 'west': 9.043052}, {
  'zipCode': '25862',
  'north':   54.7015037,
  'east':    9.1935116,
  'south':   54.6187004,
  'west':    9.0664905
}, {'zipCode': '25863', 'north': 54.6619628, 'east': 8.6650676, 'south': 54.6176756, 'west': 8.5288415}, {
  'zipCode': '25864',
  'north':   54.6585981,
  'east':    9.22266,
  'south':   54.6086179,
  'west':    9.1279761
}, {'zipCode': '25866', 'north': 54.4838343, 'east': 9.1498073, 'south': 54.4474286, 'west': 9.0703022}, {
  'zipCode': '25867',
  'north':   54.6916074,
  'east':    8.721328,
  'south':   54.6718556,
  'west':    8.681442
}, {'zipCode': '25869', 'north': 54.6433857, 'east': 8.773663, 'south': 54.627333, 'west': 8.7102138}, {
  'zipCode': '25870',
  'north':   54.41726980000001,
  'east':    9.0015652,
  'south':   54.314837,
  'west':    8.8665571
}, {'zipCode': '25872', 'north': 54.4914755, 'east': 9.3272542, 'south': 54.4208681, 'west': 9.1690482}, {
  'zipCode': '25873',
  'north':   54.4857222,
  'east':    9.194714,
  'south':   54.4184481,
  'west':    9.1138234
}, {'zipCode': '25876', 'north': 54.4232345, 'east': 9.2787176, 'south': 54.3710197, 'west': 9.1103157}, {
  'zipCode': '25878',
  'north':   54.391167,
  'east':    9.2084155,
  'south':   54.3168695,
  'west':    9.1348383
}, {'zipCode': '25879', 'north': 54.3861448, 'east': 9.3128186, 'south': 54.3155197, 'west': 9.171484}, {
  'zipCode': '25881',
  'north':   54.4005378,
  'east':    8.750199,
  'south':   54.2888531,
  'west':    8.6345283
}, {'zipCode': '25882', 'north': 54.4100489, 'east': 8.8896338, 'south': 54.3426559, 'west': 8.7820803}, {
  'zipCode': '25884',
  'north':   54.6287446,
  'east':    9.267477300000001,
  'south':   54.5431665,
  'west':    9.0935906
}, {'zipCode': '25885', 'north': 54.5605481, 'east': 9.3120296, 'south': 54.4774335, 'west': 9.1297724}, {
  'zipCode': '25887',
  'north':   54.4482521,
  'east':    9.2902646,
  'south':   54.41246220000001,
  'west':    9.182712900000002
}, {'zipCode': '25889', 'north': 54.435591, 'east': 9.0656382, 'south': 54.3758943, 'west': 8.9070156}, {
  'zipCode': '25899',
  'north':   54.8412182,
  'east':    8.9433636,
  'south':   54.681923,
  'west':    8.6815635
}, {'zipCode': '25917', 'north': 54.8263389, 'east': 9.119958100000002, 'south': 54.6941159, 'west': 8.909533}, {
  'zipCode': '25920',
  'north':   54.7956519,
  'east':    8.9390889,
  'south':   54.7136121,
  'west':    8.7917682
}, {'zipCode': '25923', 'north': 54.9049474, 'east': 8.9730935, 'south': 54.815484, 'west': 8.7948138}, {
  'zipCode': '25924',
  'north':   54.9115263,
  'east':    8.7652437,
  'south':   54.79317350000001,
  'west':    8.55086
}, {'zipCode': '25926', 'north': 54.8967419, 'east': 9.1176282, 'south': 54.7959619, 'west': 8.9509796}, {
  'zipCode': '25927',
  'north':   54.9068209,
  'east':    8.8493896,
  'south':   54.8390229,
  'west':    8.6903036
}, {'zipCode': '25938', 'north': 54.7554976, 'east': 8.5968159, 'south': 54.6791607, 'west': 8.3949203}, {
  'zipCode': '25946',
  'north':   54.7118684,
  'east':    8.4033568,
  'south':   54.6086618,
  'west':    8.295714
}, {'zipCode': '25980', 'north': 54.9311472, 'east': 8.5510399, 'south': 54.7957115, 'west': 8.2804482}, {
  'zipCode': '25992',
  'north':   55.0586834,
  'east':    8.4649989,
  'south':   54.9887344,
  'west':    8.3522095
}, {'zipCode': '25996', 'north': 54.949693, 'east': 8.3634822, 'south': 54.9251012, 'west': 8.308909}, {
  'zipCode': '25997',
  'north':   54.7960159,
  'east':    8.3037232,
  'south':   54.7400938,
  'west':    8.2770727
}, {'zipCode': '25999', 'north': 54.9920578, 'east': 8.3706813, 'south': 54.9420828, 'west': 8.3211321}, {
  'zipCode': '26121',
  'north':   53.1649519,
  'east':    8.2242068,
  'south':   53.1412126,
  'west':    8.1916776
}, {'zipCode': '26122', 'north': 53.1454867, 'east': 8.243294, 'south': 53.1276155, 'west': 8.1902753}, {
  'zipCode': '26123',
  'north':   53.1639307,
  'east':    8.251589,
  'south':   53.14217210000001,
  'west':    8.2146359
}, {'zipCode': '26125', 'north': 53.2047617, 'east': 8.3122117, 'south': 53.1414271, 'west': 8.2012641}, {
  'zipCode': '26127',
  'north':   53.2010709,
  'east':    8.2288863,
  'south':   53.1529487,
  'west':    8.1662246
}, {'zipCode': '26129', 'north': 53.17997280000001, 'east': 8.1940165, 'south': 53.126026, 'west': 8.1439042}, {
  'zipCode': '26131',
  'north':   53.14154340000001,
  'east':    8.2138346,
  'south':   53.1077203,
  'west':    8.1295153
}, {'zipCode': '26133', 'north': 53.1266226, 'east': 8.2438648, 'south': 53.08312370000001, 'west': 8.1942514}, {
  'zipCode': '26135',
  'north':   53.1537778,
  'east':    8.3068066,
  'south':   53.0884333,
  'west':    8.2086466
}, {'zipCode': '26160', 'north': 53.26471320000001, 'east': 8.1682559, 'south': 53.1308777, 'west': 7.8964432}, {
  'zipCode': '26169',
  'north':   53.1197661,
  'east':    7.961214400000001,
  'south':   52.9043429,
  'west':    7.703592300000001
}, {'zipCode': '26180', 'north': 53.354154, 'east': 8.3157319, 'south': 53.1889064, 'west': 8.1053706}, {
  'zipCode': '26188',
  'north':   53.1728545,
  'east':    8.1415775,
  'south':   53.0595317,
  'west':    7.8399758
}, {'zipCode': '26197', 'north': 53.0148079, 'east': 8.3405295, 'south': 52.8694617, 'west': 8.1143705}, {
  'zipCode': '26203',
  'north':   53.1141291,
  'east':    8.2589976,
  'south':   52.998989900000005,
  'west':    8.0510007
}, {'zipCode': '26209', 'north': 53.1072931, 'east': 8.433656, 'south': 52.9919588, 'west': 8.211070200000002}, {
  'zipCode': '26215',
  'north':   53.34358,
  'east':    8.2017272,
  'south':   53.1775219,
  'west':    8.0304477
}, {'zipCode': '26219', 'north': 53.085317, 'east': 8.1020608, 'south': 52.9642418, 'west': 7.8965444}, {
  'zipCode': '26316',
  'north':   53.4535008,
  'east':    8.2077524,
  'south':   53.3210618,
  'west':    8.0321992
}, {'zipCode': '26340', 'north': 53.47384220000001, 'east': 8.0722194, 'south': 53.3359713, 'west': 7.8585592}, {
  'zipCode': '26345',
  'north':   53.4668071,
  'east':    8.0996163,
  'south':   53.3045563,
  'west':    7.9295175
}, {'zipCode': '26349', 'north': 53.4542658, 'east': 8.3194748, 'south': 53.2844815, 'west': 8.1639845}, {
  'zipCode': '26382',
  'north':   53.5294715,
  'east':    8.1553921,
  'south':   53.506745,
  'west':    8.090858
}, {'zipCode': '26384', 'north': 53.5611573, 'east': 8.1710622, 'south': 53.514847, 'west': 8.113092}, {
  'zipCode': '26386',
  'north':   53.5776704,
  'east':    8.1539377,
  'south':   53.5283707,
  'west':    8.0810177
}, {'zipCode': '26388', 'north': 53.6382914, 'east': 8.1556948, 'south': 53.5387819, 'west': 7.9935605999999995}, {
  'zipCode': '26389',
  'north':   53.562008000000006,
  'east':    8.1174216,
  'south':   53.5021006,
  'west':    8.030647100000001
}, {'zipCode': '26409', 'north': 53.7112332, 'east': 7.860233400000001, 'south': 53.4848488, 'west': 7.6444474}, {
  'zipCode': '26419',
  'north':   53.60111,
  'east':    8.0498152,
  'south':   53.5040041,
  'west':    7.8305284
}, {'zipCode': '26427', 'north': 53.7041377, 'east': 7.774400700000001, 'south': 53.5559129, 'west': 7.4884336}, {
  'zipCode': '26434',
  'north':   53.7153933,
  'east':    8.0899875,
  'south':   53.5785967,
  'west':    7.812939700000001
}, {'zipCode': '26441', 'north': 53.6028361, 'east': 7.9529595, 'south': 53.5009369, 'west': 7.8152149}, {
  'zipCode': '26446',
  'north':   53.5167371,
  'east':    7.9753375,
  'south':   53.3621432,
  'west':    7.677077700000001
}, {'zipCode': '26452', 'north': 53.5257114, 'east': 8.067212600000001, 'south': 53.4610314, 'west': 7.9284914}, {
  'zipCode': '26465',
  'north':   53.7592483,
  'east':    7.6311577,
  'south':   53.7201706,
  'west':    7.462033400000001
}, {'zipCode': '26474', 'north': 53.7823239, 'east': 7.8172377, 'south': 53.7496925, 'west': 7.6659842000000005}, {
  'zipCode': '26486',
  'north':   53.794247,
  'east':    7.9696671,
  'south':   53.7738114,
  'west':    7.8458605
}, {'zipCode': '26487', 'north': 53.594264900000006, 'east': 7.5912326000000006, 'south': 53.5487563, 'west': 7.476914900000001}, {
  'zipCode': '26489',
  'north':   53.628701,
  'east':    7.5644525,
  'south':   53.5898916,
  'west':    7.4939875
}, {'zipCode': '26506', 'north': 53.6689728, 'east': 7.2762, 'south': 53.4968065, 'west': 7.0869637}, {
  'zipCode': '26524',
  'north':   53.6845384,
  'east':    7.3443622,
  'south':   53.5566181,
  'west':    7.224672100000001
}, {'zipCode': '26529', 'north': 53.5639252, 'east': 7.350707200000001, 'south': 53.4488649, 'west': 7.1823568}, {
  'zipCode': '26532',
  'north':   53.6408678,
  'east':    7.4433125,
  'south':   53.5025078,
  'west':    7.301052200000001
}, {'zipCode': '26548', 'north': 53.7279763, 'east': 7.348706, 'south': 53.69677, 'west': 7.1371187}, {
  'zipCode': '26553',
  'north':   53.6931228,
  'east':    7.515934200000001,
  'south':   53.6192286,
  'west':    7.313985200000001
}, {'zipCode': '26556', 'north': 53.627115, 'east': 7.5084216, 'south': 53.5328576, 'west': 7.4080923}, {
  'zipCode': '26571',
  'north':   53.68875090000001,
  'east':    7.1050957,
  'south':   53.6241681,
  'west':    6.8637677
}, {'zipCode': '26579', 'north': 53.7374356, 'east': 7.4406231, 'south': 53.7152279, 'west': 7.358959700000001}, {
  'zipCode': '26603',
  'north':   53.4868448,
  'east':    7.5092813,
  'south':   53.4551377,
  'west':    7.458159299999999
}, {'zipCode': '26605', 'north': 53.5029573, 'east': 7.6791409, 'south': 53.4054472, 'west': 7.401850800000001}, {
  'zipCode': '26607',
  'north':   53.5617576,
  'east':    7.6781761,
  'south':   53.4642702,
  'west':    7.4059098
}, {'zipCode': '26624', 'north': 53.5378683, 'east': 7.4200591000000005, 'south': 53.3965752, 'west': 7.240749400000001}, {
  'zipCode': '26629',
  'north':   53.4620641,
  'east':    7.73042,
  'south':   53.33228030000001,
  'west':    7.4863719
}, {'zipCode': '26632', 'north': 53.4676075, 'east': 7.5424912, 'south': 53.34822230000001, 'west': 7.2788631}, {
  'zipCode': '26639',
  'north':   53.472414400000005,
  'east':    7.8158155,
  'south':   53.3566276,
  'west':    7.6446691
}, {'zipCode': '26655', 'north': 53.3518989, 'east': 8.0605045, 'south': 53.1652699, 'west': 7.795003300000001}, {
  'zipCode': '26670',
  'north':   53.3760008,
  'east':    7.8854291,
  'south':   53.2321206,
  'west':    7.6659626
}, {'zipCode': '26676', 'north': 53.1818325, 'east': 7.884787500000001, 'south': 53.0844563, 'west': 7.6670072}, {
  'zipCode': '26683',
  'north':   53.185697000000005,
  'east':    7.7835178,
  'south':   53.0194733,
  'west':    7.612126600000001
}, {'zipCode': '26689', 'north': 53.26192290000001, 'east': 7.8740499999999995, 'south': 53.1590445, 'west': 7.6991095}, {
  'zipCode': '26721',
  'north':   53.4022421,
  'east':    7.2354059,
  'south':   53.36041960000001,
  'west':    7.1793513
}, {'zipCode': '26723', 'north': 53.3974304, 'east': 7.204195900000001, 'south': 53.3341985, 'west': 6.9996421}, {
  'zipCode': '26725',
  'north':   53.4085445,
  'east':    7.312423600000001,
  'south':   53.3202047,
  'west':    7.1953757
}, {'zipCode': '26736', 'north': 53.5460669, 'east': 7.211997700000001, 'south': 53.35210420000001, 'west': 7.010834}, {
  'zipCode': '26757',
  'north':   53.6201112,
  'east':    6.81349,
  'south':   53.556954,
  'west':    6.6543055
}, {'zipCode': '26759', 'north': 53.4608328, 'east': 7.2857144, 'south': 53.3854039, 'west': 7.1333828}, {
  'zipCode': '26789',
  'north':   53.29229,
  'east':    7.543151299999999,
  'south':   53.2029408,
  'west':    7.366894800000001
}, {'zipCode': '26802', 'north': 53.3757302, 'east': 7.559939599999999, 'south': 53.2725481, 'west': 7.2988759000000005}, {
  'zipCode': '26810',
  'north':   53.21430850000001,
  'east':    7.515702900000001,
  'south':   53.0769025,
  'west':    7.362713300000001
}, {'zipCode': '26817', 'north': 53.21682150000001, 'east': 7.606684400000001, 'south': 53.0339222, 'west': 7.4946436}, {
  'zipCode': '26826',
  'north':   53.2304797,
  'east':    7.411480500000001,
  'south':   53.1009186,
  'west':    7.2467527
}, {'zipCode': '26831', 'north': 53.2883137, 'east': 7.320155099999999, 'south': 53.1128612, 'west': 7.1789092}, {
  'zipCode': '26835',
  'north':   53.3570447,
  'east':    7.7045409,
  'south':   53.2473987,
  'west':    7.5251228
}, {'zipCode': '26842', 'north': 53.2144963, 'east': 7.6691, 'south': 53.0577103, 'west': 7.574596500000001}, {
  'zipCode': '26844',
  'north':   53.3256348,
  'east':    7.4074252000000005,
  'south':   53.2211199,
  'west':    7.2285516
}, {'zipCode': '26845', 'north': 53.2633053, 'east': 7.5979144, 'south': 53.222999200000004, 'west': 7.5278135}, {
  'zipCode': '26847',
  'north':   53.2379656,
  'east':    7.7346501,
  'south':   53.17737,
  'west':    7.510921200000001
}, {'zipCode': '26849', 'north': 53.2816287, 'east': 7.692074100000001, 'south': 53.22212, 'west': 7.5910126}, {
  'zipCode': '26871',
  'north':   53.1127081,
  'east':    7.524658500000001,
  'south':   53.0060005,
  'west':    7.2910168
}, {'zipCode': '26892', 'north': 53.0315337, 'east': 7.441499500000001, 'south': 52.8864461, 'west': 7.2014146}, {
  'zipCode': '26897',
  'north':   53.0592143,
  'east':    7.7231218,
  'south':   52.9352877,
  'west':    7.5326689
}, {'zipCode': '26899', 'north': 53.1172659, 'east': 7.3165564000000005, 'south': 53.0069245, 'west': 7.1992194000000005}, {
  'zipCode': '26901',
  'north':   52.9874851,
  'east':    7.752782700000001,
  'south':   52.8821734,
  'west':    7.5759326
}, {'zipCode': '26903', 'north': 53.043187, 'east': 7.586245400000001, 'south': 52.9506953, 'west': 7.437752}, {
  'zipCode': '26904',
  'north':   52.9599561,
  'east':    7.601441600000001,
  'south':   52.8870009,
  'west':    7.439161399999999
}, {'zipCode': '26906', 'north': 52.9833866, 'east': 7.301836500000001, 'south': 52.941476400000006, 'west': 7.1823606}, {
  'zipCode': '26907',
  'north':   52.9473386,
  'east':    7.3020467,
  'south':   52.9119724,
  'west':    7.1530713
}, {'zipCode': '26909', 'north': 53.0159018, 'east': 7.4769212000000005, 'south': 52.9270246, 'west': 7.373194000000001}, {
  'zipCode': '26919',
  'north':   53.3766215,
  'east':    8.5014239,
  'south':   53.2850503,
  'west':    8.4204512
}, {'zipCode': '26931', 'north': 53.2938576, 'east': 8.489851000000002, 'south': 53.15278, 'west': 8.2929425}, {
  'zipCode': '26935',
  'north':   53.4560135,
  'east':    8.5003768,
  'south':   53.362828,
  'west':    8.390497
}, {'zipCode': '26936', 'north': 53.43528280000001, 'east': 8.4103707, 'south': 53.3634918, 'west': 8.3060213}, {
  'zipCode': '26937',
  'north':   53.5003162,
  'east':    8.4104388,
  'south':   53.4245618,
  'west':    8.308758
}, {'zipCode': '26939', 'north': 53.3921468, 'east': 8.4509535, 'south': 53.2334801, 'west': 8.26946}, {
  'zipCode': '26954',
  'north':   53.5762723,
  'east':    8.5555796,
  'south':   53.434022,
  'west':    8.3625814
}, {'zipCode': '26969', 'north': 53.6189483, 'east': 8.476608, 'south': 53.4761764, 'west': 8.2256168}, {
  'zipCode': '27211',
  'north':   52.9623433,
  'east':    8.8883728,
  'south':   52.7712796,
  'west':    8.6276781
}, {'zipCode': '27232', 'north': 52.7299713, 'east': 8.8905424, 'south': 52.6170455, 'west': 8.65793}, {
  'zipCode': '27239',
  'north':   52.8510087,
  'east':    8.7031535,
  'south':   52.7283533,
  'west':    8.4499204
}, {'zipCode': '27243', 'north': 53.01878730000001, 'east': 8.6939374, 'south': 52.7977644, 'west': 8.4567964}, {
  'zipCode': '27245',
  'north':   52.6457612,
  'east':    8.9006673,
  'south':   52.5305459,
  'west':    8.7332633
}, {'zipCode': '27246', 'north': 52.69550110000001, 'east': 9.0199936, 'south': 52.6308312, 'west': 8.9087534}, {
  'zipCode': '27248',
  'north':   52.7771645,
  'east':    8.7490771,
  'south':   52.6842047,
  'west':    8.6295191
}, {'zipCode': '27249', 'north': 52.7484792, 'east': 8.9492872, 'south': 52.62951650000001, 'west': 8.8470501}, {
  'zipCode': '27251',
  'north':   52.790164,
  'east':    8.8057511,
  'south':   52.7099969,
  'west':    8.6822879
}, {'zipCode': '27252', 'north': 52.7752174, 'east': 8.8926769, 'south': 52.7134657, 'west': 8.7769721}, {
  'zipCode': '27254',
  'north':   52.7396427,
  'east':    9.0299522,
  'south':   52.6771813,
  'west':    8.9131122
}, {'zipCode': '27257', 'north': 52.8314096, 'east': 8.8974262, 'south': 52.754237, 'west': 8.8023434}, {
  'zipCode': '27259',
  'north':   52.686450900000004,
  'east':    8.7828019,
  'south':   52.5553389,
  'west':    8.616475
}, {'zipCode': '27283', 'north': 53.0038473, 'east': 9.3145853, 'south': 52.877245, 'west': 9.159976}, {
  'zipCode': '27299',
  'north':   53.0425732,
  'east':    9.2908668,
  'south':   52.9624786,
  'west':    9.0736824
}, {'zipCode': '27305', 'north': 52.8978039, 'east': 9.0678698, 'south': 52.7469462, 'west': 8.87761}, {
  'zipCode': '27308',
  'north':   53.0278817,
  'east':    9.4639361,
  'south':   52.8213869,
  'west':    9.2676736
}, {'zipCode': '27313', 'north': 52.9073655, 'east': 9.3605851, 'south': 52.7672589, 'west': 9.1800131}, {
  'zipCode': '27318',
  'north':   52.9096699,
  'east':    9.2206627,
  'south':   52.7847936,
  'west':    9.0342856
}, {'zipCode': '27321', 'north': 53.0093393, 'east': 9.124846, 'south': 52.8887027, 'west': 8.9288637}, {
  'zipCode': '27324',
  'north':   52.822301,
  'east':    9.3259138,
  'south':   52.7398265,
  'west':    9.162102200000001
}, {'zipCode': '27327', 'north': 52.9269505, 'east': 9.1161922, 'south': 52.8409457, 'west': 8.9811454}, {
  'zipCode': '27330',
  'north':   52.8067202,
  'east':    9.0641751,
  'south':   52.716131,
  'west':    8.8949256
}, {'zipCode': '27333', 'north': 52.8062193, 'east': 9.1998173, 'south': 52.7189029, 'west': 9.0377672}, {
  'zipCode': '27336',
  'north':   52.83348670000001,
  'east':    9.4847003,
  'south':   52.7147445,
  'west':    9.3168828
}, {'zipCode': '27337', 'north': 52.9734728, 'east': 9.1888954, 'south': 52.893297800000006, 'west': 9.0729518}, {
  'zipCode': '27339',
  'north':   53.0094714,
  'east':    8.9749009,
  'south':   52.9273401,
  'west':    8.9037126
}, {'zipCode': '27356', 'north': 53.1920968, 'east': 9.4708988, 'south': 53.0518174, 'west': 9.271203400000001}, {
  'zipCode': '27367',
  'north':   53.2132724,
  'east':    9.3638882,
  'south':   53.0126009,
  'west':    9.1512313
}, {'zipCode': '27374', 'north': 53.0636059, 'east': 9.6802523, 'south': 52.9171371, 'west': 9.437247}, {
  'zipCode': '27383',
  'north':   53.2466724,
  'east':    9.6513373,
  'south':   53.1082424,
  'west':    9.3604066
}, {'zipCode': '27386', 'north': 53.1171981, 'east': 9.6461377, 'south': 52.9822949, 'west': 9.3215145}, {
  'zipCode': '27389',
  'north':   53.2589854,
  'east':    9.7581484,
  'south':   53.1344226,
  'west':    9.451788900000002
}, {'zipCode': '27404', 'north': 53.3875898, 'east': 9.4463148, 'south': 53.1756157, 'west': 9.076587}, {
  'zipCode': '27412',
  'north':   53.3361016,
  'east':    9.2542279,
  'south':   53.1441053,
  'west':    8.9896303
}, {'zipCode': '27419', 'north': 53.3860296, 'east': 9.6040729, 'south': 53.2321723, 'west': 9.4103848}, {
  'zipCode': '27432',
  'north':   53.5936218,
  'east':    9.2830491,
  'south':   53.4074166,
  'west':    8.8950141
}, {'zipCode': '27442', 'north': 53.4511242, 'east': 9.1208555, 'south': 53.3161976, 'west': 8.9019382}, {
  'zipCode': '27446',
  'north':   53.4718418,
  'east':    9.3758331,
  'south':   53.3291257,
  'west':    9.0648757
}, {'zipCode': '27449', 'north': 53.5368763, 'east': 9.41238, 'south': 53.4493988, 'west': 9.2257346}, {
  'zipCode': '27472',
  'north':   53.8796811,
  'east':    8.7481745,
  'south':   53.8329529,
  'west':    8.679547
}, {'zipCode': '27474', 'north': 53.8748465, 'east': 8.6975202, 'south': 53.8429747, 'west': 8.6555162}, {
  'zipCode': '27476',
  'north':   53.892285,
  'east':    8.6874269,
  'south':   53.8058755,
  'west':    8.554977
}, {'zipCode': '27478', 'north': 53.8488643, 'east': 8.863906, 'south': 53.7588756, 'west': 8.5737167}, {
  'zipCode': '27498',
  'north':   54.191714,
  'east':    7.921434100000001,
  'south':   54.1693099,
  'west':    7.8648435999999995
}, {'zipCode': '27499', 'north': 53.9646322, 'east': 8.5181118, 'south': 53.9124807, 'west': 8.4206247}, {
  'zipCode': '27568',
  'north':   53.59841870000001,
  'east':    8.5869626,
  'south':   53.5358039,
  'west':    8.523255
}, {'zipCode': '27570', 'north': 53.553827, 'east': 8.6195863, 'south': 53.5176441, 'west': 8.5745186}, {
  'zipCode': '27572',
  'north':   53.533372400000005,
  'east':    8.6298896,
  'south':   53.4711654,
  'west':    8.5006175
}, {'zipCode': '27574', 'north': 53.5560465, 'east': 8.652295, 'south': 53.50161, 'west': 8.5997079}, {
  'zipCode': '27576',
  'north':   53.5710424,
  'east':    8.6311099,
  'south':   53.5462168,
  'west':    8.5728983
}, {'zipCode': '27578', 'north': 53.6101863, 'east': 8.6591773, 'south': 53.5697211, 'west': 8.5909684}, {
  'zipCode': '27580',
  'north':   53.6070326,
  'east':    8.601008,
  'south':   53.5632064,
  'west':    8.5077507
}, {'zipCode': '27607', 'north': 53.7372646, 'east': 8.7618937, 'south': 53.5931016, 'west': 8.509586}, {
  'zipCode': '27612',
  'north':   53.515892,
  'east':    8.7844488,
  'south':   53.3942075,
  'west':    8.4978753
}, {'zipCode': '27616', 'north': 53.52149, 'east': 8.9732003, 'south': 53.3725264, 'west': 8.6704739}, {
  'zipCode': '27619',
  'north':   53.6215984,
  'east':    8.8923549,
  'south':   53.483543,
  'west':    8.6194177
}, {'zipCode': '27624', 'north': 53.6918478, 'east': 8.9956248, 'south': 53.5196261, 'west': 8.6834285}, {
  'zipCode': '27628',
  'north':   53.4365433,
  'east':    8.7762682,
  'south':   53.281136200000006,
  'west':    8.5027117
}, {'zipCode': '27639', 'north': 53.8211053, 'east': 8.7135961, 'south': 53.6180955, 'west': 8.4828961}, {
  'zipCode': '27711',
  'north':   53.3268615,
  'east':    8.9503817,
  'south':   53.1831181,
  'west':    8.6598246
}, {'zipCode': '27721', 'north': 53.223934, 'east': 8.8078154, 'south': 53.1581967, 'west': 8.6622587}, {
  'zipCode': '27726',
  'north':   53.3376821,
  'east':    9.0294421,
  'south':   53.1851247,
  'west':    8.8240002
}, {'zipCode': '27729', 'north': 53.3956392, 'east': 8.9654677, 'south': 53.2657915, 'west': 8.7355624}, {
  'zipCode': '27749',
  'north':   53.0622239,
  'east':    8.6574357,
  'south':   53.0359074,
  'west':    8.6244672
}, {'zipCode': '27751', 'north': 53.1091048, 'east': 8.7115987, 'south': 53.0266036, 'west': 8.6226181}, {
  'zipCode': '27753',
  'north':   53.0790759,
  'east':    8.6445746,
  'south':   53.0344654,
  'west':    8.5811529
}, {'zipCode': '27755', 'north': 53.0444348, 'east': 8.6816704, 'south': 52.9867419, 'west': 8.5786556}, {
  'zipCode': '27777',
  'north':   53.1277634,
  'east':    8.635505,
  'south':   52.9648749,
  'west':    8.4251587
}, {'zipCode': '27793', 'north': 52.9409791, 'east': 8.4820209, 'south': 52.8202028, 'west': 8.3065568}, {
  'zipCode': '27798',
  'north':   53.171891,
  'east':    8.5205726,
  'south':   53.0557168,
  'west':    8.2795751
}, {'zipCode': '27801', 'north': 53.02333370000001, 'east': 8.5171358, 'south': 52.903963, 'west': 8.3142493}, {
  'zipCode': '27804',
  'north':   53.22732460000001,
  'east':    8.5785606,
  'south':   53.118996300000006,
  'west':    8.3861774
}, {'zipCode': '27809', 'north': 53.1759161, 'east': 8.6572755, 'south': 53.0989929, 'west': 8.5354941}, {
  'zipCode': '28195',
  'north':   53.091402,
  'east':    8.8201562,
  'south':   53.0698067,
  'west':    8.788809000000002
}, {'zipCode': '28197', 'north': 53.12852550000001, 'east': 8.788809000000002, 'south': 53.0670337, 'west': 8.6446143}, {
  'zipCode': '28199',
  'north':   53.08034750000001,
  'east':    8.8161807,
  'south':   53.0363197,
  'west':    8.7641005
}, {'zipCode': '28201', 'north': 53.0692269, 'east': 8.8411159, 'south': 53.0482163, 'west': 8.7880596}, {
  'zipCode': '28203',
  'north':   53.0814001,
  'east':    8.8379428,
  'south':   53.0675869,
  'west':    8.8132286
}, {'zipCode': '28205', 'north': 53.0781956, 'east': 8.856980000000002, 'south': 53.0592819, 'west': 8.8309787}, {
  'zipCode': '28207',
  'north':   53.0757862,
  'east':    8.883310900000001,
  'south':   53.0571634,
  'west':    8.849881
}, {'zipCode': '28209', 'north': 53.0954126, 'east': 8.8500347, 'south': 53.0797548, 'west': 8.8135847}, {
  'zipCode': '28211',
  'north':   53.0913163,
  'east':    8.874599,
  'south':   53.0738817,
  'west':    8.8275104
}, {'zipCode': '28213', 'north': 53.1107624, 'east': 8.8672259, 'south': 53.0851494, 'west': 8.8222068}, {
  'zipCode': '28215',
  'north':   53.1033334,
  'east':    8.8269835,
  'south':   53.0827313,
  'west':    8.7959697
}, {'zipCode': '28217', 'north': 53.1121226, 'east': 8.8005045, 'south': 53.08034750000001, 'west': 8.7462294}, {
  'zipCode': '28219',
  'north':   53.12889380000001,
  'east':    8.8255408,
  'south':   53.0945471,
  'west':    8.7660719
}, {'zipCode': '28237', 'north': 53.1526657, 'east': 8.7923719, 'south': 53.105816, 'west': 8.6555253}, {
  'zipCode': '28239',
  'north':   53.1500202,
  'east':    8.7680479,
  'south':   53.11611,
  'west':    8.7154057
}, {'zipCode': '28259', 'north': 53.0748866, 'east': 8.7705645, 'south': 53.0335938, 'west': 8.7068223}, {
  'zipCode': '28277',
  'north':   53.0519578,
  'east':    8.8437118,
  'south':   53.0237993,
  'west':    8.791799600000001
}, {'zipCode': '28279', 'north': 53.0637879, 'east': 8.8750153, 'south': 53.0165436, 'west': 8.817401}, {
  'zipCode': '28307',
  'north':   53.0595948,
  'east':    8.9803461,
  'south':   53.0110367,
  'west':    8.8957282
}, {'zipCode': '28309', 'north': 53.07073030000001, 'east': 8.9201678, 'south': 53.0130904, 'west': 8.865269}, {
  'zipCode': '28325',
  'north':   53.0837223,
  'east':    8.967078,
  'south':   53.0573605,
  'west':    8.930500800000003
}, {'zipCode': '28327', 'north': 53.082540400000006, 'east': 8.9366593, 'south': 53.0589972, 'west': 8.8912317}, {
  'zipCode': '28329',
  'north':   53.0913236,
  'east':    8.9168808,
  'south':   53.0656433,
  'west':    8.8540943
}, {'zipCode': '28355', 'north': 53.12202870000001, 'east': 8.9907318, 'south': 53.0780893, 'west': 8.89583}, {
  'zipCode': '28357',
  'north':   53.1642929,
  'east':    8.9832949,
  'south':   53.0947182,
  'west':    8.7753648
}, {'zipCode': '28359', 'north': 53.1163653, 'east': 8.9049115, 'south': 53.0882641, 'west': 8.8201502}, {
  'zipCode': '28717',
  'north':   53.1854383,
  'east':    8.7159532,
  'south':   53.1618253,
  'west':    8.6658508
}, {'zipCode': '28719', 'north': 53.1832662, 'east': 8.7984122, 'south': 53.1250344, 'west': 8.6207466}, {
  'zipCode': '28755',
  'north':   53.198289,
  'east':    8.6429513,
  'south':   53.17093890000001,
  'west':    8.5808145
}, {'zipCode': '28757', 'north': 53.1893944, 'east': 8.6589576, 'south': 53.1661946, 'west': 8.6070906}, {
  'zipCode': '28759',
  'north':   53.1789438,
  'east':    8.6768142,
  'south':   53.1588049,
  'west':    8.6231282
}, {'zipCode': '28777', 'north': 53.2288943, 'east': 8.567586, 'south': 53.181351, 'west': 8.4815929}, {
  'zipCode': '28779',
  'north':   53.2174424,
  'east':    8.5992106,
  'south':   53.1727869,
  'west':    8.5539674
}, {'zipCode': '28790', 'north': 53.3526262, 'east': 8.7029873, 'south': 53.1764522, 'west': 8.4807116}, {
  'zipCode': '28816',
  'north':   53.0531493,
  'east':    8.8175447,
  'south':   52.93565,
  'west':    8.6430058
}, {'zipCode': '28832', 'north': 53.0566266, 'east': 9.1445973, 'south': 52.9800064, 'west': 8.9158254}, {
  'zipCode': '28844',
  'north':   53.0407028,
  'east':    8.9325702,
  'south':   52.9529478,
  'west':    8.7675426
}, {'zipCode': '28857', 'north': 52.9743149, 'east': 8.952076800000002, 'south': 52.8353657, 'west': 8.7314031}, {
  'zipCode': '28865',
  'north':   53.211648600000004,
  'east':    9.0040256,
  'south':   53.125737,
  'west':    8.7815607
}, {'zipCode': '28870', 'north': 53.1845229, 'east': 9.2046443, 'south': 53.0158211, 'west': 8.9472416}, {
  'zipCode': '28876',
  'north':   53.1045298,
  'east':    9.1510852,
  'south':   53.0359912,
  'west':    8.9609864
}, {'zipCode': '28879', 'north': 53.2422592, 'east': 9.071352, 'south': 53.1259641, 'west': 8.9557715}, {
  'zipCode': '29221',
  'north':   52.631308,
  'east':    10.108942,
  'south':   52.6059623,
  'west':    10.0586053
}, {'zipCode': '29223', 'north': 52.6672237, 'east': 10.1956569, 'south': 52.6111943, 'west': 9.9925746}, {
  'zipCode': '29225',
  'north':   52.6380477,
  'east':    10.0630986,
  'south':   52.5807051,
  'west':    9.9828941
}, {'zipCode': '29227', 'north': 52.6175224, 'east': 10.1888833, 'south': 52.5627418, 'west': 10.0518419}, {
  'zipCode': '29229',
  'north':   52.7283124,
  'east':    10.2128957,
  'south':   52.6342038,
  'west':    9.9949587
}, {'zipCode': '29303', 'north': 52.9053693, 'east': 10.1616367, 'south': 52.7067651, 'west': 9.8160576}, {
  'zipCode': '29308',
  'north':   52.753306,
  'east':    10.032854500000001,
  'south':   52.6403126,
  'west':    9.7259728
}, {'zipCode': '29313', 'north': 52.6724577, 'east': 10.018451, 'south': 52.5750999, 'west': 9.8689996}, {
  'zipCode': '29320',
  'north':   52.897267,
  'east':    10.2028524,
  'south':   52.7617168,
  'west':    9.991218900000002
}, {'zipCode': '29323', 'north': 52.6897463, 'east': 9.8878249, 'south': 52.5954939, 'west': 9.7265244}, {
  'zipCode': '29328',
  'north':   52.936604100000004,
  'east':    10.2811153,
  'south':   52.83355240000001,
  'west':    10.0584854
}, {'zipCode': '29331', 'north': 52.6672159, 'east': 10.3401785, 'south': 52.5806811, 'west': 10.1774664}, {
  'zipCode': '29336',
  'north':   52.5697222,
  'east':    10.1385925,
  'south':   52.5068312,
  'west':    10.0538275
}, {'zipCode': '29339', 'north': 52.5530672, 'east': 10.1728934, 'south': 52.4977113, 'west': 10.112698}, {
  'zipCode': '29342',
  'north':   52.6115701,
  'east':    10.3004422,
  'south':   52.5453907,
  'west':    10.1216283
}, {'zipCode': '29345', 'north': 52.88181380000001, 'east': 10.3848774, 'south': 52.7746769, 'west': 10.1716286}, {
  'zipCode': '29348',
  'north':   52.8221062,
  'east':    10.44435,
  'south':   52.6869959,
  'west':    10.129796
}, {'zipCode': '29351', 'north': 52.7273337, 'east': 10.4060832, 'south': 52.6207633, 'west': 10.2788906}, {
  'zipCode': '29352',
  'north':   52.5922987,
  'east':    10.096638500000001,
  'south':   52.5322452,
  'west':    9.965072
}, {'zipCode': '29353', 'north': 52.6312328, 'east': 10.3554011, 'south': 52.582656, 'west': 10.2418214}, {
  'zipCode': '29355',
  'north':   52.6727277,
  'east':    10.2976585,
  'south':   52.6314679,
  'west':    10.2107176
}, {'zipCode': '29356', 'north': 52.538634, 'east': 10.2598218, 'south': 52.4915806, 'west': 10.1672408}, {
  'zipCode': '29358',
  'north':   52.5709159,
  'east':    10.2612482,
  'south':   52.51661790000001,
  'west':    10.1372526
}, {'zipCode': '29359', 'north': 52.7219602, 'east': 10.253448, 'south': 52.6867413, 'west': 10.1716662}, {
  'zipCode': '29361',
  'north':   52.7027521,
  'east':    10.2877156,
  'south':   52.657148,
  'west':    10.1651677
}, {'zipCode': '29362', 'north': 52.6288648, 'east': 10.4171992, 'south': 52.5544012, 'west': 10.2938128}, {
  'zipCode': '29364',
  'north':   52.5738523,
  'east':    10.3816643,
  'south':   52.488285,
  'west':    10.246411
}, {'zipCode': '29365', 'north': 52.8341635, 'east': 10.5925107, 'south': 52.737275, 'west': 10.3951484}, {
  'zipCode': '29367',
  'north':   52.7599492,
  'east':    10.4824574,
  'south':   52.6581036,
  'west':    10.3633339
}, {'zipCode': '29369', 'north': 52.6312766, 'east': 10.499015, 'south': 52.5531549, 'west': 10.3810591}, {
  'zipCode': '29378',
  'north':   52.7966309,
  'east':    10.9025636,
  'south':   52.60248370000001,
  'west':    10.6548099
}, {'zipCode': '29379', 'north': 52.7030156, 'east': 10.770013, 'south': 52.6038322, 'west': 10.627413300000002}, {
  'zipCode': '29386',
  'north':   52.7960696,
  'east':    10.6701542,
  'south':   52.6426146,
  'west':    10.4724497
}, {'zipCode': '29389', 'north': 52.8867083, 'east': 10.7718178, 'south': 52.80217, 'west': 10.6013399}, {
  'zipCode': '29392',
  'north':   52.6164101,
  'east':    10.587131,
  'south':   52.5576172,
  'west':    10.4875324
}, {'zipCode': '29393', 'north': 52.6946839, 'east': 10.5315947, 'south': 52.600580900000004, 'west': 10.3798626}, {
  'zipCode': '29394',
  'north':   52.8439169,
  'east':    10.7619967,
  'south':   52.7643591,
  'west':    10.5705627
}, {'zipCode': '29396', 'north': 52.6551985, 'east': 10.7237347, 'south': 52.5984651, 'west': 10.6072442}, {
  'zipCode': '29399',
  'north':   52.6692537,
  'east':    10.724344,
  'south':   52.5584343,
  'west':    10.5114359
}, {'zipCode': '29410', 'north': 52.9134005, 'east': 11.3446031, 'south': 52.7603493, 'west': 10.9054895}, {
  'zipCode': '29413',
  'north':   52.856186,
  'east':    11.0689918,
  'south':   52.7015268,
  'west':    10.7552738
}, {'zipCode': '29416', 'north': 52.8094735, 'east': 11.161529, 'south': 52.7222504, 'west': 11.0207262}, {
  'zipCode': '29439',
  'north':   53.034494,
  'east':    11.2595608,
  'south':   52.9250307,
  'west':    11.0625549
}, {'zipCode': '29451', 'north': 53.15779, 'east': 11.171413400000002, 'south': 53.0400091, 'west': 11.0073181}, {
  'zipCode': '29456',
  'north':   53.2007989,
  'east':    11.0976932,
  'south':   53.112711,
  'west':    10.8995015
}, {'zipCode': '29459', 'north': 52.9994565, 'east': 11.0161896, 'south': 52.9042983, 'west': 10.8410806}, {
  'zipCode': '29462',
  'north':   52.9552145,
  'east':    11.1694393,
  'south':   52.8955143,
  'west':    11.0512703
}, {'zipCode': '29465', 'north': 52.93185820000001, 'east': 10.9299593, 'south': 52.845832, 'west': 10.8037191}, {
  'zipCode': '29468',
  'north':   52.9176004,
  'east':    10.9933064,
  'south':   52.8525264,
  'west':    10.902696
}, {'zipCode': '29471', 'north': 53.0649505, 'east': 11.5159414, 'south': 52.9758625, 'west': 11.3171272}, {
  'zipCode': '29472',
  'north':   53.1685327,
  'east':    11.231456,
  'south':   53.1185905,
  'west':    11.1205774
}, {'zipCode': '29473', 'north': 53.1603469, 'east': 11.0246988, 'south': 53.0765688, 'west': 10.7505714}, {
  'zipCode': '29475',
  'north':   53.0643174,
  'east':    11.4204188,
  'south':   53.0132492,
  'west':    11.3235213
}, {'zipCode': '29476', 'north': 53.1445694, 'east': 11.2502727, 'south': 53.0264251, 'west': 11.1438507}, {
  'zipCode': '29478',
  'north':   53.0784762,
  'east':    11.4778769,
  'south':   53.0324449,
  'west':    11.3833956
}, {'zipCode': '29479', 'north': 53.0706785, 'east': 11.1497095, 'south': 53.0211859, 'west': 10.9714807}, {
  'zipCode': '29481',
  'north':   53.123762,
  'east':    11.0475325,
  'south':   53.0586894,
  'west':    10.9172825
}, {'zipCode': '29482', 'north': 53.037694, 'east': 11.1177948, 'south': 52.9499607, 'west': 10.9682136}, {
  'zipCode': '29484',
  'north':   53.139921,
  'east':    11.3267524,
  'south':   53.0376648,
  'west':    11.2142972
}, {'zipCode': '29485', 'north': 52.9538617, 'east': 11.4392031, 'south': 52.87490410000001, 'west': 11.2306909}, {
  'zipCode': '29487',
  'north':   52.97164910000001,
  'east':    11.0816258,
  'south':   52.9093377,
  'west':    10.995601
}, {'zipCode': '29488', 'north': 52.9338208, 'east': 11.2573107, 'south': 52.8912433, 'west': 11.1558017}, {
  'zipCode': '29490',
  'north':   53.2499047,
  'east':    10.9716825,
  'south':   53.1795007,
  'west':    10.8588473
}, {'zipCode': '29491', 'north': 52.9852166, 'east': 11.5050657, 'south': 52.9242038, 'west': 11.3129924}, {
  'zipCode': '29493',
  'north':   53.0526293,
  'east':    11.598118,
  'south':   52.998491800000004,
  'west':    11.4898336
}, {'zipCode': '29494', 'north': 53.0621937, 'east': 11.3744544, 'south': 52.9478511, 'west': 11.1964028}, {
  'zipCode': '29496',
  'north':   53.0459172,
  'east':    11.0251767,
  'south':   52.9686829,
  'west':    10.8880635
}, {'zipCode': '29497', 'north': 52.9835019, 'east': 11.2999358, 'south': 52.9178002, 'west': 11.194647000000002}, {
  'zipCode': '29499',
  'north':   53.1027158,
  'east':    10.9927828,
  'south':   53.01774530000001,
  'west':    10.8337999
}, {'zipCode': '29525', 'north': 53.041361300000005, 'east': 10.6970508, 'south': 52.879155700000005, 'west': 10.4662676}, {
  'zipCode': '29549',
  'north':   53.109532800000004,
  'east':    10.6628794,
  'south':   53.0357065,
  'west':    10.4929316
}, {'zipCode': '29553', 'north': 53.2036559, 'east': 10.5882109, 'south': 53.0833975, 'west': 10.3813094}, {
  'zipCode': '29556',
  'north':   52.9457768,
  'east':    10.5174329,
  'south':   52.8049201,
  'west':    10.3132249
}, {'zipCode': '29559', 'north': 52.9459914, 'east': 10.7588317, 'south': 52.8091699, 'west': 10.440394300000001}, {
  'zipCode': '29562',
  'north':   52.9824436,
  'east':    10.873537900000002,
  'south':   52.8963003,
  'west':    10.6727997
}, {'zipCode': '29565', 'north': 53.0959327, 'east': 10.3422043, 'south': 52.931931, 'west': 10.1458469}, {
  'zipCode': '29571',
  'north':   53.0441666,
  'east':    10.8926344,
  'south':   52.9523654,
  'west':    10.7184213
}, {'zipCode': '29574', 'north': 53.0544145, 'east': 10.4897303, 'south': 53.0022769, 'west': 10.3298547}, {
  'zipCode': '29575',
  'north':   53.19154060000001,
  'east':    10.7001278,
  'south':   53.0975838,
  'west':    10.5489009
}, {'zipCode': '29576', 'north': 53.0703319, 'east': 10.5798881, 'south': 53.0158578, 'west': 10.4761717}, {
  'zipCode': '29578',
  'north':   52.997226,
  'east':    10.3828775,
  'south':   52.8538549,
  'west':    10.2441743
}, {'zipCode': '29579', 'north': 53.0511076, 'east': 10.6118277, 'south': 53.0103885, 'west': 10.5386814}, {
  'zipCode': '29581',
  'north':   52.99422030000001,
  'east':    10.4883675,
  'south':   52.9300054,
  'west':    10.3492094
}, {'zipCode': '29582', 'north': 53.1158032, 'east': 10.417008900000003, 'south': 53.0047853, 'west': 10.3119244}, {
  'zipCode': '29584',
  'north':   53.1452794,
  'east':    10.7957473,
  'south':   53.0661781,
  'west':    10.667429
}, {'zipCode': '29585', 'north': 53.123613, 'east': 10.5740011, 'south': 53.0842126, 'west': 10.487129500000002}, {
  'zipCode': '29587',
  'north':   53.094165,
  'east':    10.505906,
  'south':   53.0223898,
  'west':    10.4031792
}, {'zipCode': '29588', 'north': 53.0426805, 'east': 10.752739600000002, 'south': 52.9783763, 'west': 10.6408949}, {
  'zipCode': '29590',
  'north':   52.9912967,
  'east':    10.7062083,
  'south':   52.9642441,
  'west':    10.6406551
}, {'zipCode': '29591', 'north': 53.1308153, 'east': 10.694386, 'south': 53.0660569, 'west': 10.6150971}, {
  'zipCode': '29593',
  'north':   53.016897,
  'east':    10.4850024,
  'south':   52.9683098,
  'west':    10.3154551
}, {'zipCode': '29594', 'north': 52.8988316, 'east': 10.8414707, 'south': 52.841053, 'west': 10.7165495}, {
  'zipCode': '29597',
  'north':   53.0940915,
  'east':    10.8415684,
  'south':   53.0102385,
  'west':    10.7453014
}, {'zipCode': '29599', 'north': 53.0856234, 'east': 10.7631804, 'south': 53.0303077, 'west': 10.6322831}, {
  'zipCode': '29614',
  'north':   53.07904,
  'east':    10.0300888,
  'south':   52.9286575,
  'west':    9.660463
}, {'zipCode': '29633', 'north': 53.0589743, 'east': 10.2367474, 'south': 52.8975792, 'west': 9.963204}, {
  'zipCode': '29640',
  'north':   53.2194611,
  'east':    9.9379431,
  'south':   53.0323371,
  'west':    9.6252801
}, {'zipCode': '29643', 'north': 53.1149142, 'east': 9.7815579, 'south': 52.9878623, 'west': 9.6082188}, {
  'zipCode': '29646',
  'north':   53.1808746,
  'east':    10.1183592,
  'south':   53.0559996,
  'west':    9.8603816
}, {'zipCode': '29649', 'north': 52.9798252, 'east': 10.080222, 'south': 52.866986, 'west': 9.8562135}, {
  'zipCode': '29664',
  'north':   52.9512796,
  'east':    9.8323736,
  'south':   52.7252044,
  'west':    9.3754014
}, {'zipCode': '29683', 'north': 52.9572972, 'east': 9.9188744, 'south': 52.8084623, 'west': 9.6475133}, {
  'zipCode': '29690',
  'north':   52.7599283,
  'east':    9.7612173,
  'south':   52.59942680000001,
  'west':    9.4806572
}, {'zipCode': '29693', 'north': 52.8094994, 'east': 9.6766625, 'south': 52.7018174, 'west': 9.3984229}, {
  'zipCode': '29699',
  'north':   52.970421,
  'east':    9.7205935,
  'south':   52.8706452,
  'west':    9.5900792
}, {'zipCode': '30159', 'north': 52.3842965, 'east': 9.7615838, 'south': 52.3666835, 'west': 9.72202}, {
  'zipCode': '30161',
  'north':   52.3918069,
  'east':    9.7575026,
  'south':   52.3751323,
  'west':    9.7333564
}, {'zipCode': '30163', 'north': 52.4092185, 'east': 9.7612331, 'south': 52.3882941, 'west': 9.734764}, {
  'zipCode': '30165',
  'north':   52.416555,
  'east':    9.737545,
  'south':   52.3842965,
  'west':    9.6966518
}, {'zipCode': '30167', 'north': 52.397576, 'east': 9.7333564, 'south': 52.37279980000001, 'west': 9.6815401}, {
  'zipCode': '30169',
  'north':   52.3777162,
  'east':    9.7515358,
  'south':   52.3523933,
  'west':    9.7168977
}, {'zipCode': '30171', 'north': 52.3707375, 'east': 9.7654667, 'south': 52.3596193, 'west': 9.7440542}, {
  'zipCode': '30173',
  'north':   52.3730752,
  'east':    9.7836696,
  'south':   52.3429448,
  'west':    9.7414283
}, {'zipCode': '30175', 'north': 52.3897723, 'east': 9.7863303, 'south': 52.3697813, 'west': 9.7443481}, {
  'zipCode': '30177',
  'north':   52.4064085,
  'east':    9.7853284,
  'south':   52.382182500000006,
  'west':    9.7542782
}, {'zipCode': '30179', 'north': 52.4293604, 'east': 9.7746283, 'south': 52.404059700000005, 'west': 9.7118911}, {
  'zipCode': '30419',
  'north':   52.433665,
  'east':    9.721448,
  'south':   52.3832861,
  'west':    9.604447
}, {'zipCode': '30449', 'north': 52.37279980000001, 'east': 9.7255463, 'south': 52.3526096, 'west': 9.7021256}, {
  'zipCode': '30451',
  'north':   52.3791302,
  'east':    9.7168977,
  'south':   52.36887790000001,
  'west':    9.6958077
}, {'zipCode': '30453', 'north': 52.3983223, 'east': 9.7174804, 'south': 52.3462701, 'west': 9.644581}, {
  'zipCode': '30455',
  'north':   52.3772357,
  'east':    9.6992544,
  'south':   52.346463,
  'west':    9.6357493
}, {'zipCode': '30457', 'north': 52.3475772, 'east': 9.7176773, 'south': 52.3125742, 'west': 9.6705176}, {
  'zipCode': '30459',
  'north':   52.3615463,
  'east':    9.7468261,
  'south':   52.3287933,
  'west':    9.6955276
}, {'zipCode': '30519', 'north': 52.3600809, 'east': 9.8118159, 'south': 52.3181963, 'west': 9.7401532}, {
  'zipCode': '30521',
  'north':   52.3320415,
  'east':    9.8135502,
  'south':   52.3161615,
  'west':    9.7932329
}, {'zipCode': '30539', 'north': 52.354948, 'east': 9.8838118, 'south': 52.3051373, 'west': 9.8021067}, {
  'zipCode': '30559',
  'north':   52.3895462,
  'east':    9.8962621,
  'south':   52.3381821,
  'west':    9.7833534
}, {'zipCode': '30625', 'north': 52.3953468, 'east': 9.8303962, 'south': 52.3583117, 'west': 9.7776946}, {
  'zipCode': '30627',
  'north':   52.4000804,
  'east':    9.848782,
  'south':   52.3734245,
  'west':    9.8043758
}, {'zipCode': '30629', 'north': 52.4147985, 'east': 9.9184259, 'south': 52.3749687, 'west': 9.8210224}, {
  'zipCode': '30655',
  'north':   52.4093641,
  'east':    9.8316937,
  'south':   52.3897723,
  'west':    9.7789367
}, {'zipCode': '30657', 'north': 52.4543349, 'east': 9.8271835, 'south': 52.406074200000006, 'west': 9.762901000000001}, {
  'zipCode': '30659',
  'north':   52.4311519,
  'east':    9.8893813,
  'south':   52.405429,
  'west':    9.7900971
}, {'zipCode': '30669', 'north': 52.46806920000001, 'east': 9.6973622, 'south': 52.456732, 'west': 9.6768774}, {
  'zipCode': '30823',
  'north':   52.432961,
  'east':    9.621003000000002,
  'south':   52.397959,
  'west':    9.5248381
}, {'zipCode': '30826', 'north': 52.5012183, 'east': 9.6421602, 'south': 52.4172191, 'west': 9.484328}, {
  'zipCode': '30827',
  'north':   52.4634748,
  'east':    9.6453795,
  'south':   52.4236968,
  'west':    9.581566900000002
}, {'zipCode': '30851', 'north': 52.4405685, 'east': 9.7661855, 'south': 52.42284240000001, 'west': 9.7091776}, {
  'zipCode': '30853',
  'north':   52.4633801,
  'east':    9.7729996,
  'south':   52.430858400000005,
  'west':    9.7190938
}, {'zipCode': '30855', 'north': 52.506383, 'east': 9.7766661, 'south': 52.427974, 'west': 9.6288452}, {
  'zipCode': '30880',
  'north':   52.325963,
  'east':    9.9144861,
  'south':   52.2614451,
  'west':    9.763463900000001
}, {'zipCode': '30890', 'north': 52.4006094, 'east': 9.5683804, 'south': 52.260527, 'west': 9.3990321}, {
  'zipCode': '30900',
  'north':   52.6381967,
  'east':    9.8357462,
  'south':   52.4887204,
  'west':    9.5798177
}, {'zipCode': '30916', 'north': 52.5043333, 'east': 9.9322831, 'south': 52.4156632, 'west': 9.7695686}, {
  'zipCode': '30926',
  'north':   52.4227037,
  'east':    9.6654988,
  'south':   52.3449618,
  'west':    9.4870145
}, {'zipCode': '30938', 'north': 52.6079066, 'east': 9.9966889, 'south': 52.4618188, 'west': 9.7803188}, {
  'zipCode': '30952',
  'north':   52.3562012,
  'east':    9.7024946,
  'south':   52.2609232,
  'west':    9.6157405
}, {'zipCode': '30966', 'north': 52.336073, 'east': 9.7830168, 'south': 52.2618768, 'west': 9.6705937}, {
  'zipCode': '30974',
  'north':   52.2952064,
  'east':    9.6653276,
  'south':   52.2281785,
  'west':    9.47237
}, {'zipCode': '30982', 'north': 52.2981861, 'east': 9.8279284, 'south': 52.1699921, 'west': 9.6890812}, {
  'zipCode': '30989',
  'north':   52.3700116,
  'east':    9.640921100000002,
  'south':   52.2786744,
  'west':    9.5259607
}, {'zipCode': '31008', 'north': 52.1691998, 'east': 9.7628153, 'south': 52.0661666, 'west': 9.62811}, {
  'zipCode': '31020',
  'north':   52.1445891,
  'east':    9.6846474,
  'south':   51.9641579,
  'west':    9.521654
}, {'zipCode': '31028', 'north': 52.135173, 'east': 9.887714, 'south': 52.0195721, 'west': 9.7269903}, {
  'zipCode': '31036',
  'north':   52.1043098,
  'east':    9.7418773,
  'south':   52.0320232,
  'west':    9.6430217
}, {'zipCode': '31061', 'north': 52.036353, 'east': 9.9065011, 'south': 51.928459, 'west': 9.7391792}, {
  'zipCode': '31073',
  'north':   51.9829445,
  'east':    9.8950232,
  'south':   51.8835664,
  'west':    9.6885379
}, {'zipCode': '31079', 'north': 52.084133800000004, 'east': 9.97649, 'south': 51.963023, 'west': 9.8185318}, {
  'zipCode': '31084',
  'north':   51.96865,
  'east':    10.012721,
  'south':   51.8968787,
  'west':    9.8464963
}, {'zipCode': '31089', 'north': 52.0626714, 'east': 9.7721776, 'south': 51.95552030000001, 'west': 9.634151800000001}, {
  'zipCode': '31134',
  'north':   52.161028,
  'east':    9.9657042,
  'south':   52.12446520000001,
  'west':    9.9361571
}, {'zipCode': '31135', 'north': 52.1938772, 'east': 10.0428454, 'south': 52.1325638, 'west': 9.9521931}, {
  'zipCode': '31137',
  'north':   52.1926133,
  'east':    9.9636832,
  'south':   52.1454884,
  'west':    9.8918767
}, {'zipCode': '31139', 'north': 52.16029400000001, 'east': 9.9754373, 'south': 52.0935785, 'west': 9.8465506}, {
  'zipCode': '31141',
  'north':   52.1535604,
  'east':    10.0335877,
  'south':   52.1023609,
  'west':    9.9549278
}, {'zipCode': '31157', 'north': 52.27038340000001, 'east': 9.935415, 'south': 52.1963967, 'west': 9.7689107}, {
  'zipCode': '31162',
  'north':   52.1311544,
  'east':    10.0847958,
  'south':   52.0112929,
  'west':    9.9560566
}, {'zipCode': '31167', 'north': 52.0607407, 'east': 10.2061124, 'south': 51.9554135, 'west': 10.0166782}, {
  'zipCode': '31171',
  'north':   52.2142631,
  'east':    9.8638315,
  'south':   52.1183381,
  'west':    9.6858717
}, {'zipCode': '31174', 'north': 52.2368425, 'east': 10.1567224, 'south': 52.11025500000001, 'west': 10.0123632}, {
  'zipCode': '31177',
  'north':   52.2379592,
  'east':    10.0844874,
  'south':   52.1781588,
  'west':    9.9194183
}, {'zipCode': '31180', 'north': 52.2380519, 'east': 9.9479367, 'south': 52.1588678, 'west': 9.8452725}, {
  'zipCode': '31185',
  'north':   52.2347486,
  'east':    10.263966900000002,
  'south':   52.118176,
  'west':    10.1321732
}, {'zipCode': '31188', 'north': 52.1384312, 'east': 10.2025032, 'south': 52.0421625, 'west': 10.0655945}, {
  'zipCode': '31191',
  'north':   52.2968673,
  'east':    10.0345293,
  'south':   52.2291313,
  'west':    9.9125048
}, {'zipCode': '31195', 'north': 52.0265161, 'east': 10.0884093, 'south': 51.92736370000001, 'west': 9.8908076}, {
  'zipCode': '31199',
  'north':   52.1206237,
  'east':    10.0070943,
  'south':   52.0647524,
  'west':    9.8668634
}, {'zipCode': '31224', 'north': 52.356356, 'east': 10.3483778, 'south': 52.2877086, 'west': 10.2118698}, {
  'zipCode': '31226',
  'north':   52.348476100000006,
  'east':    10.3136247,
  'south':   52.2580972,
  'west':    10.1176828
}, {'zipCode': '31228', 'north': 52.3744919, 'east': 10.2817892, 'south': 52.3201546, 'west': 10.125631800000003}, {
  'zipCode': '31234',
  'north':   52.4488486,
  'east':    10.3888916,
  'south':   52.3541373,
  'west':    10.1296049
}, {'zipCode': '31241', 'north': 52.2993744, 'east': 10.2607559, 'south': 52.2453244, 'west': 10.1233989}, {
  'zipCode': '31246',
  'north':   52.2834609,
  'east':    10.3022479,
  'south':   52.2125905,
  'west':    10.1405965
}, {'zipCode': '31249', 'north': 52.3421007, 'east': 10.143657, 'south': 52.2222331, 'west': 9.995577}, {
  'zipCode': '31275',
  'north':   52.4314396,
  'east':    10.1563213,
  'south':   52.3220432,
  'west':    9.884847000000002
}, {'zipCode': '31303', 'north': 52.54047, 'east': 10.1026286, 'south': 52.4079312, 'west': 9.9197157}, {
  'zipCode': '31311',
  'north':   52.5256342,
  'east':    10.3032376,
  'south':   52.3939546,
  'west':    10.054819000000002
}, {'zipCode': '31319', 'north': 52.36994080000001, 'east': 10.082183, 'south': 52.2798985, 'west': 9.8613523}, {
  'zipCode': '31515',
  'north':   52.5001637,
  'east':    9.506150300000002,
  'south':   52.3625491,
  'west':    9.2727928
}, {'zipCode': '31535', 'north': 52.6769947, 'east': 9.6611885, 'south': 52.4387602, 'west': 9.238312}, {
  'zipCode': '31542',
  'north':   52.3883144,
  'east':    9.4384434,
  'south':   52.2943313,
  'west':    9.3156494
}, {'zipCode': '31547', 'north': 52.5419939, 'east': 9.2803056, 'south': 52.4080079, 'west': 9.09473}, {
  'zipCode': '31552',
  'north':   52.3335794,
  'east':    9.4087512,
  'south':   52.2643844,
  'west':    9.2425961
}, {'zipCode': '31553', 'north': 52.4185139, 'east': 9.3240784, 'south': 52.3686865, 'west': 9.205454}, {
  'zipCode': '31555',
  'north':   52.3789241,
  'east':    9.4223969,
  'south':   52.3478022,
  'west':    9.377715600000002
}, {'zipCode': '31556', 'north': 52.4403499, 'east': 9.2919875, 'south': 52.3941447, 'west': 9.1584304}, {
  'zipCode': '31558',
  'north':   52.4546135,
  'east':    9.3933386,
  'south':   52.4066551,
  'west':    9.2675926
}, {'zipCode': '31559', 'north': 52.4062545, 'east': 9.4266912, 'south': 52.3479266, 'west': 9.3242126}, {
  'zipCode': '31582',
  'north':   52.6887018,
  'east':    9.3095001,
  'south':   52.5896403,
  'west':    9.1629294
}, {'zipCode': '31592', 'north': 52.5610067, 'east': 9.1103215, 'south': 52.456963, 'west': 8.9392085}, {
  'zipCode': '31595',
  'north':   52.6505998,
  'east':    9.1063512,
  'south':   52.5275575,
  'west':    8.8703519
}, {'zipCode': '31600', 'north': 52.5885472, 'east': 8.9562947, 'south': 52.4598884, 'west': 8.768138}, {
  'zipCode': '31603',
  'north':   52.527511200000006,
  'east':    8.7946146,
  'south':   52.3877603,
  'west':    8.697479
}, {'zipCode': '31604', 'north': 52.4997423, 'east': 8.9988864, 'south': 52.4011331, 'west': 8.8914599}, {
  'zipCode': '31606',
  'north':   52.4984362,
  'east':    8.9255148,
  'south':   52.389492,
  'west':    8.7724793
}, {'zipCode': '31608', 'north': 52.690739300000004, 'east': 9.19897, 'south': 52.6320322, 'west': 9.0655828}, {
  'zipCode': '31609',
  'north':   52.741295900000004,
  'east':    9.226664,
  'south':   52.6791019,
  'west':    9.0927643
}, {'zipCode': '31613', 'north': 52.7358431, 'east': 9.1517747, 'south': 52.6583972, 'west': 9.0060581}, {
  'zipCode': '31618',
  'north':   52.6610738,
  'east':    9.1726431,
  'south':   52.5805545,
  'west':    9.0395099
}, {'zipCode': '31619', 'north': 52.6622543, 'east': 9.1677349, 'south': 52.606432, 'west': 9.0488047}, {
  'zipCode': '31621',
  'north':   52.6652717,
  'east':    9.0581586,
  'south':   52.6007017,
  'west':    8.9706081
}, {'zipCode': '31622', 'north': 52.7654751, 'east': 9.3850246, 'south': 52.6735986, 'west': 9.2461498}, {
  'zipCode': '31623',
  'north':   52.7170727,
  'east':    9.2599626,
  'south':   52.6673293,
  'west':    9.181273200000001
}, {'zipCode': '31626', 'north': 52.7686236, 'east': 9.2982641, 'south': 52.7186718, 'west': 9.1969299}, {
  'zipCode': '31627',
  'north':   52.7280755,
  'east':    9.2605602,
  'south':   52.6998936,
  'west':    9.2020098
}, {'zipCode': '31628', 'north': 52.5800799, 'east': 9.2496233, 'south': 52.5227174, 'west': 9.0869026}, {
  'zipCode': '31629',
  'north':   52.6108474,
  'east':    9.2075164,
  'south':   52.5704501,
  'west':    9.0900353
}, {'zipCode': '31632', 'north': 52.608746800000006, 'east': 9.3414983, 'south': 52.5356069, 'west': 9.1853401}, {
  'zipCode': '31633',
  'north':   52.5331636,
  'east':    9.1781255,
  'south':   52.4743879,
  'west':    9.0787826
}, {'zipCode': '31634', 'north': 52.7359932, 'east': 9.4672911, 'south': 52.6170048, 'west': 9.2821706}, {
  'zipCode': '31636',
  'north':   52.6158016,
  'east':    9.3736542,
  'south':   52.5551915,
  'west':    9.2712959
}, {'zipCode': '31637', 'north': 52.7283905, 'east': 9.5378303, 'south': 52.6228443, 'west': 9.4036523}, {
  'zipCode': '31638',
  'north':   52.661288,
  'east':    9.4254428,
  'south':   52.6015363,
  'west':    9.2589389
}, {'zipCode': '31655', 'north': 52.3507354, 'east': 9.2879218, 'south': 52.2599725, 'west': 9.1281476}, {
  'zipCode': '31675',
  'north':   52.3515636,
  'east':    9.1016301,
  'south':   52.2307262,
  'west':    8.9633174
}, {'zipCode': '31683', 'north': 52.2961177, 'east': 9.238200000000003, 'south': 52.2314355, 'west': 9.0865028}, {
  'zipCode': '31688',
  'north':   52.3136095,
  'east':    9.1939354,
  'south':   52.2783794,
  'west':    9.1349106
}, {'zipCode': '31691', 'north': 52.3282227, 'east': 9.1553038, 'south': 52.2849094, 'west': 9.0713458}, {
  'zipCode': '31693',
  'north':   52.3438214,
  'east':    9.1292322,
  'south':   52.3134897,
  'west':    9.0805813
}, {'zipCode': '31698', 'north': 52.3794711, 'east': 9.3298285, 'south': 52.3391518, 'west': 9.2634382}, {
  'zipCode': '31699',
  'north':   52.3707306,
  'east':    9.3317972,
  'south':   52.3174089,
  'west':    9.2823069
}, {'zipCode': '31700', 'north': 52.3495331, 'east': 9.3003115, 'south': 52.32399890000001, 'west': 9.2554816}, {
  'zipCode': '31702',
  'north':   52.384674600000004,
  'east':    9.2746272,
  'south':   52.3218163,
  'west':    9.2269114
}, {'zipCode': '31707', 'north': 52.2515064, 'east': 9.1217696, 'south': 52.2212013, 'west': 9.0790951}, {
  'zipCode': '31708',
  'north':   52.2661192,
  'east':    9.1205339,
  'south':   52.2452267,
  'west':    9.0802169
}, {'zipCode': '31710', 'north': 52.2342513, 'east': 9.1437854, 'south': 52.2202938, 'west': 9.1129715}, {
  'zipCode': '31711',
  'north':   52.2413434,
  'east':    9.1084164,
  'south':   52.2178096,
  'west':    9.062002
}, {'zipCode': '31712', 'north': 52.3831154, 'east': 9.176311800000002, 'south': 52.3410955, 'west': 9.1089733}, {
  'zipCode': '31714',
  'north':   52.3884249,
  'east':    9.240529700000002,
  'south':   52.3473537,
  'west':    9.1906573
}, {'zipCode': '31715', 'north': 52.3729757, 'east': 9.1710994, 'south': 52.3255078, 'west': 9.0749459}, {
  'zipCode': '31717',
  'north':   52.3766824,
  'east':    9.1980186,
  'south':   52.3392452,
  'west':    9.1550676
}, {'zipCode': '31718', 'north': 52.408314, 'east': 9.2240808, 'south': 52.3683356, 'west': 9.1397434}, {
  'zipCode': '31719',
  'north':   52.4119379,
  'east':    9.1611747,
  'south':   52.3696598,
  'west':    9.0953249
}, {'zipCode': '31737', 'north': 52.2259386, 'east': 9.247804, 'south': 52.0942523, 'west': 8.9858838}, {
  'zipCode': '31749',
  'north':   52.2797361,
  'east':    9.3305804,
  'south':   52.206888,
  'west':    9.1291283
}, {'zipCode': '31785', 'north': 52.1209646, 'east': 9.393589, 'south': 52.0898597, 'west': 9.3438825}, {
  'zipCode': '31787',
  'north':   52.1675652,
  'east':    9.4452647,
  'south':   52.0757392,
  'west':    9.2592036
}, {'zipCode': '31789', 'north': 52.1385059, 'east': 9.461703, 'south': 52.0429341, 'west': 9.3197091}, {
  'zipCode': '31812',
  'north':   52.0125859,
  'east':    9.3661207,
  'south':   51.9194298,
  'west':    9.1743503
}, {'zipCode': '31832', 'north': 52.2652028, 'east': 9.7535324, 'south': 52.1283627, 'west': 9.4640257}, {
  'zipCode': '31840',
  'north':   52.22105470000001,
  'east':    9.3874094,
  'south':   52.0940877,
  'west':    9.1559911
}, {'zipCode': '31848', 'north': 52.2741665, 'east': 9.5162802, 'south': 52.1313684, 'west': 9.3245525}, {
  'zipCode': '31855',
  'north':   52.1229236,
  'east':    9.337097,
  'south':   51.995727,
  'west':    9.1538839
}, {'zipCode': '31860', 'north': 52.0809745, 'east': 9.5391737, 'south': 51.9673956, 'west': 9.2902556}, {
  'zipCode': '31863',
  'north':   52.1674192,
  'east':    9.6031277,
  'south':   52.0365946,
  'west':    9.4357383
}, {'zipCode': '31867', 'north': 52.295261, 'east': 9.4490378, 'south': 52.2124285, 'west': 9.3089104}, {
  'zipCode': '31868',
  'north':   51.9803314,
  'east':    9.4578706,
  'south':   51.9214753,
  'west':    9.3409152
}, {'zipCode': '32049', 'north': 52.1699953, 'east': 8.7546512, 'south': 52.0960072, 'west': 8.6687986}, {
  'zipCode': '32051',
  'north':   52.1356264,
  'east':    8.6741778,
  'south':   52.0752451,
  'west':    8.5570489
}, {'zipCode': '32052', 'north': 52.1231042, 'east': 8.7213077, 'south': 52.0578613, 'west': 8.616513}, {
  'zipCode': '32105',
  'north':   52.1048314,
  'east':    8.7737909,
  'south':   52.0729151,
  'west':    8.7028205
}, {'zipCode': '32107', 'north': 52.0947081, 'east': 8.779183, 'south': 52.0198446, 'west': 8.6532347}, {
  'zipCode': '32108',
  'north':   52.1267779,
  'east':    8.8565711,
  'south':   52.0244765,
  'west':    8.7233636
}, {'zipCode': '32120', 'north': 52.1818748, 'east': 8.6785676, 'south': 52.1297027, 'west': 8.591022}, {
  'zipCode': '32130',
  'north':   52.1715845,
  'east':    8.6170596,
  'south':   52.1015083,
  'west':    8.4980724
}, {'zipCode': '32139', 'north': 52.1884182, 'east': 8.5383251, 'south': 52.1007806, 'west': 8.4037633}, {
  'zipCode': '32257',
  'north':   52.2586863,
  'east':    8.6357819,
  'south':   52.1633372,
  'west':    8.4750735
}, {'zipCode': '32278', 'north': 52.2608493, 'east': 8.6842203, 'south': 52.1731468, 'west': 8.5868222}, {
  'zipCode': '32289',
  'north':   52.2677003,
  'east':    8.563069,
  'south':   52.1943728,
  'west':    8.44228
}, {'zipCode': '32312', 'north': 52.343676, 'east': 8.7285976, 'south': 52.266213, 'west': 8.5327697}, {
  'zipCode': '32339',
  'north':   52.4157433,
  'east':    8.724212,
  'south':   52.3322585,
  'west':    8.5167838
}, {'zipCode': '32351', 'north': 52.5148327, 'east': 8.567804, 'south': 52.3533591, 'west': 8.2971102}, {
  'zipCode': '32361',
  'north':   52.3667966,
  'east':    8.5677366,
  'south':   52.2597126,
  'west':    8.4407342
}, {'zipCode': '32369', 'north': 52.5314923, 'east': 8.7182737, 'south': 52.3921725, 'west': 8.5187813}, {
  'zipCode': '32423',
  'north':   52.3285837,
  'east':    8.998314700000002,
  'south':   52.2612504,
  'west':    8.9017403
}, {'zipCode': '32425', 'north': 52.36487100000001, 'east': 8.9487096, 'south': 52.2952639, 'west': 8.8500884}, {
  'zipCode': '32427',
  'north':   52.3219484,
  'east':    8.9172769,
  'south':   52.2738668,
  'west':    8.8187398
}, {'zipCode': '32429', 'north': 52.2949686, 'east': 8.9132396, 'south': 52.2470645, 'west': 8.7920692}, {
  'zipCode': '32457',
  'north':   52.272366,
  'east':    9.0769856,
  'south':   52.1808053,
  'west':    8.8280789
}, {'zipCode': '32469', 'north': 52.5003491, 'east': 9.1386122, 'south': 52.3189706, 'west': 8.774708200000001}, {
  'zipCode': '32479',
  'north':   52.4008162,
  'east':    8.8661751,
  'south':   52.2627362,
  'west':    8.6934371
}, {'zipCode': '32545', 'north': 52.2134388, 'east': 8.8301166, 'south': 52.1624377, 'west': 8.7695108}, {
  'zipCode': '32547',
  'north':   52.2212806,
  'east':    8.8635637,
  'south':   52.1747338,
  'west':    8.8006852
}, {'zipCode': '32549', 'north': 52.2764885, 'east': 8.8720625, 'south': 52.2073583, 'west': 8.7206964}, {
  'zipCode': '32584',
  'north':   52.2518996,
  'east':    8.7888626,
  'south':   52.1497713,
  'west':    8.6636962
}, {'zipCode': '32602', 'north': 52.1969045, 'east': 8.926719, 'south': 52.1014906, 'west': 8.729156}, {
  'zipCode': '32609',
  'north':   52.298009,
  'east':    8.7472998,
  'south':   52.2319187,
  'west':    8.5540073
}, {'zipCode': '32657', 'north': 52.1043985, 'east': 8.9971731, 'south': 51.9758594, 'west': 8.8057572}, {
  'zipCode': '32676',
  'north':   51.9776921,
  'east':    9.3462093,
  'south':   51.8496402,
  'west':    9.185735200000002
}, {'zipCode': '32683', 'north': 52.0502271, 'east': 9.1973185, 'south': 51.96798, 'west': 9.0376357}, {
  'zipCode': '32689',
  'north':   52.1946418,
  'east':    9.037763,
  'south':   52.0602332,
  'west':    8.8640756
}, {'zipCode': '32694', 'north': 52.0761143, 'east': 9.107925000000002, 'south': 51.9978889, 'west': 8.9649413}, {
  'zipCode': '32699',
  'north':   52.1497015,
  'east':    9.194071,
  'south':   52.0372158,
  'west':    9.0194914
}, {'zipCode': '32756', 'north': 51.9575361, 'east': 8.9110833, 'south': 51.9160512, 'west': 8.8420667}, {
  'zipCode': '32758',
  'north':   51.9982701,
  'east':    8.983639,
  'south':   51.9150905,
  'west':    8.7630753
}, {'zipCode': '32760', 'north': 51.9538768, 'east': 8.9894431, 'south': 51.8587332, 'west': 8.7929786}, {
  'zipCode': '32791',
  'north':   52.0315615,
  'east':    8.8773515,
  'south':   51.9211187,
  'west':    8.7090165
}, {'zipCode': '32805', 'north': 51.926888100000006, 'east': 9.0931143, 'south': 51.78885410000001, 'west': 8.8766252}, {
  'zipCode': '32816',
  'north':   51.9540009,
  'east':    9.2389025,
  'south':   51.8521041,
  'west':    9.0853188
}, {'zipCode': '32825', 'north': 52.0117837, 'east': 9.1942624, 'south': 51.8984522, 'west': 8.9576326}, {
  'zipCode': '32832',
  'north':   51.9354323,
  'east':    8.8260251,
  'south':   51.8606796,
  'west':    8.6838248
}, {'zipCode': '32839', 'north': 51.9113082, 'east': 9.1769755, 'south': 51.7839606, 'west': 8.950279400000001}, {
  'zipCode': '33014',
  'north':   51.7973245,
  'east':    9.1173733,
  'south':   51.6438343,
  'west':    8.9642256
}, {'zipCode': '33034', 'north': 51.7979564, 'east': 9.286673, 'south': 51.6277831, 'west': 9.0630961}, {
  'zipCode': '33039',
  'north':   51.8594894,
  'east':    9.1930161,
  'south':   51.754561,
  'west':    8.9926889
}, {'zipCode': '33098', 'north': 51.7235128, 'east': 8.7824284, 'south': 51.7007111, 'west': 8.7195015}, {
  'zipCode': '33100',
  'north':   51.7646329,
  'east':    8.9005872,
  'south':   51.673205,
  'west':    8.7574386
}, {'zipCode': '33102', 'north': 51.7590459, 'east': 8.7825243, 'south': 51.7102985, 'west': 8.7196088}, {
  'zipCode': '33104',
  'north':   51.8008385,
  'east':    8.804222,
  'south':   51.72469050000001,
  'west':    8.6760922
}, {'zipCode': '33106', 'north': 51.78907130000001, 'east': 8.7705575, 'south': 51.6594787, 'west': 8.6362871}, {
  'zipCode': '33129',
  'north':   51.8437031,
  'east':    8.681012,
  'south':   51.7041967,
  'west':    8.402814
}, {'zipCode': '33142', 'north': 51.6317077, 'east': 8.7137545, 'south': 51.4662113, 'west': 8.4726639}, {
  'zipCode': '33154',
  'north':   51.72993230000001,
  'east':    8.7256445,
  'south':   51.6047759,
  'west':    8.4680736
}, {'zipCode': '33161', 'north': 51.8781387, 'east': 8.7656869, 'south': 51.7926148, 'west': 8.5706994}, {
  'zipCode': '33165',
  'north':   51.7008389,
  'east':    9.0173057,
  'south':   51.5290884,
  'west':    8.7378637
}, {'zipCode': '33175', 'north': 51.8332775, 'east': 8.9288565, 'south': 51.7513147, 'west': 8.7227674}, {
  'zipCode': '33178',
  'north':   51.6956668,
  'east':    8.8597897,
  'south':   51.6067426,
  'west':    8.6682577
}, {'zipCode': '33181', 'north': 51.6154334, 'east': 8.8465263, 'south': 51.4472162, 'west': 8.6251813}, {
  'zipCode': '33184',
  'north':   51.802994,
  'east':    9.0001686,
  'south':   51.6764619,
  'west':    8.874513
}, {'zipCode': '33189', 'north': 51.8824709, 'east': 8.9173674, 'south': 51.7980501, 'west': 8.7453216}, {
  'zipCode': '33330',
  'north':   51.9404814,
  'east':    8.3997656,
  'south':   51.8930531,
  'west':    8.3500294
}, {'zipCode': '33332', 'north': 51.9204681, 'east': 8.4315811, 'south': 51.87385930000001, 'west': 8.3619153}, {
  'zipCode': '33333',
  'north':   51.8885724,
  'east':    8.4499957,
  'south':   51.8808065,
  'west':    8.4352399
}, {'zipCode': '33334', 'north': 51.9770906, 'east': 8.4722305, 'south': 51.8559713, 'west': 8.2839977}, {
  'zipCode': '33335',
  'north':   51.945540400000006,
  'east':    8.5059937,
  'south':   51.8987339,
  'west':    8.4118575
}, {'zipCode': '33378', 'north': 51.8979135, 'east': 8.4018021, 'south': 51.7896083, 'west': 8.1996163}, {
  'zipCode': '33397',
  'north':   51.8762047,
  'east':    8.5338081,
  'south':   51.7174252,
  'west':    8.3331904
}, {'zipCode': '33415', 'north': 51.9264582, 'east': 8.6014931, 'south': 51.8310335, 'west': 8.4461048}, {
  'zipCode': '33428',
  'north':   52.0142376,
  'east':    8.3322518,
  'south':   51.9263502,
  'west':    8.1055316
}, {'zipCode': '33442', 'north': 51.9496199, 'east': 8.3168956, 'south': 51.8421531, 'west': 8.1503993}, {
  'zipCode': '33449',
  'north':   51.80488630000001,
  'east':    8.3684256,
  'south':   51.7222272,
  'west':    8.2519307
}, {'zipCode': '33602', 'north': 52.0338518, 'east': 8.5537154, 'south': 52.0122424, 'west': 8.5192558}, {
  'zipCode': '33604',
  'north':   52.0193918,
  'east':    8.5711302,
  'south':   51.9886788,
  'west':    8.5309263
}, {'zipCode': '33605', 'north': 52.0135662, 'east': 8.607253, 'south': 51.9802234, 'west': 8.5489035}, {
  'zipCode': '33607',
  'north':   52.0327977,
  'east':    8.5881733,
  'south':   52.0109102,
  'west':    8.5396734
}, {'zipCode': '33609', 'north': 52.0629616, 'east': 8.6159337, 'south': 52.024209, 'west': 8.5431735}, {
  'zipCode': '33611',
  'north':   52.0649091,
  'east':    8.5704615,
  'south':   52.0324408,
  'west':    8.5279422
}, {'zipCode': '33613', 'north': 52.0614114, 'east': 8.537857, 'south': 52.0265085, 'west': 8.5011288}, {
  'zipCode': '33615',
  'north':   52.0532482,
  'east':    8.5291838,
  'south':   52.0165399,
  'west':    8.4844612
}, {'zipCode': '33617', 'north': 52.0226413, 'east': 8.5456077, 'south': 51.9920401, 'west': 8.4922004}, {
  'zipCode': '33619',
  'north':   52.0728882,
  'east':    8.5147499,
  'south':   52.0088584,
  'west':    8.4209522
}, {'zipCode': '33647', 'north': 52.009501, 'east': 8.5489035, 'south': 51.9436012, 'west': 8.4523993}, {
  'zipCode': '33649',
  'north':   52.0177776,
  'east':    8.503279,
  'south':   51.9445731,
  'west':    8.3778199
}, {'zipCode': '33659', 'north': 51.9889019, 'east': 8.5861578, 'south': 51.9148722, 'west': 8.4742203}, {
  'zipCode': '33689',
  'north':   51.9717242,
  'east':    8.6469234,
  'south':   51.9154963,
  'west':    8.5272801
}, {'zipCode': '33699', 'north': 52.0193012, 'east': 8.6622239, 'south': 51.958762, 'west': 8.5706669}, {
  'zipCode': '33719',
  'north':   52.0462333,
  'east':    8.6612922,
  'south':   52.00136150000001,
  'west':    8.5779254
}, {'zipCode': '33729', 'north': 52.0892447, 'east': 8.6633481, 'south': 52.0352064, 'west': 8.5666582}, {
  'zipCode': '33739',
  'north':   52.1147629,
  'east':    8.5728954,
  'south':   52.0587981,
  'west':    8.4546567
}, {'zipCode': '33758', 'north': 51.9302987, 'east': 8.7483439, 'south': 51.8519865, 'west': 8.5567984}, {
  'zipCode': '33775',
  'north':   52.0875266,
  'east':    8.2613538,
  'south':   51.9972122,
  'west':    8.0756012
}, {'zipCode': '33790', 'north': 52.0949277, 'east': 8.4186099, 'south': 51.993995700000006, 'west': 8.2424339}, {
  'zipCode': '33803',
  'north':   52.0449412,
  'east':    8.4615715,
  'south':   51.9662661,
  'west':    8.2891679
}, {'zipCode': '33813', 'north': 51.9828655, 'east': 8.7312452, 'south': 51.9156364, 'west': 8.6067016}, {
  'zipCode': '33818',
  'north':   52.0450069,
  'east':    8.7343098,
  'south':   51.9678438,
  'west':    8.6420039
}, {'zipCode': '33824', 'north': 52.1149724, 'east': 8.4725121, 'south': 52.0436652, 'west': 8.3513335}, {
  'zipCode': '33829',
  'north':   52.1349265,
  'east':    8.3688888,
  'south':   52.0448765,
  'west':    8.2014141
}, {'zipCode': '34117', 'north': 51.32365920000001, 'east': 9.5067859, 'south': 51.3081389, 'west': 9.4757436}, {
  'zipCode': '34119',
  'north':   51.3253231,
  'east':    9.4810868,
  'south':   51.3118227,
  'west':    9.4462242
}, {'zipCode': '34121', 'north': 51.313383, 'east': 9.5042024, 'south': 51.2867222, 'west': 9.4478811}, {
  'zipCode': '34123',
  'north':   51.3181762,
  'east':    9.5622928,
  'south':   51.2641415,
  'west':    9.488559
}, {'zipCode': '34125', 'north': 51.3649088, 'east': 9.5697643, 'south': 51.3111035, 'west': 9.5008403}, {
  'zipCode': '34127',
  'north':   51.3562567,
  'east':    9.5117306,
  'south':   51.320607300000006,
  'west':    9.4653585
}, {'zipCode': '34128', 'north': 51.3540149, 'east': 9.4734629, 'south': 51.3247742, 'west': 9.369429}, {
  'zipCode': '34130',
  'north':   51.3316198,
  'east':    9.4632899,
  'south':   51.3166575,
  'west':    9.4093079
}, {'zipCode': '34131', 'north': 51.3319662, 'east': 9.454622, 'south': 51.297817, 'west': 9.3500845}, {
  'zipCode': '34132',
  'north':   51.3008447,
  'east':    9.4576717,
  'south':   51.26564960000001,
  'west':    9.3624662
}, {'zipCode': '34134', 'north': 51.3033313, 'east': 9.4911036, 'south': 51.2604367, 'west': 9.4235446}, {
  'zipCode': '34212',
  'north':   51.184921,
  'east':    9.6951823,
  'south':   51.0889005,
  'west':    9.5042879
}, {'zipCode': '34225', 'north': 51.2804061, 'east': 9.482822, 'south': 51.2202616, 'west': 9.3268212}, {
  'zipCode': '34233',
  'north':   51.4154528,
  'east':    9.6050253,
  'south':   51.3377072,
  'west':    9.4852757
}, {'zipCode': '34246', 'north': 51.3820257, 'east': 9.5011222, 'south': 51.3424904, 'west': 9.4281286}, {
  'zipCode': '34253',
  'north':   51.2902077,
  'east':    9.5980517,
  'south':   51.245016,
  'west':    9.5146248
}, {'zipCode': '34260', 'north': 51.3055166, 'east': 9.671313, 'south': 51.2461262, 'west': 9.5539447}, {
  'zipCode': '34266',
  'north':   51.3422579,
  'east':    9.6392561,
  'south':   51.2914391,
  'west':    9.530687
}, {'zipCode': '34270', 'north': 51.3075472, 'east': 9.4002551, 'south': 51.2490613, 'west': 9.2501282}, {
  'zipCode': '34277',
  'north':   51.2841389,
  'east':    9.5174414,
  'south':   51.2122831,
  'west':    9.4603584
}, {'zipCode': '34281', 'north': 51.254928, 'east': 9.4305525, 'south': 51.1384955, 'west': 9.302439800000002}, {
  'zipCode': '34286',
  'north':   51.173587600000005,
  'east':    9.7808373,
  'south':   51.06915370000001,
  'west':    9.5811582
}, {'zipCode': '34289', 'north': 51.4382059, 'east': 9.351796900000002, 'south': 51.2891907, 'west': 9.2049015}, {
  'zipCode': '34292',
  'north':   51.3856957,
  'east':    9.4458079,
  'south':   51.34471680000001,
  'west':    9.3508014
}, {'zipCode': '34295', 'north': 51.2576284, 'east': 9.4673458, 'south': 51.1882777, 'west': 9.3265812}, {
  'zipCode': '34298',
  'north':   51.274337,
  'east':    9.7614346,
  'south':   51.2094721,
  'west':    9.6238693
}, {'zipCode': '34302', 'north': 51.2249934, 'east': 9.5719178, 'south': 51.1741079, 'west': 9.4528827}, {
  'zipCode': '34305',
  'north':   51.2541196,
  'east':    9.353302,
  'south':   51.1846677,
  'west':    9.2631158
}, {'zipCode': '34308', 'north': 51.2901991, 'east': 9.3092379, 'south': 51.196033, 'west': 9.2073156}, {
  'zipCode': '34311',
  'north':   51.2871558,
  'east':    9.2444107,
  'south':   51.1703337,
  'west':    9.1256192
}, {'zipCode': '34314', 'north': 51.4117356, 'east': 9.5040531, 'south': 51.3770055, 'west': 9.4233442}, {
  'zipCode': '34317',
  'north':   51.3579577,
  'east':    9.3817634,
  'south':   51.2969985,
  'west':    9.2835829
}, {'zipCode': '34320', 'north': 51.265021, 'east': 9.6451285, 'south': 51.1713632, 'west': 9.5025191}, {
  'zipCode': '34323',
  'north':   51.107994000000005,
  'east':    9.5787222,
  'south':   51.0518828,
  'west':    9.4299202
}, {'zipCode': '34326', 'north': 51.0966104, 'east': 9.6932286, 'south': 51.0234868, 'west': 9.5421702}, {
  'zipCode': '34327',
  'north':   51.1939944,
  'east':    9.5968333,
  'south':   51.1550752,
  'west':    9.4838685
}, {'zipCode': '34329', 'north': 51.3190368, 'east': 9.7196044, 'south': 51.2975778, 'west': 9.6341421}, {
  'zipCode': '34346',
  'north':   51.5576398,
  'east':    9.8025848,
  'south':   51.3563773,
  'west':    9.5684614
}, {'zipCode': '34355', 'north': 51.3927139, 'east': 9.7750387, 'south': 51.2924164, 'west': 9.530595800000002}, {
  'zipCode': '34359',
  'north':   51.5473589,
  'east':    9.6447377,
  'south':   51.4606473,
  'west':    9.5602376
}, {'zipCode': '34369', 'north': 51.56740260000001, 'east': 9.6437609, 'south': 51.4015495, 'west': 9.317657000000002}, {
  'zipCode': '34376',
  'north':   51.4676595,
  'east':    9.5500135,
  'south':   51.40010770000001,
  'west':    9.4442737
}, {'zipCode': '34379', 'north': 51.4534265, 'east': 9.4441938, 'south': 51.3655933, 'west': 9.2647889}, {
  'zipCode': '34385',
  'north':   51.657817,
  'east':    9.4982487,
  'south':   51.6105682,
  'west':    9.3986319
}, {'zipCode': '34388', 'north': 51.654294300000004, 'east': 9.5852744, 'south': 51.527331000000004, 'west': 9.3191915}, {
  'zipCode': '34393',
  'north':   51.4892645,
  'east':    9.502781700000002,
  'south':   51.3983427,
  'west':    9.3304248
}, {'zipCode': '34396', 'north': 51.552978200000005, 'east': 9.3540348, 'south': 51.4473365, 'west': 9.2164812}, {
  'zipCode': '34399',
  'north':   51.6325227,
  'east':    9.6907806,
  'south':   51.5481719,
  'west':    9.4979504
}, {'zipCode': '34414', 'north': 51.5783271, 'east': 9.2710751, 'south': 51.441692, 'west': 8.9042947}, {
  'zipCode': '34431',
  'north':   51.548775000000006,
  'east':    8.970736,
  'south':   51.3720191,
  'west':    8.7003592
}, {'zipCode': '34434', 'north': 51.6632871, 'east': 9.373399, 'south': 51.5052483, 'west': 9.1248611}, {
  'zipCode': '34439',
  'north':   51.6621035,
  'east':    9.2054195,
  'south':   51.5383461,
  'west':    8.9778941
}, {'zipCode': '34454', 'north': 51.4494933, 'east': 9.1290051, 'south': 51.2676317, 'west': 8.9181084}, {
  'zipCode': '34466',
  'north':   51.4080724,
  'east':    9.2841076,
  'south':   51.2431173,
  'west':    9.0927569
}, {'zipCode': '34471', 'north': 51.4659476, 'east': 9.1744009, 'south': 51.3563757, 'west': 9.0262331}, {
  'zipCode': '34474',
  'north':   51.5189191,
  'east':    9.092273900000002,
  'south':   51.4340167,
  'west':    8.8906551
}, {'zipCode': '34477', 'north': 51.3825168, 'east': 9.0482094, 'south': 51.2853952, 'west': 8.8644249}, {
  'zipCode': '34479',
  'north':   51.4720459,
  'east':    9.2773159,
  'south':   51.396702,
  'west':    9.1396943
}, {'zipCode': '34497', 'north': 51.3339466, 'east': 8.966969000000002, 'south': 51.20906800000001, 'west': 8.7272673}, {
  'zipCode': '34508',
  'north':   51.3436602,
  'east':    8.7814468,
  'south':   51.2464633,
  'west':    8.5564286
}, {'zipCode': '34513', 'north': 51.3141257, 'east': 9.1305645, 'south': 51.1768723, 'west': 8.9409076}, {
  'zipCode': '34516',
  'north':   51.2481104,
  'east':    9.0000656,
  'south':   51.1069816,
  'west':    8.8137576
}, {'zipCode': '34519', 'north': 51.39282970000001, 'east': 8.9383628, 'south': 51.2922397, 'west': 8.6537682}, {
  'zipCode': '34537',
  'north':   51.1448747,
  'east':    9.2216469,
  'south':   51.0281634,
  'west':    8.9565534
}, {'zipCode': '34549', 'north': 51.2199019, 'east': 9.2078729, 'south': 51.1122024, 'west': 8.9591008}, {
  'zipCode': '34560',
  'north':   51.2004655,
  'east':    9.3691038,
  'south':   51.08368840000001,
  'west':    9.1874208
}, {'zipCode': '34576', 'north': 51.0803029, 'east': 9.48525, 'south': 50.9288078, 'west': 9.3270612}, {
  'zipCode': '34582',
  'north':   51.1027297,
  'east':    9.3643836,
  'south':   50.9871124,
  'west':    9.1934715
}, {'zipCode': '34587', 'north': 51.1966614, 'east': 9.536335, 'south': 51.0744262, 'west': 9.362997}, {
  'zipCode': '34590',
  'north':   51.13414050000001,
  'east':    9.4301994,
  'south':   51.0575262,
  'west':    9.2655008
}, {'zipCode': '34593', 'north': 51.0753621, 'east': 9.5842257, 'south': 50.9091775, 'west': 9.4418522}, {
  'zipCode': '34596',
  'north':   51.0969691,
  'east':    9.2150715,
  'south':   51.0169209,
  'west':    9.0957655
}, {'zipCode': '34599', 'north': 51.0435482, 'east': 9.2910164, 'south': 50.9554309, 'west': 9.1722465}, {
  'zipCode': '34613',
  'north':   50.9751364,
  'east':    9.3021755,
  'south':   50.8619917,
  'west':    9.1085023
}, {'zipCode': '34621', 'north': 51.0155271, 'east': 9.4187226, 'south': 50.8963527, 'west': 9.2492999}, {
  'zipCode': '34626',
  'north':   50.9250622,
  'east':    9.4250073,
  'south':   50.8254507,
  'west':    9.2756535
}, {'zipCode': '34628', 'north': 50.9072723, 'east': 9.3064922, 'south': 50.818735, 'west': 9.1408817}, {
  'zipCode': '34630',
  'north':   50.9997364,
  'east':    9.1567205,
  'south':   50.902721,
  'west':    8.9727684
}, {'zipCode': '34632', 'north': 51.0282039, 'east': 9.1897509, 'south': 50.9508366, 'west': 9.0568145}, {
  'zipCode': '34633',
  'north':   50.8491616,
  'east':    9.47676,
  'south':   50.7725685,
  'west':    9.314169800000002
}, {'zipCode': '34637', 'north': 50.871674, 'east': 9.3386251, 'south': 50.7968788, 'west': 9.2302644}, {
  'zipCode': '34639',
  'north':   50.93469880000001,
  'east':    9.4947197,
  'south':   50.8791167,
  'west':    9.3936093
}, {'zipCode': '35037', 'north': 50.8247618, 'east': 8.7790771, 'south': 50.7786791, 'west': 8.7197989}, {
  'zipCode': '35039',
  'north':   50.8414338,
  'east':    8.799012,
  'south':   50.7817547,
  'west':    8.7521865
}, {'zipCode': '35041', 'north': 50.8598656, 'east': 8.7733174, 'south': 50.7711832, 'west': 8.6274159}, {
  'zipCode': '35043',
  'north':   50.8556862,
  'east':    8.858138900000002,
  'south':   50.7371111,
  'west':    8.70877
}, {'zipCode': '35066', 'north': 51.1253791, 'east': 8.9269119, 'south': 51.01928830000001, 'west': 8.6571903}, {
  'zipCode': '35075',
  'north':   50.8274849,
  'east':    8.6522801,
  'south':   50.7299911,
  'west':    8.5051268
}, {'zipCode': '35080', 'north': 50.815226, 'east': 8.523243200000001, 'south': 50.722281800000005, 'west': 8.433728}, {
  'zipCode': '35083',
  'north':   50.9783509,
  'east':    8.821486,
  'south':   50.8529925,
  'west':    8.5918048
}, {'zipCode': '35085', 'north': 50.7873746, 'east': 8.9193971, 'south': 50.6876096, 'west': 8.7486014}, {
  'zipCode': '35088',
  'north':   51.077207,
  'east':    8.71346,
  'south':   50.9520984,
  'west':    8.5037419
}, {'zipCode': '35091', 'north': 50.9133284, 'east': 8.8704503, 'south': 50.8401198, 'west': 8.7474809}, {
  'zipCode': '35094',
  'north':   50.8910873,
  'east':    8.7935421,
  'south':   50.8315278,
  'west':    8.6142752
}, {'zipCode': '35096', 'north': 50.8133309, 'east': 8.7712672, 'south': 50.7142375, 'west': 8.6350819}, {
  'zipCode': '35099',
  'north':   51.039838,
  'east':    8.8813262,
  'south':   50.9753751,
  'west':    8.7049915
}, {'zipCode': '35102', 'north': 50.7627608, 'east': 8.6693745, 'south': 50.6737967, 'west': 8.5404166}, {
  'zipCode': '35104',
  'north':   51.2345407,
  'east':    8.8780486,
  'south':   51.1052577,
  'west':    8.6919956
}, {'zipCode': '35108', 'north': 51.0732395, 'east': 8.7405278, 'south': 51.003578, 'west': 8.5832811}, {
  'zipCode': '35110',
  'north':   51.1572462,
  'east':    8.9941302,
  'south':   51.055753,
  'west':    8.8359867
}, {'zipCode': '35112', 'north': 50.7227208, 'east': 8.7674808, 'south': 50.6792443, 'west': 8.6448237}, {
  'zipCode': '35114',
  'north':   51.0851231,
  'east':    9.0970353,
  'south':   50.98974150000001,
  'west':    8.8689585
}, {'zipCode': '35116', 'north': 51.0252089, 'east': 8.6236808, 'south': 50.9486347, 'west': 8.4732968}, {
  'zipCode': '35117',
  'north':   50.9967146,
  'east':    8.7342508,
  'south':   50.9240589,
  'west':    8.6024489
}, {'zipCode': '35119', 'north': 51.01339550000001, 'east': 8.9189534, 'south': 50.9520453, 'west': 8.7272394}, {
  'zipCode': '35216',
  'north':   50.9696801,
  'east':    8.6367315,
  'south':   50.8637802,
  'west':    8.4269631
}, {'zipCode': '35232', 'north': 50.9019398, 'east': 8.6329489, 'south': 50.7952982, 'west': 8.4803259}, {
  'zipCode': '35236',
  'north':   50.9127613,
  'east':    8.5067295,
  'south':   50.8417623,
  'west':    8.355042
}, {'zipCode': '35239', 'north': 50.865207000000005, 'east': 8.5040017, 'south': 50.8015121, 'west': 8.3934147}, {
  'zipCode': '35260',
  'north':   50.9104428,
  'east':    9.0989929,
  'south':   50.751350800000004,
  'west':    8.9500682
}, {'zipCode': '35274', 'north': 50.8947201, 'east': 9.0043647, 'south': 50.7845645, 'west': 8.8286094}, {
  'zipCode': '35279',
  'north':   50.9229765,
  'east':    9.1511626,
  'south':   50.8147763,
  'west':    9.0248603
}, {'zipCode': '35282', 'north': 50.9641948, 'east': 9.0165242, 'south': 50.8588881, 'west': 8.8063873}, {
  'zipCode': '35285',
  'north':   51.0188442,
  'east':    9.0365719,
  'south':   50.9354624,
  'west':    8.8690667
}, {'zipCode': '35287', 'north': 50.8207659, 'east': 8.9800416, 'south': 50.7239057, 'west': 8.8647841}, {
  'zipCode': '35288',
  'north':   50.9721602,
  'east':    8.9752751,
  'south':   50.8995651,
  'west':    8.8765495
}, {'zipCode': '35305', 'north': 50.68925, 'east': 9.069355900000001, 'south': 50.5555559, 'west': 8.8491763}, {
  'zipCode': '35315',
  'north':   50.77114,
  'east':    9.1041542,
  'south':   50.676427,
  'west':    8.905713
}, {'zipCode': '35321', 'north': 50.58153790000001, 'east': 9.1344371, 'south': 50.4884703, 'west': 8.90685}, {
  'zipCode': '35325',
  'north':   50.6887089,
  'east':    9.1421663,
  'south':   50.5660018,
  'west':    8.9416613
}, {'zipCode': '35327', 'north': 50.6220856, 'east': 9.2627151, 'south': 50.5342597, 'west': 9.1055274}, {
  'zipCode': '35329',
  'north':   50.73057550000001,
  'east':    9.1508634,
  'south':   50.6366654,
  'west':    9.0092629
}, {'zipCode': '35390', 'north': 50.5983834, 'east': 8.6925058, 'south': 50.5775809, 'west': 8.6596699}, {
  'zipCode': '35392',
  'north':   50.5800806,
  'east':    8.7187241,
  'south':   50.54375,
  'west':    8.6519632
}, {'zipCode': '35394', 'north': 50.6097193, 'east': 8.7640475, 'south': 50.5452459, 'west': 8.6788869}, {
  'zipCode': '35396',
  'north':   50.6352285,
  'east':    8.7372775,
  'south':   50.5928584,
  'west':    8.6668561
}, {'zipCode': '35398', 'north': 50.6119104, 'east': 8.673363, 'south': 50.5264042, 'west': 8.5557586}, {
  'zipCode': '35410',
  'north':   50.5281771,
  'east':    9.0021756,
  'south':   50.4265853,
  'west':    8.7948013
}, {'zipCode': '35415', 'north': 50.55799240000001, 'east': 8.7884566, 'south': 50.4746403, 'west': 8.688649}, {
  'zipCode': '35418',
  'north':   50.6488031,
  'east':    8.861235,
  'south':   50.5680654,
  'west':    8.7231956
}, {'zipCode': '35423', 'north': 50.5576866, 'east': 8.9270218, 'south': 50.45613620000001, 'west': 8.7368279}, {
  'zipCode': '35428',
  'north':   50.5170334,
  'east':    8.7021358,
  'south':   50.4159576,
  'west':    8.5291798
}, {'zipCode': '35435', 'north': 50.6991039, 'east': 8.696577, 'south': 50.5954659, 'west': 8.5996899}, {
  'zipCode': '35440',
  'north':   50.5642728,
  'east':    8.7103427,
  'south':   50.5022491,
  'west':    8.6160065
}, {'zipCode': '35444', 'north': 50.6990902, 'east': 8.627273, 'south': 50.6020536, 'west': 8.5195093}, {
  'zipCode': '35447',
  'north':   50.630150300000004,
  'east':    8.9247892,
  'south':   50.5471679,
  'west':    8.8004212
}, {'zipCode': '35452', 'north': 50.60976600000001, 'east': 8.6433403, 'south': 50.5646802, 'west': 8.5850529}, {
  'zipCode': '35457',
  'north':   50.7037044,
  'east':    8.7320238,
  'south':   50.6319303,
  'west':    8.6464872
}, {'zipCode': '35460', 'north': 50.6963836, 'east': 8.8118928, 'south': 50.6333327, 'west': 8.7182244}, {
  'zipCode': '35463',
  'north':   50.5929343,
  'east':    8.8245189,
  'south':   50.5311271,
  'west':    8.739928300000003
}, {'zipCode': '35466', 'north': 50.7093076, 'east': 8.9558099, 'south': 50.629948, 'west': 8.8284802}, {
  'zipCode': '35469',
  'north':   50.7074886,
  'east':    8.86491,
  'south':   50.6466995,
  'west':    8.7791108
}, {'zipCode': '35510', 'north': 50.485278, 'east': 8.736783, 'south': 50.3684649, 'west': 8.5140106}, {
  'zipCode': '35516',
  'north':   50.487005,
  'east':    8.8068445,
  'south':   50.4323162,
  'west':    8.6669633
}, {'zipCode': '35519', 'north': 50.4422044, 'east': 8.7924867, 'south': 50.40180960000001, 'west': 8.7112584}, {
  'zipCode': '35576',
  'north':   50.5898802,
  'east':    8.5200501,
  'south':   50.5504619,
  'west':    8.4566496
}, {'zipCode': '35578', 'north': 50.5652155, 'east': 8.5637751, 'south': 50.518699000000005, 'west': 8.4632558}, {
  'zipCode': '35579',
  'north':   50.5569353,
  'east':    8.466242,
  'south':   50.5305018,
  'west':    8.4438778
}, {'zipCode': '35580', 'north': 50.539701, 'east': 8.5100214, 'south': 50.5075934, 'west': 8.4647532}, {
  'zipCode': '35581',
  'north':   50.5613442,
  'east':    8.5946328,
  'south':   50.5352615,
  'west':    8.5435108
}, {'zipCode': '35582', 'north': 50.5747841, 'east': 8.6189053, 'south': 50.5490297, 'west': 8.57027}, {
  'zipCode': '35583',
  'north':   50.5777249,
  'east':    8.5505936,
  'south':   50.5578117,
  'west':    8.5080895
}, {'zipCode': '35584', 'north': 50.6238621, 'east': 8.5482712, 'south': 50.5741117, 'west': 8.5087389}, {
  'zipCode': '35585',
  'north':   50.6331927,
  'east':    8.5295539,
  'south':   50.6000904,
  'west':    8.4766715
}, {'zipCode': '35586', 'north': 50.6030367, 'east': 8.5123862, 'south': 50.5733584, 'west': 8.46423}, {
  'zipCode': '35606',
  'north':   50.5832888,
  'east':    8.4607895,
  'south':   50.5091704,
  'west':    8.3645954
}, {'zipCode': '35614', 'north': 50.6684965, 'east': 8.4862504, 'south': 50.5740223, 'west': 8.3904161}, {
  'zipCode': '35619',
  'north':   50.5484998,
  'east':    8.4520468,
  'south':   50.4562976,
  'west':    8.3098707
}, {'zipCode': '35625', 'north': 50.536829100000006, 'east': 8.6507909, 'south': 50.4696074, 'west': 8.4864967}, {
  'zipCode': '35630',
  'north':   50.6636233,
  'east':    8.4371658,
  'south':   50.5800897,
  'west':    8.3047067
}, {'zipCode': '35633', 'north': 50.6272497, 'east': 8.6082497, 'south': 50.556587900000004, 'west': 8.5332828}, {
  'zipCode': '35638',
  'north':   50.5838295,
  'east':    8.3836266,
  'south':   50.5210721,
  'west':    8.2668668
}, {'zipCode': '35641', 'north': 50.5326861, 'east': 8.5473003, 'south': 50.453624, 'west': 8.4331093}, {
  'zipCode': '35644',
  'north':   50.7021573,
  'east':    8.5627826,
  'south':   50.625580600000006,
  'west':    8.4123374
}, {'zipCode': '35647', 'north': 50.4697297, 'east': 8.577214300000001, 'south': 50.3949077, 'west': 8.4233652}, {
  'zipCode': '35649',
  'north':   50.7395002,
  'east':    8.5782362,
  'south':   50.687232,
  'west':    8.4306174
}, {'zipCode': '35683', 'north': 50.7705167, 'east': 8.316558, 'south': 50.7198638, 'west': 8.2376152}, {
  'zipCode': '35684',
  'north':   50.8022591,
  'east':    8.3219281,
  'south':   50.7662511,
  'west':    8.271137
}, {'zipCode': '35685', 'north': 50.7913051, 'east': 8.2782304, 'south': 50.7545269, 'west': 8.2360494}, {
  'zipCode': '35686',
  'north':   50.7346334,
  'east':    8.2497932,
  'south':   50.7084688,
  'west':    8.2038369
}, {'zipCode': '35687', 'north': 50.7377064, 'east': 8.3329333, 'south': 50.705187900000006, 'west': 8.2482598}, {
  'zipCode': '35688',
  'north':   50.7690567,
  'east':    8.3963171,
  'south':   50.7178307,
  'west':    8.3280623
}, {'zipCode': '35689', 'north': 50.7655696, 'east': 8.3613137, 'south': 50.7370172, 'west': 8.3057999}, {
  'zipCode': '35690',
  'north':   50.7903961,
  'east':    8.3921193,
  'south':   50.7617065,
  'west':    8.2969142
}, {'zipCode': '35708', 'north': 50.8507228, 'east': 8.2999728, 'south': 50.6926136, 'west': 8.1254062}, {
  'zipCode': '35713',
  'north':   50.8567615,
  'east':    8.4326744,
  'south':   50.7722842,
  'west':    8.2914139
}, {'zipCode': '35716', 'north': 50.8826633, 'east': 8.3564438, 'south': 50.8128612, 'west': 8.2305714}, {
  'zipCode': '35719',
  'north':   50.8368745,
  'east':    8.4704734,
  'south':   50.7896761,
  'west':    8.3933217
}, {'zipCode': '35745', 'north': 50.7226843, 'east': 8.3761667, 'south': 50.6276929, 'west': 8.1913362}, {
  'zipCode': '35753',
  'north':   50.6320235,
  'east':    8.3620626,
  'south':   50.5500732,
  'west':    8.1519824
}, {'zipCode': '35756', 'north': 50.7251108, 'east': 8.440298, 'south': 50.6490867, 'west': 8.335473}, {
  'zipCode': '35759',
  'north':   50.678220200000005,
  'east':    8.2565987,
  'south':   50.59834,
  'west':    8.1098542
}, {'zipCode': '35764', 'north': 50.6713824, 'east': 8.3772523, 'south': 50.6227114, 'west': 8.271004}, {
  'zipCode': '35767',
  'north':   50.7136366,
  'east':    8.2554113,
  'south':   50.6505275,
  'west':    8.1210643
}, {'zipCode': '35768', 'north': 50.7973283, 'east': 8.4592241, 'south': 50.7108454, 'west': 8.3736225}, {
  'zipCode': '35781',
  'north':   50.5149348,
  'east':    8.3739506,
  'south':   50.4518413,
  'west':    8.1708786
}, {'zipCode': '35789', 'north': 50.4756704, 'east': 8.467387400000002, 'south': 50.3686505, 'west': 8.2911743}, {
  'zipCode': '35792',
  'north':   50.5859066,
  'east':    8.3271802,
  'south':   50.5011606,
  'west':    8.2057557
}, {'zipCode': '35794', 'north': 50.5995939, 'east': 8.2377382, 'south': 50.5188393, 'west': 8.1099268}, {
  'zipCode': '35796',
  'north':   50.4791022,
  'east':    8.3535859,
  'south':   50.3911217,
  'west':    8.2300591
}, {'zipCode': '35799', 'north': 50.5445492, 'east': 8.2341149, 'south': 50.480099200000005, 'west': 8.1558925}, {
  'zipCode': '36037',
  'north':   50.5694667,
  'east':    9.702864000000002,
  'south':   50.5464279,
  'west':    9.663268
}, {'zipCode': '36039', 'north': 50.6293394, 'east': 9.7318787, 'south': 50.554399200000006, 'west': 9.6430054}, {
  'zipCode': '36041',
  'north':   50.6287517,
  'east':    9.6793239,
  'south':   50.5022332,
  'west':    9.5659893
}, {'zipCode': '36043', 'north': 50.5531584, 'east': 9.7216703, 'south': 50.499484700000004, 'west': 9.6548458}, {
  'zipCode': '36088',
  'north':   50.741275,
  'east':    9.855685,
  'south':   50.59992940000001,
  'west':    9.6200813
}, {'zipCode': '36093', 'north': 50.5561578, 'east': 9.8376088, 'south': 50.4939686, 'west': 9.6980562}, {
  'zipCode': '36100',
  'north':   50.633400300000005,
  'east':    9.7945469,
  'south':   50.5450655,
  'west':    9.6965005
}, {'zipCode': '36103', 'north': 50.4558278, 'east': 9.6222206, 'south': 50.3810948, 'west': 9.4839589}, {
  'zipCode': '36110',
  'north':   50.7661766,
  'east':    9.6502021,
  'south':   50.6053838,
  'west':    9.4479162
}, {'zipCode': '36115', 'north': 50.6072135, 'east': 10.0614645, 'south': 50.4611837, 'west': 9.8939382}, {
  'zipCode': '36119',
  'north':   50.5181581,
  'east':    9.7018347,
  'south':   50.4265987,
  'west':    9.4503964
}, {'zipCode': '36124', 'north': 50.5111959, 'east': 9.7903628, 'south': 50.4175833, 'west': 9.6511716}, {
  'zipCode': '36129',
  'north':   50.5029887,
  'east':    9.9971116,
  'south':   50.39749530000001,
  'west':    9.800912
}, {'zipCode': '36132', 'north': 50.8095765, 'east': 9.9259977, 'south': 50.7205649, 'west': 9.7087036}, {
  'zipCode': '36137',
  'north':   50.6306858,
  'east':    9.6106201,
  'south':   50.5125878,
  'west':    9.4776595
}, {'zipCode': '36142', 'north': 50.6764705, 'east': 10.0829382, 'south': 50.57720470000001, 'west': 9.9476687}, {
  'zipCode': '36145',
  'north':   50.6298281,
  'east':    9.9608654,
  'south':   50.5251258,
  'west':    9.7644225
}, {'zipCode': '36148', 'north': 50.469894, 'east': 9.7597351, 'south': 50.356159, 'west': 9.6110924}, {
  'zipCode': '36151',
  'north':   50.750216,
  'east':    9.7676658,
  'south':   50.6707266,
  'west':    9.606775
}, {'zipCode': '36154', 'north': 50.5570287, 'east': 9.5755606, 'south': 50.4744349, 'west': 9.4273086}, {
  'zipCode': '36157',
  'north':   50.5019548,
  'east':    9.854628900000002,
  'south':   50.4166431,
  'west':    9.739981600000002
}, {'zipCode': '36160', 'north': 50.5706272, 'east': 9.878072, 'south': 50.5038158, 'west': 9.7644464}, {
  'zipCode': '36163',
  'north':   50.5307448,
  'east':    9.9510291,
  'south':   50.4561327,
  'west':    9.8211109
}, {'zipCode': '36166', 'north': 50.8122213, 'east': 9.7398538, 'south': 50.7175014, 'west': 9.6021634}, {
  'zipCode': '36167',
  'north':   50.7088597,
  'east':    9.927023300000002,
  'south':   50.6107767,
  'west':    9.7925628
}, {'zipCode': '36169', 'north': 50.75783, 'east': 9.9415308, 'south': 50.6734643, 'west': 9.8502544}, {
  'zipCode': '36179',
  'north':   51.0400885,
  'east':    9.9196736,
  'south':   50.9170742,
  'west':    9.7070612
}, {'zipCode': '36199', 'north': 51.0836208, 'east': 9.8099707, 'south': 50.9484411, 'west': 9.6576411}, {
  'zipCode': '36205',
  'north':   51.123483900000004,
  'east':    10.0591558,
  'south':   50.992106,
  'west':    9.8213267
}, {'zipCode': '36208', 'north': 50.9964245, 'east': 10.0646423, 'south': 50.9168118, 'west': 9.9024452}, {
  'zipCode': '36211',
  'north':   51.0798144,
  'east':    9.7561864,
  'south':   50.9814859,
  'west':    9.5697626
}, {'zipCode': '36214', 'north': 51.05264590000001, 'east': 10.0027375, 'south': 50.95570920000001, 'west': 9.8722889}, {
  'zipCode': '36217',
  'north':   50.97793320000001,
  'east':    9.9337003,
  'south':   50.8970036,
  'west':    9.834187
}, {'zipCode': '36219', 'north': 51.0855097, 'east': 9.8908248, 'south': 51.023466, 'west': 9.790795}, {
  'zipCode': '36251',
  'north':   50.99426600000001,
  'east':    9.8393515,
  'south':   50.8134112,
  'west':    9.5455336
}, {'zipCode': '36266', 'north': 50.9353784, 'east': 10.060006700000002, 'south': 50.8492539, 'west': 9.8946014}, {
  'zipCode': '36269',
  'north':   50.8698149,
  'east':    10.0387472,
  'south':   50.8258347,
  'west':    9.8998999
}, {'zipCode': '36272', 'north': 50.8425855, 'east': 9.7052767, 'south': 50.7585626, 'west': 9.5087945}, {
  'zipCode': '36275',
  'north':   50.8905782,
  'east':    9.6292253,
  'south':   50.8104308,
  'west':    9.4947945
}, {'zipCode': '36277', 'north': 50.8646349, 'east': 9.9085962, 'south': 50.7895688, 'west': 9.7309305}, {
  'zipCode': '36280',
  'north':   50.8950368,
  'east':    9.5185139,
  'south':   50.8092789,
  'west':    9.4045035
}, {'zipCode': '36282', 'north': 50.8516063, 'east': 9.7723353, 'south': 50.7933412, 'west': 9.6930523}, {
  'zipCode': '36284',
  'north':   50.85989970000001,
  'east':    9.9779237,
  'south':   50.7676379,
  'west':    9.8864424
}, {'zipCode': '36286', 'north': 50.9540584, 'east': 9.6559851, 'south': 50.866988, 'west': 9.4822875}, {
  'zipCode': '36287',
  'north':   50.8159597,
  'east':    9.5597719,
  'south':   50.7347841,
  'west':    9.433319
}, {'zipCode': '36289', 'north': 50.9218735, 'east': 9.9312766, 'south': 50.8424159, 'west': 9.8077131}, {
  'zipCode': '36304',
  'north':   50.8265508,
  'east':    9.461028,
  'south':   50.6989039,
  'west':    9.1733587
}, {'zipCode': '36318', 'north': 50.7396622, 'east': 9.3799584, 'south': 50.6292769, 'west': 9.2346596}, {
  'zipCode': '36320',
  'north':   50.8323505,
  'east':    9.188628,
  'south':   50.7130791,
  'west':    9.0149574
}, {'zipCode': '36323', 'north': 50.7697595, 'east': 9.507351, 'south': 50.6909284, 'west': 9.3532257}, {
  'zipCode': '36325',
  'north':   50.6820634,
  'east':    9.2449187,
  'south':   50.5890352,
  'west':    9.1048899
}, {'zipCode': '36326', 'north': 50.8365357, 'east': 9.2412057, 'south': 50.7581694, 'west': 9.144282}, {
  'zipCode': '36329',
  'north':   50.7444256,
  'east':    9.2721841,
  'south':   50.6571924,
  'west':    9.1407338
}, {'zipCode': '36341', 'north': 50.7043686, 'east': 9.4537823, 'south': 50.5772552, 'west': 9.2853694}, {
  'zipCode': '36355',
  'north':   50.5428835,
  'east':    9.4554622,
  'south':   50.4369455,
  'west':    9.247356
}, {'zipCode': '36358', 'north': 50.5943614, 'east': 9.4977865, 'south': 50.5135031, 'west': 9.2423592}, {
  'zipCode': '36364',
  'north':   50.6641717,
  'east':    9.5501918,
  'south':   50.6103467,
  'west':    9.4660253
}, {'zipCode': '36367', 'north': 50.671762400000006, 'east': 9.501384500000002, 'south': 50.5759226, 'west': 9.4175372}, {
  'zipCode': '36369',
  'north':   50.6448715,
  'east':    9.3560297,
  'south':   50.5349244,
  'west':    9.2322469
}, {'zipCode': '36381', 'north': 50.4114765, 'east': 9.6834994, 'south': 50.29185120000001, 'west': 9.44961}, {
  'zipCode': '36391',
  'north':   50.3688457,
  'east':    9.7488608,
  'south':   50.2213619,
  'west':    9.5541545
}, {'zipCode': '36396', 'north': 50.44257660000001, 'east': 9.6023315, 'south': 50.224290700000005, 'west': 9.3643094}, {
  'zipCode': '36399',
  'north':   50.49071020000001,
  'east':    9.4786325,
  'south':   50.3855085,
  'west':    9.3358023
}, {'zipCode': '36404', 'north': 50.8801789, 'east': 10.1151104, 'south': 50.708693, 'west': 9.9692774}, {
  'zipCode': '36414',
  'north':   50.8332968,
  'east':    9.9971202,
  'south':   50.7790708,
  'west':    9.9434118
}, {'zipCode': '36419', 'north': 50.7826501, 'east': 10.073188300000002, 'south': 50.6267878, 'west': 9.8767193}, {
  'zipCode': '36433',
  'north':   50.91355600000001,
  'east':    10.3426335,
  'south':   50.741878,
  'west':    10.157311
}, {'zipCode': '36448', 'north': 50.8703949, 'east': 10.4115493, 'south': 50.778696, 'west': 10.2970453}, {
  'zipCode': '36452',
  'north':   50.708693,
  'east':    10.218562500000003,
  'south':   50.6153507,
  'west':    10.0549104
}, {'zipCode': '36456', 'north': 50.8196279, 'east': 10.3380308, 'south': 50.763742400000005, 'west': 10.2441446}, {
  'zipCode': '36457',
  'north':   50.81003030000001,
  'east':    10.2223176,
  'south':   50.71746,
  'west':    10.0769346
}, {'zipCode': '36460', 'north': 50.902928, 'east': 10.2054394, 'south': 50.7966962, 'west': 10.0485963}, {
  'zipCode': '36466',
  'north':   50.7470051,
  'east':    10.1941662,
  'south':   50.6677649,
  'west':    10.0725422
}, {'zipCode': '36469', 'north': 50.8649553, 'east': 10.2352369, 'south': 50.8031575, 'west': 10.1216449}, {
  'zipCode': '37073',
  'north':   51.5498786,
  'east':    9.950514,
  'south':   51.5202061,
  'west':    9.9194502
}, {'zipCode': '37075', 'north': 51.5573124, 'east': 10.0536032, 'south': 51.5198234, 'west': 9.9231904}, {
  'zipCode': '37077',
  'north':   51.5908839,
  'east':    10.0457599,
  'south':   51.5461854,
  'west':    9.9210183
}, {'zipCode': '37079', 'north': 51.5761857, 'east': 9.9268422, 'south': 51.5104156, 'west': 9.8001329}, {
  'zipCode': '37081',
  'north':   51.5542211,
  'east':    9.9289692,
  'south':   51.516350700000004,
  'west':    9.8775112
}, {'zipCode': '37083', 'north': 51.5305386, 'east': 9.9764244, 'south': 51.4830052, 'west': 9.9153891}, {
  'zipCode': '37085',
  'north':   51.5405553,
  'east':    10.0048836,
  'south':   51.5017345,
  'west':    9.9395761
}, {'zipCode': '37115', 'north': 51.5690613, 'east': 10.3721111, 'south': 51.4660624, 'west': 10.1395281}, {
  'zipCode': '37120',
  'north':   51.625568,
  'east':    10.071108600000002,
  'south':   51.5596381,
  'west':    9.8163757
}, {'zipCode': '37124', 'north': 51.5213046, 'east': 9.9315379, 'south': 51.4098945, 'west': 9.7277637}, {
  'zipCode': '37127',
  'north':   51.5418047,
  'east':    9.8530177,
  'south':   51.4256158,
  'west':    9.635936
}, {'zipCode': '37130', 'north': 51.5356075, 'east': 10.1859501, 'south': 51.4172839, 'west': 9.9342965}, {
  'zipCode': '37133',
  'north':   51.505625200000004,
  'east':    10.0224135,
  'south':   51.3717589,
  'west':    9.8119737
}, {'zipCode': '37136', 'north': 51.6101949, 'east': 10.19299, 'south': 51.5144315, 'west': 10.0373431}, {
  'zipCode': '37139',
  'north':   51.6142039,
  'east':    9.8268323,
  'south':   51.5218294,
  'west':    9.6519528
}, {'zipCode': '37154', 'north': 51.7897952, 'east': 10.1190576, 'south': 51.6418355, 'west': 9.889450600000002}, {
  'zipCode': '37170',
  'north':   51.7410089,
  'east':    9.7684706,
  'south':   51.5509337,
  'west':    9.5120903
}, {'zipCode': '37176', 'north': 51.6733222, 'east': 10.0576377, 'south': 51.6018362, 'west': 9.865648}, {
  'zipCode': '37181',
  'north':   51.7121004,
  'east':    9.8875636,
  'south':   51.5973892,
  'west':    9.7316358
}, {'zipCode': '37186', 'north': 51.7594359, 'east': 9.9299625, 'south': 51.6432314, 'west': 9.6952603}, {
  'zipCode': '37191',
  'north':   51.73103940000001,
  'east':    10.1629616,
  'south':   51.6097479,
  'west':    10.0322718
}, {'zipCode': '37194', 'north': 51.7293477, 'east': 9.6370277, 'south': 51.6071248, 'west': 9.4175722}, {
  'zipCode': '37197',
  'north':   51.6763325,
  'east':    10.298543,
  'south':   51.609924,
  'west':    10.1659849
}, {'zipCode': '37199', 'north': 51.68130540000001, 'east': 10.2250101, 'south': 51.629793400000004, 'west': 10.1400135}, {
  'zipCode': '37213',
  'north':   51.37439100000001,
  'east':    9.8936434,
  'south':   51.3249683,
  'west':    9.8163439
}, {'zipCode': '37214', 'north': 51.3596917, 'east': 9.9480882, 'south': 51.312474, 'west': 9.8690973}, {
  'zipCode': '37215',
  'north':   51.3339047,
  'east':    9.9165351,
  'south':   51.2642999,
  'west':    9.827666
}, {'zipCode': '37216', 'north': 51.3335275, 'east': 9.8494459, 'south': 51.2584022, 'west': 9.6415451}, {
  'zipCode': '37217',
  'north':   51.3904276,
  'east':    9.8469135,
  'south':   51.3128394,
  'west':    9.7018694
}, {'zipCode': '37218', 'north': 51.4108081, 'east': 9.8508672, 'south': 51.3544971, 'west': 9.7926064}, {
  'zipCode': '37235',
  'north':   51.2449523,
  'east':    9.8568749,
  'south':   51.1434227,
  'west':    9.6242496
}, {'zipCode': '37242', 'north': 51.3213873, 'east': 10.0603265, 'south': 51.2291292, 'west': 9.8511963}, {
  'zipCode': '37247',
  'north':   51.2762588,
  'east':    9.860642,
  'south':   51.2130863,
  'west':    9.731995
}, {'zipCode': '37249', 'north': 51.4210337, 'east': 9.9327238, 'south': 51.3472547, 'west': 9.8500839}, {
  'zipCode': '37269',
  'north':   51.2409246,
  'east':    10.1310887,
  'south':   51.1315961,
  'west':    9.9525172
}, {'zipCode': '37276', 'north': 51.254645800000006, 'east': 10.1495113, 'south': 51.184462200000006, 'west': 9.9962067}, {
  'zipCode': '37281',
  'north':   51.212429500000006,
  'east':    10.2364858,
  'south':   51.1121515,
  'west':    10.1057313
}, {'zipCode': '37284', 'north': 51.1889436, 'east': 9.9609869, 'south': 51.0804622, 'west': 9.7743506}, {
  'zipCode': '37287',
  'north':   51.1835098,
  'east':    10.0880436,
  'south':   51.114582000000006,
  'west':    9.9117658
}, {'zipCode': '37290', 'north': 51.2284856, 'east': 9.9919978, 'south': 51.1686506, 'west': 9.8396504}, {
  'zipCode': '37293',
  'north':   51.0646125,
  'east':    10.214669,
  'south':   50.9934226,
  'west':    10.0310844
}, {'zipCode': '37296', 'north': 51.1326051, 'east': 10.1573338, 'south': 51.050626, 'west': 9.9864929}, {
  'zipCode': '37297',
  'north':   51.2576048,
  'east':    9.9774765,
  'south':   51.2201204,
  'west':    9.8514002
}, {'zipCode': '37299', 'north': 51.13996, 'east': 10.1757429, 'south': 51.096976, 'west': 10.0743047}, {
  'zipCode': '37308',
  'north':   51.4473606,
  'east':    10.2636518,
  'south':   51.1899328,
  'west':    10.0465483
}, {'zipCode': '37318', 'north': 51.4364463, 'east': 10.1309886, 'south': 51.2774429, 'west': 9.9232744}, {
  'zipCode': '37327',
  'north':   51.4239141,
  'east':    10.399236100000001,
  'south':   51.3378721,
  'west':    10.2203354
}, {'zipCode': '37339', 'north': 51.523334, 'east': 10.5198466, 'south': 51.3799666, 'west': 10.1595693}, {
  'zipCode': '37345',
  'north':   51.5924406,
  'east':    10.5395276,
  'south':   51.4525254,
  'west':    10.3431776
}, {'zipCode': '37351', 'north': 51.3693346, 'east': 10.3790089, 'south': 51.2811729, 'west': 10.2238623}, {
  'zipCode': '37355',
  'north':   51.3956132,
  'east':    10.5222853,
  'south':   51.3285981,
  'west':    10.3370612
}, {'zipCode': '37359', 'north': 51.3114784, 'east': 10.3196642, 'south': 51.2258975, 'west': 10.1772029}, {
  'zipCode': '37412',
  'north':   51.7086177,
  'east':    10.4786657,
  'south':   51.5560385,
  'west':    10.2539933
}, {'zipCode': '37431', 'north': 51.701450400000006, 'east': 10.5684869, 'south': 51.5622585, 'west': 10.3575573}, {
  'zipCode': '37434',
  'north':   51.6461645,
  'east':    10.321709,
  'south':   51.5370088,
  'west':    10.071823200000003
}, {'zipCode': '37441', 'north': 51.623228700000006, 'east': 10.592624, 'south': 51.5536494, 'west': 10.4972275}, {
  'zipCode': '37444',
  'north':   51.7817692,
  'east':    10.6545462,
  'south':   51.657044400000004,
  'west':    10.4682962
}, {'zipCode': '37445', 'north': 51.680049600000004, 'east': 10.6823468, 'south': 51.5582298, 'west': 10.4698281}, {
  'zipCode': '37520',
  'north':   51.8222039,
  'east':    10.5000902,
  'south':   51.6675309,
  'west':    10.114313700000002
}, {'zipCode': '37539', 'north': 51.8328206, 'east': 10.2622024, 'south': 51.7509357, 'west': 10.1425569}, {
  'zipCode': '37574',
  'north':   51.9138654,
  'east':    10.0413467,
  'south':   51.7308974,
  'west':    9.7585728
}, {'zipCode': '37581', 'north': 51.9477347, 'east': 10.1181772, 'south': 51.82492220000001, 'west': 9.9277089}, {
  'zipCode': '37586',
  'north':   51.8815688,
  'east':    9.7994928,
  'south':   51.7058098,
  'west':    9.5575209
}, {'zipCode': '37589', 'north': 51.8357577, 'east': 10.1598241, 'south': 51.72673, 'west': 9.9846203}, {
  'zipCode': '37603',
  'north':   51.85561,
  'east':    9.6310713,
  'south':   51.7201298,
  'west':    9.4150883
}, {'zipCode': '37619', 'north': 52.02070220000001, 'east': 9.5968354, 'south': 51.9085181, 'west': 9.4132192}, {
  'zipCode': '37620',
  'north':   52.0438349,
  'east':    9.6198645,
  'south':   51.9701345,
  'west':    9.5201655
}, {'zipCode': '37627', 'north': 51.91668380000001, 'east': 9.7103326, 'south': 51.7915873, 'west': 9.5164282}, {
  'zipCode': '37632',
  'north':   51.9684908,
  'east':    9.8063943,
  'south':   51.864032,
  'west':    9.5753803
}, {'zipCode': '37633', 'north': 51.9853642, 'east': 9.6317429, 'south': 51.9530969, 'west': 9.5819057}, {
  'zipCode': '37635',
  'north':   51.9875918,
  'east':    9.6499076,
  'south':   51.9379603,
  'west':    9.592464
}, {'zipCode': '37639', 'north': 51.9308489, 'east': 9.5601763, 'south': 51.8461533, 'west': 9.4563539}, {
  'zipCode': '37640',
  'north':   51.9240541,
  'east':    9.5741257,
  'south':   51.8741068,
  'west':    9.4892616
}, {'zipCode': '37642', 'north': 51.9272682, 'east': 9.6132295, 'south': 51.8961092, 'west': 9.5599416}, {
  'zipCode': '37643',
  'north':   51.9154415,
  'east':    9.6201808,
  'south':   51.873339900000005,
  'west':    9.542279
}, {'zipCode': '37647', 'north': 51.93460030000001, 'east': 9.4713429, 'south': 51.8538866, 'west': 9.3079066}, {
  'zipCode': '37649',
  'north':   51.8908124,
  'east':    9.4671873,
  'south':   51.855658,
  'west':    9.3562057
}, {'zipCode': '37671', 'north': 51.8648797, 'east': 9.4617417, 'south': 51.6718908, 'west': 9.2490875}, {
  'zipCode': '37688',
  'north':   51.7377354,
  'east':    9.4453811,
  'south':   51.6111709,
  'west':    9.238081400000002
}, {'zipCode': '37691', 'north': 51.7762413, 'east': 9.512443600000001, 'south': 51.6830077, 'west': 9.3752481}, {
  'zipCode': '37696',
  'north':   51.8667216,
  'east':    9.3063189,
  'south':   51.7786949,
  'west':    9.1564659
}, {'zipCode': '37697', 'north': 51.7060912, 'east': 9.4405201, 'south': 51.6459499, 'west': 9.3745213}, {
  'zipCode': '37699',
  'north':   51.74088,
  'east':    9.4286377,
  'south':   51.7053563,
  'west':    9.387019700000002
}, {'zipCode': '38100', 'north': 52.2727154, 'east': 10.5354313, 'south': 52.2517833, 'west': 10.511815}, {
  'zipCode': '38102',
  'north':   52.2709682,
  'east':    10.5493819,
  'south':   52.2456532,
  'west':    10.5227365
}, {'zipCode': '38104', 'north': 52.2984458, 'east': 10.638859, 'south': 52.2505444, 'west': 10.5444156}, {
  'zipCode': '38106',
  'north':   52.2935826,
  'east':    10.5528092,
  'south':   52.2674475,
  'west':    10.5196278
}, {'zipCode': '38108', 'north': 52.3224755, 'east': 10.6449081, 'south': 52.2831081, 'west': 10.523587200000001}, {
  'zipCode': '38110',
  'north':   52.3573831,
  'east':    10.6288843,
  'south':   52.3133233,
  'west':    10.4756559
}, {'zipCode': '38112', 'north': 52.3306176, 'east': 10.5398557, 'south': 52.2868836, 'west': 10.4124522}, {
  'zipCode': '38114',
  'north':   52.297332000000004,
  'east':    10.5245026,
  'south':   52.2670217,
  'west':    10.480013100000003
}, {'zipCode': '38116', 'north': 52.3027934, 'east': 10.4976522, 'south': 52.25797010000001, 'west': 10.4185067}, {
  'zipCode': '38118',
  'north':   52.2724143,
  'east':    10.5150911,
  'south':   52.2475061,
  'west':    10.4854634
}, {'zipCode': '38120', 'north': 52.2667361, 'east': 10.5059714, 'south': 52.229507, 'west': 10.4280976}, {
  'zipCode': '38122',
  'north':   52.2579483,
  'east':    10.5285145,
  'south':   52.2014708,
  'west':    10.4296226
}, {'zipCode': '38124', 'north': 52.2484882, 'east': 10.5537956, 'south': 52.1867942, 'west': 10.4905729}, {
  'zipCode': '38126',
  'north':   52.2585899,
  'east':    10.601169,
  'south':   52.2024308,
  'west':    10.5340879
}, {'zipCode': '38154', 'north': 52.3473013, 'east': 10.9102205, 'south': 52.1730876, 'west': 10.7156851}, {
  'zipCode': '38159',
  'north':   52.3063802,
  'east':    10.4543019,
  'south':   52.1927844,
  'west':    10.2923184
}, {'zipCode': '38162', 'north': 52.3033172, 'east': 10.7601211, 'south': 52.2187435, 'west': 10.5929728}, {
  'zipCode': '38165',
  'north':   52.3873742,
  'east':    10.7760678,
  'south':   52.2949907,
  'west':    10.6008096
}, {'zipCode': '38170', 'north': 52.2069066, 'east': 10.8889197, 'south': 52.0464136, 'west': 10.6485168}, {
  'zipCode': '38173',
  'north':   52.2462226,
  'east':    10.7838831,
  'south':   52.1410469,
  'west':    10.5951838
}, {'zipCode': '38176', 'north': 52.3788336, 'east': 10.441348, 'south': 52.2756883, 'west': 10.2898239}, {
  'zipCode': '38179',
  'north':   52.3710856,
  'east':    10.4928845,
  'south':   52.3215532,
  'west':    10.4035138
}, {'zipCode': '38226', 'north': 52.1766354, 'east': 10.3522601, 'south': 52.1398417, 'west': 10.3070342}, {
  'zipCode': '38228',
  'north':   52.1904978,
  'east':    10.326764,
  'south':   52.1042363,
  'west':    10.2308608
}, {'zipCode': '38229', 'north': 52.1873704, 'east': 10.4345721, 'south': 52.0680687, 'west': 10.2825614}, {
  'zipCode': '38239',
  'north':   52.2227865,
  'east':    10.5077273,
  'south':   52.1292058,
  'west':    10.3667916
}, {'zipCode': '38259', 'north': 52.1162431, 'east': 10.48782, 'south': 52.0131568, 'west': 10.284848}, {
  'zipCode': '38268',
  'north':   52.2459411,
  'east':    10.3692197,
  'south':   52.1750064,
  'west':    10.2484802
}, {'zipCode': '38271', 'north': 52.1199111, 'east': 10.2708442, 'south': 52.0573046, 'west': 10.1759805}, {
  'zipCode': '38272',
  'north':   52.181154,
  'east':    10.251951,
  'south':   52.1075555,
  'west':    10.1763302
}, {'zipCode': '38274', 'north': 52.107403, 'east': 10.326776, 'south': 52.0625499, 'west': 10.236071}, {
  'zipCode': '38275',
  'north':   52.0866165,
  'east':    10.3463065,
  'south':   52.0385928,
  'west':    10.2656659
}, {'zipCode': '38277', 'north': 52.0825872, 'east': 10.2804863, 'south': 52.0272938, 'west': 10.2057564}, {
  'zipCode': '38279',
  'north':   52.0623481,
  'east':    10.2878721,
  'south':   52.0037304,
  'west':    10.1930308
}, {'zipCode': '38300', 'north': 52.1672034, 'east': 10.6190235, 'south': 52.13566310000001, 'west': 10.5292552}, {
  'zipCode': '38302',
  'north':   52.2234034,
  'east':    10.6439294,
  'south':   52.1595903,
  'west':    10.5171114
}, {'zipCode': '38304', 'north': 52.1930862, 'east': 10.5637115, 'south': 52.1208596, 'west': 10.431872}, {
  'zipCode': '38312',
  'north':   52.131184600000005,
  'east':    10.67215,
  'south':   52.0386796,
  'west':    10.4275444
}, {'zipCode': '38315', 'north': 52.07442660000001, 'east': 10.656901, 'south': 51.9703517, 'west': 10.4670249}, {
  'zipCode': '38319',
  'north':   52.1394874,
  'east':    10.7099914,
  'south':   52.0867785,
  'west':    10.5973201
}, {'zipCode': '38321', 'north': 52.1633473, 'east': 10.6517457, 'south': 52.1154441, 'west': 10.5554686}, {
  'zipCode': '38322',
  'north':   52.0881129,
  'east':    10.7317205,
  'south':   52.0448981,
  'west':    10.6460046
}, {'zipCode': '38324', 'north': 52.1299154, 'east': 10.6274813, 'south': 52.0991573, 'west': 10.567695}, {
  'zipCode': '38325',
  'north':   52.0970593,
  'east':    10.759185,
  'south':   52.047798,
  'west':    10.7219697
}, {'zipCode': '38327', 'north': 52.1070083, 'east': 10.7313267, 'south': 52.0742305, 'west': 10.6512274}, {
  'zipCode': '38329',
  'north':   52.143123,
  'east':    10.6615558,
  'south':   52.1120496,
  'west':    10.6282487
}, {'zipCode': '38350', 'north': 52.2872682, 'east': 11.0862102, 'south': 52.167404600000005, 'west': 10.9118835}, {
  'zipCode': '38364',
  'north':   52.1837197,
  'east':    11.0278867,
  'south':   52.103275,
  'west':    10.884303900000003
}, {'zipCode': '38368', 'north': 52.348043, 'east': 11.0377011, 'south': 52.257379, 'west': 10.8773726}, {
  'zipCode': '38372',
  'north':   52.1995425,
  'east':    11.061489,
  'south':   52.1303551,
  'west':    10.9759191
}, {'zipCode': '38373', 'north': 52.2558121, 'east': 10.9414124, 'south': 52.1954323, 'west': 10.8669027}, {
  'zipCode': '38375',
  'north':   52.2203863,
  'east':    10.9094731,
  'south':   52.179748,
  'west':    10.8156316
}, {'zipCode': '38376', 'north': 52.2877724, 'east': 10.9397909, 'south': 52.2285669, 'west': 10.8623529}, {
  'zipCode': '38378',
  'north':   52.1964189,
  'east':    10.9762099,
  'south':   52.1666842,
  'west':    10.890926
}, {'zipCode': '38379', 'north': 52.2243906, 'east': 10.9867484, 'south': 52.1753375, 'west': 10.9217451}, {
  'zipCode': '38381',
  'north':   52.1000438,
  'east':    10.947143,
  'south':   52.0579709,
  'west':    10.8501541
}, {'zipCode': '38382', 'north': 52.0898118, 'east': 10.8760103, 'south': 52.0476836, 'west': 10.8298235}, {
  'zipCode': '38384',
  'north':   52.1024536,
  'east':    10.8545466,
  'south':   52.0472595,
  'west':    10.7927119
}, {'zipCode': '38387', 'north': 52.1580368, 'east': 10.9733205, 'south': 52.0562012, 'west': 10.8320087}, {
  'zipCode': '38440',
  'north':   52.44967990000001,
  'east':    10.8006223,
  'south':   52.4037057,
  'west':    10.7344448
}, {'zipCode': '38442', 'north': 52.4625755, 'east': 10.7634124, 'south': 52.3711845, 'west': 10.6471143}, {
  'zipCode': '38444',
  'north':   52.416314,
  'east':    10.8047891,
  'south':   52.337580800000005,
  'west':    10.7154761
}, {'zipCode': '38446', 'north': 52.4334892, 'east': 10.9068413, 'south': 52.3159344, 'west': 10.7850846}, {
  'zipCode': '38448',
  'north':   52.495086,
  'east':    10.8931052,
  'south':   52.4257164,
  'west':    10.741117
}, {'zipCode': '38458', 'north': 52.43422, 'east': 10.9986529, 'south': 52.3849896, 'west': 10.8997326}, {
  'zipCode': '38459',
  'north':   52.41144680000001,
  'east':    11.0746881,
  'south':   52.330020100000006,
  'west':    10.9490845
}, {'zipCode': '38461', 'north': 52.4608955, 'east': 10.9360618, 'south': 52.41354770000001, 'west': 10.8758057}, {
  'zipCode': '38462',
  'north':   52.4716375,
  'east':    10.9796486,
  'south':   52.423121900000005,
  'west':    10.9031132
}, {'zipCode': '38464', 'north': 52.4050625, 'east': 10.9680565, 'south': 52.32856660000001, 'west': 10.851248400000001}, {
  'zipCode': '38465',
  'north':   52.6327242,
  'east':    10.976942400000002,
  'south':   52.5414177,
  'west':    10.815571100000001
}, {'zipCode': '38467', 'north': 52.556524, 'east': 10.8866323, 'south': 52.5169968, 'west': 10.8172088}, {
  'zipCode': '38468',
  'north':   52.6118521,
  'east':    10.8499325,
  'south':   52.5434446,
  'west':    10.6989846
}, {'zipCode': '38470', 'north': 52.5655967, 'east': 11.0090697, 'south': 52.4952469, 'west': 10.8540583}, {
  'zipCode': '38471',
  'north':   52.5165379,
  'east':    10.9497036,
  'south':   52.459819700000004,
  'west':    10.8170669
}, {'zipCode': '38473', 'north': 52.5437904, 'east': 10.8601117, 'south': 52.4833834, 'west': 10.7818101}, {
  'zipCode': '38474',
  'north':   52.6103849,
  'east':    10.9138352,
  'south':   52.5514494,
  'west':    10.8326197
}, {'zipCode': '38476', 'north': 52.5610701, 'east': 10.8404362, 'south': 52.5106255, 'west': 10.72497}, {
  'zipCode': '38477',
  'north':   52.5357961,
  'east':    10.7934909,
  'south':   52.4792366,
  'west':    10.7173066
}, {'zipCode': '38479', 'north': 52.4950004, 'east': 10.7578051, 'south': 52.4563792, 'west': 10.7173421}, {
  'zipCode': '38486',
  'north':   52.7897813,
  'east':    11.2875379,
  'south':   52.4967016,
  'west':    10.9372801
}, {'zipCode': '38489', 'north': 52.7585794, 'east': 11.171682, 'south': 52.60917620000001, 'west': 10.8335831}, {
  'zipCode': '38518',
  'north':   52.5635978,
  'east':    10.6270957,
  'south':   52.4472279,
  'west':    10.4242053
}, {'zipCode': '38524', 'north': 52.600707500000006, 'east': 10.7276143, 'south': 52.4602471, 'west': 10.553834}, {
  'zipCode': '38527',
  'north':   52.42399,
  'east':    10.6295198,
  'south':   52.3276472,
  'west':    10.5135292
}, {'zipCode': '38528', 'north': 52.411659, 'east': 10.4922533, 'south': 52.3660536, 'west': 10.4100567}, {
  'zipCode': '38530',
  'north':   52.3953569,
  'east':    10.4414328,
  'south':   52.3710265,
  'west':    10.375857
}, {'zipCode': '38531', 'north': 52.4258754, 'east': 10.5548289, 'south': 52.3921147, 'west': 10.4782248}, {
  'zipCode': '38533',
  'north':   52.4006702,
  'east':    10.5466761,
  'south':   52.3458512,
  'west':    10.4645225
}, {'zipCode': '38536', 'north': 52.52798460000001, 'east': 10.4007458, 'south': 52.430098900000004, 'west': 10.2584247}, {
  'zipCode': '38539',
  'north':   52.5624517,
  'east':    10.4698461,
  'south':   52.4620421,
  'west':    10.3039445
}, {'zipCode': '38542', 'north': 52.4797548, 'east': 10.5044829, 'south': 52.410106000000006, 'west': 10.3664572}, {
  'zipCode': '38543',
  'north':   52.4510587,
  'east':    10.4449287,
  'south':   52.3906595,
  'west':    10.3449451
}, {'zipCode': '38547', 'north': 52.470423, 'east': 10.6756778, 'south': 52.3776545, 'west': 10.5971497}, {
  'zipCode': '38550',
  'north':   52.4626699,
  'east':    10.6319384,
  'south':   52.418619,
  'west':    10.5393098
}, {'zipCode': '38551', 'north': 52.4563954, 'east': 10.5508042, 'south': 52.395044, 'west': 10.4478513}, {
  'zipCode': '38553',
  'north':   52.4267496,
  'east':    10.6197623,
  'south':   52.3980931,
  'west':    10.570477
}, {'zipCode': '38554', 'north': 52.4807163, 'east': 10.7465531, 'south': 52.4430346, 'west': 10.6915957}, {
  'zipCode': '38556',
  'north':   52.5303149,
  'east':    10.7352525,
  'south':   52.4784179,
  'west':    10.6623039
}, {'zipCode': '38557', 'north': 52.4866824, 'east': 10.7025618, 'south': 52.4592855, 'west': 10.64151}, {
  'zipCode': '38559',
  'north':   52.5696038,
  'east':    10.558804,
  'south':   52.5438159,
  'west':    10.4781332
}, {'zipCode': '38640', 'north': 51.9261025, 'east': 10.4733346, 'south': 51.8887757, 'west': 10.4013212}, {
  'zipCode': '38642',
  'north':   51.9339236,
  'east':    10.5015033,
  'south':   51.884236800000004,
  'west':    10.4240726
}, {'zipCode': '38644', 'north': 51.9704099, 'east': 10.5060599, 'south': 51.843878700000005, 'west': 10.3173163}, {
  'zipCode': '38667',
  'north':   51.9371565,
  'east':    10.6503909,
  'south':   51.7778904,
  'west':    10.486891200000002
}, {'zipCode': '38678', 'north': 51.8583652, 'east': 10.4293504, 'south': 51.7657965, 'west': 10.2629553}, {
  'zipCode': '38685',
  'north':   51.987879,
  'east':    10.3923831,
  'south':   51.8346104,
  'west':    10.2581958
}, {'zipCode': '38690', 'north': 52.0059348, 'east': 10.6511856, 'south': 51.8959307, 'west': 10.4534274}, {
  'zipCode': '38700',
  'north':   51.7657333,
  'east':    10.7016526,
  'south':   51.6379238,
  'west':    10.5656813
}, {'zipCode': '38704', 'north': 52.0562435, 'east': 10.4996371, 'south': 51.9548889, 'west': 10.3048696}, {
  'zipCode': '38707',
  'north':   51.8978227,
  'east':    10.5505528,
  'south':   51.7646176,
  'west':    10.359822000000001
}, {'zipCode': '38709', 'north': 51.8375719, 'east': 10.3021816, 'south': 51.815004200000004, 'west': 10.2689608}, {
  'zipCode': '38723',
  'north':   51.9627104,
  'east':    10.3168351,
  'south':   51.7935216,
  'west':    10.0656856
}, {'zipCode': '38729', 'north': 52.0336413, 'east': 10.3311502, 'south': 51.9263136, 'west': 10.1738249}, {
  'zipCode': '38820',
  'north':   51.9240702,
  'east':    11.1252988,
  'south':   51.8399453,
  'west':    10.9688434
}, {'zipCode': '38822', 'north': 51.957171300000006, 'east': 11.1748568, 'south': 51.8782244, 'west': 10.8813728}, {
  'zipCode': '38828',
  'north':   51.9263674,
  'east':    11.2721673,
  'south':   51.8414176,
  'west':    11.1224392
}, {'zipCode': '38829', 'north': 51.8958329, 'east': 11.1421278, 'south': 51.8265671, 'west': 11.0575125}, {
  'zipCode': '38835',
  'north':   52.050121,
  'east':    10.8966459,
  'south':   51.9215124,
  'west':    10.560812800000003
}, {'zipCode': '38836', 'north': 52.0590739, 'east': 10.9901231, 'south': 51.94380520000001, 'west': 10.8036279}, {
  'zipCode': '38838',
  'north':   52.0511155,
  'east':    11.1021658,
  'south':   51.9454415,
  'west':    10.9390808
}, {'zipCode': '38855', 'north': 51.9383857, 'east': 10.9168516, 'south': 51.7827059, 'west': 10.6473724}, {
  'zipCode': '38871',
  'north':   51.9528729,
  'east':    10.830918500000001,
  'south':   51.790246,
  'west':    10.5762385
}, {'zipCode': '38875', 'north': 51.7957686, 'east': 10.863336, 'south': 51.670305, 'west': 10.6286481}, {
  'zipCode': '38877',
  'north':   51.6817434,
  'east':    10.7852357,
  'south':   51.6403833,
  'west':    10.6676328
}, {'zipCode': '38879', 'north': 51.8344221, 'east': 10.7062525, 'south': 51.7521811, 'west': 10.5738521}, {
  'zipCode': '38889',
  'north':   51.8458734,
  'east':    11.0563309,
  'south':   51.7051628,
  'west':    10.7822456
}, {'zipCode': '38895', 'north': 51.904388, 'east': 11.0580904, 'south': 51.8303696, 'west': 10.8628292}, {
  'zipCode': '38899',
  'north':   51.7326805,
  'east':    10.9528289,
  'south':   51.6106352,
  'west':    10.7473833
}, {'zipCode': '39104', 'north': 52.139842, 'east': 11.6598459, 'south': 52.0902202, 'west': 11.6154661}, {
  'zipCode': '39106',
  'north':   52.1531416,
  'east':    11.6676548,
  'south':   52.1366899,
  'west':    11.6307606
}, {'zipCode': '39108', 'north': 52.1424121, 'east': 11.6302841, 'south': 52.1185448, 'west': 11.5946205}, {
  'zipCode': '39110',
  'north':   52.1424781,
  'east':    11.6065388,
  'south':   52.103668,
  'west':    11.5357145
}, {'zipCode': '39112', 'north': 52.1211119, 'east': 11.6370177, 'south': 52.0980629, 'west': 11.5876867}, {
  'zipCode': '39114',
  'north':   52.1870232,
  'east':    11.7639936,
  'south':   52.0392276,
  'west':    11.6362578
}, {'zipCode': '39116', 'north': 52.1212897, 'east': 11.6031805, 'south': 52.0616972, 'west': 11.5172264}, {
  'zipCode': '39118',
  'north':   52.1053092,
  'east':    11.6214556,
  'south':   52.078828300000005,
  'west':    11.5845032
}, {'zipCode': '39120', 'north': 52.1056197, 'east': 11.6454451, 'south': 52.0661027, 'west': 11.5722795}, {
  'zipCode': '39122',
  'north':   52.10533610000001,
  'east':    11.6945925,
  'south':   52.0238362,
  'west':    11.602717600000002
}, {'zipCode': '39124', 'north': 52.1700171, 'east': 11.6603393, 'south': 52.14168690000001, 'west': 11.626994}, {
  'zipCode': '39126',
  'north':   52.2283456,
  'east':    11.7051424,
  'south':   52.14896310000001,
  'west':    11.6246323
}, {'zipCode': '39128', 'north': 52.1873458, 'east': 11.6325208, 'south': 52.1397481, 'west': 11.5836705}, {
  'zipCode': '39130',
  'north':   52.1774037,
  'east':    11.604590800000002,
  'south':   52.1392609,
  'west':    11.5517761
}, {'zipCode': '39164', 'north': 52.1510138, 'east': 11.5364937, 'south': 52.0008234, 'west': 11.2030522}, {
  'zipCode': '39167',
  'north':   52.191058000000005,
  'east':    11.5699341,
  'south':   52.1124498,
  'west':    11.3607497
}, {'zipCode': '39171', 'north': 52.0698107, 'east': 11.6402524, 'south': 51.9622965, 'west': 11.4467686}, {
  'zipCode': '39175',
  'north':   52.2114272,
  'east':    11.8337507,
  'south':   52.0905242,
  'west':    11.702581
}, {'zipCode': '39179', 'north': 52.2368326, 'east': 11.6462656, 'south': 52.1697553, 'west': 11.5360672}, {
  'zipCode': '39217',
  'north':   52.09331360000001,
  'east':    11.8618316,
  'south':   52.0085878,
  'west':    11.7360085
}, {'zipCode': '39218', 'north': 52.0639948, 'east': 11.804118, 'south': 51.9749153, 'west': 11.659643}, {
  'zipCode': '39221',
  'north':   52.03277310000001,
  'east':    11.7996462,
  'south':   51.9132483,
  'west':    11.6015203
}, {'zipCode': '39240', 'north': 51.9547041, 'east': 12.0201596, 'south': 51.8375272, 'west': 11.7071223}, {
  'zipCode': '39245',
  'north':   52.096111900000004,
  'east':    11.9078853,
  'south':   52.0383306,
  'west':    11.751404
}, {'zipCode': '39249', 'north': 52.0268839, 'east': 11.925395500000002, 'south': 51.9132473, 'west': 11.7619252}, {
  'zipCode': '39261',
  'north':   52.0067806,
  'east':    12.1416098,
  'south':   51.932932,
  'west':    12.023631
}, {'zipCode': '39264', 'north': 52.1042048, 'east': 12.3777062, 'south': 51.8587557, 'west': 11.8367481}, {
  'zipCode': '39279',
  'north':   52.1711183,
  'east':    12.2764918,
  'south':   52.0259965,
  'west':    11.8860663
}, {'zipCode': '39288', 'north': 52.3721698, 'east': 11.986840300000003, 'south': 52.211925, 'west': 11.7249389}, {
  'zipCode': '39291',
  'north':   52.3065561,
  'east':    12.2971344,
  'south':   52.0775156,
  'west':    11.6873723
}, {'zipCode': '39307', 'north': 52.5239094, 'east': 12.3307866, 'south': 52.2407784, 'west': 11.9732134}, {
  'zipCode': '39317',
  'north':   52.4670498,
  'east':    12.0504505,
  'south':   52.3131005,
  'west':    11.9158345
}, {'zipCode': '39319', 'north': 52.5748337, 'east': 12.1891953, 'south': 52.4033918, 'west': 11.9599156}, {
  'zipCode': '39326',
  'north':   52.4028782,
  'east':    11.8057805,
  'south':   52.1550466,
  'west':    11.457811
}, {'zipCode': '39340', 'north': 52.320142100000005, 'east': 11.4612892, 'south': 52.2497563, 'west': 11.3464821}, {
  'zipCode': '39343',
  'north':   52.3184424,
  'east':    11.5504152,
  'south':   52.1572906,
  'west':    11.0522163
}, {'zipCode': '39345', 'north': 52.3967115, 'east': 11.5690933, 'south': 52.2427003, 'west': 11.1603451}, {
  'zipCode': '39356',
  'north':   52.3728875,
  'east':    11.1951217,
  'south':   52.2586965,
  'west':    10.983474300000001
}, {'zipCode': '39359', 'north': 52.451916, 'east': 11.3410646, 'south': 52.3275024, 'west': 11.0353272}, {
  'zipCode': '39365',
  'north':   52.2198552,
  'east':    11.3681248,
  'south':   52.104342900000006,
  'west':    11.0119292
}, {'zipCode': '39387', 'north': 52.1092163, 'east': 11.4103598, 'south': 51.9557107, 'west': 11.1285725}, {
  'zipCode': '39393',
  'north':   52.1692688,
  'east':    11.1883668,
  'south':   52.0001553,
  'west':    10.9396383
}, {'zipCode': '39397', 'north': 52.0034448, 'east': 11.3733384, 'south': 51.8927914, 'west': 11.044697}, {
  'zipCode': '39418',
  'north':   51.88768,
  'east':    11.660489500000002,
  'south':   51.79756210000001,
  'west':    11.5083707
}, {'zipCode': '39435', 'north': 51.9849966, 'east': 11.6078086, 'south': 51.9030312, 'west': 11.3666296}, {
  'zipCode': '39439',
  'north':   51.829372,
  'east':    11.6534961,
  'south':   51.7459467,
  'west':    11.5488782
}, {'zipCode': '39443', 'north': 51.9574243, 'east': 11.7435798, 'south': 51.8262367, 'west': 11.5601787}, {
  'zipCode': '39444',
  'north':   51.9208339,
  'east':    11.572968,
  'south':   51.8101032,
  'west':    11.3287869
}, {'zipCode': '39446', 'north': 51.913509, 'east': 11.598471, 'south': 51.8637383, 'west': 11.495254}, {
  'zipCode': '39448',
  'north':   52.0069779,
  'east':    11.4595508,
  'south':   51.8836082,
  'west':    11.3402974
}, {'zipCode': '39517', 'north': 52.5434493, 'east': 12.0023518, 'south': 52.3298975, 'west': 11.4911967}, {
  'zipCode': '39524',
  'north':   52.8184356,
  'east':    12.2061409,
  'south':   52.5007257,
  'west':    11.9871561
}, {'zipCode': '39539', 'north': 52.8943563, 'east': 12.2578968, 'south': 52.7270491, 'west': 12.0223163}, {
  'zipCode': '39576',
  'north':   52.7007949,
  'east':    11.9792124,
  'south':   52.4847736,
  'west':    11.5686746
}, {'zipCode': '39579', 'north': 52.7350243, 'east': 11.8557459, 'south': 52.6751283, 'west': 11.6758977}, {
  'zipCode': '39590',
  'north':   52.6448191,
  'east':    12.0155344,
  'south':   52.477753,
  'west':    11.8743778
}, {'zipCode': '39596', 'north': 52.7945148, 'east': 12.041882, 'south': 52.6252952, 'west': 11.789173500000002}, {
  'zipCode': '39606',
  'north':   52.8954042,
  'east':    12.0308317,
  'south':   52.7092101,
  'west':    11.4689197
}, {'zipCode': '39615', 'north': 53.0416917, 'east': 12.0486324, 'south': 52.7884726, 'west': 11.4925142}, {
  'zipCode': '39619',
  'north':   52.9416312,
  'east':    11.63714,
  'south':   52.7354793,
  'west':    11.311318900000002
}, {'zipCode': '39624', 'north': 52.7774305, 'east': 11.5634561, 'south': 52.6023077, 'west': 11.2223349}, {
  'zipCode': '39628',
  'north':   52.7605697,
  'east':    11.7938515,
  'south':   52.563731,
  'west':    11.3968726
}, {'zipCode': '39629', 'north': 52.7056893, 'east': 11.602945100000001, 'south': 52.6386429, 'west': 11.4730223}, {
  'zipCode': '39638',
  'north':   52.6385211,
  'east':    11.6053202,
  'south':   52.3673038,
  'west':    11.2026483
}, {'zipCode': '39646', 'north': 52.5083331, 'east': 11.0999696, 'south': 52.3733245, 'west': 10.9336952}, {
  'zipCode': '39649',
  'north':   52.5618143,
  'east':    11.3051032,
  'south':   52.4336214,
  'west':    11.0512012
}, {'zipCode': '40210', 'north': 51.2256932, 'east': 6.7982357, 'south': 51.2171459, 'west': 6.7822497}, {
  'zipCode': '40211',
  'north':   51.2376559,
  'east':    6.7993435,
  'south':   51.2230673,
  'west':    6.7811008
}, {'zipCode': '40212', 'north': 51.2292774, 'east': 6.7879019, 'south': 51.2180898, 'west': 6.7770813}, {
  'zipCode': '40213',
  'north':   51.2317085,
  'east':    6.7811008,
  'south':   51.2161814,
  'west':    6.763486800000001
}, {'zipCode': '40215', 'north': 51.2210326, 'east': 6.7921632, 'south': 51.2086545, 'west': 6.7769783}, {
  'zipCode': '40217',
  'north':   51.2189704,
  'east':    6.7800561,
  'south':   51.2073146,
  'west':    6.7678107
}, {'zipCode': '40219', 'north': 51.2190866, 'east': 6.770695, 'south': 51.2082029, 'west': 6.7479248}, {
  'zipCode': '40221',
  'north':   51.2292524,
  'east':    6.7738241,
  'south':   51.1808759,
  'west':    6.7222569
}, {'zipCode': '40223', 'north': 51.2128487, 'east': 6.8003585, 'south': 51.1802646, 'west': 6.7605352000000005}, {
  'zipCode': '40225',
  'north':   51.20945880000001,
  'east':    6.8100745,
  'south':   51.1806208,
  'west':    6.7752039
}, {'zipCode': '40227', 'north': 51.2235504, 'east': 6.824355, 'south': 51.2040904, 'west': 6.790633}, {
  'zipCode': '40229',
  'north':   51.2068762,
  'east':    6.8793233,
  'south':   51.1898522,
  'west':    6.8143362
}, {'zipCode': '40231', 'north': 51.2230774, 'east': 6.8472132, 'south': 51.2019574, 'west': 6.8142131}, {
  'zipCode': '40233',
  'north':   51.2312716,
  'east':    6.8307026,
  'south':   51.2112484,
  'west':    6.795906
}, {'zipCode': '40235', 'north': 51.2468091, 'east': 6.8397601, 'south': 51.220447, 'west': 6.8083681}, {
  'zipCode': '40237',
  'north':   51.246591200000005,
  'east':    6.8225594,
  'south':   51.2295409,
  'west':    6.7972651
}, {'zipCode': '40239', 'north': 51.2517644, 'east': 6.8143844, 'south': 51.23711870000001, 'west': 6.7950141}, {
  'zipCode': '40468',
  'north':   51.2860399,
  'east':    6.7954442,
  'south':   51.2527714,
  'west':    6.7505065
}, {'zipCode': '40470', 'north': 51.2636702, 'east': 6.8255745, 'south': 51.2439629, 'west': 6.7918395}, {
  'zipCode': '40472',
  'north':   51.2910334,
  'east':    6.8644252,
  'south':   51.2513157,
  'west':    6.7817832
}, {'zipCode': '40474', 'north': 51.3024918, 'east': 6.8069465000000005, 'south': 51.2365788, 'west': 6.7024931}, {
  'zipCode': '40476',
  'north':   51.2555294,
  'east':    6.7962091,
  'south':   51.2397839,
  'west':    6.7704065
}, {'zipCode': '40477', 'north': 51.2439413, 'east': 6.7975041, 'south': 51.2349614, 'west': 6.7728373}, {
  'zipCode': '40479',
  'north':   51.2389759,
  'east':    6.7939862,
  'south':   51.2286164,
  'west':    6.766707
}, {'zipCode': '40489', 'north': 51.3525244, 'east': 6.8174745, 'south': 51.2864609, 'west': 6.7153446}, {
  'zipCode': '40545',
  'north':   51.2388082,
  'east':    6.7686593,
  'south':   51.2198095,
  'west':    6.7343597
}, {'zipCode': '40547', 'north': 51.256673, 'east': 6.766707, 'south': 51.2322255, 'west': 6.7161289}, {
  'zipCode': '40549',
  'north':   51.2447705,
  'east':    6.746568900000001,
  'south':   51.2240331,
  'west':    6.6888145
}, {'zipCode': '40589', 'north': 51.1901343, 'east': 6.8557631, 'south': 51.1428158, 'west': 6.79152}, {
  'zipCode': '40591',
  'north':   51.20865810000001,
  'east':    6.8453313,
  'south':   51.17648880000001,
  'west':    6.8039319
}, {'zipCode': '40593', 'north': 51.1606356, 'east': 6.8961042, 'south': 51.1262371, 'west': 6.8482353}, {
  'zipCode': '40595',
  'north':   51.1589327,
  'east':    6.9251212,
  'south':   51.1243747,
  'west':    6.8770784
}, {'zipCode': '40597', 'north': 51.1766153, 'east': 6.9001986, 'south': 51.1543811, 'west': 6.8522667}, {
  'zipCode': '40599',
  'north':   51.1902909,
  'east':    6.9047647,
  'south':   51.1691979,
  'west':    6.8461659
}, {'zipCode': '40625', 'north': 51.2457178, 'east': 6.8800593, 'south': 51.2201783, 'west': 6.8321196}, {
  'zipCode': '40627',
  'north':   51.2205463,
  'east':    6.9213127,
  'south':   51.1898327,
  'west':    6.8397601
}, {'zipCode': '40629', 'north': 51.276108, 'east': 6.9398848, 'south': 51.2337673, 'west': 6.8239222}, {
  'zipCode': '40667',
  'north':   51.2876393,
  'east':    6.7263176,
  'south':   51.2336015,
  'west':    6.6487178
}, {'zipCode': '40668', 'north': 51.3367399, 'east': 6.7350417, 'south': 51.2800445, 'west': 6.6203638}, {
  'zipCode': '40670',
  'north':   51.2941679,
  'east':    6.678363,
  'south':   51.2503503,
  'west':    6.5851188
}, {'zipCode': '40699', 'north': 51.2425308, 'east': 6.9951823, 'south': 51.1927497, 'west': 6.8710696}, {
  'zipCode': '40721',
  'north':   51.1921061,
  'east':    6.9407251,
  'south':   51.1405004,
  'west':    6.8793233
}, {'zipCode': '40723', 'north': 51.1679927, 'east': 6.9613238, 'south': 51.143715900000004, 'west': 6.9260016}, {
  'zipCode': '40724',
  'north':   51.1979841,
  'east':    6.986593,
  'south':   51.1637616,
  'west':    6.915611
}, {'zipCode': '40764', 'north': 51.14936, 'east': 7.000806000000001, 'south': 51.0674935, 'west': 6.9057115}, {
  'zipCode': '40789',
  'north':   51.1314443,
  'east':    6.9305776,
  'south':   51.0647647,
  'west':    6.8529529
}, {'zipCode': '40822', 'north': 51.2949225, 'east': 7.0409453, 'south': 51.21725620000001, 'west': 6.9268418}, {
  'zipCode': '40878',
  'north':   51.3101064,
  'east':    6.8658972,
  'south':   51.2814462,
  'west':    6.8269631
}, {'zipCode': '40880', 'north': 51.3227489, 'east': 6.8388291, 'south': 51.2748556, 'west': 6.8012365}, {
  'zipCode': '40882',
  'north':   51.333324000000005,
  'east':    6.9830942,
  'south':   51.2646033,
  'west':    6.8305106
}, {'zipCode': '40883', 'north': 51.3504463, 'east': 6.9347492, 'south': 51.3062383, 'west': 6.8685355}, {
  'zipCode': '40885',
  'north':   51.3717943,
  'east':    6.9292705,
  'south':   51.3151171,
  'west':    6.8025912
}, {'zipCode': '41061', 'north': 51.2049243, 'east': 6.4551782, 'south': 51.1837977, 'west': 6.4155072}, {
  'zipCode': '41063',
  'north':   51.2261642,
  'east':    6.4651163,
  'south':   51.1980952,
  'west':    6.3895601
}, {'zipCode': '41065', 'north': 51.21298910000001, 'east': 6.493311200000001, 'south': 51.175957, 'west': 6.4312855}, {
  'zipCode': '41066',
  'north':   51.24788740000001,
  'east':    6.522199,
  'south':   51.2061926,
  'west':    6.4296913
}, {'zipCode': '41068', 'north': 51.21806050000001, 'east': 6.4249971, 'south': 51.1865156, 'west': 6.3662336}, {
  'zipCode': '41069',
  'north':   51.1975561,
  'east':    6.4408047,
  'south':   51.165811,
  'west':    6.3560883
}, {'zipCode': '41169', 'north': 51.2291292, 'east': 6.3749074, 'south': 51.1816144, 'west': 6.3128775}, {
  'zipCode': '41179',
  'north':   51.1845253,
  'east':    6.408835,
  'south':   51.12610240000001,
  'west':    6.2910981
}, {'zipCode': '41189', 'north': 51.1511782, 'east': 6.4444335, 'south': 51.085784, 'west': 6.3492425}, {
  'zipCode': '41199',
  'north':   51.1535293,
  'east':    6.4784784,
  'south':   51.1072258,
  'west':    6.4216284
}, {'zipCode': '41236', 'north': 51.179349800000004, 'east': 6.4592919, 'south': 51.151809, 'west': 6.433973600000001}, {
  'zipCode': '41238',
  'north':   51.1908882,
  'east':    6.5366448,
  'south':   51.1396682,
  'west':    6.434953800000001
}, {'zipCode': '41239', 'north': 51.177573800000005, 'east': 6.441263, 'south': 51.1418396, 'west': 6.3992457}, {
  'zipCode': '41334',
  'north':   51.3659405,
  'east':    6.326556600000001,
  'south':   51.2527722,
  'west':    6.1538213
}, {'zipCode': '41352', 'north': 51.2311943, 'east': 6.6268082, 'south': 51.1397112, 'west': 6.4800602}, {
  'zipCode': '41363',
  'north':   51.1585477,
  'east':    6.5941707,
  'south':   51.0343171,
  'west':    6.4355028
}, {'zipCode': '41366', 'north': 51.268712, 'east': 6.3310678000000005, 'south': 51.175441, 'west': 6.2096921}, {
  'zipCode': '41372',
  'north':   51.240017,
  'east':    6.2509029,
  'south':   51.1668334,
  'west':    6.0679863
}, {'zipCode': '41379', 'north': 51.3074476, 'east': 6.2414592, 'south': 51.2273167, 'west': 6.0726534}, {
  'zipCode': '41460',
  'north':   51.2294954,
  'east':    6.7321462,
  'south':   51.1853341,
  'west':    6.674811
}, {'zipCode': '41462', 'north': 51.2356105, 'east': 6.6989844, 'south': 51.198088500000004, 'west': 6.6338294}, {
  'zipCode': '41464',
  'north':   51.2053013,
  'east':    6.7137014,
  'south':   51.1750261,
  'west':    6.628816
}, {'zipCode': '41466', 'north': 51.179199700000005, 'east': 6.7142886, 'south': 51.1493819, 'west': 6.6659402}, {
  'zipCode': '41468',
  'north':   51.1889642,
  'east':    6.798347,
  'south':   51.14308520000001,
  'west':    6.7042793
}, {'zipCode': '41469', 'north': 51.1733099, 'east': 6.7524465, 'south': 51.132472400000005, 'west': 6.6845979}, {
  'zipCode': '41470',
  'north':   51.1543778,
  'east':    6.7718008,
  'south':   51.1167565,
  'west':    6.7062089
}, {'zipCode': '41472', 'north': 51.1889127, 'east': 6.6934145, 'south': 51.1309437, 'west': 6.6148193}, {
  'zipCode': '41515',
  'north':   51.1184054,
  'east':    6.6588671,
  'south':   51.0453045,
  'west':    6.5418036
}, {'zipCode': '41516', 'north': 51.1537244, 'east': 6.7149312000000005, 'south': 51.0836676, 'west': 6.5753853}, {
  'zipCode': '41517',
  'north':   51.0958345,
  'east':    6.6383586,
  'south':   51.0183992,
  'west':    6.5192548
}, {'zipCode': '41539', 'north': 51.1196183, 'east': 6.8834254, 'south': 51.08007370000001, 'west': 6.800643}, {
  'zipCode': '41540',
  'north':   51.1101352,
  'east':    6.8379001,
  'south':   51.0458632,
  'west':    6.7407244
}, {'zipCode': '41541', 'north': 51.1626153, 'east': 6.8691103, 'south': 51.1074841, 'west': 6.795037600000001}, {
  'zipCode': '41542',
  'north':   51.1469971,
  'east':    6.8065356,
  'south':   51.0765374,
  'west':    6.696122
}, {'zipCode': '41564', 'north': 51.2609735, 'east': 6.6758748, 'south': 51.1786641, 'west': 6.5600139}, {
  'zipCode': '41569',
  'north':   51.11137320000001,
  'east':    6.7620169,
  'south':   51.0149941,
  'west':    6.6260715
}, {'zipCode': '41747', 'north': 51.2776797, 'east': 6.4241536, 'south': 51.2405246, 'west': 6.3600622}, {
  'zipCode': '41748',
  'north':   51.275684500000004,
  'east':    6.457812500000001,
  'south':   51.2176953,
  'west':    6.3601582
}, {'zipCode': '41749', 'north': 51.3223372, 'east': 6.4259397, 'south': 51.2605894, 'west': 6.3136691}, {
  'zipCode': '41751',
  'north':   51.2861116,
  'east':    6.362574,
  'south':   51.2165296,
  'west':    6.2642195
}, {'zipCode': '41812', 'north': 51.1170375, 'east': 6.4772716, 'south': 51.0126515, 'west': 6.2006991}, {
  'zipCode': '41836',
  'north':   51.08986310000001,
  'east':    6.306591,
  'south':   50.9871104,
  'west':    6.15256
}, {'zipCode': '41844', 'north': 51.1901915, 'east': 6.3557169, 'south': 51.1011375, 'west': 6.1388017}, {
  'zipCode': '41849',
  'north':   51.1487197,
  'east':    6.206357,
  'south':   51.0760668,
  'west':    6.0799949
}, {'zipCode': '42103', 'north': 51.2597009, 'east': 7.163865700000001, 'south': 51.2475143, 'west': 7.1316966}, {
  'zipCode': '42105',
  'north':   51.2693061,
  'east':    7.1483216,
  'south':   51.254111,
  'west':    7.1297437
}, {'zipCode': '42107', 'north': 51.2712148, 'east': 7.166736800000001, 'south': 51.2577766, 'west': 7.1462071}, {
  'zipCode': '42109',
  'north':   51.2874498,
  'east':    7.174589600000001,
  'south':   51.2657919,
  'west':    7.125466
}, {'zipCode': '42111', 'north': 51.3128441, 'east': 7.181421800000001, 'south': 51.2780505, 'west': 7.095235}, {
  'zipCode': '42113',
  'north':   51.28828630000001,
  'east':    7.1314786,
  'south':   51.2610873,
  'west':    7.0745258
}, {'zipCode': '42115', 'north': 51.2651237, 'east': 7.132514, 'south': 51.2457567, 'west': 7.0842815}, {
  'zipCode': '42117',
  'north':   51.2534955,
  'east':    7.1467873,
  'south':   51.23086020000001,
  'west':    7.1025803000000005
}, {'zipCode': '42119', 'north': 51.2560613, 'east': 7.175637099999999, 'south': 51.2314622, 'west': 7.1415651}, {
  'zipCode': '42275',
  'north':   51.2786624,
  'east':    7.217856699999999,
  'south':   51.2675499,
  'west':    7.1929035
}, {'zipCode': '42277', 'north': 51.2911044, 'east': 7.2391288, 'south': 51.2732783, 'west': 7.202506800000001}, {
  'zipCode': '42279',
  'north':   51.3180616,
  'east':    7.2659892,
  'south':   51.2830002,
  'west':    7.2011249
}, {'zipCode': '42281', 'north': 51.29916070000001, 'east': 7.209510599999999, 'south': 51.2727388, 'west': 7.1682384}, {
  'zipCode': '42283',
  'north':   51.2800957,
  'east':    7.1964033,
  'south':   51.263659000000004,
  'west':    7.167379099999999
}, {'zipCode': '42285', 'north': 51.276214, 'east': 7.1918704, 'south': 51.2396107, 'west': 7.1547482}, {
  'zipCode': '42287',
  'north':   51.268852,
  'east':    7.2666833,
  'south':   51.2216874,
  'west':    7.1832913
}, {'zipCode': '42289', 'north': 51.274898, 'east': 7.2388057, 'south': 51.2530435, 'west': 7.1991092000000005}, {
  'zipCode': '42327',
  'north':   51.2708879,
  'east':    7.104729900000001,
  'south':   51.2155569,
  'west':    7.0140725
}, {'zipCode': '42329', 'north': 51.2377977, 'east': 7.1135775, 'south': 51.20889480000001, 'west': 7.0357746}, {
  'zipCode': '42349',
  'north':   51.2354919,
  'east':    7.1729779,
  'south':   51.1658028,
  'west':    7.1036061
}, {'zipCode': '42369', 'north': 51.24424700000001, 'east': 7.2348213, 'south': 51.2056081, 'west': 7.1638726}, {
  'zipCode': '42389',
  'north':   51.2868062,
  'east':    7.2777563,
  'south':   51.256531900000006,
  'west':    7.2264797000000005
}, {'zipCode': '42399', 'north': 51.2635524, 'east': 7.31343, 'south': 51.2054213, 'west': 7.244167900000001}, {
  'zipCode': '42477',
  'north':   51.250545,
  'east':    7.433914399999999,
  'south':   51.16945030000001,
  'west':    7.2949709
}, {'zipCode': '42489', 'north': 51.3259823, 'east': 7.0989741, 'south': 51.2546754, 'west': 6.9805034}, {
  'zipCode': '42499',
  'north':   51.1967442,
  'east':    7.398754900000001,
  'south':   51.1009356,
  'west':    7.269505900000001
}, {'zipCode': '42549', 'north': 51.3648111, 'east': 7.0511674, 'south': 51.3149868, 'west': 7.0013137}, {
  'zipCode': '42551',
  'north':   51.3745342,
  'east':    7.09538,
  'south':   51.3180673,
  'west':    7.0185679
}, {'zipCode': '42553', 'north': 51.3381513, 'east': 7.169779500000001, 'south': 51.29318310000001, 'west': 7.0440454}, {
  'zipCode': '42555',
  'north':   51.3814164,
  'east':    7.159572900000001,
  'south':   51.32725560000001,
  'west':    7.0861731
}, {'zipCode': '42579', 'north': 51.3593411, 'east': 7.0125508, 'south': 51.2953906, 'west': 6.9180704}, {
  'zipCode': '42651',
  'north':   51.198237,
  'east':    7.1290749,
  'south':   51.1612835,
  'west':    7.0753805
}, {'zipCode': '42653', 'north': 51.2203751, 'east': 7.107816500000001, 'south': 51.1800925, 'west': 7.0515344}, {
  'zipCode': '42655',
  'north':   51.1855063,
  'east':    7.0844827,
  'south':   51.1465618,
  'west':    7.0280173
}, {'zipCode': '42657', 'north': 51.1666916, 'east': 7.0890427, 'south': 51.1247155, 'west': 7.0266847}, {
  'zipCode': '42659',
  'north':   51.1735807,
  'east':    7.1771509,
  'south':   51.1142274,
  'west':    7.0734223
}, {'zipCode': '42697', 'north': 51.1804889, 'east': 7.024801, 'south': 51.1248968, 'west': 6.9513725}, {
  'zipCode': '42699',
  'north':   51.1750658,
  'east':    7.0445428,
  'south':   51.1197204,
  'west':    6.9943298
}, {'zipCode': '42719', 'north': 51.2105728, 'east': 7.0744549, 'south': 51.1689674, 'west': 7.0073909}, {
  'zipCode': '42781',
  'north':   51.2441147,
  'east':    7.0517532,
  'south':   51.17195970000001,
  'west':    6.9598031
}, {'zipCode': '42799', 'north': 51.1385064, 'east': 7.1579474, 'south': 51.08173, 'west': 6.9882129}, {
  'zipCode': '42853',
  'north':   51.1919442,
  'east':    7.2006012,
  'south':   51.1767985,
  'west':    7.1607775
}, {'zipCode': '42855', 'north': 51.214258, 'east': 7.236169900000001, 'south': 51.1728835, 'west': 7.1555073}, {
  'zipCode': '42857',
  'north':   51.2018197,
  'east':    7.191630600000001,
  'south':   51.1422583,
  'west':    7.131016700000001
}, {'zipCode': '42859', 'north': 51.1800542, 'east': 7.243636800000001, 'south': 51.1510203, 'west': 7.1632579}, {
  'zipCode': '42897',
  'north':   51.2108838,
  'east':    7.3093209,
  'south':   51.1438507,
  'west':    7.233625699999999
}, {'zipCode': '42899', 'north': 51.2268325, 'east': 7.2665354, 'south': 51.1834321, 'west': 7.2070718}, {
  'zipCode': '42929',
  'north':   51.161058,
  'east':    7.298924,
  'south':   51.0645591,
  'west':    7.1385522
}, {'zipCode': '44135', 'north': 51.519739, 'east': 7.4889561, 'south': 51.50804840000001, 'west': 7.4636692}, {
  'zipCode': '44137',
  'north':   51.5185299,
  'east':    7.468243499999999,
  'south':   51.4974496,
  'west':    7.4335116
}, {'zipCode': '44139', 'north': 51.5100171, 'east': 7.4881286, 'south': 51.4843885, 'west': 7.4335116}, {
  'zipCode': '44141',
  'north':   51.5159382,
  'east':    7.5233753000000005,
  'south':   51.4918092,
  'west':    7.468784
}, {'zipCode': '44143', 'north': 51.5346232, 'east': 7.5544849, 'south': 51.5047539, 'west': 7.4742895}, {
  'zipCode': '44145',
  'north':   51.5474867,
  'east':    7.5139957,
  'south':   51.5182499,
  'west':    7.452422000000001
}, {'zipCode': '44147', 'north': 51.552919, 'east': 7.4647498, 'south': 51.4977989, 'west': 7.4191689}, {
  'zipCode': '44149',
  'north':   51.5234114,
  'east':    7.4335116,
  'south':   51.4764459,
  'west':    7.3536624
}, {'zipCode': '44225', 'north': 51.4917247, 'east': 7.480354300000001, 'south': 51.4655477, 'west': 7.424252}, {
  'zipCode': '44227',
  'north':   51.4979407,
  'east':    7.4850031,
  'south':   51.4312941,
  'west':    7.3828936
}, {'zipCode': '44229', 'north': 51.47094250000001, 'east': 7.486674500000001, 'south': 51.4419682, 'west': 7.4352878}, {
  'zipCode': '44263',
  'north':   51.5013813,
  'east':    7.528424800000001,
  'south':   51.4748991,
  'west':    7.4676025
}, {'zipCode': '44265', 'north': 51.4845926, 'east': 7.5229395, 'south': 51.4155255, 'west': 7.463696400000001}, {
  'zipCode': '44267',
  'north':   51.4766744,
  'east':    7.552544200000001,
  'south':   51.4343189,
  'west':    7.495870200000001
}, {'zipCode': '44269', 'north': 51.5069565, 'east': 7.549614500000001, 'south': 51.4710446, 'west': 7.4945805}, {
  'zipCode': '44287',
  'north':   51.510410500000006,
  'east':    7.580523699999999,
  'south':   51.468842,
  'west':    7.5348648
}, {'zipCode': '44289', 'north': 51.5082827, 'east': 7.609746600000001, 'south': 51.4643216, 'west': 7.5734998}, {
  'zipCode': '44309',
  'north':   51.5505424,
  'east':    7.5843285,
  'south':   51.5063242,
  'west':    7.525097
}, {'zipCode': '44319', 'north': 51.5685349, 'east': 7.638157, 'south': 51.5071985, 'west': 7.566308500000001}, {
  'zipCode': '44328',
  'north':   51.5619203,
  'east':    7.5667198,
  'south':   51.5303735,
  'west':    7.509901200000001
}, {'zipCode': '44329', 'north': 51.5867508, 'east': 7.5972489, 'south': 51.5439512, 'west': 7.4891909}, {
  'zipCode': '44339',
  'north':   51.5925186,
  'east':    7.504118900000001,
  'south':   51.539838,
  'west':    7.4221330000000005
}, {'zipCode': '44357', 'north': 51.5731833, 'east': 7.3937305, 'south': 51.5338333, 'west': 7.347386500000001}, {
  'zipCode': '44359',
  'north':   51.6000324,
  'east':    7.436461000000001,
  'south':   51.5432027,
  'west':    7.3385218
}, {'zipCode': '44369', 'north': 51.5530678, 'east': 7.436461000000001, 'south': 51.5140804, 'west': 7.369754800000001}, {
  'zipCode': '44379',
  'north':   51.5367748,
  'east':    7.405699,
  'south':   51.4953997,
  'west':    7.351441199999999
}, {'zipCode': '44388', 'north': 51.5249754, 'east': 7.3658238, 'south': 51.4820167, 'west': 7.302442199999999}, {
  'zipCode': '44532',
  'north':   51.6296033,
  'east':    7.5923255,
  'south':   51.5784957,
  'west':    7.4914996
}, {'zipCode': '44534', 'north': 51.6526727, 'east': 7.5671425, 'south': 51.6106445, 'west': 7.4534471}, {
  'zipCode': '44536',
  'north':   51.6262989,
  'east':    7.520080299999999,
  'south':   51.5796124,
  'west':    7.4181329
}, {'zipCode': '44575', 'north': 51.56814080000001, 'east': 7.337937300000001, 'south': 51.5254363, 'west': 7.282545399999999}, {
  'zipCode': '44577',
  'north':   51.6165478,
  'east':    7.363962,
  'south':   51.5201869,
  'west':    7.2911938
}, {'zipCode': '44579', 'north': 51.596951, 'east': 7.335961000000001, 'south': 51.5514101, 'west': 7.242496299999999}, {
  'zipCode': '44581',
  'north':   51.6262817,
  'east':    7.371879599999999,
  'south':   51.5771831,
  'west':    7.279937600000001
}, {'zipCode': '44623', 'north': 51.5499315, 'east': 7.241608699999999, 'south': 51.5250624, 'west': 7.204121}, {
  'zipCode': '44625',
  'north':   51.5422098,
  'east':    7.251815000000001,
  'south':   51.51699790000001,
  'west':    7.1824328
}, {'zipCode': '44627', 'north': 51.55598770000001, 'east': 7.2965543, 'south': 51.5198289, 'west': 7.2378521}, {
  'zipCode': '44628',
  'north':   51.573320700000004,
  'east':    7.2771276,
  'south':   51.5442918,
  'west':    7.2085896
}, {'zipCode': '44629', 'north': 51.560233, 'east': 7.225027200000001, 'south': 51.5385771, 'west': 7.1957786}, {
  'zipCode': '44649',
  'north':   51.5439063,
  'east':    7.1721143,
  'south':   51.5215743,
  'west':    7.1280592
}, {'zipCode': '44651', 'north': 51.523838, 'east': 7.190830900000001, 'south': 51.5030645, 'west': 7.1372295}, {
  'zipCode': '44652',
  'north':   51.5381113,
  'east':    7.190741,
  'south':   51.5146584,
  'west':    7.1551154
}, {'zipCode': '44653', 'north': 51.5565908, 'east': 7.204121, 'south': 51.5304353, 'west': 7.1238766}, {
  'zipCode': '44787',
  'north':   51.4887783,
  'east':    7.235757500000001,
  'south':   51.4728502,
  'west':    7.205117200000001
}, {'zipCode': '44789', 'north': 51.4813066, 'east': 7.2377707, 'south': 51.4550675, 'west': 7.1975542}, {
  'zipCode': '44791',
  'north':   51.4987105,
  'east':    7.297949,
  'south':   51.4813066,
  'west':    7.204383
}, {'zipCode': '44793', 'north': 51.5087591, 'east': 7.215378600000001, 'south': 51.4653449, 'west': 7.1503669}, {
  'zipCode': '44795',
  'north':   51.4692279,
  'east':    7.2212704,
  'south':   51.4357943,
  'west':    7.1713329
}, {'zipCode': '44797', 'north': 51.4438988, 'east': 7.269740500000001, 'south': 51.41050430000001, 'west': 7.176251100000001}, {
  'zipCode': '44799',
  'north':   51.4663893,
  'east':    7.2583965,
  'south':   51.4396508,
  'west':    7.213117
}, {'zipCode': '44801', 'north': 51.4635594, 'east': 7.2934596, 'south': 51.4237022, 'west': 7.2227045}, {
  'zipCode': '44803',
  'north':   51.48517400000001,
  'east':    7.2894339,
  'south':   51.4530056,
  'west':    7.2309833999999995
}, {'zipCode': '44805', 'north': 51.5313751, 'east': 7.3146562, 'south': 51.4937718, 'west': 7.244026400000001}, {
  'zipCode': '44807',
  'north':   51.5220917,
  'east':    7.257601499999999,
  'south':   51.4943059,
  'west':    7.194053200000001
}, {'zipCode': '44809', 'north': 51.5174784, 'east': 7.2255617, 'south': 51.4847618, 'west': 7.1779418}, {
  'zipCode': '44866',
  'north':   51.5060014,
  'east':    7.174041400000001,
  'south':   51.4719734,
  'west':    7.1021062
}, {'zipCode': '44867', 'north': 51.4804667, 'east': 7.174041400000001, 'south': 51.445375, 'west': 7.1082423}, {
  'zipCode': '44869',
  'north':   51.4688556,
  'east':    7.185346399999999,
  'south':   51.4369094,
  'west':    7.1153641
}, {'zipCode': '44879', 'north': 51.4439815, 'east': 7.1907137, 'south': 51.4135346, 'west': 7.1187244}, {
  'zipCode': '44892',
  'north':   51.4856949,
  'east':    7.349326700000001,
  'south':   51.4541089,
  'west':    7.287712199999999
}, {'zipCode': '44894', 'north': 51.505907500000006, 'east': 7.3437251, 'south': 51.4678281, 'west': 7.2778543}, {
  'zipCode': '45127',
  'north':   51.4650235,
  'east':    7.026926,
  'south':   51.4502689,
  'west':    6.9956121
}, {'zipCode': '45128', 'north': 51.4537651, 'east': 7.024422, 'south': 51.4372183, 'west': 6.997017}, {
  'zipCode': '45130',
  'north':   51.443045,
  'east':    7.023020100000001,
  'south':   51.4325648,
  'west':    6.9960612
}, {'zipCode': '45131', 'north': 51.4365756, 'east': 7.023181, 'south': 51.4215435, 'west': 6.9811036}, {
  'zipCode': '45133',
  'north':   51.4309859,
  'east':    7.0272044,
  'south':   51.3790913,
  'west':    6.9427663
}, {'zipCode': '45134', 'north': 51.4316762, 'east': 7.068439, 'south': 51.4059281, 'west': 7.0136898}, {
  'zipCode': '45136',
  'north':   51.4454832,
  'east':    7.0652848,
  'south':   51.4280987,
  'west':    7.0213759
}, {'zipCode': '45138', 'north': 51.4555167, 'east': 7.061687200000001, 'south': 51.4407022, 'west': 7.0220152}, {
  'zipCode': '45139',
  'north':   51.4683591,
  'east':    7.0639916,
  'south':   51.4500369,
  'west':    7.0199868
}, {'zipCode': '45141', 'north': 51.4925833, 'east': 7.0615049, 'south': 51.4621527, 'west': 6.9815624}, {
  'zipCode': '45143',
  'north':   51.4740308,
  'east':    7.0011316,
  'south':   51.4502689,
  'west':    6.9543902
}, {'zipCode': '45144', 'north': 51.4572015, 'east': 6.9887415, 'south': 51.4474975, 'west': 6.9515204}, {
  'zipCode': '45145',
  'north':   51.452723,
  'east':    7.0015758,
  'south':   51.4406206,
  'west':    6.9526047
}, {'zipCode': '45147', 'north': 51.4471168, 'east': 7.002305800000001, 'south': 51.4308484, 'west': 6.9580008}, {
  'zipCode': '45149',
  'north':   51.4388561,
  'east':    6.9934879,
  'south':   51.3949647,
  'west':    6.9388727
}, {'zipCode': '45219', 'north': 51.3893212, 'east': 6.9865037, 'south': 51.3475714, 'west': 6.9050932}, {
  'zipCode': '45239',
  'north':   51.407061000000006,
  'east':    7.0644819000000005,
  'south':   51.357113,
  'west':    6.9605193
}, {'zipCode': '45257', 'north': 51.410629, 'east': 7.1195348, 'south': 51.3620875, 'west': 7.0402709}, {
  'zipCode': '45259',
  'north':   51.4209667,
  'east':    7.0819594,
  'south':   51.3878136,
  'west':    7.0367069
}, {'zipCode': '45276', 'north': 51.4574292, 'east': 7.1000556, 'south': 51.4391619, 'west': 7.0572987}, {
  'zipCode': '45277',
  'north':   51.4433518,
  'east':    7.096920400000001,
  'south':   51.4069411,
  'west':    7.0558011
}, {'zipCode': '45279', 'north': 51.4612474, 'east': 7.1338598, 'south': 51.4288385, 'west': 7.0812298}, {
  'zipCode': '45289',
  'north':   51.4310798,
  'east':    7.13765,
  'south':   51.4064788,
  'west':    7.0897955
}, {'zipCode': '45307', 'north': 51.4810458, 'east': 7.114028000000001, 'south': 51.4519002, 'west': 7.0535806}, {
  'zipCode': '45309',
  'north':   51.4907284,
  'east':    7.1017223,
  'south':   51.467772,
  'west':    7.041822800000001
}, {'zipCode': '45326', 'north': 51.5038236, 'east': 7.0256505, 'south': 51.4710157, 'west': 6.9826935}, {
  'zipCode': '45327',
  'north':   51.5119889,
  'east':    7.074213300000001,
  'south':   51.4848583,
  'west':    7.0210519
}, {'zipCode': '45329', 'north': 51.5342019, 'east': 7.0414142, 'south': 51.4868992, 'west': 6.9860935}, {
  'zipCode': '45355',
  'north':   51.4861111,
  'east':    6.9724963,
  'south':   51.4619525,
  'west':    6.9294524
}, {'zipCode': '45356', 'north': 51.509591300000004, 'east': 6.996155, 'south': 51.46985930000001, 'west': 6.9393666}, {
  'zipCode': '45357',
  'north':   51.4982611,
  'east':    6.9497674,
  'south':   51.4710658,
  'west':    6.9021174
}, {'zipCode': '45359', 'north': 51.4866605, 'east': 6.9603401, 'south': 51.447336, 'west': 6.8943442}, {
  'zipCode': '45468',
  'north':   51.4405562,
  'east':    6.9007837,
  'south':   51.4128042,
  'west':    6.8751517
}, {'zipCode': '45470', 'north': 51.4414801, 'east': 6.9505752, 'south': 51.3734108, 'west': 6.8781625}, {
  'zipCode': '45472',
  'north':   51.4513301,
  'east':    6.9595443,
  'south':   51.4214242,
  'west':    6.9074987
}, {'zipCode': '45473', 'north': 51.4526006, 'east': 6.92353, 'south': 51.431606300000006, 'west': 6.8636985}, {
  'zipCode': '45475',
  'north':   51.47145,
  'east':    6.9172107,
  'south':   51.4471489,
  'west':    6.8725687
}, {'zipCode': '45476', 'north': 51.4586781, 'east': 6.874277500000001, 'south': 51.4382555, 'west': 6.8267663}, {
  'zipCode': '45478',
  'north':   51.4570744,
  'east':    6.8644695,
  'south':   51.4094561,
  'west':    6.8066678
}, {'zipCode': '45479', 'north': 51.4382555, 'east': 6.8774556, 'south': 51.3788315, 'west': 6.8123269}, {
  'zipCode': '45481',
  'north':   51.41902,
  'east':    6.9150418,
  'south':   51.3507415,
  'west':    6.8135089
}, {'zipCode': '45525', 'north': 51.4127408, 'east': 7.204890100000001, 'south': 51.381259, 'west': 7.1607051}, {
  'zipCode': '45527',
  'north':   51.4187662,
  'east':    7.257141200000001,
  'south':   51.329686800000005,
  'west':    7.1819595000000005
}, {'zipCode': '45529', 'north': 51.4252894, 'east': 7.2152638, 'south': 51.3099383, 'west': 7.1080627}, {
  'zipCode': '45549',
  'north':   51.3850641,
  'east':    7.3169686,
  'south':   51.2945862,
  'west':    7.1686523
}, {'zipCode': '45657', 'north': 51.635176, 'east': 7.2114295, 'south': 51.6028804, 'west': 7.171518700000001}, {
  'zipCode': '45659',
  'north':   51.6514493,
  'east':    7.242332,
  'south':   51.5782685,
  'west':    7.1347922
}, {'zipCode': '45661', 'north': 51.5843079, 'east': 7.2137459, 'south': 51.55276020000001, 'west': 7.1690353}, {
  'zipCode': '45663',
  'north':   51.596415,
  'east':    7.2634292,
  'south':   51.5592396,
  'west':    7.207621699999999
}, {'zipCode': '45665', 'north': 51.6363891, 'east': 7.2962721, 'south': 51.5815248, 'west': 7.201828600000001}, {
  'zipCode': '45699',
  'north':   51.6115028,
  'east':    7.1796486,
  'south':   51.5508324,
  'west':    7.1129372
}, {'zipCode': '45701', 'north': 51.6341591, 'east': 7.1636777, 'south': 51.5880441, 'west': 7.0666089}, {
  'zipCode': '45711',
  'north':   51.7206557,
  'east':    7.421320100000001,
  'south':   51.6136262,
  'west':    7.240012300000001
}, {'zipCode': '45721', 'north': 51.8213724, 'east': 7.3213176, 'south': 51.6954164, 'west': 7.0473016}, {
  'zipCode': '45731',
  'north':   51.6627617,
  'east':    7.465291199999999,
  'south':   51.58698480000001,
  'west':    7.319073600000001
}, {'zipCode': '45739', 'north': 51.7039088, 'east': 7.2973639, 'south': 51.6241263, 'west': 7.1902964}, {
  'zipCode': '45768',
  'north':   51.7002239,
  'east':    7.1073261,
  'south':   51.6149455,
  'west':    7.022661
}, {'zipCode': '45770', 'north': 51.7048009, 'east': 7.2056326, 'south': 51.6221361, 'west': 7.0698568}, {
  'zipCode': '45772',
  'north':   51.7203314,
  'east':    7.1671828,
  'south':   51.6522156,
  'west':    7.066431400000001
}, {'zipCode': '45879', 'north': 51.5154238, 'east': 7.1085054, 'south': 51.495875600000005, 'west': 7.0797338}, {
  'zipCode': '45881',
  'north':   51.54436,
  'east':    7.1049306,
  'south':   51.5099539,
  'west':    7.0446995
}, {'zipCode': '45883', 'north': 51.5275248, 'east': 7.0868675, 'south': 51.4967003, 'west': 7.0238858}, {
  'zipCode': '45884',
  'north':   51.5021507,
  'east':    7.10162,
  'south':   51.4810458,
  'west':    7.0670634
}, {'zipCode': '45886', 'north': 51.5152899, 'east': 7.1441028, 'south': 51.4808012, 'west': 7.0976295}, {
  'zipCode': '45888',
  'north':   51.5246584,
  'east':    7.1407766,
  'south':   51.5057567,
  'west':    7.1019016
}, {'zipCode': '45889', 'north': 51.5500658, 'east': 7.1372295, 'south': 51.523826, 'west': 7.0831295}, {
  'zipCode': '45891',
  'north':   51.5771346,
  'east':    7.1061437000000005,
  'south':   51.539004,
  'west':    7.0592301
}, {'zipCode': '45892', 'north': 51.5942417, 'east': 7.1444641, 'south': 51.5479296, 'west': 7.075654200000001}, {
  'zipCode': '45894',
  'north':   51.6003715,
  'east':    7.0861405,
  'south':   51.5602448,
  'west':    7.011907700000001
}, {'zipCode': '45896', 'north': 51.6315932, 'east': 7.0775642, 'south': 51.5789559, 'west': 6.987499500000001}, {
  'zipCode': '45897',
  'north':   51.5792177,
  'east':    7.0743729,
  'south':   51.5298636,
  'west':    7.0131213
}, {'zipCode': '45899', 'north': 51.55843320000001, 'east': 7.0610974, 'south': 51.5178529, 'west': 7.0095818}, {
  'zipCode': '45964',
  'north':   51.586945400000005,
  'east':    7.018525400000001,
  'south':   51.5448048,
  'west':    6.936779
}, {'zipCode': '45966', 'north': 51.6235979, 'east': 7.0135941, 'south': 51.5573302, 'west': 6.9208602}, {
  'zipCode': '45968',
  'north':   51.5734814,
  'east':    7.0321195,
  'south':   51.5326431,
  'west':    6.9821314
}, {'zipCode': '46045', 'north': 51.4824743, 'east': 6.881539400000001, 'south': 51.4576751, 'west': 6.8417979}, {
  'zipCode': '46047',
  'north':   51.4963015,
  'east':    6.9009366,
  'south':   51.4635095,
  'west':    6.861709
}, {'zipCode': '46049', 'north': 51.4962574, 'east': 6.8646149, 'south': 51.4486973, 'west': 6.8086887}, {
  'zipCode': '46117',
  'north':   51.5133855,
  'east':    6.930457800000001,
  'south':   51.4810601,
  'west':    6.8584822
}, {'zipCode': '46119', 'north': 51.538940200000006, 'east': 6.9011362, 'south': 51.5060103, 'west': 6.864827100000001}, {
  'zipCode': '46145',
  'north':   51.5640376,
  'east':    6.8792813,
  'south':   51.5003102,
  'west':    6.8360441000000005
}, {'zipCode': '46147', 'north': 51.5799378, 'east': 6.8685019, 'south': 51.50960820000001, 'west': 6.7772836}, {
  'zipCode': '46149',
  'north':   51.5385638,
  'east':    6.865033400000001,
  'south':   51.4853511,
  'west':    6.8092292
}, {'zipCode': '46236', 'north': 51.5382258, 'east': 6.9420242, 'south': 51.5114145, 'west': 6.9076297}, {
  'zipCode': '46238',
  'north':   51.5412863,
  'east':    6.9991075,
  'south':   51.501811200000006,
  'west':    6.9344743
}, {'zipCode': '46240', 'north': 51.5635527, 'east': 6.9982874, 'south': 51.5258279, 'west': 6.8845928}, {
  'zipCode': '46242',
  'north':   51.555432,
  'east':    6.9584869,
  'south':   51.4970292,
  'west':    6.8691918
}, {'zipCode': '46244', 'north': 51.6448359, 'east': 6.9835846, 'south': 51.5521737, 'west': 6.8328951}, {
  'zipCode': '46282',
  'north':   51.6718354,
  'east':    7.044493200000001,
  'south':   51.6227637,
  'west':    6.8938575
}, {'zipCode': '46284', 'north': 51.7049415, 'east': 7.0568279, 'south': 51.6622113, 'west': 6.9008855}, {
  'zipCode': '46286',
  'north':   51.801026,
  'east':    7.0882141,
  'south':   51.6872118,
  'west':    6.9086558
}, {'zipCode': '46325', 'north': 51.9328675, 'east': 6.9236203, 'south': 51.7737275, 'west': 6.7323128}, {
  'zipCode': '46342',
  'north':   51.9421051,
  'east':    7.0505085,
  'south':   51.8509458,
  'west':    6.8813387
}, {'zipCode': '46348', 'north': 51.8028326, 'east': 6.9163033, 'south': 51.728899, 'west': 6.7542851}, {
  'zipCode': '46354',
  'north':   51.9938518,
  'east':    6.930842400000001,
  'south':   51.9076836,
  'west':    6.7540265
}, {'zipCode': '46359', 'north': 51.8698953, 'east': 7.025319, 'south': 51.7719395, 'west': 6.8887416}, {
  'zipCode': '46395',
  'north':   51.8489003,
  'east':    6.6568338,
  'south':   51.7898183,
  'west':    6.5141516
}, {'zipCode': '46397', 'north': 51.9158682, 'east': 6.7211799, 'south': 51.824297900000005, 'west': 6.6151557}, {
  'zipCode': '46399',
  'north':   51.9175908,
  'east':    6.6871536,
  'south':   51.8359639,
  'west':    6.472510600000001
}, {'zipCode': '46414', 'north': 51.90547, 'east': 6.7656671, 'south': 51.76849310000001, 'west': 6.6435562}, {
  'zipCode': '46419',
  'north':   51.8740311,
  'east':    6.5323314,
  'south':   51.802759,
  'west':    6.3868546
}, {'zipCode': '46446', 'north': 51.9052449, 'east': 6.389387300000001, 'south': 51.805451500000004, 'west': 6.103448}, {
  'zipCode': '46459',
  'north':   51.833328800000004,
  'east':    6.534187400000001,
  'south':   51.7020688,
  'west':    6.3023872
}, {'zipCode': '46483', 'north': 51.6830977, 'east': 6.6337224, 'south': 51.6430865, 'west': 6.5945133}, {
  'zipCode': '46485',
  'north':   51.7014547,
  'east':    6.7137325,
  'south':   51.62779700000001,
  'west':    6.6047835
}, {'zipCode': '46487', 'north': 51.72360650000001, 'east': 6.6262693, 'south': 51.6162996, 'west': 6.4603134}, {
  'zipCode': '46499',
  'north':   51.8165667,
  'east':    6.7728514,
  'south':   51.6906868,
  'west':    6.4821057
}, {'zipCode': '46509', 'north': 51.75519190000001, 'east': 6.5175012, 'south': 51.6133966, 'west': 6.354928100000001}, {
  'zipCode': '46514',
  'north':   51.7488165,
  'east':    6.9406456,
  'south':   51.631017,
  'west':    6.6997252
}, {'zipCode': '46519', 'north': 51.6317922, 'east': 6.5631427, 'south': 51.5548234, 'west': 6.4137525}, {
  'zipCode': '46535',
  'north':   51.5738795,
  'east':    6.7589276,
  'south':   51.5380434,
  'west':    6.6864934
}, {'zipCode': '46537', 'north': 51.589696100000005, 'east': 6.7673698, 'south': 51.564674, 'west': 6.7119314}, {
  'zipCode': '46539',
  'north':   51.6081392,
  'east':    6.866148700000001,
  'south':   51.5324525,
  'west':    6.7439487
}, {'zipCode': '46562', 'north': 51.6367526, 'east': 6.7219563, 'south': 51.56302800000001, 'west': 6.5869894}, {
  'zipCode': '46569',
  'north':   51.7141526,
  'east':    6.8715548,
  'south':   51.5851658,
  'west':    6.6694115
}, {'zipCode': '47051', 'north': 51.4422632, 'east': 6.7819638, 'south': 51.4200991, 'west': 6.7526192}, {
  'zipCode': '47053',
  'north':   51.433647,
  'east':    6.7759547,
  'south':   51.4077215,
  'west':    6.7248824
}, {'zipCode': '47055', 'north': 51.4145255, 'east': 6.8034517, 'south': 51.3890754, 'west': 6.7432387}, {
  'zipCode': '47057',
  'north':   51.4331776,
  'east':    6.8185992,
  'south':   51.3974965,
  'west':    6.7698835
}, {'zipCode': '47058', 'north': 51.4501741, 'east': 6.8183095, 'south': 51.4320839, 'west': 6.7754609}, {
  'zipCode': '47059',
  'north':   51.4530838,
  'east':    6.7817409,
  'south':   51.4251829,
  'west':    6.7117315
}, {'zipCode': '47119', 'north': 51.4729437, 'east': 6.7532081, 'south': 51.4426758, 'west': 6.7171735}, {
  'zipCode': '47137',
  'north':   51.4860784,
  'east':    6.794171,
  'south':   51.457103200000006,
  'west':    6.7383235
}, {'zipCode': '47138', 'north': 51.4873355, 'east': 6.8221771, 'south': 51.4460279, 'west': 6.7430949}, {
  'zipCode': '47139',
  'north':   51.4960062,
  'east':    6.7610892,
  'south':   51.4667225,
  'west':    6.6809317
}, {'zipCode': '47166', 'north': 51.5035605, 'east': 6.794171, 'south': 51.4809003, 'west': 6.713864500000001}, {
  'zipCode': '47167',
  'north':   51.5197788,
  'east':    6.8243237,
  'south':   51.4836652,
  'west':    6.7688222
}, {'zipCode': '47169', 'north': 51.5314913, 'east': 6.7869961, 'south': 51.4972171, 'west': 6.7219503}, {
  'zipCode': '47178',
  'north':   51.5600691,
  'east':    6.7475036,
  'south':   51.5283168,
  'west':    6.676368
}, {'zipCode': '47179', 'north': 51.5380434, 'east': 6.784769500000001, 'south': 51.5113151, 'west': 6.6888857}, {
  'zipCode': '47198',
  'north':   51.4708793,
  'east':    6.7242607,
  'south':   51.4319382,
  'west':    6.6683834
}, {'zipCode': '47199', 'north': 51.5244203, 'east': 6.7250721, 'south': 51.4561327, 'west': 6.6278433}, {
  'zipCode': '47226',
  'north':   51.4151561,
  'east':    6.7432387,
  'south':   51.3965259,
  'west':    6.6965646
}, {'zipCode': '47228', 'north': 51.4369915, 'east': 6.7375274, 'south': 51.3976054, 'west': 6.6618643}, {
  'zipCode': '47229',
  'north':   51.4077215,
  'east':    6.7440643,
  'south':   51.3699114,
  'west':    6.668157100000001
}, {'zipCode': '47239', 'north': 51.4196614, 'east': 6.6900802, 'south': 51.382325, 'west': 6.6256311}, {
  'zipCode': '47249',
  'north':   51.3958555,
  'east':    6.7908699,
  'south':   51.3701079,
  'west':    6.7255155
}, {'zipCode': '47259', 'north': 51.3815348, 'east': 6.7699968, 'south': 51.3329386, 'west': 6.6541922}, {
  'zipCode': '47269',
  'north':   51.3805949,
  'east':    6.8302507,
  'south':   51.3425835,
  'west':    6.7545912
}, {'zipCode': '47279', 'north': 51.4014036, 'east': 6.8170306, 'south': 51.3673432, 'west': 6.7844643}, {
  'zipCode': '47441',
  'north':   51.4699069,
  'east':    6.677693,
  'south':   51.4287051,
  'west':    6.5793222
}, {'zipCode': '47443', 'north': 51.4893265, 'east': 6.6787416, 'south': 51.4433851, 'west': 6.6351229}, {
  'zipCode': '47445',
  'north':   51.5237804,
  'east':    6.644424,
  'south':   51.4612658,
  'west':    6.571465
}, {'zipCode': '47447', 'north': 51.4445939, 'east': 6.6690475, 'south': 51.3907947, 'west': 6.5583684}, {
  'zipCode': '47475',
  'north':   51.5681317,
  'east':    6.5902025,
  'south':   51.4720758,
  'west':    6.4484129
}, {'zipCode': '47495', 'north': 51.624742, 'east': 6.6995890000000005, 'south': 51.5035306, 'west': 6.5335115}, {
  'zipCode': '47506',
  'north':   51.4814519,
  'east':    6.599189,
  'south':   51.3891444,
  'west':    6.4975161
}, {'zipCode': '47509', 'north': 51.4861618, 'east': 6.5218602, 'south': 51.4162794, 'west': 6.41815}, {
  'zipCode': '47533',
  'north':   51.8654512,
  'east':    6.2449076,
  'south':   51.7318724,
  'west':    6.0368354
}, {'zipCode': '47546', 'north': 51.8278322, 'east': 6.4113303, 'south': 51.6872332, 'west': 6.2356097}, {
  'zipCode': '47551',
  'north':   51.8127104,
  'east':    6.2844429,
  'south':   51.7118398,
  'west':    6.1156615
}, {'zipCode': '47559', 'north': 51.8451232, 'east': 6.0900107, 'south': 51.723567900000006, 'west': 5.9450447}, {
  'zipCode': '47574',
  'north':   51.7480287,
  'east':    6.2293301,
  'south':   51.6138012,
  'west':    6.0260539
}, {'zipCode': '47589', 'north': 51.7152291, 'east': 6.3811624, 'south': 51.6361009, 'west': 6.2113803}, {
  'zipCode': '47608',
  'north':   51.5765723,
  'east':    6.4205949,
  'south':   51.4671314,
  'west':    6.2006167
}, {'zipCode': '47623', 'north': 51.6024911, 'east': 6.284459, 'south': 51.5503958, 'west': 6.1911318}, {
  'zipCode': '47624',
  'north':   51.5740597,
  'east':    6.2431149,
  'south':   51.5266508,
  'west':    6.1570325
}, {'zipCode': '47625', 'north': 51.5952093, 'east': 6.3598882, 'south': 51.5374374, 'west': 6.2387447}, {
  'zipCode': '47626',
  'north':   51.6195304,
  'east':    6.377981,
  'south':   51.5687998,
  'west':    6.2431995
}, {'zipCode': '47627', 'north': 51.653405400000004, 'east': 6.3124556, 'south': 51.6009943, 'west': 6.2400033}, {
  'zipCode': '47638',
  'north':   51.4883877,
  'east':    6.3236809,
  'south':   51.3548305,
  'west':    6.205257
}, {'zipCode': '47647', 'north': 51.4908201, 'east': 6.473607, 'south': 51.4056456, 'west': 6.314295500000001}, {
  'zipCode': '47652',
  'north':   51.6782383,
  'east':    6.2564912,
  'south':   51.5608248,
  'west':    6.0913991
}, {'zipCode': '47661', 'north': 51.5759693, 'east': 6.4977174, 'south': 51.4664861, 'west': 6.377965800000001}, {
  'zipCode': '47665',
  'north':   51.6732827,
  'east':    6.4434011,
  'south':   51.5602908,
  'west':    6.2919939
}, {'zipCode': '47669', 'north': 51.4472629, 'east': 6.3967057, 'south': 51.3565176, 'west': 6.2671307}, {
  'zipCode': '47798',
  'north':   51.3430843,
  'east':    6.5722526,
  'south':   51.3230474,
  'west':    6.5433816
}, {'zipCode': '47799', 'north': 51.3488972, 'east': 6.5915002, 'south': 51.3260178, 'west': 6.5650988}, {
  'zipCode': '47800',
  'north':   51.3659795,
  'east':    6.6341966,
  'south':   51.3325294,
  'west':    6.5761029
}, {'zipCode': '47802', 'north': 51.4015337, 'east': 6.635977600000001, 'south': 51.3539108, 'west': 6.5281345}, {
  'zipCode': '47803',
  'north':   51.3659356,
  'east':    6.5780136,
  'south':   51.3284903,
  'west':    6.518325
}, {'zipCode': '47804', 'north': 51.3520843, 'east': 6.5530746, 'south': 51.2866963, 'west': 6.4887667}, {
  'zipCode': '47805',
  'north':   51.3315434,
  'east':    6.5975024,
  'south':   51.3041533,
  'west':    6.5415903
}, {'zipCode': '47807', 'north': 51.3222373, 'east': 6.6214491, 'south': 51.2857809, 'west': 6.5401117}, {
  'zipCode': '47809',
  'north':   51.3499386,
  'east':    6.7062122,
  'south':   51.308349,
  'west':    6.5879355
}, {'zipCode': '47829', 'north': 51.3924551, 'east': 6.6795804, 'south': 51.3437238, 'west': 6.6100941}, {
  'zipCode': '47839',
  'north':   51.405491,
  'east':    6.5590379,
  'south':   51.339074,
  'west':    6.4779483
}, {'zipCode': '47877', 'north': 51.299894, 'east': 6.6259906, 'south': 51.2226198, 'west': 6.4129717}, {
  'zipCode': '47906',
  'north':   51.4250196,
  'east':    6.5355668,
  'south':   51.3305748,
  'west':    6.3584123
}, {'zipCode': '47918', 'north': 51.3492985, 'east': 6.5156879, 'south': 51.2775364, 'west': 6.3871285}, {
  'zipCode': '47929',
  'north':   51.3793364,
  'east':    6.4062553,
  'south':   51.3045704,
  'west':    6.3040466
}, {'zipCode': '48143', 'north': 51.967893, 'east': 7.6373048, 'south': 51.9532359, 'west': 7.613644}, {
  'zipCode': '48145',
  'north':   51.9801364,
  'east':    7.6678939,
  'south':   51.9532751,
  'west':    7.6353047
}, {'zipCode': '48147', 'north': 51.989764, 'east': 7.6617392, 'south': 51.9617919, 'west': 7.611908500000001}, {
  'zipCode': '48149',
  'north':   51.9845641,
  'east':    7.624461900000001,
  'south':   51.9427259,
  'west':    7.5751819
}, {'zipCode': '48151', 'north': 51.9572538, 'east': 7.628562800000001, 'south': 51.9221375, 'west': 7.591347000000001}, {
  'zipCode': '48153',
  'north':   51.9553776,
  'east':    7.644395600000001,
  'south':   51.9194591,
  'west':    7.6036701
}, {'zipCode': '48155', 'north': 51.9906058, 'east': 7.7231164, 'south': 51.9163722, 'west': 7.632622}, {
  'zipCode': '48157',
  'north':   52.05287250000001,
  'east':    7.747446899999999,
  'south':   51.9398493,
  'west':    7.6150704
}, {'zipCode': '48159', 'north': 52.060025100000004, 'east': 7.637614900000001, 'south': 51.97016390000001, 'west': 7.558012000000001}, {
  'zipCode': '48161',
  'north':   52.0456581,
  'east':    7.5970249,
  'south':   51.933151,
  'west':    7.473785300000001
}, {'zipCode': '48163', 'north': 51.942160200000004, 'east': 7.649127499999999, 'south': 51.8401448, 'west': 7.5031954999999995}, {
  'zipCode': '48165',
  'north':   51.9254039,
  'east':    7.709925700000001,
  'south':   51.8690703,
  'west':    7.6039684
}, {'zipCode': '48167', 'north': 51.9568001, 'east': 7.774363400000001, 'south': 51.8967047, 'west': 7.6530905}, {
  'zipCode': '48231',
  'north':   52.0518722,
  'east':    8.1022066,
  'south':   51.8517845,
  'west':    7.8570445
}, {'zipCode': '48249', 'north': 51.926259800000004, 'east': 7.433871199999999, 'south': 51.7785497, 'west': 7.0997144}, {
  'zipCode': '48268',
  'north':   52.1520083,
  'east':    7.755589500000001,
  'south':   52.0325628,
  'west':    7.523374300000001
}, {'zipCode': '48282', 'north': 52.2304137, 'east': 7.634712500000001, 'south': 52.1289372, 'west': 7.432431900000001}, {
  'zipCode': '48291',
  'north':   52.0643375,
  'east':    7.896401900000001,
  'south':   51.930381,
  'west':    7.703817999999999
}, {'zipCode': '48301', 'north': 51.9706074, 'east': 7.4624403, 'south': 51.8769208, 'west': 7.2540982}, {
  'zipCode': '48308',
  'north':   51.9415368,
  'east':    7.6014986,
  'south':   51.7719369,
  'west':    7.4203711
}, {'zipCode': '48317', 'north': 51.878475200000004, 'east': 7.8173479, 'south': 51.7237684, 'west': 7.611365800000001}, {
  'zipCode': '48324',
  'north':   51.9079045,
  'east':    7.8895597,
  'south':   51.8012636,
  'west':    7.6868526
}, {'zipCode': '48329', 'north': 52.009593, 'east': 7.5226519000000005, 'south': 51.9315439, 'west': 7.3651605}, {
  'zipCode': '48336',
  'north':   52.0683295,
  'east':    8.1143901,
  'south':   51.95586850000001,
  'west':    7.9762567
}, {'zipCode': '48341', 'north': 52.0897505, 'east': 7.556333900000001, 'south': 51.9947748, 'west': 7.4019027}, {
  'zipCode': '48346',
  'north':   52.104005,
  'east':    7.9209662000000005,
  'south':   51.9996697,
  'west':    7.7245677
}, {'zipCode': '48351', 'north': 51.9686277, 'east': 7.9185016, 'south': 51.8727662, 'west': 7.756699200000001}, {
  'zipCode': '48356',
  'north':   52.1437528,
  'east':    7.549949100000001,
  'south':   52.0627812,
  'west':    7.4270934
}, {'zipCode': '48361', 'north': 51.957265, 'east': 8.1734587, 'south': 51.893109, 'west': 8.0693268}, {
  'zipCode': '48366',
  'north':   52.0897388,
  'east':    7.4181809,
  'south':   52.0266019,
  'west':    7.290456899999999
}, {'zipCode': '48369', 'north': 52.2244433, 'east': 7.7133906, 'south': 52.1362833, 'west': 7.554958500000001}, {
  'zipCode': '48429',
  'north':   52.3109699,
  'east':    7.4868084,
  'south':   52.2621424,
  'west':    7.4297101
}, {'zipCode': '48431', 'north': 52.2971339, 'east': 7.4581447, 'south': 52.2539601, 'west': 7.3963299}, {
  'zipCode': '48432',
  'north':   52.3419237,
  'east':    7.586097400000001,
  'south':   52.1918785,
  'west':    7.365507600000001
}, {'zipCode': '48455', 'north': 52.3728568, 'east': 7.2080171, 'south': 52.2412156, 'west': 7.0262393}, {
  'zipCode': '48465',
  'north':   52.4489368,
  'east':    7.3173127,
  'south':   52.2638103,
  'west':    7.094602900000001
}, {'zipCode': '48477', 'north': 52.3707896, 'east': 7.680595200000001, 'south': 52.2065111, 'west': 7.4579441}, {
  'zipCode': '48480',
  'north':   52.4441946,
  'east':    7.6042995,
  'south':   52.3333783,
  'west':    7.3763165
}, {'zipCode': '48485', 'north': 52.2932201, 'east': 7.445213300000001, 'south': 52.1803595, 'west': 7.324802}, {
  'zipCode': '48488',
  'north':   52.4826086,
  'east':    7.4266440000000005,
  'south':   52.3338764,
  'west':    7.2125181000000005
}, {'zipCode': '48493', 'north': 52.2812926, 'east': 7.3882889, 'south': 52.18700630000001, 'west': 7.216446800000001}, {
  'zipCode': '48496',
  'north':   52.47498170000001,
  'east':    7.713755899999999,
  'south':   52.3398769,
  'west':    7.5394893
}, {'zipCode': '48499', 'north': 52.3507678, 'east': 7.4377181, 'south': 52.2802205, 'west': 7.257647600000001}, {
  'zipCode': '48527',
  'north':   52.5275094,
  'east':    7.1003344,
  'south':   52.4061527,
  'west':    6.9984549
}, {'zipCode': '48529', 'north': 52.4427508, 'east': 7.1355536, 'south': 52.3597275, 'west': 7.0335904}, {
  'zipCode': '48531',
  'north':   52.4992096,
  'east':    7.203064900000001,
  'south':   52.36725220000001,
  'west':    7.0647475
}, {'zipCode': '48565', 'north': 52.1945632, 'east': 7.4556055, 'south': 52.0811251, 'west': 7.260932800000001}, {
  'zipCode': '48599',
  'north':   52.2428214,
  'east':    7.1028087,
  'south':   52.13486460000001,
  'west':    6.950419
}, {'zipCode': '48607', 'north': 52.2664878, 'east': 7.2967451, 'south': 52.163667, 'west': 7.0945983}, {
  'zipCode': '48612',
  'north':   52.1434063,
  'east':    7.356688999999999,
  'south':   52.0565054,
  'west':    7.241764
}, {'zipCode': '48619', 'north': 52.1969502, 'east': 7.174366100000001, 'south': 52.0800018, 'west': 7.0135079}, {
  'zipCode': '48624',
  'north':   52.1292158,
  'east':    7.2744895000000005,
  'south':   52.0430301,
  'west':    7.1021695
}, {'zipCode': '48629', 'north': 52.1848087, 'east': 7.2719928, 'south': 52.1159908, 'west': 7.1352316}, {
  'zipCode': '48653',
  'north':   51.9879097,
  'east':    7.2611726,
  'south':   51.8462349,
  'west':    7.0557333
}, {'zipCode': '48683', 'north': 52.1811292, 'east': 7.0672986, 'south': 52.0210558, 'west': 6.866944800000001}, {
  'zipCode': '48691',
  'north':   52.1279685,
  'east':    6.910965,
  'south':   51.9935274,
  'west':    6.6867425
}, {'zipCode': '48703', 'north': 52.0463158, 'east': 7.0504137, 'south': 51.9618875, 'west': 6.8284346}, {
  'zipCode': '48712',
  'north':   52.0011376,
  'east':    7.0901859,
  'south':   51.8836663,
  'west':    6.9255724
}, {'zipCode': '48720', 'north': 52.0621527, 'east': 7.335940000000001, 'south': 51.9741397, 'west': 7.0463004}, {
  'zipCode': '48727',
  'north':   52.0406969,
  'east':    7.4345461,
  'south':   51.9366788,
  'west':    7.211489200000001
}, {'zipCode': '48734', 'north': 51.8882603, 'east': 7.173805300000001, 'south': 51.7769234, 'west': 6.9778344}, {
  'zipCode': '48739',
  'north':   52.090286000000006,
  'east':    7.1854659,
  'south':   52.0058371,
  'west':    7.0392732
}, {'zipCode': '49074', 'north': 52.2885914, 'east': 8.071834, 'south': 52.2634775, 'west': 8.0382286}, {
  'zipCode': '49076',
  'north':   52.3055084,
  'east':    8.0433228,
  'south':   52.2726292,
  'west':    7.929712100000001
}, {'zipCode': '49078', 'north': 52.2829928, 'east': 8.0405856, 'south': 52.2327086, 'west': 7.957493499999999}, {
  'zipCode': '49080',
  'north':   52.272147,
  'east':    8.0527366,
  'south':   52.2423346,
  'west':    8.0104219
}, {'zipCode': '49082', 'north': 52.269295, 'east': 8.0934119, 'south': 52.2241599, 'west': 8.0061316}, {
  'zipCode': '49084',
  'north':   52.2940096,
  'east':    8.1094211,
  'south':   52.2563211,
  'west':    8.0577012
}, {'zipCode': '49086', 'north': 52.3017663, 'east': 8.1806602, 'south': 52.2182398, 'west': 8.066145}, {
  'zipCode': '49088',
  'north':   52.3106818,
  'east':    8.1053072,
  'south':   52.2820111,
  'west':    8.0362416
}, {'zipCode': '49090', 'north': 52.3374348, 'east': 8.0999685, 'south': 52.283143700000004, 'west': 7.9658331}, {
  'zipCode': '49124',
  'north':   52.240231200000004,
  'east':    8.1518117,
  'south':   52.1676938,
  'west':    7.9895518
}, {'zipCode': '49134', 'north': 52.375781, 'east': 8.1000706, 'south': 52.3155546, 'west': 7.9367557}, {
  'zipCode': '49143',
  'north':   52.3210878,
  'east':    8.3347272,
  'south':   52.20258,
  'west':    8.1217155
}, {'zipCode': '49152', 'north': 52.3838799, 'east': 8.4707677, 'south': 52.272637200000005, 'west': 8.290558}, {
  'zipCode': '49163',
  'north':   52.50034740000001,
  'east':    8.3923886,
  'south':   52.3270498,
  'west':    8.1776191
}, {'zipCode': '49170', 'north': 52.2236889, 'east': 8.0412806, 'south': 52.1711156, 'west': 7.899818600000001}, {
  'zipCode': '49176',
  'north':   52.214366600000005,
  'east':    8.235962,
  'south':   52.1170844,
  'west':    8.1077564
}, {'zipCode': '49179', 'north': 52.4525147, 'east': 8.2962627, 'south': 52.3029497, 'west': 8.1256752}, {
  'zipCode': '49186',
  'north':   52.1781688,
  'east':    8.1168188,
  'south':   52.1129796,
  'west':    7.9972994
}, {'zipCode': '49191', 'north': 52.378219200000004, 'east': 8.2090604, 'south': 52.2819625, 'west': 8.0937937}, {
  'zipCode': '49196',
  'north':   52.1373073,
  'east':    8.1350524,
  'south':   52.0570609,
  'west':    8.0195864
}, {'zipCode': '49201', 'north': 52.1525961, 'east': 8.2679137, 'south': 52.0717382, 'west': 8.1476078}, {
  'zipCode': '49205',
  'north':   52.27583,
  'east':    8.0127487,
  'south':   52.2081958,
  'west':    7.921204099999999
}, {'zipCode': '49214', 'north': 52.1249022, 'east': 8.18649, 'south': 52.0719175, 'west': 8.1298392}, {
  'zipCode': '49219',
  'north':   52.1165129,
  'east':    8.0614164,
  'south':   52.03589,
  'west':    7.884974099999999
}, {'zipCode': '49324', 'north': 52.2768045, 'east': 8.3995846, 'south': 52.1486125, 'west': 8.2614351}, {
  'zipCode': '49326',
  'north':   52.23313,
  'east':    8.4498666,
  'south':   52.1079455,
  'west':    8.2030537
}, {'zipCode': '49328', 'north': 52.2831257, 'east': 8.5173146, 'south': 52.1533713, 'west': 8.3293988}, {
  'zipCode': '49356',
  'north':   52.68954140000001,
  'east':    8.4476026,
  'south':   52.5320857,
  'west':    8.293859
}, {'zipCode': '49377', 'north': 52.8005782, 'east': 8.383966500000001, 'south': 52.6868118, 'west': 8.1946843}, {
  'zipCode': '49393',
  'north':   52.7196529,
  'east':    8.3117191,
  'south':   52.6029492,
  'west':    8.1381703
}, {'zipCode': '49401', 'north': 52.5778025, 'east': 8.3494286, 'south': 52.4777798, 'west': 8.1267398}, {
  'zipCode': '49406',
  'north':   52.7788012,
  'east':    8.663698,
  'south':   52.6241653,
  'west':    8.406286
}, {'zipCode': '49413', 'north': 52.703744, 'east': 8.1914145, 'south': 52.5981259, 'west': 8.0183789}, {
  'zipCode': '49419',
  'north':   52.5936961,
  'east':    8.774309,
  'south':   52.4990723,
  'west':    8.4993441
}, {'zipCode': '49424', 'north': 52.8261881, 'east': 8.4737541, 'south': 52.7122338, 'west': 8.3090605}, {
  'zipCode': '49429',
  'north':   52.884365,
  'east':    8.4069659,
  'south':   52.7759456,
  'west':    8.2348763
}, {'zipCode': '49434', 'north': 52.5886544, 'east': 8.2034092, 'south': 52.4330971, 'west': 8.0136624}, {
  'zipCode': '49439',
  'north':   52.6385081,
  'east':    8.3437868,
  'south':   52.5567326,
  'west':    8.1482545
}, {'zipCode': '49448', 'north': 52.5300687, 'east': 8.5055368, 'south': 52.4428048, 'west': 8.2971102}, {
  'zipCode': '49451',
  'north':   52.6248245,
  'east':    8.1935967,
  'south':   52.52404680000001,
  'west':    8.0418993
}, {'zipCode': '49453', 'north': 52.6596634, 'east': 8.6467079, 'south': 52.522403000000004, 'west': 8.4158338}, {
  'zipCode': '49456',
  'north':   52.7720575,
  'east':    8.2525487,
  'south':   52.6962094,
  'west':    8.0463177
}, {'zipCode': '49457', 'north': 52.7218794, 'east': 8.4949175, 'south': 52.6327475, 'west': 8.3354033}, {
  'zipCode': '49459',
  'north':   52.5494337,
  'east':    8.458803,
  'south':   52.5029117,
  'west':    8.310661
}, {'zipCode': '49477', 'north': 52.3015722, 'east': 7.7460243, 'south': 52.2606414, 'west': 7.6692207}, {
  'zipCode': '49479',
  'north':   52.3460605,
  'east':    7.842972400000001,
  'south':   52.1980421,
  'west':    7.603644700000001
}, {'zipCode': '49492', 'north': 52.3806587, 'east': 7.921624, 'south': 52.2498679, 'west': 7.7941675}, {
  'zipCode': '49497',
  'north':   52.3796936,
  'east':    7.817387999999999,
  'south':   52.27759710000001,
  'west':    7.7282306
}, {'zipCode': '49504', 'north': 52.365814, 'east': 7.9913307, 'south': 52.232512, 'west': 7.8813051000000005}, {
  'zipCode': '49509',
  'north':   52.4009294,
  'east':    7.7833289,
  'south':   52.3228414,
  'west':    7.6255123
}, {'zipCode': '49525', 'north': 52.218864, 'east': 7.9292019, 'south': 52.1146187, 'west': 7.722589400000001}, {
  'zipCode': '49536',
  'north':   52.1803406,
  'east':    8.0252107,
  'south':   52.0831685,
  'west':    7.837260900000001
}, {'zipCode': '49545', 'north': 52.26080880000001, 'east': 7.9279961, 'south': 52.1768172, 'west': 7.6857876}, {
  'zipCode': '49549',
  'north':   52.179126,
  'east':    7.850368900000001,
  'south':   52.0933344,
  'west':    7.700341599999999
}, {'zipCode': '49565', 'north': 52.481047, 'east': 8.158788800000002, 'south': 52.3591395, 'west': 7.8421762}, {
  'zipCode': '49577',
  'north':   52.6376849,
  'east':    7.9390017,
  'south':   52.4755394,
  'west':    7.7523698
}, {'zipCode': '49584', 'north': 52.5471428, 'east': 7.797112799999999, 'south': 52.4566679, 'west': 7.6044503}, {
  'zipCode': '49586',
  'north':   52.5324029,
  'east':    7.891713000000001,
  'south':   52.3689503,
  'west':    7.7186995
}, {'zipCode': '49593', 'north': 52.6188271, 'east': 7.9980445, 'south': 52.51834550000001, 'west': 7.884835800000001}, {
  'zipCode': '49594',
  'north':   52.538039,
  'east':    8.0166395,
  'south':   52.4533021,
  'west':    7.8924263
}, {'zipCode': '49596', 'north': 52.6271863, 'east': 8.060327, 'south': 52.5309684, 'west': 7.975344300000001}, {
  'zipCode': '49597',
  'north':   52.5402684,
  'east':    8.0630013,
  'south':   52.4568853,
  'west':    7.9703561
}, {'zipCode': '49599', 'north': 52.47739, 'east': 7.790385599999999, 'south': 52.3918858, 'west': 7.684452299999999}, {
  'zipCode': '49610',
  'north':   52.6966336,
  'east':    8.0346016,
  'south':   52.6591983,
  'west':    7.9033907999999995
}, {'zipCode': '49624', 'north': 52.8141524, 'east': 7.888619000000001, 'south': 52.6772734, 'west': 7.635445400000001}, {
  'zipCode': '49626',
  'north':   52.6629339,
  'east':    7.809974900000001,
  'south':   52.5265533,
  'west':    7.607158200000001
}, {'zipCode': '49632', 'north': 52.7664132, 'east': 8.0777375, 'south': 52.6798097, 'west': 7.8324954}, {
  'zipCode': '49635',
  'north':   52.6735982,
  'east':    8.0873826,
  'south':   52.5926156,
  'west':    7.862807599999999
}, {'zipCode': '49637', 'north': 52.7096229, 'east': 7.9292343999999995, 'south': 52.6306602, 'west': 7.687694600000001}, {
  'zipCode': '49638',
  'north':   52.64659090000001,
  'east':    7.9228874,
  'south':   52.5850642,
  'west':    7.8294674
}, {'zipCode': '49661', 'north': 52.9232308, 'east': 8.1032214, 'south': 52.7958853, 'west': 7.964282300000001}, {
  'zipCode': '49681',
  'north':   53.0180754,
  'east':    8.125176,
  'south':   52.880654,
  'west':    7.9345462
}, {'zipCode': '49685', 'north': 52.9287967, 'east': 8.2587108, 'south': 52.786143, 'west': 8.0869136}, {
  'zipCode': '49688',
  'north':   52.8363472,
  'east':    8.0044259,
  'south':   52.7514267,
  'west':    7.792506800000001
}, {'zipCode': '49692', 'north': 52.8290439, 'east': 8.2010449, 'south': 52.7521543, 'west': 7.994616199999999}, {
  'zipCode': '49696',
  'north':   52.936603500000004,
  'east':    7.9999625,
  'south':   52.8168336,
  'west':    7.7921244
}, {'zipCode': '49699', 'north': 52.8797643, 'east': 7.8567821, 'south': 52.7831753, 'west': 7.6904096}, {
  'zipCode': '49716',
  'north':   52.774288000000006,
  'east':    7.4432698,
  'south':   52.622534,
  'west':    7.1351416
}, {'zipCode': '49733', 'north': 52.8821539, 'east': 7.38912, 'south': 52.7263942, 'west': 7.0669684}, {
  'zipCode': '49740',
  'north':   52.7508096,
  'east':    7.592979699999999,
  'south':   52.6085213,
  'west':    7.359829399999999
}, {'zipCode': '49744', 'north': 52.6566429, 'east': 7.4171968, 'south': 52.5495968, 'west': 7.1188632}, {
  'zipCode': '49751',
  'north':   52.929589,
  'east':    7.6661943,
  'south':   52.7619458,
  'west':    7.4161118
}, {'zipCode': '49757', 'north': 52.9437654, 'east': 7.8323982, 'south': 52.7851686, 'west': 7.5668693000000005}, {
  'zipCode': '49762',
  'north':   52.9231888,
  'east':    7.4403085,
  'south':   52.818615,
  'west':    7.1200686
}, {'zipCode': '49767', 'north': 52.760643800000004, 'east': 7.1917364, 'south': 52.5885494, 'west': 6.9955539}, {
  'zipCode': '49770',
  'north':   52.7259037,
  'east':    7.694899599999999,
  'south':   52.6095098,
  'west':    7.542665
}, {'zipCode': '49774', 'north': 52.798354700000004, 'east': 7.714315100000001, 'south': 52.6986031, 'west': 7.5000404}, {
  'zipCode': '49777',
  'north':   52.8541028,
  'east':    7.530532199999999,
  'south':   52.7168511,
  'west':    7.330798800000001
}, {'zipCode': '49779', 'north': 52.8908831, 'east': 7.310056199999999, 'south': 52.8281692, 'west': 7.1481927}, {
  'zipCode': '49808',
  'north':   52.5923854,
  'east':    7.332457800000001,
  'south':   52.4662018,
  'west':    7.1935481
}, {'zipCode': '49809', 'north': 52.5632474, 'east': 7.353804600000001, 'south': 52.4914538, 'west': 7.3031293999999995}, {
  'zipCode': '49811',
  'north':   52.6022057,
  'east':    7.4539855,
  'south':   52.4201232,
  'west':    7.303096799999999
}, {'zipCode': '49824', 'north': 52.6532736, 'east': 7.0062342, 'south': 52.5613272, 'west': 6.7100332}, {
  'zipCode': '49828',
  'north':   52.6065155,
  'east':    7.1089766,
  'south':   52.4366214,
  'west':    6.9177673
}, {'zipCode': '49832', 'north': 52.5376346, 'east': 7.6196281, 'south': 52.3963132, 'west': 7.4095603}, {
  'zipCode': '49835',
  'north':   52.602067,
  'east':    7.285612100000001,
  'south':   52.4439027,
  'west':    7.0838436
}, {'zipCode': '49838', 'north': 52.6182148, 'east': 7.622654700000001, 'south': 52.5017124, 'west': 7.4052232}, {
  'zipCode': '49843',
  'north':   52.5536556,
  'east':    6.9665535,
  'south':   52.43542570000001,
  'west':    6.7448373
}, {'zipCode': '49844', 'north': 52.6166011, 'east': 7.475165700000001, 'south': 52.5542514, 'west': 7.3798968}, {
  'zipCode': '49846',
  'north':   52.6181352,
  'east':    7.045408300000001,
  'south':   52.5496149,
  'west':    6.868233600000001
}, {'zipCode': '49847', 'north': 52.5632551, 'east': 6.8524678, 'south': 52.4675852, 'west': 6.68088}, {
  'zipCode': '49849',
  'north':   52.5741208,
  'east':    6.896334,
  'south':   52.5038535,
  'west':    6.7658642
}, {'zipCode': '50126', 'north': 50.9947424, 'east': 6.6721154, 'south': 50.9376449, 'west': 6.5635471}, {
  'zipCode': '50127',
  'north':   50.9604257,
  'east':    6.734955,
  'south':   50.9159819,
  'west':    6.6063579
}, {'zipCode': '50129', 'north': 51.0219049, 'east': 6.767401600000001, 'south': 50.9357969, 'west': 6.6353047}, {
  'zipCode': '50169',
  'north':   50.9254194,
  'east':    6.8052384,
  'south':   50.8357061,
  'west':    6.6858479
}, {'zipCode': '50170', 'north': 50.9237743, 'east': 6.7054208, 'south': 50.8376272, 'west': 6.549486}, {
  'zipCode': '50171',
  'north':   50.8995272,
  'east':    6.7332844000000005,
  'south':   50.8245397,
  'west':    6.606798900000001
}, {'zipCode': '50181', 'north': 51.0594769, 'east': 6.6603219, 'south': 50.9691042, 'west': 6.4528053}, {
  'zipCode': '50189',
  'north':   50.9763894,
  'east':    6.655046100000001,
  'south':   50.8905722,
  'west':    6.4788124
}, {'zipCode': '50226', 'north': 50.949560000000005, 'east': 6.855158, 'south': 50.8760716, 'west': 6.7199507}, {
  'zipCode': '50259',
  'north':   51.0659186,
  'east':    6.8447836,
  'south':   50.93874550000001,
  'west':    6.7111925
}, {'zipCode': '50321', 'north': 50.8515422, 'east': 6.9436983, 'south': 50.7848517, 'west': 6.8334592}, {
  'zipCode': '50354',
  'north':   50.9115756,
  'east':    6.9232481,
  'south':   50.836875400000004,
  'west':    6.7786616
}, {'zipCode': '50374', 'north': 50.862727, 'east': 6.8520105, 'south': 50.7171172, 'west': 6.6785891}, {
  'zipCode': '50389',
  'north':   50.8454279,
  'east':    7.0295988,
  'south':   50.7926777,
  'west':    6.9288951
}, {'zipCode': '50667', 'north': 50.9454841, 'east': 6.9660666, 'south': 50.9341181, 'west': 6.9398117}, {
  'zipCode': '50668',
  'north':   50.9592877,
  'east':    6.9755545,
  'south':   50.9416834,
  'west':    6.9495218
}, {'zipCode': '50670', 'north': 50.9592877, 'east': 6.9641039, 'south': 50.9400115, 'west': 6.9332667}, {
  'zipCode': '50672',
  'north':   50.9490499,
  'east':    6.9451822,
  'south':   50.93603800000001,
  'west':    6.9253122
}, {'zipCode': '50674', 'north': 50.9408339, 'east': 6.943476, 'south': 50.9228811, 'west': 6.9246978}, {
  'zipCode': '50676',
  'north':   50.9357129,
  'east':    6.9673692,
  'south':   50.9247807,
  'west':    6.9394933
}, {'zipCode': '50677', 'north': 50.9288646, 'east': 6.9617214, 'south': 50.9156903, 'west': 6.9404644}, {
  'zipCode': '50678',
  'north':   50.93301,
  'east':    6.97334,
  'south':   50.915386,
  'west':    6.951904
}, {'zipCode': '50679', 'north': 50.9546524, 'east': 6.9956111, 'south': 50.9172265, 'west': 6.9650465}, {
  'zipCode': '50733',
  'north':   50.9752393,
  'east':    6.9688318,
  'south':   50.9537445,
  'west':    6.941078000000001
}, {'zipCode': '50735', 'north': 51.0237721, 'east': 6.9990251, 'south': 50.9546524, 'west': 6.922418}, {
  'zipCode': '50737',
  'north':   51.014869700000006,
  'east':    6.9609662,
  'south':   50.9732277,
  'west':    6.9113605
}, {'zipCode': '50739', 'north': 51.00778360000001, 'east': 6.9507678, 'south': 50.9559433, 'west': 6.8914791}, {
  'zipCode': '50765',
  'north':   51.0519149,
  'east':    6.910438,
  'south':   50.9888203,
  'west':    6.8338701
}, {'zipCode': '50767', 'north': 51.0186446, 'east': 6.9113605, 'south': 50.9886406, 'west': 6.8577227}, {
  'zipCode': '50769',
  'north':   51.0849743,
  'east':    6.9739335,
  'south':   51.00778360000001,
  'west':    6.7725303
}, {'zipCode': '50823', 'north': 50.9628026, 'east': 6.9419107, 'south': 50.9390284, 'west': 6.9127242}, {
  'zipCode': '50825',
  'north':   50.9707104,
  'east':    6.9284177,
  'south':   50.9394217,
  'west':    6.8892554
}, {'zipCode': '50827', 'north': 50.9880737, 'east': 6.919112800000001, 'south': 50.9489064, 'west': 6.8734594}, {
  'zipCode': '50829',
  'north':   50.9970062,
  'east':    6.9038058,
  'south':   50.94721700000001,
  'west':    6.8325497
}, {'zipCode': '50858', 'north': 50.9446379, 'east': 6.8807687, 'south': 50.9038231, 'west': 6.8317472}, {
  'zipCode': '50859',
  'north':   50.97830170000001,
  'east':    6.8518814,
  'south':   50.9268446,
  'west':    6.8050715
}, {'zipCode': '50931', 'north': 50.9439593, 'east': 6.9321153, 'south': 50.9229727, 'west': 6.9078841}, {
  'zipCode': '50933',
  'north':   50.9567839,
  'east':    6.9125019,
  'south':   50.9287758,
  'west':    6.8495967
}, {'zipCode': '50935', 'north': 50.9368028, 'east': 6.9177084, 'south': 50.9098084, 'west': 6.8751521}, {
  'zipCode': '50937',
  'north':   50.9283413,
  'east':    6.9347949,
  'south':   50.898319,
  'west':    6.8799968
}, {'zipCode': '50939', 'north': 50.9258782, 'east': 6.9404644, 'south': 50.8932448, 'west': 6.907928}, {
  'zipCode': '50968',
  'north':   50.9178268,
  'east':    6.9905459,
  'south':   50.8851194,
  'west':    6.9433051
}, {'zipCode': '50969', 'north': 50.9229332, 'east': 6.9554598, 'south': 50.891181100000004, 'west': 6.9199838}, {
  'zipCode': '50996',
  'north':   50.89929920000001,
  'east':    7.0315837,
  'south':   50.8637366,
  'west':    6.9678547
}, {'zipCode': '50997', 'north': 50.8958533, 'east': 6.9981063, 'south': 50.8347276, 'west': 6.9128583}, {
  'zipCode': '50999',
  'north':   50.8944106,
  'east':    7.0515439,
  'south':   50.8496929,
  'west':    6.9906274
}, {'zipCode': '51061', 'north': 51.0226124, 'east': 7.0541062, 'south': 50.97401550000001, 'west': 6.9623841}, {
  'zipCode': '51063',
  'north':   50.9883175,
  'east':    7.0313635,
  'south':   50.9465134,
  'west':    6.9755545
}, {'zipCode': '51065', 'north': 50.9642387, 'east': 7.0273715, 'south': 50.9471126, 'west': 6.9954598}, {
  'zipCode': '51067',
  'north':   50.97864750000001,
  'east':    7.0668941,
  'south':   50.9509947,
  'west':    7.0154496
}, {'zipCode': '51069', 'north': 51.0211206, 'east': 7.101133700000001, 'south': 50.95388, 'west': 7.0094126}, {
  'zipCode': '51103',
  'north':   50.9525117,
  'east':    7.0416789,
  'south':   50.9277004,
  'west':    6.9879287
}, {'zipCode': '51105', 'north': 50.9374206, 'east': 7.018639000000001, 'south': 50.89543, 'west': 6.97334}, {
  'zipCode': '51107',
  'north':   50.9468398,
  'east':    7.1453536,
  'south':   50.9034848,
  'west':    7.0122769
}, {'zipCode': '51109', 'north': 50.9621264, 'east': 7.1111496, 'south': 50.927829300000006, 'west': 7.0357643}, {
  'zipCode': '51143',
  'north':   50.892466,
  'east':    7.0745673,
  'south':   50.8363553,
  'west':    6.9839464
}, {'zipCode': '51145', 'north': 50.90187730000001, 'east': 7.0961062, 'south': 50.8647824, 'west': 7.0603935}, {
  'zipCode': '51147',
  'north':   50.9164541,
  'east':    7.162028,
  'south':   50.8304573,
  'west':    7.0510886
}, {'zipCode': '51149', 'north': 50.922012, 'east': 7.1014322000000005, 'south': 50.8897311, 'west': 6.9967751}, {
  'zipCode': '51371',
  'north':   51.080536,
  'east':    6.9901286,
  'south':   51.0357355,
  'west':    6.8977575
}, {'zipCode': '51373', 'north': 51.062035, 'east': 7.0185938, 'south': 51.0111245, 'west': 6.9382558}, {
  'zipCode': '51375',
  'north':   51.0526797,
  'east':    7.0981928,
  'south':   51.0177296,
  'west':    7.025994
}, {'zipCode': '51377', 'north': 51.06809260000001, 'east': 7.1161151, 'south': 51.0160047, 'west': 7.0081283}, {
  'zipCode': '51379',
  'north':   51.0914579,
  'east':    7.0241886,
  'south':   51.054081,
  'west':    6.9781637000000005
}, {'zipCode': '51381', 'north': 51.0971563, 'east': 7.0760643, 'south': 51.0457054, 'west': 7.0080397}, {
  'zipCode': '51399',
  'north':   51.1190813,
  'east':    7.169214799999999,
  'south':   51.0635151,
  'west':    7.057659
}, {'zipCode': '51427', 'north': 50.9685907, 'east': 7.1633048, 'south': 50.9199924, 'west': 7.0859853}, {
  'zipCode': '51429',
  'north':   51.0050319,
  'east':    7.2545737,
  'south':   50.931751000000006,
  'west':    7.1293856
}, {'zipCode': '51465', 'north': 51.01833, 'east': 7.2033712, 'south': 50.9716426, 'west': 7.1108427}, {
  'zipCode': '51467',
  'north':   51.0269392,
  'east':    7.167090399999999,
  'south':   50.9976161,
  'west':    7.0617856
}, {'zipCode': '51469', 'north': 51.0065997, 'east': 7.1495895, 'south': 50.9658287, 'west': 7.0667688}, {
  'zipCode': '51491',
  'north':   50.9877251,
  'east':    7.398666900000001,
  'south':   50.90664350000001,
  'west':    7.1938575
}, {'zipCode': '51503', 'north': 50.9430972, 'east': 7.2472519, 'south': 50.8669327, 'west': 7.133527}, {
  'zipCode': '51515',
  'north':   51.0867592,
  'east':    7.339197800000001,
  'south':   50.9861661,
  'west':    7.1842111
}, {'zipCode': '51519', 'north': 51.0747049, 'east': 7.2103572, 'south': 51.0102242, 'west': 7.0908802}, {
  'zipCode': '51545',
  'north':   50.9292082,
  'east':    7.6861031,
  'south':   50.820236,
  'west':    7.5115916
}, {'zipCode': '51570', 'north': 50.8425694, 'east': 7.6827597, 'south': 50.73822260000001, 'west': 7.4683982}, {
  'zipCode': '51580',
  'north':   51.0114195,
  'east':    7.791742900000001,
  'south':   50.9005755,
  'west':    7.5892102999999995
}, {'zipCode': '51588', 'north': 50.9409024, 'east': 7.620869299999999, 'south': 50.8513694, 'west': 7.4604389}, {
  'zipCode': '51597',
  'north':   50.9172724,
  'east':    7.7664714,
  'south':   50.8185999,
  'west':    7.645826900000001
}, {'zipCode': '51598', 'north': 50.942325600000004, 'east': 7.849994099999999, 'south': 50.8389244, 'west': 7.735216100000001}, {
  'zipCode': '51643',
  'north':   51.0433184,
  'east':    7.5888641,
  'south':   51.0071453,
  'west':    7.5084629000000005
}, {'zipCode': '51645', 'north': 51.0335891, 'east': 7.6381743, 'south': 50.9731423, 'west': 7.4928902}, {
  'zipCode': '51647',
  'north':   51.086224900000005,
  'east':    7.713681600000001,
  'south':   51.0071453,
  'west':    7.4351341
}, {'zipCode': '51674', 'north': 50.9937032, 'east': 7.617065600000001, 'south': 50.9299743, 'west': 7.425722400000001}, {
  'zipCode': '51688',
  'north':   51.1873555,
  'east':    7.505016200000001,
  'south':   51.0465938,
  'west':    7.2735717
}, {'zipCode': '51702', 'north': 51.0640271, 'east': 7.735515399999999, 'south': 50.9966382, 'west': 7.616670300000001}, {
  'zipCode': '51709',
  'north':   51.1066365,
  'east':    7.6184887,
  'south':   51.04651760000001,
  'west':    7.4521302
}, {'zipCode': '51766', 'north': 51.0254985, 'east': 7.515377000000001, 'south': 50.9463469, 'west': 7.3259075}, {
  'zipCode': '51789',
  'north':   51.0791033,
  'east':    7.461660300000001,
  'south':   50.971094,
  'west':    7.2508875
}, {'zipCode': '52062', 'north': 50.7835478, 'east': 6.0970911, 'south': 50.76881, 'west': 6.0746397}, {
  'zipCode': '52064',
  'north':   50.781116600000004,
  'east':    6.0951822,
  'south':   50.7579859,
  'west':    6.061015
}, {'zipCode': '52066', 'north': 50.7744779, 'east': 6.1304208, 'south': 50.7421972, 'west': 6.0720627}, {
  'zipCode': '52068',
  'north':   50.7935084,
  'east':    6.1457515,
  'south':   50.7664069,
  'west':    6.1008512
}, {'zipCode': '52070', 'north': 50.813199, 'east': 6.1222427, 'south': 50.76854840000001, 'west': 6.0704272}, {
  'zipCode': '52072',
  'north':   50.8572449,
  'east':    6.0832712,
  'south':   50.7782321,
  'west':    6.0159425
}, {'zipCode': '52074', 'north': 50.814132, 'east': 6.0795647, 'south': 50.7183205, 'west': 5.9748624}, {
  'zipCode': '52076',
  'north':   50.7617101,
  'east':    6.2180747,
  'south':   50.6621399,
  'west':    6.0592867
}, {'zipCode': '52078', 'north': 50.7774716, 'east': 6.2055546, 'south': 50.7401486, 'west': 6.1172781}, {
  'zipCode': '52080',
  'north':   50.8085105,
  'east':    6.1932834,
  'south':   50.7612496,
  'west':    6.109553
}, {'zipCode': '52134', 'north': 50.9134609, 'east': 6.1454703, 'south': 50.8076786, 'west': 6.057427}, {
  'zipCode': '52146',
  'north':   50.8556717,
  'east':    6.2099676,
  'south':   50.8029221,
  'west':    6.0867835
}, {'zipCode': '52152', 'north': 50.6865439, 'east': 6.4196672, 'south': 50.5293571, 'west': 6.2457705}, {
  'zipCode': '52156',
  'north':   50.6117104,
  'east':    6.3802019,
  'south':   50.4943237,
  'west':    6.1740833
}, {'zipCode': '52159', 'north': 50.7053238, 'east': 6.2720942, 'south': 50.6238102, 'west': 6.1661074}, {
  'zipCode': '52222',
  'north':   50.8056741,
  'east':    6.2603494,
  'south':   50.7632195,
  'west':    6.1745342
}, {'zipCode': '52223', 'north': 50.7758794, 'east': 6.2481786, 'south': 50.71889720000001, 'west': 6.1901159}, {
  'zipCode': '52224',
  'north':   50.7888416,
  'east':    6.3656141,
  'south':   50.6846999,
  'west':    6.2024421
}, {'zipCode': '52249', 'north': 50.8846939, 'east': 6.3409387, 'south': 50.7782884, 'west': 6.1904859}, {
  'zipCode': '52349',
  'north':   50.8170093,
  'east':    6.504188,
  'south':   50.7826069,
  'west':    6.4312139
}, {'zipCode': '52351', 'north': 50.8199169, 'east': 6.5384131, 'south': 50.7596073, 'west': 6.4810081}, {
  'zipCode': '52353',
  'north':   50.8576045,
  'east':    6.5186845,
  'south':   50.8069757,
  'west':    6.3856331
}, {'zipCode': '52355', 'north': 50.8231083, 'east': 6.5066811, 'south': 50.7409797, 'west': 6.4004393}, {
  'zipCode': '52372',
  'north':   50.7823948,
  'east':    6.5489362,
  'south':   50.6934293,
  'west':    6.4187259
}, {'zipCode': '52379', 'north': 50.8355042, 'east': 6.4185633, 'south': 50.7564788, 'west': 6.298524}, {
  'zipCode': '52382',
  'north':   50.9526998,
  'east':    6.5359050000000005,
  'south':   50.84111740000001,
  'west':    6.4130331
}, {'zipCode': '52385', 'north': 50.7125743, 'east': 6.6014455, 'south': 50.6356567, 'west': 6.3720664}, {
  'zipCode': '52388',
  'north':   50.8381162,
  'east':    6.7183571,
  'south':   50.7654725,
  'west':    6.5208238
}, {'zipCode': '52391', 'north': 50.787999, 'east': 6.7025756, 'south': 50.6894052, 'west': 6.5182132}, {
  'zipCode': '52393',
  'north':   50.7746298,
  'east':    6.458430300000001,
  'south':   50.650682,
  'west':    6.2844599
}, {'zipCode': '52396', 'north': 50.664205700000004, 'east': 6.577289, 'south': 50.5976791, 'west': 6.3814238}, {
  'zipCode': '52399',
  'north':   50.89301,
  'east':    6.613844600000001,
  'south':   50.8016169,
  'west':    6.5020095
}, {'zipCode': '52428', 'north': 50.9785395, 'east': 6.4548901, 'south': 50.8784956, 'west': 6.2644865}, {
  'zipCode': '52441',
  'north':   51.02491810000001,
  'east':    6.3621875,
  'south':   50.9344094,
  'west':    6.1935632
}, {'zipCode': '52445', 'north': 51.0456677, 'east': 6.5002662, 'south': 50.9345422, 'west': 6.3371957}, {
  'zipCode': '52457',
  'north':   50.939942800000004,
  'east':    6.3354685,
  'south':   50.8551182,
  'west':    6.199735600000001
}, {'zipCode': '52459', 'north': 50.8961786, 'east': 6.4336119, 'south': 50.8332913, 'west': 6.3148026}, {
  'zipCode': '52477',
  'north':   50.8940929,
  'east':    6.230616,
  'south':   50.8417801,
  'west':    6.1218109
}, {'zipCode': '52499', 'north': 50.9498273, 'east': 6.2295162, 'south': 50.8852042, 'west': 6.1405743}, {
  'zipCode': '52511',
  'north':   51.0173661,
  'east':    6.2369032,
  'south':   50.929881,
  'west':    6.0150734
}, {'zipCode': '52525', 'north': 51.1221417, 'east': 6.213930100000001, 'south': 50.9964685, 'west': 5.968113500000001}, {
  'zipCode': '52531',
  'north':   50.9532669,
  'east':    6.1563636,
  'south':   50.8979324,
  'west':    6.0150734
}, {'zipCode': '52538', 'north': 51.066883000000004, 'east': 6.0811375, 'south': 50.9478831, 'west': 5.8663153}, {
  'zipCode': '53111',
  'north':   50.7491391,
  'east':    7.1123027,
  'south':   50.7312723,
  'west':    7.0854367
}, {'zipCode': '53113', 'north': 50.7363507, 'east': 7.1431597, 'south': 50.7038105, 'west': 7.092891600000001}, {
  'zipCode': '53115',
  'north':   50.73627040000001,
  'east':    7.1050294,
  'south':   50.7105035,
  'west':    7.0751679
}, {'zipCode': '53117', 'north': 50.7708455, 'east': 7.1063178, 'south': 50.7425124, 'west': 7.0388641}, {
  'zipCode': '53119',
  'north':   50.7590876,
  'east':    7.089912900000001,
  'south':   50.7354774,
  'west':    7.0363591
}, {'zipCode': '53121', 'north': 50.7465784, 'east': 7.082309000000001, 'south': 50.7165799, 'west': 7.0328673}, {
  'zipCode': '53123',
  'north':   50.73419260000001,
  'east':    7.0670755,
  'south':   50.6960211,
  'west':    7.0225354
}, {'zipCode': '53125', 'north': 50.7074893, 'east': 7.0826693, 'south': 50.6326909, 'west': 7.0234675}, {
  'zipCode': '53127',
  'north':   50.7197253,
  'east':    7.1085602,
  'south':   50.682167,
  'west':    7.0603123
}, {'zipCode': '53129', 'north': 50.7224438, 'east': 7.1277011, 'south': 50.6944707, 'west': 7.096048500000001}, {
  'zipCode': '53173',
  'north':   50.6979954,
  'east':    7.1760978,
  'south':   50.6755978,
  'west':    7.1452398
}, {'zipCode': '53175', 'north': 50.7177159, 'east': 7.1708866, 'south': 50.6799358, 'west': 7.0891745}, {
  'zipCode': '53177',
  'north':   50.6899429,
  'east':    7.1807582000000005,
  'south':   50.6428943,
  'west':    7.074332500000001
}, {'zipCode': '53179', 'north': 50.6870438, 'east': 7.210677200000001, 'south': 50.6425515, 'west': 7.1511579}, {
  'zipCode': '53225',
  'north':   50.7744233,
  'east':    7.1421764,
  'south':   50.7242581,
  'west':    7.0758529
}, {'zipCode': '53227', 'north': 50.7456884, 'east': 7.1969669, 'south': 50.7033691, 'west': 7.1198975}, {
  'zipCode': '53229',
  'north':   50.7675621,
  'east':    7.2090867,
  'south':   50.7157403,
  'west':    7.1261127
}, {'zipCode': '53332', 'north': 50.8053615, 'east': 7.0667005, 'south': 50.7186347, 'west': 6.8594354}, {
  'zipCode': '53340',
  'north':   50.66645470000001,
  'east':    7.0644403,
  'south':   50.5643172,
  'west':    6.9563293
}, {'zipCode': '53343', 'north': 50.6595385, 'east': 7.1951754, 'south': 50.5859311, 'west': 7.039808400000001}, {
  'zipCode': '53347',
  'north':   50.7565827,
  'east':    7.0442484,
  'south':   50.6614356,
  'west':    6.9583738
}, {'zipCode': '53359', 'north': 50.6733237, 'east': 7.019224400000001, 'south': 50.5558803, 'west': 6.8836202}, {
  'zipCode': '53424',
  'north':   50.6498917,
  'east':    7.277530699999999,
  'south':   50.5561416,
  'west':    7.1476433
}, {'zipCode': '53426', 'north': 50.5122544, 'east': 7.2113664, 'south': 50.4623284, 'west': 7.1021692}, {
  'zipCode': '53474',
  'north':   50.58247060000001,
  'east':    7.208662200000001,
  'south':   50.4767535,
  'west':    7.0542018
}, {'zipCode': '53489', 'north': 50.5657959, 'east': 7.2847606, 'south': 50.49090590000001, 'west': 7.1508419}, {
  'zipCode': '53498',
  'north':   50.5435107,
  'east':    7.3203565,
  'south':   50.4671049,
  'west':    7.2003932
}, {'zipCode': '53501', 'north': 50.6122745, 'east': 7.172416900000001, 'south': 50.5381554, 'west': 6.998011}, {
  'zipCode': '53505',
  'north':   50.5657397,
  'east':    7.0295058,
  'south':   50.4966919,
  'west':    6.8822576
}, {'zipCode': '53506', 'north': 50.5259925, 'east': 7.1198637, 'south': 50.4047658, 'west': 6.8980742}, {
  'zipCode': '53507',
  'north':   50.5433174,
  'east':    7.059332,
  'south':   50.50520050000001,
  'west':    7.0253563
}, {'zipCode': '53508', 'north': 50.5388521, 'east': 7.0325633, 'south': 50.4998486, 'west': 7.0041975}, {
  'zipCode': '53518',
  'north':   50.428894,
  'east':    7.0144487,
  'south':   50.3358834,
  'west':    6.8750758
}, {'zipCode': '53520', 'north': 50.4915081, 'east': 7.0464548, 'south': 50.306595, 'west': 6.743291900000001}, {
  'zipCode': '53533',
  'north':   50.44056270000001,
  'east':    6.8716768,
  'south':   50.3699988,
  'west':    6.7691072
}, {'zipCode': '53534', 'north': 50.3834769, 'east': 6.9246153, 'south': 50.319019600000004, 'west': 6.799564800000001}, {
  'zipCode': '53539',
  'north':   50.338446,
  'east':    7.0205834000000005,
  'south':   50.2634464,
  'west':    6.8021681
}, {'zipCode': '53545', 'north': 50.6003793, 'east': 7.339670900000001, 'south': 50.5558764, 'west': 7.2637353}, {
  'zipCode': '53547',
  'north':   50.5989764,
  'east':    7.5098902,
  'south':   50.5111679,
  'west':    7.248241200000001
}, {'zipCode': '53557', 'north': 50.5520733, 'east': 7.3871335, 'south': 50.5038982, 'west': 7.2918346}, {
  'zipCode': '53560',
  'north':   50.6309638,
  'east':    7.366689000000001,
  'south':   50.5966125,
  'west':    7.2778714
}, {'zipCode': '53562', 'north': 50.612994500000006, 'east': 7.396137300000001, 'south': 50.5741548, 'west': 7.3225943000000004}, {
  'zipCode': '53567',
  'north':   50.7182575,
  'east':    7.485660800000001,
  'south':   50.61582880000001,
  'west':    7.357411700000001
}, {'zipCode': '53572', 'north': 50.6354873, 'east': 7.316673499999999, 'south': 50.5836233, 'west': 7.210011500000001}, {
  'zipCode': '53577',
  'north':   50.6467276,
  'east':    7.4763185,
  'south':   50.5753048,
  'west':    7.362781899999999
}, {'zipCode': '53578', 'north': 50.67115, 'east': 7.389628500000001, 'south': 50.6175692, 'west': 7.314410900000001}, {
  'zipCode': '53579',
  'north':   50.6308188,
  'east':    7.3301411,
  'south':   50.5789842,
  'west':    7.2227953
}, {'zipCode': '53604', 'north': 50.68733890000001, 'east': 7.369583099999999, 'south': 50.6234388, 'west': 7.199525}, {
  'zipCode': '53619',
  'north':   50.6382367,
  'east':    7.3163333,
  'south':   50.6114928,
  'west':    7.211266900000001
}, {'zipCode': '53639', 'north': 50.7476853, 'east': 7.3720923, 'south': 50.6616848, 'west': 7.1682649000000005}, {
  'zipCode': '53721',
  'north':   50.8280134,
  'east':    7.310481200000001,
  'south':   50.7797872,
  'west':    7.1741992
}, {'zipCode': '53757', 'north': 50.800533, 'east': 7.2456751, 'south': 50.7393785, 'west': 7.1254004}, {
  'zipCode': '53773',
  'north':   50.8132773,
  'east':    7.4390372,
  'south':   50.6962221,
  'west':    7.2280253
}, {'zipCode': '53783', 'north': 50.8182419, 'east': 7.538827300000001, 'south': 50.7117828, 'west': 7.379922200000001}, {
  'zipCode': '53797',
  'north':   50.9285324,
  'east':    7.306563199999999,
  'south':   50.8118291,
  'west':    7.1908103
}, {'zipCode': '53804', 'north': 50.9497037, 'east': 7.479304, 'south': 50.8414306, 'west': 7.303104500000001}, {
  'zipCode': '53809',
  'north':   50.879752,
  'east':    7.530314300000001,
  'south':   50.7774321,
  'west':    7.336088199999999
}, {'zipCode': '53819', 'north': 50.9068036, 'east': 7.4073521, 'south': 50.8085618, 'west': 7.2754074}, {
  'zipCode': '53840',
  'north':   50.8295012,
  'east':    7.194629,
  'south':   50.7947949,
  'west':    7.1313377
}, {'zipCode': '53842', 'north': 50.8742716, 'east': 7.214899599999999, 'south': 50.802766, 'west': 7.0943936}, {
  'zipCode': '53844',
  'north':   50.8347982,
  'east':    7.145726,
  'south':   50.761555,
  'west':    7.0771987
}, {'zipCode': '53859', 'north': 50.8500948, 'east': 7.100863400000001, 'south': 50.7672297, 'west': 6.9789908}, {
  'zipCode': '53879',
  'north':   50.6790704,
  'east':    6.8168935,
  'south':   50.6372685,
  'west':    6.7574859
}, {'zipCode': '53881', 'north': 50.7029759, 'east': 6.9349158, 'south': 50.5604752, 'west': 6.7104420000000005}, {
  'zipCode': '53894',
  'north':   50.651726,
  'east':    6.7707421,
  'south':   50.5218915,
  'west':    6.5357245
}, {'zipCode': '53902', 'north': 50.6097285, 'east': 6.9314263, 'south': 50.4532625, 'west': 6.6910669}, {
  'zipCode': '53909',
  'north':   50.7556405,
  'east':    6.7779666,
  'south':   50.6303997,
  'west':    6.5670685
}, {'zipCode': '53913', 'north': 50.7341438, 'east': 6.9966556, 'south': 50.6356623, 'west': 6.8541684}, {
  'zipCode': '53919',
  'north':   50.7896443,
  'east':    6.9157507,
  'south':   50.6877956,
  'west':    6.7618788
}, {'zipCode': '53925', 'north': 50.5750035, 'east': 6.6135621, 'south': 50.457702, 'west': 6.49448}, {
  'zipCode': '53937',
  'north':   50.61888470000001,
  'east':    6.559602900000001,
  'south':   50.49824,
  'west':    6.3479327
}, {'zipCode': '53940', 'north': 50.51404170000001, 'east': 6.5809465, 'south': 50.322567, 'west': 6.3160973}, {
  'zipCode': '53945',
  'north':   50.47108440000001,
  'east':    6.8024484,
  'south':   50.335696600000006,
  'west':    6.5849263
}, {'zipCode': '53947', 'north': 50.5420047, 'east': 6.7756872, 'south': 50.44316130000001, 'west': 6.5487575}, {
  'zipCode': '53949',
  'north':   50.4434514,
  'east':    6.6070064,
  'south':   50.3353601,
  'west':    6.4130222
}, {'zipCode': '54290', 'north': 49.76182140000001, 'east': 6.6516567, 'south': 49.7381388, 'west': 6.6262698}, {
  'zipCode': '54292',
  'north':   49.7957052,
  'east':    6.7393361,
  'south':   49.756512,
  'west':    6.6290861
}, {'zipCode': '54293', 'north': 49.857117300000006, 'east': 6.7471503, 'south': 49.7534801, 'west': 6.5898888}, {
  'zipCode': '54294',
  'north':   49.777656,
  'east':    6.6590881,
  'south':   49.7008806,
  'west':    6.551792
}, {'zipCode': '54295', 'north': 49.766071600000004, 'east': 6.682275, 'south': 49.7304299, 'west': 6.635566400000001}, {
  'zipCode': '54296',
  'north':   49.7693482,
  'east':    6.7439657,
  'south':   49.6986005,
  'west':    6.6103062
}, {'zipCode': '54298', 'north': 49.9254527, 'east': 6.7139328, 'south': 49.7015942, 'west': 6.4759117}, {
  'zipCode': '54306',
  'north':   49.87039420000001,
  'east':    6.6794595,
  'south':   49.8026566,
  'west':    6.582979
}, {'zipCode': '54308', 'north': 49.7664781, 'east': 6.5483584, 'south': 49.7110139, 'west': 6.4955082}, {
  'zipCode': '54309',
  'north':   49.8302065,
  'east':    6.6401303,
  'south':   49.7854982,
  'west':    6.562131900000001
}, {'zipCode': '54310', 'north': 49.8481499, 'east': 6.5706627, 'south': 49.7622248, 'west': 6.4457498}, {
  'zipCode': '54311',
  'north':   49.7912821,
  'east':    6.6190816,
  'south':   49.7375007,
  'west':    6.527095
}, {'zipCode': '54313', 'north': 49.9177099, 'east': 6.7248832, 'south': 49.85436320000001, 'west': 6.6360195}, {
  'zipCode': '54314',
  'north':   49.6520874,
  'east':    6.768038,
  'south':   49.5352664,
  'west':    6.619308
}, {'zipCode': '54316', 'north': 49.713737, 'east': 6.8060005, 'south': 49.6368147, 'west': 6.6478134}, {
  'zipCode': '54317',
  'north':   49.771899700000006,
  'east':    6.8702618,
  'south':   49.6592611,
  'west':    6.694227500000001
}, {'zipCode': '54318', 'north': 49.7832532, 'east': 6.7634583, 'south': 49.7531581, 'west': 6.686136500000001}, {
  'zipCode': '54320',
  'north':   49.7655436,
  'east':    6.7900943,
  'south':   49.7274447,
  'west':    6.7154577
}, {'zipCode': '54329', 'north': 49.7239434, 'east': 6.6711725, 'south': 49.6340803, 'west': 6.5197086}, {
  'zipCode': '54331',
  'north':   49.7128964,
  'east':    6.6900532,
  'south':   49.65132330000001,
  'west':    6.4796581
}, {'zipCode': '54332', 'north': 49.7139619, 'east': 6.5625443, 'south': 49.6859737, 'west': 6.5101819}, {
  'zipCode': '54338',
  'north':   49.8831364,
  'east':    6.7968889,
  'south':   49.8013737,
  'west':    6.6925895
}, {'zipCode': '54340', 'north': 49.8924674, 'east': 6.9126005, 'south': 49.773611800000005, 'west': 6.7267459}, {
  'zipCode': '54341',
  'north':   49.7978255,
  'east':    6.8358773,
  'south':   49.7464402,
  'west':    6.757247
}, {'zipCode': '54343', 'north': 49.8803667, 'east': 6.8089914, 'south': 49.8487898, 'west': 6.7356353}, {
  'zipCode': '54344',
  'north':   49.8115251,
  'east':    6.7488117,
  'south':   49.7913621,
  'west':    6.7038413
}, {'zipCode': '54346', 'north': 49.8380425, 'east': 6.873719500000001, 'south': 49.7469997, 'west': 6.7824185}, {
  'zipCode': '54347',
  'north':   49.8857512,
  'east':    6.9526956,
  'south':   49.8179457,
  'west':    6.8713751
}, {'zipCode': '54349', 'north': 49.8472548, 'east': 6.9518889, 'south': 49.802811600000005, 'west': 6.8829066}, {
  'zipCode': '54411',
  'north':   49.7820667,
  'east':    7.0708245,
  'south':   49.6251485,
  'west':    6.8984002
}, {'zipCode': '54413', 'north': 49.7633959, 'east': 7.0220606, 'south': 49.6023684, 'west': 6.8571016}, {
  'zipCode': '54421',
  'north':   49.7176814,
  'east':    6.9245388,
  'south':   49.64163,
  'west':    6.8415154
}, {'zipCode': '54422', 'north': 49.7113422, 'east': 7.1052289, 'south': 49.621078, 'west': 6.979604200000001}, {
  'zipCode': '54424',
  'north':   49.7950868,
  'east':    7.0850533,
  'south':   49.7218393,
  'west':    6.9461172
}, {'zipCode': '54426', 'north': 49.8206939, 'east': 7.1049894, 'south': 49.672969200000004, 'west': 6.865122}, {
  'zipCode': '54427',
  'north':   49.6652164,
  'east':    6.8693759,
  'south':   49.5900145,
  'west':    6.7664192
}, {'zipCode': '54429', 'north': 49.6633493, 'east': 6.8416748, 'south': 49.5673761, 'west': 6.704982}, {
  'zipCode': '54439',
  'north':   49.62944310000001,
  'east':    6.6224854,
  'south':   49.5433045,
  'west':    6.358366
}, {'zipCode': '54441', 'north': 49.6963177, 'east': 6.6514221, 'south': 49.5167005, 'west': 6.4259064}, {
  'zipCode': '54450',
  'north':   49.5637571,
  'east':    6.5842746,
  'south':   49.5276712,
  'west':    6.5108844
}, {'zipCode': '54451', 'north': 49.6208447, 'east': 6.6539344, 'south': 49.55648510000001, 'west': 6.5730569}, {
  'zipCode': '54453',
  'north':   49.6691204,
  'east':    6.5234074,
  'south':   49.6157477,
  'west':    6.4229717
}, {'zipCode': '54455', 'north': 49.5933021, 'east': 6.654103900000001, 'south': 49.5458347, 'west': 6.5475915}, {
  'zipCode': '54456',
  'north':   49.6904181,
  'east':    6.5392811,
  'south':   49.6316449,
  'west':    6.4670879
}, {'zipCode': '54457', 'north': 49.6388989, 'east': 6.4846973, 'south': 49.5811448, 'west': 6.3833084}, {
  'zipCode': '54459',
  'north':   49.67877360000001,
  'east':    6.653502800000001,
  'south':   49.6108718,
  'west':    6.5735113
}, {'zipCode': '54470', 'north': 49.9567457, 'east': 7.11773, 'south': 49.8919878, 'west': 6.998139}, {
  'zipCode': '54472',
  'north':   49.9173106,
  'east':    7.2633426000000005,
  'south':   49.8345666,
  'west':    6.9596455
}, {'zipCode': '54483', 'north': 49.9160731, 'east': 7.222321600000001, 'south': 49.8642458, 'west': 7.1351936}, {
  'zipCode': '54484',
  'north':   49.9513568,
  'east':    7.0131372,
  'south':   49.9140804,
  'west':    6.9454245
}, {'zipCode': '54486', 'north': 49.9158717, 'east': 7.0549063, 'south': 49.8870236, 'west': 6.9986694}, {
  'zipCode': '54487',
  'north':   49.9008331,
  'east':    7.03331,
  'south':   49.8270155,
  'west':    6.9460281
}, {'zipCode': '54492', 'north': 50.0091428, 'east': 7.071988, 'south': 49.9439861, 'west': 6.9561409}, {
  'zipCode': '54497',
  'north':   49.8769977,
  'east':    7.226454600000001,
  'south':   49.7390685,
  'west':    6.9443464
}, {'zipCode': '54498', 'north': 49.8960695, 'east': 6.9836614, 'south': 49.8318773, 'west': 6.8724491}, {
  'zipCode': '54516',
  'north':   50.0321996,
  'east':    6.9833582,
  'south':   49.9555546,
  'west':    6.831072300000001
}, {'zipCode': '54518', 'north': 50.0415675, 'east': 6.980927, 'south': 49.8624044, 'west': 6.6811796}, {
  'zipCode': '54523',
  'north':   49.9120627,
  'east':    6.8387592,
  'south':   49.8524318,
  'west':    6.745489
}, {'zipCode': '54524', 'north': 49.9319197, 'east': 6.9030221, 'south': 49.883826000000006, 'west': 6.8574323}, {
  'zipCode': '54526',
  'north':   50.0310052,
  'east':    6.8059062,
  'south':   49.9677212,
  'west':    6.7032832
}, {'zipCode': '54528', 'north': 49.9501135, 'east': 6.8861344, 'south': 49.912413, 'west': 6.7911569}, {
  'zipCode': '54529',
  'north':   50.0084723,
  'east':    6.728036600000001,
  'south':   49.9650486,
  'west':    6.6632737
}, {'zipCode': '54531', 'north': 50.1305131, 'east': 6.9027852, 'south': 50.06314160000001, 'west': 6.6967031}, {
  'zipCode': '54533',
  'north':   50.0961725,
  'east':    6.9705628,
  'south':   50.0016329,
  'west':    6.6416315
}, {'zipCode': '54534', 'north': 50.0667128, 'east': 6.8527497, 'south': 49.9989263, 'west': 6.7495081}, {
  'zipCode': '54536',
  'north':   50.0216855,
  'east':    7.1242598,
  'south':   49.9671521,
  'west':    7.0544458
}, {'zipCode': '54538', 'north': 50.0990948, 'east': 7.1036372, 'south': 49.9600164, 'west': 6.9316414}, {
  'zipCode': '54539',
  'north':   50.0038799,
  'east':    7.0242471,
  'south':   49.9696639,
  'west':    6.9813864
}, {'zipCode': '54550', 'north': 50.2461145, 'east': 6.8755412, 'south': 50.1610053, 'west': 6.7441059}, {
  'zipCode': '54552',
  'north':   50.29042280000001,
  'east':    6.9958946,
  'south':   50.0798295,
  'west':    6.7638702
}, {'zipCode': '54558', 'north': 50.1591265, 'east': 6.976014, 'south': 50.0735701, 'west': 6.8657676}, {
  'zipCode': '54568',
  'north':   50.2634813,
  'east':    6.7367746,
  'south':   50.1563056,
  'west':    6.5611546
}, {'zipCode': '54570', 'north': 50.2789052, 'east': 6.8127798, 'south': 50.084529, 'west': 6.5484294}, {
  'zipCode': '54574',
  'north':   50.199889,
  'east':    6.6829553,
  'south':   50.152295,
  'west':    6.551298
}, {'zipCode': '54576', 'north': 50.3204269, 'east': 6.7074459, 'south': 50.252710300000004, 'west': 6.627231900000001}, {
  'zipCode': '54578',
  'north':   50.3717535,
  'east':    6.8185832,
  'south':   50.2606018,
  'west':    6.5935158
}, {'zipCode': '54579', 'north': 50.3642314, 'east': 6.8166317, 'south': 50.2861885, 'west': 6.6967878}, {
  'zipCode': '54584',
  'north':   50.3784833,
  'east':    6.6383142,
  'south':   50.3176801,
  'west':    6.5334481
}, {'zipCode': '54585', 'north': 50.388184900000006, 'east': 6.6272894, 'south': 50.346968, 'west': 6.5739771}, {
  'zipCode': '54586',
  'north':   50.3436731,
  'east':    6.5872264,
  'south':   50.3216984,
  'west':    6.5411381
}, {'zipCode': '54587', 'north': 50.3394833, 'east': 6.6531856, 'south': 50.2990972, 'west': 6.536028800000001}, {
  'zipCode': '54589',
  'north':   50.3703142,
  'east':    6.5584797,
  'south':   50.3002902,
  'west':    6.4745543
}, {'zipCode': '54595', 'north': 50.2838296, 'east': 6.5152286, 'south': 50.1513782, 'west': 6.3152274}, {
  'zipCode': '54597',
  'north':   50.3425963,
  'east':    6.6027693,
  'south':   50.0504631,
  'west':    6.2363438
}, {'zipCode': '54608', 'north': 50.2888643, 'east': 6.3858588, 'south': 50.185241, 'west': 6.213518}, {
  'zipCode': '54610',
  'north':   50.2350269,
  'east':    6.5970051,
  'south':   50.1859624,
  'west':    6.5309207
}, {'zipCode': '54611', 'north': 50.3896456, 'east': 6.4611079, 'south': 50.3325919, 'west': 6.3844213}, {
  'zipCode': '54612',
  'north':   50.1367422,
  'east':    6.5233006,
  'south':   50.082119600000006,
  'west':    6.425438500000001
}, {'zipCode': '54614', 'north': 50.1898679, 'east': 6.5028072, 'south': 50.1155622, 'west': 6.4028411}, {
  'zipCode': '54616',
  'north':   50.2587582,
  'east':    6.2542975,
  'south':   50.1785652,
  'west':    6.166325
}, {'zipCode': '54617', 'north': 50.1777493, 'east': 6.206563, 'south': 50.1100257, 'west': 6.1283588}, {
  'zipCode': '54619',
  'north':   50.1962088,
  'east':    6.340723400000001,
  'south':   50.0894682,
  'west':    6.1561724
}, {'zipCode': '54634', 'north': 50.0187333, 'east': 6.6160564, 'south': 49.9100959, 'west': 6.4516096}, {
  'zipCode': '54636',
  'north':   50.09931780000001,
  'east':    6.6347446,
  'south':   49.8622627,
  'west':    6.350794
}, {'zipCode': '54646', 'north': 49.9846924, 'east': 6.448914, 'south': 49.9113735, 'west': 6.3327803}, {
  'zipCode': '54647',
  'north':   50.0038298,
  'east':    6.6861835,
  'south':   49.9537136,
  'west':    6.595744
}, {'zipCode': '54649', 'north': 50.138993, 'east': 6.4280541, 'south': 50.03128, 'west': 6.2998541}, {
  'zipCode': '54655',
  'north':   50.1176111,
  'east':    6.6737776,
  'south':   50.0165288,
  'west':    6.5288504
}, {'zipCode': '54657', 'north': 50.1137449, 'east': 6.6668886, 'south': 49.9883748, 'west': 6.5402021}, {
  'zipCode': '54662',
  'north':   49.966872,
  'east':    6.7080946,
  'south':   49.9168612,
  'west':    6.614240400000001
}, {'zipCode': '54664', 'north': 49.9234866, 'east': 6.6670729, 'south': 49.8756932, 'west': 6.600675}, {
  'zipCode': '54666',
  'north':   49.8620819,
  'east':    6.4887523,
  'south':   49.8276356,
  'west':    6.431939
}, {'zipCode': '54668', 'north': 49.9196614, 'east': 6.511446, 'south': 49.8106022, 'west': 6.3631945}, {
  'zipCode': '54669',
  'north':   49.8742993,
  'east':    6.3992844,
  'south':   49.8296032,
  'west':    6.3199558
}, {'zipCode': '54673', 'north': 50.0868696, 'east': 6.3807204, 'south': 49.9484386, 'west': 6.1538105}, {
  'zipCode': '54675',
  'north':   50.028852,
  'east':    6.3814653,
  'south':   49.8533306,
  'west':    6.216543
}, {'zipCode': '54687', 'north': 50.1171112, 'east': 6.3282265, 'south': 50.0592049, 'west': 6.2366252}, {
  'zipCode': '54689',
  'north':   50.1206331,
  'east':    6.2553114,
  'south':   49.9893742,
  'west':    6.1124668
}, {'zipCode': '55116', 'north': 50.0096259, 'east': 8.287527, 'south': 49.9924526, 'west': 8.25755}, {
  'zipCode': '55118',
  'north':   50.0200523,
  'east':    8.2733817,
  'south':   50.001668800000004,
  'west':    8.2421006
}, {'zipCode': '55120', 'north': 50.0353045, 'east': 8.2659251, 'south': 50.0098732, 'west': 8.189349}, {
  'zipCode': '55122',
  'north':   50.0178329,
  'east':    8.2601448,
  'south':   49.9928604,
  'west':    8.2135894
}, {'zipCode': '55124', 'north': 50.016779, 'east': 8.2307604, 'south': 49.9874587, 'west': 8.1825429}, {
  'zipCode': '55126',
  'north':   50.006047,
  'east':    8.2047539,
  'south':   49.9637926,
  'west':    8.1428311
}, {'zipCode': '55127', 'north': 49.9835526, 'east': 8.243303800000001, 'south': 49.9465204, 'west': 8.1771088}, {
  'zipCode': '55128',
  'north':   49.99715880000001,
  'east':    8.2658156,
  'south':   49.96466600000001,
  'west':    8.1955318
}, {'zipCode': '55129', 'north': 49.9788466, 'east': 8.2978279, 'south': 49.8955342, 'west': 8.2246846}, {
  'zipCode': '55130',
  'north':   49.988777,
  'east':    8.3422611,
  'south':   49.9385325,
  'west':    8.2798085
}, {'zipCode': '55131', 'north': 50.0001316, 'east': 8.299300100000002, 'south': 49.9761075, 'west': 8.2444896}, {
  'zipCode': '55218',
  'north':   50.0068943,
  'east':    8.1378017,
  'south':   49.92992120000001,
  'west':    7.976977
}, {'zipCode': '55232', 'north': 49.8141446, 'east': 8.1663213, 'south': 49.7189007, 'west': 8.0405114}, {
  'zipCode': '55234',
  'north':   49.8240482,
  'east':    8.249348,
  'south':   49.6610267,
  'west':    7.9351789
}, {'zipCode': '55237', 'north': 49.8144631, 'east': 8.1005949, 'south': 49.763061, 'west': 7.9959441}, {
  'zipCode': '55239',
  'north':   49.8028955,
  'east':    8.2350749,
  'south':   49.7554855,
  'west':    8.1559647
}, {'zipCode': '55246', 'north': 50.0330734, 'east': 8.3361358, 'south': 49.9932752, 'west': 8.2794769}, {
  'zipCode': '55252',
  'north':   50.042244,
  'east':    8.3142785,
  'south':   50.0033681,
  'west':    8.254182
}, {'zipCode': '55257', 'north': 50.0355153, 'east': 8.2042949, 'south': 50.00324590000001, 'west': 8.1446742}, {
  'zipCode': '55262',
  'north':   50.0270495,
  'east':    8.1618402,
  'south':   49.978663600000004,
  'west':    8.078816
}, {'zipCode': '55263', 'north': 49.9852802, 'east': 8.1487772, 'south': 49.9591046, 'west': 8.1034124}, {
  'zipCode': '55268',
  'north':   49.9212062,
  'east':    8.2336653,
  'south':   49.87970980000001,
  'west':    8.1739307
}, {'zipCode': '55270', 'north': 49.9675724, 'east': 8.2513501, 'south': 49.8667633, 'west': 8.0410905}, {
  'zipCode': '55271',
  'north':   49.9467077,
  'east':    8.1822366,
  'south':   49.8904825,
  'west':    8.1059227
}, {'zipCode': '55276', 'north': 49.8637335, 'east': 8.3853468, 'south': 49.8095738, 'west': 8.3137928}, {
  'zipCode': '55278',
  'north':   49.898601,
  'east':    8.3845946,
  'south':   49.783293900000004,
  'west':    8.1891273
}, {'zipCode': '55283', 'north': 49.9034615, 'east': 8.3583852, 'south': 49.8451706, 'west': 8.2765854}, {
  'zipCode': '55286',
  'north':   49.8671311,
  'east':    8.1491842,
  'south':   49.8101885,
  'west':    8.0689929
}, {'zipCode': '55288', 'north': 49.8994995, 'east': 8.2146696, 'south': 49.790564, 'west': 8.0268441}, {
  'zipCode': '55291',
  'north':   49.8997375,
  'east':    8.1855564,
  'south':   49.8459486,
  'west':    8.0933303
}, {'zipCode': '55294', 'north': 49.9484457, 'east': 8.3481474, 'south': 49.9071903, 'west': 8.2716547}, {
  'zipCode': '55296',
  'north':   49.9268597,
  'east':    8.3191478,
  'south':   49.8784657,
  'west':    8.249834
}, {'zipCode': '55299', 'north': 49.9297824, 'east': 8.3546578, 'south': 49.8899991, 'west': 8.307197}, {
  'zipCode': '55411',
  'north':   49.980472,
  'east':    8.0039552,
  'south':   49.9126421,
  'west':    7.853025200000001
}, {'zipCode': '55413', 'north': 50.0484091, 'east': 7.892863599999999, 'south': 49.9464389, 'west': 7.695085000000001}, {
  'zipCode': '55422',
  'north':   50.0829148,
  'east':    7.7847346,
  'south':   50.0185472,
  'west':    7.664881
}, {'zipCode': '55424', 'north': 49.9569045, 'east': 7.9030503, 'south': 49.9303054, 'west': 7.8433919}, {
  'zipCode': '55425',
  'north':   49.985162,
  'east':    7.8511378,
  'south':   49.9329731,
  'west':    7.770033999999999
}, {'zipCode': '55430', 'north': 50.1380041, 'east': 7.765327, 'south': 50.0334505, 'west': 7.633682499999999}, {
  'zipCode': '55432',
  'north':   50.1269528,
  'east':    7.7155035000000005,
  'south':   50.0853157,
  'west':    7.5963285
}, {'zipCode': '55435', 'north': 49.9722755, 'east': 8.0435239, 'south': 49.9240591, 'west': 7.984258400000001}, {
  'zipCode': '55437',
  'north':   49.9550983,
  'east':    8.0592793,
  'south':   49.885358,
  'west':    7.952491000000001
}, {'zipCode': '55442', 'north': 50.0008842, 'east': 7.8213337, 'south': 49.9256194, 'west': 7.6858991}, {
  'zipCode': '55444',
  'north':   49.9798496,
  'east':    7.8555115,
  'south':   49.9134493,
  'west':    7.650576100000001
}, {'zipCode': '55450', 'north': 49.9216933, 'east': 7.9192904, 'south': 49.8828452, 'west': 7.8329139}, {
  'zipCode': '55452',
  'north':   49.9430222,
  'east':    7.9087084,
  'south':   49.8683398,
  'west':    7.7121273
}, {'zipCode': '55457', 'north': 49.9140285, 'east': 7.983445800000001, 'south': 49.8778023, 'west': 7.9134636}, {
  'zipCode': '55459',
  'north':   49.9211097,
  'east':    8.0145216,
  'south':   49.8897688,
  'west':    7.8973671
}, {'zipCode': '55469', 'north': 50.0752595, 'east': 7.5986302, 'south': 49.9350138, 'west': 7.4512877}, {
  'zipCode': '55471',
  'north':   50.0308723,
  'east':    7.581068500000001,
  'south':   49.8950459,
  'west':    7.3792752
}, {'zipCode': '55481', 'north': 50.0195947, 'east': 7.483918100000001, 'south': 49.8931349, 'west': 7.308919500000001}, {
  'zipCode': '55483',
  'north':   50.0254439,
  'east':    7.469198400000001,
  'south':   49.850317,
  'west':    7.2118599
}, {'zipCode': '55487', 'north': 49.9547306, 'east': 7.381143200000001, 'south': 49.8790013, 'west': 7.280828600000001}, {
  'zipCode': '55490',
  'north':   49.9235154,
  'east':    7.5447168,
  'south':   49.8406098,
  'west':    7.386351800000001
}, {'zipCode': '55491', 'north': 49.9301825, 'east': 7.3132351, 'south': 49.890277, 'west': 7.223833100000001}, {
  'zipCode': '55494',
  'north':   50.0497147,
  'east':    7.7346262,
  'south':   49.9700576,
  'west':    7.5775222
}, {'zipCode': '55496', 'north': 49.9903981, 'east': 7.6622207, 'south': 49.9263525, 'west': 7.5614186}, {
  'zipCode': '55497',
  'north':   50.0028895,
  'east':    7.670058499999999,
  'south':   49.9540434,
  'west':    7.574227900000001
}, {'zipCode': '55499', 'north': 49.9718416, 'east': 7.6186113, 'south': 49.9085004, 'west': 7.5390218}, {
  'zipCode': '55543',
  'north':   49.8631643,
  'east':    7.9126653,
  'south':   49.7918722,
  'west':    7.836371300000001
}, {'zipCode': '55545', 'north': 49.8819482, 'east': 7.9545952, 'south': 49.8228151, 'west': 7.8117695000000005}, {
  'zipCode': '55546',
  'north':   49.8809478,
  'east':    7.9703931,
  'south':   49.7521125,
  'west':    7.855143300000001
}, {'zipCode': '55559', 'north': 49.8905399, 'east': 7.9203087, 'south': 49.8629723, 'west': 7.8624719999999995}, {
  'zipCode': '55566',
  'north':   49.9099843,
  'east':    7.684437299999999,
  'south':   49.736096,
  'west':    7.5371369
}, {'zipCode': '55568', 'north': 49.800773400000004, 'east': 7.7227737, 'south': 49.7261787, 'west': 7.581473}, {
  'zipCode': '55569',
  'north':   49.839758,
  'east':    7.645671699999999,
  'south':   49.7842693,
  'west':    7.5577201
}, {'zipCode': '55571', 'north': 49.7828184, 'east': 7.751706100000001, 'south': 49.7405099, 'west': 7.6823151}, {
  'zipCode': '55576',
  'north':   49.8958965,
  'east':    8.0236192,
  'south':   49.8237484,
  'west':    7.938043900000001
}, {'zipCode': '55578', 'north': 49.8887351, 'east': 8.0967006, 'south': 49.813231, 'west': 8.0046674}, {
  'zipCode': '55583',
  'north':   49.8225533,
  'east':    7.860468399999999,
  'south':   49.7870485,
  'west':    7.7867509
}, {'zipCode': '55585', 'north': 49.817363, 'east': 7.870997400000001, 'south': 49.7435287, 'west': 7.7176327}, {
  'zipCode': '55590',
  'north':   49.7323237,
  'east':    7.703767,
  'south':   49.6876782,
  'west':    7.641910800000001
}, {'zipCode': '55592', 'north': 49.7457812, 'east': 7.726719200000001, 'south': 49.6842821, 'west': 7.5809379}, {
  'zipCode': '55593',
  'north':   49.8523963,
  'east':    7.8281654,
  'south':   49.8383316,
  'west':    7.781656199999999
}, {'zipCode': '55595', 'north': 49.9352405, 'east': 7.8464621, 'south': 49.7852295, 'west': 7.5844701}, {
  'zipCode': '55596',
  'north':   49.8363089,
  'east':    7.763407300000001,
  'south':   49.7911596,
  'west':    7.6409304
}, {'zipCode': '55597', 'north': 49.8358552, 'east': 8.0207857, 'south': 49.8005849, 'west': 7.9243025}, {
  'zipCode': '55599',
  'north':   49.8522792,
  'east':    8.0420832,
  'south':   49.7420404,
  'west':    7.9050962
}, {'zipCode': '55606', 'north': 49.8661155, 'east': 7.6225221, 'south': 49.7027844, 'west': 7.391366500000001}, {
  'zipCode': '55608',
  'north':   49.8836156,
  'east':    7.5234754,
  'south':   49.7111168,
  'west':    7.3524953
}, {'zipCode': '55618', 'north': 49.8454298, 'east': 7.5446832, 'south': 49.8037536, 'west': 7.497921900000001}, {
  'zipCode': '55619',
  'north':   49.8533354,
  'east':    7.4843912,
  'south':   49.8089167,
  'west':    7.3955096000000005
}, {'zipCode': '55621', 'north': 49.7378883, 'east': 7.5858642, 'south': 49.704718, 'west': 7.539133500000001}, {
  'zipCode': '55624',
  'north':   49.900501,
  'east':    7.3967517,
  'south':   49.8251744,
  'west':    7.253368600000001
}, {'zipCode': '55626', 'north': 49.864676800000005, 'east': 7.398356099999999, 'south': 49.824742, 'west': 7.3555673}, {
  'zipCode': '55627',
  'north':   49.8358263,
  'east':    7.5881131,
  'south':   49.7518696,
  'west':    7.514236100000001
}, {'zipCode': '55629', 'north': 49.8914267, 'east': 7.569810799999999, 'south': 49.8269466, 'west': 7.494593200000001}, {
  'zipCode': '55743',
  'north':   49.772953,
  'east':    7.445766600000001,
  'south':   49.6492332,
  'west':    7.2271986
}, {'zipCode': '55756', 'north': 49.7979469, 'east': 7.369005, 'south': 49.7711272, 'west': 7.3233989}, {
  'zipCode': '55758',
  'north':   49.867101,
  'east':    7.526391500000001,
  'south':   49.6644246,
  'west':    7.0707237
}, {'zipCode': '55765', 'north': 49.7038966, 'east': 7.247569500000001, 'south': 49.595554, 'west': 7.1057777}, {
  'zipCode': '55767',
  'north':   49.7519309,
  'east':    7.291917200000001,
  'south':   49.5754193,
  'west':    7.027988
}, {'zipCode': '55768', 'north': 49.6344433, 'east': 7.2401653, 'south': 49.594365, 'west': 7.1544939}, {
  'zipCode': '55774',
  'north':   49.6659619,
  'east':    7.4486388,
  'south':   49.5767623,
  'west':    7.294822
}, {'zipCode': '55776', 'north': 49.6806353, 'east': 7.3157812, 'south': 49.5649215, 'west': 7.2099212}, {
  'zipCode': '55777',
  'north':   49.6071555,
  'east':    7.3419558,
  'south':   49.5486094,
  'west':    7.2619645
}, {'zipCode': '55779', 'north': 49.6291788, 'east': 7.2772848, 'south': 49.5805768, 'west': 7.2017089}, {
  'zipCode': '56068',
  'north':   50.365939000000004,
  'east':    7.608399000000001,
  'south':   50.3327805,
  'west':    7.581840800000001
}, {'zipCode': '56070', 'north': 50.4096941, 'east': 7.6150559, 'south': 50.3617709, 'west': 7.5329663}, {
  'zipCode': '56072',
  'north':   50.3837565,
  'east':    7.578127300000001,
  'south':   50.3225928,
  'west':    7.478250699999999
}, {'zipCode': '56073', 'north': 50.368512, 'east': 7.5897151, 'south': 50.306838, 'west': 7.5173504}, {
  'zipCode': '56075',
  'north':   50.353027600000004,
  'east':    7.605280600000001,
  'south':   50.283001,
  'west':    7.5135129
}, {'zipCode': '56076', 'north': 50.3580356, 'east': 7.6670546, 'south': 50.3234295, 'west': 7.5903917}, {
  'zipCode': '56077',
  'north':   50.3824873,
  'east':    7.696435400000001,
  'south':   50.33985440000001,
  'west':    7.606593
}, {'zipCode': '56112', 'north': 50.3271543, 'east': 7.7104211, 'south': 50.2675087, 'west': 7.5889059}, {
  'zipCode': '56130',
  'north':   50.368473,
  'east':    7.755740900000001,
  'south':   50.2732767,
  'west':    7.6755356
}, {'zipCode': '56132', 'north': 50.36706220000001, 'east': 7.793032800000001, 'south': 50.2778167, 'west': 7.6440506}, {
  'zipCode': '56133',
  'north':   50.3603477,
  'east':    7.699350599999999,
  'south':   50.3275133,
  'west':    7.659652200000001
}, {'zipCode': '56154', 'north': 50.2656396, 'east': 7.665732200000001, 'south': 50.156754, 'west': 7.459050200000001}, {
  'zipCode': '56170',
  'north':   50.464511,
  'east':    7.6488476,
  'south':   50.4066542,
  'west':    7.550340200000001
}, {'zipCode': '56179', 'north': 50.43010160000001, 'east': 7.680830199999999, 'south': 50.3811264, 'west': 7.573332800000001}, {
  'zipCode': '56182',
  'north':   50.3893805,
  'east':    7.664503600000001,
  'south':   50.3714293,
  'west':    7.6129643
}, {'zipCode': '56191', 'north': 50.4353547, 'east': 7.6525478, 'south': 50.404654, 'west': 7.5903637999999996}, {
  'zipCode': '56203',
  'north':   50.4600727,
  'east':    7.733474700000001,
  'south':   50.4183233,
  'west':    7.632139099999999
}, {'zipCode': '56204', 'north': 50.4377571, 'east': 7.7454287, 'south': 50.3925009, 'west': 7.667900000000001}, {
  'zipCode': '56206',
  'north':   50.4715953,
  'east':    7.7081854000000005,
  'south':   50.4447244,
  'west':    7.663717
}, {'zipCode': '56218', 'north': 50.4129976, 'east': 7.5415986, 'south': 50.3633002, 'west': 7.4667975}, {
  'zipCode': '56220',
  'north':   50.4218137,
  'east':    7.5741356,
  'south':   50.3350235,
  'west':    7.4065672
}, {'zipCode': '56235', 'north': 50.4853726, 'east': 7.7591275, 'south': 50.4362845, 'west': 7.6964091}, {
  'zipCode': '56237',
  'north':   50.5183906,
  'east':    7.7407038,
  'south':   50.4425695,
  'west':    7.5951024
}, {'zipCode': '56242', 'north': 50.5619554, 'east': 7.811741800000001, 'south': 50.4960935, 'west': 7.691906800000001}, {
  'zipCode': '56244',
  'north':   50.6196994,
  'east':    7.911675500000001,
  'south':   50.4818678,
  'west':    7.668301100000001
}, {'zipCode': '56249', 'north': 50.6153453, 'east': 7.785141, 'south': 50.5567615, 'west': 7.710052599999999}, {
  'zipCode': '56253',
  'north':   50.2066306,
  'east':    7.374480600000001,
  'south':   50.1190943,
  'west':    7.240393200000001
}, {'zipCode': '56254', 'north': 50.2180465, 'east': 7.383295999999999, 'south': 50.1817811, 'west': 7.315358}, {
  'zipCode': '56269',
  'north':   50.6000264,
  'east':    7.711696900000001,
  'south':   50.5188323,
  'west':    7.5827893
}, {'zipCode': '56271', 'north': 50.623075, 'east': 7.768558600000001, 'south': 50.461253400000004, 'west': 7.5638519}, {
  'zipCode': '56276',
  'north':   50.5273579,
  'east':    7.679687,
  'south':   50.4780017,
  'west':    7.5938946
}, {'zipCode': '56281', 'north': 50.17965, 'east': 7.6581216, 'south': 50.133295, 'west': 7.5102873}, {
  'zipCode': '56283',
  'north':   50.2504219,
  'east':    7.5949657,
  'south':   50.1338625,
  'west':    7.4134469
}, {'zipCode': '56288', 'north': 50.1336537, 'east': 7.5799799000000005, 'south': 50.0101072, 'west': 7.339973900000001}, {
  'zipCode': '56290',
  'north':   50.1950882,
  'east':    7.5262906,
  'south':   50.0601768,
  'west':    7.2933353
}, {'zipCode': '56291', 'north': 50.1534145, 'east': 7.6655689, 'south': 50.0348787, 'west': 7.4944725000000005}, {
  'zipCode': '56294',
  'north':   50.2887564,
  'east':    7.418728699999999,
  'south':   50.1974327,
  'west':    7.2930313
}, {'zipCode': '56295', 'north': 50.3274175, 'east': 7.420638500000001, 'south': 50.2819917, 'west': 7.344034}, {
  'zipCode': '56299',
  'north':   50.3732977,
  'east':    7.436109600000001,
  'south':   50.321844,
  'west':    7.331566999999999
}, {'zipCode': '56305', 'north': 50.62791760000001, 'east': 7.640599000000001, 'south': 50.5835198, 'west': 7.539217000000001}, {
  'zipCode': '56307',
  'north':   50.6048026,
  'east':    7.6234468,
  'south':   50.5323965,
  'west':    7.543478600000001
}, {'zipCode': '56316', 'north': 50.59427590000001, 'east': 7.6587091, 'south': 50.5572514, 'west': 7.593177200000001}, {
  'zipCode': '56317',
  'north':   50.5839455,
  'east':    7.5985615,
  'south':   50.5221998,
  'west':    7.5303351
}, {'zipCode': '56321', 'north': 50.2975023, 'east': 7.641778399999999, 'south': 50.250923, 'west': 7.5578558000000005}, {
  'zipCode': '56322',
  'north':   50.2710148,
  'east':    7.652305899999999,
  'south':   50.2463756,
  'west':    7.627192
}, {'zipCode': '56323', 'north': 50.2892796, 'east': 7.574858599999999, 'south': 50.2567093, 'west': 7.5229915}, {
  'zipCode': '56329',
  'north':   50.1717524,
  'east':    7.728686499999999,
  'south':   50.1188765,
  'west':    7.6356763
}, {'zipCode': '56330', 'north': 50.3502767, 'east': 7.5028491, 'south': 50.2731189, 'west': 7.394058300000001}, {
  'zipCode': '56332',
  'north':   50.346073,
  'east':    7.532901300000001,
  'south':   50.1740148,
  'west':    7.344455899999999
}, {'zipCode': '56333', 'north': 50.3379851, 'east': 7.535952999999999, 'south': 50.3073816, 'west': 7.4876079}, {
  'zipCode': '56335',
  'north':   50.3961068,
  'east':    7.7192087,
  'south':   50.376979,
  'west':    7.7000364
}, {'zipCode': '56337', 'north': 50.4080211, 'east': 7.793203200000001, 'south': 50.3516078, 'west': 7.6440416}, {
  'zipCode': '56338',
  'north':   50.2895942,
  'east':    7.7317616000000005,
  'south':   50.239469,
  'west':    7.6265946
}, {'zipCode': '56340', 'north': 50.2851177, 'east': 7.746648100000001, 'south': 50.2231383, 'west': 7.5880753}, {
  'zipCode': '56341',
  'north':   50.2487292,
  'east':    7.7022546,
  'south':   50.2009852,
  'west':    7.5784207
}, {'zipCode': '56346', 'north': 50.2221865, 'east': 7.7564912, 'south': 50.121807600000004, 'west': 7.646209700000001}, {
  'zipCode': '56348',
  'north':   50.2293913,
  'east':    7.8471777,
  'south':   50.09269080000001,
  'west':    7.6428233
}, {'zipCode': '56349', 'north': 50.123149600000005, 'east': 7.839143800000001, 'south': 50.0664628, 'west': 7.7220443}, {
  'zipCode': '56355',
  'north':   50.2728253,
  'east':    7.912514300000001,
  'south':   50.1482045,
  'west':    7.712547000000001
}, {'zipCode': '56357', 'north': 50.289926, 'east': 7.9514026, 'south': 50.1012354, 'west': 7.698799300000001}, {
  'zipCode': '56368',
  'north':   50.2976925,
  'east':    8.0334874,
  'south':   50.2466299,
  'west':    7.8768264
}, {'zipCode': '56370', 'north': 50.3401011, 'east': 8.058272, 'south': 50.2142714, 'west': 7.853507700000001}, {
  'zipCode': '56377',
  'north':   50.3481636,
  'east':    7.8901899,
  'south':   50.2718321,
  'west':    7.739252
}, {'zipCode': '56379', 'north': 50.3785847, 'east': 7.9736473, 'south': 50.2591042, 'west': 7.733512900000001}, {
  'zipCode': '56410',
  'north':   50.4570966,
  'east':    7.886549399999999,
  'south':   50.3832028,
  'west':    7.7277362
}, {'zipCode': '56412', 'north': 50.4873955, 'east': 7.989159000000001, 'south': 50.3474351, 'west': 7.751074999999999}, {
  'zipCode': '56414',
  'north':   50.5213704,
  'east':    8.0107968,
  'south':   50.4357459,
  'west':    7.8417174
}, {'zipCode': '56422', 'north': 50.4895904, 'east': 7.8290394, 'south': 50.4365335, 'west': 7.7396145}, {
  'zipCode': '56424',
  'north':   50.50069630000001,
  'east':    7.8537325000000004,
  'south':   50.4463634,
  'west':    7.726966
}, {'zipCode': '56427', 'north': 50.4947507, 'east': 7.794329900000001, 'south': 50.4757153, 'west': 7.751043200000001}, {
  'zipCode': '56428',
  'north':   50.466742,
  'east':    7.8205348,
  'south':   50.4295897,
  'west':    7.7330873
}, {'zipCode': '56457', 'north': 50.6012977, 'east': 8.009693, 'south': 50.5344836, 'west': 7.923793700000001}, {
  'zipCode': '56459',
  'north':   50.6178429,
  'east':    8.0357115,
  'south':   50.511378400000005,
  'west':    7.861735499999999
}, {'zipCode': '56462', 'north': 50.6383419, 'east': 8.0215206, 'south': 50.5960819, 'west': 7.932158499999999}, {
  'zipCode': '56470',
  'north':   50.6684877,
  'east':    7.9776589,
  'south':   50.6300239,
  'west':    7.920391499999999
}, {'zipCode': '56472', 'north': 50.6969097, 'east': 8.0377531, 'south': 50.6069896, 'west': 7.9066125000000005}, {
  'zipCode': '56477',
  'north':   50.6644047,
  'east':    8.1149757,
  'south':   50.5888025,
  'west':    8.0417496
}, {'zipCode': '56479', 'north': 50.7066071, 'east': 8.1523223, 'south': 50.5325803, 'west': 8.0060828}, {
  'zipCode': '56564',
  'north':   50.4553642,
  'east':    7.488717200000001,
  'south':   50.4122197,
  'west':    7.4441356
}, {'zipCode': '56566', 'north': 50.4862718, 'east': 7.5832597, 'south': 50.4118038, 'west': 7.4793295}, {
  'zipCode': '56567',
  'north':   50.5091018,
  'east':    7.5392703,
  'south':   50.4376368,
  'west':    7.383404700000001
}, {'zipCode': '56575', 'north': 50.4289168, 'east': 7.4799318, 'south': 50.4052286, 'west': 7.428544200000001}, {
  'zipCode': '56579',
  'north':   50.540299,
  'east':    7.544142800000001,
  'south':   50.4873718,
  'west':    7.4710206
}, {'zipCode': '56581', 'north': 50.5538632, 'east': 7.495216800000001, 'south': 50.474466400000004, 'west': 7.4377052}, {
  'zipCode': '56584',
  'north':   50.5358515,
  'east':    7.596315199999999,
  'south':   50.4791788,
  'west':    7.5120962
}, {'zipCode': '56587', 'north': 50.5737496, 'east': 7.5582404, 'south': 50.5207419, 'west': 7.4648305}, {
  'zipCode': '56588',
  'north':   50.564335,
  'east':    7.4553592,
  'south':   50.5372496,
  'west':    7.3849467
}, {'zipCode': '56589', 'north': 50.5493817, 'east': 7.4620723, 'south': 50.48893280000001, 'west': 7.3948936}, {
  'zipCode': '56593',
  'north':   50.6175043,
  'east':    7.5591702,
  'south':   50.5708459,
  'west':    7.4705032
}, {'zipCode': '56594', 'north': 50.5799155, 'east': 7.539866100000001, 'south': 50.5622249, 'west': 7.513951900000001}, {
  'zipCode': '56598',
  'north':   50.5324196,
  'east':    7.4022378,
  'south':   50.4659385,
  'west':    7.311265800000001
}, {'zipCode': '56599', 'north': 50.494633, 'east': 7.409000700000001, 'south': 50.44287820000001, 'west': 7.3559604}, {
  'zipCode': '56626',
  'north':   50.4766043,
  'east':    7.4506367,
  'south':   50.386007,
  'west':    7.2869285
}, {'zipCode': '56630', 'north': 50.4133656, 'east': 7.3844084, 'south': 50.3816033, 'west': 7.3425419}, {
  'zipCode': '56637',
  'north':   50.4133656,
  'east':    7.406596700000001,
  'south':   50.3665938,
  'west':    7.3558027
}, {'zipCode': '56642', 'north': 50.4122097, 'east': 7.3633686, 'south': 50.3485865, 'west': 7.2753683}, {
  'zipCode': '56645',
  'north':   50.435776600000004,
  'east':    7.378995799999999,
  'south':   50.3917077,
  'west':    7.253718299999999
}, {'zipCode': '56648', 'north': 50.3913348, 'east': 7.4421357, 'south': 50.3629066, 'west': 7.3949854}, {
  'zipCode': '56651',
  'north':   50.4839392,
  'east':    7.2454805,
  'south':   50.4202498,
  'west':    7.1170894
}, {'zipCode': '56653', 'north': 50.4504622, 'east': 7.295827100000001, 'south': 50.3938025, 'west': 7.1704173}, {
  'zipCode': '56656',
  'north':   50.4957771,
  'east':    7.34454,
  'south':   50.45871530000001,
  'west':    7.283444499999999
}, {'zipCode': '56659', 'north': 50.4775934, 'east': 7.298834799999999, 'south': 50.4404025, 'west': 7.2348625}, {
  'zipCode': '56727',
  'north':   50.3643895,
  'east':    7.2962137,
  'south':   50.280562,
  'west':    7.0962657
}, {'zipCode': '56729', 'north': 50.4003676, 'east': 7.2450395, 'south': 50.2577897, 'west': 6.973559}, {
  'zipCode': '56736',
  'north':   50.360696000000004,
  'east':    7.2732082,
  'south':   50.3318296,
  'west':    7.228174800000001
}, {'zipCode': '56743', 'north': 50.3978571, 'east': 7.3347968, 'south': 50.335741000000006, 'west': 7.193045700000001}, {
  'zipCode': '56745',
  'north':   50.4203565,
  'east':    7.256959,
  'south':   50.3709383,
  'west':    7.0923469
}, {'zipCode': '56746', 'north': 50.4557316, 'east': 7.171470000000001, 'south': 50.3919485, 'west': 7.0362045}, {
  'zipCode': '56751',
  'north':   50.3292174,
  'east':    7.3785135,
  'south':   50.2516639,
  'west':    7.236174500000001
}, {'zipCode': '56753', 'north': 50.3506589, 'east': 7.3561063, 'south': 50.2193172, 'west': 7.270123300000001}, {
  'zipCode': '56754',
  'north':   50.255489,
  'east':    7.3189995,
  'south':   50.1901982,
  'west':    7.2089344
}, {'zipCode': '56759', 'north': 50.2811025, 'east': 7.1773408, 'south': 50.199986100000004, 'west': 7.0442701}, {
  'zipCode': '56761',
  'north':   50.2811081,
  'east':    7.2484649999999995,
  'south':   50.2025797,
  'west':    7.0501661
}, {'zipCode': '56766', 'north': 50.2551892, 'east': 7.0655242, 'south': 50.1623604, 'west': 6.9386078}, {
  'zipCode': '56767',
  'north':   50.28126090000001,
  'east':    7.0705175,
  'south':   50.2192114,
  'west':    6.9089783
}, {'zipCode': '56769', 'north': 50.306756400000005, 'east': 7.0677324, 'south': 50.2581567, 'west': 6.9491601}, {
  'zipCode': '56812',
  'north':   50.1793685,
  'east':    7.2552189,
  'south':   50.1126711,
  'west':    7.1019054
}, {'zipCode': '56814', 'north': 50.220370800000005, 'east': 7.288997500000001, 'south': 50.0650298, 'west': 7.0676}, {
  'zipCode': '56818',
  'north':   50.1886798,
  'east':    7.230333699999999,
  'south':   50.1535492,
  'west':    7.1436757
}, {'zipCode': '56820', 'north': 50.1176758, 'east': 7.246859700000001, 'south': 50.0460475, 'west': 7.175420299999999}, {
  'zipCode': '56821',
  'north':   50.1292505,
  'east':    7.2781415,
  'south':   50.099298700000006,
  'west':    7.191328000000001
}, {'zipCode': '56823', 'north': 50.2060613, 'east': 7.1176897, 'south': 50.1586629, 'west': 7.0533445}, {
  'zipCode': '56825',
  'north':   50.172849,
  'east':    7.1061142,
  'south':   50.0647653,
  'west':    6.9904594
}, {'zipCode': '56826', 'north': 50.166347, 'east': 7.0535876, 'south': 50.0825652, 'west': 6.9556304}, {
  'zipCode': '56828',
  'north':   50.2051953,
  'east':    7.0781944,
  'south':   50.1577347,
  'west':    7.0111408
}, {'zipCode': '56829', 'north': 50.1998802, 'east': 7.2950949000000005, 'south': 50.1606403, 'west': 7.2156855}, {
  'zipCode': '56841',
  'north':   49.9857978,
  'east':    7.1740111,
  'south':   49.9025508,
  'west':    7.0494069
}, {'zipCode': '56843', 'north': 50.0242272, 'east': 7.253369800000001, 'south': 49.9010961, 'west': 7.114678}, {
  'zipCode': '56850',
  'north':   49.9966888,
  'east':    7.286722000000001,
  'south':   49.9411937,
  'west':    7.1144325
}, {'zipCode': '56856', 'north': 50.0662912, 'east': 7.254792200000001, 'south': 49.9869666, 'west': 7.1288011}, {
  'zipCode': '56858',
  'north':   50.1197568,
  'east':    7.3547289,
  'south':   49.9592642,
  'west':    7.0825627
}, {'zipCode': '56859', 'north': 50.0768427, 'east': 7.251252600000001, 'south': 50.0274636, 'west': 7.0800017}, {
  'zipCode': '56861',
  'north':   50.0505289,
  'east':    7.1261477,
  'south':   49.9977217,
  'west':    7.0640462
}, {'zipCode': '56862', 'north': 50.0490266, 'east': 7.1450598, 'south': 50.0177095, 'west': 7.1023134}, {
  'zipCode': '56864',
  'north':   50.1063385,
  'east':    7.0561506,
  'south':   50.0508498,
  'west':    7.0021678
}, {'zipCode': '56865', 'north': 50.0744585, 'east': 7.3338107, 'south': 50.0099732, 'west': 7.227157900000001}, {
  'zipCode': '56867',
  'north':   50.0405886,
  'east':    7.253119800000001,
  'south':   49.9592663,
  'west':    7.1284952
}, {'zipCode': '56869', 'north': 50.0827006, 'east': 7.377404000000001, 'south': 50.0239431, 'west': 7.314633500000001}, {
  'zipCode': '57072',
  'north':   50.9087815,
  'east':    8.0466332,
  'south':   50.853182,
  'west':    7.937840300000001
}, {'zipCode': '57074', 'north': 50.9006813, 'east': 8.1279162, 'south': 50.8436736, 'west': 8.0084867}, {
  'zipCode': '57076',
  'north':   50.9174718,
  'east':    8.056663100000002,
  'south':   50.8869789,
  'west':    8.0024007
}, {'zipCode': '57078', 'north': 50.9513102, 'east': 8.0582034, 'south': 50.8970709, 'west': 7.9215923}, {
  'zipCode': '57080',
  'north':   50.8807064,
  'east':    8.0533367,
  'south':   50.8114072,
  'west':    7.925000700000001
}, {'zipCode': '57223', 'north': 51.0424884, 'east': 8.0710864, 'south': 50.9242212, 'west': 7.9198791}, {
  'zipCode': '57234',
  'north':   50.8776637,
  'east':    8.2012294,
  'south':   50.7888397,
  'west':    8.0437642
}, {'zipCode': '57250', 'north': 50.9729667, 'east': 8.2845823, 'south': 50.833958, 'west': 8.0324462}, {
  'zipCode': '57258',
  'north':   50.9346093,
  'east':    7.960623599999999,
  'south':   50.8442989,
  'west':    7.8273206
}, {'zipCode': '57271', 'north': 51.0248164, 'east': 8.2183022, 'south': 50.9429789, 'west': 8.0110413}, {
  'zipCode': '57290',
  'north':   50.8201452,
  'east':    8.0798126,
  'south':   50.7456282,
  'west':    7.9641173
}, {'zipCode': '57299', 'north': 50.7967859, 'east': 8.1668214, 'south': 50.6852053, 'west': 8.0107083}, {
  'zipCode': '57319',
  'north':   51.1497338,
  'east':    8.5485948,
  'south':   50.9596651,
  'west':    8.2204799
}, {'zipCode': '57334', 'north': 50.987491, 'east': 8.4645842, 'south': 50.860717, 'west': 8.2390252}, {
  'zipCode': '57339',
  'north':   51.051154000000004,
  'east':    8.3468605,
  'south':   50.92724640000001,
  'west':    8.1884935
}, {'zipCode': '57368', 'north': 51.1959948, 'east': 8.2414913, 'south': 51.0564347, 'west': 7.9582091}, {
  'zipCode': '57392',
  'north':   51.2885048,
  'east':    8.4892141,
  'south':   51.0927622,
  'west':    8.1303495
}, {'zipCode': '57399', 'north': 51.1144107, 'east': 8.246553100000002, 'south': 51.0049478, 'west': 7.9339165}, {
  'zipCode': '57413',
  'north':   51.2483137,
  'east':    8.1218843,
  'south':   51.1287485,
  'west':    7.898131800000001
}, {'zipCode': '57439', 'north': 51.1706901, 'east': 8.0040134, 'south': 51.0727137, 'west': 7.7957906999999995}, {
  'zipCode': '57462',
  'north':   51.0878047,
  'east':    7.973322999999999,
  'south':   50.9870251,
  'west':    7.8040926
}, {'zipCode': '57482', 'north': 51.0034247, 'east': 7.9420652, 'south': 50.9245678, 'west': 7.779740800000001}, {
  'zipCode': '57489',
  'north':   51.0799901,
  'east':    7.831124000000001,
  'south':   50.9777512,
  'west':    7.701677600000001
}, {'zipCode': '57518', 'north': 50.8038374, 'east': 7.9029779, 'south': 50.7466319, 'west': 7.8278292}, {
  'zipCode': '57520',
  'north':   50.7898231,
  'east':    8.0430537,
  'south':   50.6809423,
  'west':    7.8084091
}, {'zipCode': '57537', 'north': 50.8281592, 'east': 7.8349938, 'south': 50.7324292, 'west': 7.6593427}, {
  'zipCode': '57539',
  'north':   50.7983027,
  'east':    7.7372833,
  'south':   50.7372736,
  'west':    7.642216899999999
}, {'zipCode': '57548', 'north': 50.8678787, 'east': 7.9431954, 'south': 50.7816353, 'west': 7.8064497}, {
  'zipCode': '57555',
  'north':   50.848888,
  'east':    7.978179399999999,
  'south':   50.797578800000004,
  'west':    7.914268300000001
}, {'zipCode': '57562', 'north': 50.8146349, 'east': 7.985006000000001, 'south': 50.7456321, 'west': 7.897623}, {
  'zipCode': '57567',
  'north':   50.7636703,
  'east':    8.0259531,
  'south':   50.7209701,
  'west':    7.9169816
}, {'zipCode': '57572', 'north': 50.8823964, 'east': 7.916503199999999, 'south': 50.8282686, 'west': 7.809833499999999}, {
  'zipCode': '57577',
  'north':   50.7780336,
  'east':    7.7008136,
  'south':   50.7349997,
  'west':    7.658443800000001
}, {'zipCode': '57578', 'north': 50.7479396, 'east': 7.908483400000001, 'south': 50.7134159, 'west': 7.8574379}, {
  'zipCode': '57580',
  'north':   50.7713494,
  'east':    7.844759,
  'south':   50.7277866,
  'west':    7.769142600000001
}, {'zipCode': '57581', 'north': 50.8438526, 'east': 7.835117499999999, 'south': 50.8059487, 'west': 7.7598958}, {
  'zipCode': '57583',
  'north':   50.7157734,
  'east':    7.922326300000001,
  'south':   50.6786287,
  'west':    7.8529479
}, {'zipCode': '57584', 'north': 50.8118002, 'east': 7.8580606, 'south': 50.7742271, 'west': 7.8215068}, {
  'zipCode': '57586',
  'north':   50.7403633,
  'east':    7.955838300000001,
  'south':   50.7092281,
  'west':    7.9024038
}, {'zipCode': '57587', 'north': 50.8513128, 'east': 7.79238, 'south': 50.8008978, 'west': 7.7039398}, {
  'zipCode': '57589',
  'north':   50.7710047,
  'east':    7.6615500999999995,
  'south':   50.732242600000006,
  'west':    7.589353300000001
}, {'zipCode': '57610', 'north': 50.729311, 'east': 7.727795200000001, 'south': 50.6474415, 'west': 7.6022554}, {
  'zipCode': '57612',
  'north':   50.7490272,
  'east':    7.7550109,
  'south':   50.6814725,
  'west':    7.563168800000001
}, {'zipCode': '57614', 'north': 50.6838996, 'east': 7.7535441, 'south': 50.5919436, 'west': 7.5696625}, {
  'zipCode': '57627',
  'north':   50.7077337,
  'east':    7.8731882,
  'south':   50.6126555,
  'west':    7.7365431
}, {'zipCode': '57629', 'north': 50.7402953, 'east': 7.948249299999999, 'south': 50.5803559, 'west': 7.7182844}, {
  'zipCode': '57632',
  'north':   50.6946324,
  'east':    7.5896539999999995,
  'south':   50.5970478,
  'west':    7.450947
}, {'zipCode': '57635', 'north': 50.7465847, 'east': 7.6155965000000005, 'south': 50.66844890000001, 'west': 7.4389893}, {
  'zipCode': '57636',
  'north':   50.7172784,
  'east':    7.700864100000001,
  'south':   50.6911951,
  'west':    7.6515268
}, {'zipCode': '57638', 'north': 50.6887833, 'east': 7.6236573, 'south': 50.6506456, 'west': 7.5541113}, {
  'zipCode': '57639',
  'north':   50.6533381,
  'east':    7.7009694,
  'south':   50.6121731,
  'west':    7.6177117
}, {'zipCode': '57641', 'north': 50.630454300000004, 'east': 7.5450232, 'south': 50.60790360000001, 'west': 7.4928105}, {
  'zipCode': '57642',
  'north':   50.6614403,
  'east':    7.8894892,
  'south':   50.61364350000001,
  'west':    7.826309300000001
}, {'zipCode': '57644', 'north': 50.6785026, 'east': 7.7998269, 'south': 50.6175326, 'west': 7.7068498000000005}, {
  'zipCode': '57645',
  'north':   50.6857256,
  'east':    7.8892723,
  'south':   50.6706063,
  'west':    7.818506600000001
}, {'zipCode': '57647', 'north': 50.6446378, 'east': 7.9153334, 'south': 50.6055728, 'west': 7.8788183}, {
  'zipCode': '57648',
  'north':   50.6739194,
  'east':    7.939785700000001,
  'south':   50.6370893,
  'west':    7.861313700000001
}, {'zipCode': '58089', 'north': 51.4168628, 'east': 7.4709015, 'south': 51.3404809, 'west': 7.3903027}, {
  'zipCode': '58091',
  'north':   51.354286,
  'east':    7.5809749,
  'south':   51.2648764,
  'west':    7.4380615
}, {'zipCode': '58093', 'north': 51.4042112, 'east': 7.5906244, 'south': 51.3392843, 'west': 7.4803886}, {
  'zipCode': '58095',
  'north':   51.369204,
  'east':    7.510191399999999,
  'south':   51.3488453,
  'west':    7.459680799999999
}, {'zipCode': '58097', 'north': 51.384469, 'east': 7.503246200000001, 'south': 51.3560048, 'west': 7.4601511}, {
  'zipCode': '58099',
  'north':   51.418567700000004,
  'east':    7.552771300000001,
  'south':   51.3580364,
  'west':    7.447063199999999
}, {'zipCode': '58119', 'north': 51.3820287, 'east': 7.5989002, 'south': 51.2956167, 'west': 7.531537100000001}, {
  'zipCode': '58135',
  'north':   51.3709187,
  'east':    7.467091400000001,
  'south':   51.2899088,
  'west':    7.3759137
}, {'zipCode': '58239', 'north': 51.4759983, 'east': 7.635045200000001, 'south': 51.3852385, 'west': 7.5032305}, {
  'zipCode': '58256',
  'north':   51.3323022,
  'east':    7.432745800000001,
  'south':   51.2388271,
  'west':    7.2964768
}, {'zipCode': '58285', 'north': 51.3664949, 'east': 7.3952949, 'south': 51.3039971, 'west': 7.2978206000000005}, {
  'zipCode': '58300',
  'north':   51.4101246,
  'east':    7.407776900000001,
  'south':   51.3464905,
  'west':    7.295610800000001
}, {'zipCode': '58313', 'north': 51.436305, 'east': 7.4674678, 'south': 51.3897094, 'west': 7.353377400000001}, {
  'zipCode': '58332',
  'north':   51.3167965,
  'east':    7.3202988,
  'south':   51.2495778,
  'west':    7.2557258000000004
}, {'zipCode': '58339', 'north': 51.3188668, 'east': 7.5208816999999994, 'south': 51.2131841, 'west': 7.3640909}, {
  'zipCode': '58452',
  'north':   51.4469722,
  'east':    7.354319500000001,
  'south':   51.4010946,
  'west':    7.298935600000001
}, {'zipCode': '58453', 'north': 51.4575169, 'east': 7.414335000000001, 'south': 51.3977286, 'west': 7.337374700000001}, {
  'zipCode': '58454',
  'north':   51.4843402,
  'east':    7.423475,
  'south':   51.428522,
  'west':    7.3420393
}, {'zipCode': '58455', 'north': 51.4578013, 'east': 7.361834099999999, 'south': 51.4233206, 'west': 7.274922600000001}, {
  'zipCode': '58456',
  'north':   51.4350361,
  'east':    7.319694,
  'south':   51.3700726,
  'west':    7.2460717
}, {'zipCode': '58507', 'north': 51.2447308, 'east': 7.6485057, 'south': 51.2153605, 'west': 7.603466900000001}, {
  'zipCode': '58509',
  'north':   51.2390794,
  'east':    7.6284841,
  'south':   51.2050705,
  'west':    7.5822345
}, {'zipCode': '58511', 'north': 51.2330741, 'east': 7.6703, 'south': 51.2031465, 'west': 7.6257033}, {
  'zipCode': '58513',
  'north':   51.27076240000001,
  'east':    7.7232561,
  'south':   51.19648290000001,
  'west':    7.5812646
}, {'zipCode': '58515', 'north': 51.2434251, 'east': 7.705700800000001, 'south': 51.1551181, 'west': 7.552206900000001}, {
  'zipCode': '58540',
  'north':   51.1585402,
  'east':    7.838762799999999,
  'south':   51.06859010000001,
  'west':    7.597513
}, {'zipCode': '58553', 'north': 51.2399451, 'east': 7.5813438, 'south': 51.125000500000006, 'west': 7.417209800000001}, {
  'zipCode': '58566',
  'north':   51.1905505,
  'east':    7.6713683999999995,
  'south':   51.0997233,
  'west':    7.4952179
}, {'zipCode': '58579', 'north': 51.2962068, 'east': 7.6333032, 'south': 51.2205239, 'west': 7.4933325}, {
  'zipCode': '58636',
  'north':   51.4115586,
  'east':    7.744993599999999,
  'south':   51.3680285,
  'west':    7.6914279
}, {'zipCode': '58638', 'north': 51.3999656, 'east': 7.6990963, 'south': 51.367991, 'west': 7.6554679}, {
  'zipCode': '58640',
  'north':   51.4736517,
  'east':    7.736957500000001,
  'south':   51.3941466,
  'west':    7.612927
}, {'zipCode': '58642', 'north': 51.4063288, 'east': 7.6643271, 'south': 51.3330341, 'west': 7.5841175000000005}, {
  'zipCode': '58644',
  'north':   51.3791867,
  'east':    7.7418615,
  'south':   51.3017592,
  'west':    7.6336906
}, {'zipCode': '58675', 'north': 51.4174814, 'east': 7.8534217, 'south': 51.3122282, 'west': 7.720223900000001}, {
  'zipCode': '58706',
  'north':   51.4588383,
  'east':    7.8231221,
  'south':   51.4082498,
  'west':    7.7628143000000005
}, {'zipCode': '58708', 'north': 51.4784094, 'east': 7.896519, 'south': 51.38923170000001, 'west': 7.6916923}, {
  'zipCode': '58710',
  'north':   51.427562,
  'east':    7.8535379,
  'south':   51.3922708,
  'west':    7.8001574
}, {'zipCode': '58730', 'north': 51.5295292, 'east': 7.850268300000001, 'south': 51.4666596, 'west': 7.656295600000001}, {
  'zipCode': '58739',
  'north':   51.5338957,
  'east':    7.9244028,
  'south':   51.4572843,
  'west':    7.831793399999999
}, {'zipCode': '58762', 'north': 51.3181545, 'east': 7.768028700000001, 'south': 51.2386464, 'west': 7.6316785}, {
  'zipCode': '58769',
  'north':   51.3518721,
  'east':    7.6672367,
  'south':   51.2755788,
  'west':    7.576616600000001
}, {'zipCode': '58791', 'north': 51.288707, 'east': 7.8137384, 'south': 51.2188555, 'west': 7.691649699999999}, {
  'zipCode': '58802',
  'north':   51.397942900000004,
  'east':    7.9340108,
  'south':   51.2895054,
  'west':    7.7729684
}, {'zipCode': '58809', 'north': 51.3179836, 'east': 7.922486799999999, 'south': 51.2452576, 'west': 7.729976099999999}, {
  'zipCode': '58840',
  'north':   51.2591932,
  'east':    7.9512856,
  'south':   51.1523615,
  'west':    7.7671741
}, {'zipCode': '58849', 'north': 51.2302251, 'east': 7.813001399999999, 'south': 51.1401713, 'west': 7.6897041}, {
  'zipCode': '59063',
  'north':   51.6878931,
  'east':    7.8595855,
  'south':   51.6562023,
  'west':    7.8053317
}, {'zipCode': '59065', 'north': 51.7067245, 'east': 7.840218699999999, 'south': 51.6707287, 'west': 7.778147199999999}, {
  'zipCode': '59067',
  'north':   51.6852767,
  'east':    7.809290000000001,
  'south':   51.6360215,
  'west':    7.7524803
}, {'zipCode': '59069', 'north': 51.6682708, 'east': 7.936703700000001, 'south': 51.5786497, 'west': 7.8022042}, {
  'zipCode': '59071',
  'north':   51.706297,
  'east':    7.997435100000001,
  'south':   51.6379707,
  'west':    7.8365325
}, {'zipCode': '59073', 'north': 51.7445915, 'east': 7.8899395, 'south': 51.6917348, 'west': 7.778461799999999}, {
  'zipCode': '59075',
  'north':   51.7392731,
  'east':    7.7960769999999995,
  'south':   51.6736851,
  'west':    7.699099100000001
}, {'zipCode': '59077', 'north': 51.67918280000001, 'east': 7.8182316, 'south': 51.6114779, 'west': 7.6747699}, {
  'zipCode': '59174',
  'north':   51.614974800000006,
  'east':    7.7502902,
  'south':   51.5485082,
  'west':    7.5902989
}, {'zipCode': '59192', 'north': 51.6610852, 'east': 7.690428600000001, 'south': 51.5877449, 'west': 7.560597400000001}, {
  'zipCode': '59199',
  'north':   51.62690750000001,
  'east':    7.8270933,
  'south':   51.561186,
  'west':    7.714382100000001
}, {'zipCode': '59227', 'north': 51.831293, 'east': 7.991584600000001, 'south': 51.7267146, 'west': 7.788190700000001}, {
  'zipCode': '59229',
  'north':   51.7790666,
  'east':    7.9804163,
  'south':   51.7001126,
  'west':    7.8582373
}, {'zipCode': '59269', 'north': 51.8191029, 'east': 8.1375625, 'south': 51.70168770000001, 'west': 7.952891700000001}, {
  'zipCode': '59302',
  'north':   51.8997389,
  'east':    8.2598919,
  'south':   51.7581019,
  'west':    8.0817955
}, {'zipCode': '59320', 'north': 51.9228651, 'east': 8.1408843, 'south': 51.8047066, 'west': 7.8783413}, {
  'zipCode': '59329',
  'north':   51.778545900000005,
  'east':    8.3200888,
  'south':   51.658104,
  'west':    8.11614
}, {'zipCode': '59348', 'north': 51.8336962, 'east': 7.5385479, 'south': 51.7215939, 'west': 7.2589982}, {
  'zipCode': '59368',
  'north':   51.728775,
  'east':    7.728652800000001,
  'south':   51.6271266,
  'west':    7.540556
}, {'zipCode': '59379', 'north': 51.7361609, 'east': 7.549053399999999, 'south': 51.6404029, 'west': 7.4088218}, {
  'zipCode': '59387',
  'north':   51.8429674,
  'east':    7.7372746,
  'south':   51.7055096,
  'west':    7.548748700000001
}, {'zipCode': '59394', 'north': 51.7757479, 'east': 7.6277017, 'south': 51.6847617, 'west': 7.4771127}, {
  'zipCode': '59399',
  'north':   51.7428868,
  'east':    7.451753,
  'south':   51.6647032,
  'west':    7.298802299999999
}, {'zipCode': '59423', 'north': 51.5396382, 'east': 7.734075899999999, 'south': 51.5206774, 'west': 7.6580349}, {
  'zipCode': '59425',
  'north':   51.5712563,
  'east':    7.752469100000001,
  'south':   51.5201918,
  'west':    7.634181700000001
}, {'zipCode': '59427', 'north': 51.5786497, 'east': 7.8429416, 'south': 51.4904102, 'west': 7.6287533}, {
  'zipCode': '59439',
  'north':   51.5211483,
  'east':    7.672892000000001,
  'south':   51.4514581,
  'west':    7.596379499999999
}, {'zipCode': '59457', 'north': 51.595809, 'east': 7.998559499999999, 'south': 51.50876310000001, 'west': 7.8270933}, {
  'zipCode': '59469',
  'north':   51.5382417,
  'east':    8.0347028,
  'south':   51.4660833,
  'west':    7.8812241
}, {'zipCode': '59494', 'north': 51.62755810000001, 'east': 8.1762011, 'south': 51.5214743, 'west': 7.9831444}, {
  'zipCode': '59505',
  'north':   51.6464789,
  'east':    8.2449186,
  'south':   51.5198309,
  'west':    8.1234923
}, {'zipCode': '59510', 'north': 51.7230105, 'east': 8.2118773, 'south': 51.6141252, 'west': 7.9450712}, {
  'zipCode': '59514',
  'north':   51.6757925,
  'east':    8.09042,
  'south':   51.5630856,
  'west':    7.909544399999999
}, {'zipCode': '59519', 'north': 51.5330592, 'east': 8.2365748, 'south': 51.4222501, 'west': 8.0113129}, {
  'zipCode': '59555',
  'north':   51.7110991,
  'east':    8.3718587,
  'south':   51.6705752,
  'west':    8.3007435
}, {'zipCode': '59556', 'north': 51.7277848, 'east': 8.3664699, 'south': 51.6252638, 'west': 8.1827493}, {
  'zipCode': '59557',
  'north':   51.6827697,
  'east':    8.3791113,
  'south':   51.6411238,
  'west':    8.3091184
}, {'zipCode': '59558', 'north': 51.7240986, 'east': 8.4850582, 'south': 51.6264187, 'west': 8.3550522}, {
  'zipCode': '59581',
  'north':   51.5227689,
  'east':    8.409836,
  'south':   51.3885478,
  'west':    8.2017846
}, {'zipCode': '59590', 'north': 51.6885312, 'east': 8.5871221, 'south': 51.5686143, 'west': 8.4119087}, {
  'zipCode': '59597',
  'north':   51.6479756,
  'east':    8.4313821,
  'south':   51.5710992,
  'west':    8.2000704
}, {'zipCode': '59602', 'north': 51.5989395, 'east': 8.5478748, 'south': 51.4050201, 'west': 8.3502506}, {
  'zipCode': '59609',
  'north':   51.5929391,
  'east':    8.3861459,
  'south':   51.5071301,
  'west':    8.2242744
}, {'zipCode': '59755', 'north': 51.4747056, 'east': 8.0186153, 'south': 51.4391207, 'west': 7.946814600000001}, {
  'zipCode': '59757',
  'north':   51.4913301,
  'east':    8.040623,
  'south':   51.3716779,
  'west':    7.864030100000001
}, {'zipCode': '59759', 'north': 51.460876, 'east': 8.0357369, 'south': 51.4041414, 'west': 7.954483000000001}, {
  'zipCode': '59821',
  'north':   51.4187646,
  'east':    8.0980784,
  'south':   51.3547136,
  'west':    7.9907563
}, {'zipCode': '59823', 'north': 51.4563178, 'east': 8.2102601, 'south': 51.3601022, 'west': 8.0309499}, {
  'zipCode': '59846',
  'north':   51.3943681,
  'east':    8.1320307,
  'south':   51.2331063,
  'west':    7.901431699999999
}, {'zipCode': '59872', 'north': 51.4107402, 'east': 8.3741186, 'south': 51.2507209, 'west': 8.0965476}, {
  'zipCode': '59889',
  'north':   51.3163921,
  'east':    8.2964126,
  'south':   51.1734278,
  'west':    8.0658511
}, {'zipCode': '59909', 'north': 51.4082815, 'east': 8.453382800000002, 'south': 51.261356, 'west': 8.3488148}, {
  'zipCode': '59929',
  'north':   51.4845507,
  'east':    8.7572233,
  'south':   51.2884305,
  'west':    8.461125
}, {'zipCode': '59939', 'north': 51.434444400000004, 'east': 8.5734533, 'south': 51.259278, 'west': 8.4147953}, {
  'zipCode': '59955',
  'north':   51.27698110000001,
  'east':    8.6078878,
  'south':   51.1243752,
  'west':    8.4191316
}, {'zipCode': '59964', 'north': 51.2766871, 'east': 8.7681755, 'south': 51.1322634, 'west': 8.5875765}, {
  'zipCode': '59969',
  'north':   51.1768682,
  'east':    8.7201034,
  'south':   51.061447,
  'west':    8.4971952
}, {'zipCode': '60306', 'north': 50.116553, 'east': 8.6707255, 'south': 50.1155649, 'west': 8.6695498}, {
  'zipCode': '60308',
  'north':   50.112719,
  'east':    8.653367,
  'south':   50.11186090000001,
  'west':    8.6520947
}, {'zipCode': '60310', 'north': 50.11112210000001, 'east': 8.6736415, 'south': 50.1101158, 'west': 8.6720136}, {
  'zipCode': '60311',
  'north':   50.1146162,
  'east':    8.695728,
  'south':   50.1055195,
  'west':    8.670528
}, {'zipCode': '60313', 'north': 50.1189513, 'east': 8.6954234, 'south': 50.1123981, 'west': 8.6707175}, {
  'zipCode': '60314',
  'north':   50.12695800000001,
  'east':    8.7511194,
  'south':   50.1063399,
  'west':    8.6929202
}, {'zipCode': '60316', 'north': 50.129362, 'east': 8.7081594, 'south': 50.113076500000005, 'west': 8.6865185}, {
  'zipCode': '60318',
  'north':   50.1322654,
  'east':    8.6945044,
  'south':   50.1168667,
  'west':    8.6783244
}, {'zipCode': '60320', 'north': 50.1474338, 'east': 8.6961813, 'south': 50.1313751, 'west': 8.6597601}, {
  'zipCode': '60322',
  'north':   50.1338377,
  'east':    8.6841378,
  'south':   50.1156202,
  'west':    8.6664684
}, {'zipCode': '60323', 'north': 50.1317641, 'east': 8.6727236, 'south': 50.1158427, 'west': 8.6526952}, {
  'zipCode': '60325',
  'north':   50.1248666,
  'east':    8.6719437,
  'south':   50.1094577,
  'west':    8.6488782
}, {'zipCode': '60326', 'north': 50.114002, 'east': 8.6512742, 'south': 50.0942792, 'west': 8.6044347}, {
  'zipCode': '60327',
  'north':   50.112743,
  'east':    8.6666439,
  'south':   50.0889704,
  'west':    8.6167326
}, {'zipCode': '60329', 'north': 50.1141779, 'east': 8.6743594, 'south': 50.1010326, 'west': 8.6565254}, {
  'zipCode': '60385',
  'north':   50.1371295,
  'east':    8.7290235,
  'south':   50.1143071,
  'west':    8.6990897
}, {'zipCode': '60386', 'north': 50.1441125, 'east': 8.785131, 'south': 50.1078517, 'west': 8.7194482}, {
  'zipCode': '60388',
  'north':   50.1802396,
  'east':    8.8004716,
  'south':   50.1315838,
  'west':    8.7223643
}, {'zipCode': '60389', 'north': 50.1634819, 'east': 8.7417934, 'south': 50.1248413, 'west': 8.6922768}, {
  'zipCode': '60431',
  'north':   50.158845,
  'east':    8.6674751,
  'south':   50.131792100000006,
  'west':    8.6322556
}, {'zipCode': '60433', 'north': 50.1779531, 'east': 8.6907487, 'south': 50.14571900000001, 'west': 8.6498067}, {
  'zipCode': '60435',
  'north':   50.1813482,
  'east':    8.7230561,
  'south':   50.1358252,
  'west':    8.674346800000002
}, {'zipCode': '60437', 'north': 50.2271408, 'east': 8.7350627, 'south': 50.1709988, 'west': 8.6229612}, {
  'zipCode': '60438',
  'north':   50.1886202,
  'east':    8.6475161,
  'south':   50.1688127,
  'west':    8.6054019
}, {'zipCode': '60439', 'north': 50.17627, 'east': 8.6533976, 'south': 50.1490925, 'west': 8.5878627}, {
  'zipCode': '60486',
  'north':   50.12386010000001,
  'east':    8.6526051,
  'south':   50.1051012,
  'west':    8.5996156
}, {'zipCode': '60487', 'north': 50.1372681, 'east': 8.662349, 'south': 50.1194024, 'west': 8.6200184}, {
  'zipCode': '60488',
  'north':   50.1561118,
  'east':    8.6397065,
  'south':   50.1256235,
  'west':    8.583558
}, {'zipCode': '60489', 'north': 50.1379828, 'east': 8.6221016, 'south': 50.1140429, 'west': 8.5855905}, {
  'zipCode': '60528',
  'north':   50.0960544,
  'east':    8.6950536,
  'south':   50.039134,
  'west':    8.598454
}, {'zipCode': '60529', 'north': 50.0992626, 'east': 8.618740800000001, 'south': 50.0659909, 'west': 8.5463291}, {
  'zipCode': '60549',
  'north':   50.0669378,
  'east':    8.611366,
  'south':   50.0155435,
  'west':    8.5161638
}, {'zipCode': '60594', 'north': 50.108593400000004, 'east': 8.7276856, 'south': 50.0970876, 'west': 8.6743594}, {
  'zipCode': '60596',
  'north':   50.1058637,
  'east':    8.6837983,
  'south':   50.0889061,
  'west':    8.6543186
}, {'zipCode': '60598', 'north': 50.1018485, 'east': 8.695936, 'south': 50.0589911, 'west': 8.6560494}, {
  'zipCode': '60599',
  'north':   50.1064634,
  'east':    8.7501794,
  'south':   50.0583178,
  'west':    8.6912346
}, {'zipCode': '61118', 'north': 50.2231886, 'east': 8.7928395, 'south': 50.162271000000004, 'west': 8.7019996}, {
  'zipCode': '61130',
  'north':   50.2810816,
  'east':    8.9627713,
  'south':   50.2061472,
  'west':    8.8325575
}, {'zipCode': '61137', 'north': 50.2365, 'east': 8.8872463, 'south': 50.1777212, 'west': 8.8042036}, {
  'zipCode': '61138',
  'north':   50.1996065,
  'east':    8.833314600000001,
  'south':   50.1701461,
  'west':    8.7896621
}, {'zipCode': '61169', 'north': 50.3645207, 'east': 8.8211508, 'south': 50.2897875, 'west': 8.636693}, {
  'zipCode': '61184',
  'north':   50.2679751,
  'east':    8.8274889,
  'south':   50.1944687,
  'west':    8.688465
}, {'zipCode': '61191', 'north': 50.3181858, 'east': 8.7440071, 'south': 50.236651, 'west': 8.59224}, {
  'zipCode': '61194',
  'north':   50.3230577,
  'east':    8.9041529,
  'south':   50.235801,
  'west':    8.7768646
}, {'zipCode': '61197', 'north': 50.3550839, 'east': 8.9817868, 'south': 50.289340800000005, 'west': 8.8175675}, {
  'zipCode': '61200',
  'north':   50.446364,
  'east':    8.9017435,
  'south':   50.3632805,
  'west':    8.7683027
}, {'zipCode': '61203', 'north': 50.3775132, 'east': 8.9384068, 'south': 50.3287252, 'west': 8.7983426}, {
  'zipCode': '61206',
  'north':   50.3103163,
  'east':    8.7878797,
  'south':   50.2552798,
  'west':    8.728307
}, {'zipCode': '61209', 'north': 50.4195877, 'east': 8.9577011, 'south': 50.3561113, 'west': 8.8387538}, {
  'zipCode': '61231',
  'north':   50.4117868,
  'east':    8.7962687,
  'south':   50.3420463,
  'west':    8.7096011
}, {'zipCode': '61239', 'north': 50.4074642, 'east': 8.7197011, 'south': 50.3243179, 'west': 8.6001504}, {
  'zipCode': '61250',
  'north':   50.3981854,
  'east':    8.6120226,
  'south':   50.303074,
  'west':    8.4333804
}, {'zipCode': '61267', 'north': 50.3352239, 'east': 8.5560398, 'south': 50.2456409, 'west': 8.4717384}, {
  'zipCode': '61273',
  'north':   50.3542882,
  'east':    8.6503429,
  'south':   50.2569422,
  'west':    8.5184085
}, {'zipCode': '61276', 'north': 50.3875383, 'east': 8.4652322, 'south': 50.2735661, 'west': 8.315449}, {
  'zipCode': '61279',
  'north':   50.4150672,
  'east':    8.5159064,
  'south':   50.3445843,
  'west':    8.387932
}, {'zipCode': '61348', 'north': 50.2340107, 'east': 8.6377472, 'south': 50.210694, 'west': 8.5745514}, {
  'zipCode': '61350',
  'north':   50.2743799,
  'east':    8.6390373,
  'south':   50.2266637,
  'west':    8.496068300000001
}, {'zipCode': '61352', 'north': 50.2415854, 'east': 8.7124093, 'south': 50.2003246, 'west': 8.6047222}, {
  'zipCode': '61381',
  'north':   50.2902557,
  'east':    8.6899175,
  'south':   50.2312927,
  'west':    8.5687703
}, {'zipCode': '61389', 'north': 50.3082466, 'east': 8.4976224, 'south': 50.2249466, 'west': 8.3951998}, {
  'zipCode': '61440',
  'north':   50.2452253,
  'east':    8.6387555,
  'south':   50.1673005,
  'west':    8.4534459
}, {'zipCode': '61449', 'north': 50.1949961, 'east': 8.5899312, 'south': 50.1555088, 'west': 8.5475614}, {
  'zipCode': '61462',
  'north':   50.2303774,
  'east':    8.5135839,
  'south':   50.1589056,
  'west':    8.4118612
}, {'zipCode': '61476', 'north': 50.21822170000001, 'east': 8.5642133, 'south': 50.1635578, 'west': 8.474676300000002}, {
  'zipCode': '61479',
  'north':   50.2589518,
  'east':    8.452565,
  'south':   50.1769876,
  'west':    8.3522514
}, {'zipCode': '63065', 'north': 50.1130049, 'east': 8.7841736, 'south': 50.0989232, 'west': 8.7552319}, {
  'zipCode': '63067',
  'north':   50.1156488,
  'east':    8.760548,
  'south':   50.098216,
  'west':    8.725610900000001
}, {'zipCode': '63069', 'north': 50.099797200000005, 'east': 8.7899459, 'south': 50.0469845, 'west': 8.7224506}, {
  'zipCode': '63071',
  'north':   50.1028766,
  'east':    8.8031024,
  'south':   50.0672063,
  'west':    8.7650178
}, {'zipCode': '63073', 'north': 50.1053341, 'east': 8.84299, 'south': 50.0673204, 'west': 8.7930526}, {
  'zipCode': '63075',
  'north':   50.1374044,
  'east':    8.818389300000002,
  'south':   50.0970192,
  'west':    8.7689144
}, {'zipCode': '63110', 'north': 50.0725986, 'east': 8.944647000000002, 'south': 49.9595812, 'west': 8.8187867}, {
  'zipCode': '63128',
  'north':   50.03931320000001,
  'east':    8.8266504,
  'south':   49.9824837,
  'west':    8.7476525
}, {'zipCode': '63150', 'north': 50.0675914, 'east': 8.8632191, 'south': 50.0246601, 'west': 8.755517}, {
  'zipCode': '63165',
  'north':   50.133323,
  'east':    8.8868565,
  'south':   50.071421,
  'west':    8.8074319
}, {'zipCode': '63179', 'north': 50.0949425, 'east': 8.9101344, 'south': 50.0507419, 'west': 8.8232502}, {
  'zipCode': '63225',
  'north':   50.0307564,
  'east':    8.7252766,
  'south':   49.9497126,
  'west':    8.602245100000001
}, {'zipCode': '63263', 'north': 50.0641396, 'east': 8.761557, 'south': 50.0147043, 'west': 8.590754}, {
  'zipCode': '63303',
  'north':   50.0539497,
  'east':    8.7669837,
  'south':   49.9533482,
  'west':    8.6352236
}, {'zipCode': '63322', 'north': 50.0089949, 'east': 8.854076900000003, 'south': 49.9463963, 'west': 8.7414556}, {
  'zipCode': '63329',
  'north':   50.013801,
  'east':    8.7039958,
  'south':   49.9481773,
  'west':    8.6033652
}, {'zipCode': '63450', 'north': 50.143785, 'east': 8.9504265, 'south': 50.1128522, 'west': 8.9025445}, {
  'zipCode': '63452',
  'north':   50.1587574,
  'east':    8.9642903,
  'south':   50.1312473,
  'west':    8.8898432
}, {'zipCode': '63454', 'north': 50.1955019, 'east': 8.9109131, 'south': 50.12446990000001, 'west': 8.8605873}, {
  'zipCode': '63456',
  'north':   50.1289848,
  'east':    8.9432635,
  'south':   50.0753198,
  'west':    8.8743714
}, {'zipCode': '63457', 'north': 50.1521989, 'east': 9.0302592, 'south': 50.0885387, 'west': 8.9174599}, {
  'zipCode': '63477',
  'north':   50.1851043,
  'east':    8.882477100000001,
  'south':   50.1249607,
  'west':    8.7800008
}, {'zipCode': '63486', 'north': 50.2199677, 'east': 8.9806087, 'south': 50.1493305, 'west': 8.8841148}, {
  'zipCode': '63500',
  'north':   50.0620859,
  'east':    9.0233349,
  'south':   49.9959012,
  'west':    8.9067087
}, {'zipCode': '63505', 'north': 50.2143203, 'east': 9.091694, 'south': 50.1512742, 'west': 9.0070963}, {
  'zipCode': '63512',
  'north':   50.0905062,
  'east':    8.9913926,
  'south':   50.0450477,
  'west':    8.9053607
}, {'zipCode': '63517', 'north': 50.1603794, 'east': 9.0740257, 'south': 50.1125969, 'west': 9.0017921}, {
  'zipCode': '63526',
  'north':   50.18865280000001,
  'east':    9.0176165,
  'south':   50.1312015,
  'west':    8.9514434
}, {'zipCode': '63533', 'north': 50.0440379, 'east': 9.0513498, 'south': 49.9878859, 'west': 8.969379}, {
  'zipCode': '63538',
  'north':   50.0996719,
  'east':    9.0028946,
  'south':   50.0669335,
  'west':    8.9429559
}, {'zipCode': '63543', 'north': 50.2155023, 'east': 9.0172657, 'south': 50.1816043, 'west': 8.9497777}, {
  'zipCode': '63546',
  'north':   50.2530851,
  'east':    9.0325734,
  'south':   50.2107424,
  'west':    8.9348582
}, {'zipCode': '63549', 'north': 50.2499457, 'east': 9.0774867, 'south': 50.2015733, 'west': 9.0127804}, {
  'zipCode': '63571',
  'north':   50.249007000000006,
  'east':    9.2718253,
  'south':   50.162576,
  'west':    9.1122157
}, {'zipCode': '63579', 'north': 50.1633462, 'east': 9.2117198, 'south': 50.0902558, 'west': 9.0687643}, {
  'zipCode': '63584',
  'north':   50.2881636,
  'east':    9.261874,
  'south':   50.1816635,
  'west':    9.0648681
}, {'zipCode': '63589', 'north': 50.2009755, 'east': 9.271792, 'south': 50.138653, 'west': 9.155703}, {
  'zipCode': '63594',
  'north':   50.1872705,
  'east':    9.1592462,
  'south':   50.1425695,
  'west':    9.0582201
}, {'zipCode': '63599', 'north': 50.2384004, 'east': 9.4072467, 'south': 50.1305714, 'west': 9.2424551}, {
  'zipCode': '63607',
  'north':   50.3338149,
  'east':    9.3511889,
  'south':   50.2261457,
  'west':    9.1928487
}, {'zipCode': '63619', 'north': 50.245454200000005, 'east': 9.4300365, 'south': 50.1740446, 'west': 9.2925136}, {
  'zipCode': '63628',
  'north':   50.3546959,
  'east':    9.5053677,
  'south':   50.180815,
  'west':    9.3321937
}, {'zipCode': '63633', 'north': 50.4634275, 'east': 9.3784426, 'south': 50.3222967, 'west': 9.2515213}, {
  'zipCode': '63636',
  'north':   50.333247,
  'east':    9.3486983,
  'south':   50.2722289,
  'west':    9.2398009
}, {'zipCode': '63637', 'north': 50.214956, 'east': 9.5196678, 'south': 50.1275733, 'west': 9.3693045}, {
  'zipCode': '63639',
  'north':   50.16919920000001,
  'east':    9.531148700000001,
  'south':   50.0835583,
  'west':    9.3758665
}, {'zipCode': '63654', 'north': 50.3437876, 'east': 9.2273722, 'south': 50.2296898, 'west': 8.9996016}, {
  'zipCode': '63667',
  'north':   50.4999226,
  'east':    9.1260933,
  'south':   50.3779409,
  'west':    8.9001055
}, {'zipCode': '63674', 'north': 50.314909, 'east': 9.0081414, 'south': 50.2540689, 'west': 8.8977458}, {
  'zipCode': '63679',
  'north':   50.5549999,
  'east':    9.2709638,
  'south':   50.4245024,
  'west':    9.0402448
}, {'zipCode': '63683', 'north': 50.3972869, 'east': 9.1647588, 'south': 50.315661500000004, 'west': 8.9866959}, {
  'zipCode': '63688',
  'north':   50.4635764,
  'east':    9.2904562,
  'south':   50.3619491,
  'west':    9.1302536
}, {'zipCode': '63691', 'north': 50.3904675, 'east': 9.0596509, 'south': 50.3293441, 'west': 8.9260579}, {
  'zipCode': '63694',
  'north':   50.2852094,
  'east':    9.0234584,
  'south':   50.2434151,
  'west':    8.9569712
}, {'zipCode': '63695', 'north': 50.34145740000001, 'east': 9.0379775, 'south': 50.3019285, 'west': 8.9632411}, {
  'zipCode': '63697',
  'north':   50.4426063,
  'east':    9.1803211,
  'south':   50.3780543,
  'west':    9.0935934
}, {'zipCode': '63699', 'north': 50.3748463, 'east': 9.277896600000002, 'south': 50.3249167, 'west': 9.1098744}, {
  'zipCode': '63739',
  'north':   49.9875423,
  'east':    9.1945547,
  'south':   49.9613571,
  'west':    9.1281871
}, {'zipCode': '63741', 'north': 50.0212787, 'east': 9.171004, 'south': 49.929289, 'west': 9.0802454}, {
  'zipCode': '63743',
  'north':   49.9739023,
  'east':    9.2380031,
  'south':   49.9200613,
  'west':    9.1082666
}, {'zipCode': '63755', 'north': 50.1266155, 'east': 9.155914, 'south': 50.0407745, 'west': 9.0205213}, {
  'zipCode': '63762',
  'north':   49.9562641,
  'east':    9.1112586,
  'south':   49.8655749,
  'west':    9.0190688
}, {'zipCode': '63768', 'north': 50.056071, 'east': 9.2526476, 'south': 49.96646390000001, 'west': 9.1737328}, {
  'zipCode': '63773',
  'north':   50.0309521,
  'east':    9.1970639,
  'south':   49.9818713,
  'west':    9.1502656
}, {'zipCode': '63776', 'north': 50.098338, 'east': 9.224118, 'south': 50.034139, 'west': 9.101599}, {
  'zipCode': '63785',
  'north':   49.8578944,
  'east':    9.1559762,
  'south':   49.7902508,
  'west':    9.0770541
}, {'zipCode': '63791', 'north': 50.0631403, 'east': 9.0960212, 'south': 50.0258283, 'west': 8.9763497}, {
  'zipCode': '63796',
  'north':   50.1142512,
  'east':    9.0365425,
  'south':   50.0591179,
  'west':    8.9879828
}, {'zipCode': '63801', 'north': 50.0375026, 'east': 9.1025654, 'south': 49.9895211, 'west': 9.0296955}, {
  'zipCode': '63808',
  'north':   49.9802534,
  'east':    9.2401742,
  'south':   49.9364924,
  'west':    9.1809408
}, {'zipCode': '63811', 'north': 50.0103745, 'east': 9.0969219, 'south': 49.9529324, 'west': 9.014204300000001}, {
  'zipCode': '63814',
  'north':   50.0265451,
  'east':    9.1124562,
  'south':   49.973116600000004,
  'west':    9.0643716
}, {'zipCode': '63820', 'north': 49.8656927, 'east': 9.2593259, 'south': 49.8023467, 'west': 9.146746}, {
  'zipCode': '63825',
  'north':   50.14957700000001,
  'east':    9.3377337,
  'south':   50.0517761,
  'west':    9.1988486
}, {'zipCode': '63826', 'north': 50.1467368, 'east': 9.2237838, 'south': 50.096191, 'west': 9.1591918}, {
  'zipCode': '63828',
  'north':   50.1417589,
  'east':    9.3213481,
  'south':   50.0875566,
  'west':    9.2484067
}, {'zipCode': '63829', 'north': 50.1036212, 'east': 9.2291047, 'south': 50.0571146, 'west': 9.176632}, {
  'zipCode': '63831',
  'north':   50.1411483,
  'east':    9.4048025,
  'south':   50.0818241,
  'west':    9.3102565
}, {'zipCode': '63834', 'north': 49.9360484, 'east': 9.2497869, 'south': 49.8908353, 'west': 9.1424514}, {
  'zipCode': '63839',
  'north':   49.8969207,
  'east':    9.2320647,
  'south':   49.8413519,
  'west':    9.158318
}, {'zipCode': '63840', 'north': 49.8902037, 'east': 9.2484606, 'south': 49.8536048, 'west': 9.193664}, {
  'zipCode': '63843',
  'north':   49.9384782,
  'east':    9.1532715,
  'south':   49.8742554,
  'west':    9.0936081
}, {'zipCode': '63846', 'north': 50.0456241, 'east': 9.3965087, 'south': 49.9810252, 'west': 9.246402}, {
  'zipCode': '63849',
  'north':   49.9284298,
  'east':    9.2818172,
  'south':   49.867109,
  'west':    9.1875343
}, {'zipCode': '63853', 'north': 49.88213460000001, 'east': 9.1188287, 'south': 49.831793000000005, 'west': 9.0332578}, {
  'zipCode': '63856',
  'north':   50.0070059,
  'east':    9.3059198,
  'south':   49.9251862,
  'west':    9.2184476
}, {'zipCode': '63857', 'north': 49.9876613, 'east': 9.3695945, 'south': 49.9186435, 'west': 9.2713459}, {
  'zipCode': '63860',
  'north':   50.0113077,
  'east':    9.4523188,
  'south':   49.9271458,
  'west':    9.3455958
}, {'zipCode': '63863', 'north': 49.8669789, 'east': 9.3622521, 'south': 49.7998923, 'west': 9.2288108}, {
  'zipCode': '63864',
  'north':   50.0201747,
  'east':    9.1599843,
  'south':   49.9987943,
  'west':    9.1267614
}, {'zipCode': '63867', 'north': 50.0461097, 'east': 9.1793302, 'south': 50.0059263, 'west': 9.091050100000002}, {
  'zipCode': '63868',
  'north':   49.895164,
  'east':    9.1640096,
  'south':   49.853455800000006,
  'west':    9.1040969
}, {'zipCode': '63869', 'north': 50.0581542, 'east': 9.399995800000001, 'south': 50.0118593, 'west': 9.3029295}, {
  'zipCode': '63871',
  'north':   50.0866321,
  'east':    9.3612105,
  'south':   50.0414004,
  'west':    9.3122805
}, {'zipCode': '63872', 'north': 49.9093095, 'east': 9.3409124, 'south': 49.8525612, 'west': 9.2452477}, {
  'zipCode': '63874',
  'north':   49.9043086,
  'east':    9.3989242,
  'south':   49.83226,
  'west':    9.2870425
}, {'zipCode': '63875', 'north': 49.9441721, 'east': 9.3394672, 'south': 49.8986648, 'west': 9.2661577}, {
  'zipCode': '63877',
  'north':   50.0664279,
  'east':    9.3116978,
  'south':   49.9974209,
  'west':    9.2299624
}, {'zipCode': '63879', 'north': 49.94835750000001, 'east': 9.48023, 'south': 49.8714698, 'west': 9.3373777}, {
  'zipCode': '63897',
  'north':   49.7268495,
  'east':    9.3726707,
  'south':   49.653924,
  'west':    9.121182600000001
}, {'zipCode': '63906', 'north': 49.8327668, 'east': 9.2262802, 'south': 49.78521760000001, 'west': 9.1356057}, {
  'zipCode': '63911',
  'north':   49.7972211,
  'east':    9.2271369,
  'south':   49.7394384,
  'west':    9.1277219
}, {'zipCode': '63916', 'north': 49.6969866, 'east': 9.3064875, 'south': 49.5787534, 'west': 9.0879642}, {
  'zipCode': '63920',
  'north':   49.760596,
  'east':    9.2740919,
  'south':   49.7086046,
  'west':    9.1796576
}, {'zipCode': '63924', 'north': 49.7354214, 'east': 9.2289151, 'south': 49.7012676, 'west': 9.1390895}, {
  'zipCode': '63925',
  'north':   49.7555229,
  'east':    9.1885881,
  'south':   49.7283658,
  'west':    9.1420901
}, {'zipCode': '63927', 'north': 49.7416624, 'east': 9.3563818, 'south': 49.7036108, 'west': 9.2586206}, {
  'zipCode': '63928',
  'north':   49.73195210000001,
  'east':    9.4195,
  'south':   49.6427756,
  'west':    9.3012647
}, {'zipCode': '63930', 'north': 49.7318935, 'east': 9.4223348, 'south': 49.6762907, 'west': 9.3557174}, {
  'zipCode': '63931',
  'north':   49.6606737,
  'east':    9.2246903,
  'south':   49.5734945,
  'west':    9.0648758
}, {'zipCode': '63933', 'north': 49.8132691, 'east': 9.3242488, 'south': 49.7671393, 'west': 9.2048542}, {
  'zipCode': '63934',
  'north':   49.7860546,
  'east':    9.2964929,
  'south':   49.7440171,
  'west':    9.212758700000002
}, {'zipCode': '63936', 'north': 49.661771, 'east': 9.2992807, 'south': 49.5798623, 'west': 9.2308181}, {
  'zipCode': '63937',
  'north':   49.7074599,
  'east':    9.275298,
  'south':   49.650164,
  'west':    9.093206200000001
}, {'zipCode': '63939', 'north': 49.8109123, 'east': 9.1763869, 'south': 49.7552395, 'west': 9.1069689}, {
  'zipCode': '64283',
  'north':   49.8763837,
  'east':    8.6639654,
  'south':   49.8638818,
  'west':    8.6396164
}, {'zipCode': '64285', 'north': 49.8673901, 'east': 8.6837758, 'south': 49.8360565, 'west': 8.6380504}, {
  'zipCode': '64287',
  'north':   49.9300969,
  'east':    8.7497079,
  'south':   49.8413374,
  'west':    8.6611364
}, {'zipCode': '64289', 'north': 49.9224869, 'east': 8.7088883, 'south': 49.8744362, 'west': 8.6503902}, {
  'zipCode': '64291',
  'north':   49.95381390000001,
  'east':    8.7390084,
  'south':   49.8928002,
  'west':    8.6188957
}, {'zipCode': '64293', 'north': 49.9006207, 'east': 8.657472700000001, 'south': 49.8654716, 'west': 8.5990548}, {
  'zipCode': '64295',
  'north':   49.872311,
  'east':    8.6463156,
  'south':   49.8295234,
  'west':    8.5598937
}, {'zipCode': '64297', 'north': 49.8447957, 'east': 8.6796287, 'south': 49.7956794, 'west': 8.6047365}, {
  'zipCode': '64319',
  'north':   49.8379735,
  'east':    8.6488037,
  'south':   49.7673023,
  'west':    8.5355736
}, {'zipCode': '64331', 'north': 49.9557403, 'east': 8.6349484, 'south': 49.8805637, 'west': 8.5499206}, {
  'zipCode': '64342',
  'north':   49.798934100000004,
  'east':    8.7047918,
  'south':   49.7245854,
  'west':    8.6177748
}, {'zipCode': '64347', 'north': 49.8833744, 'east': 8.602602200000002, 'south': 49.8255257, 'west': 8.5197503}, {
  'zipCode': '64354',
  'north':   49.8561078,
  'east':    8.869994,
  'south':   49.7962377,
  'west':    8.7834342
}, {'zipCode': '64367', 'north': 49.8468773, 'east': 8.7262724, 'south': 49.77317380000001, 'west': 8.6576698}, {
  'zipCode': '64372',
  'north':   49.8628071,
  'east':    8.7997482,
  'south':   49.7756814,
  'west':    8.6920284
}, {'zipCode': '64380', 'north': 49.8923412, 'east': 8.8082226, 'south': 49.8469323, 'west': 8.7238374}, {
  'zipCode': '64385',
  'north':   49.7448578,
  'east':    8.9290569,
  'south':   49.6558408,
  'west':    8.7845173
}, {'zipCode': '64390', 'north': 49.9650643, 'east': 8.6722918, 'south': 49.9418153, 'west': 8.5943769}, {
  'zipCode': '64395',
  'north':   49.798065900000005,
  'east':    8.9276535,
  'south':   49.737999,
  'west':    8.8405127
}, {'zipCode': '64397', 'north': 49.7874968, 'east': 8.7905611, 'south': 49.7222509, 'west': 8.6997014}, {
  'zipCode': '64401',
  'north':   49.8099913,
  'east':    8.864583500000002,
  'south':   49.7616097,
  'west':    8.7742641
}, {'zipCode': '64404', 'north': 49.7762152, 'east': 8.6258079, 'south': 49.7440769, 'west': 8.5602571}, {
  'zipCode': '64405',
  'north':   49.7811967,
  'east':    8.8384824,
  'south':   49.7359284,
  'west':    8.7754895
}, {'zipCode': '64407', 'north': 49.7684961, 'east': 8.8768817, 'south': 49.7230622, 'west': 8.7975144}, {
  'zipCode': '64409',
  'north':   49.9596393,
  'east':    8.7921152,
  'south':   49.8951819,
  'west':    8.7265268
}, {'zipCode': '64521', 'north': 49.9658298, 'east': 8.5508861, 'south': 49.8631368, 'west': 8.4045983}, {
  'zipCode': '64546',
  'north':   50.0267114,
  'east':    8.6299947,
  'south':   49.9436488,
  'west':    8.5038979
}, {'zipCode': '64560', 'north': 49.8824697, 'east': 8.5496953, 'south': 49.7848113, 'west': 8.3767089}, {
  'zipCode': '64569',
  'north':   49.9769334,
  'east':    8.5277166,
  'south':   49.9289839,
  'west':    8.4220237
}, {'zipCode': '64572', 'north': 49.9504011, 'east': 8.5775738, 'south': 49.8724279, 'west': 8.4959002}, {
  'zipCode': '64579',
  'north':   49.7902323,
  'east':    8.5618369,
  'south':   49.7171159,
  'west':    8.4482214
}, {'zipCode': '64584', 'north': 49.8031315, 'east': 8.5042965, 'south': 49.7654271, 'west': 8.413033}, {
  'zipCode': '64589',
  'north':   49.8377024,
  'east':    8.5058193,
  'south':   49.784416,
  'west':    8.3845946
}, {'zipCode': '64625', 'north': 49.730273, 'east': 8.7130697, 'south': 49.6537978, 'west': 8.5438088}, {
  'zipCode': '64646',
  'north':   49.681156,
  'east':    8.7317793,
  'south':   49.6030986,
  'west':    8.5887933
}, {'zipCode': '64653', 'north': 49.6844445, 'east': 8.5975683, 'south': 49.6015824, 'west': 8.5139729}, {
  'zipCode': '64658',
  'north':   49.6883759,
  'east':    8.8871139,
  'south':   49.6226678,
  'west':    8.7211168
}, {'zipCode': '64665', 'north': 49.7673364, 'east': 8.6461688, 'south': 49.7247436, 'west': 8.554274}, {
  'zipCode': '64668',
  'north':   49.6689888,
  'east':    8.8104032,
  'south':   49.5849617,
  'west':    8.7075144
}, {'zipCode': '64673', 'north': 49.7343121, 'east': 8.6269231, 'south': 49.7112742, 'west': 8.5604949}, {
  'zipCode': '64678',
  'north':   49.7383678,
  'east':    8.807284,
  'south':   49.6718794,
  'west':    8.7232006
}, {'zipCode': '64683', 'north': 49.7176919, 'east': 8.5686489, 'south': 49.6415686, 'west': 8.4786361}, {
  'zipCode': '64686',
  'north':   49.7547362,
  'east':    8.7747362,
  'south':   49.6803223,
  'west':    8.6563798
}, {'zipCode': '64689', 'north': 49.6555304, 'east': 8.8846239, 'south': 49.6018277, 'west': 8.8064881}, {
  'zipCode': '64711',
  'north':   49.6754175,
  'east':    9.0746713,
  'south':   49.5859016,
  'west':    8.9368093
}, {'zipCode': '64720', 'north': 49.7525975, 'east': 9.1505794, 'south': 49.6191329, 'west': 8.9191324}, {
  'zipCode': '64732',
  'north':   49.781098,
  'east':    9.0847648,
  'south':   49.7027969,
  'west':    8.9160857
}, {'zipCode': '64739', 'north': 49.8204407, 'east': 9.0260467, 'south': 49.7589386, 'west': 8.9175695}, {
  'zipCode': '64747',
  'north':   49.8573406,
  'east':    9.0899808,
  'south':   49.7908431,
  'west':    8.9916616
}, {'zipCode': '64750', 'north': 49.8003943, 'east': 9.1343678, 'south': 49.7392183, 'west': 9.0049454}, {
  'zipCode': '64753',
  'north':   49.7554937,
  'east':    8.992666,
  'south':   49.7061403,
  'west':    8.9025873
}, {'zipCode': '64754', 'north': 49.5751281, 'east': 9.1015859, 'south': 49.5443267, 'west': 9.0708282}, {
  'zipCode': '64756',
  'north':   49.7056616,
  'east':    8.966911,
  'south':   49.5995327,
  'west':    8.864259200000001
}, {'zipCode': '64757', 'north': 49.4857607, 'east': 8.9156274, 'south': 49.4802342, 'west': 8.9000937}, {
  'zipCode': '64760',
  'north':   49.6107435,
  'east':    9.1266013,
  'south':   49.47055520000001,
  'west':    8.8729042
}, {'zipCode': '64807', 'north': 49.9257792, 'east': 8.8717134, 'south': 49.8802371, 'west': 8.7632631}, {
  'zipCode': '64823',
  'north':   49.9194678,
  'east':    9.0437906,
  'south':   49.8117086,
  'west':    8.8493909
}, {'zipCode': '64832', 'north': 50.0018912, 'east': 9.026714, 'south': 49.9106294, 'west': 8.8652247}, {
  'zipCode': '64839',
  'north':   49.9438725,
  'east':    8.9158284,
  'south':   49.9055398,
  'west':    8.7705856
}, {'zipCode': '64846', 'north': 49.9025421, 'east': 8.8653876, 'south': 49.8473058, 'west': 8.7400207}, {
  'zipCode': '64850',
  'north':   49.9501839,
  'east':    9.0504967,
  'south':   49.8621463,
  'west':    8.9571943
}, {'zipCode': '64853', 'north': 49.8697489, 'east': 8.9695829, 'south': 49.7868142, 'west': 8.8522172}, {
  'zipCode': '64859',
  'north':   49.9637252,
  'east':    8.8866278,
  'south':   49.9361624,
  'west':    8.7563653
}, {'zipCode': '65183', 'north': 50.091334, 'east': 8.2448973, 'south': 50.078368, 'west': 8.2286347}, {
  'zipCode': '65185',
  'north':   50.0809257,
  'east':    8.249216,
  'south':   50.0699535,
  'west':    8.2291592
}, {'zipCode': '65187', 'north': 50.0756777, 'east': 8.26349, 'south': 50.0426569, 'west': 8.209456100000002}, {
  'zipCode': '65189',
  'north':   50.0892105,
  'east':    8.2766675,
  'south':   50.0577313,
  'west':    8.2406375
}, {'zipCode': '65191', 'north': 50.1107711, 'east': 8.2987729, 'south': 50.0785675, 'west': 8.2570024}, {
  'zipCode': '65193',
  'north':   50.1397577,
  'east':    8.270834,
  'south':   50.0837999,
  'west':    8.206069
}, {'zipCode': '65195', 'north': 50.135058, 'east': 8.2357454, 'south': 50.0781382, 'west': 8.165246}, {
  'zipCode': '65197',
  'north':   50.0979724,
  'east':    8.2309629,
  'south':   50.066056,
  'west':    8.1701771
}, {'zipCode': '65199', 'north': 50.1241853, 'east': 8.2220103, 'south': 50.0568203, 'west': 8.112398900000002}, {
  'zipCode': '65201',
  'north':   50.0875328,
  'east':    8.2138032,
  'south':   50.0329559,
  'west':    8.1106032
}, {'zipCode': '65203', 'north': 50.05261380000001, 'east': 8.2839535, 'south': 50.0219361, 'west': 8.2102173}, {
  'zipCode': '65205',
  'north':   50.0802498,
  'east':    8.3813395,
  'south':   50.0251427,
  'west':    8.2560031
}, {'zipCode': '65207', 'north': 50.15202250000001, 'east': 8.3861874, 'south': 50.0663736, 'west': 8.232196}, {
  'zipCode': '65232',
  'north':   50.22138590000001,
  'east':    8.2431337,
  'south':   50.0936766,
  'west':    8.0980898
}, {'zipCode': '65239', 'north': 50.0582241, 'east': 8.4128185, 'south': 49.9969793, 'west': 8.3303845}, {
  'zipCode': '65307',
  'north':   50.178061500000005,
  'east':    8.1022409,
  'south':   50.0950049,
  'west':    7.9569931
}, {'zipCode': '65321', 'north': 50.2384751, 'east': 8.0430749, 'south': 50.1037211, 'west': 7.8828107}, {
  'zipCode': '65326',
  'north':   50.2814366,
  'east':    8.1618678,
  'south':   50.2058618,
  'west':    8.0217552
}, {'zipCode': '65329', 'north': 50.24540720000001, 'east': 8.1696872, 'south': 50.137601, 'west': 8.0167423}, {
  'zipCode': '65343',
  'north':   50.0938484,
  'east':    8.1589938,
  'south':   50.0180041,
  'west':    8.0577716
}, {'zipCode': '65344', 'north': 50.07445080000001, 'east': 8.1425345, 'south': 50.0425507, 'west': 8.1070346}, {
  'zipCode': '65345',
  'north':   50.0947155,
  'east':    8.1174518,
  'south':   50.0453951,
  'west':    8.0668296
}, {'zipCode': '65346', 'north': 50.0810926, 'east': 8.1091057, 'south': 50.0064042, 'west': 8.0255084}, {
  'zipCode': '65347',
  'north':   50.0777325,
  'east':    8.0768207,
  'south':   50.005046400000005,
  'west':    8.0027752
}, {'zipCode': '65366', 'north': 50.0974469, 'east': 7.992171800000001, 'south': 49.9750086, 'west': 7.882817599999999}, {
  'zipCode': '65375',
  'north':   50.113067,
  'east':    8.0545397,
  'south':   49.9834719,
  'west':    7.911588500000001
}, {'zipCode': '65385', 'north': 50.08595290000001, 'east': 7.9478336, 'south': 49.9718008, 'west': 7.8385535}, {
  'zipCode': '65388',
  'north':   50.1324939,
  'east':    8.1378217,
  'south':   50.0652138,
  'west':    7.955906800000001
}, {'zipCode': '65391', 'north': 50.1285052, 'east': 7.9254562, 'south': 50.00629880000001, 'west': 7.7724063}, {
  'zipCode': '65396',
  'north':   50.066374700000004,
  'east':    8.175228,
  'south':   50.0297972,
  'west':    8.1282418
}, {'zipCode': '65399', 'north': 50.087212300000004, 'east': 8.1091941, 'south': 50.0276893, 'west': 8.0442582}, {
  'zipCode': '65428',
  'north':   50.0252731,
  'east':    8.5493031,
  'south':   49.9387245,
  'west':    8.3563769
}, {'zipCode': '65439', 'north': 50.0584611, 'east': 8.4671619, 'south': 49.996493300000004, 'west': 8.3744698}, {
  'zipCode': '65451',
  'north':   50.0838781,
  'east':    8.5536742,
  'south':   50.025149000000006,
  'west':    8.470557
}, {'zipCode': '65462', 'north': 50.0021682, 'east': 8.3795233, 'south': 49.9334836, 'west': 8.2909719}, {
  'zipCode': '65468',
  'north':   49.9580166,
  'east':    8.4468636,
  'south':   49.8596375,
  'west':    8.341017
}, {'zipCode': '65474', 'north': 50.001824, 'east': 8.4102954, 'south': 49.971088, 'west': 8.3344557}, {
  'zipCode': '65479',
  'north':   50.0369711,
  'east':    8.5200557,
  'south':   49.9903713,
  'west':    8.4241131
}, {'zipCode': '65510', 'north': 50.2919956, 'east': 8.4056802, 'south': 50.1723287, 'west': 8.135105}, {
  'zipCode': '65520',
  'north':   50.339418,
  'east':    8.3643251,
  'south':   50.263582,
  'west':    8.220453
}, {'zipCode': '65527', 'north': 50.20133, 'east': 8.3702647, 'south': 50.1450833, 'west': 8.227364}, {
  'zipCode': '65529',
  'north':   50.2962214,
  'east':    8.411499200000002,
  'south':   50.2209289,
  'west':    8.2944945
}, {'zipCode': '65549', 'north': 50.4084982, 'east': 8.0963865, 'south': 50.365684, 'west': 8.0376847}, {
  'zipCode': '65550',
  'north':   50.3755169,
  'east':    8.1240717,
  'south':   50.3536523,
  'west':    8.0642725
}, {'zipCode': '65551', 'north': 50.3904545, 'east': 8.1570102, 'south': 50.3646669, 'west': 8.0963865}, {
  'zipCode': '65552',
  'north':   50.4114573,
  'east':    8.1165443,
  'south':   50.3755169,
  'west':    8.0853283
}, {'zipCode': '65553', 'north': 50.41579, 'east': 8.0978734, 'south': 50.3901927, 'west': 8.0723463}, {
  'zipCode': '65554',
  'north':   50.4456762,
  'east':    8.1112732,
  'south':   50.423747,
  'west':    8.0527558
}, {'zipCode': '65555', 'north': 50.4302339, 'east': 8.0866828, 'south': 50.4020279, 'west': 8.0491387}, {
  'zipCode': '65556',
  'north':   50.4081978,
  'east':    8.051832,
  'south':   50.3813351,
  'west':    7.983343100000001
}, {'zipCode': '65558', 'north': 50.4155522, 'east': 8.1229853, 'south': 50.2597569, 'west': 7.8518473}, {
  'zipCode': '65582',
  'north':   50.408557,
  'east':    8.0637318,
  'south':   50.3350041,
  'west':    7.959239600000001
}, {'zipCode': '65589', 'north': 50.4934417, 'east': 8.1519394, 'south': 50.4240926, 'west': 7.994376100000001}, {
  'zipCode': '65594',
  'north':   50.4622302,
  'east':    8.2395751,
  'south':   50.3855994,
  'west':    8.0853122
}, {'zipCode': '65597', 'north': 50.3614597, 'east': 8.2374468, 'south': 50.2750584, 'west': 8.0709886}, {
  'zipCode': '65599',
  'north':   50.5570364,
  'east':    8.0703951,
  'south':   50.4697411,
  'west':    7.9785853
}, {'zipCode': '65604', 'north': 50.4452619, 'east': 8.0540122, 'south': 50.40118090000001, 'west': 7.9638058}, {
  'zipCode': '65606',
  'north':   50.440598,
  'east':    8.2986503,
  'south':   50.3577586,
  'west':    8.1570102
}, {'zipCode': '65611', 'north': 50.3889582, 'east': 8.260629, 'south': 50.3372209, 'west': 8.1151101}, {
  'zipCode': '65614',
  'north':   50.4995335,
  'east':    8.216896,
  'south':   50.4282101,
  'west':    8.1023434
}, {'zipCode': '65618', 'north': 50.3863744, 'east': 8.3608891, 'south': 50.322333900000004, 'west': 8.1976668}, {
  'zipCode': '65620',
  'north':   50.541162500000006,
  'east':    8.1663957,
  'south':   50.4822791,
  'west':    8.0687596
}, {'zipCode': '65623', 'north': 50.330566, 'east': 8.1071734, 'south': 50.2643543, 'west': 8.01461}, {
  'zipCode': '65624',
  'north':   50.3892363,
  'east':    7.997507899999999,
  'south':   50.3534047,
  'west':    7.9336221
}, {'zipCode': '65626', 'north': 50.3694583, 'east': 8.013887, 'south': 50.3365757, 'west': 7.985544999999999}, {
  'zipCode': '65627',
  'north':   50.5294814,
  'east':    8.0809638,
  'south':   50.4806167,
  'west':    8.0381083
}, {'zipCode': '65629', 'north': 50.3555744, 'east': 8.0774736, 'south': 50.3161053, 'west': 8.0080917}, {
  'zipCode': '65719',
  'north':   50.1424808,
  'east':    8.4742181,
  'south':   50.0456677,
  'west':    8.3436712
}, {'zipCode': '65760', 'north': 50.1747956, 'east': 8.6027384, 'south': 50.1275483, 'west': 8.531685}, {
  'zipCode': '65779',
  'north':   50.1879338,
  'east':    8.4865824,
  'south':   50.1011295,
  'west':    8.3761191
}, {'zipCode': '65795', 'north': 50.0857311, 'east': 8.5209762, 'south': 50.0188514, 'west': 8.4468681}, {
  'zipCode': '65812',
  'north':   50.1735778,
  'east':    8.5182951,
  'south':   50.1291954,
  'west':    8.4530014
}, {'zipCode': '65817', 'north': 50.2026869, 'east': 8.4143197, 'south': 50.1285255, 'west': 8.3179168}, {
  'zipCode': '65824',
  'north':   50.1684602,
  'east':    8.5635385,
  'south':   50.1260914,
  'west':    8.5094716
}, {'zipCode': '65830', 'north': 50.09918, 'east': 8.4878996, 'south': 50.0581759, 'west': 8.4449996}, {
  'zipCode': '65835',
  'north':   50.1362137,
  'east':    8.5189248,
  'south':   50.1060441,
  'west':    8.4724102
}, {'zipCode': '65843', 'north': 50.151123, 'east': 8.5604498, 'south': 50.1153975, 'west': 8.5025798}, {
  'zipCode': '65929',
  'north':   50.1219154,
  'east':    8.5666035,
  'south':   50.06893,
  'west':    8.5070885
}, {'zipCode': '65931', 'north': 50.1154556, 'east': 8.5272739, 'south': 50.0614965, 'west': 8.4727228}, {
  'zipCode': '65933',
  'north':   50.1154915,
  'east':    8.6218777,
  'south':   50.0882749,
  'west':    8.5741581
}, {'zipCode': '65934', 'north': 50.1141628, 'east': 8.5948117, 'south': 50.092145, 'west': 8.5554296}, {
  'zipCode': '65936',
  'north':   50.135695,
  'east':    8.598368,
  'south':   50.1086158,
  'west':    8.5485654
}, {'zipCode': '66111', 'north': 49.2428478, 'east': 7.0151555, 'south': 49.2290863, 'west': 6.9825888}, {
  'zipCode': '66113',
  'north':   49.2665697,
  'east':    7.0024733,
  'south':   49.2411693,
  'west':    6.9438839
}, {'zipCode': '66115', 'north': 49.3220676, 'east': 7.0166324, 'south': 49.23385270000001, 'west': 6.9180128}, {
  'zipCode': '66117',
  'north':   49.2371036,
  'east':    6.9923651,
  'south':   49.2031135,
  'west':    6.9382298
}, {'zipCode': '66119', 'north': 49.2334678, 'east': 7.026166900000001, 'south': 49.18794960000001, 'west': 6.971610600000001}, {
  'zipCode': '66121',
  'north':   49.2436858,
  'east':    7.0791704,
  'south':   49.2176088,
  'west':    7.0017774
}, {'zipCode': '66123', 'north': 49.2660397, 'east': 7.0540247, 'south': 49.2288617, 'west': 6.9928359}, {
  'zipCode': '66125',
  'north':   49.2961108,
  'east':    7.0642275,
  'south':   49.2551256,
  'west':    6.9940809
}, {'zipCode': '66126', 'north': 49.28304, 'east': 6.9326828, 'south': 49.2438254, 'west': 6.9038448}, {
  'zipCode': '66127',
  'north':   49.2450631,
  'east':    6.9115214,
  'south':   49.2127518,
  'west':    6.8276387
}, {'zipCode': '66128', 'north': 49.2470074, 'east': 6.946428300000001, 'south': 49.2088272, 'west': 6.8777882}, {
  'zipCode': '66129',
  'north':   49.1958334,
  'east':    7.0899334,
  'south':   49.1721922,
  'west':    7.0295099
}, {'zipCode': '66130', 'north': 49.2212325, 'east': 7.1133686, 'south': 49.176195, 'west': 7.0183827}, {
  'zipCode': '66131',
  'north':   49.2563988,
  'east':    7.1380203,
  'south':   49.1914994,
  'west':    7.0768237
}, {'zipCode': '66132', 'north': 49.2458262, 'east': 7.101092, 'south': 49.20660410000001, 'west': 7.0469835}, {
  'zipCode': '66133',
  'north':   49.2630219,
  'east':    7.089059,
  'south':   49.2280436,
  'west':    7.0437258
}, {'zipCode': '66265', 'north': 49.3776043, 'east': 7.0208045, 'south': 49.3181522, 'west': 6.8631982}, {
  'zipCode': '66271',
  'north':   49.1814833,
  'east':    7.1293801,
  'south':   49.1119687,
  'west':    7.0277273
}, {'zipCode': '66280', 'north': 49.320959200000004, 'east': 7.1171558, 'south': 49.2717948, 'west': 7.0324296}, {
  'zipCode': '66287',
  'north':   49.3510543,
  'east':    7.0620617,
  'south':   49.2832669,
  'west':    6.9918604
}, {'zipCode': '66292', 'north': 49.3313979, 'east': 6.983356, 'south': 49.2825695, 'west': 6.9011622}, {
  'zipCode': '66299',
  'north':   49.3467799,
  'east':    7.1147543,
  'south':   49.3125591,
  'west':    7.0543434
}, {'zipCode': '66333', 'north': 49.2742608, 'east': 6.9111163, 'south': 49.1636722, 'west': 6.7112786}, {
  'zipCode': '66346',
  'north':   49.3266872,
  'east':    6.9292827,
  'south':   49.2622009,
  'west':    6.8552244
}, {'zipCode': '66352', 'north': 49.2157401, 'east': 6.8619435, 'south': 49.1512816, 'west': 6.7758815}, {
  'zipCode': '66359',
  'north':   49.2870283,
  'east':    6.8585228,
  'south':   49.26981250000001,
  'west':    6.774695
}, {'zipCode': '66386', 'north': 49.3098952, 'east': 7.193569900000001, 'south': 49.2367683, 'west': 7.0620746}, {
  'zipCode': '66399',
  'north':   49.250384000000004,
  'east':    7.2031147,
  'south':   49.1204144,
  'west':    7.1008725
}, {'zipCode': '66424', 'north': 49.3863745, 'east': 7.404830700000001, 'south': 49.2474202, 'west': 7.2737168}, {
  'zipCode': '66440',
  'north':   49.2990758,
  'east':    7.368961800000001,
  'south':   49.1295768,
  'west':    7.178156500000001
}, {'zipCode': '66450', 'north': 49.4078807, 'east': 7.305666, 'south': 49.3218836, 'west': 7.2195411}, {
  'zipCode': '66453',
  'north':   49.1946543,
  'east':    7.3102707,
  'south':   49.1149892,
  'west':    7.1589674
}, {'zipCode': '66459', 'north': 49.3404302, 'east': 7.3164228, 'south': 49.259758, 'west': 7.184605299999999}, {
  'zipCode': '66482',
  'north':   49.3169125,
  'east':    7.448650700000001,
  'south':   49.1899493,
  'west':    7.288762099999999
}, {'zipCode': '66484', 'north': 49.3208686, 'east': 7.5330175, 'south': 49.1627839, 'west': 7.343507899999999}, {
  'zipCode': '66497',
  'north':   49.2753723,
  'east':    7.4847371,
  'south':   49.2049817,
  'west':    7.397170199999999
}, {'zipCode': '66500', 'north': 49.2054272, 'east': 7.4118759, 'south': 49.158362000000004, 'west': 7.3198038}, {
  'zipCode': '66501',
  'north':   49.3251924,
  'east':    7.4668329,
  'south':   49.279910300000004,
  'west':    7.405845799999999
}, {'zipCode': '66503', 'north': 49.261608, 'east': 7.5080221, 'south': 49.2196544, 'west': 7.458195300000001}, {
  'zipCode': '66504',
  'north':   49.2014208,
  'east':    7.530942300000001,
  'south':   49.1744099,
  'west':    7.481599700000001
}, {'zipCode': '66506', 'north': 49.2924742, 'east': 7.5560776, 'south': 49.2465098, 'west': 7.490446899999999}, {
  'zipCode': '66507',
  'north':   49.2998597,
  'east':    7.52434,
  'south':   49.2684681,
  'west':    7.470234100000001
}, {'zipCode': '66509', 'north': 49.2703316, 'east': 7.5423176, 'south': 49.2314949, 'west': 7.4797773}, {
  'zipCode': '66538',
  'north':   49.3572814,
  'east':    7.217137800000001,
  'south':   49.3200188,
  'west':    7.1476703
}, {'zipCode': '66539', 'north': 49.363879, 'east': 7.268947299999999, 'south': 49.2855938, 'west': 7.179731799999999}, {
  'zipCode': '66540',
  'north':   49.4074009,
  'east':    7.263005199999999,
  'south':   49.3208234,
  'west':    7.1058824
}, {'zipCode': '66557', 'north': 49.416484, 'east': 7.1241885, 'south': 49.3494766, 'west': 6.9939748}, {
  'zipCode': '66564',
  'north':   49.4410854,
  'east':    7.2928486,
  'south':   49.3843532,
  'west':    7.0907642
}, {'zipCode': '66571', 'north': 49.4379674, 'east': 7.0549983, 'south': 49.3556191, 'west': 6.9174973}, {
  'zipCode': '66578',
  'north':   49.3966353,
  'east':    7.1505436,
  'south':   49.336928,
  'west':    7.0792437
}, {'zipCode': '66583', 'north': 49.3257611, 'east': 7.188621800000001, 'south': 49.2958181, 'west': 7.1060620000000005}, {
  'zipCode': '66589',
  'north':   49.3806934,
  'east':    7.1028354,
  'south':   49.32836340000001,
  'west':    7.023342
}, {'zipCode': '66606', 'north': 49.5114119, 'east': 7.305642499999999, 'south': 49.4160871, 'west': 7.0677384}, {
  'zipCode': '66620',
  'north':   49.6359292,
  'east':    7.0415103,
  'south':   49.509332,
  'west':    6.8914796
}, {'zipCode': '66625', 'north': 49.639427, 'east': 7.2139811, 'south': 49.5220513, 'west': 6.990853}, {
  'zipCode': '66629',
  'north':   49.584866100000006,
  'east':    7.309201000000001,
  'south':   49.4992609,
  'west':    7.1697219
}, {'zipCode': '66636', 'north': 49.5265508, 'east': 7.0626402, 'south': 49.4366717, 'west': 6.9100551}, {
  'zipCode': '66640',
  'north':   49.5460109,
  'east':    7.206878799999999,
  'south':   49.4855078,
  'west':    7.1220062
}, {'zipCode': '66646', 'north': 49.5036001, 'east': 7.111932000000001, 'south': 49.4092586, 'west': 6.9949751}, {
  'zipCode': '66649',
  'north':   49.5518944,
  'east':    7.1420097,
  'south':   49.494102700000006,
  'west':    7.0481254
}, {'zipCode': '66663', 'north': 49.5048155, 'east': 6.7252024, 'south': 49.3974379, 'west': 6.5042406}, {
  'zipCode': '66679',
  'north':   49.5616338,
  'east':    6.8152123,
  'south':   49.4541845,
  'west':    6.6334059000000005
}, {'zipCode': '66687', 'north': 49.6130328, 'east': 6.96296, 'south': 49.4689258, 'west': 6.7998825}, {
  'zipCode': '66693',
  'north':   49.5443154,
  'east':    6.6576014,
  'south':   49.4461018,
  'west':    6.4472808
}, {'zipCode': '66701', 'north': 49.467226, 'east': 6.8021756, 'south': 49.3721302, 'west': 6.6630237}, {
  'zipCode': '66706',
  'north':   49.5513658,
  'east':    6.5006209,
  'south':   49.4528548,
  'west':    6.3575993
}, {'zipCode': '66709', 'north': 49.5920532, 'east': 6.8513465, 'south': 49.5082279, 'west': 6.7572958}, {
  'zipCode': '66740',
  'north':   49.36206,
  'east':    6.815681,
  'south':   49.26028,
  'west':    6.6751543
}, {'zipCode': '66763', 'north': 49.3959571, 'east': 6.7784754, 'south': 49.3376833, 'west': 6.6895027}, {
  'zipCode': '66773',
  'north':   49.334013,
  'east':    6.8828437,
  'south':   49.2820268,
  'west':    6.7826209
}, {'zipCode': '66780', 'north': 49.4195594, 'east': 6.7042422, 'south': 49.3300417, 'west': 6.5374625}, {
  'zipCode': '66787',
  'north':   49.27410270000001,
  'east':    6.8382887,
  'south':   49.2189017,
  'west':    6.7196376
}, {'zipCode': '66793', 'north': 49.3844094, 'east': 6.9221459, 'south': 49.3168013, 'west': 6.7638243}, {
  'zipCode': '66798',
  'north':   49.3496312,
  'east':    6.7326365,
  'south':   49.2798022,
  'west':    6.5890954
}, {'zipCode': '66802', 'north': 49.304515, 'east': 6.7624638, 'south': 49.2152107, 'west': 6.6600887}, {
  'zipCode': '66806',
  'north':   49.3232872,
  'east':    6.8042358,
  'south':   49.277692,
  'west':    6.7628344
}, {'zipCode': '66809', 'north': 49.4157826, 'east': 6.8550287, 'south': 49.366854, 'west': 6.7463986}, {
  'zipCode': '66822',
  'north':   49.4877919,
  'east':    6.9896218,
  'south':   49.3666225,
  'west':    6.8459397
}, {'zipCode': '66839', 'north': 49.5017193, 'east': 6.9367679, 'south': 49.392207, 'west': 6.7853531}, {
  'zipCode': '66849',
  'north':   49.4210291,
  'east':    7.6064829000000005,
  'south':   49.38387,
  'west':    7.5170582999999995
}, {'zipCode': '66851', 'north': 49.4185125, 'east': 7.693681599999999, 'south': 49.3046888, 'west': 7.4730466}, {
  'zipCode': '66862',
  'north':   49.4290493,
  'east':    7.6684286,
  'south':   49.4048092,
  'west':    7.593374999999999
}, {'zipCode': '66869', 'north': 49.5701745, 'east': 7.435604099999999, 'south': 49.5035936, 'west': 7.3474930999999994}, {
  'zipCode': '66871',
  'north':   49.6003867,
  'east':    7.467718600000001,
  'south':   49.4818069,
  'west':    7.2755557
}, {'zipCode': '66877', 'north': 49.4843405, 'east': 7.634225200000001, 'south': 49.4045518, 'west': 7.4956784999999995}, {
  'zipCode': '66879',
  'north':   49.5369781,
  'east':    7.606729499999999,
  'south':   49.435095,
  'west':    7.4457516
}, {'zipCode': '66882', 'north': 49.4425429, 'east': 7.5217485, 'south': 49.40249, 'west': 7.410491699999999}, {
  'zipCode': '66885',
  'north':   49.5788355,
  'east':    7.509719400000001,
  'south':   49.5287469,
  'west':    7.416901000000001
}, {'zipCode': '66887', 'north': 49.6461788, 'east': 7.5862617000000006, 'south': 49.4955206, 'west': 7.414687}, {
  'zipCode': '66892',
  'north':   49.4217453,
  'east':    7.4868721,
  'south':   49.3632157,
  'west':    7.392529399999999
}, {'zipCode': '66894', 'north': 49.396012, 'east': 7.5493617, 'south': 49.309615, 'west': 7.3777247}, {
  'zipCode': '66901',
  'north':   49.4216123,
  'east':    7.415516,
  'south':   49.374441700000006,
  'west':    7.316438600000001
}, {'zipCode': '66903', 'north': 49.465748000000005, 'east': 7.4235005, 'south': 49.414352, 'west': 7.2838434}, {
  'zipCode': '66904',
  'north':   49.4566692,
  'east':    7.418130000000001,
  'south':   49.4144333,
  'west':    7.3284658
}, {'zipCode': '66907', 'north': 49.5001753, 'east': 7.4738856, 'south': 49.4507826, 'west': 7.400008800000001}, {
  'zipCode': '66909',
  'north':   49.5211757,
  'east':    7.4830001,
  'south':   49.4232172,
  'west':    7.2959949
}, {'zipCode': '66914', 'north': 49.4022883, 'east': 7.405537500000001, 'south': 49.3634952, 'west': 7.289663}, {
  'zipCode': '66916',
  'north':   49.4564158,
  'east':    7.326001200000001,
  'south':   49.3966736,
  'west':    7.2460216
}, {'zipCode': '66917', 'north': 49.3560433, 'east': 7.545796200000001, 'south': 49.2910109, 'west': 7.4743722}, {
  'zipCode': '66919',
  'north':   49.377463,
  'east':    7.6674503,
  'south':   49.2734939,
  'west':    7.5305144
}, {'zipCode': '66953', 'north': 49.2346411, 'east': 7.6739808, 'south': 49.1929661, 'west': 7.5922499}, {
  'zipCode': '66954',
  'north':   49.2338706,
  'east':    7.6056556,
  'south':   49.168128,
  'west':    7.5132617
}, {'zipCode': '66955', 'north': 49.1984059, 'east': 7.6783267, 'south': 49.1414303, 'west': 7.5593477}, {
  'zipCode': '66957',
  'north':   49.20296230000001,
  'east':    7.741878599999999,
  'south':   49.0796917,
  'west':    7.472397800000001
}, {'zipCode': '66969', 'north': 49.1929675, 'east': 7.7302037, 'south': 49.116536, 'west': 7.577227400000001}, {
  'zipCode': '66976',
  'north':   49.257380100000006,
  'east':    7.675239200000001,
  'south':   49.21773,
  'west':    7.597799
}, {'zipCode': '66978', 'north': 49.3215318, 'east': 7.8424742, 'south': 49.2255481, 'west': 7.6047684}, {
  'zipCode': '66981',
  'north':   49.2478398,
  'east':    7.759456500000001,
  'south':   49.1951093,
  'west':    7.655669
}, {'zipCode': '66987', 'north': 49.2949299, 'east': 7.609215500000001, 'south': 49.2435862, 'west': 7.528643800000001}, {
  'zipCode': '66989',
  'north':   49.3082351,
  'east':    7.637883400000001,
  'south':   49.1967923,
  'west':    7.481517
}, {'zipCode': '66994', 'north': 49.1879491, 'east': 7.8084254, 'south': 49.1084994, 'west': 7.707882000000001}, {
  'zipCode': '66996',
  'north':   49.1791242,
  'east':    7.8626942,
  'south':   49.044006,
  'west':    7.6056610000000004
}, {'zipCode': '66999', 'north': 49.226686, 'east': 7.814996600000001, 'south': 49.16455560000001, 'west': 7.7190437}, {
  'zipCode': '67059',
  'north':   49.4897636,
  'east':    8.456946400000001,
  'south':   49.4660444,
  'west':    8.4082855
}, {'zipCode': '67061', 'north': 49.4870483, 'east': 8.4639707, 'south': 49.4622088, 'west': 8.4271567}, {
  'zipCode': '67063',
  'north':   49.544118,
  'east':    8.4451699,
  'south':   49.4816808,
  'west':    8.3826888
}, {'zipCode': '67065', 'north': 49.4834318, 'east': 8.476940100000002, 'south': 49.4266985, 'west': 8.3822042}, {
  'zipCode': '67067',
  'north':   49.4711506,
  'east':    8.4343443,
  'south':   49.4272681,
  'west':    8.3619034
}, {'zipCode': '67069', 'north': 49.5480579, 'east': 8.4122517, 'south': 49.4984058, 'west': 8.375562}, {
  'zipCode': '67071',
  'north':   49.5145852,
  'east':    8.4142496,
  'south':   49.4589063,
  'west':    8.2982215
}, {'zipCode': '67098', 'north': 49.5044425, 'east': 8.2544034, 'south': 49.3983675, 'west': 7.9518051}, {
  'zipCode': '67105',
  'north':   49.4240933,
  'east':    8.4196059,
  'south':   49.3473036,
  'west':    8.3269498
}, {'zipCode': '67112', 'north': 49.471343, 'east': 8.3891027, 'south': 49.4118128, 'west': 8.3074346}, {
  'zipCode': '67117',
  'north':   49.4341361,
  'east':    8.4135806,
  'south':   49.3932349,
  'west':    8.3665956
}, {'zipCode': '67122', 'north': 49.4439887, 'east': 8.5082924, 'south': 49.4080297, 'west': 8.4521569}, {
  'zipCode': '67125',
  'north':   49.4531131,
  'east':    8.3408539,
  'south':   49.3955669,
  'west':    8.2807212
}, {'zipCode': '67126', 'north': 49.4368186, 'east': 8.3069143, 'south': 49.3939135, 'west': 8.2577226}, {
  'zipCode': '67127',
  'north':   49.4465475,
  'east':    8.2845582,
  'south':   49.4182738,
  'west':    8.2327313
}, {'zipCode': '67133', 'north': 49.5025926, 'east': 8.3118678, 'south': 49.4719722, 'west': 8.2687738}, {
  'zipCode': '67134',
  'north':   49.4980704,
  'east':    8.2749633,
  'south':   49.477774,
  'west':    8.2387269
}, {'zipCode': '67136', 'north': 49.478732, 'east': 8.3202055, 'south': 49.4465826, 'west': 8.2712556}, {
  'zipCode': '67141',
  'north':   49.4388909,
  'east':    8.4785059,
  'south':   49.3820828,
  'west':    8.4064097
}, {'zipCode': '67146', 'north': 49.4387672, 'east': 8.234386, 'south': 49.4008682, 'west': 8.1225096}, {
  'zipCode': '67147',
  'north':   49.437686,
  'east':    8.211866,
  'south':   49.4185618,
  'west':    8.1499903
}, {'zipCode': '67149', 'north': 49.4214931, 'east': 8.27326, 'south': 49.3780202, 'west': 8.1986652}, {
  'zipCode': '67150',
  'north':   49.4300214,
  'east':    8.237595300000002,
  'south':   49.404239,
  'west':    8.2041835
}, {'zipCode': '67152', 'north': 49.404853300000006, 'east': 8.2152658, 'south': 49.3828978, 'west': 8.133019}, {
  'zipCode': '67157',
  'north':   49.4611113,
  'east':    8.2106319,
  'south':   49.3823443,
  'west':    8.0602818
}, {'zipCode': '67158', 'north': 49.4795314, 'east': 8.2774295, 'south': 49.4534855, 'west': 8.231985000000002}, {
  'zipCode': '67159',
  'north':   49.4682756,
  'east':    8.2360804,
  'south':   49.437197600000005,
  'west':    8.2062662
}, {'zipCode': '67161', 'north': 49.4702382, 'east': 8.283813, 'south': 49.43955530000001, 'west': 8.227793600000002}, {
  'zipCode': '67165',
  'north':   49.4156655,
  'east':    8.4966638,
  'south':   49.3755053,
  'west':    8.413404
}, {'zipCode': '67166', 'north': 49.4052163, 'east': 8.5013472, 'south': 49.340693, 'west': 8.4208721}, {
  'zipCode': '67167',
  'north':   49.4988706,
  'east':    8.2392754,
  'south':   49.4802932,
  'west':    8.203132
}, {'zipCode': '67169', 'north': 49.5009039, 'east': 8.1955561, 'south': 49.4751785, 'west': 8.1452881}, {
  'zipCode': '67227',
  'north':   49.5825759,
  'east':    8.423145,
  'south':   49.4846955,
  'west':    8.3093124
}, {'zipCode': '67229', 'north': 49.561936, 'east': 8.2843599, 'south': 49.5234413, 'west': 8.2064275}, {
  'zipCode': '67240',
  'north':   49.6089162,
  'east':    8.4238,
  'south':   49.5581595,
  'west':    8.3333069
}, {'zipCode': '67245', 'north': 49.5375476, 'east': 8.3145204, 'south': 49.4957198, 'west': 8.2563309}, {
  'zipCode': '67246',
  'north':   49.5917653,
  'east':    8.2841044,
  'south':   49.5529401,
  'west':    8.2168924
}, {'zipCode': '67251', 'north': 49.525338100000006, 'east': 8.2381247, 'south': 49.4942379, 'west': 8.1858175}, {
  'zipCode': '67256',
  'north':   49.5335905,
  'east':    8.2762391,
  'south':   49.4510893,
  'west':    8.0524703
}, {'zipCode': '67258', 'north': 49.558826, 'east': 8.3228148, 'south': 49.5330966, 'west': 8.2562716}, {
  'zipCode': '67259',
  'north':   49.5999073,
  'east':    8.3437598,
  'south':   49.5482452,
  'west':    8.279154700000001
}, {'zipCode': '67269', 'north': 49.5961497, 'east': 8.2007885, 'south': 49.5432881, 'west': 8.1214857}, {
  'zipCode': '67271',
  'north':   49.62669540000001,
  'east':    8.222181,
  'south':   49.5068627,
  'west':    8.081616
}, {'zipCode': '67273', 'north': 49.5313861, 'east': 8.201626300000001, 'south': 49.4597653, 'west': 8.0195317}, {
  'zipCode': '67278',
  'north':   49.6288068,
  'east':    8.2230362,
  'south':   49.5911522,
  'west':    8.1397258
}, {'zipCode': '67280', 'north': 49.597201, 'east': 8.1404278, 'south': 49.5529443, 'west': 8.0870475}, {
  'zipCode': '67281',
  'north':   49.5567388,
  'east':    8.2140845,
  'south':   49.5234833,
  'west':    8.166847500000001
}, {'zipCode': '67283', 'north': 49.6038039, 'east': 8.2335444, 'south': 49.572561, 'west': 8.156457}, {
  'zipCode': '67292',
  'north':   49.6918892,
  'east':    8.0447803,
  'south':   49.640755,
  'west':    7.9093345
}, {'zipCode': '67294', 'north': 49.730954, 'east': 8.1224825, 'south': 49.6453163, 'west': 7.9468559999999995}, {
  'zipCode': '67295',
  'north':   49.6550994,
  'east':    8.0478148,
  'south':   49.62089000000001,
  'west':    7.9249447
}, {'zipCode': '67297', 'north': 49.6468542, 'east': 8.0678923, 'south': 49.6018092, 'west': 8.0120035}, {
  'zipCode': '67304',
  'north':   49.5881866,
  'east':    8.1014401,
  'south':   49.5128249,
  'west':    7.957567999999999
}, {'zipCode': '67305', 'north': 49.5502021, 'east': 8.0459124, 'south': 49.4903509, 'west': 7.9503421}, {
  'zipCode': '67307',
  'north':   49.6121325,
  'east':    8.0893984,
  'south':   49.5631496,
  'west':    7.968393799999999
}, {'zipCode': '67308', 'north': 49.6736898, 'east': 8.161547, 'south': 49.5758431, 'west': 8.0490621}, {
  'zipCode': '67310',
  'north':   49.5468662,
  'east':    8.1022214,
  'south':   49.5226893,
  'west':    8.0440812
}, {'zipCode': '67311', 'north': 49.5572623, 'east': 8.1267938, 'south': 49.5330044, 'west': 8.0861601}, {
  'zipCode': '67316',
  'north':   49.5164785,
  'east':    8.0740213,
  'south':   49.4632014,
  'west':    7.9947807
}, {'zipCode': '67317', 'north': 49.5347903, 'east': 8.1168687, 'south': 49.4748562, 'west': 8.0464614}, {
  'zipCode': '67319',
  'north':   49.5323291,
  'east':    8.0916528,
  'south':   49.4738154,
  'west':    7.948370500000001
}, {'zipCode': '67346', 'north': 49.3760142, 'east': 8.4890993, 'south': 49.2830644, 'west': 8.3768474}, {
  'zipCode': '67354',
  'north':   49.3052601,
  'east':    8.4669735,
  'south':   49.249842,
  'west':    8.3643067
}, {'zipCode': '67360', 'north': 49.2687398, 'east': 8.4130244, 'south': 49.2179603, 'west': 8.3276462}, {
  'zipCode': '67361',
  'north':   49.2849148,
  'east':    8.3034674,
  'south':   49.2631251,
  'west':    8.2475858
}, {'zipCode': '67363', 'north': 49.2643702, 'east': 8.3489542, 'south': 49.203062, 'west': 8.2386044}, {
  'zipCode': '67365',
  'north':   49.297744,
  'east':    8.3683959,
  'south':   49.2580251,
  'west':    8.3084041
}, {'zipCode': '67366', 'north': 49.2884694, 'east': 8.3144774, 'south': 49.253871700000005, 'west': 8.268774}, {
  'zipCode': '67368',
  'north':   49.2615911,
  'east':    8.3514029,
  'south':   49.2086787,
  'west':    8.3060391
}, {'zipCode': '67373', 'north': 49.3544893, 'east': 8.4091041, 'south': 49.2978436, 'west': 8.3500403}, {
  'zipCode': '67374',
  'north':   49.3353078,
  'east':    8.3661935,
  'south':   49.3033623,
  'west':    8.3145562
}, {'zipCode': '67376', 'north': 49.3125825, 'east': 8.3749848, 'south': 49.2812131, 'west': 8.3143247}, {
  'zipCode': '67377',
  'north':   49.319797,
  'east':    8.324059500000002,
  'south':   49.2800766,
  'west':    7.9812599
}, {'zipCode': '67378', 'north': 49.2614349, 'east': 8.286961, 'south': 49.2107431, 'west': 8.2316521}, {
  'zipCode': '67433',
  'north':   49.3735356,
  'east':    8.1983211,
  'south':   49.3263242,
  'west':    8.1039251
}, {'zipCode': '67434', 'north': 49.3673851, 'east': 8.1782212, 'south': 49.3112585, 'west': 8.0009672}, {
  'zipCode': '67435',
  'north':   49.411961700000006,
  'east':    8.3173792,
  'south':   49.295981,
  'west':    8.0950461
}, {'zipCode': '67454', 'north': 49.3910355, 'east': 8.3174421, 'south': 49.3113235, 'west': 8.2162179}, {
  'zipCode': '67459',
  'north':   49.40537450000001,
  'east':    8.3798383,
  'south':   49.3160167,
  'west':    8.2670812
}, {'zipCode': '67466', 'north': 49.3987483, 'east': 8.0898378, 'south': 49.33898000000001, 'west': 7.984857699999999}, {
  'zipCode': '67468',
  'north':   49.4630633,
  'east':    8.068456,
  'south':   49.3652163,
  'west':    7.919145199999999
}, {'zipCode': '67471', 'north': 49.40008710000001, 'east': 8.0005116, 'south': 49.3086953, 'west': 7.8247568}, {
  'zipCode': '67472',
  'north':   49.3933553,
  'east':    8.0360561,
  'south':   49.3449844,
  'west':    7.969196400000001
}, {'zipCode': '67473', 'north': 49.3933027, 'east': 8.107298400000001, 'south': 49.3603849, 'west': 8.0762608}, {
  'zipCode': '67475',
  'north':   49.4300836,
  'east':    8.0231007,
  'south':   49.3882399,
  'west':    7.9511774
}, {'zipCode': '67480', 'north': 49.319836, 'east': 8.1559781, 'south': 49.2731655, 'west': 7.9607902}, {
  'zipCode': '67482',
  'north':   49.3345595,
  'east':    8.2696201,
  'south':   49.258829,
  'west':    7.974237899999999
}, {'zipCode': '67483', 'north': 49.2982602, 'east': 8.2090917, 'south': 49.2485223, 'west': 8.0028704}, {
  'zipCode': '67487',
  'north':   49.3242668,
  'east':    8.1443255,
  'south':   49.2871217,
  'west':    7.995997699999999
}, {'zipCode': '67489', 'north': 49.3438025, 'east': 8.2013653, 'south': 49.2877367, 'west': 7.9796767}, {
  'zipCode': '67547',
  'north':   49.6828596,
  'east':    8.4146456,
  'south':   49.5927035,
  'west':    8.3336351
}, {'zipCode': '67549', 'north': 49.658027, 'east': 8.3580526, 'south': 49.6219666, 'west': 8.2930063}, {
  'zipCode': '67550',
  'north':   49.7364538,
  'east':    8.4462072,
  'south':   49.6441506,
  'west':    8.2556005
}, {'zipCode': '67551', 'north': 49.6552736, 'east': 8.343111300000002, 'south': 49.5863409, 'west': 8.2380942}, {
  'zipCode': '67574',
  'north':   49.7240219,
  'east':    8.3516943,
  'south':   49.6824673,
  'west':    8.2768705
}, {'zipCode': '67575', 'north': 49.7728104, 'east': 8.4577257, 'south': 49.7246535, 'west': 8.3504402}, {
  'zipCode': '67577',
  'north':   49.784483800000004,
  'east':    8.3546341,
  'south':   49.744254,
  'west':    8.2822912
}, {'zipCode': '67578', 'north': 49.8034889, 'east': 8.4174356, 'south': 49.7441553, 'west': 8.3490873}, {
  'zipCode': '67580',
  'north':   49.76890490000001,
  'east':    8.4797407,
  'south':   49.72625260000001,
  'west':    8.4078824
}, {'zipCode': '67582', 'north': 49.7491015, 'east': 8.3560707, 'south': 49.7318302, 'west': 8.2866036}, {
  'zipCode': '67583',
  'north':   49.8212777,
  'east':    8.4000249,
  'south':   49.7820232,
  'west':    8.3115847
}, {'zipCode': '67585', 'north': 49.7769374, 'east': 8.3010648, 'south': 49.7484238, 'west': 8.2548677}, {
  'zipCode': '67586',
  'north':   49.7906223,
  'east':    8.2813979,
  'south':   49.7673317,
  'west':    8.232824
}, {'zipCode': '67587', 'north': 49.7878176, 'east': 8.3121587, 'south': 49.7714088, 'west': 8.2680746}, {
  'zipCode': '67590',
  'north':   49.6468974,
  'east':    8.241321,
  'south':   49.6206974,
  'west':    8.1749508
}, {'zipCode': '67591', 'north': 49.6717284, 'east': 8.2789252, 'south': 49.5879651, 'west': 8.1503937}, {
  'zipCode': '67592',
  'north':   49.677733200000006,
  'east':    8.2356112,
  'south':   49.6428711,
  'west':    8.1717766
}, {'zipCode': '67593', 'north': 49.7199511, 'east': 8.2797009, 'south': 49.6760342, 'west': 8.2023824}, {
  'zipCode': '67595',
  'north':   49.7402867,
  'east':    8.3507415,
  'south':   49.712604,
  'west':    8.2450745
}, {'zipCode': '67596', 'north': 49.7699288, 'east': 8.2874542, 'south': 49.7299685, 'west': 8.2035752}, {
  'zipCode': '67598',
  'north':   49.71148000000001,
  'east':    8.2211311,
  'south':   49.6729707,
  'west':    8.1706433
}, {'zipCode': '67599', 'north': 49.6886989, 'east': 8.2639147, 'south': 49.6657188, 'west': 8.2222484}, {
  'zipCode': '67655',
  'north':   49.4483207,
  'east':    7.7880599,
  'south':   49.4340057,
  'west':    7.743439300000001
}, {'zipCode': '67657', 'north': 49.4781686, 'east': 7.864367599999999, 'south': 49.4270818, 'west': 7.7459613}, {
  'zipCode': '67659',
  'north':   49.4951266,
  'east':    7.8066447,
  'south':   49.4394901,
  'west':    7.694781800000001
}, {'zipCode': '67661', 'north': 49.4916674, 'east': 7.848398300000001, 'south': 49.3587066, 'west': 7.6271007}, {
  'zipCode': '67663',
  'north':   49.4492298,
  'east':    7.8706819,
  'south':   49.39551840000001,
  'west':    7.6993459
}, {'zipCode': '67677', 'north': 49.5328995, 'east': 7.9671205, 'south': 49.4508966, 'west': 7.8438436000000005}, {
  'zipCode': '67678',
  'north':   49.5130602,
  'east':    7.8860555,
  'south':   49.461074,
  'west':    7.799606800000001
}, {'zipCode': '67680', 'north': 49.5352289, 'east': 7.9404919000000005, 'south': 49.5078341, 'west': 7.890664999999999}, {
  'zipCode': '67681',
  'north':   49.5384633,
  'east':    7.8958794999999995,
  'south':   49.5040322,
  'west':    7.833258900000001
}, {'zipCode': '67685', 'north': 49.5215338, 'east': 7.671717, 'south': 49.4347569, 'west': 7.5577255}, {
  'zipCode': '67686',
  'north':   49.4848643,
  'east':    7.6085253,
  'south':   49.4639391,
  'west':    7.5577255
}, {'zipCode': '67688', 'north': 49.4936262, 'east': 7.704914100000001, 'south': 49.441905, 'west': 7.6378899}, {
  'zipCode': '67691',
  'north':   49.452667,
  'east':    7.9441436,
  'south':   49.3951196,
  'west':    7.846759600000001
}, {'zipCode': '67693', 'north': 49.4867376, 'east': 7.964533499999999, 'south': 49.3641459, 'west': 7.815175000000001}, {
  'zipCode': '67697',
  'north':   49.5383633,
  'east':    7.8421197,
  'south':   49.48283790000001,
  'west':    7.7249837
}, {'zipCode': '67699', 'north': 49.57611310000001, 'east': 7.7830412, 'south': 49.5258072, 'west': 7.725931600000001}, {
  'zipCode': '67700',
  'north':   49.597123,
  'east':    7.746712100000001,
  'south':   49.5459101,
  'west':    7.638721500000001
}, {'zipCode': '67701', 'north': 49.5634323, 'east': 7.7381162, 'south': 49.5316953, 'west': 7.684101}, {
  'zipCode': '67705',
  'north':   49.396517200000005,
  'east':    7.8377984,
  'south':   49.3069523,
  'west':    7.691659899999999
}, {'zipCode': '67706', 'north': 49.38678610000001, 'east': 7.704140200000001, 'south': 49.3433766, 'west': 7.6470347}, {
  'zipCode': '67707',
  'north':   49.369481400000005,
  'east':    7.7407834,
  'south':   49.3321142,
  'west':    7.668684100000001
}, {'zipCode': '67714', 'north': 49.3157904, 'east': 7.7001167, 'south': 49.2651107, 'west': 7.617383200000001}, {
  'zipCode': '67715',
  'north':   49.3397244,
  'east':    7.724637200000001,
  'south':   49.3151892,
  'west':    7.666039799999999
}, {'zipCode': '67716', 'north': 49.3262932, 'east': 7.799530500000001, 'south': 49.2711661, 'west': 7.677585699999999}, {
  'zipCode': '67718',
  'north':   49.3450229,
  'east':    7.771881399999999,
  'south':   49.3133609,
  'west':    7.7020733
}, {'zipCode': '67722', 'north': 49.5888853, 'east': 7.912281500000001, 'south': 49.5334958, 'west': 7.800612900000001}, {
  'zipCode': '67724',
  'north':   49.6017909,
  'east':    7.917987,
  'south':   49.5318364,
  'west':    7.738269200000001
}, {'zipCode': '67725', 'north': 49.611034700000005, 'east': 7.9791137, 'south': 49.5544485, 'west': 7.8935323}, {
  'zipCode': '67727',
  'north':   49.5630382,
  'east':    7.8780244,
  'south':   49.53047420000001,
  'west':    7.8208388
}, {'zipCode': '67728', 'north': 49.5621609, 'east': 7.9006102, 'south': 49.5263591, 'west': 7.8608597}, {
  'zipCode': '67729',
  'north':   49.5650457,
  'east':    7.9738391,
  'south':   49.5280907,
  'west':    7.9037508
}, {'zipCode': '67731', 'north': 49.5026075, 'east': 7.757785, 'south': 49.4726598, 'west': 7.7031141}, {
  'zipCode': '67732',
  'north':   49.5322224,
  'east':    7.699337600000001,
  'south':   49.5110346,
  'west':    7.6635748
}, {'zipCode': '67734', 'north': 49.5313463, 'east': 7.7361006, 'south': 49.4872092, 'west': 7.606729499999999}, {
  'zipCode': '67735',
  'north':   49.5413808,
  'east':    7.7469678,
  'south':   49.5087734,
  'west':    7.6853525
}, {'zipCode': '67737', 'north': 49.5606967, 'east': 7.693830500000001, 'south': 49.5214666, 'west': 7.606729499999999}, {
  'zipCode': '67742',
  'north':   49.6731026,
  'east':    7.6760445,
  'south':   49.6127972,
  'west':    7.5046379
}, {'zipCode': '67744', 'north': 49.7184466, 'east': 7.745053399999999, 'south': 49.5875568, 'west': 7.4264753}, {
  'zipCode': '67745',
  'north':   49.6738317,
  'east':    7.5853328,
  'south':   49.6472056,
  'west':    7.5397462
}, {'zipCode': '67746', 'north': 49.6784244, 'east': 7.5534362, 'south': 49.6522493, 'west': 7.4451485}, {
  'zipCode': '67748',
  'north':   49.7017254,
  'east':    7.672356,
  'south':   49.659024,
  'west':    7.621154300000001
}, {'zipCode': '67749', 'north': 49.633932400000006, 'east': 7.5866274, 'south': 49.596771, 'west': 7.5286474}, {
  'zipCode': '67752',
  'north':   49.6151433,
  'east':    7.656035,
  'south':   49.5520973,
  'west':    7.582754699999999
}, {'zipCode': '67753', 'north': 49.640107, 'east': 7.705915900000001, 'south': 49.5215338, 'west': 7.562320400000001}, {
  'zipCode': '67754',
  'north':   49.5746633,
  'east':    7.5907439000000005,
  'south':   49.5475131,
  'west':    7.521076999999999
}, {'zipCode': '67756', 'north': 49.6002776, 'east': 7.5827039, 'south': 49.5645165, 'west': 7.532912900000001}, {
  'zipCode': '67757',
  'north':   49.5716883,
  'east':    7.661904800000001,
  'south':   49.5316036,
  'west':    7.6057134
}, {'zipCode': '67759', 'north': 49.6524614, 'east': 7.7446932, 'south': 49.5861691, 'west': 7.6739279}, {
  'zipCode': '67806',
  'north':   49.6584746,
  'east':    7.9437342,
  'south':   49.5951244,
  'west':    7.713753400000001
}, {'zipCode': '67808', 'north': 49.7557767, 'east': 8.0198697, 'south': 49.5753222, 'west': 7.744357600000001}, {
  'zipCode': '67811',
  'north':   49.6807932,
  'east':    7.8543949,
  'south':   49.6474615,
  'west':    7.797403
}, {'zipCode': '67813', 'north': 49.6976651, 'east': 7.9087142, 'south': 49.66126580000001, 'west': 7.839103}, {
  'zipCode': '67814',
  'north':   49.6453524,
  'east':    7.978358799999999,
  'south':   49.6055926,
  'west':    7.8911518
}, {'zipCode': '67816', 'north': 49.6155992, 'east': 8.0360458, 'south': 49.5794455, 'west': 7.969547599999999}, {
  'zipCode': '67817',
  'north':   49.6098086,
  'east':    7.9357268,
  'south':   49.572307,
  'west':    7.8564713
}, {'zipCode': '67819', 'north': 49.7314241, 'east': 7.9757764, 'south': 49.660858100000006, 'west': 7.8893873}, {
  'zipCode': '67821',
  'north':   49.7581243,
  'east':    7.849115100000001,
  'south':   49.697281,
  'west':    7.7937942
}, {'zipCode': '67822', 'north': 49.7658679, 'east': 7.91737, 'south': 49.6460783, 'west': 7.699080499999999}, {
  'zipCode': '67823',
  'north':   49.7648714,
  'east':    7.7990402,
  'south':   49.6788517,
  'west':    7.7142489
}, {'zipCode': '67824', 'north': 49.7921164, 'east': 7.8198937, 'south': 49.7579927, 'west': 7.7556966}, {
  'zipCode': '67826',
  'north':   49.7711948,
  'east':    7.7951033,
  'south':   49.7495816,
  'west':    7.7573037000000005
}, {'zipCode': '67827', 'north': 49.68498670000001, 'east': 7.712558, 'south': 49.6356541, 'west': 7.651407099999999}, {
  'zipCode': '67829',
  'north':   49.7142378,
  'east':    7.7352164000000005,
  'south':   49.6663633,
  'west':    7.671832800000001
}, {'zipCode': '68159', 'north': 49.5121174, 'east': 8.4719717, 'south': 49.4809192, 'west': 8.435846}, {
  'zipCode': '68161',
  'north':   49.494004,
  'east':    8.4824011,
  'south':   49.4765336,
  'west':    8.4631112
}, {'zipCode': '68163', 'north': 49.4834985, 'east': 8.5436716, 'south': 49.4537332, 'west': 8.4563779}, {
  'zipCode': '68165',
  'north':   49.4913801,
  'east':    8.508472,
  'south':   49.4690553,
  'west':    8.471383
}, {'zipCode': '68167', 'north': 49.5103139, 'east': 8.5107834, 'south': 49.4834985, 'west': 8.470749}, {
  'zipCode': '68169',
  'north':   49.5410247,
  'east':    8.5028589,
  'south':   49.494004,
  'west':    8.4235605
}, {'zipCode': '68199', 'north': 49.4687188, 'east': 8.5122141, 'south': 49.4397787, 'west': 8.4415465}, {
  'zipCode': '68219',
  'north':   49.4597493,
  'east':    8.5736296,
  'south':   49.4103619,
  'west':    8.4974104
}, {'zipCode': '68229', 'north': 49.4530941, 'east': 8.587622800000002, 'south': 49.4211932, 'west': 8.5573812}, {
  'zipCode': '68239',
  'north':   49.4776193,
  'east':    8.5899902,
  'south':   49.4366615,
  'west':    8.5260576
}, {'zipCode': '68259', 'north': 49.5215787, 'east': 8.5870329, 'south': 49.4762107, 'west': 8.508472}, {
  'zipCode': '68305',
  'north':   49.5552865,
  'east':    8.5253845,
  'south':   49.5114591,
  'west':    8.4540611
}, {'zipCode': '68307', 'north': 49.5904894, 'east': 8.5168805, 'south': 49.5313252, 'west': 8.4141602}, {
  'zipCode': '68309',
  'north':   49.548737700000004,
  'east':    8.5606039,
  'south':   49.4932063,
  'west':    8.4935628
}, {'zipCode': '68519', 'north': 49.5938416, 'east': 8.6203969, 'south': 49.5191807, 'west': 8.4950739}, {
  'zipCode': '68526',
  'north':   49.50204,
  'east':    8.6464957,
  'south':   49.4449026,
  'west':    8.5697202
}, {'zipCode': '68535', 'north': 49.4778458, 'east': 8.6287032, 'south': 49.4242417, 'west': 8.573217900000001}, {
  'zipCode': '68542',
  'north':   49.540368,
  'east':    8.6417307,
  'south':   49.4920748,
  'west':    8.5719703
}, {'zipCode': '68549', 'north': 49.4949321, 'east': 8.5867495, 'south': 49.4592139, 'west': 8.5436716}, {
  'zipCode': '68623',
  'north':   49.6787661,
  'east':    8.5941621,
  'south':   49.5729433,
  'west':    8.3646389
}, {'zipCode': '68642', 'north': 49.6750051, 'east': 8.5213121, 'south': 49.6133327, 'west': 8.4087629}, {
  'zipCode': '68647',
  'north':   49.7161697,
  'east':    8.5110705,
  'south':   49.6622848,
  'west':    8.3546642
}, {'zipCode': '68649', 'north': 49.7347097, 'east': 8.5403323, 'south': 49.6965461, 'west': 8.4245439}, {
  'zipCode': '68723',
  'north':   49.4179892,
  'east':    8.6332336,
  'south':   49.3373693,
  'west':    8.5326545
}, {'zipCode': '68753', 'north': 49.2698584, 'east': 8.5987228, 'south': 49.1888901, 'west': 8.4783659}, {
  'zipCode': '68766',
  'north':   49.3593176,
  'east':    8.6039975,
  'south':   49.3034154,
  'west':    8.4554738
}, {'zipCode': '68775', 'north': 49.3955918, 'east': 8.5602506, 'south': 49.33672440000001, 'west': 8.4920028}, {
  'zipCode': '68782',
  'north':   49.4182341,
  'east':    8.5445458,
  'south':   49.3732915,
  'west':    8.4633733
}, {'zipCode': '68789', 'north': 49.291536, 'east': 8.6576284, 'south': 49.2353244, 'west': 8.5614753}, {
  'zipCode': '68794',
  'north':   49.2901477,
  'east':    8.5435525,
  'south':   49.2440376,
  'west':    8.4455461
}, {'zipCode': '68799', 'north': 49.329283, 'east': 8.6098869, 'south': 49.2660679, 'west': 8.5275601}, {
  'zipCode': '68804',
  'north':   49.332039,
  'east':    8.5527408,
  'south':   49.2627401,
  'west':    8.4501017
}, {'zipCode': '68809', 'north': 49.3069631, 'east': 8.5443689, 'south': 49.2825488, 'west': 8.5094983}, {
  'zipCode': '69115',
  'north':   49.4119124,
  'east':    8.6988813,
  'south':   49.3926188,
  'west':    8.6525853
}, {'zipCode': '69117', 'north': 49.4154501, 'east': 8.7688298, 'south': 49.3722237, 'west': 8.6923882}, {
  'zipCode': '69118',
  'north':   49.4596927,
  'east':    8.7940496,
  'south':   49.3864383,
  'west':    8.7177623
}, {'zipCode': '69120', 'north': 49.4266775, 'east': 8.7262691, 'south': 49.410229, 'west': 8.6544658}, {
  'zipCode': '69121',
  'north':   49.4524202,
  'east':    8.7404846,
  'south':   49.4189495,
  'west':    8.6465518
}, {'zipCode': '69123', 'north': 49.4431153, 'east': 8.6699819, 'south': 49.39271990000001, 'west': 8.5731788}, {
  'zipCode': '69124',
  'north':   49.3983935,
  'east':    8.678009,
  'south':   49.3520029,
  'west':    8.6010036
}, {'zipCode': '69126', 'north': 49.3987863, 'east': 8.7304043, 'south': 49.3551103, 'west': 8.6724425}, {
  'zipCode': '69151',
  'north':   49.4162763,
  'east':    8.8913542,
  'south':   49.3683288,
  'west':    8.7547183
}, {'zipCode': '69168', 'north': 49.3307401, 'east': 8.7801926, 'south': 49.2651942, 'west': 8.6478028}, {
  'zipCode': '69181',
  'north':   49.3615211,
  'east':    8.771924,
  'south':   49.312583,
  'west':    8.5999209
}, {'zipCode': '69190', 'north': 49.327009, 'east': 8.6685701, 'south': 49.2753412, 'west': 8.5952745}, {
  'zipCode': '69198',
  'north':   49.4997435,
  'east':    8.7709576,
  'south':   49.4544051,
  'west':    8.6383921
}, {'zipCode': '69207', 'north': 49.3704642, 'east': 8.6678561, 'south': 49.3132705, 'west': 8.6058159}, {
  'zipCode': '69214',
  'north':   49.41503470000001,
  'east':    8.6475003,
  'south':   49.3792829,
  'west':    8.6092342
}, {'zipCode': '69221', 'north': 49.4667503, 'east': 8.7462352, 'south': 49.4366257, 'west': 8.6199477}, {
  'zipCode': '69226',
  'north':   49.3398527,
  'east':    8.7369885,
  'south':   49.3058351,
  'west':    8.6676201
}, {'zipCode': '69231', 'north': 49.2804299, 'east': 8.7264492, 'south': 49.2499874, 'west': 8.6511643}, {
  'zipCode': '69234',
  'north':   49.3152703,
  'east':    8.8062467,
  'south':   49.2588823,
  'west':    8.7156248
}, {'zipCode': '69239', 'north': 49.455704, 'east': 8.8944359, 'south': 49.3952611, 'west': 8.8012935}, {
  'zipCode': '69242',
  'north':   49.2706887,
  'east':    8.7699409,
  'south':   49.226727800000006,
  'west':    8.6822226
}, {'zipCode': '69245', 'north': 49.3838418, 'east': 8.8030926, 'south': 49.3337988, 'west': 8.743202}, {
  'zipCode': '69250',
  'north':   49.4751186,
  'east':    8.8370556,
  'south':   49.4101175,
  'west':    8.7617028
}, {'zipCode': '69251', 'north': 49.3755538, 'east': 8.7612871, 'south': 49.3475782, 'west': 8.7199798}, {
  'zipCode': '69253',
  'north':   49.5268043,
  'east':    8.823284,
  'south':   49.4687721,
  'west':    8.7492208
}, {'zipCode': '69254', 'north': 49.2609603, 'east': 8.7015349, 'south': 49.2283212, 'west': 8.646774}, {
  'zipCode': '69256',
  'north':   49.353845,
  'east':    8.8308505,
  'south':   49.323162,
  'west':    8.7698546
}, {'zipCode': '69257', 'north': 49.3815719, 'east': 8.8596925, 'south': 49.34634340000001, 'west': 8.7966442}, {
  'zipCode': '69259',
  'north':   49.4807191,
  'east':    8.7890996,
  'south':   49.4541925,
  'west':    8.7488496
}, {'zipCode': '69412', 'north': 49.5303436, 'east': 9.0864813, 'south': 49.4143136, 'west': 8.9253838}, {
  'zipCode': '69427',
  'north':   49.5822313,
  'east':    9.277755300000003,
  'south':   49.4864813,
  'west':    9.0709534
}, {'zipCode': '69429', 'north': 49.5001337, 'east': 9.1304135, 'south': 49.4142275, 'west': 9.0193874}, {
  'zipCode': '69434',
  'north':   49.506068,
  'east':    8.9516281,
  'south':   49.4237973,
  'west':    8.8070018
}, {'zipCode': '69436', 'north': 49.4511496, 'east': 8.9901584, 'south': 49.3826477, 'west': 8.8827232}, {
  'zipCode': '69437',
  'north':   49.4243129,
  'east':    9.1027058,
  'south':   49.37257670000001,
  'west':    9.0365758
}, {'zipCode': '69439', 'north': 49.428114, 'east': 9.0561609, 'south': 49.4027041, 'west': 9.0125319}, {
  'zipCode': '69469',
  'north':   49.5938416,
  'east':    8.7663975,
  'south':   49.4945926,
  'west':    8.6033587
}, {'zipCode': '69483', 'north': 49.6141415, 'east': 8.9117603, 'south': 49.4913688, 'west': 8.7856437}, {
  'zipCode': '69488',
  'north':   49.6019439,
  'east':    8.7765786,
  'south':   49.5352344,
  'west':    8.6803133
}, {'zipCode': '69493', 'north': 49.5267533, 'east': 8.7130546, 'south': 49.4877119, 'west': 8.626506100000002}, {
  'zipCode': '69502',
  'north':   49.607412,
  'east':    8.6983354,
  'south':   49.5818503,
  'west':    8.5893949
}, {'zipCode': '69509', 'north': 49.6298585, 'east': 8.8006014, 'south': 49.5517212, 'west': 8.6881558}, {
  'zipCode': '69514',
  'north':   49.6256828,
  'east':    8.6936961,
  'south':   49.5981821,
  'west':    8.5940648
}, {'zipCode': '69517', 'north': 49.5478772, 'east': 8.7741088, 'south': 49.5147887, 'west': 8.7026153}, {
  'zipCode': '69518',
  'north':   49.5754648,
  'east':    8.8075264,
  'south':   49.5113616,
  'west':    8.7689144
}, {'zipCode': '70173', 'north': 48.7923255, 'east': 9.1908101, 'south': 48.7706432, 'west': 9.1676246}, {
  'zipCode': '70174',
  'north':   48.7889258,
  'east':    9.1804914,
  'south':   48.775349,
  'west':    9.158759
}, {'zipCode': '70176', 'north': 48.7843505, 'east': 9.169096, 'south': 48.771088400000004, 'west': 9.1559936}, {
  'zipCode': '70178',
  'north':   48.7747985,
  'east':    9.1778304,
  'south':   48.7619111,
  'west':    9.1579055
}, {'zipCode': '70180', 'north': 48.7708881, 'east': 9.1831337, 'south': 48.7545647, 'west': 9.1644753}, {
  'zipCode': '70182',
  'north':   48.7856029,
  'east':    9.1911543,
  'south':   48.7679411,
  'west':    9.1776107
}, {'zipCode': '70184', 'north': 48.7812603, 'east': 9.22685, 'south': 48.7523165, 'west': 9.1741357}, {
  'zipCode': '70186',
  'north':   48.7829924,
  'east':    9.2253087,
  'south':   48.7634958,
  'west':    9.195062
}, {'zipCode': '70188', 'north': 48.7940447, 'east': 9.2339669, 'south': 48.778241, 'west': 9.1904275}, {
  'zipCode': '70190',
  'north':   48.7995092,
  'east':    9.2180795,
  'south':   48.78067870000001,
  'west':    9.1880046
}, {'zipCode': '70191', 'north': 48.81094300000001, 'east': 9.2066658, 'south': 48.7863535, 'west': 9.1704082}, {
  'zipCode': '70192',
  'north':   48.8097021,
  'east':    9.1771685,
  'south':   48.7858574,
  'west':    9.1462318
}, {'zipCode': '70193', 'north': 48.7934488, 'east': 9.1609774, 'south': 48.7719326, 'west': 9.1328435}, {
  'zipCode': '70195',
  'north':   48.8022251,
  'east':    9.1502994,
  'south':   48.769346,
  'west':    9.1028812
}, {'zipCode': '70197', 'north': 48.7916884, 'east': 9.1612328, 'south': 48.7570311, 'west': 9.0751527}, {
  'zipCode': '70199',
  'north':   48.7672785,
  'east':    9.165978900000002,
  'south':   48.7472332,
  'west':    9.1241394
}, {'zipCode': '70327', 'north': 48.7964027, 'east': 9.2914534, 'south': 48.7609955, 'west': 9.2213733}, {
  'zipCode': '70329',
  'north':   48.7887283,
  'east':    9.3158205,
  'south':   48.7401154,
  'west':    9.2049205
}, {'zipCode': '70372', 'north': 48.8154592, 'east': 9.2423685, 'south': 48.7835921, 'west': 9.2083712}, {
  'zipCode': '70374',
  'north':   48.8263119,
  'east':    9.2568091,
  'south':   48.795324,
  'west':    9.2220031
}, {'zipCode': '70376', 'north': 48.8382369, 'east': 9.2274587, 'south': 48.7982986, 'west': 9.1793704}, {
  'zipCode': '70378',
  'north':   48.866399400000006,
  'east':    9.2459496,
  'south':   48.818404,
  'west':    9.2022797
}, {'zipCode': '70435', 'north': 48.8453025, 'east': 9.1888111, 'south': 48.8175556, 'west': 9.1337224}, {
  'zipCode': '70437',
  'north':   48.8514291,
  'east':    9.2173241,
  'south':   48.8220212,
  'west':    9.1748793
}, {'zipCode': '70439', 'north': 48.8619084, 'east': 9.17622, 'south': 48.8322552, 'west': 9.1336444}, {
  'zipCode': '70469',
  'north':   48.8250774,
  'east':    9.1836622,
  'south':   48.7871432,
  'west':    9.1268669
}, {'zipCode': '70499', 'north': 48.8355254, 'east': 9.1409133, 'south': 48.781305, 'west': 9.0766404}, {
  'zipCode': '70563',
  'north':   48.7406444,
  'east':    9.1321317,
  'south':   48.7179859,
  'west':    9.0782893
}, {'zipCode': '70565', 'north': 48.729881, 'east': 9.1730291, 'south': 48.7043211, 'west': 9.0822102}, {
  'zipCode': '70567',
  'north':   48.7389874,
  'east':    9.1861891,
  'south':   48.7048952,
  'west':    9.1272728
}, {'zipCode': '70569', 'north': 48.7686197, 'east': 9.1410427, 'south': 48.7204575, 'west': 9.0386007}, {
  'zipCode': '70597',
  'north':   48.7594812,
  'east':    9.1998658,
  'south':   48.7306957,
  'west':    9.1353219
}, {'zipCode': '70599', 'north': 48.7444178, 'east': 9.2449478, 'south': 48.69201880000001, 'west': 9.1672554}, {
  'zipCode': '70619',
  'north':   48.7577356,
  'east':    9.2470247,
  'south':   48.726392,
  'west':    9.1910831
}, {'zipCode': '70629', 'north': 48.69793130000001, 'east': 9.2260203, 'south': 48.6802044, 'west': 9.1805858}, {
  'zipCode': '70734',
  'north':   48.8186022,
  'east':    9.3196965,
  'south':   48.7782911,
  'west':    9.2465923
}, {'zipCode': '70736', 'north': 48.8626678, 'east': 9.295222, 'south': 48.8108964, 'west': 9.2371585}, {
  'zipCode': '70771',
  'north':   48.7105066,
  'east':    9.1912208,
  'south':   48.6460481,
  'west':    9.0848216
}, {'zipCode': '70794', 'north': 48.694556, 'east': 9.2703068, 'south': 48.6237554, 'west': 9.1597336}, {
  'zipCode': '70806',
  'north':   48.8843269,
  'east':    9.2241209,
  'south':   48.8475184,
  'west':    9.1523363
}, {'zipCode': '70825', 'north': 48.8690875, 'east': 9.1431604, 'south': 48.8234961, 'west': 9.0480677}, {
  'zipCode': '70839',
  'north':   48.8160492,
  'east':    9.0880658,
  'south':   48.7556181,
  'west':    9.0311972
}, {'zipCode': '71032', 'north': 48.7037556, 'east': 9.1129604, 'south': 48.6597566, 'west': 9.0045751}, {
  'zipCode': '71034',
  'north':   48.7010242,
  'east':    9.0113688,
  'south':   48.6550168,
  'west':    8.9322423
}, {'zipCode': '71063', 'north': 48.7361777, 'east': 9.0259512, 'south': 48.6874129, 'west': 8.9718987}, {
  'zipCode': '71065',
  'north':   48.7276244,
  'east':    9.1083624,
  'south':   48.6931935,
  'west':    9.0014614
}, {'zipCode': '71067', 'north': 48.7385538, 'east': 9.0676256, 'south': 48.7114756, 'west': 8.9801777}, {
  'zipCode': '71069',
  'north':   48.7468285,
  'east':    9.0548882,
  'south':   48.6737578,
  'west':    8.9047675
}, {'zipCode': '71083', 'north': 48.6392506, 'east': 8.981905800000002, 'south': 48.5602164, 'west': 8.7898756}, {
  'zipCode': '71088',
  'north':   48.6642167,
  'east':    9.0485669,
  'south':   48.6066836,
  'west':    8.9840887
}, {'zipCode': '71093', 'north': 48.6479251, 'east': 9.101434, 'south': 48.5880705, 'west': 9.022254}, {
  'zipCode': '71101',
  'north':   48.6753626,
  'east':    9.1061896,
  'south':   48.6416094,
  'west':    9.0214341
}, {'zipCode': '71106', 'north': 48.763396900000004, 'east': 9.0366608, 'south': 48.7285226, 'west': 8.9291311}, {
  'zipCode': '71111',
  'north':   48.6579297,
  'east':    9.1736919,
  'south':   48.6001232,
  'west':    9.0878179
}, {'zipCode': '71116', 'north': 48.6622832, 'east': 8.9726667, 'south': 48.6143641, 'west': 8.8450204}, {
  'zipCode': '71120',
  'north':   48.7308904,
  'east':    8.944095,
  'south':   48.6964839,
  'west':    8.855128100000002
}, {'zipCode': '71126', 'north': 48.5778052, 'east': 8.8908967, 'south': 48.5369917, 'west': 8.7914579}, {
  'zipCode': '71131',
  'north':   48.6050344,
  'east':    8.825082900000002,
  'south':   48.5481058,
  'west':    8.7464371
}, {'zipCode': '71134', 'north': 48.7100693, 'east': 8.9300437, 'south': 48.653932, 'west': 8.8179322}, {
  'zipCode': '71139',
  'north':   48.675427000000006,
  'east':    8.9921435,
  'south':   48.6374297,
  'west':    8.9002378
}, {'zipCode': '71144', 'north': 48.6805846, 'east': 9.1574688, 'south': 48.6438255, 'west': 9.0881753}, {
  'zipCode': '71149',
  'north':   48.5423829,
  'east':    8.8658874,
  'south':   48.4974641,
  'west':    8.7848626
}, {'zipCode': '71154', 'north': 48.63651960000001, 'east': 8.9296688, 'south': 48.605898, 'west': 8.8642067}, {
  'zipCode': '71155',
  'north':   48.6479936,
  'east':    9.0430644,
  'south':   48.576285,
  'west':    8.9766597
}, {'zipCode': '71157', 'north': 48.6458996, 'east': 8.9895989, 'south': 48.5951418, 'west': 8.9190743}, {
  'zipCode': '71159',
  'north':   48.5497985,
  'east':    8.8089356,
  'south':   48.5210663,
  'west':    8.7554771
}, {'zipCode': '71229', 'north': 48.8334998, 'east': 9.0688097, 'south': 48.745869, 'west': 8.9571285}, {
  'zipCode': '71254',
  'north':   48.8656348,
  'east':    9.0935507,
  'south':   48.8092409,
  'west':    8.9568652
}, {'zipCode': '71263', 'north': 48.8030491, 'east': 8.932580800000002, 'south': 48.7169224, 'west': 8.7998835}, {
  'zipCode': '71272',
  'north':   48.8085216,
  'east':    8.9764557,
  'south':   48.7333396,
  'west':    8.8771859
}, {'zipCode': '71277', 'north': 48.831597, 'east': 8.9673335, 'south': 48.790024, 'west': 8.8904651}, {
  'zipCode': '71282',
  'north':   48.8826541,
  'east':    9.0639132,
  'south':   48.8529458,
  'west':    8.9832758
}, {'zipCode': '71287', 'north': 48.8676472, 'east': 8.9659902, 'south': 48.8161525, 'west': 8.871483300000001}, {
  'zipCode': '71292',
  'north':   48.8453932,
  'east':    8.8599481,
  'south':   48.8151168,
  'west':    8.7783957
}, {'zipCode': '71296', 'north': 48.8370006, 'east': 8.9021871, 'south': 48.7922826, 'west': 8.8381058}, {
  'zipCode': '71297',
  'north':   48.8804721,
  'east':    8.9023282,
  'south':   48.8353665,
  'west':    8.8361875
}, {'zipCode': '71299', 'north': 48.8677082, 'east': 8.8500547, 'south': 48.8423025, 'west': 8.7757549}, {
  'zipCode': '71332',
  'north':   48.8431437,
  'east':    9.3482611,
  'south':   48.8105117,
  'west':    9.2858179
}, {'zipCode': '71334', 'north': 48.8726939, 'east': 9.3673119, 'south': 48.8140938, 'west': 9.2758953}, {
  'zipCode': '71336',
  'north':   48.9091159,
  'east':    9.3620767,
  'south':   48.8429121,
  'west':    9.2970944
}, {'zipCode': '71364', 'north': 48.9025469, 'east': 9.4627203, 'south': 48.8433659, 'west': 9.362098}, {
  'zipCode': '71384',
  'north':   48.838368800000005,
  'east':    9.4381426,
  'south':   48.7580735,
  'west':    9.3361081
}, {'zipCode': '71394', 'north': 48.8171078, 'east': 9.3583663, 'south': 48.7654276, 'west': 9.303111200000002}, {
  'zipCode': '71397',
  'north':   48.92152870000001,
  'east':    9.4568432,
  'south':   48.8820975,
  'west':    9.3432762
}, {'zipCode': '71404', 'north': 48.8628506, 'east': 9.4225313, 'south': 48.8238161, 'west': 9.343261800000002}, {
  'zipCode': '71409',
  'north':   48.891343,
  'east':    9.3811187,
  'south':   48.8603492,
  'west':    9.322818
}, {'zipCode': '71522', 'north': 48.9934394, 'east': 9.509182600000003, 'south': 48.901489, 'west': 9.3720761}, {
  'zipCode': '71540',
  'north':   49.0262198,
  'east':    9.6977504,
  'south':   48.9335725,
  'west':    9.519492
}, {'zipCode': '71543', 'north': 49.139348, 'east': 9.5276228, 'south': 49.0466836, 'west': 9.3940949}, {
  'zipCode': '71546',
  'north':   49.0255317,
  'east':    9.4372453,
  'south':   48.948819,
  'west':    9.3255435
}, {'zipCode': '71549', 'north': 48.9729974, 'east': 9.564231, 'south': 48.9185954, 'west': 9.4809722}, {
  'zipCode': '71554',
  'north':   48.9442082,
  'east':    9.5422478,
  'south':   48.897441,
  'west':    9.4568549
}, {'zipCode': '71560', 'north': 49.0473777, 'east': 9.5580413, 'south': 48.9696537, 'west': 9.456521800000003}, {
  'zipCode': '71563',
  'north':   48.934911,
  'east':    9.3795622,
  'south':   48.9046203,
  'west':    9.3077535
}, {'zipCode': '71566', 'north': 48.9587418, 'east': 9.5962156, 'south': 48.8974923, 'west': 9.518656}, {
  'zipCode': '71570',
  'north':   49.025270600000006,
  'east':    9.4981076,
  'south':   48.9641391,
  'west':    9.4183625
}, {'zipCode': '71573', 'north': 48.9224349, 'east': 9.4983656, 'south': 48.8911, 'west': 9.441229300000002}, {
  'zipCode': '71576',
  'north':   48.9413365,
  'east':    9.417704200000001,
  'south':   48.9103076,
  'west':    9.3514253
}, {'zipCode': '71577', 'north': 49.0789343, 'east': 9.6147062, 'south': 49.0128733, 'west': 9.4895271}, {
  'zipCode': '71579',
  'north':   49.075956,
  'east':    9.4990799,
  'south':   49.0169758,
  'west':    9.3803814
}, {'zipCode': '71634', 'north': 48.9243399, 'east': 9.1978552, 'south': 48.8962236, 'west': 9.1373656}, {
  'zipCode': '71636',
  'north':   48.9029577,
  'east':    9.1886693,
  'south':   48.8734228,
  'west':    9.1462193
}, {'zipCode': '71638', 'north': 48.8972247, 'east': 9.2446975, 'south': 48.8763516, 'west': 9.1819976}, {
  'zipCode': '71640',
  'north':   48.9070207,
  'east':    9.2477942,
  'south':   48.8855462,
  'west':    9.1971902
}, {'zipCode': '71642', 'north': 48.9303294, 'east': 9.3124752, 'south': 48.8881379, 'west': 9.1927429}, {
  'zipCode': '71665',
  'north':   49.02149,
  'east':    9.0383741,
  'south':   48.8826443,
  'west':    8.8999088
}, {'zipCode': '71672', 'north': 48.98913580000001, 'east': 9.3717164, 'south': 48.9001802, 'west': 9.2226617}, {
  'zipCode': '71679',
  'north':   48.9208176,
  'east':    9.1628886,
  'south':   48.8914407,
  'west':    9.1228717
}, {'zipCode': '71686', 'north': 48.911379, 'east': 9.3130376, 'south': 48.8463782, 'west': 9.2228717}, {
  'zipCode': '71691',
  'north':   48.9503459,
  'east':    9.2285889,
  'south':   48.915103,
  'west':    9.1513306
}, {'zipCode': '71696', 'north': 48.8985529, 'east': 9.1536998, 'south': 48.8562509, 'west': 9.1063118}, {
  'zipCode': '71701',
  'north':   48.8906284,
  'east':    9.122833,
  'south':   48.8570487,
  'west':    9.0285055
}, {'zipCode': '71706', 'north': 48.9442215, 'east': 9.1265109, 'south': 48.8873747, 'west': 9.0143677}, {
  'zipCode': '71711',
  'north':   48.997182,
  'east':    9.3518877,
  'south':   48.9499126,
  'west':    9.2143887
}, {'zipCode': '71717', 'north': 49.0798206, 'east': 9.4013369, 'south': 49.032106, 'west': 9.286482}, {
  'zipCode': '71720',
  'north':   49.0633839,
  'east':    9.4168693,
  'south':   49.000848,
  'west':    9.2925842
}, {'zipCode': '71723', 'north': 49.0438895, 'east': 9.3415523, 'south': 48.9841558, 'west': 9.2361816}, {
  'zipCode': '71726',
  'north':   48.9540085,
  'east':    9.252236,
  'south':   48.9284854,
  'west':    9.217556
}, {'zipCode': '71729', 'north': 48.9543734, 'east': 9.3167214, 'south': 48.9181109, 'west': 9.2754695}, {
  'zipCode': '71732',
  'north':   48.9391875,
  'east':    9.1584083,
  'south':   48.908531,
  'west':    9.0864889
}, {'zipCode': '71735', 'north': 48.9134347, 'east': 9.0307489, 'south': 48.8545376, 'west': 8.906675}, {
  'zipCode': '71737',
  'north':   48.9666417,
  'east':    9.3819904,
  'south':   48.9281594,
  'west':    9.3067479
}, {'zipCode': '71739', 'north': 48.9498915, 'east': 9.0487717, 'south': 48.9186037, 'west': 8.9970186}, {
  'zipCode': '72070',
  'north':   48.553896,
  'east':    9.0581092,
  'south':   48.4891684,
  'west':    8.9645155
}, {'zipCode': '72072', 'north': 48.5387521, 'east': 9.1300447, 'south': 48.4505844, 'west': 8.9842922}, {
  'zipCode': '72074',
  'north':   48.5937129,
  'east':    9.1310745,
  'south':   48.5177367,
  'west':    9.0319244
}, {'zipCode': '72076', 'north': 48.575801, 'east': 9.0759377, 'south': 48.5235447, 'west': 9.0130889}, {
  'zipCode': '72108',
  'north':   48.5424393,
  'east':    9.0155745,
  'south':   48.3968804,
  'west':    8.7555188
}, {'zipCode': '72116', 'north': 48.4331878, 'east': 9.147813, 'south': 48.3627852, 'west': 8.9971254}, {
  'zipCode': '72119',
  'north':   48.5913235,
  'east':    9.0341928,
  'south':   48.5139393,
  'west':    8.8838166
}, {'zipCode': '72124', 'north': 48.5823318, 'east': 9.2221215, 'south': 48.5454656, 'west': 9.1254371}, {
  'zipCode': '72127',
  'north':   48.5351844,
  'east':    9.1481596,
  'south':   48.4703382,
  'west':    9.0720752
}, {'zipCode': '72131', 'north': 48.4465861, 'east': 9.058275700000001, 'south': 48.398179, 'west': 8.9741799}, {
  'zipCode': '72135',
  'north':   48.6227917,
  'east':    9.1318157,
  'south':   48.5785573,
  'west':    9.0777752
}, {'zipCode': '72138', 'north': 48.5665981, 'east': 9.1758514, 'south': 48.5224365, 'west': 9.1180074}, {
  'zipCode': '72141',
  'north':   48.6059181,
  'east':    9.2073516,
  'south':   48.5732573,
  'west':    9.1187181
}, {'zipCode': '72144', 'north': 48.4857845, 'east': 9.0803015, 'south': 48.4340065, 'west': 9.010072}, {
  'zipCode': '72145',
  'north':   48.4384127,
  'east':    8.9398864,
  'south':   48.3900598,
  'west':    8.865739
}, {'zipCode': '72147', 'north': 48.4469942, 'east': 9.1009988, 'south': 48.4180303, 'west': 9.0434214}, {
  'zipCode': '72149',
  'north':   48.5003625,
  'east':    8.906814500000001,
  'south':   48.4637055,
  'west':    8.8268908
}, {'zipCode': '72160', 'north': 48.5102158, 'east': 8.7780216, 'south': 48.3945993, 'west': 8.5449317}, {
  'zipCode': '72172',
  'north':   48.4109681,
  'east':    8.7448679,
  'south':   48.3125754,
  'west':    8.5441922
}, {'zipCode': '72175', 'north': 48.405387000000005, 'east': 8.5860347, 'south': 48.3167667, 'west': 8.4706276}, {
  'zipCode': '72178',
  'north':   48.5166208,
  'east':    8.6392342,
  'south':   48.4579019,
  'west':    8.5143269
}, {'zipCode': '72181', 'north': 48.460843600000004, 'east': 8.8698593, 'south': 48.4063781, 'west': 8.763431}, {
  'zipCode': '72184',
  'north':   48.5068473,
  'east':    8.8301263,
  'south':   48.43798610000001,
  'west':    8.6894733
}, {'zipCode': '72186', 'north': 48.4166605, 'east': 8.7666023, 'south': 48.3776682, 'west': 8.6762257}, {
  'zipCode': '72189',
  'north':   48.3543299,
  'east':    8.720438,
  'south':   48.2999898,
  'west':    8.6195581
}, {'zipCode': '72202', 'north': 48.603054300000004, 'east': 8.7732994, 'south': 48.483931, 'west': 8.6718932}, {
  'zipCode': '72213',
  'north':   48.6556942,
  'east':    8.6780923,
  'south':   48.5446589,
  'west':    8.5387803
}, {'zipCode': '72218', 'north': 48.6800602, 'east': 8.8194594, 'south': 48.5941452, 'west': 8.639064}, {
  'zipCode': '72221',
  'north':   48.5643834,
  'east':    8.6867506,
  'south':   48.49987540000001,
  'west':    8.5980675
}, {'zipCode': '72224', 'north': 48.6419759, 'east': 8.7282046, 'south': 48.5752306, 'west': 8.631216}, {
  'zipCode': '72226',
  'north':   48.6615126,
  'east':    8.5704257,
  'south':   48.5860973,
  'west':    8.4491698
}, {'zipCode': '72227', 'north': 48.5825757, 'east': 8.6453527, 'south': 48.542071, 'west': 8.5857374}, {
  'zipCode': '72229',
  'north':   48.5777605,
  'east':    8.7031618,
  'south':   48.558042900000004,
  'west':    8.6692794
}, {'zipCode': '72250', 'north': 48.560767, 'east': 8.4911419, 'south': 48.4203185, 'west': 8.242128}, {
  'zipCode': '72270',
  'north':   48.6232327,
  'east':    8.431224,
  'south':   48.4744332,
  'west':    8.2064786
}, {'zipCode': '72275', 'north': 48.4412962, 'east': 8.502316, 'south': 48.3005127, 'west': 8.3263705}, {
  'zipCode': '72280',
  'north':   48.5228809,
  'east':    8.5482271,
  'south':   48.453423,
  'west':    8.4561633
}, {'zipCode': '72285', 'north': 48.5571618, 'east': 8.6286021, 'south': 48.4978229, 'west': 8.4710551}, {
  'zipCode': '72290',
  'north':   48.4400377,
  'east':    8.5241251,
  'south':   48.3432649,
  'west':    8.3674954
}, {'zipCode': '72293', 'north': 48.460785, 'east': 8.5471208, 'south': 48.4006069, 'west': 8.4524553}, {
  'zipCode': '72294',
  'north':   48.5887506,
  'east':    8.5629895,
  'south':   48.5484272,
  'west':    8.4915434
}, {'zipCode': '72296', 'north': 48.4716783, 'east': 8.5790997, 'south': 48.3993409, 'west': 8.525008900000001}, {
  'zipCode': '72297',
  'north':   48.639428,
  'east':    8.5351587,
  'south':   48.5160144,
  'west':    8.3814691
}, {'zipCode': '72299', 'north': 48.576741, 'east': 8.5781478, 'south': 48.5439759, 'west': 8.5474703}, {
  'zipCode': '72336',
  'north':   48.3265796,
  'east':    8.9472737,
  'south':   48.2136696,
  'west':    8.7679116
}, {'zipCode': '72348', 'north': 48.3445935, 'east': 8.7670101, 'south': 48.2295676, 'west': 8.6487384}, {
  'zipCode': '72351',
  'north':   48.3358145,
  'east':    8.8310368,
  'south':   48.2570589,
  'west':    8.7330343
}, {'zipCode': '72355', 'north': 48.2339265, 'east': 8.8070295, 'south': 48.1595688, 'west': 8.7193406}, {
  'zipCode': '72356',
  'north':   48.2581613,
  'east':    8.7633354,
  'south':   48.2289156,
  'west':    8.7288608
}, {'zipCode': '72358', 'north': 48.2605027, 'east': 8.7976298, 'south': 48.222281, 'west': 8.7568403}, {
  'zipCode': '72359',
  'north':   48.2450833,
  'east':    8.821358,
  'south':   48.2006188,
  'west':    8.7637855
}, {'zipCode': '72361', 'north': 48.2218618, 'east': 8.8554559, 'south': 48.1795178, 'west': 8.8137835}, {
  'zipCode': '72362',
  'north':   48.153149,
  'east':    8.9559013,
  'south':   48.1035592,
  'west':    8.8622511
}, {'zipCode': '72364', 'north': 48.1912213, 'east': 8.8940967, 'south': 48.1400239, 'west': 8.8145323}, {
  'zipCode': '72365',
  'north':   48.2036709,
  'east':    8.8309178,
  'south':   48.1793003,
  'west':    8.7841836
}, {'zipCode': '72367', 'north': 48.197386400000006, 'east': 8.7923124, 'south': 48.17959950000001, 'west': 8.7559029}, {
  'zipCode': '72369',
  'north':   48.2324333,
  'east':    8.7394807,
  'south':   48.2070495,
  'west':    8.7021476
}, {'zipCode': '72379', 'north': 48.3908045, 'east': 9.0622175, 'south': 48.3001104, 'west': 8.8907469}, {
  'zipCode': '72393',
  'north':   48.3766716,
  'east':    9.221655,
  'south':   48.2519685,
  'west':    9.0246383
}, {'zipCode': '72401', 'north': 48.417492, 'east': 8.8634428, 'south': 48.3139447, 'west': 8.7320054}, {
  'zipCode': '72406',
  'north':   48.344611,
  'east':    8.981266,
  'south':   48.2763684,
  'west':    8.8721105
}, {'zipCode': '72411', 'north': 48.4166663, 'east': 9.0038642, 'south': 48.3807678, 'west': 8.9345015}, {
  'zipCode': '72414',
  'north':   48.4173987,
  'east':    8.9269981,
  'south':   48.3585729,
  'west':    8.8298763
}, {'zipCode': '72415', 'north': 48.3639385, 'east': 8.9192595, 'south': 48.3152374, 'west': 8.8492595}, {
  'zipCode': '72417',
  'north':   48.344958,
  'east':    9.0685911,
  'south':   48.3093234,
  'west':    9.0148239
}, {'zipCode': '72419', 'north': 48.2699241, 'east': 9.2014529, 'south': 48.2092948, 'west': 9.1090282}, {
  'zipCode': '72458',
  'north':   48.2286749,
  'east':    9.0806102,
  'south':   48.15921960000001,
  'west':    8.9757522
}, {'zipCode': '72459', 'north': 48.2826736, 'east': 8.9961354, 'south': 48.1920458, 'west': 8.8834636}, {
  'zipCode': '72461',
  'north':   48.315199,
  'east':    9.0806477,
  'south':   48.2225236,
  'west':    8.955225
}, {'zipCode': '72469', 'north': 48.2224557, 'east': 9.0107518, 'south': 48.1194426, 'west': 8.845324}, {
  'zipCode': '72474',
  'north':   48.236339,
  'east':    9.1885668,
  'south':   48.1337968,
  'west':    9.0798495
}, {'zipCode': '72475', 'north': 48.2565067, 'east': 9.1188436, 'south': 48.2220698, 'west': 9.0686249}, {
  'zipCode': '72477',
  'north':   48.1452603,
  'east':    9.0272593,
  'south':   48.0863532,
  'west':    8.9504508
}, {'zipCode': '72479', 'north': 48.2217546, 'east': 9.114380100000002, 'south': 48.1487553, 'west': 9.0327166}, {
  'zipCode': '72488',
  'north':   48.154227,
  'east':    9.2724926,
  'south':   48.0279343,
  'west':    9.0841246
}, {'zipCode': '72501', 'north': 48.288844, 'east': 9.3506416, 'south': 48.2202793, 'west': 9.1825517}, {
  'zipCode': '72505',
  'north':   48.0407863,
  'east':    9.2979347,
  'south':   47.9719782,
  'west':    9.1746283
}, {'zipCode': '72510', 'north': 48.1649933, 'east': 9.1647493, 'south': 48.0860784, 'west': 8.9987307}, {
  'zipCode': '72511',
  'north':   48.157635,
  'east':    9.3282037,
  'south':   48.0863915,
  'west':    9.2196675
}, {'zipCode': '72513', 'north': 48.2351471, 'east': 9.3203804, 'south': 48.1530084, 'west': 9.191334}, {
  'zipCode': '72514',
  'north':   48.0869089,
  'east':    9.1907055,
  'south':   48.0213294,
  'west':    9.1082444
}, {'zipCode': '72516', 'north': 48.1119593, 'east': 9.3673267, 'south': 48.0480775, 'west': 9.2693299}, {
  'zipCode': '72517',
  'north':   48.0908401,
  'east':    9.2925217,
  'south':   48.0517894,
  'west':    9.2302441
}, {'zipCode': '72519', 'north': 48.2130998, 'east': 9.2562266, 'south': 48.147361, 'west': 9.1731724}, {
  'zipCode': '72525',
  'north':   48.4771487,
  'east':    9.6374716,
  'south':   48.3063511,
  'west':    9.4103751
}, {'zipCode': '72531', 'north': 48.3762612, 'east': 9.4590697, 'south': 48.2975501, 'west': 9.2796799}, {
  'zipCode': '72532',
  'north':   48.4253072,
  'east':    9.4615511,
  'south':   48.3506223,
  'west':    9.3303766
}, {'zipCode': '72534', 'north': 48.339059000000006, 'east': 9.5581235, 'south': 48.2445907, 'west': 9.3940872}, {
  'zipCode': '72535',
  'north':   48.4690442,
  'east':    9.7085486,
  'south':   48.413527,
  'west':    9.6188166
}, {'zipCode': '72537', 'north': 48.3954523, 'east': 9.6050156, 'south': 48.354362, 'west': 9.5290005}, {
  'zipCode': '72539',
  'north':   48.3168245,
  'east':    9.4341441,
  'south':   48.2221622,
  'west':    9.3322114
}, {'zipCode': '72555', 'north': 48.5619654, 'east': 9.3454482, 'south': 48.4925539, 'west': 9.2383084}, {
  'zipCode': '72574',
  'north':   48.5337084,
  'east':    9.4876933,
  'south':   48.436294,
  'west':    9.3450911
}, {'zipCode': '72581', 'north': 48.5492247, 'east': 9.4027444, 'south': 48.5042869, 'west': 9.3173583}, {
  'zipCode': '72582',
  'north':   48.5466189,
  'east':    9.4902797,
  'south':   48.4991724,
  'west':    9.4162222
}, {'zipCode': '72584', 'north': 48.5390234, 'east': 9.425119, 'south': 48.5046517, 'west': 9.3786597}, {
  'zipCode': '72585',
  'north':   48.5696917,
  'east':    9.2897818,
  'south':   48.5448423,
  'west':    9.2395893
}, {'zipCode': '72587', 'north': 48.5399905, 'east': 9.5928666, 'south': 48.4701395, 'west': 9.4719868}, {
  'zipCode': '72589',
  'north':   48.541365,
  'east':    9.6632028,
  'south':   48.4894922,
  'west':    9.5800585
}, {'zipCode': '72622', 'north': 48.6565781, 'east': 9.4169838, 'south': 48.576354, 'west': 9.2799198}, {
  'zipCode': '72631',
  'north':   48.65115,
  'east':    9.2941569,
  'south':   48.6045609,
  'west':    9.1440028
}, {'zipCode': '72636', 'north': 48.6061906, 'east': 9.4009298, 'south': 48.5695561, 'west': 9.317229}, {
  'zipCode': '72639',
  'north':   48.5775657,
  'east':    9.4086431,
  'south':   48.5307636,
  'west':    9.3139806
}, {'zipCode': '72644', 'north': 48.6608609, 'east': 9.4002823, 'south': 48.6324124, 'west': 9.3537607}, {
  'zipCode': '72649',
  'north':   48.6664911,
  'east':    9.318517,
  'south':   48.6418982,
  'west':    9.262535
}, {'zipCode': '72654', 'north': 48.6028775, 'east': 9.2653914, 'south': 48.5663179, 'west': 9.2073414}, {
  'zipCode': '72655',
  'north':   48.6022726,
  'east':    9.2920283,
  'south':   48.5820976,
  'west':    9.2579444
}, {'zipCode': '72657', 'north': 48.6044491, 'east': 9.2351205, 'south': 48.5803423, 'west': 9.1638727}, {
  'zipCode': '72658',
  'north':   48.5893075,
  'east':    9.302212400000002,
  'south':   48.5651801,
  'west':    9.2500154
}, {'zipCode': '72660', 'north': 48.6076335, 'east': 9.432986500000002, 'south': 48.5533442, 'west': 9.372935}, {
  'zipCode': '72661',
  'north':   48.5810275,
  'east':    9.3225023,
  'south':   48.5583523,
  'west':    9.2868208
}, {'zipCode': '72663', 'north': 48.5990487, 'east': 9.3231777, 'south': 48.5764412, 'west': 9.2804571}, {
  'zipCode': '72664',
  'north':   48.571404400000006,
  'east':    9.3515963,
  'south':   48.55045470000001,
  'west':    9.3111476
}, {'zipCode': '72666', 'north': 48.6234094, 'east': 9.296179400000002, 'south': 48.5970234, 'west': 9.23568}, {
  'zipCode': '72667',
  'north':   48.6222278,
  'east':    9.253,
  'south':   48.5972736,
  'west':    9.1797556
}, {'zipCode': '72669', 'north': 48.6731223, 'east': 9.3654147, 'south': 48.6475061, 'west': 9.3091494}, {
  'zipCode': '72760',
  'north':   48.5303438,
  'east':    9.2184495,
  'south':   48.495784500000006,
  'west':    9.1874003
}, {'zipCode': '72762', 'north': 48.499619200000005, 'east': 9.2168226, 'south': 48.4594738, 'west': 9.1708239}, {
  'zipCode': '72764',
  'north':   48.5006878,
  'east':    9.2347427,
  'south':   48.4728117,
  'west':    9.2043338
}, {'zipCode': '72766', 'north': 48.5786138, 'east': 9.2555868, 'south': 48.4754628, 'west': 9.2120062}, {
  'zipCode': '72768',
  'north':   48.5563714,
  'east':    9.2283285,
  'south':   48.51131480000001,
  'west':    9.1637563
}, {'zipCode': '72770', 'north': 48.5155014, 'east': 9.209302900000003, 'south': 48.4126792, 'west': 9.1148655}, {
  'zipCode': '72793',
  'north':   48.4844894,
  'east':    9.299818900000002,
  'south':   48.4242141,
  'west':    9.1680198
}, {'zipCode': '72800', 'north': 48.5106202, 'east': 9.3224732, 'south': 48.4556075, 'west': 9.2303754}, {
  'zipCode': '72805',
  'north':   48.4622283,
  'east':    9.3160028,
  'south':   48.387427900000006,
  'west':    9.2168084
}, {'zipCode': '72810', 'north': 48.4832594, 'east': 9.139475300000003, 'south': 48.4266128, 'west': 9.0651525}, {
  'zipCode': '72813',
  'north':   48.5139422,
  'east':    9.4177817,
  'south':   48.4090024,
  'west':    9.2937001
}, {'zipCode': '72818', 'north': 48.3652202, 'east': 9.3461482, 'south': 48.2525962, 'west': 9.1607382}, {
  'zipCode': '72820',
  'north':   48.4313556,
  'east':    9.2501512,
  'south':   48.333124000000005,
  'west':    9.1172962
}, {'zipCode': '72827', 'north': 48.5273287, 'east': 9.1701637, 'south': 48.4996682, 'west': 9.136121}, {
  'zipCode': '72829',
  'north':   48.4231891,
  'east':    9.3462207,
  'south':   48.3587854,
  'west':    9.2397929
}, {'zipCode': '73033', 'north': 48.7173968, 'east': 9.6787918, 'south': 48.6988, 'west': 9.6262018}, {
  'zipCode': '73035',
  'north':   48.7492181,
  'east':    9.696224400000002,
  'south':   48.6549775,
  'west':    9.5912214
}, {'zipCode': '73037', 'north': 48.7762403, 'east': 9.752372, 'south': 48.6617791, 'west': 9.6451076}, {
  'zipCode': '73054',
  'north':   48.7279902,
  'east':    9.7533837,
  'south':   48.6736093,
  'west':    9.6811546
}, {'zipCode': '73061', 'north': 48.7492251, 'east': 9.5736246, 'south': 48.6777629, 'west': 9.4834097}, {
  'zipCode': '73066',
  'north':   48.7623939,
  'east':    9.61225,
  'south':   48.6708269,
  'west':    9.5022623
}, {'zipCode': '73072', 'north': 48.7355156, 'east': 9.8681052, 'south': 48.6323946, 'west': 9.760290600000001}, {
  'zipCode': '73079',
  'north':   48.6925146,
  'east':    9.7865519,
  'south':   48.6511038,
  'west':    9.7116894
}, {'zipCode': '73084', 'north': 48.7161564, 'east': 9.7794306, 'south': 48.6819618, 'west': 9.7238261}, {
  'zipCode': '73087',
  'north':   48.6600238,
  'east':    9.6331981,
  'south':   48.6185735,
  'west':    9.5780876
}, {'zipCode': '73092', 'north': 48.6859524, 'east': 9.6879468, 'south': 48.6277166, 'west': 9.625053500000002}, {
  'zipCode': '73095',
  'north':   48.7044072,
  'east':    9.5787749,
  'south':   48.671506,
  'west':    9.534655
}, {'zipCode': '73098', 'north': 48.7473423, 'east': 9.6739817, 'south': 48.7189887, 'west': 9.6209816}, {
  'zipCode': '73099',
  'north':   48.7810517,
  'east':    9.6224955,
  'south':   48.74370280000001,
  'west':    9.5708463
}, {'zipCode': '73101', 'north': 48.6412281, 'east': 9.5946337, 'south': 48.6163626, 'west': 9.5348921}, {
  'zipCode': '73102',
  'north':   48.7612109,
  'east':    9.6756259,
  'south':   48.739416000000006,
  'west':    9.6487485
}, {'zipCode': '73104', 'north': 48.7745299, 'east': 9.6587515, 'south': 48.7421475, 'west': 9.6129471}, {
  'zipCode': '73105',
  'north':   48.65859510000001,
  'east':    9.6481419,
  'south':   48.6231371,
  'west':    9.6212429
}, {'zipCode': '73107', 'north': 48.6653315, 'east': 9.6886352, 'south': 48.633685, 'west': 9.6598481}, {
  'zipCode': '73108',
  'north':   48.653708,
  'east':    9.6618243,
  'south':   48.623474,
  'west':    9.6413641
}, {'zipCode': '73110', 'north': 48.6796416, 'east': 9.6010214, 'south': 48.6553069, 'west': 9.5405286}, {
  'zipCode': '73111',
  'north':   48.7538521,
  'east':    9.941493400000002,
  'south':   48.692954900000004,
  'west':    9.8333043
}, {'zipCode': '73113', 'north': 48.7536063, 'east': 9.7831485, 'south': 48.7099229, 'west': 9.7249641}, {
  'zipCode': '73114',
  'north':   48.6731445,
  'east':    9.7342365,
  'south':   48.6338866,
  'west':    9.6857147
}, {'zipCode': '73116', 'north': 48.7790388, 'east': 9.7158426, 'south': 48.7405345, 'west': 9.6521035}, {
  'zipCode': '73117',
  'north':   48.7501809,
  'east':    9.6316615,
  'south':   48.7171245,
  'west':    9.567909000000002
}, {'zipCode': '73119', 'north': 48.6601942, 'east': 9.6028382, 'south': 48.6304337, 'west': 9.5501177}, {
  'zipCode': '73207',
  'north':   48.7425016,
  'east':    9.4526084,
  'south':   48.700375,
  'west':    9.388827900000003
}, {'zipCode': '73230', 'north': 48.6722291, 'east': 9.5241535, 'south': 48.608730300000005, 'west': 9.3918591}, {
  'zipCode': '73235',
  'north':   48.63387,
  'east':    9.5959706,
  'south':   48.5758626,
  'west':    9.4993164
}, {'zipCode': '73240', 'north': 48.6908304, 'east': 9.4223112, 'south': 48.6454331, 'west': 9.3564801}, {
  'zipCode': '73249',
  'north':   48.7092577,
  'east':    9.450965900000002,
  'south':   48.6674366,
  'west':    9.3890943
}, {'zipCode': '73252', 'north': 48.5853898, 'east': 9.5824715, 'south': 48.512357200000004, 'west': 9.436257100000002}, {
  'zipCode': '73257',
  'north':   48.7045543,
  'east':    9.3973355,
  'south':   48.6685656,
  'west':    9.3153461
}, {'zipCode': '73262', 'north': 48.7287108, 'east': 9.4933966, 'south': 48.7018869, 'west': 9.438293}, {
  'zipCode': '73265',
  'north':   48.6323399,
  'east':    9.4797776,
  'south':   48.5931568,
  'west':    9.4025296
}, {'zipCode': '73266', 'north': 48.6107318, 'east': 9.5455681, 'south': 48.5520325, 'west': 9.4704583}, {
  'zipCode': '73268',
  'north':   48.5697409,
  'east':    9.4590822,
  'south':   48.535754,
  'west':    9.4026434
}, {'zipCode': '73269', 'north': 48.7080301, 'east': 9.49381, 'south': 48.6793918, 'west': 9.4412841}, {
  'zipCode': '73271',
  'north':   48.6464487,
  'east':    9.5563803,
  'south':   48.6290655,
  'west':    9.5041389
}, {'zipCode': '73272', 'north': 48.5987164, 'east': 9.596362, 'south': 48.5511707, 'west': 9.5330909}, {
  'zipCode': '73274',
  'north':   48.687103,
  'east':    9.4952388,
  'south':   48.6620783,
  'west':    9.4349273
}, {'zipCode': '73275', 'north': 48.6605747, 'east': 9.554965700000002, 'south': 48.6392114, 'west': 9.506618}, {
  'zipCode': '73277',
  'north':   48.605571,
  'east':    9.4742967,
  'south':   48.5677316,
  'west':    9.4119579
}, {'zipCode': '73278', 'north': 48.6924694, 'east': 9.5528954, 'south': 48.6565239, 'west': 9.4908739}, {
  'zipCode': '73312',
  'north':   48.6724056,
  'east':    9.9452551,
  'south':   48.5565427,
  'west':    9.7301079
}, {'zipCode': '73326', 'north': 48.6392479, 'east': 9.7514598, 'south': 48.5630812, 'west': 9.6871685}, {
  'zipCode': '73329',
  'north':   48.6558521,
  'east':    9.8182016,
  'south':   48.6173596,
  'west':    9.7618326
}, {'zipCode': '73333', 'north': 48.6730271, 'east': 9.8107283, 'south': 48.6403906, 'west': 9.7411556}, {
  'zipCode': '73337',
  'north':   48.6574033,
  'east':    9.8162211,
  'south':   48.5854561,
  'west':    9.7169951
}, {'zipCode': '73340', 'north': 48.631916, 'east': 9.9727822, 'south': 48.5379281, 'west': 9.81737}, {
  'zipCode': '73342',
  'north':   48.6375814,
  'east':    9.7309737,
  'south':   48.5499184,
  'west':    9.6537292
}, {'zipCode': '73344', 'north': 48.6263805, 'east': 9.6781547, 'south': 48.5786052, 'west': 9.5881381}, {
  'zipCode': '73345',
  'north':   48.565765,
  'east':    9.7228958,
  'south':   48.5174609,
  'west':    9.632575
}, {'zipCode': '73347', 'north': 48.5966885, 'east': 9.6803369, 'south': 48.5538561, 'west': 9.6311635}, {
  'zipCode': '73349',
  'north':   48.5861011,
  'east':    9.6527995,
  'south':   48.5367054,
  'west':    9.5603118
}, {'zipCode': '73430', 'north': 48.8554465, 'east': 10.0991179, 'south': 48.82944040000001, 'west': 10.0650674}, {
  'zipCode': '73431',
  'north':   48.8537402,
  'east':    10.1388387,
  'south':   48.7962939,
  'west':    10.0462473
}, {'zipCode': '73432', 'north': 48.8655481, 'east': 10.2779161, 'south': 48.7481716, 'west': 10.09139}, {
  'zipCode': '73433',
  'north':   48.8960158,
  'east':    10.1587877,
  'south':   48.849515,
  'west':    10.0507219
}, {'zipCode': '73434', 'north': 48.8987785, 'east': 10.0725849, 'south': 48.8249156, 'west': 9.9714036}, {
  'zipCode': '73441',
  'north':   48.9214941,
  'east':    10.4294562,
  'south':   48.8116614,
  'west':    10.2601807
}, {'zipCode': '73447', 'north': 48.8089863, 'east': 10.1484558, 'south': 48.753288, 'west': 10.0564492}, {
  'zipCode': '73450',
  'north':   48.826512,
  'east':    10.4484984,
  'south':   48.7229664,
  'west':    10.2039872
}, {'zipCode': '73453', 'north': 48.9548771, 'east': 10.0478561, 'south': 48.8564474, 'west': 9.866058}, {
  'zipCode': '73457',
  'north':   48.8492041,
  'east':    10.0833664,
  'south':   48.746045800000005,
  'west':    9.9618403
}, {'zipCode': '73460', 'north': 48.929849100000006, 'east': 10.126057, 'south': 48.875769, 'west': 10.0582826}, {
  'zipCode': '73463',
  'north':   48.9288791,
  'east':    10.2946486,
  'south':   48.8540115,
  'west':    10.139799300000002
}, {'zipCode': '73466', 'north': 48.9070455, 'east': 10.326778, 'south': 48.8357247, 'west': 10.1978171}, {
  'zipCode': '73467',
  'north':   48.9075027,
  'east':    10.4539054,
  'south':   48.8515774,
  'west':    10.3588023
}, {'zipCode': '73469', 'north': 48.8858045, 'east': 10.4539305, 'south': 48.80739260000001, 'west': 10.3870619}, {
  'zipCode': '73479',
  'north':   49.0255744,
  'east':    10.3074333,
  'south':   48.9041982,
  'west':    10.0515584
}, {'zipCode': '73485', 'north': 48.9698737, 'east': 10.4569049, 'south': 48.8929319, 'west': 10.2715074}, {
  'zipCode': '73486',
  'north':   48.9820857,
  'east':    10.0221232,
  'south':   48.9399915,
  'west':    9.8905763
}, {'zipCode': '73488', 'north': 49.0480279, 'east': 10.2720238, 'south': 48.9810403, 'west': 10.1665661}, {
  'zipCode': '73489',
  'north':   49.0608132,
  'east':    10.1917252,
  'south':   48.9998653,
  'west':    10.043924
}, {'zipCode': '73491', 'north': 48.9784804, 'east': 10.122188200000002, 'south': 48.8955877, 'west': 10.0039729}, {
  'zipCode': '73492',
  'north':   48.9484067,
  'east':    10.2038957,
  'south':   48.8886868,
  'west':    10.1127229
}, {'zipCode': '73494', 'north': 49.0523822, 'east': 10.1002209, 'south': 48.9676413, 'west': 9.9729808}, {
  'zipCode': '73495',
  'north':   49.0320398,
  'east':    10.3828787,
  'south':   48.9699274,
  'west':    10.2533696
}, {'zipCode': '73497', 'north': 48.99688900000001, 'east': 10.4251887, 'south': 48.9580715, 'west': 10.300391500000002}, {
  'zipCode': '73499',
  'north':   49.0604201,
  'east':    10.3211421,
  'south':   49.0070147,
  'west':    10.2211362
}, {'zipCode': '73525', 'north': 48.8065298, 'east': 9.8360229, 'south': 48.7822778, 'west': 9.76286}, {
  'zipCode': '73527',
  'north':   48.8801212,
  'east':    9.8972915,
  'south':   48.78561950000001,
  'west':    9.7194233
}, {'zipCode': '73529', 'north': 48.8102807, 'east': 9.9435797, 'south': 48.7145541, 'west': 9.7137136}, {
  'zipCode': '73540',
  'north':   48.8196493,
  'east':    9.9965794,
  'south':   48.7541865,
  'west':    9.8938823
}, {'zipCode': '73547', 'north': 48.828706200000006, 'east': 9.7311914, 'south': 48.7703412, 'west': 9.6189511}, {
  'zipCode': '73550',
  'north':   48.7858216,
  'east':    9.8623573,
  'south':   48.7168788,
  'west':    9.7877488
}, {'zipCode': '73553', 'north': 48.9151263, 'east': 9.7694064, 'south': 48.8143379, 'west': 9.6391262}, {
  'zipCode': '73557',
  'north':   48.8532994,
  'east':    9.8121194,
  'south':   48.8122405,
  'west':    9.7531356
}, {'zipCode': '73560', 'north': 48.8418337, 'east': 9.951773000000001, 'south': 48.8001708, 'west': 9.8907512}, {
  'zipCode': '73563',
  'north':   48.8455274,
  'east':    10.0042817,
  'south':   48.8067903,
  'west':    9.9327883
}, {'zipCode': '73565', 'north': 48.9090037, 'east': 9.8002179, 'south': 48.860516, 'west': 9.7373766}, {
  'zipCode': '73566',
  'north':   48.7660084,
  'east':    10.0486262,
  'south':   48.7251351,
  'west':    9.9077599
}, {'zipCode': '73568', 'north': 48.8763293, 'east': 9.821749, 'south': 48.8399591, 'west': 9.7562879}, {
  'zipCode': '73569',
  'north':   48.9238824,
  'east':    9.9334835,
  'south':   48.8683475,
  'west':    9.800326
}, {'zipCode': '73571', 'north': 48.8783692, 'east': 9.9320353, 'south': 48.8408359, 'west': 9.8594467}, {
  'zipCode': '73572',
  'north':   48.862879,
  'east':    9.9853079,
  'south':   48.8339059,
  'west':    9.917508
}, {'zipCode': '73574', 'north': 48.8502888, 'east': 9.9254915, 'south': 48.8182302, 'west': 9.8450316}, {
  'zipCode': '73575',
  'north':   48.8564775,
  'east':    9.8869701,
  'south':   48.8435154,
  'west':    9.8502364
}, {'zipCode': '73577', 'north': 48.9130762, 'east': 9.8527172, 'south': 48.8671629, 'west': 9.7632957}, {
  'zipCode': '73579',
  'north':   48.8909527,
  'east':    9.9644384,
  'south':   48.858501,
  'west':    9.8940125
}, {'zipCode': '73614', 'north': 48.8542558, 'east': 9.5873122, 'south': 48.7403038, 'west': 9.4731035}, {
  'zipCode': '73630',
  'north':   48.8456536,
  'east':    9.4778073,
  'south':   48.788578,
  'west':    9.4037873
}, {'zipCode': '73635', 'north': 48.9135892, 'east': 9.6014377, 'south': 48.8485611, 'west': 9.478069}, {
  'zipCode': '73642',
  'north':   48.9239029,
  'east':    9.695367,
  'south':   48.840277,
  'west':    9.5574731
}, {'zipCode': '73650', 'north': 48.8231133, 'east': 9.5053339, 'south': 48.7652723, 'west': 9.4342031}, {
  'zipCode': '73655',
  'north':   48.8453927,
  'east':    9.6522846,
  'south':   48.7702145,
  'west':    9.553602
}, {'zipCode': '73660', 'north': 48.8536945, 'east': 9.6028967, 'south': 48.7967176, 'west': 9.5455169}, {
  'zipCode': '73663',
  'north':   48.8926854,
  'east':    9.5141865,
  'south':   48.8203635,
  'west':    9.4257011
}, {'zipCode': '73666', 'north': 48.7776356, 'east': 9.4774195, 'south': 48.72193250000001, 'west': 9.4124422}, {
  'zipCode': '73667',
  'north':   48.9469836,
  'east':    9.7008732,
  'south':   48.8932294,
  'west':    9.586484800000001
}, {'zipCode': '73669', 'north': 48.7741865, 'east': 9.5107705, 'south': 48.7242115, 'west': 9.4638429}, {
  'zipCode': '73728',
  'north':   48.7521031,
  'east':    9.3284296,
  'south':   48.7298395,
  'west':    9.2821582
}, {'zipCode': '73730', 'north': 48.7620056, 'east': 9.4165802, 'south': 48.7121237, 'west': 9.316032900000002}, {
  'zipCode': '73732',
  'north':   48.7757682,
  'east':    9.3564548,
  'south':   48.7390267,
  'west':    9.2965788
}, {'zipCode': '73733', 'north': 48.7728947, 'east': 9.3062434, 'south': 48.7298564, 'west': 9.2562418}, {
  'zipCode': '73734',
  'north':   48.742347,
  'east':    9.3537666,
  'south':   48.7065634,
  'west':    9.277597
}, {'zipCode': '73760', 'north': 48.7443022, 'east': 9.3119378, 'south': 48.6946689, 'west': 9.21151}, {
  'zipCode': '73765',
  'north':   48.7003875,
  'east':    9.31706,
  'south':   48.6601628,
  'west':    9.2449127
}, {'zipCode': '73770', 'north': 48.713383, 'east': 9.3715477, 'south': 48.6672023, 'west': 9.2874694}, {
  'zipCode': '73773',
  'north':   48.7881384,
  'east':    9.419717800000003,
  'south':   48.7437175,
  'west':    9.3439524
}, {'zipCode': '73776', 'north': 48.7397656, 'east': 9.3973872, 'south': 48.7158345, 'west': 9.3609246}, {
  'zipCode': '73779',
  'north':   48.7200441,
  'east':    9.4121255,
  'south':   48.6999555,
  'west':    9.3589433
}, {'zipCode': '74072', 'north': 49.1470454, 'east': 9.2310494, 'south': 49.1317576, 'west': 9.1988517}, {
  'zipCode': '74074',
  'north':   49.1456657,
  'east':    9.3019672,
  'south':   49.1084028,
  'west':    9.2021944
}, {'zipCode': '74076', 'north': 49.1878979, 'east': 9.2665057, 'south': 49.139361, 'west': 9.2007054}, {
  'zipCode': '74078',
  'north':   49.2097276,
  'east':    9.2083546,
  'south':   49.1452707,
  'west':    9.0446088
}, {'zipCode': '74080', 'north': 49.1572531, 'east': 9.2034166, 'south': 49.1199065, 'west': 9.1302405}, {
  'zipCode': '74081',
  'north':   49.1331951,
  'east':    9.2577566,
  'south':   49.0928882,
  'west':    9.130632
}, {'zipCode': '74172', 'north': 49.2198386, 'east': 9.3187421, 'south': 49.1695634, 'west': 9.1596456}, {
  'zipCode': '74177',
  'north':   49.2766325,
  'east':    9.267763,
  'south':   49.2043606,
  'west':    9.1772184
}, {'zipCode': '74182', 'north': 49.1592448, 'east': 9.4481066, 'south': 49.1016975, 'west': 9.332464100000001}, {
  'zipCode': '74189',
  'north':   49.1910023,
  'east':    9.3764779,
  'south':   49.117532,
  'west':    9.2543336
}, {'zipCode': '74193', 'north': 49.1958519, 'east': 9.0912498, 'south': 49.0956105, 'west': 8.9450352}, {
  'zipCode': '74196',
  'north':   49.2793487,
  'east':    9.3689922,
  'south':   49.1869444,
  'west':    9.2630347
}, {'zipCode': '74199', 'north': 49.1166547, 'east': 9.3773096, 'south': 49.0740764, 'west': 9.2448818}, {
  'zipCode': '74206',
  'north':   49.2512855,
  'east':    9.2038163,
  'south':   49.2065716,
  'west':    9.1094435
}, {'zipCode': '74211', 'north': 49.1712667, 'east': 9.1606297, 'south': 49.1182106, 'west': 9.0716351}, {
  'zipCode': '74214',
  'north':   49.3969476,
  'east':    9.6395573,
  'south':   49.2958221,
  'west':    9.4398899
}, {'zipCode': '74219', 'north': 49.36633890000001, 'east': 9.4175782, 'south': 49.2775314, 'west': 9.2874669}, {
  'zipCode': '74223',
  'north':   49.1129684,
  'east':    9.2605559,
  'south':   49.087412,
  'west':    9.1890134
}, {'zipCode': '74226', 'north': 49.1255083, 'east': 9.1511069, 'south': 49.0966748, 'west': 9.0686891}, {
  'zipCode': '74229',
  'north':   49.2631864,
  'east':    9.3126451,
  'south':   49.2073561,
  'west':    9.2272504
}, {'zipCode': '74232', 'north': 49.0923804, 'east': 9.3403255, 'south': 49.0602426, 'west': 9.2721215}, {
  'zipCode': '74235',
  'north':   49.2037035,
  'east':    9.294869400000001,
  'south':   49.1595757,
  'west':    9.2351878
}, {'zipCode': '74238', 'north': 49.4308306, 'east': 9.7074375, 'south': 49.3439015, 'west': 9.5699531}, {
  'zipCode': '74239',
  'north':   49.3000625,
  'east':    9.4450606,
  'south':   49.2316667,
  'west':    9.3463597
}, {'zipCode': '74243', 'north': 49.238071, 'east': 9.4529946, 'south': 49.18366000000001, 'west': 9.3521109}, {
  'zipCode': '74245',
  'north':   49.120283,
  'east':    9.4534259,
  'south':   49.0693783,
  'west':    9.3364758
}, {'zipCode': '74246', 'north': 49.1998821, 'east': 9.3698828, 'south': 49.1647985, 'west': 9.2930751}, {
  'zipCode': '74248',
  'north':   49.1597124,
  'east':    9.3399734,
  'south':   49.12450090000001,
  'west':    9.3059668
}, {'zipCode': '74249', 'north': 49.3372061, 'east': 9.5201837, 'south': 49.287025, 'west': 9.4263218}, {
  'zipCode': '74251',
  'north':   49.137944,
  'east':    9.3426756,
  'south':   49.1067081,
  'west':    9.2999754
}, {'zipCode': '74252', 'north': 49.2005625, 'east': 9.0572881, 'south': 49.1646104, 'west': 9.0098719}, {
  'zipCode': '74254',
  'north':   49.2648167,
  'east':    9.1888856,
  'south':   49.2306885,
  'west':    9.1572576
}, {'zipCode': '74255', 'north': 49.3876176, 'east': 9.3753697, 'south': 49.3439577, 'west': 9.3092601}, {
  'zipCode': '74257',
  'north':   49.2183507,
  'east':    9.217107,
  'south':   49.2010621,
  'west':    9.1710958
}, {'zipCode': '74259', 'north': 49.3645395, 'east': 9.4673388, 'south': 49.2940531, 'west': 9.3883786}, {
  'zipCode': '74321',
  'north':   48.9840387,
  'east':    9.1666674,
  'south':   48.9242646,
  'west':    9.0675527
}, {'zipCode': '74336', 'north': 49.1184536, 'east': 9.125894200000001, 'south': 49.0450224, 'west': 8.9905451}, {
  'zipCode': '74343',
  'north':   49.0448671,
  'east':    9.0945107,
  'south':   48.9416484,
  'west':    8.8759239
}, {'zipCode': '74348', 'north': 49.1085039, 'east': 9.2088064, 'south': 49.0513617, 'west': 9.1147767}, {
  'zipCode': '74354',
  'north':   49.0338057,
  'east':    9.2346687,
  'south':   48.9753921,
  'west':    9.1150388
}, {'zipCode': '74357', 'north': 49.0625133, 'east': 9.1355024, 'south': 49.0081509, 'west': 9.0149127}, {
  'zipCode': '74360',
  'north':   49.084820900000004,
  'east':    9.3205278,
  'south':   49.0273072,
  'west':    9.2023309
}, {'zipCode': '74363', 'north': 49.0911278, 'east': 9.0484676, 'south': 49.0366347, 'west': 8.9704883}, {
  'zipCode': '74366',
  'north':   49.0648513,
  'east':    9.1744082,
  'south':   49.0232149,
  'west':    9.100672
}, {'zipCode': '74369', 'north': 49.0103526, 'east': 9.13206, 'south': 48.979088000000004, 'west': 9.0586008}, {
  'zipCode': '74372',
  'north':   48.9824142,
  'east':    9.0406328,
  'south':   48.9467964,
  'west':    8.9818672
}, {'zipCode': '74374', 'north': 49.0845073, 'east': 8.9507307, 'south': 49.0354407, 'west': 8.8649261}, {
  'zipCode': '74376',
  'north':   49.0496938,
  'east':    9.1890216,
  'south':   49.00468860000001,
  'west':    9.1481855
}, {'zipCode': '74379', 'north': 48.993762, 'east': 9.2159771, 'south': 48.9444763, 'west': 9.1501152}, {
  'zipCode': '74382',
  'north':   49.0630424,
  'east':    9.2563369,
  'south':   49.0203349,
  'west':    9.1660758
}, {'zipCode': '74385', 'north': 48.9766914, 'east': 9.2356658, 'south': 48.9422726, 'west': 9.1837121}, {
  'zipCode': '74388',
  'north':   49.1050012,
  'east':    9.2488506,
  'south':   49.0683842,
  'west':    9.1613438
}, {'zipCode': '74389', 'north': 49.0684373, 'east': 9.0642083, 'south': 49.02102320000001, 'west': 8.9872071}, {
  'zipCode': '74391',
  'north':   49.0326694,
  'east':    9.1126802,
  'south':   49.0074794,
  'west':    9.0569098
}, {'zipCode': '74392', 'north': 49.0164886, 'east': 9.074714, 'south': 49.001298, 'west': 9.0296904}, {
  'zipCode': '74394',
  'north':   49.0161839,
  'east':    9.2014891,
  'south':   48.9849759,
  'west':    9.1684145
}, {'zipCode': '74395', 'north': 49.0225488, 'east': 9.2548763, 'south': 48.9775558, 'west': 9.1918516}, {
  'zipCode': '74397',
  'north':   49.0805176,
  'east':    8.9864585,
  'south':   49.0346977,
  'west':    8.933261
}, {'zipCode': '74399', 'north': 49.0264792, 'east': 9.1603016, 'south': 49.0004747, 'west': 9.1163006}, {
  'zipCode': '74405',
  'north':   49.049201,
  'east':    9.8312041,
  'south':   48.9506769,
  'west':    9.7023038
}, {'zipCode': '74417', 'north': 48.9747318, 'east': 9.845444500000001, 'south': 48.9021652, 'west': 9.6738113}, {
  'zipCode': '74420',
  'north':   49.0552334,
  'east':    9.7108324,
  'south':   48.9915552,
  'west':    9.5957487
}, {'zipCode': '74423', 'north': 49.0819973, 'east': 9.9547739, 'south': 48.9950465, 'west': 9.7945769}, {
  'zipCode': '74424',
  'north':   49.0544293,
  'east':    9.987211300000002,
  'south':   49.0067527,
  'west':    9.8803896
}, {'zipCode': '74426', 'north': 49.0304514, 'east': 10.014429, 'south': 48.9579933, 'west': 9.8480314}, {
  'zipCode': '74427',
  'north':   49.0127108,
  'east':    9.766793,
  'south':   48.9559477,
  'west':    9.6520809
}, {'zipCode': '74429', 'north': 49.000421900000006, 'east': 9.914545, 'south': 48.917504, 'west': 9.7943294}, {
  'zipCode': '74523',
  'north':   49.1668978,
  'east':    9.8754428,
  'south':   49.0459744,
  'west':    9.5901401
}, {'zipCode': '74532', 'north': 49.23355230000001, 'east': 9.9747106, 'south': 49.1118719, 'west': 9.8217801}, {
  'zipCode': '74535',
  'north':   49.1336466,
  'east':    9.6537101,
  'south':   49.0472589,
  'west':    9.4974715
}, {'zipCode': '74538', 'north': 49.0974386, 'east': 9.7577594, 'south': 49.029833, 'west': 9.646057}, {
  'zipCode': '74541',
  'north':   49.1396206,
  'east':    9.9548657,
  'south':   49.075172,
  'west':    9.8398786
}, {'zipCode': '74542', 'north': 49.25824250000001, 'east': 9.8405057, 'south': 49.1578114, 'west': 9.7342031}, {
  'zipCode': '74544',
  'north':   49.0958345,
  'east':    9.8097718,
  'south':   49.0441439,
  'west':    9.7341213
}, {'zipCode': '74545', 'north': 49.1523045, 'east': 9.698075, 'south': 49.0841958, 'west': 9.579495}, {
  'zipCode': '74547',
  'north':   49.1994159,
  'east':    9.7889839,
  'south':   49.1346217,
  'west':    9.688112100000001
}, {'zipCode': '74549', 'north': 49.1929558, 'east': 9.8983951, 'south': 49.1316787, 'west': 9.8023765}, {
  'zipCode': '74564',
  'north':   49.1860658,
  'east':    10.167651,
  'south':   49.0791167,
  'west':    9.9437754
}, {'zipCode': '74572', 'north': 49.3430495, 'east': 10.1231227, 'south': 49.2635061, 'west': 9.8407182}, {
  'zipCode': '74575',
  'north':   49.396061700000004,
  'east':    10.1246346,
  'south':   49.307009,
  'west':    9.8429926
}, {'zipCode': '74579', 'north': 49.0994299, 'east': 10.2654864, 'south': 49.040537, 'west': 10.1587201}, {
  'zipCode': '74582',
  'north':   49.2871268,
  'east':    9.9886237,
  'south':   49.1922989,
  'west':    9.8614584
}, {'zipCode': '74585', 'north': 49.3277539, 'east': 10.1566147, 'south': 49.2163494, 'west': 9.9373797}, {
  'zipCode': '74586',
  'north':   49.1286783,
  'east':    10.0920721,
  'south':   49.0322466,
  'west':    9.9091282
}, {'zipCode': '74589', 'north': 49.2137094, 'east': 10.1710319, 'south': 49.1515117, 'west': 10.0097486}, {
  'zipCode': '74592',
  'north':   49.2341341,
  'east':    10.0444624,
  'south':   49.16836760000001,
  'west':    9.9170681
}, {'zipCode': '74594', 'north': 49.1826428, 'east': 10.2571251, 'south': 49.0942858, 'west': 10.1126317}, {
  'zipCode': '74595',
  'north':   49.2907757,
  'east':    9.9162836,
  'south':   49.2155778,
  'west':    9.8131335
}, {'zipCode': '74597', 'north': 49.1065419, 'east': 10.1725509, 'south': 49.03668, 'west': 10.0662505}, {
  'zipCode': '74599',
  'north':   49.2495197,
  'east':    10.1405643,
  'south':   49.2015732,
  'west':    10.0320289
}, {'zipCode': '74613', 'north': 49.2835168, 'east': 9.6172269, 'south': 49.1505408, 'west': 9.4143924}, {
  'zipCode': '74626',
  'north':   49.2127489,
  'east':    9.5397759,
  'south':   49.0958892,
  'west':    9.3432691
}, {'zipCode': '74629', 'north': 49.19306460000001, 'east': 9.6134127, 'south': 49.1052603, 'west': 9.4570667}, {
  'zipCode': '74632',
  'north':   49.2634596,
  'east':    9.6584612,
  'south':   49.1736849,
  'west':    9.5381336
}, {'zipCode': '74635', 'north': 49.2660165, 'east': 9.7656411, 'south': 49.1616393, 'west': 9.6234586}, {
  'zipCode': '74638',
  'north':   49.2132698,
  'east':    9.6744832,
  'south':   49.1273422,
  'west':    9.5929616
}, {'zipCode': '74639', 'north': 49.2826781, 'east': 9.5588303, 'south': 49.2165462, 'west': 9.4490121}, {
  'zipCode': '74653',
  'north':   49.3581955,
  'east':    9.8348016,
  'south':   49.2405993,
  'west':    9.6106365
}, {'zipCode': '74670', 'north': 49.3272072, 'east': 9.5906745, 'south': 49.2326979, 'west': 9.4391854}, {
  'zipCode': '74673',
  'north':   49.407800900000005,
  'east':    9.8602016,
  'south':   49.283795,
  'west':    9.7305773
}, {'zipCode': '74676', 'north': 49.30988970000001, 'east': 9.6493512, 'south': 49.252429, 'west': 9.5685334}, {
  'zipCode': '74677',
  'north':   49.4178765,
  'east':    9.7712235,
  'south':   49.3369337,
  'west':    9.6630659
}, {'zipCode': '74679', 'north': 49.3349224, 'east': 9.630778, 'south': 49.283832000000004, 'west': 9.578065}, {
  'zipCode': '74706',
  'north':   49.4864973,
  'east':    9.4780295,
  'south':   49.4013822,
  'west':    9.3395011
}, {'zipCode': '74722', 'north': 49.5942857, 'east': 9.4300524, 'south': 49.4519343, 'west': 9.2244834}, {
  'zipCode': '74731',
  'north':   49.655296,
  'east':    9.4934713,
  'south':   49.4991118,
  'west':    9.2589146
}, {'zipCode': '74736', 'north': 49.663763, 'east': 9.5517254, 'south': 49.50007750000001, 'west': 9.3837466}, {
  'zipCode': '74740',
  'north':   49.4226301,
  'east':    9.4645946,
  'south':   49.3580916,
  'west':    9.3281962
}, {'zipCode': '74743', 'north': 49.462780900000006, 'east': 9.3908023, 'south': 49.4087923, 'west': 9.2560446}, {
  'zipCode': '74744',
  'north':   49.5562085,
  'east':    9.5966882,
  'south':   49.4382876,
  'west':    9.4906939
}, {'zipCode': '74746', 'north': 49.620362, 'east': 9.4710648, 'south': 49.545868, 'west': 9.3776244}, {
  'zipCode': '74747',
  'north':   49.4498023,
  'east':    9.6037855,
  'south':   49.375246000000004,
  'west':    9.4476344
}, {'zipCode': '74749', 'north': 49.5105449, 'east': 9.5212659, 'south': 49.4206841, 'west': 9.425328500000003}, {
  'zipCode': '74821',
  'north':   49.4609513,
  'east':    9.1969334,
  'south':   49.3213017,
  'west':    9.0872578
}, {'zipCode': '74831', 'north': 49.3287013, 'east': 9.2423985, 'south': 49.2531816, 'west': 9.1303776}, {
  'zipCode': '74834',
  'north':   49.4483693,
  'east':    9.261509700000001,
  'south':   49.3538616,
  'west':    9.156951
}, {'zipCode': '74838', 'north': 49.5012995, 'east': 9.2622272, 'south': 49.4305868, 'west': 9.1087322}, {
  'zipCode': '74842',
  'north':   49.3817012,
  'east':    9.3153085,
  'south':   49.2974262,
  'west':    9.1786702
}, {'zipCode': '74847', 'north': 49.375732, 'east': 9.1038234, 'south': 49.3195236, 'west': 9.0112708}, {
  'zipCode': '74850',
  'north':   49.437325400000006,
  'east':    9.346511600000001,
  'south':   49.3715562,
  'west':    9.2339932
}, {'zipCode': '74855', 'north': 49.3365912, 'east': 9.1567425, 'south': 49.2718779, 'west': 9.0732062}, {
  'zipCode': '74858',
  'north':   49.3955548,
  'east':    9.0431579,
  'south':   49.3352411,
  'west':    8.9387222
}, {'zipCode': '74861', 'north': 49.330927, 'east': 9.3582041, 'south': 49.2644056, 'west': 9.2277104}, {
  'zipCode': '74862',
  'north':   49.384796900000005,
  'east':    9.1045851,
  'south':   49.363756,
  'west':    9.0487301
}, {'zipCode': '74864', 'north': 49.4709775, 'east': 9.1945663, 'south': 49.4138058, 'west': 9.1249418}, {
  'zipCode': '74865',
  'north':   49.3394956,
  'east':    9.1871566,
  'south':   49.3029452,
  'west':    9.1141341
}, {'zipCode': '74867', 'north': 49.4274131, 'east': 9.0689473, 'south': 49.3698938, 'west': 8.9831307}, {
  'zipCode': '74869',
  'north':   49.3998714,
  'east':    9.0075668,
  'south':   49.3652755,
  'west':    8.9467827
}, {'zipCode': '74889', 'north': 49.2946837, 'east': 9.0104596, 'south': 49.1743099, 'west': 8.7638466}, {
  'zipCode': '74906',
  'north':   49.2994345,
  'east':    9.1601486,
  'south':   49.1958123,
  'west':    8.962904
}, {'zipCode': '74909', 'north': 49.3526516, 'east': 8.8672687, 'south': 49.3049289, 'west': 8.7781175}, {
  'zipCode': '74912',
  'north':   49.2227931,
  'east':    9.0269085,
  'south':   49.1731608,
  'west':    8.9540621
}, {'zipCode': '74915', 'north': 49.3227535, 'east': 8.9559164, 'south': 49.2711299, 'west': 8.8485848}, {
  'zipCode': '74918',
  'north':   49.24762890000001,
  'east':    8.8145034,
  'south':   49.1979248,
  'west':    8.7360044
}, {'zipCode': '74921', 'north': 49.34781540000001, 'east': 9.0569135, 'south': 49.2922501, 'west': 8.931627}, {
  'zipCode': '74924',
  'north':   49.3172232,
  'east':    9.0422074,
  'south':   49.2552434,
  'west':    8.9378331
}, {'zipCode': '74925', 'north': 49.379752, 'east': 8.9549245, 'south': 49.3247583, 'west': 8.881002}, {
  'zipCode': '74927',
  'north':   49.3357293,
  'east':    8.8839477,
  'south':   49.2985499,
  'west':    8.8362339
}, {'zipCode': '74928', 'north': 49.3272644, 'east': 9.1174653, 'south': 49.2771091, 'west': 9.0425333}, {
  'zipCode': '74930',
  'north':   49.2130341,
  'east':    8.9649077,
  'south':   49.1783874,
  'west':    8.8863243
}, {'zipCode': '74931', 'north': 49.4080311, 'east': 8.9232107, 'south': 49.3404316, 'west': 8.840097}, {
  'zipCode': '74933',
  'north':   49.3331435,
  'east':    8.9332206,
  'south':   49.3042191,
  'west':    8.8692345
}, {'zipCode': '74934', 'north': 49.38795770000001, 'east': 8.9699608, 'south': 49.3431649, 'west': 8.9143156}, {
  'zipCode': '74936',
  'north':   49.2800301,
  'east':    9.1165343,
  'south':   49.2572688,
  'west':    9.060321
}, {'zipCode': '74937', 'north': 49.3695195, 'east': 8.9124565, 'south': 49.3304988, 'west': 8.862049}, {
  'zipCode': '74939',
  'north':   49.3153509,
  'east':    8.8548033,
  'south':   49.28033810000001,
  'west':    8.7876317
}, {'zipCode': '75015', 'north': 49.0983467, 'east': 8.7632662, 'south': 48.9885269, 'west': 8.6350129}, {
  'zipCode': '75031',
  'north':   49.19481890000001,
  'east':    9.0030141,
  'south':   49.0747599,
  'west':    8.8161401
}, {'zipCode': '75038', 'north': 49.109341300000004, 'east': 8.842115, 'south': 49.0337018, 'west': 8.7434991}, {
  'zipCode': '75045',
  'north':   49.0613223,
  'east':    8.6610762,
  'south':   48.9853882,
  'west':    8.5434273
}, {'zipCode': '75050', 'north': 49.1798671, 'east': 9.0172992, 'south': 49.12673360000001, 'west': 8.9361809}, {
  'zipCode': '75053',
  'north':   49.0787418,
  'east':    8.6878404,
  'south':   49.0311026,
  'west':    8.6064592
}, {'zipCode': '75056', 'north': 49.132097, 'east': 8.8945186, 'south': 49.0799611, 'west': 8.8254716}, {
  'zipCode': '75057',
  'north':   49.0954883,
  'east':    8.8798336,
  'south':   49.0585021,
  'west':    8.8117529
}, {'zipCode': '75059', 'north': 49.1268586, 'east': 8.8360241, 'south': 49.0865264, 'west': 8.783090500000002}, {
  'zipCode': '75172',
  'north':   48.8955682,
  'east':    8.7029905,
  'south':   48.8837871,
  'west':    8.6664315
}, {'zipCode': '75173', 'north': 48.891818, 'east': 8.7036921, 'south': 48.8672913, 'west': 8.6464416}, {
  'zipCode': '75175',
  'north':   48.9057283,
  'east':    8.7553906,
  'south':   48.8646767,
  'west':    8.6978189
}, {'zipCode': '75177', 'north': 48.9368806, 'east': 8.7407328, 'south': 48.8940857, 'west': 8.6704571}, {
  'zipCode': '75179',
  'north':   48.920316,
  'east':    8.6951876,
  'south':   48.8737917,
  'west':    8.6274784
}, {'zipCode': '75180', 'north': 48.8800563, 'east': 8.6969778, 'south': 48.8347093, 'west': 8.6247381}, {
  'zipCode': '75181',
  'north':   48.92865,
  'east':    8.8087399,
  'south':   48.819013,
  'west':    8.6739938
}, {'zipCode': '75196', 'north': 48.9898972, 'east': 8.6133652, 'south': 48.9132256, 'west': 8.5343658}, {
  'zipCode': '75203',
  'north':   49.0076718,
  'east':    8.6959603,
  'south':   48.9455808,
  'west':    8.574612
}, {'zipCode': '75210', 'north': 48.9199625, 'east': 8.6344941, 'south': 48.8732069, 'west': 8.5161395}, {
  'zipCode': '75217',
  'north':   48.8993313,
  'east':    8.6489922,
  'south':   48.8437399,
  'west':    8.5650058
}, {'zipCode': '75223', 'north': 48.9340376, 'east': 8.8433241, 'south': 48.8848923, 'west': 8.7542094}, {
  'zipCode': '75228',
  'north':   48.9378772,
  'east':    8.7021567,
  'south':   48.9087469,
  'west':    8.6454144
}, {'zipCode': '75233', 'north': 48.8504964, 'east': 8.8440495, 'south': 48.7859595, 'west': 8.7681247}, {
  'zipCode': '75236',
  'north':   48.9569925,
  'east':    8.6614913,
  'south':   48.9101016,
  'west':    8.5965112
}, {'zipCode': '75239', 'north': 48.957294000000005, 'east': 8.7046574, 'south': 48.9303299, 'west': 8.6478998}, {
  'zipCode': '75242',
  'north':   48.8452223,
  'east':    8.8164671,
  'south':   48.774347,
  'west':    8.6976456
}, {'zipCode': '75245', 'north': 48.9962484, 'east': 8.7540299, 'south': 48.9219297, 'west': 8.6679763}, {
  'zipCode': '75248',
  'north':   48.9990221,
  'east':    8.7818483,
  'south':   48.9382066,
  'west':    8.7192183
}, {'zipCode': '75249', 'north': 48.9523669, 'east': 8.7934541, 'south': 48.921899800000006, 'west': 8.7235808}, {
  'zipCode': '75305',
  'north':   48.8666106,
  'east':    8.62275,
  'south':   48.7842942,
  'west':    8.5168166
}, {'zipCode': '75323', 'north': 48.7917545, 'east': 8.6245469, 'south': 48.6501706, 'west': 8.4391614}, {
  'zipCode': '75328',
  'north':   48.8285624,
  'east':    8.7249812,
  'south':   48.7675022,
  'west':    8.5857874
}, {'zipCode': '75331', 'north': 48.8551107, 'east': 8.7003584, 'south': 48.8102548, 'west': 8.6167455}, {
  'zipCode': '75334',
  'north':   48.88245070000001,
  'east':    8.5691178,
  'south':   48.8097409,
  'west':    8.4680645
}, {'zipCode': '75335', 'north': 48.8140048, 'east': 8.5329451, 'south': 48.756884, 'west': 8.4605888}, {
  'zipCode': '75337',
  'north':   48.6870559,
  'east':    8.496836,
  'south':   48.6167612,
  'west':    8.4040874
}, {'zipCode': '75339', 'north': 48.8186798, 'east': 8.608565, 'south': 48.7873674, 'west': 8.5500234}, {
  'zipCode': '75365',
  'north':   48.7614935,
  'east':    8.8053722,
  'south':   48.6479779,
  'west':    8.658392
}, {'zipCode': '75378', 'north': 48.8034173, 'east': 8.8197081, 'south': 48.7516576, 'west': 8.637263}, {
  'zipCode': '75382',
  'north':   48.7581557,
  'east':    8.8254997,
  'south':   48.705169500000004,
  'west':    8.7382685
}, {'zipCode': '75385', 'north': 48.7267178, 'east': 8.732281, 'south': 48.6587268, 'west': 8.600387}, {
  'zipCode': '75387',
  'north':   48.6889294,
  'east':    8.7336174,
  'south':   48.6303864,
  'west':    8.6013661
}, {'zipCode': '75389', 'north': 48.7286795, 'east': 8.658387, 'south': 48.614295, 'west': 8.5436292}, {
  'zipCode': '75391',
  'north':   48.71489,
  'east':    8.8631516,
  'south':   48.6694192,
  'west':    8.7894406
}, {'zipCode': '75392', 'north': 48.6666945, 'east': 8.852597, 'south': 48.6336853, 'west': 8.7908189}, {
  'zipCode': '75394',
  'north':   48.772199,
  'east':    8.6930157,
  'south':   48.7076917,
  'west':    8.5711899
}, {'zipCode': '75395', 'north': 48.736664700000006, 'east': 8.8775066, 'south': 48.7068256, 'west': 8.821389600000002}, {
  'zipCode': '75397',
  'north':   48.7626843,
  'east':    8.835559,
  'south':   48.735978,
  'west':    8.7552143
}, {'zipCode': '75399', 'north': 48.8383738, 'east': 8.729545, 'south': 48.7952025, 'west': 8.6537603}, {
  'zipCode': '75417',
  'north':   49.0002345,
  'east':    8.926439600000002,
  'south':   48.8972286,
  'west':    8.7704453
}, {'zipCode': '75428', 'north': 49.021372400000004, 'east': 8.9491747, 'south': 48.9363972, 'west': 8.8691301}, {
  'zipCode': '75433',
  'north':   49.02290750000001,
  'east':    8.881787,
  'south':   48.97137080000001,
  'west':    8.7567234
}, {'zipCode': '75438', 'north': 49.0463479, 'east': 8.8393671, 'south': 48.9925246, 'west': 8.7296844}, {
  'zipCode': '75443',
  'north':   48.9809743,
  'east':    8.8402557,
  'south':   48.9457456,
  'west':    8.7678822
}, {'zipCode': '75446', 'north': 48.9213651, 'east': 8.9292566, 'south': 48.860469, 'west': 8.8217022}, {
  'zipCode': '75447',
  'north':   49.0620907,
  'east':    8.9046435,
  'south':   49.0004858,
  'west':    8.8337222
}, {'zipCode': '75449', 'north': 48.8886135, 'east': 8.8441659, 'south': 48.8614169, 'west': 8.7905477}, {
  'zipCode': '76131',
  'north':   49.091271,
  'east':    8.452283,
  'south':   49.0029253,
  'west':    8.3888997
}, {'zipCode': '76133', 'north': 49.0269281, 'east': 8.4116287, 'south': 49.0020226, 'west': 8.3689027}, {
  'zipCode': '76135',
  'north':   49.0106809,
  'east':    8.3963937,
  'south':   48.9713801,
  'west':    8.3588613
}, {'zipCode': '76137', 'north': 49.01424010000001, 'east': 8.4538736, 'south': 48.98904, 'west': 8.3830118}, {
  'zipCode': '76139',
  'north':   49.0510084,
  'east':    8.4738081,
  'south':   49.0054252,
  'west':    8.4184334
}, {'zipCode': '76149', 'north': 49.0711079, 'east': 8.4099433, 'south': 49.0191041, 'west': 8.3466605}, {
  'zipCode': '76185',
  'north':   49.03473350000001,
  'east':    8.3772264,
  'south':   48.9914281,
  'west':    8.3351505
}, {'zipCode': '76187', 'north': 49.0804907, 'east': 8.3767078, 'south': 49.0151982, 'west': 8.298779700000003}, {
  'zipCode': '76189',
  'north':   49.0251033,
  'east':    8.3714207,
  'south':   48.978326900000006,
  'west':    8.2773142
}, {'zipCode': '76199', 'north': 48.9966848, 'east': 8.4379046, 'south': 48.9576057, 'west': 8.380707000000001}, {
  'zipCode': '76227',
  'north':   49.02392540000001,
  'east':    8.5104471,
  'south':   48.9648276,
  'west':    8.428850400000002
}, {'zipCode': '76228', 'north': 48.9826235, 'east': 8.5417299, 'south': 48.9404699, 'west': 8.4363098}, {
  'zipCode': '76229',
  'north':   49.0612934,
  'east':    8.522582600000002,
  'south':   48.99433,
  'west':    8.4671553
}, {'zipCode': '76275', 'north': 48.9758066, 'east': 8.4565002, 'south': 48.8766523, 'west': 8.3486783}, {
  'zipCode': '76287',
  'north':   48.99195100000001,
  'east':    8.3706272,
  'south':   48.9345468,
  'west':    8.2586445
}, {'zipCode': '76297', 'north': 49.139932, 'east': 8.5293028, 'south': 49.0394144, 'west': 8.4280586}, {
  'zipCode': '76307',
  'north':   48.9541043,
  'east':    8.5545613,
  'south':   48.8584752,
  'west':    8.4474699
}, {'zipCode': '76316', 'north': 48.9308239, 'east': 8.414977, 'south': 48.8399136, 'west': 8.2880272}, {
  'zipCode': '76327',
  'north':   49.0316079,
  'east':    8.5996662,
  'south':   48.9522115,
  'west':    8.5001954
}, {'zipCode': '76332', 'north': 48.8359039, 'east': 8.5053447, 'south': 48.74370400000001, 'west': 8.3789319}, {
  'zipCode': '76337',
  'north':   48.9464114,
  'east':    8.4973255,
  'south':   48.9019902,
  'west':    8.4468955
}, {'zipCode': '76344', 'north': 49.1295843, 'east': 8.4413127, 'south': 49.0632903, 'west': 8.3406849}, {
  'zipCode': '76351',
  'north':   49.1582996,
  'east':    8.4529553,
  'south':   49.097153,
  'west':    8.3673632
}, {'zipCode': '76356', 'north': 49.0872406, 'east': 8.595108, 'south': 49.0278382, 'west': 8.486376}, {
  'zipCode': '76359',
  'north':   48.8909329,
  'east':    8.5008171,
  'south':   48.812158200000006,
  'west':    8.4050826
}, {'zipCode': '76437', 'north': 48.9197337, 'east': 8.2646818, 'south': 48.8141378, 'west': 8.115802}, {
  'zipCode': '76448',
  'north':   48.9533831,
  'east':    8.3545091,
  'south':   48.9068043,
  'west':    8.2295859
}, {'zipCode': '76456', 'north': 48.8558708, 'east': 8.3000789, 'south': 48.7858277, 'west': 8.2339662}, {
  'zipCode': '76461',
  'north':   48.8865089,
  'east':    8.3086064,
  'south':   48.8466826,
  'west':    8.249574700000002
}, {'zipCode': '76467', 'north': 48.9275474, 'east': 8.300122800000002, 'south': 48.8810637, 'west': 8.2244398}, {
  'zipCode': '76470',
  'north':   48.9052272,
  'east':    8.2715829,
  'south':   48.8654572,
  'west':    8.2070854
}, {'zipCode': '76473', 'north': 48.8527365, 'east': 8.193101, 'south': 48.7899845, 'west': 8.1065627}, {
  'zipCode': '76474',
  'north':   48.9809901,
  'east':    8.27224,
  'south':   48.9386843,
  'west':    8.1880011
}, {'zipCode': '76476', 'north': 48.8530201, 'east': 8.3079327, 'south': 48.8283555, 'west': 8.2635343}, {
  'zipCode': '76477',
  'north':   48.9470468,
  'east':    8.2382703,
  'south':   48.9161484,
  'west':    8.1705264
}, {'zipCode': '76479', 'north': 48.9265606, 'east': 8.2308062, 'south': 48.8864596, 'west': 8.1636986}, {
  'zipCode': '76530',
  'north':   48.7978311,
  'east':    8.2954898,
  'south':   48.7350325,
  'west':    8.1809492
}, {'zipCode': '76532', 'north': 48.8479125, 'east': 8.2557825, 'south': 48.7582604, 'west': 8.1462773}, {
  'zipCode': '76534',
  'north':   48.764311,
  'east':    8.3209469,
  'south':   48.6667428,
  'west':    8.1077765
}, {'zipCode': '76547', 'north': 48.78563960000001, 'east': 8.2030988, 'south': 48.7375726, 'west': 8.0750298}, {
  'zipCode': '76549',
  'north':   48.82673530000001,
  'east':    8.1529096,
  'south':   48.770894500000004,
  'west':    8.0835635
}, {'zipCode': '76571', 'north': 48.8626318, 'east': 8.4173633, 'south': 48.7693393, 'west': 8.2849864}, {
  'zipCode': '76593',
  'north':   48.78681180000001,
  'east':    8.485322,
  'south':   48.65798710000001,
  'west':    8.280177
}, {'zipCode': '76596', 'north': 48.7203481, 'east': 8.4361881, 'south': 48.5863629, 'west': 8.2220521}, {
  'zipCode': '76597',
  'north':   48.8039269,
  'east':    8.4344597,
  'south':   48.74370400000001,
  'west':    8.371669
}, {'zipCode': '76599', 'north': 48.7357652, 'east': 8.4073811, 'south': 48.7025698, 'west': 8.3202508}, {
  'zipCode': '76646',
  'north':   49.200431800000004,
  'east':    8.6896815,
  'south':   49.0595244,
  'west':    8.5081576
}, {'zipCode': '76661', 'north': 49.2668573, 'east': 8.5081576, 'south': 49.1832988, 'west': 8.3812176}, {
  'zipCode': '76669',
  'north':   49.243433,
  'east':    8.691488,
  'south':   49.1894721,
  'west':    8.5734576
}, {'zipCode': '76676', 'north': 49.1897757, 'east': 8.5217711, 'south': 49.1252922, 'west': 8.4322538}, {
  'zipCode': '76684',
  'north':   49.2380423,
  'east':    8.8258167,
  'south':   49.1529611,
  'west':    8.6775866
}, {'zipCode': '76689', 'north': 49.1610365, 'east': 8.5765033, 'south': 49.1142847, 'west': 8.5045769}, {
  'zipCode': '76694',
  'north':   49.1835935,
  'east':    8.6160363,
  'south':   49.1372972,
  'west':    8.5606412
}, {'zipCode': '76698', 'north': 49.2042673, 'east': 8.717078, 'south': 49.1399448, 'west': 8.5738926}, {
  'zipCode': '76703',
  'north':   49.1734374,
  'east':    8.842195500000003,
  'south':   49.08821880000001,
  'west':    8.6409774
}, {'zipCode': '76706', 'north': 49.2083394, 'east': 8.4856838, 'south': 49.1332564, 'west': 8.3676774}, {
  'zipCode': '76707',
  'north':   49.2149521,
  'east':    8.586767300000002,
  'south':   49.1705146,
  'west':    8.5160007
}, {'zipCode': '76709', 'north': 49.246742, 'east': 8.6409062, 'south': 49.2091673, 'west': 8.5822289}, {
  'zipCode': '76726',
  'north':   49.256339100000005,
  'east':    8.4053451,
  'south':   49.1762499,
  'west':    8.3415033
}, {'zipCode': '76744', 'north': 49.0926751, 'east': 8.3507426, 'south': 48.980197, 'west': 8.0551003}, {
  'zipCode': '76751',
  'north':   49.1086917,
  'east':    8.3273564,
  'south':   49.0631104,
  'west':    8.2488387
}, {'zipCode': '76756', 'north': 49.2125705, 'east': 8.3509487, 'south': 49.1713549, 'west': 8.2575074}, {
  'zipCode': '76761',
  'north':   49.1753831,
  'east':    8.3214792,
  'south':   49.1201314,
  'west':    8.2552085
}, {'zipCode': '76764', 'north': 49.1346623, 'east': 8.3192104, 'south': 49.0887945, 'west': 8.2550931}, {
  'zipCode': '76767',
  'north':   49.0414721,
  'east':    8.2916379,
  'south':   48.9906621,
  'west':    8.2203499
}, {'zipCode': '76768', 'north': 49.0007095, 'east': 8.2326222, 'south': 48.9668924, 'west': 8.1631502}, {
  'zipCode': '76770',
  'north':   49.1275717,
  'east':    8.2724093,
  'south':   49.103948800000005,
  'west':    8.2094524
}, {'zipCode': '76771', 'north': 49.1878691, 'east': 8.3780868, 'south': 49.1425088, 'west': 8.3023207}, {
  'zipCode': '76773',
  'north':   49.1546401,
  'east':    8.3494404,
  'south':   49.1339351,
  'west':    8.2903783
}, {'zipCode': '76774', 'north': 49.1450034, 'east': 8.3708025, 'south': 49.1014277, 'west': 8.291067}, {
  'zipCode': '76776',
  'north':   48.999557900000006,
  'east':    8.2876592,
  'south':   48.9664472,
  'west':    8.220398
}, {'zipCode': '76777', 'north': 49.1282129, 'east': 8.3597667, 'south': 49.0867286, 'west': 8.306656}, {
  'zipCode': '76779',
  'north':   49.0007321,
  'east':    8.1653921,
  'south':   48.9769872,
  'west':    8.068397
}, {'zipCode': '76829', 'north': 49.282501, 'east': 8.1806097, 'south': 49.1548639, 'west': 7.9739279}, {
  'zipCode': '76831',
  'north':   49.2112431,
  'east':    8.1341028,
  'south':   49.0987771,
  'west':    7.9904018
}, {'zipCode': '76833', 'north': 49.2515664, 'east': 8.1539852, 'south': 49.2023769, 'west': 8.0121198}, {
  'zipCode': '76835',
  'north':   49.3336096,
  'east':    8.1396119,
  'south':   49.2349314,
  'west':    7.935495199999999
}, {'zipCode': '76846', 'north': 49.2069732, 'east': 7.873562599999999, 'south': 49.1624833, 'west': 7.8025353}, {
  'zipCode': '76848',
  'north':   49.3342423,
  'east':    7.9179051,
  'south':   49.1421747,
  'west':    7.7509363
}, {'zipCode': '76855', 'north': 49.2840771, 'east': 8.0219718, 'south': 49.1819241, 'west': 7.8254341}, {
  'zipCode': '76857',
  'north':   49.3163444,
  'east':    8.0447336,
  'south':   49.1203028,
  'west':    7.8646346000000005
}, {'zipCode': '76863', 'north': 49.1728988, 'east': 8.2638522, 'south': 49.1089326, 'west': 8.1669341}, {
  'zipCode': '76865',
  'north':   49.1748988,
  'east':    8.1973206,
  'south':   49.1251214,
  'west':    8.1156354
}, {'zipCode': '76870', 'north': 49.1105963, 'east': 8.2594537, 'south': 49.0604218, 'west': 8.1323985}, {
  'zipCode': '76872',
  'north':   49.1360882,
  'east':    8.2167685,
  'south':   49.05245550000001,
  'west':    8.0788418
}, {'zipCode': '76877', 'north': 49.2181181, 'east': 8.2182764, 'south': 49.1641912, 'west': 8.1680776}, {
  'zipCode': '76879',
  'north':   49.2602189,
  'east':    8.2621217,
  'south':   49.1699355,
  'west':    8.1484038
}, {'zipCode': '76887', 'north': 49.1320084, 'east': 8.0689882, 'south': 49.0784021, 'west': 7.802257299999999}, {
  'zipCode': '76889',
  'north':   49.1604957,
  'east':    8.0929333,
  'south':   48.999398,
  'west':    7.8457277
}, {'zipCode': '76891', 'north': 49.1453684, 'east': 7.914347599999999, 'south': 49.032962, 'west': 7.729296800000001}, {
  'zipCode': '77652',
  'north':   48.5526917,
  'east':    7.974704400000001,
  'south':   48.4483631,
  'west':    7.910498300000001
}, {'zipCode': '77654', 'north': 48.5036205, 'east': 8.0284868, 'south': 48.4529038, 'west': 7.9455749}, {
  'zipCode': '77656',
  'north':   48.5307717,
  'east':    7.9794321,
  'south':   48.3968238,
  'west':    7.861643299999999
}, {'zipCode': '77694', 'north': 48.641053, 'east': 7.9508037, 'south': 48.4862511, 'west': 7.7919616}, {
  'zipCode': '77704',
  'north':   48.57471710000001,
  'east':    8.1246481,
  'south':   48.4527293,
  'west':    7.9971269000000005
}, {'zipCode': '77709', 'north': 48.4014027, 'east': 8.3305412, 'south': 48.2366108, 'west': 8.1693016}, {
  'zipCode': '77716',
  'north':   48.34219890000001,
  'east':    8.1565022,
  'south':   48.2214948,
  'west':    8.0177781
}, {'zipCode': '77723', 'north': 48.4566519, 'east': 8.0973714, 'south': 48.3522361, 'west': 7.969905}, {
  'zipCode': '77728',
  'north':   48.5610134,
  'east':    8.242242200000002,
  'south':   48.4148011,
  'west':    8.1148566
}, {'zipCode': '77731', 'north': 48.6035367, 'east': 7.9567276, 'south': 48.49881270000001, 'west': 7.8339192}, {
  'zipCode': '77736',
  'north':   48.3771206,
  'east':    8.1478653,
  'south':   48.3110461,
  'west':    8.028807
}, {'zipCode': '77740', 'north': 48.4941633, 'east': 8.2749572, 'south': 48.4003388, 'west': 8.1693804}, {
  'zipCode': '77743',
  'north':   48.5001834,
  'east':    7.8604331,
  'south':   48.3935127,
  'west':    7.7524626
}, {'zipCode': '77746', 'north': 48.4929965, 'east': 7.9165406, 'south': 48.4345621, 'west': 7.8349524}, {
  'zipCode': '77749',
  'north':   48.4530526,
  'east':    7.9785695,
  'south':   48.3679384,
  'west':    7.8501654
}, {'zipCode': '77756', 'north': 48.3509928, 'east': 8.2127061, 'south': 48.24748850000001, 'west': 8.1199998}, {
  'zipCode': '77761',
  'north':   48.3055973,
  'east':    8.3921489,
  'south':   48.2431023,
  'west':    8.2769548
}, {'zipCode': '77767', 'north': 48.6164404, 'east': 8.0364358, 'south': 48.5048926, 'west': 7.9385729}, {
  'zipCode': '77770',
  'north':   48.520217300000006,
  'east':    8.093464,
  'south':   48.4511827,
  'west':    7.968055200000001
}, {'zipCode': '77773', 'north': 48.38723, 'east': 8.4141772, 'south': 48.2820776, 'west': 8.3039639}, {
  'zipCode': '77776',
  'north':   48.4790913,
  'east':    8.3610114,
  'south':   48.3486783,
  'west':    8.2328416
}, {'zipCode': '77781', 'north': 48.3606873, 'east': 8.0516628, 'south': 48.2893754, 'west': 7.977979999999999}, {
  'zipCode': '77784',
  'north':   48.4249687,
  'east':    8.2181094,
  'south':   48.3385849,
  'west':    8.1020607
}, {'zipCode': '77787', 'north': 48.4622302, 'east': 8.1511485, 'south': 48.3703288, 'west': 8.0541199}, {
  'zipCode': '77790',
  'north':   48.3251296,
  'east':    8.076146,
  'south':   48.2475549,
  'west':    7.987862899999999
}, {'zipCode': '77791', 'north': 48.42433770000001, 'east': 8.0035644, 'south': 48.3783759, 'west': 7.9484583}, {
  'zipCode': '77793',
  'north':   48.2882453,
  'east':    8.2465523,
  'south':   48.20442570000001,
  'west':    8.1665596
}, {'zipCode': '77794', 'north': 48.5489497, 'east': 8.1749384, 'south': 48.4914717, 'west': 8.0914167}, {
  'zipCode': '77796',
  'north':   48.2678571,
  'east':    8.1775694,
  'south':   48.2081206,
  'west':    8.0716343
}, {'zipCode': '77797', 'north': 48.4658583, 'east': 8.0421038, 'south': 48.4198824, 'west': 7.967829600000001}, {
  'zipCode': '77799',
  'north':   48.4646042,
  'east':    7.9958416,
  'south':   48.4345665,
  'west':    7.951524699999999
}, {'zipCode': '77815', 'north': 48.7582943, 'east': 8.2586024, 'south': 48.6233536, 'west': 8.0329686}, {
  'zipCode': '77830',
  'north':   48.7099788,
  'east':    8.232624200000002,
  'south':   48.6480355,
  'west':    8.1635398
}, {'zipCode': '77833', 'north': 48.7200666, 'east': 8.147946700000002, 'south': 48.6490814, 'west': 8.0271649}, {
  'zipCode': '77836',
  'north':   48.79937420000001,
  'east':    8.1062098,
  'south':   48.718551,
  'west':    7.9689183
}, {'zipCode': '77839', 'north': 48.7538793, 'east': 8.0441593, 'south': 48.6794981, 'west': 7.959276600000001}, {
  'zipCode': '77855',
  'north':   48.683097000000004,
  'east':    8.104681,
  'south':   48.5864062,
  'west':    7.965798400000001
}, {'zipCode': '77866', 'north': 48.7184764, 'east': 8.0204148, 'south': 48.603378600000006, 'west': 7.8360646}, {
  'zipCode': '77871',
  'north':   48.617772,
  'east':    8.099182,
  'south':   48.5595929,
  'west':    7.962612700000001
}, {'zipCode': '77876', 'north': 48.613949000000005, 'east': 8.1488837, 'south': 48.545094, 'west': 8.0859116}, {
  'zipCode': '77880',
  'north':   48.6552481,
  'east':    8.2485597,
  'south':   48.5935501,
  'west':    8.0660182
}, {'zipCode': '77883', 'north': 48.59346, 'east': 8.2051924, 'south': 48.5272022, 'west': 8.1231179}, {
  'zipCode': '77886',
  'north':   48.6570194,
  'east':    8.2022192,
  'south':   48.6185302,
  'west':    8.1130323
}, {'zipCode': '77887', 'north': 48.63155890000001, 'east': 8.2309695, 'south': 48.5953585, 'west': 8.0989671}, {
  'zipCode': '77889',
  'north':   48.6041481,
  'east':    8.2370334,
  'south':   48.5581625,
  'west':    8.1472843
}, {'zipCode': '77933', 'north': 48.3821335, 'east': 7.9787097, 'south': 48.2865257, 'west': 7.7858394}, {
  'zipCode': '77948',
  'north':   48.4165489,
  'east':    7.982794099999999,
  'south':   48.3458359,
  'west':    7.8219555000000005
}, {'zipCode': '77955', 'north': 48.2793238, 'east': 7.9400379, 'south': 48.218248, 'west': 7.759225299999999}, {
  'zipCode': '77960',
  'north':   48.3498116,
  'east':    7.9936373000000005,
  'south':   48.268486,
  'west':    7.893625600000001
}, {'zipCode': '77963', 'north': 48.4197757, 'east': 7.801523300000001, 'south': 48.3138077, 'west': 7.731016399999999}, {
  'zipCode': '77966',
  'north':   48.323570700000005,
  'east':    7.7869068,
  'south':   48.2597454,
  'west':    7.695357400000001
}, {'zipCode': '77971', 'north': 48.3291736, 'east': 7.8985123999999995, 'south': 48.2696134, 'west': 7.7913741000000005}, {
  'zipCode': '77972',
  'north':   48.3333121,
  'east':    7.841884100000001,
  'south':   48.267936,
  'west':    7.776833000000001
}, {'zipCode': '77974', 'north': 48.4338178, 'east': 7.8323792, 'south': 48.3691855, 'west': 7.744955700000001}, {
  'zipCode': '77975',
  'north':   48.2677277,
  'east':    7.797775700000001,
  'south':   48.2341152,
  'west':    7.743888499999999
}, {'zipCode': '77977', 'north': 48.30337810000001, 'east': 7.7525683, 'south': 48.2358358, 'west': 7.6808779}, {
  'zipCode': '77978',
  'north':   48.3054084,
  'east':    8.0267469,
  'south':   48.2017283,
  'west':    7.903999
}, {'zipCode': '78048', 'north': 48.1000257, 'east': 8.4817199, 'south': 48.047985100000005, 'west': 8.3994864}, {
  'zipCode': '78050',
  'north':   48.0815468,
  'east':    8.4732142,
  'south':   48.0399017,
  'west':    8.4096986
}, {'zipCode': '78052', 'north': 48.1253665, 'east': 8.5309105, 'south': 47.980919500000006, 'west': 8.3081027}, {
  'zipCode': '78054',
  'north':   48.0880127,
  'east':    8.5416896,
  'south':   48.0451265,
  'west':    8.500519800000001
}, {'zipCode': '78056', 'north': 48.0889816, 'east': 8.6365388, 'south': 48.0375198, 'west': 8.5196534}, {
  'zipCode': '78073',
  'north':   48.0501748,
  'east':    8.6633779,
  'south':   47.9449477,
  'west':    8.5054979
}, {'zipCode': '78078', 'north': 48.1689327, 'east': 8.5582713, 'south': 48.1013655, 'west': 8.4560669}, {
  'zipCode': '78083',
  'north':   48.1158511,
  'east':    8.5848947,
  'south':   48.0683304,
  'west':    8.5195696
}, {'zipCode': '78086', 'north': 48.0268073, 'east': 8.5214804, 'south': 47.9865851, 'west': 8.4128425}, {
  'zipCode': '78087',
  'north':   48.1305565,
  'east':    8.4492092,
  'south':   48.0896501,
  'west':    8.3921916
}, {'zipCode': '78089', 'north': 48.1110168, 'east': 8.4096986, 'south': 48.0540941, 'west': 8.3124465}, {
  'zipCode': '78098',
  'north':   48.1873962,
  'east':    8.2978488,
  'south':   48.09535940000001,
  'west':    8.2137299
}, {'zipCode': '78112', 'north': 48.1731271, 'east': 8.4027407, 'south': 48.0730467, 'west': 8.2726827}, {
  'zipCode': '78120',
  'north':   48.1041854,
  'east':    8.2823394,
  'south':   47.991629,
  'west':    8.1307813
}, {'zipCode': '78126', 'north': 48.182051400000006, 'east': 8.490593, 'south': 48.1187475, 'west': 8.3402985}, {
  'zipCode': '78132',
  'north':   48.2476993,
  'east':    8.3130062,
  'south':   48.1582346,
  'west':    8.1791583
}, {'zipCode': '78136', 'north': 48.18400020000001, 'east': 8.2473026, 'south': 48.1160148, 'west': 8.125183}, {
  'zipCode': '78141',
  'north':   48.131289,
  'east':    8.254394500000002,
  'south':   48.0800556,
  'west':    8.1357287
}, {'zipCode': '78144', 'north': 48.2228278, 'east': 8.400521, 'south': 48.1587021, 'west': 8.2820155}, {
  'zipCode': '78147',
  'north':   48.0814748,
  'east':    8.364081,
  'south':   47.9651689,
  'west':    8.1906584
}, {'zipCode': '78148', 'north': 48.07536170000001, 'east': 8.1756269, 'south': 48.0078131, 'west': 8.1094922}, {
  'zipCode': '78166',
  'north':   48.00911990000001,
  'east':    8.6209866,
  'south':   47.8845627,
  'west':    8.3114602
}, {'zipCode': '78176', 'north': 47.8924921, 'east': 8.6403608, 'south': 47.7705317, 'west': 8.4698257}, {
  'zipCode': '78183',
  'north':   47.9443799,
  'east':    8.615657,
  'south':   47.84562,
  'west':    8.4338232
}, {'zipCode': '78187', 'north': 47.9616462, 'east': 8.7197532, 'south': 47.8464506, 'west': 8.5865445}, {
  'zipCode': '78194',
  'north':   48.0016765,
  'east':    8.8248269,
  'south':   47.8854985,
  'west':    8.636074
}, {'zipCode': '78199', 'north': 47.970609100000004, 'east': 8.476654, 'south': 47.8746838, 'west': 8.2692309}, {
  'zipCode': '78224',
  'north':   47.8169484,
  'east':    8.9267002,
  'south':   47.6968108,
  'west':    8.8084216
}, {'zipCode': '78234', 'north': 47.9275162, 'east': 8.8477611, 'south': 47.8075851, 'west': 8.6903407}, {
  'zipCode': '78239',
  'north':   47.7517129,
  'east':    8.8868123,
  'south':   47.7033771,
  'west':    8.804796600000001
}, {'zipCode': '78244', 'north': 47.7602837, 'east': 8.8107682, 'south': 47.7059644, 'west': 8.7111438}, {
  'zipCode': '78247',
  'north':   47.8155128,
  'east':    8.8256822,
  'south':   47.7417713,
  'west':    8.6835732
}, {'zipCode': '78250', 'north': 47.8697273, 'east': 8.7337272, 'south': 47.7581961, 'west': 8.6139253}, {
  'zipCode': '78253',
  'north':   47.9341812,
  'east':    8.9820597,
  'south':   47.8425662,
  'west':    8.8334003
}, {'zipCode': '78256', 'north': 47.8297614, 'east': 8.9497101, 'south': 47.7631011, 'west': 8.8758843}, {
  'zipCode': '78259',
  'north':   47.8563233,
  'east':    8.8598039,
  'south':   47.7896402,
  'west':    8.7834011
}, {'zipCode': '78262', 'north': 47.71633670000001, 'east': 8.810095, 'south': 47.6749464, 'west': 8.7264855}, {
  'zipCode': '78266',
  'north':   47.7151988,
  'east':    8.7178556,
  'south':   47.6848539,
  'west':    8.6585911
}, {'zipCode': '78267', 'north': 47.8635205, 'east': 8.8880302, 'south': 47.8253642, 'west': 8.8308295}, {
  'zipCode': '78269',
  'north':   47.8356064,
  'east':    8.8865145,
  'south':   47.8096625,
  'west':    8.846036
}, {'zipCode': '78315', 'north': 47.814156, 'east': 9.0615595, 'south': 47.7196089, 'west': 8.9064448}, {
  'zipCode': '78333',
  'north':   47.9027458,
  'east':    9.1289661,
  'south':   47.8000985,
  'west':    8.9362602
}, {'zipCode': '78337', 'north': 47.7052422, 'east': 8.9536392, 'south': 47.6520239, 'west': 8.8503819}, {
  'zipCode': '78343',
  'north':   47.7167529,
  'east':    9.0074531,
  'south':   47.6684328,
  'west':    8.9434349
}, {'zipCode': '78345', 'north': 47.7418328, 'east': 8.9748882, 'south': 47.6873402, 'west': 8.9089014}, {
  'zipCode': '78351',
  'north':   47.8489497,
  'east':    9.0879543,
  'south':   47.7672505,
  'west':    8.9752505
}, {'zipCode': '78354', 'north': 47.8123066, 'east': 9.126380700000002, 'south': 47.7845709, 'west': 9.0706589}, {
  'zipCode': '78355',
  'north':   47.9211713,
  'east':    9.1513294,
  'south':   47.8551714,
  'west':    9.0486042
}, {'zipCode': '78357', 'north': 47.9425204, 'east': 9.0816672, 'south': 47.8820516, 'west': 8.9643069}, {
  'zipCode': '78359',
  'north':   47.8683137,
  'east':    8.9909592,
  'south':   47.81665120000001,
  'west':    8.8820345
}, {'zipCode': '78462', 'north': 47.669883, 'east': 9.1820616, 'south': 47.6539842, 'west': 9.1504864}, {
  'zipCode': '78464',
  'north':   47.6978056,
  'east':    9.2179906,
  'south':   47.6631332,
  'west':    9.1712015
}, {'zipCode': '78465', 'north': 47.7627374, 'east': 9.202013, 'south': 47.6951189, 'west': 9.085385400000002}, {
  'zipCode': '78467',
  'north':   47.7160565,
  'east':    9.1844375,
  'south':   47.6657449,
  'west':    9.1144859
}, {'zipCode': '78476', 'north': 47.774138, 'east': 9.1282339, 'south': 47.6956087, 'west': 9.0321536}, {
  'zipCode': '78479',
  'north':   47.7387922,
  'east':    9.148837,
  'south':   47.664862,
  'west':    9.019023300000002
}, {'zipCode': '78532', 'north': 48.0322457, 'east': 8.9087753, 'south': 47.9262364, 'west': 8.692077900000001}, {
  'zipCode': '78549',
  'north':   48.1068709,
  'east':    8.7711708,
  'south':   48.0406417,
  'west':    8.6676327
}, {'zipCode': '78554', 'north': 48.1211301, 'east': 8.7209773, 'south': 48.0630193, 'west': 8.6340791}, {
  'zipCode': '78559',
  'north':   48.1560607,
  'east':    8.7923686,
  'south':   48.1146227,
  'west':    8.7334924
}, {'zipCode': '78564', 'north': 48.1723806, 'east': 8.8650548, 'south': 48.1222063, 'west': 8.769282}, {
  'zipCode': '78567',
  'north':   48.057796800000006,
  'east':    8.9791336,
  'south':   47.9887339,
  'west':    8.8982423
}, {'zipCode': '78570', 'north': 48.0653394, 'east': 8.9130783, 'south': 48.0045392, 'west': 8.8189148}, {
  'zipCode': '78573',
  'north':   48.0321178,
  'east':    8.8388907,
  'south':   47.9817332,
  'west':    8.739059
}, {'zipCode': '78576', 'north': 47.9753291, 'east': 8.9545825, 'south': 47.8790066, 'west': 8.816292}, {
  'zipCode': '78579',
  'north':   47.9971339,
  'east':    9.0216057,
  'south':   47.9222433,
  'west':    8.8942035
}, {'zipCode': '78580', 'north': 48.1105755, 'east': 8.9475122, 'south': 48.0576303, 'west': 8.892796}, {
  'zipCode': '78582',
  'north':   48.1024209,
  'east':    8.7857699,
  'south':   48.0487306,
  'west':    8.7529685
}, {'zipCode': '78583', 'north': 48.1214108, 'east': 8.8575646, 'south': 48.0811392, 'west': 8.7695653}, {
  'zipCode': '78585',
  'north':   48.1349298,
  'east':    8.8455438,
  'south':   48.102935,
  'west':    8.7884234
}, {'zipCode': '78586', 'north': 48.1846611, 'east': 8.828299900000001, 'south': 48.1510381, 'west': 8.7512713}, {
  'zipCode': '78588',
  'north':   48.1297402,
  'east':    8.7738647,
  'south':   48.091720200000005,
  'west':    8.6968545
}, {'zipCode': '78589', 'north': 48.0879921, 'east': 8.8307188, 'south': 48.030994, 'west': 8.7722232}, {
  'zipCode': '78591',
  'north':   48.0538916,
  'east':    8.6973571,
  'south':   48.025697,
  'west':    8.638144
}, {'zipCode': '78592', 'north': 48.1352008, 'east': 8.9002559, 'south': 48.0997659, 'west': 8.8395466}, {
  'zipCode': '78594',
  'north':   48.0617733,
  'east':    8.7161909,
  'south':   48.0332825,
  'west':    8.668021100000002
}, {'zipCode': '78595', 'north': 48.058443, 'east': 8.7589161, 'south': 48.0346808, 'west': 8.704548}, {
  'zipCode': '78597',
  'north':   48.1074313,
  'east':    8.9970939,
  'south':   48.0551551,
  'west':    8.9392332
}, {'zipCode': '78598', 'north': 48.1070079, 'east': 8.8939411, 'south': 48.08671530000001, 'west': 8.8399182}, {
  'zipCode': '78600',
  'north':   48.086309,
  'east':    8.9386264,
  'south':   48.0344303,
  'west':    8.849445
}, {'zipCode': '78601', 'north': 48.0907405, 'east': 8.862213, 'south': 48.0473458, 'west': 8.7981927}, {
  'zipCode': '78603',
  'north':   48.0997926,
  'east':    8.9188223,
  'south':   48.0713416,
  'west':    8.856502
}, {'zipCode': '78604', 'north': 48.0533289, 'east': 8.8205881, 'south': 48.013606800000005, 'west': 8.7474762}, {
  'zipCode': '78606',
  'north':   48.0408658,
  'east':    8.7572857,
  'south':   47.9910633,
  'west':    8.6834147
}, {'zipCode': '78607', 'north': 48.0338345, 'east': 8.7004477, 'south': 47.9927199, 'west': 8.6301872}, {
  'zipCode': '78609',
  'north':   48.049338,
  'east':    8.6441062,
  'south':   48.0082588,
  'west':    8.5771241
}, {'zipCode': '78628', 'north': 48.2226334, 'east': 8.744275, 'south': 48.1177086, 'west': 8.545795}, {
  'zipCode': '78647',
  'north':   48.1021776,
  'east':    8.6747261,
  'south':   48.0409496,
  'west':    8.5993769
}, {'zipCode': '78652', 'north': 48.1396531, 'east': 8.6604811, 'south': 48.0710927, 'west': 8.5428165}, {
  'zipCode': '78655',
  'north':   48.2583109,
  'east':    8.5552297,
  'south':   48.1811779,
  'west':    8.4401655
}, {'zipCode': '78658', 'north': 48.1908926, 'east': 8.6066741, 'south': 48.1250643, 'west': 8.4743724}, {
  'zipCode': '78661',
  'north':   48.2686607,
  'east':    8.7103826,
  'south':   48.1873934,
  'west':    8.6018944
}, {'zipCode': '78662', 'north': 48.2678881, 'east': 8.6084757, 'south': 48.2042582, 'west': 8.5232954}, {
  'zipCode': '78664',
  'north':   48.2079714,
  'east':    8.4935674,
  'south':   48.1759767,
  'west':    8.4154887
}, {'zipCode': '78665', 'north': 48.1409501, 'east': 8.7453936, 'south': 48.1110788, 'west': 8.6836584}, {
  'zipCode': '78667',
  'north':   48.2185995,
  'east':    8.6124785,
  'south':   48.185152,
  'west':    8.5525479
}, {'zipCode': '78669', 'north': 48.1812261, 'east': 8.7533633, 'south': 48.134661, 'west': 8.6668229}, {
  'zipCode': '78713',
  'north':   48.278987,
  'east':    8.535085,
  'south':   48.1923407,
  'west':    8.3536198
}, {'zipCode': '78727', 'north': 48.3317239, 'east': 8.6630642, 'south': 48.2606568, 'west': 8.4934768}, {
  'zipCode': '78730',
  'north':   48.2638998,
  'east':    8.3645558,
  'south':   48.2136342,
  'west':    8.287135
}, {'zipCode': '78733', 'north': 48.3242542, 'east': 8.4445754, 'south': 48.2421056, 'west': 8.3773734}, {
  'zipCode': '78736',
  'north':   48.293307000000006,
  'east':    8.6838373,
  'south':   48.2133904,
  'west':    8.5527455
}, {'zipCode': '78737', 'north': 48.31597, 'east': 8.5244006, 'south': 48.2730843, 'west': 8.4149969}, {
  'zipCode': '78739',
  'north':   48.2036719,
  'east':    8.4476846,
  'south':   48.1566532,
  'west':    8.3842492
}, {'zipCode': '79098', 'north': 48.0006433, 'east': 7.8595833, 'south': 47.9897217, 'west': 7.8364201}, {
  'zipCode': '79100',
  'north':   47.9923624,
  'east':    7.898461600000001,
  'south':   47.9035777,
  'west':    7.8162398
}, {'zipCode': '79102', 'north': 47.99252690000001, 'east': 7.887500900000001, 'south': 47.9802059, 'west': 7.847477900000001}, {
  'zipCode': '79104',
  'north':   48.031341,
  'east':    7.908527800000001,
  'south':   47.9900041,
  'west':    7.8427217
}, {'zipCode': '79106', 'north': 48.0210303, 'east': 7.8549341, 'south': 47.9923624, 'west': 7.821438199999999}, {
  'zipCode': '79108',
  'north':   48.0710579,
  'east':    7.8728829,
  'south':   48.0087566,
  'west':    7.7899733
}, {'zipCode': '79110', 'north': 48.036046600000006, 'east': 7.8417555000000005, 'south': 48.0019353, 'west': 7.7797096}, {
  'zipCode': '79111',
  'north':   48.0264895,
  'east':    7.825467399999999,
  'south':   47.9640747,
  'west':    7.7309468
}, {'zipCode': '79112', 'north': 48.038767, 'east': 7.775114, 'south': 47.9592406, 'west': 7.662005499999999}, {
  'zipCode': '79114',
  'north':   48.0133239,
  'east':    7.8308244,
  'south':   47.9828499,
  'west':    7.798000700000001
}, {'zipCode': '79115', 'north': 48.0011234, 'east': 7.836765, 'south': 47.9766943, 'west': 7.8059096}, {
  'zipCode': '79117',
  'north':   48.0155692,
  'east':    7.9308444,
  'south':   47.9119195,
  'west':    7.8491997
}, {'zipCode': '79183', 'north': 48.1346298, 'east': 8.0345064, 'south': 48.0573332, 'west': 7.893829600000001}, {
  'zipCode': '79189',
  'north':   47.96850620000001,
  'east':    7.7245315,
  'south':   47.8809208,
  'west':    7.6374159
}, {'zipCode': '79194', 'north': 48.0573185, 'east': 7.921995500000001, 'south': 48.0099238, 'west': 7.849403}, {
  'zipCode': '79199',
  'north':   47.98895060000001,
  'east':    7.9934988,
  'south':   47.9362657,
  'west':    7.9139824
}, {'zipCode': '79206', 'north': 48.0910639, 'east': 7.6848783, 'south': 47.9541651, 'west': 7.568690899999999}, {
  'zipCode': '79211',
  'north':   48.0927396,
  'east':    7.924624200000001,
  'south':   48.0469042,
  'west':    7.844784999999999
}, {'zipCode': '79215', 'north': 48.2342219, 'east': 8.1878322, 'south': 48.1271668, 'west': 7.9813973}, {
  'zipCode': '79219',
  'north':   47.9013859,
  'east':    7.7686032,
  'south':   47.8313011,
  'west':    7.6766347
}, {'zipCode': '79224', 'north': 48.0484669, 'east': 7.7903396, 'south': 48.0143393, 'west': 7.733124}, {
  'zipCode': '79227',
  'north':   47.9805245,
  'east':    7.790467199999999,
  'south':   47.9357791,
  'west':    7.6764996
}, {'zipCode': '79232', 'north': 48.087343800000006, 'east': 7.824761600000001, 'south': 48.0320696, 'west': 7.7444538000000005}, {
  'zipCode': '79235',
  'north':   48.116890600000005,
  'east':    7.700481100000001,
  'south':   48.0531118,
  'west':    7.573472199999999
}, {'zipCode': '79238', 'north': 47.9539638, 'east': 7.8434159, 'south': 47.8740298, 'west': 7.7016913}, {
  'zipCode': '79241',
  'north':   48.0845314,
  'east':    7.7075655,
  'south':   48.0284124,
  'west':    7.6112185
}, {'zipCode': '79244', 'north': 47.9042985, 'east': 7.8917649, 'south': 47.801281, 'west': 7.755060899999999}, {
  'zipCode': '79249',
  'north':   47.9732172,
  'east':    7.8469023,
  'south':   47.9589691,
  'west':    7.8089081
}, {'zipCode': '79252', 'north': 48.0255453, 'east': 8.0168214, 'south': 47.9749185, 'west': 7.926268100000001}, {
  'zipCode': '79254',
  'north':   47.949162900000005,
  'east':    8.0397911,
  'south':   47.86673,
  'west':    7.8819255
}, {'zipCode': '79256', 'north': 48.005705, 'east': 8.0935571, 'south': 47.9210442, 'west': 7.982211}, {
  'zipCode': '79258',
  'north':   47.9602818,
  'east':    7.663360600000001,
  'south':   47.899824,
  'west':    7.5805585
}, {'zipCode': '79261', 'north': 48.19416, 'east': 8.0459128, 'south': 48.1064915, 'west': 7.955539400000001}, {
  'zipCode': '79263',
  'north':   48.13149060000001,
  'east':    8.1664289,
  'south':   48.0081333,
  'west':    8.0153791
}, {'zipCode': '79268', 'north': 48.0920468, 'east': 7.752843900000001, 'south': 48.0550036, 'west': 7.6847447}, {
  'zipCode': '79271',
  'north':   48.0684619,
  'east':    8.1019614,
  'south':   47.9937725,
  'west':    7.994966100000001
}, {'zipCode': '79274', 'north': 48.03972780000001, 'east': 8.1543817, 'south': 47.9524483, 'west': 8.0457793}, {
  'zipCode': '79276',
  'north':   48.0982896,
  'east':    7.845749500000001,
  'south':   48.0787278,
  'west':    7.7952772
}, {'zipCode': '79279', 'north': 48.0876423, 'east': 7.866017500000001, 'south': 48.0513614, 'west': 7.789809600000001}, {
  'zipCode': '79280',
  'north':   47.9624361,
  'east':    7.855681,
  'south':   47.943934,
  'west':    7.8091824
}, {'zipCode': '79282', 'north': 47.8680764, 'east': 7.729267400000001, 'south': 47.8470073, 'west': 7.6702425}, {
  'zipCode': '79283',
  'north':   47.936556,
  'east':    7.874976,
  'south':   47.8932094,
  'west':    7.766923300000001
}, {'zipCode': '79285', 'north': 47.9688458, 'east': 7.809513599999999, 'south': 47.9324067, 'west': 7.765566}, {
  'zipCode': '79286',
  'north':   48.0652777,
  'east':    8.015244300000003,
  'south':   48.0138124,
  'west':    7.916029400000001
}, {'zipCode': '79288', 'north': 48.0635657, 'east': 7.7530433, 'south': 48.0330295, 'west': 7.697270999999999}, {
  'zipCode': '79289',
  'north':   47.95129,
  'east':    7.8792842,
  'south':   47.9092743,
  'west':    7.835234000000001
}, {'zipCode': '79291', 'north': 48.0474697, 'east': 7.708420500000001, 'south': 47.9957505, 'west': 7.6568179}, {
  'zipCode': '79292',
  'north':   47.9481776,
  'east':    7.7808689,
  'south':   47.9285714,
  'west':    7.7395575
}, {'zipCode': '79294', 'north': 47.9425736, 'east': 7.8301265, 'south': 47.9220082, 'west': 7.7918523}, {
  'zipCode': '79295',
  'north':   47.8529749,
  'east':    7.7671337000000005,
  'south':   47.8024885,
  'west':    7.658862700000001
}, {'zipCode': '79297', 'north': 48.1649523, 'east': 8.0832274, 'south': 48.1201015, 'west': 7.9889946}, {
  'zipCode': '79299',
  'north':   47.954647,
  'east':    7.842704100000001,
  'south':   47.9208556,
  'west':    7.8030259
}, {'zipCode': '79312', 'north': 48.1599243, 'east': 7.9097335, 'south': 48.0852045, 'west': 7.814996800000001}, {
  'zipCode': '79331',
  'north':   48.1756342,
  'east':    7.872878100000001,
  'south':   48.0801039,
  'west':    7.754147900000001
}, {'zipCode': '79336', 'north': 48.2422138, 'east': 7.9177209, 'south': 48.1871096, 'west': 7.742437800000001}, {
  'zipCode': '79341',
  'north':   48.22842910000001,
  'east':    7.941413899999999,
  'south':   48.1596274,
  'west':    7.7103183
}, {'zipCode': '79346', 'north': 48.15900140000001, 'east': 7.728899700000001, 'south': 48.1076139, 'west': 7.638909800000001}, {
  'zipCode': '79348',
  'north':   48.206725500000005,
  'east':    7.985951699999999,
  'south':   48.1308731,
  'west':    7.8578008
}, {'zipCode': '79350', 'north': 48.1446338, 'east': 7.9484634000000005, 'south': 48.0801272, 'west': 7.881287400000001}, {
  'zipCode': '79353',
  'north':   48.1370296,
  'east':    7.7778147,
  'south':   48.1063084,
  'west':    7.698883400000001
}, {'zipCode': '79356', 'north': 48.1129837, 'east': 7.7615288, 'south': 48.0738763, 'west': 7.695703100000001}, {
  'zipCode': '79359',
  'north':   48.1900807,
  'east':    7.775810700000001,
  'south':   48.1283864,
  'west':    7.713421
}, {'zipCode': '79361', 'north': 48.1709079, 'east': 7.6486409, 'south': 48.1059377, 'west': 7.5769151}, {
  'zipCode': '79362',
  'north':   48.1955601,
  'east':    7.741571,
  'south':   48.15503650000001,
  'west':    7.674763799999999
}, {'zipCode': '79364', 'north': 48.1825599, 'east': 7.880780200000001, 'south': 48.1415313, 'west': 7.765096400000001}, {
  'zipCode': '79365',
  'north':   48.260511400000006,
  'east':    7.7465617,
  'south':   48.1955601,
  'west':    7.6721256
}, {'zipCode': '79367', 'north': 48.2346147, 'east': 7.7103183, 'south': 48.176316, 'west': 7.6370842}, {
  'zipCode': '79369',
  'north':   48.1957888,
  'east':    7.683440599999999,
  'south':   48.14544120000001,
  'west':    7.615305300000001
}, {'zipCode': '79379', 'north': 47.846309, 'east': 7.7022788, 'south': 47.7572273, 'west': 7.5715979}, {
  'zipCode': '79395',
  'north':   47.9014697,
  'east':    7.618259,
  'south':   47.74349,
  'west':    7.5297842
}, {'zipCode': '79400', 'north': 47.7478425, 'east': 7.718267699999999, 'south': 47.6586199, 'west': 7.5695615}, {
  'zipCode': '79410',
  'north':   47.8170738,
  'east':    7.783562999999999,
  'south':   47.7714699,
  'west':    7.645961000000001
}, {'zipCode': '79415', 'north': 47.744765, 'east': 7.6205624, 'south': 47.6993146, 'west': 7.514476499999999}, {
  'zipCode': '79418',
  'north':   47.7804814,
  'east':    7.7090131,
  'south':   47.7237408,
  'west':    7.5509983
}, {'zipCode': '79423', 'north': 47.90737380000001, 'east': 7.7081553000000005, 'south': 47.8571153, 'west': 7.581717000000001}, {
  'zipCode': '79424',
  'north':   47.8110908,
  'east':    7.6379358,
  'south':   47.7719999,
  'west':    7.5541253
}, {'zipCode': '79426', 'north': 47.8771972, 'east': 7.6749741, 'south': 47.8319704, 'west': 7.5953486}, {
  'zipCode': '79427',
  'north':   47.9120556,
  'east':    7.6747489,
  'south':   47.8779908,
  'west':    7.5977396
}, {'zipCode': '79429', 'north': 47.7905672, 'east': 7.752678199999999, 'south': 47.7141775, 'west': 7.6830316}, {
  'zipCode': '79539',
  'north':   47.6325674,
  'east':    7.701149900000001,
  'south':   47.5958359,
  'west':    7.6335003
}, {'zipCode': '79540', 'north': 47.6109759, 'east': 7.697138700000001, 'south': 47.5918984, 'west': 7.647332800000001}, {
  'zipCode': '79541',
  'north':   47.6773603,
  'east':    7.7290086,
  'south':   47.6033351,
  'west':    7.641936300000001
}, {'zipCode': '79576', 'north': 47.6343513, 'east': 7.649635699999999, 'south': 47.5768697, 'west': 7.566955400000001}, {
  'zipCode': '79585',
  'north':   47.7339225,
  'east':    7.7833218,
  'south':   47.6165538,
  'west':    7.7081078
}, {'zipCode': '79588', 'north': 47.707747, 'east': 7.6374162, 'south': 47.6320869, 'west': 7.5117461}, {
  'zipCode': '79589',
  'north':   47.6489976,
  'east':    7.6482399,
  'south':   47.6199077,
  'west':    7.6016866
}, {'zipCode': '79591', 'north': 47.644799, 'east': 7.6090516, 'south': 47.6199074, 'west': 7.5739856}, {
  'zipCode': '79592',
  'north':   47.6574476,
  'east':    7.6164845,
  'south':   47.6433947,
  'west':    7.588464100000001
}, {'zipCode': '79594', 'north': 47.6068651, 'east': 7.7233727, 'south': 47.571398, 'west': 7.6717419}, {
  'zipCode': '79595',
  'north':   47.6609228,
  'east':    7.6805121000000005,
  'south':   47.6357675,
  'west':    7.608858900000001
}, {'zipCode': '79597', 'north': 47.6696524, 'east': 7.639575500000001, 'south': 47.6461905, 'west': 7.607397500000001}, {
  'zipCode': '79599',
  'north':   47.6672455,
  'east':    7.6745696,
  'south':   47.6490509,
  'west':    7.6369852
}, {'zipCode': '79618', 'north': 47.6253256, 'east': 7.8410472, 'south': 47.5422251, 'west': 7.706725400000001}, {
  'zipCode': '79639',
  'north':   47.5755256,
  'east':    7.723855299999999,
  'south':   47.5324787,
  'west':    7.633955800000001
}, {'zipCode': '79650', 'north': 47.7301658, 'east': 7.9814655, 'south': 47.6181464, 'west': 7.782607400000001}, {
  'zipCode': '79664',
  'north':   47.6779637,
  'east':    7.954043499999999,
  'south':   47.5769395,
  'west':    7.871201
}, {'zipCode': '79669', 'north': 47.7556874, 'east': 7.909085600000001, 'south': 47.6903862, 'west': 7.8050269000000005}, {
  'zipCode': '79674',
  'north':   47.8803074,
  'east':    8.0224118,
  'south':   47.75734260000001,
  'west':    7.8863416
}, {'zipCode': '79677', 'north': 47.8389514, 'east': 7.9640179, 'south': 47.7335659, 'west': 7.8329376}, {
  'zipCode': '79682',
  'north':   47.7753718,
  'east':    8.0331929,
  'south':   47.6892774,
  'west':    7.9576775
}, {'zipCode': '79685', 'north': 47.7641343, 'east': 7.975125599999999, 'south': 47.7057958, 'west': 7.886909800000001}, {
  'zipCode': '79686',
  'north':   47.6890737,
  'east':    7.917171300000001,
  'south':   47.6421754,
  'west':    7.8686675
}, {'zipCode': '79688', 'north': 47.7030825, 'east': 7.846618500000001, 'south': 47.6671253, 'west': 7.8155608}, {
  'zipCode': '79689',
  'north':   47.6729186,
  'east':    7.796865,
  'south':   47.62178120000001,
  'west':    7.7580478
}, {'zipCode': '79692', 'north': 47.8225047, 'east': 7.8523893, 'south': 47.6750444, 'west': 7.7354385}, {
  'zipCode': '79694',
  'north':   47.8328242,
  'east':    7.9293368,
  'south':   47.7930512,
  'west':    7.8924783
}, {'zipCode': '79695', 'north': 47.8675314, 'east': 7.9185569, 'south': 47.8240903, 'west': 7.8550843}, {
  'zipCode': '79713',
  'north':   47.59245000000001,
  'east':    8.0143704,
  'south':   47.543775,
  'west':    7.906539500000001
}, {'zipCode': '79725', 'north': 47.6226867, 'east': 8.1101412, 'south': 47.5529487, 'west': 8.019422}, {
  'zipCode': '79730',
  'north':   47.6210783,
  'east':    8.0526078,
  'south':   47.5504054,
  'west':    7.993810900000001
}, {'zipCode': '79733', 'north': 47.7224402, 'east': 8.1004784, 'south': 47.6095979, 'west': 8.0065869}, {
  'zipCode': '79736',
  'north':   47.6560886,
  'east':    8.0260318,
  'south':   47.5804048,
  'west':    7.9319159
}, {'zipCode': '79737', 'north': 47.723120200000004, 'east': 8.0381077, 'south': 47.6324598, 'west': 7.9374443}, {
  'zipCode': '79739',
  'north':   47.637499,
  'east':    7.9029969,
  'south':   47.5820935,
  'west':    7.830035100000001
}, {'zipCode': '79761', 'north': 47.7060498, 'east': 8.3391939, 'south': 47.6050093, 'west': 8.1165081}, {
  'zipCode': '79771',
  'north':   47.6867049,
  'east':    8.4846206,
  'south':   47.6015207,
  'west':    8.3545212
}, {'zipCode': '79774', 'north': 47.6703075, 'east': 8.1675266, 'south': 47.5827505, 'west': 8.0707066}, {
  'zipCode': '79777',
  'north':   47.7793113,
  'east':    8.3785166,
  'south':   47.6711552,
  'west':    8.1929347
}, {'zipCode': '79780', 'north': 47.8325849, 'east': 8.5309327, 'south': 47.7095217, 'west': 8.3178698}, {
  'zipCode': '79787',
  'north':   47.6416999,
  'east':    8.3702166,
  'south':   47.6058025,
  'west':    8.2895315
}, {'zipCode': '79790', 'north': 47.6260826, 'east': 8.397689600000001, 'south': 47.5702777, 'west': 8.2539708}, {
  'zipCode': '79793',
  'north':   47.6871577,
  'east':    8.4070192,
  'south':   47.6274812,
  'west':    8.3205553
}, {'zipCode': '79798', 'north': 47.67270810000001, 'east': 8.6289299, 'south': 47.6263021, 'west': 8.5268985}, {
  'zipCode': '79801',
  'north':   47.6068579,
  'east':    8.4946936,
  'south':   47.5656586,
  'west':    8.3462439
}, {'zipCode': '79802', 'north': 47.6501087, 'east': 8.5413841, 'south': 47.6143701, 'west': 8.4452797}, {
  'zipCode': '79804',
  'north':   47.6324306,
  'east':    8.1910618,
  'south':   47.5937519,
  'west':    8.1432559
}, {'zipCode': '79805', 'north': 47.726655, 'east': 8.4207729, 'south': 47.6793636, 'west': 8.3573697}, {
  'zipCode': '79807',
  'north':   47.6446154,
  'east':    8.6051521,
  'south':   47.5956648,
  'west':    8.5429072
}, {'zipCode': '79809', 'north': 47.7044097, 'east': 8.2570848, 'south': 47.6420694, 'west': 8.125016}, {
  'zipCode': '79822',
  'north':   48.0014642,
  'east':    8.2719009,
  'south':   47.8839859,
  'west':    8.1094803
}, {'zipCode': '79837', 'north': 47.8585416, 'east': 8.1928775, 'south': 47.669633, 'west': 8.0217873}, {
  'zipCode': '79843',
  'north':   47.9438682,
  'east':    8.4401346,
  'south':   47.8390149,
  'west':    8.2675253
}, {'zipCode': '79848', 'north': 47.8540986, 'east': 8.418209, 'south': 47.7660749, 'west': 8.227541}, {
  'zipCode': '79853',
  'north':   47.9051461,
  'east':    8.2799046,
  'south':   47.8329228,
  'west':    8.1075522
}, {'zipCode': '79856', 'north': 47.9196297, 'east': 8.1452527, 'south': 47.8677469, 'west': 8.0217367}, {
  'zipCode': '79859',
  'north':   47.857711,
  'east':    8.2346566,
  'south':   47.7474167,
  'west':    8.0797176
}, {'zipCode': '79862', 'north': 47.7487364, 'east': 8.2240304, 'south': 47.6941697, 'west': 8.1291144}, {
  'zipCode': '79865',
  'north':   47.8250094,
  'east':    8.3094034,
  'south':   47.7321875,
  'west':    8.1921079
}, {'zipCode': '79868', 'north': 47.8855945, 'east': 8.1438109, 'south': 47.8413416, 'west': 7.9836811}, {
  'zipCode': '79871',
  'north':   47.9977588,
  'east':    8.3215206,
  'south':   47.9413983,
  'west':    8.1937433
}, {'zipCode': '79872', 'north': 47.84364320000001, 'east': 8.096943, 'south': 47.7583576, 'west': 7.997503600000001}, {
  'zipCode': '79874',
  'north':   47.9795448,
  'east':    8.1573445,
  'south':   47.9090796,
  'west':    8.0164253
}, {'zipCode': '79875', 'north': 47.7524823, 'east': 8.1453412, 'south': 47.6576379, 'west': 8.0578055}, {
  'zipCode': '79877',
  'north':   47.9472199,
  'east':    8.3119742,
  'south':   47.86437310000001,
  'west':    8.2425094
}, {'zipCode': '79879', 'north': 47.8565184, 'east': 8.4733287, 'south': 47.7810413, 'west': 8.3705325}, {
  'zipCode': '80331',
  'north':   48.1400845,
  'east':    11.5849362,
  'south':   48.1314374,
  'west':    11.5631981
}, {'zipCode': '80333', 'north': 48.1524187, 'east': 11.5802613, 'south': 48.1375718, 'west': 11.5580808}, {
  'zipCode': '80335',
  'north':   48.1553441,
  'east':    11.5675035,
  'south':   48.137662,
  'west':    11.5411358
}, {'zipCode': '80336', 'north': 48.1393294, 'east': 11.5682879, 'south': 48.1228193, 'west': 11.5437429}, {
  'zipCode': '80337',
  'north':   48.1330056,
  'east':    11.5672228,
  'south':   48.1204307,
  'west':    11.5416635
}, {'zipCode': '80339', 'north': 48.1429476, 'east': 11.5502604, 'south': 48.1263733, 'west': 11.5261252}, {
  'zipCode': '80469',
  'north':   48.1348336,
  'east':    11.5845126,
  'south':   48.1183947,
  'west':    11.5571153
}, {'zipCode': '80538', 'north': 48.1583393, 'east': 11.6050223, 'south': 48.1279739, 'west': 11.5780292}, {
  'zipCode': '80539',
  'north':   48.15373,
  'east':    11.5887869,
  'south':   48.135002400000005,
  'west':    11.5765295
}, {'zipCode': '80634', 'north': 48.1577691, 'east': 11.5369975, 'south': 48.1428037, 'west': 11.5201579}, {
  'zipCode': '80636',
  'north':   48.1596525,
  'east':    11.5539067,
  'south':   48.1407404,
  'west':    11.5346341
}, {'zipCode': '80637', 'north': 48.17121900000001, 'east': 11.5507887, 'south': 48.1463976, 'west': 11.5250759}, {
  'zipCode': '80638',
  'north':   48.1733499,
  'east':    11.5298914,
  'south':   48.1532778,
  'west':    11.4813081
}, {'zipCode': '80639', 'north': 48.1594846, 'east': 11.5325999, 'south': 48.1412436, 'west': 11.4817313}, {
  'zipCode': '80686',
  'north':   48.1399542,
  'east':    11.5319541,
  'south':   48.1236734,
  'west':    11.490444
}, {'zipCode': '80687', 'north': 48.1465716, 'east': 11.5286581, 'south': 48.13443660000001, 'west': 11.4816234}, {
  'zipCode': '80689',
  'north':   48.1417389,
  'east':    11.5029546,
  'south':   48.1236223,
  'west':    11.4647395
}, {'zipCode': '80796', 'north': 48.1689193, 'east': 11.5756762, 'south': 48.1565906, 'west': 11.5630145}, {
  'zipCode': '80797',
  'north':   48.17167390000001,
  'east':    11.5669647,
  'south':   48.1516178,
  'west':    11.5464895
}, {'zipCode': '80798', 'north': 48.15958630000001, 'east': 11.5721064, 'south': 48.1507496, 'west': 11.560800900000002}, {
  'zipCode': '80799',
  'north':   48.1567198,
  'east':    11.5823956,
  'south':   48.1476118,
  'west':    11.5678552
}, {'zipCode': '80801', 'north': 48.1634553, 'east': 11.5855268, 'south': 48.1547585, 'west': 11.5712365}, {
  'zipCode': '80802',
  'north':   48.1679538,
  'east':    11.6025483,
  'south':   48.1520632,
  'west':    11.579715
}, {'zipCode': '80803', 'north': 48.1676615, 'east': 11.5860272, 'south': 48.1610086, 'west': 11.5741172}, {
  'zipCode': '80804',
  'north':   48.1780514,
  'east':    11.5880492,
  'south':   48.1670295,
  'west':    11.5646411
}, {'zipCode': '80805', 'north': 48.1879313, 'east': 11.6292033, 'south': 48.1569633, 'west': 11.5852675}, {
  'zipCode': '80807',
  'north':   48.1907452,
  'east':    11.6123493,
  'south':   48.175572900000006,
  'west':    11.5656554
}, {'zipCode': '80809', 'north': 48.1902792, 'east': 11.5703243, 'south': 48.166104, 'west': 11.5353463}, {
  'zipCode': '80933',
  'north':   48.2248462,
  'east':    11.5646837,
  'south':   48.208306,
  'west':    11.5465059
}, {'zipCode': '80935', 'north': 48.2099353, 'east': 11.5701751, 'south': 48.18802800000001, 'west': 11.5371082}, {
  'zipCode': '80937',
  'north':   48.2285636,
  'east':    11.5874962,
  'south':   48.1902604,
  'west':    11.5630614
}, {'zipCode': '80939', 'north': 48.2292444, 'east': 11.6507277, 'south': 48.1794664, 'west': 11.5760461}, {
  'zipCode': '80992',
  'north':   48.1827436,
  'east':    11.5432743,
  'south':   48.1646909,
  'west':    11.4878454
}, {'zipCode': '80993', 'north': 48.1926584, 'east': 11.5399273, 'south': 48.1810904, 'west': 11.4992762}, {
  'zipCode': '80995',
  'north':   48.2481162,
  'east':    11.5634723,
  'south':   48.1878109,
  'west':    11.4750248
}, {'zipCode': '80997', 'north': 48.2195538, 'east': 11.5086598, 'south': 48.1660142, 'west': 11.4594747}, {
  'zipCode': '80999',
  'north':   48.2117169,
  'east':    11.473448,
  'south':   48.173787,
  'west':    11.4303891
}, {'zipCode': '81241', 'north': 48.1529071, 'east': 11.4856465, 'south': 48.1284188, 'west': 11.4435469}, {
  'zipCode': '81243',
  'north':   48.15556,
  'east':    11.454342300000002,
  'south':   48.1334827,
  'west':    11.4170975
}, {'zipCode': '81245', 'north': 48.1750214, 'east': 11.4856465, 'south': 48.1465716, 'west': 11.4064633}, {
  'zipCode': '81247',
  'north':   48.17945100000001,
  'east':    11.4916459,
  'south':   48.1569955,
  'west':    11.4385323
}, {'zipCode': '81249', 'north': 48.2052394, 'east': 11.441887600000001, 'south': 48.1251677, 'west': 11.360777}, {
  'zipCode': '81369',
  'north':   48.12206690000001,
  'east':    11.5419044,
  'south':   48.1017399,
  'west':    11.5185921
}, {'zipCode': '81371', 'north': 48.1244424, 'east': 11.5589952, 'south': 48.1001387, 'west': 11.539486100000001}, {
  'zipCode': '81373',
  'north':   48.1322354,
  'east':    11.5445833,
  'south':   48.114151,
  'west':    11.5171781
}, {'zipCode': '81375', 'north': 48.1255273, 'east': 11.5025835, 'south': 48.1119863, 'west': 11.4647619}, {
  'zipCode': '81377',
  'north':   48.125818,
  'east':    11.5201451,
  'south':   48.0958473,
  'west':    11.4629691
}, {'zipCode': '81379', 'north': 48.1199143, 'east': 11.5613908, 'south': 48.0850338, 'west': 11.5015955}, {
  'zipCode': '81475',
  'north':   48.10358900000001,
  'east':    11.5040448,
  'south':   48.076495,
  'west':    11.470851
}, {'zipCode': '81476', 'north': 48.1013993, 'east': 11.5071467, 'south': 48.0735139, 'west': 11.4857966}, {
  'zipCode': '81477',
  'north':   48.0986151,
  'east':    11.5225957,
  'south':   48.0692942,
  'west':    11.4916093
}, {'zipCode': '81479', 'north': 48.0906242, 'east': 11.5451234, 'south': 48.0616244, 'west': 11.5025769}, {
  'zipCode': '81539',
  'north':   48.115916,
  'east':    11.6046608,
  'south':   48.1042843,
  'west':    11.5730445
}, {'zipCode': '81541', 'north': 48.1283331, 'east': 11.6044477, 'south': 48.1141063, 'west': 11.5712965}, {
  'zipCode': '81543',
  'north':   48.124632,
  'east':    11.5772592,
  'south':   48.0919549,
  'west':    11.5505513
}, {'zipCode': '81545', 'north': 48.0995806, 'east': 11.5758717, 'south': 48.0681278, 'west': 11.541326400000003}, {
  'zipCode': '81547',
  'north':   48.1115985,
  'east':    11.5882977,
  'south':   48.089336800000005,
  'west':    11.5615457
}, {'zipCode': '81549', 'north': 48.1125943, 'east': 11.616311, 'south': 48.0849751, 'west': 11.5822246}, {
  'zipCode': '81667',
  'north':   48.1365297,
  'east':    11.6120269,
  'south':   48.1244652,
  'west':    11.586525000000002
}, {'zipCode': '81669', 'north': 48.131746, 'east': 11.6182287, 'south': 48.1089499, 'west': 11.5825324}, {
  'zipCode': '81671',
  'north':   48.13153220000001,
  'east':    11.6416827,
  'south':   48.1151278,
  'west':    11.6010878
}, {'zipCode': '81673', 'north': 48.1341709, 'east': 11.6478854, 'south': 48.1169866, 'west': 11.6101387}, {
  'zipCode': '81675',
  'north':   48.1505605,
  'east':    11.6166043,
  'south':   48.1317262,
  'west':    11.5918459
}, {'zipCode': '81677', 'north': 48.1471678, 'east': 11.6654504, 'south': 48.1320302, 'west': 11.6070837}, {
  'zipCode': '81679',
  'north':   48.1569669,
  'east':    11.6187845,
  'south':   48.13963580000001,
  'west':    11.5981671
}, {'zipCode': '81735', 'north': 48.1190326, 'east': 11.666577400000001, 'south': 48.0993856, 'west': 11.6170983}, {
  'zipCode': '81737',
  'north':   48.1132619,
  'east':    11.6626159,
  'south':   48.0859672,
  'west':    11.6133549
}, {'zipCode': '81739', 'north': 48.1012221, 'east': 11.6887349, 'south': 48.0774915, 'west': 11.632997}, {
  'zipCode': '81825',
  'north':   48.1320302,
  'east':    11.6859241,
  'south':   48.1040215,
  'west':    11.6407506
}, {'zipCode': '81827', 'north': 48.1211211, 'east': 11.7146415, 'south': 48.0935434, 'west': 11.6643793}, {
  'zipCode': '81829',
  'north':   48.1481132,
  'east':    11.7229083,
  'south':   48.1193629,
  'west':    11.6478854
}, {'zipCode': '81925', 'north': 48.1804212, 'east': 11.6366722, 'south': 48.1457455, 'west': 11.6050223}, {
  'zipCode': '81927',
  'north':   48.1778549,
  'east':    11.6479447,
  'south':   48.1430871,
  'west':    11.627725
}, {'zipCode': '81929', 'north': 48.1828884, 'east': 11.6960995, 'south': 48.1385199, 'west': 11.6328303}, {
  'zipCode': '82008',
  'north':   48.087345600000006,
  'east':    11.6502625,
  'south':   48.0523402,
  'west':    11.5930057
}, {'zipCode': '82024', 'north': 48.0552141, 'east': 11.673783, 'south': 48.003067, 'west': 11.5846936}, {
  'zipCode': '82031',
  'north':   48.068462,
  'east':    11.5600545,
  'south':   48.0239361,
  'west':    11.5092503
}, {'zipCode': '82041', 'north': 48.0455173, 'east': 11.6292525, 'south': 47.9612975, 'west': 11.5394778}, {
  'zipCode': '82049',
  'north':   48.0780039,
  'east':    11.5442887,
  'south':   48.0359103,
  'west':    11.4879948
}, {'zipCode': '82054', 'north': 48.0062222, 'east': 11.7023882, 'south': 47.9199719, 'west': 11.5664402}, {
  'zipCode': '82057',
  'north':   47.9802069,
  'east':    11.4630526,
  'south':   47.9083671,
  'west':    11.3892562
}, {'zipCode': '82061', 'north': 48.1058319, 'east': 11.508757800000001, 'south': 48.0094929, 'west': 11.4034094}, {
  'zipCode': '82064',
  'north':   48.0938795,
  'east':    11.5976571,
  'south':   47.9492277,
  'west':    11.4712422
}, {'zipCode': '82065', 'north': 48.0367463, 'east': 11.5095386, 'south': 47.9824257, 'west': 11.459796}, {
  'zipCode': '82067',
  'north':   47.9859314,
  'east':    11.4754994,
  'south':   47.9568667,
  'west':    11.427201
}, {'zipCode': '82069', 'north': 48.01324, 'east': 11.4680833, 'south': 47.9791362, 'west': 11.4039723}, {
  'zipCode': '82110',
  'north':   48.1586746,
  'east':    11.4128816,
  'south':   48.1057036,
  'west':    11.305967900000002
}, {'zipCode': '82131', 'north': 48.1013301, 'east': 11.4203054, 'south': 48.0276811, 'west': 11.2759891}, {
  'zipCode': '82140',
  'north':   48.2371537,
  'east':    11.40485,
  'south':   48.1792862,
  'west':    11.283190400000002
}, {'zipCode': '82152', 'north': 48.11849200000001, 'east': 11.4636007, 'south': 48.0784031, 'west': 11.3162968}, {
  'zipCode': '82166',
  'north':   48.1375559,
  'east':    11.465648,
  'south':   48.106755,
  'west':    11.3999608
}, {'zipCode': '82178', 'north': 48.18832840000001, 'east': 11.3752827, 'south': 48.1365978, 'west': 11.317475}, {
  'zipCode': '82194',
  'north':   48.2068346,
  'east':    11.3935928,
  'south':   48.177571,
  'west':    11.3537558
}, {'zipCode': '82205', 'north': 48.1364374, 'east': 11.348537, 'south': 48.0867453, 'west': 11.2093764}, {
  'zipCode': '82211',
  'north':   48.0462345,
  'east':    11.2128151,
  'south':   47.957283800000006,
  'west':    11.1161899
}, {'zipCode': '82216', 'north': 48.2744894, 'east': 11.331887100000001, 'south': 48.1928348, 'west': 11.1871798}, {
  'zipCode': '82223',
  'north':   48.180909,
  'east':    11.3405144,
  'south':   48.1513627,
  'west':    11.2881544
}, {'zipCode': '82229', 'north': 48.075127200000004, 'east': 11.2806938, 'south': 47.9969603, 'west': 11.1616705}, {
  'zipCode': '82234',
  'north':   48.1017529,
  'east':    11.2963819,
  'south':   48.0406109,
  'west':    11.2200794
}, {'zipCode': '82237', 'north': 48.1153564, 'east': 11.2354312, 'south': 48.048511, 'west': 11.167374200000001}, {
  'zipCode': '82239',
  'north':   48.1655117,
  'east':    11.33124,
  'south':   48.1195939,
  'west':    11.2233053
}, {'zipCode': '82256', 'north': 48.2127557, 'east': 11.2895746, 'south': 48.1460919, 'west': 11.1722731}, {
  'zipCode': '82266',
  'north':   48.1111186,
  'east':    11.1868743,
  'south':   48.0319793,
  'west':    11.1161899
}, {'zipCode': '82269', 'north': 48.1730884, 'east': 11.0685256, 'south': 48.1036838, 'west': 10.9451553}, {
  'zipCode': '82272',
  'north':   48.1973728,
  'east':    11.135881,
  'south':   48.1200482,
  'west':    10.9930275
}, {'zipCode': '82275', 'north': 48.1981545, 'east': 11.329965, 'south': 48.158292, 'west': 11.2612355}, {
  'zipCode': '82276',
  'north':   48.2138584,
  'east':    11.1430105,
  'south':   48.1670125,
  'west':    11.0584961
}, {'zipCode': '82278', 'north': 48.2573121, 'east': 11.1183018, 'south': 48.1998576, 'west': 11.0372223}, {
  'zipCode': '82279',
  'north':   48.0973622,
  'east':    11.1383183,
  'south':   48.06333920000001,
  'west':    11.094196
}, {'zipCode': '82281', 'north': 48.2967233, 'east': 11.232544800000001, 'south': 48.2187753, 'west': 11.1318463}, {
  'zipCode': '82284',
  'north':   48.1483593,
  'east':    11.2018647,
  'south':   48.0968442,
  'west':    11.133646400000002
}, {'zipCode': '82285', 'north': 48.2372316, 'east': 11.1366672, 'south': 48.2076215, 'west': 11.0862368}, {
  'zipCode': '82287',
  'north':   48.1945769,
  'east':    11.1677591,
  'south':   48.1383441,
  'west':    11.1023421
}, {'zipCode': '82288', 'north': 48.1376545, 'east': 11.1507197, 'south': 48.1060897, 'west': 11.1029775}, {
  'zipCode': '82290',
  'north':   48.1824408,
  'east':    11.2050605,
  'south':   48.1387214,
  'west':    11.1490857
}, {'zipCode': '82291', 'north': 48.2344161, 'east': 11.2030687, 'south': 48.1777753, 'west': 11.1155741}, {
  'zipCode': '82293',
  'north':   48.2814973,
  'east':    11.1457832,
  'south':   48.2315608,
  'west':    11.0596036
}, {'zipCode': '82294', 'north': 48.2576652, 'east': 11.1703757, 'south': 48.2262285, 'west': 11.116976}, {
  'zipCode': '82296',
  'north':   48.1571649,
  'east':    11.2344867,
  'south':   48.1121699,
  'west':    11.1689663
}, {'zipCode': '82297', 'north': 48.2311367, 'east': 11.0613685, 'south': 48.1917592, 'west': 10.977892}, {
  'zipCode': '82299',
  'north':   48.1313119,
  'east':    11.1394045,
  'south':   48.0877067,
  'west':    11.055532500000002
}, {'zipCode': '82319', 'north': 48.039201, 'east': 11.4334957, 'south': 47.9689867, 'west': 11.2236355}, {
  'zipCode': '82327',
  'north':   47.956928,
  'east':    11.3093089,
  'south':   47.8750166,
  'west':    11.184085700000002
}, {'zipCode': '82335', 'north': 47.9999878, 'east': 11.4289524, 'south': 47.91935070000001, 'west': 11.3179778}, {
  'zipCode': '82340',
  'north':   47.9705123,
  'east':    11.3208961,
  'south':   47.9130631,
  'west':    11.2612121
}, {'zipCode': '82343', 'north': 47.9988784, 'east': 11.3409804, 'south': 47.9439467, 'west': 11.2306484}, {
  'zipCode': '82346',
  'north':   48.0226469,
  'east':    11.245446,
  'south':   47.9213969,
  'west':    11.1318968
}, {'zipCode': '82347', 'north': 47.8979309, 'east': 11.3174627, 'south': 47.8396621, 'west': 11.241373}, {
  'zipCode': '82349',
  'north':   48.0930332,
  'east':    11.359276,
  'south':   48.0697272,
  'west':    11.3132077
}, {'zipCode': '82362', 'north': 47.8867544, 'east': 11.2380759, 'south': 47.7987768, 'west': 11.061891000000001}, {
  'zipCode': '82377',
  'north':   47.8000073,
  'east':    11.4304095,
  'south':   47.7282512,
  'west':    11.3360907
}, {'zipCode': '82380', 'north': 47.8401033, 'east': 11.1218688, 'south': 47.7498968, 'west': 11.0144558}, {
  'zipCode': '82383',
  'north':   47.8338352,
  'east':    11.0513558,
  'south':   47.7708878,
  'west':    10.9584887
}, {'zipCode': '82386', 'north': 47.7944435, 'east': 11.1898606, 'south': 47.7281901, 'west': 11.0595803}, {
  'zipCode': '82387',
  'north':   47.7880159,
  'east':    11.3599686,
  'south':   47.7360331,
  'west':    11.2502007
}, {'zipCode': '82389', 'north': 47.7748193, 'east': 11.0751741, 'south': 47.715466, 'west': 10.947482200000001}, {
  'zipCode': '82390',
  'north':   47.8119425,
  'east':    11.272195,
  'south':   47.7608629,
  'west':    11.1790293
}, {'zipCode': '82392', 'north': 47.7504863, 'east': 11.3217912, 'south': 47.7083027, 'west': 11.257072}, {
  'zipCode': '82393',
  'north':   47.817664,
  'east':    11.3782012,
  'south':   47.7609078,
  'west':    11.275613
}, {'zipCode': '82395', 'north': 47.7759833, 'east': 11.2678383, 'south': 47.7167758, 'west': 11.189191}, {
  'zipCode': '82396',
  'north':   47.9548811,
  'east':    11.2360533,
  'south':   47.8851558,
  'west':    11.1205365
}, {'zipCode': '82398', 'north': 47.8497998, 'east': 11.1904429, 'south': 47.7723244, 'west': 11.0625017}, {
  'zipCode': '82399',
  'north':   47.9296178,
  'east':    11.1441573,
  'south':   47.8826975,
  'west':    11.0599993
}, {'zipCode': '82401', 'north': 47.7630875, 'east': 11.0450862, 'south': 47.6944523, 'west': 10.9057326}, {
  'zipCode': '82402',
  'north':   47.8581179,
  'east':    11.329793,
  'south':   47.7862019,
  'west':    11.2110892
}, {'zipCode': '82404', 'north': 47.7412715, 'east': 11.3747902, 'south': 47.6929742, 'west': 11.3053063}, {
  'zipCode': '82405',
  'north':   47.909145,
  'east':    11.0710505,
  'south':   47.8135587,
  'west':    10.968732400000002
}, {'zipCode': '82407', 'north': 47.8977628, 'east': 11.2502676, 'south': 47.8432586, 'west': 11.0774705}, {
  'zipCode': '82409',
  'north':   47.7217734,
  'east':    10.9913354,
  'south':   47.615976200000006,
  'west':    10.9026995
}, {'zipCode': '82418', 'north': 47.7266063, 'east': 11.2914663, 'south': 47.62225, 'west': 11.1218438}, {
  'zipCode': '82431',
  'north':   47.6985852,
  'east':    11.4339237,
  'south':   47.6180618,
  'west':    11.3165432
}, {'zipCode': '82432', 'north': 47.6201203, 'east': 11.3934268, 'south': 47.55271960000001, 'west': 11.2628115}, {
  'zipCode': '82433',
  'north':   47.6982598,
  'east':    11.1290776,
  'south':   47.6303829,
  'west':    11.0276513
}, {'zipCode': '82435', 'north': 47.7160503, 'east': 11.0492199, 'south': 47.6739216, 'west': 10.9649147}, {
  'zipCode': '82436',
  'north':   47.7685031,
  'east':    11.199227,
  'south':   47.7147879,
  'west':    11.0915697
}, {'zipCode': '82438', 'north': 47.640654, 'east': 11.2639912, 'south': 47.5443947, 'west': 11.1147304}, {
  'zipCode': '82439',
  'north':   47.7154418,
  'east':    11.347992800000002,
  'south':   47.6522167,
  'west':    11.2463681
}, {'zipCode': '82441', 'north': 47.6693987, 'east': 11.2889534, 'south': 47.5869503, 'west': 11.176707}, {
  'zipCode': '82442',
  'north':   47.6916363,
  'east':    11.053743,
  'south':   47.5824616,
  'west':    10.915819
}, {'zipCode': '82444', 'north': 47.6777887, 'east': 11.3533729, 'south': 47.61339550000001, 'west': 11.2709025}, {
  'zipCode': '82445',
  'north':   47.6592528,
  'east':    11.1672851,
  'south':   47.5913864,
  'west':    11.0770643
}, {'zipCode': '82447', 'north': 47.7326627, 'east': 11.2247739, 'south': 47.7104685, 'west': 11.178966}, {
  'zipCode': '82449',
  'north':   47.7370507,
  'east':    11.175312800000002,
  'south':   47.6782669,
  'west':    11.0433494
}, {'zipCode': '82467', 'north': 47.5872089, 'east': 11.2230507, 'south': 47.3933482, 'west': 10.8693855}, {
  'zipCode': '82475',
  'north':   47.4212848,
  'east':    11.0164855,
  'south':   47.3956618,
  'west':    10.968792
}, {'zipCode': '82481', 'north': 47.5319677, 'east': 11.421433, 'south': 47.39510760000001, 'west': 11.1567072}, {
  'zipCode': '82487',
  'north':   47.628944,
  'east':    11.1247517,
  'south':   47.5719414,
  'west':    10.987454
}, {'zipCode': '82488', 'north': 47.5839961, 'east': 11.118088, 'south': 47.55928490000001, 'west': 10.92667}, {
  'zipCode': '82490',
  'north':   47.5528575,
  'east':    11.1757514,
  'south':   47.5117332,
  'west':    11.0326546
}, {'zipCode': '82491', 'north': 47.4847451, 'east': 11.065661, 'south': 47.4184081, 'west': 10.9324993}, {
  'zipCode': '82493',
  'north':   47.5014886,
  'east':    11.2530523,
  'south':   47.4417973,
  'west':    11.1576315
}, {'zipCode': '82494', 'north': 47.5471813, 'east': 11.2959635, 'south': 47.4871086, 'west': 11.1975024}, {
  'zipCode': '82496',
  'north':   47.5825301,
  'east':    11.193344,
  'south':   47.5426772,
  'west':    11.0687164
}, {'zipCode': '82497', 'north': 47.6433629, 'east': 11.0789344, 'south': 47.5819637, 'west': 10.9716582}, {
  'zipCode': '82499',
  'north':   47.58096880000001,
  'east':    11.3346173,
  'south':   47.5052138,
  'west':    11.2226094
}, {'zipCode': '82515', 'north': 47.9345715, 'east': 11.4512134, 'south': 47.8935866, 'west': 11.3982209}, {
  'zipCode': '82538',
  'north':   47.9009906,
  'east':    11.5214658,
  'south':   47.8417242,
  'west':    11.4104657
}, {'zipCode': '82541', 'north': 47.9353794, 'east': 11.4192271, 'south': 47.7926875, 'west': 11.3052748}, {
  'zipCode': '82544',
  'north':   47.9748968,
  'east':    11.6054347,
  'south':   47.8771298,
  'west':    11.4311036
}, {'zipCode': '82547', 'north': 47.8876583, 'east': 11.455429, 'south': 47.7891904, 'west': 11.3589275}, {
  'zipCode': '82549',
  'north':   47.8565274,
  'east':    11.5229005,
  'south':   47.7689682,
  'west':    11.4034095
}, {'zipCode': '83022', 'north': 47.86801350000001, 'east': 12.15217, 'south': 47.8436576, 'west': 12.1075597}, {
  'zipCode': '83024',
  'north':   47.8870322,
  'east':    12.1389312,
  'south':   47.8518851,
  'west':    12.076473
}, {'zipCode': '83026', 'north': 47.8544748, 'east': 12.1531667, 'south': 47.8091223, 'west': 12.0433534}, {
  'zipCode': '83043',
  'north':   47.9123664,
  'east':    12.0543851,
  'south':   47.81821080000001,
  'west':    11.9457614
}, {'zipCode': '83052', 'north': 47.9431047, 'east': 11.9815837, 'south': 47.8424829, 'west': 11.8765455}, {
  'zipCode': '83059',
  'north':   47.8705,
  'east':    12.0841715,
  'south':   47.8162512,
  'west':    12.0021311
}, {'zipCode': '83064', 'north': 47.81821080000001, 'east': 12.131900900000002, 'south': 47.7394614, 'west': 12.0186372}, {
  'zipCode': '83071',
  'north':   47.8961086,
  'east':    12.2484852,
  'south':   47.8356317,
  'west':    12.1336956
}, {'zipCode': '83075', 'north': 47.8341476, 'east': 12.0675553, 'south': 47.7358558, 'west': 11.9160576}, {
  'zipCode': '83080',
  'north':   47.7013745,
  'east':    12.2018113,
  'south':   47.6277273,
  'west':    12.020308
}, {'zipCode': '83083', 'north': 47.876003, 'east': 12.292232, 'south': 47.8096337, 'west': 12.1687867}, {
  'zipCode': '83088',
  'north':   47.6549038,
  'east':    12.2083007,
  'south':   47.6008901,
  'west':    12.0283507
}, {'zipCode': '83093', 'north': 47.9389685, 'east': 12.3646698, 'south': 47.8659013, 'west': 12.2330646}, {
  'zipCode': '83098',
  'north':   47.7600677,
  'east':    12.1327261,
  'south':   47.6985416,
  'west':    12.0087081
}, {'zipCode': '83101', 'north': 47.840598, 'east': 12.2510149, 'south': 47.779509, 'west': 12.128575}, {
  'zipCode': '83104',
  'north':   47.9952567,
  'east':    12.1111141,
  'south':   47.8906916,
  'west':    11.92661
}, {'zipCode': '83109', 'north': 47.937949, 'east': 12.1035846, 'south': 47.8641215, 'west': 12.0252778}, {
  'zipCode': '83112',
  'north':   47.8373164,
  'east':    12.3403507,
  'south':   47.7472157,
  'west':    12.2359929
}, {'zipCode': '83115', 'north': 47.8062899, 'east': 12.1864689, 'south': 47.7571142, 'west': 12.1253493}, {
  'zipCode': '83119',
  'north':   48.053828,
  'east':    12.4682185,
  'south':   47.9762934,
  'west':    12.3326584
}, {'zipCode': '83122', 'north': 47.7914689, 'east': 12.2583602, 'south': 47.71804620000001, 'west': 12.1474098}, {
  'zipCode': '83123',
  'north':   48.0576184,
  'east':    12.357822,
  'south':   47.9647408,
  'west':    12.2609795
}, {'zipCode': '83125', 'north': 47.960992, 'east': 12.4419582, 'south': 47.9008261, 'west': 12.3451541}, {
  'zipCode': '83126',
  'north':   47.7453412,
  'east':    12.164735,
  'south':   47.6784209,
  'west':    12.0444878
}, {'zipCode': '83128', 'north': 47.9824522, 'east': 12.3056672, 'south': 47.9199323, 'west': 12.2240927}, {
  'zipCode': '83129',
  'north':   47.9708124,
  'east':    12.3512432,
  'south':   47.923123700000005,
  'west':    12.2860044
}, {'zipCode': '83131', 'north': 47.7590415, 'east': 12.2310979, 'south': 47.7004961, 'west': 12.1292449}, {
  'zipCode': '83132',
  'north':   48.0055697,
  'east':    12.4287878,
  'south':   47.9418441,
  'west':    12.326961
}, {'zipCode': '83134', 'north': 47.9121445, 'east': 12.2377636, 'south': 47.8683482, 'west': 12.1392136}, {
  'zipCode': '83135',
  'north':   47.9689471,
  'east':    12.1566203,
  'south':   47.8819117,
  'west':    12.0819158
}, {'zipCode': '83137', 'north': 48.0098882, 'east': 12.2851486, 'south': 47.9693983, 'west': 12.209916800000002}, {
  'zipCode': '83139',
  'north':   47.959526,
  'east':    12.2679599,
  'south':   47.8811717,
  'west':    12.1960265
}, {'zipCode': '83209', 'north': 47.8883066, 'east': 12.4275222, 'south': 47.8226677, 'west': 12.2817113}, {
  'zipCode': '83224',
  'north':   47.8203678,
  'east':    12.5433392,
  'south':   47.7499207,
  'west':    12.3755469
}, {'zipCode': '83229', 'north': 47.8086268, 'east': 12.3816293, 'south': 47.6783215, 'west': 12.2412609}, {
  'zipCode': '83233',
  'north':   47.8366424,
  'east':    12.434963700000003,
  'south':   47.7788266,
  'west':    12.3106932
}, {'zipCode': '83236', 'north': 47.8612435, 'east': 12.5176477, 'south': 47.79485960000001, 'west': 12.4250178}, {
  'zipCode': '83242',
  'north':   47.7094365,
  'east':    12.604817300000002,
  'south':   47.6251006,
  'west':    12.4389352
}, {'zipCode': '83246', 'north': 47.7512224, 'east': 12.5312755, 'south': 47.6921932, 'west': 12.4082348}, {
  'zipCode': '83250',
  'north':   47.771577,
  'east':    12.4987429,
  'south':   47.74339040000001,
  'west':    12.4039029
}, {'zipCode': '83253', 'north': 47.9001988, 'east': 12.3733195, 'south': 47.8517108, 'west': 12.2796208}, {
  'zipCode': '83254',
  'north':   47.9083884,
  'east':    12.411839,
  'south':   47.8707972,
  'west':    12.3481936
}, {'zipCode': '83256', 'north': 47.9334856, 'east': 12.5341212, 'south': 47.834049, 'west': 12.407151200000001}, {
  'zipCode': '83257',
  'north':   47.9277362,
  'east':    12.4437259,
  'south':   47.8747035,
  'west':    12.3930531
}, {'zipCode': '83259', 'north': 47.7547714, 'east': 12.4503485, 'south': 47.68384210000001, 'west': 12.3320818}, {
  'zipCode': '83278',
  'north':   47.9370981,
  'east':    12.7026553,
  'south':   47.8320797,
  'west':    12.5785909
}, {'zipCode': '83301', 'north': 47.9794813, 'east': 12.642756, 'south': 47.9222987, 'west': 12.5255937}, {
  'zipCode': '83308',
  'north':   48.0699348,
  'east':    12.6186272,
  'south':   47.9862473,
  'west':    12.4979762
}, {'zipCode': '83313', 'north': 47.8471957, 'east': 12.74307, 'south': 47.7679677, 'west': 12.5988645}, {
  'zipCode': '83317',
  'north':   47.8915312,
  'east':    12.914325300000002,
  'south':   47.8091866,
  'west':    12.7358805
}, {'zipCode': '83324', 'north': 47.795669, 'east': 12.7250407, 'south': 47.6701222, 'west': 12.5120414}, {
  'zipCode': '83329',
  'north':   47.9884812,
  'east':    12.8098358,
  'south':   47.8841429,
  'west':    12.6499559
}, {'zipCode': '83334', 'north': 47.8103704, 'east': 12.848623800000002, 'south': 47.7328681, 'west': 12.7105907}, {
  'zipCode': '83339',
  'north':   47.954273,
  'east':    12.5846112,
  'south':   47.8633725,
  'west':    12.4905638
}, {'zipCode': '83342', 'north': 48.119247, 'east': 12.5843817, 'south': 48.042937, 'west': 12.4360123}, {
  'zipCode': '83346',
  'north':   47.8352265,
  'east':    12.6290723,
  'south':   47.7542881,
  'west':    12.5194953
}, {'zipCode': '83349', 'north': 48.049497, 'east': 12.7061879, 'south': 47.956519, 'west': 12.5950976}, {
  'zipCode': '83352',
  'north':   48.028361,
  'east':    12.5479715,
  'south':   47.9685329,
  'west':    12.4450931
}, {'zipCode': '83355', 'north': 47.8934843, 'east': 12.610731300000001, 'south': 47.798004500000005, 'west': 12.4991779}, {
  'zipCode': '83358',
  'north':   47.9587192,
  'east':    12.4946765,
  'south':   47.9175823,
  'west':    12.4138227
}, {'zipCode': '83361', 'north': 48.0664083, 'east': 12.5181061, 'south': 48.0135544, 'west': 12.4170699}, {
  'zipCode': '83362',
  'north':   47.89619580000001,
  'east':    12.748285500000001,
  'south':   47.8398254,
  'west':    12.6498539
}, {'zipCode': '83364', 'north': 47.851268, 'east': 12.7876685, 'south': 47.8040834, 'west': 12.7283224}, {
  'zipCode': '83365',
  'north':   47.9381831,
  'east':    12.6351596,
  'south':   47.890332,
  'west':    12.5427933
}, {'zipCode': '83367', 'north': 47.9384908, 'east': 12.886727700000002, 'south': 47.8767149, 'west': 12.7674202}, {
  'zipCode': '83368',
  'north':   47.9895002,
  'east':    12.6055214,
  'south':   47.9648267,
  'west':    12.55797
}, {'zipCode': '83370', 'north': 47.9987314, 'east': 12.5245962, 'south': 47.9454753, 'west': 12.4060043}, {
  'zipCode': '83371',
  'north':   47.9968963,
  'east':    12.5855769,
  'south':   47.9743678,
  'west':    12.5411929
}, {'zipCode': '83373', 'north': 48.0138634, 'east': 12.772016100000002, 'south': 47.94913840000001, 'west': 12.6696106}, {
  'zipCode': '83374',
  'north':   47.960386,
  'east':    12.6644915,
  'south':   47.9188745,
  'west':    12.5828151
}, {'zipCode': '83376', 'north': 47.9792683, 'east': 12.5323, 'south': 47.9361568, 'west': 12.4783468}, {
  'zipCode': '83377',
  'north':   47.8609375,
  'east':    12.6309428,
  'south':   47.8253553,
  'west':    12.5625789
}, {'zipCode': '83379', 'north': 47.9227678, 'east': 12.7511883, 'south': 47.8793934, 'west': 12.6710435}, {
  'zipCode': '83395',
  'north':   47.8686827,
  'east':    13.0041715,
  'south':   47.8190542,
  'west':    12.9403097
}, {'zipCode': '83404', 'north': 47.8513512, 'east': 12.978941, 'south': 47.7793686, 'west': 12.8734189}, {
  'zipCode': '83410',
  'north':   47.9626028,
  'east':    12.9667598,
  'south':   47.8940164,
  'west':    12.8481403
}, {'zipCode': '83413', 'north': 48.0435642, 'east': 12.879433, 'south': 47.9484867, 'west': 12.7699837}, {
  'zipCode': '83416',
  'north':   47.9172934,
  'east':    12.988052900000001,
  'south':   47.8420554,
  'west':    12.8749457
}, {'zipCode': '83417', 'north': 47.9748743, 'east': 12.8760452, 'south': 47.9219867, 'west': 12.7558318}, {
  'zipCode': '83435',
  'north':   47.7722781,
  'east':    12.944815700000001,
  'south':   47.6809196,
  'west':    12.7972223
}, {'zipCode': '83451', 'north': 47.8066965, 'east': 12.9399981, 'south': 47.7445234, 'west': 12.8355796}, {
  'zipCode': '83454',
  'north':   47.8351056,
  'east':    12.9106636,
  'south':   47.7562565,
  'west':    12.7639721
}, {'zipCode': '83457', 'north': 47.7391834, 'east': 12.933767500000002, 'south': 47.6884084, 'west': 12.8795504}, {
  'zipCode': '83458',
  'north':   47.7566929,
  'east':    12.8980037,
  'south':   47.6272543,
  'west':    12.6958024
}, {'zipCode': '83471', 'north': 47.7229387, 'east': 13.0989358, 'south': 47.4633962, 'west': 12.9087264}, {
  'zipCode': '83483',
  'north':   47.7131751,
  'east':    13.0008281,
  'south':   47.6103096,
  'west':    12.8843267
}, {'zipCode': '83486', 'north': 47.6586979, 'east': 12.9621502, 'south': 47.4969431, 'west': 12.7791497}, {
  'zipCode': '83487',
  'north':   47.7125863,
  'east':    13.0807484,
  'south':   47.6614742,
  'west':    13.0004466
}, {'zipCode': '83512', 'north': 48.0782433, 'east': 12.2533041, 'south': 48.0214951, 'west': 12.1669807}, {
  'zipCode': '83527',
  'north':   48.219327,
  'east':    12.2194433,
  'south':   48.13094550000001,
  'west':    12.1023445
}, {'zipCode': '83530', 'north': 48.1316913, 'east': 12.4505913, 'south': 48.0335401, 'west': 12.323494}, {
  'zipCode': '83533',
  'north':   48.0887033,
  'east':    12.1774746,
  'south':   48.0202302,
  'west':    12.1017699
}, {'zipCode': '83536', 'north': 48.1824994, 'east': 12.3193459, 'south': 48.1264732, 'west': 12.2034482}, {
  'zipCode': '83539',
  'north':   48.1011773,
  'east':    12.1595696,
  'south':   48.0112474,
  'west':    12.0627604
}, {'zipCode': '83543', 'north': 48.0150884, 'east': 12.1627038, 'south': 47.9602269, 'west': 12.0925789}, {
  'zipCode': '83544',
  'north':   48.1323694,
  'east':    12.150183500000002,
  'south':   48.0821259,
  'west':    12.0735463
}, {'zipCode': '83546', 'north': 48.1923946, 'east': 12.3462023, 'south': 48.15490630000001, 'west': 12.287363}, {
  'zipCode': '83547',
  'north':   48.1282574,
  'east':    12.3886568,
  'south':   48.0447088,
  'west':    12.2155547
}, {'zipCode': '83549', 'north': 48.06329900000001, 'east': 12.3104758, 'south': 47.9953074, 'west': 12.1917546}, {
  'zipCode': '83550',
  'north':   48.0181653,
  'east':    12.100147,
  'south':   47.9697497,
  'west':    12.026848
}, {'zipCode': '83553', 'north': 48.0612971, 'east': 12.0879751, 'south': 48.0044654, 'west': 11.9966486}, {
  'zipCode': '83555',
  'north':   48.1567653,
  'east':    12.3156672,
  'south':   48.1309895,
  'west':    12.2850809
}, {'zipCode': '83556', 'north': 48.028386, 'east': 12.2439572, 'south': 47.957339, 'west': 12.1491153}, {
  'zipCode': '83558',
  'north':   48.1741012,
  'east':    12.1541469,
  'south':   48.1223685,
  'west':    12.0319511
}, {'zipCode': '83559', 'north': 48.1762237, 'east': 12.3541134, 'south': 48.136497500000004, 'west': 12.3111924}, {
  'zipCode': '83561',
  'north':   48.0334558,
  'east':    12.1751016,
  'south':   47.9981777,
  'west':    12.1184257
}, {'zipCode': '83562', 'north': 48.1573897, 'east': 12.2098392, 'south': 48.083010400000006, 'west': 12.1225729}, {
  'zipCode': '83564',
  'north':   48.1431097,
  'east':    12.2728608,
  'south':   48.067881,
  'west':    12.1642217
}, {'zipCode': '83567', 'north': 48.1533975, 'east': 12.4184951, 'south': 48.1052219, 'west': 12.2514277}, {
  'zipCode': '83569',
  'north':   47.9746502,
  'east':    12.2336934,
  'south':   47.8932963,
  'west':    12.1315367
}, {'zipCode': '83607', 'north': 47.9180572, 'east': 11.7446079, 'south': 47.821483, 'west': 11.6081797}, {
  'zipCode': '83620',
  'north':   47.9568893,
  'east':    11.9256091,
  'south':   47.8594968,
  'west':    11.8014099
}, {'zipCode': '83623', 'north': 47.9250611, 'east': 11.664400700000002, 'south': 47.8050752, 'west': 11.472116500000002}, {
  'zipCode': '83624',
  'north':   47.9391934,
  'east':    11.7149679,
  'south':   47.8805525,
  'west':    11.6234616
}, {'zipCode': '83626', 'north': 47.9432259, 'east': 11.8066525, 'south': 47.8335596, 'west': 11.7180596}, {
  'zipCode': '83627',
  'north':   47.8677538,
  'east':    11.801405,
  'south':   47.7702381,
  'west':    11.675035
}, {'zipCode': '83629', 'north': 47.9228937, 'east': 11.8714946, 'south': 47.807998, 'west': 11.7736953}, {
  'zipCode': '83646',
  'north':   47.8302881,
  'east':    11.6127155,
  'south':   47.6612642,
  'west':    11.4668365
}, {'zipCode': '83661', 'north': 47.7093303, 'east': 11.6801787, 'south': 47.4951084, 'west': 11.3320865}, {
  'zipCode': '83666',
  'north':   47.8246486,
  'east':    11.7255869,
  'south':   47.7144373,
  'west':    11.6222942
}, {'zipCode': '83670', 'north': 47.7921958, 'east': 11.4993621, 'south': 47.717384, 'west': 11.3979012}, {
  'zipCode': '83671',
  'north':   47.7254139,
  'east':    11.4830427,
  'south':   47.6510191,
  'west':    11.3439425
}, {'zipCode': '83673', 'north': 47.7416946, 'east': 11.4840128, 'south': 47.69720660000001, 'west': 11.3725071}, {
  'zipCode': '83674',
  'north':   47.7573794,
  'east':    11.6734474,
  'south':   47.6781343,
  'west':    11.5562039
}, {'zipCode': '83676', 'north': 47.6543322, 'east': 11.5426317, 'south': 47.5311566, 'west': 11.2912207}, {
  'zipCode': '83677',
  'north':   47.7940987,
  'east':    11.662938200000001,
  'south':   47.7276586,
  'west':    11.588015
}, {'zipCode': '83679', 'north': 47.8315138, 'east': 11.6655343, 'south': 47.7894707, 'west': 11.5986205}, {
  'zipCode': '83684',
  'north':   47.7477354,
  'east':    11.8132801,
  'south':   47.688915,
  'west':    11.7184886
}, {'zipCode': '83700', 'north': 47.702328, 'east': 11.8943228, 'south': 47.6008644, 'west': 11.7376014}, {
  'zipCode': '83703',
  'north':   47.7882394,
  'east':    11.7986107,
  'south':   47.720101,
  'west':    11.6653021
}, {'zipCode': '83707', 'north': 47.7481325, 'east': 11.7404677, 'south': 47.6627254, 'west': 11.6427384}, {
  'zipCode': '83708',
  'north':   47.7009484,
  'east':    11.8549553,
  'south':   47.5812091,
  'west':    11.5979836
}, {'zipCode': '83714', 'north': 47.8254775, 'east': 11.9051413, 'south': 47.756558, 'west': 11.7748475}, {
  'zipCode': '83727',
  'north':   47.7566223,
  'east':    11.9425066,
  'south':   47.6082267,
  'west':    11.7935022
}, {'zipCode': '83730', 'north': 47.7873152, 'east': 12.0230175, 'south': 47.6715514, 'west': 11.8844407}, {
  'zipCode': '83734',
  'north':   47.7806009,
  'east':    11.8943812,
  'south':   47.725472,
  'west':    11.7844146
}, {'zipCode': '83735', 'north': 47.7080151, 'east': 12.0625112, 'south': 47.6110737, 'west': 11.9187139}, {
  'zipCode': '83737',
  'north':   47.8648924,
  'east':    11.9588393,
  'south':   47.7735348,
  'west':    11.8307587
}, {'zipCode': '84028', 'north': 48.5588055, 'east': 12.1862925, 'south': 48.5288527, 'west': 12.1403083}, {
  'zipCode': '84030',
  'north':   48.6271123,
  'east':    12.1974578,
  'south':   48.5461142,
  'west':    12.071193200000002
}, {'zipCode': '84032', 'north': 48.5902779, 'east': 12.1400195, 'south': 48.5346579, 'west': 12.0399948}, {
  'zipCode': '84034',
  'north':   48.5503864,
  'east':    12.168839,
  'south':   48.5070022,
  'west':    12.0182158
}, {'zipCode': '84036', 'north': 48.5955945, 'east': 12.284488500000002, 'south': 48.46146, 'west': 12.1067528}, {
  'zipCode': '84048',
  'north':   48.6930208,
  'east':    11.8280603,
  'south':   48.5997653,
  'west':    11.6918651
}, {'zipCode': '84051', 'north': 48.675188, 'east': 12.3068235, 'south': 48.5642896, 'west': 12.1284411}, {
  'zipCode': '84056',
  'north':   48.7727642,
  'east':    12.1334772,
  'south':   48.6678412,
  'west':    11.9470098
}, {'zipCode': '84061', 'north': 48.7295643, 'east': 12.2778995, 'south': 48.6508814, 'west': 12.1464505}, {
  'zipCode': '84066',
  'north':   48.8383296,
  'east':    12.294828,
  'south':   48.7214042,
  'west':    12.12755
}, {'zipCode': '84069', 'north': 48.8788317, 'east': 12.2596454, 'south': 48.7649053, 'west': 12.0968982}, {
  'zipCode': '84072',
  'north':   48.5950714,
  'east':    11.7976473,
  'south':   48.5094075,
  'west':    11.6576142
}, {'zipCode': '84076', 'north': 48.7268879, 'east': 12.0276085, 'south': 48.6179256, 'west': 11.8779226}, {
  'zipCode': '84079',
  'north':   48.5816843,
  'east':    12.0658851,
  'south':   48.4912995,
  'west':    11.9573768
}, {'zipCode': '84082', 'north': 48.8621124, 'east': 12.3990406, 'south': 48.7214517, 'west': 12.2351764}, {
  'zipCode': '84085',
  'north':   48.8798885,
  'east':    12.1170184,
  'south':   48.7742265,
  'west':    11.9769029
}, {'zipCode': '84088', 'north': 48.7690612, 'east': 12.2231697, 'south': 48.6980935, 'west': 12.0872394}, {
  'zipCode': '84089',
  'north':   48.7233466,
  'east':    11.7620096,
  'south':   48.6481641,
  'west':    11.6536757
}, {'zipCode': '84091', 'north': 48.68575770000001, 'east': 11.9025473, 'south': 48.6300696, 'west': 11.7953162}, {
  'zipCode': '84092',
  'north':   48.74727750000001,
  'east':    12.3463283,
  'south':   48.6758067,
  'west':    12.265317800000002
}, {'zipCode': '84094', 'north': 48.7233027, 'east': 11.8941114, 'south': 48.6774985, 'west': 11.7535159}, {
  'zipCode': '84095',
  'north':   48.6045708,
  'east':    12.0878484,
  'south':   48.5717036,
  'west':    11.9518724
}, {'zipCode': '84097', 'north': 48.819459300000005, 'east': 12.1043522, 'south': 48.7497064, 'west': 12.0303618}, {
  'zipCode': '84098',
  'north':   48.7082747,
  'east':    12.1740039,
  'south':   48.60922540000001,
  'west':    12.0075401
}, {'zipCode': '84100', 'north': 48.63652, 'east': 12.3743279, 'south': 48.5472532, 'west': 12.2739128}, {
  'zipCode': '84101',
  'north':   48.6257237,
  'east':    11.9788009,
  'south':   48.57285430000001,
  'west':    11.8915711
}, {'zipCode': '84103', 'north': 48.6941233, 'east': 12.375932, 'south': 48.6215557, 'west': 12.256008}, {
  'zipCode': '84104',
  'north':   48.6163175,
  'east':    11.8684333,
  'south':   48.5595138,
  'west':    11.6966123
}, {'zipCode': '84106', 'north': 48.6380194, 'east': 11.91133, 'south': 48.5789098, 'west': 11.8105118}, {
  'zipCode': '84107',
  'north':   48.6570009,
  'east':    12.079185600000002,
  'south':   48.5907243,
  'west':    11.9630759
}, {'zipCode': '84109', 'north': 48.6359925, 'east': 12.3669688, 'south': 48.6143541, 'west': 12.3167161}, {
  'zipCode': '84130',
  'north':   48.662146,
  'east':    12.5249002,
  'south':   48.5641239,
  'west':    12.4302539
}, {'zipCode': '84137', 'north': 48.4941096, 'east': 12.4293869, 'south': 48.3835854, 'west': 12.2726756}, {
  'zipCode': '84140',
  'north':   48.52480930000001,
  'east':    12.6542813,
  'south':   48.3996117,
  'west':    12.4700622
}, {'zipCode': '84144', 'north': 48.5148969, 'east': 12.3418398, 'south': 48.411807, 'west': 12.1942901}, {
  'zipCode': '84149',
  'north':   48.4229399,
  'east':    12.3523938,
  'south':   48.3205456,
  'west':    12.1959591
}, {'zipCode': '84152', 'north': 48.7665495, 'east': 12.5356332, 'south': 48.6771182, 'west': 12.3243507}, {
  'zipCode': '84155',
  'north':   48.456131,
  'east':    12.4838872,
  'south':   48.3442858,
  'west':    12.3461238
}, {'zipCode': '84160', 'north': 48.5609452, 'east': 12.5696754, 'south': 48.479638, 'west': 12.4835599}, {
  'zipCode': '84163',
  'north':   48.5959508,
  'east':    12.6096221,
  'south':   48.5163883,
  'west':    12.4721622
}, {'zipCode': '84164', 'north': 48.7369133, 'east': 12.579774, 'south': 48.6448219, 'west': 12.3832509}, {
  'zipCode': '84166',
  'north':   48.581233,
  'east':    12.364963100000002,
  'south':   48.5038483,
  'west':    12.2156778
}, {'zipCode': '84168', 'north': 48.5618347, 'east': 12.5095078, 'south': 48.4854419, 'west': 12.4105896}, {
  'zipCode': '84169',
  'north':   48.4747237,
  'east':    12.233809,
  'south':   48.4239924,
  'west':    12.1426542
}, {'zipCode': '84171', 'north': 48.4323344, 'east': 12.23735, 'south': 48.3943867, 'west': 12.146511800000003}, {
  'zipCode': '84172',
  'north':   48.4750398,
  'east':    12.1101622,
  'south':   48.4157102,
  'west':    11.9916152
}, {'zipCode': '84174', 'north': 48.5165637, 'east': 12.094794100000001, 'south': 48.4564215, 'west': 12.0011407}, {
  'zipCode': '84175',
  'north':   48.5237048,
  'east':    12.4800483,
  'south':   48.4512929,
  'west':    12.3503285
}, {'zipCode': '84177', 'north': 48.672597, 'east': 12.5757588, 'south': 48.5827803, 'west': 12.5018161}, {
  'zipCode': '84178',
  'north':   48.5753999,
  'east':    12.430220500000003,
  'south':   48.4904217,
  'west':    12.3103899
}, {'zipCode': '84180', 'north': 48.6507318, 'east': 12.4735567, 'south': 48.5335827, 'west': 12.3936003}, {
  'zipCode': '84181',
  'north':   48.4248573,
  'east':    12.2671648,
  'south':   48.3643947,
  'west':    12.1699985
}, {'zipCode': '84183', 'north': 48.6457661, 'east': 12.4159716, 'south': 48.55832100000001, 'west': 12.3482269}, {
  'zipCode': '84184',
  'north':   48.5233071,
  'east':    12.1559606,
  'south':   48.4602856,
  'west':    12.0684482
}, {'zipCode': '84186', 'north': 48.4729999, 'east': 12.1628604, 'south': 48.4156395, 'west': 12.0706426}, {
  'zipCode': '84187',
  'north':   48.6902746,
  'east':    12.3985817,
  'south':   48.6410119,
  'west':    12.3298632
}, {'zipCode': '84189', 'north': 48.3815406, 'east': 12.3740858, 'south': 48.316335, 'west': 12.2814748}, {
  'zipCode': '84307',
  'north':   48.4463408,
  'east':    12.799126,
  'south':   48.3698485,
  'west':    12.6986261
}, {'zipCode': '84323', 'north': 48.4410645, 'east': 12.6633854, 'south': 48.3589727, 'west': 12.5646688}, {
  'zipCode': '84326',
  'north':   48.5237463,
  'east':    12.7931833,
  'south':   48.4118723,
  'west':    12.6233605
}, {'zipCode': '84329', 'north': 48.390447, 'east': 12.8765942, 'south': 48.3125569, 'west': 12.7377097}, {
  'zipCode': '84332',
  'north':   48.4862908,
  'east':    12.8683808,
  'south':   48.3783258,
  'west':    12.7702898
}, {'zipCode': '84333', 'north': 48.5477351, 'east': 12.773297, 'south': 48.5027601, 'west': 12.7138964}, {
  'zipCode': '84335',
  'north':   48.3808729,
  'east':    12.779086,
  'south':   48.3209459,
  'west':    12.6858964
}, {'zipCode': '84337', 'north': 48.5280107, 'east': 12.8933045, 'south': 48.4570699, 'west': 12.7810595}, {
  'zipCode': '84339',
  'north':   48.4361311,
  'east':    12.7101352,
  'south':   48.3534063,
  'west':    12.6326614
}, {'zipCode': '84347', 'north': 48.484859, 'east': 13.0111395, 'south': 48.3922328, 'west': 12.8984758}, {
  'zipCode': '84359',
  'north':   48.3208088,
  'east':    13.081121,
  'south':   48.2530489,
  'west':    12.941497
}, {'zipCode': '84364', 'north': 48.4917083, 'east': 13.1421686, 'south': 48.376557500000004, 'west': 12.9861377}, {
  'zipCode': '84367',
  'north':   48.3971152,
  'east':    12.971011,
  'south':   48.2581151,
  'west':    12.8219767
}, {'zipCode': '84371', 'north': 48.4453052, 'east': 13.093275900000002, 'south': 48.3462813, 'west': 12.9190707}, {
  'zipCode': '84375',
  'north':   48.2811601,
  'east':    13.013022,
  'south':   48.2066557,
  'west':    12.8916107
}, {'zipCode': '84378', 'north': 48.5339516, 'east': 13.038558000000002, 'south': 48.4650357, 'west': 12.8660932}, {
  'zipCode': '84381',
  'north':   48.5834143,
  'east':    13.0242806,
  'south':   48.510405000000006,
  'west':    12.8900808
}, {'zipCode': '84384', 'north': 48.3749392, 'east': 13.0805838, 'south': 48.3096946, 'west': 12.9492789}, {
  'zipCode': '84385',
  'north':   48.5552058,
  'east':    13.1060881,
  'south':   48.4840891,
  'west':    13.0008212
}, {'zipCode': '84387', 'north': 48.2723107, 'east': 12.9685195, 'south': 48.2371653, 'west': 12.8941013}, {
  'zipCode': '84389',
  'north':   48.4781344,
  'east':    12.9471175,
  'south':   48.3798612,
  'west':    12.8358921
}, {'zipCode': '84405', 'north': 48.3275608, 'east': 12.255264, 'south': 48.225122, 'west': 12.0695918}, {
  'zipCode': '84416',
  'north':   48.3981408,
  'east':    12.2462474,
  'south':   48.2926117,
  'west':    12.0411079
}, {'zipCode': '84419', 'north': 48.2994385, 'east': 12.3268158, 'south': 48.1945193, 'west': 12.2148159}, {
  'zipCode': '84424',
  'north':   48.2356462,
  'east':    12.1162342,
  'south':   48.1396804,
  'west':    12.0021112
}, {'zipCode': '84427', 'north': 48.2602748, 'east': 12.2325492, 'south': 48.1824588, 'west': 12.0795965}, {
  'zipCode': '84428',
  'north':   48.33177760000001,
  'east':    12.3694005,
  'south':   48.2839946,
  'west':    12.2487653
}, {'zipCode': '84431', 'north': 48.2718291, 'east': 12.4003501, 'south': 48.2042175, 'west': 12.2899038}, {
  'zipCode': '84432',
  'north':   48.4261929,
  'east':    12.1715966,
  'south':   48.3619146,
  'west':    12.0888269
}, {'zipCode': '84434', 'north': 48.4287353, 'east': 12.1067972, 'south': 48.3846746, 'west': 12.0185881}, {
  'zipCode': '84435',
  'north':   48.2991871,
  'east':    12.099081,
  'south':   48.2224112,
  'west':    12.0045358
}, {'zipCode': '84437', 'north': 48.2216522, 'east': 12.3233028, 'south': 48.1516859, 'west': 12.2013991}, {
  'zipCode': '84439',
  'north':   48.4019039,
  'east':    12.120614800000002,
  'south':   48.349417,
  'west':    12.0443491
}, {'zipCode': '84453', 'north': 48.2860754, 'east': 12.564791, 'south': 48.2148932, 'west': 12.4522791}, {
  'zipCode': '84478',
  'north':   48.2273947,
  'east':    12.4912964,
  'south':   48.1676953,
  'west':    12.3777989
}, {'zipCode': '84489', 'north': 48.2038514, 'east': 12.8569115, 'south': 48.122821, 'west': 12.7581494}, {
  'zipCode': '84494',
  'north':   48.4296921,
  'east':    12.6095657,
  'south':   48.2802928,
  'west':    12.4182227
}, {'zipCode': '84503', 'north': 48.2530561, 'east': 12.7328493, 'south': 48.1997428, 'west': 12.6076317}, {
  'zipCode': '84508',
  'north':   48.199837,
  'east':    12.7880173,
  'south':   48.1016525,
  'west':    12.6279024
}, {'zipCode': '84513', 'north': 48.3019638, 'east': 12.62286, 'south': 48.238697900000005, 'west': 12.536393}, {
  'zipCode': '84518',
  'north':   48.1696646,
  'east':    12.6326357,
  'south':   48.1009156,
  'west':    12.5422168
}, {'zipCode': '84524', 'north': 48.2692852, 'east': 12.817159500000002, 'south': 48.2152157, 'west': 12.6625461}, {
  'zipCode': '84529',
  'north':   48.1144731,
  'east':    12.808711300000002,
  'south':   47.9855466,
  'west':    12.6806115
}, {'zipCode': '84533', 'north': 48.2918244, 'east': 12.9442137, 'south': 48.1793497, 'west': 12.7950242}, {
  'zipCode': '84539',
  'north':   48.2983284,
  'east':    12.4633166,
  'south':   48.2094917,
  'west':    12.320448100000002
}, {'zipCode': '84543', 'north': 48.293721, 'east': 12.7165409, 'south': 48.247491700000005, 'west': 12.6100173}, {
  'zipCode': '84544',
  'north':   48.2238217,
  'east':    12.3963198,
  'south':   48.1693826,
  'west':    12.3056521
}, {'zipCode': '84546', 'north': 48.4175935, 'east': 12.4810496, 'south': 48.3621001, 'west': 12.4249629}, {
  'zipCode': '84547',
  'north':   48.2295218,
  'east':    12.803977,
  'south':   48.1788674,
  'west':    12.7277959
}, {'zipCode': '84549', 'north': 48.1639534, 'east': 12.5760093, 'south': 48.0953709, 'west': 12.4646165}, {
  'zipCode': '84550',
  'north':   48.1131142,
  'east':    12.6310007,
  'south':   48.0642691,
  'west':    12.5644429
}, {'zipCode': '84552', 'north': 48.3696372, 'east': 12.6903881, 'south': 48.3314675, 'west': 12.6070986}, {
  'zipCode': '84553',
  'north':   48.1429204,
  'east':    12.719528,
  'south':   48.0883704,
  'west':    12.6465975
}, {'zipCode': '84555', 'north': 48.1794802, 'east': 12.402882, 'south': 48.1455773, 'west': 12.347806200000003}, {
  'zipCode': '84556',
  'north':   48.2180191,
  'east':    12.737876800000002,
  'south':   48.1532176,
  'west':    12.6417971
}, {'zipCode': '84558', 'north': 48.13781660000001, 'east': 12.7110921, 'south': 48.0444937, 'west': 12.6125522}, {
  'zipCode': '84559',
  'north':   48.2108647,
  'east':    12.4982967,
  'south':   48.1442303,
  'west':    12.390893400000001
}, {'zipCode': '84561', 'north': 48.2200412, 'east': 12.8315258, 'south': 48.150425000000006, 'west': 12.7495679}, {
  'zipCode': '84562',
  'north':   48.314918,
  'east':    12.5180727,
  'south':   48.2273337,
  'west':    12.4316224
}, {'zipCode': '84564', 'north': 48.3468067, 'east': 12.4257393, 'south': 48.2761646, 'west': 12.3211076}, {
  'zipCode': '84565',
  'north':   48.1922241,
  'east':    12.5574578,
  'south':   48.1447738,
  'west':    12.4755757
}, {'zipCode': '84567', 'north': 48.327707000000004, 'east': 12.8511744, 'south': 48.252861, 'west': 12.7176622}, {
  'zipCode': '84568',
  'north':   48.3584345,
  'east':    12.70233,
  'south':   48.2821111,
  'west':    12.5535386
}, {'zipCode': '84570', 'north': 48.2424153, 'east': 12.6017967, 'south': 48.1579943, 'west': 12.4688503}, {
  'zipCode': '84571',
  'north':   48.3323532,
  'east':    12.7555011,
  'south':   48.2608523,
  'west':    12.6802358
}, {'zipCode': '84573', 'north': 48.3737782, 'east': 12.4719278, 'south': 48.3162061, 'west': 12.3747127}, {
  'zipCode': '84574',
  'north':   48.1734196,
  'east':    12.4956791,
  'south':   48.1110654,
  'west':    12.3959613
}, {'zipCode': '84576', 'north': 48.242742, 'east': 12.6354871, 'south': 48.218032300000004, 'west': 12.5853418}, {
  'zipCode': '84577',
  'north':   48.2277429,
  'east':    12.6578715,
  'south':   48.1805531,
  'west':    12.575645
}, {'zipCode': '84579', 'north': 48.195486, 'east': 12.663936, 'south': 48.1383844, 'west': 12.5785491}, {
  'zipCode': '85049',
  'north':   48.7905189,
  'east':    11.4372128,
  'south':   48.7369045,
  'west':    11.2559076
}, {'zipCode': '85051', 'north': 48.7642522, 'east': 11.4549592, 'south': 48.6838773, 'west': 11.3103239}, {
  'zipCode': '85053',
  'north':   48.7643113,
  'east':    11.4990558,
  'south':   48.7278157,
  'west':    11.4306196
}, {'zipCode': '85055', 'north': 48.8228907, 'east': 11.5034223, 'south': 48.7591384, 'west': 11.3824605}, {
  'zipCode': '85057',
  'north':   48.7954672,
  'east':    11.4330053,
  'south':   48.7673972,
  'west':    11.3776071
}, {'zipCode': '85072', 'north': 48.9233981, 'east': 11.275641300000002, 'south': 48.8495647, 'west': 11.128619900000002}, {
  'zipCode': '85077',
  'north':   48.7505782,
  'east':    11.5655509,
  'south':   48.6829484,
  'west':    11.4303408
}, {'zipCode': '85080', 'north': 48.8499981, 'east': 11.3969506, 'south': 48.7774661, 'west': 11.3264915}, {
  'zipCode': '85084',
  'north':   48.6992438,
  'east':    11.5496433,
  'south':   48.6239001,
  'west':    11.4272089
}, {'zipCode': '85088', 'north': 48.8142363, 'east': 11.6706709, 'south': 48.72020150000001, 'west': 11.5514701}, {
  'zipCode': '85092',
  'north':   48.885551,
  'east':    11.5709333,
  'south':   48.7842939,
  'west':    11.4650168
}, {'zipCode': '85095', 'north': 48.963235, 'east': 11.5214291, 'south': 48.8863958, 'west': 11.4044873}, {
  'zipCode': '85098',
  'north':   48.8335105,
  'east':    11.6050642,
  'south':   48.7327345,
  'west':    11.4891929
}, {'zipCode': '85101', 'north': 48.8357162, 'east': 11.4798838, 'south': 48.792864, 'west': 11.4275873}, {
  'zipCode': '85104',
  'north':   48.874348700000006,
  'east':    11.7411182,
  'south':   48.773867,
  'west':    11.6294511
}, {'zipCode': '85107', 'north': 48.7016847, 'east': 11.5110398, 'south': 48.6595855, 'west': 11.4289403}, {
  'zipCode': '85110',
  'north':   48.9915197,
  'east':    11.458595,
  'south':   48.8681932,
  'west':    11.2606564
}, {'zipCode': '85111', 'north': 48.8846512, 'east': 11.2859814, 'south': 48.80700000000001, 'west': 11.0959415}, {
  'zipCode': '85113',
  'north':   48.8989382,
  'east':    11.4069106,
  'south':   48.846504,
  'west':    11.3404804
}, {'zipCode': '85114', 'north': 48.8596742, 'east': 11.3323798, 'south': 48.7817961, 'west': 11.2495202}, {
  'zipCode': '85116',
  'north':   48.8017498,
  'east':    11.2624777,
  'south':   48.7715217,
  'west':    11.1804064
}, {'zipCode': '85117', 'north': 48.8591271, 'east': 11.345089900000001, 'south': 48.7936983, 'west': 11.2706285}, {
  'zipCode': '85119',
  'north':   48.743056200000005,
  'east':    11.6016679,
  'south':   48.7083571,
  'west':    11.5384683
}, {'zipCode': '85120', 'north': 48.839386700000006, 'east': 11.4773275, 'south': 48.8168213, 'west': 11.4433645}, {
  'zipCode': '85122',
  'north':   48.9123364,
  'east':    11.3565296,
  'south':   48.836571500000005,
  'west':    11.2687226
}, {'zipCode': '85123', 'north': 48.7051746, 'east': 11.4570621, 'south': 48.6333741, 'west': 11.3383475}, {
  'zipCode': '85125',
  'north':   49.0421946,
  'east':    11.4791881,
  'south':   48.9696598,
  'west':    11.2855053
}, {'zipCode': '85126', 'north': 48.7932181, 'east': 11.7198626, 'south': 48.742302, 'west': 11.635958}, {
  'zipCode': '85128',
  'north':   48.8325152,
  'east':    11.2767917,
  'south':   48.7889432,
  'west':    11.1491037
}, {'zipCode': '85129', 'north': 48.863126, 'east': 11.6413634, 'south': 48.8112806, 'west': 11.5611005}, {
  'zipCode': '85131',
  'north':   48.9704009,
  'east':    11.2934468,
  'south':   48.907948600000005,
  'west':    11.123104
}, {'zipCode': '85132', 'north': 48.9667851, 'east': 11.1847306, 'south': 48.8845371, 'west': 11.0271395}, {
  'zipCode': '85134',
  'north':   48.90212,
  'east':    11.5705346,
  'south':   48.8328137,
  'west':    11.402973
}, {'zipCode': '85135', 'north': 49.052803100000006, 'east': 11.292168500000002, 'south': 48.9598247, 'west': 11.1393401}, {
  'zipCode': '85137',
  'north':   48.9505403,
  'east':    11.3707172,
  'south':   48.8646399,
  'west':    11.2463162
}, {'zipCode': '85139', 'north': 48.8627562, 'east': 11.4409663, 'south': 48.8113418, 'west': 11.3726101}, {
  'zipCode': '85221',
  'north':   48.3117077,
  'east':    11.5025338,
  'south':   48.227495,
  'west':    11.3843887
}, {'zipCode': '85229', 'north': 48.4278839, 'east': 11.4283232, 'south': 48.3062001, 'west': 11.2836877}, {
  'zipCode': '85232',
  'north':   48.2959937,
  'east':    11.4360882,
  'south':   48.2011354,
  'west':    11.276441
}, {'zipCode': '85235', 'north': 48.3473359, 'east': 11.2570533, 'south': 48.2683288, 'west': 11.1098916}, {
  'zipCode': '85238',
  'north':   48.4415491,
  'east':    11.5307301,
  'south':   48.3749196,
  'west':    11.421004500000002
}, {'zipCode': '85241', 'north': 48.328464100000005, 'east': 11.5316185, 'south': 48.2743542, 'west': 11.4198816}, {
  'zipCode': '85244',
  'north':   48.356845,
  'east':    11.5204201,
  'south':   48.3026752,
  'west':    11.3979098
}, {'zipCode': '85247', 'north': 48.3477774, 'east': 11.4037086, 'south': 48.2767328, 'west': 11.2898222}, {
  'zipCode': '85250',
  'north':   48.4422525,
  'east':    11.3318206,
  'south':   48.3383962,
  'west':    11.1614244
}, {'zipCode': '85253', 'north': 48.37380240000001, 'east': 11.3331994, 'south': 48.2981794, 'west': 11.2240505}, {
  'zipCode': '85254',
  'north':   48.31505620000001,
  'east':    11.3059053,
  'south':   48.260326,
  'west':    11.2154081
}, {'zipCode': '85256', 'north': 48.3873422, 'east': 11.5250652, 'south': 48.3436421, 'west': 11.4190133}, {
  'zipCode': '85258',
  'north':   48.410356,
  'east':    11.4559229,
  'south':   48.3541667,
  'west':    11.3794231
}, {'zipCode': '85259', 'north': 48.3044685, 'east': 11.2460522, 'south': 48.2906531, 'west': 11.215745300000002}, {
  'zipCode': '85276',
  'north':   48.5949523,
  'east':    11.5882227,
  'south':   48.4920115,
  'west':    11.3855605
}, {'zipCode': '85283', 'north': 48.6659033, 'east': 11.7076832, 'south': 48.5414829, 'west': 11.5468749}, {
  'zipCode': '85290',
  'north':   48.7490976,
  'east':    11.7136462,
  'south':   48.6302154,
  'west':    11.5385984
}, {'zipCode': '85293', 'north': 48.4743538, 'east': 11.535182, 'south': 48.4325895, 'west': 11.4157099}, {
  'zipCode': '85296',
  'north':   48.647396400000005,
  'east':    11.5853489,
  'south':   48.5837142,
  'west':    11.4902114
}, {'zipCode': '85298', 'north': 48.539423, 'east': 11.4888444, 'south': 48.4601431, 'west': 11.3834246}, {
  'zipCode': '85301',
  'north':   48.5612647,
  'east':    11.6656049,
  'south':   48.4626329,
  'west':    11.5643035
}, {'zipCode': '85302', 'north': 48.542031800000004, 'east': 11.4188617, 'south': 48.4429655, 'west': 11.2949946}, {
  'zipCode': '85304',
  'north':   48.5179327,
  'east':    11.5706374,
  'south':   48.467941,
  'west':    11.4555641
}, {'zipCode': '85305', 'north': 48.4668888, 'east': 11.4497128, 'south': 48.4062936, 'west': 11.3614208}, {
  'zipCode': '85307',
  'north':   48.4977221,
  'east':    11.6033331,
  'south':   48.4543007,
  'west':    11.5287698
}, {'zipCode': '85309', 'north': 48.6441897, 'east': 11.508773, 'south': 48.581681, 'west': 11.4255949}, {
  'zipCode': '85354',
  'north':   48.4490313,
  'east':    11.7627379,
  'south':   48.33060600000001,
  'west':    11.6404507
}, {'zipCode': '85356', 'north': 48.443490100000005, 'east': 11.8357025, 'south': 48.3369116, 'west': 11.7126555}, {
  'zipCode': '85368',
  'north':   48.5323968,
  'east':    12.019424,
  'south':   48.426983500000006,
  'west':    11.8348501
}, {'zipCode': '85375', 'north': 48.3543282, 'east': 11.7053411, 'south': 48.2902492, 'west': 11.6034907}, {
  'zipCode': '85376',
  'north':   48.3762445,
  'east':    11.6829867,
  'south':   48.3325621,
  'west':    11.594316
}, {'zipCode': '85386', 'north': 48.3315463, 'east': 11.6980772, 'south': 48.2657211, 'west': 11.5715474}, {
  'zipCode': '85391',
  'north':   48.46709830000001,
  'east':    11.6380087,
  'south':   48.3993857,
  'west':    11.5548192
}, {'zipCode': '85395', 'north': 48.5277703, 'east': 11.7915819, 'south': 48.4644439, 'west': 11.659454}, {
  'zipCode': '85399',
  'north':   48.3513952,
  'east':    11.7725822,
  'south':   48.2695204,
  'west':    11.683889
}, {'zipCode': '85402', 'north': 48.4449748, 'east': 11.7027186, 'south': 48.3717742, 'west': 11.5497879}, {
  'zipCode': '85405',
  'north':   48.5638756,
  'east':    11.8689598,
  'south':   48.5011136,
  'west':    11.761196
}, {'zipCode': '85406', 'north': 48.5123038, 'east': 11.8364245, 'south': 48.4393114, 'west': 11.6874191}, {
  'zipCode': '85408',
  'north':   48.5866681,
  'east':    11.9727574,
  'south':   48.5244105,
  'west':    11.8951908
}, {'zipCode': '85410', 'north': 48.4958181, 'east': 11.8901474, 'south': 48.4466585, 'west': 11.7975401}, {
  'zipCode': '85411',
  'north':   48.4613208,
  'east':    11.5648774,
  'south':   48.3880497,
  'west':    11.492617
}, {'zipCode': '85413', 'north': 48.5885987, 'east': 11.9106198, 'south': 48.5266545, 'west': 11.8281074}, {
  'zipCode': '85414',
  'north':   48.4989523,
  'east':    11.7204267,
  'south':   48.4251382,
  'west':    11.6101461
}, {'zipCode': '85416', 'north': 48.4570033, 'east': 11.9116117, 'south': 48.4121844, 'west': 11.7954001}, {
  'zipCode': '85417',
  'north':   48.4397477,
  'east':    11.8479735,
  'south':   48.3670845,
  'west':    11.7753817
}, {'zipCode': '85419', 'north': 48.5710653, 'east': 11.945324700000002, 'south': 48.49335670000001, 'west': 11.8529207}, {
  'zipCode': '85435',
  'north':   48.3681198,
  'east':    11.9610133,
  'south':   48.2533095,
  'west':    11.8608448
}, {'zipCode': '85445', 'north': 48.3857725, 'east': 11.8933987, 'south': 48.28964570000001, 'west': 11.7644278}, {
  'zipCode': '85447',
  'north':   48.3919074,
  'east':    12.0648268,
  'south':   48.3319181,
  'west':    11.9330512
}, {'zipCode': '85452', 'north': 48.3003716, 'east': 11.885816, 'south': 48.2487859, 'west': 11.7311725}, {
  'zipCode': '85456',
  'north':   48.4148472,
  'east':    12.0369051,
  'south':   48.3698871,
  'west':    11.9430951
}, {'zipCode': '85457', 'north': 48.266717, 'east': 11.9701949, 'south': 48.2230791, 'west': 11.8696977}, {
  'zipCode': '85459',
  'north':   48.4275646,
  'east':    11.9524016,
  'south':   48.3611675,
  'west':    11.8938408
}, {'zipCode': '85461', 'north': 48.3449522, 'east': 12.0586066, 'south': 48.2718695, 'west': 11.9375219}, {
  'zipCode': '85462',
  'north':   48.4236639,
  'east':    11.9024539,
  'south':   48.3293086,
  'west':    11.8077246
}, {'zipCode': '85464', 'north': 48.2664871, 'east': 11.8582181, 'south': 48.2050861, 'west': 11.7479744}, {
  'zipCode': '85465',
  'north':   48.45479370000001,
  'east':    12.0249559,
  'south':   48.4011141,
  'west':    11.913408
}, {'zipCode': '85467', 'north': 48.2655505, 'east': 11.8764906, 'south': 48.2209718, 'west': 11.7806588}, {
  'zipCode': '85469',
  'north':   48.2806121,
  'east':    12.0140652,
  'south':   48.2377495,
  'west':    11.930757600000002
}, {'zipCode': '85521', 'north': 48.0757162, 'east': 11.7127577, 'south': 48.0395275, 'west': 11.6430463}, {
  'zipCode': '85540',
  'north':   48.13785,
  'east':    11.7598535,
  'south':   48.09748140000001,
  'west':    11.6932571
}, {'zipCode': '85551', 'north': 48.2219107, 'east': 11.7755431, 'south': 48.1483138, 'west': 11.7292586}, {
  'zipCode': '85560',
  'north':   48.1671456,
  'east':    12.0463161,
  'south':   48.0479114,
  'west':    11.8399485
}, {'zipCode': '85567', 'north': 48.066785, 'east': 12.0263478, 'south': 47.999342, 'west': 11.8765604}, {
  'zipCode': '85570',
  'north':   48.2265184,
  'east':    11.9169648,
  'south':   48.1725523,
  'west':    11.831545100000001
}, {'zipCode': '85579', 'north': 48.0886253, 'east': 11.6911983, 'south': 48.0686796, 'west': 11.607764100000002}, {
  'zipCode': '85586',
  'north':   48.185970700000006,
  'east':    11.841683000000002,
  'south':   48.1501119,
  'west':    11.769396000000002
}, {'zipCode': '85591', 'north': 48.1265362, 'east': 11.792711, 'south': 48.0916753, 'west': 11.7539813}, {
  'zipCode': '85598',
  'north':   48.1347455,
  'east':    11.8450319,
  'south':   48.0882504,
  'west':    11.7775446
}, {'zipCode': '85599', 'north': 48.1552174, 'east': 11.832893, 'south': 48.1195564, 'west': 11.775992600000002}, {
  'zipCode': '85604',
  'north':   48.1183891,
  'east':    11.8552305,
  'south':   48.0450109,
  'west':    11.7818065
}, {'zipCode': '85609', 'north': 48.2410984, 'east': 11.7557126, 'south': 48.144285, 'west': 11.673925}, {
  'zipCode': '85614',
  'north':   48.1224875,
  'east':    11.9481094,
  'south':   48.0463165,
  'west':    11.8340431
}, {'zipCode': '85617', 'north': 48.0205353, 'east': 12.0412255, 'south': 47.9540877, 'west': 11.9217301}, {
  'zipCode': '85622',
  'north':   48.1598144,
  'east':    11.7894813,
  'south':   48.1213014,
  'west':    11.705088
}, {'zipCode': '85625', 'north': 48.019787, 'east': 11.94645, 'south': 47.9310765, 'west': 11.8187558}, {
  'zipCode': '85630',
  'north':   48.1085518,
  'east':    11.8087925,
  'south':   48.0361005,
  'west':    11.7185318
}, {'zipCode': '85635', 'north': 48.0410568, 'east': 11.788486, 'south': 47.9975121, 'west': 11.6928445}, {
  'zipCode': '85640',
  'north':   48.1058115,
  'east':    11.7324076,
  'south':   48.0631489,
  'west':    11.6809575
}, {'zipCode': '85643', 'north': 48.1382759, 'east': 12.0878805, 'south': 48.0484244, 'west': 11.992155}, {
  'zipCode': '85646',
  'north':   48.1824994,
  'east':    11.8880691,
  'south':   48.1241869,
  'west':    11.8083846
}, {'zipCode': '85649', 'north': 48.0453524, 'east': 11.744638, 'south': 47.9364125, 'west': 11.6250879}, {
  'zipCode': '85652',
  'north':   48.2261809,
  'east':    11.8471476,
  'south':   48.1742728,
  'west':    11.7550548
}, {'zipCode': '85653', 'north': 48.003387, 'east': 11.8520861, 'south': 47.9159284, 'west': 11.7049677}, {
  'zipCode': '85656',
  'north':   48.2445872,
  'east':    12.0368014,
  'south':   48.183201100000005,
  'west':    11.9619014
}, {'zipCode': '85658', 'north': 48.0219648, 'east': 11.8484845, 'south': 47.9618925, 'west': 11.759871}, {
  'zipCode': '85659',
  'north':   48.1988529,
  'east':    12.028367000000001,
  'south':   48.1642704,
  'west':    11.9240582
}, {'zipCode': '85661', 'north': 48.2001254, 'east': 11.9472798, 'south': 48.1548517, 'west': 11.880664300000001}, {
  'zipCode': '85662',
  'north':   48.0711869,
  'east':    11.7540448,
  'south':   48.0290074,
  'west':    11.6799574
}, {'zipCode': '85664', 'north': 48.1743652, 'east': 12.0505111, 'south': 48.1183742, 'west': 11.9404497}, {
  'zipCode': '85665',
  'north':   48.0624197,
  'east':    11.9169995,
  'south':   48.0087589,
  'west':    11.8331875
}, {'zipCode': '85667', 'north': 48.0584217, 'east': 11.8482692, 'south': 48.0053356, 'west': 11.7834811}, {
  'zipCode': '85669',
  'north':   48.2322035,
  'east':    11.983613400000001,
  'south':   48.1822473,
  'west':    11.9016412
}, {'zipCode': '85716', 'north': 48.2991862, 'east': 11.5967226, 'south': 48.2551324, 'west': 11.5138273}, {
  'zipCode': '85737',
  'north':   48.2789773,
  'east':    11.7598089,
  'south':   48.2017406,
  'west':    11.6435458
}, {'zipCode': '85748', 'north': 48.273226, 'east': 11.685189, 'south': 48.2168632, 'west': 11.5971976}, {
  'zipCode': '85757',
  'north':   48.2557742,
  'east':    11.5009054,
  'south':   48.2043577,
  'west':    11.4262293
}, {'zipCode': '85764', 'north': 48.2829021, 'east': 11.615142, 'south': 48.2133031, 'west': 11.4949743}, {
  'zipCode': '85774',
  'north':   48.2079677,
  'east':    11.6967822,
  'south':   48.1733964,
  'west':    11.627706
}, {'zipCode': '85777', 'north': 48.3944749, 'east': 11.6251212, 'south': 48.32670840000001, 'west': 11.5038653}, {
  'zipCode': '85778',
  'north':   48.35211950000001,
  'east':    11.582160000000002,
  'south':   48.2786733,
  'west':    11.5063543
}, {'zipCode': '86150', 'north': 48.3713438, 'east': 10.9059156, 'south': 48.3586519, 'west': 10.8773015}, {
  'zipCode': '86152',
  'north':   48.3794386,
  'east':    10.9100123,
  'south':   48.36494170000001,
  'west':    10.8766936
}, {'zipCode': '86153', 'north': 48.4050684, 'east': 10.9305887, 'south': 48.3592858, 'west': 10.8777064}, {
  'zipCode': '86154',
  'north':   48.41424510000001,
  'east':    10.8890492,
  'south':   48.374242,
  'west':    10.866097600000002
}, {'zipCode': '86156', 'north': 48.4168283, 'east': 10.8777064, 'south': 48.368778000000006, 'west': 10.8302528}, {
  'zipCode': '86157',
  'north':   48.3719792,
  'east':    10.8787832,
  'south':   48.3499181,
  'west':    10.8512788
}, {'zipCode': '86159', 'north': 48.36100090000001, 'east': 10.9082439, 'south': 48.3250887, 'west': 10.8722744}, {
  'zipCode': '86161',
  'north':   48.3668343,
  'east':    10.9403754,
  'south':   48.3239147,
  'west':    10.8990236
}, {'zipCode': '86163', 'north': 48.36990360000001, 'east': 10.9566347, 'south': 48.3269412, 'west': 10.9335498}, {
  'zipCode': '86165',
  'north':   48.4007179,
  'east':    10.9593328,
  'south':   48.363681,
  'west':    10.9134469
}, {'zipCode': '86167', 'north': 48.4098723, 'east': 10.9433498, 'south': 48.3763221, 'west': 10.903733}, {
  'zipCode': '86169',
  'north':   48.458654100000004,
  'east':    10.9438796,
  'south':   48.3812863,
  'west':    10.8839349
}, {'zipCode': '86179', 'north': 48.3319186, 'east': 10.9553441, 'south': 48.2581444, 'west': 10.8571707}, {
  'zipCode': '86199',
  'north':   48.3550833,
  'east':    10.895311,
  'south':   48.2903973,
  'west':    10.7633616
}, {'zipCode': '86316', 'north': 48.4310051, 'east': 11.080281800000002, 'south': 48.2977753, 'west': 10.9337844}, {
  'zipCode': '86343',
  'north':   48.29386800000001,
  'east':    10.9113489,
  'south':   48.2280882,
  'west':    10.855113
}, {'zipCode': '86356', 'north': 48.4195685, 'east': 10.8507442, 'south': 48.3648307, 'west': 10.757484400000001}, {
  'zipCode': '86368',
  'north':   48.460930600000005,
  'east':    10.8900693,
  'south':   48.4024018,
  'west':    10.7373037
}, {'zipCode': '86381', 'north': 48.2962402, 'east': 10.4346218, 'south': 48.2035743, 'west': 10.3322441}, {
  'zipCode': '86391',
  'north':   48.3797503,
  'east':    10.857767900000002,
  'south':   48.34145,
  'west':    10.7949062
}, {'zipCode': '86399', 'north': 48.30760080000001, 'east': 10.8758736, 'south': 48.2415954, 'west': 10.671804}, {
  'zipCode': '86405',
  'north':   48.5878795,
  'east':    10.8746052,
  'south':   48.5043883,
  'west':    10.7615729
}, {'zipCode': '86415', 'north': 48.2879594, 'east': 11.0667856, 'south': 48.245217800000006, 'west': 10.9403762}, {
  'zipCode': '86420',
  'north':   48.3967227,
  'east':    10.806092700000002,
  'south':   48.3041599,
  'west':    10.7116593
}, {'zipCode': '86424', 'north': 48.3843688, 'east': 10.668449, 'south': 48.28225940000001, 'west': 10.4945711}, {
  'zipCode': '86438',
  'north':   48.3331591,
  'east':    11.0244096,
  'south':   48.2800472,
  'west':    10.9442516
}, {'zipCode': '86441', 'north': 48.453627, 'east': 10.6984348, 'south': 48.3581384, 'west': 10.5054366}, {
  'zipCode': '86444',
  'north':   48.48135210000001,
  'east':    11.0310535,
  'south':   48.413177,
  'west':    10.893174
}, {'zipCode': '86447', 'north': 48.5496346, 'east': 11.0344849, 'south': 48.46813910000001, 'west': 10.8641147}, {
  'zipCode': '86450',
  'north':   48.4992099,
  'east':    10.6455588,
  'south':   48.4188896,
  'west':    10.527919000000002
}, {'zipCode': '86453', 'north': 48.4218551, 'east': 11.1443849, 'south': 48.3567619, 'west': 10.9952539}, {
  'zipCode': '86456',
  'north':   48.4794473,
  'east':    10.8715036,
  'south':   48.4321207,
  'west':    10.7511972
}, {'zipCode': '86459', 'north': 48.3601039, 'east': 10.7615111, 'south': 48.2579459, 'west': 10.6681111}, {
  'zipCode': '86462',
  'north':   48.5061713,
  'east':    10.8829136,
  'south':   48.4557247,
  'west':    10.7707428
}, {'zipCode': '86465', 'north': 48.485141, 'east': 10.7675398, 'south': 48.4308519, 'west': 10.6077512}, {
  'zipCode': '86470',
  'north':   48.3064536,
  'east':    10.5152305,
  'south':   48.2480846,
  'west':    10.4354065
}, {'zipCode': '86473', 'north': 48.323515, 'east': 10.5997055, 'south': 48.2356749, 'west': 10.4961502}, {
  'zipCode': '86476',
  'north':   48.3354328,
  'east':    10.439537,
  'south':   48.2721681,
  'west':    10.320276800000002
}, {'zipCode': '86477', 'north': 48.4377763, 'east': 10.7533506, 'south': 48.408962100000004, 'west': 10.6825078}, {
  'zipCode': '86479',
  'north':   48.2632572,
  'east':    10.5741358,
  'south':   48.1984658,
  'west':    10.5104068
}, {'zipCode': '86480', 'north': 48.22117240000001, 'east': 10.4460232, 'south': 48.1596834, 'west': 10.315434}, {
  'zipCode': '86482',
  'north':   48.419239,
  'east':    10.8001882,
  'south':   48.3921892,
  'west':    10.732940800000002
}, {'zipCode': '86483', 'north': 48.2569653, 'east': 10.5217314, 'south': 48.214548, 'west': 10.4581428}, {
  'zipCode': '86485',
  'north':   48.5477873,
  'east':    10.8524227,
  'south':   48.4683932,
  'west':    10.7087375
}, {'zipCode': '86486', 'north': 48.4533859, 'east': 10.7524371, 'south': 48.4329535, 'west': 10.6780847}, {
  'zipCode': '86488',
  'north':   48.2664341,
  'east':    10.3393224,
  'south':   48.21904580000001,
  'west':    10.2715136
}, {'zipCode': '86489', 'north': 48.2937637, 'east': 10.351279, 'south': 48.2431297, 'west': 10.2932189}, {
  'zipCode': '86491',
  'north':   48.2300399,
  'east':    10.3521848,
  'south':   48.1835101,
  'west':    10.2891364
}, {'zipCode': '86492', 'north': 48.210077, 'east': 11.0357962, 'south': 48.1555942, 'west': 10.94789}, {
  'zipCode': '86494',
  'north':   48.4987587,
  'east':    10.7015758,
  'south':   48.4518815,
  'west':    10.6410338
}, {'zipCode': '86495', 'north': 48.3557529, 'east': 11.1740619, 'south': 48.3071217, 'west': 11.053744}, {
  'zipCode': '86497',
  'north':   48.41831810000001,
  'east':    10.7549134,
  'south':   48.376101600000005,
  'west':    10.6226125
}, {'zipCode': '86498', 'north': 48.2296139, 'east': 10.3012796, 'south': 48.1620043, 'west': 10.2181448}, {
  'zipCode': '86500',
  'north':   48.3883287,
  'east':    10.7441051,
  'south':   48.3190557,
  'west':    10.6508014
}, {'zipCode': '86502', 'north': 48.5428802, 'east': 10.7651966, 'south': 48.4804141, 'west': 10.6578987}, {
  'zipCode': '86504',
  'north':   48.2674182,
  'east':    11.058844,
  'south':   48.2222782,
  'west':    10.9075094
}, {'zipCode': '86505', 'north': 48.330084, 'east': 10.5046493, 'south': 48.2932663, 'west': 10.403976}, {
  'zipCode': '86507',
  'north':   48.2486282,
  'east':    10.90366,
  'south':   48.17816950000001,
  'west':    10.8200572
}, {'zipCode': '86508', 'north': 48.513502300000006, 'east': 10.9701575, 'south': 48.4583086, 'west': 10.8704397}, {
  'zipCode': '86510',
  'north':   48.3190507,
  'east':    11.1268735,
  'south':   48.2601138,
  'west':    10.997825200000001
}, {'zipCode': '86511', 'north': 48.2537904, 'east': 10.9838013, 'south': 48.1986995, 'west': 10.90366}, {
  'zipCode': '86513',
  'north':   48.2874436,
  'east':    10.4775626,
  'south':   48.2057939,
  'west':    10.4049076
}, {'zipCode': '86514', 'north': 48.3431911, 'east': 10.6659059, 'south': 48.2980177, 'west': 10.615284}, {
  'zipCode': '86517',
  'north':   48.2697923,
  'east':    10.8438439,
  'south':   48.2295722,
  'west':    10.7597009
}, {'zipCode': '86519', 'north': 48.3219899, 'east': 10.331011400000001, 'south': 48.2652459, 'west': 10.2850424}, {
  'zipCode': '86529',
  'north':   48.6284268,
  'east':    11.3177946,
  'south':   48.5289909,
  'west':    11.1265102
}, {'zipCode': '86551', 'north': 48.5023577, 'east': 11.2143386, 'south': 48.3963573, 'west': 11.0120442}, {
  'zipCode': '86554',
  'north':   48.6202629,
  'east':    11.1655342,
  'south':   48.5204816,
  'west':    10.9763044
}, {'zipCode': '86556', 'north': 48.5498896, 'east': 11.2502966, 'south': 48.4720904, 'west': 11.1268491}, {
  'zipCode': '86558',
  'north':   48.6434151,
  'east':    11.4586152,
  'south':   48.5349367,
  'west':    11.336037
}, {'zipCode': '86559', 'north': 48.3764717, 'east': 11.1731633, 'south': 48.3363864, 'west': 11.0705841}, {
  'zipCode': '86561',
  'north':   48.5532697,
  'east':    11.3414754,
  'south':   48.4827249,
  'west':    11.2418854
}, {'zipCode': '86562', 'north': 48.6609057, 'east': 11.3069148, 'south': 48.6102207, 'west': 11.2027625}, {
  'zipCode': '86564',
  'north':   48.6698102,
  'east':    11.3857593,
  'south':   48.605264,
  'west':    11.2798374
}, {'zipCode': '86565', 'north': 48.5486314, 'east': 11.3035959, 'south': 48.463830400000006, 'west': 11.2028093}, {
  'zipCode': '86567',
  'north':   48.45537980000001,
  'east':    11.3894481,
  'south':   48.410019,
  'west':    11.2565767
}, {'zipCode': '86568', 'north': 48.5234951, 'east': 11.114192900000003, 'south': 48.4540516, 'west': 11.0173703}, {
  'zipCode': '86570',
  'north':   48.5512398,
  'east':    11.147681,
  'south':   48.4863429,
  'west':    11.0592689
}, {'zipCode': '86571', 'north': 48.6348426, 'east': 11.2617461, 'south': 48.5828091, 'west': 11.1605557}, {
  'zipCode': '86573',
  'north':   48.440129,
  'east':    11.0865683,
  'south':   48.4086429,
  'west':    11.0129369
}, {'zipCode': '86574', 'north': 48.5518124, 'east': 11.0533069, 'south': 48.496139, 'west': 10.9808586}, {
  'zipCode': '86576',
  'north':   48.47909,
  'east':    11.3115715,
  'south':   48.4293467,
  'west':    11.1913595
}, {'zipCode': '86577', 'north': 48.4181617, 'east': 11.1992003, 'south': 48.3650989, 'west': 11.1080608}, {
  'zipCode': '86579',
  'north':   48.6133232,
  'east':    11.3850204,
  'south':   48.5348796,
  'west':    11.29744
}, {'zipCode': '86609', 'north': 48.763405, 'east': 10.8678827, 'south': 48.675551, 'west': 10.6688373}, {
  'zipCode': '86633',
  'north':   48.8030757,
  'east':    11.3045372,
  'south':   48.6882469,
  'west':    11.1045706
}, {'zipCode': '86637', 'north': 48.5981815, 'east': 10.7809066, 'south': 48.4897136, 'west': 10.5635492}, {
  'zipCode': '86641',
  'north':   48.7212375,
  'east':    11.0417605,
  'south':   48.6091492,
  'west':    10.8713519
}, {'zipCode': '86643', 'north': 48.8233655, 'east': 11.1468549, 'south': 48.7218305, 'west': 10.9474774}, {
  'zipCode': '86647',
  'north':   48.6628929,
  'east':    10.7860252,
  'south':   48.5695084,
  'west':    10.636873
}, {'zipCode': '86650', 'north': 48.9080948, 'east': 10.7761566, 'south': 48.8491952, 'west': 10.6623019}, {
  'zipCode': '86653',
  'north':   48.9000254,
  'east':    10.9377272,
  'south':   48.7607532,
  'west':    10.7962078
}, {'zipCode': '86655', 'north': 48.830359, 'east': 10.77046, 'south': 48.7265481, 'west': 10.6177504}, {
  'zipCode': '86657',
  'north':   48.7575538,
  'east':    10.6606853,
  'south':   48.6803391,
  'west':    10.4893009
}, {'zipCode': '86660', 'north': 48.7280405, 'east': 10.7486425, 'south': 48.6475675, 'west': 10.6209872}, {
  'zipCode': '86663',
  'north':   48.7103024,
  'east':    10.8548856,
  'south':   48.6654025,
  'west':    10.7899137
}, {'zipCode': '86666', 'north': 48.73324, 'east': 11.1049028, 'south': 48.64405740000001, 'west': 10.9796735}, {
  'zipCode': '86668',
  'north':   48.7055301,
  'east':    11.3447295,
  'south':   48.6494696,
  'west':    11.2445949
}, {'zipCode': '86669', 'north': 48.7074128, 'east': 11.2541192, 'south': 48.5890985, 'west': 11.121851}, {
  'zipCode': '86672',
  'north':   48.5999421,
  'east':    10.9870347,
  'south':   48.5311511,
  'west':    10.8694903
}, {'zipCode': '86673', 'north': 48.7974543, 'east': 11.3067197, 'south': 48.7324056, 'west': 11.1602904}, {
  'zipCode': '86674',
  'north':   48.6034832,
  'east':    11.0081277,
  'south':   48.5637256,
  'west':    10.9241008
}, {'zipCode': '86675', 'north': 48.8058381, 'east': 10.8795917, 'south': 48.764676400000006, 'west': 10.801288}, {
  'zipCode': '86676',
  'north':   48.6754755,
  'east':    11.1746204,
  'south':   48.594546,
  'west':    11.0221354
}, {'zipCode': '86678', 'north': 48.6080398, 'east': 10.8183333, 'south': 48.5705055, 'west': 10.7637596}, {
  'zipCode': '86679',
  'north':   48.6386715,
  'east':    10.887454,
  'south':   48.586116,
  'west':    10.8415752
}, {'zipCode': '86681', 'north': 48.8705042, 'east': 10.8124114, 'south': 48.8096584, 'west': 10.7272377}, {
  'zipCode': '86682',
  'north':   48.7305956,
  'east':    10.9039126,
  'south':   48.691567500000005,
  'west':    10.8219974
}, {'zipCode': '86684', 'north': 48.6432641, 'east': 11.0044102, 'south': 48.5964618, 'west': 10.9141932}, {
  'zipCode': '86685',
  'north':   48.8643752,
  'east':    10.757766,
  'south':   48.8052427,
  'west':    10.664997900000001
}, {'zipCode': '86687', 'north': 48.8137985, 'east': 10.8972565, 'south': 48.725385, 'west': 10.728916}, {
  'zipCode': '86688',
  'north':   48.8079709,
  'east':    11.0047333,
  'south':   48.71872870000001,
  'west':    10.867141
}, {'zipCode': '86690', 'north': 48.6871512, 'east': 10.8479207, 'south': 48.6189804, 'west': 10.736655800000001}, {
  'zipCode': '86692',
  'north':   48.651555800000004,
  'east':    10.9314006,
  'south':   48.5841708,
  'west':    10.8788966
}, {'zipCode': '86694', 'north': 48.7411376, 'east': 10.9812417, 'south': 48.7010265, 'west': 10.869501}, {
  'zipCode': '86695',
  'north':   48.63507010000001,
  'east':    10.8540354,
  'south':   48.5743473,
  'west':    10.7764551
}, {'zipCode': '86697', 'north': 48.7408529, 'east': 11.1816559, 'south': 48.6603095, 'west': 11.0559118}, {
  'zipCode': '86698',
  'north':   48.6948645,
  'east':    10.8909746,
  'south':   48.6291855,
  'west':    10.8316959
}, {'zipCode': '86700', 'north': 48.901321, 'east': 10.834687700000002, 'south': 48.8590769, 'west': 10.7609444}, {
  'zipCode': '86701',
  'north':   48.7111154,
  'east':    11.2107892,
  'south':   48.6644723,
  'west':    11.1252337
}, {'zipCode': '86703', 'north': 48.8648725, 'east': 10.9847786, 'south': 48.8259665, 'west': 10.9225504}, {
  'zipCode': '86704',
  'north':   48.843629,
  'east':    11.0055069,
  'south':   48.8028626,
  'west':    10.9112931
}, {'zipCode': '86706', 'north': 48.7449189, 'east': 11.3769454, 'south': 48.6801405, 'west': 11.2770133}, {
  'zipCode': '86707',
  'north':   48.586323,
  'east':    10.8562358,
  'south':   48.5517668,
  'west':    10.7691824
}, {'zipCode': '86709', 'north': 48.9493797, 'east': 10.8325982, 'south': 48.8765367, 'west': 10.7136947}, {
  'zipCode': '86720',
  'north':   48.9225568,
  'east':    10.5939782,
  'south':   48.7945929,
  'west':    10.4264795
}, {'zipCode': '86732', 'north': 48.9976778, 'east': 10.625714, 'south': 48.9059843, 'west': 10.5335928}, {
  'zipCode': '86733',
  'north':   48.8701155,
  'east':    10.6843268,
  'south':   48.8220198,
  'west':    10.57577
}, {'zipCode': '86735', 'north': 48.78670410000001, 'east': 10.5313753, 'south': 48.6947347, 'west': 10.4120665}, {
  'zipCode': '86736',
  'north':   49.0360417,
  'east':    10.675124000000002,
  'south':   48.9736086,
  'west':    10.5167761
}, {'zipCode': '86738', 'north': 48.8869015, 'east': 10.6045357, 'south': 48.8447278, 'west': 10.5366466}, {
  'zipCode': '86739',
  'north':   48.8194705,
  'east':    10.5107751,
  'south':   48.77325100000001,
  'west':    10.4203222
}, {'zipCode': '86741', 'north': 48.9813582, 'east': 10.5808739, 'south': 48.9360633, 'west': 10.5088378}, {
  'zipCode': '86742',
  'north':   49.0117904,
  'east':    10.5382464,
  'south':   48.939509900000004,
  'west':    10.4015314
}, {'zipCode': '86744', 'north': 48.9817558, 'east': 10.6873483, 'south': 48.9321068, 'west': 10.6105743}, {
  'zipCode': '86745',
  'north':   48.79803340000001,
  'east':    10.5593845,
  'south':   48.7517343,
  'west':    10.4761934
}, {'zipCode': '86747', 'north': 48.9497432, 'east': 10.5409502, 'south': 48.903936, 'west': 10.4803912}, {
  'zipCode': '86748',
  'north':   48.95842,
  'east':    10.4919502,
  'south':   48.8994026,
  'west':    10.437826100000002
}, {'zipCode': '86750', 'north': 48.9606307, 'east': 10.701605600000002, 'south': 48.9188583, 'west': 10.6282756}, {
  'zipCode': '86751',
  'north':   48.7980354,
  'east':    10.6606809,
  'south':   48.7320208,
  'west':    10.5397165
}, {'zipCode': '86753', 'north': 48.8378399, 'east': 10.6350572, 'south': 48.7678855, 'west': 10.5286786}, {
  'zipCode': '86754',
  'north':   48.9379478,
  'east':    10.6988685,
  'south':   48.8876398,
  'west':    10.5747094
}, {'zipCode': '86756', 'north': 48.84893, 'east': 10.5548186, 'south': 48.8123536, 'west': 10.4975073}, {
  'zipCode': '86757',
  'north':   48.9150003,
  'east':    10.515445,
  'south':   48.8673605,
  'west':    10.4212612
}, {'zipCode': '86759', 'north': 48.9092658, 'east': 10.6670095, 'south': 48.8552334, 'west': 10.5713908}, {
  'zipCode': '86807',
  'north':   48.0593694,
  'east':    10.8166939,
  'south':   48.0010252,
  'west':    10.676875
}, {'zipCode': '86825', 'north': 48.0533428, 'east': 10.6609783, 'south': 47.9476902, 'west': 10.5442741}, {
  'zipCode': '86830',
  'north':   48.2300623,
  'east':    10.8028349,
  'south':   48.1596564,
  'west':    10.661576
}, {'zipCode': '86833', 'north': 48.1736352, 'east': 10.701539, 'south': 48.0835725, 'west': 10.6035675}, {
  'zipCode': '86836',
  'north':   48.206855100000006,
  'east':    10.8772629,
  'south':   48.132445600000004,
  'west':    10.7796628
}, {'zipCode': '86842', 'north': 48.0983664, 'east': 10.6787714, 'south': 48.016464000000006, 'west': 10.5836397}, {
  'zipCode': '86845',
  'north':   48.2544301,
  'east':    10.829009,
  'south':   48.2037105,
  'west':    10.6407744
}, {'zipCode': '86850', 'north': 48.3232991, 'east': 10.6924053, 'south': 48.2452975, 'west': 10.5916952}, {
  'zipCode': '86853',
  'north':   48.16937550000001,
  'east':    10.8014503,
  'south':   48.089038,
  'west':    10.6838654
}, {'zipCode': '86854', 'north': 48.0988826, 'east': 10.7080636, 'south': 48.0474833, 'west': 10.663798}, {
  'zipCode': '86856',
  'north':   48.1776222,
  'east':    10.7473787,
  'south':   48.1294891,
  'west':    10.68401
}, {'zipCode': '86857', 'north': 48.1386279, 'east': 10.859061, 'south': 48.0994091, 'west': 10.7871238}, {
  'zipCode': '86859',
  'north':   48.1035422,
  'east':    10.8483537,
  'south':   48.0333229,
  'west':    10.7560253
}, {'zipCode': '86860', 'north': 48.016228, 'east': 10.7608648, 'south': 47.9621873, 'west': 10.6454283}, {
  'zipCode': '86862',
  'north':   48.1114139,
  'east':    10.8171389,
  'south':   48.0465393,
  'west':    10.7013068
}, {'zipCode': '86863', 'north': 48.2794219, 'east': 10.6383976, 'south': 48.2419976, 'west': 10.5669774}, {
  'zipCode': '86865',
  'north':   48.17554,
  'east':    10.6484472,
  'south':   48.1116695,
  'west':    10.5412883
}, {'zipCode': '86866', 'north': 48.2502779, 'east': 10.6661204, 'south': 48.1949847, 'west': 10.5981814}, {
  'zipCode': '86868',
  'north':   48.2088325,
  'east':    10.6282884,
  'south':   48.1577603,
  'west':    10.5420409
}, {'zipCode': '86869', 'north': 47.9669587, 'east': 10.801112, 'south': 47.9297788, 'west': 10.687547000000002}, {
  'zipCode': '86871',
  'north':   48.0917566,
  'east':    10.6168686,
  'south':   48.0432031,
  'west':    10.5390669
}, {'zipCode': '86872', 'north': 48.2236898, 'east': 10.6711646, 'south': 48.1653831, 'west': 10.6151167}, {
  'zipCode': '86874',
  'north':   48.1465026,
  'east':    10.6162296,
  'south':   48.0559117,
  'west':    10.5096848
}, {'zipCode': '86875', 'north': 48.022901100000006, 'east': 10.8263776, 'south': 47.9514019, 'west': 10.7448192}, {
  'zipCode': '86877',
  'north':   48.2468111,
  'east':    10.6152691,
  'south':   48.2064049,
  'west':    10.5578134
}, {'zipCode': '86879', 'north': 48.0554925, 'east': 10.6991316, 'south': 48.0139834, 'west': 10.6498074}, {
  'zipCode': '86899',
  'north':   48.0794911,
  'east':    10.9238811,
  'south':   47.9878848,
  'west':    10.807257
}, {'zipCode': '86911', 'north': 48.0030878, 'east': 11.1322401, 'south': 47.8985639, 'west': 10.9670228}, {
  'zipCode': '86916',
  'north':   48.1353187,
  'east':    10.8986303,
  'south':   48.0674532,
  'west':    10.8426109
}, {'zipCode': '86919', 'north': 48.0437418, 'east': 11.1181639, 'south': 47.9867152, 'west': 11.0355952}, {
  'zipCode': '86920',
  'north':   47.9360023,
  'east':    10.9321389,
  'south':   47.8422086,
  'west':    10.7483766
}, {'zipCode': '86922', 'north': 48.1060975, 'east': 11.0604435, 'south': 48.0740459, 'west': 10.9978476}, {
  'zipCode': '86923',
  'north':   48.0507333,
  'east':    11.0544279,
  'south':   47.98908420000001,
  'west':    10.9677493
}, {'zipCode': '86925', 'north': 47.9797959, 'east': 10.8825246, 'south': 47.8829644, 'west': 10.7712914}, {
  'zipCode': '86926',
  'north':   48.0987339,
  'east':    11.104774,
  'south':   48.0615517,
  'west':    11.048987
}, {'zipCode': '86928', 'north': 48.0285169, 'east': 11.0099694, 'south': 47.9793311, 'west': 10.9264763}, {
  'zipCode': '86929',
  'north':   48.1198056,
  'east':    10.990448700000002,
  'south':   48.0397743,
  'west':    10.8862244
}, {'zipCode': '86931', 'north': 48.2377021, 'east': 10.960912300000002, 'south': 48.1601812, 'west': 10.8905225}, {
  'zipCode': '86932',
  'north':   48.034402400000005,
  'east':    10.9727467,
  'south':   47.97498110000001,
  'west':    10.8753319
}, {'zipCode': '86934', 'north': 47.9519236, 'east': 11.0076455, 'south': 47.9022376, 'west': 10.8818258}, {
  'zipCode': '86935',
  'north':   47.9308346,
  'east':    11.0226055,
  'south':   47.8741533,
  'west':    10.9471615
}, {'zipCode': '86937', 'north': 48.1914613, 'east': 10.9244154, 'south': 48.1214698, 'west': 10.8534862}, {
  'zipCode': '86938',
  'north':   48.0695843,
  'east':    11.1202669,
  'south':   48.03346,
  'west':    11.064327100000002
}, {'zipCode': '86940', 'north': 48.0650603, 'east': 10.9756641, 'south': 48.0214209, 'west': 10.9044095}, {
  'zipCode': '86941',
  'north':   48.1060975,
  'east':    11.0677738,
  'south':   48.0910319,
  'west':    11.026801
}, {'zipCode': '86943', 'north': 47.9889594, 'east': 10.9985516, 'south': 47.9588092, 'west': 10.9294904}, {
  'zipCode': '86944',
  'north':   47.9986274,
  'east':    10.882577,
  'south':   47.9587082,
  'west':    10.8054361
}, {'zipCode': '86946', 'north': 47.9856045, 'east': 10.9807092, 'south': 47.9330345, 'west': 10.8643982}, {
  'zipCode': '86947',
  'north':   48.1682006,
  'east':    11.0221064,
  'south':   48.0849565,
  'west':    10.8817154
}, {'zipCode': '86949', 'north': 48.088978, 'east': 11.073623, 'south': 48.0351433, 'west': 10.9778441}, {
  'zipCode': '86956',
  'north':   47.8411661,
  'east':    10.9322544,
  'south':   47.7838366,
  'west':    10.8557773
}, {'zipCode': '86971', 'north': 47.8816624, 'east': 11.0024819, 'south': 47.7364116, 'west': 10.8505536}, {
  'zipCode': '86972',
  'north':   47.8688836,
  'east':    10.8906915,
  'south':   47.7755905,
  'west':    10.8252726
}, {'zipCode': '86974', 'north': 47.9169433, 'east': 10.983589, 'south': 47.861906, 'west': 10.9184038}, {
  'zipCode': '86975',
  'north':   47.7689263,
  'east':    10.8286161,
  'south':   47.681505300000005,
  'west':    10.7156838
}, {'zipCode': '86977', 'north': 47.7997046, 'east': 10.8614951, 'south': 47.7418584, 'west': 10.7651488}, {
  'zipCode': '86978',
  'north':   47.874257,
  'east':    10.932318700000001,
  'south':   47.8354507,
  'west':    10.86294
}, {'zipCode': '86980', 'north': 47.8305537, 'east': 10.832426, 'south': 47.7859663, 'west': 10.7468326}, {
  'zipCode': '86981',
  'north':   47.9001674,
  'east':    10.9332201,
  'south':   47.8581035,
  'west':    10.8682725
}, {'zipCode': '86983', 'north': 47.7278712, 'east': 10.829434400000002, 'south': 47.6577509, 'west': 10.7402765}, {
  'zipCode': '86984',
  'north':   47.7075047,
  'east':    10.8506983,
  'south':   47.6560155,
  'west':    10.778203
}, {'zipCode': '86986', 'north': 47.8341914, 'east': 10.8566583, 'south': 47.80881240000001, 'west': 10.7957333}, {
  'zipCode': '86987',
  'north':   47.8640453,
  'east':    10.8604081,
  'south':   47.8238438,
  'west':    10.7583384
}, {'zipCode': '86989', 'north': 47.7717393, 'east': 10.9194693, 'south': 47.6550472, 'west': 10.802702}, {
  'zipCode': '87435',
  'north':   47.7292892,
  'east':    10.3241077,
  'south':   47.694586,
  'west':    10.2739224
}, {'zipCode': '87437', 'north': 47.7717985, 'east': 10.378058, 'south': 47.6947686, 'west': 10.2993553}, {
  'zipCode': '87439',
  'north':   47.7779516,
  'east':    10.3199933,
  'south':   47.6974181,
  'west':    10.2326564
}, {'zipCode': '87448', 'north': 47.7011511, 'east': 10.3249174, 'south': 47.6059609, 'west': 10.176592800000002}, {
  'zipCode': '87452',
  'north':   47.8405332,
  'east':    10.2906648,
  'south':   47.7434146,
  'west':    10.0651974
}, {'zipCode': '87459', 'north': 47.6130023, 'east': 10.5979156, 'south': 47.5334723, 'west': 10.4544285}, {
  'zipCode': '87463',
  'north':   47.8637638,
  'east':    10.3529848,
  'south':   47.7900917,
  'west':    10.1859417
}, {'zipCode': '87466', 'north': 47.6913431, 'east': 10.5112811, 'south': 47.6112263, 'west': 10.3293126}, {
  'zipCode': '87471',
  'north':   47.71453540000001,
  'east':    10.4494685,
  'south':   47.6784548,
  'west':    10.3188512
}, {'zipCode': '87474', 'north': 47.7525249, 'east': 10.286058, 'south': 47.669462700000004, 'west': 10.1128174}, {
  'zipCode': '87477',
  'north':   47.6922971,
  'east':    10.425295000000002,
  'south':   47.6163825,
  'west':    10.3041598
}, {'zipCode': '87480', 'north': 47.703591, 'east': 10.2302126, 'south': 47.6116938, 'west': 10.0670403}, {
  'zipCode': '87484',
  'north':   47.6545226,
  'east':    10.5511213,
  'south':   47.5843703,
  'west':    10.4500543
}, {'zipCode': '87487', 'north': 47.7762853, 'east': 10.2668897, 'south': 47.71185260000001, 'west': 10.1397412}, {
  'zipCode': '87488',
  'north':   47.7667182,
  'east':    10.4698897,
  'south':   47.6889093,
  'west':    10.3570329
}, {'zipCode': '87490', 'north': 47.8328993, 'east': 10.3976418, 'south': 47.7572129, 'west': 10.3145427}, {
  'zipCode': '87493',
  'north':   47.798475,
  'east':    10.3419067,
  'south':   47.7669683,
  'west':    10.2824902
}, {'zipCode': '87494', 'north': 47.6838069, 'east': 10.5910058, 'south': 47.6415283, 'west': 10.5043653}, {
  'zipCode': '87496',
  'north':   47.8879156,
  'east':    10.4043414,
  'south':   47.8044291,
  'west':    10.324898
}, {'zipCode': '87497', 'north': 47.6281761, 'east': 10.4747622, 'south': 47.5333251, 'west': 10.3561937}, {
  'zipCode': '87499',
  'north':   47.8111512,
  'east':    10.457300200000002,
  'south':   47.7443738,
  'west':    10.385799
}, {'zipCode': '87509', 'north': 47.6298749, 'east': 10.271939, 'south': 47.5127421, 'west': 10.1193209}, {
  'zipCode': '87527',
  'north':   47.5515883,
  'east':    10.3736831,
  'south':   47.4595388,
  'west':    10.1953652
}, {'zipCode': '87534', 'north': 47.5834211, 'east': 10.1651031, 'south': 47.4656259, 'west': 9.9584168}, {
  'zipCode': '87538',
  'north':   47.4864701,
  'east':    10.2922534,
  'south':   47.3896828,
  'west':    10.0566789
}, {'zipCode': '87541', 'north': 47.5612207, 'east': 10.4759984, 'south': 47.3703237, 'west': 10.3219365}, {
  'zipCode': '87544',
  'north':   47.5540132,
  'east':    10.2669523,
  'south':   47.4541097,
  'west':    10.1046809
}, {'zipCode': '87545', 'north': 47.5625063, 'east': 10.3571441, 'south': 47.524267, 'west': 10.2457001}, {
  'zipCode': '87547',
  'north':   47.6274647,
  'east':    10.168154400000002,
  'south':   47.567577,
  'west':    10.0584057
}, {'zipCode': '87549', 'north': 47.6269293, 'east': 10.3892505, 'south': 47.5478587, 'west': 10.2439358}, {
  'zipCode': '87561',
  'north':   47.4715831,
  'east':    10.3892998,
  'south':   47.2701114,
  'west':    10.0829423
}, {'zipCode': '87600', 'north': 47.9228255, 'east': 10.6612118, 'south': 47.8409314, 'west': 10.551097900000002}, {
  'zipCode': '87616',
  'north':   47.8056518,
  'east':    10.7126756,
  'south':   47.6739039,
  'west':    10.5220627
}, {'zipCode': '87629', 'north': 47.6204664, 'east': 10.7272695, 'south': 47.550991, 'west': 10.582768300000001}, {
  'zipCode': '87634',
  'north':   47.9072736,
  'east':    10.5006604,
  'south':   47.797742500000005,
  'west':    10.3724125
}, {'zipCode': '87637', 'north': 47.698659, 'east': 10.6740399, 'south': 47.590109, 'west': 10.5443374}, {
  'zipCode': '87640',
  'north':   47.8557926,
  'east':    10.6811501,
  'south':   47.7883396,
  'west':    10.6018127
}, {'zipCode': '87642', 'north': 47.6676653, 'east': 10.9538737, 'south': 47.53361600000001, 'west': 10.736752}, {
  'zipCode': '87645',
  'north':   47.6455193,
  'east':    10.8531642,
  'south':   47.5139211,
  'west':    10.6918589
}, {'zipCode': '87647', 'north': 47.8163067, 'east': 10.5462549, 'south': 47.7090918, 'west': 10.4354729}, {
  'zipCode': '87648',
  'north':   47.8625065,
  'east':    10.5598791,
  'south':   47.7929782,
  'west':    10.4668919
}, {'zipCode': '87650', 'north': 47.9845884, 'east': 10.5872666, 'south': 47.9189341, 'west': 10.5022151}, {
  'zipCode': '87651',
  'north':   47.8553365,
  'east':    10.7713291,
  'south':   47.783838100000004,
  'west':    10.6753426
}, {'zipCode': '87653', 'north': 47.9479244, 'east': 10.538176700000001, 'south': 47.8820544, 'west': 10.437585}, {
  'zipCode': '87654',
  'north':   47.8957515,
  'east':    10.5715436,
  'south':   47.8449877,
  'west':    10.4792514
}, {'zipCode': '87656', 'north': 47.9811424, 'east': 10.7275117, 'south': 47.9038656, 'west': 10.6426912}, {
  'zipCode': '87657',
  'north':   47.7275452,
  'east':    10.5345155,
  'south':   47.6600918,
  'west':    10.459532200000002
}, {'zipCode': '87659', 'north': 47.63256640000001, 'east': 10.6704148, 'south': 47.5848848, 'west': 10.6105983}, {
  'zipCode': '87660',
  'north':   47.92975090000001,
  'east':    10.6024658,
  'south':   47.8849487,
  'west':    10.5279308
}, {'zipCode': '87662', 'north': 47.9404937, 'east': 10.791135000000002, 'south': 47.8399283, 'west': 10.7121651}, {
  'zipCode': '87663',
  'north':   47.725224,
  'east':    10.654386,
  'south':   47.6626186,
  'west':    10.5479774
}, {'zipCode': '87665', 'north': 47.9076102, 'east': 10.6917165, 'south': 47.8382009, 'west': 10.651381900000002}, {
  'zipCode': '87666',
  'north':   47.9681283,
  'east':    10.6530174,
  'south':   47.9033007,
  'west':    10.5679884
}, {'zipCode': '87668', 'north': 47.970825, 'east': 10.6721595, 'south': 47.935771, 'west': 10.6242579}, {
  'zipCode': '87669',
  'north':   47.6447774,
  'east':    10.7430511,
  'south':   47.6016114,
  'west':    10.6641327
}, {'zipCode': '87671', 'north': 47.9256156, 'east': 10.4493991, 'south': 47.8763274, 'west': 10.3587484}, {
  'zipCode': '87672',
  'north':   47.6932738,
  'east':    10.7727791,
  'south':   47.6272151,
  'west':    10.6535872
}, {'zipCode': '87674', 'north': 47.8601547, 'east': 10.6198114, 'south': 47.7841093, 'west': 10.5377996}, {
  'zipCode': '87675',
  'north':   47.7985094,
  'east':    10.7828579,
  'south':   47.68516460000001,
  'west':    10.6597569
}, {'zipCode': '87677', 'north': 47.9109315, 'east': 10.7379022, 'south': 47.8424374, 'west': 10.6817381}, {
  'zipCode': '87679',
  'north':   47.9458422,
  'east':    10.7342997,
  'south':   47.9010656,
  'west':    10.6836029
}, {'zipCode': '87700', 'north': 48.0474893, 'east': 10.2339473, 'south': 47.915513, 'west': 10.084774}, {
  'zipCode': '87719',
  'north':   48.0989782,
  'east':    10.5670621,
  'south':   48.0136994,
  'west':    10.4346519
}, {'zipCode': '87724', 'north': 47.9936165, 'east': 10.382480600000001, 'south': 47.8816884, 'west': 10.2654353}, {
  'zipCode': '87727',
  'north':   48.168912,
  'east':    10.2927611,
  'south':   48.0905583,
  'west':    10.2060066
}, {'zipCode': '87730', 'north': 47.9133017, 'east': 10.3065957, 'south': 47.8393652, 'west': 10.174263500000002}, {
  'zipCode': '87733',
  'north':   47.9966618,
  'east':    10.4492144,
  'south':   47.8984501,
  'west':    10.3347217
}, {'zipCode': '87734', 'north': 47.9825961, 'east': 10.2430832, 'south': 47.9476793, 'west': 10.186556800000002}, {
  'zipCode': '87736',
  'north':   47.9113465,
  'east':    10.3644023,
  'south':   47.8560881,
  'west':    10.2672209
}, {'zipCode': '87737', 'north': 48.1113649, 'east': 10.2447809, 'south': 48.0653944, 'west': 10.1692414}, {
  'zipCode': '87739',
  'north':   48.179819300000005,
  'east':    10.4499479,
  'south':   48.0816759,
  'west':    10.3443289
}, {'zipCode': '87740', 'north': 48.0161295, 'east': 10.1543858, 'south': 47.95798, 'west': 10.1133115}, {
  'zipCode': '87742',
  'north':   48.0301389,
  'east':    10.5575055,
  'south':   47.959788,
  'west':    10.4388201
}, {'zipCode': '87743', 'north': 48.1195245, 'east': 10.3249396, 'south': 48.064663700000004, 'west': 10.2336341}, {
  'zipCode': '87745',
  'north':   48.2250759,
  'east':    10.5979325,
  'south':   48.1328025,
  'west':    10.4829696
}, {'zipCode': '87746', 'north': 48.0980766, 'east': 10.3960418, 'south': 48.0143034, 'west': 10.299676900000001}, {
  'zipCode': '87748',
  'north':   48.0953208,
  'east':    10.1712792,
  'south':   48.058901,
  'west':    10.1350666
}, {'zipCode': '87749', 'north': 47.992010500000006, 'east': 10.3044402, 'south': 47.9538195, 'west': 10.2356762}, {
  'zipCode': '87751',
  'north':   48.06494260000001,
  'east':    10.212627,
  'south':   48.0228193,
  'west':    10.1280195
}, {'zipCode': '87752', 'north': 48.0441065, 'east': 10.2739769, 'south': 48.0023576, 'west': 10.2226005}, {
  'zipCode': '87754',
  'north':   48.0804616,
  'east':    10.4455211,
  'south':   48.0161007,
  'west':    10.359542
}, {'zipCode': '87755', 'north': 48.1980424, 'east': 10.3634283, 'south': 48.1104925, 'west': 10.2736071}, {
  'zipCode': '87757',
  'north':   48.2172034,
  'east':    10.5101029,
  'south':   48.1509948,
  'west':    10.3890547
}, {'zipCode': '87758', 'north': 47.9387087, 'east': 10.1958885, 'south': 47.8695129, 'west': 10.1050085}, {
  'zipCode': '87760',
  'north':   47.9616763,
  'east':    10.2869862,
  'south':   47.9224101,
  'west':    10.2066143
}, {'zipCode': '87761', 'north': 48.096101, 'east': 10.336323, 'south': 48.040828, 'west': 10.229615}, {
  'zipCode': '87763',
  'north':   47.9195196,
  'east':    10.1238918,
  'south':   47.8556421,
  'west':    10.0769931
}, {'zipCode': '87764', 'north': 47.9001043, 'east': 10.1926567, 'south': 47.8200688, 'west': 10.0919067}, {
  'zipCode': '87766',
  'north':   48.0040591,
  'east':    10.2444805,
  'south':   47.9803395,
  'west':    10.2001189
}, {'zipCode': '87767', 'north': 48.0808362, 'east': 10.2392221, 'south': 48.0413235, 'west': 10.1622094}, {
  'zipCode': '87769',
  'north':   48.119558,
  'east':    10.4547805,
  'south':   48.0707037,
  'west':    10.3549186
}, {'zipCode': '87770', 'north': 48.1400097, 'east': 10.356052400000001, 'south': 48.087446, 'west': 10.2626443}, {
  'zipCode': '87772',
  'north':   48.16006,
  'east':    10.4789322,
  'south':   48.0840043,
  'west':    10.4110801
}, {'zipCode': '87773', 'north': 48.1247878, 'east': 10.1974931, 'south': 48.079348, 'west': 10.1358859}, {
  'zipCode': '87775',
  'north':   48.1638694,
  'east':    10.5240759,
  'south':   48.09364550000001,
  'west':    10.4462525
}, {'zipCode': '87776', 'north': 48.0258665, 'east': 10.4176045, 'south': 47.9661094, 'west': 10.3127826}, {
  'zipCode': '87778',
  'north':   48.035843,
  'east':    10.4684838,
  'south':   47.9834245,
  'west':    10.3971982
}, {'zipCode': '87779', 'north': 48.0110781, 'east': 10.2311803, 'south': 47.9969589, 'west': 10.200105}, {
  'zipCode': '87781',
  'north':   48.0197803,
  'east':    10.295445,
  'south':   47.9835277,
  'west':    10.2415785
}, {'zipCode': '87782', 'north': 47.9767423, 'east': 10.5213153, 'south': 47.9221587, 'west': 10.4308118}, {
  'zipCode': '87784',
  'north':   48.0553438,
  'east':    10.3338512,
  'south':   47.9875476,
  'west':    10.2532819
}, {'zipCode': '87785', 'north': 48.1385664, 'east': 10.242279, 'south': 48.1021867, 'west': 10.1907837}, {
  'zipCode': '87787',
  'north':   47.9270159,
  'east':    10.3033166,
  'south':   47.8689272,
  'west':    10.2290203
}, {'zipCode': '87789', 'north': 47.9523995, 'east': 10.2388117, 'south': 47.9015936, 'west': 10.1585185}, {
  'zipCode': '88045',
  'north':   47.6822875,
  'east':    9.4887389,
  'south':   47.6482267,
  'west':    9.4231854
}, {'zipCode': '88046', 'north': 47.6815725, 'east': 9.5296198, 'south': 47.6378052, 'west': 9.479148300000002}, {
  'zipCode': '88048',
  'north':   47.7378725,
  'east':    9.5608112,
  'south':   47.6609243,
  'west':    9.3815131
}, {'zipCode': '88069', 'north': 47.7055265, 'east': 9.6931989, 'south': 47.6036859, 'west': 9.544070300000001}, {
  'zipCode': '88074',
  'north':   47.7366501,
  'east':    9.627527,
  'south':   47.6549223,
  'west':    9.5133323
}, {'zipCode': '88079', 'north': 47.6365416, 'east': 9.6456692, 'south': 47.5797796, 'west': 9.5545949}, {
  'zipCode': '88085',
  'north':   47.6422874,
  'east':    9.5978263,
  'south':   47.5864105,
  'west':    9.5264762
}, {'zipCode': '88090', 'north': 47.693519, 'east': 9.3953063, 'south': 47.6602054, 'west': 9.329788500000001}, {
  'zipCode': '88094',
  'north':   47.7649963,
  'east':    9.5014268,
  'south':   47.6986294,
  'west':    9.4283351
}, {'zipCode': '88097', 'north': 47.661761, 'east': 9.5690728, 'south': 47.6120491, 'west': 9.5106877}, {
  'zipCode': '88099',
  'north':   47.6888132,
  'east':    9.7447006,
  'south':   47.6319196,
  'west':    9.6576915
}, {'zipCode': '88131', 'north': 47.6120659, 'east': 9.7408412, 'south': 47.5336621, 'west': 9.6362276}, {
  'zipCode': '88138',
  'north':   47.6432112,
  'east':    9.8398206,
  'south':   47.553265700000004,
  'west':    9.7056385
}, {'zipCode': '88142', 'north': 47.5935611, 'east': 9.6614395, 'south': 47.5574093, 'west': 9.6195812}, {
  'zipCode': '88145',
  'north':   47.678619,
  'east':    9.9244063,
  'south':   47.6039799,
  'west':    9.7934235
}, {'zipCode': '88147', 'north': 47.6449759, 'east': 9.7450247, 'south': 47.6017143, 'west': 9.681841200000001}, {
  'zipCode': '88149',
  'north':   47.5861638,
  'east':    9.6305897,
  'south':   47.56864,
  'west':    9.6020771
}, {'zipCode': '88161', 'north': 47.6209262, 'east': 9.9304335, 'south': 47.5823276, 'west': 9.8588773}, {
  'zipCode': '88167',
  'north':   47.6878075,
  'east':    10.0769879,
  'south':   47.5596842,
  'west':    9.9196757
}, {'zipCode': '88171', 'north': 47.6126429, 'east': 9.9729333, 'south': 47.5384518, 'west': 9.8652766}, {
  'zipCode': '88175',
  'north':   47.6099349,
  'east':    9.8892365,
  'south':   47.5285372,
  'west':    9.8150703
}, {'zipCode': '88178', 'north': 47.6600813, 'east': 9.9461582, 'south': 47.6124489, 'west': 9.8541378}, {
  'zipCode': '88179',
  'north':   47.5795575,
  'east':    9.9696237,
  'south':   47.5296364,
  'west':    9.9085306
}, {'zipCode': '88212', 'north': 47.804199700000005, 'east': 9.6631781, 'south': 47.7489742, 'west': 9.5818366}, {
  'zipCode': '88213',
  'north':   47.8155938,
  'east':    9.608746,
  'south':   47.7303094,
  'west':    9.483661
}, {'zipCode': '88214', 'north': 47.7850539, 'east': 9.6547281, 'south': 47.701165, 'west': 9.5627519}, {
  'zipCode': '88239',
  'north':   47.7737764,
  'east':    9.8982351,
  'south':   47.6029327,
  'west':    9.7273425
}, {'zipCode': '88250', 'north': 47.8250025, 'east': 9.6737212, 'south': 47.784703500000006, 'west': 9.6052095}, {
  'zipCode': '88255',
  'north':   47.8897365,
  'east':    9.7274688,
  'south':   47.80441350000001,
  'west':    9.6113259
}, {'zipCode': '88260', 'north': 47.7542766, 'east': 10.0038516, 'south': 47.6519505, 'west': 9.8672387}, {
  'zipCode': '88263',
  'north':   47.8637217,
  'east':    9.5401847,
  'south':   47.7565444,
  'west':    9.4293885
}, {'zipCode': '88267', 'north': 47.8129885, 'east': 9.806504, 'south': 47.7479281, 'west': 9.7294601}, {
  'zipCode': '88271',
  'north':   47.9055613,
  'east':    9.4815587,
  'south':   47.81827650000001,
  'west':    9.3759667
}, {'zipCode': '88273', 'north': 47.9081471, 'east': 9.6412643, 'south': 47.8342471, 'west': 9.4802382}, {
  'zipCode': '88276',
  'north':   47.85720340000001,
  'east':    9.6164916,
  'south':   47.7945732,
  'west':    9.5312234
}, {'zipCode': '88279', 'north': 47.7496805, 'east': 9.8115312, 'south': 47.6811347, 'west': 9.7102072}, {
  'zipCode': '88281',
  'north':   47.8334934,
  'east':    9.7442887,
  'south':   47.7497513,
  'west':    9.6466222
}, {'zipCode': '88284', 'north': 47.9183367, 'east': 9.6787287, 'south': 47.861909, 'west': 9.5604195}, {
  'zipCode': '88285',
  'north':   47.7399448,
  'east':    9.7321463,
  'south':   47.6813533,
  'west':    9.6513575
}, {'zipCode': '88287', 'north': 47.7717472, 'east': 9.6943539, 'south': 47.7120571, 'west': 9.6313429}, {
  'zipCode': '88289',
  'north':   47.7959821,
  'east':    9.7560127,
  'south':   47.7288245,
  'west':    9.6836486
}, {'zipCode': '88299', 'north': 47.8834708, 'east': 10.1379981, 'south': 47.7366686, 'west': 9.9037278}, {
  'zipCode': '88316',
  'north':   47.7671247,
  'east':    10.1398021,
  'south':   47.6390512,
  'west':    9.9771768
}, {'zipCode': '88317', 'north': 47.9230272, 'east': 10.1104057, 'south': 47.8621513, 'west': 9.9985703}, {
  'zipCode': '88319',
  'north':   47.9812587,
  'east':    10.1125146,
  'south':   47.9034737,
  'west':    9.9949639
}, {'zipCode': '88326', 'north': 47.9832833, 'east': 9.7297726, 'south': 47.9050501, 'west': 9.5672843}, {
  'zipCode': '88339',
  'north':   47.9810095,
  'east':    9.8457619,
  'south':   47.8552122,
  'west':    9.6554322
}, {'zipCode': '88348', 'north': 48.0691718, 'east': 9.6185438, 'south': 47.9572469, 'west': 9.3969691}, {
  'zipCode': '88353',
  'north':   47.848383,
  'east':    9.9639304,
  'south':   47.7266874,
  'west':    9.8029069
}, {'zipCode': '88356', 'north': 48.0151371, 'east': 9.4563153, 'south': 47.8819299, 'west': 9.2783386}, {
  'zipCode': '88361',
  'north':   47.9903844,
  'east':    9.6067738,
  'south':   47.8981225,
  'west':    9.4628468
}, {'zipCode': '88364', 'north': 47.8791875, 'east': 9.837041, 'south': 47.7798658, 'west': 9.7319422}, {
  'zipCode': '88367',
  'north':   48.0635871,
  'east':    9.4342261,
  'south':   47.9853831,
  'west':    9.333709500000001
}, {'zipCode': '88368', 'north': 47.8800751, 'east': 9.7903841, 'south': 47.8283641, 'west': 9.6980402}, {
  'zipCode': '88370',
  'north':   47.9238273,
  'east':    9.5467375,
  'south':   47.8833368,
  'west':    9.487065700000002
}, {'zipCode': '88371', 'north': 48.0056123, 'east': 9.6224528, 'south': 47.9330266, 'west': 9.5385509}, {
  'zipCode': '88373',
  'north':   47.8938719,
  'east':    9.5002751,
  'south':   47.866011,
  'west':    9.4548132
}, {'zipCode': '88374', 'north': 47.9648147, 'east': 9.5021684, 'south': 47.9255347, 'west': 9.4214742}, {
  'zipCode': '88376',
  'north':   47.9423567,
  'east':    9.4534394,
  'south':   47.9152767,
  'west':    9.4018223
}, {'zipCode': '88377', 'north': 47.9241299, 'east': 9.457962, 'south': 47.8940157, 'west': 9.3990883}, {
  'zipCode': '88379',
  'north':   47.9276918,
  'east':    9.5082246,
  'south':   47.8809618,
  'west':    9.4385661
}, {'zipCode': '88400', 'north': 48.1302278, 'east': 9.9062784, 'south': 48.0472189, 'west': 9.671518}, {
  'zipCode': '88410',
  'north':   47.985089,
  'east':    10.048239,
  'south':   47.8295289,
  'west':    9.7981813
}, {'zipCode': '88416', 'north': 48.1225075, 'east': 10.039179100000002, 'south': 47.9854988, 'west': 9.863521100000002}, {
  'zipCode': '88422',
  'north':   48.1393399,
  'east':    9.686528,
  'south':   48.0330663,
  'west':    9.5321254
}, {'zipCode': '88427', 'north': 48.05237960000001, 'east': 9.7091043, 'south': 47.9647279, 'west': 9.5965606}, {
  'zipCode': '88430',
  'north':   48.0519381,
  'east':    10.066906500000002,
  'south':   47.9457376,
  'west':    9.9127102
}, {'zipCode': '88433', 'north': 48.2164668, 'east': 9.8436391, 'south': 48.1193459, 'west': 9.7112845}, {
  'zipCode': '88436',
  'north':   48.0318495,
  'east':    9.9233633,
  'south':   47.9507658,
  'west':    9.7574674
}, {'zipCode': '88437', 'north': 48.1760178, 'east': 9.9410105, 'south': 48.0914747, 'west': 9.8130223}, {
  'zipCode': '88441',
  'north':   48.1147538,
  'east':    9.7788415,
  'south':   48.0509545,
  'west':    9.7033462
}, {'zipCode': '88444', 'north': 48.0898953, 'east': 9.8704663, 'south': 48.0174956, 'west': 9.8060672}, {
  'zipCode': '88447',
  'north':   48.1644536,
  'east':    9.8334828,
  'south':   48.1077941,
  'west':    9.7255508
}, {'zipCode': '88448', 'north': 48.179809, 'east': 9.7313874, 'south': 48.1086776, 'west': 9.6487454}, {
  'zipCode': '88450',
  'north':   48.0749617,
  'east':    10.1068412,
  'south':   48.0183137,
  'west':    10.018751900000002
}, {'zipCode': '88451', 'north': 48.1296773, 'east': 10.1396741, 'south': 48.0895495, 'west': 10.0524677}, {
  'zipCode': '88453',
  'north':   48.1177327,
  'east':    10.1046576,
  'south':   48.0589639,
  'west':    9.9932566
}, {'zipCode': '88454', 'north': 48.0589567, 'east': 9.8350734, 'south': 47.9906777, 'west': 9.7498243}, {
  'zipCode': '88456',
  'north':   48.0628846,
  'east':    9.7874126,
  'south':   47.9710187,
  'west':    9.6862338
}, {'zipCode': '88457', 'north': 48.0989082, 'east': 10.14411, 'south': 48.0220717, 'west': 10.0401689}, {
  'zipCode': '88459',
  'north':   48.0268774,
  'east':    10.1361204,
  'south':   47.9735716,
  'west':    10.0438125
}, {'zipCode': '88471', 'north': 48.2699517, 'east': 9.990991400000002, 'south': 48.190307, 'west': 9.7640875}, {
  'zipCode': '88477',
  'north':   48.2485063,
  'east':    10.0430679,
  'south':   48.1353001,
  'west':    9.9175331
}, {'zipCode': '88480', 'north': 48.2939754, 'east': 9.9516549, 'south': 48.2331226, 'west': 9.8581729}, {
  'zipCode': '88481',
  'north':   48.1994108,
  'east':    10.0965111,
  'south':   48.1423845,
  'west':    10.0094174
}, {'zipCode': '88483', 'north': 48.2536028, 'east': 10.0034497, 'south': 48.2065336, 'west': 9.9005807}, {
  'zipCode': '88484',
  'north':   48.1534188,
  'east':    10.0523771,
  'south':   48.1011141,
  'west':    9.9140096
}, {'zipCode': '88486', 'north': 48.1639596, 'east': 10.1104823, 'south': 48.1148902, 'west': 10.0273541}, {
  'zipCode': '88487',
  'north':   48.203058,
  'east':    9.9603208,
  'south':   48.1554581,
  'west':    9.8377447
}, {'zipCode': '88489', 'north': 48.2050204, 'east': 10.068671900000002, 'south': 48.151223, 'west': 9.9785647}, {
  'zipCode': '88499',
  'north':   48.2537264,
  'east':    9.5459776,
  'south':   48.0904449,
  'west':    9.3496753
}, {'zipCode': '88512', 'north': 48.0952737, 'east': 9.3877428, 'south': 47.9897709, 'west': 9.261357200000003}, {
  'zipCode': '88515',
  'north':   48.2395664,
  'east':    9.4288996,
  'south':   48.1046624,
  'west':    9.2599483
}, {'zipCode': '88518', 'north': 48.1025615, 'east': 9.5075198, 'south': 48.0162307, 'west': 9.3777047}, {
  'zipCode': '88521',
  'north':   48.1242424,
  'east':    9.5449502,
  'south':   48.0619502,
  'west':    9.3914277
}, {'zipCode': '88524', 'north': 48.2072525, 'east': 9.6776898, 'south': 48.115106, 'west': 9.544196}, {
  'zipCode': '88525',
  'north':   48.149925,
  'east':    9.5681562,
  'south':   48.0847725,
  'west':    9.4974813
}, {'zipCode': '88527', 'north': 48.2025707, 'east': 9.6021422, 'south': 48.1425512, 'west': 9.4951498}, {
  'zipCode': '88529',
  'north':   48.2671489,
  'east':    9.5003646,
  'south':   48.1949436,
  'west':    9.3671205
}, {'zipCode': '88605', 'north': 48.06108840000001, 'east': 9.1947548, 'south': 47.9097405, 'west': 9.0116522}, {
  'zipCode': '88630',
  'north':   47.9887717,
  'east':    9.3467027,
  'south':   47.8578557,
  'west':    9.1683505
}, {'zipCode': '88631', 'north': 48.1096305, 'east': 9.1151735, 'south': 48.0281179, 'west': 8.9341838}, {
  'zipCode': '88633',
  'north':   47.8821808,
  'east':    9.374536,
  'south':   47.8004254,
  'west':    9.2369543
}, {'zipCode': '88634', 'north': 47.9085578, 'east': 9.2588193, 'south': 47.8405376, 'west': 9.1177715}, {
  'zipCode': '88636',
  'north':   47.9005264,
  'east':    9.4116075,
  'south':   47.817339,
  'west':    9.3162737
}, {'zipCode': '88637', 'north': 48.0857481, 'east': 9.0917765, 'south': 47.9829871, 'west': 8.9340709}, {
  'zipCode': '88639',
  'north':   47.9799697,
  'east':    9.225345,
  'south':   47.8821695,
  'west':    9.1025961
}, {'zipCode': '88662', 'north': 47.8591685, 'east': 9.2561134, 'south': 47.7454429, 'west': 9.0654361}, {
  'zipCode': '88677',
  'north':   47.7567823,
  'east':    9.4647789,
  'south':   47.6774356,
  'west':    9.3092649
}, {'zipCode': '88682', 'north': 47.8171989, 'east': 9.3660543, 'south': 47.7238768, 'west': 9.2211664}, {
  'zipCode': '88690',
  'north':   47.7593345,
  'east':    9.2859306,
  'south':   47.71365170000001,
  'west':    9.2051514
}, {'zipCode': '88693', 'north': 47.8381316, 'east': 9.4638161, 'south': 47.7346546, 'west': 9.3357652}, {
  'zipCode': '88696',
  'north':   47.85863280000001,
  'east':    9.2455857,
  'south':   47.7879357,
  'west':    9.1019029
}, {'zipCode': '88697', 'north': 47.7531068, 'east': 9.3831056, 'south': 47.700308500000006, 'west': 9.3050771}, {
  'zipCode': '88699',
  'north':   47.8498717,
  'east':    9.3130238,
  'south':   47.7920812,
  'west':    9.213204
}, {'zipCode': '88709', 'north': 47.730217, 'east': 9.3380502, 'south': 47.6691115, 'west': 9.2369377}, {
  'zipCode': '88718',
  'north':   47.7197359,
  'east':    9.28673,
  'south':   47.703334,
  'west':    9.2530263
}, {'zipCode': '88719', 'north': 47.7089645, 'east': 9.3165143, 'south': 47.6772903, 'west': 9.2882152}, {
  'zipCode': '89073',
  'north':   48.4189038,
  'east':    10.0178694,
  'south':   48.3935309,
  'west':    9.9825465
}, {'zipCode': '89075', 'north': 48.4369026, 'east': 10.0428303, 'south': 48.4028113, 'west': 9.9362034}, {
  'zipCode': '89077',
  'north':   48.4063928,
  'east':    9.9892596,
  'south':   48.3784498,
  'west':    9.9498846
}, {'zipCode': '89079', 'north': 48.3814482, 'east': 10.0004356, 'south': 48.3066627, 'west': 9.8420502}, {
  'zipCode': '89081',
  'north':   48.4685153,
  'east':    10.0382533,
  'south':   48.36580910000001,
  'west':    9.8718164
}, {'zipCode': '89129', 'north': 48.5724072, 'east': 10.2026493, 'south': 48.45360320000001, 'west': 10.0002072}, {
  'zipCode': '89134',
  'north':   48.493458,
  'east':    9.9427673,
  'south':   48.3730215,
  'west':    9.7975757
}, {'zipCode': '89143', 'north': 48.456764, 'east': 9.8648045, 'south': 48.3539489, 'west': 9.6790282}, {
  'zipCode': '89150',
  'north':   48.536057,
  'east':    9.8020699,
  'south':   48.4322868,
  'west':    9.5818252
}, {'zipCode': '89155', 'north': 48.3641214, 'east': 9.9403185, 'south': 48.270193500000005, 'west': 9.761009}, {
  'zipCode': '89160',
  'north':   48.526695000000004,
  'east':    9.9759741,
  'south':   48.4322699,
  'west':    9.7979057
}, {'zipCode': '89165', 'north': 48.2546501, 'east': 10.0852742, 'south': 48.1940074, 'west': 10.0118577}, {
  'zipCode': '89168',
  'north':   48.5830886,
  'east':    10.2712624,
  'south':   48.500027,
  'west':    10.1826452
}, {'zipCode': '89171', 'north': 48.3519056, 'east': 10.0383592, 'south': 48.2917265, 'west': 9.9731926}, {
  'zipCode': '89173',
  'north':   48.5858405,
  'east':    9.9691193,
  'south':   48.5066387,
  'west':    9.8327702
}, {'zipCode': '89174', 'north': 48.6072136, 'east': 10.0607806, 'south': 48.5505154, 'west': 9.9603233}, {
  'zipCode': '89176',
  'north':   48.558410200000004,
  'east':    10.241621900000002,
  'south':   48.4883265,
  'west':    10.170607
}, {'zipCode': '89177', 'north': 48.5867626, 'east': 10.110081, 'south': 48.5245564, 'west': 10.036503500000002}, {
  'zipCode': '89179',
  'north':   48.5068398,
  'east':    10.008298,
  'south':   48.4629512,
  'west':    9.9364159
}, {'zipCode': '89180', 'north': 48.4879501, 'east': 9.8288892, 'south': 48.4339113, 'west': 9.717319}, {
  'zipCode': '89182',
  'north':   48.5282371,
  'east':    10.057201700000002,
  'south':   48.4858255,
  'west':    9.9856861
}, {'zipCode': '89183', 'north': 48.5415249, 'east': 10.0442733, 'south': 48.50442420000001, 'west': 9.9703723}, {
  'zipCode': '89185',
  'north':   48.3122654,
  'east':    9.9746196,
  'south':   48.2667607,
  'west':    9.919412
}, {'zipCode': '89186', 'north': 48.3054101, 'east': 10.0674751, 'south': 48.2431967, 'west': 9.999526500000002}, {
  'zipCode': '89188',
  'north':   48.5577631,
  'east':    9.8083737,
  'south':   48.4938253,
  'west':    9.6940802
}, {'zipCode': '89189', 'north': 48.560075, 'east': 10.0697658, 'south': 48.5267167, 'west': 10.0038207}, {
  'zipCode': '89191',
  'north':   48.5662101,
  'east':    9.848837,
  'south':   48.5079021,
  'west':    9.721253
}, {'zipCode': '89192', 'north': 48.5572396, 'east': 10.2180079, 'south': 48.4827151, 'west': 10.153061}, {
  'zipCode': '89194',
  'north':   48.2855221,
  'east':    10.0289404,
  'south':   48.2435337,
  'west':    9.9576392
}, {'zipCode': '89195', 'north': 48.3250826, 'east': 10.0245554, 'south': 48.2787151, 'west': 9.9484582}, {
  'zipCode': '89197',
  'north':   48.5905521,
  'east':    10.0233572,
  'south':   48.5324647,
  'west':    9.9440704
}, {'zipCode': '89198', 'north': 48.5381265, 'east': 9.9794028, 'south': 48.4925047, 'west': 9.9127211}, {
  'zipCode': '89231',
  'north':   48.4107258,
  'east':    10.0463144,
  'south':   48.3449167,
  'west':    9.9674293
}, {'zipCode': '89233', 'north': 48.433496, 'east': 10.1315955, 'south': 48.3310324, 'west': 9.9973898}, {
  'zipCode': '89250',
  'north':   48.3431041,
  'east':    10.124798600000002,
  'south':   48.298165,
  'west':    10.0091735
}, {'zipCode': '89257', 'north': 48.27264100000001, 'east': 10.1536049, 'south': 48.1796194, 'west': 10.0619924}, {
  'zipCode': '89264',
  'north':   48.3449134,
  'east':    10.2413627,
  'south':   48.2590223,
  'west':    10.1138754
}, {'zipCode': '89269', 'north': 48.3147706, 'east': 10.13745, 'south': 48.2621163, 'west': 10.036613}, {
  'zipCode': '89275',
  'north':   48.4775896,
  'east':    10.1424704,
  'south':   48.4275198,
  'west':    10.0278916
}, {'zipCode': '89278', 'north': 48.4466907, 'east': 10.183065, 'south': 48.3972612, 'west': 10.0874985}, {
  'zipCode': '89281',
  'north':   48.1991299,
  'east':    10.1805718,
  'south':   48.1312721,
  'west':    10.0895893
}, {'zipCode': '89284', 'north': 48.4056338, 'east': 10.2326628, 'south': 48.32837, 'west': 10.1041535}, {
  'zipCode': '89287',
  'north':   48.2719454,
  'east':    10.114898200000003,
  'south':   48.2447262,
  'west':    10.0743008
}, {'zipCode': '89290', 'north': 48.2678562, 'east': 10.2548528, 'south': 48.191812, 'west': 10.1420586}, {
  'zipCode': '89291',
  'north':   48.3935302,
  'east':    10.132578,
  'south':   48.3624487,
  'west':    10.084899
}, {'zipCode': '89293', 'north': 48.1475058, 'east': 10.1581852, 'south': 48.1083014, 'west': 10.1080088}, {
  'zipCode': '89294',
  'north':   48.191053,
  'east':    10.2236771,
  'south':   48.1464753,
  'west':    10.1712593
}, {'zipCode': '89296', 'north': 48.164802, 'east': 10.2151099, 'south': 48.1177926, 'west': 10.146948100000001}, {
  'zipCode': '89297',
  'north':   48.3612511,
  'east':    10.315240400000002,
  'south':   48.2018866,
  'west':    10.1856383
}, {'zipCode': '89299', 'north': 48.2167999, 'east': 10.2369731, 'south': 48.177528, 'west': 10.1454114}, {
  'zipCode': '89312',
  'north':   48.5201051,
  'east':    10.3372926,
  'south':   48.4179515,
  'west':    10.2252001
}, {'zipCode': '89331', 'north': 48.4647969, 'east': 10.4317711, 'south': 48.4037518, 'west': 10.3282784}, {
  'zipCode': '89335',
  'north':   48.4020635,
  'east':    10.3490946,
  'south':   48.3428523,
  'west':    10.2061265
}, {'zipCode': '89340', 'north': 48.4885014, 'east': 10.2440441, 'south': 48.4045233, 'west': 10.1340319}, {
  'zipCode': '89343',
  'north':   48.4303117,
  'east':    10.548689,
  'south':   48.3490028,
  'west':    10.3914084
}, {'zipCode': '89344', 'north': 48.5401993, 'east': 10.4904973, 'south': 48.47986000000001, 'west': 10.4221031}, {
  'zipCode': '89346',
  'north':   48.4396388,
  'east':    10.2455156,
  'south':   48.3646353,
  'west':    10.1642928
}, {'zipCode': '89347', 'north': 48.4448377, 'east': 10.2817959, 'south': 48.4084569, 'west': 10.2226112}, {
  'zipCode': '89349',
  'north':   48.3749208,
  'east':    10.5212849,
  'south':   48.323088,
  'west':    10.3856115
}, {'zipCode': '89350', 'north': 48.4847964, 'east': 10.4690978, 'south': 48.4415527, 'west': 10.3868768}, {
  'zipCode': '89352',
  'north':   48.3521873,
  'east':    10.3435525,
  'south':   48.3025867,
  'west':    10.2642695
}, {'zipCode': '89353', 'north': 48.536452, 'east': 10.5190579, 'south': 48.4739297, 'west': 10.4652773}, {
  'zipCode': '89355',
  'north':   48.527390600000004,
  'east':    10.4279308,
  'south':   48.480985,
  'west':    10.3787275
}, {'zipCode': '89356', 'north': 48.4696569, 'east': 10.514142, 'south': 48.4287914, 'west': 10.4138804}, {
  'zipCode': '89358',
  'north':   48.4227661,
  'east':    10.410683,
  'south':   48.3210634,
  'west':    10.321495
}, {'zipCode': '89359', 'north': 48.4244591, 'east': 10.3408845, 'south': 48.384629, 'west': 10.2325477}, {
  'zipCode': '89361',
  'north':   48.4504304,
  'east':    10.5422512,
  'south':   48.4175295,
  'west':    10.478778
}, {'zipCode': '89362', 'north': 48.500546, 'east': 10.420291400000002, 'south': 48.4611738, 'west': 10.3138427}, {
  'zipCode': '89364',
  'north':   48.4732398,
  'east':    10.389575,
  'south':   48.4349113,
  'west':    10.3238934
}, {'zipCode': '89365', 'north': 48.4382766, 'east': 10.4995777, 'south': 48.4169491, 'west': 10.4271756}, {
  'zipCode': '89367',
  'north':   48.3703105,
  'east':    10.3271819,
  'south':   48.3313393,
  'west':    10.2568072
}, {'zipCode': '89368', 'north': 48.48837410000001, 'east': 10.5348448, 'south': 48.4446648, 'west': 10.4586563}, {
  'zipCode': '89407',
  'north':   48.6198944,
  'east':    10.627284000000001,
  'south':   48.5350752,
  'west':    10.4414699
}, {'zipCode': '89415', 'north': 48.6080716, 'east': 10.495166600000001, 'south': 48.5209915, 'west': 10.3558934}, {
  'zipCode': '89420',
  'north':   48.6840731,
  'east':    10.6285849,
  'south':   48.5868415,
  'west':    10.5148338
}, {'zipCode': '89423', 'north': 48.5832231, 'east': 10.4101977, 'south': 48.4794756, 'west': 10.2710973}, {
  'zipCode': '89426',
  'north':   48.6708225,
  'east':    10.4975122,
  'south':   48.5937304,
  'west':    10.3812605
}, {'zipCode': '89428', 'north': 48.686216800000004, 'east': 10.328856100000001, 'south': 48.6336075, 'west': 10.2542442}, {
  'zipCode': '89429',
  'north':   48.6599707,
  'east':    10.3786625,
  'south':   48.59540500000001,
  'west':    10.2945357
}, {'zipCode': '89431', 'north': 48.5507951, 'east': 10.3428364, 'south': 48.5219317, 'west': 10.3016091}, {
  'zipCode': '89434',
  'north':   48.6937833,
  'east':    10.6522457,
  'south':   48.5946133,
  'west':    10.5764557
}, {'zipCode': '89435', 'north': 48.6992495, 'east': 10.5329586, 'south': 48.6085574, 'west': 10.4635717}, {
  'zipCode': '89437',
  'north':   48.6299522,
  'east':    10.3967951,
  'south':   48.580539,
  'west':    10.3233067
}, {'zipCode': '89438', 'north': 48.5420619, 'east': 10.597233, 'south': 48.4711447, 'west': 10.4856542}, {
  'zipCode': '89440',
  'north':   48.7053806,
  'east':    10.5686916,
  'south':   48.6311993,
  'west':    10.5007256
}, {'zipCode': '89441', 'north': 48.6050835, 'east': 10.3633625, 'south': 48.5496914, 'west': 10.2930904}, {
  'zipCode': '89443',
  'north':   48.6849205,
  'east':    10.7080988,
  'south':   48.6098492,
  'west':    10.6176465
}, {'zipCode': '89446', 'north': 48.6995367, 'east': 10.4394519, 'south': 48.6255959, 'west': 10.3610179}, {
  'zipCode': '89447',
  'north':   48.7035845,
  'east':    10.352023100000002,
  'south':   48.6564733,
  'west':    10.2680348
}, {'zipCode': '89518', 'north': 48.6961267, 'east': 10.1582502, 'south': 48.6743985, 'west': 10.1023345}, {
  'zipCode': '89520',
  'north':   48.7832977,
  'east':    10.2792202,
  'south':   48.6784471,
  'west':    10.1079073
}, {'zipCode': '89522', 'north': 48.6897744, 'east': 10.2667961, 'south': 48.643614, 'west': 10.1062642}, {
  'zipCode': '89537',
  'north':   48.6587524,
  'east':    10.3230487,
  'south':   48.5694241,
  'west':    10.1673917
}, {'zipCode': '89542', 'north': 48.663306, 'east': 10.2174143, 'south': 48.5492845, 'west': 10.0776507}, {
  'zipCode': '89547',
  'north':   48.6679228,
  'east':    10.1779838,
  'south':   48.566768800000006,
  'west':    9.9200829
}, {'zipCode': '89551', 'north': 48.7871775, 'east': 10.177156, 'south': 48.7032643, 'west': 10.0247888}, {
  'zipCode': '89555',
  'north':   48.7375353,
  'east':    10.1241261,
  'south':   48.6440404,
  'west':    9.9569343
}, {'zipCode': '89558', 'north': 48.7337401, 'east': 9.9946589, 'south': 48.6402061, 'west': 9.8507371}, {
  'zipCode': '89561',
  'north':   48.7482602,
  'east':    10.4955731,
  'south':   48.6529484,
  'west':    10.2983686
}, {'zipCode': '89564', 'north': 48.7555967, 'east': 10.3362274, 'south': 48.6796956, 'west': 10.2011963}, {
  'zipCode': '89567',
  'north':   48.5883743,
  'east':    10.3160545,
  'south':   48.5158337,
  'west':    10.2301499
}, {'zipCode': '89568', 'north': 48.6140022, 'east': 10.3074792, 'south': 48.5804983, 'west': 10.2178776}, {
  'zipCode': '89584',
  'north':   48.366076,
  'east':    9.863898,
  'south':   48.2031865,
  'west':    9.5058853
}, {'zipCode': '89597', 'north': 48.2555419, 'east': 9.684320800000002, 'south': 48.1915986, 'west': 9.5972839}, {
  'zipCode': '89601',
  'north':   48.4276423,
  'east':    9.7772174,
  'south':   48.3457273,
  'west':    9.5803837
}, {'zipCode': '89604', 'north': 48.3662549, 'east': 9.8208663, 'south': 48.2986154, 'west': 9.6185089}, {
  'zipCode': '89605',
  'north':   48.3476035,
  'east':    9.800906900000001,
  'south':   48.3192052,
  'west':    9.7424961
}, {'zipCode': '89607', 'north': 48.2243256, 'east': 9.6755472, 'south': 48.1839007, 'west': 9.6165712}, {
  'zipCode': '89608',
  'north':   48.2811553,
  'east':    9.8053245,
  'south':   48.2451403,
  'west':    9.7583213
}, {'zipCode': '89610', 'north': 48.3241061, 'east': 9.8606111, 'south': 48.290673, 'west': 9.8046376}, {
  'zipCode': '89611',
  'north':   48.253185,
  'east':    9.6291115,
  'south':   48.1902191,
  'west':    9.5218946
}, {'zipCode': '89613', 'north': 48.2039661, 'east': 9.748624400000002, 'south': 48.1570337, 'west': 9.6416794}, {
  'zipCode': '89614',
  'north':   48.3091035,
  'east':    9.8385881,
  'south':   48.2722805,
  'west':    9.7788867
}, {'zipCode': '89616', 'north': 48.2556409, 'east': 9.7161377, 'south': 48.2169969, 'west': 9.6605184}, {
  'zipCode': '89617',
  'north':   48.2586311,
  'east':    9.627684,
  'south':   48.2231746,
  'west':    9.594303800000002
}, {'zipCode': '89619', 'north': 48.220567800000005, 'east': 9.7428319, 'south': 48.19226, 'west': 9.67076}, {
  'zipCode': '90402',
  'north':   49.4572136,
  'east':    11.1120717,
  'south':   49.4458222,
  'west':    11.0657734
}, {'zipCode': '90403', 'north': 49.4596893, 'east': 11.0893293, 'south': 49.4498662, 'west': 11.0691184}, {
  'zipCode': '90408',
  'north':   49.472564,
  'east':    11.0847037,
  'south':   49.4584478,
  'west':    11.0670177
}, {'zipCode': '90409', 'north': 49.4734492, 'east': 11.103427, 'south': 49.4576649, 'west': 11.0799123}, {
  'zipCode': '90411',
  'north':   49.5150391,
  'east':    11.132975,
  'south':   49.47259220000001,
  'west':    11.052294400000001
}, {'zipCode': '90419', 'north': 49.469442, 'east': 11.073176300000002, 'south': 49.454658800000004, 'west': 11.032592400000002}, {
  'zipCode': '90425',
  'north':   49.4868585,
  'east':    11.0958245,
  'south':   49.4642644,
  'west':    11.0363931
}, {'zipCode': '90427', 'north': 49.5407534, 'east': 11.097264300000003, 'south': 49.4652368, 'west': 10.9887326}, {
  'zipCode': '90429',
  'north':   49.4691994,
  'east':    11.0700607,
  'south':   49.4449554,
  'west':    11.0115545
}, {'zipCode': '90431', 'north': 49.4649409, 'east': 11.048957700000003, 'south': 49.4281964, 'west': 10.997852}, {
  'zipCode': '90439',
  'north':   49.4476273,
  'east':    11.062266,
  'south':   49.4322994,
  'west':    11.0339887
}, {'zipCode': '90441', 'north': 49.4413015, 'east': 11.0822353, 'south': 49.41342970000001, 'west': 11.0261946}, {
  'zipCode': '90443',
  'north':   49.4483821,
  'east':    11.0859268,
  'south':   49.4272492,
  'west':    11.0552774
}, {'zipCode': '90449', 'north': 49.449961, 'east': 11.0376552, 'south': 49.4111243, 'west': 10.9912855}, {
  'zipCode': '90451',
  'north':   49.423165,
  'east':    11.0725118,
  'south':   49.3855038,
  'west':    11.0153804
}, {'zipCode': '90453', 'north': 49.3983096, 'east': 11.0657506, 'south': 49.3511576, 'west': 10.9924618}, {
  'zipCode': '90455',
  'north':   49.404577,
  'east':    11.1228189,
  'south':   49.3314339,
  'west':    11.0383511
}, {'zipCode': '90459', 'north': 49.4458222, 'east': 11.090222, 'south': 49.4293386, 'west': 11.0704461}, {
  'zipCode': '90461',
  'north':   49.4454226,
  'east':    11.1075329,
  'south':   49.4112451,
  'west':    11.073239
}, {'zipCode': '90469', 'north': 49.4204704, 'east': 11.1299442, 'south': 49.3898086, 'west': 11.0656205}, {
  'zipCode': '90471',
  'north':   49.4367711,
  'east':    11.1599893,
  'south':   49.3984341,
  'west':    11.0990173
}, {'zipCode': '90473', 'north': 49.410919, 'east': 11.1559213, 'south': 49.3930013, 'west': 11.1235254}, {
  'zipCode': '90475',
  'north':   49.4679528,
  'east':    11.3024175,
  'south':   49.3844928,
  'west':    11.1279519
}, {'zipCode': '90478', 'north': 49.4478646, 'east': 11.1198229, 'south': 49.4256467, 'west': 11.0858908}, {
  'zipCode': '90480',
  'north':   49.4616703,
  'east':    11.1552262,
  'south':   49.4240525,
  'west':    11.1047332
}, {'zipCode': '90482', 'north': 49.4745573, 'east': 11.1884572, 'south': 49.4478605, 'west': 11.1082763}, {
  'zipCode': '90489',
  'north':   49.4670715,
  'east':    11.1159929,
  'south':   49.4509844,
  'west':    11.0864585
}, {'zipCode': '90491', 'north': 49.4890853, 'east': 11.1803643, 'south': 49.45824900000001, 'west': 11.0979977}, {
  'zipCode': '90513',
  'north':   49.4637363,
  'east':    10.9859545,
  'south':   49.4033951,
  'west':    10.8690507
}, {'zipCode': '90518', 'north': 49.4329653, 'east': 11.4612162, 'south': 49.3532414, 'west': 11.3109818}, {
  'zipCode': '90522',
  'north':   49.4454542,
  'east':    10.9987491,
  'south':   49.4017249,
  'west':    10.9384173
}, {'zipCode': '90530', 'north': 49.3925594, 'east': 11.2518385, 'south': 49.2977705, 'west': 11.0780329}, {
  'zipCode': '90537',
  'north':   49.4083115,
  'east':    11.2887903,
  'south':   49.3537387,
  'west':    11.1527891
}, {'zipCode': '90542', 'north': 49.6094602, 'east': 11.2885747, 'south': 49.5598551, 'west': 11.1438229}, {
  'zipCode': '90547',
  'north':   49.4263139,
  'east':    11.0259601,
  'south':   49.3801696,
  'west':    10.922722700000001
}, {'zipCode': '90552', 'north': 49.4963207, 'east': 11.3157889, 'south': 49.4460902, 'west': 11.2013564}, {
  'zipCode': '90556',
  'north':   49.5005191,
  'east':    10.9160342,
  'south':   49.4171929,
  'west':    10.778822
}, {'zipCode': '90559', 'north': 49.3715615, 'east': 11.3901547, 'south': 49.3021221, 'west': 11.2652292}, {
  'zipCode': '90562',
  'north':   49.5908901,
  'east':    11.197743,
  'south':   49.4731039,
  'west':    11.0299106
}, {'zipCode': '90571', 'north': 49.5174507, 'east': 11.231891, 'south': 49.4649569, 'west': 11.1683133}, {
  'zipCode': '90574',
  'north':   49.4215334,
  'east':    10.9553,
  'south':   49.3545521,
  'west':    10.8244609
}, {'zipCode': '90579', 'north': 49.5314064, 'east': 10.8472597, 'south': 49.4463062, 'west': 10.7178813}, {
  'zipCode': '90584',
  'north':   49.2819582,
  'east':    11.3222868,
  'south':   49.2025677,
  'west':    11.1619487
}, {'zipCode': '90587', 'north': 49.5490344, 'east': 10.9347524, 'south': 49.4930829, 'west': 10.831164}, {
  'zipCode': '90592',
  'north':   49.3771266,
  'east':    11.3227802,
  'south':   49.3220002,
  'west':    11.2067256
}, {'zipCode': '90596', 'north': 49.3350361, 'east': 11.1900642, 'south': 49.2768935, 'west': 11.0869921}, {
  'zipCode': '90599',
  'north':   49.4372308,
  'east':    10.7762328,
  'south':   49.3593911,
  'west':    10.593057200000002
}, {'zipCode': '90602', 'north': 49.3252037, 'east': 11.3417949, 'south': 49.2437269, 'west': 11.1859187}, {
  'zipCode': '90607',
  'north':   49.5247159,
  'east':    11.2672612,
  'south':   49.4851334,
  'west':    11.2191689
}, {'zipCode': '90610', 'north': 49.4375694, 'east': 11.3549866, 'south': 49.3733682, 'west': 11.255972400000001}, {
  'zipCode': '90613',
  'north':   49.447833300000006,
  'east':    10.8455653,
  'south':   49.3725037,
  'west':    10.7371655
}, {'zipCode': '90614', 'north': 49.4352275, 'east': 10.8815716, 'south': 49.4112715, 'west': 10.8227811}, {
  'zipCode': '90616',
  'north':   49.4782491,
  'east':    10.7006383,
  'south':   49.4211291,
  'west':    10.5960588
}, {'zipCode': '90617', 'north': 49.536574, 'east': 10.8429074, 'south': 49.5128152, 'west': 10.809569700000003}, {
  'zipCode': '90619',
  'north':   49.4909376,
  'east':    10.6228514,
  'south':   49.4381069,
  'west':    10.529506
}, {'zipCode': '90762', 'north': 49.486848800000004, 'east': 11.0166159, 'south': 49.4648174, 'west': 10.9793048}, {
  'zipCode': '90763',
  'north':   49.4713187,
  'east':    11.0115545,
  'south':   49.4361798,
  'west':    10.9797111
}, {'zipCode': '90765', 'north': 49.5412538, 'east': 11.0284801, 'south': 49.4691994, 'west': 10.9670405}, {
  'zipCode': '90766',
  'north':   49.5005422,
  'east':    10.9866306,
  'south':   49.4713187,
  'west':    10.9428084
}, {'zipCode': '90768', 'north': 49.5357872, 'east': 10.982649800000003, 'south': 49.4514225, 'west': 10.8931571}, {
  'zipCode': '91052',
  'north':   49.5976136,
  'east':    11.0454196,
  'south':   49.5751016,
  'west':    10.9824379
}, {'zipCode': '91054', 'north': 49.6197472, 'east': 11.0675804, 'south': 49.58625370000001, 'west': 10.993285}, {
  'zipCode': '91056',
  'north':   49.6455844,
  'east':    10.9989981,
  'south':   49.5327697,
  'west':    10.9153629
}, {'zipCode': '91058', 'north': 49.5856132, 'east': 11.038938800000002, 'south': 49.5347825, 'west': 10.9734478}, {
  'zipCode': '91074',
  'north':   49.6128793,
  'east':    10.9376782,
  'south':   49.5347847,
  'west':    10.81027
}, {'zipCode': '91077', 'north': 49.6586231, 'east': 11.2085028, 'south': 49.5873517, 'west': 11.0757687}, {
  'zipCode': '91080',
  'north':   49.6378788,
  'east':    11.1180885,
  'south':   49.5845646,
  'west':    11.018886
}, {'zipCode': '91083', 'north': 49.6819914, 'east': 11.0633926, 'south': 49.6339558, 'west': 10.9868241}, {
  'zipCode': '91085',
  'north':   49.6642931,
  'east':    10.8753979,
  'south':   49.5901744,
  'west':    10.7537496
}, {'zipCode': '91086', 'north': 49.6123289, 'east': 10.8650916, 'south': 49.5515669, 'west': 10.7796258}, {
  'zipCode': '91088',
  'north':   49.6397123,
  'east':    11.0332838,
  'south':   49.61170520000001,
  'west':    10.9958222
}, {'zipCode': '91090', 'north': 49.6817979, 'east': 11.1396875, 'south': 49.6458164, 'west': 11.0721067}, {
  'zipCode': '91091',
  'north':   49.6417977,
  'east':    10.8946091,
  'south':   49.6078964,
  'west':    10.8522238
}, {'zipCode': '91093', 'north': 49.6729721, 'east': 10.9333064, 'south': 49.600788400000006, 'west': 10.8442128}, {
  'zipCode': '91094',
  'north':   49.6557192,
  'east':    11.0947718,
  'south':   49.6236949,
  'west':    11.0213775
}, {'zipCode': '91096', 'north': 49.6852097, 'east': 11.0179265, 'south': 49.6138388, 'west': 10.9265754}, {
  'zipCode': '91097',
  'north':   49.6056289,
  'east':    10.7906295,
  'south':   49.5807826,
  'west':    10.754288500000001
}, {'zipCode': '91099', 'north': 49.6801114, 'east': 11.0883087, 'south': 49.6525698, 'west': 11.0462548}, {
  'zipCode': '91126',
  'north':   49.3760278,
  'east':    11.0962193,
  'south':   49.2630607,
  'west':    10.8990518
}, {'zipCode': '91154', 'north': 49.2978708, 'east': 11.2164658, 'south': 49.1758609, 'west': 11.031486}, {
  'zipCode': '91161',
  'north':   49.2176441,
  'east':    11.327727400000002,
  'south':   49.126396,
  'west':    11.1150281
}, {'zipCode': '91166', 'north': 49.2391826, 'east': 11.099932, 'south': 49.15326790000001, 'west': 10.9579928}, {
  'zipCode': '91171',
  'north':   49.1352588,
  'east':    11.4266094,
  'south':   48.9980103,
  'west':    11.2488922
}, {'zipCode': '91174', 'north': 49.2095965, 'east': 10.9875905, 'south': 49.1313238, 'west': 10.836963}, {
  'zipCode': '91177',
  'north':   49.1569054,
  'east':    11.2945625,
  'south':   49.0459299,
  'west':    11.1599527
}, {'zipCode': '91180', 'north': 49.1791723, 'east': 11.1720047, 'south': 49.0805336, 'west': 11.0518687}, {
  'zipCode': '91183',
  'north':   49.2745863,
  'east':    11.0078148,
  'south':   49.1925376,
  'west':    10.8549387
}, {'zipCode': '91186', 'north': 49.2987834, 'east': 11.0801966, 'south': 49.2303873, 'west': 10.9685344}, {
  'zipCode': '91187',
  'north':   49.1749035,
  'east':    11.073273300000002,
  'south':   49.1245326,
  'west':    10.962016800000002
}, {'zipCode': '91189', 'north': 49.384517, 'east': 10.997813600000002, 'south': 49.2935289, 'west': 10.8615658}, {
  'zipCode': '91207',
  'north':   49.5752174,
  'east':    11.351191,
  'south':   49.465682300000005,
  'west':    11.1802922
}, {'zipCode': '91217', 'north': 49.5334432, 'east': 11.4618331, 'south': 49.4710394, 'west': 11.3847795}, {
  'zipCode': '91220',
  'north':   49.6336577,
  'east':    11.4264402,
  'south':   49.5445286,
  'west':    11.2631266
}, {'zipCode': '91224', 'north': 49.5532559, 'east': 11.5962137, 'south': 49.450502, 'west': 11.4536803}, {
  'zipCode': '91227',
  'north':   49.4833656,
  'east':    11.3868614,
  'south':   49.407868,
  'west':    11.2700532
}, {'zipCode': '91230', 'north': 49.509548800000005, 'east': 11.5573752, 'south': 49.42188, 'west': 11.4355911}, {
  'zipCode': '91233',
  'north':   49.5543961,
  'east':    11.3827667,
  'south':   49.5133344,
  'west':    11.3027267
}, {'zipCode': '91235', 'north': 49.6447953, 'east': 11.576256, 'south': 49.5596845, 'west': 11.428501}, {
  'zipCode': '91236',
  'north':   49.4576865,
  'east':    11.5778551,
  'south':   49.4089526,
  'west':    11.4856831
}, {'zipCode': '91238', 'north': 49.4927106, 'east': 11.4781917, 'south': 49.4190413, 'west': 11.3580866}, {
  'zipCode': '91239',
  'north':   49.5106002,
  'east':    11.4205997,
  'south':   49.48257550000001,
  'west':    11.3695503
}, {'zipCode': '91241', 'north': 49.6181726, 'east': 11.4660792, 'south': 49.5249156, 'west': 11.374568}, {
  'zipCode': '91242',
  'north':   49.5216471,
  'east':    11.3725541,
  'south':   49.4789716,
  'west':    11.3160892
}, {'zipCode': '91244', 'north': 49.5397713, 'east': 11.3918426, 'south': 49.497897, 'west': 11.3541043}, {
  'zipCode': '91245',
  'north':   49.6694644,
  'east':    11.3944844,
  'south':   49.5755701,
  'west':    11.291621
}, {'zipCode': '91247', 'north': 49.589878, 'east': 11.5307208, 'south': 49.5227258, 'west': 11.4464311}, {
  'zipCode': '91249',
  'north':   49.5169535,
  'east':    11.626372200000002,
  'south':   49.4824277,
  'west':    11.5322716
}, {'zipCode': '91257', 'north': 49.8380205, 'east': 11.633758200000003, 'south': 49.6396125, 'west': 11.4239119}, {
  'zipCode': '91275',
  'north':   49.7608799,
  'east':    11.6697065,
  'south':   49.6227606,
  'west':    11.5436728
}, {'zipCode': '91278', 'north': 49.8362107, 'east': 11.4911626, 'south': 49.7004253, 'west': 11.347356}, {
  'zipCode': '91281',
  'north':   49.8110485,
  'east':    11.7881154,
  'south':   49.7111821,
  'west':    11.628678600000002
}, {'zipCode': '91282', 'north': 49.7238285, 'east': 11.4690642, 'south': 49.613851100000005, 'west': 11.3467188}, {
  'zipCode': '91284',
  'north':   49.7160211,
  'east':    11.6016272,
  'south':   49.6051395,
  'west':    11.489725
}, {'zipCode': '91286', 'north': 49.726449, 'east': 11.3832467, 'south': 49.6782352, 'west': 11.2773786}, {
  'zipCode': '91287',
  'north':   49.6818128,
  'east':    11.5090549,
  'south':   49.6354677,
  'west':    11.435983900000002
}, {'zipCode': '91289', 'north': 49.8343595, 'east': 11.6405391, 'south': 49.7735367, 'west': 11.5487375}, {
  'zipCode': '91301',
  'north':   49.7508956,
  'east':    11.1266282,
  'south':   49.6738471,
  'west':    10.9995766
}, {'zipCode': '91315', 'north': 49.7856089, 'east': 10.9351401, 'south': 49.6463689, 'west': 10.743677}, {
  'zipCode': '91320',
  'north':   49.81953240000001,
  'east':    11.3025609,
  'south':   49.7468898,
  'west':    11.1142304
}, {'zipCode': '91322', 'north': 49.7020486, 'east': 11.3082628, 'south': 49.6255077, 'west': 11.1694653}, {
  'zipCode': '91325',
  'north':   49.7444237,
  'east':    10.9624247,
  'south':   49.6671534,
  'west':    10.8631542
}, {'zipCode': '91327', 'north': 49.8176522, 'east': 11.3883854, 'south': 49.7188116, 'west': 11.232857600000003}, {
  'zipCode': '91330',
  'north':   49.8415931,
  'east':    11.159709,
  'south':   49.7417249,
  'west':    11.0087816
}, {'zipCode': '91332', 'north': 49.9252685, 'east': 11.2439981, 'south': 49.8322767, 'west': 11.0635077}, {
  'zipCode': '91334',
  'north':   49.7008862,
  'east':    10.9766497,
  'south':   49.6748482,
  'west':    10.9174115
}, {'zipCode': '91336', 'north': 49.722421700000005, 'east': 11.0183373, 'south': 49.6805945, 'west': 10.9220019}, {
  'zipCode': '91338',
  'north':   49.6627931,
  'east':    11.2877481,
  'south':   49.5903272,
  'west':    11.1477854
}, {'zipCode': '91341', 'north': 49.6857171, 'east': 10.9545233, 'south': 49.6508026, 'west': 10.8883444}, {
  'zipCode': '91344',
  'north':   49.8828867,
  'east':    11.4096347,
  'south':   49.799690000000005,
  'west':    11.2567048
}, {'zipCode': '91346', 'north': 49.8746745, 'east': 11.3107957, 'south': 49.7778282, 'west': 11.1980381}, {
  'zipCode': '91347',
  'north':   49.9308045,
  'east':    11.3135518,
  'south':   49.8659464,
  'west':    11.1841671
}, {'zipCode': '91349', 'north': 49.7399679, 'east': 11.3133639, 'south': 49.6856167, 'west': 11.2046125}, {
  'zipCode': '91350',
  'north':   49.7065319,
  'east':    10.8768813,
  'south':   49.66197,
  'west':    10.8245361
}, {'zipCode': '91352', 'north': 49.7860203, 'east': 11.0436178, 'south': 49.716656, 'west': 10.9130074}, {
  'zipCode': '91353',
  'north':   49.7246292,
  'east':    11.0537746,
  'south':   49.670885,
  'west':    10.9733977
}, {'zipCode': '91355', 'north': 49.690112, 'east': 11.3694376, 'south': 49.6319245, 'west': 11.2717838}, {
  'zipCode': '91356',
  'north':   49.7498177,
  'east':    11.183672,
  'south':   49.7169962,
  'west':    11.124829900000002
}, {'zipCode': '91358', 'north': 49.6913025, 'east': 11.1973958, 'south': 49.6571982, 'west': 11.1343643}, {
  'zipCode': '91359',
  'north':   49.7262199,
  'east':    11.2235782,
  'south':   49.6694085,
  'west':    11.1453643
}, {'zipCode': '91361', 'north': 49.7159163, 'east': 11.147368900000002, 'south': 49.6716146, 'west': 11.079350000000002}, {
  'zipCode': '91362',
  'north':   49.769604300000005,
  'east':    11.2502587,
  'south':   49.7137701,
  'west':    11.1526194
}, {'zipCode': '91364', 'north': 49.84517960000001, 'east': 11.205675, 'south': 49.8081733, 'west': 11.1354877}, {
  'zipCode': '91365',
  'north':   49.7684964,
  'east':    11.1599026,
  'south':   49.7293558,
  'west':    11.1049555
}, {'zipCode': '91367', 'north': 49.6373071, 'east': 11.3003128, 'south': 49.6160515, 'west': 11.2362144}, {
  'zipCode': '91369',
  'north':   49.7243428,
  'east':    11.1616192,
  'south':   49.6945911,
  'west':    11.1186538
}, {'zipCode': '91413', 'north': 49.605004, 'east': 10.6828826, 'south': 49.52865100000001, 'west': 10.4605905}, {
  'zipCode': '91438',
  'north':   49.5868686,
  'east':    10.5348339,
  'south':   49.4615139,
  'west':    10.3473837
}, {'zipCode': '91443', 'north': 49.7143469, 'east': 10.5343845, 'south': 49.6260639, 'west': 10.4021236}, {
  'zipCode': '91448',
  'north':   49.6031645,
  'east':    10.8215689,
  'south':   49.5020341,
  'west':    10.6456061
}, {'zipCode': '91452', 'north': 49.5048543, 'east': 10.7607067, 'south': 49.4329662, 'west': 10.6803298}, {
  'zipCode': '91456',
  'north':   49.6236496,
  'east':    10.6991936,
  'south':   49.5742631,
  'west':    10.5451977
}, {'zipCode': '91459', 'north': 49.5425973, 'east': 10.7135805, 'south': 49.4680902, 'west': 10.5039034}, {
  'zipCode': '91460',
  'north':   49.656913,
  'east':    10.5842702,
  'south':   49.5974409,
  'west':    10.4870015
}, {'zipCode': '91462', 'north': 49.6627278, 'east': 10.7865219, 'south': 49.6266703, 'west': 10.650938}, {
  'zipCode': '91463',
  'north':   49.57115580000001,
  'east':    10.6113032,
  'south':   49.5172973,
  'west':    10.4729378
}, {'zipCode': '91465', 'north': 49.5445072, 'east': 10.3652914, 'south': 49.4877061, 'west': 10.2452928}, {
  'zipCode': '91466',
  'north':   49.6404896,
  'east':    10.7746912,
  'south':   49.587642,
  'west':    10.6572874
}, {'zipCode': '91468', 'north': 49.6552529, 'east': 10.6757972, 'south': 49.5941271, 'west': 10.589682}, {
  'zipCode': '91469',
  'north':   49.555258,
  'east':    10.7895452,
  'south':   49.5095446,
  'west':    10.7259151
}, {'zipCode': '91471', 'north': 49.4941975, 'east': 10.4542432, 'south': 49.4286179, 'west': 10.3564451}, {
  'zipCode': '91472',
  'north':   49.5754536,
  'east':    10.5835025,
  'south':   49.4927414,
  'west':    10.43721
}, {'zipCode': '91474', 'north': 49.6333981, 'east': 10.5406361, 'south': 49.5950044, 'west': 10.4827416}, {
  'zipCode': '91475',
  'north':   49.7353576,
  'east':    10.7907373,
  'south':   49.6747667,
  'west':    10.6967363
}, {'zipCode': '91477', 'north': 49.7108928, 'east': 10.461080300000003, 'south': 49.6317232, 'west': 10.3328099}, {
  'zipCode': '91478',
  'north':   49.6102149,
  'east':    10.3831606,
  'south':   49.5369176,
  'west':    10.2576358
}, {'zipCode': '91480', 'north': 49.7248221, 'east': 10.5987166, 'south': 49.6544004, 'west': 10.504512800000002}, {
  'zipCode': '91481',
  'north':   49.6914746,
  'east':    10.6374608,
  'south':   49.6148808,
  'west':    10.5500897
}, {'zipCode': '91483', 'north': 49.7503437, 'east': 10.510330000000002, 'south': 49.6793708, 'west': 10.3679674}, {
  'zipCode': '91484',
  'north':   49.634303,
  'east':    10.5033034,
  'south':   49.5655786,
  'west':    10.2881109
}, {'zipCode': '91486', 'north': 49.6918124, 'east': 10.7883801, 'south': 49.6422386, 'west': 10.6207342}, {
  'zipCode': '91487',
  'north':   49.7225314,
  'east':    10.7268076,
  'south':   49.6776631,
  'west':    10.5919089
}, {'zipCode': '91489', 'north': 49.591525, 'east': 10.7578478, 'south': 49.556756, 'west': 10.7140324}, {
  'zipCode': '91522',
  'north':   49.3437688,
  'east':    10.6563094,
  'south':   49.2339602,
  'west':    10.4543048
}, {'zipCode': '91541', 'north': 49.4070171, 'east': 10.2221838, 'south': 49.3246117, 'west': 10.1028342}, {
  'zipCode': '91550',
  'north':   49.1299949,
  'east':    10.4316132,
  'south':   49.0286323,
  'west':    10.20405
}, {'zipCode': '91555', 'north': 49.2382742, 'east': 10.4416168, 'south': 49.1070606, 'west': 10.2294941}, {
  'zipCode': '91560',
  'north':   49.3893063,
  'east':    10.8730041,
  'south':   49.3033611,
  'west':    10.7139456
}, {'zipCode': '91564', 'north': 49.3207685, 'east': 10.8692918, 'south': 49.2514167, 'west': 10.7575743}, {
  'zipCode': '91567',
  'north':   49.2841992,
  'east':    10.5828637,
  'south':   49.1769613,
  'west':    10.3809464
}, {'zipCode': '91572', 'north': 49.216997, 'east': 10.6303912, 'south': 49.1241007, 'west': 10.4857179}, {
  'zipCode': '91575',
  'north':   49.3169439,
  'east':    10.913425,
  'south':   49.198377,
  'west':    10.741670200000002
}, {'zipCode': '91578', 'north': 49.349028, 'east': 10.4997543, 'south': 49.2424591, 'west': 10.3093975}, {
  'zipCode': '91580',
  'north':   49.3672219,
  'east':    10.788121,
  'south':   49.283962,
  'west':    10.6487281
}, {'zipCode': '91583', 'north': 49.3328312, 'east': 10.3507429, 'south': 49.2542969, 'west': 10.1685622}, {
  'zipCode': '91586',
  'north':   49.301847,
  'east':    10.7743963,
  'south':   49.2301593,
  'west':    10.6361368
}, {'zipCode': '91587', 'north': 49.4799815, 'east': 10.1926512, 'south': 49.4238571, 'west': 10.0948706}, {
  'zipCode': '91589',
  'north':   49.2785642,
  'east':    10.4681932,
  'south':   49.2055971,
  'west':    10.3152496
}, {'zipCode': '91590', 'north': 49.3684797, 'east': 10.7169917, 'south': 49.3318964, 'west': 10.6680694}, {
  'zipCode': '91592',
  'north':   49.345348400000006,
  'east':    10.3665945,
  'south':   49.2953439,
  'west':    10.2540714
}, {'zipCode': '91593', 'north': 49.5089666, 'east': 10.3794088, 'south': 49.4125572, 'west': 10.2687481}, {
  'zipCode': '91595',
  'north':   49.2458404,
  'east':    10.610425,
  'south':   49.1972715,
  'west':    10.5637449
}, {'zipCode': '91596', 'north': 49.1619233, 'east': 10.5241762, 'south': 49.11954080000001, 'west': 10.4284253}, {
  'zipCode': '91598',
  'north':   49.414936,
  'east':    10.4689128,
  'south':   49.3222089,
  'west':    10.3501431
}, {'zipCode': '91599', 'north': 49.1762839, 'east': 10.4607534, 'south': 49.1063986, 'west': 10.381409}, {
  'zipCode': '91601',
  'north':   49.2832162,
  'east':    10.3341322,
  'south':   49.2303118,
  'west':    10.2642993
}, {'zipCode': '91602', 'north': 49.131966, 'east': 10.4333389, 'south': 49.0778948, 'west': 10.3335678}, {
  'zipCode': '91604',
  'north':   49.4508389,
  'east':    10.572911800000002,
  'south':   49.3655052,
  'west':    10.4681174
}, {'zipCode': '91605', 'north': 49.497503800000004, 'east': 10.3053593, 'south': 49.4310726, 'west': 10.2316703}, {
  'zipCode': '91607',
  'north':   49.3654197,
  'east':    10.2751125,
  'south':   49.3179751,
  'west':    10.1709997
}, {'zipCode': '91608', 'north': 49.3944105, 'east': 10.3734152, 'south': 49.3303615, 'west': 10.2483137}, {
  'zipCode': '91610',
  'north':   49.3561939,
  'east':    10.1903342,
  'south':   49.278259,
  'west':    10.1314629
}, {'zipCode': '91611', 'north': 49.3931511, 'east': 10.5800364, 'south': 49.3207975, 'west': 10.412965}, {
  'zipCode': '91613',
  'north':   49.4805587,
  'east':    10.4051464,
  'south':   49.4103331,
  'west':    10.3335332
}, {'zipCode': '91614', 'north': 49.0468791, 'east': 10.3897361, 'south': 49.0031955, 'west': 10.3165902}, {
  'zipCode': '91616',
  'north':   49.4097024,
  'east':    10.2624662,
  'south':   49.3503266,
  'west':    10.2034857
}, {'zipCode': '91617', 'north': 49.432708, 'east': 10.4914091, 'south': 49.384993800000004, 'west': 10.3838848}, {
  'zipCode': '91619',
  'north':   49.4865091,
  'east':    10.5584264,
  'south':   49.4119016,
  'west':    10.4188192
}, {'zipCode': '91620', 'north': 49.4920477, 'east': 10.269081, 'south': 49.442616, 'west': 10.1683122}, {
  'zipCode': '91622',
  'north':   49.4485283,
  'east':    10.618928,
  'south':   49.3825981,
  'west':    10.5357433
}, {'zipCode': '91623', 'north': 49.3147263, 'east': 10.698339, 'south': 49.2557195, 'west': 10.6117256}, {
  'zipCode': '91625',
  'north':   49.2509085,
  'east':    10.2529492,
  'south':   49.1555163,
  'west':    10.1197915
}, {'zipCode': '91626', 'north': 49.1336757, 'east': 10.3411419, 'south': 49.09448030000001, 'west': 10.2578161}, {
  'zipCode': '91628',
  'north':   49.4607918,
  'east':    10.2769769,
  'south':   49.400157,
  'west':    10.1394832
}, {'zipCode': '91629', 'north': 49.3991703, 'east': 10.6788987, 'south': 49.3220242, 'west': 10.5650929}, {
  'zipCode': '91631',
  'north':   49.28395760000001,
  'east':    10.2171057,
  'south':   49.2342059,
  'west':    10.1092948
}, {'zipCode': '91632', 'north': 49.1978178, 'east': 10.5157554, 'south': 49.1434251, 'west': 10.4306224}, {
  'zipCode': '91634',
  'north':   49.0585574,
  'east':    10.4379558,
  'south':   48.977069,
  'west':    10.348996
}, {'zipCode': '91635', 'north': 49.4355433, 'east': 10.3658631, 'south': 49.3521887, 'west': 10.229502700000001}, {
  'zipCode': '91637',
  'north':   49.2740414,
  'east':    10.2760724,
  'south':   49.2075226,
  'west':    10.175939
}, {'zipCode': '91639', 'north': 49.2552184, 'east': 10.7853095, 'south': 49.185697, 'west': 10.6865281}, {
  'zipCode': '91710',
  'north':   49.1652374,
  'east':    10.8192726,
  'south':   49.067055700000004,
  'west':    10.6456157
}, {'zipCode': '91717', 'north': 49.0839496, 'east': 10.6879463, 'south': 49.0057122, 'west': 10.4861876}, {
  'zipCode': '91719',
  'north':   49.0433417,
  'east':    10.8162431,
  'south':   48.9496724,
  'west':    10.6839756
}, {'zipCode': '91720', 'north': 49.1888873, 'east': 10.9162852, 'south': 49.1275613, 'west': 10.8129864}, {
  'zipCode': '91722',
  'north':   49.1622411,
  'east':    10.6938877,
  'south':   49.1012457,
  'west':    10.5781815
}, {'zipCode': '91723', 'north': 49.0875364, 'east': 10.8372013, 'south': 49.033839, 'west': 10.7291489}, {
  'zipCode': '91725',
  'north':   49.1326533,
  'east':    10.6089766,
  'south':   49.0554191,
  'west':    10.4827301
}, {'zipCode': '91726', 'north': 49.0708155, 'east': 10.5409919, 'south': 49.0251296, 'west': 10.484443700000002}, {
  'zipCode': '91728',
  'north':   49.07361,
  'east':    10.7377959,
  'south':   49.0343945,
  'west':    10.680773
}, {'zipCode': '91729', 'north': 49.2047293, 'east': 10.8622084, 'south': 49.1185336, 'west': 10.7283696}, {
  'zipCode': '91731',
  'north':   49.1274374,
  'east':    10.494037,
  'south':   49.0732725,
  'west':    10.4173711
}, {'zipCode': '91732', 'north': 49.2423805, 'east': 10.7311027, 'south': 49.1618241, 'west': 10.6460891}, {
  'zipCode': '91734',
  'north':   49.2323232,
  'east':    10.8384592,
  'south':   49.1947513,
  'west':    10.762541400000002
}, {'zipCode': '91735', 'north': 49.1871597, 'east': 10.7404299, 'south': 49.1410154, 'west': 10.6929404}, {
  'zipCode': '91737',
  'north':   49.196508900000005,
  'east':    10.6987736,
  'south':   49.151579,
  'west':    10.6092136
}, {'zipCode': '91738', 'north': 49.1383591, 'east': 10.9224052, 'south': 49.0897893, 'west': 10.810945300000002}, {
  'zipCode': '91740',
  'north':   49.0680816,
  'east':    10.5845861,
  'south':   49.034711,
  'west':    10.5297001
}, {'zipCode': '91741', 'north': 49.1105146, 'east': 10.8871704, 'south': 49.0459326, 'west': 10.7998279}, {
  'zipCode': '91743',
  'north':   49.1205091,
  'east':    10.6614352,
  'south':   49.067925,
  'west':    10.5721718
}, {'zipCode': '91744', 'north': 49.0584985, 'east': 10.4934201, 'south': 49.001638500000006, 'west': 10.411128200000002}, {
  'zipCode': '91746',
  'north':   49.2383817,
  'east':    10.6688915,
  'south':   49.18599960000001,
  'west':    10.5956563
}, {'zipCode': '91747', 'north': 49.0471637, 'east': 10.7153422, 'south': 48.9591345, 'west': 10.6288456}, {
  'zipCode': '91749',
  'north':   49.0992476,
  'east':    10.5094866,
  'south':   49.04157,
  'west':    10.3940392
}, {'zipCode': '91757', 'north': 49.0111457, 'east': 10.9847767, 'south': 48.8935162, 'west': 10.7507594}, {
  'zipCode': '91781',
  'north':   49.0644829,
  'east':    11.087902,
  'south':   48.9568321,
  'west':    10.8878275
}, {'zipCode': '91785', 'north': 49.157497400000004, 'east': 11.0933049, 'south': 49.08332360000001, 'west': 10.8758693}, {
  'zipCode': '91788',
  'north':   48.9825332,
  'east':    11.0830751,
  'south':   48.8965272,
  'west':    10.922414
}, {'zipCode': '91790', 'north': 49.0894895, 'east': 11.2013384, 'south': 48.9616603, 'west': 11.0507234}, {
  'zipCode': '91792',
  'north':   49.09809560000001,
  'east':    10.9933367,
  'south':   49.0445613,
  'west':    10.8682816
}, {'zipCode': '91793', 'north': 49.0690468, 'east': 10.9000128, 'south': 49.0009389, 'west': 10.8318625}, {
  'zipCode': '91795',
  'north':   48.9040233,
  'east':    11.147921200000003,
  'south':   48.8342436,
  'west':    11.0396929
}, {'zipCode': '91796', 'north': 49.102625700000004, 'east': 11.114757200000001, 'south': 49.041755900000005, 'west': 11.0290136}, {
  'zipCode': '91798',
  'north':   49.0987244,
  'east':    11.0482255,
  'south':   49.0408713,
  'west':    10.9752563
}, {'zipCode': '91799', 'north': 48.9255959, 'east': 10.9742125, 'south': 48.858336, 'west': 10.8531403}, {
  'zipCode': '91801',
  'north':   49.0309675,
  'east':    10.8690241,
  'south':   48.9830633,
  'west':    10.8014127
}, {'zipCode': '91802', 'north': 49.045085, 'east': 10.8529907, 'south': 49.0000553, 'west': 10.7607854}, {
  'zipCode': '91804',
  'north':   48.8872653,
  'east':    11.054546600000002,
  'south':   48.8208581,
  'west':    10.9371879
}, {'zipCode': '91805', 'north': 48.9630966, 'east': 10.8000104, 'south': 48.8993878, 'west': 10.6697699}, {
  'zipCode': '91807',
  'north':   48.9155989,
  'east':    11.0448459,
  'south':   48.8787076,
  'west':    10.9666649
}, {'zipCode': '91809', 'north': 48.8463867, 'east': 11.1733569, 'south': 48.79008, 'west': 11.0323424}, {
  'zipCode': '92224',
  'north':   49.4967507,
  'east':    11.9143627,
  'south':   49.4117467,
  'west':    11.77667
}, {'zipCode': '92237', 'north': 49.5629862, 'east': 11.817672, 'south': 49.4621792, 'west': 11.6639508}, {
  'zipCode': '92242',
  'north':   49.6146116,
  'east':    12.009874,
  'south':   49.4895635,
  'west':    11.8707875
}, {'zipCode': '92245', 'north': 49.4640816, 'east': 11.9494638, 'south': 49.3706777, 'west': 11.809964}, {
  'zipCode': '92249',
  'north':   49.6537009,
  'east':    11.8835461,
  'south':   49.5641704,
  'west':    11.724946200000002
}, {'zipCode': '92253', 'north': 49.5789857, 'east': 12.1222796, 'south': 49.4806377, 'west': 11.9759857}, {
  'zipCode': '92256',
  'north':   49.593575300000005,
  'east':    11.8901103,
  'south':   49.483891,
  'west':    11.7259045
}, {'zipCode': '92259', 'north': 49.5776812, 'east': 11.7059195, 'south': 49.4848992, 'west': 11.5903725}, {
  'zipCode': '92260',
  'north':   49.4727547,
  'east':    11.7889597,
  'south':   49.4193039,
  'west':    11.7424989
}, {'zipCode': '92262', 'north': 49.486671400000006, 'east': 11.6804966, 'south': 49.3857572, 'west': 11.555496}, {
  'zipCode': '92263',
  'north':   49.4224727,
  'east':    12.064379900000002,
  'south':   49.344981100000005,
  'west':    11.9187008
}, {'zipCode': '92265', 'north': 49.6239071, 'east': 11.7730219, 'south': 49.55012130000001, 'west': 11.6620845}, {
  'zipCode': '92266',
  'north':   49.386946,
  'east':    12.011215,
  'south':   49.3155501,
  'west':    11.8605443
}, {'zipCode': '92268', 'north': 49.5579954, 'east': 11.6314765, 'south': 49.5106573, 'west': 11.5407321}, {
  'zipCode': '92269',
  'north':   49.4368341,
  'east':    12.1020673,
  'south':   49.3783913,
  'west':    11.9771237
}, {'zipCode': '92271', 'north': 49.6443489, 'east': 11.981587500000002, 'south': 49.5607533, 'west': 11.852925000000003}, {
  'zipCode': '92272',
  'north':   49.5135936,
  'east':    12.0417457,
  'south':   49.4142913,
  'west':    11.8730362
}, {'zipCode': '92274', 'north': 49.5764623, 'east': 11.9212639, 'south': 49.5242322, 'west': 11.8327761}, {
  'zipCode': '92275',
  'north':   49.6092553,
  'east':    11.6533802,
  'south':   49.5425109,
  'west':    11.510927
}, {'zipCode': '92277', 'north': 49.3423764, 'east': 11.8845507, 'south': 49.2791613, 'west': 11.7217659}, {
  'zipCode': '92278',
  'north':   49.5025251,
  'east':    11.7589976,
  'south':   49.4116175,
  'west':    11.6283855
}, {'zipCode': '92280', 'north': 49.41767790000001, 'east': 11.750943, 'south': 49.3074211, 'west': 11.6239145}, {
  'zipCode': '92281',
  'north':   49.6453044,
  'east':    11.7198315,
  'south':   49.569198,
  'west':    11.5603469
}, {'zipCode': '92283', 'north': 49.4325725, 'east': 11.6645751, 'south': 49.3078557, 'west': 11.4446898}, {
  'zipCode': '92284',
  'north':   49.5016154,
  'east':    11.8499835,
  'south':   49.4647444,
  'west':    11.7590006
}, {'zipCode': '92286', 'north': 49.3388215, 'east': 11.9825873, 'south': 49.2779739, 'west': 11.8575081}, {
  'zipCode': '92287',
  'north':   49.3093411,
  'east':    11.9831853,
  'south':   49.214384,
  'west':    11.8699485
}, {'zipCode': '92289', 'north': 49.4300652, 'east': 11.9067822, 'south': 49.3253736, 'west': 11.703051}, {
  'zipCode': '92318',
  'north':   49.3198223,
  'east':    11.586427300000002,
  'south':   49.2359512,
  'west':    11.3746936
}, {'zipCode': '92331', 'north': 49.2298275, 'east': 11.7980849, 'south': 49.0962681, 'west': 11.6635778}, {
  'zipCode': '92334',
  'north':   49.18775,
  'east':    11.576365800000001,
  'south':   49.0618568,
  'west':    11.3524298
}, {'zipCode': '92339', 'north': 49.0876959, 'east': 11.5951447, 'south': 48.9516219, 'west': 11.3854907}, {
  'zipCode': '92342',
  'north':   49.2554002,
  'east':    11.4009884,
  'south':   49.1135142,
  'west':    11.2617312
}, {'zipCode': '92345', 'north': 49.0966396, 'east': 11.6780541, 'south': 48.9514742, 'west': 11.5148304}, {
  'zipCode': '92348',
  'north':   49.4109196,
  'east':    11.5013364,
  'south':   49.3036194,
  'west':    11.3711134
}, {'zipCode': '92353', 'north': 49.3284725, 'east': 11.3918503, 'south': 49.2509701, 'west': 11.316452600000002}, {
  'zipCode': '92355',
  'north':   49.340427,
  'east':    11.7887457,
  'south':   49.18647920000001,
  'west':    11.5524426
}, {'zipCode': '92358', 'north': 49.2064816, 'east': 11.6903015, 'south': 49.1127178, 'west': 11.5383801}, {
  'zipCode': '92360',
  'north':   49.2090686,
  'east':    11.4918092,
  'south':   49.1445123,
  'west':    11.3744569
}, {'zipCode': '92361', 'north': 49.2893499, 'east': 11.4282947, 'south': 49.2207746, 'west': 11.3435791}, {
  'zipCode': '92363',
  'north':   49.1360772,
  'east':    11.7122495,
  'south':   49.0468953,
  'west':    11.53652
}, {'zipCode': '92364', 'north': 49.2709698, 'east': 11.6030956, 'south': 49.1568823, 'west': 11.470944}, {
  'zipCode': '92366',
  'north':   49.2987528,
  'east':    11.942563600000002,
  'south':   49.1509714,
  'west':    11.7680545
}, {'zipCode': '92367', 'north': 49.3803045, 'east': 11.6101505, 'south': 49.2823418, 'west': 11.4818255}, {
  'zipCode': '92369',
  'north':   49.2510701,
  'east':    11.4985972,
  'south':   49.1891251,
  'west':    11.3941267
}, {'zipCode': '92421', 'north': 49.3826214, 'east': 12.2108622, 'south': 49.24795580000001, 'west': 11.9664446}, {
  'zipCode': '92431',
  'north':   49.3924713,
  'east':    12.4896343,
  'south':   49.3048921,
  'west':    12.2194143
}, {'zipCode': '92436', 'north': 49.2761232, 'east': 12.3952832, 'south': 49.2100384, 'west': 12.2172878}, {
  'zipCode': '92439',
  'north':   49.3302154,
  'east':    12.3808972,
  'south':   49.2623216,
  'west':    12.2054389
}, {'zipCode': '92442', 'north': 49.3523094, 'east': 12.2492557, 'south': 49.2794687, 'west': 12.1365831}, {
  'zipCode': '92444',
  'north':   49.4142735,
  'east':    12.5869504,
  'south':   49.2927018,
  'west':    12.4776702
}, {'zipCode': '92445', 'north': 49.320589500000004, 'east': 12.510077900000002, 'south': 49.2607719, 'west': 12.3313298}, {
  'zipCode': '92447',
  'north':   49.4176672,
  'east':    12.3863866,
  'south':   49.3472312,
  'west':    12.2803051
}, {'zipCode': '92449', 'north': 49.2971249, 'east': 12.2208741, 'south': 49.2483688, 'west': 12.1308867}, {
  'zipCode': '92507',
  'north':   49.4882395,
  'east':    12.262992,
  'south':   49.4131696,
  'west':    12.0923979
}, {'zipCode': '92521', 'north': 49.4114492, 'east': 12.2449807, 'south': 49.333697, 'west': 12.0735642}, {
  'zipCode': '92526',
  'north':   49.5537973,
  'east':    12.5141817,
  'south':   49.4316249,
  'west':    12.3765388
}, {'zipCode': '92533', 'north': 49.5940465, 'east': 12.2466249, 'south': 49.4842819, 'west': 12.0721652}, {
  'zipCode': '92536',
  'north':   49.5404605,
  'east':    12.3008459,
  'south':   49.473309900000004,
  'west':    12.105141
}, {'zipCode': '92539', 'north': 49.5522065, 'east': 12.641384, 'south': 49.4687524, 'west': 12.471855500000002}, {
  'zipCode': '92540',
  'north':   49.446818,
  'east':    12.3431201,
  'south':   49.3900507,
  'west':    12.2309563
}, {'zipCode': '92542', 'north': 49.4425381, 'east': 12.4500251, 'south': 49.3718627, 'west': 12.3622896}, {
  'zipCode': '92543',
  'north':   49.4922565,
  'east':    12.3464001,
  'south':   49.4250909,
  'west':    12.2282738
}, {'zipCode': '92545', 'north': 49.4827179, 'east': 12.4123725, 'south': 49.4131198, 'west': 12.3196904}, {
  'zipCode': '92546',
  'north':   49.4951337,
  'east':    12.1106525,
  'south':   49.4037034,
  'west':    12.0304074
}, {'zipCode': '92548', 'north': 49.4239354, 'east': 12.2714925, 'south': 49.3629586, 'west': 12.14937}, {
  'zipCode': '92549',
  'north':   49.5269045,
  'east':    12.6444149,
  'south':   49.4757597,
  'west':    12.5970866
}, {'zipCode': '92551', 'north': 49.4438604, 'east': 12.168086900000002, 'south': 49.3924179, 'west': 12.0974439}, {
  'zipCode': '92552',
  'north':   49.5327933,
  'east':    12.4332355,
  'south':   49.467719100000004,
  'west':    12.327271900000001
}, {'zipCode': '92554', 'north': 49.4274791, 'east': 12.5038003, 'south': 49.3616823, 'west': 12.4103673}, {
  'zipCode': '92555',
  'north':   49.557704,
  'east':    12.3029569,
  'south':   49.505986,
  'west':    12.217834300000002
}, {'zipCode': '92557', 'north': 49.5026734, 'east': 12.615149300000002, 'south': 49.45283400000001, 'west': 12.5082497}, {
  'zipCode': '92559',
  'north':   49.4752037,
  'east':    12.5557999,
  'south':   49.4049065,
  'west':    12.4460808
}, {'zipCode': '92637', 'north': 49.7264882, 'east': 12.2919507, 'south': 49.6143585, 'west': 12.064423100000003}, {
  'zipCode': '92648',
  'north':   49.6672254,
  'east':    12.4125314,
  'south':   49.5581513,
  'west':    12.2393157
}, {'zipCode': '92655', 'north': 49.7500318, 'east': 11.9984982, 'south': 49.6228788, 'west': 11.639921400000002}, {
  'zipCode': '92660',
  'north':   49.7521441,
  'east':    12.1960052,
  'south':   49.7018489,
  'west':    12.1367284
}, {'zipCode': '92665', 'north': 49.8013008, 'east': 12.1812915, 'south': 49.697651, 'west': 12.0436299}, {
  'zipCode': '92670',
  'north':   49.8563312,
  'east':    12.216740200000002,
  'south':   49.775233,
  'west':    12.0923767
}, {'zipCode': '92676', 'north': 49.8412124, 'east': 11.894376200000002, 'south': 49.7120162, 'west': 11.7332154}, {
  'zipCode': '92681',
  'north':   49.9024158,
  'east':    12.0997198,
  'south':   49.7837999,
  'west':    11.972902
}, {'zipCode': '92685', 'north': 49.7715001, 'east': 12.3353898, 'south': 49.6824003, 'west': 12.2093788}, {
  'zipCode': '92690',
  'north':   49.822243900000004,
  'east':    12.050928800000001,
  'south':   49.7066395,
  'west':    11.9084254
}, {'zipCode': '92693', 'north': 49.6285117, 'east': 12.5939182, 'south': 49.5349287, 'west': 12.4474186}, {
  'zipCode': '92694',
  'north':   49.6486785,
  'east':    12.125858000000001,
  'south':   49.5964319,
  'west':    12.0629576
}, {'zipCode': '92696', 'north': 49.7710857, 'east': 12.4057327, 'south': 49.713007, 'west': 12.3116493}, {
  'zipCode': '92697',
  'north':   49.7450427,
  'east':    12.4854361,
  'south':   49.6745412,
  'west':    12.3459788
}, {'zipCode': '92699', 'north': 49.6626248, 'east': 12.2425255, 'south': 49.61081, 'west': 12.1910781}, {
  'zipCode': '92700',
  'north':   49.6801025,
  'east':    11.9709759,
  'south':   49.6381179,
  'west':    11.8984076
}, {'zipCode': '92702', 'north': 49.6397571, 'east': 12.0907484, 'south': 49.56900790000001, 'west': 11.9679225}, {
  'zipCode': '92703',
  'north':   49.8856058,
  'east':    12.1369553,
  'south':   49.8137163,
  'west':    12.0562499
}, {'zipCode': '92705', 'north': 49.6210622, 'east': 12.3050524, 'south': 49.5472642, 'west': 12.218458}, {
  'zipCode': '92706',
  'north':   49.6187677,
  'east':    12.1979571,
  'south':   49.548103,
  'west':    12.067098400000003
}, {'zipCode': '92708', 'north': 49.7256943, 'east': 12.0863589, 'south': 49.6363641, 'west': 11.9709759}, {
  'zipCode': '92709',
  'north':   49.6266389,
  'east':    12.4900364,
  'south':   49.5205634,
  'west':    12.3524351
}, {'zipCode': '92711', 'north': 49.7759059, 'east': 12.1150834, 'south': 49.6817816, 'west': 12.0075198}, {
  'zipCode': '92712',
  'north':   49.6427948,
  'east':    12.2345745,
  'south':   49.5853629,
  'west':    12.1360544
}, {'zipCode': '92714', 'north': 49.6884944, 'east': 12.4823461, 'south': 49.6064527, 'west': 12.3656938}, {
  'zipCode': '92715',
  'north':   49.80516690000001,
  'east':    12.2674523,
  'south':   49.7401084,
  'west':    12.1622881
}, {'zipCode': '92717', 'north': 49.879472, 'east': 12.1776242, 'south': 49.8285287, 'west': 12.1115776}, {
  'zipCode': '92718',
  'north':   49.6628571,
  'east':    12.1998383,
  'south':   49.6395569,
  'west':    12.156652
}, {'zipCode': '92720', 'north': 49.7406632, 'east': 12.047408500000001, 'south': 49.6968851, 'west': 11.9661623}, {
  'zipCode': '92721',
  'north':   49.7612291,
  'east':    12.2369166,
  'south':   49.7204492,
  'west':    12.167816200000003
}, {'zipCode': '92723', 'north': 49.5830136, 'east': 12.404864, 'south': 49.4866674, 'west': 12.2576384}, {
  'zipCode': '92724',
  'north':   49.8109909,
  'east':    11.944584,
  'south':   49.73788590000001,
  'west':    11.8494623
}, {'zipCode': '92726', 'north': 49.6898782, 'east': 12.5299888, 'south': 49.60522, 'west': 12.4483126}, {
  'zipCode': '92727',
  'north':   49.7172336,
  'east':    12.3874257,
  'south':   49.6548642,
  'west':    12.2551032
}, {'zipCode': '92729', 'north': 49.667616300000006, 'east': 12.0976628, 'south': 49.6067097, 'west': 11.9575663}, {
  'zipCode': '93047',
  'north':   49.024257,
  'east':    12.1100254,
  'south':   49.0118901,
  'west':    12.0809846
}, {'zipCode': '93049', 'north': 49.0313694, 'east': 12.0884584, 'south': 49.0046501, 'west': 12.0290862}, {
  'zipCode': '93051',
  'north':   49.0124974,
  'east':    12.098707,
  'south':   48.9904337,
  'west':    12.0339004
}, {'zipCode': '93053', 'north': 49.0121013, 'east': 12.1421506, 'south': 48.9667457, 'west': 12.065783}, {
  'zipCode': '93055',
  'north':   49.0487185,
  'east':    12.1916078,
  'south':   48.9669455,
  'west':    12.1069805
}, {'zipCode': '93057', 'north': 49.0764158, 'east': 12.146099700000002, 'south': 49.0309131, 'west': 12.0941296}, {
  'zipCode': '93059',
  'north':   49.0507157,
  'east':    12.1340223,
  'south':   49.0194944,
  'west':    12.0314888
}, {'zipCode': '93073', 'north': 48.999385, 'east': 12.2334109, 'south': 48.9605144, 'west': 12.1764018}, {
  'zipCode': '93077',
  'north':   48.9640153,
  'east':    12.1338172,
  'south':   48.8610658,
  'west':    11.9685988
}, {'zipCode': '93080', 'north': 49.001815, 'east': 12.1154176, 'south': 48.9223265, 'west': 11.986548800000001}, {
  'zipCode': '93083',
  'north':   48.9785389,
  'east':    12.2000493,
  'south':   48.922376,
  'west':    12.0954119
}, {'zipCode': '93086', 'north': 49.0471319, 'east': 12.4909977, 'south': 48.9719675, 'west': 12.3243747}, {
  'zipCode': '93087',
  'north':   48.9357473,
  'east':    12.2438371,
  'south':   48.8959295,
  'west':    12.1681659
}, {'zipCode': '93089', 'north': 48.9036498, 'east': 12.3235303, 'south': 48.8395905, 'west': 12.2362166}, {
  'zipCode': '93090',
  'north':   49.0444003,
  'east':    12.3331229,
  'south':   49.006348,
  'west':    12.2437224
}, {'zipCode': '93092', 'north': 49.0231989, 'east': 12.3424686, 'south': 48.976856500000004, 'west': 12.1863776}, {
  'zipCode': '93093',
  'north':   49.0488813,
  'east':    12.2767516,
  'south':   49.0139545,
  'west':    12.189625
}, {'zipCode': '93095', 'north': 48.920988400000006, 'east': 12.2917723, 'south': 48.86753, 'west': 12.1717682}, {
  'zipCode': '93096',
  'north':   48.9502431,
  'east':    12.210139,
  'south':   48.9253241,
  'west':    12.1507149
}, {'zipCode': '93098', 'north': 48.9918261, 'east': 12.3294438, 'south': 48.9004704, 'west': 12.1934083}, {
  'zipCode': '93099',
  'north':   48.93835700000001,
  'east':    12.4571736,
  'south':   48.8567026,
  'west':    12.3529317
}, {'zipCode': '93101', 'north': 48.8830197, 'east': 12.2619844, 'south': 48.8411902, 'west': 12.1862125}, {
  'zipCode': '93102',
  'north':   48.9897417,
  'east':    12.4536808,
  'south':   48.9296805,
  'west':    12.2789478
}, {'zipCode': '93104', 'north': 48.9488656, 'east': 12.3940138, 'south': 48.8466994, 'west': 12.2935392}, {
  'zipCode': '93105',
  'north':   49.0562194,
  'east':    12.213719600000001,
  'south':   49.0069252,
  'west':    12.158515
}, {'zipCode': '93107', 'north': 48.9487087, 'east': 12.1977996, 'south': 48.8699151, 'west': 12.0807007}, {
  'zipCode': '93109',
  'north':   49.0551851,
  'east':    12.4161823,
  'south':   48.9998086,
  'west':    12.3194269
}, {'zipCode': '93128', 'north': 49.2232841, 'east': 12.2358583, 'south': 49.0865426, 'west': 12.003688}, {
  'zipCode': '93133',
  'north':   49.3080793,
  'east':    12.0798202,
  'south':   49.1593012,
  'west':    11.93589
}, {'zipCode': '93138', 'north': 49.1109702, 'east': 12.1045916, 'south': 49.0320408, 'west': 12.0117348}, {
  'zipCode': '93142',
  'north':   49.2217959,
  'east':    12.1566788,
  'south':   49.1433314,
  'west':    12.0564896
}, {'zipCode': '93149', 'north': 49.2614484, 'east': 12.3637552, 'south': 49.1495787, 'west': 12.1374594}, {
  'zipCode': '93152',
  'north':   49.053699,
  'east':    12.001422,
  'south':   48.9864611,
  'west':    11.8644405
}, {'zipCode': '93155', 'north': 49.10726, 'east': 11.899304200000001, 'south': 48.9808461, 'west': 11.6591858}, {
  'zipCode': '93158',
  'north':   49.2645223,
  'east':    12.161717,
  'south':   49.2032642,
  'west':    12.0323029
}, {'zipCode': '93161', 'north': 49.0256965, 'east': 12.0476213, 'south': 48.9457913, 'west': 11.902391300000001}, {
  'zipCode': '93164',
  'north':   49.1149407,
  'east':    11.9608637,
  'south':   49.039415500000004,
  'west':    11.8396029
}, {'zipCode': '93167', 'north': 49.1427773, 'east': 12.5480028, 'south': 49.0561762, 'west': 12.4094586}, {
  'zipCode': '93170',
  'north':   49.1665319,
  'east':    12.3242893,
  'south':   49.0353546,
  'west':    12.161165900000002
}, {'zipCode': '93173', 'north': 49.109781, 'east': 12.2350479, 'south': 49.0465167, 'west': 12.132681300000002}, {
  'zipCode': '93176',
  'north':   49.174571,
  'east':    11.8972312,
  'south':   49.0702972,
  'west':    11.7459612
}, {'zipCode': '93177', 'north': 49.1237405, 'east': 12.3617767, 'south': 49.0270164, 'west': 12.2538869}, {
  'zipCode': '93179',
  'north':   49.1039196,
  'east':    12.4403742,
  'south':   49.0439438,
  'west':    12.32549
}, {'zipCode': '93180', 'north': 49.0459308, 'east': 11.9252882, 'south': 49.0168318, 'west': 11.8688719}, {
  'zipCode': '93182',
  'north':   49.1571205,
  'east':    11.9777663,
  'south':   49.0928144,
  'west':    11.8723101
}, {'zipCode': '93183', 'north': 49.1950852, 'east': 12.0378487, 'south': 49.1173807, 'west': 11.8582702}, {
  'zipCode': '93185',
  'north':   49.151930300000004,
  'east':    12.5961273,
  'south':   49.0828361,
  'west':    12.4766565
}, {'zipCode': '93186', 'north': 49.076291, 'east': 12.0479368, 'south': 49.0149251, 'west': 11.9645812}, {
  'zipCode': '93188',
  'north':   49.0959537,
  'east':    12.0256005,
  'south':   49.049786,
  'west':    11.938913300000001
}, {'zipCode': '93189', 'north': 49.2053854, 'east': 12.3743268, 'south': 49.1551513, 'west': 12.3228299}, {
  'zipCode': '93191',
  'north':   49.0982591,
  'east':    12.5054497,
  'south':   49.0277424,
  'west':    12.4080247
}, {'zipCode': '93192', 'north': 49.1745006, 'east': 12.4029337, 'south': 49.0870044, 'west': 12.303127}, {
  'zipCode': '93194',
  'north':   49.234248,
  'east':    12.448517,
  'south':   49.1571376,
  'west':    12.347027500000001
}, {'zipCode': '93195', 'north': 49.1227119, 'east': 12.0179444, 'south': 49.0838043, 'west': 11.9419684}, {
  'zipCode': '93197',
  'north':   49.1141322,
  'east':    12.171152700000002,
  'south':   49.0625198,
  'west':    12.0888638
}, {'zipCode': '93199', 'north': 49.1844961, 'east': 12.4661704, 'south': 49.0987873, 'west': 12.3684316}, {
  'zipCode': '93309',
  'north':   48.996694,
  'east':    12.0135427,
  'south':   48.8537583,
  'west':    11.7168172
}, {'zipCode': '93326', 'north': 48.8940867, 'east': 11.9223532, 'south': 48.7743412, 'west': 11.7933867}, {
  'zipCode': '93333',
  'north':   48.9105095,
  'east':    11.8293443,
  'south':   48.7178401,
  'west':    11.6553308
}, {'zipCode': '93336', 'north': 48.9610614, 'east': 11.7442081, 'south': 48.8578026, 'west': 11.5079755}, {
  'zipCode': '93339',
  'north':   49.0216268,
  'east':    11.8038132,
  'south':   48.9180349,
  'west':    11.5948606
}, {'zipCode': '93342', 'north': 48.9124429, 'east': 11.9884466, 'south': 48.8178579, 'west': 11.8709536}, {
  'zipCode': '93343',
  'north':   48.9712937,
  'east':    11.8409514,
  'south':   48.9184246,
  'west':    11.7658313
}, {'zipCode': '93345', 'north': 48.8798618, 'east': 12.034419, 'south': 48.8067128, 'west': 11.937776600000001}, {
  'zipCode': '93346',
  'north':   48.9779792,
  'east':    11.885336,
  'south':   48.9238029,
  'west':    11.8059654
}, {'zipCode': '93348', 'north': 48.7956788, 'east': 11.946386300000002, 'south': 48.7379519, 'west': 11.8775738}, {
  'zipCode': '93349',
  'north':   48.8872152,
  'east':    11.6787241,
  'south':   48.8238006,
  'west':    11.5669304
}, {'zipCode': '93351', 'north': 49.0227419, 'east': 11.897246800000001, 'south': 48.9705553, 'west': 11.7651704}, {
  'zipCode': '93352',
  'north':   48.8267273,
  'east':    12.0412946,
  'south':   48.7244402,
  'west':    11.9066781
}, {'zipCode': '93354', 'north': 48.8054065, 'east': 11.8989244, 'south': 48.7073969, 'west': 11.7817546}, {
  'zipCode': '93356',
  'north':   48.9112714,
  'east':    12.0490913,
  'south':   48.8720039,
  'west':    11.9671659
}, {'zipCode': '93358', 'north': 48.7473285, 'east': 11.8524222, 'south': 48.7079504, 'west': 11.7847192}, {
  'zipCode': '93359',
  'north':   48.7544091,
  'east':    11.9469365,
  'south':   48.6994072,
  'west':    11.876111
}, {'zipCode': '93413', 'north': 49.2687886, 'east': 12.7550058, 'south': 49.1356869, 'west': 12.557506900000002}, {
  'zipCode': '93426',
  'north':   49.27684940000001,
  'east':    12.5697212,
  'south':   49.133864100000004,
  'west':    12.3706135
}, {'zipCode': '93437', 'north': 49.3509625, 'east': 12.9193251, 'south': 49.2513592, 'west': 12.7457048}, {
  'zipCode': '93444',
  'north':   49.2355442,
  'east':    12.9714974,
  'south':   49.1274916,
  'west':    12.8067442
}, {'zipCode': '93449', 'north': 49.4347964, 'east': 12.7863735, 'south': 49.2917135, 'west': 12.6101408}, {
  'zipCode': '93453',
  'north':   49.30615910000001,
  'east':    13.0940138,
  'south':   49.2214327,
  'west':    12.9241936
}, {'zipCode': '93455', 'north': 49.1856453, 'east': 12.7146346, 'south': 49.0955964, 'west': 12.5771475}, {
  'zipCode': '93458',
  'north':   49.3448871,
  'east':    13.0130672,
  'south':   49.2444481,
  'west':    12.871239700000002
}, {'zipCode': '93462', 'north': 49.23185, 'east': 13.1147711, 'south': 49.1548282, 'west': 13.0098319}, {
  'zipCode': '93464',
  'north':   49.4904444,
  'east':    12.6579773,
  'south':   49.3919817,
  'west':    12.5263609
}, {'zipCode': '93466', 'north': 49.2340784, 'east': 12.826018, 'south': 49.169506, 'west': 12.7157788}, {
  'zipCode': '93468',
  'north':   49.1818996,
  'east':    12.825997,
  'south':   49.1101508,
  'west':    12.7320799
}, {'zipCode': '93470', 'north': 49.2066549, 'east': 13.1708968, 'south': 49.1107315, 'west': 13.0450967}, {
  'zipCode': '93471',
  'north':   49.166443900000004,
  'east':    13.0658823,
  'south':   49.1026463,
  'west':    12.9134494
}, {'zipCode': '93473', 'north': 49.300876, 'east': 12.8665377, 'south': 49.225869, 'west': 12.7649208}, {
  'zipCode': '93474',
  'north':   49.2280969,
  'east':    13.0269563,
  'south':   49.1570615,
  'west':    12.9590125
}, {'zipCode': '93476', 'north': 49.1892507, 'east': 12.8400546, 'south': 49.1236477, 'west': 12.7779458}, {
  'zipCode': '93477',
  'north':   49.3516825,
  'east':    12.787342100000002,
  'south':   49.2971683,
  'west':    12.710888
}, {'zipCode': '93479', 'north': 49.2240001, 'east': 12.9108551, 'south': 49.1844544, 'west': 12.8533718}, {
  'zipCode': '93480',
  'north':   49.2313061,
  'east':    12.9739259,
  'south':   49.17112,
  'west':    12.9006887
}, {'zipCode': '93482', 'north': 49.3231485, 'east': 12.6551203, 'south': 49.2170075, 'west': 12.5670004}, {
  'zipCode': '93483',
  'north':   49.2479721,
  'east':    12.5857847,
  'south':   49.219954,
  'west':    12.5171913
}, {'zipCode': '93485', 'north': 49.2588645, 'east': 12.9589807, 'south': 49.2069207, 'west': 12.8307805}, {
  'zipCode': '93486',
  'north':   49.2496874,
  'east':    12.8107427,
  'south':   49.196371,
  'west':    12.6965191
}, {'zipCode': '93488', 'north': 49.3981355, 'east': 12.6542544, 'south': 49.3099626, 'west': 12.5461652}, {
  'zipCode': '93489',
  'north':   49.2072355,
  'east':    12.6487672,
  'south':   49.1242698,
  'west':    12.5490425
}, {'zipCode': '93491', 'north': 49.3161242, 'east': 12.578641, 'south': 49.236539, 'west': 12.4541062}, {
  'zipCode': '93492',
  'north':   49.4534495,
  'east':    12.658236400000002,
  'south':   49.381908,
  'west':    12.5932117
}, {'zipCode': '93494', 'north': 49.3228762, 'east': 12.7116887, 'south': 49.2471349, 'west': 12.629446}, {
  'zipCode': '93495',
  'north':   49.3031556,
  'east':    12.8109268,
  'south':   49.2240697,
  'west':    12.7000397
}, {'zipCode': '93497', 'north': 49.2710538, 'east': 12.7098635, 'south': 49.2309425, 'west': 12.6532036}, {
  'zipCode': '93499',
  'north':   49.17476,
  'east':    12.7610413,
  'south':   49.1112393,
  'west':    12.6910247
}, {'zipCode': '94032', 'north': 48.5808924, 'east': 13.5069693, 'south': 48.5538268, 'west': 13.4207038}, {
  'zipCode': '94034',
  'north':   48.6134154,
  'east':    13.514393,
  'south':   48.5740372,
  'west':    13.3506178
}, {'zipCode': '94036', 'north': 48.6076512, 'east': 13.4466109, 'south': 48.54045010000001, 'west': 13.3023403}, {
  'zipCode': '94051',
  'north':   48.6929677,
  'east':    13.7216246,
  'south':   48.5991982,
  'west':    13.5415877
}, {'zipCode': '94060', 'north': 48.43935040000001, 'east': 13.4231748, 'south': 48.3610613, 'west': 13.2291761}, {
  'zipCode': '94065',
  'north':   48.7963051,
  'east':    13.6747208,
  'south':   48.6820456,
  'west':    13.5433534
}, {'zipCode': '94072', 'north': 48.388001, 'east': 13.4003674, 'south': 48.2929019, 'west': 13.2130692}, {
  'zipCode': '94078',
  'north':   48.882965,
  'east':    13.6122827,
  'south':   48.7785183,
  'west':    13.4772467
}, {'zipCode': '94081', 'north': 48.591481, 'east': 13.3872849, 'south': 48.4572743, 'west': 13.2551824}, {
  'zipCode': '94086',
  'north':   48.5330669,
  'east':    13.2687003,
  'south':   48.4058169,
  'west':    13.1151449
}, {'zipCode': '94089', 'north': 48.7999102, 'east': 13.8396373, 'south': 48.7113484, 'west': 13.6976904}, {
  'zipCode': '94094',
  'north':   48.4169399,
  'east':    13.2489634,
  'south':   48.294378,
  'west':    13.1212121
}, {'zipCode': '94099', 'north': 48.5117678, 'east': 13.404518000000001, 'south': 48.4196714, 'west': 13.233803}, {
  'zipCode': '94104',
  'north':   48.761859400000006,
  'east':    13.4551657,
  'south':   48.6954656,
  'west':    13.3295923
}, {'zipCode': '94107', 'north': 48.6361466, 'east': 13.7557176, 'south': 48.5130182, 'west': 13.5817114}, {
  'zipCode': '94110',
  'north':   48.6789581,
  'east':    13.8271725,
  'south':   48.5534733,
  'west':    13.705566400000002
}, {'zipCode': '94113', 'north': 48.6721248, 'east': 13.443844900000002, 'south': 48.6014084, 'west': 13.2886552}, {
  'zipCode': '94116',
  'north':   48.729106,
  'east':    13.5311394,
  'south':   48.6471452,
  'west':    13.4301628
}, {'zipCode': '94118', 'north': 48.7737556, 'east': 13.7357174, 'south': 48.6857937, 'west': 13.6396901}, {
  'zipCode': '94121',
  'north':   48.6517826,
  'east':    13.5260037,
  'south':   48.5946639,
  'west':    13.4369432
}, {'zipCode': '94124', 'north': 48.7140447, 'east': 13.590345400000002, 'south': 48.64397400000001, 'west': 13.4832682}, {
  'zipCode': '94127',
  'north':   48.5565208,
  'east':    13.4581768,
  'south':   48.4780673,
  'west':    13.3441215
}, {'zipCode': '94130', 'north': 48.5941324, 'east': 13.6581428, 'south': 48.5463263, 'west': 13.5522516}, {
  'zipCode': '94133',
  'north':   48.7955223,
  'east':    13.5631348,
  'south':   48.7112722,
  'west':    13.4696209
}, {'zipCode': '94136', 'north': 48.6539334, 'east': 13.5895715, 'south': 48.5644065, 'west': 13.5124181}, {
  'zipCode': '94137',
  'north':   48.43647130000001,
  'east':    13.1749009,
  'south':   48.3896741,
  'west':    13.0892546
}, {'zipCode': '94139', 'north': 48.7252985, 'east': 13.8374192, 'south': 48.6605609, 'west': 13.721618000000001}, {
  'zipCode': '94140',
  'north':   48.3527036,
  'east':    13.176942,
  'south':   48.2772994,
  'west':    13.0717405
}, {'zipCode': '94142', 'north': 48.7588075, 'east': 13.4779996, 'south': 48.7131917, 'west': 13.4260963}, {
  'zipCode': '94143',
  'north':   48.8340537,
  'east':    13.729222100000001,
  'south':   48.7611502,
  'west':    13.5981497
}, {'zipCode': '94145', 'north': 48.8871227, 'east': 13.8152377, 'south': 48.7812708, 'west': 13.6713894}, {
  'zipCode': '94146',
  'north':   48.8750394,
  'east':    13.6982001,
  'south':   48.799985400000004,
  'west':    13.5651661
}, {'zipCode': '94148', 'north': 48.3673263, 'east': 13.297214100000001, 'south': 48.32478030000001, 'west': 13.2064471}, {
  'zipCode': '94149',
  'north':   48.3986492,
  'east':    13.1672858,
  'south':   48.3414323,
  'west':    13.0801978
}, {'zipCode': '94151', 'north': 48.9740476, 'east': 13.6462302, 'south': 48.8574565, 'west': 13.5053671}, {
  'zipCode': '94152',
  'north':   48.4989742,
  'east':    13.4473789,
  'south':   48.4070371,
  'west':    13.3665894
}, {'zipCode': '94154', 'north': 48.7204289, 'east': 13.4531908, 'south': 48.6645869, 'west': 13.3463423}, {
  'zipCode': '94157',
  'north':   48.8180391,
  'east':    13.4918197,
  'south':   48.7454387,
  'west':    13.3943695
}, {'zipCode': '94158', 'north': 48.9116854, 'east': 13.7242774, 'south': 48.8553395, 'west': 13.6325344}, {
  'zipCode': '94160',
  'north':   48.8350207,
  'east':    13.4929366,
  'south':   48.7827095,
  'west':    13.4278485
}, {'zipCode': '94161', 'north': 48.6760908, 'east': 13.4452903, 'south': 48.642521, 'west': 13.3571013}, {
  'zipCode': '94163',
  'north':   48.805964,
  'east':    13.4046528,
  'south':   48.7453373,
  'west':    13.3103402
}, {'zipCode': '94164', 'north': 48.6921723, 'east': 13.759928200000001, 'south': 48.64210860000001, 'west': 13.6758981}, {
  'zipCode': '94166',
  'north':   48.36778160000001,
  'east':    13.1022214,
  'south':   48.2772635,
  'west':    13.032155
}, {'zipCode': '94167', 'north': 48.4645024, 'east': 13.3172935, 'south': 48.4084125, 'west': 13.2257972}, {
  'zipCode': '94169',
  'north':   48.8204615,
  'east':    13.3517245,
  'south':   48.7303979,
  'west':    13.257426
}, {'zipCode': '94209', 'north': 49.0106733, 'east': 13.2119948, 'south': 48.9246362, 'west': 13.0471896}, {
  'zipCode': '94227',
  'north':   49.1204971,
  'east':    13.4058312,
  'south':   48.9773874,
  'west':    13.1635335
}, {'zipCode': '94234', 'north': 49.1375415, 'east': 12.9952709, 'south': 49.0439812, 'west': 12.841738}, {
  'zipCode': '94239',
  'north':   49.0064852,
  'east':    13.0737668,
  'south':   48.9282055,
  'west':    12.9313565
}, {'zipCode': '94244', 'north': 49.0734733, 'east': 13.0997793, 'south': 48.9882608, 'west': 12.9405033}, {
  'zipCode': '94249',
  'north':   49.1236313,
  'east':    13.168389600000001,
  'south':   49.0348083,
  'west':    13.0468501
}, {'zipCode': '94250', 'north': 49.0103961, 'east': 12.9524651, 'south': 48.9352355, 'west': 12.8469552}, {
  'zipCode': '94252',
  'north':   49.1736238,
  'east':    13.2362665,
  'south':   49.0605109,
  'west':    13.1327529
}, {'zipCode': '94253', 'north': 48.9589651, 'east': 13.1394519, 'south': 48.8798468, 'west': 13.0063829}, {
  'zipCode': '94255',
  'north':   49.0789808,
  'east':    13.116216,
  'south':   49.0065959,
  'west':    12.996381100000002
}, {'zipCode': '94256', 'north': 49.14220040000001, 'east': 13.0909824, 'south': 49.0660978, 'west': 12.9636835}, {
  'zipCode': '94258',
  'north':   49.02050160000001,
  'east':    13.4171054,
  'south':   48.9465988,
  'west':    13.2245994
}, {'zipCode': '94259', 'north': 48.9371326, 'east': 13.2518606, 'south': 48.8571734, 'west': 13.1173804}, {
  'zipCode': '94261',
  'north':   48.960227,
  'east':    13.2964156,
  'south':   48.8722502,
  'west':    13.2147326
}, {'zipCode': '94262', 'north': 49.0765225, 'east': 12.9503337, 'south': 48.9909109, 'west': 12.804747400000002}, {
  'zipCode': '94264',
  'north':   49.0690449,
  'east':    13.2064637,
  'south':   48.9926593,
  'west':    13.1080216
}, {'zipCode': '94265', 'north': 49.0232016, 'east': 13.016402, 'south': 48.9704024, 'west': 12.9312729}, {
  'zipCode': '94267',
  'north':   49.1351892,
  'east':    12.8789463,
  'south':   49.0562047,
  'west':    12.7634962
}, {'zipCode': '94269', 'north': 48.9837889, 'east': 13.2710046, 'south': 48.9184153, 'west': 13.1501239}, {
  'zipCode': '94315',
  'north':   48.9246325,
  'east':    12.667091,
  'south':   48.8417102,
  'west':    12.4802154
}, {'zipCode': '94327', 'north': 48.9531886, 'east': 12.8006267, 'south': 48.8718615, 'west': 12.6308739}, {
  'zipCode': '94330',
  'north':   48.9071927,
  'east':    12.7335344,
  'south':   48.7796688,
  'west':    12.537222
}, {'zipCode': '94333', 'north': 48.8640078, 'east': 12.5046356, 'south': 48.7582075, 'west': 12.291940900000002}, {
  'zipCode': '94336',
  'north':   48.9801154,
  'east':    12.7900694,
  'south':   48.9153829,
  'west':    12.6757812
}, {'zipCode': '94339', 'north': 48.8217503, 'east': 12.6109959, 'south': 48.7155318, 'west': 12.431765}, {
  'zipCode': '94342',
  'north':   48.8858528,
  'east':    12.7696886,
  'south':   48.7901424,
  'west':    12.6617386
}, {'zipCode': '94344', 'north': 49.1017775, 'east': 12.6176187, 'south': 48.9822082, 'west': 12.4721717}, {
  'zipCode': '94345',
  'north':   48.9666598,
  'east':    12.5210198,
  'south':   48.9093189,
  'west':    12.4380931
}, {'zipCode': '94347', 'north': 49.0192074, 'east': 12.6614392, 'south': 48.9594838, 'west': 12.5855673}, {
  'zipCode': '94348',
  'north':   48.9156433,
  'east':    12.5282413,
  'south':   48.8691993,
  'west':    12.4676137
}, {'zipCode': '94350', 'north': 49.0302156, 'east': 12.6169355, 'south': 48.9719331, 'west': 12.568252600000001}, {
  'zipCode': '94351',
  'north':   48.8538378,
  'east':    12.5865436,
  'south':   48.8026117,
  'west':    12.4821784
}, {'zipCode': '94353', 'north': 49.0582778, 'east': 12.7824552, 'south': 48.9995319, 'west': 12.6761054}, {
  'zipCode': '94354',
  'north':   49.0468121,
  'east':    12.7274557,
  'south':   48.9718176,
  'west':    12.6434295
}, {'zipCode': '94356', 'north': 48.99158450000001, 'east': 12.5696312, 'south': 48.9105984, 'west': 12.4377012}, {
  'zipCode': '94357',
  'north':   49.1136352,
  'east':    12.742827100000001,
  'south':   49.0393426,
  'west':    12.6692504
}, {'zipCode': '94359', 'north': 49.1098409, 'east': 12.6939065, 'south': 49.0773302, 'west': 12.6180413}, {
  'zipCode': '94360',
  'north':   48.9932749,
  'east':    12.7090125,
  'south':   48.9493947,
  'west':    12.6281102
}, {'zipCode': '94362', 'north': 49.0070851, 'east': 12.8157932, 'south': 48.954025300000005, 'west': 12.7123528}, {
  'zipCode': '94363',
  'north':   48.8275045,
  'east':    12.734606000000001,
  'south':   48.7329826,
  'west':    12.5907997
}, {'zipCode': '94365', 'north': 48.943324100000005, 'east': 12.6648352, 'south': 48.8961805, 'west': 12.5584109}, {
  'zipCode': '94366',
  'north':   48.97990920000001,
  'east':    12.849469100000002,
  'south':   48.9258453,
  'west':    12.7675165
}, {'zipCode': '94368', 'north': 48.888369, 'east': 12.481104600000002, 'south': 48.84146230000001, 'west': 12.4008664}, {
  'zipCode': '94369',
  'north':   48.9223215,
  'east':    12.4910339,
  'south':   48.8689671,
  'west':    12.4237301
}, {'zipCode': '94371', 'north': 49.119694900000006, 'east': 12.8061046, 'south': 49.037641900000004, 'west': 12.7180571}, {
  'zipCode': '94372',
  'north':   49.0643665,
  'east':    12.6747158,
  'south':   49.006257,
  'west':    12.5904295
}, {'zipCode': '94374', 'north': 48.967896, 'east': 12.8911981, 'south': 48.8891895, 'west': 12.7880908}, {
  'zipCode': '94375',
  'north':   49.095759400000006,
  'east':    12.6862104,
  'south':   49.0315125,
  'west':    12.5935092
}, {'zipCode': '94377', 'north': 48.98163, 'east': 12.6559706, 'south': 48.930759900000005, 'west': 12.5572364}, {
  'zipCode': '94379',
  'north':   49.0550618,
  'east':    12.8806255,
  'south':   48.9621698,
  'west':    12.7701923
}, {'zipCode': '94405', 'north': 48.71103800000001, 'east': 12.7906635, 'south': 48.6008972, 'west': 12.6272606}, {
  'zipCode': '94419',
  'north':   48.624841,
  'east':    12.7171973,
  'south':   48.4819563,
  'west':    12.5261128
}, {'zipCode': '94424', 'north': 48.6079887, 'east': 12.8958224, 'south': 48.5011152, 'west': 12.7521117}, {
  'zipCode': '94428',
  'north':   48.6820547,
  'east':    12.9677096,
  'south':   48.5900637,
  'west':    12.7396106
}, {'zipCode': '94431', 'north': 48.7583638, 'east': 12.7153573, 'south': 48.6627982, 'west': 12.5377708}, {
  'zipCode': '94436',
  'north':   48.6148677,
  'east':    12.7786767,
  'south':   48.507351,
  'west':    12.6540644
}, {'zipCode': '94437', 'north': 48.6820777, 'east': 12.654497, 'south': 48.5990778, 'west': 12.5514766}, {
  'zipCode': '94439',
  'north':   48.616932,
  'east':    12.9952543,
  'south':   48.5404663,
  'west':    12.8686473
}, {'zipCode': '94447', 'north': 48.8106871, 'east': 12.9691039, 'south': 48.7528248, 'west': 12.8410358}, {
  'zipCode': '94469',
  'north':   48.9086147,
  'east':    13.0741545,
  'south':   48.7868595,
  'west':    12.873559
}, {'zipCode': '94474', 'north': 48.6806288, 'east': 13.3191086, 'south': 48.5710964, 'west': 13.0913392}, {
  'zipCode': '94481',
  'north':   48.9033248,
  'east':    13.472329,
  'south':   48.7845054,
  'west':    13.3523831
}, {'zipCode': '94486', 'north': 48.7758678, 'east': 13.1010403, 'south': 48.5982267, 'west': 12.9042595}, {
  'zipCode': '94491',
  'north':   48.8253992,
  'east':    13.147073300000002,
  'south':   48.7314889,
  'west':    13.0173594
}, {'zipCode': '94496', 'north': 48.6043311, 'east': 13.2887778, 'south': 48.5086428, 'west': 13.1492954}, {
  'zipCode': '94501',
  'north':   48.6305212,
  'east':    13.1755765,
  'south':   48.5303958,
  'west':    12.9833325
}, {'zipCode': '94505', 'north': 48.9545623, 'east': 12.9523267, 'south': 48.8733167, 'west': 12.854159100000002}, {
  'zipCode': '94508',
  'north':   48.8149763,
  'east':    13.2557701,
  'south':   48.7376934,
  'west':    13.1268002
}, {'zipCode': '94513', 'north': 48.881629100000005, 'east': 13.3713983, 'south': 48.7970214, 'west': 13.2862371}, {
  'zipCode': '94518',
  'north':   48.983159400000005,
  'east':    13.390978000000002,
  'south':   48.869299,
  'west':    13.2788184
}, {'zipCode': '94522', 'north': 48.7993618, 'east': 12.8289463, 'south': 48.6709739, 'west': 12.6728524}, {
  'zipCode': '94526',
  'north':   48.8783725,
  'east':    12.9491457,
  'south':   48.8375179,
  'west':    12.8654519
}, {'zipCode': '94527', 'north': 48.768826, 'east': 12.9380651, 'south': 48.704454000000005, 'west': 12.8431138}, {
  'zipCode': '94529',
  'north':   48.7032982,
  'east':    13.3631421,
  'south':   48.6558562,
  'west':    13.2536075
}, {'zipCode': '94530', 'north': 48.8376392, 'east': 13.1379346, 'south': 48.774812600000004, 'west': 13.0549609}, {
  'zipCode': '94532',
  'north':   48.7615008,
  'east':    13.2592599,
  'south':   48.7014442,
  'west':    13.1845454
}, {'zipCode': '94533', 'north': 48.7277608, 'east': 12.9637701, 'south': 48.6749019, 'west': 12.894655500000002}, {
  'zipCode': '94535',
  'north':   48.741042300000004,
  'east':    13.3064827,
  'south':   48.6811007,
  'west':    13.2011547
}, {'zipCode': '94536', 'north': 48.9224549, 'east': 13.3327122, 'south': 48.8588919, 'west': 13.2778936}, {
  'zipCode': '94538',
  'north':   48.744206,
  'east':    13.3629579,
  'south':   48.6857417,
  'west':    13.2865741
}, {'zipCode': '94539', 'north': 48.9573491, 'east': 13.0392642, 'south': 48.8661731, 'west': 12.942373}, {
  'zipCode': '94541',
  'north':   48.8362986,
  'east':    13.2193051,
  'south':   48.770915,
  'west':    13.1144232
}, {'zipCode': '94542', 'north': 48.5370865, 'east': 13.2023301, 'south': 48.4660014, 'west': 13.0849792}, {
  'zipCode': '94544',
  'north':   48.7066341,
  'east':    13.2302927,
  'south':   48.6508265,
  'west':    13.1098202
}, {'zipCode': '94545', 'north': 48.9150037, 'east': 13.576735, 'south': 48.8093146, 'west': 13.4440129}, {
  'zipCode': '94547',
  'north':   48.7429198,
  'east':    13.2016025,
  'south':   48.6982929,
  'west':    13.108716
}, {'zipCode': '94548', 'north': 48.877811, 'east': 13.3099355, 'south': 48.8153101, 'west': 13.2342116}, {
  'zipCode': '94550',
  'north':   48.6891676,
  'east':    13.1120703,
  'south':   48.6192642,
  'west':    12.9936692
}, {'zipCode': '94551', 'north': 48.8881033, 'east': 13.2078565, 'south': 48.8211503, 'west': 13.0861367}, {
  'zipCode': '94553',
  'north':   48.878922200000005,
  'east':    12.850458,
  'south':   48.8228269,
  'west':    12.7765586
}, {'zipCode': '94554', 'north': 48.8054816, 'east': 13.006400600000001, 'south': 48.7179457, 'west': 12.9299537}, {
  'zipCode': '94556',
  'north':   48.9604711,
  'east':    13.5146862,
  'south':   48.8608563,
  'west':    13.4420061
}, {'zipCode': '94557', 'north': 48.7943011, 'east': 13.0558098, 'south': 48.7420683, 'west': 12.9973291}, {
  'zipCode': '94559',
  'north':   48.9075296,
  'east':    12.8506304,
  'south':   48.8303418,
  'west':    12.7555996
}, {'zipCode': '94560', 'north': 48.8993263, 'east': 12.9187214, 'south': 48.8397509, 'west': 12.8354792}, {
  'zipCode': '94562',
  'north':   48.7322569,
  'east':    12.870361700000002,
  'south':   48.677755,
  'west':    12.7936176
}, {'zipCode': '94563', 'north': 48.7867562, 'east': 12.8639693, 'south': 48.7196445, 'west': 12.7693622}, {
  'zipCode': '94566',
  'north':   48.9203337,
  'east':    13.397433400000002,
  'south':   48.8914535,
  'west':    13.367963000000001
}, {'zipCode': '94568', 'north': 48.9813787, 'east': 13.4615265, 'south': 48.8735482, 'west': 13.3718814}, {
  'zipCode': '94569',
  'north':   48.8423395,
  'east':    12.9090565,
  'south':   48.7800224,
  'west':    12.7501487
}, {'zipCode': '94571', 'north': 48.8820128, 'east': 13.0936855, 'south': 48.8188631, 'west': 13.008859300000001}, {
  'zipCode': '94572',
  'north':   48.8649068,
  'east':    13.2736755,
  'south':   48.81259000000001,
  'west':    13.1680582
}, {'zipCode': '94574', 'north': 48.7173354, 'east': 12.9119139, 'south': 48.6604779, 'west': 12.8434059}, {
  'zipCode': '94575',
  'north':   48.6882963,
  'east':    13.340950200000002,
  'south':   48.6020087,
  'west':    13.2016551
}, {'zipCode': '94577', 'north': 48.746824800000006, 'east': 13.1601626, 'south': 48.68243, 'west': 13.0157848}, {
  'zipCode': '94579',
  'north':   48.8252176,
  'east':    13.2850818,
  'south':   48.7455378,
  'west':    13.211270500000001
}, {'zipCode': '95028', 'north': 50.3494186, 'east': 11.971850700000001, 'south': 50.298855, 'west': 11.9026834}, {
  'zipCode': '95030',
  'north':   50.3551141,
  'east':    11.9181237,
  'south':   50.2845928,
  'west':    11.8066265
}, {'zipCode': '95032', 'north': 50.3116028, 'east': 11.9367221, 'south': 50.2673889, 'west': 11.8640271}, {
  'zipCode': '95100',
  'north':   50.2199473,
  'east':    12.2159697,
  'south':   50.1167345,
  'west':    11.9723624
}, {'zipCode': '95111', 'north': 50.3016915, 'east': 12.1620233, 'south': 50.1900622, 'west': 11.960217200000002}, {
  'zipCode': '95119',
  'north':   50.3724731,
  'east':    11.7420771,
  'south':   50.2842104,
  'west':    11.6256673
}, {'zipCode': '95126', 'north': 50.2456005, 'east': 12.0093589, 'south': 50.1608398, 'west': 11.8420828}, {
  'zipCode': '95131',
  'north':   50.33593450000001,
  'east':    11.6877049,
  'south':   50.2553371,
  'west':    11.5367533
}, {'zipCode': '95138', 'north': 50.398785, 'east': 11.6817453, 'south': 50.3167986, 'west': 11.5966337}, {
  'zipCode': '95145',
  'north':   50.2822614,
  'east':    11.9800022,
  'south':   50.2325041,
  'west':    11.8787864
}, {'zipCode': '95152', 'north': 50.3567043, 'east': 11.8023545, 'south': 50.2859943, 'west': 11.7070577}, {
  'zipCode': '95158',
  'north':   50.2031142,
  'east':    12.004635600000002,
  'south':   50.1067603,
  'west':    11.8850539
}, {'zipCode': '95163', 'north': 50.1438667, 'east': 11.9475063, 'south': 50.0599006, 'west': 11.8121584}, {
  'zipCode': '95168',
  'north':   50.1615085,
  'east':    12.0757752,
  'south':   50.0880288,
  'west':    11.9455217
}, {'zipCode': '95173', 'north': 50.2275484, 'east': 12.1389656, 'south': 50.1710784, 'west': 12.0438539}, {
  'zipCode': '95176',
  'north':   50.3001874,
  'east':    11.8996475,
  'south':   50.2128228,
  'west':    11.7801486
}, {'zipCode': '95179', 'north': 50.3856911, 'east': 11.6240721, 'south': 50.3000756, 'west': 11.5287509}, {
  'zipCode': '95180',
  'north':   50.4188269,
  'east':    11.8315599,
  'south':   50.3372728,
  'west':    11.7338892
}, {'zipCode': '95182', 'north': 50.3094113, 'east': 12.0021582, 'south': 50.2651801, 'west': 11.9295425}, {
  'zipCode': '95183',
  'north':   50.4247582,
  'east':    11.9847813,
  'south':   50.3380533,
  'west':    11.833639
}, {'zipCode': '95185', 'north': 50.3540604, 'east': 12.0324135, 'south': 50.2981196, 'west': 11.9507985}, {
  'zipCode': '95186',
  'north':   50.1211474,
  'east':    12.116207200000002,
  'south':   50.0767724,
  'west':    12.0315243
}, {'zipCode': '95188', 'north': 50.4061333, 'east': 11.7549098, 'south': 50.3453151, 'west': 11.6889968}, {
  'zipCode': '95189',
  'north':   50.3960971,
  'east':    11.8820442,
  'south':   50.314872,
  'west':    11.7896624
}, {'zipCode': '95191', 'north': 50.3187098, 'east': 11.8275396, 'south': 50.2662732, 'west': 11.7772245}, {
  'zipCode': '95192',
  'north':   50.3957168,
  'east':    11.6944828,
  'south':   50.3565769,
  'west':    11.6381366
}, {'zipCode': '95194', 'north': 50.3360935, 'east': 12.1262693, 'south': 50.2619722, 'west': 11.9753443}, {
  'zipCode': '95195',
  'north':   50.1117226,
  'east':    12.045866,
  'south':   50.0556759,
  'west':    11.934722
}, {'zipCode': '95197', 'north': 50.2988017, 'east': 11.7902448, 'south': 50.2486524, 'west': 11.677939000000002}, {
  'zipCode': '95199',
  'north':   50.14493840000001,
  'east':    12.166894,
  'south':   50.0955334,
  'west':    12.0577586
}, {'zipCode': '95213', 'north': 50.2411263, 'east': 11.8471663, 'south': 50.14760350000001, 'west': 11.6683501}, {
  'zipCode': '95233',
  'north':   50.2784464,
  'east':    11.805653,
  'south':   50.2014666,
  'west':    11.5985128
}, {'zipCode': '95234', 'north': 50.1755576, 'east': 11.8864335, 'south': 50.130852, 'west': 11.8072239}, {
  'zipCode': '95236',
  'north':   50.181062,
  'east':    11.763352,
  'south':   50.115247,
  'west':    11.6422489
}, {'zipCode': '95237', 'north': 50.21323910000001, 'east': 11.9113728, 'south': 50.1453802, 'west': 11.8156933}, {
  'zipCode': '95239',
  'north':   50.167552,
  'east':    11.8662202,
  'south':   50.1033593,
  'west':    11.7404532
}, {'zipCode': '95326', 'north': 50.19279170000001, 'east': 11.5047981, 'south': 50.0545551, 'west': 11.3605048}, {
  'zipCode': '95336',
  'north':   50.1665296,
  'east':    11.4084268,
  'south':   50.0555097,
  'west':    11.2968787
}, {'zipCode': '95339', 'north': 50.1440785, 'east': 11.647763700000002, 'south': 50.0584506, 'west': 11.5276707}, {
  'zipCode': '95346',
  'north':   50.2185974,
  'east':    11.5742989,
  'south':   50.1415703,
  'west':    11.4584197
}, {'zipCode': '95349', 'north': 50.0659085, 'east': 11.4669185, 'south': 49.933736800000005, 'west': 11.3272486}, {
  'zipCode': '95352',
  'north':   50.204372,
  'east':    11.6895734,
  'south':   50.1353454,
  'west':    11.5882388
}, {'zipCode': '95355', 'north': 50.268932, 'east': 11.629361900000001, 'south': 50.1864858, 'west': 11.4558436}, {
  'zipCode': '95356',
  'north':   50.2318863,
  'east':    11.6596647,
  'south':   50.1731336,
  'west':    11.566532
}, {'zipCode': '95358', 'north': 50.18507970000001, 'east': 11.6068674, 'south': 50.1382717, 'west': 11.5405745}, {
  'zipCode': '95359',
  'north':   50.0739777,
  'east':    11.4145528,
  'south':   50.007042000000006,
  'west':    11.2754973
}, {'zipCode': '95361', 'north': 50.1339899, 'east': 11.5444341, 'south': 50.0674009, 'west': 11.4781884}, {
  'zipCode': '95362',
  'north':   50.1661088,
  'east':    11.619068,
  'south':   50.12178190000001,
  'west':    11.5535678
}, {'zipCode': '95364', 'north': 50.1363999, 'east': 11.5889035, 'south': 50.110547, 'west': 11.5391221}, {
  'zipCode': '95365',
  'north':   50.2255899,
  'east':    11.4907241,
  'south':   50.1729434,
  'west':    11.4129986
}, {'zipCode': '95367', 'north': 50.0923266, 'east': 11.5731781, 'south': 50.0460232, 'west': 11.481529000000002}, {
  'zipCode': '95369',
  'north':   50.1507009,
  'east':    11.5552671,
  'south':   50.1137813,
  'west':    11.4730918
}, {'zipCode': '95444', 'north': 49.9566302, 'east': 11.5922865, 'south': 49.9317892, 'west': 11.5575336}, {
  'zipCode': '95445',
  'north':   49.9772589,
  'east':    11.600443100000001,
  'south':   49.9356518,
  'west':    11.4942753
}, {'zipCode': '95447', 'north': 49.9415903, 'east': 11.5932815, 'south': 49.9051018, 'west': 11.5201828}, {
  'zipCode': '95448',
  'north':   49.9729687,
  'east':    11.6728262,
  'south':   49.8881942,
  'west':    11.553792700000002
}, {'zipCode': '95460', 'north': 50.088617, 'east': 11.7328751, 'south': 50.017577, 'west': 11.6014191}, {
  'zipCode': '95463',
  'north':   50.03104020000001,
  'east':    11.6562682,
  'south':   49.9668163,
  'west':    11.5337394
}, {'zipCode': '95466', 'north': 49.9900021, 'east': 11.839404, 'south': 49.8894378, 'west': 11.628293}, {
  'zipCode': '95469',
  'north':   49.9025899,
  'east':    11.8619394,
  'south':   49.8348184,
  'west':    11.707497100000001
}, {'zipCode': '95473', 'north': 49.9046116, 'east': 11.739178, 'south': 49.8020551, 'west': 11.4995391}, {
  'zipCode': '95478',
  'north':   49.8926597,
  'east':    12.0229656,
  'south':   49.8126076,
  'west':    11.8390493
}, {'zipCode': '95482', 'north': 50.1339305, 'east': 11.8183991, 'south': 50.0497019, 'west': 11.6745731}, {
  'zipCode': '95485',
  'north':   50.0325914,
  'east':    11.8284625,
  'south':   49.9618845,
  'west':    11.7425635
}, {'zipCode': '95488', 'north': 49.9958671, 'east': 11.5304116, 'south': 49.9190504, 'west': 11.3825093}, {
  'zipCode': '95490',
  'north':   49.9362788,
  'east':    11.4899312,
  'south':   49.8737864,
  'west':    11.3533991
}, {'zipCode': '95491', 'north': 49.88999750000001, 'east': 11.5637174, 'south': 49.8025324, 'west': 11.342689}, {
  'zipCode': '95493',
  'north':   50.1070037,
  'east':    11.8766791,
  'south':   50.0215034,
  'west':    11.7229882
}, {'zipCode': '95494', 'north': 49.9226542, 'east': 11.5679494, 'south': 49.8735636, 'west': 11.5151533}, {
  'zipCode': '95496',
  'north':   49.9024775,
  'east':    11.4736699,
  'south':   49.8678397,
  'west':    11.4243207
}, {'zipCode': '95497', 'north': 50.0449321, 'east': 11.7656356, 'south': 49.970966, 'west': 11.6408891}, {
  'zipCode': '95499',
  'north':   50.0581009,
  'east':    11.6043815,
  'south':   50.0115756,
  'west':    11.5417292
}, {'zipCode': '95500', 'north': 50.0058619, 'east': 11.565941, 'south': 49.9560165, 'west': 11.4867022}, {
  'zipCode': '95502',
  'north':   50.08759,
  'east':    11.6528447,
  'south':   50.0259549,
  'west':    11.5683013
}, {'zipCode': '95503', 'north': 49.904695, 'east': 11.5296971, 'south': 49.8331197, 'west': 11.4625211}, {
  'zipCode': '95505',
  'north':   49.9638095,
  'east':    11.8886261,
  'south':   49.88716060000001,
  'west':    11.8033956
}, {'zipCode': '95506', 'north': 49.8527369, 'east': 11.970485800000002, 'south': 49.7996743, 'west': 11.85859}, {
  'zipCode': '95508',
  'north':   49.9611322,
  'east':    11.9711178,
  'south':   49.88079200000001,
  'west':    11.8505895
}, {'zipCode': '95509', 'north': 50.125046, 'east': 11.6879376, 'south': 50.0689691, 'west': 11.616199}, {
  'zipCode': '95511',
  'north':   49.9240408,
  'east':    11.5371834,
  'south':   49.8999111,
  'west':    11.4828195
}, {'zipCode': '95512', 'north': 50.0624441, 'east': 11.581548, 'south': 49.9825135, 'west': 11.4357475}, {
  'zipCode': '95514',
  'north':   49.8521597,
  'east':    11.8863664,
  'south':   49.801887,
  'west':    11.7879335
}, {'zipCode': '95515', 'north': 49.9148711, 'east': 11.3842682, 'south': 49.8731056, 'west': 11.2931944}, {
  'zipCode': '95517',
  'north':   49.9259988,
  'east':    11.753062,
  'south':   49.85962850000001,
  'west':    11.6181317
}, {'zipCode': '95519', 'north': 49.8348616, 'east': 11.8072793, 'south': 49.7617263, 'west': 11.6928097}, {
  'zipCode': '95615',
  'north':   50.04636,
  'east':    12.175950900000002,
  'south':   49.9757079,
  'west':    12.030089100000001
}, {'zipCode': '95632', 'north': 50.0872489, 'east': 12.0923836, 'south': 49.9802842, 'west': 11.9282347}, {
  'zipCode': '95643',
  'north':   49.9546368,
  'east':    12.4505155,
  'south':   49.8228475,
  'west':    12.2479281
}, {'zipCode': '95652', 'north': 50.0613533, 'east': 12.4240674, 'south': 49.9781131, 'west': 12.2322628}, {
  'zipCode': '95659',
  'north':   50.0809857,
  'east':    12.244241100000002,
  'south':   49.9975037,
  'west':    12.1243871
}, {'zipCode': '95666', 'north': 49.9969493, 'east': 12.363645, 'south': 49.8850156, 'west': 12.185233300000002}, {
  'zipCode': '95671',
  'north':   49.8587376,
  'east':    12.4863338,
  'south':   49.7537349,
  'west':    12.3467446
}, {'zipCode': '95676', 'north': 49.9518375, 'east': 12.251755, 'south': 49.8639894, 'west': 12.1329578}, {
  'zipCode': '95679',
  'north':   49.9953096,
  'east':    12.1435517,
  'south':   49.8941878,
  'west':    11.968536300000002
}, {'zipCode': '95680', 'north': 50.0304076, 'east': 12.0620951, 'south': 49.9917116, 'west': 12.0042715}, {
  'zipCode': '95682',
  'north':   49.9791815,
  'east':    11.932469300000001,
  'south':   49.9435401,
  'west':    11.866695
}, {'zipCode': '95683', 'north': 49.9795544, 'east': 11.9713079, 'south': 49.9238288, 'west': 11.9035258}, {
  'zipCode': '95685',
  'north':   49.8919255,
  'east':    12.2841186,
  'south':   49.8145356,
  'west':    12.1690648
}, {'zipCode': '95686', 'north': 50.0272229, 'east': 11.9020218, 'south': 49.9650413, 'west': 11.7935107}, {
  'zipCode': '95688',
  'north':   49.911945200000005,
  'east':    12.1378298,
  'south':   49.8677182,
  'west':    12.0570096
}, {'zipCode': '95689', 'north': 49.94705530000001, 'east': 12.1620616, 'south': 49.89294290000001, 'west': 12.1028515}, {
  'zipCode': '95691',
  'north':   50.128092,
  'east':    12.2338115,
  'south':   50.0798957,
  'west':    12.144509000000001
}, {'zipCode': '95692', 'north': 50.0383475, 'east': 12.2678703, 'south': 49.9772144, 'west': 12.1866895}, {
  'zipCode': '95694',
  'north':   49.9987901,
  'east':    11.9044974,
  'south':   49.9585196,
  'west':    11.8284194
}, {'zipCode': '95695', 'north': 49.9381287, 'east': 12.5512077, 'south': 49.8375246, 'west': 12.3890564}, {
  'zipCode': '95697',
  'north':   49.9986598,
  'east':    11.9738416,
  'south':   49.9706978,
  'west':    11.8995895
}, {'zipCode': '95698', 'north': 50.0025104, 'east': 12.4995578, 'south': 49.932156, 'west': 12.3399522}, {
  'zipCode': '95700',
  'north':   49.9753432,
  'east':    11.9990234,
  'south':   49.9057277,
  'west':    11.939119600000001
}, {'zipCode': '95701', 'north': 50.00082330000001, 'east': 12.2127587, 'south': 49.943525400000006, 'west': 12.115401}, {
  'zipCode': '95703',
  'north':   49.8559947,
  'east':    12.3778032,
  'south':   49.7584203,
  'west':    12.1989803
}, {'zipCode': '95704', 'north': 49.967367, 'east': 12.0525591, 'south': 49.8655029, 'west': 11.9547734}, {
  'zipCode': '95706',
  'north':   50.0975423,
  'east':    12.2754108,
  'south':   50.0382566,
  'west':    12.1796249
}, {'zipCode': '95707', 'north': 50.101184200000006, 'east': 12.1768149, 'south': 50.0403467, 'west': 12.0796457}, {
  'zipCode': '95709',
  'north':   50.0691182,
  'east':    11.9741705,
  'south':   49.9845509,
  'west':    11.8577237
}, {'zipCode': '96047', 'north': 49.90722040000001, 'east': 10.9062853, 'south': 49.8701841, 'west': 10.8597461}, {
  'zipCode': '96049',
  'north':   49.9073588,
  'east':    10.9308636,
  'south':   49.842646300000006,
  'west':    10.8265043
}, {'zipCode': '96050', 'north': 49.908641, 'east': 10.9607049, 'south': 49.8489115, 'west': 10.8942481}, {
  'zipCode': '96052',
  'north':   49.9286327,
  'east':    10.9443988,
  'south':   49.8949327,
  'west':    10.8306288
}, {'zipCode': '96103', 'north': 49.9496357, 'east': 10.9127615, 'south': 49.9118999, 'west': 10.8308292}, {
  'zipCode': '96106',
  'north':   50.1386227,
  'east':    10.8368231,
  'south':   50.0463188,
  'west':    10.5950248
}, {'zipCode': '96110', 'north': 50.0380149, 'east': 11.1261054, 'south': 49.9257911, 'west': 10.9584366}, {
  'zipCode': '96114',
  'north':   49.8498663,
  'east':    11.0550645,
  'south':   49.7676918,
  'west':    10.9052374
}, {'zipCode': '96117', 'north': 49.9807467, 'east': 11.0379843, 'south': 49.9174913, 'west': 10.9221343}, {
  'zipCode': '96120',
  'north':   49.92389800000001,
  'east':    10.847533100000001,
  'south':   49.8784391,
  'west':    10.7559318
}, {'zipCode': '96123', 'north': 49.934473000000004, 'east': 11.1065502, 'south': 49.8931359, 'west': 10.9729312}, {
  'zipCode': '96126',
  'north':   50.2314305,
  'east':    10.7818848,
  'south':   50.1532507,
  'west':    10.5806946
}, {'zipCode': '96129', 'north': 49.923994, 'east': 11.0895792, 'south': 49.8284274, 'west': 10.9284487}, {
  'zipCode': '96132',
  'north':   49.7998163,
  'east':    10.7212229,
  'south':   49.721094,
  'west':    10.5247679
}, {'zipCode': '96135', 'north': 49.8873687, 'east': 10.9145479, 'south': 49.8370958, 'west': 10.7856232}, {
  'zipCode': '96138',
  'north':   49.8724662,
  'east':    10.8300129,
  'south':   49.7723374,
  'west':    10.6152139
}, {'zipCode': '96142', 'north': 50.00081990000001, 'east': 11.3768162, 'south': 49.8863789, 'west': 11.1934478}, {
  'zipCode': '96145',
  'north':   50.2517977,
  'east':    10.8941042,
  'south':   50.1412019,
  'west':    10.7259213
}, {'zipCode': '96146', 'north': 49.8136041, 'east': 11.0488414, 'south': 49.781319800000006, 'west': 10.9695044}, {
  'zipCode': '96148',
  'north':   50.0246121,
  'east':    10.8814728,
  'south':   49.9646279,
  'west':    10.7498182
}, {'zipCode': '96149', 'north': 49.9938859, 'east': 10.9853163, 'south': 49.9416231, 'west': 10.8642473}, {
  'zipCode': '96151',
  'north':   50.0330262,
  'east':    10.7532129,
  'south':   50.0044092,
  'west':    10.6749091
}, {'zipCode': '96152', 'north': 49.7565588, 'east': 10.6543186, 'south': 49.7042005, 'west': 10.4778962}, {
  'zipCode': '96154',
  'north':   49.8755187,
  'east':    10.6441847,
  'south':   49.7927879,
  'west':    10.5157937
}, {'zipCode': '96155', 'north': 49.8612649, 'east': 11.1058672, 'south': 49.7925077, 'west': 11.0138798}, {
  'zipCode': '96157',
  'north':   49.8818454,
  'east':    10.5829382,
  'south':   49.785348,
  'west':    10.4401106
}, {'zipCode': '96158', 'north': 49.8476388, 'east': 10.9334655, 'south': 49.7707985, 'west': 10.8094735}, {
  'zipCode': '96160',
  'north':   49.823261,
  'east':    10.5552603,
  'south':   49.7405372,
  'west':    10.4197681
}, {'zipCode': '96161', 'north': 50.0473054, 'east': 10.8209816, 'south': 50.01687460000001, 'west': 10.7680097}, {
  'zipCode': '96163',
  'north':   49.9481515,
  'east':    10.9401544,
  'south':   49.924983,
  'west':    10.9046405
}, {'zipCode': '96164', 'north': 49.9747354, 'east': 10.8951256, 'south': 49.9401524, 'west': 10.8396842}, {
  'zipCode': '96166',
  'north':   50.0673994,
  'east':    10.750593,
  'south':   50.0156365,
  'west':    10.6597077
}, {'zipCode': '96167', 'north': 49.978651, 'east': 11.2191066, 'south': 49.9064229, 'west': 11.0918442}, {
  'zipCode': '96169',
  'north':   50.00047730000001,
  'east':    10.8128772,
  'south':   49.9582354,
  'west':    10.7406475
}, {'zipCode': '96170', 'north': 49.9250624, 'east': 10.758643000000001, 'south': 49.8677201, 'west': 10.685239}, {
  'zipCode': '96172',
  'north':   49.7882496,
  'east':    10.8033642,
  'south':   49.7312411,
  'west':    10.7133205
}, {'zipCode': '96173', 'north': 49.9748241, 'east': 10.855845, 'south': 49.9147667, 'west': 10.7211195}, {
  'zipCode': '96175',
  'north':   49.8500319,
  'east':    10.9632794,
  'south':   49.8154792,
  'west':    10.8985204
}, {'zipCode': '96176', 'north': 50.1751256, 'east': 10.8043088, 'south': 50.1238779, 'west': 10.6776976}, {
  'zipCode': '96178',
  'north':   49.8035725,
  'east':    10.8787215,
  'south':   49.7378205,
  'west':    10.7564084
}, {'zipCode': '96179', 'north': 50.0954074, 'east': 10.9313277, 'south': 49.98202070000001, 'west': 10.8319936}, {
  'zipCode': '96181',
  'north':   49.9329537,
  'east':    10.663069700000001,
  'south':   49.8553036,
  'west':    10.4578003
}, {'zipCode': '96182', 'north': 50.0518023, 'east': 10.8604562, 'south': 50.0087577, 'west': 10.7843498}, {
  'zipCode': '96184',
  'north':   50.0864159,
  'east':    10.8422729,
  'south':   50.02036210000001,
  'west':    10.7341197
}, {'zipCode': '96185', 'north': 49.8911383, 'east': 10.7349815, 'south': 49.8402694, 'west': 10.6050094}, {
  'zipCode': '96187',
  'north':   50.025591,
  'east':    11.2526562,
  'south':   49.9550982,
  'west':    11.1067095
}, {'zipCode': '96188', 'north': 49.9927294, 'east': 10.761419, 'south': 49.9537533, 'west': 10.6980837}, {
  'zipCode': '96190',
  'north':   50.1576095,
  'east':    10.8805979,
  'south':   50.0729447,
  'west':    10.7946294
}, {'zipCode': '96191', 'north': 49.9413265, 'east': 10.8019008, 'south': 49.9032944, 'west': 10.7129107}, {
  'zipCode': '96193',
  'north':   49.7801397,
  'east':    10.7667968,
  'south':   49.7170985,
  'west':    10.6690764
}, {'zipCode': '96194', 'north': 49.8998464, 'east': 10.8092651, 'south': 49.8520808, 'west': 10.7366133}, {
  'zipCode': '96196',
  'north':   50.0581006,
  'east':    11.1976752,
  'south':   49.9929511,
  'west':    11.090819400000003
}, {'zipCode': '96197', 'north': 50.0234338, 'east': 11.3705293, 'south': 49.9433432, 'west': 11.2499971}, {
  'zipCode': '96199',
  'north':   50.050108,
  'east':    11.0082237,
  'south':   49.9800976,
  'west':    10.9086167
}, {'zipCode': '96215', 'north': 50.219592, 'east': 11.1899069, 'south': 50.0434665, 'west': 10.9751563}, {
  'zipCode': '96224',
  'north':   50.1745921,
  'east':    11.3636081,
  'south':   50.1106482,
  'west':    11.2023358
}, {'zipCode': '96231', 'north': 50.1745888, 'east': 11.1260159, 'south': 50.0346705, 'west': 10.9154333}, {
  'zipCode': '96237',
  'north':   50.267441100000006,
  'east':    11.1180137,
  'south':   50.20431670000001,
  'west':    11.0304873
}, {'zipCode': '96242', 'north': 50.27055970000001, 'east': 11.2028232, 'south': 50.2050703, 'west': 11.0981186}, {
  'zipCode': '96247',
  'north':   50.2105812,
  'east':    11.1788354,
  'south':   50.1531109,
  'west':    11.0821127
}, {'zipCode': '96250', 'north': 50.1192672, 'east': 11.0623907, 'south': 50.015861, 'west': 10.8747346}, {
  'zipCode': '96253',
  'north':   50.2270726,
  'east':    11.0110422,
  'south':   50.1732284,
  'west':    10.9141649
}, {'zipCode': '96257', 'north': 50.2047766, 'east': 11.2539526, 'south': 50.1523934, 'west': 11.1698661}, {
  'zipCode': '96260',
  'north':   50.1099946,
  'east':    11.3243023,
  'south':   49.9895473,
  'west':    11.144326000000001
}, {'zipCode': '96264', 'north': 50.1450842, 'east': 11.321031, 'south': 50.0876942, 'west': 11.1760644}, {
  'zipCode': '96268',
  'north':   50.2917606,
  'east':    11.279139700000002,
  'south':   50.2114576,
  'west':    11.1777633
}, {'zipCode': '96269', 'north': 50.2059997, 'east': 10.9809789, 'south': 50.1504186, 'west': 10.8699163}, {
  'zipCode': '96271',
  'north':   50.2514725,
  'east':    11.0498463,
  'south':   50.212667,
  'west':    10.9941407
}, {'zipCode': '96272', 'north': 50.1601818, 'east': 11.2094313, 'south': 50.1135591, 'west': 11.1310125}, {
  'zipCode': '96274',
  'north':   50.1763594,
  'east':    10.948551,
  'south':   50.0882356,
  'west':    10.8490604
}, {'zipCode': '96275', 'north': 50.1810107, 'east': 11.2134539, 'south': 50.1501703, 'west': 11.1498785}, {
  'zipCode': '96277',
  'north':   50.2282905,
  'east':    11.2403215,
  'south':   50.1995104,
  'west':    11.1684227
}, {'zipCode': '96279', 'north': 50.2140609, 'east': 11.1986313, 'south': 50.1850934, 'west': 11.102589}, {
  'zipCode': '96317',
  'north':   50.3032399,
  'east':    11.4176765,
  'south':   50.1934297,
  'west':    11.2647047
}, {'zipCode': '96328', 'north': 50.234516, 'east': 11.3486469, 'south': 50.1591389, 'west': 11.2242887}, {
  'zipCode': '96332',
  'north':   50.4127342,
  'east':    11.3735402,
  'south':   50.3052308,
  'west':    11.262286500000002
}, {'zipCode': '96337', 'north': 50.5232047, 'east': 11.4410633, 'south': 50.4361633, 'west': 11.2980416}, {
  'zipCode': '96342',
  'north':   50.3411902,
  'east':    11.335320600000001,
  'south':   50.2683603,
  'west':    11.2513631
}, {'zipCode': '96346', 'north': 50.3251443, 'east': 11.5661994, 'south': 50.2331221, 'west': 11.433287700000001}, {
  'zipCode': '96349',
  'north':   50.3828512,
  'east':    11.5411742,
  'south':   50.2783325,
  'west':    11.3964081
}, {'zipCode': '96352', 'north': 50.389751, 'east': 11.436433, 'south': 50.2572658, 'west': 11.3307928}, {
  'zipCode': '96355',
  'north':   50.4855228,
  'east':    11.3190016,
  'south':   50.3942986,
  'west':    11.2450259
}, {'zipCode': '96358', 'north': 50.4434184, 'east': 11.4514864, 'south': 50.3458448, 'west': 11.3538028}, {
  'zipCode': '96361',
  'north':   50.4683239,
  'east':    11.3899609,
  'south':   50.3925032,
  'west':    11.2788163
}, {'zipCode': '96364', 'north': 50.2841602, 'east': 11.4625598, 'south': 50.2014435, 'west': 11.3479952}, {
  'zipCode': '96365',
  'north':   50.4027836,
  'east':    11.6105626,
  'south':   50.3216293,
  'west':    11.462199
}, {'zipCode': '96367', 'north': 50.4335806, 'east': 11.4923067, 'south': 50.3547125, 'west': 11.4317614}, {
  'zipCode': '96369',
  'north':   50.2187823,
  'east':    11.434532000000003,
  'south':   50.1633882,
  'west':    11.2947235
}, {'zipCode': '96450', 'north': 50.29891260000001, 'east': 11.0637628, 'south': 50.2217501, 'west': 10.8872646}, {
  'zipCode': '96465',
  'north':   50.3675639,
  'east':    11.1838519,
  'south':   50.2527801,
  'west':    11.0382445
}, {'zipCode': '96472', 'north': 50.3657638, 'east': 11.0989146, 'south': 50.260379500000006, 'west': 10.9858157}, {
  'zipCode': '96476',
  'north':   50.392712200000005,
  'east':    10.8647711,
  'south':   50.273078,
  'west':    10.7123208
}, {'zipCode': '96479', 'north': 50.291378, 'east': 10.9216226, 'south': 50.2081142, 'west': 10.8252278}, {
  'zipCode': '96482',
  'north':   50.2450809,
  'east':    10.9796678,
  'south':   50.19858,
  'west':    10.854948
}, {'zipCode': '96484', 'north': 50.3967156, 'east': 10.9674266, 'south': 50.2817707, 'west': 10.8152889}, {
  'zipCode': '96486',
  'north':   50.3926022,
  'east':    11.0024135,
  'south':   50.2905242,
  'west':    10.8998225
}, {'zipCode': '96487', 'north': 50.2996855, 'east': 11.015443, 'south': 50.2756132, 'west': 10.9808468}, {
  'zipCode': '96489',
  'north':   50.2330126,
  'east':    11.0046034,
  'south':   50.2104087,
  'west':    10.9751048
}, {'zipCode': '96515', 'north': 50.5075066, 'east': 11.2847864, 'south': 50.3208602, 'west': 11.1140478}, {
  'zipCode': '96523',
  'north':   50.4754307,
  'east':    11.2028205,
  'south':   50.3992347,
  'west':    11.1086067
}, {'zipCode': '96524', 'north': 50.4577883, 'east': 11.2841372, 'south': 50.266772, 'west': 11.1304997}, {
  'zipCode': '96528',
  'north':   50.4429332,
  'east':    11.1548948,
  'south':   50.3447614,
  'west':    10.9394039
}, {'zipCode': '97070', 'north': 49.8028499, 'east': 9.952757200000002, 'south': 49.7850944, 'west': 9.9234179}, {
  'zipCode': '97072',
  'north':   49.7968793,
  'east':    9.9507856,
  'south':   49.77142010000001,
  'west':    9.9270494
}, {'zipCode': '97074', 'north': 49.79756, 'east': 10.0008562, 'south': 49.7653478, 'west': 9.9399647}, {
  'zipCode': '97076',
  'north':   49.8243931,
  'east':    10.0144286,
  'south':   49.7887497,
  'west':    9.9503497
}, {'zipCode': '97078', 'north': 49.83976220000001, 'east': 9.9905435, 'south': 49.8009103, 'west': 9.9327238}, {
  'zipCode': '97080',
  'north':   49.8454619,
  'east':    9.9612421,
  'south':   49.7963181,
  'west':    9.8723224
}, {'zipCode': '97082', 'north': 49.8039407, 'east': 9.9399647, 'south': 49.7392167, 'west': 9.8716285}, {
  'zipCode': '97084',
  'north':   49.77142010000001,
  'east':    9.9857216,
  'south':   49.7106843,
  'west':    9.9212077
}, {'zipCode': '97199', 'north': 49.7249231, 'east': 10.114588, 'south': 49.5924994, 'west': 9.9784599}, {
  'zipCode': '97204',
  'north':   49.7945138,
  'east':    9.9121419,
  'south':   49.7565214,
  'west':    9.841411
}, {'zipCode': '97209', 'north': 49.8626548, 'east': 9.920898, 'south': 49.8201844, 'west': 9.8650806}, {
  'zipCode': '97215',
  'north':   49.6185998,
  'east':    10.3310846,
  'south':   49.4708293,
  'west':    10.0772949
}, {'zipCode': '97218', 'north': 49.7901882, 'east': 10.0329598, 'south': 49.7713891, 'west': 9.975028}, {
  'zipCode': '97222',
  'north':   49.94871,
  'east':    10.0013995,
  'south':   49.833288,
  'west':    9.9120822
}, {'zipCode': '97225', 'north': 49.9240432, 'east': 9.859741000000001, 'south': 49.87028, 'west': 9.6994548}, {
  'zipCode': '97228',
  'north':   49.8255371,
  'east':    10.0656738,
  'south':   49.7723414,
  'west':    10.0000008
}, {'zipCode': '97230', 'north': 49.873837, 'east': 10.0511236, 'south': 49.8034557, 'west': 9.9854363}, {
  'zipCode': '97232',
  'north':   49.69373790000001,
  'east':    9.9884523,
  'south':   49.5952599,
  'west':    9.877174
}, {'zipCode': '97234', 'north': 49.7690267, 'east': 9.9927447, 'south': 49.6806504, 'west': 9.844958}, {
  'zipCode': '97236',
  'north':   49.776009,
  'east':    10.0561958,
  'south':   49.7235181,
  'west':    9.9531703
}, {'zipCode': '97237', 'north': 49.74976, 'east': 9.7993418, 'south': 49.68773680000001, 'west': 9.71049}, {
  'zipCode': '97239',
  'north':   49.5697255,
  'east':    10.0845243,
  'south':   49.5208213,
  'west':    10.0068821
}, {'zipCode': '97241', 'north': 49.9319411, 'east': 10.1542489, 'south': 49.8518342, 'west': 10.0322483}, {
  'zipCode': '97243',
  'north':   49.5282293,
  'east':    10.0595249,
  'south':   49.4809886,
  'west':    9.9734608
}, {'zipCode': '97244', 'north': 49.6295004, 'east': 9.9467415, 'south': 49.5453043, 'west': 9.8100603}, {
  'zipCode': '97246',
  'north':   49.7385524,
  'east':    10.0368097,
  'south':   49.7084091,
  'west':    9.9844154
}, {'zipCode': '97247', 'north': 49.9135426, 'east': 10.1893862, 'south': 49.8575253, 'west': 10.1395471}, {
  'zipCode': '97249',
  'north':   49.7764349,
  'east':    9.852965,
  'south':   49.7461343,
  'west':    9.8067348
}, {'zipCode': '97250', 'north': 49.8763327, 'east': 9.8598705, 'south': 49.8445743, 'west': 9.8266815}, {
  'zipCode': '97252',
  'north':   49.70524100000001,
  'east':    10.1339148,
  'south':   49.65704530000001,
  'west':    10.0612014
}, {'zipCode': '97253', 'north': 49.6654441, 'east': 10.0506871, 'south': 49.5989332, 'west': 9.9536979}, {
  'zipCode': '97255',
  'north':   49.6055444,
  'east':    10.0753626,
  'south':   49.5467818,
  'west':    9.941011
}, {'zipCode': '97256', 'north': 49.6972348, 'east': 9.921342, 'south': 49.6647285, 'west': 9.8542757}, {
  'zipCode': '97258',
  'north':   49.6328819,
  'east':    10.3033779,
  'south':   49.5331834,
  'west':    10.0695475
}, {'zipCode': '97259', 'north': 49.8427101, 'east': 9.8064553, 'south': 49.8018858, 'west': 9.7239491}, {
  'zipCode': '97261',
  'north':   49.8939563,
  'east':    9.9430482,
  'south':   49.8468713,
  'west':    9.8614749
}, {'zipCode': '97262', 'north': 49.9535198, 'east': 10.0703659, 'south': 49.897091, 'west': 9.9857867}, {
  'zipCode': '97264',
  'north':   49.7770134,
  'east':    9.7649982,
  'south':   49.7370313,
  'west':    9.6375221
}, {'zipCode': '97265', 'north': 49.8268889, 'east': 9.8567472, 'south': 49.7762817, 'west': 9.7836227}, {
  'zipCode': '97267',
  'north':   49.9433793,
  'east':    9.8296917,
  'south':   49.9074821,
  'west':    9.737699
}, {'zipCode': '97268', 'north': 49.6844608, 'east': 9.906277, 'south': 49.6255544, 'west': 9.8217575}, {
  'zipCode': '97270',
  'north':   49.7580298,
  'east':    9.8538299,
  'south':   49.7086653,
  'west':    9.7943674
}, {'zipCode': '97271', 'north': 49.7347374, 'east': 9.865428, 'south': 49.6793944, 'west': 9.8218027}, {
  'zipCode': '97273',
  'north':   49.8556729,
  'east':    10.0877173,
  'south':   49.822983,
  'west':    10.0071543
}, {'zipCode': '97274', 'north': 49.8812444, 'east': 9.8354594, 'south': 49.8234915, 'west': 9.7441354}, {
  'zipCode': '97276',
  'north':   49.8522973,
  'east':    9.8708171,
  'south':   49.82291240000001,
  'west':    9.8288705
}, {'zipCode': '97277', 'north': 49.7540163, 'east': 9.7162458, 'south': 49.6870997, 'west': 9.6254973}, {
  'zipCode': '97279',
  'north':   49.8922182,
  'east':    10.1652799,
  'south':   49.8358709,
  'west':    10.0823761
}, {'zipCode': '97280', 'north': 49.83861470000001, 'east': 9.731319, 'south': 49.7841101, 'west': 9.6501238}, {
  'zipCode': '97282',
  'north':   49.9337231,
  'east':    9.9278256,
  'south':   49.8881993,
  'west':    9.8459301
}, {'zipCode': '97283', 'north': 49.5831899, 'east': 10.0083963, 'south': 49.5353976, 'west': 9.8980363}, {
  'zipCode': '97285',
  'north':   49.5464208,
  'east':    10.0195355,
  'south':   49.4804744,
  'west':    9.9136735
}, {'zipCode': '97286', 'north': 49.7368079, 'east': 10.064441, 'south': 49.6804432, 'west': 9.973041}, {
  'zipCode': '97288',
  'north':   49.7769093,
  'east':    10.0614817,
  'south':   49.7404913,
  'west':    10.0040367
}, {'zipCode': '97289', 'north': 49.9650514, 'east': 9.8991075, 'south': 49.9189288, 'west': 9.8296869}, {
  'zipCode': '97291',
  'north':   49.8952714,
  'east':    9.883597,
  'south':   49.8480927,
  'west':    9.8298297
}, {'zipCode': '97292', 'north': 49.8136, 'east': 9.7569027, 'south': 49.7632878, 'west': 9.6448802}, {
  'zipCode': '97294',
  'north':   49.9034134,
  'east':    10.0826995,
  'south':   49.84783,
  'west':    9.9780237
}, {'zipCode': '97295', 'north': 49.7783307, 'east': 9.8137311, 'south': 49.7401882, 'west': 9.7554502}, {
  'zipCode': '97297',
  'north':   49.8066594,
  'east':    9.8569507,
  'south':   49.7660176,
  'west':    9.749156600000001
}, {'zipCode': '97299', 'north': 49.82646220000001, 'east': 9.8834815, 'south': 49.7819903, 'west': 9.8261714}, {
  'zipCode': '97318',
  'north':   49.7908763,
  'east':    10.2310234,
  'south':   49.70390080000001,
  'west':    10.0399057
}, {'zipCode': '97320', 'north': 49.7865955, 'east': 10.281174, 'south': 49.6867037, 'west': 10.0905012}, {
  'zipCode': '97332',
  'north':   49.916047,
  'east':    10.319075000000002,
  'south':   49.8189135,
  'west':    10.1627192
}, {'zipCode': '97334', 'north': 49.8655722, 'east': 10.239152700000002, 'south': 49.8197871, 'west': 10.1676349}, {
  'zipCode': '97337',
  'north':   49.849521,
  'east':    10.2092953,
  'south':   49.7769021,
  'west':    10.05625
}, {'zipCode': '97340', 'north': 49.6910844, 'east': 10.201918, 'south': 49.5876517, 'west': 10.0813975}, {
  'zipCode': '97342',
  'north':   49.713395,
  'east':    10.2692668,
  'south':   49.6241429,
  'west':    10.1299736
}, {'zipCode': '97346', 'north': 49.7355441, 'east': 10.38808, 'south': 49.6210732, 'west': 10.2371047}, {
  'zipCode': '97348',
  'north':   49.7434888,
  'east':    10.3587341,
  'south':   49.6190911,
  'west':    10.1818038
}, {'zipCode': '97350', 'north': 49.7250345, 'east': 10.2397827, 'south': 49.6783331, 'west': 10.1829468}, {
  'zipCode': '97353',
  'north':   49.8306131,
  'east':    10.4288156,
  'south':   49.7756039,
  'west':    10.2512364
}, {'zipCode': '97355', 'north': 49.7967614, 'east': 10.4364257, 'south': 49.7136393, 'west': 10.2345469}, {
  'zipCode': '97357',
  'north':   49.8699179,
  'east':    10.4437575,
  'south':   49.7867976,
  'west':    10.2970692
}, {'zipCode': '97359', 'north': 49.8330777, 'east': 10.2848997, 'south': 49.7749862, 'west': 10.1942365}, {
  'zipCode': '97421',
  'north':   50.0550973,
  'east':    10.2437284,
  'south':   50.037086900000006,
  'west':    10.1992455
}, {'zipCode': '97422', 'north': 50.094875, 'east': 10.2755953, 'south': 50.0451155, 'west': 10.2004305}, {
  'zipCode': '97424',
  'north':   50.0715644,
  'east':    10.2584527,
  'south':   50.00874790000001,
  'west':    10.1651718
}, {'zipCode': '97437', 'north': 50.084783, 'east': 10.5754661, 'south': 50.0008139, 'west': 10.4238332}, {
  'zipCode': '97440',
  'north':   50.0438419,
  'east':    10.148233,
  'south':   49.9285229,
  'west':    10.0009065
}, {'zipCode': '97447', 'north': 49.9273924, 'east': 10.4126861, 'south': 49.8688964, 'west': 10.2795806}, {
  'zipCode': '97450',
  'north':   50.0579378,
  'east':    10.0460939,
  'south':   49.9305679,
  'west':    9.8646344
}, {'zipCode': '97453', 'north': 50.1416966, 'east': 10.4333403, 'south': 50.0242527, 'west': 10.257468}, {
  'zipCode': '97456',
  'north':   50.1500679,
  'east':    10.2349833,
  'south':   50.0658244,
  'west':    10.1686793
}, {'zipCode': '97461', 'north': 50.1852791, 'east': 10.6083324, 'south': 50.0923313, 'west': 10.46089}, {
  'zipCode': '97464',
  'north':   50.0836852,
  'east':    10.2024536,
  'south':   50.0536305,
  'west':    10.1320025
}, {'zipCode': '97469', 'north': 50.0407807, 'east': 10.3355498, 'south': 49.9926803, 'west': 10.2346938}, {
  'zipCode': '97475',
  'north':   50.0551804,
  'east':    10.675154,
  'south':   49.9905665,
  'west':    10.5515791
}, {'zipCode': '97478', 'north': 50.026513900000005, 'east': 10.582366900000002, 'south': 49.9294271, 'west': 10.4438295}, {
  'zipCode': '97483',
  'north':   49.9983289,
  'east':    10.7422078,
  'south':   49.9223991,
  'west':    10.6039894
}, {'zipCode': '97486', 'north': 50.1157515, 'east': 10.6887808, 'south': 50.0388955, 'west': 10.4381109}, {
  'zipCode': '97488',
  'north':   50.2316844,
  'east':    10.4509119,
  'south':   50.1336168,
  'west':    10.3164282
}, {'zipCode': '97490', 'north': 50.1537406, 'east': 10.2018944, 'south': 50.0692461, 'west': 10.0708616}, {
  'zipCode': '97491',
  'north':   50.2008873,
  'east':    10.5161855,
  'south':   50.1210812,
  'west':    10.3928489
}, {'zipCode': '97493', 'north': 50.0312836, 'east': 10.2023193, 'south': 49.96208990000001, 'west': 10.1368815}, {
  'zipCode': '97494',
  'north':   50.2365588,
  'east':    10.598461500000003,
  'south':   50.1613615,
  'west':    10.4519588
}, {'zipCode': '97496', 'north': 50.16840010000001, 'east': 10.7007921, 'south': 50.0971798, 'west': 10.5764155}, {
  'zipCode': '97497',
  'north':   49.9322005,
  'east':    10.423757000000002,
  'south':   49.8910398,
  'west':    10.3735901
}, {'zipCode': '97499', 'north': 49.9981226, 'east': 10.4488762, 'south': 49.9296856, 'west': 10.3624266}, {
  'zipCode': '97500',
  'north':   50.0228932,
  'east':    10.7666114,
  'south':   49.9740703,
  'west':    10.6231368
}, {'zipCode': '97502', 'north': 50.1017536, 'east': 10.147901500000001, 'south': 50.0438247, 'west': 10.0593325}, {
  'zipCode': '97503',
  'north':   50.0468852,
  'east':    10.4016062,
  'south':   50.01355,
  'west':    10.3204739
}, {'zipCode': '97505', 'north': 50.0607763, 'east': 10.1895458, 'south': 50.019434, 'west': 10.0826621}, {
  'zipCode': '97506',
  'north':   50.0143057,
  'east':    10.2417613,
  'south':   49.9670179,
  'west':    10.1729454
}, {'zipCode': '97508', 'north': 50.0200507, 'east': 10.398804, 'south': 49.9535026, 'west': 10.2723749}, {
  'zipCode': '97509',
  'north':   49.97961440000001,
  'east':    10.3131704,
  'south':   49.8807779,
  'west':    10.1822146
}, {'zipCode': '97511', 'north': 49.8888342, 'east': 10.3793471, 'south': 49.854556, 'west': 10.2994642}, {
  'zipCode': '97513',
  'north':   49.95749970000001,
  'east':    10.5174586,
  'south':   49.8713756,
  'west':    10.4074425
}, {'zipCode': '97514', 'north': 49.961613, 'east': 10.7079556, 'south': 49.8821816, 'west': 10.5323757}, {
  'zipCode': '97516',
  'north':   49.89059,
  'east':    10.4617817,
  'south':   49.8201803,
  'west':    10.3726114
}, {'zipCode': '97517', 'north': 50.1963191, 'east': 10.2354999, 'south': 50.1458097, 'west': 10.1715284}, {
  'zipCode': '97519',
  'north':   50.1363923,
  'east':    10.4998391,
  'south':   50.0770342,
  'west':    10.3771225
}, {'zipCode': '97520', 'north': 49.998003, 'east': 10.2567973, 'south': 49.9339183, 'west': 10.1774577}, {
  'zipCode': '97522',
  'north':   50.0047571,
  'east':    10.6156915,
  'south':   49.9546275,
  'west':    10.5672116
}, {'zipCode': '97523', 'north': 49.9395534, 'east': 10.1644736, 'south': 49.9023608, 'west': 10.099171}, {
  'zipCode': '97525',
  'north':   50.0128754,
  'east':    10.2879523,
  'south':   49.9762283,
  'west':    10.236884
}, {'zipCode': '97526', 'north': 50.0558005, 'east': 10.2896, 'south': 50.0205481, 'west': 10.2387094}, {
  'zipCode': '97528',
  'north':   50.2788535,
  'east':    10.6115312,
  'south':   50.20314530000001,
  'west':    10.514239900000002
}, {'zipCode': '97529', 'north': 49.9762251, 'east': 10.3996429, 'south': 49.9199523, 'west': 10.296447}, {
  'zipCode': '97531',
  'north':   50.0740881,
  'east':    10.4724699,
  'south':   49.9906357,
  'west':    10.3830584
}, {'zipCode': '97532', 'north': 50.1621405, 'east': 10.368074500000002, 'south': 50.0674801, 'west': 10.2187176}, {
  'zipCode': '97534',
  'north':   49.9773599,
  'east':    10.1897275,
  'south':   49.9293946,
  'west':    10.085741300000002
}, {'zipCode': '97535', 'north': 50.1085299, 'east': 10.0927083, 'south': 50.02967490000001, 'west': 9.9570861}, {
  'zipCode': '97537',
  'north':   49.9412023,
  'east':    10.1894026,
  'south':   49.9035672,
  'west':    10.1525439
}, {'zipCode': '97539', 'north': 50.0264755, 'east': 10.5037274, 'south': 49.9580024, 'west': 10.4038293}, {
  'zipCode': '97616',
  'north':   50.3782203,
  'east':    10.274570400000002,
  'south':   50.2815701,
  'west':    10.1481467
}, {'zipCode': '97618', 'north': 50.3906057, 'east': 10.3793402, 'south': 50.2632702, 'west': 10.0557186}, {
  'zipCode': '97631',
  'north':   50.339252200000004,
  'east':    10.554812,
  'south':   50.1964637,
  'west':    10.412265100000003
}, {'zipCode': '97633', 'north': 50.4030838, 'east': 10.6049656, 'south': 50.2202182, 'west': 10.3119025}, {
  'zipCode': '97638',
  'north':   50.4946378,
  'east':    10.4047892,
  'south':   50.3679269,
  'west':    10.2093068
}, {'zipCode': '97640', 'north': 50.498279, 'east': 10.4387493, 'south': 50.3498336, 'west': 10.2295584}, {
  'zipCode': '97645',
  'north':   50.4957571,
  'east':    10.2576712,
  'south':   50.4191211,
  'west':    10.0797641
}, {'zipCode': '97647', 'north': 50.5311546, 'east': 10.3038194, 'south': 50.4377912, 'west': 10.0300405}, {
  'zipCode': '97650',
  'north':   50.5647142,
  'east':    10.2249381,
  'south':   50.4909045,
  'west':    10.0389459
}, {'zipCode': '97653', 'north': 50.4469237, 'east': 10.1104961, 'south': 50.355459, 'west': 9.9312669}, {
  'zipCode': '97654',
  'north':   50.4394439,
  'east':    10.239088,
  'south':   50.3502597,
  'west':    10.1165296
}, {'zipCode': '97656', 'north': 50.481894, 'east': 10.1630854, 'south': 50.3915128, 'west': 9.9879801}, {
  'zipCode': '97657',
  'north':   50.371652,
  'east':    10.0746338,
  'south':   50.2960816,
  'west':    9.9275206
}, {'zipCode': '97659', 'north': 50.3985297, 'east': 10.1618848, 'south': 50.3339858, 'west': 10.0242146}, {
  'zipCode': '97688',
  'north':   50.2514337,
  'east':    10.1426211,
  'south':   50.1436082,
  'west':    9.967390500000002
}, {'zipCode': '97702', 'north': 50.3043473, 'east': 10.3619785, 'south': 50.2056051, 'west': 10.095405}, {
  'zipCode': '97705',
  'north':   50.3449373,
  'east':    10.0648251,
  'south':   50.2257307,
  'west':    9.9013169
}, {'zipCode': '97708', 'north': 50.3198919, 'east': 10.1281271, 'south': 50.2378299, 'west': 10.0196282}, {
  'zipCode': '97711',
  'north':   50.2379877,
  'east':    10.3699788,
  'south':   50.1443064,
  'west':    10.1774471
}, {'zipCode': '97714', 'north': 50.191096, 'east': 10.1803086, 'south': 50.1034862, 'west': 10.0897298}, {
  'zipCode': '97717',
  'north':   50.1963031,
  'east':    10.0890997,
  'south':   50.0987378,
  'west':    9.979447
}, {'zipCode': '97720', 'north': 50.2516505, 'east': 10.1843626, 'south': 50.1881254, 'west': 10.0877925}, {
  'zipCode': '97723',
  'north':   50.2452217,
  'east':    10.0100036,
  'south':   50.1625689,
  'west':    9.8480738
}, {'zipCode': '97724', 'north': 50.2899404, 'east': 10.2125614, 'south': 50.2616528, 'west': 10.0944954}, {
  'zipCode': '97725',
  'north':   50.1801291,
  'east':    10.019462,
  'south':   50.0843067,
  'west':    9.928442700000002
}, {'zipCode': '97727', 'north': 50.127105, 'east': 9.9696146, 'south': 50.0705369, 'west': 9.9052772}, {
  'zipCode': '97729',
  'north':   50.1478076,
  'east':    10.0989705,
  'south':   50.104847,
  'west':    10.0508529
}, {'zipCode': '97737', 'north': 50.1127668, 'east': 9.7887445, 'south': 49.9945709, 'west': 9.5569298}, {
  'zipCode': '97753',
  'north':   50.0212664,
  'east':    9.8712831,
  'south':   49.9183878,
  'west':    9.6196097
}, {'zipCode': '97762', 'north': 50.1831988, 'east': 9.9655266, 'south': 50.0296496, 'west': 9.7791758}, {
  'zipCode': '97769',
  'north':   50.335521,
  'east':    9.8653844,
  'south':   50.278531,
  'west':    9.7278779
}, {'zipCode': '97772', 'north': 50.4225134, 'east': 9.9559169, 'south': 50.3186759, 'west': 9.7435223}, {
  'zipCode': '97773',
  'north':   50.24319820000001,
  'east':    9.6017703,
  'south':   50.157071800000004,
  'west':    9.4946293
}, {'zipCode': '97775', 'north': 50.1945807, 'east': 9.7155374, 'south': 50.1013985, 'west': 9.5555623}, {
  'zipCode': '97776',
  'north':   50.0546244,
  'east':    9.925456,
  'south':   49.9590636,
  'west':    9.7616941
}, {'zipCode': '97778', 'north': 50.171088, 'east': 9.6138927, 'south': 50.0992772, 'west': 9.5073895}, {
  'zipCode': '97779',
  'north':   50.3142324,
  'east':    9.933668,
  'south':   50.2580445,
  'west':    9.8643739
}, {'zipCode': '97780', 'north': 50.03452510000001, 'east': 9.8134143, 'south': 50.0004287, 'west': 9.7353504}, {
  'zipCode': '97782',
  'north':   50.1704338,
  'east':    9.8071207,
  'south':   50.07289140000001,
  'west':    9.6796849
}, {'zipCode': '97783', 'north': 50.0929399, 'east': 9.8315997, 'south': 50.0266799, 'west': 9.7362664}, {
  'zipCode': '97785',
  'north':   50.2089779,
  'east':    9.6997335,
  'south':   50.1716825,
  'west':    9.5825308
}, {'zipCode': '97786', 'north': 50.4257171, 'east': 9.806883, 'south': 50.3416278, 'west': 9.7329819}, {
  'zipCode': '97788',
  'north':   50.04694080000001,
  'east':    9.6540104,
  'south':   50.0177292,
  'west':    9.5765026
}, {'zipCode': '97789', 'north': 50.3075336, 'east': 9.8731481, 'south': 50.242021, 'west': 9.7496983}, {
  'zipCode': '97791',
  'north':   50.2327401,
  'east':    9.6946615,
  'south':   50.1986386,
  'west':    9.5649602
}, {'zipCode': '97792', 'north': 50.3391046, 'east': 9.9312937, 'south': 50.3044103, 'west': 9.8445071}, {
  'zipCode': '97794',
  'north':   50.124375,
  'east':    9.6927111,
  'south':   50.0766345,
  'west':    9.5799299
}, {'zipCode': '97795', 'north': 50.2989511, 'east': 9.9294294, 'south': 50.2147232, 'west': 9.7729372}, {
  'zipCode': '97797',
  'north':   50.2241434,
  'east':    9.8595728,
  'south':   50.1250949,
  'west':    9.6946615
}, {'zipCode': '97799', 'north': 50.2950251, 'east': 9.7901827, 'south': 50.2087141, 'west': 9.6233018}, {
  'zipCode': '97816',
  'north':   50.1085839,
  'east':    9.6797004,
  'south':   49.9402963,
  'west':    9.4615159
}, {'zipCode': '97828', 'north': 49.9128552, 'east': 9.63281, 'south': 49.8035934, 'west': 9.4994664}, {
  'zipCode': '97833',
  'north':   50.0909941,
  'east':    9.5088247,
  'south':   50.0119318,
  'west':    9.3369538
}, {'zipCode': '97834', 'north': 49.8905037, 'east': 9.7636649, 'south': 49.8322, 'west': 9.6730653}, {
  'zipCode': '97836',
  'north':   49.94357000000001,
  'east':    9.5689157,
  'south':   49.8395195,
  'west':    9.4223246
}, {'zipCode': '97837', 'north': 49.84311, 'east': 9.6800751, 'south': 49.8044168, 'west': 9.59124}, {
  'zipCode': '97839',
  'north':   49.8970855,
  'east':    9.5564701,
  'south':   49.8328802,
  'west':    9.4889811
}, {'zipCode': '97840', 'north': 49.959797, 'east': 9.6061123, 'south': 49.8504561, 'west': 9.405291300000002}, {
  'zipCode': '97842',
  'north':   49.89166,
  'east':    9.68429,
  'south':   49.8339721,
  'west':    9.6007299
}, {'zipCode': '97843', 'north': 50.0169341, 'east': 9.5062574, 'south': 49.943637100000004, 'west': 9.3941334}, {
  'zipCode': '97845',
  'north':   49.9630591,
  'east':    9.6023186,
  'south':   49.9102439,
  'west':    9.4979932
}, {'zipCode': '97846', 'north': 50.0697308, 'east': 9.5494745, 'south': 50.0203986, 'west': 9.4864938}, {
  'zipCode': '97848',
  'north':   49.9979307,
  'east':    9.5541137,
  'south':   49.9694502,
  'west':    9.4779345
}, {'zipCode': '97849', 'north': 49.9368199, 'east': 9.6576862, 'south': 49.8840692, 'west': 9.57957}, {
  'zipCode': '97851',
  'north':   49.9362801,
  'east':    9.5987799,
  'south':   49.87904,
  'west':    9.5159331
}, {'zipCode': '97852', 'north': 49.8524012, 'east': 9.51323, 'south': 49.815288200000005, 'west': 9.4332555}, {
  'zipCode': '97854',
  'north':   49.9840024,
  'east':    9.7159734,
  'south':   49.9204801,
  'west':    9.5989244
}, {'zipCode': '97855', 'north': 49.8328332, 'east': 9.6546607, 'south': 49.774826, 'west': 9.5449615}, {
  'zipCode': '97857',
  'north':   49.9309098,
  'east':    9.709838700000002,
  'south':   49.8754199,
  'west':    9.650047
}, {'zipCode': '97859', 'north': 50.0527729, 'east': 9.5126727, 'south': 49.9927696, 'west': 9.3953675}, {
  'zipCode': '97877',
  'north':   49.7912941,
  'east':    9.651990200000002,
  'south':   49.6926977,
  'west':    9.3676243
}, {'zipCode': '97892', 'north': 49.8248476, 'east': 9.5690357, 'south': 49.744221, 'west': 9.4963166}, {
  'zipCode': '97896',
  'north':   49.7791776,
  'east':    9.4209892,
  'south':   49.6995423,
  'west':    9.2620268
}, {'zipCode': '97900', 'north': 49.7096287, 'east': 9.608590600000001, 'south': 49.634882, 'west': 9.3969649}, {
  'zipCode': '97901',
  'north':   49.8818514,
  'east':    9.479700700000002,
  'south':   49.7988099,
  'west':    9.35378
}, {'zipCode': '97903', 'north': 49.8135423, 'east': 9.3598999, 'south': 49.765211, 'west': 9.2912479}, {
  'zipCode': '97904',
  'north':   49.818214,
  'east':    9.40642,
  'south':   49.7701538,
  'west':    9.34137
}, {'zipCode': '97906', 'north': 49.8189067, 'east': 9.4702419, 'south': 49.7694421, 'west': 9.4274101}, {
  'zipCode': '97907',
  'north':   49.8227105,
  'east':    9.5139621,
  'south':   49.7704863,
  'west':    9.446146000000002
}, {'zipCode': '97909', 'north': 49.8255246, 'east': 9.4339426, 'south': 49.7782299, 'west': 9.3560901}, {
  'zipCode': '97922',
  'north':   49.5983929,
  'east':    9.8241684,
  'south':   49.5066065,
  'west':    9.5713428
}, {'zipCode': '97941', 'north': 49.6838867, 'east': 9.7259262, 'south': 49.5619523, 'west': 9.5853828}, {
  'zipCode': '97944',
  'north':   49.5476048,
  'east':    9.7056377,
  'south':   49.425691,
  'west':    9.5596158
}, {'zipCode': '97947', 'north': 49.6620702, 'east': 9.8149095, 'south': 49.5717872, 'west': 9.7100565}, {
  'zipCode': '97950',
  'north':   49.731367000000006,
  'east':    9.8411597,
  'south':   49.6372572,
  'west':    9.6909902
}, {'zipCode': '97953', 'north': 49.6491443, 'east': 9.6292194, 'south': 49.5450004, 'west': 9.4990682}, {
  'zipCode': '97956',
  'north':   49.72705,
  'east':    9.729165,
  'south':   49.6556083,
  'west':    9.5493918
}, {'zipCode': '97957', 'north': 49.6612846, 'east': 9.8728281, 'south': 49.5611403, 'west': 9.7925007}, {
  'zipCode': '97959',
  'north':   49.4528323,
  'east':    9.7145679,
  'south':   49.4068716,
  'west':    9.6379197
}, {'zipCode': '97980', 'north': 49.5296517, 'east': 9.8677627, 'south': 49.3878799, 'west': 9.6918677}, {
  'zipCode': '97990',
  'north':   49.5560795,
  'east':    10.0023523,
  'south':   49.4308028,
  'west':    9.8511809
}, {'zipCode': '97993', 'north': 49.5455479, 'east': 10.1617523, 'south': 49.3835353, 'west': 9.9708121}, {
  'zipCode': '97996',
  'north':   49.4559746,
  'east':    10.0521828,
  'south':   49.3516287,
  'west':    9.8333133
}, {'zipCode': '97999', 'north': 49.5871443, 'east': 9.9250233, 'south': 49.4813547, 'west': 9.7947663}, {
  'zipCode': '98527',
  'north':   50.6294531,
  'east':    10.7414691,
  'south':   50.5677482,
  'west':    10.6703152
}, {'zipCode': '98528', 'north': 50.6663152, 'east': 10.7893179, 'south': 50.613491200000006, 'west': 10.6898607}, {
  'zipCode': '98529',
  'north':   50.6469578,
  'east':    10.68611,
  'south':   50.5607522,
  'west':    10.6095772
}, {'zipCode': '98530', 'north': 50.62737570000001, 'east': 10.6587348, 'south': 50.5294756, 'west': 10.4561177}, {
  'zipCode': '98544',
  'north':   50.6899753,
  'east':    10.736026900000002,
  'south':   50.6245186,
  'west':    10.6224112
}, {'zipCode': '98547', 'north': 50.6815692, 'east': 10.6022676, 'south': 50.5838192, 'west': 10.4396053}, {
  'zipCode': '98553',
  'north':   50.61615230000001,
  'east':    10.8660824,
  'south':   50.4716398,
  'west':    10.6361299
}, {'zipCode': '98554', 'north': 50.6773778, 'east': 10.6477826, 'south': 50.6105969, 'west': 10.5487198}, {
  'zipCode': '98559',
  'north':   50.7386111,
  'east':    10.8278674,
  'south':   50.6360854,
  'west':    10.649931000000002
}, {'zipCode': '98574', 'north': 50.7628827, 'east': 10.5607099, 'south': 50.6710166, 'west': 10.251347}, {
  'zipCode': '98587',
  'north':   50.7378981,
  'east':    10.7107988,
  'south':   50.6654039,
  'west':    10.5012193
}, {'zipCode': '98590', 'north': 50.7406435, 'east': 10.4077518, 'south': 50.6458077, 'west': 10.1784064}, {
  'zipCode': '98593',
  'north':   50.8367462,
  'east':    10.5851483,
  'south':   50.7308778,
  'west':    10.4434076
}, {'zipCode': '98596', 'north': 50.8509019, 'east': 10.4958516, 'south': 50.756528700000004, 'west': 10.3703942}, {
  'zipCode': '98597',
  'north':   50.7885074,
  'east':    10.4445616,
  'south':   50.7186023,
  'west':    10.2012942
}, {'zipCode': '98617', 'north': 50.6377479, 'east': 10.5827905, 'south': 50.4609743, 'west': 10.1723239}, {
  'zipCode': '98630',
  'north':   50.4542842,
  'east':    10.682157,
  'south':   50.3353073,
  'west':    10.4505253
}, {'zipCode': '98631', 'north': 50.5120472, 'east': 10.585609, 'south': 50.3926291, 'west': 10.3472022}, {
  'zipCode': '98634',
  'north':   50.6876141,
  'east':    10.4158881,
  'south':   50.5164738,
  'west':    10.0370372
}, {'zipCode': '98639', 'north': 50.68259340000001, 'east': 10.4596077, 'south': 50.5865375, 'west': 10.3045369}, {
  'zipCode': '98646',
  'north':   50.4794483,
  'east':    10.830477,
  'south':   50.313302,
  'west':    10.5742799
}, {'zipCode': '98660', 'north': 50.5414743, 'east': 10.7471164, 'south': 50.4416507, 'west': 10.5517421}, {
  'zipCode': '98663',
  'north':   50.3452585,
  'east':    10.8521476,
  'south':   50.2043467,
  'west':    10.5909426
}, {'zipCode': '98666', 'north': 50.55337730000001, 'east': 10.9997777, 'south': 50.4816979, 'west': 10.8366653}, {
  'zipCode': '98667',
  'north':   50.609875900000006,
  'east':    10.9455244,
  'south':   50.50483160000001,
  'west':    10.815133
}, {'zipCode': '98669', 'north': 50.4408286, 'east': 10.8776203, 'south': 50.37901980000001, 'west': 10.7533004}, {
  'zipCode': '98673',
  'north':   50.5032398,
  'east':    11.0400651,
  'south':   50.3801715,
  'west':    10.7701291
}, {'zipCode': '98693', 'north': 50.7494563, 'east': 11.0582016, 'south': 50.6114122, 'west': 10.818229}, {
  'zipCode': '98694',
  'north':   50.6797641,
  'east':    11.0666141,
  'south':   50.5614306,
  'west':    10.825294
}, {'zipCode': '98701', 'north': 50.6436747, 'east': 11.0904542, 'south': 50.5472011, 'west': 10.8946753}, {
  'zipCode': '98711',
  'north':   50.6511958,
  'east':    10.8426214,
  'south':   50.5751055,
  'west':    10.7650128
}, {'zipCode': '98716', 'north': 50.7472803, 'east': 10.8707735, 'south': 50.66088760000001, 'west': 10.7915258}, {
  'zipCode': '98724',
  'north':   50.5664929,
  'east':    11.2336098,
  'south':   50.42498520000001,
  'west':    11.0007627
}, {'zipCode': '98743', 'north': 50.5457513, 'east': 11.3470003, 'south': 50.4751401, 'west': 11.2223411}, {
  'zipCode': '98744',
  'north':   50.6318046,
  'east':    11.234589,
  'south':   50.5305912,
  'west':    11.0663943
}, {'zipCode': '98746', 'north': 50.5635468, 'east': 11.1089964, 'south': 50.4774881, 'west': 10.9666471}, {
  'zipCode': '99084',
  'north':   50.9866286,
  'east':    11.0422053,
  'south':   50.9656646,
  'west':    11.0115418
}, {'zipCode': '99085', 'north': 51.0263905, 'east': 11.0841328, 'south': 50.9732196, 'west': 11.0338128}, {
  'zipCode': '99086',
  'north':   51.0134213,
  'east':    11.0470033,
  'south':   50.9838289,
  'west':    11.0148783
}, {'zipCode': '99087', 'north': 51.0363566, 'east': 11.0562422, 'south': 51.0117996, 'west': 11.012927}, {
  'zipCode': '99089',
  'north':   51.0125778,
  'east':    11.0278323,
  'south':   50.9828707,
  'west':    10.9989879
}, {'zipCode': '99090', 'north': 51.0431767, 'east': 10.9877289, 'south': 50.9743824, 'west': 10.8546679}, {
  'zipCode': '99091',
  'north':   51.034456,
  'east':    11.0243662,
  'south':   51.0003421,
  'west':    10.9726443
}, {'zipCode': '99092', 'north': 51.0272956, 'east': 11.0204213, 'south': 50.9407934, 'west': 10.8623413}, {
  'zipCode': '99094',
  'north':   50.9715495,
  'east':    11.0397822,
  'south':   50.8904895,
  'west':    10.9325653
}, {'zipCode': '99095', 'north': 51.079978, 'east': 11.101023300000001, 'south': 51.0185815, 'west': 10.975157}, {
  'zipCode': '99096',
  'north':   50.972744,
  'east':    11.0569595,
  'south':   50.9369325,
  'west':    11.0150232
}, {'zipCode': '99097', 'north': 50.9596466, 'east': 11.1089548, 'south': 50.9038642, 'west': 11.0035731}, {
  'zipCode': '99098',
  'north':   51.0343461,
  'east':    11.1751358,
  'south':   50.9479957,
  'west':    11.0627819
}, {'zipCode': '99099', 'north': 50.9785555, 'east': 11.1535116, 'south': 50.9244608, 'west': 11.0409496}, {
  'zipCode': '99100',
  'north':   51.0980784,
  'east':    10.8919693,
  'south':   50.9773351,
  'west':    10.7682832
}, {'zipCode': '99102', 'north': 50.9299989, 'east': 11.1891605, 'south': 50.8911382, 'west': 11.0117005}, {
  'zipCode': '99189',
  'north':   51.142556,
  'east':    11.0455603,
  'south':   51.0142924,
  'west':    10.8624265
}, {'zipCode': '99192', 'north': 50.9919249, 'east': 10.9641719, 'south': 50.8776461, 'west': 10.8268195}, {
  'zipCode': '99195',
  'north':   51.1333953,
  'east':    11.202119100000003,
  'south':   51.0523007,
  'west':    10.9634823
}, {'zipCode': '99198', 'north': 51.076240000000006, 'east': 11.2174411, 'south': 50.9203737, 'west': 11.0880228}, {
  'zipCode': '99310',
  'north':   50.8756352,
  'east':    11.1778711,
  'south':   50.7267636,
  'west':    10.8700598
}, {'zipCode': '99326', 'north': 50.8210352, 'east': 11.2241136, 'south': 50.6941734, 'west': 10.9812798}, {
  'zipCode': '99330',
  'north':   50.7956398,
  'east':    10.8781708,
  'south':   50.683893600000005,
  'west':    10.7242501
}, {'zipCode': '99334', 'north': 50.9154265, 'east': 11.164623, 'south': 50.8173973, 'west': 10.8204068}, {
  'zipCode': '99338',
  'north':   50.8231842,
  'east':    10.937007700000002,
  'south':   50.7309459,
  'west':    10.8186779
}, {'zipCode': '99423', 'north': 50.9928456, 'east': 11.3492665, 'south': 50.9703733, 'west': 11.3069279}, {
  'zipCode': '99425',
  'north':   51.0016074,
  'east':    11.4019598,
  'south':   50.937107,
  'west':    11.2710295
}, {'zipCode': '99427', 'north': 51.0367147, 'east': 11.3732644, 'south': 50.977329600000004, 'west': 11.234905100000002}, {
  'zipCode': '99428',
  'north':   51.032629,
  'east':    11.3442193,
  'south':   50.9171123,
  'west':    11.1626521
}, {'zipCode': '99438', 'north': 50.9541366, 'east': 11.3817831, 'south': 50.8289486, 'west': 11.171443}, {
  'zipCode': '99439',
  'north':   51.1066418,
  'east':    11.4172113,
  'south':   51.0187511,
  'west':    11.1920333
}, {'zipCode': '99441', 'north': 51.0245649, 'east': 11.5451474, 'south': 50.8774146, 'west': 11.347906300000002}, {
  'zipCode': '99444',
  'north':   50.8988077,
  'east':    11.4628336,
  'south':   50.7934476,
  'west':    11.2488606
}, {'zipCode': '99448', 'north': 50.9037486, 'east': 11.2572856, 'south': 50.8038766, 'west': 11.1247134}, {
  'zipCode': '99510',
  'north':   51.1093305,
  'east':    11.686815,
  'south':   50.9606806,
  'west':    11.3851076
}, {'zipCode': '99518', 'north': 51.1205694, 'east': 11.6973742, 'south': 51.03982250000001, 'west': 11.4797773}, {
  'zipCode': '99610',
  'north':   51.224014,
  'east':    11.268947400000002,
  'south':   51.101167,
  'west':    11.0159446
}, {'zipCode': '99625', 'north': 51.2936608, 'east': 11.3911474, 'south': 51.1285424, 'west': 11.1724003}, {
  'zipCode': '99628',
  'north':   51.1951794,
  'east':    11.485437,
  'south':   51.0895954,
  'west':    11.2474987
}, {'zipCode': '99631', 'north': 51.2476291, 'east': 11.1425505, 'south': 51.1699954, 'west': 10.972806}, {
  'zipCode': '99634',
  'north':   51.2058558,
  'east':    11.0660028,
  'south':   51.1168507,
  'west':    10.9057945
}, {'zipCode': '99636', 'north': 51.2405774, 'east': 11.4530235, 'south': 51.1554022, 'west': 11.3010985}, {
  'zipCode': '99638',
  'north':   51.2802101,
  'east':    11.1868254,
  'south':   51.2173616,
  'west':    10.9931062
}, {'zipCode': '99706', 'north': 51.4216635, 'east': 10.9494875, 'south': 51.2835175, 'west': 10.6202585}, {
  'zipCode': '99707',
  'north':   51.4295922,
  'east':    11.1227077,
  'south':   51.2894469,
  'west':    10.9010464
}, {'zipCode': '99713', 'north': 51.3982748, 'east': 10.8456835, 'south': 51.2042961, 'west': 10.4836523}, {
  'zipCode': '99718',
  'north':   51.32697550000001,
  'east':    11.0374672,
  'south':   51.1995917,
  'west':    10.789047
}, {'zipCode': '99734', 'north': 51.557286, 'east': 10.9479648, 'south': 51.4404771, 'west': 10.6761482}, {
  'zipCode': '99735',
  'north':   51.5508302,
  'east':    10.8163986,
  'south':   51.3893567,
  'west':    10.5455885
}, {'zipCode': '99752', 'north': 51.4931248, 'east': 10.6918307, 'south': 51.4240961, 'west': 10.4987899}, {
  'zipCode': '99755',
  'north':   51.6451402,
  'east':    10.7766401,
  'south':   51.4873906,
  'west':    10.4724444
}, {'zipCode': '99759', 'north': 51.4365072, 'east': 10.6897644, 'south': 51.36485, 'west': 10.480879800000002}, {
  'zipCode': '99765',
  'north':   51.527567700000006,
  'east':    10.9801066,
  'south':   51.4005215,
  'west':    10.7897209
}, {'zipCode': '99768', 'north': 51.6489423, 'east': 10.9375833, 'south': 51.5328343, 'west': 10.7202057}, {
  'zipCode': '99817',
  'north':   51.0534347,
  'east':    10.391523400000002,
  'south':   50.9308125,
  'west':    10.1757224
}, {'zipCode': '99819', 'north': 51.0518201, 'east': 10.338939700000001, 'south': 50.8612844, 'west': 10.113664700000001}, {
  'zipCode': '99820',
  'north':   51.084239,
  'east':    10.6163825,
  'south':   50.8944561,
  'west':    10.3751401
}, {'zipCode': '99826', 'north': 51.1528535, 'east': 10.4370511, 'south': 51.0217545, 'west': 10.2481909}, {
  'zipCode': '99830',
  'north':   51.1540377,
  'east':    10.315589,
  'south':   51.0481746,
  'west':    10.1180191
}, {'zipCode': '99831', 'north': 51.1055746, 'east': 10.3003322, 'south': 51.0253231, 'west': 10.1842354}, {
  'zipCode': '99834',
  'north':   51.0131233,
  'east':    10.2517745,
  'south':   50.9261945,
  'west':    10.0194315
}, {'zipCode': '99837', 'north': 50.96448, 'east': 10.186762, 'south': 50.875678, 'west': 9.9495835}, {
  'zipCode': '99842',
  'north':   50.9353197,
  'east':    10.438319,
  'south':   50.8623246,
  'west':    10.3245775
}, {'zipCode': '99846', 'north': 50.9237874, 'east': 10.4433111, 'south': 50.9030281, 'west': 10.4054127}, {
  'zipCode': '99848',
  'north':   50.9700803,
  'east':    10.4566229,
  'south':   50.9094634,
  'west':    10.3191335
}, {'zipCode': '99867', 'north': 50.9912832, 'east': 10.781844, 'south': 50.892809, 'west': 10.6223035}, {
  'zipCode': '99869',
  'north':   51.0573818,
  'east':    10.877273,
  'south':   50.8411346,
  'west':    10.4791676
}, {'zipCode': '99880', 'north': 50.9779046, 'east': 10.6512947, 'south': 50.8426158, 'west': 10.401617}, {
  'zipCode': '99885',
  'north':   50.8656637,
  'east':    10.8599206,
  'south':   50.7154826,
  'west':    10.656551
}, {'zipCode': '99887', 'north': 50.8803577, 'east': 10.7275764, 'south': 50.7156799, 'west': 10.5415623}, {
  'zipCode': '99891',
  'north':   50.8942435,
  'east':    10.5413258,
  'south':   50.8327664,
  'west':    10.463545
}, {'zipCode': '99894', 'north': 50.9245286, 'east': 10.6765094, 'south': 50.81299800000001, 'west': 10.5158289}, {
  'zipCode': '99897',
  'north':   50.8092359,
  'east':    10.651602,
  'south':   50.7240209,
  'west':    10.5376897
}, {'zipCode': '99947', 'north': 51.2213583, 'east': 10.771314, 'south': 51.0320616, 'west': 10.3836686}, {
  'zipCode': '99955',
  'north':   51.2224371,
  'east':    10.9227206,
  'south':   51.0945673,
  'west':    10.7167149
}, {'zipCode': '99958', 'north': 51.1389535, 'east': 10.8104442, 'south': 51.04758, 'west': 10.6948849}, {
  'zipCode': '99974',
  'north':   51.3032188,
  'east':    10.5543053,
  'south':   51.174412200000006,
  'west':    10.361723
}, {'zipCode': '99976', 'north': 51.3441264, 'east': 10.5067511, 'south': 51.176756, 'west': 10.1855872}, {
  'zipCode': '99986',
  'north':   51.1885781,
  'east':    10.5027018,
  'south':   51.0912581,
  'west':    10.2989815
}, {'zipCode': '99988', 'north': 51.2161061, 'east': 10.341163, 'south': 51.1348483, 'west': 10.2003248}, {
  'zipCode': '99991',
  'north':   51.1939828,
  'east':    10.6244156,
  'south':   51.1281929,
  'west':    10.4925031
}, {'zipCode': '99994', 'north': 51.2865592, 'east': 10.7116352, 'south': 51.2124215, 'west': 10.6220781}, {
  'zipCode': '99996',
  'north':   51.326082,
  'east':    10.6491559,
  'south':   51.249224,
  'west':    10.4952721
}, {'zipCode': '99998', 'north': 51.2705089, 'east': 10.6358093, 'south': 51.1455334, 'west': 10.4618972}];
