import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Route} from '@angular/router';
import {routes} from '../../../../app-routing.module';
import {RoutingService} from '../../../../services/routing.service';
import {EventKey, EventService} from '../../../../services/event.service';
import {GuidedTourStep} from '../../../common/guided-tour/guided.tour.service';
import {MisTagUtils, SubscriptionType} from "../../../../services/aaa/model/account.model";
import {AuthenticationProvider} from "../../../../services/aaa/authentication.provider";

export class HeaderNavigationItemData {
  label: string;
  icon: string;
  active: boolean;
  paths: string[];
}

@Component({
  selector: 'mis-header-navigation',
  templateUrl: './header.navigation.component.html',
  styleUrls: ['./header.navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit {
  objectKeys = Object.keys;
  guidedTourStep = GuidedTourStep;

  navigationItems: { [s: string]: HeaderNavigationItemData } = {};
  watchlistItemCount: string;

  constructor(private activeRoute: ActivatedRoute,
              private routingService: RoutingService,
              private eventService: EventService,
              private authenticationProvider: AuthenticationProvider) {

    this.buildNavigationItems();
    this.selectActiveNavigationItem();
  }

  private buildNavigationItems(): void {
    routes.forEach((route) => {
      if (route.data
        && route.data.label
        && this.isAuthorizedToSeeNavigationItem(route)) {

        this.navigationItems[route.path] = {
          label: route.data.label,
          icon: route.data.icon,
          active: false,
          paths: ['/' + route.path]
        };
      }
    });
  }

  private isAuthorizedToSeeNavigationItem(route: Route): boolean {
    return this.hasAccountPermission(route)
      && this.hasSubscriptionForRoute(route);
  }

  private hasAccountPermission(route: Route): boolean {
    const requiredPermissionTag = route.data.requiredPermissionTag;
    if (!requiredPermissionTag) {
      return true;
    }

    const permissionTag = MisTagUtils.getTagByKey(this.authenticationProvider.getUser().account.tags, MisTagUtils.keys.permissionMarketReports);

    return permissionTag && permissionTag.value === 'true';
  }

  private hasSubscriptionForRoute(route: Route): boolean {
    const requiredSubscription = route.data.requiredSubscription;
    if (!requiredSubscription) {
      return true;
    }

    return this.authenticationProvider.authorizedFor(requiredSubscription as SubscriptionType);
  }

  private selectActiveNavigationItem(): void {
    const router = this.routingService.getRouter();
    if (router.url != null && router.url !== '/' && router.url !== '') {
      this.visitLink(this.findNavigationItem(router.url));
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const navigation = <NavigationEnd>val;
        const navUrl = navigation.url.replace('/', '');
        this.visitLink(this.findNavigationItem(navUrl));
      }
    });
  }

  ngOnInit(): void {
    this.eventService.on(EventKey.WATCHLIST_CHANGED, (watchlistItemCount) => {
      this.watchlistItemCount = (watchlistItemCount > 99 ? '99+' : (watchlistItemCount === 0 ? null : watchlistItemCount));
    });
  }

  private findNavigationItem(currentPath: string): HeaderNavigationItemData {

    if (currentPath.indexOf('/research') === 0) {
      currentPath = '/research';
    }

    if (this.navigationItems[currentPath]) {
      return this.navigationItems[currentPath];
    }

    const navKeys = Object.keys(this.navigationItems).filter(key => {
      return this.navigationItems[key].paths.filter(path => {
        return path === currentPath;
      }).length === 1;
    });

    if (navKeys.length > 0) {
      return this.navigationItems[navKeys[0]];
    }

    return null;
  }

  visitLink(selectedNavItem: HeaderNavigationItemData) {
    for (const item of Object.values(this.navigationItems)) {
      item.active = false;
    }

    if (selectedNavItem) {
      selectedNavItem.active = true;
    }
  }

  getCount(navItemKeys: string) {
    if (navItemKeys === 'watchlist' && this.watchlistItemCount) {
      return this.watchlistItemCount;
    }
    return null;
  }
}
