import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MisAccount, MisContact} from '../../../../../services/aaa/model/account.model';
import {AccountService} from '../../../../../services/aaa/account.service';
import {MisBaseUtils} from '../../../../../services/common/base.util';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';

export interface ContactEditModalData {
  account: MisAccount;
  contact: MisContact;
}

@Component({
  selector: 'mis-contact-edit-modal',
  templateUrl: './contact.edit.modal.component.html'
})
export class ContactEditModalComponent {
  account: MisAccount;
  contact: MisContact;
  formGroup: FormGroup;
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<ContactEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: ContactEditModalData,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private accountService: AccountService) {
    this.account = modalData.account;
    this.contact = modalData.contact;

    this.formGroup = formBuilder.group({
      'salutation': [modalData.contact.salutation],
      'firstName': [modalData.contact.firstName, Validators.required],
      'lastName': [modalData.contact.lastName, Validators.required],
      'email': [modalData.contact.email, [Validators.required, Validators.email]],
      'phone': [modalData.contact.phone],
      'mobile': [modalData.contact.mobile]
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    this.prepareAccount();

    this.accountService.updateAccount(this.account).subscribe((account) => {
      this.snackBar.success('Die Kontaktdaten wurden erfolgreich aktualisiert.');
      this.dialogRef.close();
    }, (err) => {
      this.isLoading = false;
      this.snackBar.error('Beim Aktualisieren der Kontaktdaten ist ein Fehler aufgetreten.');
    });
  }

  private prepareAccount(): void {
    const contact = this.account.contacts.find(con => con.id === this.contact.id);
    if (contact) {
      contact.salutation = MisBaseUtils.getValueOrDefault(this.formGroup.get('salutation').value, null);
      contact.firstName = this.formGroup.get('firstName').value;
      contact.lastName = this.formGroup.get('lastName').value;
      contact.email = this.formGroup.get('email').value;
      contact.mobile = MisBaseUtils.getValueOrDefault(this.formGroup.get('mobile').value, null);
      contact.phone = MisBaseUtils.getValueOrDefault(this.formGroup.get('phone').value, null);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
