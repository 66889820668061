import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ViewServiceInstance} from '../../../viewservice/view.service';

@Component({
  selector: 'mis-login-home',
  templateUrl: './login.home.component.html',
  styleUrls: ['./login.home.component.scss']
})
export class LoginHomeComponent implements OnInit {
  activeComponent = 'login';

  isMobile: boolean;

  isLoginActive = true;
  isRegistrationActive = false;
  toggleButtonTitle: string;

  constructor(private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const requestedAction = this.route.snapshot.routeConfig.path;
    this.show(requestedAction);

    this.isMobile = ViewServiceInstance.isMobile();
  }

  // @HostListener('window:scroll', ['$event'])
  // scrollHandler(event) {
  //   if (window.scrollY > 30) {
  //     this.eventService.broadcast('SHRINK_HEADER_LOGO', true);
  //   } else {
  //     this.eventService.broadcast('SHRINK_HEADER_LOGO', false);
  //   }
  // }

  show(component: string): void {

    if (component === 'registration') {
      this.route.queryParams.subscribe(params => {
        this.fillActiveComponentName(component);
        this.location.go('/' + component);
      });
      return;
    }

    this.fillActiveComponentName(component);
    this.location.go('/' + component);
  }

  private fillActiveComponentName(component: string) {
    this.isLoginActive = false;
    this.isRegistrationActive = false;

    if (component === 'registration') {
      this.isRegistrationActive = true;
      this.activeComponent = component;
    } else {
      this.isLoginActive = true;
      this.activeComponent = 'login';
    }
    this.toggleButtonTitle = this.isLoginActive ? 'Jetzt registrieren' : 'Zur Anmeldung';
  }

  toggleWrapper(event): void {
    this.show(this.activeComponent === 'registration' ? 'login' : 'registration');
    event.target.blur();
  }
}
