import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../../services/aaa/authentication.service';

@Component({
  selector: 'mis-password-forgotten-step1',
  templateUrl: './password.forgotten.step1.component.html'
})
export class PasswordForgottenStep1Component {
  @Output() done = new EventEmitter<string>();
  passwordForgottenFormGroup: FormGroup;
  showLoading: boolean;
  passwordResetFailed: boolean;

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService) {
    this.passwordForgottenFormGroup = this.formBuilder.group({
      'email': ['', [Validators.email, Validators.required]]
    });
  }

  requestVerificationCode(): void {
    if (!this.passwordForgottenFormGroup.valid) {
      return;
    }

    this.showLoading = true;
    const email = this.passwordForgottenFormGroup.controls['email'].value;
    this.authenticationService.forgotPassword(email).subscribe(() => {
      this.showLoading = false;
      this.done.emit(this.passwordForgottenFormGroup.controls['email'].value);

    }, (error) => {
      this.showLoading = false;
      this.passwordResetFailed = true;
    });
  }
}
