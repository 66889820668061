import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';

export class TextColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnTextComponent;
  }
}

@Component({
  selector: 'mis-immo-table-column-text',
  templateUrl: './immo.table.column.text.component.html',
  styleUrls: ['./immo.table.column.text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnTextComponent extends AbstractImmoTableColumnItem implements OnInit {
  text: string;

  ngOnInit(): void {
    this.text = this.getValue(this.researchItem, this.columnName);
  }
}
