<div class="mis-row mis-market-report">
  <div class="mis-column-66"
       [style.maxHeight]="maxHeightTableContainer">

    <div class="mis-column-100 mis-menu-bar">
      <div class="mis-column-boxed">
        <mis-menu-bar-button label="Bericht erstellen"
                             icon="fas fa-plus"
                             (clickAction)="openModalCreateNewConfig()"></mis-menu-bar-button>

        <mis-menu-bar-button label="Aktualisieren"
                             icon="fas fa-redo"
                             (clickAction)="loadMarketReports()"></mis-menu-bar-button>
      </div>
    </div>

    <table>
      <thead>
      <tr>
        <th class="table-column-50">Ort</th>
        <th class="table-column-40">Herausgeber</th>
        <th class="table-column-10">Aktion</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngIf="isLoading">
        <td colspan="3">
          <mis-loading></mis-loading>
        </td>
      </tr>

      <ng-container *ngIf="!isLoading">
        <tr *ngFor="let marketReportConfiguration of marketReportConfigurations"
            [ngClass]="{'active': marketReportConfiguration === selectedMarketReport?.dynamicMarketReport}"
            class="clickable">
          <td (click)="selectDynamicReportDetails(marketReportConfiguration)">{{marketReportConfiguration.city}}</td>
          <td (click)="selectDynamicReportDetails(marketReportConfiguration)">propfox.io</td>
          <td class="table-actions">
            <a (click)="deleteMarketReportConfiguration($event, marketReportConfiguration)"
               title="Marktbericht löschen">
              <i class="fas fa-trash-alt"></i>
            </a>
          </td>
        </tr>
        <tr *ngFor="let report of staticReports"
            [ngClass]="{'active': report === selectedMarketReport?.staticMarketReport}"
            class="clickable">
          <td (click)="selectStaticReportDetails(report)">{{report.title}}</td>
          <td (click)="selectStaticReportDetails(report)">Bundesverband der privaten Immobilienwirtschaft</td>
          <td>&nbsp;</td>
        </tr>
      </ng-container>

      </tbody>
    </table>
  </div>

  <div class="mis-column-33">
    <h2>Details</h2>
    <div *ngIf="!selectedMarketReport">
      Klicken Sie einen Marktbericht an, um weitere Details zu erhalten.
    </div>

    <ng-container *ngIf="selectedMarketReport">
      <div *ngIf="selectedMarketReport.staticMarketReport">
        <h3>Stadt:</h3>
        <ul>
          <li>{{selectedMarketReport.staticMarketReport.ort}}</li>
        </ul>

        <h3>Herausgeber:</h3>
        <ul>
          <li>Bundesverband der privaten Immobilienwirtschaft</li>
        </ul>

        <h3>Download:</h3>
        <div class="mis-button-pane mis-buttons-left">
          <a href="assets/downloads/marktbericht/{{selectedMarketReport.staticMarketReport.filename}}.pdf"
             class="mis-button"
             target="_blank"
             title="Klicken Sie hier um den {{selectedMarketReport.staticMarketReport.title}} herunterzuladen.">
            <i class="fas fa-file-download"></i> herunterladen
          </a>
        </div>
      </div>
      <div *ngIf="selectedMarketReport.dynamicMarketReport">
        <h3>Stadt:</h3>
        <ul>
          <li>{{selectedMarketReport.dynamicMarketReport.city}}</li>
        </ul>

        <h3>Vergleichsstädte:</h3>
        <ul>
          <li *ngFor="let city of selectedMarketReport.dynamicMarketReport.comparisonCities">
            {{city}}
          </li>
        </ul>

        <h3>Herausgeber:</h3>
        <ul>
          <li>propfox.io</li>
        </ul>

        <h3>Download:</h3>
        <div *ngIf="selectedMarketReport.dynamicMarketReport.downloadable"
             class="mis-button-pane mis-buttons-left">
          <button *ngIf="!downloadInProgress"
                  (click)="exportMarketReport($event, selectedMarketReport.dynamicMarketReport)"
                  title="Klicken Sie hier um den Marktbericht für {{selectedMarketReport.dynamicMarketReport.city}} herunterzuladen.">
            <i class="fas fa-file-download"></i> herunterladen
          </button>

          <mis-loading *ngIf="downloadInProgress"
                       size="small"></mis-loading>
        </div>

        <mis-hint *ngIf="!selectedMarketReport.dynamicMarketReport.downloadable"
                  type="INFO-BORDERED">
          Der Marktbericht befindet sich noch in der Erstellung. Dies kann mehrere Minuten dauern. Anschließend steht
          der Bericht zum Download bereit.
        </mis-hint>
      </div>
    </ng-container>
  </div>
</div>

