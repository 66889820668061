import {ResearchItem} from '../../../../../services/research/model/research.item.model';
import {AbstractImmoTableColumnData} from './immo.table.column.data';
import {EventEmitter} from '@angular/core';
import {FieldName} from '../../../../../services/research/model/research.filter.model';

export type ImmoTableFilterEventAction = 'ADD' | 'SET' | 'REMOVE';


export class ImmoTableFilterEvent {
  constructor(public field: FieldName, public value: any, public action: ImmoTableFilterEventAction) {
  }
}

export interface ImmoTableColumnItem {
  researchItem: ResearchItem;
  columnName: string;
  additionalData: AbstractImmoTableColumnData;
  filter: EventEmitter<ImmoTableFilterEvent>;
  columnClick: EventEmitter<void>;
}
