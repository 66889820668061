<div [formGroup]="formGroup">
  <mat-form-field>
    <input matInput
           formControlName="password"
           type="password"
           placeholder="Passwort"
           required="required"
           misValidateEqual="passwordRepeat"
           misReverse="true">
    <div class="mis-field-error" *ngIf="formGroup.controls['password'].errors?.pattern">
      Das Passwort entspricht noch nicht den Passwortregeln.
    </div>
    <div class="mis-field-error" *ngIf="formGroup.controls['password'].errors?.minlength">
      Das Passwort muss mindestens 8 Zeichen lang sein.
    </div>
  </mat-form-field>

  <mat-form-field>
    <input matInput
           formControlName="passwordRepeat"
           type="password"
           placeholder="Passwortwiederholung"
           required="required"
           misValidateEqual="password">
    <div class="mis-field-error"
         *ngIf="formGroup.controls['passwordRepeat'].errors?.validateEqual === false">
      Das wiederholte Passwort ist nicht gleich mit dem eingegebenen Passwort.
    </div>
  </mat-form-field>
</div>
