import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import * as _ from 'lodash';
import {MisEventUtils} from '../../../../services/common/event.util';

export class FormFieldsDropdownValue {
  constructor(public value: string, public additional: string = null) {
  }
}

export class FormFieldsDropdownLoadAutocompleteValues {
  constructor(public subscriber: Subscriber<{ [key: string]: FormFieldsDropdownValue}>, public searchString: string) {
  }
}

@Component({
  selector:    'mis-form-field-dropdown',
  templateUrl: './form.fields.dropdown.component.html',
  styleUrls:   ['./form.fields.dropdown.component.scss']
})
export class FormFieldsDropdownComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() isAutoComplete: boolean;
  @Input() isMultiSelect: boolean;
  @Input() allowEnteredValue: boolean;
  @Input() values: { [key: string]: FormFieldsDropdownValue };
  @Input() selectedValues: string[] = [];
  @Input() displayMode: 'boxed' | 'simple' = 'boxed';
  @Input() dropDownPosition: 'bottom' | 'top' = 'bottom';
  @Output() loadAutoCompleteValues = new EventEmitter<FormFieldsDropdownLoadAutocompleteValues>();
  @Output() changed = new EventEmitter<{ [key: string]: FormFieldsDropdownValue }>();

  @ViewChild('dropDownInput') dropDownInput: ElementRef<HTMLInputElement>;
  @ViewChild('toggleButton') toggleButton: ElementRef<HTMLElement>;

  isDroppedDown: boolean;
  objectKeys = Object.keys;
  internSelectedValues: { [key: string]: FormFieldsDropdownValue } = {};
  selectedValuesAsString: string;
  showLabel = true;
  isLoadingAutoComplete: boolean;
  allSelectedValues = '';
  searchString = '';

  focusedIndex = -1;

  ngOnInit(): void {
    this.prepareSelection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareSelection();
  }

  private prepareSelection() {
    this.internSelectedValues = {};
    this.showLabel = true;

    if (this.selectedValues && this.selectedValues.length > 0) {
      this.selectedValues.forEach(valueKey => {
        if (this.values && this.values.hasOwnProperty(valueKey)) {
          this.internSelectedValues[valueKey] = this.values[valueKey];
          this.showLabel = false;

        } else if (this.isAutoComplete) {
          this.internSelectedValues[valueKey] = new FormFieldsDropdownValue(valueKey);
          this.showLabel = false;
        }
      });

      this.allSelectedValues = Object.values(this.internSelectedValues).map(value=> value.value).join(',');
    }

    this.prepareSelectionAsString();
  }

  private prepareSelectionAsString(): void {
    if (Object.keys(this.internSelectedValues).length === 0) {
      this.selectedValuesAsString = this.label;
    } else {
      this.selectedValuesAsString = Object.values(this.internSelectedValues).map((item) => item.value).join(', ');
    }
  }

  toggleDropDown(event: Event = null) {
    if (event) {
      MisEventUtils.stopEvent(event);
      return;
    }

    if (this.disabled) {
      return;
    }
    this.isDroppedDown = !this.isDroppedDown;

    if (this.isDroppedDown && this.isMultiSelect && this.isAutoComplete) {
      this.dropDownInput.nativeElement.focus();
    }

    if (!this.isDroppedDown) {
      this.closeDropDown();
    }
  }

  selectValueKey(event: Event, valueKey: string, isSelected: boolean = false) {
    if (event) {
      event.stopPropagation();
    }

    if (this.disabled) {
      return;
    }

    if (!this.isMultiSelect) {
      this.internSelectedValues = {};
      this.internSelectedValues[valueKey] = this.values[valueKey];
      this.showLabel = false;

    } else {
      if (isSelected) {
        delete this.internSelectedValues[valueKey];

      } else {
        this.internSelectedValues[valueKey] = this.values[valueKey];
      }

      this.showLabel = this.objectKeys(this.internSelectedValues).length === 0;
    }

    this.closeDropDown();

    this.allSelectedValues = Object.values(this.internSelectedValues).map(value => value.value).join(',');

    this.prepareSelectionAsString();
    this.changed.emit(this.internSelectedValues);
  }

  isSelected(valueKey: string): boolean {
    return this.internSelectedValues.hasOwnProperty(valueKey);
  }

  processKeyboardNavigation(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDropDown();
      return;
    }

    if (event.key === 'Tab') {
      this.closeDropDown();
      return;
    }

    this.searchString = this.isAutoComplete ? this.dropDownInput.nativeElement.value : null;
    if (event.key === 'Enter' || (this.focusedIndex >= 0 && event.key === ' ')) {

      if (this.focusedIndex >= 0) {
        // focus selection
        const focusedKey = Object.keys(this.values)[this.focusedIndex];
        if (focusedKey) {
          this.selectValueKey(null, focusedKey, this.isSelected(focusedKey));
          return;
        }
      }

      if (this.searchString && this.searchString.trim() !== '') {
        const valueKeys = Object.keys(this.values).filter(value => this.values[value].value.toLowerCase() === this.searchString.toLowerCase());
        if (valueKeys.length > 0) {
          // first auto completed value
          this.selectValueKey(null, valueKeys[0], false);

        } else if (this.allowEnteredValue) {
          // entered value
          this.selectValueKey(null, this.searchString, false);
        }
      }

      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      if (!this.isDroppedDown) {
        this.toggleDropDown();
        return;
      }

      const numberOfItems = !this.values ? 0 : Object.keys(this.values).length;
      this.focusedIndex = ((this.focusedIndex + 1) < numberOfItems) ? this.focusedIndex + 1 : numberOfItems - 1;
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusedIndex = ((this.focusedIndex - 1) > 0) ? this.focusedIndex - 1 : 0;
      return;
    }

    if ((event.key === 'ArrowRight' || event.key === 'ArrowLeft') && !this.isAutoComplete) {
      event.preventDefault();
      return;
    }
  }

  triggerLoadAutoCompleteValues(event: KeyboardEvent): void {
    const keysToIgnore = [
      'Escape',
      'Tab',
      'Enter',
      'ArrowDown',
      'ArrowUp',
      'ArrowRight',
      'ArrowLeft',
      'Shift',
      'Control',
      'Capslock'];

    if (_.includes(keysToIgnore, event.key)) {
      return;
    }

    this.searchString = this.isAutoComplete ? this.dropDownInput.nativeElement.value : null;

    this.isLoadingAutoComplete = true;
    this.getAutocompleteValues(this.searchString).subscribe((values) => {
      this.values = {};
      this.focusedIndex = -1;

      if (this.allowEnteredValue && this.searchString.trim() !== '') {
        this.values[this.searchString] = new FormFieldsDropdownValue(this.searchString);
      }

      if (values) {
        Object.keys(values).forEach(key => {
          this.values[key] = values[key];
        });
      }

      this.isLoadingAutoComplete = false;

    }, (error) => {
      this.values = {};
      this.isLoadingAutoComplete = false;
    });
  }

  private getAutocompleteValues(searchString: string): Observable<{ [key: string]: FormFieldsDropdownValue }> {
    return new Observable<{ [key: string]: FormFieldsDropdownValue }>((subscriber) => {
      this.loadAutoCompleteValues.emit(new FormFieldsDropdownLoadAutocompleteValues(subscriber, searchString));
    });
  }

  openDropDown() {
    this.isDroppedDown = true;
  }

  closeDropDown() {
    this.isDroppedDown = false;
    this.focusedIndex = -1;

    if (this.isAutoComplete) {
      this.values = null;
      this.dropDownInput.nativeElement.value = '';
      this.searchString = '';
    }
  }

  toggle(event: Event) {
    MisEventUtils.stopEvent(event);
    this.toggleDropDown();
  }
}
