import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {colorValueValidator} from "../../../../basic/form/form-fields-color-picker/color.picker.validator";
import {File} from "../../../../basic/form/form-fields-file-upload/file.model";
import {MarketReportService} from "../../../../../services/market-report/market.report.service";
import {MarketReportLayout} from "../../../../../services/market-report/model/market.report.customization.model";

@Component({
  selector: 'mis-market-report-layout-modal',
  templateUrl: './market.report.layout.modal.component.html'
})
export class MarketReportLayoutModalComponent {
  @ViewChild('logoUpload') logoUploadInput;

  formGroup: FormGroup;
  isLoading: boolean;

  logoFile: File | null;

  constructor(public dialogRef: MatDialogRef<MarketReportLayoutModalComponent>,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private marketReportService: MarketReportService) {
    this.isLoading = true;
    this.loadCustomization();
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;

    const customization = new MarketReportLayout(
      this.logoFile,
      this.formGroup.get('introText').value,
      this.formGroup.get('introMainHeadingColor').value,
      this.formGroup.get('introSubHeadingColor').value,
      this.formGroup.get('mainHeadingColor').value,
      this.formGroup.get('subHeadingColor').value,
      this.formGroup.get('textColor').value,
      this.formGroup.get('chartColor1').value,
      this.formGroup.get('chartColor2').value,
      this.formGroup.get('chartColor3').value
    );

    this.marketReportService.saveMarketReportLayoutConfiguration(customization).subscribe({
      next: () => {
        this.snackBar.success('Die Marktberichtlayoutkonfiguration wurde erfolgreich gespeichert.');
        this.dialogRef.close();
      },
      error: () => {
        this.snackBar.error('Beim Aktualisieren der Marktberichtlayoutkonfiguration ist ein Fehler aufgetreten.');
        this.isLoading = false;
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  changedLogoFile(file: File) {
    this.logoFile = file;
  }

  private loadCustomization(): void {
    this.marketReportService.loadMarketReportLayout().subscribe({
      next: (customization) => {
        this.formGroup = this.formBuilder.group({
          'introText': [customization.introText, Validators.required],
          'introMainHeadingColor': [customization.introMainHeadingColor, Validators.required],
          'introSubHeadingColor': [customization.introSubHeadingColor, Validators.required],
          'mainHeadingColor': [customization.mainHeadingColor, Validators.compose([Validators.required, colorValueValidator()])],
          'subHeadingColor': [customization.subHeadingColor, Validators.compose([Validators.required, colorValueValidator()])],
          'textColor': [customization.textColor, Validators.compose([Validators.required, colorValueValidator()])],
          'chartColor1': [customization.chartColor1, Validators.compose([Validators.required, colorValueValidator()])],
          'chartColor2': [customization.chartColor2, Validators.compose([Validators.required, colorValueValidator()])],
          'chartColor3': [customization.chartColor3, Validators.compose([Validators.required, colorValueValidator()])],
        });

        this.logoFile = customization.logo;
        this.isLoading = false;
      },
      error: () => {
        // TODO
        this.isLoading = false;
      }
    });
  }
}

