export type AngebotsTyp = 'MIETE' | 'KAUF';

export type ImmobilienTyp = 'ZIMMER' | 'WOHNUNG' | 'HAUS' | 'ANLAGEOBJEKT' | 'GRUNDSTUECK' | 'ZWANGSVERSTEIGERUNG';

export type DateFilterType = 'EFFECTIVE_DAY' | 'TIME_RANGE' | 'NEWER_THAN' | 'OLDER_THAN' | 'NEWER_SINCE';

export class QuerySpec {
  immobilienTyp: ImmobilienTyp;
  angebotsTyp: AngebotsTyp;
  root: QueryField;
  page?: number;
  size?: number;
  sort?: Array<SortField>;

  constructor(query: QuerySpec = null) {
    if (query != null) {
      this.immobilienTyp = query.immobilienTyp;
      this.angebotsTyp = query.angebotsTyp;
      this.root = query.root;
      this.page = query.page;
      this.size = query.size;
      this.sort = query.sort;
    }
  }


  hasCityOrZipCodeFilters(): boolean {
    const rootFilter = this.root.combineList;

    let hasCity = false;
    let hasZipCode = false;

    rootFilter.forEach((locationFilterItem) => {
      if (locationFilterItem.field === 'ort') {
        hasCity = true;
      } else if (locationFilterItem.field === 'postleitzahl') {
        hasZipCode = true;
      }
    });

    return hasCity || hasZipCode;
  }
}

export class QueryField {
  field: FieldName;
  comparator: CompareOperation;
  bValue: boolean;
  sValue: string;
  sValueList: Array<string>;
  nValue: number;
  nValueList: Array<number>;
  dValue: Date;
  dValueList: Array<Date>;
  tValue: Date;
  tValueList: Array<Date>;
  tValueType: DateFilterType;
  tValueTypeArg: any;
  combineList: Array<QueryField>;
  combineWith: CombineOperation;
  combineListElementsWith: CombineOperation;

  public static eq(field: FieldName, value: any) {
    return QueryField.build(field, 'eq', value);
  }

  public static gth(field: FieldName, value: any) {
    return QueryField.build(field, 'gth', value);
  }

  public static gthOrEq(field: FieldName, value: any) {
    return QueryField.build(field, 'gthOrEq', value);
  }

  public static lth(field: FieldName, value: any) {
    return QueryField.build(field, 'lth', value);
  }

  public static lthOrEq(field: FieldName, value: any) {
    return QueryField.build(field, 'lthOrEq', value);
  }

  public static starts(field: FieldName, value: any) {
    return QueryField.build(field, 'starts', value);
  }

  public static like(field: FieldName, value: any) {
    return QueryField.build(field, 'contains', value);
  }

  public static between(field: FieldName, start: Date, end: Date) {
    const target = new QueryField();
    target.field = field;
    target.comparator = 'between';
    target.dValueList = [start, end];
    return target;
  }


  private static build(field: FieldName, comparator: CompareOperation, value: any) {
    const target = new QueryField();
    target.field = field;
    target.comparator = comparator;

    if (typeof value === 'string') {
      target.sValue = value;

    } else if (typeof value === 'boolean') {
      target.bValue = value;

    } else if (typeof value === 'number') {
      target.nValue = value;

    } else if (value instanceof Date) {
      target.tValue = value;
    }

    return target;
  }
}

export type CombineOperation = 'and' | 'or';

export type FieldName =
  'source'
  | 'firstOccurrenceDate'
  | 'createdDate'
  | 'validUntil'
  | 'quelle'
  | 'titel'
  | 'bundesland'
  | 'kreis'
  | 'ort'
  | 'ortsteil'
  | 'postleitzahl'
  | 'baujahr'
  | 'wohnflaeche'
  | 'grundstueckflaeche'
  | 'zimmerAnzahl'
  | 'badezimmer'
  | 'schlafzimmer'
  | 'etage'
  | 'etagenAnzahl'
  | 'preis'
  | 'preisJeQm'
  | 'nebenkosten'
  | 'heizkosten'
  | 'kaution'
  | 'latest'
  | 'freeOfCommission'
  | 'realtorContact'
  | 'realtorName'
  | 'realtorUrl'
  | 'extId'
  | 'realtorObjectId'
  | 'zustand'
  | 'ausstattung'
  | 'merkmale'
  | 'features';

export type CompareOperation =
  'eq'
  | 'nEq'
  | 'gth'
  | 'gthOrEq'
  | 'lth'
  | 'lthOrEq'
  | 'starts'
  | 'contains'
  | 'ends'
  | 'between';

export class SortField {
  constructor(public field: FieldName, public direction: SortDirection) {
  }
}

export type SortDirection = 'asc' | 'desc';
