import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MisEventUtils} from '../../../../services/common/event.util';

export type HintType = 'SUCCESS' | 'FAILED' | 'INFO' | 'WARN' | 'INFO-BORDERED';

@Component({
  selector: 'mis-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent {
  @Input() icon = 'fas fa-info-circle';
  @Input() type: HintType = 'INFO';
  @Input() closable: boolean;
  @Output() closed = new EventEmitter<void>();

  emitCloseEvent(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.closed.emit();
  }
}
