import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {LocalStorageService} from '../local.storage.service';
import {Observable} from 'rxjs';
import {ZipCodeMapPlaceMark} from './model/zip.code.map.place.mark.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MapService extends AbstractService {

  cache: {[key:string]: ZipCodeMapPlaceMark} = {};

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  getPolygonData(zipCode: string): Observable<ZipCodeMapPlaceMark> {
    return new Observable<ZipCodeMapPlaceMark>(subscriber => {
      if (this.cache.hasOwnProperty(zipCode)) {
        subscriber.next(this.cache[zipCode]);
        subscriber.complete();
        subscriber.unsubscribe();
        return;
      }

      this.GET<ZipCodeMapPlaceMark>(environment.misCdnEndpoint + '/zip_code/single/' + zipCode + '.json').subscribe((zipCodeMapPlaceMark) => {
        this.cache[zipCodeMapPlaceMark.zipCode] = zipCodeMapPlaceMark;
        subscriber.next(zipCodeMapPlaceMark);
      }, (error) => {
        subscriber.error(error);
      }, () => {
        subscriber.complete();
      });
    });
  }

  getZipCodePlaceMarks(level: number): Observable<ZipCodeMapPlaceMark[]> {
   return this.GET<ZipCodeMapPlaceMark[]>(environment.misCdnEndpoint + '/zip_code/zip-code-place-mark-list-level-' + level + '.json');
  }
}
