import {Pipe, PipeTransform} from '@angular/core';
import {MisUserRole} from '../services/aaa/model/account.model';

/**
 * Custom pipe to translate user role keys to words.
 */
@Pipe({name: 'misTranslateUserRole'})
export class TranslateUserRolePipe implements PipeTransform {

  transform(value: MisUserRole): string {
    switch (value) {
      case 'ACCOUNT_MEMBER':
        return 'Nutzer';
      case 'ACCOUNT_ADMIN':
        return 'Administrator';
      case 'SUPER_USER':
        return 'Super Admin';
      default:
        return '';
    }
  }
}
