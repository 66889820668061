<div class="mis-form-fields-date-picker-wrapper">
  <div class="mis-form-fields-date-picker"
       (click)="datePicker.open()">
    <div class="mis-form-fields-date-picker-input">
    <span class="mis-form-fields-date-picker-empty"
          *ngIf="!selectedDate">nicht ausgewählt</span>
      <span *ngIf="selectedDate">{{selectedDate | misFormatDate}}</span>
      <i class="fas fa-calendar-alt"></i>

      <div class="mis-form-fields-date-picker-input-field">
        <input matInput
               [matDatepicker]="datePicker"
               [min]="minDate"
               [max]="maxDate"
               [value]="selectedDate"
               (dateChange)="changeDate($event)">
      </div>

      <mat-datepicker #datePicker
                      [calendarHeaderComponent]="misMatDatePickerHeader"
                      (yearSelected)="selectedTodayDate($event)"></mat-datepicker>
    </div>
  </div>
</div>
