import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/basic/header/header.component';
import {HeaderNavigationComponent} from './components/basic/header/header-navigation/header.navigation.component';
import {HeaderNavigationItemComponent} from './components/basic/header/header-navigation/header-navigation-item/header.navigation.item.component';
import {LoadingComponent} from './components/basic/loading/loading.component';
import {InfoBoxComponent} from './components/basic/hint/info-box/info.box.component';
import {HintComponent} from './components/basic/hint/hint/hint.component';
import {FormFieldsPasswordComponent} from './components/basic/form/form-fields-password/form.fields.password.component';
import {FilterPaneComponent} from './components/common/immo-table/filter-pane/filter.pane.component';
import {RecaptchaComponent} from './components/basic/form/recaptcha/recaptcha.component';
import {FileuploadModalComponent} from './components/basic/modal/file-upload-modal/fileupload.modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NgxCaptchaModule} from 'ngx-captcha';
import {HttpClientModule} from '@angular/common/http';
import {LoginHomeComponent} from './components/public/authentication/login.home.component';
import {LoginWrapperComponent} from './components/public/authentication/login-wrapper/login.wrapper.component';
import {LoginComponent} from './components/public/authentication/login-wrapper/login/login.component';
import {PasswordForgottenWrapperComponent} from './components/public/authentication/login-wrapper/password-forgotten-wrapper/password.forgotten.wrapper.component';
import {PasswordForgottenStep1Component} from './components/public/authentication/login-wrapper/password-forgotten-wrapper/password-forgotten-step1/password.forgotten.step1.component';
import {PasswordForgottenStep2Component} from './components/public/authentication/login-wrapper/password-forgotten-wrapper/password-forgotten-step2/password.forgotten.step2.component';
import {RegistrationWrapperComponent} from './components/public/authentication/registration/registration.wrapper.component';
import {WatchlistComponent} from './components/secure/watchlist/watchlist.component';
import {ResearchComponent} from './components/secure/research/research.component';
import {EqualValidatorDirective} from './directives/equalValidator.directive';
import {AuthenticationGuard} from './services/aaa/authentication.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SnackbarComponent} from './components/basic/snackbar/snackbar.component';
import {ConfirmationModalComponent} from './components/basic/modal/confirmation-modal/confirmation.modal.component';
import {ImmoOverviewComponent} from './components/common/immo-table/immo.overview.component';
import {AccountManagementOverviewComponent} from './components/secure/account-management-overview/account.management.overview.component';
import {FieldBoxComponent} from './components/basic/field-box/field.box.component';
import {AccountManagementComponent} from './components/secure/account-management-overview/account-management/account.management.component';
import {AccountDataEditModalComponent} from './components/secure/account-management-overview/account-management/account-data-edit-modal/account.data.edit.modal.component';
import {ContactEditModalComponent} from './components/secure/account-management-overview/account-management/contact-edit-modal/contact.edit.modal.component';
import {ImmoAnalysisByFilterComponent} from './components/common/immo-table/immo-table/immo-table-details-pane/immo-analysis-by-filter/immo.analysis.by.filter.component';
import {EmptyResultHintComponent} from './components/basic/hint/empty-result-hint/empty.result.hint.component';
import {PlainBackendComponent} from './components/secure/plain-backend/plain.backend.component';
import {BulletChartComponent} from './components/common/chart/bullet-chart/bullet.chart.component';
import {RatingLabelComponent} from './components/common/rating-label/rating.label.component';
import {UserOverviewComponent} from './components/secure/account-management-overview/user-management/user-overview/user.overview.component';
import {TranslateSaluationPipe} from './pipes/translate.saluation.pipe';
import {TranslateUserRolePipe} from './pipes/translate.userRole.pipe';
import {TranslateContactTypePipe} from './pipes/translate.contactType.pipe';
import {UserCreateModalComponent} from './components/secure/account-management-overview/user-management/user-create-modal/user.create.modal.component';
import {UserEditModalComponent} from './components/secure/account-management-overview/user-management/user-details/user-edit-modal/user.edit.modal.component';
import {UserDetailsComponent} from './components/secure/account-management-overview/user-management/user-details/user.details.component';
import {MenuBarButtonComponent} from './components/common/menu-bar-button/menu.bar.button.component';
import {HeaderProfileItemComponent} from './components/basic/header/header-profile/header.profile.item.component';
import {SelfserviceComponent} from './components/secure/account-management-overview/selfservice/selfservice.component';
import {UserFlowFactDataEditModalComponent} from './components/secure/account-management-overview/user-management/user-details/user-flow-fact-data-edit-modal/user.flow.fact.data.edit.modal.component';
import {AccountFlowFactDataEditModalComponent} from './components/secure/account-management-overview/account-management/account-flow-fact-data-edit-modal/account.flow.fact.data.edit.modal.component';
import {PasswordChangeModalComponent} from './components/secure/account-management-overview/user-management/user-details/password-change-modal/password.change.modal.component';
import {ImmoManagementComponent} from './components/secure/immo-management/immo.management.component';
import {WatchIconComponent} from './components/common/immo-table/immo-table/immo-table-common/watch-icon/watch.icon.component';
import {FormFieldsFromUntilComponent} from './components/basic/form/form-fields-from-until/form.fields.from.until.component';
import {FormFieldHorizontalComponent} from './components/basic/form/form-field-horizonal/form.field.horizontal.component';
import {TranslateNumberFormatPipe} from './pipes/translate.numberFormat.pipe';
import {CrystalGalleryModule} from 'ngx-crystal-gallery';
import {FilterExtendedItemsComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter.extended.items.component';
import {MisMatDatePickerHeader} from './material/mis-mat-datepicker/mis.mat.date.picker.header';
import {TariffManagementComponent} from './components/secure/account-management-overview/tariff-management/tariff.management.component';
import {TariffItemComponent} from './components/secure/account-management-overview/tariff-management/tariff-items/tariff-item/tariff.item.component';
import {TariffItemsComponent} from './components/secure/account-management-overview/tariff-management/tariff-items/tariff.items.component';
import {FormFieldsDropdownComponent} from './components/basic/form/form-fields-dropdown/form.fields.dropdown.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {ImmoTableComponent} from './components/common/immo-table/immo-table/immo.table.component';
import {CiCdComponent} from './components/secure/ci-cd/ci.cd.component';
import {ColorCalculatorComponent} from './components/secure/ci-cd/color-calculator/color.calculator.component';
import {EscapeHtmlPipe} from './pipes/escape.html.pipe';
import {ImmoTableColumnImageComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-image/immo.table.column.image.component';
import {ImmoTableColumnDirective} from './components/common/immo-table/immo-table/immo-table-column/immo.table.column.directive';
import {ImmoTableColumnComponent} from './components/common/immo-table/immo-table/immo-table-column/immo.table.column.component';
import {ImmoTableColumnTextComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-text/immo.table.column.text.component';
import {ImmoTableColumnBooleanComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-boolean/immo.table.column.boolean.component';
import {ImmoTableColumnNumberComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-number/immo.table.column.number.component';
import {ImmoTableColumnSourceComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-source/immo.table.column.source.component';
import {ImmoTableColumnDateComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-date/immo.table.column.date.component';
import {FilterExtendedItemBooleanComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-boolean/filter.extended.item.boolean.component';
import {SavedSearchFiltersComponent} from './components/common/immo-table/immo-navigation/saved-searches-modal/saved-search-filters/saved.search.filters.component';
import {FilterExtendedItemTextComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-text/filter.extended.item.text.component';
import {FormFieldsInputComponent} from './components/basic/form/form-fields-input/form.fields.input.component';
import {FilterExtendedItemMinMaxComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-min-max/filter.extended.item.min.max.component';
import {FilterExtendedItemEnumComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-enum/filter.extended.item.enum.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ImmoTableColumnRealtorComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-realtor/immo.table.column.realtor.component';
import {ChartsModule} from 'ng2-charts';
import {ImmoPriceHistoryComponent} from './components/common/immo-table/immo-table/immo-table-details-pane/immo-price-history/immo.price.history.component';
import {SearchShareModalComponent} from './components/common/immo-table/immo-navigation/search-share-modal/search.share.modal.component';
import {FormFieldToggleComponent} from './components/basic/form/form-field-toggle/form-field-toggle.component';
import {LineChartComponent} from './components/common/chart/line-chart/line.chart.component';
import {ImmoTableColumnPriceWithTrendComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-price-with-trend/immo.table.column.price.with.trend.component';
import {SearchNameModalComponent} from './components/common/immo-table/immo-navigation/search-name-modal/search.name.modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ImmoTableDetailsPaneComponent} from './components/common/immo-table/immo-table/immo-table-details-pane/immo.table.details.pane.component';
import {SavedSearchesModalComponent} from './components/common/immo-table/immo-navigation/saved-searches-modal/saved.searches.modal.component';
import {TabsGroupComponent} from './components/basic/tabs/tabs-group.component';
import {TabItemComponent} from './components/basic/tabs/tab-item/tab-item.component';
import {SupportHintComponent} from './components/basic/hint/support-hint/support.hint.component';
import {ChallengesComponent} from './components/public/authentication/challenges/challenges.component';
import {SelfserviceVerificationComponent} from './components/secure/account-management-overview/selfservice-verification/selfservice.verification.component';
import {TariffChangeComponent} from './components/secure/account-management-overview/tariff-management/tariff-change/tariff.change.component';
import {MatMenuModule} from '@angular/material/menu';
import {FilterTimeRangeComponent} from './components/common/immo-table/filter-pane/filter-time-range/filter.time.range.component';
import {MatRadioModule} from '@angular/material/radio';
import {FormFieldsDatepickerComponent} from './components/basic/form/form-fields-datepicker/form.fields.datepicker.component';
import {DateFormatterPipe} from './pipes/date.formatter.pipe';
import {ImmoNavigationComponent} from './components/common/immo-table/immo-navigation/immo.navigation.component';
import {JoyrideModule} from 'ngx-joyride';
import {HeaderHelpItemComponent} from './components/basic/header/header-help/header.help.item.component';
import {FormFieldsDatetimePickerComponent} from './components/basic/form/form-fields-datetime-picker/form.fields.datetime.picker.component';
import {DebugInfoComponent} from './components/common/debug-info/debug.info.component';
import {FilterExtendedItemReferenceIdComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-reference-id/filter.extended.item.reference.id.component';
import {OffererSettingsModalComponent} from './components/common/immo-table/immo-navigation/offerer-settings-modal/offerer.settings.modal.component';
import {FilterExtendedItemOfferersComponent} from './components/common/immo-table/filter-pane/filter-extended-items/filter-extended-item-offerers/filter.extended.item.offerers.component';
import {HelpComponent} from './components/secure/help/help.component';
import {ImmoDetailsModalComponent} from './components/common/immo-table/immo-table/immo-table-details-pane/immo-details-modal/immo.details.modal.component';
import {ImmoAnalysisByObjectComponent} from './components/common/immo-table/immo-table/immo-table-details-pane/immo-analysis-by-object/immo.analysis.by.object.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {MapComponent} from './components/common/map/map.component';
import {TableActionIconButtonComponent} from './components/common/immo-table/immo-table/immo-table-common/table-action-icon-button/table.action.icon.button.component';
import {MisAgeTextPipe} from './pipes/mis-age-text.pipe';
import {ImmoTableColumnTitleComponent} from './components/common/immo-table/immo-table/immo-table-column/immo-table-column-title/immo-table-column-title.component';
import {HigherTariffRequiredHintComponent} from './components/basic/hint/higher-tariff-required-hint/higher.tariff.required.hint.component';
import {AdsenseComponent} from './components/basic/adsense/adsense.component';
import {FeedbackComponent} from './components/common/feedback/feedback.component';
import {FeedbackModalComponent} from './components/common/feedback/feedback-modal/feedback-modal.component';
import {PriceAtlasComponent} from './components/secure/price-atlas/price.atlas.component';
import {PriceAtlasDetailsComponent} from './components/secure/price-atlas/price-atlas-details/price.atlas.details.component';
import {PriceAtlasMapComponent} from './components/secure/price-atlas/price-atlas-map/price.atlas.map.component';
import {PriceAtlasFilterComponent} from './components/secure/price-atlas/price-atlas-filter/price-atlas-filter.component';
import {PriceAtlasMapLegendComponent} from './components/secure/price-atlas/price-atlas-map/price-atlas-map-legend/price.atlas.map.legend.component';
import {PriceAtlasMapToolbarComponent} from './components/secure/price-atlas/price-atlas-map/price-atlas-map-toolbar/price.atlas.map.toolbar.component';
import {PriceAtlasTableComponent} from './components/secure/price-atlas/price-atlas-table/price-atlas-table.component';
import {PriceAtlasDetailsConfigurationModalComponent} from './components/secure/price-atlas/price-atlas-details/price-atlas-details-configuration-modal/price-atlas-details-configuration-modal.component';
import {QuickInfoItemComponent} from './components/basic/quick-info-item/quick.info.item.component';
import {ColorPickerModule} from "ngx-color-picker";
import {FormFieldsFileUploadComponent} from "./components/basic/form/form-fields-file-upload/form-fields-file-upload.component";
import {FormFieldsColorPickerComponent} from "./components/basic/form/form-fields-color-picker/form-fields-color-picker.component";
import {PrintContainerComponent} from "./components/basic/print-container/print.container.component";
import {Nl2BrPipe} from "./pipes/nl2br.pipe";
import {MultiBulletChartComponent} from "./components/common/chart/multi-bullet-chart/multi.bullet.chart.component";
import {GraphicStatisticComponent} from "./components/common/chart/graphic-statistic/graphic-statistic.component";
import {MarketReportOverviewComponent} from "./components/secure/market-report-overview/market-report-overview.component";
import {MarketReportLayoutModalComponent} from "./components/secure/market-report-overview/market-report-configuration/market-report-layout-modal/market.report.layout.modal.component";
import {MarketReportDownloadOverviewComponent} from "./components/secure/market-report-overview/market-report-download/market-report-download-overview.component";
import {MarketReportExportComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/market.report.export.component";
import {MarketReportIntroPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/01-intro-page/market.report.intro.page.component";
import {MarketReportOverviewPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/02-market-overview-page/market.report.overview.page.component";
import {MarketReportCityShortReportPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/03-city-short-report/market.report.city.short.report.page.component";
import {MarketReportLeasingMarketPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/04-leasing-market/market.report.leasing.market.page.component";
import {MarketReportBuyerMarketPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/05-buyer-market/market.report.buyer.market.page.component";
import {MarketReportImprintPageComponent} from "./components/secure/market-report-overview/market-report-download/market-report-export/06-imprint/market.report.imprint.page.component";
import {MarketReportConfigurationOverviewComponent} from "./components/secure/market-report-overview/market-report-configuration/market-report-configuration-overview.component";
import {MarketReportConfigurationModalComponent} from "./components/secure/market-report-overview/market-report-download/market-report-configuration-modal/market-report-configuration-modal.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MIS_DATE_PICKER_FORMAT = {
  parse:   {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput:          'DD.MM.YYYY',
    monthYearLabel:     'MM YYYY',
    dateA11yLabel:      'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations:    [
    AppComponent,

    // public
    LoginHomeComponent,
    LoginWrapperComponent,
    LoginComponent,
    PasswordForgottenWrapperComponent,
    PasswordForgottenStep1Component,
    PasswordForgottenStep2Component,
    RegistrationWrapperComponent,
    ChallengesComponent,

    // secure
    WatchlistComponent,
    ResearchComponent,
    SavedSearchesModalComponent,
    AccountManagementOverviewComponent,
    AccountManagementComponent,
    AccountDataEditModalComponent,
    AccountFlowFactDataEditModalComponent,
    ContactEditModalComponent,
    UserOverviewComponent,
    UserCreateModalComponent,
    UserDetailsComponent,
    UserEditModalComponent,
    PasswordChangeModalComponent,
    UserFlowFactDataEditModalComponent,
    SelfserviceComponent,
    SelfserviceVerificationComponent,
    TariffManagementComponent,
    TariffItemsComponent,
    TariffItemComponent,
    TariffChangeComponent,
    ImmoManagementComponent,
    HelpComponent,

    // Marktbericht
    MarketReportOverviewComponent,

    // Marktbericht Konfiguration
    MarketReportConfigurationOverviewComponent,
    MarketReportLayoutModalComponent,
    MarketReportConfigurationModalComponent,

    // Marktbericht Download
    MarketReportDownloadOverviewComponent,
    MarketReportExportComponent,
    MarketReportIntroPageComponent,
    MarketReportOverviewPageComponent,
    MarketReportCityShortReportPageComponent,
    MarketReportLeasingMarketPageComponent,
    MarketReportBuyerMarketPageComponent,
    MarketReportImprintPageComponent,

    // dev components
    CiCdComponent,
    ColorCalculatorComponent,
    PlainBackendComponent,
    DebugInfoComponent,

    // common
    PrintContainerComponent,
    FeedbackComponent,
    FeedbackModalComponent,
    HeaderComponent,
    HeaderNavigationComponent,
    HeaderNavigationItemComponent,
    HeaderHelpItemComponent,
    LoadingComponent,
    QuickInfoItemComponent,
    InfoBoxComponent,
    FieldBoxComponent,
    HintComponent,
    SupportHintComponent,
    HigherTariffRequiredHintComponent,
    FormFieldHorizontalComponent,
    FormFieldsPasswordComponent,
    FormFieldsFromUntilComponent,
    FormFieldsDatetimePickerComponent,
    FormFieldsInputComponent,
    FormFieldsFileUploadComponent,
    FormFieldToggleComponent,
    FormFieldsColorPickerComponent,
    FormFieldsDatepickerComponent,
    RecaptchaComponent,
    FileuploadModalComponent,
    FormFieldsDropdownComponent,
    SnackbarComponent,
    ConfirmationModalComponent,
    EmptyResultHintComponent,
    RatingLabelComponent,
    MenuBarButtonComponent,
    HeaderProfileItemComponent,
    TabsGroupComponent,
    TabItemComponent,
    MapComponent,
    AdsenseComponent,

    FilterPaneComponent,
    FilterExtendedItemsComponent,
    FilterTimeRangeComponent,
    SavedSearchFiltersComponent,
    FilterExtendedItemBooleanComponent,
    FilterExtendedItemTextComponent,
    FilterExtendedItemMinMaxComponent,
    FilterExtendedItemEnumComponent,
    FilterExtendedItemReferenceIdComponent,
    FilterExtendedItemOfferersComponent,
    SearchShareModalComponent,
    SearchNameModalComponent,
    OffererSettingsModalComponent,

    ImmoTableComponent,
    ImmoTableColumnComponent,
    ImmoTableColumnDirective,
    ImmoTableColumnTextComponent,
    ImmoTableColumnTitleComponent,
    ImmoTableColumnImageComponent,
    ImmoTableColumnBooleanComponent,
    ImmoTableColumnNumberComponent,
    ImmoTableColumnSourceComponent,
    ImmoTableColumnDateComponent,
    ImmoTableColumnRealtorComponent,
    ImmoTableColumnPriceWithTrendComponent,

    ImmoOverviewComponent,
    ImmoNavigationComponent,
    ImmoTableDetailsPaneComponent,
    ImmoAnalysisByFilterComponent,
    ImmoAnalysisByObjectComponent,
    ImmoPriceHistoryComponent,

    ImmoDetailsModalComponent,
    TableActionIconButtonComponent,
    WatchIconComponent,
    PriceAtlasComponent,
    PriceAtlasMapComponent,
    PriceAtlasMapLegendComponent,
    PriceAtlasMapToolbarComponent,
    PriceAtlasFilterComponent,
    PriceAtlasDetailsComponent,
    PriceAtlasDetailsConfigurationModalComponent,
    PriceAtlasTableComponent,

    // Charts
    BulletChartComponent,
    GraphicStatisticComponent,
    LineChartComponent,
    MultiBulletChartComponent,

    // directives
    EqualValidatorDirective,

    // Pipes
    EscapeHtmlPipe,
    DateFormatterPipe,
    TranslateSaluationPipe,
    TranslateUserRolePipe,
    TranslateContactTypePipe,
    TranslateNumberFormatPipe,
    MisAgeTextPipe,
    Nl2BrPipe,

    // Material
    MisMatDatePickerHeader,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,

    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatChipsModule,
    MatAutocompleteModule,

    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,

    DragDropModule,
    NgxCaptchaModule,

    CrystalGalleryModule,

    ClickOutsideModule,
    ChartsModule,

    GoogleMapsModule,

    JoyrideModule.forRoot(),
    ColorPickerModule
  ],
  providers:       [
    AuthenticationGuard,
    {
      provide:  MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration:           5000,
        horizontalPosition: 'end',
        verticalPosition:   'top'
      }
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MIS_DATE_PICKER_FORMAT},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
  ],
  bootstrap:       [AppComponent],
  entryComponents: [
    FeedbackModalComponent,
    FileuploadModalComponent,
    ConfirmationModalComponent,
    SnackbarComponent,
    AccountDataEditModalComponent,
    ContactEditModalComponent,
    AccountFlowFactDataEditModalComponent,
    UserCreateModalComponent,
    UserEditModalComponent,
    UserFlowFactDataEditModalComponent,
    PasswordChangeModalComponent,
    MisMatDatePickerHeader,
    SearchShareModalComponent,
    SearchNameModalComponent,
    SavedSearchesModalComponent,
    OffererSettingsModalComponent,

    ImmoTableColumnTextComponent,
    ImmoTableColumnImageComponent,
    ImmoTableColumnBooleanComponent,
    ImmoTableColumnNumberComponent,
    ImmoTableColumnSourceComponent,
    ImmoTableColumnDateComponent,
    ImmoTableColumnRealtorComponent,
    ImmoTableColumnPriceWithTrendComponent,

    ImmoDetailsModalComponent,

    PriceAtlasDetailsConfigurationModalComponent
  ]
})
export class AppModule {
}
