<div class="mis-modal-medium">
  <h2 mat-dialog-title>Suchaufträge verwalten</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <mat-dialog-content *ngIf="!isLoading">
    <mis-empty-result-hint *ngIf="!savedSearches">Um eine E-Mail-Benachrichtigung zu erhalten, legen Sie mindestens
      einen Suchauftrag an.
    </mis-empty-result-hint>

    <div class="mis-saved-search-notification-container">
      <p [ngClass]="{'error': illegalNotificationFrequencySelected}">
        Intervall für E-Mail-Benachrichtigungen
      </p>
      <div>
        <mis-form-field-dropdown label="Benachrichtigungsintervall"
                                 [values]="availableNotificationFrequencies"
                                 [selectedValues]="[currentNotificationFrequency]"
                                 [disabled]="!isFrequencyEditable"
                                 (changed)="changeNotificationFrequency($event)"></mis-form-field-dropdown>

        <mis-higher-tariff-required-hint *ngIf="isTariffFree"
                                         size="SMALL">
          Im Tarif "Free" ist nur der Berechtigungsintervall "morgens" nutzbar.
        </mis-higher-tariff-required-hint>
      </div>

      <p>Off-Market Angebote als E-Mail-Benachrichtigung erhalten</p>
      <div class="checkbox">
        <mat-checkbox [checked]="isReceiveOffMarketOffers"
                      [disabled]="!isFrequencyEditable"
                      (change)="changedReceiveOffMarketOffers($event)">
          Off-Market Angebote erhalten.
        </mat-checkbox>
      </div>
    </div>

    <div class="mis-saved-searches-table">
      <div class="mis-saved-searches-table-header">
        <div class="mis-saved-searches-table-icon"></div>
        <div class="mis-saved-searches-table-content"></div>
        <div class="mis-saved-searches-table-abo">E-Mail Abo</div>
      </div>

      <div class="mis-saved-search-table-body"
           *ngFor="let savedSearch of savedSearches">
        <div class="mis-saved-searches-table-icon">
          <a href="#"
             (click)="toggleSearchDetails($event, savedSearch)"
             [ngClass]="{'opened': savedSearch.showDetails}"
             title="Details zur Suche anzeigen">
            <i class="fas fa-chevron-down"></i>
          </a>
        </div>
        <div class="mis-saved-searches-table-content">
          <div class="mis-saved-searches-table-content-title">
            {{savedSearch.name}}
          </div>

          <mis-saved-search-filters *ngIf="savedSearch.showDetails"
                                    [savedSearch]="savedSearch"></mis-saved-search-filters>

          <a href="#"
             (click)="applySearch($event, savedSearch)">Suche öffnen</a>
          <a href="#"
             (click)="deleteSearch($event, savedSearch)">Suche löschen</a>
        </div>
        <div class="mis-saved-searches-table-abo">
          <mat-checkbox [checked]="(savedSearch.subscriptions && savedSearch.subscriptions.length > 0)"
                        (change)="changeNotificationForSearch(savedSearch, $event.checked)">
          </mat-checkbox>
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane">
      <button class="mis-button-pane-back"
              cdkFocusInitial
              type="button"
              (click)="closeModal()">Schließen
      </button>
    </div>
  </mat-dialog-actions>
</div>
