<div *ngIf="changeStep === 1">
  <h2>Tarifwechsel Schritt 1 / 2</h2>

  <p>Sie möchten Ihren Tarif wechseln? Kein Problem.</p>
  <p>Geben Sie bitte Ihre Rechnungsdaten in das untenstehende Formular ein. Diese benötigen wir für die Ausstellung der
    monatlichen Rechnung.</p>

  <h3>Rechnungsdaten</h3>
  <div class="mis-row">
    <div class="mis-column-66">
      <mis-loading *ngIf="isLoading"></mis-loading>

      <form [formGroup]="formGroup"
            (submit)="saveForm()"
            *ngIf="!isLoading">

        <mat-form-field>
          <input matInput
                 formControlName="companyName"
                 name="company"
                 placeholder="Firma">
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="salutation"
                      placeholder="Anrede">
            <mat-option value="">-</mat-option>
            <mat-option value="MR">Herr</mat-option>
            <mat-option value="MRS">Frau</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="firstName"
                 name="firstName"
                 placeholder="Vorname"
                 required="required">
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="lastName"
                 name="lastName"
                 placeholder="Nachname"
                 required="required">
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="email"
                 name="email"
                 type="email"
                 placeholder="E-Mail-Adresse">
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="phone"
                 type="tel"
                 placeholder="Telefon">
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="address"
                 name="address"
                 placeholder="Anschrift"
                 required="required">
        </mat-form-field>

        <div class="mis-row mis-row-separated">
          <div class="mis-column-33">
            <mat-form-field>
              <input matInput
                     formControlName="zipCode"
                     name="zip"
                     placeholder="PLZ"
                     required="required">
            </mat-form-field>
          </div>
          <div class="mis-column-66">
            <mat-form-field>
              <input matInput
                     formControlName="city"
                     placeholder="Ort"
                     required="required">
            </mat-form-field>
          </div>
        </div>

        <mat-form-field>
          <mat-select formControlName="countryCode"
                      placeholder="Land"
                      [required]="true">
            <mat-option *ngFor="let country of countries"
                        [value]="country.code">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 formControlName="vatId"
                 placeholder="Umsatzsteuer ID">
        </mat-form-field>

        <div class="mis-button-pane">
          <button class="mis-button-pane-next"
                  type="submit">Weiter
          </button>
          <button class="mis-button-pane-back"
                  type="button"
                  (click)="cancelTariffChange.emit()">
            Abbrechen
          </button>
        </div>
      </form>
    </div>
    <div class="mis-column-33">
      <mis-info-box heading="asdasdas">asdasdasdasd asd asd as da sd asd asd
      </mis-info-box>
    </div>
  </div>
</div>

<div *ngIf="changeStep === 2">
  <h2>Tarifwechsel Schritt 2 / 2</h2>

  <p>asdasdasdasdas da sd asd as dasdasdas da d ads weg rh srtj age W FE G F AD A</p>

  <h3>Zusammenfassung</h3>

  <mis-field-box label="Rechnungsanschrift">
    <div>{{formGroup.get('companyName').value}}</div>
    <div>{{formGroup.get('salutation').value ? (formGroup.get('salutation').value === 'MR' ? 'Herr' : (formGroup.get('salutation').value === 'MRS' ? 'Frau' : '')) : ''}}</div>
    <div>{{formGroup.get('firstName').value}} {{formGroup.get('lastName').value}}</div>
    <div>{{formGroup.get('address').value}}</div>
    <div>{{formGroup.get('zipCode').value}} {{formGroup.get('city').value}}</div>
    <div *ngIf="formGroup.get('vatId').value">
      Umsatzsteuernummer: {{formGroup.get('vatId').value}}
    </div>
  </mis-field-box>

  <mis-field-box label="Rechnungskontakt">
    <div>{{formGroup.get('salutation').value ? (formGroup.get('salutation').value === 'MR' ? 'Herr' : (formGroup.get('salutation').value === 'MRS' ? 'Frau' : '')) : ''}}</div>
    <div>{{formGroup.get('firstName').value}} {{formGroup.get('lastName').value}}</div>
    <div>{{formGroup.get('email').value}}</div>
    <div>{{formGroup.get('phone').value}}</div>
  </mis-field-box>

  <mis-field-box label="Ausgewählter Tarif">
    <div>{{tariffInfo.title}}</div>
    <b>{{tariffInfo.price.getCurrentEuroValue()}},{{tariffInfo.price.getCurrentCentValue()}} €</b> / Monat je Nutzer
  </mis-field-box>

  <div class="mis-button-pane">
    <button class="mis-button-pane-next"
            (click)="changeTariff()">Tarif jetzt kostenpflichtig ändern.
    </button>
    <button class="mis-button-pane-back"
            (click)="cancelTariffChange.emit()">
      Abbrechen
    </button>
  </div>
</div>

<div *ngIf="changeStep === 3">
  <h2>Tarifwechsel Schritt 2 / 2</h2>

  <p>asdasdasdasdas da sd asd as dasdasdas da d ads weg rh srtj age W FE G F AD A</p>

  <mis-loading *ngIf="isLoading"></mis-loading>
  <mis-support-hint *ngIf="tariffChangeFailed"></mis-support-hint>

  <div *ngIf="!isLoading" class="mis-button-pane">
    <button class="mis-button-pane-back"
            type="button"
            (click)="redirectToResearch()">
      Zur Marktrecherche
    </button>
  </div>
</div>
