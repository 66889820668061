import {Component} from '@angular/core';
import {FeedbackModalComponent} from './feedback-modal/feedback-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ViewServiceInstance} from '../../../viewservice/view.service';

@Component({
  selector:    'mis-feedback',
  templateUrl: './feedback.component.html',
  styleUrls:   ['./feedback.component.scss']
})
export class FeedbackComponent {
  viewServiceInstance = ViewServiceInstance;

  constructor(private dialog: MatDialog) {
  }

  toggleBugReport(): void {
    this.dialog.open(FeedbackModalComponent);
  }
}
