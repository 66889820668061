<div class="mis-filter-extended-item-min-max">

  <mis-form-field-from-until [title]="label"
                             placeHolderFrom="ab"
                             placeHolderUntil="bis"
                             controlNamePrefix="value"
                             [defaultFrom]="minValue"
                             [defaultUntil]="maxValue"
                             [steps]="steps"
                             [decimalDigits]="decimalDigits"
                             [buffer]="-1"
                             [useThousandSeparator]="useThousandSeparator"
                             (changedValues)="changed.emit($event)"></mis-form-field-from-until>

</div>
