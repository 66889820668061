import {Pipe, PipeTransform} from '@angular/core';
import {MisContactType} from '../services/aaa/model/account.model';

/**
 * Custom pipe to translate contact type or types to words.
 */
@Pipe({name: 'misTranslateContactType'})
export class TranslateContactTypePipe implements PipeTransform {

  transform(value: MisContactType | MisContactType[]): string {

    if (value == null) {
      return '';
    }

    if (value instanceof Array) {
      return value.map(type => this.translateContactType(type))
        .filter(type => type != null)
        .join(',');
    }

    return this.translateContactType(value);
  }

  private translateContactType(type: MisContactType): string {
    switch (type) {
      case 'BILLING':
        return 'Rechnungskontakt';
      case 'TECHNICAL':
        return 'Technischer Ansprechpartner';
      case 'USER':
      default:
        return null;

    }
  }
}
