<div class="tabs-group">
  <ul *ngIf="allTabs"
      class="tabs-group-header">
    <li *ngFor="let tab of allTabs">
      <a href="#"
         [ngClass]="{'tab-active': tab.active}"
         (click)="selectTab($event, tab)"
         [title]="tab.tabTitle">
        {{tab.tabTitle}}
        <i *ngIf="tab.tabTitleIcon"
           class="{{tab.tabTitleIcon}}"></i>
      </a>
    </li>
  </ul>

  <div class="tabs-group-body">
    <ng-content></ng-content>
  </div>
</div>
