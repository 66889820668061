import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MisAccount} from '../../../../../services/aaa/model/account.model';
import {COUNTRY_CODES, CountryCode} from '../../../../../services/common/country.codes.util';
import {AccountService} from '../../../../../services/aaa/account.service';
import {MisBaseUtils} from '../../../../../services/common/base.util';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';

export interface AccountDataEditModalData {
  account: MisAccount;
}

@Component({
  selector: 'mis-account-data-edit-modal',
  templateUrl: './account.data.edit.modal.component.html'
})
export class AccountDataEditModalComponent {
  account: MisAccount;
  formGroup: FormGroup;
  countries: CountryCode[];
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<AccountDataEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: AccountDataEditModalData,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private accountService: AccountService) {
    this.account = modalData.account;
    this.countries = COUNTRY_CODES;

    this.formGroup = formBuilder.group({
      'companyName': [this.account.companyName, Validators.required],
      'address': [this.account.address, Validators.required],
      'zipCode': [this.account.zipCode, Validators.required],
      'city': [this.account.city, Validators.required],
      'countryCode': [this.account.countryCode, Validators.required],
      'vatId': [this.account.vatId],
      'web': [this.account.web]
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    this.prepareAccount();

    this.accountService.updateAccount(this.account).subscribe((account) => {
      this.snackBar.success('Die Kontodaten wurden erfolgreich aktualisiert.');
      this.dialogRef.close();
    }, (err) => {
      this.isLoading = false;
      this.snackBar.error('Beim Aktualisieren der Kontodaten ist ein Fehler aufgetreten.');
    });
  }

  private prepareAccount(): void {
    this.account.companyName = this.formGroup.get('companyName').value;
    this.account.address = this.formGroup.get('address').value;
    this.account.zipCode = this.formGroup.get('zipCode').value;
    this.account.city = this.formGroup.get('city').value;
    this.account.countryCode = this.formGroup.get('countryCode').value;
    this.account.vatId = MisBaseUtils.getValueOrDefault(this.formGroup.get('vatId').value, null);
    this.account.web = MisBaseUtils.getValueOrDefault(this.formGroup.get('web').value, null);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}

