import {
  AbstractImmoTableFilter,
  ImmoTableBooleanFilter,
  ImmoTableEnumFilter,
  ImmoTableFilterValueWithConditionalFilters,
  ImmoTableNumberMinMaxFilter,
  ImmoTableOffererFilter,
  ImmoTableReferenceIdFilter,
  ImmoTableTextFilter,
  OfferTypeFilters
} from './immo.table.classes';

/**
 * Filter Konstanten für Enum values / Auswahlfilter.
 */
export const IMMO_SOURCE_VALUES = {
  'Immobilienscout24':    'Immobilienscout 24',
  'Immowelt':             'Immowelt',
  'Ebay-Kleinanzeigen':   'Ebay-Kleinanzeigen',
  'RegionalImmobilien24': 'RegionalImmobilien24',
};

export const IMMO_AUSSTATTUNG_VALUES = {
  'UNBEKANNT': 'Unbekannt',
  'EINFACH':   'Einfach',
  'NORMAL':    'Normal',
  'GEHOBEN':   'Gehoben',
  'LUXURIOES': 'Luxoriös',
};

export const IMMO_ZUSTAND_VALUES = {
  'UNBEKANNT':              'Unbekannt',
  'GEHOBEN':                'Gehoben',
  'GEPFLEGT':               'Gepflegt',
  'ERSTBEZUG':              'Erstbezug',
  'NEUWERTIG':              'Neuwertig',
  'RENOVIERT':              'Renoviert',
  'SANIERT':                'Saniert',
  'MODERNISIERT':           'Modernisiert',
  'NACH_VEREINBARUNG':      'Nach Vereinbarung',
  'RENOVIERUNGSBEDUERFTIG': 'Renovierungsbedürftig',
  'ABBRUCHREIF':            'Abbruchreif',
};

export const IMMO_FEATURES_VALUES = {
  'ZENTRALHEIZUNG':          'Zentralheizung',
  'EINBAUKUECHE':            'Einbauküche',
  'STELLPLATZ':              'Garage / Stellplatz',
  'BALKON':                  'Balkon / Terrasse',
  'GARTEN':                  'Garten / -mitbenutzung',
  'BAD_MIT_WANNE':           'Bad mit Wanne',
  'GAESTE_WC':               'Gäste WC',
  'KELLERANTEIL':            'Keller',
  'DIELENBODEN':             'Dielenboden',
  'SANIERT':                 'saniert',
  'RENOVIERT':               'renoviert',
  'NEUBAU':                  'Neubau',
  'FREI':                    'frei',
  'WOHNBERECHTIGUNGSSCHEIN': 'Wohnberechtigungsschein erforderlich',
  'WG_GEEIGNET':             'WG geeignet',
  'BARRIEREFREI':            'barrierefrei',
  'PERSONENAUFZUG':          'Personenaufzug',
  'KLIMATISIERT':            'klimatisiert',
  'FERIENIMMOBILIE':         'Ferienimmobilie',
  'PROVISIONSFREI':          'Provisionsfrei',
  'KURZFRISTIG_BEBAUBAR':    'Kurzfristig bebaubar',
  'VERMIETET':               'Vermietet',
  'ZWANGSVERSTEIGERUNG':     'Zwangsversteigerung',
  'ZWEITVERSTEIGERUNG':      'Zweitversteigerung'
};

/**
 * Filter Konfiguration. Verfügbare Filter und deren Eigenschaften.
 * Die Reihenfolge wird im Dropdown Menü so verwendet.
 */
export const IMMO_EXTENDED_FILTERS = {
  SOURCE:       new ImmoTableEnumFilter('source', 'Quelle', IMMO_SOURCE_VALUES),
  REFERENCE_ID: new ImmoTableReferenceIdFilter('extId', 'Referenz-ID'),

  MIETE:           new ImmoTableNumberMinMaxFilter('preis', 'Miete', 0, 100),
  MIETE_JE_QM:     new ImmoTableNumberMinMaxFilter('preisJeQm', 'Miete je m²', 2, 1),
  KAUFPREIS:       new ImmoTableNumberMinMaxFilter('preis', 'Kaufpreis', 0, 10_000),
  KAUFPREIS_JE_QM: new ImmoTableNumberMinMaxFilter('preisJeQm', 'Kaufpreis je m²', 0, 100),
  NEBENKOSTEN:     new ImmoTableNumberMinMaxFilter('nebenkosten', 'Nebenkosten', 2, 10),
  HEIZKOSTEN:      new ImmoTableNumberMinMaxFilter('heizkosten', 'Heizkosten', 2, 10),
  KAUTION:         new ImmoTableNumberMinMaxFilter('kaution', 'Kaution', 2, 100),

  WOHNFLAECHE:        new ImmoTableNumberMinMaxFilter('wohnflaeche', 'Wohnfläche', 0, 5),
  GRUNDSTUECKFLAECHE: new ImmoTableNumberMinMaxFilter('grundstueckflaeche', 'Grundstücksfläche', 0, 100),

  FREE_OF_COMMISSION: new ImmoTableBooleanFilter('freeOfCommission', 'Provision'),
  REALTOR_NAME:       new ImmoTableOffererFilter('realtorName', 'Anbieter'),
  ANBIETER_OBJEKT_ID: new ImmoTableTextFilter('realtorObjectId', 'Anbieter Objekt-Id'),

  BAUJAHR:       new ImmoTableNumberMinMaxFilter('baujahr', 'Baujahr', 0, 10, false),
  ETAGE:         new ImmoTableNumberMinMaxFilter('etage', 'Etage', 0, 1),
  ETAGEN_ANZAHL: new ImmoTableNumberMinMaxFilter('etagenAnzahl', 'Etagenanzahl', 0, 1),

  ZIMMER_ANZAHL: new ImmoTableNumberMinMaxFilter('zimmerAnzahl', 'Zimmer Anzahl', 1, 0.5),
  BADEZIMMER:    new ImmoTableNumberMinMaxFilter('badezimmer', 'Badezimmer', 0, 1),
  SCHLAFZIMMER:  new ImmoTableNumberMinMaxFilter('schlafzimmer', 'Schlafzimmer', 0, 1),

  ZUSTAND:     new ImmoTableEnumFilter('zustand', 'Zustand', IMMO_ZUSTAND_VALUES, true, 'or'),
  AUSSTATTUNG: new ImmoTableEnumFilter('ausstattung', 'Ausstattung', IMMO_AUSSTATTUNG_VALUES, true, 'or'),
  FEATURES:    new ImmoTableEnumFilter('features', 'Merkmale', IMMO_FEATURES_VALUES, true, 'and'),
};

export const IMMO_EXTENDED_FILTERS_CONFIGURATIONS: AbstractImmoTableFilter[] = Object.values(IMMO_EXTENDED_FILTERS);

/**
 * Hauptfilter - Immobilien Typ
 * inkl. Filterabhängigkeit.
 */
export const IMMO_MIETE_PROPERTY_FILTERS: { [key: string]: ImmoTableFilterValueWithConditionalFilters } = {
  WOHNUNG:     new ImmoTableFilterValueWithConditionalFilters('WOHNUNG', 'Wohnung', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.MIETE,
    IMMO_EXTENDED_FILTERS.MIETE_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.SCHLAFZIMMER,
    IMMO_EXTENDED_FILTERS.BADEZIMMER,
    IMMO_EXTENDED_FILTERS.ETAGE,
    IMMO_EXTENDED_FILTERS.ETAGEN_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.KAUTION,
    IMMO_EXTENDED_FILTERS.HEIZKOSTEN,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  ZIMMER:      new ImmoTableFilterValueWithConditionalFilters('ZIMMER', 'Zimmer', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.MIETE,
    IMMO_EXTENDED_FILTERS.MIETE_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.KAUTION,
    IMMO_EXTENDED_FILTERS.HEIZKOSTEN,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  HAUS:        new ImmoTableFilterValueWithConditionalFilters('HAUS', 'Haus', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.MIETE,
    IMMO_EXTENDED_FILTERS.MIETE_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.SCHLAFZIMMER,
    IMMO_EXTENDED_FILTERS.BADEZIMMER,
    IMMO_EXTENDED_FILTERS.ETAGEN_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.KAUTION,
    IMMO_EXTENDED_FILTERS.HEIZKOSTEN,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  GRUNDSTUECK: new ImmoTableFilterValueWithConditionalFilters('GRUNDSTUECK', 'Grundstück', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.MIETE,
    IMMO_EXTENDED_FILTERS.MIETE_JE_QM,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ])
};

export const IMMO_KAUF_PROPERTY_FILTERS: { [key: string]: ImmoTableFilterValueWithConditionalFilters } = {
  WOHNUNG:             new ImmoTableFilterValueWithConditionalFilters('WOHNUNG', 'Wohnung', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.KAUFPREIS,
    IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.SCHLAFZIMMER,
    IMMO_EXTENDED_FILTERS.BADEZIMMER,
    IMMO_EXTENDED_FILTERS.ETAGE,
    IMMO_EXTENDED_FILTERS.ETAGEN_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.HEIZKOSTEN,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  HAUS:                new ImmoTableFilterValueWithConditionalFilters('HAUS', 'Haus', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.KAUFPREIS,
    IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.SCHLAFZIMMER,
    IMMO_EXTENDED_FILTERS.BADEZIMMER,
    IMMO_EXTENDED_FILTERS.ETAGEN_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.KAUTION,
    IMMO_EXTENDED_FILTERS.HEIZKOSTEN,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  ANLAGEOBJEKT:        new ImmoTableFilterValueWithConditionalFilters('ANLAGEOBJEKT', 'Anlageobjekt', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.KAUFPREIS,
    IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.NEBENKOSTEN,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  GRUNDSTUECK:         new ImmoTableFilterValueWithConditionalFilters('GRUNDSTUECK', 'Grundstück', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.KAUFPREIS,
    IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.FREE_OF_COMMISSION,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
  ZWANGSVERSTEIGERUNG: new ImmoTableFilterValueWithConditionalFilters('ZWANGSVERSTEIGERUNG', 'Zwangsversteigerung', [
    IMMO_EXTENDED_FILTERS.SOURCE,
    IMMO_EXTENDED_FILTERS.KAUFPREIS,
    IMMO_EXTENDED_FILTERS.KAUFPREIS_JE_QM,
    IMMO_EXTENDED_FILTERS.WOHNFLAECHE,
    IMMO_EXTENDED_FILTERS.GRUNDSTUECKFLAECHE,
    IMMO_EXTENDED_FILTERS.ZIMMER_ANZAHL,
    IMMO_EXTENDED_FILTERS.BAUJAHR,
    IMMO_EXTENDED_FILTERS.AUSSTATTUNG,
    IMMO_EXTENDED_FILTERS.ZUSTAND,
    // IMMO_EXTENDED_FILTERS.FEATURES,
    IMMO_EXTENDED_FILTERS.REALTOR_NAME,
    IMMO_EXTENDED_FILTERS.ANBIETER_OBJEKT_ID,
    IMMO_EXTENDED_FILTERS.REFERENCE_ID,
  ]),
};


/**
 * Hauptfilter - Angebotstyp
 * inkl. Filterabhängigkeit.
 */
export const IMMO_OFFER_FILTERS = [
  new OfferTypeFilters('MIETE', 'Miete', IMMO_MIETE_PROPERTY_FILTERS),
  new OfferTypeFilters('KAUF', 'Kauf', IMMO_KAUF_PROPERTY_FILTERS)
];
