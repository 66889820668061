export class MisBaseUtils {

  static getValueOrDefault(value: any, defaultValue: any): any {
    if (value == null || value === '') {
      return defaultValue;
    }
    return value;
  }

  /**
   * Formatiert eine Zahl und setzt Tausendertrennen, sowie ggf. Nachkommastellen.
   *
   * @param number number oder string. String als Zahl ohne tausendertrenner! z.B. 100000,5. wird zu 100.000,50
   * @param fractionDigits Anzahl der Nachkommastellen.
   * @param defaultValue Standardwert, sofern der Eingangswert null ist.
   * @param useThousandSeparator Soll ein Tausender Separator eingefügt werden?
   */
  static formatNumber(number: string | number, fractionDigits: number = 2, defaultValue = '-', useThousandSeparator: boolean = true): string {
    if (!number) {
      return defaultValue;
    }

    let float = parseFloat('' + number);
    if (!float) {
      return defaultValue;
    }

    let result = Intl.NumberFormat('de-DE', {
      // useGrouping: true,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }).format(float);

    if (!useThousandSeparator) {
      result = result.replace('.', '');
    }
    return result;
  }

  static parseNumber(value: string): number {
    if (!value) {
      return null;
    }

    let parsedNumber = ('' + value);
    parsedNumber = parsedNumber.replace(/\./g, '');
    parsedNumber = parsedNumber.replace(/,/g, '.');
    return parseFloat(parsedNumber);
  }
}
