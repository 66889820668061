<div class="mis-hint mis-hint-type-{{type | lowercase}}">
  <div class="mis-hint-icon">
    <i class="{{icon}}"></i>
  </div>
  <div class="mis-hint-content">
    <a *ngIf="closable"
       href="#"
       (click)="emitCloseEvent($event)"
       class="mis-hint-close"
       title="Schließen">
      <i class="fas fa-times"></i>
    </a>

    <ng-content></ng-content>
  </div>
</div>
