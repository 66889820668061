import {Component} from '@angular/core';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../viewservice/view.service';

@Component({
  selector:    'mis-debug-info',
  templateUrl: './debug.info.component.html',
  styleUrls:   ['./debug.info.component.scss']
})
export class DebugInfoComponent implements ViewServiceChangeListener {
  windowHeight: string;
  windowWidth: string;
  viewServiceInstance = ViewServiceInstance;

  debugEnabled = false;

  constructor() {
    ViewServiceInstance.listenOnResize(this);
  }

  onResize(): void {
    this.windowWidth = window.innerWidth + 'px';
    this.windowHeight = window.innerHeight + 'px';
  }
}
