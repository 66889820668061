<div class="mis-fileupload">
  <h2 mat-dialog-title>{{modalData.title}}</h2>

  <mat-dialog-content>
    <p>Laden Sie jetzt all Ihre Immobilien hoch um sie mit PropFox analysieren zu können.</p>
    <p>Unter folgendem Link können Sie das Template einer Excelliste herunterladen, welche das Format für zu
      importierende Datei beschreibt.
      <br>
      <br>
      <a href="../../../../../assets/downloads/propfox_importvorlage.xlsx"><i class="fas fa-download"></i>&nbsp;&nbsp;&nbsp;Excel
        Template für den Import</a>
    </p>
    <form [formGroup]="formGroup"
          (submit)="startFileUpload()"
          *ngIf="!isLoading">
      <input formControlName="file"
             #file
             name="file"
             type="file"
             required="required">

      <div class="mis-error" *ngIf="uploadFailed">
        <p>Das Hochladen der Datei ist fehlgeschlagen. Bitte prüfen Sie ob das Datei-Format der Vorlage entspricht.</p>
      </div>

      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Datei hochladen</button>
        <button class="mis-button-pane-back"
        [mat-dialog-close]="true">Abbrechen</button>
      </div>
    </form>

    <mis-loading *ngIf="isLoading"></mis-loading>

  </mat-dialog-content>
</div>
