import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

export enum EventKey {
  WATCHLIST_CHANGED = 'WATCHLIST_CHANGED',
  HEADER_MENU_OPENED = 'HEADER_MENU_OPENED',
}

@Injectable({providedIn: 'root'})
export class EventService {
  private listeners = {};
  private observer: Subscriber<any>;
  private observable: Observable<any>;

  constructor() {
    this.observable = new Observable<any>((observer) => {
      this.observer = observer;
    });

    this.observable.subscribe(
      ({name, args}) => {
        if (this.listeners[name]) {
          for (const listener of this.listeners[name]) {
            listener(...args);
          }
        }
      });
  }

  on(name: EventKey, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  broadcast(name: EventKey, ...args) {
    this.observer.next({
      name,
      args
    });
  }
}
