<div class="mis-user-overview">

  <div class="mis-column-100 mis-menu-bar">
    <div class="mis-column-boxed">
      <mis-menu-bar-button label="Nutzer anlegen"
                           icon="fas fa-user-plus"
                           (clickAction)="createUser()"></mis-menu-bar-button>

      <mis-menu-bar-button label="Aktualisieren"
                           icon="fas fa-redo"
                           (clickAction)="loadUsers()"></mis-menu-bar-button>
    </div>
  </div>

  <table>
    <thead>
    <tr>
      <th>Anrede</th>
      <th>Vorname</th>
      <th>Nachname</th>
      <th>E-Mail-Adresse</th>
      <th>Rolle</th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngIf="isLoading">
      <td colspan="3">
        <mis-loading></mis-loading>
      </td>
    </tr>

    <ng-container *ngIf="!isLoading">
      <tr *ngFor="let user of users"
          class="{{user.role === 'INACTIVE' ? 'mis-user-overview-inactive' : ''}}">
        <td>{{user.contact.salutation | misTranslateSalutation}}</td>
        <td>{{user.contact.firstName}}</td>
        <td>{{user.contact.lastName}}</td>
        <td>{{user.contact.email}}
          <div>{{user.contact.phone}}</div>
          <div>{{user.contact.mobile}}</div>
        </td>
        <td>{{user.role | misTranslateUserRole}}</td>
        <td class="table-actions">
          <a href="#"
             (click)="editUser($event, user)"
             title="Nutzerdaten bearbeiten">
            <i class="fas fa-user-edit"></i>
          </a>
          <a *ngIf="hasUserFlowFactDataTags(user)"
             href="#"
             (click)="editUserFlowFactData($event, user)"
             title="FlowFact Einstellungen bearbeiten">
            <i class="fas fa-cogs"></i>
          </a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
