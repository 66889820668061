import {Component, Input} from '@angular/core';

@Component({
  selector: 'mis-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() size: 'tiny' | 'small' | 'medium' = 'medium';
}
