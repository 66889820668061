import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MisTagUtils, MisUser} from '../../../../../../services/aaa/model/account.model';
import {UserService} from '../../../../../../services/aaa/user.service';
import {MisBaseUtils} from '../../../../../../services/common/base.util';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../../basic/snackbar/snackbar.service';

@Component({
  selector: 'mis-user-flow-fact-data-edit-modal',
  templateUrl: './user.flow.fact.data.edit.modal.component.html'
})
export class UserFlowFactDataEditModalComponent {
  user: MisUser;
  formGroup: FormGroup;
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<UserFlowFactDataEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: MisUser,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private userService: UserService) {
    this.user = modalData;

    this.formGroup = formBuilder.group({
      'flowFactUserName': [MisTagUtils.getTagByKey(this.user.tags, MisTagUtils.keys.flowFactServerUserName).value],
      'flowFactPassword': ['']
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    this.updateUserTag();
  }

  updateUserTag(): void {

    // update flow fact user name
    const flowFactUserNameTag = MisTagUtils.getTagByKey(this.user.tags, MisTagUtils.keys.flowFactServerUserName);
    flowFactUserNameTag.value = MisBaseUtils.getValueOrDefault(this.formGroup.get('flowFactUserName').value, null);
    this.userService.updateUserTag(this.user.id, flowFactUserNameTag).subscribe(() => {

      // update password
      const flowFactUserPasswordTag = MisTagUtils.getTagByKey(this.user.tags, MisTagUtils.keys.flowFactServerUserPassword);
      flowFactUserPasswordTag.value = MisBaseUtils.getValueOrDefault(this.formGroup.get('flowFactPassword').value, null);
      this.userService.updateUserTag(this.user.id, flowFactUserPasswordTag).subscribe(() => {
        this.showSuccessHint();
      }, (err) => {
        this.showFailedHint();
      });

    }, (err) => {
      this.showFailedHint();
    });
  }

  private showSuccessHint(): void {
    this.snackBar.success('Die FlowFact Einstellungen wurden erfolgreich übernommen.');
    this.dialogRef.close(true);
  }

  private showFailedHint(): void {
    this.snackBar.error('Die Aktualisierung der FlowFact Einstellungen ist fehlgeschlagen.');
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
