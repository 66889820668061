import {Component, Input} from '@angular/core';
import {AbstractFormComponent} from '../../../basic/form/abstract.form.component';
import {FormBuilder, Validators} from '@angular/forms';
import {AccountService} from '../../../../services/aaa/account.service';
import {PasswordValidator} from '../../../basic/form/form-fields-password/form.fields.password.component';
import {MisReCaptcha, MisRegisterAccount} from '../../../../services/aaa/model/account.model';
import {LocalStorageService} from '../../../../services/local.storage.service';
import {AuthenticationService} from '../../../../services/aaa/authentication.service';
import {RoutingService} from '../../../../services/routing.service';

@Component({
  selector: 'mis-registration-wrapper',
  templateUrl: './registration.wrapper.component.html',
})
export class RegistrationWrapperComponent extends AbstractFormComponent {
  @Input() isRegistrationVisible: boolean;
  registrationFailedShowSupportHint: boolean;
  registrationFailedShowEmailHint: boolean;
  registrationFailedShowPasswordHint: boolean;

  constructor(formBuilder: FormBuilder,
              private accountService: AccountService,
              private authenticationService: AuthenticationService,
              private routingService: RoutingService,
              private localStorageService: LocalStorageService) {
    super();

    this.formGroup = formBuilder.group({
      'email': ['', Validators.required],
      'password': ['', PasswordValidator],
      'termsAndPrivacy': ['', Validators.requiredTrue],
      'newsletter': [''],
    });
  }

  customSaveAction(): void {
    this.registrationFailedShowPasswordHint = false;
    this.registrationFailedShowEmailHint = false;
    this.registrationFailedShowSupportHint = false;

    const registerAccount = new MisRegisterAccount();
    registerAccount.email = this.formGroup.get('email').value;
    registerAccount.password = this.formGroup.get('password').value;
    registerAccount.promotionCode = this.localStorageService.get('registrationCode');

    registerAccount.reCaptcha = new MisReCaptcha();
    registerAccount.reCaptcha.reCaptchaResponse = this.formGroup.get('recaptchaResponse').value;
    registerAccount.reCaptcha.reCaptchaId = this.formGroup.get('recaptchaId').value;

    const newsletter = this.formGroup.get('newsletter').value;
    registerAccount.newsletter = newsletter === '' ? false : newsletter;

    // register user
    this.accountService.registerAccount(registerAccount).subscribe(() => {

      // login user
      this.authenticationService.authenticate(registerAccount.email, registerAccount.password).subscribe((user) => {
        this.localStorageService.remove('registrationCode');
        this.routingService.navigate('/research');

      }, (error) => {
        this.routingService.navigate('/login');
      });

    }, (error) => {

      switch (error.status) {
        case 500:
          this.registrationFailedShowSupportHint = true;
          break;

        case 400:
          this.setFieldHasError('password');
          this.registrationFailedShowPasswordHint = true;
          break;

        case 403:
          this.setFieldHasError('recaptcha');
          break;

        case 409:
          this.setFieldHasError('email');
          this.registrationFailedShowEmailHint = true;
          break;
      }

      this.isLoading = false;
    });
  }

  private setFieldHasError(fieldName: string): void {
    Object.keys(this.formGroup.controls).forEach(field => {
      if (field === fieldName) {
        const control = this.formGroup.get(field);
        control.setValue('');
        control.markAsTouched({onlySelf: true});
      }
    });
  }
}
