import {SubscriptionType} from '../../../services/aaa/model/account.model';

export type PriceAtlasFieldType =
  'preis'
  | 'alter'
  | 'preisJeQm'
  | 'flaeche'
  | 'einwohner'
  | 'anzahl'
  | 'rendite'
  | 'faktor'
  | 'anzahlJeEinwohner'
  | 'kaufkraft';

export class PriceAtlasField {
  constructor(public title: string,
              public tooltip: string,
              public icon: string,
              public type: PriceAtlasFieldType,
              public hasBulletChart: boolean,
              public hasMapToolbarAction: boolean,
              public unit: string) {
  }

  minimalRequiredAccountSubscription(): SubscriptionType {
    switch (this.type) {
      case 'rendite':
      case 'preisJeQm':
      case 'alter':
        return 'FREE';
      default:
        return 'ESSENTIAL';
    }
  }

  getFractionsDigits(isOfferTypeMiete: boolean): number {
    if (this.type === 'preis' ||
      this.type === 'alter' ||
      this.type === 'einwohner' ||
      this.type === 'anzahl' ||
      this.type === 'anzahlJeEinwohner') {
      return 0;
    }

    if (this.type === 'flaeche') {
      return 1;
    }

    if (this.type === 'preisJeQm') {
      return isOfferTypeMiete ? 2 : 0;
    }
    return 2;
  }
}

export const PriceAtlasFields: PriceAtlasField[] = [
  new PriceAtlasField('Rendite', 'Prozentuale Rendite', 'fas fa-chart-line', 'rendite', true, true, '%'),
  new PriceAtlasField('Faktor', 'Renditefaktor', 'fas fa-chart-line', 'faktor', true, true, ''),
  new PriceAtlasField('Preis/m²', 'Preis je m²', 'fas fa-euro-sign', 'preisJeQm', true, true, '€/m²'),
  new PriceAtlasField('Preis', 'Preis', 'far fa-money-bill-alt', 'preis', true, true, '€'),

  new PriceAtlasField('Wohnfläche', 'Wohnflächenstatistik', 'fas fa-home', 'flaeche', true, true, 'm²'),
  new PriceAtlasField('Alter', 'Durchschnittliches Alter der Inserate', 'far fa-clock', 'alter', true, true, 'Tag(e)'),
  new PriceAtlasField('Kaufkraft', 'Kaufkraftindex', 'fas fa-shopping-cart', 'kaufkraft', false, false, ''),

  new PriceAtlasField('Einwohner', 'Einwohneranzahl', 'fas fa-users', 'einwohner', false, true, ''),
  new PriceAtlasField('Objekte', 'Anzahl der Objekte', 'far fa-clone', 'anzahl', false, true, ''),
  new PriceAtlasField('je 10k Einw.', 'Objekte je 10.000 Einwohner', 'fas fa-street-view', 'anzahlJeEinwohner', false, true, '')
];
