<p>Nur noch schnell das neue Passwort eingeben und dann geht's los.</p>

<form [formGroup]="passwordResetFormGroup"
      (submit)="savePassword()"
      *ngIf="!showLoading">

  <mat-form-field>
    <input matInput
           formControlName="verificationCode"
           placeholder="Verifizierungscode"
           required="required">
  </mat-form-field>

  <mis-form-field-password [formGroup]="passwordResetFormGroup"></mis-form-field-password>

  <mis-support-hint *ngIf="showPasswordResetFailed"></mis-support-hint>

  <button type="submit">Neues Passwort speichern</button>
</form>

<mis-loading *ngIf="showLoading"></mis-loading>
