<h3>Preisatlas des Wohnungsmarktes</h3>

<div class="price-atlas-filter-pane mis-row"
     [joyrideStep]="guidedTourStep.PRICE_ATLAS_FILTER"
     title="Preisatlas Filter"
     text="Hier können Sie eine Filterung der auf dem Preisatlas angezeigten Ergebnisse vornehmen. Die Statistiken beziehen sich ausschließlich auf Wohnungen. Schalten Sie zwischen Kauf und Miete von Wohnungen um, oder schränken Sie die Anzahl der Zimmer ein.">

  <div class="mis-column-50">
    <span>Angebotstyp</span>
    <mis-form-field-toggle [activeItemKey]="selectedOfferType"
                           [items]="availableOfferItems"
                           (toggleItem)="changedOfferType($event)"></mis-form-field-toggle>
  </div>

  <div class="mis-column-50">
    <span>Zeitfenster</span>
    365 Tage
  </div>

  <div class="mis-column-100">
    <span>Zimmeranzahl</span>
    <mis-form-field-toggle [activeItemKey]="selectedNumberOfRooms"
                           [items]="availableNumberOfRooms"
                           [disabled]="roomNumberFilterDisabled"
                           (toggleItem)="changedNumberOfRooms($event)"></mis-form-field-toggle>
  </div>
</div>
