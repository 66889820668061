<div class="mis-field-box">
  <span>
    {{label}}
    <a *ngIf="editable"
       href="#"
       (click)="triggerEdit($event)">bearbeiten</a>
  </span>
  <p class="{{isEmpty ? 'mis-field-box-empty' : ''}}">
    <ng-content></ng-content>
  </p>
</div>
