<div class="mis-column-boxed mis-help">

  <h1>Sie brauchen Hilfe?</h1>
  <h2 class="mis-help-heading">Wir helfen Ihnen gern!</h2>

  <div class="mis-help-content">
    <h2>Marktrecherche Filterung</h2>
    <iframe src="https://www.youtube.com/embed/PMw-I3IVrdc"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>

  <div class="mis-help-content">
    <h2>Immobilienalarm / E-Mail Benachrichtigung</h2>
    <iframe src="https://www.youtube.com/embed/pOtbZYD2hYM"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>

  <div class="mis-help-content">
    <h2>Direkter Kontakt zu unserem Support</h2>
    <p>
      Melden Sie sich gern bei unserem Support unter support@propfox.io oder telefonisch unter 0173 65 65 647.
    </p>
  </div>
</div>
