import {DateFilterType, QuerySpec} from '../../../../services/research/model/research.filter.model';
import {FilterDateRangeEvent} from './filter.builder';
import * as moment from 'moment';
import {
  IMMO_KAUF_PROPERTY_FILTERS,
  IMMO_MIETE_PROPERTY_FILTERS,
  IMMO_OFFER_FILTERS
} from '../immo-table/immo-table-configuration/immo.table.filter.configuration';

export class FilterQueryReader {

  /** Liest das QuerySpec aus und gibt den Zeitfilter des QuerySpec zurück. */
  static getFilterDateRangeEvent(query: QuerySpec): FilterDateRangeEvent {
    if (!query) {
      return null;
    }

    let foundFodFilter = false;
    let dateFilterType: DateFilterType = 'TIME_RANGE';
    let dateFilterTypeArg: any;
    let fodStart: Date;
    let vud: Date;

    query.root.combineList.forEach((filterItem) => {
      if (filterItem.field === 'firstOccurrenceDate') {
        try {
          fodStart = moment(filterItem.tValue).utc(false).toDate();
          dateFilterType = filterItem.tValueType;
          dateFilterTypeArg = filterItem.tValueTypeArg;
          foundFodFilter = true;

        } catch (e) {
        }
      }

      if (filterItem.field === 'validUntil') {
        vud = moment(filterItem.tValue).utc(false).toDate();
      }
    });

    if (!foundFodFilter) {
      return null;
    }

    if (!dateFilterType || dateFilterType === 'TIME_RANGE') {
      return FilterDateRangeEvent.ofTimeRange(vud, fodStart);
    }

    switch (dateFilterType) {
      case 'NEWER_SINCE':
        return FilterDateRangeEvent.ofNewerSince(fodStart);
      case 'NEWER_THAN':
        return FilterDateRangeEvent.ofNewerThan(parseInt(dateFilterTypeArg));
      case 'OLDER_THAN':
        return FilterDateRangeEvent.ofOlderThan(parseInt(dateFilterTypeArg));
      case 'EFFECTIVE_DAY':
        return FilterDateRangeEvent.ofEffectiveDay(fodStart);
    }

    return null;
  }

  /** Liest den Angebotstyp des QuerySpec aus und gibt den Anzeigename zurück. */
  static getAngebotsTypDisplayName(query: QuerySpec): string {
    return IMMO_OFFER_FILTERS.filter(l => l.value === query.angebotsTyp)[0].title;
  }

  static getImmobilienTypDisplayName(query: QuerySpec): string {
    if (query.angebotsTyp == 'KAUF') {
      return IMMO_KAUF_PROPERTY_FILTERS[query.immobilienTyp].title;
    }
    return IMMO_MIETE_PROPERTY_FILTERS[query.immobilienTyp].title;
  }

  /** Liest die Orte und Postleitzahlen des QuerySpec aus und gibt sie in konkatinierter Form mit Komma-separiert zurück. */
  static joinLocationFilters(query: QuerySpec): string {
    const rootFilter = query.root.combineList;
    const locations = [];

    rootFilter.forEach((item) => {
      if (item.field === 'ort' || item.field === 'postleitzahl') {
        item.sValueList.forEach(value => locations.push(value));
      }
    });

    return locations.join(', ');
  }
}
