export type MisClusterType = 'MIXED' | 'ZIP_CODE' | 'CITY' | 'ROOM_NUMBER' | 'PRICE' | 'LIVING_SPACE' | 'PRICE_PER_LIVING_SPACE' | 'PROPERTY_SIZE' | 'PRICE_PER_PROPERTY_SIZE';

export class MisClusterDescription {
  type: MisClusterType;
  zipCodes: string[];
  cities: string[];
  roomNumberRange: number[];
  priceRange: number[];
  livingSpaceRange: number[];
  pricePerLivingSpace: number[];
  propertySizeRange: number[];
  pricePerPropertySizeRange: number[];
}

export type MisBenchmakType = 'ROOM_NUMBER' | 'PRICE' | 'LIVING_SPACE' | 'PRICE_PER_LIVING_SPACE' | 'PROPERTY_SIZE' | 'PRICE_PER_PROPERTY_SIZE' | 'AGE' | 'UTILITIES';

export class MisBenchmarkRow {
  type: MisBenchmakType;
  actual: number;
  min: number;
  percentile20: number;
  mean: number;
  median: number;
  percentile80: number;
  max: number;
}

export type MisPropertyRating = 'TOP' | 'ADVANCED' | 'AVERAGE' | 'LOWER' | 'POOR';

export class MisSinglePropertyBenchmark {
  clusterDescription: MisClusterDescription;
  score: number;
  rating: MisPropertyRating;
  rows: MisBenchmarkRow[];
  numberOfElements: number;
}
