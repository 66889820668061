import {Component} from '@angular/core';
import {UserService} from '../../../../services/aaa/user.service';
import {RoutingService} from '../../../../services/routing.service';
import {ActivatedRoute} from '@angular/router';
import {SnackbarService} from '../../../basic/snackbar/snackbar.service';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';

@Component({
  selector: 'mis-selfservice-verification',
  templateUrl: './selfservice.verification.component.html',
  styleUrls: ['./selfservice.verification.component.scss']
})
export class SelfserviceVerificationComponent {
  isLoading: boolean = true;
  failed: boolean;
  resend: boolean = false;
  emailAddress: string;

  constructor(private authenticationProvider: AuthenticationProvider,
              private userService: UserService,
              private routingService: RoutingService,
              private route: ActivatedRoute,
              private snackbar: SnackbarService) {

    const verificationCode = this.route.snapshot.params.code;
    this.emailAddress = this.authenticationProvider.getUser().contact.email;

    this.userService.verifyEmail(verificationCode).subscribe(() => {
      this.routingService.navigate('/research');

    }, (err) => {
      this.isLoading = false;
      this.failed = true;
      snackbar.error('Die Verifizierung Ihrer E-Mail-Adresse ist fehlgeschlagen.');
    });
  }

  resendVerificationEmail() {
    this.isLoading = true;
    this.failed = false;

    this.userService.resendVerificationEmail().subscribe(() => {
      this.isLoading = false;
      this.resend = true;

    }, () => {
      this.isLoading = false;
      this.failed = true;
    });
  }

  cancelVerification() {
    this.routingService.navigate('/research');
  }
}
