import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';
import {AuthenticationService} from '../../../../services/aaa/authentication.service';
import {RoutingService} from '../../../../services/routing.service';
import {MisEventUtils} from '../../../../services/common/event.util';
import {ViewServiceInstance} from '../../../../viewservice/view.service';

@Component({
  selector: 'mis-header-profile-item',
  templateUrl: './header.profile.item.component.html',
  styleUrls: ['./header.profile.item.component.scss']
})
export class HeaderProfileItemComponent implements OnInit {
  @Input() showDetailed: boolean;

  viewServiceInstance = ViewServiceInstance;

  username: string;
  isAdminUser: boolean;
  showContextMenu: boolean;

  constructor(private routingService: RoutingService,
              private authenticationService: AuthenticationService,
              private authenticationProvider: AuthenticationProvider) {
  }

  ngOnInit(): void {
    this.updateUserName();
    this.authenticationProvider.subscribeUserChanges(() => {
      this.updateUserName();
    });

    this.isAdminUser = this.authenticationProvider.getUser().role === 'ACCOUNT_ADMIN';
  }

  logout(event): void {
    MisEventUtils.stopEvent(event);

    this.authenticationService.logout();
    this.routingService.navigate('/login');
  }

  private updateUserName() {
    if (this.authenticationProvider.isAuthenticated()) {
      const contact = this.authenticationProvider.getUser().contact;

      if (contact.firstName) {
        this.username = contact.firstName + ' ' + contact.lastName;
      } else {
        this.username = contact.email;
      }
    }
  }

  toggleProfileOverlay(event) {
    MisEventUtils.stopEvent(event);
    this.showContextMenu = !this.showContextMenu;
  }
}
