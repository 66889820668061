import {Component, OnInit} from '@angular/core';
import {MisUser} from '../../../../services/aaa/model/account.model';
import {UserService} from '../../../../services/aaa/user.service';

@Component({
  selector: 'mis-selfservice',
  templateUrl: './selfservice.component.html'
})
export class SelfserviceComponent implements OnInit {
  isLoading = true;
  selfserviceUser: MisUser = null;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser(): void {
    this.isLoading = true;
    this.userService.getCurrentUser().subscribe((user) => {
      this.selfserviceUser = user;
      this.isLoading = false;

    }, () => {
      this.isLoading = false;
    });
  }
}
