import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {QuerySpec} from './model/research.filter.model';
import {environment} from '../../../environments/environment';
import {MisTag, MisTagUtils} from '../aaa/model/account.model';
import {UserService} from '../aaa/user.service';
import {LocalStorageService} from "../local.storage.service";

export type NotificationType = 'EMAIL';
export type NotificationFrequency = 'HOURLY' | 'DAILY_IN_MORNING' | 'DAILY_IN_EVENING';

export class PropertyNotificationSubscription {
  constructor(public type: NotificationType,
              public frequency: NotificationFrequency) {
  }
}

export class SavedSearch {
  constructor(public name: string,
              public spec: QuerySpec,
              public subscriptions?: PropertyNotificationSubscription[]) {
  }
}

export class ActiveSearch {
  constructor(public querySpec: QuerySpec, public pageHits: number) {
  }
}

@Injectable({providedIn: 'root'})
export class SavedSearchService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService,
              private userService: UserService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadSavedSearches(): Observable<SavedSearch[]> {
    return this.GET<SavedSearch[]>(environment.misResearchEndpoint + '/saved-search');
  }

  saveSearch(savedSearch: SavedSearch): Observable<void> {
    return this.POST<void>(environment.misResearchEndpoint + '/saved-search/' + encodeURIComponent(savedSearch.name), savedSearch.spec);
  }

  deleteSavedSearch(savedSearch: SavedSearch): Observable<void> {
    return this.DELETE(environment.misResearchEndpoint + '/saved-search/' + encodeURIComponent(savedSearch.name));
  }

  updateActiveSearch(query: QuerySpec, pageHits: number): Observable<void> {
    return new Observable<void>(subscription => {
      if (!this.authenticationProvider.isAuthenticated()) {
        subscription.error();
        return;
      }

      const user = this.authenticationProvider.getUser();
      const storedActiveFilterTag = MisTagUtils.getTagByKey(user.tags, MisTagUtils.keys.immolistActiveFilter);

      if (storedActiveFilterTag == null) {
        const newTag = new MisTag();
        newTag.key = MisTagUtils.keys.immolistActiveFilter;
        newTag.value = JSON.stringify({filter: new ActiveSearch(query, pageHits)});
        newTag.type = 'JSON';

        user.tags.push(newTag);
        this.userService.addUserTag(user.id, newTag).subscribe(() => {
          subscription.next();
        }, () => {
          subscription.error();
        }, () => {
          subscription.complete();
        });

      } else {
        storedActiveFilterTag.value = JSON.stringify({filter: new ActiveSearch(query, pageHits)});
        this.userService.updateUserTag(user.id, storedActiveFilterTag).subscribe(() => {
          subscription.next();
        }, () => {
          subscription.error();
        }, () => {
          subscription.complete();
        });
      }
    });
  }

  getActiveSearch(): ActiveSearch {
    if (!this.authenticationProvider.isAuthenticated()) {
      return null;
    }

    const user = this.authenticationProvider.getUser();
    const storedActiveFilterTag = MisTagUtils.getTagByKey(user.tags, MisTagUtils.keys.immolistActiveFilter);

    if (storedActiveFilterTag == null) {
      return null;
    } else {
      return JSON.parse(storedActiveFilterTag.value).filter;
    }
  }

  findSavedSearch(savedSearchName: string): Observable<SavedSearch> {
    return this.GET(environment.misResearchEndpoint + '/saved-search/' + encodeURIComponent(savedSearchName));
  }

  updateNotificationSubscription(savedSearch: SavedSearch, subscription: PropertyNotificationSubscription) {
    return this.POST(environment.misResearchEndpoint + '/notification/' + encodeURIComponent(savedSearch.name) + '?type=' + subscription.type + '&frequency=' + subscription.frequency, null);
  }

  deleteNotificationSubscription(savedSearch: SavedSearch) {
    return this.DELETE(environment.misResearchEndpoint + '/notification/' + encodeURIComponent(savedSearch.name));
  }

  updateNotificationFrequency(notificationType: NotificationFrequency) {
    return this.POST(environment.misResearchEndpoint + '/notification?frequency=' + notificationType + '&type=EMAIL', null);
  }

  deleteNotification(): Observable<void> {
    return this.DELETE(environment.misResearchEndpoint + '/notification');
  }
}
