import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

export type StorageKeys = 'MIS-TOKEN' | 'MIS-USER' | 'MIS-CLIENT-ID' | 'registrationCode';

@Injectable({providedIn: 'root'})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  get<T>(storageKey: StorageKeys, defaultValue: T = null): T {
    return <T>this.storage.get(storageKey) || defaultValue;
  }

  set(storageKey: StorageKeys, value: any): void {
    this.storage.set(storageKey, value);
  }

  remove(storageKey: StorageKeys): void {
    this.storage.remove(storageKey);
  }
}
