import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AbstractService} from '../abstract.service';
import {AuthenticationProvider} from './authentication.provider';
import {MisOfferer} from './model/offerer.model';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class OffererService extends AbstractService {
  private offererList: MisOfferer[];

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  searchOfferer(query: string): Observable<MisOfferer[]> {
    const params = new HttpParams()
      .set('q', query);

    return this.GET<MisOfferer[]>(environment.misAccountEndpoint + '/offerer', params);
  }

  assignToAccount(offerer: MisOfferer): Observable<MisOfferer> {
    return new Observable<MisOfferer>((subscribe) => {
      this.POST<MisOfferer>(environment.misAccountEndpoint + '/offerer/my/' + offerer.id, offerer).subscribe((updatedOfferer) => {
        this.offererList.push(updatedOfferer);
        subscribe.next(updatedOfferer);
        subscribe.complete();

      }, (err) => {
        subscribe.error(err);
        subscribe.complete();
      });
    });
  }

  removeFromAccount(offererId: number): Observable<void> {
    return new Observable<void>((subscribe) => {
      this.DELETE<void>(environment.misAccountEndpoint + '/offerer/my/' + offererId).subscribe(() => {
        this.offererList = this.offererList.filter((o) => o.id !== offererId);
        subscribe.next();
        subscribe.complete();

      }, (err) => {
        subscribe.error(err);
        subscribe.complete();
      });
    });
  }

  getList(): Observable<MisOfferer[]> {
    return new Observable<MisOfferer[]>((subscribe) => {
      if (this.offererList) {
        subscribe.next(this.offererList);
        subscribe.complete();
        return;
      }

      this.GET<MisOfferer[]>(environment.misAccountEndpoint + '/offerer/my').subscribe((offererList) => {
        this.offererList = offererList;
        subscribe.next(this.offererList);
        subscribe.complete();

      }, (err) => {
        subscribe.error(err);
        subscribe.complete();
      });
    });
  }
}
