import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';

export class MultiBulletChartData {
  data: ChartDataSets[] = [];
  options: ChartOptions;
  colors: Color[];
  labels: Label[] = [];
}

@Component({
  selector: 'mis-multi-bullet-chart',
  templateUrl: './multi.bullet.chart.component.html',
  styleUrls: ['./multi.bullet.chart.component.scss']
})
export class MultiBulletChartComponent implements OnInit { // OnChanges, OnDestroy, ViewServiceChangeListener {
  @Input() chartWidth = 400;
  @Input() chartHeight = 200;
  @Input() chartData: MultiBulletChartData;
  @Input() maxWidth = 720;
  @Input() chartLegend = false;

  chartType = 'bar';
  chartPlugins = [];

  ngOnInit() {

  }
}
