import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MisEventUtils} from '../../../../services/common/event.util';

export class DatetimePickerEvent {
  constructor(public hours: number, public minutes: number) {
  }
}

@Component({
  selector:    'mis-form-fields-datetime-picker',
  templateUrl: './form.fields.datetime.picker.component.html',
  styleUrls:   ['./form.fields.datetime.picker.component.scss']
})
export class FormFieldsDatetimePickerComponent implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() controlNamePrefix: string = 'datetimepicker';
  @Input() hours: number;
  @Input() minutes: number = 0;

  @Output() changedValues = new EventEmitter<DatetimePickerEvent>();

  isFormInitialized: boolean;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }

    if (!this.isFormInitialized) {
      const formCtrl = new FormControl(this.hours);

      this.formGroup.addControl(this.controlNamePrefix, formCtrl);
      this.isFormInitialized = true;

    }

    if (this.hours && this.minutes) {
      const hoursValue = this.hours < 10 ? '0' + this.hours : '' + this.hours;
      const minutesValue = this.minutes < 10 ? '0' + this.minutes : '' + this.minutes;


      this.formGroup.get(this.controlNamePrefix).setValue(hoursValue + ':' + minutesValue);
    }
  }

  changeInputValue(event): void {
    MisEventUtils.stopEvent(event);

    const currentInputValue = event.srcElement.value;

    let hours: number;
    let minutes: number;

    if (currentInputValue.indexOf(':')) {
      const timeValues = currentInputValue.split(':');
      hours = parseInt(timeValues[0]);
      minutes = parseInt(timeValues[1]);

    } else {
      hours = parseInt(currentInputValue);
      minutes = 0;
    }

    this.changedValues.emit(new DatetimePickerEvent(hours, minutes));
  }
}
