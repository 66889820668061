import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../../viewservice/view.service';
import {MisEventUtils} from '../../../../services/common/event.util';

export type BulletChartType = 'DETAILED' | 'SIMPLE';

@Component({
  selector:    'mis-bullet-chart',
  templateUrl: './bullet.chart.component.html',
  styleUrls:   ['./bullet.chart.component.scss']
})
export class BulletChartComponent implements OnInit, OnChanges, OnDestroy, ViewServiceChangeListener {
  @Input() min: number;
  @Input() max: number;
  @Input() chartMin: number;
  @Input() chartMax: number;
  @Input() percentile20: number;
  @Input() median: number;
  @Input() mean: number;
  @Input() percentile80: number;
  @Input() target: number;
  @Input() unit: string;
  @Input() chartTitle: string;
  @Input() formatDecimalDigits: number = 2;
  @Input() maxWidthPx: number;
  @Input() chartType: BulletChartType = 'DETAILED';
  @ViewChild('bulletWrapperContainer', {static: true}) bulletWrapperContainer: ElementRef<HTMLElement>;
  @ViewChild('bulletWrapper', {static: true}) bulletWrapper: ElementRef<HTMLElement>;

  highlightedField: string;

  rangeLeft: number;
  rangeRight: number;
  rangeRightExceeded: boolean;
  rangeOutOfChart: boolean;
  minLeft: string;
  maxRight: string;
  targetLeft: string;
  medianLeft: string;
  showDetails: boolean;
  showDescription: boolean;
  overUnder: string;
  descriptionTitle: string;


  private wrapperWidth: number;
  private p100: number;

  ngOnInit(): void {
    ViewServiceInstance.listenOnResize(this);
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChart();
  }

  onResize(): void {
    setTimeout(() => {
      this.initChart();
    }, 50);
  }

  private initWrapperWidth(): void {
    let wrapperWidth = this.bulletWrapper.nativeElement.clientWidth;
    const wrapperContainerWidth = this.bulletWrapperContainer.nativeElement.clientWidth;

    if (wrapperWidth === wrapperContainerWidth) {
      wrapperWidth -= 60; // padding
    }

    const maxWidth = this.maxWidthPx - 60; // padding
    this.wrapperWidth = (maxWidth && wrapperWidth > maxWidth) ? maxWidth : wrapperWidth;
  }

  private initChart(): void {
    this.initWrapperWidth();

    const min = this.chartMin ? this.chartMin : this.min;
    const max = this.chartMax ? this.chartMax : this.max;

    let minOuter = (this.target && this.target < min) ? this.target : min;
    let maxOuter = (this.target && this.target > max) ? this.target : max;

    this.p100 = maxOuter - minOuter;

    if (this.min === this.max && this.min === this.target) {
      this.minLeft = '0px';
      this.maxRight = '0px';
      this.rangeLeft = 0;
      this.rangeRight = 0;
      this.targetLeft = (this.wrapperWidth / 2) + 'px';
      this.medianLeft = (this.wrapperWidth / 2) + 'px';
      return;
    }

    // Line
    this.minLeft = this.positionFromLeft(minOuter, maxOuter, min) + 'px';
    this.maxRight = this.positionFromRight(minOuter, maxOuter, max) + 'px';

    // Range
    this.rangeLeft = this.positionFromLeft(minOuter, maxOuter, this.percentile20);
    this.rangeRight = this.positionFromRight(minOuter, maxOuter, this.percentile80);
    this.rangeRightExceeded = maxOuter === this.percentile80;
    this.rangeOutOfChart = this.percentile20 > this.max || (this.chartMax && this.percentile20 > this.chartMax);

    // Target / Median
    this.targetLeft = this.positionFromLeft(minOuter, maxOuter, this.target) + 'px';
    this.medianLeft = this.positionFromLeft(minOuter, maxOuter, this.median) + 'px';

    // Some description text preparations
    this.overUnder = (this.target < this.median) ? 'unterhalb des' : (this.target > this.median) ? 'oberhalb des' : 'genau auf dem';
    switch (this.chartTitle) {
      case 'Preis':
      case 'Preis/m²':
        this.descriptionTitle = 'einen ' + this.chartTitle;
        break;
      case 'Zimmer':
        this.descriptionTitle = 'eine ' + this.chartTitle + 'anzahl';
        break;
      case 'Nebenkosten':
        this.descriptionTitle = 'Nebenkosten in Höhe ';
        break;
      case 'Grundstücksfläche':
      case 'Wohnfläche':
        this.descriptionTitle = 'eine ' + this.chartTitle;
        break;
      default:
        this.descriptionTitle = this.chartTitle;
        break;
    }
  }

  private positionFromLeft(min: number, max: number, current: number) {
    if (current === 0) {
      return 0;
    }

    if (current === min) {
      return 0;
    } else if (current === max) {
      return this.wrapperWidth;
    }

    const a = this.p100;
    const b = current - min;

    return b * this.wrapperWidth / a;
  }

  private positionFromRight(min: number, max: number, current: number) {
    if (max === current || max < current) {
      return 0;
    }

    if (current === 0) {
      return 0;
    }

    const a = this.p100;
    const b = max - current;

    return b * this.wrapperWidth / a;
  }

  private prepareValues() {
    try {
      this.percentile20 = +this.percentile20.toFixed(3);
      this.percentile80 = +this.percentile80.toFixed(3);
      this.target = +this.target.toFixed(3);
      this.min = +this.min ? +this.min.toFixed(3) : 0.0;
      this.max = +this.max.toFixed(3);
    } catch (e) {
    }
  }

  toggleDetails(event: Event): void {
    MisEventUtils.stopEvent(event);

    this.showDetails = !this.showDetails;
    this.showDescription = false;
  }

  highlight(field: string) {
    this.highlightedField = field;
  }

  unHighlight() {
    this.highlightedField = null;
  }

  toggleDescription(event: Event) {
    MisEventUtils.stopEvent(event);

    this.showDetails = true;
    this.showDescription = true;
  }

  closeDescription(): void {
    this.showDescription = false;
  }
}
