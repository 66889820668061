<mis-immo-overview viewType="IMMO_MANAGEMENT"
                   [tableService]="tableService">

  <!--<mis-immo-table-action label="Aktualisieren"-->
                         <!--icon="fas fa-redo"-->
                         <!--type="reload"></mis-immo-table-action>-->

<!--  <mis-immo-table-action label="Suche exportieren"
                         icon="fas fa-file-download"
                         type="export"></mis-immo-table-action>
-->
  <!--<mis-immo-table-action label="Immo Upload"-->
                         <!--icon="fas fa-file-upload"-->
                         <!--(clickAction)="openUploadModal()"></mis-immo-table-action>-->

  <!--<mis-immo-table-action label="Erstellen"
                         icon="fas fa-plus"
                         (clickAction)="addImmo()"></mis-immo-table-action>

  <mis-immo-table-action label="Bearbeiten"
                         icon="fas fa-pen"
                         type="edit"></mis-immo-table-action>

  <mis-immo-table-action label="Auswahl löschen"
                         icon="fas fa-trash-alt"
                         type="delete"></mis-immo-table-action>

  <mis-immo-table-action label="Teilen"
                         icon="fas fa-share-alt"
                         type="share"></mis-immo-table-action>-->

</mis-immo-overview>
