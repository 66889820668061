import {Component} from '@angular/core';
import {WatchlistService} from '../../../services/research/watchlist.service';

@Component({
  selector: 'mis-watchlist',
  templateUrl: './watchlist.component.html'
})
export class WatchlistComponent {
  constructor(public tableService: WatchlistService) {}
}
