import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../basic/snackbar/snackbar.service';
import {AbstractFormComponent} from '../../../basic/form/abstract.form.component';
import {FormBuilder, Validators} from '@angular/forms';
import {FeedbackService} from '../../../../services/feedback/feedback.service';
import {FeedbackReport} from '../../../../services/feedback/model/feedback.report.model';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';

@Component({
  selector:    'mis-feedback-modal',
  templateUrl: './feedback-modal.component.html',
})
export class FeedbackModalComponent extends AbstractFormComponent {
  hideNameField: boolean = true;

  constructor(public dialogRef: MatDialogRef<FeedbackModalComponent>,
              formBuilder: FormBuilder,
              private feedbackService: FeedbackService,
              authenticationProvider: AuthenticationProvider,
              private snackBar: SnackbarService) {
    super();

    let name = '';
    let email = '';
    this.hideNameField = authenticationProvider.isAuthenticated();

    if (this.hideNameField) {
      const user = authenticationProvider.getUser();
      name = user.contact.firstName + ' ' + user.contact.lastName;
      email = user.contact.email;
    }

    this.formGroup = formBuilder.group({
      'name':    [name],
      'email':   [email, Validators.email],
      'message': ['', Validators.required]
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  customSaveAction(): void {
    const report = new FeedbackReport();
    report.name = this.getFieldValue('name');
    report.email = this.getFieldValue('email');
    report.message = this.getFieldValue('message');

    this.feedbackService.send(report).subscribe(() => {
      this.snackBar.success('Ihre Nachricht wurde erfolgreich versendet. Vielen Dank!');
      this.closeModal();
    }, () => {
      this.snackBar.error('Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.');
      this.isLoading = false;
    });
  }
}
