import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';
import {MisBaseUtils} from '../../../../../../services/common/base.util';

export class PriceWithTrendColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnPriceWithTrendComponent;
  }
}

@Component({
  selector:    'mis-immo-table-column-price-with-trend',
  templateUrl: './immo.table.column.price.with.trend.component.html',
  styleUrls:   ['./immo.table.column.price.with.trend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnPriceWithTrendComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: AbstractImmoTableColumnData;

  numberValue: string;
  direction: 'INCREASE' | 'DECREASE' | 'STEADY' = 'STEADY';

  ngOnInit(): void {
    this.initPriceValue();
    this.initTrend();
  }

  initPriceValue(): void {
    const suffix = '€';
    const fractionDigits = 2;

    const value = MisBaseUtils.formatNumber(this.getValue(this.researchItem, this.columnName), fractionDigits);
    if (value === '-') {
      this.numberValue = value;
    } else {
      this.numberValue = MisBaseUtils.formatNumber(this.getValue(this.researchItem, this.columnName), fractionDigits) + ' ' + suffix;
    }
  }


  initTrend(): void {
    if (!this.researchItem.priceHistory || this.researchItem.priceHistory.length === 0) {
      this.direction = 'STEADY';
      return;
    }

    if (this.researchItem.priceHistory.length === 1) {
      this.direction = 'STEADY';
      return;
    }

    const lastPrice = this.researchItem.priceHistory[this.researchItem.priceHistory.length - 2].value;
    const currentPrice = this.researchItem.priceHistory[this.researchItem.priceHistory.length - 1].value;

    if (lastPrice > currentPrice) {
      this.direction = 'DECREASE';
    } else if (lastPrice < currentPrice) {
      this.direction = 'INCREASE';
    } else {
      this.direction = 'STEADY';
    }
  }
}
