<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h3>{{city}} auf einen Blick</h3>

    <h4>Mietübersicht</h4>
    <div class="mis-row">
      <ng-container *ngFor="let quickInfoField of quickInfoFields">
        <mis-quick-info-item [item]="buildQuickInfoItem(quickInfoField)"
                             width="165px">
          {{getInfoItemValue(quickInfoField, true)}}
        </mis-quick-info-item>
      </ng-container>
    </div>

    <div class="vertically-space"></div>

    <h4>Kaufübersicht</h4>
    <div class="mis-row"
         style="justify-content:center">
      <ng-container *ngFor="let quickInfoField of quickInfoFields">
        <mis-quick-info-item [item]="buildQuickInfoItem(quickInfoField)"
                             width="165px">
          {{getInfoItemValue(quickInfoField, false)}}
        </mis-quick-info-item>
      </ng-container>
    </div>

  </div>
</div>
