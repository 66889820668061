import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {AbstractImmoTableFilter} from '../../../immo-table/immo-table-configuration/immo.table.classes';
import {OffererService} from '../../../../../../services/aaa/offerer.service';
import {
  FormFieldsDropdownLoadAutocompleteValues,
  FormFieldsDropdownValue
} from '../../../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';

@Component({
  selector:    'mis-filter-extended-item-offerers',
  templateUrl: './filter.extended.item.offerers.component.html',
  styleUrls:   ['./filter.extended.item.offerers.component.scss']
})
export class FilterExtendedItemOfferersComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<string[]>();

  availableValues: { [key: string]: FormFieldsDropdownValue } = {};
  selectedOffererIds: string[] = [];

  constructor(private offererService: OffererService) {
  }

  ngOnInit(): void {
    if (this.queryFields && this.queryFields.length > 0) {

      if (this.queryFields[0].sValue != null) {
        this.availableValues[this.queryFields[0].sValue] = new FormFieldsDropdownValue(this.queryFields[0].sValue);
        this.selectedOffererIds.push(this.queryFields[0].sValue);

      } else if (this.queryFields[0].sValueList != null) {
        const values = this.queryFields[0].sValueList;
        values.forEach((value) => {
          this.availableValues[value] = new FormFieldsDropdownValue(value);
        });
        this.selectedOffererIds = this.queryFields[0].sValueList;
      }
    }
  }

  findOfferersByName(autoCompleteSettings: FormFieldsDropdownLoadAutocompleteValues): void {
    this.offererService.searchOfferer(autoCompleteSettings.searchString).subscribe((offerers) => {
      let autoCompleteItems: { [key: string]: FormFieldsDropdownValue } = {};

      offerers.forEach((offerer) => {
        autoCompleteItems[offerer.name] = new FormFieldsDropdownValue(offerer.name);
      });
      autoCompleteSettings.subscriber.next(autoCompleteItems);

    }, () => {
      autoCompleteSettings.subscriber.next({});
    });
  }

  changedValues(newValueKeys: { [key: string]: FormFieldsDropdownValue }): void {
    if (!this.disabled) {
      this.changed.emit(Object.values(newValueKeys).map(v => v.value));
    }
  }
}
