import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AbstractService} from '../abstract.service';
import {MisAccount, MisRegisterAccount, MisTag} from './model/account.model';
import {AuthenticationProvider} from './authentication.provider';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class AccountService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  registerAccount(registration: MisRegisterAccount): Observable<void> {
    return this.POST<void>(environment.misAccountEndpoint + '/registration/simple', registration);
  }

  getAccount(): Observable<MisAccount> {
    return this.GET<MisAccount>(environment.misAccountEndpoint + '/account');
  }

  updateAccount(account: MisAccount): Observable<MisAccount> {
    return this.PUT<MisAccount>(environment.misAccountEndpoint + '/account/' + account.id, account);
  }

  updateAccountTags(accountId: number, tags: MisTag[]) {
    return this.PUT<MisAccount>(environment.misAccountEndpoint + '/account/' + accountId + '/tags', tags);
  }
}
