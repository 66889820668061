<h1>CI CD</h1>

<div class="mis-row mis-column-boxed">
  <div class="mis-column-100">
    <h2>Color Calculator</h2>

    <form [formGroup]="formGroup">
      <input formControlName="hexColor"
             (keyup)="co=formGroup.get('hexColor').value">
    </form>

    <div class="mis-row">
      <div class="mis-column-15">
        <h3>Blau</h3>
        <mis-color-calculator [hexColor]="'009EE3'"></mis-color-calculator>
      </div>

      <div class="mis-column-15">
        <h3>Grau</h3>
        <mis-color-calculator [hexColor]="'475860'"></mis-color-calculator>
      </div>

      <div class="mis-column-15">
        <h3>Custom</h3>
        <mis-color-calculator [hexColor]="co"></mis-color-calculator>
      </div>
    </div>
  </div>
</div>
