export class ResearchItemDetailsImage {
  alt: string;
  url: string;
}

export class ResearchItemDetails {
  link: string;
  title: string;
  address: string;
  lon: number;
  lat: number;
  isExactPosition: boolean;
  propertyType: string;
  propertyDescription: string;
  facilitiesDescription: string;
  areaDescription: string;
  otherDescription: string;
  realtorPhone: string;
  realtorEmail: string;
  images: ResearchItemDetailsImage[];
}
