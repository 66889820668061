<div class="mis-recaptcha" [formGroup]="formGroup">
  <ngx-recaptcha2 *ngIf="showCaptcha"
                  #captchaElem
                  [siteKey]="siteKey"
                  (reset)="handleReset()"
                  (success)="handleSuccess($event)"
                  [useGlobalDomain]="false"
                  [size]="size"
                  [hl]="lang"
                  [theme]="theme"
                  [type]="type"
                  formControlName="recaptcha">
  </ngx-recaptcha2>

  <input formControlName="recaptchaResponse" type="hidden">
  <input formControlName="recaptchaId" type="hidden">

  <div class="mis-field-error"
       *ngIf="hasError()">Bitte lösen Sie das Captcha um fortzufahren.
  </div>
</div>
