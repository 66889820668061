import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

export type SnackbarType = 'SUCCESS' | 'ERROR' | 'HINT';

export class SnackbarData {
  constructor(public icon: string,
              public message: string,
              public type: SnackbarType = 'SUCCESS') {
  }
}

@Component({
  selector: 'mis-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
  }

  getTypeCssClass() {
    return 'mis-snackbar-type-' + this.data.type.toLowerCase();
  }
}
