import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'mis-color-calculator',
  templateUrl: './color.calculator.component.html',
  styleUrls: ['./color.calculator.component.scss']
})
export class ColorCalculatorComponent implements OnInit, OnChanges {
  @Input() hexColor: string;

  colorSteps = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  red: number;
  green: number;
  blue: number;

  ngOnInit(): void {
    this.calculate(this.hexColor);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculate(this.hexColor);
  }

  calculate(hexColor: string) {
    this.red = null;
    this.green = null;
    this.blue = null;

    if (!hexColor) {
      return;
    }

    if (hexColor.length === 3) {
      this.red = Math.pow(this.parseHexToDec(hexColor.substr(0, 1)), 2);
      this.green = Math.pow(this.parseHexToDec(hexColor.substr(1, 1)), 2);
      this.blue = Math.pow(this.parseHexToDec(hexColor.substr(2, 1)), 2);

    } else if (hexColor.length === 6) {
      this.red = this.parseHexToDec(hexColor.substr(0, 2));
      this.green = this.parseHexToDec(hexColor.substr(2, 2));
      this.blue = this.parseHexToDec(hexColor.substr(4, 2));

    } else {
      // error
    }
  }

  private parseHexToDec(val: string): number {
    return parseInt(val, 16);
  }

  private parseDecToHex(val: number): string {
    const result = val.toString(16);

    if (result.length === 1) {
      return '0' + result;
    }

    return result;
  }

  calcLighterAlphaColorAsHex(percentage: number) {
    const red = this.calcLighterAlphaColor(this.red, percentage);
    const green = this.calcLighterAlphaColor(this.green, percentage);
    const blue = this.calcLighterAlphaColor(this.blue, percentage);

    return '#' + red + green + blue;
  }

  private calcLighterAlphaColor(colorVal: number, percentage: number): string {
    let result = (255 - colorVal) * percentage / 100;
    result = colorVal + result;
    result = Math.round(result);

    if (result <= 0) {
      return '00';
    }

    if (result >= 255) {
      return 'FF';
    }

    return this.parseDecToHex(result);
  }

  calcDarkerAlphaColorAsHex(percentage: number) {
    const red = this.calcDarkerAlphaColor(this.red, percentage);
    const green = this.calcDarkerAlphaColor(this.green, percentage);
    const blue = this.calcDarkerAlphaColor(this.blue, percentage);

    return '#' + red + green + blue;
  }

  private calcDarkerAlphaColor(colorVal: number, percentage: number): string {
    let result = colorVal * percentage / 100;
    result = colorVal - result;
    result = Math.round(result);

    if (result <= 0) {
      return '00';
    }

    if (result >= 255) {
      return 'FF';
    }

    return this.parseDecToHex(result);
  }
}
