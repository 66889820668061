<div class="price-atlas">
  <div class="price-atlas-details">
    <div class="price-atlas-details-filter">
      <mis-price-atlas-filter (filterChanged)="applyFilter($event)"></mis-price-atlas-filter>
    </div>

    <div *ngIf="activeZipCodePreisAtlas"
         class="price-atlas-details-container"
         [style.maxHeight]="maxHeightDetailsContainer">

      <mis-price-atlas-details *ngIf="activeZipCodePreisAtlas"
                               [zipCode]="activeZipCode"
                               [visiblePriceAtlasFields]="visiblePriceAtlasFields"
                               [preisAtlas]="activeZipCodePreisAtlas"
                               [preisAtlasFilter]="currentFilter"
                               [preisatlasMeta]="preisAtlasResponse.meta"
                               (changedVisiblePriceAtlasFields)="changedVisiblePriceAtlasFields($event)"></mis-price-atlas-details>
    </div>

    <div *ngIf="!activeZipCodePreisAtlas"
         class="price-atlas-details-hint">
      <mis-hint type="INFO-BORDERED">
        Klicken Sie in der Karte auf ein farbig markiertes Postleitzahlen um weitere Information zum entsprechenden
        Gebiet zu erhalten.
      </mis-hint>
    </div>
  </div>

  <div class="price-atlas-map">
    <div *ngIf="isLoading"
         class="loading-wrapper">
      <mis-loading></mis-loading>
    </div>
    <mis-price-atlas-map #priceAtlasMap
                         [availableAnalyticsLayerTypes]="availablePriceAtlasFieldsOnMap"
                         [mapCenter]="mapCenter"
                         (changeAnalyticsLayerType)="updateAnalyticLayerType($event)"
                         (changedSelectedZipCode)="changedSelectedZipCode($event)"
                         (changedVisibleZipCodes)="changedMapPosition($event)"
                         (hoveredZipCode)="showZipCodeInfos($event)"
                         (mapMouseout)="showSelectedZipCodeInfo()"></mis-price-atlas-map>

    <mis-price-atlas-table #priceAtlasTable></mis-price-atlas-table>
  </div>
</div>
