import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'mis-form-field-color-picker',
  templateUrl: './form-fields-color-picker.component.html',
  styleUrls: ['./form-fields-color-picker.component.scss']
})
export class FormFieldsColorPickerComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() formGroupControlName: string;
  @Input() placeholder: string;
  @Input() required: string;

  color: string;

  ngOnInit(): void {
    // reset value of variable color, if form value will be changed, or resetted by parent component.
    this.formGroup.get(this.formGroupControlName)
      .valueChanges
      .subscribe((value) => {
        this.color = value;
      });

    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  private init(): void {
    if (!this.formGroup) {
      console.log('The component mis-form-field-color-picker requires the @Input formGroup.');
      return;
    }

    this.color = this.getValue();
  }

  private getValue() {
    const formValue = this.formGroup.get(this.formGroupControlName).value;
    if (!formValue || formValue === '') {
      return null;
    }

    return formValue;
  }

  updateColor(color: string): void {
    this.color = color;
    this.formGroup.get(this.formGroupControlName).setValue(color);
  }
}
