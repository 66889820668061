import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {File} from "./file.model";
import {environment} from "../../../../../environments/environment";
import * as _ from 'lodash';
import {MisEventUtils} from "../../../../services/common/event.util";

const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/svg+xml'
];

@Component({
  selector: 'mis-form-field-file-upload',
  templateUrl: './form-fields-file-upload.component.html',
  styleUrls: ['./form-fields-file-upload.component.scss']
})
export class FormFieldsFileUploadComponent implements OnChanges {
  @Input() accept: string;
  @Input() file: File | null;
  @Input() placeholder: string;
  @Output() choseFile = new EventEmitter<File>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  actualFile: File;
  previewImage: SafeUrl;

  maxFileSizeExceeded: boolean;
  illegalFileType: boolean;

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.file) {
      this.actualFile = this.file;
      this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(this.actualFile.base64);
    }
  }

  handleFileInput(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    this.illegalFileType = false;
    this.maxFileSizeExceeded = false;

    if (target.files && target.files[0]) {
      const file = target.files[0];

      // Check file type
      if (file.size > environment.maxFileUploadSizeBytes) {
        this.maxFileSizeExceeded = true;

      } else if (!_.includes(ALLOWED_FILE_TYPES, file.type)) {
        this.illegalFileType = true;

      } else {
        const fr = new FileReader();

        fr.onload = (event: any) => {
          const imageBase64 = event.target.result;
          this.previewImage = this.domSanitizer.bypassSecurityTrustUrl(imageBase64);

          const b64File = new File(event.target.result);
          b64File.name = file.name;
          this.actualFile = b64File;

          this.choseFile.emit(b64File);
        };
        fr.readAsDataURL(file);
      }
    }

    // clear input field
    this.fileInput.nativeElement.value = '';
  }

  removeFile(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.actualFile = null;
    this.previewImage = null;
    this.illegalFileType = false;
    this.maxFileSizeExceeded = false;

    this.choseFile.emit(null);
  }
}
