<div class="mis-modal-medium">
  <h2 mat-dialog-title>Feedback</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form *ngIf="!isLoading"
        [formGroup]="formGroup"
        (submit)="saveForm()">
    <mat-dialog-content>
      <div>
        <p>Ihnen ist ein Fehler aufgefallen oder Sie möchten uns eine neue Funktion vorschlagen? Dann schreiben Sie uns. Wir freuen uns auf Ihre Nachricht.</p>

        <div [hidden]="hideNameField">
          <mat-form-field>
            <input matInput
                   formControlName="name"
                   name="name"
                   placeholder="Geben Sie Ihren Namen ein."/>
          </mat-form-field>

          <mat-form-field>
            <input matInput
                   formControlName="email"
                   name="email"
                   type="email"
                   placeholder="Geben Sie Ihre E-Mail Adresse ein."/>
          </mat-form-field>
        </div>

        <mat-form-field>
          <textarea matInput
                    formControlName="message"
                    name="description"
                    required="required"
                    placeholder="Geben Sie hier Ihre Nachricht ein."
                    rows="16"></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Absenden
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
