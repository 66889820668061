import {Component, Input, OnInit} from '@angular/core';
import {MisPropertyRating} from '../../../services/analytic/model/simple.analysis.model';

@Component({
  selector: 'mis-rating-label',
  templateUrl: './rating.label.component.html',
  styleUrls: ['./rating.label.component.scss']
})
export class RatingLabelComponent implements OnInit {
  @Input() rating: MisPropertyRating;
  numberOfStars: number[] = [];

  ngOnInit() {
    switch (this.rating) {
      case 'TOP':
        this.numberOfStars = Array(5).fill(1);
        break;
      case 'ADVANCED':
        this.numberOfStars = Array(4).fill(1);
        break;
      case 'AVERAGE':
        this.numberOfStars = Array(3).fill(1);
        break;
      case 'LOWER':
        this.numberOfStars = Array(2).fill(1);
        break;
      case 'POOR':
        this.numberOfStars = Array(1).fill(1);
        break;
    }
  }
}
