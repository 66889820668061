import {Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractFormComponent} from '../../../../basic/form/abstract.form.component';
import {SavedSearch, SavedSearchService} from '../../../../../services/research/saved.search.service';
import {FilterQueryReader} from '../../filter-pane/filter.query.reader';
import * as _ from 'lodash';

@Component({
  selector:    'mis-search-name-modal',
  templateUrl: './search.name.modal.component.html',
  styleUrls:   ['./search.name.modal.component.scss']
})
export class SearchNameModalComponent extends AbstractFormComponent {
  showExistingWarning: boolean;
  savedSearchNames: string[] = [];

  constructor(public dialogRef: MatDialogRef<SearchNameModalComponent>,
              @Inject(MAT_DIALOG_DATA) public savedSearch: SavedSearch,
              private savedSearchService: SavedSearchService,
              private formBuilder: FormBuilder) {
    super();

    this.formGroup = formBuilder.group({
      'filterName': [this.buildRecommendedName(), Validators.required]
    });

    this.isLoading = true;
    this.savedSearchService.loadSavedSearches().subscribe((searches) => {
        this.savedSearchNames = searches.map(s => s.name);
        this.checkSearchName();
        this.isLoading = false;

      }, (err) => {
        this.isLoading = false;
      }
    );
  }

  private buildRecommendedName(): string {
    const querySpec = this.savedSearch.spec;

    let recommendedName = FilterQueryReader.getAngebotsTypDisplayName(querySpec) + ' ' + FilterQueryReader.getImmobilienTypDisplayName(querySpec);
    recommendedName += ' in ';
    recommendedName += FilterQueryReader.joinLocationFilters(querySpec);

    return recommendedName;
  }

  checkSearchName(): void {
    const searchName = this.formGroup.get('filterName').value;
    this.showExistingWarning = _.includes(this.savedSearchNames, searchName);
  }

  customSaveAction(): void {
    this.dialogRef.close(this.formGroup.get('filterName').value);
  }

  closeModal(): void {
    this.dialogRef.close(null);
  }
}
