<span *ngIf="!realtorUrl"
      [title]="title">
  <span>{{realtorName}}</span><br>
  <span *ngIf="realtorContact">{{realtorContact}}</span>
</span>

<a *ngIf="realtorUrl"
   [href]="realtorUrl"
   target="_blank"
   [title]="title">
  <span>{{realtorName}}</span><br>
  <span *ngIf="realtorContact">{{realtorContact}}</span>
</a>
