<div class="mis-filter-extended-items">
  <ul>

    <!-- ######### is active filter -->
    <li>
      <div class="mis-filter-extended-item">
        <div class="mis-filter-extended-item-filter-name">Aktive Immobilien</div>

        <div class="mis-filter-extended-item-field">
          <div class="mis-filter-extended-item-active">
            <mat-checkbox [checked]="showOnlyActiveProperties"
                          [disabled]="showOnlyActivePropertiesFilterDisabled"
                          (change)="changeShowOnlyActivePropertiesFilter($event.checked)">nur aktive anzeigen
            </mat-checkbox>
          </div>
        </div>
      </div>
    </li>

    <!-- ######### simple filter item -->
    <li *ngFor="let filter of extendedFilters">
      <div class="mis-filter-extended-item">
        <div class="mis-filter-extended-item-filter-name">{{filter.filterName}}
          <span *ngIf="!(viewType === 'IMMO_MANAGEMENT' && filter.fieldName === 'realtorName')"
                class="mis-filter-extended-item-remove"
                title="Diesen Filter entfernen"
                (click)="remove(filter)">
            <i class="fas fa-times-circle"></i>
          </span>
        </div>

        <div class="mis-filter-extended-item-field">
          <mis-filter-extended-item-boolean *ngIf="filter.filterSettings.type === 'BOOLEAN'"
                                            [queryFields]="filter.queryFields"
                                            [label]="filter.filterName"
                                            [settings]="filter.filterSettings"
                                            (changed)="changedBooleanFilter(filter, $event)"></mis-filter-extended-item-boolean>

          <mis-filter-extended-item-text *ngIf="filter.filterSettings.type === 'TEXT'"
                                         [queryFields]="filter.queryFields"
                                         [label]="filter.filterName"
                                         [settings]="filter.filterSettings"
                                         (changed)="changedTextFilter(filter, $event)"></mis-filter-extended-item-text>

          <mis-filter-extended-item-min-max *ngIf="filter.filterSettings.type === 'NUMBER_MIN_MAX'"
                                            [queryFields]="filter.queryFields"
                                            [label]="filter.filterName"
                                            [settings]="filter.filterSettings"
                                            (changed)="changedMinMaxFilter(filter, $event)"></mis-filter-extended-item-min-max>

          <mis-filter-extended-item-enum *ngIf="filter.filterSettings.type === 'ENUM'"
                                         [queryFields]="filter.queryFields"
                                         [label]="filter.filterName"
                                         [settings]="filter.filterSettings"
                                         (changed)="changedEnumFilter(filter, $event)"></mis-filter-extended-item-enum>

          <mis-filter-extended-item-reference-id *ngIf="filter.filterSettings.type === 'REFERENCE_ID'"
                                                 [queryFields]="filter.queryFields"
                                                 [label]="filter.filterName"
                                                 [settings]="filter.filterSettings"
                                                 (changed)="changedTextFilter(filter, $event)"></mis-filter-extended-item-reference-id>

          <mis-filter-extended-item-offerers *ngIf="filter.filterSettings.type === 'OFFERER'"
                                             [disabled]="viewType === 'IMMO_MANAGEMENT'"
                                             [queryFields]="filter.queryFields"
                                             [label]="filter.filterName"
                                             [settings]="filter.filterSettings"
                                             (changed)="changedOffererFilter(filter, $event)"></mis-filter-extended-item-offerers>
        </div>
      </div>
    </li>

    <!-- ######### more item -->
    <li>
      <div>

        <div class="mis-filter-extended-item mis-filter-extended-item-button"
             (click)="toggleShowMoreFilterDropDown()"
             (clickOutside)="showMoreFilterDropDown = false"
             #menuTrigger="matMenuTrigger"
             [matMenuTriggerFor]="filterMenu"
             title="Weitere Filtermöglichkeiten öffnen.">
          <span>Weitere Filter</span>
          <span>
            <i class="fas fa-plus"></i>
          </span>
        </div>

        <div class="mis-filter-extended-item mis-filter-extended-item-button"
             (click)="startSearch($event)"
             title="Suche Starten">
          <span>Suche starten</span>
          <span>
            <i class="fas fa-search"></i>
          </span>
        </div>

        <mat-menu #filterMenu="matMenu">
          <a *ngFor="let filter of availableFilters"
             mat-menu-item
             href="#" (click)="addFilter($event, filter)">{{filter.title}}</a>
        </mat-menu>
      </div>
    </li>
  </ul>

</div>
