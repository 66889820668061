import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AccountService} from '../../../../../services/aaa/account.service';
import {MisAccount, MisTagUtils} from '../../../../../services/aaa/model/account.model';
import {MisBaseUtils} from '../../../../../services/common/base.util';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';

@Component({
  selector: 'mis-account-flow-fact-data-edit-modal',
  templateUrl: './account.flow.fact.data.edit.modal.component.html'
})
export class AccountFlowFactDataEditModalComponent {
  account: MisAccount;
  formGroup: FormGroup;
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<AccountFlowFactDataEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: MisAccount,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private accountService: AccountService) {
    this.account = modalData;

    this.formGroup = formBuilder.group({
      'flowFactServerUrl': [MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerUrl).value],
      'flowFactServerCustomerId': [MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerCustomerId).value],
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    this.updateAccount();
  }

  updateAccount(): void {
    MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerUrl).value = MisBaseUtils.getValueOrDefault(this.formGroup.get('flowFactServerUrl').value, null);
    MisTagUtils.getTagByKey(this.account.tags, MisTagUtils.keys.flowFactServerCustomerId).value = MisBaseUtils.getValueOrDefault(this.formGroup.get('flowFactServerCustomerId').value, null);

    this.accountService.updateAccountTags(this.account.id, this.account.tags).subscribe(() => {
      this.showSuccessHint();
    }, (err) => {
      this.showFailedHint();
    });
  }

  private showSuccessHint(): void {
    this.snackBar.success('Die FlowFact Einstellungen wurden erfolgreich übernommen.');
    this.closeModal();
  }

  private showFailedHint(): void {
    this.snackBar.error('Die Aktualisierung der FlowFact Einstellungen ist fehlgeschlagen.');
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
