import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {RoutingService} from '../../../../services/routing.service';
import {ViewServiceInstance} from '../../../../viewservice/view.service';
import {AbstractFormComponent} from '../../../basic/form/abstract.form.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';
import {UserService} from '../../../../services/aaa/user.service';
import {MisTag, MisTagUtils} from '../../../../services/aaa/model/account.model';
import {MisEventUtils} from '../../../../services/common/event.util';

@Component({
  selector: 'mis-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent extends AbstractFormComponent implements OnInit {
  isMobile: boolean;
  formGroup: FormGroup;

  isTermsAcceptanceNecessary: boolean;
  isEmailVerificationNecessary: boolean;

  isSendingVerificationEmail: boolean;
  sentVerificationEmail: boolean;
  sentVerificationEmailFailed: boolean;

  constructor(private location: Location,
              private routingService: RoutingService,
              private authenticationProvider: AuthenticationProvider,
              private userService: UserService,
              formBuilder: FormBuilder) {
    super();

    if (!this.authenticationProvider.isProvisionalAuthenticated()) {
      this.routingService.navigate('/login');
      return;
    }

    this.isTermsAcceptanceNecessary = this.authenticationProvider.isTermsAcceptanceNecessary();
    this.isEmailVerificationNecessary = this.authenticationProvider.isEmailVerificationNecessary();

    // AGB erneut akzeptieren
    this.formGroup = formBuilder.group({});
    if (this.isTermsAcceptanceNecessary) {
      this.formGroup.addControl('termsAndPrivacy', new FormControl('', Validators.requiredTrue));
    }

    // E-Mail Verifitierung - Beides zusammen kann laut Micha nicht auftreten.
    if (this.isEmailVerificationNecessary) {
      this.authenticationProvider.confirmProvisionalLogin();
    }
  }

  ngOnInit(): void {
    this.isMobile = ViewServiceInstance.isMobile();
  }

  customSaveAction(): void {
    this.authenticationProvider.confirmProvisionalLogin();
    const user = this.authenticationProvider.getUser();

    if (this.isTermsAcceptanceNecessary) {
      const termsAcceptanceDateTag = new MisTag();
      termsAcceptanceDateTag.key = MisTagUtils.keys.termsAcceptanceDate;
      termsAcceptanceDateTag.type = 'DATE_TIME';
      termsAcceptanceDateTag.value = new Date().toISOString();

      this.userService.updateUserTag(user.id, termsAcceptanceDateTag).subscribe(() => {
        this.finishLogin();

      }, (error) => {
        this.authenticationProvider.successLogoutDone();
      });

    } else {
      this.finishLogin();
    }
  }

  private finishLogin(): void {
    this.authenticationProvider.triggerUserChangeCallback();
  }

  resendVerificationMail(event: Event) {
    MisEventUtils.stopEvent(event);

    this.isSendingVerificationEmail = true;
    this.sentVerificationEmail = false;
    this.sentVerificationEmailFailed = false;

    this.userService.resendVerificationEmail().subscribe(() => {
      this.isSendingVerificationEmail = false;
      this.sentVerificationEmail = true;

    }, (error) => {
      this.isSendingVerificationEmail = false;
      this.sentVerificationEmailFailed = true;
    });
  }
}
