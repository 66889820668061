import {AbstractControl, ValidatorFn} from '@angular/forms';
import {MisBaseUtils} from '../services/common/base.util';

export class MisValidators {

  static min(minValue: string | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minNumberValue = MisBaseUtils.parseNumber('' + minValue);
      const currentNumberValue = MisBaseUtils.parseNumber('' + control.value);

      return minNumberValue > currentNumberValue ? {'minimalError': 'Value is to small'} : null;
    };
  }

  static max(maxValue: string | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const maxNumberValue = MisBaseUtils.parseNumber('' + maxValue);
      const currentNumberValue = MisBaseUtils.parseNumber('' + control.value);

      return maxNumberValue < currentNumberValue ? {'maximalError': 'Value is to large'} : null;
    };
  }
}
