<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h3>Marktdaten für den Wohnungsmarkt {{city}}</h3>

    <p>{{ this.marketReportData.cityComparison.cityDescription}}</p>
    <p>{{ this.marketReportData.cityComparison.chartDescription}}</p>

    <div class="vertically-space"></div>
    <h4>Städtevergleich - Entwicklung des Kaufpreisfaktors</h4>
    <mis-line-chart *ngIf="showChart"
                    [chartWidth]="400"
                    [chartHeight]="200"
                    [maxWidth]="650"
                    [chartData]="cityComparisonChart"
                    [chartLegend]="true"></mis-line-chart>
  </div>
</div>
