<div class="mis-form-field-dropdown-wrapper mis-form-field-dropdown-{{displayMode}}"
     [ngClass]="{'disabled': disabled}"
     (clickOutside)="closeDropDown()">
  <div [title]="allSelectedValues">
    <div class="mis-form-field-dropdown-input-wrapper">

      <div *ngIf="isMultiSelect && objectKeys(internSelectedValues).length > 0"
           class="mis-form-field-dropdown-selected-count">{{objectKeys(internSelectedValues).length}}</div>

      <div>
        <div class="mis-form-field-dropdown-icon"
             *ngIf="icon">
          <i class="{{icon}}"></i>
        </div>

        <div *ngIf="!isAutoComplete"
             (click)="toggleDropDown()"
             class="mis-form-field-dropdown-selected-values"
             [ngClass]="{'mis-form-field-dropdown-selected-values-empty': objectKeys(internSelectedValues).length === 0}">
          <input value="{{selectedValuesAsString}}"
                 disabled="disabled">
        </div>

        <div *ngIf="isAutoComplete"
             (click)="toggleDropDown()"
             class="mis-form-field-dropdown-selected-values mis-form-field-dropdown-selected-values-multiple"
             [ngClass]="{'mis-form-field-dropdown-selected-values-empty': objectKeys(internSelectedValues).length === 0}">

          <span *ngFor="let selectedItemKey of objectKeys(internSelectedValues)">
            {{internSelectedValues[selectedItemKey].value}}
            <i *ngIf="!disabled"
               (click)="selectValueKey($event, selectedItemKey, true)"
               class="fas fa-times-circle"></i>
          </span>

          <span *ngIf="objectKeys(internSelectedValues).length === 0">{{label}}</span>
        </div>

        <div class="mis-form-field-dropdown-arrow"
             (click)="toggleDropDown($event)"
             [ngClass]="{'dropped-down': isDroppedDown}">
          <a href="#"
             (click)="toggle($event)"
             (keydown)="processKeyboardNavigation($event)">
            <i class="fas fa-chevron-down"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="mis-form-field-dropdown-values-wrapper"
         [ngClass]="{'open' : isDroppedDown, 'open-top': dropDownPosition == 'top'}">

      <div *ngIf="isAutoComplete"
           class="mis-form-field-dropdown-autocomplete-input-wrapper">
        <label class="mis-form-field-dropdown-autocomplete-input">
          <i class="fas fa-search"></i>
          <input #dropDownInput
                 (focus)="openDropDown()"
                 (keydown)="processKeyboardNavigation($event)"
                 (keyup)="triggerLoadAutoCompleteValues($event)">
        </label>
      </div>
      <div *ngIf="isLoadingAutoComplete">
        <mis-loading size="tiny"></mis-loading>
      </div>

      <!-- selected auto complete values -->
      <div class="mis-form-field-dropdown-values"
           *ngIf="(!values || objectKeys(values).length == 0) && isAutoComplete && (internSelectedValues && objectKeys(internSelectedValues).length > 0) && searchString === ''">
        <div *ngFor="let valueKey of objectKeys(internSelectedValues)"
             (click)="selectValueKey($event, valueKey, true)"
             class="mis-form-field-dropdown-value selected">
          <span *ngIf="isMultiSelect"
                class="mis-form-field-dropdown-value-icon">
            <i class="icon-not-selected far fa-square"></i>
            <i class="icon-selected fas fa-check-square"></i>
          </span> {{internSelectedValues[valueKey].value}}
          <span *ngIf="internSelectedValues[valueKey].additional"
                class="mis-form-field-dropdown-additional">{{internSelectedValues[valueKey].additional}}</span>
        </div>
      </div>

      <!-- selected auto nothing found -->
      <div class="mis-form-field-dropdown-values"
           *ngIf="searchString !== '' && (!values || objectKeys(values).length == 0)">
        <div class="mis-form-field-dropdown-value">Für <i>"{{searchString}}"</i> wurde nichts gefunden.</div>
      </div>

      <!-- values list -->
      <div *ngIf="values"
           class="mis-form-field-dropdown-values">
        <div *ngFor="let valueKey of objectKeys(values); let i = index"
             (click)="selectValueKey($event, valueKey, isSelected(valueKey))"
             (mouseover)="focusedIndex = i"
             class="mis-form-field-dropdown-value"
             [ngClass]="{'selected': isSelected(valueKey), 'focused': focusedIndex === i}">
          <span *ngIf="isMultiSelect"
                class="mis-form-field-dropdown-value-icon">
            <i class="icon-not-selected far fa-square"></i>
            <i class="icon-selected fas fa-check-square"></i>
          </span> {{values[valueKey].value}}

          <span *ngIf="values[valueKey].additional"
                class="mis-form-field-dropdown-additional">{{values[valueKey].additional}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
