import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MisBaseUtils} from '../../../../services/common/base.util';
import {MisValidators} from '../../../../directives/validators';
import {MisEventUtils} from '../../../../services/common/event.util';

export class FromUntilEvent {
  constructor(public minValue: number = null, public maxValue: number = null, public forcedChange: boolean = false) {
  }
}

export type FromUntilInputType = 'From' | 'Until';

@Component({
  selector:    'mis-form-field-from-until',
  templateUrl: './form.fields.from.until.component.html',
  styleUrls:   ['./form.fields.from.until.component.scss']
})
export class FormFieldsFromUntilComponent implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() controlNamePrefix: string;
  @Input() defaultFrom: number;
  @Input() defaultUntil: number;
  @Input() title: string;
  @Input() placeHolderFrom: string;
  @Input() placeHolderUntil: string;
  @Input() buffer = 1;
  @Input() steps = 0.01;
  @Input() decimalDigits = 2;
  @Input() useThousandSeparator: boolean = true;

  @Output() changedValues = new EventEmitter<FromUntilEvent>();

  @ViewChild('fromInput') fromInputElement: ElementRef<HTMLInputElement>;
  @ViewChild('untilInput') untilInputElement: ElementRef<HTMLInputElement>;

  isFormInitialized: boolean;

  hasErrors = false;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }

    const fromValue = MisBaseUtils.formatNumber(this.defaultFrom, this.decimalDigits, null,this.useThousandSeparator);
    const untilValue = MisBaseUtils.formatNumber(this.defaultUntil, this.decimalDigits, null, this.useThousandSeparator);

    if (!this.isFormInitialized) {
      const fromCtrl = new FormControl(fromValue, this.buildFullValidator());
      const untilCtrl = new FormControl(untilValue, this.buildFullValidator());

      this.formGroup.addControl(this.controlNamePrefix + 'From', fromCtrl);
      this.formGroup.addControl(this.controlNamePrefix + 'Until', untilCtrl);
      this.isFormInitialized = true;

    } else {
      this.formGroup.get(this.controlNamePrefix + 'From').setValue(fromValue);
      this.formGroup.get(this.controlNamePrefix + 'Until').setValue(untilValue);
    }
  }

  private buildFullValidator(validator: ValidatorFn = null): ValidatorFn {
    const validators = [];

    if (this.decimalDigits > 0) {
      validators.push(Validators.pattern('^\\d+(\\.|\\d)*([,]\\d{0,' + this.decimalDigits + '})?$'));
    } else {
      validators.push(Validators.pattern('^\\d+(\\.|\\d)*$'));
    }

    if (validator != null) {
      validators.push(validator);
    }

    return Validators.compose(validators);
  }

  changeFromInputValue(event): void {
    const currentInputValue = event.srcElement.value;
    const currentNumberValue = MisBaseUtils.parseNumber(currentInputValue);

    let newValue = MisBaseUtils.formatNumber(currentNumberValue, this.decimalDigits, null, this.useThousandSeparator);
    this.formGroup.get(this.controlNamePrefix + 'From').setValue(newValue);

    this.changeFromValue(currentInputValue);
  }

  changeFromValue(newValue: string, forcedChange: boolean = false): void {
    const newNumberValue = MisBaseUtils.parseNumber(newValue);
    const ctrl = this.formGroup.get(this.controlNamePrefix + 'Until');

    if (!newNumberValue) {
      ctrl.setValidators(this.buildFullValidator());
      ctrl.updateValueAndValidity();

      this.triggerChangeEventIfPossible(forcedChange);
      return;
    }

    ctrl.setValidators(this.buildFullValidator(MisValidators.min(newValue)));
    ctrl.updateValueAndValidity();

    this.triggerChangeEventIfPossible(forcedChange);
  }

  changeUntilInputValue(event): void {
    const currentInputValue = event.srcElement.value;
    const currentNumberValue = MisBaseUtils.parseNumber(currentInputValue);

    let newValue = MisBaseUtils.formatNumber(currentNumberValue, this.decimalDigits, null, this.useThousandSeparator);
    this.formGroup.get(this.controlNamePrefix + 'Until').setValue(newValue);

    this.changeUntilValue(currentInputValue);
  }

  changeUntilValue(newValue: string, forcedChange: boolean = false): void {
    const newNumberValue = MisBaseUtils.parseNumber(newValue);
    const ctrl = this.formGroup.get(this.controlNamePrefix + 'From');

    if (!newNumberValue) {
      ctrl.setValidators(this.buildFullValidator());
      ctrl.updateValueAndValidity();

      this.triggerChangeEventIfPossible(forcedChange);
      return;
    }

    ctrl.setValidators(this.buildFullValidator(MisValidators.max(newValue)));
    ctrl.updateValueAndValidity();

    this.triggerChangeEventIfPossible(forcedChange);
  }

  private checkValidationHint() {
    const minErrors = this.formGroup.get(this.controlNamePrefix + 'From').errors;
    const maxErrors = this.formGroup.get(this.controlNamePrefix + 'Until').errors;

    const hasMinErrors = minErrors && Object.keys(minErrors).length > 0;
    const hasMaxErrors = maxErrors && Object.keys(maxErrors).length > 0;

    this.hasErrors = hasMinErrors || hasMaxErrors;
  }

  triggerChangeEventIfPossible(forcedChange: boolean = false) {
    this.checkValidationHint();
    if (this.hasErrors) {

      return;
    }

    const fromValue = MisBaseUtils.parseNumber(this.formGroup.get(this.controlNamePrefix + 'From').value);
    const untilValue = MisBaseUtils.parseNumber(this.formGroup.get(this.controlNamePrefix + 'Until').value);

    this.changedValues.emit(new FromUntilEvent(MisBaseUtils.getValueOrDefault(fromValue, null), MisBaseUtils.getValueOrDefault(untilValue, null), forcedChange));
  }

  increase(event: Event, inputType: FromUntilInputType) {
    MisEventUtils.stopEvent(event);

    const currentValue = this.getCurrentValidValue(inputType);
    let newValue = 1;

    if (currentValue) {
      newValue = currentValue + this.steps;
    }

    this.formGroup.get(this.controlNamePrefix + inputType).setValue(MisBaseUtils.formatNumber(newValue, this.decimalDigits, null, this.useThousandSeparator));

    if (inputType === 'From') {
      this.changeFromValue('' + newValue);
    } else {
      this.changeUntilValue('' + newValue);
    }
  }

  decrease(event: Event, inputType: FromUntilInputType) {
    MisEventUtils.stopEvent(event);

    const currentValue = this.getCurrentValidValue(inputType);
    let newValue = 1;

    if (currentValue && currentValue > newValue) {
      newValue = currentValue - this.steps;
    }

    this.formGroup.get(this.controlNamePrefix + inputType).setValue(MisBaseUtils.formatNumber(newValue, this.decimalDigits, null, this.useThousandSeparator));

    if (inputType === 'From') {
      this.changeFromValue('' + newValue);
    } else {
      this.changeUntilValue('' + newValue);
    }
  }

  private getCurrentValidValue(inputType: FromUntilInputType): number {
    const currentRawValue = this.formGroup.get(this.controlNamePrefix + inputType).value;

    if (!currentRawValue) {
      return (inputType === 'Until') ? this.getCurrentValidValue('From') : 0;
    }
    return MisBaseUtils.parseNumber(currentRawValue);
  }

  keyDown(event: KeyboardEvent, inputType: FromUntilInputType) {

    if (event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Tab' ||
      event.key === 'Home' ||
      event.key === 'End' ||
      event.key === 'Control' ||
      event.ctrlKey ||
      event.metaKey) {

      return;
    }

    if (event.key === 'Enter') {

      if (inputType === 'From') {
        this.changeFromValue(this.formGroup.get(this.controlNamePrefix + 'From').value, true);
      } else {
        this.changeUntilValue(this.formGroup.get(this.controlNamePrefix + 'Until').value, true);
      }

      return;
    }

    if (event.key === 'ArrowUp') {
      this.increase(event, inputType);
      return;
    }
    if (event.key === 'ArrowDown') {
      this.decrease(event, inputType);
      return;
    }

    const isNumber = event.key.match(/[0-9]/);
    const isComma = event.key === ',';

    if (!isNumber && !isComma) {
      MisEventUtils.stopEvent(event);
      return;
    }
  }

  keyUp(event: KeyboardEvent, inputType: FromUntilInputType) {

    const currentInputValue = this.formGroup.get(this.controlNamePrefix + inputType).value;
    if (!currentInputValue ||
      event.key === 'a' ||
      event.key === 'c' ||
      event.key === 'x' ||
      event.key === 'v' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Shift' ||
      event.key === 'Home' ||
      event.key === 'End' ||
      event.key === 'Control' ||
      event.ctrlKey ||
      event.metaKey) {

      return;
    }

    const inputElement = (inputType === 'From' ? this.fromInputElement : this.untilInputElement).nativeElement;
    const currentCursorPosition = inputElement.selectionStart;
    const currentNumberValue = MisBaseUtils.parseNumber(currentInputValue);
    const isCommaKey = event.key === ',';

    // Setup decimal digits for formatter
    let decimalDigits = this.decimalDigits;
    if (isCommaKey) {
      decimalDigits = 0;
    }

    const hasComma = currentInputValue.indexOf(',') >= 0;
    if (!isCommaKey && hasComma) {
      const commaPosition = currentInputValue.indexOf(',');
      const currentDecimalLength = currentInputValue.substring(commaPosition).length - 1;
      decimalDigits = currentDecimalLength < decimalDigits ? currentDecimalLength : decimalDigits;
    }

    // Build and set new value - and validation hint
    let newValue = MisBaseUtils.formatNumber(currentNumberValue, decimalDigits, null, this.useThousandSeparator);

    if (event.key === ',' && this.decimalDigits > 0) {
      newValue += ',';
    }
    const formCtrl = this.formGroup.get(this.controlNamePrefix + inputType);
    formCtrl.setValue(newValue);
    this.checkValidationHint();

    // Update Cursor Position
    let newCursorPosition = currentCursorPosition;
    if (newValue == null) {
      return;
    }

    if ((newValue.length - currentInputValue.length) === 1) {
      newCursorPosition++;
    }
    if ((newValue.length - currentInputValue.length) === -1) {
      newCursorPosition--;
    }

    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    this.triggerChangeEventIfPossible();
  }
}
