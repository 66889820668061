import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import * as moment from 'moment';
import {Moment} from 'moment';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';

export class DateColumnData extends AbstractImmoTableColumnData {
  constructor(public showDateInfo: boolean) {
    super();
  }
}

export class DateColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: DateColumnData;

  constructor(showDateInfo: boolean) {
    super();
    this.component = ImmoTableColumnDateComponent;
    this.data = new DateColumnData(showDateInfo);
  }
}

@Component({
  selector: 'mis-immo-table-column-date',
  templateUrl: './immo.table.column.date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnDateComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: DateColumnData;

  dateMoment: Moment;
  dateString: string;

  ngOnInit(): void {
    const columnValue = this.getValue(this.researchItem, this.columnName);
    if (columnValue) {
      this.dateMoment = moment(columnValue);
      this.dateString = this.dateMoment.format('DD.MM.YYYY');
    }
  }

}
