<div class="mis-challenges mis-small-container">
  <div class="mis-small-container-item mis-small-container-item-active">
    <div class="mis-small-container-item-content">
      <div>
        <h1>Offene Bestätigungen</h1>

        <div *ngIf="!isLoading">
          <p>Bevor Sie ins Portal gelangen, müssen die unten stehenden Aktionen abschließen.</p>

          <form [formGroup]="formGroup"
                (submit)="saveForm()">

            <div *ngIf="isTermsAcceptanceNecessary"
                 class="mis-challenges-item">
              <h2>AGB- und Datenschutzbestimmungen</h2>
              <mat-checkbox ngClass="{{hasError('termsAndPrivacy') ? 'mis-error' : ''}}"
                            formControlName="termsAndPrivacy">
                Ich stimme den <a href="https://www.propfox.io/datenschutz" target="_blank">Allgemeinen
                Geschäftsbedingungen</a> und den
                <a href="https://www.propfox.io/agb" target="_blank">Datenschutzbestimmungen</a> laut DSGVO zu.*
              </mat-checkbox>
            </div>

            <div *ngIf="isEmailVerificationNecessary"
                 class="mis-challenges-item">
              <h2>E-Mail-Verifizierung</h2>
              <p>Wir haben festgestellt, dass Sie Ihre E-Mail-Adresse noch nicht verifiziert haben. Bitte holen Sie dies
                bald nach, andernfalls wird Ihr Zugang deaktiviert.</p>

              <div>
                <mis-loading *ngIf="isSendingVerificationEmail"
                             size="medium"></mis-loading>
                <p *ngIf="sentVerificationEmail">Die Verifizierungsemail wurde versandt, bitte prüfen Sie Ihr E-Mail-Postfach.</p>
                <p *ngIf="sentVerificationEmailFailed"
                   class="mis-error">Die Verifizierungsemail konnte nicht versandt werden, bitte versuchen Sie es erneut.</p>
              </div>
              <button *ngIf="!isSendingVerificationEmail && !sentVerificationEmail"
                      (click)="resendVerificationMail($event)"><i class="fas fa-redo-alt"></i> Erneut senden
              </button>
            </div>

            <button>Weiter zum Portal</button>
          </form>
        </div>
      </div>

      <div *ngIf="isLoading">
        <p>Die Änderungen werden gespeichert, anschließend werden Sie zum Portal weitergeleitet.</p>
        <mis-loading></mis-loading>
      </div>
    </div>
  </div>
</div>
