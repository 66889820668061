import {StatisticMeta, StatisticValues} from './price.atlas.model';

export class RenditeAtlasResponse {
  renditeAtlas: Rendite[];
  meta: RenditeAtlasMeta;
}

export class Rendite {
  postleitzahl: string;
  einwohner: number;
  rendite: StatisticValues;
  faktor: StatisticValues;
}

export class RenditeAtlasMeta {
  einwohner: StatisticMeta;
  rendite: StatisticMeta;
  faktor: StatisticMeta;
}
