import {Component, Input, OnInit} from '@angular/core';
import {PriceHistoryItem, ResearchItem} from '../../../../../../services/research/model/research.item.model';
import {ChartOptions} from 'chart.js';
import * as moment from 'moment';
import {MisBaseUtils} from '../../../../../../services/common/base.util';
import {LineChart} from '../../../../chart/line-chart/line.chart.component';
import {Color} from 'ng2-charts';
import {AuthenticationProvider} from '../../../../../../services/aaa/authentication.provider';

@Component({
  selector:    'mis-immo-price-history',
  templateUrl: './immo.price.history.component.html',
  styleUrls:   ['./immo.price.history.component.scss']
})
export class ImmoPriceHistoryComponent implements OnInit {
  @Input() researchItem: ResearchItem;
  @Input() maxLabelsXAxis: number = 12;
  @Input() maxLabelsYAxis: number = 10;
  @Input() type: 'PRICE' | 'PRICE_BY_SIZE' = 'PRICE';

  priceChart: LineChart;
  showChart = false;
  featureActivated: boolean;

  private scaleFactor = 2;

  constructor(authenticationProvider: AuthenticationProvider) {
    this.featureActivated = authenticationProvider.authorizedFor('ESSENTIAL');
  }

  ngOnInit(): void {
    if (this.type === 'PRICE') {
      this.buildTotalPriceChart();
    } else {
      this.buildBySizeChart();
    }
  }

  private buildTotalPriceChart(): void {
    this.priceChart = new LineChart();
    this.priceChart.colors = this.buildChartColors();

    this.buildChart(this.priceChart, (price) => {
      return price;
    });
  }

  private buildBySizeChart(): void {
    this.priceChart = new LineChart();
    this.priceChart.colors = this.buildChartColors();

    this.buildChart(this.priceChart, (price) => {
      if (!price || price === 0) {
        return price;
      }

      if (this.researchItem.wohnflaeche && this.researchItem.wohnflaeche >= 1) {
        return price / this.researchItem.wohnflaeche;
      }

      if (this.researchItem.grundstueckflaeche && this.researchItem.grundstueckflaeche >= 1) {
        return price / this.researchItem.grundstueckflaeche;
      }

      return price;
    });
  }

  private buildChartColors(): Color[] {
    return [
      {
        borderColor:     '#444',
        borderWidth:     1,
        backgroundColor: '#009EE3',
        pointRadius:     0,
      },
    ];
  }

  private buildChart(chart: LineChart, calculatePriceCallback: (price: number) => number): void {
    const priceChartData: number[] = [];
    const historyItemsByDate: { [key: string]: PriceHistoryItem } = {};
    this.showChart = true;

    if (!this.researchItem.priceHistory || this.researchItem.priceHistory.length === 0) {
      this.researchItem.priceHistory = [];
      this.researchItem.priceHistory.push(new PriceHistoryItem(this.researchItem.firstOccurrenceDate, this.researchItem.preis));
    }

    // add last day
    const today = moment();
    const validUntil = moment(this.researchItem.validUntil);
    const lastDateValue = (validUntil.isAfter(today) ? today : validUntil).toDate();
    const currentLastDateValue = this.researchItem.priceHistory[this.researchItem.priceHistory.length - 1].date;
    const diffDays = moment(lastDateValue).diff(currentLastDateValue, 'days');
    if (diffDays > 0) {
      this.researchItem.priceHistory.push(new PriceHistoryItem(lastDateValue, this.researchItem.preis));
    }

    // prepare history items
    this.researchItem.priceHistory.forEach(priceHistoryItem => {
      const dateKey = moment(priceHistoryItem.date).format('YYYY-MM-DD');
      historyItemsByDate[dateKey] = priceHistoryItem;
    });

    const firstDate = moment(this.researchItem.priceHistory[0].date).startOf('day');
    const lastDate = moment(this.researchItem.priceHistory[this.researchItem.priceHistory.length - 1].date).startOf('day');
    const diff = moment(lastDate).diff(firstDate, 'days');

    let currentValue = 0;

    for (let i = 0; i <= diff; i++) {
      const historyDate = moment(firstDate).add(i, 'days');
      const dateKey = historyDate.format('YYYY-MM-DD');

      chart.labels.push(historyDate.format('DD.MM.YYYY'));

      if (historyItemsByDate.hasOwnProperty(dateKey)) {
        currentValue = calculatePriceCallback(historyItemsByDate[dateKey].value);
        priceChartData.push(currentValue);
      } else {
        priceChartData.push(currentValue);
      }
    }

    if (diff === 0) {
      chart.labels.push(firstDate.format('DD.MM.YYYY'));
      priceChartData.push(currentValue);
    }

    // build price line chart
    chart.data.push({
      label:       'Preishistorie',
      data:        priceChartData,
      lineTension: 0, // avoid round curves
    });

    this.setupChartOptions(chart, priceChartData);
  }

  private setupChartOptions(chart: LineChart, priceChartData: number[]): void {
    let minValue = null;
    let maxValue = 0;

    priceChartData.forEach(item => {
      if (maxValue < item) {
        maxValue = item;
      }
      if (!minValue || minValue > item) {
        minValue = item;
      }
    });

    const scale = this.getYAxisScale(maxValue - minValue, maxValue);

    const min = this.getYAxisMinValue(minValue, scale);
    const max = this.getYAxisMaxValue(maxValue, scale);

    chart.options = this.buildChartOptions(min, max, scale);
  }

  private getYAxisMinValue(minValue: number, scale: number): number {
    if (!minValue) {
      return 0;
    }
    const scaleMultiplicator = scale / this.scaleFactor;
    const minValueResult = (Math.floor(minValue / scaleMultiplicator) * scaleMultiplicator) - (2 * scale);

    if (minValueResult < 0) {
      return 0;
    } else {
      return minValueResult;
    }
  }

  private getYAxisMaxValue(maxValue: number, scale: number): number {
    const scaleMultiplicator = scale / this.scaleFactor;
    return (Math.ceil(maxValue / scaleMultiplicator) * scaleMultiplicator) + (2 * scale);
  }

  private getYAxisScale(minMaxDifference: number, maxValue): number {
    let scale = this.scaleFactor / 10;
    let calcValue = minMaxDifference;

    if (minMaxDifference > 0) {
      calcValue = minMaxDifference;
    } else if (maxValue > 0) {
      calcValue = maxValue;
    } else {
      return scale;
    }

    while (calcValue > 1.2) {
      calcValue = calcValue / 10;
      scale *= 10;
    }
    return scale;
  }

  private buildChartOptions(yAxisMin: number, yAxisMax: number, yAxisSteps: number): ChartOptions {
    return {
      responsive: true,
      showLines:  true,
      spanGaps:   true,
      tooltips:   {
        mode:      'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            return MisBaseUtils.formatNumber(+tooltipItem.yLabel) + '€';
          }
        }
      },
      scales:     {
        xAxes: [{
          ticks: {
            autoSkip:      true,
            maxTicksLimit: this.maxLabelsXAxis
          }
        }],
        yAxes: [{
          ticks: {
            autoSkip:      true,
            maxTicksLimit: this.maxLabelsYAxis,
            min:           yAxisMin,
            max:           yAxisMax,
            stepSize:      yAxisSteps,
            callback:      function (value: any, index: any, values: any): string | number {
              return value ? MisBaseUtils.formatNumber(value) + '€' : '0,00€';
            }
          }
        }]
      },
      hover:      {
        mode:      'index',
        intersect: false,
      }
    };
  }
}
