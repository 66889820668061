import {Component, OnInit} from '@angular/core';
import {TariffService} from '../../../../services/aaa/tariff.service';
import {MisSubscription} from '../../../../services/aaa/model/tariff.model';
import {SubscriptionType} from '../../../../services/aaa/model/account.model';

export type TariffManagementViewType = 'TARIFF_SELECTION' | 'INVOICE_DATA_FORM';

@Component({
  selector: 'mis-tariff-management',
  templateUrl: './tariff.management.component.html',
  styleUrls: ['./tariff.management.component.scss']
})
export class TariffManagementComponent implements OnInit {
  view: TariffManagementViewType = 'TARIFF_SELECTION';
  isLoading = true;
  tariff: MisSubscription;
  selectedTariff: SubscriptionType;

  constructor(private tariffService: TariffService) {
  }

  ngOnInit(): void {
    this.loadTariff();
  }

  loadTariff(): void {
    this.isLoading = true;

    this.tariffService.getSubscription().subscribe((tariff) => {
      this.tariff = tariff;
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
    });
  }

  changedTariff(selectedTariff: SubscriptionType) {
    this.selectedTariff = selectedTariff;
    this.view = 'INVOICE_DATA_FORM';
  }

  reset() {
    this.view = 'TARIFF_SELECTION';
    this.loadTariff();
  }
}

