import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WatchlistService} from '../../../../../../services/research/watchlist.service';
import {ResearchItemId} from '../../../../../../services/research/model/research.item.id.model';
import {MisEventUtils} from '../../../../../../services/common/event.util';

@Component({
  selector: 'mis-watch-icon',
  templateUrl: './watch.icon.component.html',
  styleUrls: ['./watch.icon.component.scss']
})
export class WatchIconComponent implements OnInit, OnChanges {
  @Input() researchItemId: ResearchItemId;
  watched: boolean;
  isLoading: boolean;
  toolTip = 'Diese Immobilie auf Ihre Merkliste hinzufügen.';

  constructor(private watchlistService: WatchlistService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initWatchState();
  }

  ngOnInit(): void {
    this.initWatchState();
  }

  initWatchState(): void {
    this.watchlistService.isItemWatched(this.researchItemId)
      .subscribe((isWatched) => {
        this.watched = isWatched;
        this.updateToolTip();
      });
  }

  toggleWatching(event): void {
    MisEventUtils.stopEvent(event);

    if (!this.isLoading) {
      this.watched = !this.watched;
      this.updateToolTip();
      this.saveChangedWatchState();
    }
  }

  private updateToolTip(): void {
    if (this.watched) {
      this.toolTip = 'Diese Immobilie auf Ihre Merkliste hinzufügen.';
    } else {
      this.toolTip = 'Diese Immobilie befindet sich auf Ihrer Merkliste.';
    }
  }

  saveChangedWatchState(): void {
    this.isLoading = true;

    this.watchlistService.updateWatchState(this.researchItemId, this.watched).subscribe(() => {
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.watched = !this.watched;
    });
  }
}
