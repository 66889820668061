import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from '../abstract.service';
import { ImmobilienTyp, QuerySpec, SortField } from '../research/model/research.filter.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationProvider } from '../aaa/authentication.provider';
import { MisAuswertungRoot } from './model/legacy.analysis.model';
import { LocalStorageService } from "../local.storage.service";

export type AnalyticDownloadType = '.xlsx' | '.docx';

@Injectable({providedIn: 'root'})
export class AnalyticService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  downloadAuswertungByImmobilie(variant: AnalyticDownloadType, sourceName: string, externalId: string, immoTyp: ImmobilienTyp): Observable<Blob> {
    const url = environment.misAnalyticEndpoint + '/' + immoTyp + '/' + sourceName + '/' + externalId + '/auswertung' + variant;
    if (variant === '.xlsx') {
      return this.GET<Blob>(url, new HttpParams(), 'blob', {'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

    } else if (variant === '.docx') {
      return this.GET<Blob>(url, new HttpParams(), 'blob', {'accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
    }

    return new Observable(observer => {
      observer.error('Illegal variant export type.');
      observer.complete();
    });
  }

  downloadCalculationTool(sourceName: string, externalId: string, immoTyp: ImmobilienTyp): Observable<Blob> {
    const url = environment.misAnalyticEndpoint + '/' + immoTyp + '/' + sourceName + '/' + externalId + '/kalkulation.xlsx';
    return this.GET<Blob>(url, new HttpParams(), 'blob', {'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  }

  downloadAuswertung(variant: AnalyticDownloadType, filter: QuerySpec, sort: SortField): Observable<Blob> {
    const url = environment.misAnalyticEndpoint + '/auswertung' + variant;

    filter.sort = sort != null ? [sort] : [{'field': 'preis', 'direction': 'asc'}];
    const params = new HttpParams()
      .set('query', JSON.stringify(filter));

    if (variant === '.xlsx') {
      return this.GET<Blob>(url, params, 'blob', {'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

    } else if (variant === '.docx') {
      return this.GET<Blob>(url, params, 'blob', {'accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
    }

    return new Observable(observer => {
      observer.error('Illegal variant export type.');
      observer.complete();
    });
  }

  getAuswertungByImmobilie(sourceName: string, externalId: string, immoTyp: ImmobilienTyp): Observable<MisAuswertungRoot> {
    const url = environment.misAnalyticEndpoint + '/' + immoTyp + '/' + sourceName + '/' + externalId + '/auswertung.json';
    return this.GET<MisAuswertungRoot>(url);
  }
}
