<div>
  <h2 mat-dialog-title>{{modalData.title}}</h2>

  <mat-dialog-content>
    <div>{{modalData.message}}</div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane mis-buttons-center">
      <button [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
      <button [mat-dialog-close]="false">Nein</button>
    </div>
  </mat-dialog-actions>
</div>
