<mis-higher-tariff-required-hint *ngIf="!featureActivated"
                                 type="PRICE_CHART">
  Die Preishistorie ist erst ab dem Tarif "Essential" nutzbar.
</mis-higher-tariff-required-hint>

<div class="mis-immo-price-history"
     *ngIf="showChart && featureActivated">

  <mis-line-chart [chartWidth]="450"
                  [chartHeight]="200"
                  [chartData]="priceChart"></mis-line-chart>

</div>

