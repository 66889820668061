import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

export type DateFormatterType = 'DATE' | 'DATE_TIME';

@Pipe({name: 'misFormatDate'})
export class DateFormatterPipe implements PipeTransform {

  transform(value: Date | string, type: DateFormatterType = 'DATE'): string {
    if (value === null || value === '') {
      return '-';
    }

    if (type === 'DATE') {
      return moment(value).format('DD.MM.YYYY');
    } else if (type === 'DATE_TIME') {
      return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
    } else {
      return 'MISSING TYPE!';
    }
  }
}
