<nav class="mis-header-navigation"
     [joyrideStep]="guidedTourStep.HEADER_MAIN_NAVIGATION"
     title="Hauptmenü"
     text="Wählen Sie hier aus, welche Übersicht Sie sehen möchten.">
  <ul>
    <li *ngFor="let navItemKeys of objectKeys(navigationItems)">
      <mis-header-navigation-item [routerLinkTarget]="'/' + navItemKeys"
                                  [active]="navigationItems[navItemKeys].active"
                                  [label]="navigationItems[navItemKeys].label"
                                  [icon]="navigationItems[navItemKeys].icon"
                                  [count]="getCount(navItemKeys)"
                                  (visit)="visitLink(navigationItems[navItemKeys])"></mis-header-navigation-item>
    </li>
  </ul>
</nav>
