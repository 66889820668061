import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {MarketReportService} from "../../../../../services/market-report/market.report.service";
import {
  MarketReportCity,
  MarketReportConfiguration
} from "../../../../../services/market-report/model/market.report.configuration.model";
import {MatChipInputEvent} from "@angular/material/chips";
import {Observable} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {startWith} from "rxjs/operators";

@Component({
  selector: 'mis-market-report-configuration-modal',
  templateUrl: './market-report-configuration-modal.component.html'
})
export class MarketReportConfigurationModalComponent {
  formGroup: FormGroup;
  isLoading = false;

  cityCtrl = new FormControl('', Validators.required);
  cityDescriptionCtrl = new FormControl('', Validators.required);
  comparisonCityCtrl = new FormControl('');

  @ViewChild('comparisonCityInput') comparisonCityInput: ElementRef<HTMLInputElement>;
  selectedComparisonCities: string[] = [];
  filteredComparisonCitiesObservable: Observable<string[]>;
  isComparisonCitiesFieldInvalid: boolean = false;

  private allAvailableCities: string[] = [];
  private allAvailableCitiesDetails: { [cityName: string]: MarketReportCity } = {}

  constructor(public dialogRef: MatDialogRef<MarketReportConfigurationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public marketReportConfiguration: MarketReportConfiguration,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private marketReportService: MarketReportService) {

    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl('cityCtrl', this.cityCtrl);
    this.formGroup.addControl('cityDescriptionCtrl', this.cityDescriptionCtrl);
    this.formGroup.addControl('comparisonCityCtrl', this.comparisonCityCtrl);

    this.loadAvailableMarketReportCities();
  }

  private loadAvailableMarketReportCities(): void {
    this.marketReportService.loadAvailableMarketReportCities().subscribe({
      next: (cities) => {
        this.allAvailableCities = Object.keys(cities);
        this.allAvailableCitiesDetails = cities;

        this.initComparisonCityAutoComplete();
      },
      error: () => {
        // TODO
      }
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid || !this.comparisonCitiesValid()) {
      return;
    }

    this.isLoading = true;

    const marketReportConfiguration = new MarketReportConfiguration();
    marketReportConfiguration.city = this.cityCtrl.value;
    marketReportConfiguration.cityDescription = this.cityDescriptionCtrl.value;
    marketReportConfiguration.comparisonCities = this.selectedComparisonCities;

    this.marketReportService.saveMarketReportConfiguration(marketReportConfiguration).subscribe({
       next: () => {
         this.snackBar.success('Die Marktberichtkonfiguration wurde erfolgreich gespeichert.');
        this.dialogRef.close();
      },
      error: () => {
        this.snackBar.error('Beim Speichern der Marktberichtkonfiguration ist ein Fehler aufgetreten.');
        this.isLoading = false;
      }
    });
  }

  private initComparisonCityAutoComplete(): void {
    this.filteredComparisonCitiesObservable = new Observable<string[]>(subscriber => {
      this.comparisonCityCtrl.valueChanges
        .pipe(startWith(null))
        .subscribe({
          next: (city: string | null) => {
            if (city) {
              subscriber.next(this.filterAutoCompleteCities(city));
            } else {
              subscriber.next(this.getAvailableCities());
            }
          }
        });

      this.cityCtrl.valueChanges
        .subscribe({
          next: (city: string | null) => {
            subscriber.next(this.getAvailableCities());

            // fill default to description
            if (city
              && (!this.cityDescriptionCtrl.value || this.cityDescriptionCtrl.value == '')
              && this.allAvailableCitiesDetails.hasOwnProperty(city)) {
              this.cityDescriptionCtrl.setValue(this.allAvailableCitiesDetails[city].cityDescription);
            }
          }
        });
    });
  }

  private comparisonCitiesValid(): boolean {
    this.isComparisonCitiesFieldInvalid = false;

    if (this.selectedComparisonCities.length === 0) {
      this.isComparisonCitiesFieldInvalid = true;
      return false;
    }
    return true;
  }

  closeModal(): void {
    this.dialogRef.close();
  }


  addComparisonCity(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add selected city
    if (value && this.allAvailableCities.filter(city => city.toLowerCase() === value.toLowerCase()).length === 1) {
      this.selectedComparisonCities.push(value);
      this.isComparisonCitiesFieldInvalid = false;

      this.resetComparisonInput();
      this.updateDisableStateOfComparisonInput();
    }
  }

  removeComparisonCity(city: string): void {
    const index = this.selectedComparisonCities.indexOf(city);

    if (index >= 0) {
      this.selectedComparisonCities.splice(index, 1);
    }

    this.isComparisonCitiesFieldInvalid = !this.comparisonCitiesValid();
    this.updateDisableStateOfComparisonInput();
  }

  selectedComparisonCity(event: MatAutocompleteSelectedEvent): void {
    this.selectedComparisonCities.push(event.option.viewValue);
    this.isComparisonCitiesFieldInvalid = false;

    this.resetComparisonInput();
    this.updateDisableStateOfComparisonInput();
  }

  private resetComparisonInput(): void {
    // Clear the input value
    this.comparisonCityInput.nativeElement.value = '';
    this.comparisonCityCtrl.setValue(null);
  }

  private updateDisableStateOfComparisonInput(): void {
    // Clear the input value
    this.comparisonCityInput.nativeElement.value = '';
    this.comparisonCityCtrl.setValue(null);

    if (this.selectedComparisonCities.length >= 2) {
      this.comparisonCityCtrl.disable();
    } else {
      this.comparisonCityCtrl.enable();
    }
  }

  private filterAutoCompleteCities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.getAvailableCities().filter(city => city.toLowerCase().includes(filterValue));
  }

  getAvailableCities(withSelectedCity: boolean = false): string[] {
    const selectedCity = this.cityCtrl.value;

    return this.allAvailableCities.filter(city => {
      if (!withSelectedCity && selectedCity && selectedCity === city) {
        return false;
      }
      return this.selectedComparisonCities.filter(ignore => ignore === city).length === 0;
    });
  }

}
