<div class="mis-modal-medium">
  <h2 mat-dialog-title>FlowFact Einstellungen</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form [formGroup]="formGroup"
        (submit)="saveAndClose()"
        *ngIf="!isLoading">
    <mat-dialog-content>

      <mat-form-field>
        <input matInput
               formControlName="flowFactServerUrl"
               name="flowFactServerUrl"
               placeholder="Server Url">
      </mat-form-field>
      <mat-form-field>
        <input matInput
               formControlName="flowFactServerCustomerId"
               name="flowFactServerCustomerId"
               placeholder="Server Customer Id">
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Speichern
        </button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen
        </button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
