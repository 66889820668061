import {AngebotsTyp, ImmobilienTyp} from '../../research/model/research.filter.model';

export class PriceAtlasFilter {
  constructor(public propertyType: ImmobilienTyp,
              public offerType: AngebotsTyp,
              public zipCodes: string[],
              public validUntilDate: Date,
              public numberOfRooms: number = null) {
  }
}
