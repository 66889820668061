import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {
  FormFieldsInputLoadAutocompleteValue,
  FormFieldsInputLoadAutocompleteValues
} from '../../../../basic/form/form-fields-input/form.fields.input.component';
import {OffererService} from '../../../../../services/aaa/offerer.service';
import {MisOfferer} from '../../../../../services/aaa/model/offerer.model';
import {MisEventUtils} from '../../../../../services/common/event.util';

@Component({
  selector:    'mis-offerer-settings-modal',
  templateUrl: './offerer.settings.modal.component.html',
  styleUrls:   ['./offerer.settings.modal.component.scss']
})
export class OffererSettingsModalComponent {
  formOfferer: MisOfferer;
  formOffererValue: string;

  isLoading: boolean;
  objectKeys = Object.keys;
  sortedOffererList: { [key: string]: MisOfferer[] } = {};
  autoCompleteOffererList: MisOfferer[] = [];

  constructor(public dialogRef: MatDialogRef<OffererSettingsModalComponent>,
              private offererService: OffererService,
              private snackBar: SnackbarService) {

    this.resetForm();
    this.loadOfferers();
  }

  private loadOfferers(): void {
    this.isLoading = true;

    this.offererService.getList().subscribe((offerers) => {
      this.sortedOffererList = {};
      offerers.forEach((offerer) => {
        if (!this.sortedOffererList.hasOwnProperty(offerer.source)) {
          this.sortedOffererList[offerer.source] = [];
        }

        this.sortedOffererList[offerer.source].push(offerer);
      });
      this.isLoading = false;

    }, () => {
      this.snackBar.error('Die Anbieterliste konnte nicht geladen werden.');
      this.isLoading = false;
    });
  }

  changeOffererName(selectedOffererKey: string): void {
    if (selectedOffererKey === null) {
      this.formOfferer = null;
      return;
    }

    this.formOfferer = this.autoCompleteOffererList.find((offerer) => ('' + offerer.id) === selectedOffererKey);
    this.formOffererValue = this.formOfferer.name;
  }

  resetForm(): void {
    this.formOfferer = null;
    this.formOffererValue = null;
  }

  findOfferersByName(autoCompleteSetting: FormFieldsInputLoadAutocompleteValues) {

    this.offererService.searchOfferer(autoCompleteSetting.searchString).subscribe((offerers) => {
      let autoCompleteItems = {};
      offerers.forEach((offerer) => {
        autoCompleteItems[offerer.id] = new FormFieldsInputLoadAutocompleteValue(offerer.name, offerer.source + ' | ' + offerer.url);
      });
      this.autoCompleteOffererList = offerers;
      autoCompleteSetting.subscriber.next(autoCompleteItems);

    }, () => {
      autoCompleteSetting.subscriber.next({});
    });
  }

  saveOfferer() {
    if (this.formOfferer === null) {
      return;
    }

    this.isLoading = true;
    this.offererService.assignToAccount(this.formOfferer).subscribe(() => {
      this.resetForm();
      this.loadOfferers();
      this.snackBar.success('Die Anbieter Einstellungen wurden gespeichert.');

    }, () => {
      this.snackBar.error('Die Anbieter Einstellungen konnten nicht gespeichert werden.');
      this.isLoading = false;
    });
  }

  removeOffererItem(event: Event, offerer: MisOfferer): void {
    MisEventUtils.stopEvent(event);

    this.isLoading = true;
    this.offererService.removeFromAccount(offerer.id).subscribe(() => {
      this.loadOfferers();
      this.snackBar.success('Die Anbieter Einstellungen wurde gelöscht.');

    }, () => {
      this.snackBar.error('Die Anbieter Einstellungen konnte nicht gelöscht werden.');
      this.isLoading = false;
    });
  }
}
