import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';

@Component({
  selector:    'mis-search-share-modal',
  templateUrl: './search.share.modal.component.html',
  styleUrls:   ['./search.share.modal.component.scss']
})
export class SearchShareModalComponent {

  constructor(public dialogRef: MatDialogRef<SearchShareModalComponent>,
              @Inject(MAT_DIALOG_DATA) public urlToShare: string,
              private snackBar: SnackbarService) {
  }

  copyToClipboard(): void {

    window.navigator['clipboard'].writeText(this.urlToShare)
      .then(() => {
        this.snackBar.success('Die aktuelle Filterung wurde in den Zwischenspeicher kopiert.');
      }, (error) => {
        this.snackBar.error('Die Filtereinstellungen konnten nicht kopiert werden.');
      });
  }
}
