import {ResearchItem} from '../../../../../services/research/model/research.item.model';
import {ImmoTableColumnItem, ImmoTableFilterEvent} from './immo.table.column.item';
import {AbstractImmoTableColumnData} from './immo.table.column.data';
import {EventEmitter} from '@angular/core';

export abstract class AbstractImmoTableColumnItem implements ImmoTableColumnItem {
  additionalData: AbstractImmoTableColumnData;
  columnClick: EventEmitter<void>;
  columnName: string;
  filter: EventEmitter<ImmoTableFilterEvent>;
  researchItem: ResearchItem;

  protected getValue(row: ResearchItem, columnName: string): any {
    const columnNames = columnName.split('.');

    let value = row;
    if (value == null) {
      return null;
    }

    for (const columnNameItem of columnNames) {
      if (!value.hasOwnProperty(columnNameItem)) {
        return null;
      }

      value = value[columnNameItem];
    }

    return value;
  }
}
