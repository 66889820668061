import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MisEventUtils} from '../../../services/common/event.util';

@Component({
  selector: 'mis-menu-bar-button',
  templateUrl: './menu.bar.button.component.html',
  styleUrls: ['./menu.bar.button.component.scss']
})
export class MenuBarButtonComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() isLoading = false;

  @Output() clickAction = new EventEmitter();

  clickedAction(event): void {
    MisEventUtils.stopEvent(event);
    event.currentTarget.blur();

    if (!this.disabled) {
      this.clickAction.emit(event);
    }
  }
}
