<div class="mis-password-reset">
  <h1>Passwort vergessen?</h1>

  <mis-password-forgotten-step1 *ngIf="showStep1"
                                (done)="step1Done($event)">
  </mis-password-forgotten-step1>

  <mis-password-forgotten-step2 *ngIf="showStep2"
                                [email]="email"
                                (done)="step2Done($event)">
  </mis-password-forgotten-step2>

  <div *ngIf="showStep3">
    <mis-hint icon="fas fa-check"
              type="SUCCESS">Glückwunsch Sie haben Ihr Passwort erfolgreich zurückgesetzt.
    </mis-hint>
    <div class="mis-button-center">
      <button (click)="loginAndGoToDashboard()">Zum Dashboard</button>
    </div>
  </div>

</div>
