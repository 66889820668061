import {Component} from '@angular/core';
import {GuidedTourStep} from '../../../common/guided-tour/guided.tour.service';
import {MisEventUtils} from '../../../../services/common/event.util';
import {PriceAtlasFilter} from '../../../../services/price-altas/model/price.atlas.filter.model';
import {QueryField, QuerySpec, SortField} from '../../../../services/research/model/research.filter.model';
import {ResearchItem} from '../../../../services/research/model/research.item.model';
import {ViewServiceInstance} from '../../../../viewservice/view.service';
import {Subscription} from 'rxjs';
import {DEFAULT_SORT_CRITERIA} from '../../../common/immo-table/immo.overview.component';
import {PageEvent} from '@angular/material/paginator';
import {SortItem} from '../../../common/immo-table/immo-table/immo.table.component';
import {SavedSearchService} from '../../../../services/research/saved.search.service';
import {ResearchService} from '../../../../services/research/research.service';
import {FilterBuilder, FilterDateRangeEvent} from '../../../common/immo-table/filter-pane/filter.builder';
import {environment} from '../../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector:    'mis-price-atlas-table',
  templateUrl: './price-atlas-table.component.html',
  styleUrls:   ['./price-atlas-table.component.scss']
})
export class PriceAtlasTableComponent {
  guidedTourStep = GuidedTourStep;

  contentHeight: number = 400;
  styleContentHeight: string;
  tableOpened: boolean = false;
  hasFilterChanged: boolean;
  isLoading: boolean;

  priceAtlasFilter: PriceAtlasFilter;
  filterQuery: QuerySpec;
  selectedZipCode: string;
  sortField: SortField = DEFAULT_SORT_CRITERIA;
  researchItems: ResearchItem[];

  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageHitsPerPage: number;
  pageIndex = 0;
  pageItemCount = 0;
  totalPages = 1;

  isTableResizingEnabled: boolean = false;
  lastMouseMoveEvent: MouseEvent;

  viewServiceInstance = ViewServiceInstance;
  private loadListSubscriber: Subscription;

  constructor(private savedSearchService: SavedSearchService,
              private researchService: ResearchService) {

    this.pageHitsPerPage = environment.paginationDefaultHitsPerPage;
    const activeUserFilter = this.savedSearchService.getActiveSearch();
    if (activeUserFilter) {
      this.pageHitsPerPage = activeUserFilter.pageHits;
    }

    setInterval(() => {
      this.updateTableContainerHeight();
    }, 100);
  }

  toggleTable(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.tableOpened = !this.tableOpened;

    if (!this.tableOpened) {
      this.styleContentHeight = null;
    } else {
      this.styleContentHeight = this.contentHeight + 'px';
    }

    if (this.tableOpened && this.hasFilterChanged) {
      this.reloadResearchItems(0, this.pageHitsPerPage);
    }
  }

  updateFilter(filter: PriceAtlasFilter): void {
    this.priceAtlasFilter = filter;
    this.filterQuery = this.buildFilterQuery(filter);

    if (this.tableOpened) {
      this.reloadResearchItems(0, this.pageHitsPerPage);
    } else {
      this.hasFilterChanged = true;
    }
  }

  updateZipCode(zipCode: string): void {

    if (this.selectedZipCode === zipCode) {
      return;
    }

    this.selectedZipCode = zipCode;
    if (this.priceAtlasFilter) {
      this.updateFilter(this.priceAtlasFilter);
    }
  }

  private buildFilterQuery(filter: PriceAtlasFilter): QuerySpec {
    const filterBuilder = new FilterBuilder();
    filterBuilder.withOfferType(filter.offerType)
      .withPropertyType(filter.propertyType);

    if (!this.selectedZipCode) {
      return;
    }
    filterBuilder.withZipCodes([this.selectedZipCode]);

    const now = moment();
    const vud = moment(filter.validUntilDate);
    if (now.diff(vud, 'days') > 1) {
      // 1 Year
      filterBuilder.withDateRange(FilterDateRangeEvent.ofTimeRange(filter.validUntilDate, moment().toDate()));
    } else {
      // current active
      filterBuilder.withShowOnlyActive(true);
    }

    if (filter.numberOfRooms) {
      filterBuilder.withExtendsFilters([QueryField.eq('zimmerAnzahl', filter.numberOfRooms)]);
    }

    return filterBuilder.build();
  }

  reloadResearchItems(pageIndex: number, pageHits: number): void {

    // disabled immo management list loading
    if (this.loadListSubscriber) {
      this.loadListSubscriber.unsubscribe();
      this.loadListSubscriber = null;
    }

    this.hasFilterChanged = false;
    this.pageIndex = pageIndex;
    this.pageHitsPerPage = pageHits;

    if (!this.filterQuery) {
      this.researchItems = [];
      this.pageItemCount = 0;
      return;
    }
    this.isLoading = true;

    this.loadItemList(this.filterQuery);
  }

  private loadItemList(filterQuery: QuerySpec): void {
    this.researchItems = [];

    this.filterQuery = filterQuery;
    this.filterQuery.page = this.pageIndex;
    this.filterQuery.size = this.pageHitsPerPage;

    this.loadListSubscriber = this.researchService.getList(this.filterQuery, this.sortField).subscribe((result) => {
      this.researchItems = result.content;
      this.pageItemCount = result.totalElements;
      this.totalPages = result.totalPages;
      this.isLoading = false;

    }, () => {
      this.researchItems = [];

      this.pageItemCount = 0;
      this.isLoading = false;
    });
  }

  // ------------------------------------------------------------
  // Pagination / Sort
  changePagination(pageEvent: PageEvent): void {
    this.reloadResearchItems(pageEvent.pageIndex, pageEvent.pageSize);
  }

  getSortItem(): SortItem {
    const direction = this.sortField.direction === 'asc' ? 'ASC' : 'DESC';
    return new SortItem(direction, this.sortField.field);
  }

  changeSort(sortField: SortField): void {
    this.sortField = sortField;
    this.reloadResearchItems(0, this.pageHitsPerPage);
  }

  startDrag(event: MouseEvent) {
    MisEventUtils.stopEvent(event);
    this.isTableResizingEnabled = true;
  }

  stopDrag(event: MouseEvent) {
    this.isTableResizingEnabled = false;
  }

  drag(event: MouseEvent): void {
    if (this.isTableResizingEnabled) {
      this.lastMouseMoveEvent = event;
    }
  }

  private updateTableContainerHeight(): void {
    if (!this.tableOpened || !this.lastMouseMoveEvent) {
      return;
    }

    const newHeight = window.innerHeight - this.lastMouseMoveEvent.y;
    if (this.lastMouseMoveEvent.y > 100 && newHeight < (window.innerHeight - 150)) {
      this.contentHeight = newHeight;
      this.styleContentHeight = this.contentHeight + 'px';
    }
  }
}

