<div class="mis-form-field-file-upload">
  <label>
    <span class="mis-form-field-file-upload-label">
      {{placeholder}}
    </span>

    <span *ngIf="previewImage"
          class="mis-form-field-file-upload-preview">
      <img [src]="previewImage"
           alt="Vorschau">
      <a href="#"
         (click)="removeFile($event)">
        <i class="fas fa-trash-alt"></i> Löschen
      </a>
    </span>

    <input #fileInput
           [placeholder]="placeholder"
           type="file"
           [accept]="accept"
           (change)="handleFileInput($event)">

    <span *ngIf="maxFileSizeExceeded"
          class="mis-error">
      Die Datei darf maximal 2MB groß sein.
    </span>
    <span *ngIf="illegalFileType"
          class="mis-error">
      Die ausgewählte Datei entspricht nicht dem Typ JPG, PNG, SVG.
    </span>
  </label>
</div>
