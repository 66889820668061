<div class="mis-print-page mis-market-report-intro-page">
  <div class="mis-print-page-content">
    <div *ngIf="logo"
         class="mis-market-report-logo">
      <img [src]="logo"
           (load)="loaded.emit();"
           (error)="loaded.emit();">
    </div>

    <h1>Marktbericht</h1>
    <h2>{{city}}</h2>

    <p>erstellt am: {{now | misFormatDate}}</p>
  </div>
</div>

<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h3>Vorwort</h3>

    <p [innerHTML]="marketReportLayout?.introText | misNl2br"></p>
  </div>
</div>
