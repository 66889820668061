import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../services/aaa/authentication.service';
import {RoutingService} from '../../../../../services/routing.service';

@Component({
  selector: 'mis-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  loginFormGroup: FormGroup;
  showLoading: boolean;
  showLoginFailed: boolean;

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private routerService: RoutingService) {

    this.loginFormGroup = formBuilder.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required],
    });
  }

  login(): void {
    this.showLoginFailed = false;

    if (!this.loginFormGroup.valid) {
      return;
    }

    this.showLoading = true;
    const email = this.loginFormGroup.get('email').value;
    const password = this.loginFormGroup.get('password').value;
    this.authenticationService.authenticate(email, password).subscribe((authenticationResult) => {
      this.showLoading = false;

    }, (error) => {
      this.showLoading = false;
      this.showLoginFailed = true;
    });
  }
}
