import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector:    'mis-account-management-overview',
  templateUrl: './account.management.overview.component.html'
})
export class AccountManagementOverviewComponent implements OnInit {
  activeIndex = 0;

  constructor(private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const requestedAction = this.route.snapshot.routeConfig.path;
    if (requestedAction === 'usermanagement') {
      this.activeIndex = 1;
    } else if (requestedAction === 'tariffmanagement') {
      this.activeIndex = 2;
    }
  }

  changedTab(event: MatTabChangeEvent) {
    this.activeIndex = event.index;

    if (event.index === 0) {
      this.location.go('/account');
    } else if (event.index === 1) {
      this.location.go('/usermanagement');
    } else if (event.index === 2) {
      this.location.go('/tariffmanagement');
    }
  }
}
