<div class="mis-modal-large">
  <h2 mat-dialog-title>{{researchItem.title}}</h2>
  <div *ngIf="isLoading"
       class="mis-immo-details-loading-spinner">
    <mis-loading size="small"></mis-loading>
  </div>

  <mat-dialog-content>

    <div class="mis-row mis-immo-details">

      <div *ngIf="notFound"
           class="mis-column-100">
        <mis-hint type="WARN">
          Dieses Objekt kann aktuell nicht geladen werden oder befindet sich im Archiv. Daher können nicht alle
          Informationen ermittelt werden.
        </mis-hint>
      </div>

      <div class="mis-column-50">
        <div class="mis-row mis-short-details-pane">

          <mis-quick-info-item *ngIf="rendite"
                               [item]="buildQuickInfoItem('Rendite', 'fas fa-chart-line')">
            {{rendite.rendite.median * 100 | misNumberFormat}} %
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="rendite"
                               [item]="buildQuickInfoItem('Faktor', 'fas fa-chart-line')">
            {{rendite.faktor.median | misNumberFormat}}
          </mis-quick-info-item>

          <mis-quick-info-item [item]="buildQuickInfoItem('Preis', 'far fa-money-bill-alt')">
            {{researchItem.preis | misNumberFormat}} €
          </mis-quick-info-item>

          <mis-quick-info-item [item]="buildQuickInfoItem('Preis/m²', 'fas fa-euro-sign')">
            {{researchItem.preisJeQm | misNumberFormat}} €/m²
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.wohnflaeche > 0"
                               [item]="buildQuickInfoItem('Wohnfläche', 'fas fa-home')">
            {{researchItem.wohnflaeche | misNumberFormat}} m²
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.grundstueckflaeche > 0"
                               [item]="buildQuickInfoItem('Grundstückfläche', 'fas fa-home')">
            {{researchItem.grundstueckflaeche | misNumberFormat}} m²
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.zimmerAnzahl > 0"
                               [item]="buildQuickInfoItem('Zimmer', 'fas fa-door-open')">
            {{researchItem.zimmerAnzahl}}
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.nebenkosten > 0"
                               [item]="buildQuickInfoItem('Nebenkosten', 'fas fa-file-invoice-dollar')">
            {{researchItem.nebenkosten | misNumberFormat}} €
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.heizkosten > 0"
                               [item]="buildQuickInfoItem('Heizkosten', 'fas fa-temperature-high')">
            {{researchItem.heizkosten | misNumberFormat}} €
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.baujahr > 0"
                               [item]="buildQuickInfoItem('Baujahr', 'far fa-calendar-alt')">
            {{researchItem.baujahr}}
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.etage && researchItem.etage !== ''"
                               [item]="buildQuickInfoItem('Etage', 'far fa-building')">
            {{researchItem.etage}}
            <span *ngIf="researchItem?.etagenAnzahl && researchItem.etagenAnzahl !== ''">
                / {{researchItem.etagenAnzahl}}
            </span>
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItemDetails.zustand"
                               [item]="buildQuickInfoItem('Zustand', 'far fa-eye')">
            {{researchItemDetails.zustand}}
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.ausstattung && researchItem.ausstattung !== 'UNBEKANNT'"
                               [item]="buildQuickInfoItem('Ausstattung', '')">
            {{researchItem.ausstattung}}
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItem.energietraeger?.length > 1"
                               [item]="buildQuickInfoItem('Energieträger', '')">
            <span *ngFor="let energy of researchItem.energietraeger"
                  class="mis-details-item-chip">{{energy}}</span>
          </mis-quick-info-item>

          <mis-quick-info-item *ngIf="researchItemDetails.propertyType"
                               [item]="buildQuickInfoItem('Immobilientyp', '')">
            {{researchItemDetails.propertyType}}
          </mis-quick-info-item>

          <mis-quick-info-item [item]="buildQuickInfoItem('Gefunden am', 'far fa-calendar-times')">
            {{researchItem.firstOccurrenceDate | misFormatDate}}<br>
            ({{researchItem.firstOccurrenceDate | misAgeText}})
          </mis-quick-info-item>

          <mis-quick-info-item [item]="buildQuickInfoItem('Quelle', 'fas fa-external-link-alt')">
            <a [href]="researchItem.link"
               target="_blank"
               title="Öffne Quelle">
              {{researchItem.id.sourceName}}<br>{{researchItem.id.externalId}}</a>
          </mis-quick-info-item>

        </div>
      </div>
      <div class="mis-column-50 mis-details-right">

        <div class="mis-details-item mis-details-item-mobile-only"
             *ngIf="researchItemDetails?.images?.length > 0">
          <div class="mis-details-item-heading">Bilder</div>
        </div>
        <div class="mis-details-images"
             *ngIf="researchItemDetails?.images?.length > 0">
          <ul>
            <li *ngFor="let image of researchItemDetails.images; let i = index"
                class="mis-details-image"
                [ngStyle]="{transform: 'translateX(-' + (currentImageIndex * 100) + '%'}">
              <img [src]="image.url"
                   [alt]="image.alt"
                   [title]="image.alt"
                   (click)="openLightBox($event, i)">
            </li>
          </ul>

          <div *ngIf="researchItemDetails?.images?.length > 1"
               class="mis-details-images-previous"
               (click)="showPreviousImage()">
            <i class="fas fa-chevron-left"></i>
          </div>

          <div *ngIf="researchItemDetails?.images?.length > 1"
               class="mis-details-images-next"
               (click)="showNextImage()">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>

      <div class="mis-column-100">
        <div class="mis-row">
          <div class="mis-column-50">
            <div class="mis-details-item">
              <div class="mis-details-item-heading">Merkmale</div>
              <div>
                <span *ngFor="let feature of researchItemDetails.features"
                      class="mis-details-item-chip">{{feature}}</span>
              </div>
            </div>
          </div>
          <div class="mis-column-50">
            <div class="mis-details-item">
              <div class="mis-details-item-heading">Anbieter</div>
              <div>
                <span *ngIf="researchItem.realtorName && researchItem.realtorName !== ''">
                  <span
                    *ngIf="(!researchItem.realtorUrl || researchItem.realtorUrl === '')">{{researchItem.realtorName}}
                    <br></span>
                  <span *ngIf="researchItem.realtorUrl && researchItem.realtorUrl !== ''">
                    <a [href]="researchItem.realtorUrl" target="_blank" title="Zur Webseite des Anbieters">
                      <i class="fas fa-external-link-alt"></i> {{researchItem.realtorName}}
                    </a><br>
                  </span>
                </span>
                <span
                  *ngIf="researchItem.realtorContact && researchItem.realtorContact !== ''">{{researchItem.realtorContact}}
                  <br></span>
                <span
                  *ngIf="researchItemDetails.realtorPhone && researchItemDetails.realtorPhone !== ''">{{researchItemDetails.realtorPhone}}
                  <br></span>
                <span
                  *ngIf="researchItemDetails.realtorEmail && researchItemDetails.realtorEmail !== ''">{{researchItemDetails.realtorEmail}}
                  <br></span>
                <span
                  *ngIf="researchItem.realtorObjectId && researchItem.realtorObjectId !== ''">Objekt-ID: {{researchItem.realtorObjectId}}
                  <br></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mis-column-100">
        <div class="mis-details-item">
          <div class="mis-details-item-heading">Adresse</div>
          <div>
            <p>
              <a [href]="mapsLink()"
                 target="_blank"
                 title="Öffnet Google Maps">
                <i class="fas fa-map-marker-alt"></i> {{researchItem.adresse.rawLocation}}
              </a>
            </p>
            <div style="width: 100%">
              <mis-map height="400"
                       [markers]="mapMarkers"
                       [zipCode]="researchItem.adresse.postleitzahl"></mis-map>
            </div>
          </div>
        </div>
      </div>

      <div class="mis-column-100">
        <div class="mis-details-item">
          <div class="mis-row">
            <div class="mis-column-50 mis-details-charts">
              <mis-tabs-group>
                <mis-tab-item tabTitle="Angebotsspektrum"
                              tabTitleIcon="far fa-chart-bar"
                              (isActivated)="showAnalysisTab('BY_FILTER')">
                  <p>Aktuelles Objekt im Vergleich mit allen anderen Objekten ihres gewählten Suchfilters
                    verglichen.</p>
                  <mis-immo-analysis-by-filter *ngIf="activeAnalysisTab === 'BY_FILTER'"
                                               [filterQuery]="filterQuery"
                                               [researchItem]="researchItem"></mis-immo-analysis-by-filter>
                </mis-tab-item>
                <mis-tab-item tabTitle="Objektauswertung"
                              tabTitleIcon="fas fa-chart-pie"
                              (isActivated)="showAnalysisTab('BY_OBJECT')">
                  <p>Aktuelles Objekt im Vergleich zu ähnlichen Angeboten (Mikrolage, Größe, Art) der letzten 9
                    Monate.</p>
                  <mis-immo-analysis-by-object *ngIf="activeAnalysisTab === 'BY_OBJECT'"
                                               [researchItem]="researchItem"></mis-immo-analysis-by-object>
                </mis-tab-item>
              </mis-tabs-group>

            </div>
            <div class="mis-column-50 mis-details-charts">
              <mis-tabs-group>
                <mis-tab-item tabTitle="Preishistorie"
                              (isActivated)="showPriceHistoryTab('PRICE')">
                  <mis-immo-price-history *ngIf="activePriceHistoryTab === 'PRICE'"
                                          type="PRICE"
                                          maxLabelsXAxis="5"
                                          maxLabelsYAxis="5"
                                          [researchItem]="researchItem"></mis-immo-price-history>
                </mis-tab-item>
                <mis-tab-item tabTitle="Preishistorie / m²"
                              (isActivated)="showPriceHistoryTab('PRICE_BY_SIZE')">
                  <mis-immo-price-history *ngIf="activePriceHistoryTab === 'PRICE_BY_SIZE'"
                                          type="PRICE_BY_SIZE"
                                          maxLabelsXAxis="5"
                                          maxLabelsYAxis="5"
                                          [researchItem]="researchItem"></mis-immo-price-history>
                </mis-tab-item>
              </mis-tabs-group>
            </div>
          </div>
        </div>
      </div>

      <div class="mis-column-100">

        <div class="mis-details-item" *ngIf="researchItemDetails.propertyDescription">
          <div class="mis-details-item-heading">Objektbeschreibung</div>
          <div [innerHTML]="researchItemDetails.propertyDescription"></div>
        </div>

        <div class="mis-details-item" *ngIf="researchItemDetails.facilitiesDescription">
          <div class="mis-details-item-heading">Ausstattung</div>
          <div [innerHTML]="researchItemDetails.facilitiesDescription"></div>
        </div>

        <div class="mis-details-item" *ngIf="researchItemDetails.areaDescription">
          <div class="mis-details-item-heading">Lage</div>
          <div [innerHTML]="researchItemDetails.areaDescription"></div>
        </div>

        <div class="mis-details-item" *ngIf="researchItemDetails.otherDescription">
          <div class="mis-details-item-heading">Weiteres</div>
          <div [innerHTML]="researchItemDetails.otherDescription"></div>
        </div>

      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane">
      <button class="mis-button-pane-back"
              cdkFocusInitial
              type="button"
              (click)="closeModal()">Schließen
      </button>
    </div>
  </mat-dialog-actions>
</div>
