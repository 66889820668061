<div class="mis-adsense">

<!--  <ng-adsense adtest="on"-->
<!--              adClient="ca-pub-6526842202868084"-->
<!--              adSlot="0000000000"></ng-adsense>-->
<!--  display="inline-block"-->
<!--  adFormat="auto"-->

<!--  // adClient: 'ca-pub-6526842202868084',-->
<!--  // adSlot: 7259870550,-->


<!--    <ng-adsense [display]="'inline-block'"-->
<!--                 adtest="on"-->
<!--                 [width]="320"-->
<!--                 [height]="108"></ng-adsense>-->

</div>
