import {SubscriptionType} from './account.model';

export class TariffPrice {
  constructor(public currentAmount: number, public oldAmount: number = null) {
  }

  getCurrentEuroValue(): number {
    return Math.floor(this.currentAmount);
  }

  getCurrentCentValue(): string {
    const centValue = ((this.currentAmount - Math.floor(this.currentAmount)) * 100).toFixed(0);
    return centValue.length === 1 ? centValue + '0' : centValue;
  }
}

export class TariffInfoItem {
  constructor(public title: string,
              public shortDescription: string,
              public price: TariffPrice,
              public backendKey: SubscriptionType,
              public content: string[],
              public favorite: boolean = false) {
  }
}

// Backend
export class MisSubscription {
  type: SubscriptionType;
  nextType: SubscriptionType;
  bookingDateTime: Date;
  expirationDate: Date;
  interval: SubscriptionInterval;
  nextInterval: SubscriptionInterval;
}

export type SubscriptionInterval = 'MONTHLY' | 'SIX_MONTH' | 'YEARLY';
