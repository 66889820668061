import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";
import {Moment} from "moment";

@Pipe({
  name: 'misAgeText'
})
export class MisAgeTextPipe implements PipeTransform {

  transform(value: Moment|Date, nullValue: string = ''): string {
    if (value === null) {
      return nullValue;
    }

    const occurrence = moment(value).startOf('day');
    const now = moment().startOf('day');

    const days = now.diff(occurrence, 'days');
    if (days === 0) return 'Heute';
    if (days === 1) return 'Gestern';
    if (days === 2) return 'Vorgestern';
    if (days <= 6) return 'vor ' + days + ' Tagen';
    if (days <= 9) return 'vor einer Woche';
    if (days <= 16) return 'vor zwei Wochen';
    if (days <= 23) return 'vor drei Wochen';
    if (days <= 30) return 'vor vier Wochen';
    if (days <= 44) return 'vor einem Monat';
    if (days <= 72) return 'vor zwei Monaten';
    if (days <= 104) return 'vor drei Monaten';

    const month = now.diff(occurrence, 'month');
    return 'vor ' + month + ' Monaten';
  }

}
