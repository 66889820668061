<div class="mis-filter-extended-item-offerer">
  <mis-form-field-dropdown label="Anbieter"
                           [disabled]="disabled"
                           [isAutoComplete]="true"
                           [isMultiSelect]="true"
                           [allowEnteredValue]="false"
                           [values]="availableValues"
                           [selectedValues]="selectedOffererIds"
                           (loadAutoCompleteValues)="findOfferersByName($event)"
                           (changed)="changedValues($event)"></mis-form-field-dropdown>
</div>
