<div class="mis-immo-navigation"
     (click)="toggleNavigation($event)"
     [ngStyle]="{'height': navigationHeight}"
     [ngClass]="{'mis-immo-navigation-open': navigationOpened, 'mis-immo-navigation-desktop': viewServiceInstance.isDesktop(), 'mis-immo-navigation-mobile': (viewServiceInstance.isMobile() && !viewServiceInstance.isTablet())}"
     [joyrideStep]="guidedTourStep.IMMO_SHOW_SUB_MENU"
     title="Untermenü"
     text="Im Untermenü stehen Ihnen weitere Funktionen zur Verwaltung Ihrer Suchauftäge, zu umfangreichen Analysen oder zum Datenexport zur verfügung. Zeigen Sie die Ergebnisse zu einem gespeicherten Suchauftrag an, oder stellen Sie eine E-Mail-Benachrichtigung ein, um immer auf dem neusten Stand zu bleiben.">
  <a href="#"
     (click)="toggleNavigation($event)"
     class="mis-immo-navigation-toggle"
     title="{{navigationOpened ? 'Menü schließen' : 'Menü aufklappen'}}">
    <i class="fas fa-chevron-right"></i>
  </a>

  <div *ngIf="!navigationOpened"
       class="mis-immo-navigation-hint">
    Menü aufklappen
  </div>

  <div class="mis-immo-navigation-wrapper">
    <nav [hidden]="!navigationOpened">
      <div *ngIf="viewServiceInstance.isDesktop()"
           [joyrideStep]="guidedTourStep.IMMO_SUB_MENU_EXPORT"
           title="Auswertungen"
           text="Erhalten Sie umfangreiche Auswertungen und professionelle Statistiken passend zu Ihrem Suchergebnis um Ihre Markrecherche mit fundamentalen Daten zu untermauern.">
        <h4>Auswertungen</h4>
        <div class="mis-immo-navigation-links">
          <a href="#"
             (click)="exportResults($event, '.xlsx')"
             [ngClass]="{'disabled': isLoadingAuswertung || isLoadingExport}"
             title="Auswertungen/Marktbericht als Excel-Datei herunterladen">Excel-Auswertungen</a>
          <a href="#"
             (click)="exportResults($event, '.docx')"
             [ngClass]="{'disabled': isLoadingAuswertung || isLoadingExport}"
             title="Auswertungen/Marktbericht als Word-Datei herunterladen">Word-Auswertungen</a>
          <a href="#"
             (click)="exportResults($event, '.csv')"
             [ngClass]="{'disabled': isLoadingAuswertung || isLoadingExport}"
             title="Ergebnisse zur individuellen Weiterverarbeitung als CSV-Datei herunterladen">CSV-Export</a>
        </div>
      </div>

      <div [joyrideStep]="guidedTourStep.IMMO_SUB_MENU_MY_SEARCH"
           title="Ihre Suchaufträge und E-Mail-Benachrichtigungen"
           text="Hier können Sie Ihre Filtereinstellungen als dauerhafte Suchaufträge abspeichern und E-Mail-Benachrichtigungen verwalten. ">
        <h4>Suchaufträge</h4>

        <div *ngIf="savedSearches.length == 0"
             class="mis-immo-navigation-links">

          <a href="#"
             title="Die aktuellen Filtereinstellungen als Suchauftrag abspeichern"
             (click)="storeSearch($event)">Suchauftrag anlegen</a>
        </div>

        <div class="mis-immo-navigation-links">
          <a *ngFor="let savedSearch of savedSearches; let last = last"
             href="#"
             title="{{savedSearch.name}}"
             (click)="openSearch($event, savedSearch)">
            {{savedSearch.name}}
          </a>
          <a *ngIf="numberOfMoreSavedSearches > 0"
             href="#"
             title="Weitere Suchen anzeigen"
             (click)="openSavedSearchModal($event)">... +{{numberOfMoreSavedSearches}} weitere Suchen</a>
          <div *ngIf="savedSearches.length > 0"
               class="mis-immo-navigation-links-separator"></div>

          <a *ngIf="viewServiceInstance.isDesktop() || viewServiceInstance.isTablet()"
             href="#"
             title="Suchaufträge und E-Mail-Benachrichtigungen verwalten"
             (click)="openSavedSearchModal($event)">Suchaufträge verwalten</a>
          <a href="#"
             title="Die aktuellen FIltereinstellungen als Suchauftrag abspeichern"
             (click)="storeSearch($event)">Suchauftrag speichern</a>
        </div>
      </div>

      <div [joyrideStep]="guidedTourStep.IMMO_SUB_MENU_SHARE"
           title="Teilen Sie Ihre Filtereinstellungen"
           text="Lassen Sie Ihre Partner, Kollegen oder Freunde an Ihren Suchergebnissen teilhaben.">
        <h4>Weiteres</h4>
        <div class="mis-immo-navigation-links">
          <a *ngIf="viewType === 'RESEARCH'"
             href="#"
             (click)="shareFilter($event)">Filter teilen</a>

          <a *ngIf="viewType === 'IMMO_MANAGEMENT'"
             href="#"
             title="Anbieter Einstellungen"
             (click)="openOffererSettingsModal($event)">Anbieter Einstellungen</a>
        </div>
      </div>
    </nav>
  </div>
</div>
