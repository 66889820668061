<div class="saved-search-filters">

  <table>
    <tr>
      <td>Immobilientyp</td>
      <td>{{propertyType}}</td>
    </tr>
    <tr>
      <td>Angebotstyp</td>
      <td>{{offerType}}</td>
    </tr>
    <tr>
      <td>Ort / PLZ</td>
      <td>{{locations}}</td>
    </tr>

    <tr *ngFor="let extendedFilter of extendedFilters">
      <td>{{extendedFilter.filterName}}</td>
      <td>
        <div *ngIf="extendedFilter.filterSettings.type === 'BOOLEAN'">
          {{extendedFilter.queryFields[0].bValue ? 'Ja' : 'Nein'}}
        </div>

        <div *ngIf="extendedFilter.filterSettings.type === 'NUMBER_MIN_MAX'">
          {{buildMinMaxDisplayValue(extendedFilter)}}
        </div>

        <div *ngIf="extendedFilter.filterSettings.type === 'ENUM'">
          {{buildEnumDisplayValue(extendedFilter)}}
        </div>

        <div *ngIf="extendedFilter.filterSettings.type === 'TEXT'">
          {{extendedFilter.queryFields[0].sValue}}
        </div>
      </td>
    </tr>
  </table>

</div>
