import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationExtras, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RoutingService {
  private routeAfterLogin: ActivatedRouteSnapshot;

  constructor(private router: Router) {
  }

  setRouteAfterLogin(route: ActivatedRouteSnapshot): void {
    this.routeAfterLogin = route;
  }

  navigateAfterLogin(): void {
    if (!this.routeAfterLogin) {
      this.navigate('/research');
      return;
    }

    // navigateWithQuery
    const route = this.routeAfterLogin;
    let urlParts = route.url.map(urlSegment => urlSegment.path);

    this.navigateWithQuery(urlParts, {
      queryParams: route.queryParams
    });

    this.routeAfterLogin = null;
  }

  navigate(simpleUrl: string): Promise<boolean> {
    return this.router.navigate([simpleUrl]);
  }

  navigateWithQuery(urlSegments: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(urlSegments, extras);
  }

  getRouter(): Router {
    return this.router;
  }
}
