<div *ngIf="marketReportData && marketReportLayout">
  <mis-market-report-intro-page [city]="marketReportConfiguration.city"
                                [marketReportLayout]="marketReportLayout"
                                (loaded)="rendered('intro-page')"></mis-market-report-intro-page>

  <mis-market-report-overview-page [city]="marketReportConfiguration.city"
                                   [marketReportData]="marketReportData"
                                   [marketReportLayout]="marketReportLayout"
                                   (loaded)="rendered('overview-page')"></mis-market-report-overview-page>

  <mis-market-report-city-short-report-page [city]="marketReportConfiguration.city"
                                            [marketReportData]="marketReportData"
                                            [marketReportLayout]="marketReportLayout"
                                            (loaded)="rendered('city-short-report-page')"></mis-market-report-city-short-report-page>

  <mis-market-report-leasing-market-page [city]="marketReportConfiguration.city"
                                         [marketReportData]="marketReportData"
                                         [marketReportLayout]="marketReportLayout"
                                         (loaded)="rendered('leasing-market-page')"></mis-market-report-leasing-market-page>

  <mis-market-report-buyer-market-page [city]="marketReportConfiguration.city"
                                       [marketReportData]="marketReportData"
                                       [marketReportLayout]="marketReportLayout"
                                       (loaded)="rendered('buyer-market-page')"></mis-market-report-buyer-market-page>

  <mis-market-report-imprint-page (loaded)="rendered('imprint-page')"></mis-market-report-imprint-page>
</div>
