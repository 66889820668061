<div [formGroup]="formGroup"
     class="mis-form-field-color-picker">
  <mat-form-field>
    <input matInput
           [formControlName]="formGroupControlName"
           [placeholder]="placeholder"
           [(colorPicker)]="color"
           (colorPickerChange)="updateColor($event)"
           [required]="required">
    <span class="mis-form-field-color-picker-preview"
          [style.background]="color"></span>
  </mat-form-field>
</div>
