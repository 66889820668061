import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {LocalStorageService} from "../local.storage.service";

@Injectable({providedIn: 'root'})
export class FileuploadService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  upload(endpoint: string, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.POST(endpoint, formData);
  }
}
