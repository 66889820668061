<div class="mis-form-field-input">

  <div class="mis-form-field-input-wrapper"
       (clickOutside)="closeDropDown()">
    <input type="text"
           placeholder="{{placeholder}}"
           value="{{value}}"
           (blur)="changed($event)"
           (change)="changed($event)"
           (keydown)="processKeyboardNavigation($event)"
           (keyup)="triggerLoadAutoCompleteValues($event)"
           #input>
  </div>

  <div class="mis-form-field-dropdown-values-wrapper"
       [ngClass]="{'open' : isDroppedDown}">

    <div *ngIf="isLoadingAutoComplete">
      <mis-loading size="tiny"></mis-loading>
    </div>

    <!-- selected auto complete values -->
    <div class="mis-form-field-dropdown-values">
      <div *ngFor="let valueKey of objectKeys(autoCompleteValues); let i = index"
           (click)="selectValueKey($event, valueKey)"
           (mouseover)="focusedIndex = i"
           class="mis-form-field-dropdown-value"
           [ngClass]="{'focused': focusedIndex === i}">
        <span>{{autoCompleteValues[valueKey].value}}</span>
        <span>{{autoCompleteValues[valueKey].additional}}</span>
      </div>
    </div>
  </div>
</div>
