import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Preisatlas, PreisatlasMeta, StatisticValues} from '../../../../services/price-altas/model/price.atlas.model';
import {GuidedTourStep} from '../../../common/guided-tour/guided.tour.service';
import {PriceAtlasFilter} from '../../../../services/price-altas/model/price.atlas.filter.model';
import {MisEventUtils} from '../../../../services/common/event.util';
import {MatDialog} from '@angular/material/dialog';
import {PriceAtlasDetailsConfigurationModalComponent} from './price-atlas-details-configuration-modal/price-atlas-details-configuration-modal.component';
import {PriceAtlasField, PriceAtlasFields, PriceAtlasFieldType} from '../price.atlas.configuration';
import {MisBaseUtils} from '../../../../services/common/base.util';
import * as _ from 'lodash';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';
import {QuickInfoItem} from '../../../basic/quick-info-item/quick.info.item.component';

@Component({
  selector:        'mis-price-atlas-details',
  templateUrl:     './price.atlas.details.component.html',
  styleUrls:       ['./price.atlas.details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceAtlasDetailsComponent {
  @Input() zipCode: string;
  @Input() preisAtlas: Preisatlas;
  @Input() preisAtlasFilter: PriceAtlasFilter;
  @Input() preisatlasMeta: PreisatlasMeta;
  @Input() visiblePriceAtlasFields: PriceAtlasFieldType[] = [];
  @Output() changedVisiblePriceAtlasFields = new EventEmitter<PriceAtlasFieldType[]>();

  guidedTourStep = GuidedTourStep;
  priceAtlasFields = PriceAtlasFields;

  constructor(private dialog: MatDialog,
              private authenticationProvider: AuthenticationProvider) {
  }

  openConfigurationModal(event: Event) {
    MisEventUtils.stopEvent(event);
    const dialogRef = this.dialog.open(PriceAtlasDetailsConfigurationModalComponent, {
      data: this.visiblePriceAtlasFields
    });
    dialogRef.afterClosed().subscribe((updatedVisibleFields) => {
      if (updatedVisibleFields != null) {
        this.changedVisiblePriceAtlasFields.emit(updatedVisibleFields);
      }
    });
  }

  buildQuickInfoItem(priceAtlasField: PriceAtlasField): QuickInfoItem {
    let isEnabled = true;
    if (((priceAtlasField.type === 'rendite' || priceAtlasField.type === 'faktor') && this.preisAtlasFilter.offerType !== 'KAUF') || priceAtlasField.type === 'kaufkraft') {
      isEnabled = false;
    }

    return new QuickInfoItem(priceAtlasField.title,
      priceAtlasField.tooltip,
      priceAtlasField.icon,
      this.authenticationProvider.authorizedFor(priceAtlasField.minimalRequiredAccountSubscription()) && isEnabled);
  }

  getInfoItemValue(field: PriceAtlasField): string {
    if (((field.type === 'rendite' || field.type === 'faktor') && this.preisAtlasFilter.offerType !== 'KAUF') || field.type === 'kaufkraft') {
      return null;
    }

    const preisAtlasItem = this.preisAtlas[field.type];
    let value;

    if (!preisAtlasItem) {
      return null;
    } else if (preisAtlasItem.hasOwnProperty('median')) {
      value = (preisAtlasItem as StatisticValues).median;
    } else {
      value = preisAtlasItem;
    }

    const digits = field.getFractionsDigits(this.preisAtlasFilter.offerType === 'MIETE');
    return MisBaseUtils.formatNumber(value, digits) + ' ' + field.unit;
  }

  isFieldVisible(field: PriceAtlasField): boolean {
    return _.includes(this.visiblePriceAtlasFields, field.type);
  }

  isBulletChartAvailable(field: PriceAtlasField): boolean {
    if (!field.hasBulletChart ||
      !this.isFieldVisible(field) ||
      !this.authenticationProvider.authorizedFor(field.minimalRequiredAccountSubscription())) {
      return false;
    }

    if ((field.type === 'rendite' || field.type === 'faktor') && this.preisAtlasFilter.offerType !== 'KAUF') {
      return false;
    }

    return this.preisAtlas.hasOwnProperty(field.type) && this.preisAtlas[field.type] !== null;
  }

  getPreisAtlasItem(field: PriceAtlasField): StatisticValues {
    return this.preisAtlas[field.type] as StatisticValues;
  }

  getPreisAtlasItemChartMin(field: PriceAtlasField): number {
    if (field.type === 'rendite' || field.type === 'faktor') {
      return 0;
    }
    return this.preisatlasMeta[field.type].lowerBound;
  }

  getPreisAtlasItemChartMax(field: PriceAtlasField): number {
    if (field.type === 'rendite') {
      return 15;
    }
    if (field.type === 'faktor') {
      return 50;
    }
    return this.preisatlasMeta[field.type].upperBound;
  }
}
