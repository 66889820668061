<div class="mis-account-management-overview mis-column-boxed">

  <mat-tab-group (selectedTabChange)="changedTab($event)"
                 [selectedIndex]="activeIndex">
    <mat-tab label="Kontoverwaltung">
      <mis-account-management *ngIf="activeIndex === 0"></mis-account-management>
    </mat-tab>

    <mat-tab label="Nutzerverwaltung">
      <mis-user-overview *ngIf="activeIndex === 1"></mis-user-overview>
    </mat-tab>

    <mat-tab label="Tarifverwaltung">
      <mis-tariff-management *ngIf="activeIndex === 2"></mis-tariff-management>
    </mat-tab>
  </mat-tab-group>

</div>
