import {ChangeDetectionStrategy, Component, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';

export class RealtorColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnRealtorComponent;
  }
}

@Component({
  selector: 'mis-immo-table-column-realtor',
  templateUrl: './immo.table.column.realtor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmoTableColumnRealtorComponent extends AbstractImmoTableColumnItem implements OnInit {
  additionalData: AbstractImmoTableColumnData;

  realtorContact: string;
  realtorName: string;
  realtorObjectId: string;
  realtorUrl: string;
  title: string;

  ngOnInit(): void {
    this.realtorName = this.getValue(this.researchItem, 'realtorName');
    this.realtorUrl = this.getValue(this.researchItem, 'realtorUrl');
    this.realtorObjectId = this.getValue(this.researchItem, 'realtorObjectId');
    this.realtorContact = this.getValue(this.researchItem, 'realtorContact');

    this.buildTitle();
  }

  private buildTitle(): void {
    this.title = this.realtorName;

    if (this.realtorContact) {
      this.title += '\r\n' + this.realtorContact;
    }
  }
}
