import {Component, EventEmitter, Output} from '@angular/core';
import {AngebotsTyp} from '../../../../services/research/model/research.filter.model';
import {GuidedTourStep} from '../../../common/guided-tour/guided.tour.service';
import {FormFieldToggleItem, FormFieldToggleSelectedItem} from '../../../basic/form/form-field-toggle/form-field-toggle.component';
import * as moment from 'moment';
import {PriceAtlasFilter} from '../../../../services/price-altas/model/price.atlas.filter.model';
import {AuthenticationProvider} from '../../../../services/aaa/authentication.provider';

export class PriceAtlasFilterEvent {
  constructor(public offerType: AngebotsTyp,
              public validUntilDate: Date,
              public numberOfRooms: number) {
  }

  toPriceAtlas(zipCodes: string[]): PriceAtlasFilter {
    return new PriceAtlasFilter('WOHNUNG', this.offerType, zipCodes, this.validUntilDate, this.numberOfRooms);
  }
}

@Component({
  selector:    'mis-price-atlas-filter',
  templateUrl: './price-atlas-filter.component.html',
  styleUrls:   ['./price-atlas-filter.component.scss']
})
export class PriceAtlasFilterComponent {
  @Output() filterChanged = new EventEmitter<PriceAtlasFilterEvent>();

  guidedTourStep = GuidedTourStep;

  availableOfferItems = {
    'KAUF':  FormFieldToggleItem.ofValue('Kauf'),
    'MIETE': FormFieldToggleItem.ofValue('Miete'),
  };
  selectedOfferType: AngebotsTyp = 'KAUF';

  availableNumberOfRooms = {
    '1':    FormFieldToggleItem.ofValue('1'),
    '2':    FormFieldToggleItem.ofValue('2'),
    '3':    FormFieldToggleItem.ofValue('3'),
    '4':    FormFieldToggleItem.ofValue('4'),
    '5':    FormFieldToggleItem.ofValue('5+'),
    'alle': FormFieldToggleItem.ofValue('Alle'),
  };
  selectedNumberOfRooms: string = 'alle';
  roomNumberFilterDisabled: boolean = false;

  constructor(private authenticationProvider: AuthenticationProvider) {
    this.roomNumberFilterDisabled = !this.authenticationProvider.authorizedFor('ESSENTIAL');
  }

  changedOfferType(selectedOfferType: FormFieldToggleSelectedItem): void {
    this.selectedOfferType = selectedOfferType.key as AngebotsTyp;
    this.emitFilterChanged();
  }

  changedNumberOfRooms(selectedNumberOfRooms: FormFieldToggleSelectedItem): void {
    this.selectedNumberOfRooms = selectedNumberOfRooms.key;
    this.emitFilterChanged();
  }

  private getNumberOfRooms(): number {
    return this.selectedNumberOfRooms === 'alle' ? null : parseInt(this.selectedNumberOfRooms);
  }

  private emitFilterChanged(): void {
    const validUntilDate = moment().subtract(1, 'year');
    this.filterChanged.emit(new PriceAtlasFilterEvent(this.selectedOfferType, validUntilDate.toDate(), this.getNumberOfRooms()));
  }
}
