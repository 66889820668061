export class PreisatlasResponse {
  preisatlas: Preisatlas[];
  meta: PreisatlasMeta;
}

export class PreisatlasMeta {
  anzahl: StatisticMeta;
  einwohner: StatisticMeta;
  anzahlJeEinwohner: StatisticMeta;
  preis: StatisticMeta;
  preisJeQm: StatisticMeta;
  flaeche: StatisticMeta;
  alter: StatisticMeta;

  rendite: StatisticMeta;
  faktor: StatisticMeta;
}

export class StatisticMeta {
  lowerBound: number;
  upperBound: number;
  unit: string;
}

export class Preisatlas {
  postleitzahl: string;

  anzahl: number;
  anzahlJeEinwohner: number;
  einwohner: number;
  preis: StatisticValues;
  preisJeQm: StatisticValues;
  flaeche: StatisticValues;
  alter: StatisticValues;

  rendite: StatisticValues;
  faktor: StatisticValues;
}


export class StatisticValues {
  min: number;
  p20: number;
  median: number;
  mean: number;
  p80: number;
  max: number;
}
