export interface ViewServiceChangeListener {
  onResize(): void;
}

export class ViewService {

  private isViewMobile: boolean;
  private isViewTablet: boolean;
  private isViewDesktop: boolean;

  private listeners: ViewServiceChangeListener[] = [];

  constructor() {
    this.initUserAgent();
  }

  private initUserAgent(): void {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      this.isViewMobile = true;
    } else {
      this.isViewDesktop = true;
    }
  }

  updateViewSize(): void {
    this.isViewTablet = this.isMobile() && window.innerWidth > 1024;

    this.listeners.forEach(listener => {
      listener.onResize();
    });
  }

  isMobile(): boolean {
    return this.isViewMobile;
  }

  isTablet(): boolean {
    return this.isViewTablet;
  }

  isDesktop(): boolean {
    return this.isViewDesktop;
  }

  listenOnResize(listener: ViewServiceChangeListener): void {
    this.listeners.push(listener);
    listener.onResize();
  }

  stopListening(listener: ViewServiceChangeListener): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }
}

export const ViewServiceInstance = new ViewService();
