import {Component, Input} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';

export class LineChart {
  data: ChartDataSets[] = [];
  options: ChartOptions;
  colors: Color[];
  labels: Label[] = [];
}

@Component({
  selector: 'mis-line-chart',
  templateUrl: './line.chart.component.html',
  styleUrls: ['./line.chart.component.scss']
})
export class LineChartComponent {
  @Input() chartWidth = 400;
  @Input() chartHeight = 200;
  @Input() chartData: LineChart;
  @Input() maxWidth = 720;
  @Input() chartLegend = false;

  chartType = 'line';
  chartPlugins = [];
}
