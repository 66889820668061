import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImmoTableColumnDirective} from './immo.table.column.directive';
import {ImmoTableColumnItem, ImmoTableFilterEvent} from './immo.table.column.item';
import {ResearchItem} from '../../../../../services/research/model/research.item.model';
import {AbstractImmoTableColumn} from './immo.table.column.data';

@Component({
  selector:    'mis-immo-table-column',
  templateUrl: './immo.table.column.component.html'
})
export class ImmoTableColumnComponent implements OnInit {
  @Input() renderingConfig: AbstractImmoTableColumn;
  @Input() columnName: string;
  @Input() researchItem: ResearchItem;
  @Output() filter = new EventEmitter<ImmoTableFilterEvent>();
  @Output() columnClick = new EventEmitter<void>();
  @ViewChild(ImmoTableColumnDirective, {static: true}) vc: ImmoTableColumnDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.renderingConfig.component);

    const viewContainerRef = this.vc.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    const instance: ImmoTableColumnItem = componentRef.instance as ImmoTableColumnItem;
    instance.researchItem = this.researchItem;
    instance.columnName = this.columnName;
    instance.additionalData = this.renderingConfig.data;

    if (instance.filter) {
      instance.filter.subscribe(filter => {
        this.filter.emit(filter);
      });
    }

    if (instance.columnClick) {
      instance.columnClick.subscribe(ignore => {
        this.columnClick.emit();
      });
    }
  }
}
