<div class="mis-row mis-market-report">
  <div class="mis-column-100">
    <h2>
      Marktberichtseinstellungen
    </h2>

    <mis-field-box label="Martkberichtlayout anpassen"
                   [editable]="true"
                   (edit)="customizeMarketReport($event)">
      Gestalten Sie das Layout Ihres Marktberichtes auf Ihre speziellen Wünsche an.<br>
      Die Layoutanpassungen sind nur für Marktberichte verfügbar, welche von propfox.io herausgegeben werden.
    </mis-field-box>
  </div>

</div>
