import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ResearchItem} from '../../../../../../services/research/model/research.item.model';
import {IMMO_FEATURES_VALUES, IMMO_ZUSTAND_VALUES} from '../../immo-table-configuration/immo.table.filter.configuration';
import {ResearchService} from '../../../../../../services/research/research.service';
import {ResearchItemDetails, ResearchItemDetailsImage} from '../../../../../../services/research/model/research.item.details.model';
import {CrystalLightbox} from 'ngx-crystal-gallery';
import {MisEventUtils} from '../../../../../../services/common/event.util';
import {MapComponentMarker} from '../../../../map/map.component';
import {ImmoTableDetailsAnalysisTab, ImmoTableDetailsPriceHistoryTab} from '../immo.table.details.pane.component';
import {QuickInfoItem} from '../../../../../basic/quick-info-item/quick.info.item.component';
import {QuerySpec} from '../../../../../../services/research/model/research.filter.model';
import {LoadRenditeRequest, RenditeService} from '../../../../../../services/price-altas/rendite.service';
import {Rendite} from '../../../../../../services/price-altas/model/rendite.model';

export class ImmoDetailsModalComponentData {
  constructor(public researchItem: ResearchItem,
              public filterQuery: QuerySpec) {
  }
}

export class ResearchItemDetailsDetailed extends ResearchItemDetails {
  features: string[] = [];
  zustand: string = null;
  realtorPhone: string = null;
  realtorEmail: string = null;
}

@Component({
  selector:    'mis-immo-details-modal',
  templateUrl: './immo.details.modal.component.html',
  styleUrls:   ['./immo.details.modal.component.scss']
})
export class ImmoDetailsModalComponent {
  currentImageIndex: number = 0;
  isLoading: boolean = true;
  researchItemDetails: ResearchItemDetailsDetailed;
  researchItem: ResearchItem;
  mapMarkers: MapComponentMarker[] = [];
  activeAnalysisTab: ImmoTableDetailsAnalysisTab = 'BY_FILTER';
  activePriceHistoryTab: ImmoTableDetailsPriceHistoryTab = 'PRICE';
  notFound: boolean;
  filterQuery: QuerySpec;
  rendite: Rendite;

  constructor(private dialogRef: MatDialogRef<ImmoDetailsModalComponent>,
              @Inject(MAT_DIALOG_DATA) data: ImmoDetailsModalComponentData,
              private researchService: ResearchService,
              private lightbox: CrystalLightbox,
              private renditeService: RenditeService) {
    this.researchItem = data.researchItem;
    this.filterQuery = data.filterQuery;
    this.createDetailsModelWithoutDetails();

    this.loadDescription();
    this.loadRenditeIfNecessary();
  }

  private loadDescription(): void {
    this.researchService.getDetails(this.researchItem.id).subscribe((details) => {
      this.researchItemDetails = details as ResearchItemDetailsDetailed;
      this.fillDetailsModel();
      this.isLoading = false;

    }, (err) => {
      this.notFound = true;
      this.createDetailsModelWithoutDetails();
      this.isLoading = false;
    });
  }

  private createDetailsModelWithoutDetails(): void {
    this.researchItemDetails = new ResearchItemDetailsDetailed();
    this.researchItemDetails.images = [];
    this.researchItemDetails.address = this.researchItem.adresse.rawLocation;
    this.researchItemDetails.title = this.researchItem.title;

    this.fillDetailsModel();
  }

  private fillDetailsModel(): void {
    const researchItem = this.researchItem;

    if (this.researchItemDetails.lon && this.researchItemDetails.lat) {
      this.mapMarkers.push(new MapComponentMarker(this.researchItemDetails.lon, this.researchItemDetails.lat, this.researchItemDetails.isExactPosition));
    } else if (researchItem.adresse.latitude && researchItem.adresse.longitude) {
      this.mapMarkers.push(new MapComponentMarker(researchItem.adresse.longitude, researchItem.adresse.latitude, true));
    }

    const zustand = researchItem.zustand ? researchItem.zustand : 'UNBEKANNT';
    this.researchItemDetails.zustand = IMMO_ZUSTAND_VALUES.hasOwnProperty(zustand) ? IMMO_ZUSTAND_VALUES[zustand] : null;

    if (this.researchItemDetails.images.length === 0 && this.researchItem.imageUrl) {
      const image = new ResearchItemDetailsImage();
      image.url = this.researchItem.imageUrl;
      this.researchItemDetails.images = [image];
    }

    this.prepareFeatures();
  }

  private prepareFeatures(): void {
    if (this.researchItem.features) {
      this.researchItemDetails.features = this.researchItem.features.map((feature) => {
        if (!IMMO_FEATURES_VALUES.hasOwnProperty(feature)) {
          return this.researchItemDetails.features.push(feature);
        }
        return IMMO_FEATURES_VALUES[feature];
      });
    }
  }

  private loadRenditeIfNecessary(): void {

    if (this.filterQuery?.angebotsTyp === 'KAUF' &&
      this.filterQuery?.immobilienTyp === 'WOHNUNG' &&
      this.researchItem?.adresse?.postleitzahl) {

      let numberOfRooms = null;
      const price = this.researchItem.preis;
      const flaeche = this.researchItem.wohnflaeche;

      if (this.researchItem.zimmerAnzahl > 0) {
        numberOfRooms = this.researchItem.zimmerAnzahl;
      }

      const renditeRequest = new LoadRenditeRequest(numberOfRooms, price, flaeche);
      this.renditeService.loadRendite(this.filterQuery.immobilienTyp, this.researchItem.adresse.postleitzahl, renditeRequest).subscribe((rendite) => {
        this.rendite = rendite;

      }, () => {
      });
    }
  }

  showPreviousImage(): void {
    this.currentImageIndex = this.currentImageIndex === 0 ? (this.researchItemDetails.images.length - 1) : this.currentImageIndex - 1;
  }

  showNextImage(): void {
    this.currentImageIndex = (this.currentImageIndex >= (this.researchItemDetails.images.length - 1)) ? 0 : this.currentImageIndex + 1;
  }

  mapsLink(): string {
    if (this.researchItem?.adresse?.rawLocation && this.researchItem.adresse.rawLocation.length > 0) {
      return 'https://www.google.de/maps/search/' + encodeURI(this.researchItem.adresse.rawLocation);
    }
    return '';
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openLightBox(event: Event, index: number): void {
    MisEventUtils.stopEvent(event);

    const images = this.researchItemDetails.images.map((image) => {
      return {
        path:  image.url,
        index: index
      };
    });

    this.lightbox.open(images);
  }

  showAnalysisTab(activeTab: ImmoTableDetailsAnalysisTab) {
    this.activeAnalysisTab = activeTab;
  }

  showPriceHistoryTab(activeTab: ImmoTableDetailsPriceHistoryTab) {
    this.activePriceHistoryTab = activeTab;
  }

  buildQuickInfoItem(title: string, icon: string, isEnabled = true): QuickInfoItem {
    return new QuickInfoItem(title, title, icon, isEnabled);
  }
}
