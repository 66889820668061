import {Component, EventEmitter, OnInit, Type} from '@angular/core';
import {AbstractImmoTableColumnItem} from '../abstract.immo.table.column.item';
import {AbstractImmoTableColumn, AbstractImmoTableColumnData} from '../immo.table.column.data';
import {MisEventUtils} from '../../../../../../services/common/event.util';

export class TitleColumn extends AbstractImmoTableColumn {
  public component: Type<any>;
  public data: AbstractImmoTableColumnData;

  constructor() {
    super();
    this.component = ImmoTableColumnTitleComponent;
  }
}

@Component({
  selector: 'mis-immo-table-column-title',
  templateUrl: './immo-table-column-title.component.html',
  styleUrls: ['./immo-table-column-title.component.scss']
})
export class ImmoTableColumnTitleComponent extends AbstractImmoTableColumnItem implements OnInit {
  columnClick = new EventEmitter<void>();

  text: string;
  showInactiveInfo: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.text = this.getValue(this.researchItem, this.columnName);
    this.showInactiveInfo = this.researchItem.inactive;
  }

  triggerClick(event: Event): void {
    MisEventUtils.stopEvent(event);

    this.columnClick.emit();
  }
}
