import {Injectable} from '@angular/core';
import {PrintConfig, PrintContainerComponent} from "../components/basic/print-container/print.container.component";

@Injectable({providedIn: 'root'})
export class PrintService {
  private printComponents: PrintContainerComponent[] = [];

  add(print: PrintContainerComponent) {
    // add printer to array of active printers
    this.printComponents.push(print);
  }

  remove(id: string) {
    // remove printer from array of active printers
    this.printComponents = this.printComponents.filter(x => x.id !== id);
  }

  print(printConfig: PrintConfig, id: string = 'global-printer'): PrintContainerComponent {
    // open printer specified by id
    const printer = this.printComponents.find(x => x.id === id);
    printer.open(printConfig);

    return printer;
  }
}
