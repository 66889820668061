import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QueryField} from '../../../../../../services/research/model/research.filter.model';
import {AbstractImmoTableFilter} from '../../../immo-table/immo-table-configuration/immo.table.classes';

@Component({
  selector: 'mis-filter-extended-item-text',
  templateUrl: './filter.extended.item.text.component.html'
})
export class FilterExtendedItemTextComponent implements OnInit {
  @Input() queryFields: QueryField[];
  @Input() label: string;
  @Input() settings: AbstractImmoTableFilter;
  @Output() changed = new EventEmitter<string>();

  value = '';

  ngOnInit(): void {
    if (this.queryFields && this.queryFields.length > 0) {
      const text = this.queryFields[0].sValue;
      this.value = text;//; ? text.replace(/%/g, '') : text;
    }
  }
}
