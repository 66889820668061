import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector:    'mis-market-report-overview',
  templateUrl: './market-report-overview.component.html'
})
export class MarketReportOverviewComponent implements OnInit {
  activeIndex = 0;

  constructor(private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const requestedAction = this.route.snapshot.routeConfig.path;
    if (requestedAction === 'market-report-configuration') {
      this.activeIndex = 1;
    }
  }

  changedTab(event: MatTabChangeEvent) {
    this.activeIndex = event.index;

    if (event.index === 0) {
      this.location.go('/market-report');
    } else if (event.index === 1) {
      this.location.go('/market-report-configuration');
    }
  }
}
