import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {NotificationFrequency, PropertyNotificationSubscription, SavedSearch, SavedSearchService} from '../../../../../services/research/saved.search.service';
import {MisEventUtils} from '../../../../../services/common/event.util';
import {FormFieldsDropdownValue} from '../../../../basic/form/form-fields-dropdown/form.fields.dropdown.component';
import {AuthenticationProvider} from '../../../../../services/aaa/authentication.provider';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MisTag, MisTagUtils} from '../../../../../services/aaa/model/account.model';
import {UserService} from '../../../../../services/aaa/user.service';

export class SavedSearchDisplayModel extends SavedSearch {
  showDetails: boolean;
}

@Component({
  selector:    'mis-saved-search-modal',
  templateUrl: './saved.searches.modal.component.html',
  styleUrls:   ['./saved.searches.modal.component.scss']
})
export class SavedSearchesModalComponent implements OnInit {
  savedSearches: SavedSearchDisplayModel[];
  isLoading = true;
  isReceiveOffMarketOffers: boolean;

  availableNotificationFrequencies: { [key: string]: FormFieldsDropdownValue } = {
    'NONE':             new FormFieldsDropdownValue('keine'),
    'HOURLY':           new FormFieldsDropdownValue('Sofortbenachrichtigung'),
    'DAILY_IN_MORNING': new FormFieldsDropdownValue('morgens'),
    'DAILY_IN_EVENING': new FormFieldsDropdownValue('abends')
  };
  currentNotificationFrequency = 'NONE';
  isFrequencyEditable: boolean;

  illegalNotificationFrequencySelected: boolean;
  isTariffFree: boolean;

  constructor(public dialogRef: MatDialogRef<SavedSearchesModalComponent>,
              private snackBar: SnackbarService,
              private savedSearchService: SavedSearchService,
              private authenticationProvider: AuthenticationProvider,
              private userService: UserService) {

    this.isTariffFree = this.authenticationProvider.getUser().account.subscription === 'FREE';
    const offMarketTag = MisTagUtils.getTagByKey(authenticationProvider.getUser().tags, MisTagUtils.keys.notificationOffMarketOffers);
    if (offMarketTag) {
      this.isReceiveOffMarketOffers = offMarketTag.value;
    }
  }

  ngOnInit(): void {
    this.loadSavedSearches();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  loadSavedSearches(): void {
    this.savedSearches = null;
    this.savedSearchService.loadSavedSearches().subscribe((savedSearches) => {
      this.savedSearches = savedSearches.map(savedSearch => savedSearch as SavedSearchDisplayModel);
      this.initModal();
      this.isLoading = false;

    }, (error) => {
      this.isLoading = false;
    });
  }

  private initModal(): void {
    const searchSubscriptions = this.savedSearches.filter(savedSearch => {
      const searchSubscription = savedSearch.subscriptions;
      return searchSubscription && searchSubscription.length > 0;
    });

    this.isFrequencyEditable = searchSubscriptions.length > 0;
    if (this.isFrequencyEditable) {
      const firstSearchSubscription = searchSubscriptions.map(search => search.subscriptions[0])[0];
      this.currentNotificationFrequency = firstSearchSubscription.frequency;

    } else {
      this.currentNotificationFrequency = 'NONE';
    }
  }

  changeNotificationFrequency(values: { [key: string]: FormFieldsDropdownValue }): void {
    const notificationFrequency = Object.keys(values)[0];

    if (this.authenticationProvider.getUser().account.subscription === 'FREE' &&
      (notificationFrequency !== 'NONE' && notificationFrequency !== 'DAILY_IN_MORNING')) {

      this.illegalNotificationFrequencySelected = true;
      this.snackBar.error('Der Benachrichtigungsintervall könnte nicht gespeichert werden. Als Free Kunde ist diese Option auswählbar.');
      return;
    }

    this.illegalNotificationFrequencySelected = false;
    const observable = notificationFrequency === 'NONE' ? this.savedSearchService.deleteNotification() : this.savedSearchService.updateNotificationFrequency(notificationFrequency as NotificationFrequency);

    this.isLoading = true;
    observable.subscribe(() => {
      this.loadSavedSearches();
    }, (error) => {
      this.loadSavedSearches();
    });
  }

  changeNotificationForSearch(savedSearch: SavedSearch, enabledNotification: boolean) {
    this.isLoading = true;

    const notificationFrequency: NotificationFrequency = this.currentNotificationFrequency == 'NONE' ? 'DAILY_IN_MORNING' : this.currentNotificationFrequency as NotificationFrequency;
    const notificationSubscription = new PropertyNotificationSubscription('EMAIL', notificationFrequency);
    const observable = enabledNotification ? this.savedSearchService.updateNotificationSubscription(savedSearch, notificationSubscription) : this.savedSearchService.deleteNotificationSubscription(savedSearch);

    observable.subscribe(() => {
      this.loadSavedSearches();
    }, (error) => {
      this.loadSavedSearches();
    });
  }

  toggleSearchDetails(event: Event, savedSearch: SavedSearchDisplayModel): void {
    MisEventUtils.stopEvent(event);
    savedSearch.showDetails = !savedSearch.showDetails;
  }

  applySearch(event: Event, savedSearch: SavedSearch): void {
    MisEventUtils.stopEvent(event);
    this.dialogRef.close(savedSearch);
  }

  deleteSearch(event: Event, search: SavedSearch): void {
    MisEventUtils.stopEvent(event);

    this.savedSearchService.deleteSavedSearch(search).subscribe(() => {
      this.snackBar.success('Die Suche wurde gelöscht.');
      this.loadSavedSearches();

    }, () => {
      this.snackBar.error('Die gespeicherte Suche konnte nicht gelöscht werden.');
    });
  }

  changedReceiveOffMarketOffers(checkBoxChangeEvent: MatCheckboxChange) {
    const user = this.authenticationProvider.getUser();

    let offMarketTag = MisTagUtils.getTagByKey(user.tags, MisTagUtils.keys.notificationOffMarketOffers);
    if (!offMarketTag) {
      offMarketTag = new MisTag();
      offMarketTag.key = MisTagUtils.keys.notificationOffMarketOffers;
      offMarketTag.type = 'BOOLEAN';
      user.tags.push(offMarketTag);
    }
    offMarketTag.value = checkBoxChangeEvent.checked;

    this.userService.updateUserTag(user.id, offMarketTag).subscribe(() => {
      this.snackBar.success('Die Benachrichtungseinstellungen für Off-Market Angebote wurden geändert.');
    }, (err) => {
    });
  }
}
