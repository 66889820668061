import {ResearchItemId} from './research.item.id.model';
import {Adress} from './address.model';
import {AngebotsTyp, ImmobilienTyp} from "./research.filter.model";

export class PriceHistoryItem {
  date: Date;
  value: number;

  constructor(date: Date, value: number) {
    this.date = date;
    this.value = value;
  }
}

export class ResearchItem {
  id: ResearchItemId;
  key: string;
  title: string;
  link: string;
  imageUrl: string;
  adresse: Adress;
  preis: number;
  preisJeQm: number;
  wohnflaeche: number;
  zimmerAnzahl: number;
  merkmale: string;
  features: string[];

  angebotsTyp: AngebotsTyp;
  ausstattung: string;
  baujahr: number;
  createdDate: Date;
  firstOccurrenceDate: Date;
  energietraeger: [];
  etage: string;
  etagenAnzahl: string;
  grundstueckflaeche: number;
  heizkosten: number;
  immobilienTyp: ImmobilienTyp;
  kaution: number;
  latest: boolean;
  nebenkosten: number;
  source: {
    name: string;
    active: boolean;
  };
  validUntil: Date;
  zustand: string;
  freeOfCommission: boolean;
  realtorName: string;
  realtorUrl: string;
  realtorObjectId: string;
  realtorContact: string;

  hovered: boolean;
  tableShowDetails: boolean;
  tableIsVisible: boolean;
  inactive: boolean;

  priceHistory: PriceHistoryItem[];

}
