import {Component, Inject} from '@angular/core';
import {MisUser} from '../../../../../../services/aaa/model/account.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../../../services/aaa/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../../basic/snackbar/snackbar.service';

@Component({
  selector: 'mis-password-change-modal',
  templateUrl: './password.change.modal.component.html'
})
export class PasswordChangeModalComponent {
  user: MisUser;
  formGroup: FormGroup;
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<PasswordChangeModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: MisUser,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private userService: UserService) {
    this.user = modalData;

    this.formGroup = formBuilder.group({
      'passwordOld': ['', Validators.required],
    });
  }

  saveAndClose(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;

    const oldPassword = this.formGroup.get('passwordOld').value;
    const newPassword = this.formGroup.get('password').value;

    this.userService.changePassword(oldPassword, newPassword).subscribe(() => {
      this.snackBar.success('Ihr Passwort wurde erfolgreich geändert.');
      this.dialogRef.close(true);
    }, (err) => {
      this.isLoading = false;
      this.snackBar.error('Ihr Passwort konnte nicht geändert werden. Prüfen Sie ob Sie Ihr altes Passwort korrekt eingegeben haben.');
    });
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
