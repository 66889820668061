import {Component, EventEmitter, Output} from '@angular/core';
import {MarketReportExportSubComponent} from "../market.report.export.component";

@Component({
  selector: 'mis-market-report-imprint-page',
  templateUrl: './market.report.imprint.page.component.html',
  styleUrls: ['./market.report.imprint.page.component.scss']
})
export class MarketReportImprintPageComponent extends MarketReportExportSubComponent {
  @Output() loaded = new EventEmitter<void>();
}

