<div class="mis-immo-table-column-image">
  <div *ngIf="imageUrl"
       class="mis-immo-img-wrapper">
    <div>
      <img [src]="imageUrl"
           alt="Bild zur Immobilie"
           (click)="triggerClick($event)"
           (error)="showSkeleton()">
    </div>
  </div>

  <div *ngIf="!imageUrl"
       class="mis-immo-img-skeleton"
       title="Für dieses Objekt ist leider kein Bild verfügbar"
       (click)="triggerClick($event)">
    <i class="fas fa-home"></i>
  </div>
</div>
