<div class="mis-modal-medium">
  <h2 mat-dialog-title>Suche teilen</h2>

  <p>Die folgende URL enthält alle von Ihnen gewählten Sucheeinstellungen. Kopieren Sie die URL und versenden Sie diese an Partner, Kollegen oder Freunde um Ihre Ergebnisliste zu teilen.</p>

  <div class="mis-search-share-modal-share-content">
    <div class="mis-search-share-modal-share-content-logo"></div>
    <input value="{{urlToShare}}" disabled="disabled">
    <i class="far fa-copy"
       title="Url in die Zwischenablage kopieren"
       (click)="copyToClipboard()"></i>
  </div>

  <mat-dialog-actions align="end">
    <div class="mis-button-pane">
      <button class="mis-button-pane-back"
              type="button"
              (click)="dialogRef.close()">Schließen
      </button>
    </div>
  </mat-dialog-actions>
</div>
