import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {FileuploadService} from '../../../../services/common/fileupload.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarService} from '../../snackbar/snackbar.service';


export interface FileuploadModalData {
  title: string;
  description: string;
  endpoint: string;
}

@Component({
  selector: 'mis-fileupload-modal',
  templateUrl: './fileupload.modal.component.html',
  styleUrls: ['./fileupload.modal.component.scss']
})
export class FileuploadModalComponent {
  @ViewChild('file') fileInput;
  modalData: FileuploadModalData;
  formGroup: FormGroup;
  isLoading = false;
  uploadFailed = false;

  constructor(private formBuilder: FormBuilder,
              private cd: ChangeDetectorRef,
              private fileuploadService: FileuploadService,
              public dialogRef: MatDialogRef<FileuploadModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: FileuploadModalData,
              private snackBar: SnackbarService) {
    this.modalData = modalData;

    this.formGroup = formBuilder.group({
      'file': ['', Validators.required],
    });
  }

  startFileUpload(): void {
    if (!this.formGroup.valid) {
      return;
    }

    const endpoint = this.modalData.endpoint;
    const fileInputElement = this.fileInput.nativeElement;

    if (fileInputElement.files && fileInputElement.files[0]) {
      this.isLoading = true;
      const fileToUpload = fileInputElement.files[0];

      this.fileuploadService.upload(endpoint, fileToUpload).subscribe(res => {
        this.snackBar.success('Die Datei wurde erfolgreich hochgeladen und verarbeitet.');
        this.dialogRef.close();

      }, () => {
        this.isLoading = false;
        this.uploadFailed = true;
      });
    }
  }
}
