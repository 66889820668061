import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ResearchItem} from '../../../../../services/research/model/research.item.model';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../../../viewservice/view.service';
import {MatDialog} from '@angular/material/dialog';
import {ImmoDetailsModalComponent, ImmoDetailsModalComponentData} from './immo-details-modal/immo.details.modal.component';
import {AnalyticService} from '../../../../../services/analytic/analytic.service';
import {MisEventUtils} from '../../../../../services/common/event.util';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {MapComponentMarker} from '../../../map/map.component';
import {QuerySpec} from '../../../../../services/research/model/research.filter.model';

export type ImmoTableDetailsTab = 'STATISTICS' | 'PRICE_HISTORY';
export type ImmoTableDetailsAnalysisTab = 'BY_FILTER' | 'BY_OBJECT';
export type ImmoTableDetailsPriceHistoryTab = 'PRICE' | 'PRICE_BY_SIZE';

@Component({
  selector:    'mis-immo-table-details-pane',
  templateUrl: './immo.table.details.pane.component.html',
  styleUrls:   ['./immo.table.details.pane.component.scss']
})
export class ImmoTableDetailsPaneComponent implements OnInit, OnDestroy, ViewServiceChangeListener {
  @Input() filterQuery: QuerySpec;
  @Input() researchItem: ResearchItem;
  @Input() visible: boolean;
  @Input() marginLeft: number;

  contentWidth: number;
  isDownloadActive: boolean;
  showTabs = true;
  showTabStatistics: boolean = false;
  activeAnalysisTab: ImmoTableDetailsAnalysisTab = 'BY_FILTER';
  activePriceHistoryTab: ImmoTableDetailsPriceHistoryTab = 'PRICE';
  mapMarkers: MapComponentMarker[] = [];

  constructor(private dialog: MatDialog,
              private snackBar: SnackbarService,
              private analyticService: AnalyticService) {
  }

  ngOnInit(): void {
    ViewServiceInstance.listenOnResize(this);

    let address = this.researchItem.adresse;
    if (address != null) {
      let lon = address.longitude;
      let lat = address.latitude;
      if (lon > 0 && lat > 0) {
        this.mapMarkers.push(new MapComponentMarker(lon, lat, address.strasse?.length > 0));
      }
    }
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    const newContentWidth = window.innerWidth - 150;

    this.contentWidth = newContentWidth < 500 ? 500 : newContentWidth;
    this.showTabs = window.innerWidth < 1270;
  }

  showTab(view: ImmoTableDetailsTab) {
    this.showTabStatistics = view === 'STATISTICS';
  }

  openDetails(): void {
    this.dialog.open(ImmoDetailsModalComponent, {
      data: new ImmoDetailsModalComponentData(this.researchItem, this.filterQuery)
    });
  }

  downloadAuswertung(event: Event): void {
    MisEventUtils.stopEvent(event);

    if (this.isDownloadActive) {
      return;
    }
    this.isDownloadActive = true;

    const res = this.analyticService
      .downloadAuswertungByImmobilie('.xlsx', this.researchItem.id.sourceName, this.researchItem.id.externalId, this.researchItem.immobilienTyp);
    res.subscribe((result) => {
      saveAs(result, 'propfox-auswertung-' + this.researchItem.id.sourceName + '-' + this.researchItem.id.externalId + '.xlsx');
      this.isDownloadActive = false;

    }, (error) => {
      this.snackBar.error('Die Auswertung konnte nicht heruntergeladen werden.');
      this.isDownloadActive = false;
    });
  }

  // downloadCalculationTool(event: Event): void {
  //   MisEventUtils.stopEvent(event);
  //
  //   if (this.isDownloadActive) {
  //     return;
  //   }
  //   this.isDownloadActive = true;
  //
  //   const res = this.analyticService.downloadCalculationTool(this.researchItem.id.sourceName, this.researchItem.id.externalId);
  //   res.subscribe((result) => {
  //     saveAs(result, 'propfox-kalkulation-' + this.researchItem.id.sourceName + '-' + this.researchItem.id.externalId + '.xlsx');
  //     this.isDownloadActive = false;
  //   }, (error) => {
  //     this.snackBar.error('Das Kalkulationstool konnte nicht heruntergeladen werden.');
  //     this.isDownloadActive = false;
  //   });
  // }

  showAnalysisTab(activeTab: ImmoTableDetailsAnalysisTab) {
    this.activeAnalysisTab = activeTab;
  }

  showPriceHistoryTab(activeTab: ImmoTableDetailsPriceHistoryTab) {
    this.activePriceHistoryTab = activeTab;
  }
}
