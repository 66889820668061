import {Component} from '@angular/core';
import {AuthenticationService} from '../../../../../services/aaa/authentication.service';
import {RoutingService} from '../../../../../services/routing.service';

@Component({
  selector:    'mis-password-forgotten-wrapper',
  templateUrl: './password.forgotten.wrapper.component.html'
})
export class PasswordForgottenWrapperComponent {
  showStep1 = true;
  showStep2: boolean;
  showStep3: boolean;
  email: string;
  password: string;

  constructor(private authenticationService: AuthenticationService,
              private routingService: RoutingService) {
  }

  step1Done(email): void {
    this.showStep1 = false;
    this.showStep2 = true;

    this.email = email;
  }

  step2Done(password): void {
    this.showStep2 = false;
    this.showStep3 = true;

    this.password = password;
  }

  loginAndGoToDashboard(): void {
    this.authenticationService.authenticate(this.email, this.password).subscribe((user) => {
      this.routingService.navigate('/research');
    }, (error) => {
      this.routingService.navigate('/login');
    });
  }

  resetFromParent(): void {
    this.showStep1 = false;
    this.showStep2 = false;
    this.email = null;
    this.password = null;

    setTimeout(() => {
      this.showStep1 = true;
    }, 50);
  }
}
