import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {GraphicStatistic} from "../../../../services/market-report/model/market.report.model";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'mis-graphic-statistic',
  templateUrl: './graphic-statistic.component.html',
  styleUrls: ['./graphic-statistic.component.scss']
})
export class GraphicStatisticComponent implements OnChanges {
  @Input() statistic: GraphicStatistic;

  svgUrl?: SafeUrl;

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.statistic?.svgImageBase64) {
      this.svgUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/svg+xml;base64,' + this.statistic.svgImageBase64);
    }
  }
}
