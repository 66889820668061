import {
  AfterViewChecked,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {PrintService} from "../../../viewservice/print.service";

export class PrintConfig {
  constructor(public component: Type<PrintComponent>,
              public data: any = null) {
  }
}

export interface PrintComponent {
  printContainer: PrintContainerComponent;
  data: any;
  isInitialised: boolean;

  initPrint(): void;
}

@Component({
  selector: 'mis-print-container',
  templateUrl: './print.container.component.html',
  styleUrls: ['./print.container.component.scss']
})
export class PrintContainerComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() id: string;

  @ViewChild('appPrintContent', {read: ViewContainerRef, static: false})
  private appPrintContent: ViewContainerRef;

  private printConfig: PrintConfig;
  private printComponent: PrintComponent;
  private canPrint: boolean;

  private readonly element: any;

  constructor(private printService: PrintService,
              private el: ElementRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('printer must have an id');
      return;
    }

    this.printService.add(this);
  }

  ngAfterViewChecked(): void {
    if (this.canPrint && this.printComponent?.isInitialised) {
      this.canPrint = false;

      window.onafterprint = (event) => {
        const viewContainerRef = this.appPrintContent;
        viewContainerRef.clear();
      };
      window.print();
    }
  }

  ngOnDestroy(): void {
    this.printService.remove(this.id);
    this.element.remove();
  }

  open(printConfig: PrintConfig): void {
    if (!printConfig) {
      console.log('PrintConfig was not set!');
      return;
    }

    if (!printConfig.component) {
      console.log('PrintConfig component was not set!');
      return;
    }

    this.printConfig = printConfig;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(printConfig.component);
    const viewContainerRef = this.appPrintContent;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    const printInstance = componentRef.instance as PrintComponent;
    printInstance.printContainer = this;
    printInstance.data = this.printConfig.data;
    printInstance.initPrint();
    this.printComponent = printInstance;

    this.canPrint = true;
  }
}
