import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SavedSearch, SavedSearchService} from '../../../../services/research/saved.search.service';
import {SnackbarService} from '../../../basic/snackbar/snackbar.service';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../../viewservice/view.service';
import {MatDialog} from '@angular/material/dialog';
import {MisEventUtils} from '../../../../services/common/event.util';
import {SavedSearchesModalComponent} from './saved-searches-modal/saved.searches.modal.component';
import {SearchShareModalComponent} from './search-share-modal/search.share.modal.component';
import {QuerySpec} from '../../../../services/research/model/research.filter.model';
import {FilterBuilder} from '../filter-pane/filter.builder';
import {SearchNameModalComponent} from './search-name-modal/search.name.modal.component';
import {GuidedTourService, GuidedTourStep, GuidedTourType} from '../../guided-tour/guided.tour.service';
import {ImmoOverviewViewType} from '../immo.overview.component';
import {OffererSettingsModalComponent} from './offerer-settings-modal/offerer.settings.modal.component';

@Component({
  selector:    'mis-immo-navigation',
  templateUrl: './immo.navigation.component.html',
  styleUrls:   ['./immo.navigation.component.scss']
})
export class ImmoNavigationComponent implements OnInit, OnDestroy, ViewServiceChangeListener {
  @Input() filterQuery: QuerySpec;
  @Input() isLoadingAuswertung: boolean;
  @Input() isLoadingExport: boolean;
  @Input() viewType: ImmoOverviewViewType;
  @Output() applySearch = new EventEmitter<SavedSearch>();
  @Output() refreshFiltering = new EventEmitter<void>();
  @Output() export = new EventEmitter<string>();
  @Output() changedOpenState = new EventEmitter<boolean>();

  guidedTourStep = GuidedTourStep;
  viewServiceInstance = ViewServiceInstance;

  navigationHeight: string;
  navigationOpened: boolean;
  savedSearches: SavedSearch[] = [];
  numberOfMoreSavedSearches: number = 0;

  constructor(private savedSearchService: SavedSearchService,
              private snackBar: SnackbarService,
              private dialog: MatDialog,
              private guidedTourService: GuidedTourService) {

    ViewServiceInstance.listenOnResize(this);
  }

  ngOnInit() {
    this.loadSavedSearches();
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    this.navigationHeight = window.innerHeight + 'px';
  }

  loadSavedSearches(): void {
    this.savedSearchService.loadSavedSearches().subscribe((savedSearches) => {
      this.savedSearches = savedSearches.slice(Math.max(savedSearches.length - 7, 0)).reverse();
      this.numberOfMoreSavedSearches = (savedSearches.length > 7) ? (savedSearches.length - 7) : 0;

    }, () => {
      this.savedSearches = [];
      this.numberOfMoreSavedSearches = 0;
    });
  }

  // ------------------------------------------------------------
  // Export
  exportResults(event: Event, variant: string): void {
    MisEventUtils.stopEvent(event);

    this.export.emit(variant);
  }

  // ------------------------------------------------------------
  // Filter
  storeSearch(event: Event): void {
    MisEventUtils.stopEvent(event);

    const savedSearch = new SavedSearch('', this.filterQuery);
    const dialogRef = this.dialog.open(SearchNameModalComponent, {
      data: savedSearch
    });

    dialogRef.afterClosed().subscribe((filterName) => {
      if (!filterName) {
        return;
      }

      savedSearch.name = filterName;
      this.savedSearchService.saveSearch(savedSearch).subscribe(() => {
        this.snackBar.success('Ihr Suchauftrag wurde erfolgreich gespeichert.');
        this.loadSavedSearches();

      }, () => {
        this.snackBar.error('Ihr Suchauftrag konnte nicht gespeichert werden.');
      });
    });
  }

  openSavedSearchModal(event: Event): void {
    MisEventUtils.stopEvent(event);

    const dialogRef = this.dialog.open(SavedSearchesModalComponent);
    dialogRef.afterClosed().subscribe((savedSearch: SavedSearch) => {
      if (savedSearch) {
        this.openSearch(null, savedSearch);
      }

      this.loadSavedSearches();
    });
  }

  openSearch(event: Event, savedSearch: SavedSearch): void {
    MisEventUtils.stopEvent(event);
    this.applySearch.emit(savedSearch);
  }

  shareFilter(event: Event): void {
    MisEventUtils.stopEvent(event);
    const urlToShare = document.location.href + '?filterQuery=' + encodeURIComponent(JSON.stringify(this.filterQuery));

    this.dialog.open(SearchShareModalComponent, {
      data: urlToShare
    });
  }

  toggleNavigation(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.navigationOpened = !this.navigationOpened;
    this.changedOpenState.emit(this.navigationOpened);

    if (this.navigationOpened) {
      this.guidedTourService.startTour(GuidedTourType.IMMO_SUB_MENU);
    }
  }

  openOffererSettingsModal(event: Event): void {
    MisEventUtils.stopEvent(event);

    const dialogRef = this.dialog.open(OffererSettingsModalComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.refreshFiltering.emit();
    });
  }
}
