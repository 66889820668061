import {Component, OnDestroy} from '@angular/core';
import {MisEventUtils} from "../../../../services/common/event.util";
import {PrintService} from "../../../../viewservice/print.service";
import {PrintConfig} from "../../../basic/print-container/print.container.component";
import {
  MarketReportExportComponent,
  MarketReportExportComponentData
} from "./market-report-export/market.report.export.component";
import {MarketReportService} from "../../../../services/market-report/market.report.service";
import {ViewServiceChangeListener, ViewServiceInstance} from "../../../../viewservice/view.service";
import {MarketReportConfiguration} from "../../../../services/market-report/model/market.report.configuration.model";
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "../../../basic/snackbar/snackbar.service";
import {MarketReportConfigurationModalComponent} from "./market-report-configuration-modal/market-report-configuration-modal.component";
import {ConfirmationModalComponent} from "../../../basic/modal/confirmation-modal/confirmation.modal.component";

export class StaticMarketReport {
  constructor(public title: string,
              public ort: string,
              public filename: string) {
  }
}

export class SelectedMarketReport {
  constructor(public staticMarketReport: StaticMarketReport,
              public dynamicMarketReport: MarketReportConfiguration) {
  }
}

@Component({
  selector: 'mis-market-report-download-overview',
  templateUrl: './market-report-download-overview.component.html',
  styleUrls: [
    './market-report-download-overview.component.scss',
    '../../../../../assets/scss/_table.scss'
  ]
})
export class MarketReportDownloadOverviewComponent implements OnDestroy, ViewServiceChangeListener {

  staticReports: StaticMarketReport[] = [
  ];

  isLoading = false;
  downloadInProgress = false;
  marketReportConfigurations: MarketReportConfiguration[] = [];

  selectedMarketReport: SelectedMarketReport | undefined;
  maxHeightTableContainer: string = '500px';

  constructor(private printService: PrintService,
              private marketReportService: MarketReportService,
              private dialog: MatDialog,
              private snackBar: SnackbarService) {
    ViewServiceInstance.listenOnResize(this);
    this.loadMarketReports();
  }

  onResize(): void {
    this.maxHeightTableContainer = window.innerHeight - 150 + 'px';
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  exportMarketReport(event: Event, marketReportConfig: MarketReportConfiguration): void {
    MisEventUtils.stopEvent(event);
    this.downloadInProgress = true;

    this.marketReportService.loadMarketReportLayout().subscribe({
      next: (marketReportLayout) => {

        this.marketReportService.loadMarketReportData(marketReportConfig.id).subscribe({
          next: (marketReportData) => {

            // Open Print modal
            this.printService.print(
              new PrintConfig(
                MarketReportExportComponent,
                new MarketReportExportComponentData(marketReportLayout, marketReportData, marketReportConfig)
              ),
              'market-report-export'
            );

            setTimeout(() => {
              this.downloadInProgress = false;
            }, 2500);

          }, error: () => {
            this.snackBar.error('Der Download ist fehlgeschlagen. (2)');
            this.downloadInProgress = false;
          }
        });

      }, error: () => {
        this.snackBar.error('Der Download ist fehlgeschlagen. (1)');
        this.downloadInProgress = false;
      }
    });
  }

  selectStaticReportDetails(reportConfig: StaticMarketReport): void {
    this.selectedMarketReport = {
      staticMarketReport: reportConfig,
      dynamicMarketReport: undefined
    };
  }

  selectDynamicReportDetails(reportConfig: MarketReportConfiguration): void {
    this.selectedMarketReport = {
      staticMarketReport: undefined,
      dynamicMarketReport: reportConfig
    };
  }

  loadMarketReports(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.selectedMarketReport = undefined;

    this.marketReportService.loadMarketReportConfigurations().subscribe({
      next: (data) => {
        this.marketReportConfigurations = Object.values(data);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  openModalCreateNewConfig(): void {
    const dialogRef = this.dialog.open(MarketReportConfigurationModalComponent);
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.loadMarketReports();
      }
    });
  }

  deleteMarketReportConfiguration(event: Event, marketReportConfig: MarketReportConfiguration): void {
    MisEventUtils.stopEvent(event);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Marktberichtskonfiguration Löschen',
        message: 'Wollen Sie die ausgewählte Marktberichtskonfiguration und die dazugehörigen Marktberichte wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.marketReportService.deleteMarketReportConfiguration(marketReportConfig).subscribe(() => {
          this.snackBar.success('Die Marktberichtskonfiguration wurde erfolgreich gelöscht.');
          this.loadMarketReports();

        }, (err) => {
          this.snackBar.error('Beim Löschen der Marktberichtskonfiguration ist ein Fehler aufgetreten.');
        });
      }
    });
  }
}
