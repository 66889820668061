<div class="mis-modal-medium">
  <h2 mat-dialog-title>Kontodaten bearbeiten</h2>
  <mis-loading *ngIf="isLoading"></mis-loading>

  <form [formGroup]="formGroup"
        (submit)="saveAndClose()"
        *ngIf="!isLoading">
    <mat-dialog-content>
      <mat-form-field>
        <input matInput
               formControlName="companyName"
               placeholder="Firma"
               required="required">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="address"
               name="address"
               placeholder="Anschrift"
               required="required">
      </mat-form-field>

      <div class="mis-row mis-row-separated">
        <div class="mis-column-33">
          <mat-form-field>
            <input matInput
                   formControlName="zipCode"
                   name="zip"
                   placeholder="PLZ"
                   required="required">
          </mat-form-field>
        </div>
        <div class="mis-column-66">
          <mat-form-field>
            <input matInput
                   formControlName="city"
                   placeholder="Ort"
                   required="required">
          </mat-form-field>
        </div>
      </div>

      <mat-form-field>
        <mat-select formControlName="countryCode"
                    placeholder="Land"
                    [required]="true">
          <mat-option *ngFor="let country of countries"
                      [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="vatId"
               placeholder="Umsatzsteuer ID">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="web"
               placeholder="Internet-Adresse">
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="mis-button-pane">
        <button class="mis-button-pane-next"
                type="submit">Speichern</button>
        <button class="mis-button-pane-back"
                type="button"
                (click)="closeModal()">Abbrechen</button>
      </div>
    </mat-dialog-actions>

  </form>
</div>
