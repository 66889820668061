<a *ngIf="!isLoading"
   href="#"
   class="mis-menu-bar-button {{disabled ? 'disabled' : ''}}"
   (click)="clickedAction($event)">
  <i class="{{icon}}"></i><span>{{label}}</span>
</a>

<span class="mis-menu-bar-button-loading" *ngIf="isLoading">
  <mis-loading size="small"></mis-loading>
</span>
