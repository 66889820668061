import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MisEventUtils} from '../../../../../services/common/event.util';
import {FromUntilEvent} from '../../../../basic/form/form-fields-from-until/form.fields.from.until.component';
import {PriceAtlasFieldType} from '../../price.atlas.configuration';

export class PriceAtlasMapLegendItem {
  constructor(public color: string,
              public minValue: number,
              public maxValue: number,
              public prefix: string,
              public unit: string) {
  }
}

export class PriceAtlasMapLegendChangedBound {
  constructor(public lowerBound: number,
              public upperBound: number) {
  }
}

@Component({
  selector:    'mis-price-atlas-map-legend',
  templateUrl: './price.atlas.map.legend.component.html',
  styleUrls:   ['./price.atlas.map.legend.component.scss']
})
export class PriceAtlasMapLegendComponent implements OnChanges {
  @Input() legendItems: PriceAtlasMapLegendItem[] = [];
  @Input() analyticLayerType: PriceAtlasFieldType;
  @Output() updateLegendBounds = new EventEmitter<PriceAtlasMapLegendChangedBound>();
  @Output() resetLegendBounds = new EventEmitter<void>();

  roundDigits: number = 0;
  showConfigureLegendForm: boolean;

  customUpperBound: number;
  customLowerBound: number;
  newBounds: PriceAtlasMapLegendChangedBound;

  ngOnChanges(changes: SimpleChanges): void {
    this.setupBounds();
    this.updateDigits();
  }

  private setupBounds(): void {
    if (this.legendItems) {
      this.customLowerBound = this.legendItems[0].maxValue;
      this.customUpperBound = this.legendItems[this.legendItems.length - 1].minValue;
      this.newBounds = new PriceAtlasMapLegendChangedBound(this.customLowerBound, this.customUpperBound);
    }
  }

  private updateDigits(): void {
    if (!this.legendItems) {
      return;
    }

    this.roundDigits = 0;

    for (let item of this.legendItems) {
      const nachKommaZahl = item.minValue - Math.floor(item.minValue);
      if (nachKommaZahl > 0) {
        if (this.analyticLayerType === 'preis' || this.analyticLayerType === 'preisJeQm') {
          this.roundDigits = 2;
        } else {
          this.roundDigits = 1;
        }
        return;
      }
    }
  }

  configureLegend(event: MouseEvent): void {
    MisEventUtils.stopEvent(event);
    this.showConfigureLegendForm = true;
  }

  changedBounds(fromUntil: FromUntilEvent) {
    this.newBounds = new PriceAtlasMapLegendChangedBound(fromUntil.minValue, fromUntil.maxValue);
  }

  updateConfiguration(): void {
    this.showConfigureLegendForm = false;
    this.updateLegendBounds.emit(this.newBounds);
  }

  closeConfiguration(): void {
    this.showConfigureLegendForm = false;
  }

  triggerResetLegendBounds(event: MouseEvent) {
    MisEventUtils.stopEvent(event);
    this.resetLegendBounds.emit();
  }
}
