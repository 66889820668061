import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginHomeComponent} from './components/public/authentication/login.home.component';
import {AuthenticationGuard} from './services/aaa/authentication.guard';
import {ResearchComponent} from './components/secure/research/research.component';
import {WatchlistComponent} from './components/secure/watchlist/watchlist.component';
import {AccountManagementOverviewComponent} from './components/secure/account-management-overview/account.management.overview.component';
import {PlainBackendComponent} from './components/secure/plain-backend/plain.backend.component';
import {SelfserviceComponent} from './components/secure/account-management-overview/selfservice/selfservice.component';
import {CiCdComponent} from './components/secure/ci-cd/ci.cd.component';
import {ChallengesComponent} from './components/public/authentication/challenges/challenges.component';
import {SelfserviceVerificationComponent} from './components/secure/account-management-overview/selfservice-verification/selfservice.verification.component';
import {ImmoManagementComponent} from './components/secure/immo-management/immo.management.component';
import {HelpComponent} from './components/secure/help/help.component';
import {PriceAtlasComponent} from './components/secure/price-atlas/price.atlas.component';
import {MarketReportOverviewComponent} from "./components/secure/market-report-overview/market-report-overview.component";
import {MisTagUtils} from "./services/aaa/model/account.model";

export const routes: Routes = [
  {
    path:       '',
    redirectTo: '/research',
    pathMatch:  'full'
  },
  {
    path:      'login',
    component: LoginHomeComponent,
  },
  {
    path:      'challenges',
    component: ChallengesComponent,
  },
  {
    path:      'password-forgotten',
    component: LoginHomeComponent,
  },
  {
    path:      'registration',
    component: LoginHomeComponent,
  },
  {
    path:        'research',
    component:   ResearchComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      label: 'Marktrecherche',
      icon:  'fas fa-search-dollar'
    }
  },
  {
    path:        'research/:filtername',
    component:   ResearchComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path:        'watchlist',
    component:   WatchlistComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      label: 'Merkliste',
      icon:  'fas fa-star'
    }
  },
  {
    path:        'immo-management',
    component:   ImmoManagementComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      label: 'Meine Objekte',
      icon:  'fas fa-house-user'
    }
  },
  {
    path:        'price-atlas',
    component:   PriceAtlasComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      label: 'Preisatlas',
      icon:  'fas fa-globe-americas'
    }
  },
  {
    path:        'market-report',
    component:   MarketReportOverviewComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      label: 'Marktberichte',
      icon:  'fas fa-chart-pie',
      requiredPermissionTag: MisTagUtils.keys.permissionMarketReports,
      requiredSubscription: 'ADVANCED'
    }
  },
  {
    path:        'market-report-configuration',
    component:   MarketReportOverviewComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      requiredSubscription: 'ADVANCED'
    }
  },
  {
    path:        'account',
    component:   AccountManagementOverviewComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      requiredRole: 'ACCOUNT_ADMIN'
    }
  },
  {
    path:        'usermanagement',
    component:   AccountManagementOverviewComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      requiredRole: 'ACCOUNT_ADMIN'
    }
  },
  {
    path:        'tariffmanagement',
    component:   AccountManagementOverviewComponent,
    canActivate: [AuthenticationGuard],
    data:        {
      requiredRole: 'ACCOUNT_ADMIN'
    }
  },
  {
    path:        'selfservice',
    component:   SelfserviceComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path:        'selfservice/verify/:code',
    component:   SelfserviceVerificationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path:        'help',
    component:   HelpComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path:      'plain-backend',
    component: PlainBackendComponent
  },
  {
    path:      'cicd',
    component: CiCdComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
