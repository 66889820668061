import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../../viewservice/view.service';
import {MapService} from '../../../../services/map/map.service';
import {MapChangedEvent, MapComponent, MapImageOverlay, MapPolygon, MapPolygonOptions} from '../../../common/map/map.component';
import {ZIP_CODE_LOCATIONS} from '../../../../services/map/zip-code-filter-list';
import {Preisatlas, PreisatlasMeta, StatisticMeta} from '../../../../services/price-altas/model/price.atlas.model';
import {GuidedTourStep} from '../../../common/guided-tour/guided.tour.service';
import {PriceAtlasMapLegendChangedBound, PriceAtlasMapLegendItem} from './price-atlas-map-legend/price.atlas.map.legend.component';
import * as _ from 'lodash';
import Timeout = NodeJS.Timeout;
import {PriceAtlasFieldType} from '../price.atlas.configuration';

export const PRICE_ATLAS_GREEN_RED_COLORS = [
  '#55DD00',
//  '#88EE00',
  '#AAFF00',
//  '#CCFF00',
  '#EEFF00',
//  '#FFEE00',
  '#FFDD00',
//  '#FFBB00',
  '#FF8800',
  '#FF0000'];

export const PRICE_ATLAS_BLUE_COLORS = [
  '#e6f5fc',
  //'#ccecf9',
  '#b3e2f7',
  //'#99d8f4',
  '#66c5ee',
  //'#33b1e9',
  '#009EE3',
  //'#008ecc',
  '#007eb6',
  '#005f88'];

export class PriceAtlasMapChangedMap {
  constructor(public visibleZipCodes: string[],
              public position: google.maps.LatLng) {
  }
}

@Component({
  selector:    'mis-price-atlas-map',
  templateUrl: './price.atlas.map.component.html',
  styleUrls:   ['./price.atlas.map.component.scss']
})
export class PriceAtlasMapComponent implements ViewServiceChangeListener, OnDestroy {
  @Input() selectedZipCode: string;
  @Input() availableAnalyticsLayerTypes: PriceAtlasFieldType[];
  @Input() mapCenter: google.maps.LatLngLiteral;

  @Output() changeAnalyticsLayerType = new EventEmitter<PriceAtlasFieldType>();
  @Output() changedVisibleZipCodes = new EventEmitter<PriceAtlasMapChangedMap>();
  @Output() changedSelectedZipCode = new EventEmitter<string>();
  @Output() hoveredZipCode = new EventEmitter<string>();
  @Output() mapMouseout = new EventEmitter<void>();

  @ViewChild('mapComponent', {static: true}) mapComponent: MapComponent;

  contentHeight: number = 1000;
  isLoading: boolean = true;
  initialized: boolean;
  guidedTourStep = GuidedTourStep;

  analyticLayerType: PriceAtlasFieldType;
  level1ImageOverlay: MapImageOverlay;
  polygons: MapPolygon[] = [];
  visibleZipCodes: string[] = [];
  preisAtlasByZipCodes: { [zipCode: string]: Preisatlas };
  preisAtlasMeta: PreisatlasMeta;
  updateDelayTimeout: Timeout;
  priceAtlasMapLegendItems: PriceAtlasMapLegendItem[];
  customStatisticMeta: { [key: string]: StatisticMeta } = {};

  toolTipPositionX: string;
  toolTipPositionY: string;
  toolTipTimeOut: Timeout;
  actualHoveredPolygon: MapPolygon;

  constructor(private mapService: MapService) {
    ViewServiceInstance.listenOnResize(this);
  }

  updatePreisatlas(preisAtlasByZipCodes: { [zipCode: string]: Preisatlas }, preisAtlasMeta: PreisatlasMeta): void {
    this.preisAtlasByZipCodes = preisAtlasByZipCodes;
    this.preisAtlasMeta = preisAtlasMeta;
  }

  updateAnalyticLayerType(analyticLayerType: PriceAtlasFieldType): void {
    this.analyticLayerType = analyticLayerType;

    this.buildLegend();
    this.drawPreisAtlas();
  }

  ngOnDestroy(): void {
    ViewServiceInstance.stopListening(this);
  }

  onResize(): void {
    this.contentHeight = window.innerHeight - 1;
  }

  updateMapContent(zoomEvent: MapChangedEvent): void {
    if (this.updateDelayTimeout) {
      clearTimeout(this.updateDelayTimeout);
    }

    if (zoomEvent.zoomLevel < 10) {
      this.polygons = [];
      return;
    }

    this.updateDelayTimeout = setTimeout(() => {
      const newZipCodes = this.getZipCodesByBounds(zoomEvent.bounds);
      if (this.hasVisibleZipCodesChanged(newZipCodes)) {
        this.visibleZipCodes = newZipCodes;
        this.changedVisibleZipCodes.emit(new PriceAtlasMapChangedMap(this.visibleZipCodes, zoomEvent.bounds.getCenter()));
      }

      clearTimeout(this.updateDelayTimeout);
    }, 550);
  }

  private drawPreisAtlas(): void {
    if (!this.preisAtlasByZipCodes) {
      return;
    }

    this.polygons = [];
    Object.keys(this.preisAtlasByZipCodes).forEach(zipCode => {
      const preisAtlas = this.preisAtlasByZipCodes[zipCode];

      this.mapService.getPolygonData(zipCode).subscribe((zipCodeMapPlaceMark) => {
        zipCodeMapPlaceMark.polygons.forEach((poly) => {
          const mapPoly = poly as MapPolygon;
          mapPoly.id = zipCodeMapPlaceMark.zipCode;
          mapPoly.options = new MapPolygonOptions();
          mapPoly.options.fillColor = this.calculateColor(preisAtlas);
          mapPoly.options.fillOpacity = 0.7;
          mapPoly.options.strokeWeight = 0;
          mapPoly.options.strokeColor = '#777';
          mapPoly.options.strokeWeight = 0.25;

          if (zipCode === this.selectedZipCode) {
            mapPoly.options.strokeWeight = 1.5;
            mapPoly.options.strokeColor = '#000';
          }

          this.polygons.push(mapPoly);
        });

      }, (error) => {
      });
    });
  }

  private buildLegend(): void {

    let meta;
    if (this.customStatisticMeta.hasOwnProperty(this.analyticLayerType)) {
      meta = this.customStatisticMeta[this.analyticLayerType];

    } else if (this.analyticLayerType === 'alter') {
      meta = new StatisticMeta();
      meta.lowerBound = 10;
      meta.upperBound = 100;
      meta.unit = 'Tage';

    } else {
      meta = this.preisAtlasMeta[this.analyticLayerType];
    }

    this.priceAtlasMapLegendItems = this.buildLegendItems(
      meta.lowerBound,
      meta.upperBound,
      meta.unit
    );
  }

  private buildLegendItems(minValue: number, maxValue: number, unit: string): PriceAtlasMapLegendItem[] {
    const colors = this.analyticLayerType === 'flaeche' ? PRICE_ATLAS_BLUE_COLORS : PRICE_ATLAS_GREEN_RED_COLORS;
    const numberOfSteps = colors.length;
    const stepDiff = this.calculateStepValue((maxValue - minValue), numberOfSteps);

    const preparedMinValue = Math.round((minValue / stepDiff)) * stepDiff;
    const preparedMaxValue = (stepDiff * (numberOfSteps - 1)) + preparedMinValue;
    const legendItems: PriceAtlasMapLegendItem[] = [];

    for (let i = 0; i < numberOfSteps; i++) {
      let min = null;
      let max = null;
      let prefix = null;

      if (i === 0) {
        min = null;
        max = preparedMaxValue - (stepDiff * ((numberOfSteps - 2) - i));
        prefix = '<';
      } else if (i === (numberOfSteps - 1)) {
        min = preparedMaxValue;
        max = null;
        prefix = '>';

      } else {
        min = preparedMaxValue - (stepDiff * (numberOfSteps - 1 - i));
        max = preparedMaxValue - (stepDiff * (numberOfSteps - 2 - i));
      }

      legendItems.push(new PriceAtlasMapLegendItem(colors[i], min, max, prefix, unit));
    }

    return legendItems;
  }

  private calculateStepValue(number: number, numberOfSteps: number): number {
    let valueToRound = number / numberOfSteps;

    const berechneZahlenKleiner10 = (zahl: number): number => {
      const vorKommaZahl = Math.floor(zahl);
      const nachKommaZahl = Math.round(zahl * 10) - (vorKommaZahl * 10);

      return vorKommaZahl + (Math.round(nachKommaZahl / 5) * 5) / 10;
    };

    if (number >= 1) {
      // Verarbeite Zahlen > 1
      if (number < 10) {
        return berechneZahlenKleiner10(valueToRound);
      }

      let multiplicator = 1;
      while (valueToRound > 10) {
        valueToRound /= 10;
        multiplicator *= 10;
      }

      const preparedStepValue = berechneZahlenKleiner10(valueToRound);
      return preparedStepValue * multiplicator;

    } else {
      // Verarbeite Zahlen < 1
      let divident = 1;
      while (valueToRound < 1) {
        valueToRound *= 10;
        divident *= 10;
      }

      if (valueToRound < 2) {
        return 1 / divident;
      } else if (valueToRound < 4) {
        return 2.5 / divident;
      } else if (valueToRound < 6) {
        return 5 / divident;
      } else if (valueToRound < 8) {
        return 7.5 / divident;
      } else {
        return 10 / divident;
      }
    }
  }

  private calculateColor(preisAtlas: Preisatlas): string {
    let itemMedian: number;
    if (this.analyticLayerType === 'einwohner' || this.analyticLayerType === 'anzahl' || this.analyticLayerType === 'anzahlJeEinwohner') {
      itemMedian = preisAtlas[this.analyticLayerType];
    } else {
      itemMedian = preisAtlas[this.analyticLayerType].median;
    }

    const legendItem = this.priceAtlasMapLegendItems.filter((legendItem) => {
      if (legendItem.minValue === null && legendItem.maxValue >= itemMedian) {
        return true;

      } else if (legendItem.minValue <= itemMedian && legendItem.maxValue > itemMedian) {
        return true;

      } else if (legendItem.maxValue === null && legendItem.minValue <= itemMedian) {
        return true;
      }
      return false;
    })[0];

    if (!legendItem) {
      return '#00000011';
    }
    return legendItem.color;
  }

  private hasVisibleZipCodesChanged(zipCodes: string[]): boolean {
    if (!this.visibleZipCodes || this.visibleZipCodes.length !== zipCodes.length) {
      return true;
    }

    return this.visibleZipCodes.filter((zipCode) => !_.includes(zipCodes, zipCode)).length > 0;
  }

  private getZipCodesByBounds(bounds: google.maps.LatLngBounds): string[] {
    const outerDistance = 0.005;

    const result = ZIP_CODE_LOCATIONS.filter((zipData) => {
      const isNorthInside = zipData.north > (bounds.getSouthWest().lat() + outerDistance);
      const isSouthInside = zipData.south < (bounds.getNorthEast().lat() - outerDistance);
      const isEastInside = zipData.east > (bounds.getSouthWest().lng() + outerDistance);
      const isWestInside = zipData.west < (bounds.getNorthEast().lng() - outerDistance);

      return isNorthInside && isEastInside && isWestInside && isSouthInside;
    }).map((zipData) => {
      return zipData.zipCode;
    });

    return result;
  }

  clickedPolygon(clickedPolygon: MapPolygon) {
    this.selectedZipCode = this.selectedZipCode === clickedPolygon.id ? null : clickedPolygon.id;
    this.changedSelectedZipCode.emit(this.selectedZipCode);
    this.drawPreisAtlas();
  }

  hoveredPolygon(hoveredPolygon: MapPolygon) {
    this.hoveredZipCode.emit(hoveredPolygon.id);
    this.actualHoveredPolygon = hoveredPolygon;
  }

  emitMapMouseout(): void {
    this.mapMouseout.emit();
    this.actualHoveredPolygon = null;
  }

  updateCustomStatisticsMeta(bounds: PriceAtlasMapLegendChangedBound): void {

    const v = this.calculateStepValue(bounds.upperBound - bounds.lowerBound, PRICE_ATLAS_GREEN_RED_COLORS.length);
    const newStatisticsMeta = new StatisticMeta();
    newStatisticsMeta.lowerBound = bounds.lowerBound - v;
    newStatisticsMeta.upperBound = bounds.upperBound + v;
    newStatisticsMeta.unit = this.preisAtlasMeta[this.analyticLayerType].unit;

    this.customStatisticMeta[this.analyticLayerType] = newStatisticsMeta;

    this.updateAnalyticLayerType(this.analyticLayerType);
  }

  resetCustomStatisticsMeta() {
    delete this.customStatisticMeta[this.analyticLayerType];
    this.updateAnalyticLayerType(this.analyticLayerType);
  }

  updateMouseLocation(event: MouseEvent): void {
    if (this.toolTipTimeOut) {
      clearTimeout(this.toolTipTimeOut);
    }

    this.toolTipTimeOut = setTimeout(() => {
      this.toolTipPositionX = (window.innerWidth - event.pageX ) + 'px';
      this.toolTipPositionY = (event.pageY - 50) + 'px';
    }, 200);
  }
}
