<div class="price-atlas-map-legend">

  <div *ngIf="!showConfigureLegendForm">
    <h3>
      Legende
      <a href="#"
         title="Legende konfigurieren"
         (click)="configureLegend($event)">
        <i class="fas fa-cogs"></i>
      </a>

      <a href="#"
         title="Legendenkonfiguration zurücksetzen"
         (click)="triggerResetLegendBounds($event)">
        <i class="fas fa-undo-alt"></i>
      </a>
    </h3>

    <div *ngFor="let legendItem of legendItems"
         class="price-atlas-map-legend-item">

      <span class="price-atlas-map-legend-item-color"
            [style.background]="legendItem.color">
      </span>

      <span *ngIf="legendItem.prefix">{{legendItem.prefix}}</span>
      <span *ngIf="legendItem.minValue">{{legendItem.minValue | misNumberFormat:roundDigits}}</span>
      <span *ngIf="legendItem.minValue && legendItem.maxValue">-</span>
      <span *ngIf="legendItem.maxValue">{{legendItem.maxValue | misNumberFormat:roundDigits}}</span>
      <span>{{legendItem.unit}}</span>
    </div>
  </div>


  <div *ngIf="showConfigureLegendForm">
    <h3>
      Legende konfigurieren
    </h3>

    <div>
      <mis-form-field-from-until title="Eigene Legende"
                                 placeHolderFrom="von"
                                 placeHolderUntil="bis"
                                 controlNamePrefix="value"
                                 [defaultFrom]="customLowerBound"
                                 [defaultUntil]="customUpperBound"
                                 [steps]="1"
                                 [decimalDigits]="0.5"
                                 [buffer]="-1"
                                 [useThousandSeparator]="true"
                                 (changedValues)="changedBounds($event)"></mis-form-field-from-until>

      <div class="mis-button-pane">
        <button (click)="closeConfiguration()">
          Abbrechen
        </button>
        <button (click)="updateConfiguration()">
          Speichern
        </button>
      </div>
    </div>
  </div>

</div>
