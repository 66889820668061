import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractFormComponent} from '../../../../basic/form/abstract.form.component';
import {FormBuilder, FormControl} from '@angular/forms';
import {PriceAtlasFields, PriceAtlasFieldType} from '../../price.atlas.configuration';
import * as _ from 'lodash';

@Component({
  selector:    'mis-price-atlas-details-configuration-modal',
  templateUrl: './price-atlas-details-configuration-modal.component.html',
})
export class PriceAtlasDetailsConfigurationModalComponent extends AbstractFormComponent {
  priceAtlasFields = PriceAtlasFields;

  constructor(public dialogRef: MatDialogRef<PriceAtlasDetailsConfigurationModalComponent>,
              @Inject(MAT_DIALOG_DATA) private visiblePriceAtlasFields: PriceAtlasFieldType[],
              formBuilder: FormBuilder) {
    super();

    this.formGroup = formBuilder.group({});
    this.priceAtlasFields.forEach(field => {
      const isVisible = _.includes(this.visiblePriceAtlasFields, field.type);
      this.formGroup.addControl(field.type, new FormControl(isVisible));
    });
  }

  customSaveAction(): void {
    const fieldKeys = Object.keys(this.formGroup.controls);

    const visibleFields: PriceAtlasFieldType[] = [];
    fieldKeys.forEach((fieldKey) => {
      if (this.formGroup.controls[fieldKey].value === true) {
        visibleFields.push(fieldKey as PriceAtlasFieldType);
      }
    });
    this.dialogRef.close(visibleFields);
  }
}
