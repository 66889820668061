import {Pipe, PipeTransform} from '@angular/core';
import {MisSalutation} from '../services/aaa/model/account.model';

/**
 * Custom pipe to translate salutation keys to words.
 */
@Pipe({name: 'misTranslateSalutation'})
export class TranslateSaluationPipe implements PipeTransform {

  transform(value: MisSalutation): string {
    switch (value) {
      case 'MR': return 'Herr';
      case 'MRS': return 'Frau';
      default: return '';
    }
  }
}
