import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import * as _ from 'lodash';
import {MisEventUtils} from '../../../../services/common/event.util';

export class FormFieldsInputLoadAutocompleteValue {
  constructor(public value: string, public additional: string = null) {
  }
}

export class FormFieldsInputLoadAutocompleteValues {
  constructor(public subscriber: Subscriber<{ [key: string]: FormFieldsInputLoadAutocompleteValue }>, public searchString: string) {
  }
}

@Component({
  selector:    'mis-form-field-input',
  templateUrl: './form.fields.input.component.html',
  styleUrls:   ['./form.fields.input.component.scss']
})
export class FormFieldsInputComponent {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() isAutoComplete: boolean;
  @Output() loadAutoCompleteValues = new EventEmitter<FormFieldsInputLoadAutocompleteValues>();
  @Output() changedValue = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  autoCompleteValues: { [key: string]: FormFieldsInputLoadAutocompleteValue } = {};
  isDroppedDown: boolean;
  isLoadingAutoComplete: boolean;
  objectKeys = Object.keys;
  searchString = '';

  focusedIndex = -1;

  changed(event) {
    const newValue = event.target.value;

    if (this.isAutoComplete) {
      return;
    }

    if (newValue !== this.value) {
      this.changedValue.emit(newValue);
    }
  }

  private getAutocompleteValues(searchString: string): Observable<{ [key: string]: FormFieldsInputLoadAutocompleteValue }> {
    return new Observable<{ [key: string]: FormFieldsInputLoadAutocompleteValue }>((subscriber) => {
      this.loadAutoCompleteValues.emit(new FormFieldsInputLoadAutocompleteValues(subscriber, searchString));
    });
  }

  processKeyboardNavigation(event: KeyboardEvent) {

    if (!this.isAutoComplete) {
      return;
    }

    if (event.key === 'Escape') {
      this.closeDropDown();
      return;
    }

    if (event.key === 'Tab') {
      this.closeDropDown();
      return;
    }

    this.searchString = this.isAutoComplete ? this.input.nativeElement.value : null;
    if (event.key === 'Enter' || (this.focusedIndex >= 0 && event.key === ' ')) {

      if (this.focusedIndex >= 0) {
        // focus selection
        const focusedKey = Object.keys(this.autoCompleteValues)[this.focusedIndex];
        if (focusedKey) {
          this.selectValueKey(null, focusedKey);
          return;
        }
      }

      if (this.searchString && this.searchString.trim() !== '') {
        const valueKeys = Object.keys(this.autoCompleteValues).filter(valueKey => this.autoCompleteValues[valueKey].value.toLowerCase() === this.searchString.toLowerCase());
        if (valueKeys.length > 0) {
          // first auto completed value
          this.selectValueKey(null, valueKeys[0]);
        }
      }

      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      const numberOfItems = !this.autoCompleteValues ? 0 : Object.keys(this.autoCompleteValues).length;
      this.focusedIndex = ((this.focusedIndex + 1) < numberOfItems) ? this.focusedIndex + 1 : numberOfItems - 1;
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusedIndex = ((this.focusedIndex - 1) > 0) ? this.focusedIndex - 1 : 0;
      return;
    }

    if ((event.key === 'ArrowRight' || event.key === 'ArrowLeft') && !this.isAutoComplete) {
      event.preventDefault();
      return;
    }
  }

  triggerLoadAutoCompleteValues(event: KeyboardEvent): void {
    if (!this.isAutoComplete) {
      return;
    }

    const keysToIgnore = [
      'Escape',
      'Tab',
      'Enter',
      'ArrowDown',
      'ArrowUp',
      'ArrowRight',
      'ArrowLeft',
      'Shift',
      'Control',
      'Capslock'];

    if (_.includes(keysToIgnore, event.key)) {
      return;
    }

    this.searchString = this.isAutoComplete ? this.input.nativeElement.value : null;

    this.isLoadingAutoComplete = true;
    this.isDroppedDown = true;
    this.autoCompleteValues = {};

    this.getAutocompleteValues(this.searchString).subscribe((values) => {
      this.autoCompleteValues = {};
      this.focusedIndex = -1;

      if (values) {
        Object.keys(values).forEach(valueKey => {
          this.autoCompleteValues[valueKey] = values[valueKey];
        });
      }

      this.isDroppedDown = Object.keys(values).length > 0;
      this.changedValue.emit(null);
      this.isLoadingAutoComplete = false;

    }, (error) => {
      this.autoCompleteValues = {};
      this.closeDropDown();
      this.isLoadingAutoComplete = false;
    });
  }

  closeDropDown() {
    this.isDroppedDown = false;
  }

  selectValueKey(event: Event, valueKey: string): void {
    MisEventUtils.stopEvent(event);

    this.value = valueKey === null ? null : this.autoCompleteValues[valueKey].value;
    this.changedValue.emit(valueKey);
    this.closeDropDown();
  }
}
