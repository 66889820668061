<div class="mis-higher-tariff-required-hint {{backgroundClass}} {{size | lowercase}}">
  <div class="mis-hint-icon">
    <i class="fas fa-lock"></i>
<!--    <i class="fas fa-comments-dollar"></i>-->
  </div>

  <div class="mis-hint-text">
    <h3><ng-content></ng-content></h3>
    <p>
      Die Freischaltung der Funktion können Sie in der <a routerLink="/tariffmanagement">Tarifverwaltung</a>
      durchführen.
    </p>
  </div>
</div>
