<div class="mis-header-navigation-item">
  <a [routerLink]="routerLinkTarget"
     routerLinkActive="active"
     class="{{active ? 'mis-link-active' : ''}}"
     (click)="triggerVisitEvent()"
     [title]="label">
    <i class="{{icon}}"></i>

    <span *ngIf="count"
          class="mis-header-navigation-item-count">{{count}}</span>
    <span>{{label}}</span>
  </a>
</div>
