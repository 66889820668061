import {HttpClient} from '@angular/common/http';
import {AbstractService} from '../abstract.service';
import {QueryField, QuerySpec, SortField} from './model/research.filter.model';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {TableServiceInterface} from './table.service.interface';
import * as _ from 'lodash';
import {ResearchItem} from './model/research.item.model';
import {Page} from './model/page.model';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../local.storage.service";
import {MisTagUtils} from "../aaa/model/account.model";

export abstract class AbstractTableService extends AbstractService implements TableServiceInterface {

  protected constructor(httpClient: HttpClient,
                        authenticationProvider: AuthenticationProvider,
                        localStorageService: LocalStorageService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  protected buildFilterQueryString(filter: QuerySpec): string {
    const preparedFilter = _.cloneDeep(filter);

    preparedFilter.root.combineList = preparedFilter.root.combineList.filter((queryField) => {
      const isEmptyListAndEmptyStringValue = queryField.sValueList && queryField.sValueList.length === 0 && queryField.sValue === null;
      return !isEmptyListAndEmptyStringValue;
    });

    const restrictedZipCodesTag = MisTagUtils.getTagByKey(this.authenticationProvider.getUser().account.tags, MisTagUtils.keys.filterRestrictedZipCodes);

    if (restrictedZipCodesTag) {
      const restrictedZipCodesField = new QueryField();
      restrictedZipCodesField.field = 'postleitzahl';
      restrictedZipCodesField.comparator = 'starts';
      restrictedZipCodesField.combineListElementsWith = 'or';
      restrictedZipCodesField.sValueList = (restrictedZipCodesTag.value as string).split(',');
      preparedFilter.root.combineList.push(restrictedZipCodesField)
    }

    return JSON.stringify(preparedFilter);
  }

  abstract getList(filter: QuerySpec, sort: SortField): Observable<Page<ResearchItem>>;
}
