<header *ngIf="!showNavigation"
        class="mis-header-simple">
  <a class="mis-logo"
     routerLink="/research"></a>
</header>

<header *ngIf="showNavigation"
        [ngStyle]="{'height': contentHeight}"
        [ngClass]="{'mis-header-detailed': showHeaderDetails}"
        class="mis-header-left">

  <a class="mis-logo"
     routerLink="/research"></a>

  <mis-header-navigation></mis-header-navigation>

  <div *ngIf="viewServiceInstance.isDesktop()"
       class="mis-show-more"
       [joyrideStep]="guidedTourStep.HEADER_SHOW_DETAILED"
       title="Detailiertes Hauptmenü"
       text="Klicken Sie hier, um das Hauptmenü mit den Menütiteln sehen zu können.">
    <a href="#"
       (click)="toggleHeaderDetails($event)">
      <i class="fas fa-angle-double-right"></i>
      <span>Menü zuklappen</span>
    </a>
  </div>

  <div *ngIf="viewServiceInstance.isDesktop()"
       class="mis-help"
       [joyrideStep]="guidedTourStep.HEADER_SHOW_HELP"
       title="Hilfe notwendig?"
       text="Falls Sie mal nicht weiter wissen, finden Sie hier Unterstützung.">
    <mis-header-help-item [showDetailed]="showHeaderDetails"></mis-header-help-item>
  </div>

  <div class="mis-profile"
       [joyrideStep]="guidedTourStep.HEADER_PROFILE"
       title="Kontoverwaltung und Co."
       text="Unter diesem Menüeintrag versteckt sich ein Kontextmenü über welches Sie Ihre Daten einsehen können. Weiterhin haben Sie die Möglichkeit sich abzumelden.">
    <mis-header-profile-item [showDetailed]="showHeaderDetails"></mis-header-profile-item>
  </div>
</header>
