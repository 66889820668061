<div class="mis-print-page">
  <div class="mis-print-page-content">
    <h3>Glossar</h3>
    <table>
      <colgroup>
        <col style="width: 180px">
        <col style="width: auto">
      </colgroup>
      <tr>
        <td><strong>Kaufpreisfaktor</strong></td>
        <td>Der Kaufpreisfaktor ist eine wirtschaftliche Kennziffer, die Auskunft über
          die Höhe des Preises einer Immobilie gibt. Den Kaufpreisfaktor einer
          Immobilie errechnet man, indem man den Kaufpreis der Immobilie durch
          die Jahresnettomiete dividiert.
        </td>
      </tr>
      <tr>
        <td><strong>Median</strong></td>
        <td>Der Median ist ein gewichteter Mittelwert, welcher die zentrale Tendenz
          der Werte anzeigt. Er ist robust gegenüber extremen Werten am Anfang
          oder Ende einer Skala.
        </td>
      </tr>
      <tr>
        <td><strong>KdU-Tabelle</strong></td>
        <td>Verankert sind die Kosten der Unterkunft, welche durch das
          Arbeitslosengeld II übernommen werden.
        </td>
      </tr>
      <tr>
        <td><strong>Zi</strong></td>
        <td>Zimmer</td>
      </tr>
    </table>

    <h3>Rechtliche Abgrenzung</h3>
    <p>Sämtliche veröffentlichten Informationen, Daten, Graphiken und Formeln wurden mit größter Sorgfalt
      und nach bestem Wissen und Gewissen recherchiert und zusammengestellt. Vervielfältigungen
      urheberrechtlich geschützter Inhalte sind nur mit Zustimmung des BFW Landesverbandes
      Mitteldeutschland e.V., Turnerweg 6, 01097 Dresden und entsprechender Quellenangabe gestattet. Der
      Stand der Informationen lautet Dezember 2020. Die Standortkennzahlen haben folgenden Bezug
      www.propfox.de, www.mb-research.de. Die KdU-Tabellen sind sind von www.dresden.de entnommen.</p>

    <h3>Herausgeber</h3>
    <p>
      <img src="../../../../../../../assets/img/MIS_Logo_mit_Text_One_Line.svg"
           style="width: 100px"
           (load)="loaded.emit()"
           (error)="loaded.emit()">
      <br>
      <br>
      My Immo Scan UG<br>
      Hainstraße 5<br>
      01097 Dresden
    </p>
    <p>
      <strong>Kontakt:</strong><br>
      Telefon: +49 173 6565647<br>
      E-Mail: kontakt@propfox.io<br>
      Webseite: propfox.io
    </p>
  </div>
</div>
