<div class="mis-graphic-statistic">
  <img *ngIf="svgUrl"
       [src]="svgUrl">

  <div class="mis-graphic-statistic-legend">
    <p *ngFor="let legendItem of statistic?.legend">
          <span class="mis-graphic-statistic-legend-color"
                [style.backgroundColor]="legendItem.color"></span>
      <span class="mis-graphic-statistic-legend-text">
        <span *ngIf="legendItem.prefix">{{legendItem.prefix}} </span>

        <span *ngIf="legendItem.minValue">{{legendItem.minValue | misNumberFormat}}</span>
        <span *ngIf="legendItem.minValue && legendItem.maxValue"> - </span>
        <span *ngIf="legendItem.maxValue">{{legendItem.maxValue | misNumberFormat}}</span>

        {{legendItem.unit}}
      </span>
    </p>
  </div>
</div>
