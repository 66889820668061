import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationProvider} from '../aaa/authentication.provider';
import {LocalStorageService} from '../local.storage.service';
import {AbstractService} from '../abstract.service';
import {MarketReportLayout} from "./model/market.report.customization.model";
import {File} from "../../components/basic/form/form-fields-file-upload/file.model";
import {MarketReportData} from "./model/market.report.model";
import {Observable} from "rxjs";
import {AccountService} from "../aaa/account.service";
import {MisTag, MisTagUtils} from "../aaa/model/account.model";
import {MarketReportCity, MarketReportConfiguration} from "./model/market.report.configuration.model";

@Injectable({providedIn: 'root'})
export class MarketReportService extends AbstractService {

  constructor(httpClient: HttpClient,
              authenticationProvider: AuthenticationProvider,
              localStorageService: LocalStorageService,
              private accountService: AccountService) {
    super(httpClient, authenticationProvider, localStorageService);
  }

  loadMarketReportLayout(): Observable<MarketReportLayout> {
    return new Observable<MarketReportLayout>(subscriber => {

      // load account
      this.accountService.getAccount().subscribe({
        next: (account) => {

          // load market report layout from account tags
          const layoutTag = MisTagUtils.getTagByKey(account.tags, MisTagUtils.keys.marketReportLayout);
          let layout = undefined;

          if (layoutTag) {
            try {
              layout = JSON.parse(layoutTag.value);
            } catch (e) {
            }
          }

          // use default layout if layout does not exists or is not parsable
          if (!layout) {
            layout = this.getDefaultMarketReportLayout();
          }

          if (layout.logo) {
            layout.logo = layout.logo as File;
          }

          subscriber.next(layout);
          subscriber.complete();

        }, error: () => {
          subscriber.error();
        }
      });
    });
  }

  private getDefaultMarketReportLayout(): MarketReportLayout {
    return new MarketReportLayout(
      null,
      '',
      '#475860',
      '#009EE3',
      '#009EE3',
      '#009EE3',
      '#222',
      '#9EE300',
      '#009EE3',
      '#E3009E');
  }

  saveMarketReportLayoutConfiguration(reportLayout: MarketReportLayout): Observable<MarketReportLayout> {
    return new Observable<MarketReportLayout>(subscriber => {

      // load account
      this.accountService.getAccount().subscribe({
        next: (account) => {

          // find tag
          let layoutTag = MisTagUtils.getTagByKey(account.tags, MisTagUtils.keys.marketReportLayout);

          if (!layoutTag) {
            layoutTag = new MisTag();
            layoutTag.key = MisTagUtils.keys.marketReportLayout;
            layoutTag.type = "JSON";
            account.tags.push(layoutTag);
          }

          layoutTag.value = JSON.stringify(reportLayout);

          this.accountService.updateAccountTags(account.id, account.tags).subscribe({
            next: () => {
              subscriber.next(reportLayout);
            },
            error: () => {
              subscriber.error();
            },
            complete: () => {
              subscriber.complete();
            }
          });

        }, error: () => {
          subscriber.error();
        }
      });
    });
  }

  loadAvailableMarketReportCities(): Observable<{ [cityName: string]: MarketReportCity }> {
    return this.GET<{ [cityName: string]: MarketReportCity }>('https://api-beta.propfox.io/analytic/api/marktbericht/available-cities'); // TODO
  }

  loadMarketReportConfigurations(): Observable<MarketReportConfiguration[]> {
    return this.GET<MarketReportConfiguration[]>('https://api-beta.propfox.io/analytic/api/marktbericht/konfiguration'); // TODO BE
  }

  saveMarketReportConfiguration(marketReportConfiguration: MarketReportConfiguration): Observable<MarketReportConfiguration> {
    return this.POST<MarketReportConfiguration>('https://api-beta.propfox.io/analytic/api/marktbericht/konfiguration', marketReportConfiguration); // TODO BE
  }

  deleteMarketReportConfiguration(marketReportConfig: MarketReportConfiguration) {
    return this.DELETE('https://api-beta.propfox.io/analytic/api/marktbericht/konfiguration/' + marketReportConfig.id); // TODO BE
  }

  loadMarketReportData(marketReportConfigurationId: string): Observable<MarketReportData> {
    return this.GET<MarketReportData>('https://api-beta.propfox.io/analytic/api/marktbericht/' + marketReportConfigurationId); // TODO BE
  }
}
