import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FieldName, QueryField, QuerySpec} from '../../../../../services/research/model/research.filter.model';
import {FromUntilEvent} from '../../../../basic/form/form-fields-from-until/form.fields.from.until.component';
import {
  AbstractImmoTableFilter,
  ImmoTableEnumFilter,
  ImmoTableOffererFilter
} from '../../immo-table/immo-table-configuration/immo.table.classes';
import {MisEventUtils} from '../../../../../services/common/event.util';
import {ImmoOverviewViewType} from '../../immo.overview.component';

export class FilterListItemExtendedFilterItem {
  public filterName: string;
  public filterSettings: AbstractImmoTableFilter;
  public fieldName: string;
  public queryFields: QueryField[] = [];
}

@Component({
  selector:    'mis-filter-extended-items',
  templateUrl: './filter.extended.items.component.html',
  styleUrls:   ['./filter.extended.items.component.scss']
})
export class FilterExtendedItemsComponent implements OnChanges {
  @Input() filterQuery: QuerySpec;
  @Input() availableExtendedFilters: AbstractImmoTableFilter[];
  @Input() extendedFilterQueryFields: QueryField[];
  @Input() showOnlyActiveProperties: boolean;
  @Input() showOnlyActivePropertiesFilterDisabled = true;
  @Input() viewType: ImmoOverviewViewType;

  @Output() changedShowOnlyActivePropertiesFilter = new EventEmitter<boolean>();
  @Output() changedFilter = new EventEmitter<QueryField[]>();
  @Output() changedFilterPaneHeight = new EventEmitter<void>();

  extendedFilters: FilterListItemExtendedFilterItem[] = [];
  showMoreFilterDropDown: boolean;
  availableFilters: AbstractImmoTableFilter[] = [];

  isDirty: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.initComponent();
  }

  private initComponent() {
    if (this.filterQuery != null) {
      this.extendedFilters = [];
      this.prepare();
    }
  }

  private prepare(): void {
    this.convertExtendedFilters();
    this.prepareAvailableFilters();
  }

  private convertExtendedFilters(): void {
    if (this.extendedFilterQueryFields.length === 0) {
      return;
    }

    const sortedFilterItems: { [key: string]: FilterListItemExtendedFilterItem } = {};

    this.extendedFilterQueryFields.forEach(extendedFilter => {
      const config = this.findColumnConfiguration(extendedFilter.field);

      if (config != null) {
        let preparedFilterItem = sortedFilterItems[config.fieldName];
        if (!preparedFilterItem) {
          preparedFilterItem = new FilterListItemExtendedFilterItem();
          preparedFilterItem.filterName = config.title;
          preparedFilterItem.fieldName = config.fieldName;

          sortedFilterItems[config.fieldName] = preparedFilterItem;
        }
        preparedFilterItem.queryFields.push(extendedFilter);
      }
    });
    this.extendedFilters = Object.values(sortedFilterItems);
    setTimeout(() => {
      this.changedFilterPaneHeight.emit();
    }, 50);
  }

  private findColumnConfiguration(filterFieldName): AbstractImmoTableFilter {
    return this.availableExtendedFilters.find(filterSettings => filterFieldName === filterSettings.fieldName);
  }

  remove(filter: FilterListItemExtendedFilterItem): void {
    this.extendedFilterQueryFields = this.extendedFilterQueryFields.filter(extendedFilter => extendedFilter.field !== filter.fieldName);
    this.emitChangedFilter();
  }

  prepareAvailableFilters(): void {

    this.availableFilters = this.availableExtendedFilters.filter(filterSettings => {
      const filters = this.extendedFilters
        .filter(extendedFilter => extendedFilter.fieldName === filterSettings.fieldName)
        .map(extendedFilter => {
          extendedFilter.filterSettings = filterSettings;
          return extendedFilter;
        });

      return filters.length <= 0;
    });
  }

  addFilter(event: Event, filterConfiguration: AbstractImmoTableFilter): void {
    event.preventDefault();
    this.showMoreFilterDropDown = false;

    const filter = new FilterListItemExtendedFilterItem();
    filter.fieldName = filterConfiguration.fieldName;
    filter.filterName = filterConfiguration.title;
    filter.filterSettings = filterConfiguration;
    this.extendedFilters.push(filter);

    this.prepareAvailableFilters();

    if (filter.filterSettings.type === 'BOOLEAN') {
      this.changedBooleanFilter(filter, false);
    }

    setTimeout(() => {
      this.changedFilterPaneHeight.emit();
    }, 50);
  }

  toggleShowMoreFilterDropDown() {
    this.showMoreFilterDropDown = !this.showMoreFilterDropDown;

    if (this.showMoreFilterDropDown) {
      this.emitChangedFilter();
    }
  }

  changedBooleanFilter(filter: FilterListItemExtendedFilterItem, newValue: boolean) {
    let foundFilter = false;
    this.extendedFilterQueryFields.forEach(extendedFilter => {
      if (extendedFilter.field === filter.fieldName) {
        extendedFilter.bValue = newValue;
        foundFilter = true;
      }
    });

    if (!foundFilter) {
      this.extendedFilterQueryFields.push(QueryField.eq(<FieldName>filter.fieldName, newValue));
    }

    this.emitChangedFilter();
  }

  changedTextFilter(filter: FilterListItemExtendedFilterItem, newValue: string) {
    let foundFilter = false;

    const preparedNewValue = newValue;

    this.extendedFilterQueryFields.forEach(extendedFilter => {
      if (extendedFilter.field === filter.fieldName) {
        extendedFilter.sValue = preparedNewValue;
        foundFilter = true;
      }
    });

    if (!foundFilter) {
      this.extendedFilterQueryFields.push(QueryField.like(<FieldName>filter.fieldName, preparedNewValue));
    }

    this.emitChangedFilter();
  }

  changedMinMaxFilter(filter: FilterListItemExtendedFilterItem, fromUntilEvent: FromUntilEvent) {
    let foundMinFilter = false;
    let foundMaxFilter = false;

    this.extendedFilterQueryFields.forEach(extendedFilter => {
      if (extendedFilter.field === filter.fieldName) {
        if (fromUntilEvent.minValue && extendedFilter.comparator === 'gthOrEq') {
          extendedFilter.nValue = fromUntilEvent.minValue;
          foundMinFilter = true;
        }
        if (fromUntilEvent.maxValue && extendedFilter.comparator === 'lthOrEq') {
          extendedFilter.nValue = fromUntilEvent.maxValue;
          foundMaxFilter = true;
        }
      }
    });

    if (!foundMinFilter) {
      const fromValue = fromUntilEvent.minValue;
      if (!fromValue) {
        this.extendedFilterQueryFields = this.extendedFilterQueryFields.filter(extendedFilter => !(extendedFilter.field === filter.fieldName && extendedFilter.comparator === 'gthOrEq'));
      } else {
        this.extendedFilterQueryFields.push(QueryField.gthOrEq(<FieldName>filter.fieldName, fromValue));
      }
    }

    if (!foundMaxFilter) {
      const untilValue = fromUntilEvent.maxValue;
      if (!untilValue) {
        this.extendedFilterQueryFields = this.extendedFilterQueryFields.filter(extendedFilter => !(extendedFilter.field === filter.fieldName && extendedFilter.comparator === 'lthOrEq'));
      } else {
        this.extendedFilterQueryFields.push(QueryField.lthOrEq(<FieldName>filter.fieldName, untilValue));
      }
    }

    if (fromUntilEvent.forcedChange) {
      this.emitChangedFilter();
      return;
    }

    this.isDirty = true;
  }

  changedEnumFilter(filter: FilterListItemExtendedFilterItem, newValues: string[]) {
    const filterSettings = filter.filterSettings as ImmoTableEnumFilter;

    let foundFilter = false;
    this.extendedFilterQueryFields.forEach(extendedFilter => {
      if (extendedFilter.field === filter.fieldName) {

        if (newValues.length === 1) {
          extendedFilter.combineListElementsWith = null;
          extendedFilter.sValueList = null;
          extendedFilter.sValue = newValues[0];

        } else {
          extendedFilter.sValue = null;
          extendedFilter.combineListElementsWith = filterSettings.listCombineOperator;
          extendedFilter.sValueList = newValues;
        }

        foundFilter = true;
      }
    });

    if (!foundFilter) {

      if (newValues.length === 1) {
        this.extendedFilterQueryFields.push(QueryField.eq(<FieldName>filter.fieldName, newValues[0]));

      } else {
        const queryField = new QueryField();
        queryField.field = <FieldName>filter.fieldName;
        queryField.comparator = 'eq';
        queryField.sValueList = newValues;
        queryField.combineListElementsWith = filterSettings.listCombineOperator;

        this.extendedFilterQueryFields.push(queryField);
      }
    }

    this.emitChangedFilter();
  }

  changeShowOnlyActivePropertiesFilter(showActive: boolean) {
    if (this.showOnlyActivePropertiesFilterDisabled) {
      return;
    }

    this.changedShowOnlyActivePropertiesFilter.emit(showActive);
  }

  changedOffererFilter(filter: FilterListItemExtendedFilterItem, offerers: string[]) {
    const filterSettings = filter.filterSettings as ImmoTableOffererFilter;

    let foundFilter = false;
    this.extendedFilterQueryFields.forEach(extendedFilter => {
      if (extendedFilter.field === filter.fieldName) {

        if (offerers.length === 1) {
          extendedFilter.combineListElementsWith = null;
          extendedFilter.sValueList = null;
          extendedFilter.sValue = offerers[0];

        } else {
          extendedFilter.sValue = null;
          extendedFilter.combineListElementsWith = 'or';
          extendedFilter.sValueList = offerers;
        }

        foundFilter = true;
      }
    });

    if (!foundFilter) {
      if (offerers.length === 1) {
        this.extendedFilterQueryFields.push(QueryField.eq(<FieldName>filter.fieldName, offerers[0]));

      } else {
        const queryField = new QueryField();
        queryField.field = <FieldName>filter.fieldName;
        queryField.comparator = 'eq';
        queryField.sValueList = offerers;
        queryField.combineListElementsWith = 'or';

        this.extendedFilterQueryFields.push(queryField);
      }
    }

    this.emitChangedFilter();
  }

  startSearch(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.emitChangedFilter();
  }

  private emitChangedFilter(): void {
    this.isDirty = false;

    this.changedFilter.emit(this.extendedFilterQueryFields);
  }
}
