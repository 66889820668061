<div class="mis-immo-table-details">

  <div class="mis-immo-table-details-container-wrapper"
       [ngStyle]="{'max-width': contentWidth + marginLeft + 'px', 'padding-left': marginLeft + 'px'}">

    <div *ngIf="visible"
         class="mis-button-pane">
      <button (click)="openDetails()"><i class="fas fa-clipboard-list"></i> Exposé öffnen</button>

      <!-- Excel Download -->
      <button *ngIf="!isDownloadActive"
              (click)="downloadAuswertung($event)"
              title="Herunterladen der Detailanalyse">
        <i class="fas fa-file-download"></i> Excel-Auswertung herunterladen (Beta)
      </button>
      <div class="mis-immo-table-cell-loading"
           *ngIf="isDownloadActive">
        <mis-loading size="tiny"></mis-loading>
        Auswertung wird zusammengestellt ...
      </div>

      <!--<p *ngIf="!isDownloadActive">-->
      <!--&lt;!&ndash;      <a href="#"&ndash;&gt;-->
      <!--&lt;!&ndash;         title="Herunterladen des Excel-Kalkulationstools"&ndash;&gt;-->
      <!--&lt;!&ndash;         (click)="downloadCalculationTool($event)">&ndash;&gt;-->
      <!--&lt;!&ndash;        <i class="fas fa-calculator"></i> Excel-Kalkulationstool&ndash;&gt;-->
      <!--&lt;!&ndash;      </a>&ndash;&gt;-->
      <!--<i class="fas fa-calculator"></i> Excel-Kalkulationstool (i.V.)-->
      <!--</p>-->

    </div>

    <div *ngIf="showTabs && visible">
      <mis-tabs-group>
        <mis-tab-item tabTitle="Analyse"
                      tabTitleIcon="far fa-chart-bar"
                      (isActivated)="showTab('STATISTICS')">

          <div class="mis-immo-table-details-mobile-spacer"></div>

          <mis-tabs-group>
            <mis-tab-item tabTitle="Angebotsspektrum"
                          tabTitleIcon="far fa-chart-bar"
                          (isActivated)="showAnalysisTab('BY_FILTER')">

              <p>Hier wird das aktuelle Objekt mit allen anderen Objekten ihres gewählten Suchfilters verglichen.
                Schränken Sie Ihren Filter weiter ein, um statistische Ausreißer herauszufiltern, oder vergrößern Sie
                die Vergleichsbasis indem Sie den Filter weiter stellen.</p>

              <mis-immo-analysis-by-filter *ngIf="activeAnalysisTab === 'BY_FILTER'"
                                           [filterQuery]="filterQuery"
                                           [researchItem]="researchItem"></mis-immo-analysis-by-filter>

            </mis-tab-item>
            <mis-tab-item tabTitle="Objektauswertung"
                          tabTitleIcon="fas fa-chart-pie"
                          (isActivated)="showAnalysisTab('BY_OBJECT')">

              <p>Hier wird das aktuelle Objekt mit anderen Objekten verglichen, welche sich im gleichen
                Ort/Postleitzahlengebiet befinden und eine vergleichbare Größe (Fläche) und Zimmeranzahl haben.
                Die statistischen Vergleichsdaten werden nicht durch Filterung beeinflusst, sondern werden durch den
                Datenbestand in unserem System errechnet.</p>

              <mis-immo-analysis-by-object *ngIf="activeAnalysisTab === 'BY_OBJECT'"
                                           [researchItem]="researchItem"></mis-immo-analysis-by-object>
            </mis-tab-item>
          </mis-tabs-group>

        </mis-tab-item>

        <mis-tab-item tabTitle="Preishistorie"
                      tabTitleIcon="fas fa-chart-line"
                      (isActivated)="showTab('PRICE_HISTORY')">

          <div class="mis-immo-table-details-mobile-spacer"></div>

          <mis-immo-price-history [researchItem]="researchItem"></mis-immo-price-history>
        </mis-tab-item>

        <mis-tab-item tabTitle="Karte - {{researchItem.adresse.rawLocation}}"
                      tabTitleIcon="fas fa-map-marker-alt">
          <mis-map [markers]="mapMarkers" height="400"
                   [zipCode]="researchItem.adresse.postleitzahl"></mis-map>
        </mis-tab-item>

      </mis-tabs-group>
    </div>

    <div *ngIf="!showTabs && visible"
         class="mis-row mis-immo-table-details-container-inline">

      <div class="mis-column-50">
        <div class="mis-immo-table-details-container">

          <mis-tabs-group>
            <mis-tab-item tabTitle="Angebotsspektrum"
                          tabTitleIcon="far fa-chart-bar"
                          (isActivated)="showAnalysisTab('BY_FILTER')">

              <p>Hier wird das aktuelle Objekt mit allen anderen Objekten ihres gewählten Suchfilters verglichen.
                Schränken Sie Ihren Filter weiter ein, um statistische Ausreißer herauszufiltern, oder vergrößern Sie
                die Vergleichsbasis indem Sie den Filter weiter stellen.</p>

              <mis-immo-analysis-by-filter *ngIf="activeAnalysisTab === 'BY_FILTER'"
                                           [filterQuery]="filterQuery"
                                           [researchItem]="researchItem"></mis-immo-analysis-by-filter>
            </mis-tab-item>
            <mis-tab-item tabTitle="Objektauswertung"
                          tabTitleIcon="fas fa-chart-pie"
                          (isActivated)="showAnalysisTab('BY_OBJECT')">

              <p>Hier wird das aktuelle Objekt mit anderen Objekten verglichen, welche sich im gleichen
                Ort/Postleitzahlengebiet befinden und eine vergleichbare Größe (Fläche) und Zimmeranzahl haben.
                Die statistischen Vergleichsdaten werden nicht durch Filterung beeinflusst, sondern werden durch den
                Datenbestand in unserem System errechnet.</p>

              <mis-immo-analysis-by-object *ngIf="activeAnalysisTab === 'BY_OBJECT'"
                                           [researchItem]="researchItem"></mis-immo-analysis-by-object>
            </mis-tab-item>

            <mis-tab-item tabTitle="Preishistorie"
                          (isActivated)="showPriceHistoryTab('PRICE')">
              <mis-immo-price-history *ngIf="activePriceHistoryTab === 'PRICE'"
                                      type="PRICE"
                                      [researchItem]="researchItem"></mis-immo-price-history>
            </mis-tab-item>
            <mis-tab-item tabTitle="Preishistorie / m²"
                          (isActivated)="showPriceHistoryTab('PRICE_BY_SIZE')">
              <mis-immo-price-history *ngIf="activePriceHistoryTab === 'PRICE_BY_SIZE'"
                                      type="PRICE_BY_SIZE"
                                      [researchItem]="researchItem"></mis-immo-price-history>
            </mis-tab-item>
          </mis-tabs-group>
        </div>
      </div>

      <div class="mis-column-50">
        <div class="mis-immo-table-details-container">
          <mis-tabs-group>
            <mis-tab-item tabTitle="Karte - {{researchItem.adresse.rawLocation}}"
                          tabTitleIcon="fas fa-map-marker-alt">
              <mis-map [markers]="mapMarkers" height="400"
                       [zipCode]="researchItem.adresse.postleitzahl"></mis-map>
            </mis-tab-item>
          </mis-tabs-group>
        </div>
      </div>

    </div>

  </div>
</div>
