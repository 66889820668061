import {Component, HostListener, OnInit} from '@angular/core';
import {AuthenticationProvider} from './services/aaa/authentication.provider';
import {MisUserWithAccount} from './services/aaa/model/account.model';
import {AuthenticationService} from './services/aaa/authentication.service';
import {RoutingService} from './services/routing.service';
import {ViewServiceInstance} from './viewservice/view.service';
import {EventKey, EventService} from './services/event.service';
import {GuidedTourService, GuidedTourType} from './components/common/guided-tour/guided.tour.service';

@Component({
  selector:    'mis-app-root',
  templateUrl: './app.component.html',
  styleUrls:   ['./app.component.scss']
})
export class AppComponent implements OnInit {
  authenticatedUser: MisUserWithAccount;
  initialized: boolean;
  showBackgroundImage: boolean;
  showDetailedHeader: boolean;
  contentHeight: string;

  constructor(private authenticationProvider: AuthenticationProvider,
              private authenticationService: AuthenticationService,
              private routingService: RoutingService,
              private eventService: EventService,
              private guidedTourService: GuidedTourService) {

    // Init user change listener
    this.authenticationProvider.subscribeUserChanges((userUpdate) => this.updateViewAfterUserChange(userUpdate));
    this.authenticationProvider.subscribeChallengesRequired((user) => this.showTermsHintIfNecessary(user));

    // header change listener
    this.eventService.on(EventKey.HEADER_MENU_OPENED, (showHeaderDetails) => {
      this.showDetailedHeader = showHeaderDetails;
    });
  }

  ngOnInit(): void {
    this.onResize();

    if (this.authenticationProvider.isAuthenticated()) {

      this.authenticationService.refreshAuthenticationToken().subscribe((user) => {
        // Nichts zu tun. Der Refresh wird via subscribeUserChanges durchgeführt. Siehe constructor.

      }, (error) => {
        this.routingService.navigate('login');
        this.showBackgroundImage = true;
        this.removeInitializationSpinner();
      });

    } else {
      // AuthenicationGuard routes to login
      this.showBackgroundImage = true;
      this.removeInitializationSpinner();
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    ViewServiceInstance.updateViewSize();
    this.contentHeight = window.innerHeight + 'px';

  }

  removeInitializationSpinner(): void {
    this.initialized = true;

    const initWrapper = document.getElementById('mis-initialization-wrapper');
    if (!initWrapper) {
      return;
    }
    initWrapper.classList.add('mis-initialization-hide');
    setTimeout(() => {
      initWrapper.remove();
    }, 400);
  }

  updateRouteForAuthenticatedUser() {
    const requestedAction = this.routingService.getRouter().url;

    if (this.authenticatedUser != null) {
      if (requestedAction === '/registration' ||
        requestedAction === '/login' ||
        requestedAction === '/challenges' ||
        requestedAction === '/password-forgotten') {

        this.routingService.navigateAfterLogin();
        this.showBackgroundImage = false;
      }

    } else {
      if (requestedAction !== '/registration' && requestedAction !== '/login') {
        this.routingService.navigate('/login');
        this.showBackgroundImage = true;
      }
    }
  }

  private showTermsHintIfNecessary(user: MisUserWithAccount): void {
    this.routingService.navigate('/challenges');
    this.removeInitializationSpinner();
  }

  updateViewAfterUserChange(user: MisUserWithAccount): void {
    this.authenticatedUser = user;

    this.updateRouteForAuthenticatedUser();
    this.removeInitializationSpinner();

    this.guidedTourService.startTour(GuidedTourType.INITIAL_TOUR);
  }
}
