<div class="mis-filter-pane-wrapper"
     [ngClass]="{'mis-filter-pane-wrapper-mobile': (viewServiceInstance.isMobile() && !viewServiceInstance.isTablet())}">

  <div class="mis-filter-pane-main-wrapper"
       [joyrideStep]="guidedTourStep.IMMO_FILTER_PANE_MAIN"
       title="Hauptfilter"
       text="Wählen Sie hier aus, nach welchen Kriterien Sie Suchen möchten. Sie können nach mehreren Orten und Postleitzahlen/Postleitzahlgebieten gleichzeitig suchen (Vorsicht: wenn Sie beide Werte angeben, werden nur Immobilien angezeigt, welche sich im Postleizahlgebiet der angegbenen Stadt befinden). Sollten Sie in die Situation kommen, keine Suchergebnisse zu bekommen, heben Sie ggf. einige Filterungen auf.">
    <div class="mis-row">
      <div class="mis-filter-pane-left">
        <div class="mis-row">
          <div class="mis-column-50">
            <mis-form-field-dropdown label="Angebotstyp"
                                     [values]="availableOfferTypes"
                                     [selectedValues]="[selectedOfferType]"
                                     (changed)="changedOfferType($event)"></mis-form-field-dropdown>
          </div>

          <div class="mis-column-50">
            <mis-form-field-dropdown label="Immobilientyp"
                                     [values]="availablePropertyTypes"
                                     [selectedValues]="[selectedPropertyType]"
                                     (changed)="changedProperty($event)"></mis-form-field-dropdown>
          </div>
        </div>
      </div>

      <div class="mis-column-25">
        <div *ngIf="restrictedZipCodes.length > 0"
             class="filter-pane-restriction-info"
             title="">
          <i class="fas fa-info-circle"></i>

          <div class="mis-tooltip">
            <div class="mis-tooltip-content">
              Sie können nur Objekte im Postleitzahlengebiet
              <span *ngFor="let zipCode of restrictedZipCodes; let last = last;">{{ zipCode }}<span *ngIf="!last">, </span></span>
              sehen.
            </div>
          </div>
        </div>

        <mis-form-field-dropdown icon="fas fa-search-location"
                                 label="Orte"
                                 [isAutoComplete]="true"
                                 [isMultiSelect]="true"
                                 [selectedValues]="selectedCities"
                                 (loadAutoCompleteValues)="loadDropdownCityValues($event)"
                                 (changed)="changedCities($event)"></mis-form-field-dropdown>
      </div>
      <div class="mis-column-25">
        <div *ngIf="restrictedZipCodes.length > 0"
             class="filter-pane-restriction-info"
             title="">
          <i class="fas fa-info-circle"></i>

          <div class="mis-tooltip">
            <div class="mis-tooltip-content">
              Sie können nur Objekte im Postleitzahlengebiet
              <span *ngFor="let zipCode of restrictedZipCodes; let last = last;">{{ zipCode }}<span *ngIf="!last">, </span></span>
              sehen.
            </div>
          </div>
        </div>

        <mis-form-field-dropdown icon="fas fa-search-location"
                                 label="Postleitzahlen"
                                 [isAutoComplete]="true"
                                 [isMultiSelect]="true"
                                 [allowEnteredValue]="true"
                                 [selectedValues]="selectedZipCodes"
                                 (loadAutoCompleteValues)="loadDropdownZipCodeValues($event)"
                                 (changed)="changedZipCodes($event)"></mis-form-field-dropdown>
      </div>

      <div class="mis-filter-pane-right">
        <a href="#"
           class="mis-filter-pane-reset"
           (click)="triggerResetFilter($event)"
           [joyrideStep]="guidedTourStep.IMMO_FILTER_RESET"
           title="Filter zurücksetzen"
           text="Klicken Sie hier, um alle Suchfilter aufzuheben.">
          <i class="fas fa-trash-alt"></i>
          <span>Zurücksetzen</span>
        </a>

        <a href="#"
           class="mis-filter-pane-time-range"
           (click)="toggleDetailFilterPane($event)"
           [joyrideStep]="guidedTourStep.IMMO_FILTER_TIME_RANGE_FILTER"
           title="Filterzeitraum"
           text="Hier haben Sie die Möglichkeit Ihre Suche auf eine bestimmte Zeitspanne zu begrenzen.">
          <i class="fas fa-clock"></i>
          <span>{{filterDateRangeDescription}}</span>
          <span class="mis-filter-pane-time-range-short">{{filterDateRangeDescriptionShort}}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="mis-filter-detail-pane-wrapper"
       [ngClass]="{'opened': showDetailFilterPane}">
    <div class="mis-filter-detail-pane-container"
         #filterTimeRangePane>
      <mis-filter-time-range [filterQuery]="filterQuery"
                             (changedFilter)="updateDateRange($event)"></mis-filter-time-range>
    </div>
  </div>

  <div class="mis-filter-detail-pane-wrapper"
       [ngClass]="{'opened': showDetailFilterPane}">
    <div class="mis-filter-detail-pane-container"
         #filterDetailPane>
      <mis-filter-extended-items [filterQuery]="filterQuery"
                                 [availableExtendedFilters]="availableExtendedFilters"
                                 [extendedFilterQueryFields]="extendedFilterQueryFields"
                                 [showOnlyActiveProperties]="showOnlyActiveProperties"
                                 [showOnlyActivePropertiesFilterDisabled]="showOnlyActivePropertiesFilterDisabled"
                                 [viewType]="viewType"
                                 (changedShowOnlyActivePropertiesFilter)="updateShowOnlyActiveProperties($event)"
                                 (changedFilter)="updateExtendedFilter($event)"
                                 (changedFilterPaneHeight)="emitUpdateFilterPaneHeightEvent()"></mis-filter-extended-items>
    </div>
  </div>

  <div class="mis-filter-detail-toggle-pane"
       [ngClass]="{'opened': showDetailFilterPane}">
    <a href="#"
       *ngIf="!showDetailFilterPane"
       (click)="openDetailFilterPane($event)"
       [joyrideStep]="guidedTourStep.IMMO_FILTER_EXTENDED_FILTERS"
       title="Weitere Filter"
       text="Klicken Sie hier, um Ihr Suchergebnis noch weiter einzuschränken. Wählen Sie über die 'Weitere Filter +' Schaltfläche weitere Kriterien aus und fügen Sie Ihre Filter hinzu.">
      weitere Filter anzeigen
      <span *ngIf="extendedFilterQueryFields.length > 1">
          {{extendedFilterQueryFields.length - 1}}
      </span>
    </a>

    <a href="#"
       *ngIf="showDetailFilterPane"
       (click)="closeDetailFilterPane($event)">
      weniger Filter anzeigen
    </a>
  </div>
</div>
