import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmationModalData {
  title: string;
  message: string;
}

@Component({
  selector: 'mis-confirmation-modal',
  templateUrl: './confirmation.modal.component.html'
})
export class ConfirmationModalComponent {
  dialogRef: MatDialogRef<ConfirmationModalComponent>;
  modalData: ConfirmationModalData;

  constructor(dialogRef: MatDialogRef<ConfirmationModalComponent>,
              @Inject(MAT_DIALOG_DATA) modalData: ConfirmationModalData) {
    this.dialogRef = dialogRef;
    this.modalData = modalData;
  }
}
