<div class="mis-login-wrapper mis-column-horizontal-slidable mis-column-horizontal-slidable-0">
  <div class="mis-column-horizontal-slidable-item-0">
    <div>
      <h1>Registierung</h1>

      <div *ngIf="!isLoading && !registrationFailedShowSupportHint">
        <p>Willkommen bei propfox.io! Anmelden und loslegen.</p>

        <form [formGroup]="formGroup"
              (submit)="saveForm()">

          <mat-form-field>
            <input matInput
                   formControlName="email"
                   name="email"
                   type="email"
                   placeholder="E-Mail-Adresse"
                   required="required">
            <div class="mis-field-error" *ngIf="registrationFailedShowEmailHint">
              Die gewählte E-Mail-Adresse ist bereits vergeben, wählen Sie bitte eine andere.
            </div>
          </mat-form-field>

          <mat-form-field>
            <input matInput
                   formControlName="password"
                   name="password"
                   type="password"
                   placeholder="Passwort"
                   required="required">
            <div class="mis-field-error"
                 *ngIf="formGroup.controls['password'].errors?.pattern || registrationFailedShowPasswordHint">
              Das Passwort muss Buchstaben und Zahlen enthalten.
            </div>
            <div class="mis-field-error"
                 *ngIf="formGroup.controls['password'].errors?.minlength || registrationFailedShowPasswordHint">
              Das Passwort muss mindestens 8 Zeichen lang sein.
            </div>
          </mat-form-field>

          <mat-checkbox ngClass="{{hasError('termsAndPrivacy') ? 'mis-error' : ''}}"
                        formControlName="termsAndPrivacy">
            Ich stimme den <a href="https://www.propfox.io/datenschutz" target="_blank">Allgemeinen
            Geschäftsbedingungen</a> und den
            <a href="https://www.propfox.io/agb" target="_blank">Datenschutzbestimmungen</a> laut DSGVO zu.*
          </mat-checkbox>

          <mat-checkbox formControlName="newsletter">
            Ja, ich möchte über Neuigkeiten per E-Mail informiert werden.
          </mat-checkbox>

          <mis-recaptcha [showCaptcha]="isRegistrationVisible"
                         [formGroup]="formGroup"></mis-recaptcha>

          <button>Jetzt kostenlos registrieren!</button>

        </form>
      </div>

      <div *ngIf="isLoading">
        <p>Vielen Dank für Ihre Registrieren. Wir legen nur noch schnell ein Konto für Sie an, anschließend werden Sie
          automatisch in das Portal weitergeleitet.</p>

        <mis-loading></mis-loading>
      </div>

      <div *ngIf="registrationFailedShowSupportHint">
        <mis-support-hint></mis-support-hint>
      </div>

    </div>
  </div>
</div>
