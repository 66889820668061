import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MisTagUtils, MisUser} from '../../../../../services/aaa/model/account.model';
import {UserEditModalComponent} from './user-edit-modal/user.edit.modal.component';
import {UserFlowFactDataEditModalComponent} from './user-flow-fact-data-edit-modal/user.flow.fact.data.edit.modal.component';
import {PasswordChangeModalComponent} from './password-change-modal/password.change.modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MisEventUtils} from '../../../../../services/common/event.util';
import * as moment from 'moment';
import {AuthenticationProvider} from '../../../../../services/aaa/authentication.provider';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../../../../../services/aaa/user.service';
import {AbstractFormComponent} from '../../../../basic/form/abstract.form.component';

@Component({
  selector: 'mis-user-details',
  templateUrl: './user.details.component.html',
  styleUrls: ['./user.details.component.scss']
})
export class UserDetailsComponent extends AbstractFormComponent implements OnInit {
  @Input() user: MisUser;
  @Output() closed = new EventEmitter<void>();

  hasFlowFactData: boolean;
  termAcceptanceDate: Date;
  showNewsletterInfo: boolean;

  constructor(private dialog: MatDialog,
              formBuilder: FormBuilder,
              private authenticationProvider: AuthenticationProvider,
              private userService: UserService) {
    super();

    this.formGroup = formBuilder.group({
      'newsletter': ['']
    });
  }

  ngOnInit(): void {
    this.hasFlowFactData = this.hasFlowFactDataTags();
    this.termAcceptanceDate = this.getTermsAcceptanceDate();

    this.showNewsletterInfo = this.user.id === this.authenticationProvider.getUser().id;
    this.initNewsletterForm();
  }

  editUserData(event): void {
    MisEventUtils.stopEvent(event);

    this.dialog.open(UserEditModalComponent, {
      data: this.user
    });
  }

  editFlowFactData(): void {
    this.dialog.open(UserFlowFactDataEditModalComponent, {
      data: this.user
    });
  }

  openPasswordChangeModal(): void {
    this.dialog.open(PasswordChangeModalComponent, {
      data: this.user
    });
  }

  private hasFlowFactDataTags() {
    return this.user.tags.filter(tag => tag.key === MisTagUtils.keys.flowFactServerUserName).length > 0;
  }

  getFlowFactUserName() {
    return MisTagUtils.getTagByKey(this.user.tags, MisTagUtils.keys.flowFactServerUserName).value;
  }

  getTermsAcceptanceDate(): Date {
    const acceptanceDate = MisTagUtils.getTagByKey(this.user.tags, MisTagUtils.keys.termsAcceptanceDate).value;
    return moment(acceptanceDate).toDate();
  }

  private initNewsletterForm(): void {
    this.userService.hasNewsletter().subscribe((hasNewsletter) => {
      this.formGroup.get('newsletter').setValue(hasNewsletter);
    }, (err) => {
    });
  }

  customSaveAction(): void {
    const registerNewsletter = this.formGroup.get('newsletter').value;
    this.userService.updateNewsletter(registerNewsletter).subscribe(() => {
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
    });
  }
}
