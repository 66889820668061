import {Component, Input} from '@angular/core';
import {EventKey, EventService} from '../../../services/event.service';
import * as moment from 'moment';
import {ViewServiceChangeListener, ViewServiceInstance} from '../../../viewservice/view.service';
import {MisEventUtils} from '../../../services/common/event.util';
import {GuidedTourStep} from '../../common/guided-tour/guided.tour.service';

@Component({
  selector: 'mis-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements ViewServiceChangeListener {
  @Input() showNavigation: boolean;

  guidedTourStep = GuidedTourStep;
  viewServiceInstance = ViewServiceInstance;

  isXMas = false;
  contentHeight = '700px';
  showHeaderDetails: boolean = false;

  constructor(private eventService: EventService) {
    ViewServiceInstance.listenOnResize(this);

    const xMasBegin = moment('0112', 'DDMM');
    const xMasEnd = moment('2812', 'DDMM');
    this.isXMas = moment().isAfter(xMasBegin) && moment().isBefore(xMasEnd);
  }

  onResize(): void {
    this.contentHeight = window.innerHeight + 'px';
  }

  toggleHeaderDetails(event: Event): void {
    MisEventUtils.stopEvent(event);
    this.showHeaderDetails = !this.showHeaderDetails;

    this.eventService.broadcast(EventKey.HEADER_MENU_OPENED, this.showHeaderDetails);
  }
}
