import {Pipe, PipeTransform} from '@angular/core';
import {MisBaseUtils} from '../services/common/base.util';

/**
 * Custom pipe to translate user role keys to words.
 */
@Pipe({name: 'misNumberFormat'})
export class TranslateNumberFormatPipe implements PipeTransform {

  transform(value: number, digits: number = 2): string {
    return MisBaseUtils.formatNumber(value, digits);
  }
}
