<div>
  <h1>Anmeldung</h1>

  <div>
    <p>Willkommen bei propfox.io! Jetzt einloggen und loslegen.</p>

    <form [formGroup]="loginFormGroup"
          (submit)="login()"
          *ngIf="!showLoading">

      <mat-form-field>
        <input matInput
               formControlName="email"
               name="email"
               type="email"
               placeholder="E-Mail"
               required="required">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               formControlName="password"
               name="password"
               type="password"
               placeholder="Passwort"
               required="required">
      </mat-form-field>

      <div class="mis-error"
           *ngIf="showLoginFailed">
        <p>Der Login ist fehlgeschlagen. Bitte prüfen Sie Ihre E-Mail-Adresse und Ihr Passwort.</p>
      </div>

      <button>Anmelden</button>
    </form>
  </div>

  <mis-loading *ngIf="showLoading"></mis-loading>
</div>
