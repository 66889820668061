import {Component} from '@angular/core';
import {MisUserCreate} from '../../../../../services/aaa/model/account.model';
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../../../../../services/aaa/user.service';
import {MisBaseUtils} from '../../../../../services/common/base.util';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../basic/snackbar/snackbar.service';
import {AbstractFormComponent} from '../../../../basic/form/abstract.form.component';

@Component({
  selector: 'mis-user-create-modal',
  templateUrl: './user.create.modal.component.html'
})
export class UserCreateModalComponent extends AbstractFormComponent {
  userCreationFailed: boolean;

  constructor(public dialogRef: MatDialogRef<UserCreateModalComponent>,
              private snackBar: SnackbarService,
              private formBuilder: FormBuilder,
              private userService: UserService) {
    super();

    this.formGroup = formBuilder.group({
      'salutation': [''],
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email, this.validateEmailAddressExists()]],
      'role': ['ACCOUNT_MEMBER', [Validators.required]],
      'phone': [''],
      'mobile': [''],
    });
  }

  customSaveAction(): void {
    const user = this.buildUser();

    this.userService.createUser(user).subscribe(() => {
      this.snackBar.success('Der Nutzer wurde erfolgreich angelegt.');
      this.dialogRef.close();
    }, (err) => {

      this.userCreationFailed = true;
      this.formGroup.get('email').markAsTouched();

      this.isLoading = false;
      this.snackBar.error('Der Nutzer konnte nicht angelegt werden. Die E-Mail-Adresse existiert bereits im System.');
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private buildUser(): MisUserCreate {
    const user = new MisUserCreate();
    user.salutation = MisBaseUtils.getValueOrDefault(this.formGroup.get('salutation').value, null);

    user.firstName = this.formGroup.get('firstName').value;
    user.lastName = this.formGroup.get('lastName').value;
    user.email = this.formGroup.get('email').value;
    user.password = this.formGroup.get('password').value;

    user.role = this.formGroup.get('role').value;
    user.phone = MisBaseUtils.getValueOrDefault(this.formGroup.get('phone').value, null);
    user.mobile = MisBaseUtils.getValueOrDefault(this.formGroup.get('mobile').value, null);

    return user;
  }

  validateEmailAddressExists(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.userCreationFailed ? {'alreadyExists': true} : null;
    };
  }
}
